import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { PeopleHeader } from './PeopleHeader';
import './style.scss';
import { getUserDetails } from '../../config/utils';
import Header from '../../containers/Header';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const PageHeader: React.FC<any> = (props) => {
	const { enqueueSnackbar } = useSnackbar();
	const { listOkrResult } = props;
	const { t } = useTranslation();

	// const [isUnLockRequested, setIsUnLockRequested] = useState<Boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '' });
	const userDetail: any = getUserDetails();
	const currentYear: any = getLocalStorageItem('year') || null;
	const currentUser = getLocalStorageItem('currentUser')
		? JSON.parse(getLocalStorageItem('currentUser') || '{}')
		: null;
	useEffect(() => {
		window.addEventListener('scroll', scrollPeopleHeader);
		return () => {
			window.removeEventListener('scroll', scrollPeopleHeader);
		};
	});

	useEffect(() => {
		let unlockUser = getLocalStorageItem('unlockUser') || null;
		if (listOkrResult && listOkrResult.isLocked && unlockUser) {
			// setModalProps({
			// 	open: true,
			// 	message: isUnLockRequested ? t('request4AlreadyRaised') : t('request4Editing', { emailID: userDetail.emailId }),
			// 	unlockRequest: true,
			// });
			removeLocalStorageItem('unlockUser');
		}
	}, [listOkrResult]);

	const history = useHistory();
	useEffect(() => {
		document.body.classList.add('guideme-home');
		return () => {
			document.body.classList.remove('guideme-home');
		};
	}, [history.location]);

	// const sendUnlockRequest = () => {
	// 	setModalProps({
	// 		open: true,
	// 		message: isUnLockRequested ? t('request4AlreadyRaised') : t('request4Editing', { emailID: userDetail.emailId }),
	// 		unlockRequest: true,
	// 	});
	// };

	// const handleCloseModal = async (e: any, type: any) => {
	// 	if (type === 1) {
	// 		const data = {
	// 			year: currentYear,
	// 			cycle: currentCycleId,
	// 			employeeId: userDetail.employeeId,
	// 		};
	// 		try {
	// 			setModalProps({ open: false });
	// 			const response = await props.unlockRequest(data);
	// 			if (response.data.status === 200) {
	// 				setIsUnLockRequested(true);
	// 				enqueueSnackbar(response.data.messageList.unlockStatus, {
	// 					variant: 'success',
	// 					autoHideDuration: 5000,
	// 				});
	// 			} else {
	// 				enqueueSnackbar(t('unlockRequest'), {
	// 					variant: 'error',
	// 					autoHideDuration: 5000,
	// 				});
	// 			}
	// 		} catch (err) {
	// 			enqueueSnackbar(t('unlockRequest'), {
	// 				variant: 'error',
	// 				autoHideDuration: 5000,
	// 			});
	// 		}
	// 	}

	// 	setModalProps({ open: false, message: '' });
	// };
	const scrollPeopleHeader = (e: any) => {
		const objElement = document.getElementById('obj-header');
		const peopleElement = document.getElementById('people-header');
		const wrapElement = document.getElementById('wrap-container');
		if (window.pageYOffset >= 88) {
			peopleElement?.classList.add('sticky-header');
			objElement?.classList.add('sticky-header');
			wrapElement?.classList.add('sticky-bar-active');
		}
		if (window.pageYOffset < 70) {
			peopleElement?.classList.remove('sticky-header');
			objElement?.classList.remove('sticky-header');
			wrapElement?.classList.remove('sticky-bar-active');
		}
	};

	return (
		<Fragment>
			{/* {!showArchiveList && ( */}
			{listOkrResult && currentUser && (
				<>
					<Box className='page-subheader' id='people-header'>
						<Box className='page-subheader-inner'>
							<Box className='page-subheader-left'>
								<Box className='page-subheader-title'>
									<Typography variant='h2'>{t('alignLabel')}</Typography>
								</Box>
							</Box>
							<Box className='page-subheader-right'>
								<Header {...props} fromTab={true} />
							</Box>
						</Box>
					</Box>
					<PeopleHeader
						{...props}
						currentUser={currentUser}
						listOkrResult={listOkrResult}
						loggedInUser={!currentUser}
						userDetail={userDetail}
					/>

					{/* <Box
						className={`content-header `}
						display='flex'
						alignItems='center'
						justifyContent='space-between'
						id='goal-header'
					> */}
					{/* {listOkrSuccess === 'success' && ( */}
					{/* <Box className='content-header-wrap'>
    <Box className='content-header-left'> */}
					{/* {!currentUser && (
            <Fragment>
                <Box className='header-title'>
                    <Typography variant='h2' className='my-name'>
                        {listOkrResult.welcomeMessage ? <>Hi, {userDetail.firstName}</> : ''}
                    </Typography>
                    <Typography>
                        {listOkrResult && listOkrResult.welcomeMessage ? listOkrResult.welcomeMessage : ''}
                    </Typography>
                </Box>
            </Fragment>
        )} */}
					{/* {listOkrResult && currentUser && (
            <PeopleHeader {...props} currentUser={currentUser} listOkrResult={listOkrResult} />
        )} */}
					{/* </Box>
    <Box className={`content-header-right ${props.showAddGoal ? 'create-form-display' : ''}`}> */}
					{/* {listOkrResult && listOkrResult.isLocked && !currentUser && (
            <Tooltip title={`${t('lockTooltipText')}`} arrow>
                <Button
                    disabled={currentUser ? true : false}
                    className='btn-circle-white lockPurple'
                    onClick={() => sendUnlockRequest()}
                    id={'lock-btn'}
                >
                    <LockIcon />
                </Button>
            </Tooltip>
        )} */}
					{/* {listOkrResult && !currentUser ? (
            // <PersonalFeedbackMenu {...props} loggedInUser={!currentUser} userDetail={userDetail} />
            <></>
        ) : (
            <PersonalFeedbackAction {...props} loggedInUser={!currentUser} userDetail={userDetail} />
        )} */}

					{/* {checkPermission(getPermissionResult.employeePermissions, createOkrs) ? (
            <CreateAlignOkr {...props} showAddGoalForm={showAddGoalForm} panelOpenFrom={HOME} />
        ) : (
            <></>
        )} */}

					{/* {modalProps.open && (
            <AlertDialog
                module='myGoal'
                message={modalProps.message}
                handleCloseModal={handleCloseModal}
                modalOpen={modalProps.open}
            />
        )} */}
					{/* </Box> */}
					{/* </Box> */}
					{/* )} */}
					{/* </Box> */}
				</>
			)}
			{/* )} */}
			{/* <OnBoardingPopup showAddGoalForm={showAddGoalForm} currentRoute={currentRoute} {...props} /> */}
		</Fragment>
	);
};
