import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getNomineeData, saveNomineeData } from '../../action/perform';
import { getUserDetails } from '../../config/utils';
import { NominateDrawer } from './NominateDrawer';
import { MainDrawer } from '../Admin/CommonComponent/MainDrawer';
import { useSnackbar } from 'notistack';
import { showLoader } from '../../action/common';

export const NominateMainDrawer: React.FC<any> = (props: any) => {
	const { nominatedUser, performanceNomineeId, setOpenNominateDrawer, openNominateDrawer, setReloadRequired } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	let userDetail = getUserDetails();
	const dispatch = useDispatch();
	const [checkinSettingList, setCheckinSettingList] = React.useState<any>([]);
	const [selectedUser, setSelectedUser] = React.useState<any>([]);
	const [selectedUserList, setSelectedUserList] = React.useState<any>([]);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

	const [paggingInfo, setPaggingInfo] = useState<any>({
		page: 1,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
	});

	useEffect(() => {
		if (nominatedUser.isManaged) {
			getNominee();
		}
	}, []);

	const getNominee = async (pageIndex = 1, pageSize = 10) => {
		let data = `performanceCycleId=${performanceNomineeId}&nomineeForEmployeeId=${nominatedUser?.employeeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`;
		const response: any = await dispatch(getNomineeData(data));
		if (Boolean(response) && response.data.status === 200) {
			setPaggingInfo(response.data.paggingInfo);
			if (response.data.paggingInfo?.page === 1) {
				setCheckinSettingList(response.data.entityList);
			} else {
				if (response.data.entityList && response.data.paggingInfo?.page > 1) {
					setCheckinSettingList([...checkinSettingList, ...response.data.entityList]);
				} else if (response.data.entityList === null || response.data.entityList.length === 0) {
					setCheckinSettingList([]);
				}
			}
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleNominateDrawerClose = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			setIsButtonDisabled(true);
			let data: any = {
				performanceCycleId: performanceNomineeId,
				nomineeForEmployeeId: nominatedUser?.employeeId,
				nomineeEmployeeId: [],
			};
			checkinSettingList.forEach((value: any) => {
				if (value.isNew) {
					data.nomineeEmployeeId.push(value.employeeId);
				}
			});
			if (data.nomineeEmployeeId.length > 0) {
				let response: any = await dispatch(saveNomineeData(data));
				if (Boolean(response) && response.data.status === 200) {
					enqueueSnackbar(response?.data?.messageList?.result, {
						variant: 'success',
						autoHideDuration: 5000,
					});
					setIsButtonDisabled(false);
				} else {
					enqueueSnackbar(t('someErrorOccurred'), {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
			if (checkinSettingList.length > 0) {
				event.preventDefault();
				setOpenNominateDrawer(false);
				setReloadRequired(true);
			}
		} else {
			event.preventDefault();
			setOpenNominateDrawer(false);
		}
	};
	const appendSelectedUser = () => {
		let user: any[] = [];
		selectedUser.forEach((item: any) => {
			user.push({ ...item, isNew: true });
		});
		setCheckinSettingList([...checkinSettingList, ...user]);
		setSelectedUserList([...user]);
		setSelectedUser([]);
	};
	return (
		<MainDrawer
			open={openNominateDrawer}
			transitionDuration={{ enter: 500, exit: 1000 }}
			headerTitle={
				<>
					<span dangerouslySetInnerHTML={{ __html: t('nominate360degree') }}></span>
				</>
			}
			rightCloseBtn={false}
			drawerClassName={'main-drawer-panel '}
			//loader={loader}
			saveButtonText={nominatedUser.isManaged ? t('update') : t('Save')}
			children={
				<Box className='drawer-inner-content'>
					<NominateDrawer
						{...props}
						nominatedUser={nominatedUser}
						performanceNomineeId={performanceNomineeId}
						selectedUser={selectedUser}
						setSelectedUser={setSelectedUser}
						appendSelectedUser={appendSelectedUser}
						checkinSettingList={checkinSettingList}
						setCheckinSettingList={setCheckinSettingList}
						paggingInfo={paggingInfo}
						getNominee={getNominee}
						setSelectedUserList={setSelectedUserList}
						selectedUserList={selectedUserList}
					/>
				</Box>
			}
			isSaveButtonDisabled={checkinSettingList.length === 0 || (checkinSettingList.length > 0 && isButtonDisabled)}
			handleDrawerClose={(event: any) => handleNominateDrawerClose(event, 'close')}
			handleSaveClick={(event: any) => handleNominateDrawerClose(event, 'save')}
			handleCancelClick={(event: any) => handleNominateDrawerClose(event, 'cancel')}
		/>
	);
};
