import React, { Fragment, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Slide,
	Checkbox,
	FormControlLabel,
} from '@material-ui/core';
import { AlertUnsavedIcon } from '../../config/svg/GlobalSvg';
import { useTranslation } from 'react-i18next';
import { DrawerTransitionSetting } from '../../config/utils';
import { CheckedIcon, BorderCheckboxIcon, UnCheckedIcon } from '../../config/svg/formElementIcons';

export default function CheckBoxAlertDialog(props) {
	const { message, handleCloseModal, modalOpen, module, isCancel, checkBoxListData } = props;
	const [checkBoxData, setCheckBoxData] = useState({});
	const [onlyAllowedCheckboxChecked, setOnlyAllowedCheckboxChecked] = useState(false);
	const { t } = useTranslation();
	const getButtonText = (type) => {
		return type === 1 ? t('cancelBtn') : t('proceedToEdit');
	};

	return (
		<Dialog
			className={`left-align-dialog goal-form-edit-dialog`}
			open={modalOpen}
			keepMounted
			transitionDuration={DrawerTransitionSetting}
			onClose={(e) => handleCloseModal(e, 2, checkBoxData)}
			aria-labelledby='alert-dialog-slide-title'
			aria-describedby='alert-dialog-slide-description'
		>
			<DialogContent>
				<AlertUnsavedIcon />
				<DialogContentText
					id='alert-dialog-slide-description'
					dangerouslySetInnerHTML={{ __html: message }}
				></DialogContentText>
				{checkBoxListData.length > 0 ? (
					checkBoxListData.map((checkboxItem, index) => (
						<FormControlLabel
							control={
								<Checkbox
									checkedIcon={<CheckedIcon />}
									icon={<BorderCheckboxIcon />}
									aria-label='Permission Checkbox'
									name={`checkbox-${index}`}
									checked={checkBoxData[checkboxItem.id] === true}
									onChange={(e) => {
										let checkBoxDataCp = { ...checkBoxData };
										if (e.target.checked === false) {
											delete checkBoxDataCp[checkboxItem.id];
											setCheckBoxData(checkBoxDataCp);
											if (checkboxItem.onlyAllowed === true) {
												setOnlyAllowedCheckboxChecked(false);
											}
										} else {
											if (checkboxItem.onlyAllowed === true) {
												setOnlyAllowedCheckboxChecked(e.target.checked);
												setCheckBoxData({ [checkboxItem.id]: e.target.checked });
											} else {
												setOnlyAllowedCheckboxChecked(false);
												setCheckBoxData({ ...checkBoxData, [checkboxItem.id]: e.target.checked });
											}
										}
									}}
									disabled={onlyAllowedCheckboxChecked && !checkboxItem.onlyAllowed}
								/>
							}
							label={checkboxItem.name}
							disabled={onlyAllowedCheckboxChecked && !checkboxItem.onlyAllowed}
						/>
					))
				) : (
					<></>
				)}
			</DialogContent>
			<DialogActions>
				<Fragment>
					<Button className='btn-link' onClick={(e) => handleCloseModal(e, 2, checkBoxData)} color='primary'>
						{getButtonText(1)}
					</Button>

					<Button
						className='btn-primary'
						onClick={(e) => handleCloseModal(e, 1, checkBoxData)}
						color='primary'
						disabled={Object.keys(checkBoxData).length === 0}
					>
						{getButtonText(2)}
					</Button>
				</Fragment>
			</DialogActions>
		</Dialog>
	);
}
