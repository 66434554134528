import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, Input, InputAdornment, IconButton, Button, FormHelperText } from '@material-ui/core';
import Logo from '../../../images/okr-logo.svg';
import { VisibilityOff, Visibility } from '../../../config/svg/ProfileSvg';
import { Link } from 'react-router-dom';
import CatWalkImage from '../../../images/login5_catwalk.svg';
import { ProfileIcon } from '../../../config/svg/CommonSvg';
import { useSnackbar } from 'notistack';
import { getQuarterStartEnd, validatePassword } from '../../../config/utils';
import { SECRET_LOGIN } from '../../../config/app-url';
import '../../../styles/pages/login/login.scss';
import {
	clearLocalStorage,
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../../../services/StorageService';
import { ClientLogo } from '../../Common/ClientLogo';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { getDecryptString, getEncryptString } from '../../../config/utils';
import { CREATE_PASSWORD, FORGET_PASSWORD } from '../../../config/api-url';
import LinearLoader from '../../Common/Loader';
import { WithoutHttpDefaultOrigin } from '../../../config/constant';

export default function ResetPassword(props) {
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const [isTokenExpire, setIsTokenExpire] = useState(false);
	const { t } = useTranslation();
	const host = window.location.origin;
	const origin = window.location.host.indexOf('localhost') >= 0 ? WithoutHttpDefaultOrigin : window.location.host;

	const impersonateUserDetail = getLocalStorageItem('impersonateUserDetail');
	const urlParams = impersonateUserDetail ? JSON.parse(getDecryptString(impersonateUserDetail)) : '';

	let DecForgetPass = urlParams && urlParams.forgetPassword ? urlParams.forgetPassword : null;
	const emailID = urlParams && urlParams.emailId ? urlParams.emailId : null;

	if (DecForgetPass != null) {
		DecForgetPass = true;
	}
	//DecForgetPass = 'true';
	const [loader, setLoader] = useState(false);

	// const userDetailsString = getLocalStorageItem('userDetail');
	// const userDetail = JSON.parse(userDetailsString);
	// const emailID = userDetail.emailId ? userDetail.emailId : '';

	useEffect(() => {
		let queryString = props.location.search.split('?id=');
		let id = queryString[1] ? queryString[1] : '';
		if (id !== '') {
			clearLocalStorage();
			localStorage.setItem('tokenId', JSON.stringify(id));
		}
	}, [props.location.search]);

	const { enqueueSnackbar } = useSnackbar();
	let { currentYear } = getQuarterStartEnd();

	const handleClickShowoneTimePassword = () => {
		setFormData({ ...formData, showoneTimePassword: !formData.showoneTimePassword });
	};

	const handleClickShowPassword = () => {
		setFormData({ ...formData, showPassword: !formData.showPassword });
	};
	const handleClickShowRePassword = () => {
		setFormData({ ...formData, showRePassword: !formData.showRePassword });
	};

	const [formData, setFormData] = React.useState({
		password: '',
		rePassword: '',
		oneTimePassword: '',
		username: '',
		showPassword: false,
		showRePassword: false,
		showoneTimePassword: false,
		formValid: false,
		isPasswordValid: false,
		isRePasswordValid: false,
		isoneTimePasswordValid: false,
		messageList: {},
	});
	const handleChange = (prop) => (event) => {
		const value = event.target.value;
		if (prop === 'password') {
			// let psdValid = value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]{8,}$/)
			// 	? true
			// 	: false;
			let psdValid = validatePassword(value);
			setFormData({
				...formData,
				password: value.trim(),
				isPasswordValid: psdValid,
				formValid: value.trim() && formData.rePassword.trim() && psdValid && formData.isRePasswordValid,
			});
		} else if (prop === 'rePassword') {
			setFormData({
				...formData,
				rePassword: value.trim(),
				isRePasswordValid: formData.password === value.trim(),
				formValid:
					value.trim() && formData.password.trim() && formData.isPasswordValid && formData.password === value.trim(),
			});
		} else if (prop === 'oneTimePassword') {
			setFormData({
				...formData,
				oneTimePassword: value.trim(),
				isoneTimePasswordValid: true,
				formValid: true,
			});
		} else {
			setFormData({ ...formData, [prop]: event.target.value });
		}
	};

	const submitForm = (e) => {
		e.preventDefault();
		setIsFormSubmit(true);
		if (formData.formValid) {
			let data = {
				newPassword: formData.password,
			};
			//props.resetPasswordAction(data);
		}
	};

	const generateMail = () => {
		props.generateResetPasswordMail().then((response) => {
			setIsTokenExpire(false);
			if (response && response.data) {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
			}
		});
	};

	useEffect(() => {
		const responseAPI = props.messageList;
		const keys = Object.keys(responseAPI);
		const messages = keys.map((item) => responseAPI[item]);
		if (props.resetPasswordStatus === 200) {
			clearLocalStorage();
			enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
			props.history.push(SECRET_LOGIN);
		}
		props.updateStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.resetPasswordStatus]);

	async function createPassword() {
		setLoader(true);
		if (formData.isPasswordValid && formData.isRePasswordValid && emailID && formData.isoneTimePasswordValid) {
			const IsForgetPassword = DecForgetPass ? DecForgetPass : false;
			// Custom Login API Calling...
			let request = {
				userEmailId: emailID,
				userPassword: formData.password,
				userReEnterPassword: formData.rePassword,
				IsForgetPassword: IsForgetPassword,
				OTP: formData.oneTimePassword,
			};
			props
				.createPassword(request)
				.then((result) => {
					if (result.status === 200) {
						setLoader(false);
						enqueueSnackbar(result.data.messageList.message, { variant: 'success', autoHideDuration: 5000 });
						removeLocalStorageItem('isResetLink');
						window.location.href = window.location.href = host + '/custom-login';
					}
				})
				.catch((e) => {
					// console.log(e);
				});

			// END ****************************
		} else {
			setLoader(false);
			enqueueSnackbar(t('strongPassword'), { variant: 'error', autoHideDuration: 5000 });
		}
	}

	function navigateToLogin() {
		window.location.href = window.location.href = host + '/custom-login';
	}

	async function resetPasswordCall() {
		setLoader(true);
		//console.log(formData);
		if (formData.username) {
			// Custom Login API Calling...

			props
				.forgetPassword({ userEmailId: formData.username })
				.then((resultForget) => {
					if (resultForget.status === 200) {
						setLoader(false);
						enqueueSnackbar(resultForget.data.messageList.message, { variant: 'success', autoHideDuration: 5000 });
						// window.location.href = window.location.href = host +"/custom-login";
					} else {
						setLoader(false);
						enqueueSnackbar(t('emailNotValid'), { variant: 'error', autoHideDuration: 5000 });
					}
				})
				.catch((e) => {
					// console.log(e);
				});
			// END ****************************
		} else {
			setLoader(false);
		}
	}
	return (
		<Fragment>
			<section className='sign-in-area'>
				{loader && <LinearLoader />}
				<div className='login-screen'>
					<div className='inner'>
						<div className='img-inner-wrapper'>
							<ClientLogo className='client-logo' />
							<AnimatedIcon className='' width='404' type='image/svg+xml' alt='' imagePath={CatWalkImage} />
						</div>
					</div>
					{getLocalStorageItem('isResetLink') && (
						<div className='inner login-form-area'>
							<div className='login-inner-wrapper reset-inner-form'>
								<img src={Logo} className='logo' alt='' />
								<Typography variant='h2' className='welcomeText'>
									{t('createPassword')}
								</Typography>
								<Typography className='welcomeSubText'>{t('enterNewPassword')}</Typography>
								<form className='login-inner-form reset-form' onSubmit={submitForm}>
									{isTokenExpire && (
										<Typography>
											{t('expiredToken')}
											<Link onClick={() => generateMail()}> {t('clickHere')} </Link>
											{t('generateNew')}
										</Typography>
									)}

									<div className='hi-useremail'>
										<span>{emailID}</span>
									</div>
									<FormControl
										variant='outlined'
										style={{ width: '100%' }}
										className='pass-txtbox'
										error={isFormSubmit && !formData.isoneTimePasswordValid}
									>
										<Input
											id='standard-adornment-password'
											type={formData.showoneTimePassword ? 'text' : 'password'}
											value={formData.oneTimePassword}
											onChange={handleChange('oneTimePassword')}
											placeholder={t('oneTimePassword')}
											className='oneTimePassword'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														disableRipple='true'
														aria-label={t('ResetPasswordInputAriaLabel')}
														onClick={handleClickShowoneTimePassword}
													>
														{formData.showoneTimePassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
										{/* {!formData.isPasswordValid && (
										<FormHelperText className='pass-error'>{t('strongPassword')}</FormHelperText>
									)} */}
									</FormControl>

									<FormControl
										variant='outlined'
										style={{ width: '100%' }}
										className='pass-txtbox'
										error={isFormSubmit && !formData.isPasswordValid}
									>
										<Input
											id='standard-adornment-password'
											type={formData.showPassword ? 'text' : 'password'}
											value={formData.password}
											onChange={handleChange('password')}
											placeholder={t('passwordPlaceholder')}
											className='password'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														disableRipple='true'
														aria-label={t('ResetPasswordInputAriaLabel')}
														onClick={handleClickShowPassword}
													>
														{formData.showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
										{/* {!formData.isPasswordValid && (
										<FormHelperText className='pass-error'>{t('strongPassword')}</FormHelperText>
									)} */}
									</FormControl>
									<FormControl
										variant='outlined'
										style={{ width: '100%' }}
										className='repass-txtbox'
										error={isFormSubmit && !formData.isRePasswordValid}
									>
										<Input
											id='standard-adornment-retype-password'
											type={formData.showRePassword ? 'text' : 'password'}
											value={formData.rePassword}
											onChange={handleChange('rePassword')}
											placeholder='Re-enter Password'
											className='reset-pass'
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														disableRipple='true'
														aria-label='toggle password visibility'
														onClick={handleClickShowRePassword}
													>
														{formData.showRePassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
										{isFormSubmit && !formData.isRePasswordValid && (
											<FormHelperText>{t('passwordNotMatch')}</FormHelperText>
										)}
									</FormControl>

									<Button type='submit' className='btn-primary login-btn' onClick={createPassword}>
										{t('create')}
									</Button>
								</form>

								<div className='footer-content'>
									<Typography variant='body2'>
										&copy; {currentYear} {t('infoproRights')}
										{' | '}
										<Link target='_blank' to='/privacy-policy'>
											{t('privacyPolicy')}
										</Link>
									</Typography>
								</div>
							</div>
						</div>
					)}
					{!getLocalStorageItem('isResetLink') && (
						<div className='inner login-form-area'>
							<div className='login-inner-wrapper reset-inner-form'>
								<img src={Logo} className='logo' alt='' />
								<Typography variant='h2' className='welcomeText'>
									{t('resetyourPassword')}
								</Typography>
								<form className='login-inner-form reset-form' onSubmit={submitForm}>
									{isTokenExpire && (
										<Typography>
											{t('expiredToken')}
											<Link onClick={() => generateMail()}> {t('clickHere')} </Link>
											{t('generateNew')}
										</Typography>
									)}
									<FormControl variant='outlined' style={{ width: '100%' }} error={isFormSubmit && !formData.username}>
										<Input
											id='input-with-icon-adornment'
											placeholder='User Email'
											value={formData.username}
											onChange={handleChange('username')}
											endAdornment={
												<InputAdornment position='end'>
													<ProfileIcon />
												</InputAdornment>
											}
										/>
										{isFormSubmit && !formData.username && <FormHelperText>{t('enterUseremail')}</FormHelperText>}
									</FormControl>
									<Button type='submit' className='btn-primary login-btn' onClick={resetPasswordCall}>
										{t('sendLink')}
									</Button>
									<div className='forgotRow'>
										<Link className='forgotLink' href='#' onClick={navigateToLogin} underline='none'>
											Back to Login?
										</Link>
									</div>
								</form>

								<div className='footer-content'>
									<Typography variant='body2'>
										&copy; {currentYear} {t('infoproRights')}
										{/* {' | '}
									<Link target='_blank' to='/privacy-policy'>
										{t('privacyPolicy')}
									</Link> */}
									</Typography>
								</div>
							</div>
						</div>
					)}
				</div>
			</section>
		</Fragment>
	);
}
