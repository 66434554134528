import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
	Avatar,
	Box,
	Button,
	FormControl,
	Grid,
	InputLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	TextField,
	Typography,
	withStyles,
} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { Rating } from '@material-ui/lab';
import { getMonthDateYear, getUserName } from '../../config/utils';
import { labels, marks } from '../../config/constant';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { useDispatch } from 'react-redux';
import { getCriteriaMaster, giveUpdatePersonalFeedback } from '../../action/personalFeedback';
import GlobalSearch from '../Common/GlobalSearch';
import { globalSearchAPIWithTeam, searchEmployee } from '../../action/search';
import { getUserById, updatePage } from '../../action/common';

const AntSwitch = withStyles((theme) => ({
	root: {
		width: 28,
		height: 16,
		padding: 0,
		display: 'flex',
	},
	switchBase: {
		padding: 2,
		color: theme.palette.grey[500],
		'&$checked': {
			transform: 'translateX(12px)',
			color: theme.palette.common.white,
			'& + $track': {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
				borderColor: theme.palette.primary.main,
			},
		},
	},
	thumb: {
		width: 12,
		height: 12,
		boxShadow: 'none',
	},
	track: {
		border: `1px solid ${theme.palette.grey[500]}`,
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.common.white,
	},
	checked: {},
}))(Switch);

const CreateGivePersonalFeedback = (props) => {
	const {
		tabValue = 0,
		changeTabValue,
		userDetail,
		handleDrawerConfirmation,
		setFormDataUpdated,
		setLoader,
		loader,
		// giveDetailsPersonalFeedback,
		// giveUpdatePersonalFeedback,
		// getCriteriaMaster,
		loggedInUser,
		currentUser,
		handlePersonalFeedbackClose,
		feedbackData,
		selectedDateRange,
		handleDrawerCloseAfterSave,
		isEdit,
		editableDetails,
	} = props;

	const dispatch = useDispatch();

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const staticData = {
		raisedTypeId: 1,
		raisedForId: Boolean(currentUser) && currentUser.employeeId ? currentUser.employeeId : 0,
		feedbackRequestId: 0, // 0 for default and id will be provided when redirected from Notifications
		feedbackOnTypeId: 3,
		feedbackOnId: Boolean(currentUser) && currentUser.employeeId ? currentUser.employeeId : 0,
		sharedRemark: t('givingForMsgPF'),
		status: 3,
		criteriaTypeId: 1, // 1-Praise || 2-Growth
		criteriaFeedbackMappingRequests: [],
		selectedUser: [],
		submit: false,
		isError: true,
	};
	const [givePFList, setGivePFList] = useState([]);
	const [praiseCriteriaMaster, setPraiseCriteriaMaster] = useState();
	const [growthOpportunityCriteriaMaster, setGrowthOpportunityCriteriaMaster] = useState();
	const [switchCheck, setSwitchCheck] = React.useState(false);
	const [searchFeedback, setSearchFeedback] = useState('');
	const [maxFeedbackRequestId, setMaxFeedbackRequestId] = useState(0);
	const [formData, setFormData] = useState(staticData);
	const [expandGivenFeedback, setExpandGivenFeedback] = useState(null);

	useEffect(() => {
		getAPICall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDateRange]);

	useEffect(() => {
		if (
			formData.sharedRemark === t('givingForMsgPF') &&
			formData.criteriaFeedbackMappingRequests &&
			formData.criteriaFeedbackMappingRequests.length <= 0 &&
			formData.raisedForId === 0 &&
			formData.feedbackOnId === 0
		) {
			setFormDataUpdated(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	useEffect(() => {
		if (Boolean(feedbackData) && Boolean(feedbackData.feedbackById) && Boolean(feedbackData.feedbackRequestId)) {
			setFormData({
				...formData,
				raisedForId: Boolean(feedbackData.feedbackById) && feedbackData.feedbackById,
				feedbackOnId: Boolean(feedbackData.feedbackById) && feedbackData.feedbackById,
				feedbackRequestId: Boolean(feedbackData) && feedbackData.feedbackRequestId ? feedbackData.feedbackRequestId : 0,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedbackData]);

	const getAPICall = () => {
		setSearchFeedback('');
		fetchGiveFeedback(true, '', 0);
	};
	const handleChange = (event) => {
		setSwitchCheck(event.target.checked);
		setFormData({
			...formData,
			submit: false,
			criteriaFeedbackMappingRequests: [],
			criteriaTypeId: event.target.checked ? 2 : 1,
		});
	};
	const setMessageValue = (event) => {
		const { value } = event.target;
		setFormDataUpdated(true);
		event.preventDefault();
		event.stopPropagation();
		setFormData({ ...formData, sharedRemark: value, isError: false });
	};
	const deleteJsonKey = (data) => {
		delete data['selectedUser'];
		delete data['submit'];
		delete data['isError'];
		return data;
	};
	const validatePraiseOrGO = () => {
		if (formData.criteriaTypeId === 1) {
			const praiseCriteriaMasterDataLength =
				Boolean(praiseCriteriaMaster) && praiseCriteriaMaster.criteriaList && praiseCriteriaMaster.criteriaList.length;
			if (formData.criteriaFeedbackMappingRequests.length !== praiseCriteriaMasterDataLength) {
				return false;
			}
		} else if (formData.criteriaTypeId === 2) {
			const growthOpportunityDataLength =
				Boolean(growthOpportunityCriteriaMaster) &&
				growthOpportunityCriteriaMaster.criteriaList &&
				growthOpportunityCriteriaMaster.criteriaList.length;
			if (formData.criteriaFeedbackMappingRequests.length !== growthOpportunityDataLength) {
				return false;
			}
		}
		return true;
	};
	const givenPersonalFeedbackAPICall = (formData) => {
		dispatch(giveUpdatePersonalFeedback(formData))
			.then((response) => {
				if (Boolean(response) && response.data && response.data.status === 200) {
					setLoader(false);
					// enqueueSnackbar(t('givePFSuccess'), {
					// 	variant: 'success',
					// 	autoHideDuration: 5000,
					// });
					if (loggedInUser && !Boolean(currentUser)) {
						changeTabValue(1);
						fetchGiveFeedback(true, '', 0);
					} else {
						handlePersonalFeedbackClose({}, 'save');
					}
					setFormData(staticData);
				} else {
					setLoader(false);
					const responseAPI = Boolean(response) && response.data && response.data.messageList;
					const keys = Boolean(responseAPI) && Object.keys(responseAPI);
					const messages = Boolean(keys) && keys.length && keys.map((item) => responseAPI[item]);
					enqueueSnackbar(messages, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
				setFormDataUpdated(false);
				handleDrawerCloseAfterSave(isEdit ? 'RequestedFeedback' : 'GiveFeedback', 'save');
			})
			.catch(() => {
				setLoader(false);
				setFormDataUpdated(false);
				enqueueSnackbar(t('givePFReject'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
			});
	};
	const submitRequest = async (event, type) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 2) {
			handleDrawerConfirmation(event);
		} else {
			const maxFeedbackRequestId = Math.max(...givePFList.map((o) => o.feedbackDetailId), 0);
			setMaxFeedbackRequestId(maxFeedbackRequestId || -10);
			let editableUserInfo =
				isEdit && editableDetails
					? {
							...formData,
							raisedForId: editableDetails?.employeeId,
							feedbackOnId: editableDetails?.employeeId,
							feedbackRequestId: editableDetails?.feedbackRequestId,
					  }
					: { ...formData };
			setFormData({
				...editableUserInfo,
				submit: true,
			});
			if (
				Boolean(formData) &&
				formData.sharedRemark &&
				formData.sharedRemark.trim() !== '' &&
				formData.raisedForId !== 0 &&
				validatePraiseOrGO()
			) {
				setLoader(true);
				deleteJsonKey(editableUserInfo);
				givenPersonalFeedbackAPICall(editableUserInfo);
			}
		}
	};
	const onSelectedUser = async (selected) => {
		const isExist =
			formData.selectedUser &&
			formData.selectedUser.find(
				(item) => item.employeeId === Boolean(selected) && selected.length && selected[0].employeeId
			);
		if (selected.length > 0 && !isExist) {
			const allUser = [...formData.selectedUser, { ...formData.selectedUser, ...selected[0] }];
			const allUserIds = Boolean(allUser) && allUser.length && allUser[0].employeeId ? allUser[0].employeeId : 0;
			setFormData(() => ({
				...formData,
				selectedUser: allUser,
				raisedForId: allUserIds,
				feedbackOnId: allUserIds,
				isError: false,
			}));
			setFormDataUpdated(true);
		} else {
			setFormData(() => ({
				...formData,
				selectedUser: [],
				raisedForId: 0,
				feedbackOnId: 0,
			}));
			if (formData.sharedRemark === t('givingForMsgPF')) {
				setFormDataUpdated(false);
			}
		}
	};

	const goToGiveFeedbackListing = (feedbackDetailId) => {
		changeTabValue(1);
		if (Boolean(feedbackDetailId)) {
			setTimeout(() => {
				setExpandGivenFeedback({ feedbackDetailId: feedbackDetailId });
				var element = document.getElementById('viewPF' + feedbackDetailId);
				if (element) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
			}, 500);
		}
	};

	const fetchGiveFeedback = async (sort, searchName, type) => {
		setLoader(true);
		setExpandGivenFeedback(null);

		Promise.allSettled([dispatch(getCriteriaMaster(`typeId=${1}`)), dispatch(getCriteriaMaster(`typeId=${2}`))])
			.then((response) => {
				if (response) {
					let getCriteriaMastersRespPraise =
						Boolean(response) && response.length && response[0] && response[0].value ? response[0].value : '';
					let getCriteriaMastersRespGrowthOpportunity =
						Boolean(response) && response.length && response[1] && response[1].value ? response[1].value : '';

					if (
						Boolean(getCriteriaMastersRespPraise) &&
						getCriteriaMastersRespPraise.data &&
						getCriteriaMastersRespPraise.data.status === 200
					) {
						setPraiseCriteriaMaster(getCriteriaMastersRespPraise.data.entity || []);
					}
					if (
						Boolean(getCriteriaMastersRespGrowthOpportunity) &&
						getCriteriaMastersRespGrowthOpportunity.data &&
						getCriteriaMastersRespGrowthOpportunity.data.status === 200
					) {
						setGrowthOpportunityCriteriaMaster(getCriteriaMastersRespGrowthOpportunity.data.entity || []);
					}
					// if (Boolean(givingPFData) && givingPFData.data && givingPFData.data.status === 200) {
					// 	setGivePFList(givingPFData.data.entityList);
					// 	setTimeout(() => {
					// 		setMaxFeedbackRequestId(0);
					// 	}, 5000);
					// } else {
					// 	setGivePFList([]);
					// }
					setGivePFList([]);
					setTimeout(() => {
						setMaxFeedbackRequestId(0);
					}, 5000);
					setLoader(false);
				}
			})
			.catch((error) => {
				setLoader(false);
			});
	};
	const updateUsersRating = (formData, praiseDetail, value) => {
		const checkVals = formData.criteriaFeedbackMappingRequests;
		if (Boolean(checkVals) && checkVals.length) {
			const findExisting =
				checkVals && checkVals.find((item) => item.criteriaMasterId === praiseDetail.criteriaMasterId);
			return Boolean(findExisting)
				? checkVals &&
						checkVals.map((item) => {
							if (item.criteriaMasterId === praiseDetail.criteriaMasterId) {
								item.score = value;
							}
							return item;
						})
				: [
						...formData.criteriaFeedbackMappingRequests,
						{
							criteriaMasterId: Boolean(praiseDetail) ? praiseDetail.criteriaMasterId : 0,
							score: value,
						},
				  ];
		} else {
			return [
				...formData.criteriaFeedbackMappingRequests,
				{
					criteriaMasterId: Boolean(praiseDetail) ? praiseDetail.criteriaMasterId : 0,
					score: value,
				},
			];
		}
	};
	const onChangePraiseRating = (event, value) => {
		let name = event.target.name;
		name = name.split('-');
		name = Boolean(name) && name.length ? name[1] : '';
		const praiseDetail =
			Boolean(praiseCriteriaMaster) && praiseCriteriaMaster.criteriaList && praiseCriteriaMaster.criteriaList.length
				? praiseCriteriaMaster.criteriaList.find((praise) => praise.criteriaName === name)
				: '';
		if (formData.criteriaTypeId === 1) {
			if (value) {
				setFormData({
					...formData,
					isError: false,
					criteriaFeedbackMappingRequests: updateUsersRating(formData, praiseDetail, value),
				});
			} else {
				const data =
					formData.criteriaFeedbackMappingRequests &&
					formData.criteriaFeedbackMappingRequests.length &&
					formData.criteriaFeedbackMappingRequests.filter(
						(item) => item.criteriaMasterId !== praiseDetail.criteriaMasterId
					);
				setFormData({
					...formData,
					isError: false,
					criteriaFeedbackMappingRequests: data,
				});
			}
			setFormDataUpdated(true);
		}
	};
	const onChangeGrowthOpportunityRating = (_event, value, name) => {
		name = name.split('-');
		name = Boolean(name) && name.length ? name[1] : '';
		const goDetail =
			Boolean(growthOpportunityCriteriaMaster) &&
			growthOpportunityCriteriaMaster.criteriaList &&
			growthOpportunityCriteriaMaster.criteriaList.length
				? growthOpportunityCriteriaMaster.criteriaList.find((praise) => praise.criteriaName === name)
				: '';
		if (formData.criteriaTypeId === 2) {
			if (value) {
				setFormData({
					...formData,
					isError: false,
					criteriaFeedbackMappingRequests: updateUsersRating(formData, goDetail, value),
				});
			} else {
				const data =
					formData.criteriaFeedbackMappingRequests &&
					formData.criteriaFeedbackMappingRequests.length &&
					formData.criteriaFeedbackMappingRequests.filter(
						(item) => item.criteriaMasterId !== goDetail.criteriaMasterId
					);
				setFormData({
					...formData,
					isError: false,
					criteriaFeedbackMappingRequests: data,
				});
			}
			setFormDataUpdated(true);
		}
	};
	const FeedbackPraiseRatingComponent = ({ name, label, criteriaMasterId, disable, defaultValue }) => {
		const selectedValue =
			Boolean(formData.criteriaFeedbackMappingRequests) &&
			formData.criteriaFeedbackMappingRequests.length &&
			formData.criteriaFeedbackMappingRequests.find((item) => item.criteriaMasterId === criteriaMasterId);
		const selectedRating = Boolean(defaultValue) ? defaultValue : Boolean(selectedValue) ? selectedValue.score : 0;
		const [hover, setHover] = React.useState(selectedValue ? selectedValue.score : -1);

		return (
			<Box className='praise-rating helperText-bottom'>
				<Typography component='legend'>{label}</Typography>
				<Rating
					name={name}
					defaultValue={0}
					value={selectedRating}
					getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
					precision={1}
					onChange={onChangePraiseRating}
					disabled={disable}
					onChangeActive={(_event, newHover) => {
						setHover(newHover);
					}}
				/>
				{Boolean(selectedRating) || hover !== -1 ? (
					<Typography variant='body2'>{labels[hover !== -1 ? hover : parseInt(selectedRating)]}</Typography>
				) : (
					<></>
				)}
				{Boolean(formData) && formData.submit && !selectedValue ? (
					<Typography className='error-field' variant='caption' color='error'>
						<ErrorIcon />
						{t('giveForPleaseSelect')}
					</Typography>
				) : (
					''
				)}
			</Box>
		);
	};
	const FeedbackGrowthOpportunity = ({ name, label, criteriaMasterId, disable, defaultValue }) => {
		const selectedValue =
			Boolean(formData.criteriaFeedbackMappingRequests) &&
			formData.criteriaFeedbackMappingRequests.length &&
			formData.criteriaFeedbackMappingRequests.find((item) => item.criteriaMasterId === criteriaMasterId);
		const [sliderValue, setSliderValue] = React.useState(selectedValue ? selectedValue.score : 0);

		return (
			<Box className='growth-ratings' display='flex' justifyContent='space-between'>
				<Box className='growth-ratings-label'>
					<Typography id={`${name}-slider-small-steps`} gutterBottom>
						{label}
					</Typography>
				</Box>
				<Box className='growth-ratings-slider helperText-bottom'>
					<Slider
						defaultValue={0}
						name={`${name}`}
						aria-labelledby={`${name}-slider-small-steps`}
						step={1}
						value={Boolean(defaultValue) ? defaultValue : Boolean(sliderValue) ? sliderValue : 0}
						min={0}
						max={5}
						valueLabelDisplay='off'
						marks={marks}
						onChangeCommitted={(event, value) => onChangeGrowthOpportunityRating(event, value, name)}
						onChange={(e, value) => setSliderValue(value)}
						disabled={disable}
					/>
					{Boolean(formData) && formData.submit && !selectedValue ? (
						<Typography variant='caption' color='error' className='error-field'>
							{/* {`${t('giveForPleaseSelect')} ${label}.`} */}
							{t('giveForPleaseSelect')}
						</Typography>
					) : (
						''
					)}
				</Box>
			</Box>
		);
	};

	const searchCustomEmployee = (searchStr, page, pageSize) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr, page, pageSize, type) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data) => {
		return dispatch(getUserById(data));
	};

	return (
		<Fragment>
			{tabValue === 0 ? (
				Boolean(growthOpportunityCriteriaMaster) && Boolean(praiseCriteriaMaster) ? (
					Boolean(feedbackData) && feedbackData.length ? (
						<Box className='feedback-comments-area pf-comments-area'>
							{feedbackData.status > 1 ? (
								<Box
									className='already-provide-feed'
									display='flex'
									alignItems='center'
									justifyContent='center'
									flexDirection='column'
								>
									<Typography variant='h4'>{t('feedbackAlreadyGiven1')},</Typography>
									<Typography variant='h4'>
										<Button
											onClick={() =>
												goToGiveFeedbackListing(
													Boolean(feedbackData.feedbackProvideDetails) && feedbackData.feedbackProvideDetails.length > 0
														? feedbackData.feedbackProvideDetails[0].feedbackDetailId
														: 0
												)
											}
										>
											{t('clickHere')}
										</Button>{' '}
										{t('feedbackAlreadyGiven2')}
									</Typography>
								</Box>
							) : (
								<List className='comments-list'>
									<ListItem alignItems='flex-start'>
										<ListItemAvatar>
											{Boolean(feedbackData.askByImagePath) ? (
												<Avatar
													alt={`${feedbackData.askByFirstName} ${feedbackData.askByLastName}`}
													src={feedbackData.askByImagePath}
												/>
											) : (
												<Avatar>
													{getUserName({
														firstName: feedbackData.askByFirstName,
														lastName: feedbackData.askByLastName,
													})}
												</Avatar>
											)}
										</ListItemAvatar>
										<ListItemText
											primary={`${feedbackData.askByFirstName} ${feedbackData.askByLastName}`}
											secondary={
												<Typography component='small' variant='body2'>
													{getMonthDateYear(feedbackData.askByCreatedOn)}
												</Typography>
											}
										/>
									</ListItem>
									<List className='comments-sub-list comments-sub-given-list'>
										<ListItem>
											<ListItemText primary={`${feedbackData.askByRequestRemark}`} />
										</ListItem>
										<ListItem>
											<Box className='feedback-scale drawer-field-bottom'>
												<FormControl variant='outlined' style={{ width: '100%' }}>
													<InputLabel htmlFor='ask-feedback-from'>{t('givingForToggleLabelPF')}</InputLabel>
													<Typography component='div'>
														<Grid component='label' container alignItems='center' spacing={1}>
															<Grid item>{t('givingForPraise')}</Grid>
															<Grid item>
																<AntSwitch checked={switchCheck} onChange={handleChange} />
															</Grid>
															<Grid item>{t('givingForGrowthOpportunity')}</Grid>
														</Grid>
													</Typography>
												</FormControl>
											</Box>
										</ListItem>
										<ListItem>
											<Box className='feedback-ratings-area'>
												<FormControl variant='outlined' style={{ width: '100%' }}>
													<InputLabel htmlFor='ask-feedback-from' margin={10}>
														{t('givingForFeedbackLabelPF')}
													</InputLabel>
													{switchCheck ? (
														<Box className='growth-opportuniy-area'>
															{Boolean(growthOpportunityCriteriaMaster) &&
															growthOpportunityCriteriaMaster.criteriaList &&
															growthOpportunityCriteriaMaster.criteriaList.length
																? growthOpportunityCriteriaMaster.criteriaList.map((growthOpportunity) => (
																		<FeedbackGrowthOpportunity
																			name={`feedbackGO-${growthOpportunity.criteriaName}`}
																			label={`${growthOpportunity.criteriaName}`}
																			criteriaMasterId={growthOpportunity.criteriaMasterId}
																			disable={false}
																			defaultValue={0}
																		/>
																  ))
																: ''}
														</Box>
													) : (
														<Box className='praise-area'>
															{Boolean(praiseCriteriaMaster) &&
															praiseCriteriaMaster.criteriaList &&
															praiseCriteriaMaster.criteriaList.length
																? praiseCriteriaMaster.criteriaList.map((praise) => (
																		<FeedbackPraiseRatingComponent
																			name={`feedbackPraise-${praise.criteriaName}`}
																			label={`${praise.criteriaName}`}
																			criteriaMasterId={praise.criteriaMasterId}
																			disable={false}
																			defaultValue={0}
																		/>
																  ))
																: ''}
														</Box>
													)}
												</FormControl>
											</Box>
										</ListItem>
										<ListItem>
											<Box style={{ width: '100%' }} className='feedback-message helperText-bottom'>
												<TextField
													style={{ width: '100%' }}
													id='given-feedback-msg'
													label={t('givingForMsgLabelPF')}
													multiline
													rows={4}
													placeholder={t('typeMessageHere')}
													value={formData.sharedRemark}
													onChange={setMessageValue}
													error={
														formData && !formData.sharedRemark && formData.submit && formData.sharedRemark.trim() === ''
													}
													helperText={
														formData &&
														!formData.sharedRemark &&
														formData.submit &&
														formData.sharedRemark.trim() === '' ? (
															<>
																<ErrorIcon />
																{t('enterValidMessage')}
															</>
														) : (
															''
														)
													}
												/>
											</Box>
										</ListItem>
										<ListItem className='okr-form-action-wrap'>
											<div className='okr-form-action feedback-action'>
												<Button
													className='cancel-btn btn-link'
													id='given-pf-cancel-btn'
													onClick={(event) => submitRequest(event, 2)}
													color='primary'
												>
													{t('cancelBtn')}
												</Button>
												<Button
													className='submitBtn btn-primary'
													id='given-pf-send-btn'
													disabled={(formData && formData.isError) || loader}
													onClick={(event) => submitRequest(event, 1)}
													color='primary'
												>
													{t('send')}
												</Button>
											</div>
										</ListItem>
									</List>
								</List>
							)}
						</Box>
					) : (
						<form className='okr-form-fields personal-feedback-form' noValidate autoComplete='off'>
							{loggedInUser && !Boolean(currentUser) && !isEdit ? (
								<Box
									className={` drawer-field-bottom helperText-bottom ${
										Boolean(formData) && formData.raisedForId === 0 && formData.submit ? 'error-search' : ''
									} `}
								>
									<FormControl variant='outlined' style={{ width: '100%' }}>
										<InputLabel htmlFor='ask-feedback-from' margin={10}>
											{t('givingForLabelPF')}
										</InputLabel>
										<GlobalSearch
											{...props}
											module={'feedback'}
											onSelectedUser={onSelectedUser}
											fetchCycleUsers={true}
											customClass={'user-quick-search-type2'}
											customId={'give-pf-src'}
											multiple={false}
											defaultValue={null}
											defaultFocus={null}
											placeHolderText={null}
											searchEmployee={searchCustomEmployee}
											updatePage={updateCustomPage}
											globalSearchAPIWithTeam={globalSearchWithTeam}
											getUserById={getCustomUserById}
										/>
										{Boolean(formData) && formData.raisedForId === 0 && formData.submit ? (
											<Typography className='error-field' variant='caption' color='error'>
												{t('selectEmployee')}
											</Typography>
										) : (
											''
										)}
									</FormControl>
								</Box>
							) : (
								<Box className='personal-feed-col1 user-info drawer-field-bottom'>
									<Box className='user-img'>
										{editableDetails.imagePath ? (
											<Avatar
												alt={`${editableDetails.firstName} ${editableDetails.lastName}`}
												src={editableDetails.imagePath}
											/>
										) : (
											<Avatar>{getUserName(editableDetails)}</Avatar>
										)}
									</Box>
									<Box className='user-details'>
										<Typography variant='h4'>
											{editableDetails.firstName} {editableDetails.lastName}
										</Typography>
										<Typography variant='subtitle2'>{editableDetails.emailId}</Typography>
									</Box>
								</Box>
							)}
							<Box className='feedback-scale drawer-field-bottom'>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel htmlFor='ask-feedback-from' margin={10}>
										{t('givingForToggleLabelPF')}
									</InputLabel>
									<Typography component='div'>
										<Grid component='label' container alignItems='center' spacing={1}>
											<Grid item>{t('givingForPraise')}</Grid>
											<Grid item>
												<AntSwitch checked={switchCheck} onChange={handleChange} />
											</Grid>
											<Grid item>{t('givingForGrowthOpportunity')}</Grid>
										</Grid>
									</Typography>
								</FormControl>
							</Box>
							<Box className='feedback-ratings-area'>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel htmlFor='ask-feedback-from' margin={10}>
										{t('givingForFeedbackLabelPF')}
									</InputLabel>
									{switchCheck ? (
										<Box className='growth-opportuniy-area'>
											{Boolean(growthOpportunityCriteriaMaster) &&
											growthOpportunityCriteriaMaster.criteriaList &&
											growthOpportunityCriteriaMaster.criteriaList.length
												? growthOpportunityCriteriaMaster.criteriaList.map((growthOpportunity) => (
														<FeedbackGrowthOpportunity
															name={`feedbackGO-${growthOpportunity.criteriaName}`}
															label={`${growthOpportunity.criteriaName}`}
															criteriaMasterId={growthOpportunity.criteriaMasterId}
															disable={false}
															defaultValue={0}
														/>
												  ))
												: ''}
										</Box>
									) : (
										<Box className='praise-area'>
											{Boolean(praiseCriteriaMaster) &&
											praiseCriteriaMaster.criteriaList &&
											praiseCriteriaMaster.criteriaList.length
												? praiseCriteriaMaster.criteriaList.map((praise) => (
														<FeedbackPraiseRatingComponent
															name={`feedbackPraise-${praise.criteriaName}`}
															label={`${praise.criteriaName}`}
															criteriaMasterId={praise.criteriaMasterId}
															disable={false}
															defaultValue={0}
														/>
												  ))
												: ''}
										</Box>
									)}
								</FormControl>
							</Box>
							<Box className='feedback-message '>
								<TextField
									style={{ width: '100%' }}
									id={'given-feedback-msg'}
									label={t('givingForMsgLabelPF')}
									className={'helperText-bottom'}
									multiline
									rows={4}
									placeholder={t('typeMessageHere')}
									value={formData.sharedRemark}
									onChange={setMessageValue}
									error={formData && !formData.sharedRemark && formData.submit && formData.sharedRemark.trim() === ''}
									helperText={
										formData && !formData.sharedRemark && formData.submit && formData.sharedRemark.trim() === '' ? (
											<>
												<ErrorIcon />
												{t('enterValidMessage')}
											</>
										) : (
											''
										)
									}
								/>
							</Box>
							<div className='okr-form-action feedback-action'>
								<Button
									className='cancel-btn btn-link'
									id={'given-pf-cancel'}
									onClick={(event) => submitRequest(event, 2)}
									color='primary'
								>
									{t('cancelBtn')}
								</Button>
								<Button
									className='submitBtn btn-primary'
									id={'given-pf-send'}
									disabled={(formData && formData.isError) || loader}
									onClick={(event) => submitRequest(event, 1)}
									color='primary'
								>
									{t('send')}
								</Button>
							</div>
						</form>
					)
				) : (
					<></>
				)
			) : (
				<></>
			)}
		</Fragment>
	);
};

export default CreateGivePersonalFeedback;
