import React, { Fragment, useEffect, useState } from 'react';
import { Box, ClickAwayListener, Grid, Popper, Switch, Tab, Tabs, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FormSelectPopper } from './FormSelectPopper';
import { NoRecord } from '../../../CommonComponent/NoRecord';
import { PointIcon, ReportsTooltipIcon, WeightageIcon } from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { abbreviateNumber } from '../../../../../config/utils';

export const AssessmentFormsSettingDrawer: React.FC<any> = (props: any) => {
	const {
		assessmentSettingTabSelected,
		setAssessmentSettingTabSelected,
		formObjectData,
		setFormObjectData,
		toggleClick,
		setToggleClick,
		errorMsg,
		setErrorMsg,
		setIsFormEdited,
		nonRatingQuestion,
		setNonRatingQuestion,
	} = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [totalWeightageValue, setTotalWeightageValue] = useState<number>(0);

	useEffect(() => {
		if (formObjectData?.ratingFormSectionResponse) {
			setTotalWeightage();
		}
	}, [formObjectData.ratingFormSectionResponse]);

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleTabChange = (event: any, newValue: Number) => {
		setAssessmentSettingTabSelected({ activeTab: newValue });
	};

	const handleWeightageActiveToggleClick = (e: any) => {
		let weightageValue = 0;
		if (e.target.checked) {
			weightageValue = abbreviateNumber(100 / (formObjectData.ratingFormSectionResponse.length || 1), 2);
		}
		let weightageDataCopy = formObjectData.ratingFormSectionResponse.map((item: any) => {
			return { ...item, weightagePercentage: weightageValue };
		});
		setFormObjectData({ ...formObjectData, ratingFormSectionResponse: weightageDataCopy });

		setToggleClick(e.target.checked);
		setErrorMsg(false);
		setIsFormEdited(true);
	};
	const setTotalWeightage = () => {
		let weightage = 0;
		formObjectData.ratingFormSectionResponse.forEach((element: any) => {
			weightage = weightage + parseFloat(element.weightagePercentage);
		});
		setTotalWeightageValue(abbreviateNumber(weightage, 0));
	};

	const handleWeightageChange = (e: any, index: any) => {
		let weightageDataCopy = formObjectData.ratingFormSectionResponse.map((item: any) => item);
		weightageDataCopy[index]['weightagePercentage'] = parseInt(e.target.value || 0);
		setFormObjectData({ ...formObjectData, ratingFormSectionResponse: weightageDataCopy });
		setErrorMsg(false);
		setIsFormEdited(true);
	};

	function a11yProps(index: number) {
		return {
			id: `assessment-tabpanel-${index}`,
			'aria-controls': `assessment-tabpanel-${index}`,
		};
	}

	return (
		<Fragment>
			<>
				<Tabs className='okr-tabs drawer-tabs' value={0} onChange={handleTabChange} aria-label='Admin Tabs'>
					<Tab label={t('settingsLabel')} value={0} {...a11yProps(0)} />
				</Tabs>
				<Box className='drawer-tabs-panel'>
					<form className='drawer-form-fields assessment-setting-drawer' noValidate autoComplete='off'>
						<Box className=' drawer-input-field'>
							<FormSelectPopper
								{...props}
								formObjectData={formObjectData}
								setFormObjectData={setFormObjectData}
								setToggleClick={setToggleClick}
								setIsFormEdited={setIsFormEdited}
								nonRatingQuestion={nonRatingQuestion}
								setNonRatingQuestion={setNonRatingQuestion}
							/>
						</Box>
						{formObjectData.ratingFormId !== 0 ? (
							<>
								<Box className='drawer-blue-bg-full'>
									<Box className='form-setting-parameter'>
										<Box className='score-status-field'>
											<Grid component='label' container>
												<Grid item>
													<Box>
														<Switch
															id='performanceScore'
															name='performanceScore'
															checked={toggleClick}
															onChange={handleWeightageActiveToggleClick}
															inputProps={{ 'aria-label': 'controlled' }}
														/>
													</Box>
												</Grid>
												<Grid item>{t('Performance score calculation with weightage')}</Grid>
											</Grid>
										</Box>
										<Box className='score-list-field'>
											<Box className='score-type-head'>
												<Box className='score-row'>
													<Box className='col-1'></Box>
													<Box className='col-2'>
														<Box className='weightage-head'>
															<Typography variant='h5'>
																<WeightageIcon />
																{t('sectionWeightage')}
															</Typography>
															<Box
																aria-owns={open ? 'mouse-over-popover' : undefined}
																aria-haspopup='true'
																onClick={handleOpen}
																//onMouseLeave={handleClose}
																component={'div'}
																className='filter-info-box'
															>
																<OKRButton id={'btnId'} icon={<ReportsTooltipIcon />} className={'info-icon'} />
																<Popper
																	className={'role-description-popup point-tooltip'}
																	open={open}
																	anchorEl={anchorEl}
																	transition
																	placement={'bottom-end'}
																>
																	<ClickAwayListener onClickAway={handleClose}>
																		<Box className='textarea-expand generate-okr-filter-selected'>
																			<Typography variant='body1'>{t('pointsTooltip')}</Typography>
																		</Box>
																	</ClickAwayListener>
																</Popper>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
											<Box className='score-type-content'>
												{formObjectData.ratingFormSectionResponse.map((item: any, index: number) => (
													<Box className='score-row'>
														<Box className='col-1'>
															<Typography variant='h5'>{item.name}</Typography>
														</Box>
														<Box className='col-2'>
															<Box className='weightage-wrap'>
																<TextField
																	className='field-col2 '
																	placeholder={t('points')}
																	value={item.weightagePercentage}
																	name={t('points')}
																	onChange={(e) => {
																		handleWeightageChange(e, index);
																	}}
																	//inputProps={{ maxLength: ratingScaleNameMaxLength }}
																	disabled={!toggleClick}
																	InputLabelProps={{
																		shrink: true,
																	}}
																	InputProps={{ 'aria-label': t('points'), disableUnderline: true }}
																/>
															</Box>
														</Box>
													</Box>
												))}
												{nonRatingQuestion && nonRatingQuestion.length > 0 ? (
													nonRatingQuestion.map((nonRatingQues: any, index: number) => (
														<Box className='score-row trap-row'>
															<Box className='col-1'>
																<Typography variant='h5'>{nonRatingQues.name}</Typography>
															</Box>
															<Box className='col-2'>
																<Box className='weightage-wrap'>
																	<TextField
																		className='field-col2 '
																		placeholder={t('points')}
																		value={0}
																		name={t('points')}
																		disabled={true}
																		InputLabelProps={{
																			shrink: true,
																		}}
																		InputProps={{ 'aria-label': t('points'), disableUnderline: true }}
																	/>
																</Box>
																<Box
																	aria-owns={open ? 'mouse-over-popover' : undefined}
																	aria-haspopup='true'
																	onClick={handleOpen}
																	//onMouseLeave={handleClose}
																	component={'div'}
																	className='filter-info-box'
																>
																	<OKRButton id={'btnId'} icon={<ReportsTooltipIcon />} className={'info-icon'} />
																	<Popper
																		className={'role-description-popup point-tooltip'}
																		open={open}
																		anchorEl={anchorEl}
																		transition
																		placement={'bottom-end'}
																	>
																		<ClickAwayListener onClickAway={handleClose}>
																			<Box className='textarea-expand generate-okr-filter-selected'>
																				<Typography variant='body1'>{t('noQuestionSection')}</Typography>
																			</Box>
																		</ClickAwayListener>
																	</Popper>
																</Box>
															</Box>
														</Box>
													))
												) : (
													<></>
												)}
												<Box className='score-row'>
													<Box className='col-1'>
														<Typography variant='h5'>Total Weightage</Typography>
													</Box>
													<Box className='col-2'>
														<Box className='weightage-wrap total-weightage'>
															<TextField
																className='field-col2 helperText-bottom'
																placeholder={t('points')}
																value={totalWeightageValue}
																helperText={
																	errorMsg && (
																		<>
																			<ErrorIcon /> {t('weightageValidation')}
																		</>
																	)
																}
																//inputProps={{ maxLength: ratingScaleNameMaxLength }}
																disabled={true}
																InputLabelProps={{
																	shrink: true,
																}}
																InputProps={{ 'aria-label': t('points'), disableUnderline: true }}
															/>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
								<Box className='note-text'>
									<Typography variant='h6'>
										Note: These settings will apply only to this specific form assignment
									</Typography>
								</Box>
							</>
						) : (
							<NoRecord {...props} noRecordMessage={t('assessmentFormHead1')} subHead={t('assessmentFormSubHead')} />
						)}
					</form>
				</Box>
			</>
		</Fragment>
	);
};
