import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../../../Admin/CommonComponent/MainDrawer';
import { CreateFeedbackDrawer } from './CreateFeedbackDrawer';
import { Enums } from '../../../../config/enums';
import { formatFeedbackDate, getDifferenceBetweenDates } from '../../../../config/utils';
import {
	getFeedbackReason,
	getPlanReason,
	highlightFeedbackId,
	postFeedback,
	putFeedback,
	reloadDetailsPage,
	reloadFeedbackList,
} from '../../../../action/performanceImprovementPlan';
import { useDispatch } from 'react-redux';
import * as Moment from 'moment';

import { extendMoment } from 'moment-range';
import AlertDialog from '../../../Common/Dialog';
import { useSnackbar } from 'notistack';

export const CreateFeedback: React.FC<any> = (props: any) => {
	const { openCreatePipForm, setOpenFeedbackDrawer, showMsg, employeeFeedbackPermission } = props;
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [isCategoryVisible, setIsCategoryVisible] = useState<boolean>(false);
	const [isTimeFrameVisible, setIsTimeFrameVisible] = useState<boolean>(false);
	const [isTimeFrameRequired, setIsTimeFrameRequired] = useState<boolean>(false);
	const [isCreateTimeframe, setIsCreateTimeframe] = useState<boolean>(true);
	const [validationCheck, setValidationCheck] = useState<any>({});
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
	const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
	const moment = extendMoment(Moment);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '', type: 1 });
	const { enqueueSnackbar } = useSnackbar();
	const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
	const [extendDateToggle, setExtendDateToggle] = useState<boolean>(false);
	const [isDatesDisabled, setIsDatesDisabled] = useState<any>({ start: false, end: false });

	const defaultMinDateVal = new Date();
	defaultMinDateVal.setMonth(defaultMinDateVal.getMonth() - 120);
	const [minDateValue, setMinDateValue] = useState<any>(defaultMinDateVal);
	const [timeFrameChangeReasonTypes, settimeFrameChangeReasonTypes] = useState<any[]>([]);

	const defaultFeedbackFormDetails = {
		nomineeId: Enums.ZERO,
		title: '',
		summary: '',
		timeframeStartDate: '',
		timeframeEndDate: '',
		areaOfImprovements: '',
		performanceImprovementPlanStatusId: Enums.ONE, // Draft = 1, Submitted = 2, Active = 3, SuccessfullyCleared = 4, Terminate = 5
		feedbackIds: [], // [1, 2]
		performanceImprovementPlanReviewDates: [],
		documents: [],
		tasks: [],
		feedbackTimeframeResponse: [],
	};
	const [feedbackFormDetails, setFeedbackFormDetails] = useState<any>(defaultFeedbackFormDetails);
	useEffect(() => {
		getTimeFrameReasonAPICall();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCreatePipForm]);
	useEffect(() => {
		if (openCreatePipForm && openCreatePipForm.type === 'Edit') {
			let formData: any = { ...openCreatePipForm.feedbackFormEditDetails };
			formData.timeFrame = {
				start: openCreatePipForm.feedbackFormEditDetails.timeframeStartDate,
				end: openCreatePipForm.feedbackFormEditDetails.timeframeEndDate,
			};

			formData.performanceImprovementPlanReviewDates = [
				{
					performanceImprovementPlanReviewers: openCreatePipForm.feedbackFormEditDetails.feedbackReviewerResponse.map(
						(item: any) => {
							return { ...item, employeeId: item.reviewerId };
						}
					),
					reviewerIds: openCreatePipForm.feedbackFormEditDetails.feedbackReviewerResponse.map(
						(item: any) => item.reviewerId
					),
				},
			];
			formData.nomineeId = openCreatePipForm.feedbackFormEditDetails.nomineeDetails
				? openCreatePipForm.feedbackFormEditDetails.nomineeDetails?.employeeId
				: Enums.ZERO;
			formData.nomineeError = '';
			formData.nomineeDetails = openCreatePipForm?.feedbackFormEditDetails?.nomineeDetails
				? [
						{
							...openCreatePipForm?.feedbackFormEditDetails?.nomineeDetails,
							label: openCreatePipForm?.feedbackFormEditDetails?.nomineeDetails.fullName,
						},
				  ]
				: null;
			formData.feedbackType = {
				name: openCreatePipForm.feedbackFormEditDetails.feedbackType,
				feedbackTypeId: openCreatePipForm.feedbackFormEditDetails.feedbackTypeId,
			};
			formData.feedbackCategory = {
				name: openCreatePipForm.feedbackFormEditDetails.feedbackCategory,
				feedbackCategoryId: openCreatePipForm.feedbackFormEditDetails.feedbackCategoryId,
			};
			if (openCreatePipForm.feedbackFormEditDetails.timeframeStartDate) {
				let momentStartDate = moment(new Date(openCreatePipForm.feedbackFormEditDetails.timeframeStartDate));
				setSelectedStartDate(momentStartDate);
				formData.timeframeStartDate = momentStartDate;
			}
			let endDateValue: any = null;
			if (openCreatePipForm.feedbackFormEditDetails.timeframeEndDate) {
				endDateValue = new Date(openCreatePipForm.feedbackFormEditDetails.timeframeEndDate);
			}
			let dateVisible = false;
			if (openCreatePipForm?.feedbackFormEditDetails?.feedbackTypeResponse?.isCategoryRequired) {
				setIsCategoryVisible(true);
				if (openCreatePipForm.feedbackFormEditDetails.feedbackCategoryResponse) {
					dateVisible = openCreatePipForm.feedbackFormEditDetails.feedbackCategoryResponse.timeframe !== 3;
					setIsTimeFrameVisible(dateVisible || false);
					setIsTimeFrameRequired(
						openCreatePipForm.feedbackFormEditDetails.feedbackCategoryResponse.isTimeframeRequired
					);
					setIsCreateTimeframe(
						openCreatePipForm.feedbackFormEditDetails.feedbackCategoryResponse.isCreateTimeframe || false
					);
				}
			}
			setSelectedEndDate(endDateValue);
			formData.timeframeEndDate = dateVisible ? moment(endDateValue) : '';
			setFeedbackFormDetails(formData);
			setIsDatesDisabled({
				start: formData?.feedbackCategoryResponse?.editTimeFrameType !== 1,
				end: !(
					formData?.feedbackCategoryResponse?.editTimeFrameType === 1 ||
					(formData?.feedbackCategoryResponse?.editTimeFrameType === 3 &&
						(formData?.feedbackCategoryResponse?.editEndDateType === 1 ||
							formData?.feedbackCategoryResponse?.editEndDateType === 2))
				),
			});
			if (formData?.feedbackCategoryResponse?.editEndDateType !== 3) {
				let minEndDateValue: any = new Date();
				if (formData?.feedbackCategoryResponse?.editEndDateType === 1) {
					minEndDateValue = new Date(openCreatePipForm.feedbackFormEditDetails.timeframeStartDate);
				}
				setMinDateValue(minEndDateValue);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openCreatePipForm]);
	const { t } = useTranslation();
	const handleFeedbackDrawerClose = (event: any, type: string, skipDateCheck = false) => {
		event?.preventDefault && event.preventDefault();

		if (type === 'save') {
			let errorList: any = {};
			let scrollToId = '';
			if (!feedbackFormDetails.nomineeId) {
				errorList.nomineeId = 'Search and select nominee';
				scrollToId = 'feedback_nominee_container';
			}
			if (!feedbackFormDetails.feedbackType?.feedbackTypeId) {
				errorList.feedbackType = 'Type is required';
				if (!scrollToId) {
					scrollToId = 'feedback_type';
				}
			}
			if (!feedbackFormDetails.title) {
				errorList.title = 'Title is required';
				if (!scrollToId) {
					scrollToId = 'feedback_title';
				}
			}
			if (!feedbackFormDetails.feedbackCategory?.feedbackCategoryId && isCategoryVisible) {
				errorList.feedbackCategory = 'Category is required';
				if (!scrollToId) {
					scrollToId = 'feedback_category';
				}
			}
			if (feedbackFormDetails?.fromExtension && feedbackFormDetails?.isTimeFrameChanged) {
				const selectedItem =
					feedbackFormDetails?.feedbackTimeframeResponse && feedbackFormDetails?.feedbackTimeframeResponse?.length
						? feedbackFormDetails?.feedbackTimeframeResponse.find(
								(item: any) => item?.feedbackTimeframeId === Enums.ZERO
						  )
						: null;
				if (!selectedItem) {
					errorList.reason = 'Reason is required';
					scrollToId = 'feedback_extend_reason';
				}
			}
			if (
				(!feedbackFormDetails.timeframeStartDate?._d || !feedbackFormDetails.timeframeEndDate?._d) &&
				isCategoryVisible &&
				isTimeFrameRequired
			) {
				if (!feedbackFormDetails.timeframeStartDate?._d) {
					errorList.timeframeStartDate = 'Timeframe is required';
				}
				if (!feedbackFormDetails.timeframeEndDate?._d) {
					errorList.timeframeEndDate = 'Timeframe is required';
				}
				if (!scrollToId) {
					scrollToId = 'feedback_timeframe';
				}
			} else if (!scrollToId && !skipDateCheck && !isCreateTimeframe && feedbackFormDetails.timeframeEndDate?._d) {
				let todaysDate = formatFeedbackDate(new Date());
				let startDate = formatFeedbackDate(new Date(feedbackFormDetails.timeframeStartDate?._d));
				let endDate = formatFeedbackDate(new Date(feedbackFormDetails.timeframeEndDate?._d));
				if (startDate < todaysDate) {
					setModalProps({ open: true, type: 2, event: null, message: t('pastFeedbackDate') });
					return false;
				} else if (endDate < startDate) {
					errorList.timeframeEndDate = 'End date should be greater than start date';
				}
			}
			if (scrollToId) {
				scrollTo(scrollToId);
			}
			setValidationCheck(errorList);
			if (Object.keys(errorList).length > 0) {
				return false;
			}
			let postData: any = {
				nomineeId: feedbackFormDetails.nomineeId,
				feedbackTypeId: feedbackFormDetails.feedbackType?.feedbackTypeId,
				feedbackCategoryId: feedbackFormDetails.feedbackCategory?.feedbackCategoryId || 0,
				title: feedbackFormDetails.title,
				summary: feedbackFormDetails.summary,
				timeframeStartDate: feedbackFormDetails.timeframeStartDate?._d
					? formatFeedbackDate(feedbackFormDetails.timeframeStartDate?._d)
					: '',
				timeframeEndDate: feedbackFormDetails.timeframeEndDate?._d
					? formatFeedbackDate(feedbackFormDetails.timeframeEndDate?._d)
					: '',
				reviewerIds: [],
				documents: feedbackFormDetails.documents,
				isTimeFrameChanged: feedbackFormDetails.isTimeFrameChanged,
				feedbackTimeframeResponse: feedbackFormDetails.feedbackTimeframeResponse,
				isThreeSixtyExperience: false,
			};
			if (feedbackFormDetails?.performanceImprovementPlanReviewDates.length > 0) {
				postData.reviewerIds = feedbackFormDetails?.performanceImprovementPlanReviewDates[0].reviewerIds;
			}
			if (openCreatePipForm && openCreatePipForm.type === 'Edit') {
				postData.feedbackId = feedbackFormDetails.feedbackId;
			}
			if (feedbackFormDetails.reviewers && feedbackFormDetails.reviewers.length > 0) {
				feedbackFormDetails.reviewers.forEach((item: any) => {
					postData.reviewerIds.push(item.id);
				});
			}
			saveFeedback(postData);
		} else {
			if (isFormEdited === true) {
				setModalProps({ open: true, event: null, message: t('unSavedItemAlert'), type: 1 });
			} else {
				setOpenFeedbackDrawer({
					open: false,
					feedbackFormEditDetails: null,
					type: 'Add',
				});
			}
		}
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 0 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);
	};
	const dispatch = useDispatch();
	const saveFeedback = async (payload: any) => {
		let resp: any = null;
		setIsSaveDisabled(true);
		if (payload.feedbackId) {
			resp = await dispatch(putFeedback(payload));
		} else {
			resp = await dispatch(postFeedback(payload));
		}
		if (resp && resp?.data && resp.data?.status === Enums.STATUS_SUCCESS) {
			setIsSaveDisabled(false);
			dispatch(reloadFeedbackList(true));
			dispatch(reloadDetailsPage(true));
			enqueueSnackbar(resp?.data?.messageList.message, {
				variant: 'success',
				autoHideDuration: 3000,
			});
			dispatch(highlightFeedbackId(resp.data.entity.feedbackId));

			setOpenFeedbackDrawer({
				open: false,
				feedbackFormEditDetails: null,
				type: 'Add',
			});
		}
	};

	const updateFeedbackFormDetails = (keyName: string, value: any) => {
		try {
			let errorObject: any = {};
			if (keyName === 'feedbackType') {
				errorObject = { feedbackType: '', feedbackCategory: '', timeFrame: '' };
			} else if (keyName === 'feedbackCategory') {
				errorObject = { timeFrame: '' };
			}
			setValidationCheck({ ...validationCheck, [keyName]: '', ...errorObject });
			if (keyName === 'nomineeId') {
				const nomineeDetails = {
					nomineeId: value ? value[0]?.employeeId : Enums.ZERO,
					nomineeDetails: value || null,
					nomineeError: '',
				};
				setIsFormEdited(true);
				setFeedbackFormDetails({ ...feedbackFormDetails, ...nomineeDetails });
			} else if (keyName === 'timeFrameDates') {
				setIsFormEdited(true);
				setFeedbackFormDetails({ ...feedbackFormDetails, ...value, timeFrameError: '' });
			} else if (keyName === 'feedbackIds') {
				setIsFormEdited(true);
				setFeedbackFormDetails({ ...feedbackFormDetails, [keyName]: value });
			} else {
				if (keyName !== 'performanceImprovementPlanReviewDates') {
					if (keyName == 'timeFrame') {
						if (value !== null) {
							setIsFormEdited(true);
						}
					} else {
						setIsFormEdited(true);
					}
				}
				const errorObj =
					keyName === 'title'
						? { titleError: '' }
						: keyName === 'summary'
						? { summaryError: '' }
						: keyName === 'areaOfImprovements'
						? { areaOfImprovementsError: '' }
						: {};

				if (keyName === 'feedbackCategory' && !(value && value.isTimeframeVisible)) {
					setFeedbackFormDetails({
						...feedbackFormDetails,
						timeframeEndDate: null,
						timeframeStartDate: null,
						[keyName]: value,
					});
				} else if (keyName === 'feedbackType') {
					setFeedbackFormDetails({ ...feedbackFormDetails, [keyName]: value, feedbackCategory: null });
				} else {
					setFeedbackFormDetails({ ...feedbackFormDetails, [keyName]: value });
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 1) {
				setOpenFeedbackDrawer({
					open: false,
					feedbackFormEditDetails: null,
					type: 'Add',
				});
			} else if (modalProps.type === 2) {
				handleFeedbackDrawerClose({}, 'save', true);
			}
		}
		setModalProps({ open: false, event: null, message: '', type: 1 });
	};
	const getTimeFrameReasonAPICall = async () => {
		try {
			const response: any = await dispatch(getFeedbackReason());
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				settimeFrameChangeReasonTypes(entityList && entityList?.length ? entityList : []);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Some error occurred`);
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
		}
	};
	return (
		<>
			<MainDrawer
				open={openCreatePipForm?.open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={<Typography variant='h2'>Feedback</Typography>}
				rightCloseBtn={false}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
				//loader={loader}
				saveButtonText={t('submit')}
				children={
					<Box className='drawer-inner-content'>
						<CreateFeedbackDrawer
							{...props}
							feedbackFormDetails={feedbackFormDetails}
							updateFeedbackFormDetails={updateFeedbackFormDetails}
							feedbackFormType={openCreatePipForm.type}
							isCategoryVisible={isCategoryVisible}
							setIsCategoryVisible={setIsCategoryVisible}
							isTimeFrameVisible={isTimeFrameVisible}
							setIsTimeFrameVisible={setIsTimeFrameVisible}
							setFeedbackFormDetails={setFeedbackFormDetails}
							isTimeFrameRequired={isTimeFrameRequired}
							setIsTimeFrameRequired={setIsTimeFrameRequired}
							isCreateTimeframe={isCreateTimeframe}
							setIsCreateTimeframe={setIsCreateTimeframe}
							validationCheck={validationCheck}
							setValidationCheck={setValidationCheck}
							selectedDateRange={selectedDateRange}
							setSelectedDateRange={setSelectedDateRange}
							setIsFormEdited={setIsFormEdited}
							showMsg={showMsg}
							selectedStartDate={selectedStartDate}
							setSelectedStartDate={setSelectedStartDate}
							selectedEndDate={selectedEndDate}
							setSelectedEndDate={setSelectedEndDate}
							extendDateToggle={extendDateToggle}
							setExtendDateToggle={setExtendDateToggle}
							openCreatePipForm={openCreatePipForm}
							setIsDatesDisabled={setIsDatesDisabled}
							isDatesDisabled={isDatesDisabled}
							minEndDateValue={minDateValue}
							setMinDateValue={setMinDateValue}
							timeFrameChangeReasonTypes={timeFrameChangeReasonTypes}
							employeeFeedbackPermission={employeeFeedbackPermission}
						/>
					</Box>
				}
				handleDrawerClose={(event: any) => handleFeedbackDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleFeedbackDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleFeedbackDrawerClose(event, 'cancel')}
				isSaveButtonDisabled={isSaveDisabled}
			/>

			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
