import React, { Fragment } from 'react';
import { Box, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, DragIndicator } from '../../../../../config/svg/CommonSvg';
import { OKRButton } from '../../../../Common/OKRButton';
import { Enums } from '../../../../../config/enums';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import {
	getRemainingCharacter,
	getScaleNameBasedOnType,
	getScaleNamePlaceholderBasedOnType,
} from '../../../../../config/utils';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

export const RatingScaleList: React.FC<any> = (props: any) => {
	const {
		customScaleDetails,
		customScaleType,
		handleRatingScaleNameChange,
		handleRatingScalePointChange,
		checkRatingScaleLength,
		handleDeleteRatingScaleItem,
		handleUpdateDragDropSequence,
	} = props;
	const { t } = useTranslation();
	const ratingScaleNameMaxLength = 30;
	const startRatingScaleNameMaxLength = 25;
	const multipleChoiceRatingScaleNameMaxLength = 250;

	// =========================================================== \\
	// ======== Question Draggable Method Start here ============= \\
	// =========================================================== \\
	const getItemStyle = (isDragging: any, draggableStyle: any) => ({
		// styles we need to apply on draggable
		...draggableStyle,
		...(isDragging && {
			background: 'rgb(235,235,235)',
		}),
	});
	const reorder = (list: any, startIndex: number, endIndex: number) => {
		try {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		} catch (e) {
			console.error(e);
			return [];
		}
	};
	const onDragEnd = (result: any, questionList: any) => {
		try {
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}
			const items = reorder(questionList, result.source.index, result.destination.index);
			let data = items;
			const addOptionIndex = items.findIndex((rec: any) => rec.isDisable);
			if (addOptionIndex !== items.length - 1) {
				const lastRecord = items[addOptionIndex];
				let filterData = [...items].filter((item: any) => !item.isDisable);
				filterData = lastRecord ? [...filterData, lastRecord] : [...filterData];
				data = filterData.map((ele: any, indexVal: number) => {
					ele.scaleIndex = indexVal + 1;
					return ele;
				});
			} else {
				data = items.map((ele: any, indexVal: number) => {
					ele.scaleIndex = indexVal + 1;
					return ele;
				});
			}

			handleUpdateDragDropSequence(data);
		} catch (e) {
			console.error(e);
		}
	};
	// =========================================================== \\
	// ======== Question Draggable Method End here =============== \\
	// =========================================================== \\

	return (
		<Box className='rating-type-content'>
			{customScaleDetails?.ratingScaleDetails && customScaleDetails?.ratingScaleDetails?.length ? (
				<Fragment>
					<DragDropContext onDragEnd={(result: any) => onDragEnd(result, customScaleDetails?.ratingScaleDetails)}>
						<Droppable droppableId='list'>
							{(provided: any) => (
								<div ref={provided.innerRef} className='' {...provided.droppableProps}>
									{customScaleDetails?.ratingScaleDetails && customScaleDetails?.ratingScaleDetails?.length ? (
										customScaleDetails?.ratingScaleDetails.map((scale: any, indexVal: number) => {
											const {
												ratingScale,
												point,
												scaleIndex,
												isDisable,
												isError,
												errorMessage,
												isPointError,
												pointErrorMessage,
											} = scale;

											return (
												<Draggable
													key={`draggable_${scaleIndex}_index_${indexVal}_section_${indexVal}`}
													draggableId={`draggable_${scaleIndex}_questionIndex_${indexVal}`}
													index={indexVal}
													isDragDisabled={isDisable ? true : false}
												>
													{(provided: any, snapshot: any) => (
														<Fragment>
															<Box
																className={`rating-row ${isDisable ? 'rating-row-option' : ''}`}
																style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
																ref={provided.innerRef}
																{...provided.draggableProps}
																{...provided.dragHandleProps}
															>
																<Box className='col-1'>
																	<Box className='question-text'>
																		<Box className='question-label'>
																			{customScaleType === 'View' ? (
																				<></>
																			) : (
																				<Tooltip arrow title={t('dragDropLabel')}>
																					<span className='drag-icon'>
																						<DragIndicator />
																					</span>
																				</Tooltip>
																			)}
																			<Typography variant='h5'>
																				{getScaleNameBasedOnType(
																					customScaleDetails?.scaleTypeId,
																					customScaleDetails?.scaleType
																				)}
																				<Typography component='span' className='slide-txt'>
																					{scaleIndex}
																				</Typography>
																			</Typography>
																		</Box>
																		<Box className='textbox-wrap'>
																			<TextField
																				id={`ratingScaleItem_${indexVal + 1}`}
																				className='field-col2 helperText-bottom'
																				label={<></>}
																				placeholder={
																					isDisable
																						? t('addOption')
																						: t('ratingPlaceholder', {
																								scaleType: getScaleNamePlaceholderBasedOnType(
																									customScaleDetails?.scaleTypeId,
																									customScaleDetails?.scaleType
																								),
																						  })
																				}
																				disabled={customScaleType === 'View' ? true : false}
																				value={ratingScale || ''}
																				name={isDisable ? t('AddOption') : t('slideRating')}
																				error={isError || false}
																				helperText={
																					isError && errorMessage ? (
																						<>
																							<ErrorIcon /> {errorMessage || ''}
																						</>
																					) : null
																				}
																				onChange={(e: any) => handleRatingScaleNameChange(e, scale)}
																				inputProps={{
																					maxLength:
																						customScaleDetails?.scaleTypeId === Enums.ONE ||
																						customScaleDetails?.scaleTypeId === Enums.THREE
																							? startRatingScaleNameMaxLength
																							: customScaleDetails?.scaleTypeId === Enums.TWO
																							? multipleChoiceRatingScaleNameMaxLength
																							: ratingScaleNameMaxLength,
																				}}
																				InputLabelProps={{
																					shrink: true,
																				}}
																				InputProps={{
																					'aria-label': t('ratingPlaceholder', {
																						scaleType: getScaleNamePlaceholderBasedOnType(
																							customScaleDetails?.scaleTypeId,
																							customScaleDetails?.scaleType
																						),
																					}),
																					disableUnderline: true,
																				}}
																			/>
																			{isDisable || customScaleType === 'View' ? (
																				<></>
																			) : (
																				<Typography variant='h6'>
																					{t('charactersRemaining')}:{' '}
																					{getRemainingCharacter(
																						ratingScale,
																						customScaleDetails?.scaleTypeId === Enums.ONE ||
																							customScaleDetails?.scaleTypeId === Enums.THREE
																							? startRatingScaleNameMaxLength
																							: customScaleDetails?.scaleTypeId === Enums.TWO
																							? multipleChoiceRatingScaleNameMaxLength
																							: ratingScaleNameMaxLength
																					)}
																				</Typography>
																			)}
																		</Box>
																	</Box>
																</Box>
																<Box className='col-2'>
																	{isDisable ? (
																		<></>
																	) : (
																		<Box className='weightage-wrap'>
																			<TextField
																				className='field-col2 helperText-bottom'
																				label={<></>}
																				placeholder={t('points')}
																				disabled={customScaleType === 'View' ? true : false}
																				value={point}
																				onChange={(e: any) => handleRatingScalePointChange(e, scale)}
																				error={isPointError || false}
																				helperText={
																					isPointError && pointErrorMessage ? (
																						<>
																							<ErrorIcon /> {pointErrorMessage || ''}
																						</>
																					) : null
																				}
																				inputProps={{
																					maxLength:
																						customScaleDetails?.scaleTypeId === Enums.ONE ||
																						customScaleDetails?.scaleTypeId === Enums.THREE
																							? startRatingScaleNameMaxLength
																							: ratingScaleNameMaxLength,
																				}}
																				InputLabelProps={{
																					shrink: true,
																				}}
																				InputProps={{ 'aria-label': t('points'), disableUnderline: true }}
																			/>
																			{checkRatingScaleLength() && customScaleType !== 'View' ? (
																				<OKRButton
																					icon={<DeleteIcon />}
																					disabled={customScaleType === 'View' ? true : false}
																					handleClick={(e: any) => handleDeleteRatingScaleItem(e, scale)}
																					className='editIcon'
																					title={t('delete')}
																				/>
																			) : (
																				<></>
																			)}
																		</Box>
																	)}
																</Box>
															</Box>
														</Fragment>
													)}
												</Draggable>
											);
										})
									) : (
										<></>
									)}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</Fragment>
			) : (
				<></>
			)}
		</Box>
	);
};
