import React, { useState } from 'react';
import { ClickAwayListener, TextField, Box } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../../config/svg/Action';
//import './style.scss';
import { useDispatch } from 'react-redux';
import { setOkrCreationInProgress } from '../../../action/common';

export const OkrTextEdit: React.FC<any> = (props) => {
	const { updateName, handleClickInputAway, okr, kr, goalType } = props;
	const keyData = goalType === 2 ? kr : okr;
	const description = goalType === 1 ? okr.objectiveName : keyData.keyDescription;
	const [textValue, setTextValue] = useState<String>(description);
	const inputRef = React.useRef<any>();
	const dispatch = useDispatch();
	const enterFocus = (e: any) => {
		e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
	};

	return (
		<ClickAwayListener onClickAway={(e) => handleClickInputAway(e, okr, goalType, keyData, textValue)}>
			<Box className='edit-okr-field'>
				<TextField
					maxRows={2}
					multiline
					value={textValue}
					autoFocus
					fullWidth
					onChange={(e) => {
						setTextValue(e.target.value);
						dispatch(setOkrCreationInProgress(true));
					}}
					inputRef={inputRef}
					onFocus={(e) => enterFocus(e)}
				/>
				<Box className='textfield-action-btn'>
					<OKRButton
						className='cancel-btn'
						icon={<CancelIcon />}
						handleClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							dispatch(setOkrCreationInProgress(false));
							handleClickInputAway(e, okr, goalType, keyData, '', false);
						}}
					/>
					<OKRButton
						className='save-btn'
						icon={<TickIcon />}
						handleClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							dispatch(setOkrCreationInProgress(false));
							updateName(e, okr, goalType, keyData, textValue);
						}}
					/>
				</Box>
			</Box>
		</ClickAwayListener>
	);
};
