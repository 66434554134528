import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	entity: {},
	entityList: [],
	signInStatus: '',
	pending: false,
	messageList: [],
	error: {},
	currentCycleData: {},
};

export default function signReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.SIGIN_PENDING:
			return {
				...state,
				pending: true,
				messageList: [],
			};
		case actionTypes.SIGIN_SUCCESS:
			return {
				...state,
				pending: false,
				entity: action.payload.entity,
				signInStatus: action.payload.status,
				messageList: action.payload.messageList,
			};
		case actionTypes.SIGIN_FAILED:
			return {
				...state,
				pending: false,
				error: action.error,
			};
		case actionTypes.UPDATE_STATUS: {
			return {
				...state,
				entity: {},
				entityList: [],
				signInStatus: '',
				pending: false,
				messageList: [],
				error: {},
			};
		}
		case actionTypes.CURRENT_CYCLE_DATA: {
			return {
				...state,
				currentCycleData: action.payload,
			};
		}
		case actionTypes.LOGOUT:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
}
