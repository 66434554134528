import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { recognitionSearchWithTeam } from '../../action/search';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { getUserDetails } from '../../config/utils';
import { MultiSelect } from '../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../Admin/CommonComponent/NoSearchRecord';

export const TeamEmployeeSearch = (props: any) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});

	let userDetail = getUserDetails();

	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			const resp = await props.searchCustomEmployee(value, 1, 6);
			let list = resp.data.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: userData.searchType === 2 ? userData.teamName : userData.firstName + ' ' + userData.lastName,
							value: userData.searchType === 2 ? userData.teamId : userData.employeeId,
							secondaryText: userData.searchType === 2 ? 'Leader Name: ' + userData.teamHeadName : userData.emailId,
							imagePath: userData.searchType === 2 ? userData.logoImagePath : userData.imagePath,
							emailId: userData.emailId,
							classList: userData.searchType === 2 ? 'user-team-chip' : '',
						};
					})
				);
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
				setReportingToOptions([]);
			}
		}
	};

	const getTeamData = (data: any) => {
		return dispatch(recognitionSearchWithTeam(data));
	};
	return (
		<>
			<MultiSelect
				key={'reportingTo'}
				id='recognize-to-dropdown'
				selectedOptions={props.selectedUser}
				optionsList={reportingToOptions}
				setOptionsList={setReportingToOptions}
				onSelectOption={(value: any) => {
					props.setSelectedUser(value);
					setReportingToOptions([]);
					setEmptyRecords({ ...emptyRecords, reportingTo: false });
				}}
				blurCalled={(value: any) => {
					setReportingToOptions([]);
				}}
				isLoadMore={true}
				blurInputOnSelect={true}
				setEmptyRecords={setEmptyRecords}
				//isMulti={true}
				customFilter={() => {}}
				placeHolder={t('recognizeSrcPlaceholder')}
				noOptionsMessage={<NoSearchRecord />}
				showNoOptions={emptyRecords.reportingTo === true}
				fetchAsyncData={true}
				isSingleSelection={true}
				performSearch={getTeamData}
				closeMenuOnSelect={true}
				labelTemplate={'avatarLabel'}
				selectClassName={`select-search-dropdown select-search-icon ${
					props.selectedUser.length === 0 && props.focussed ? 'error' : ''
				}`}
				selectClassNamePrefix={'react-select'}
				error={''}
				helperText={''}
				disabled={props.disabled}
			/>
		</>
	);
};
