import React, { useRef, useState } from 'react';
import { Box, Button, Link, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UploadIcon from '../../../../../images/upload-img-new.svg';
import { DeleteIcon, DownloadIcon } from '../../../../../config/svg/CommonSvgIcon';
import '../../../../../styles/pages/admin/bulkUserUpload.scss';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';
import exportFromJSON from 'export-from-json';
import { BulkAssignUsersCsvTemplateUrl } from '../../../../../config/url';
import { OKRButton } from '../../../../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../../../../config/svg/Action';

export const BulkAssignUsers: React.FC<any> = (props) => {
	const { uploadFileData, setUploadFileData, setValidationInProgress, setLoader, handleUsersCancel, handleUsersSave } =
		props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });

	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const thisValidateBulkUsers = async (file: any) => {
		if (file.fileName) {
			const formData = new FormData();
			formData.append('formFile', file.filePath);
			const response = await props.validateBulkGoalRoleUpload(formData);
			if (Boolean(response) && response.data && response.data) {
				if (response.data.status === 200) {
					setValidationInProgress(false);
					setLoader(false);
					setUploadFileData({
						...uploadFileData,
						filePath: file.filePath,
						fileName: file.fileName,
						fileUploaded: true,
					});
					showApiMsgs(t('bulkGoalFormUploadSuccess'), 'success');
					return response.data;
				} else if (response.data.status === 400) {
					if (response.data.entity && response.data.entity.bulkErrors.length > 0) {
						const errorDetail = response.data.entity.bulkErrors;
						//setErrorLogMessage(errorDetail);
					}
					const responseAPI = response.data.messageList;
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					showApiMsgs(`${messages} `, 'error');
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showApiMsgs('error', messages || `Some error occurred`);
				}
			}
		}
	};

	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		e.target.value = '';
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'text/csv',
		];
		if (size >= 1024) {
			enqueueSnackbar(t('fileSize1MB'), { variant: 'error', autoHideDuration: 5000 });
		} else if (!format.includes(fileType.toLowerCase())) {
			enqueueSnackbar(t('fileType4BulkUpload'), { variant: 'error', autoHideDuration: 5000 });
		} else {
			let fileObj = { filePath: file, fileName: file.name, fileUploaded: true };
			//thisValidateBulkUsers(fileObj);
			setUploadFileData({
				...uploadFileData,
				filePath: fileObj.filePath,
				fileName: fileObj.fileName,
				fileUploaded: true,
			});
			handleUsersSave(4, fileObj);
		}
		setTimeout(() => {
			const selectedEl = document.getElementById('uploaded-file-details');
			if (selectedEl) {
				selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			}
		}, 15);

		//setErrorLogMessage([]);
	};

	const removeFile = () => {
		setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
	};

	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'deleteFile') {
				removeFile();
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	const preventDefault = (event: any) => event.preventDefault();
	return (
		<>
			<input ref={inputFileRef} type='file' style={{ display: 'none' }} onChange={handleFileChange} />
			<Box className='bulk-user-content'>
				<Box className='upload-head'>
					<Typography variant='h5'>
						<Link id={'download-temp'} target='_blank' rel='noopener noreferrer' href={BulkAssignUsersCsvTemplateUrl}>
							{t('downloadTemplate')}
						</Link>{' '}
						<span dangerouslySetInnerHTML={{ __html: t('bulkAssignUsersHeading') }}></span>
					</Typography>
				</Box>
				<Box
					className='upload-file'
					onClick={(event: any) => {
						uploadFile(event);
					}}
				>
					<Box className='upload-file-inner'>
						<img src={UploadIcon} alt='Upload File' className='upload-img' />
						<Typography variant='h4'>
							<Link id={'browse-btn'} href='#' onClick={preventDefault}>
								{t('UsersBulkUploadBrowseFile')}
							</Link>
						</Typography>
					</Box>
				</Box>
				{/* {uploadFileData.fileName !== '' ? (
					<Box className='uploading-content'>
						<Box className='uploading-bar'>
							<Box className='bar'>
								<Box className='success'></Box>
								<Box className='inner-bar'>
									<Typography>
										{t('UsersBulkUploadFileName')} : {uploadFileData.fileName ? uploadFileData.fileName : ''}
									</Typography>
									<Button
										className='delete-icon'
										onClick={() =>
											setModalProps({
												open: true,
												module: 'delete',
												type: 'deleteFile',
												message: t('UsersBulkUploadDeleteFile'),
											})
										}
									>
										<DeleteIcon />
									</Button>
								</Box>
							</Box>
							{uploadFileData.fileName !== '' ? (
								<Box className='textfield-action-btn'>
									<OKRButton
										className='cancel-btn'
										id={'cancel-btn-bulk-assign-users'}
										icon={<CancelIcon />}
										handleClick={() => {
											handleUsersCancel(4);
										}}
									/>
									<OKRButton
										className='save-btn'
										id={'save-btn-bulk-assign-users'}
										icon={<TickIcon />}
										handleClick={() => {
											handleUsersSave(4);
										}}
									/>
								</Box>
							) : (
								<></>
							)}
						</Box>
					</Box>
				) : (
					<></>
				)} */}
			</Box>
			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
