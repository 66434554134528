import {
	Box,
	Avatar,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	Typography,
	ListItemIcon,
	Tooltip,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { BottomArrowIcon, DeleteIcon, EditIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { getTime, getUserDetails, getUserName } from '../../../config/utils';
import {
	deleteCommentsPost,
	getCommentsList,
	getLikesList,
	likeRecognization,
	saveCommentsPost,
	saveRecognizePost,
} from '../../../action/recognize';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { enableTaggingWithImage } from '../../Common/CkEditor/CkEditorEnums';
import { searchEmployee } from '../../../action/search';
import { RecognizePostBadges } from '../RecognizePostBadges/RecognizePostBadges';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { useSnackbar } from 'notistack';
import { CreateCommentForm } from './CreateCommentForm';
import AlertDialog from '../../Common/Dialog';
import { getLocalStorageItem } from '../../../services/StorageService';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import LikeImg from '../../../images/Like.svg';
import { ReplyArrow } from '../../../config/svg/ArrowSvg';
import { ReplyList } from '../ReplyListing/ReplyList';

export const CreateComment = (props: any) => {
	const { setHighlightComment, highlightComment } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [showReplyBox, setShowReplyBox] = useState<boolean>(false);
	const [replyUser, setReplyUser] = useState<any>(null);
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [postText, setPostText] = React.useState<any>('');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [commentsListData, setCommentsListData] = useState<any[]>([]);
	const [pagingInfo, setPagingInfo] = useState<any>({
		pageIndex: 1,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
	});
	const [likeData, setLikeData] = useState<any[]>([]);
	const [likedId, setLikedId] = useState<number>(0);

	const loggedInUserDetail = getUserDetails();
	const [replyCommentId, setReplyCommentId] = useState<number>(0);
	const [likeAnimation, setLikeAnimation] = useState<number>(0);
	const [editCommentId, setEditEditId] = useState<number>(0);
	const [modalProps, setModalProps] = React.useState<any>({
		open: false,
		data: {},
	});
	let userDetail = getUserDetails();
	const [replyHighlight, setReplyHighlight] = useState(false);
	const [savedId, setSavedId] = useState(0);

	useEffect(() => {
		if (highlightComment) {
			getComments(1, 99999);
			setHighlightComment(false);
		} else {
			getComments();
		}
	}, []);

	const updateCount = async (itemId: any, add: boolean, updateColumn = '', commentCount = 0) => {
		let counter = add ? 1 : -1;
		let commentsListDataCopy = commentsListData.map((listData: any) => listData);
		const index = commentsListDataCopy.findIndex((listData: any) => itemId === listData.commentDetailsId);
		if (index > -1) {
			if (updateColumn === 'like') {
				commentsListDataCopy[index].isLiked = add;
				commentsListDataCopy[index].totalLikes += counter;
			} else {
				let userDetails = Object.assign({}, userDetail);
				if (commentsListDataCopy[index].totalReplies === 0 && counter > 0) {
					commentsListDataCopy[index].replyFirstName = userDetail.firstName;
					commentsListDataCopy[index].replyLastName = userDetail.lastName;
					commentsListDataCopy[index].replyFullName = userDetail.firstName + ' ' + userDetail.lastName;
					commentsListDataCopy[index].replyImagePath = userDetail.imagePath;
				} else if (commentsListDataCopy[index].totalReplies === 1 && counter < 0) {
					commentsListDataCopy[index].replyFirstName = null;
					commentsListDataCopy[index].replyLastName = null;
					commentsListDataCopy[index].replyFullName = null;
					commentsListDataCopy[index].replyImagePath = null;
				}
				commentsListDataCopy[index].totalReplies = commentCount;
			}
		}
		setCommentsListData(commentsListDataCopy);
	};
	const updateCommentCount = (recognitionId: any, add: boolean) => {
		props.updateRecogCommentCount(recognitionId, add);
	};
	const getComments = async (pageIndex = 1, pageSize = 10) => {
		setEditEditId(0);
		const response: any = await dispatch(
			getCommentsList(`moduleDetailsId=${props.recognitionId}&pageIndex=${pageIndex}&pageSize=${pageSize}&moduleId=1`)
		);
		if (Boolean(response) && response.data.status === 200) {
			setPagingInfo(response.data.pagingInfo ? response.data.pagingInfo : pagingInfo);
			if (response.data.pagingInfo?.pageIndex === 1) {
				setCommentsListData(response.data.entity.commentDetailResponses);
			} else {
				if (response.data.entity.commentDetailResponses && response.data.pagingInfo?.pageIndex > 1) {
					setCommentsListData([...commentsListData, ...response.data.entity.commentDetailResponses]);
				} else if (
					response.data.entity.commentDetailResponses === null ||
					response.data.entity.commentDetailResponses.length === 0
				) {
					setCommentsListData([]);
				}
			}

			//comment highlight
			let recognitionCommentHighlight: any = getLocalStorageItem('recognitionCommentHighlight');
			if (Boolean(recognitionCommentHighlight)) {
				recognitionCommentHighlight = JSON.parse(recognitionCommentHighlight);
				if (recognitionCommentHighlight.replyDetailsId) {
					setReplyCommentId(recognitionCommentHighlight.commentDetailsId);
					setReplyHighlight(true);
				} else {
					setSavedId(recognitionCommentHighlight.commentDetailsId);
				}
				const selectedEl = document.getElementById(`${'commentPost-' + recognitionCommentHighlight.commentDetailsId}`);
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}

				setTimeout(() => {
					setSavedId(0);
				}, 7000);
			}
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setModalProps({
			open: false,
			data: {},
		});
	};
	const handleDeleteComment = (handleDeleteComment: any) => {
		setModalProps({
			open: true,
			data: handleDeleteComment,
		});
		handleClose();
	};
	const handleConfirmClick = async () => {
		let data = `commentDetailsId=${modalProps.data.commentDetailsId}`;
		const response: any = await dispatch(deleteCommentsPost(data));

		if (Boolean(response) && response.data.status === 200) {
			// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
			// 	variant: 'success',
			// 	autoHideDuration: 5000,
			// });
			//props.getAllOrgRecognize(1, 10, props.isMyPost);
			props.updateRecogCommentCount(props.recognitionId, false);
			getComments(1, pagingInfo.pageIndex * 10);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleEditComment = (editCommentId: any) => {
		setEditEditId(editCommentId.commentDetailsId);
	};

	const setReplyListId = async (data: any, showReplyBox = false) => {
		if (replyCommentId !== 0 && replyCommentId === data.commentDetailsId) {
			setReplyCommentId(0);
		} else {
			setReplyCommentId(data.commentDetailsId);
		}
		if (data.employeeId === loggedInUserDetail.employeeId) {
			setReplyUser(null);
		} else {
			setReplyUser(data);
		}
	};
	const setPrefilledUser = (data: any) => {
		if (loggedInUserDetail.employeeId !== data.employeeId) {
			setReplyUser(data);
		} else {
			setReplyUser(null);
		}
	};
	const likeCommentList = async (item: any, like: any) => {
		setLikeAnimation(item.commentDetailsId);
		const response: any = await dispatch(
			likeRecognization({
				moduleDetailsId: item.commentDetailsId,
				moduleId: 3,
				employeeId: userDetail.employeeId,
				isActive: like,
			})
		);

		if (Boolean(response) && response.data.status === 200) {
			updateCount(item.commentDetailsId, like, 'like');
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleOpen = async (event: any, item: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		let data = `moduleDetailsId=${item.commentDetailsId}&moduleId=3`;
		const response: any = await dispatch(getLikesList(data));
		if (Boolean(response) && response.data.status === 200) {
			setLikeData(response.data.entity.recognitionLikeResponses);
			setLikedId(item.commentDetailsId);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
		setLikeData([]);
		setLikedId(0);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	return (
		<>
			{editCommentId === 0 && (
				<Box className='recog-post-comment-list'>
					<Box className='recog-comment-top'>
						<Box className='recog-user-info'>
							<Box className='user-img'>
								{loggedInUserDetail && loggedInUserDetail.imagePath ? (
									<Avatar
										className='avatar-default'
										alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
										src={loggedInUserDetail.imagePath}
									/>
								) : (
									<Avatar className='avatar-default'>
										{getUserName({
											firstName: loggedInUserDetail.firstName,
											lastName: loggedInUserDetail.lastName,
											fullName: loggedInUserDetail.fullname,
										})}
									</Avatar>
								)}
							</Box>
						</Box>
						<CreateCommentForm
							getComments={getComments}
							recognitionId={props.recognitionId}
							getAllOrgRecognize={props.getAllOrgRecognize}
							isMyPost={props.isMyPost}
							updateCommentCount={updateCommentCount}
							commentPageIndex={pagingInfo?.pageIndex}
							setEditEditId={setEditEditId}
						/>
					</Box>
				</Box>
			)}
			{commentsListData.length > 0 &&
				commentsListData.map((commentsData: any) => {
					return commentsData.commentDetailsId === editCommentId ? (
						<Box className='recog-post-comment-list' id={`${'recogCommentPost-' + commentsData.commentDetailsId}`}>
							<Box className='recog-comment-top'>
								<Box className='recog-user-info'>
									<Box className='user-img'>
										{commentsData && commentsData.imagePath ? (
											<Avatar
												className='avatar-default'
												alt={`${commentsData.firstName} ${commentsData.lastName}`}
												src={commentsData.imagePath}
											/>
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													firstName: commentsData.firstName,
													lastName: commentsData.lastName,
													fullName: commentsData.fullname,
												})}
											</Avatar>
										)}
									</Box>
								</Box>
								<CreateCommentForm
									getComments={getComments}
									commentsData={commentsData}
									recognitionId={props.recognitionId}
									getAllOrgRecognize={props.getAllOrgRecognize}
									isMyPost={props.isMyPost}
									updateCommentCount={updateCommentCount}
									commentPageIndex={pagingInfo?.pageIndex}
									setEditEditId={setEditEditId}
								/>
							</Box>
						</Box>
					) : (
						<Box className='recog-post-comment-list' id={`${'recogCommentPost-' + commentsData.commentDetailsId}`}>
							<Box className='recog-comment-top'>
								<Box className='recog-user-info'>
									<Box className='user-img'>
										{commentsData?.imagePath ? (
											<Avatar src={commentsData?.imagePath} />
										) : (
											<Avatar>
												{getUserName({
													fullName: '',
													firstName: commentsData?.firstName,
													lastName: commentsData?.lastName,
												})}
											</Avatar>
										)}
									</Box>
								</Box>
								<Box className='recog-post-box'>
									<Box
										className={`${
											savedId === commentsData.commentDetailsId
												? 'recog-post-box-inner highlight'
												: 'recog-post-box-inner'
										}`}
									>
										<Box className='recog-post-head'>
											<Box className='recog-post-head-left'>
												<Typography>{commentsData.fullName}</Typography>
												<Typography variant='h6' className='font-weight-normal'>
													{commentsData.updatedOn ? getTime(commentsData.updatedOn) : getTime(commentsData.createdOn)}{' '}
													{commentsData.isEdited ? `(${t('editedLabel')})` : ''}
												</Typography>
											</Box>
											<Box className='recog-post-head-right'>
												{commentsData.employeeId === userDetail.employeeId && (
													<PopperMenu
														popperClassName={'popper-menu recog-post-popper-menu'}
														anchorEl={anchorEl}
														handleClose={handleClose}
														includeKebabMenu={true}
														menuClicked={popperMenuClicked}
														setPopperMenuClicked={setPopperMenuClicked}
														{...props}
														children={
															<>
																<ListItem
																	onClick={() => {
																		handleEditComment(commentsData);
																	}}
																>
																	<ListItemAvatar>
																		<EditIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('editLabel')} />
																</ListItem>

																<ListItem
																	onClick={() => {
																		handleDeleteComment(commentsData);
																	}}
																>
																	<ListItemAvatar>
																		<DeleteIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('delete')} />
																</ListItem>
															</>
														}
													/>
												)}
											</Box>
										</Box>
										<Box
											className='recog-post-content long-text'
											id={`${'commentPost-' + commentsData.commentDetailsId}`}
										>
											<Typography
												variant='h5'
												className='font-weight-normal'
												dangerouslySetInnerHTML={{ __html: commentsData.comments }}
											></Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box className='recog-comment-btm'>
								<Box className='recog-comment-btm-left'>
									{!commentsData.isLiked ? (
										<OKRButton
											className='like-btn'
											handleClick={() => {
												likeCommentList(commentsData, true);
											}}
											text={t('likeLevel')}
										/>
									) : (
										<Box className='like-filled-btn'>
											<OKRButton
												className={`like-btn ${likeAnimation === commentsData.commentDetailsId ? 'btn-anim' : ''}`}
												icon={
													<>
														{likeAnimation === commentsData.commentDetailsId && (
															<AnimatedIcon
																className='like-animation-img'
																width='60'
																type='image/svg+xml'
																alt='No user'
																imagePath={LikeImg}
															/>
														)}
													</>
												}
												text={<span className='remove-img'>{t('likedLevel1')}</span>}
												handleClick={() => {
													likeCommentList(commentsData, false);
													setLikeAnimation(0);
												}}
											/>
										</Box>
									)}

									<OKRButton
										className='reply-btn'
										text={t('reply')}
										handleClick={() => {
											setShowReplyBox(true);
											setReplyCommentId(commentsData.commentDetailsId);
											if (commentsData.employeeId === loggedInUserDetail.employeeId) {
												setReplyUser(null);
											} else {
												setReplyUser(commentsData);
											}
											const selectedEl = document.getElementById(`create-reply-box`);
											if (selectedEl) {
												selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
											}
										}}
									/>
								</Box>
								<Box className='recog-comment-btm-right'>
									<List disablePadding>
										{
											<ListItem
												aria-owns={open ? 'mouse-over-popover' : undefined}
												aria-haspopup='true'
												onMouseEnter={(e: any) => {
													commentsData.totalLikes > 0 && handleOpen(e, commentsData);
												}}
												onMouseLeave={handleClose}
												className={commentsData.totalLikes > 0 ? 'active' : ''}
											>
												<ListItemText
													className={commentsData.totalLikes > 0 ? 'like-popup' : ''}
													primary={`${commentsData.totalLikes} ${
														commentsData.totalLikes > 1 ? t('likedLevel') : t('likeLevel')
													}`}
												/>
												{open && commentsData.commentDetailsId === likedId && (
													<Box>
														<PopperMenu
															{...props}
															children={
																<>
																	{likeData.length > 0 &&
																		likeData.map((likeList: any) => {
																			return (
																				<ListItem>
																					<Box className='user-info'>
																						<Box className='user-img'>
																							{likeList?.imagePath ? (
																								<Avatar src={likeList?.imagePath} />
																							) : (
																								<Avatar>
																									{getUserName({
																										fullName: '',
																										firstName: likeList?.firstName,
																										lastName: likeList?.lastName,
																									})}
																								</Avatar>
																							)}
																						</Box>
																						<Box className='user-info-details'>
																							{likeList.fullName.length > 30 ? (
																								<Tooltip title={likeList.fullName} arrow>
																									<Typography variant='subtitle2'>
																										{likeList.fullName.substring(0, 30)}
																										...
																									</Typography>
																								</Tooltip>
																							) : (
																								<Typography>{likeList.fullName}</Typography>
																							)}

																							{likeList.emailId.length > 28 ? (
																								<Tooltip title={likeList.emailId} arrow>
																									<Typography variant='subtitle2'>
																										{likeList.emailId.substring(0, 28)}
																										...
																									</Typography>
																								</Tooltip>
																							) : (
																								<Typography variant='subtitle2'>{likeList.emailId}</Typography>
																							)}
																						</Box>
																					</Box>
																				</ListItem>
																			);
																		})}
																</>
															}
															popperClassName={'recog-liked-by-dropdown'}
															anchorEl={anchorEl}
															handleClose={handleClose}
															popperPlacement={'bottom'}
															open={open}
														/>
													</Box>
												)}
											</ListItem>
										}

										<ListItem className={commentsData.totalReplies > 0 ? 'active' : ''}>
											<ListItemText
												primary={`${commentsData.totalReplies} ${
													commentsData.totalReplies > 1 ? t('Replies') : t('reply')
												}`}
												onClick={() => {
													setReplyListId(commentsData, false);
												}}
											/>
										</ListItem>
									</List>
								</Box>
							</Box>
							<Box className='recog-replies-trail'>
								<Box className='no-of-replies'>
									{commentsData.totalReplies === 1 ? (
										<>
											<OKRButton
												className='no-of-replies-btn'
												icon={<ReplyArrow />}
												text={
													<>
														{commentsData?.replyImagePath ? (
															<Avatar src={commentsData?.replyImagePath} />
														) : (
															<Avatar>
																{getUserName({
																	fullName: '',
																	firstName: commentsData?.replyFirstName,
																	lastName: commentsData?.replyLastName,
																})}
															</Avatar>
														)}
														{commentsData.replyFullName} {t('Replied')}
													</>
												}
												handleClick={() => {
													setReplyListId(commentsData);
												}}
											/>
										</>
									) : commentsData.totalReplies > 1 ? (
										<>
											<OKRButton
												className='no-of-replies-btn'
												icon={<ReplyArrow />}
												text={`${commentsData.totalReplies} ${t('Replies')}`}
												handleClick={() => {
													setReplyListId(commentsData, false);
												}}
											/>
										</>
									) : (
										<></>
									)}
								</Box>
								{replyCommentId === commentsData.commentDetailsId ? (
									<ReplyList
										setReplyCommentId={setReplyCommentId}
										commentDetailsId={commentsData.commentDetailsId}
										commentsData={commentsData.employeeId !== loggedInUserDetail.employeeId ? commentsData : null}
										updateCount={updateCount}
										showReplyBox={showReplyBox}
										setShowReplyBox={setShowReplyBox}
										replyHighlight={replyHighlight}
										setReplyHighlight={setReplyHighlight}
										setPrefilledUser={setPrefilledUser}
										replyUser={replyUser}
									/>
								) : (
									<></>
								)}
							</Box>
						</Box>
					);
				})}
			{pagingInfo?.totalPages > 1 && pagingInfo?.totalPages !== pagingInfo.pageIndex && (
				<List className='load-more-ul' disablePadding>
					<ListItem className='load-more'>
						<OKRButton
							handleClick={(e) => {
								getComments(pagingInfo.pageIndex + 1 || 1, 10);
							}}
							className={`load-more-btn btn-small-text`}
							text={t('viewMoreComments')}
						/>
					</ListItem>
				</List>
			)}
			{modalProps.open && (
				<AlertDialog
					module='information'
					message={t('commentDelete')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
