import React, { useState, useEffect, useRef } from 'react';
// import { UserAssessmentHeader } from '../UserAssessmentHeader';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../../config/enums';
// import { TeamAssessmentHeader } from '../TeamAssessmentHeader';
import { Box, Typography, Button, Avatar, Tooltip } from '@material-ui/core';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { checkUnSavePopUpVisible, getUserDetails, getUserName } from '../../../config/utils';
import { UserAssessmentForm } from '../UserSelfAssessment/UserAssessmentForm';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getQuestionTypeMasterData } from '../../../action/adminSettings';
import { saveAssessmentForm } from '../../../action/UserPerformance';
import AlertDialog from '../../Common/Dialog';
// import { getUserDetails } from '../../../config/utils';

export const ThreeSixtyAssessmentDetail = (props: any) => {
	const {
		performanceCycle,
		handleOneOnOneClick,
		employeeId,
		// fromTeamAssessment,
		backClicked,
		selectedUserAssessment,
		// isFinalRating,
		// performanceRoleId,
		// getTeamAssessList,
		loader,
		setLoader,
		getEmployeeAssessmentDetails,
		ratingAssessmentType = null,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const userDetails = getUserDetails();

	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	const ref2 = useRef<any>(null);
	const { questionTypeMasterData } = useSelector((state: any) => state?.adminSettingReducer);
	const {
		employeeAssessmentResponse,
		// employeeAssessmentResponseStatus,
		// selfAssessmentResponse,
		// selfAssessmentResponseStatus,
	} = useSelector((state: any) => state?.userPerformanceReducer);

	const [assessmentFormDetails, setAssessmentFormDetails] = useState<any>(null);
	const [fullAssessmentFormDetails, setFullAssessmentFormDetails] = useState<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });

	useEffect(() => {
		if (employeeAssessmentResponse?.performanceRoleLinkedFormSection) {
			setLoader(false);
			setAssessmentFormDetails(addQuestionNumber(employeeAssessmentResponse?.performanceRoleLinkedFormSection));
		}
		if (employeeAssessmentResponse) {
			setFullAssessmentFormDetails(employeeAssessmentResponse);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeAssessmentResponse]);
	useEffect(() => {
		if (!questionTypeMasterData) {
			dispatch(getQuestionTypeMasterData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (performanceCycle?.performanceCycleId) {
			setLoader(true);
			getEmployeeAssessmentDetails(
				employeeId || Enums.ZERO,
				performanceCycle?.performanceCycleId || Enums.ZERO,
				ratingAssessmentType || Enums.THREE
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [performanceCycle]);

	// ======= HANDLING UN_SAVE CHANGES WITH BELOW CODE =========== //
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);

	const addQuestionNumber = (details: any) => {
		let quesNumber = 0;
		const updatedDetails =
			details && details?.length
				? [...details].map((section: any) => {
						if (section?.performanceRoleLinkedFormDetails && section?.performanceRoleLinkedFormDetails?.length) {
							section.performanceRoleLinkedFormDetails = section?.performanceRoleLinkedFormDetails.map(
								(question: any) => {
									quesNumber = quesNumber + 1;
									question.questionNumber = quesNumber;

									return question;
								}
							);
						}
						return section;
				  })
				: details;
		return updatedDetails;
	};

	const handleClickOutside = (event: any) => {
		if (
			ref.current &&
			(ref1.current?.contains(event.target) ||
				ref2.current?.contains(event.target) ||
				!ref.current?.contains(event.target)) &&
			checkUnSavePopUpVisible(event) &&
			isFormEdited
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				//|| !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('unSavedItemAlert') });
			}
		} else {
			//
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'ClearForm') {
				if (employeeAssessmentResponse?.performanceRoleLinkedFormSection) {
					setAssessmentFormDetails(addQuestionNumber(employeeAssessmentResponse?.performanceRoleLinkedFormSection));
					setIsFormEdited(false);
				}
			} else if (modalProps?.type === 'UnSaveChanges') {
				if (modalProps?.event) {
					setAssessmentFormDetails(addQuestionNumber(employeeAssessmentResponse?.performanceRoleLinkedFormSection));
					setIsFormEdited(false);
					const { target } = modalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	const handleSaveAssessmentAPICall = async (status: any, performanceType = Enums.ONE) => {
		try {
			const requestPayload = {
				performanceCycleId: performanceCycle?.performanceCycleId || Enums.ZERO,
				ratingAssessmentId: employeeAssessmentResponse?.ratingAssessmentId || Enums.ZERO,
				employeeId: employeeId || Enums.ZERO,
				reportingTo: userDetails?.employeeId || Enums.ZERO,
				ratingAssessmentType: employeeAssessmentResponse?.ratingAssessmentType || Enums.TWO,
				status: status || Enums.ZERO,
				performanceRoleLinkedFormSection: [...assessmentFormDetails],
			};
			const response: any = await dispatch(saveAssessmentForm(requestPayload));
			if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(
					messages ? messages : status === Enums.TWO ? 'Successfully saved in drafts.' : `Successfully saved.`,
					{
						variant: 'success',
						autoHideDuration: 3000,
					}
				);

				// if (performanceCycle?.performanceCycleId) {
				// 	setLoader(true);
				// 	getEmployeeAssessmentDetails(
				// 		employeeId || Enums.ZERO,
				// 		performanceCycle?.performanceCycleId || Enums.ZERO,
				// 		Enums.THREE
				// 	);
				// }
				setIsFormEdited(false);
				backClicked(true);
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while updating assessment form.`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleClearAssessmentForm = (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (isFormEdited) {
				setModalProps({ open: true, event: null, type: 'ClearForm', message: t('clearAssessmentForm') });
			} else {
				if (employeeAssessmentResponse?.performanceRoleLinkedFormSection) {
					setAssessmentFormDetails(addQuestionNumber(employeeAssessmentResponse?.performanceRoleLinkedFormSection));
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleSaveAsDraftAssessmentForm = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				handleSaveAssessmentAPICall(Enums.ONE, Enums.TWO);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleSubmitAssessmentForm = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			if (checkAssessmentFormValidation()) {
				if (assessmentFormDetails && assessmentFormDetails?.length) {
					handleSaveAssessmentAPICall(Enums.TWO, Enums.TWO);
				} else {
					setLoader(false);
				}
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const checkAssessmentFormValidation = () => {
		let isFormValidated = true;
		let firstErrorQuestion = 0;
		let firstErrorQuestionComment = 0;
		if (assessmentFormDetails && assessmentFormDetails?.length) {
			let updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
				const data = { ...item };
				return data;
			});
			updatedAssessmentFormDetails = [...updatedAssessmentFormDetails].map((section: any) => {
				const sectionData = { ...section };
				if (sectionData?.performanceRoleLinkedFormDetails && sectionData?.performanceRoleLinkedFormDetails?.length) {
					sectionData.performanceRoleLinkedFormDetails = sectionData.performanceRoleLinkedFormDetails.map(
						(ques: any) => {
							const quesData = { ...ques };
							if (
								quesData?.isQuestionsMandatory &&
								(!quesData?.ratingValue ||
									(quesData?.ratingValue &&
										typeof quesData.ratingValue === 'string' &&
										quesData.ratingValue.trim() === ''))
							) {
								quesData.isQuestionError = true;
								quesData.isQuestionErrorMsg = 'This question is mandatory to answer';
								firstErrorQuestion = ++firstErrorQuestion;
								isFormValidated = false;
								if (firstErrorQuestion === 1 && firstErrorQuestionComment === 0) {
									scrollTo(
										`section_question_index_value_${sectionData?.performanceRoleLinkedFormSectionId}_${quesData?.performanceRoleLinkedFormDetailsId}_${quesData?.ratingAssessmentId}`
									);
								}
							}
							if (quesData?.isCommentBoxAvailable && quesData?.isCommentBoxMandatory) {
								if (
									!quesData?.questionCommentBox ||
									(quesData?.questionCommentBox && quesData.questionCommentBox.trim() === '')
								) {
									quesData.isCommentExpand = true;
									quesData.isQuestionCommentError = true;
									quesData.isQuestionCommentErrorMsg = 'This question comment is mandatory to answer';
									firstErrorQuestionComment = ++firstErrorQuestionComment;
									isFormValidated = false;
									if (firstErrorQuestionComment === 1 && firstErrorQuestion === 0) {
										scrollTo(
											`section_question_index_value_${sectionData?.performanceRoleLinkedFormSectionId}_${quesData?.performanceRoleLinkedFormDetailsId}_${quesData?.ratingAssessmentId}`
										);
									}
								}
							}

							return quesData;
						}
					);
				}

				return sectionData;
			});

			setAssessmentFormDetails([...updatedAssessmentFormDetails]);
		}
		return isFormValidated;
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 150 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 100);
	};

	// ===================================================================================================================
	const handleChangeStarQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeStarQuestionHoverValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'starRatingHoverValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeScaleQuestionValue = (
		event: any,
		valueIndex: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number,
		isCommitted: boolean
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value, isCommitted);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeMultiChoiceQuestionValue = (
		event: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeQuestionComment = (
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'questionCommentBox', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleChangeShortQuestionValue = (
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'ratingValue', value);
		} catch (error) {
			console.error(error);
		}
	};
	const handleCommentsToggle = (
		e: any,
		value: any,
		question: any,
		questionIndex: number,
		section: any,
		sectionIndex: number
	) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			updateAssessmentFormDetails(sectionIndex, questionIndex, 'isCommentExpand', value);
		} catch (error) {
			console.error(error);
		}
	};
	const updateAssessmentFormDetails = (
		sectionIndex: number,
		questionIndex: number,
		keyName: string,
		value: any,
		scaleCommittedFlag = false
		// keyName1?: string,
		// value1?: any
	) => {
		try {
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				const updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
					const data = { ...item };
					return data;
				});
				updatedAssessmentFormDetails[sectionIndex].performanceRoleLinkedFormDetails = updatedAssessmentFormDetails[
					sectionIndex
				].performanceRoleLinkedFormDetails?.length
					? updatedAssessmentFormDetails[sectionIndex].performanceRoleLinkedFormDetails?.map(
							(ques: any, quesIndex: number) => {
								const quesData = { ...ques };
								if (quesIndex === questionIndex && keyName) {
									if (keyName === 'isCommentExpand') {
										quesData[keyName] = !quesData?.isCommentExpand;
									} else {
										if (keyName !== 'starRatingHoverValue' || scaleCommittedFlag) {
											quesData.isQuestionError = false;
											quesData.isQuestionErrorMsg = '';
										}
										if (keyName === 'questionCommentBox') {
											quesData.isQuestionCommentError = false;
											quesData.isQuestionCommentErrorMsg = '';
										}
										if (keyName === 'ratingValue') {
											quesData[keyName] = value ? String(value) : '';
											if (quesData?.isCommentBoxMandatory && !quesData?.questionCommentBox) {
												quesData.isCommentExpand = true;
											}
										} else {
											quesData[keyName] = value;
										}
									}
									// if (keyName1) {
									// 	quesData[keyName1] = value1;
									// }
								}
								return quesData;
							}
					  )
					: [];
				setAssessmentFormDetails([...updatedAssessmentFormDetails]);
				// console.log(keyName);
				if (keyName !== 'starRatingHoverValue') {
					setIsFormEdited(true);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Box className='page-sub-head no-pad-bottom'>
				<Box className='back-to-main'>
					<Typography variant='body2'>
						<Button id='back-btn' onClick={backClicked}>
							<BackArrow /> {t('back')}
						</Button>
					</Typography>
				</Box>
				<Box className='user-info'>
					<Box className='user-info'>
						<Box className='user-img'>
							{selectedUserAssessment.imagePath ? (
								<Avatar src={selectedUserAssessment.imagePath} />
							) : (
								<Avatar>
									{getUserName({
										fullName: '',
										firstName: selectedUserAssessment.firstName,
										lastName: selectedUserAssessment.lastName,
									})}
								</Avatar>
							)}
						</Box>
						<Box className='user-info-details'>
							<Tooltip title={selectedUserAssessment.firstName + ' ' + selectedUserAssessment.lastName} arrow>
								<Typography
									variant='h4'
									className='font-weight-normal'
								>{`${selectedUserAssessment.firstName} ${selectedUserAssessment.lastName}`}</Typography>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</Box>
			{/* <TeamAssessmentHeader
				handleOneOnOneClick={handleOneOnOneClick}
				performanceCycle={performanceCycle}
				fromTeamAssessment={fromTeamAssessment || false}
				ref1={ref1}
				ref2={ref2}
				employeeId={employeeId || Enums.ZERO}
				userDetails={selectedUserAssessment}
				backClicked={backClicked}
			/> */}
			<div ref={ref}>
				<UserAssessmentForm
					callingFrom={'ThreeSixtyAssessment'}
					isViewMode={fullAssessmentFormDetails?.status === Enums.TWO}
					performanceCycle={performanceCycle}
					questionTypeMasterData={questionTypeMasterData}
					assessmentFormDetails={assessmentFormDetails}
					defaultAssessmentFormDetails={employeeAssessmentResponse?.performanceRoleLinkedFormSection}
					handleClearAssessmentForm={handleClearAssessmentForm}
					handleSaveAsDraftAssessmentForm={handleSaveAsDraftAssessmentForm}
					handleSubmitAssessmentForm={handleSubmitAssessmentForm}
					handleChangeQuestionComment={handleChangeQuestionComment}
					handleChangeShortQuestionValue={handleChangeShortQuestionValue}
					handleChangeStarQuestionValue={handleChangeStarQuestionValue}
					handleChangeStarQuestionHoverValue={handleChangeStarQuestionHoverValue}
					handleChangeScaleQuestionValue={handleChangeScaleQuestionValue}
					handleChangeMultiChoiceQuestionValue={handleChangeMultiChoiceQuestionValue}
					handleCommentsToggle={handleCommentsToggle}
					loader={loader}
					isFormEdited={isFormEdited}
					handleOneOnOneClick={handleOneOnOneClick}
				/>
			</div>

			{modalProps.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
