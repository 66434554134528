import React, { useState, useEffect, useRef } from 'react';
import { UserAssessmentHeader } from '../UserAssessmentHeader';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../../config/enums';
import { TeamAssessmentHeader } from '../TeamAssessmentHeader';
import { Box, Typography, Button, Avatar, Tooltip } from '@material-ui/core';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { checkUnSavePopUpVisible, getUserDetails, getUserName } from '../../../config/utils';
import { UserAssessmentForm } from '../UserSelfAssessment/UserAssessmentForm';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { getQuestionTypeMasterData } from '../../../action/adminSettings';
import { saveAssessmentForm } from '../../../action/UserPerformance';
import AlertDialog from '../../Common/Dialog';
import { TeamAssessmentDetailForm } from './TeamAssessmentDetailForm';
import { MultipleThreeSixtyAssessmentDetailsForm } from '../UserThreeSixtyAssessment/MultipleThreeSixtyAssessmentDetailsForm';
import { getMultipleAssessment } from '../../../action/perform';
import { FinalRatingAssessmentForm } from '../FinalRatingAssessment/FinalRatingAssessmentForm';
// import { getUserDetails } from '../../../config/utils';

export const TeamAssessmentDetail = (props: any) => {
	const {
		performanceCycle,
		handleOneOnOneClick,
		employeeId,
		fromTeamAssessment,
		backClicked,
		selectedUserAssessment,
		isFinalRating,
		performanceRoleId,
		getTeamAssessList,
		loader,
		setLoader,
		getEmployeeAssessmentDetails,
		assessmentType,
		managerRatingDateExceeded = false,
		handleSnapshot,
		getSelfAssessmentDetails,
		performanceRatings,
		setSelectedUserAssessment,
	} = props;
	const { t } = useTranslation();

	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	const ref2 = useRef<any>(null);

	const refSelf = useRef<any>(null);
	const refManager = useRef<any>(null);
	const refThreeSixty = useRef<any>(null);
	const dispatch = useDispatch();

	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

	const [isFinalSubmitted, setIsFinalSubmitted] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [showTeamAssessment, setShowTeamAssessment] = useState<number>(2);
	const { multipleAssessmentResponse, teamAssessmentResponse } = useSelector((state: any) => state.performReducer);
	const [selectedTile, setSelectedTile] = useState<number>(2);
	// ======= HANDLING UN_SAVE CHANGES WITH BELOW CODE =========== //
	useEffect(() => {
		if (isFinalRating) {
			setShowTeamAssessment(5);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);

	const handleClickOutside = (event: any) => {
		if (
			(ref1.current?.contains(event.target) ||
				ref2.current?.contains(event.target) ||
				refSelf.current?.contains(event.target) ||
				refThreeSixty.current?.contains(event.target) ||
				!ref.current?.contains(event.target)) &&
			isFormEdited
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('unSavedItemAlert') });
			}
		} else {
			//
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.event) {
				setIsFormEdited(false);
				const { target } = modalProps.event;
				const clonedNativeEvent = document.createEvent('HTMLEvents');
				clonedNativeEvent.initEvent('click', true, false);
				target.dispatchEvent(clonedNativeEvent);
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	const getRatingData = (type: number) => {
		setSelectedTile(type);
		setShowTeamAssessment(type);
		if (type === 3) {
			const data = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				employeeIds: [],
				nomineeForEmployeeId: selectedUserAssessment?.employeeId,
				performanceRoleId:
					teamAssessmentResponse.performanceRoleEmployeeResponse &&
					teamAssessmentResponse.performanceRoleEmployeeResponse.length
						? selectedUserAssessment?.performanceRoleId || 0
						: Enums.ZERO,
				ratingAssessmentType: Enums.THREE,
			};
			dispatch(getMultipleAssessment(data));
		} else if (type === 5) {
		} else {
			getEmployeeAssessmentDetails(employeeId || Enums.ZERO, performanceCycle?.performanceCycleId || Enums.ZERO, type);
		}
	};

	return (
		<>
			<Box className='page-sub-head no-pad-bottom'>
				<Box className='back-to-main'>
					<Typography variant='body2'>
						<Button id='back-btn' onClick={backClicked}>
							<BackArrow /> {t('back')}
						</Button>
					</Typography>
				</Box>
				<Box className='user-info'>
					<Box className='user-info'>
						<Box className='user-img'>
							{selectedUserAssessment.imagePath ? (
								<Avatar src={selectedUserAssessment.imagePath} />
							) : (
								<Avatar>
									{getUserName({
										fullName: '',
										firstName: selectedUserAssessment.firstName,
										lastName: selectedUserAssessment.lastName,
									})}
								</Avatar>
							)}
						</Box>
						<Box className='user-info-details'>
							<Tooltip title={selectedUserAssessment.firstName + ' ' + selectedUserAssessment.lastName} arrow>
								<Typography
									variant='h4'
									className='font-weight-normal'
								>{`${selectedUserAssessment.firstName} ${selectedUserAssessment.lastName}`}</Typography>
							</Tooltip>
						</Box>
					</Box>
				</Box>
			</Box>
			<TeamAssessmentHeader
				handleOneOnOneClick={handleOneOnOneClick}
				performanceCycle={performanceCycle}
				fromTeamAssessment={fromTeamAssessment || false}
				ref1={ref1}
				ref2={ref2}
				refSelf={refSelf}
				refManager={refManager}
				refThreeSixty={refThreeSixty}
				employeeId={employeeId || Enums.ZERO}
				userDetails={selectedUserAssessment}
				backClicked={backClicked}
				assessmentType={assessmentType}
				isFinalRating={isFinalRating}
				getRatingData={getRatingData}
				selectedUserAssessment={selectedUserAssessment}
			/>
			{showTeamAssessment === 3 ? (
				<MultipleThreeSixtyAssessmentDetailsForm
					{...props}
					callingType={'MyTeamAssessment'}
					performanceCycle={performanceCycle}
					loader={loader}
					setLoader={setLoader}
					backClicked={backClicked}
					employeeId={employeeId}
					selectedUserAssessment={selectedUserAssessment}
					handleBack={() => {}}
					userDetail={selectedUserAssessment}
					multipleAssessmentResponse={multipleAssessmentResponse}
					performanceCycleId={performanceCycle?.performanceCycleId}
					getTeamAssessList={getTeamAssessList}
					performanceRoleId={performanceRoleId}
					performanceRatings={performanceRatings}
					handleOneOnOneClick={handleOneOnOneClick}
					handleSnapshot={handleSnapshot}
					getSelfAssessmentDetails={getSelfAssessmentDetails}
					isViewMode={true}
					assessmentType={isFinalRating ? 'FinalAssessment' : 'Manager'}
					fromTeamAssessment={true}
					isFinalRating={isFinalRating}
					getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
					isFormEdited={isFormEdited}
					setIsFormEdited={setIsFormEdited}
					refComp={ref}
				/>
			) : showTeamAssessment === 5 ? (
				<FinalRatingAssessmentForm
					{...props}
					refComp={ref}
					setIsFormEdited={setIsFormEdited}
					selectedUserAssessment={selectedUserAssessment}
					backClicked={backClicked}
					isFinalSubmitted={isFinalSubmitted}
					setIsFinalSubmitted={setIsFinalSubmitted}
					setSelectedUserAssessment={setSelectedUserAssessment}
				/>
			) : (
				<TeamAssessmentDetailForm
					{...props}
					assessmentType={isFinalRating ? 'FinalAssessment' : 'Manager'}
					performanceCycle={performanceCycle}
					employeeId={selectedUserAssessment?.employeeId}
					fromTeamAssessment={true}
					backClicked={backClicked}
					isFinalRating={isFinalRating}
					handleOneOnOneClick={handleOneOnOneClick}
					selectedUserAssessment={selectedUserAssessment}
					managerRatingDateExceeded={managerRatingDateExceeded}
					refComp={ref}
					isFormEdited={isFormEdited}
					setIsFormEdited={setIsFormEdited}
					selectedTile={selectedTile}
				/>
			)}
			{modalProps.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
