import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	Chip,
	List,
	ListItem,
	Typography,
	TablePagination,
	Tooltip,
	TextField,
	InputAdornment,
	Tabs,
	Tab,
} from '@material-ui/core';
import { OKRButton } from '../../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, EditIcon, FormIcon, PerformanceIcon, SearchIcon } from '../../../../config/svg/CommonSvg';
import { AssessmentFormsIcon, CloneIcon, CrossIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/settings/perfomanceReviewListing.scss';
import {
	addPerformanceCycle,
	addScaleDetails,
	deleteManageScale,
	deletePerformanceCycle,
	getManageScaleList,
	getManageScaleListWIthReduxState,
	getPerformanceCycleList,
	getPerformanceTabCount,
	getQuestionTypeMasterData,
	performanceCycleExcludeEmployeeList,
	performanceCycleLinkedForm,
	publishPerformanceCycle,
	ratingFormCreate,
	ratingFormList,
	ratingFormUpdate,
	updateCompetencyRatingSettingTabValue,
	updateManageScaleStatus,
	updatePerformanceCycle,
	updateScaleDetails,
} from '../../../../action/adminSettings';
import { Enums } from '../../../../config/enums';
import {
	checkSupportPermissionType,
	checkUnSavePopUpVisible,
	findDuplicateNames,
	getFullName,
	getMonthDateYear,
} from '../../../../config/utils';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { AddPerformanceReview } from './AddPerformanceReview';
import { AssessmentFormsDrawer } from './AssessmentFormsDrawer';
import { NoRecord } from '../../CommonComponent/NoRecord';
import { useSnackbar } from 'notistack';
import { AssessmentPreviewDialog } from './AssessmentManageForm';
import { Visibility } from '../../../../config/svg/ProfileSvg';
import AlertDialog from '../../../Common/Dialog';
import LinearLoader from '../../../Common/Loader';
import { supportModulePerformAssessment } from '../../AdminConstant';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { getOkrMasterData } from '../../../../action/myGoal';
import { setLocalStorageItem } from '../../../../services/StorageService';
import { AssessmentFormsSettingDrawer } from './AssessmentFormsSetting/AssessmentFormsSettingDrawer';
import { SettingsTabPanel } from '..';
import { ScaleIcon } from '../../../../config/svg/AssessmentSvg';
import { PerformanceCyclesList } from './PerformanceCyclesList';
import { AssessmentFormsList } from './AssessmentFormsList';
import { ManageScale } from './ManageScale';
import { AddAssessmentForm } from './AddAssessmentForm';
import { AssessmentFormsSettingMainDrawer } from './AssessmentFormsSetting/AssessmentFormsSettingMainDrawer';

export const PerformanceReview: React.FC<any> = (props: any) => {
	const {
		openAssessmentForm,
		setOpenAssessmentForm,
		settingDropdownSelected,
		handleAddPerformanceReviewBtnClick,
		setAssessmentTabSelected,
		assessmentTabSelected,
		performanceReviewFormType,
		setPerformanceReviewFormType,
		tabSelected,
		assessmentSettingTabSelected,
		setAssessmentSettingTabSelected,
		handleTabChange,
		a11yProps,
		handleAddAssessmentFormBtnClick,
		settingAssessmentFormSelected,
		setAssessmentFormType,
		assessmentFormType,
	} = props;

	const [openAssessmentSettingForm, setOpenAssessmentSettingForm] = useState<any>({
		open: false,
	});
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { competencyRatingSettingTabValue, questionTypeMasterData, manageScaleListResponse } = useSelector(
		(state: any) => state?.adminSettingReducer
	);
	const { okrMasterDataStatus, okrMasterData } = useSelector((state: any) => state?.okrReducer);

	const maxLen = 12;
	const staticAssessMentFormData = {
		questionsType: 1,
	};
	const staticErrorObject = {
		error: '',
		helperText: '',
		type: '',
	};
	const staticEvaluationQuesData = [
		{
			listIndex: 1,
			questionsName: '',
			questionsType: 1,
		},
	];
	const staticFeedbackQuesData = [
		{
			listIndex: 1,
			questionsName: '',
			questionsType: 2,
		},
	];
	const refObject = useRef<any>(null);
	const refObjectAssessmentForm = useRef<any>(null);
	const defaultCustomScaleCreateData: {
		performanceScaleId: number | string;
		scaleType: string;
		scaleTypeId: number;
		scaleName: string;
		isScaleActive: boolean;
		defaultRatingScaleCount: number;
		maxRatingScaleCount: number;
		ratingScaleDetails: any;
	} = {
		performanceScaleId: Enums.ZERO,
		scaleType: t('starRating'),
		scaleTypeId: 1,
		scaleName: '',
		isScaleActive: true,
		defaultRatingScaleCount: 2,
		maxRatingScaleCount: 10,
		ratingScaleDetails: [
			{
				performanceScaleDetailId: Enums.ZERO,
				ratingScale: '',
				point: 0,
				scaleIndex: 1,
				isDisable: false,
				isError: false,
				errorMessage: '',
				isPointError: false,
				pointErrorMessage: '',
			},
			{
				performanceScaleDetailId: Enums.ZERO,
				ratingScale: '',
				point: 0,
				scaleIndex: 2,
				isDisable: false,
				isError: false,
				errorMessage: '',
				isPointError: false,
				pointErrorMessage: '',
			},
			{
				performanceScaleDetailId: Enums.ZERO,
				ratingScale: '',
				point: 0,
				scaleIndex: 3,
				isDisable: true,
				isError: false,
				errorMessage: '',
				isPointError: false,
				pointErrorMessage: '',
			},
		],
	};
	// const ref1 = useRef<any>(null);
	// const ref2 = useRef<any>(null);

	const [totalAssessmentListUser, setTotalAssessmentListUser] = useState(0);
	const [assessmentListPageIndex, setAssessmentListPageIndex] = useState<any>(0);
	const [assessmentListPageSize, setAssessmentListPageSize] = useState<any>(Enums.TEN);
	const [reviewFormList, setReviewFormList] = useState<any>(null);
	const [searchAssessmentListValue, setSearchAssessmentListValue] = useState<string>('');
	const [searchAssessmentListTextArray, setSearchAssessmentListTextArray] = useState<string>('');
	const [sortAssessmentListOrder, setSortAssessmentListOrder] = useState<string>('asc');
	const [sortingAssessmentListColumn, setSortingAssessmentListColumn] = useState<string>('');

	const [totalUser, setTotalUser] = useState(0);
	const [loader, setLoader] = useState<any>(false);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [performanceReviewList, setPerformanceReviewList] = useState<any>(null);
	const [listLoading, setListLoading] = useState<any>(null);
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [ratingFormLoader, setRatingFormLoader] = useState<any>(false);
	const [assessmentFormData, setAssessmentFormData] = useState<any>(staticAssessMentFormData);
	const [assessmentFormNameError, setAssessmentFormNameError] = useState<any>(staticErrorObject);
	const [assessmentEvaluationQuesError, setAssessmentEvaluationQuesError] = useState<any>(staticErrorObject);
	const [assessmentFeedbackQuesError, setAssessmentFeedbackQuesError] = useState<any>(staticErrorObject);
	const [evaluationQuestion, setEvaluationQuestion] = useState<any>(staticEvaluationQuesData);
	const [feedbackQuestion, setFeedbackQuestion] = useState<any>(staticFeedbackQuesData);
	const [selectedRatingForm, setSelectedRatingForm] = useState<any>(null);
	const [assessmentPreview, setAssessmentPreview] = useState<any>({ open: false, details: null });
	const [selectedTemplateDetails, setSelectedTemplateDetails] = useState<any>([]);
	const [selectedCoreValueTemplateDetails, setSelectedCoreValueTemplateDetails] = useState<any>([]);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [editableDetails, setEditableDetails] = useState<any>(null);
	const [searchValue, setSearchValue] = useState<string>('');
	const [isPerformanceFormEdited, setIsPerformanceFormEdited] = useState<boolean>(false);
	const [isAssessmentFormEdited, setIsAssessmentFormEdited] = useState<boolean>(false);
	const [modalPerformanceProps, setModalPerformanceProps] = useState<any>({ open: false, message: '', details: '' });
	const [openUserDrawer, setOpenUserDrawer] = useState<any>({ open: false, type: '' });
	const [performanceRatings, setPerformanceRatings] = useState<any>([]);
	const [openManageScaleDrawer, setOpenManageScaleDrawer] = useState<any>(false);
	const [manageScaleTabSelected, setManageScaleTabSelected] = useState<any>(0);
	const [customScaleDetails, setCustomScaleDetails] = useState(defaultCustomScaleCreateData);
	const [customScaleFormError, setCustomScaleFormError] = useState<any>({
		scaleName: false,
		errorMessage: '',
	});
	const [isCustomScaleEdited, setIsCustomScaleEdited] = useState(false);
	const [modalCustomScaleProps, setModalCustomScaleProps] = useState<any>({
		open: false,
		message: '',
		details: '',
		alertType: '',
	});
	const [sortManageScaleListOrder, setSortManageScaleListOrder] = useState<string>('asc');
	const [sortingManageScaleListColumn, setSortingManageScaleListColumn] = useState<string>('');
	const [manageScaleList, setManageScaleList] = useState<any>(null);
	const [customScaleType, setCustomScaleType] = useState<any>({ type: 'Add', scaleDetails: null });
	const [tabsCount, setTabsCount] = useState<any>(null);
	const [manageScaleListHighlightItemIndex, setManageScaleListHighlightItemIndex] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });
	const [assessmentFormSetting, setAssessmentFormSetting] = useState<any>({});
	const [highlightRecordId, setHighlightRecordId] = useState<any>(null);

	useEffect(() => {
		if (!settingDropdownSelected?.open) {
			setSelectedTemplateDetails([]);
			setSelectedCoreValueTemplateDetails([]);
			setAssessmentFormSetting({});
		} else if (
			settingDropdownSelected?.open &&
			(performanceReviewFormType === 'Edit' ||
				performanceReviewFormType === 'Clone' ||
				performanceReviewFormType === 'View')
		) {
			const backBtn = document.getElementById('back-btn');
			if (backBtn) {
				if (isPerformanceFormEdited) {
					setModalPerformanceProps({ open: true, message: t('unSavedItemAlert'), details: '' });
				} else {
					setPerformanceReviewFormType('Add');
					setEditableDetails(null);
					setSelectedTemplateDetails([]);
					setSelectedCoreValueTemplateDetails([]);
					setIsPerformanceFormEdited(false);
				}
			}
		}
		if (!Boolean(okrMasterData)) {
			dispatch(getOkrMasterData());
		}
		if (!questionTypeMasterData) {
			dispatch(getQuestionTypeMasterData());
		}
		getPerformanceTabsCount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [settingDropdownSelected]);

	useEffect(() => {
		if (okrMasterDataStatus === 'success') {
			setPerformanceRatings(okrMasterData.performanceRatings);
		}
	}, [okrMasterDataStatus]);

	useEffect(() => {
		if (assessmentTabSelected?.activeTab === 1) {
			const { editDetails, cloneDetails } = assessmentTabSelected;
			setAssessmentFormNameError(staticErrorObject);
			setAssessmentEvaluationQuesError(staticErrorObject);
			setAssessmentFeedbackQuesError(staticErrorObject);
			if (editDetails || cloneDetails) {
				const { name, formQuestionListRequest } = editDetails || cloneDetails || {};
				const evaluationList: any = [];
				const feedbackList: any = [];
				setAssessmentFormData({ ...assessmentFormData, formName: name || '', questionsType: 1 });
				formQuestionListRequest &&
					formQuestionListRequest.length > 0 &&
					formQuestionListRequest.forEach((record: any, index: number) => {
						if (record?.questionsType === 1) {
							evaluationList.push({ ...record, listIndex: index + 1 });
						}
						if (record?.questionsType === 2) {
							feedbackList.push({ ...record, listIndex: index + 1 });
						}
					});
				evaluationList && evaluationList.length > 0
					? setEvaluationQuestion([...evaluationList])
					: setEvaluationQuestion(staticEvaluationQuesData);
				feedbackList && feedbackList.length > 0
					? setFeedbackQuestion([...feedbackList])
					: setFeedbackQuestion(staticFeedbackQuesData);
			} else {
				setAssessmentFormData({ ...assessmentFormData, formName: '', questionsType: 1 });
				setEvaluationQuestion(staticEvaluationQuesData);
				setFeedbackQuestion(staticFeedbackQuesData);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessmentTabSelected]);

	useEffect(() => {
		if (tabSelected === 0 && checkSupportPermissionType(supportModulePerformAssessment)) {
			handleClickPerformReview();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabSelected]);

	/** OUT SIDE CLICK */
	// useEffect(() => {
	// 	console.log('OutSide ==>', openAssessmentForm, openUserDrawer);
	// 	document.addEventListener('click', handleClickOutside, true);
	// 	return () => {
	// 		document.removeEventListener('click', handleClickOutside, true);
	// 	};
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [isPerformanceFormEdited]);
	const handleClickOutside = (event: any) => {
		if (
			refObject.current &&
			!refObject.current?.contains(event.target) &&
			(checkUnSavePopUpVisible(event) || event.target.id === 'back-btn') &&
			isPerformanceFormEdited
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalPerformanceProps({ open: true, message: t('unSavedItemAlert'), details: '', event: event });
			}
		} else {
		}
	};

	const handleClickPerformReview = () => {
		setLoader(true);
		setPageIndex(1);
		getPerformanceReviewList({
			pageIndex: 1,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
			pageSize: pageSize,
		});
	};

	const getPerformanceReviewList = async (requestPayload: any) => {
		try {
			setListLoading(true);
			const response: any = await dispatch(getPerformanceCycleList(requestPayload));
			if (response) {
				!performanceReviewList && setPerformanceReviewList({ records: [], paggingInfo: {} });
				if (response && response.data && response.data.status === 200) {
					const { entity } = response.data;
					if (entity) {
						const { records } = entity || {};
						if (records && records.length) {
							const updatedData = { records: records };
							setPerformanceReviewList(updatedData);
							setTotalUser(entity && entity.totalRecords);
							setLocalStorageItem('performanceCycleDetails', JSON.stringify(records));
						} else {
							setPerformanceReviewList({ records: [] });
						}
					} else {
						setPerformanceReviewList({ records: [] });
					}
					setListLoading(false);
					setLoader(false);
					// if (totalPages > 1 && totalPages > pageIndex) {
					// 	setShowLoadMore(true);
					// } else {
					// 	setShowLoadMore(false);
					// }
				} else {
					setLoader(false);
					setListLoading(false);
					setPerformanceReviewList({ records: [] });
				}
			}
		} catch (e) {
			setLoader(false);
			console.error(e);
		}
	};

	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		setLoader(true);
		getPerformanceReviewList({
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			finder: searchTextArray,
			pageSize: pageSize,
		});
		setSortingColumn(column);
	};
	const handleChange = (e: any) => {
		setSearchValue(e.target.value);
	};
	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleSearch(e.target.value);
			}
			setSearchValue('');
		}
	};
	const handleSearch = (value: any) => {
		setPageIndex(1);
		setSearchTextArray(value);
		setLoader(true);
		getPerformanceReviewList({
			pageIndex: 1,
			sortingText: '',
			order: '',
			finder: value,
			pageSize: pageSize,
		});
	};
	const clearSearch = () => {
		setPageIndex(1);
		setSearchTextArray('');
		setLoader(true);
		getPerformanceReviewList({
			pageIndex: 1,
			sortingText: '',
			order: '',
			finder: '',
			pageSize: pageSize,
		});
	};

	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		setLoader(true);
		getPerformanceReviewList({
			pageIndex: newPage,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
			pageSize: pageSize,
		});
	};

	const handleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		setLoader(true);
		getPerformanceReviewList({
			pageIndex: 1,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
			pageSize: pSize,
		});
	};
	const handleManageReviewForm = (event: any) => {
		setAssessmentTabSelected({ activeTab: 0 });
		setOpenAssessmentForm({ open: true, ratingFormType: 'manage' });
	};
	const handleDrawerClose = (event: any, type: string) => {
		if (assessmentTabSelected?.activeTab === 1) {
			if (type === 'save' || type === 'draft') {
				handleSubmit(type);
			} else {
				setOpenAssessmentForm({ open: false });
			}
		} else if (openAssessmentForm?.ratingFormType === 'direct') {
			if (type === 'save') {
				const { performanceCompetencyRecord, record } = selectedRatingForm || {};
				if (performanceCompetencyRecord && performanceCompetencyRecord?.isRole) {
					const updatedSelectedTemplateDetails =
						selectedTemplateDetails && selectedTemplateDetails.length > 0
							? selectedTemplateDetails.map((item: any) => {
									if (item.performanceRoleId === performanceCompetencyRecord?.performanceRoleId) {
										item.ratingFormDetails = record;
									}
									return item;
							  })
							: selectedTemplateDetails;
					setSelectedTemplateDetails([...updatedSelectedTemplateDetails]);
				} else if (performanceCompetencyRecord && performanceCompetencyRecord?.isCore) {
					const updatedSelectedCoreValueTemplateDetails =
						selectedCoreValueTemplateDetails && selectedCoreValueTemplateDetails.length > 0
							? selectedCoreValueTemplateDetails.map((item: any) => {
									if (item.performanceRoleId === performanceCompetencyRecord?.performanceRoleId) {
										item.ratingFormDetails = record;
									}
									return item;
							  })
							: selectedCoreValueTemplateDetails;
					setSelectedCoreValueTemplateDetails([...updatedSelectedCoreValueTemplateDetails]);
				}
				setIsPerformanceFormEdited(true);
			}
			setOpenAssessmentForm({ open: false });
		} else {
			setOpenAssessmentForm({ open: false });
		}
	};
	const handlePerformanceReviewRoleTemplate = (e: any, record: any) => {
		const updatedSelectedTemplateDetails =
			selectedTemplateDetails && selectedTemplateDetails.length > 0
				? [...selectedTemplateDetails].filter((item: any) => item.performanceRoleId !== record.performanceRoleId)
				: [];
		let updatedSelectedTemplateDetailsN =
			selectedTemplateDetails && selectedTemplateDetails.length > 0
				? [...selectedTemplateDetails].filter((item: any) => item.performanceRoleId === record.performanceRoleId)
				: [record];
		updatedSelectedTemplateDetailsN =
			updatedSelectedTemplateDetailsN && updatedSelectedTemplateDetailsN.length > 0
				? updatedSelectedTemplateDetailsN
				: [record];
		const finalData =
			updatedSelectedTemplateDetails && updatedSelectedTemplateDetails.length > 0
				? [...updatedSelectedTemplateDetails, ...updatedSelectedTemplateDetailsN]
				: [...updatedSelectedTemplateDetails, record];
		setSelectedTemplateDetails([...finalData]);
		setOpenAssessmentForm({ open: true, ratingFormType: 'direct', record: { ...record, isRole: true } });
	};
	const handlePerformanceCoreValueTemplate = (e: any, record: any) => {
		const updatedSelectedCoreValueTemplateDetails =
			selectedCoreValueTemplateDetails && selectedCoreValueTemplateDetails.length > 0
				? selectedCoreValueTemplateDetails.filter((item: any) => item.performanceRoleId !== record.performanceRoleId)
				: [];
		let updatedSelectedCoreValueTemplateDetailsN =
			selectedCoreValueTemplateDetails && selectedCoreValueTemplateDetails.length > 0
				? selectedCoreValueTemplateDetails.filter((item: any) => item.performanceRoleId === record.performanceRoleId)
				: [record];
		updatedSelectedCoreValueTemplateDetailsN =
			updatedSelectedCoreValueTemplateDetailsN && updatedSelectedCoreValueTemplateDetailsN.length > 0
				? updatedSelectedCoreValueTemplateDetailsN
				: [record];
		const finalData =
			updatedSelectedCoreValueTemplateDetailsN && updatedSelectedCoreValueTemplateDetailsN.length > 0
				? [...updatedSelectedCoreValueTemplateDetails, ...updatedSelectedCoreValueTemplateDetailsN]
				: [...updatedSelectedCoreValueTemplateDetails, record];
		setSelectedCoreValueTemplateDetails([...finalData]);
		setOpenAssessmentForm({ open: true, ratingFormType: 'direct', record: { ...record, isCore: true } });
	};
	/** Start Assessment Form Related details */
	const handleSelectQuestionType = (type: number) => {
		setAssessmentFormData({ ...assessmentFormData, questionsType: type });
	};
	const handleAssessmentFormChange = (event: any) => {
		const { value, name } = event?.target;
		setAssessmentFormData({ ...assessmentFormData, [name]: value });
	};
	const validateEvaluationQuestion = () => {
		let count = 0;
		const updatedEvaluationQuestion =
			evaluationQuestion && evaluationQuestion.length > 0
				? evaluationQuestion.map((item: any) => {
						if (!item?.questionsName && !item?.questionsName.trim()) {
							count = count + 1;
							item.error = true;
							item.helperText = 'Question field cannot be left blank';
						} else {
							item.error = false;
							item.helperText = '';
						}
						return item;
				  })
				: evaluationQuestion;

		setEvaluationQuestion([...updatedEvaluationQuestion]);
		if (evaluationQuestion && evaluationQuestion.length === count) {
			setAssessmentEvaluationQuesError({
				error: 'Assessment Template should have atleast one rating Question',
				helperText: 'Assessment Template should have atleast one rating Question',
				type: 'evaluationQuestions',
			});
			return false;
		} else if (count !== 0) {
			return false;
		} else {
			return true;
		}
	};
	const validateFeedbackQuestion = () => {
		setAssessmentFeedbackQuesError({
			error: '',
			helperText: '',
			type: '',
		});

		return true;
	};
	const validateAssessmentForm = () => {
		if (!assessmentFormData?.formName) {
			setAssessmentFormNameError({
				error: 'Add a form name',
				helperText: 'Add a form name',
				type: 'formName',
			});
			return false;
		} else {
			return true;
		}
	};
	const handleSubmit = async (buttonType: string) => {
		if (validateAssessmentForm()) {
			setAssessmentFormNameError(staticErrorObject);
			if (validateEvaluationQuestion()) {
				setAssessmentEvaluationQuesError(staticErrorObject);
				if (validateFeedbackQuestion()) {
					setAssessmentFeedbackQuesError(staticErrorObject);
					setLoader(true);

					const { editDetails } = assessmentTabSelected;
					const finalQuestionsList = [...evaluationQuestion, ...feedbackQuestion];
					const updatedFinalQuestionsList = finalQuestionsList.map((rec: any) => {
						delete rec.listIndex;
						delete rec.error;
						delete rec.helperText;
						if (!editDetails && rec?.ratingFormDetailsId) {
							delete rec.ratingFormDetailsId;
						}
						rec.questionsName = rec.questionsName ? rec.questionsName.trim() : rec.questionsName;
						return rec;
					});
					const requestPayload = {
						formName: assessmentFormData?.formName,
						isPublish: buttonType === 'save' ? true : false,
						formQuestionCreateRequest: updatedFinalQuestionsList.filter((item) => item?.questionsName),
					};
					if (editDetails && editDetails?.ratingFormId) {
						const response: any = await dispatch(
							ratingFormUpdate({ ...requestPayload, ratingFormId: editDetails.ratingFormId })
						);
						createUpdateAPIResponse(response);
					} else {
						const response: any = await dispatch(ratingFormCreate(requestPayload));
						createUpdateAPIResponse(response);
					}
				}
			}
		}
	};
	const createUpdateAPIResponse = (response: any) => {
		if (response) {
			setLoader(false);
			if (response?.data && response?.data?.status === 200) {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || 'Rating form created successfully', {
					variant: 'success',
					autoHideDuration: 3000,
				});

				setAssessmentTabSelected({ activeTab: 0 });
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || 'Error occurred while adding assessment form', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		} else {
			setLoader(false);
		}
	};
	const handleSelectedManageRatingItem = (record: any, performanceCompetencyRecord: any) => {
		setSelectedRatingForm({ record: record || '', performanceCompetencyRecord: performanceCompetencyRecord || '' });
	};
	/** End Assessment Form Related details */
	const handlePreviewRatingFormClick = (e: any, details: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAssessmentPreview({ open: true, details: details?.ratingFormDetails || null });
	};
	const handlePerformNoTemplateClick = (e: any, details: any) => {
		e.preventDefault();
		e.stopPropagation();
	};
	const handleCloseDialog = () => {
		setAssessmentPreview({ open: false, details: null });
	};
	const handlePerformanceReviewForm = async (requestPayload: any, type: string) => {
		setLoader(true);
		const updatedRequestPayload = { ...requestPayload };
		let performanceRoleLinkedFormRequest: any = [];
		/* if (selectedTemplateDetails && selectedTemplateDetails.length > 0 && competencyRatingSettingTabValue === 0) {
			selectedTemplateDetails.forEach((item: any) => {
				performanceRoleLinkedFormRequest.push({
					performanceRoleLinkedFormId: item?.performanceRoleLinkedFormId || 0,
					performanceRoleId: item?.performanceRoleId || 0,
					ratingFormId: item?.ratingFormDetails?.ratingFormId || 0,
					ratingFormName: item?.ratingFormDetails?.name || '',
				});
			});
		} */
		/* if (
			selectedCoreValueTemplateDetails &&
			selectedCoreValueTemplateDetails.length > 0 &&
			competencyRatingSettingTabValue === 1
		) {
			selectedCoreValueTemplateDetails.forEach((item: any) => {
				performanceRoleLinkedFormRequest.push({
					performanceRoleLinkedFormId: item?.performanceRoleLinkedFormId || 0,
					performanceRoleId: item?.performanceRoleId || 0,
					ratingFormId: item?.ratingFormDetails?.ratingFormId || 0,
					ratingFormName: item?.ratingFormDetails?.name || '',
				});
			});
		}
		updatedRequestPayload.performanceRoleLinkedFormRequest = performanceRoleLinkedFormRequest; */
		//updatedRequestPayload.ratingFormType = competencyRatingSettingTabValue === 0 ? 1 : 2;
		let response: any = null;
		if (type === 'Edit') {
			if (updatedRequestPayload.status === 1) {
				response = await dispatch(updatePerformanceCycle(updatedRequestPayload));
			} else if (updatedRequestPayload.status === 4) {
				// const queryParams = `performanceCycleId=${updatedRequestPayload?.performanceCycleId || 0}`;
				// response = await dispatch(publishPerformanceCycle(updatedRequestPayload, queryParams));
				response = await dispatch(updatePerformanceCycle(updatedRequestPayload));
			}
		} else {
			response = await dispatch(addPerformanceCycle(updatedRequestPayload));
		}

		setLoader(false);
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				const { messageList, isSuccess } = response.data;
				if (isSuccess) {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(
						messages && messages.length > 0
							? messages
							: `Performance Review ${
									type === 'Edit' && updatedRequestPayload.status === 2
										? 'published'
										: type === 'Edit'
										? 'updated'
										: 'created'
							  } successfully`,
						{
							variant: 'success',
							autoHideDuration: 3000,
						}
					);

					/**
					 * Hide Create Performance Form and load updated list
					 */
					handleAddPerformanceReviewBtnClick({}, false, 'Add');
					setLoader(true);
					setPageIndex(1);
					setPerformanceReviewFormType('Add');
					getPerformanceReviewList({
						pageIndex: 1,
						sortingText: sortingColumn,
						order: sortOrder,
						finder: searchTextArray,
						pageSize: pageSize,
					});
				} else {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while adding performance review', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || 'Error occurred while adding performance review', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		}
	};
	const handleBackButtonClick = () => {
		setPerformanceReviewFormType('Add');
		setEditableDetails(null);
		setSelectedTemplateDetails([]);
		setSelectedCoreValueTemplateDetails([]);
		handleAddPerformanceReviewBtnClick({}, false, 'Add');
		handleClickPerformReview();
	};
	const handleBacAssessmentFormButtonClick = () => {
		setAssessmentFormType({ type: 'Add', details: null });
		// setEditableDetails(null);
		// setSelectedTemplateDetails([]);
		// setSelectedCoreValueTemplateDetails([]);
		handleAddAssessmentFormBtnClick({}, false, 'Add');
		// handleClickPerformReview();
		resetTabDetailsAndChange({}, 1);
	};
	const handleDeletePerformanceReviewForm = (
		performanceFormDataValue: any,
		editableDetailsValue: any,
		typeValue: any
	) => {
		if ((typeValue === 'Edit' || typeValue === 'View') && editableDetailsValue) {
			setModalProps({
				open: true,
				message: t('deleteConfirmationForAlignment', {
					type: 'performance review cycle',
				}),
				details: editableDetailsValue,
			});
		}
	};
	const handlePerformanceReviewRecordAction = (e: any, record: any, type: string) => {
		e.preventDefault();
		e.stopPropagation();
		// type === View || Edit || Clone || Delete
		if (type === 'Delete') {
			setModalProps({
				open: true,
				message: t('deleteConfirmationForAlignment', {
					type: 'performance review cycle',
				}),
				details: record,
			});
		} else if (type === 'Clone') {
			const updatedRecord = { ...record, name: '', endDate: null, startDate: null, reviewDate: null, status: 1 };
			performanceCycleExcludeEmployeeListDetails(updatedRecord, type);
		} else if (type === 'Edit') {
			performanceCycleExcludeEmployeeListDetails(record, type);
		} else if (type === 'View') {
			performanceCycleExcludeEmployeeListDetails(record, type);
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		// e.preventDefault();
		if (type === 1) {
			callDeleteRatingFormAPI();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const callDeleteRatingFormAPI = async () => {
		if (modalProps.details && modalProps.details.performanceCycleId) {
			setLoader(true);
			const queryParams = `performanceCycleId=${modalProps.details.performanceCycleId}`;
			setModalProps({ open: false, message: '', details: '' });
			const response: any = await dispatch(deletePerformanceCycle(queryParams));
			if (response) {
				if (response && response?.data && response.data.status === 200) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Performance review form deleted successfully', {
						variant: 'success',
						autoHideDuration: 3000,
					});

					if ((performanceReviewFormType === 'Edit' || performanceReviewFormType === 'View') && editableDetails) {
						handleAddPerformanceReviewBtnClick({}, false, performanceReviewFormType);
						setPerformanceReviewFormType('Add');
						setEditableDetails(null);
						setSelectedTemplateDetails([]);
						setSelectedCoreValueTemplateDetails([]);
						// handleAddPerformanceReviewBtnClick({}, false, 'Add');
						// handleClickPerformReview();
					}
					handleClickPerformReview();
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while deleting performance review record', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				setLoader(false);
			} else {
				setLoader(false);
			}
		}
	};

	const performanceCycleExcludeEmployeeListDetails = async (cycleDetails: any, type: string) => {
		try {
			setLoader(true);
			const requestPayload = {
				performanceCycleId: cycleDetails?.performanceCycleId || 0,
				isExcludedFromCycle: true,
				pageIndex: 1,
				pageSize: 9999999,
				sortingText: '',
				order: sortOrder || 'asc',
				finder: '',
			};
			const response: any = await dispatch(performanceCycleExcludeEmployeeList(requestPayload));
			if (response) {
				if (response?.data && response?.data?.status === 200) {
					const { records } = response?.data?.entity || {}; // pageIndex, pageSize, totalPages, totalRecords

					getAllLinkedFormDetails(cycleDetails, type, records);
				} else {
					// const { messageList } = response.data;
					// const keys = Object.keys(messageList);
					// const messages = keys.map((item) => messageList[item]);
					// enqueueSnackbar(messages || 'Error occurred while fetching detail', {
					// 	variant: 'error',
					// 	autoHideDuration: 3000,
					// });
					getAllLinkedFormDetails(cycleDetails, type, []);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const getAllLinkedFormDetails = async (cycleDetails: any, type: string, records: any) => {
		try {
			if (cycleDetails?.performanceRoleLinkedFormList && cycleDetails?.performanceRoleLinkedFormList?.length > 0) {
				const formIds = cycleDetails?.performanceRoleLinkedFormList.map(
					(item: any) => item.performanceRoleLinkedFormId
				);
				const requestPayload = {
					cycleStatus: cycleDetails?.status || 0,
					performanceRoleLinkedFormIds: formIds || [],
				};
				const response: any = await dispatch(performanceCycleLinkedForm(requestPayload));
				if (response) {
					if (response?.data && response?.data?.status === 200) {
						const { entityList } = response?.data || {};
						const linkedFormDetails: any = [];
						entityList &&
							entityList.length > 0 &&
							entityList.forEach((item: any) => {
								if (item?.performanceRoleLinkedFormId) {
									const rec =
										cycleDetails?.performanceRoleLinkedFormList &&
										cycleDetails?.performanceRoleLinkedFormList?.length > 0
											? cycleDetails?.performanceRoleLinkedFormList.find(
													(record: any) => record?.performanceRoleLinkedFormId === item?.performanceRoleLinkedFormId
											  )
											: null;
									const updatedData = {
										performanceRoleId: rec?.performanceRoleId || 0,
										performanceRoleName: rec?.performanceRoleName || '',
										employeeCount: item?.assesseesCount || 0,
										performanceRoleLinkedFormId: rec?.performanceRoleLinkedFormId || 0,
										ratingFormDetails: {
											selectedItem: true,
											isPublish: true,
											ratingFormId: rec?.ratingFormId,
											name: rec?.ratingFormName,
											formQuestionListRequest:
												item?.linkedFormDetailsResponse && item?.linkedFormDetailsResponse.length > 0
													? item?.linkedFormDetailsResponse.map((node: any) => {
															node.ratingFormDetailsId = node?.performanceRoleLinkedFormDetailsId || 0;
															delete node.performanceRoleLinkedFormDetailsId;
															return node;
													  })
													: [],
										},
									};
									linkedFormDetails.push(updatedData);
								}
							});
						updateOtherEditableDetails(cycleDetails, type, records, linkedFormDetails);
					} else {
						updateOtherEditableDetails(cycleDetails, type, records, []);
					}
				} else {
					updateOtherEditableDetails(cycleDetails, type, records, []);
				}
			} else {
				updateOtherEditableDetails(cycleDetails, type, records, []);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const updateOtherEditableDetails = (cycleDetails: any, type: string, records: any, ratingFormDetails: any) => {
		try {
			if (cycleDetails && cycleDetails?.performanceCycleId) {
				const tabDefaultValue = cycleDetails?.ratingFormType === 1 ? 0 : 1;
				// const tabDefaultValue =
				// 	cycleDetails?.performanceRoleLinkedFormList && cycleDetails?.performanceRoleLinkedFormList?.length > 0
				// 		? cycleDetails.performanceRoleLinkedFormList.find((item: any) => item.performanceRoleId === 0)
				// 		: null;
				cycleDetails.excludedUsersList = records || [];
				cycleDetails.excludedEmployeeId =
					records && records.length > 0 ? records.map((item: any) => item.employeeId) : [];

				if (ratingFormDetails && ratingFormDetails.length > 0) {
					if (tabDefaultValue) {
						// Core Values
						let filterData = [...ratingFormDetails];
						filterData = [...ratingFormDetails].filter((item: any) => item.performanceRoleId === 0);
						setSelectedCoreValueTemplateDetails(filterData);
					} else {
						// Performance Review Roles
						let filterData = [...ratingFormDetails];
						filterData = [...ratingFormDetails].filter((item: any) => item.performanceRoleId !== 0);
						setSelectedTemplateDetails([...filterData]);
					}
				}
				dispatch(updateCompetencyRatingSettingTabValue(tabDefaultValue ? 1 : 0));
				setEditableDetails(cycleDetails);
				setPerformanceReviewFormType(type);
				handleAddPerformanceReviewBtnClick({}, true, type);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalPerformanceProps?.event) {
				handleAddPerformanceReviewBtnClick({}, false, 'Add');
				setIsPerformanceFormEdited(false);
				handleBackButtonClick();

				/** OUT SIDE CLICK */
				// await setIsPerformanceFormEdited(false);
				// const { target } = modalPerformanceProps?.event || {};
				// const clonedNativeEvent = document.createEvent('HTMLEvents');
				// clonedNativeEvent.initEvent('click', true, false);
				// target.dispatchEvent(clonedNativeEvent);
			} else {
				setPerformanceReviewFormType('Add');
				setEditableDetails(null);
				setSelectedTemplateDetails([]);
				setSelectedCoreValueTemplateDetails([]);
				setIsPerformanceFormEdited(false);
			}

			setModalPerformanceProps({ open: false, message: '', details: '' });
		} else {
			setModalPerformanceProps({ open: false, message: '', details: '' });
		}
	};
	// ==============================================================================
	// ==============================================================================
	useEffect(() => {
		const requestParam = {
			pageIndex: Enums.ONE,
			pageSize: Enums.MAX_USER_SIZE,
			sortingText: '',
			order: 'asc',
		};
		getManageScaleListDetails(requestParam);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (manageScaleListResponse) {
			setManageScaleList(manageScaleListResponse?.length ? manageScaleListResponse : []);
			setLoader(false);
		}
	}, [manageScaleListResponse]);
	const getPerformanceTabsCount = async () => {
		try {
			const response: any = await dispatch(getPerformanceTabCount());
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data;
				setTabsCount(entity || null);
			} else {
				setTabsCount(null);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const sortManageScaleList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingManageScaleListColumn) {
			setSortManageScaleListOrder(sortManageScaleListOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortManageScaleListOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortManageScaleListOrder('desc');
		}

		const requestParam = {
			pageIndex: Enums.ONE,
			pageSize: Enums.MAX_USER_SIZE,
			sortingText: column,
			order: newSortOrder,
		};
		getManageScaleListDetails(requestParam);
		setSortingManageScaleListColumn(column);
	};
	const getManageScaleListDetails = async (requestParam: any) => {
		try {
			setLoader(true);
			dispatch(getManageScaleListWIthReduxState(requestParam));
			/*
			const response: any = await dispatch(getManageScaleList(requestParam));
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response?.data;
				const { records } = entity || {};
				setManageScaleList(records && records?.length ? records : []);
			} else {
				setManageScaleList([]);
			}
			setLoader(false);
			*/
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const [scaleDetailsFromAssessmentForm, setScaleDetailsFromAssessmentForm] = useState<any>(null);
	const handleManageScale = (event: any, callingFrom = '', scaleName = '', scaleType = 0) => {
		if (callingFrom === 'AssessmentForm') {
			setCustomScaleDetails({ ...defaultCustomScaleCreateData, scaleType: scaleName, scaleTypeId: scaleType });
			setScaleDetailsFromAssessmentForm({ callingFrom, scaleName, scaleType });
			setManageScaleTabSelected(Enums.ONE);
			setCustomScaleType({ type: 'Add', scaleDetails: null });
			setOpenManageScaleDrawer(true);
		} else {
			setScaleDetailsFromAssessmentForm(null);
			setManageScaleTabSelected(Enums.ZERO);
			setOpenManageScaleDrawer(true);
			if (tabSelected === Enums.ONE) {
				const requestParam = {
					pageIndex: Enums.ONE,
					pageSize: Enums.MAX_USER_SIZE,
					sortingText: sortingManageScaleListColumn,
					order: sortManageScaleListOrder,
				};
				getManageScaleListDetails(requestParam);
			}
		}
	};
	const handleScaleNameChange = (event: any) => {
		try {
			const { name, value } = event?.target || {};
			setCustomScaleDetails({ ...customScaleDetails, [name]: value });
			setIsCustomScaleEdited(true);
			setCustomScaleFormError({ scaleName: false, errorMessage: '' });
		} catch (e) {
			setCustomScaleDetails({ ...customScaleDetails, scaleName: '' });
			setIsCustomScaleEdited(true);
			setCustomScaleFormError({ scaleName: false, errorMessage: '' });
			console.error(e);
		}
	};
	const handleScaleActiveToggleClick = (event: any) => {
		try {
			const { name, checked } = event?.target || {};
			setCustomScaleDetails({ ...customScaleDetails, [name]: checked });
			setIsCustomScaleEdited(true);
		} catch (e) {
			setCustomScaleDetails({ ...customScaleDetails, isScaleActive: false });
			setIsCustomScaleEdited(true);
			console.error(e);
		}
	};
	const handleScaleTypeChange = (id = 1, name: '', scaleType: any) => {
		try {
			setCustomScaleDetails({ ...customScaleDetails, scaleType: name, scaleTypeId: id });
			setIsCustomScaleEdited(true);
		} catch (e) {
			setCustomScaleDetails({ ...customScaleDetails, scaleType: '', scaleTypeId: 1 });
			setIsCustomScaleEdited(true);
			console.error(e);
		}
	};
	const handleUpdateDragDropSequence = (updatedDetails: any) => {
		try {
			const copyRatingScaleDetails: any[] =
				updatedDetails && updatedDetails?.length ? [...updatedDetails] : customScaleDetails?.ratingScaleDetails;
			setCustomScaleDetails({ ...customScaleDetails, ratingScaleDetails: [...copyRatingScaleDetails] });
			setIsCustomScaleEdited(true);
		} catch (error) {}
	};
	const updateRatingScale = (scale: any, key: string, value: any) => {
		const { ratingScaleDetails } = customScaleDetails || {};
		let copyRatingScaleDetails = ratingScaleDetails && ratingScaleDetails?.length ? [...ratingScaleDetails] : [];
		copyRatingScaleDetails.map((rec: any) => {
			if (rec?.scaleIndex === scale?.scaleIndex) {
				rec[key] = value;
				rec.isDisable = false;
				rec.isError = false;
				rec.errorMessage = '';
				rec.isPointError = false;
				rec.pointErrorMessage = '';
			}
			return rec;
		});
		const lastRec =
			copyRatingScaleDetails && copyRatingScaleDetails?.length
				? copyRatingScaleDetails[copyRatingScaleDetails.length - 1]
				: null;
		if (scale && lastRec && lastRec.scaleIndex < Enums.TEN && scale?.scaleIndex === lastRec?.scaleIndex) {
			copyRatingScaleDetails.push({
				ratingScale: '',
				point: 0,
				scaleIndex: lastRec && lastRec?.scaleIndex ? lastRec.scaleIndex + 1 : 1,
				isDisable: true,
				isError: false,
				errorMessage: '',
				isPointError: false,
				pointErrorMessage: '',
			});
		}
		setCustomScaleDetails({ ...customScaleDetails, ratingScaleDetails: [...copyRatingScaleDetails] });
		setIsCustomScaleEdited(true);
	};
	const handleConfirmDeleteRatingScaleClick = (e: any, scale: any) => {
		const { ratingScaleDetails } = customScaleDetails || {};
		const copyRatingScaleDetails = ratingScaleDetails && ratingScaleDetails?.length ? [...ratingScaleDetails] : [];
		const updatedRatingScaleDetails = copyRatingScaleDetails
			.filter((rec: any) => rec?.scaleIndex !== scale?.scaleIndex)
			.map((rec: any, indexVal: number) => ({ ...rec, scaleIndex: indexVal + 1 }));

		const lastRec = updatedRatingScaleDetails[updatedRatingScaleDetails.length - 1];
		if (
			updatedRatingScaleDetails &&
			updatedRatingScaleDetails?.length &&
			updatedRatingScaleDetails?.length < customScaleDetails?.maxRatingScaleCount &&
			!lastRec?.isDisable
		) {
			updatedRatingScaleDetails.push({
				ratingScale: '',
				point: 0,
				scaleIndex: lastRec && lastRec?.scaleIndex ? lastRec.scaleIndex + 1 : 1,
				isDisable: true,
				isError: false,
				errorMessage: '',
				isPointError: false,
				pointErrorMessage: '',
			});
		}
		setCustomScaleDetails({ ...customScaleDetails, ratingScaleDetails: [...updatedRatingScaleDetails] });
	};
	const resetCustomScaleTabData = (tabIndex = 0, type = 1) => {
		if (isCustomScaleEdited) {
			setModalCustomScaleProps({
				open: true,
				message: t('unSavedItemAlert'),
				details: type,
				alertType: 'unSaveCustomScale',
			});
		} else {
			setCustomScaleDetails(defaultCustomScaleCreateData);
			setCustomScaleFormError({
				scaleName: false,
				errorMessage: '',
			});
			setManageScaleTabSelected(tabIndex);
			type === 1 && setOpenManageScaleDrawer(false);
			setIsCustomScaleEdited(false);
			if (type === 2) {
				setCustomScaleType({ type: 'Add', scaleDetails: null });
			}
		}
	};
	const checkRatingScale = () => {
		const { ratingScaleDetails } = customScaleDetails || {};
		const ratingScaleDetailsData = ratingScaleDetails && ratingScaleDetails?.length ? [...ratingScaleDetails] : [];
		let isErrorAvailable = ratingScaleDetailsData.find((rec: any) => rec.isError || rec.isPointError);
		let updatedRatingScaleDetailsData = ratingScaleDetailsData;
		let check = 0;
		if (!isErrorAvailable) {
			updatedRatingScaleDetailsData = ratingScaleDetailsData?.map((rating: any) => {
				const data = { ...rating };
				if ((!data?.ratingScale || (data?.ratingScale && data?.ratingScale?.trim() === '')) && !data?.isDisable) {
					data.isError = true;
					data.errorMessage = null;
				}
				if (data?.point === '' && !data?.isDisable) {
					data.isPointError = true;
					data.pointErrorMessage = null;
				}
				return data;
			});
			const firstErrorIndex = updatedRatingScaleDetailsData?.findIndex((rec: any) => rec?.isError);
			const firstPointErrorIndex = updatedRatingScaleDetailsData?.findIndex((rec: any) => rec?.isPointError);
			updatedRatingScaleDetailsData = updatedRatingScaleDetailsData?.map((rating: any, index: number) => {
				const data = { ...rating };
				if (data?.isError && index === firstErrorIndex) {
					data.errorMessage = 'Label description mandatory';
				}
				if (data?.isPointError && index === firstPointErrorIndex) {
					data.pointErrorMessage = 'Adding point is mandatory';
				}
				// Duplicate label description

				return data;
			});
			check = 1;
		}
		isErrorAvailable = updatedRatingScaleDetailsData.find((rec: any) => rec.isError || rec.isPointError);
		if (!isErrorAvailable || check === 0) {
			const duplicateNamesArray = findDuplicateNames(updatedRatingScaleDetailsData, 'ratingScale');
			if (duplicateNamesArray && duplicateNamesArray?.length) {
				const bds = new Set(duplicateNamesArray);
				updatedRatingScaleDetailsData = updatedRatingScaleDetailsData?.map((rating: any) => {
					const data = { ...rating };
					if (bds.has(data?.ratingScale) && !data?.isDisable) {
						data.isError = true;
						data.errorMessage = null;
					}
					return data;
				});
				const dupFirstErrorIndex = updatedRatingScaleDetailsData?.findIndex((rec: any) => rec?.isError);
				updatedRatingScaleDetailsData = updatedRatingScaleDetailsData?.map((rating: any, index: number) => {
					const data = { ...rating };
					if (data?.isError && index === dupFirstErrorIndex) {
						data.errorMessage = 'Duplicate label description';
					}

					return data;
				});
				isErrorAvailable = updatedRatingScaleDetailsData.find((rec: any) => rec.isError || rec.isPointError);
			} else {
				updatedRatingScaleDetailsData = updatedRatingScaleDetailsData?.map((rating: any) => {
					const data = { ...rating };
					if (!data?.isDisable) {
						data.isError = false;
						data.errorMessage = null;
					}
					return data;
				});
			}
		}
		setCustomScaleDetails({ ...customScaleDetails, ratingScaleDetails: [...updatedRatingScaleDetailsData] });

		return !Boolean(isErrorAvailable);
	};
	const validateCustomScaleForm = () => {
		if (!customScaleDetails?.scaleName) {
			setCustomScaleFormError({ scaleName: true, errorMessage: 'Scale name mandatory' });
			return false;
		} else if (customScaleDetails?.scaleName && customScaleDetails?.scaleName.trim() === '') {
			setCustomScaleFormError({ scaleName: true, errorMessage: 'Scale name mandatory' });
			return false;
		} else if (
			customScaleType?.type !== 'Edit' &&
			manageScaleList &&
			manageScaleList?.length &&
			manageScaleList?.find(
				(rec: any) =>
					rec?.scaleName?.trim() === customScaleDetails?.scaleName?.trim() &&
					rec?.performanceScaleId !== customScaleDetails?.performanceScaleId
			)
		) {
			setCustomScaleFormError({ scaleName: true, errorMessage: 'Scale name already exists' });
			return false;
		} else {
			return checkRatingScale();
		}
	};
	const handleSaveCustomScaleForm = async (event: any) => {
		try {
			if (validateCustomScaleForm()) {
				setLoader(true);
				setCustomScaleFormError({ scaleName: false, errorMessage: '' });
				const { scaleTypeId, scaleName, isScaleActive, ratingScaleDetails, performanceScaleId } =
					customScaleDetails || {};
				const performanceScaleDetailResponse: any[] = [];
				ratingScaleDetails &&
					ratingScaleDetails?.length &&
					ratingScaleDetails.forEach((element: any) => {
						if (!element?.isDisable && !element?.isError) {
							performanceScaleDetailResponse.push({
								performanceScaleDetailId: element?.performanceScaleDetailId || Enums.ZERO,
								name: element?.ratingScale ? element?.ratingScale?.trim() : '',
								weightage: element?.point ? parseInt(element?.point) : Enums.ZERO,
								sequence: element?.scaleIndex || Enums.ONE,
							});
						}
					});
				const requestPayload = {
					performanceScaleId: performanceScaleId || Enums.ZERO,
					questionsTypeId: scaleTypeId || Enums.ONE,
					name: scaleName ? scaleName.trim() : '',
					status: isScaleActive ? Enums.ONE : Enums.ZERO,
					performanceScaleDetailResponse,
				};

				let response: any = null; //await dispatch(addScaleDetails(requestPayload));
				if (customScaleType?.type === 'Add' || customScaleType?.type === 'Clone') {
					response = await dispatch(addScaleDetails(requestPayload));
				} else if (customScaleType?.type === 'Edit') {
					response = await dispatch(updateScaleDetails(requestPayload));
				}
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					if (scaleDetailsFromAssessmentForm && scaleDetailsFromAssessmentForm?.callingFrom === 'AssessmentForm') {
						setOpenManageScaleDrawer(false);
						setCustomScaleDetails(defaultCustomScaleCreateData);
						setManageScaleTabSelected(Enums.ZERO);
						setIsCustomScaleEdited(false);
						setSortManageScaleListOrder('asc');
						setSortingManageScaleListColumn('');
						const requestParam = {
							pageIndex: Enums.ONE,
							pageSize: Enums.MAX_USER_SIZE,
							sortingText: '',
							order: 'asc',
						};
						getManageScaleListDetails(requestParam);
					} else {
						if (customScaleType?.type === 'Add' || customScaleType?.type === 'Clone') {
							setManageScaleList(null);
							setManageScaleListHighlightItemIndex(true);
						}
						setTimeout(() => {
							// Reset Custom Scale form and close drawer
							setCustomScaleDetails(defaultCustomScaleCreateData);
							setManageScaleTabSelected(Enums.ZERO);
							setIsCustomScaleEdited(false);

							setSortManageScaleListOrder('asc');
							setSortingManageScaleListColumn('');
							const requestParam = {
								pageIndex: Enums.ONE,
								pageSize: Enums.MAX_USER_SIZE,
								sortingText: '',
								order: 'asc',
							};
							getManageScaleListDetails(requestParam);
						}, 1000);
					}
				} else {
					setLoader(false);
					const { messageList } = response?.data || {};
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Some error occurred', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleCustomScaleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			const { alertType, details } = modalCustomScaleProps;
			if (alertType === 'unSaveCustomScale') {
				setCustomScaleDetails(defaultCustomScaleCreateData);
				setCustomScaleFormError({
					scaleName: false,
					errorMessage: '',
				});
				setManageScaleTabSelected(0);
				details === 1 && setOpenManageScaleDrawer(false);
			}
			setIsCustomScaleEdited(false);
			setModalCustomScaleProps({ open: false, message: '', details: '', alertType: '' });
		} else {
			setModalCustomScaleProps({ open: false, message: '', details: '', alertType: '' });
		}
	};
	const convertScaleItem = (scaleItem: any, customScaleType: string) => {
		try {
			const ratingScaleDetails: any[] = [];
			scaleItem?.manageScaleDetailResponses &&
				scaleItem?.manageScaleDetailResponses?.length &&
				scaleItem.manageScaleDetailResponses.forEach((rec: any, indexVal: number) => {
					ratingScaleDetails.push({
						performanceScaleDetailId:
							customScaleType === 'Clone' ? Enums.ZERO : rec?.performanceScaleDetailId || Enums.ZERO,
						ratingScale: rec?.name || '',
						point: rec?.weightage || Enums.ZERO,
						scaleIndex: rec?.sequence || indexVal,
						isDisable: false,
						isError: false,
						errorMessage: '',
						isPointError: false,
						pointErrorMessage: '',
					});
				});
			const lastRec = ratingScaleDetails[ratingScaleDetails.length - 1];
			if (
				ratingScaleDetails &&
				ratingScaleDetails?.length &&
				ratingScaleDetails?.length < customScaleDetails?.maxRatingScaleCount &&
				!lastRec?.isDisable &&
				customScaleType !== 'View'
			) {
				ratingScaleDetails.push({
					performanceScaleDetailId: Enums.ZERO,
					ratingScale: '',
					point: 0,
					scaleIndex: lastRec && lastRec?.scaleIndex ? lastRec.scaleIndex + 1 : Enums.ONE,
					isDisable: true,
					isError: false,
					errorMessage: '',
					isPointError: false,
					pointErrorMessage: '',
				});
			}
			const convertedObject = {
				...customScaleDetails,
				performanceScaleId: customScaleType === 'Clone' ? Enums.ZERO : scaleItem?.performanceScaleId || Enums.ZERO,
				scaleName: customScaleType === 'Clone' ? `cloned ${scaleItem?.scaleName || ''}` : scaleItem?.scaleName || '',
				scaleType: scaleItem?.questionType || customScaleDetails?.scaleType,
				scaleTypeId: scaleItem?.questionTypeId || customScaleDetails?.scaleTypeId,
				isScaleActive: scaleItem?.status === Enums.ONE ? true : false,
				ratingScaleDetails,
			};

			return convertedObject;
		} catch (error) {
			console.error(error);
			return customScaleDetails;
		}
	};
	const handleScaleEditFromListClick = (e: any, scaleItem: any) => {
		try {
			const convertedObject = convertScaleItem(scaleItem, 'Edit');
			setCustomScaleDetails({ ...convertedObject });
			setCustomScaleType({ type: 'Edit', scaleDetails: scaleItem });
			setManageScaleTabSelected(Enums.ONE);
		} catch (error) {
			console.error(error);
		}
	};
	const handleScaleCloneFromListClick = (e: any, scaleItem: any) => {
		try {
			const convertedObject = convertScaleItem(scaleItem, 'Clone');
			setCustomScaleDetails({ ...convertedObject });
			setCustomScaleType({ type: 'Clone', scaleDetails: scaleItem });
			setManageScaleTabSelected(Enums.ONE);
		} catch (error) {
			console.error(error);
		}
	};
	const handleScaleViewFromListClick = (e: any, scaleItem: any) => {
		try {
			const convertedObject = convertScaleItem(scaleItem, 'View');
			setCustomScaleDetails({ ...convertedObject });
			setCustomScaleType({ type: 'View', scaleDetails: scaleItem });
			setManageScaleTabSelected(Enums.ONE);
		} catch (error) {
			console.error(error);
		}
	};
	const handleScaleToggleUpdateFromListClick = async (e: any, scaleItem: any) => {
		try {
			setLoader(true);
			const { checked } = e?.target || {};
			const status = checked ? 1 : 0;
			const queryParams = `performanceScaleId=${scaleItem?.performanceScaleId}&status=${status}`;
			const response: any = await dispatch(updateManageScaleStatus({}, queryParams));
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const copyManageScaleList = manageScaleList && manageScaleList?.length ? [...manageScaleList] : [];
				const updatedManageScaleList = copyManageScaleList?.map((rec: any) => {
					if (rec?.performanceScaleId === scaleItem?.performanceScaleId) {
						rec.status = status;
					}
					return rec;
				});
				setManageScaleList(updatedManageScaleList);
				// const requestParam = {
				// 	pageIndex: Enums.ONE,
				// 	pageSize: Enums.MAX_USER_SIZE,
				// 	sortingText: sortingManageScaleListColumn,
				// 	order: sortManageScaleListOrder,
				// };
				// getManageScaleListDetails(requestParam);
			} else {
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleScaleDeleteFromListClick = async (e: any, scaleItem: any) => {
		try {
			setOpenDeleteDialog({ open: true, details: { scaleItem } });
		} catch (error) {
			console.error(error);
		}
	};
	const handleDialogCancelClick = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { scaleItem } = openDeleteDialog?.details || {};
			if (scaleItem) {
				setOpenDeleteDialog({ open: false, details: null });
				setLoader(true);
				const queryParams = `performanceScaleId=${scaleItem?.performanceScaleId}`;
				const response: any = await dispatch(deleteManageScale(queryParams));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const copyManageScaleList = manageScaleList && manageScaleList?.length ? [...manageScaleList] : [];
					const updatedManageScaleList = copyManageScaleList?.filter(
						(rec: any) => rec?.performanceScaleId !== scaleItem?.performanceScaleId
					);
					setManageScaleList(updatedManageScaleList);
				} else {
				}
				setLoader(false);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};
	// ==============================================================================
	// ===============================================================================
	const getRatingFormListDetails = async (requestPayload: any) => {
		setLoader(true);
		const response: any = await dispatch(ratingFormList(requestPayload));
		if (response) {
			!reviewFormList && setReviewFormList({ records: [] });
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				const { records } = entity;
				const updatedData = { records: records };
				setReviewFormList(updatedData);
				setTotalAssessmentListUser(entity && entity.totalRecords);
				setLoader(false);
			} else {
				const updatedData = { records: [] };
				setTotalAssessmentListUser(0);
				setReviewFormList(updatedData);
				setLoader(false);
			}
		}
		setLoader(false);
	};
	const handleAssessmentListChangePage = (event: any, newPage: number) => {
		const publishType = openAssessmentForm?.ratingFormType === 'direct' ? 2 : 1;
		newPage = newPage + 1;
		setAssessmentListPageIndex(newPage);
		getRatingFormListDetails({
			publishType: publishType || 1,
			finder: searchAssessmentListTextArray,
			pageIndex: newPage,
			sortingText: sortingAssessmentListColumn,
			order: sortAssessmentListOrder,
			pageSize: assessmentListPageSize,
		});
	};

	const handleAssessmentListChangeRowsPerPage = async (event: any) => {
		const publishType = openAssessmentForm?.ratingFormType === 'direct' ? 2 : 1;
		const pSize = event.target.value;
		setAssessmentListPageSize(pSize);
		setAssessmentListPageIndex(1);
		getRatingFormListDetails({
			publishType: publishType || 1,
			finder: searchAssessmentListTextArray,
			pageIndex: 1,
			sortingText: sortingAssessmentListColumn,
			order: sortAssessmentListOrder,
			pageSize: pSize,
		});
	};

	const handleAssessmentListChange = (e: any) => {
		setSearchAssessmentListValue(e.target.value);
	};
	const searchAssessmentListClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleAssessmentListSearch(e.target.value);
			}
			setSearchAssessmentListValue('');
		}
	};
	const handleAssessmentListSearch = (value: any) => {
		const publishType = openAssessmentForm?.ratingFormType === 'direct' ? 2 : 1;
		setAssessmentListPageIndex(1);
		setSearchAssessmentListTextArray(value);
		setLoader(true);
		getRatingFormListDetails({
			publishType: publishType || 1,
			pageIndex: 1,
			sortingText: '',
			order: '',
			finder: value,
			pageSize: assessmentListPageSize,
		});
	};
	const clearAssessmentListSearch = () => {
		const publishType = openAssessmentForm?.ratingFormType === 'direct' ? 2 : 1;
		setAssessmentListPageIndex(1);
		setSearchAssessmentListTextArray('');
		setLoader(true);
		getRatingFormListDetails({
			publishType: publishType || 1,
			pageIndex: 1,
			sortingText: '',
			order: '',
			finder: '',
			pageSize: assessmentListPageSize,
		});
	};
	const resetTabDetailsAndChange = (event: any, newValue: Number) => {
		setSearchTextArray('');
		setPageIndex(1);
		setPageSize(Enums.TEN);
		setSortOrder('asc');

		setSearchAssessmentListTextArray('');
		setAssessmentListPageIndex(1);
		setAssessmentListPageSize(Enums.TEN);
		setSortAssessmentListOrder('asc');
		// setTotalAssessmentListUser(0);
		// setReviewFormList(null);

		handleTabChange(event, newValue);
	};
	//////////
	const handleDrawerAssessmentClose = (event: any, type: string) => {
		setOpenAssessmentSettingForm({ open: false });
	};
	return checkSupportPermissionType(supportModulePerformAssessment) ? (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			{!settingDropdownSelected?.open && !settingAssessmentFormSelected?.open ? (
				<Box className='performance-assessment-settings'>
					<Box className='admin-tabpanel-inner'>
						<Box className='admin-tabpanel-head'>
							<Box className='admin-tabpanel-head-left'>
								<Tabs
									className='okr-tab-button'
									value={tabSelected}
									onChange={resetTabDetailsAndChange}
									aria-label='Admin Tabs'
								>
									{checkSupportPermissionType(supportModulePerformAssessment) ? (
										<Tab
											icon={<PerformanceIcon />}
											label={`${t('performanceCyclesLevel')} (${tabsCount?.performanceCycleCount || 0})`}
											value={0}
											{...a11yProps(0)}
										/>
									) : (
										<></>
									)}
									<Tab
										icon={<AssessmentFormsIcon />}
										label={`${t('assessmentForms')} (${tabsCount?.assessmentFormsCount || 0})`}
										value={1}
										{...a11yProps(1)}
									/>
								</Tabs>
								{tabSelected === 1 ? (
									<Box className='setting-header-actions'>
										{/* {checkSupportPermissionType(supportModulePerformAssessment) ? (
										<>
											<OKRButton
												handleClick={handleManageReviewForm}
												className='btn-link'
												text={t('mngRatingForm')}
												icon={<FormIcon />}
											/>
										</>
									) : (
										<></>
									)} */}
										<OKRButton
											className='btn-link'
											text={t('manageScale')}
											icon={<ScaleIcon />}
											handleClick={handleManageScale}
										/>
									</Box>
								) : (
									<></>
								)}
							</Box>
							{tabSelected === 0 ? (
								<Box className='admin-tabpanel-head-right'>
									<Box>
										{totalUser ? (
											<TablePagination
												component='div'
												className='user-list-pagination'
												count={totalUser}
												page={pageIndex > 1 ? pageIndex - 1 : 0}
												onPageChange={handleChangePage}
												rowsPerPage={pageSize}
												onRowsPerPageChange={handleChangeRowsPerPage}
												SelectProps={{
													MenuProps: {
														PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
													},
												}}
											/>
										) : (
											<></>
										)}
									</Box>
									<Box className='user-search'>
										<TextField
											className='search-text-field'
											id='user-src-field'
											placeholder={t('searchText')}
											fullWidth
											value={searchValue}
											onChange={(e) => handleChange(e)}
											onKeyDown={(e) => searchClicked(e)}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<SearchIcon />
													</InputAdornment>
												),
											}}
										/>
										{searchTextArray && (
											<Box className='user-search-chip'>
												<List>
													<ListItem>
														{searchTextArray.length > 20 ? (
															<Tooltip title={searchTextArray} arrow>
																<Chip
																	label={searchTextArray.substring(0, 20) + '...'}
																	onDelete={clearSearch}
																	deleteIcon={
																		<span>
																			<CrossIcon />
																		</span>
																	}
																/>
															</Tooltip>
														) : (
															<Chip
																label={searchTextArray}
																onDelete={clearSearch}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														)}
													</ListItem>
												</List>
											</Box>
										)}
									</Box>
								</Box>
							) : tabSelected === 1 ? (
								<Box className='admin-tabpanel-head-right'>
									<Box>
										{totalAssessmentListUser ? (
											<TablePagination
												component='div'
												className='user-list-pagination'
												count={totalAssessmentListUser}
												page={assessmentListPageIndex > 1 ? assessmentListPageIndex - 1 : 0}
												onPageChange={handleAssessmentListChangePage}
												rowsPerPage={assessmentListPageSize}
												onRowsPerPageChange={handleAssessmentListChangeRowsPerPage}
												SelectProps={{
													MenuProps: {
														PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
													},
												}}
											/>
										) : (
											<></>
										)}
									</Box>
									<Box className='user-search'>
										<TextField
											className='search-text-field'
											id='user-src-field'
											placeholder={t('searchText')}
											fullWidth
											value={searchAssessmentListValue}
											onChange={(e) => handleAssessmentListChange(e)}
											onKeyDown={(e) => searchAssessmentListClicked(e)}
											InputProps={{
												startAdornment: (
													<InputAdornment position='start'>
														<SearchIcon />
													</InputAdornment>
												),
											}}
										/>
										{searchAssessmentListTextArray && (
											<Box className='user-search-chip'>
												<List>
													<ListItem>
														{searchAssessmentListTextArray.length > 20 ? (
															<Tooltip title={searchAssessmentListTextArray} arrow>
																<Chip
																	label={searchAssessmentListTextArray.substring(0, 20) + '...'}
																	onDelete={clearAssessmentListSearch}
																	deleteIcon={
																		<span>
																			<CrossIcon />
																		</span>
																	}
																/>
															</Tooltip>
														) : (
															<Chip
																label={searchAssessmentListTextArray}
																onDelete={clearAssessmentListSearch}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														)}
													</ListItem>
												</List>
											</Box>
										)}
									</Box>
								</Box>
							) : (
								<></>
							)}
						</Box>
						<SettingsTabPanel value={tabSelected} index={0}>
							<PerformanceCyclesList
								performanceReviewList={performanceReviewList}
								handlePerformanceReviewRecordAction={handlePerformanceReviewRecordAction}
								sortUserList={sortUserList}
								sortingColumn={sortingColumn}
								sortOrder={sortOrder}
								maxLen={maxLen}
								listLoading={listLoading}
							/>
						</SettingsTabPanel>
						<SettingsTabPanel value={tabSelected} index={1}>
							<AssessmentFormsList
								setLoader={setLoader}
								setAssessmentTabSelected={setAssessmentTabSelected}
								handleSelectedManageRatingItem={handleSelectedManageRatingItem}
								setAssessmentPreview={setAssessmentPreview}
								totalAssessmentListUser={totalAssessmentListUser}
								setTotalAssessmentListUser={setTotalAssessmentListUser}
								assessmentListPageIndex={assessmentListPageIndex}
								setAssessmentListPageIndex={setAssessmentListPageIndex}
								assessmentListPageSize={assessmentListPageSize}
								setAssessmentListPageSize={setAssessmentListPageSize}
								publishType={openAssessmentForm?.ratingFormType === 'direct' ? 2 : 1}
								reviewFormList={reviewFormList}
								setReviewFormList={setReviewFormList}
								searchAssessmentListTextArray={searchAssessmentListTextArray}
								setSearchAssessmentListTextArray={setSearchAssessmentListTextArray}
								sortAssessmentListOrder={sortAssessmentListOrder}
								setSortAssessmentListOrder={setSortAssessmentListOrder}
								sortingAssessmentListColumn={sortingAssessmentListColumn}
								setSortingAssessmentListColumn={setSortingAssessmentListColumn}
								getRatingFormListDetails={getRatingFormListDetails}
								handleAddAssessmentFormBtnClick={handleAddAssessmentFormBtnClick}
								setAssessmentFormType={setAssessmentFormType}
								setHighlightRecordId={setHighlightRecordId}
								highlightRecordId={highlightRecordId}
							/>
						</SettingsTabPanel>
					</Box>
				</Box>
			) : settingDropdownSelected?.open ? (
				<AddPerformanceReview
					{...props}
					loader={loader}
					setLoader={setLoader}
					type={performanceReviewFormType || 'Add'}
					selectedTemplateDetails={selectedTemplateDetails}
					selectedCoreValueTemplateDetails={selectedCoreValueTemplateDetails}
					setSelectedTemplateDetails={setSelectedTemplateDetails}
					setSelectedCoreValueTemplateDetails={setSelectedCoreValueTemplateDetails}
					handlePerformanceReviewRoleTemplate={handlePerformanceReviewRoleTemplate}
					handlePerformanceCoreValueTemplate={handlePerformanceCoreValueTemplate}
					handleManageReviewForm={handleManageReviewForm}
					handlePreviewRatingFormClick={handlePreviewRatingFormClick}
					handlePerformNoTemplateClick={handlePerformNoTemplateClick}
					handlePerformanceReviewForm={handlePerformanceReviewForm}
					handleBackButtonClick={handleBackButtonClick}
					editableDetails={editableDetails}
					handleDeletePerformanceReviewForm={handleDeletePerformanceReviewForm}
					performanceReviewList={performanceReviewList.records}
					isPerformanceFormEdited={isPerformanceFormEdited}
					setIsPerformanceFormEdited={setIsPerformanceFormEdited}
					refObject={refObject}
					openUserDrawer={openUserDrawer}
					setOpenUserDrawer={setOpenUserDrawer}
					openAssessmentSettingForm={openAssessmentSettingForm}
					setOpenAssessmentSettingForm={setOpenAssessmentSettingForm}
					assessmentFormSetting={assessmentFormSetting}
					setAssessmentFormSetting={setAssessmentFormSetting}
				/>
			) : settingAssessmentFormSelected?.open ? (
				<AddAssessmentForm
					{...props}
					loader={loader}
					setLoader={setLoader}
					type={assessmentFormType?.type || 'Add'}
					editableFormDetails={assessmentFormType?.details || null}
					isAssessmentFormEdited={isAssessmentFormEdited}
					setIsAssessmentFormEdited={setIsAssessmentFormEdited}
					refObjectAssessmentForm={refObjectAssessmentForm}
					handleBacAssessmentFormButtonClick={handleBacAssessmentFormButtonClick}
					questionTypeMasterData={questionTypeMasterData}
					getManageScaleListDetails={getManageScaleListDetails}
					manageScaleList={manageScaleList}
					handleManageScale={handleManageScale}
					setHighlightRecordId={setHighlightRecordId}
				/>
			) : (
				<></>
			)}
			{openAssessmentForm && openAssessmentForm?.open && (
				<MainDrawer
					open={openAssessmentForm?.open}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={
						openAssessmentForm?.ratingFormType === 'direct'
							? t('selectAssessmentTemplateLevel')
							: t('assessmentFormLevel')
					}
					loader={ratingFormLoader}
					children={
						<Box className='drawer-inner-content'>
							<AssessmentFormsDrawer
								{...props}
								setLoader={setRatingFormLoader}
								setAssessmentTabSelected={setAssessmentTabSelected}
								assessmentTabSelected={assessmentTabSelected}
								openAssessmentForm={openAssessmentForm}
								assessmentFormData={assessmentFormData}
								setAssessmentFormData={setAssessmentFormData}
								handleAssessmentFormChange={handleAssessmentFormChange}
								assessmentFormNameError={assessmentFormNameError}
								assessmentEvaluationQuesError={assessmentEvaluationQuesError}
								setAssessmentEvaluationQuesError={setAssessmentEvaluationQuesError}
								assessmentFeedbackQuesError={assessmentFeedbackQuesError}
								handleSelectQuestionType={handleSelectQuestionType}
								evaluationQuestion={evaluationQuestion}
								setEvaluationQuestion={setEvaluationQuestion}
								feedbackQuestion={feedbackQuestion}
								setFeedbackQuestion={setFeedbackQuestion}
								handleSelectedManageRatingItem={handleSelectedManageRatingItem}
								editDetails={assessmentTabSelected?.editDetails || null}
								cloneDetails={assessmentTabSelected?.cloneDetails || null}
								assessmentPreview={assessmentPreview}
								setAssessmentPreview={setAssessmentPreview}
							/>
						</Box>
					}
					saveButtonText={
						openAssessmentForm?.ratingFormType !== 'direct'
							? assessmentTabSelected?.editDetails && assessmentTabSelected?.editDetails?.isPublish
								? t('saveBtn')
								: t('publishLabel')
							: 'Done'
					}
					rightCloseBtn={false}
					isSaveDraft={
						assessmentTabSelected?.activeTab === 1
							? assessmentTabSelected?.editDetails && assessmentTabSelected?.editDetails?.isPublish
								? false
								: true
							: false
					}
					isSaveButtonVisible={
						assessmentTabSelected?.activeTab === 0 && openAssessmentForm?.ratingFormType !== 'direct' ? false : true
					}
					// isFooterVisible={
					// 	assessmentTabSelected?.activeTab === 0 && openAssessmentForm?.ratingFormType !== 'direct' ? false : true
					// }
					// drawerClassName={
					// 	assessmentTabSelected?.activeTab === 0 && openAssessmentForm?.ratingFormType !== 'direct'
					// 		? 'main-drawer-panel main-drawer-no-footer'
					// 		: 'main-drawer-panel'
					// }
					isSaveButtonDisabled={loader}
					isDraftButtonDisabled={loader}
					handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
					handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
					handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
					handleDraftButtonClick={(event: any) => handleDrawerClose(event, 'draft')}
				/>
			)}
			{openManageScaleDrawer && (
				<ManageScale
					{...props}
					loader={loader}
					customScaleType={customScaleType?.type || 'Add'}
					scaleDetails={customScaleType?.scaleDetails || null}
					questionTypeMasterData={questionTypeMasterData}
					setOpenManageScaleDrawer={setOpenManageScaleDrawer}
					openManageScaleDrawer={openManageScaleDrawer}
					setManageScaleTabSelected={setManageScaleTabSelected}
					manageScaleTabSelected={manageScaleTabSelected}
					customScaleDetails={customScaleDetails}
					setCustomScaleDetails={setCustomScaleDetails}
					handleScaleNameChange={handleScaleNameChange}
					handleScaleActiveToggleClick={handleScaleActiveToggleClick}
					customScaleFormError={customScaleFormError}
					setCustomScaleFormError={setCustomScaleFormError}
					handleScaleTypeChange={handleScaleTypeChange}
					updateRatingScale={updateRatingScale}
					handleConfirmDeleteRatingScaleClick={handleConfirmDeleteRatingScaleClick}
					resetCustomScaleTabData={resetCustomScaleTabData}
					isCustomScaleEdited={isCustomScaleEdited}
					setIsCustomScaleEdited={setIsCustomScaleEdited}
					handleSaveCustomScaleForm={handleSaveCustomScaleForm}
					manageScaleList={manageScaleList}
					setManageScaleList={setManageScaleList}
					sortManageScaleListOrder={sortManageScaleListOrder}
					sortingManageScaleListColumn={sortingManageScaleListColumn}
					sortManageScaleList={sortManageScaleList}
					handleScaleToggleUpdateFromListClick={handleScaleToggleUpdateFromListClick}
					handleScaleDeleteFromListClick={handleScaleDeleteFromListClick}
					handleScaleEditFromListClick={handleScaleEditFromListClick}
					handleScaleCloneFromListClick={handleScaleCloneFromListClick}
					handleScaleViewFromListClick={handleScaleViewFromListClick}
					manageScaleListHighlightItemIndex={manageScaleListHighlightItemIndex}
					setManageScaleListHighlightItemIndex={setManageScaleListHighlightItemIndex}
					handleUpdateDragDropSequence={handleUpdateDragDropSequence}
					scaleDetailsFromAssessmentForm={scaleDetailsFromAssessmentForm}
					setScaleDetailsFromAssessmentForm={setScaleDetailsFromAssessmentForm}
				/>
			)}
			{openAssessmentSettingForm && openAssessmentSettingForm?.open && (
				<AssessmentFormsSettingMainDrawer
					{...props}
					assessmentSettingTabSelected={assessmentSettingTabSelected}
					setAssessmentSettingTabSelected={setAssessmentSettingTabSelected}
					openAssessmentSettingForm={openAssessmentSettingForm}
					setOpenAssessmentSettingForm={setOpenAssessmentSettingForm}
					assessmentFormSetting={assessmentFormSetting}
					setAssessmentFormSetting={setAssessmentFormSetting}
					setIsPerformanceFormEdited={setIsPerformanceFormEdited}
				/>
			)}
			{assessmentPreview && assessmentPreview?.open ? (
				<AssessmentPreviewDialog
					assessmentPreview={assessmentPreview}
					handleCloseDialog={handleCloseDialog}
					performanceRatings={performanceRatings}
				/>
			) : (
				<></>
			)}
			{/* {modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)} */}
			{/* Delete user dialog */}
			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					headingText={t('deletePerformanceCycle')}
					subHeading={t('deletePerformanceCycleSubHead')}
				/>
			)}
			{modalPerformanceProps && modalPerformanceProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalPerformanceProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalPerformanceProps.open}
				/>
			)}
			{modalCustomScaleProps && modalCustomScaleProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalCustomScaleProps?.message || ''}
					handleCloseModal={handleCustomScaleCloseModal}
					modalOpen={modalCustomScaleProps.open}
				/>
			)}

			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('deleteRatingScale')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</>
	) : (
		<></>
	);
};
