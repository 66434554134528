import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import CkEditor from '../../../../Common/CkEditor/CkEditor';
import { EditIcon } from '../../../../../config/svg/CommonSvgIcon';
import { updatedPerformancePlanEmailConf } from '../../../../../action/adminPerformanceDevelopment';
import { useDispatch } from 'react-redux';
import { Enums } from '../../../../../config/enums';
import { CancelIcon } from '../../../../../config/svg/Action';

export const PipEmailSettings: React.FC<any> = (props: any) => {
	const { t, loader, setLoader, performancePlanEmailConfDetails, showMsg, emailSAubmitData } = props;
	const dispatch = useDispatch();

	const [isSubjectEdited, setIsSubjectEdited] = useState<boolean>(false);
	const [emailFormDetails, setEmailFormDetails] = useState<any>(null);
	const [errorDetails, setErrorDetails] = useState<any>({
		isSubjectError: '',
		isMiddleBodyError: '',
	});

	useEffect(() => {
		if (performancePlanEmailConfDetails) {
			setEmailFormDetails(performancePlanEmailConfDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [performancePlanEmailConfDetails]);

	const handleToggleSubjectEdit = () => {
		setIsSubjectEdited(true);
	};
	const handleToggleSubjectCancel = () => {
		setIsSubjectEdited(false);
	};
	const handleSubjectChange = (e: any) => {
		const { name, value } = e?.target || {};
		setEmailFormDetails({ ...emailFormDetails, [name]: value });
	};
	const handleMiddleBodyChange = (key: string, value: any) => {
		setEmailFormDetails({ ...emailFormDetails, [key]: value });
	};
	const handleEmailCancelClick = () => {
		setEmailFormDetails(performancePlanEmailConfDetails);
		setIsSubjectEdited(false);
	};
	const handleEmailSubmitClick = async (e: any) => {
		try {
			if (!emailFormDetails?.subject || (emailFormDetails?.subject && emailFormDetails.subject.trim() !== '')) {
				if (
					!emailFormDetails?.bodyMiddle ||
					(emailFormDetails?.bodyMiddle && emailFormDetails.bodyMiddle.trim() !== '')
				) {
					setLoader(true);
					const requestPayload = {
						subject: emailFormDetails?.subject,
						bodyMiddle: emailFormDetails?.bodyMiddle,
					};
					const response: any = await dispatch(updatedPerformancePlanEmailConf(requestPayload));
					if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
						emailSAubmitData();
						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('success', messages || `Email for nominee initiated successfully.`);

						handleEmailCancelClick();
						setLoader(false);
					} else {
						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('error', messages || `Some error occurred`);
						setLoader(false);
					}
				} else {
					setErrorDetails({ isMiddleBodyError: 'Please enter email content.', isSubjectError: '' });
				}
			} else {
				setErrorDetails({ isMiddleBodyError: '', isSubjectError: 'Please enter subject details.' });
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Box className='performance-development-card full-rectangular-card pip-email-settings'>
			<Box className='pd-card-header'>
				<Typography variant='h4' className='font-weight-normal'>
					{t('pipEmailSettingsLabel')}
				</Typography>
				<Typography variant='h6'>
					Note: This is actual representation of email sent to the nominee on PIP creation, any changes made to the
					editable part in this configuration will reflect in the actual email sent to the nominee, once saved.
				</Typography>
			</Box>
			<Box className='pip-email-content'>
				<Typography variant='h5'>From: Unlock</Typography>
				<Typography variant='h5'>To: 'Nominee name'</Typography>
				<Box className='pip-email-subject-field'>
					{isSubjectEdited ? (
						<Box className='pip-email-subject-in-edit'>
							<Typography variant='h5'>Subject: </Typography>
							<TextField
								className='resolution-text-field'
								name={'subject'}
								placeholder={emailFormDetails?.subject || t('pipEmailSubjectLabel')}
								onChange={handleSubjectChange}
							/>
							{errorDetails?.isSubjectError ? <Typography>{errorDetails?.isSubjectError || ''}</Typography> : <></>}
						</Box>
					) : (
						<Box className='pip-email-subject-after-edit'>
							<Typography variant='h5'>{`Subject: ${
								emailFormDetails?.subject || 'A Performance Improvement Plan has been created for you'
							}`}</Typography>
						</Box>
					)}

					{!isSubjectEdited ? (
						<OKRButton
							handleClick={handleToggleSubjectEdit}
							className='edit-btn'
							title={t('editLabel')}
							icon={<EditIcon />}
						/>
					) : (
						<OKRButton
							handleClick={handleToggleSubjectCancel}
							className='cancel-btn'
							title={t('cancelBtn')}
							icon={<CancelIcon />}
						/>
					)}
				</Box>

				{emailFormDetails?.bodyHeader ? (
					<Box className='pip-email-body-part1'>
						<Box
							dangerouslySetInnerHTML={{
								__html: emailFormDetails?.bodyHeader || '',
							}}
						></Box>
					</Box>
				) : (
					<></>
				)}

				{/* <Box className='pip-email-pad-bottom'>
					<Typography>Hi 'Nominee name',</Typography>
				</Box>
				<Box className='pip-email-pad-bottom'>
					<Typography>
						A Performance Improvement Plan has been created for you by initiator "Initiator name" which starts on "
						Start date" and ends on "End date".
					</Typography>
				</Box>
				<Box className='pip-email-pad-bottom'>
					<Typography>Details of the PIP are:</Typography>
				</Box>
				<Box className='pip-email-pad-bottom'>
					<Typography>Title: 'Title'</Typography>
					<Typography>Summary: 'Summary'</Typography>
					<Typography>
						To view other details like Areas of Improvement, Tasks (if added), Linked Feedbacks (if added), Documents
						(if added), Reviewer names, etc., you can login to the application
					</Typography>
					<OKRButton className='btn-primary view-plan-btn' text='View Plan' disabled />
				</Box>
				<Box className='pip-email-pad-bottom'>
					<Typography>
						The PIP process also includes reviews to be conducted. Here are the review dates: Jan 1 - 2024, Jan 2 -
						2024, Jan 3 - 2024, Jan 4 - 2024
					</Typography>
				</Box>
				<Box className='pip-email-pad-bottom'>
					<Typography>
						Please be available during these dates and share the progress you made with all the reviewers.
					</Typography>
					<Typography>To add these dates to your calendar, click on the attached ICS files.</Typography>

					<Typography>If dates are updated or added, you will receive separate mails accordingly.</Typography>
				</Box> */}
				<Box className='pip-email-body-part2'>
					<CkEditor
						placeHolderText={t('areaOfConcernPlaceholder')}
						showEmoticons={true}
						value={emailFormDetails?.bodyMiddle || ''}
						handleEditorChange={(value: string) => {
							if (emailFormDetails?.bodyMiddle !== value) {
								handleMiddleBodyChange('bodyMiddle', value || '');
							}
						}}
					/>
					{errorDetails?.isMiddleBodyError ? <Typography>{errorDetails?.isMiddleBodyError || ''}</Typography> : <></>}
				</Box>

				{/* {emailFormDetails?.bodyMiddle ? (
					<Box className='pip-email-body-part3'>
						<Box
							dangerouslySetInnerHTML={{
								__html: emailFormDetails?.bodyMiddle || '',
							}}
						></Box>
					</Box>
				) : (
					<></>
				)} */}
				{/* <Typography>Regards,</Typography>
				<Typography>Team Unlock:U</Typography> */}
				{emailFormDetails?.bodyFooter ? (
					<Box className='pip-email-footer'>
						<Box
							dangerouslySetInnerHTML={{
								__html: emailFormDetails?.bodyFooter || '',
							}}
						></Box>
					</Box>
				) : (
					<></>
				)}
			</Box>
			<Box className='pd-setting-card-actions'>
				<Box className='pd-setting-card-actions-left'></Box>
				<Box className='pd-setting-card-actions-right'>
					<OKRButton
						className='btn-link'
						text={t('cancelBtn')}
						disabled={loader}
						handleClick={handleEmailCancelClick}
					/>
					<OKRButton
						className='btn-primary'
						text={t('saveBtn')}
						disabled={loader}
						handleClick={handleEmailSubmitClick}
					/>
				</Box>
			</Box>
		</Box>
	);
};
