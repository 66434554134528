import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, FormControl, Input, InputAdornment, Button, FormHelperText } from '@material-ui/core';
import Logo from '../../../images/okr-logo.svg';
import { ProfileIcon } from '../../../config/svg/CommonSvg';
import RecoverImg from '../../../images/recover-pass-img.svg';
import { Link } from 'react-router-dom';
import { getQuarterStartEnd } from '../../../config/utils';
import { useSnackbar } from 'notistack';
import { SECRET_LOGIN } from '../../../config/app-url';
import '../../../styles/pages/login/login.scss';
import { ClientLogo } from '../../Common/ClientLogo';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { EMAIL_REGEX } from '../../../config/constant';

export default function ForgotPassword(props) {
	let { currentYear } = getQuarterStartEnd();
	const { enqueueSnackbar } = useSnackbar();
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const [formData, setFormData] = React.useState({
		emailId: '',
		formValid: false,
		isEmailValid: false,
		messageList: {},
	});
	const { t } = useTranslation();

	const handleChange = (prop) => (event) => {
		setIsFormSubmit(false);
		const value = event.target.value;
		if (prop === 'emailId') {
			let emlValid = value.match(EMAIL_REGEX) ? true : false;
			setFormData({
				...formData,
				emailId: value.trim(),
				isEmailValid: emlValid,
				formValid: value.trim() && emlValid,
			});
		} else {
			setFormData({ ...formData, [prop]: event.target.value });
		}
	};

	const submitForm = (e) => {
		e.preventDefault();
		setIsFormSubmit(true);
		if (formData.formValid) {
			let data = {
				emailId: formData.emailId,
			};
			props.forgotPasswordAction(data);
		}
	};

	useEffect(() => {
		const responseAPI = props.messageList;
		const keys = Object.keys(responseAPI);
		const messages = keys.map((item) => responseAPI[item]);
		if (props.forgotPasswordStatus === 200) {
			enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
			props.history.push(SECRET_LOGIN);
		} else if (props.forgotPasswordStatus === 400) {
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
		}
		props.updateStatus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.forgotPasswordStatus]);

	return (
		<Fragment>
			<section className='sign-in-area'>
				<div className='login-screen'>
					<div className='inner'>
						<div className='img-inner-wrapper'>
							<ClientLogo className='client-logo' />
							<AnimatedIcon
								className='login-img'
								width='420'
								type='image/svg+xml'
								alt='RecoverImg'
								imagePath={RecoverImg}
							/>
						</div>
					</div>
					<div className='inner login-form-area'>
						<div className='login-inner-wrapper recovery-password'>
							<img src={Logo} className='logo' alt='' />
							<Typography variant='h2' className='welcomeText'>
								{t('recoverPassword')}
							</Typography>
							<Typography className='welcomeSubText'>{t('emailRecoverPassword')}</Typography>
							<form className='login-inner-form recover-form' onSubmit={submitForm}>
								<FormControl variant='outlined' style={{ width: '100%' }} error={isFormSubmit && !formData.emailId}>
									<Input
										id='input-with-icon-adornment'
										placeholder={t('ForgotPasswordEmail')}
										value={formData.emailId}
										onChange={handleChange('emailId')}
										endAdornment={
											<InputAdornment position='end'>
												<ProfileIcon />
												{/* <img src={UserIcon} alt='' /> */}
											</InputAdornment>
										}
									/>
									{isFormSubmit && !formData.emailId && <FormHelperText>{t('emailRequired')}</FormHelperText>}
									{isFormSubmit && formData.emailId && !formData.isEmailValid && (
										<FormHelperText>{t('emailInvalid')}</FormHelperText>
									)}
								</FormControl>

								<Button type='submit' className='btn-primary login-btn'>
									{t('send')}
								</Button>
							</form>
							<div className='signup-content reset-link'>
								<Typography>
									{t('backTo')} <Link to='/'>{t('login')}</Link>{' '}
								</Typography>
							</div>

							<div className='footer-content'>
								<Typography variant='body2'>
									&copy; {currentYear} {t('infoproRights')}
									{' | '}
									<Link target='_blank' to='/privacy-policy'>
										{t('privacyPolicy')}
									</Link>
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
}
