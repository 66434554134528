import { Box, Typography, Tooltip } from '@material-ui/core';
import React from 'react';
import { ViewFeedback } from '../../../config/svg/PersonalFeedbackSvg';
import { UserAvatarGroup } from '../../Admin/CommonComponent/UserAvatarGroup';

export const FeedbackTile = (props: any) => {
	const {
		tileIcon,
		tileScore,
		tileTitle,
		tileSubHead,
		tileScoreLeft,
		directReporteeList = null,
		tileSecondIcon,
		tooltipLeftIcon,
		tooltipRightIcon,
	} = props;

	return (
		<Box className='feedback-states-area '>
			<Box className={`feedback-state-box`}>
				<Box className='title-box'>
					<Typography variant='h2' className='feedback-state-title'>
						<Tooltip title={tooltipLeftIcon || ''} arrow>
							<span>{tileIcon || <ViewFeedback />}</span>
						</Tooltip>

						{tileScore}
					</Typography>
					{tileScoreLeft ? (
						<Typography variant='h2' className='feedback-state-title'>
							<Tooltip title={tooltipRightIcon || ''} arrow>
								<span>{tileSecondIcon || <ViewFeedback />}</span>
							</Tooltip>
							{tileScoreLeft}
						</Typography>
					) : (
						<></>
					)}
				</Box>

				{tileTitle ? <Typography variant='h5'>{tileTitle || ''}</Typography> : <></>}
				{tileSubHead ? (
					<Typography variant='h6' className='font-weight-normal'>
						{tileSubHead || ''}
					</Typography>
				) : (
					<></>
				)}
				{directReporteeList && directReporteeList?.length ? (
					<>
						<UserAvatarGroup
							assignedUserDetails={directReporteeList}
							isUserPlaceholder={true}
							subTitle={' '}
							helpText={' '}
							max={6}
							handleAddUserClick={() => {}}
							isOnHoverVisible={true}
							isUserAvatarClick={false}
							handleUserAvatarClick={() => {}}
							isDisabled={true}
							isAddUserButtonHide={true}
							isHoverListTitleVisible={false}
							isShowSecondaryText={false}
						/>
					</>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
};
