import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControlLabel, Switch, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, DummyIcon, NameRenameIcon } from '../../../../../config/svg/CommonSvgIcon';
import { EnumPerformanceGoalCycleStatus } from '../../../../../config/enums';
import { HeaderTooltip } from '../../../../Reports/HeaderTooltip';
import { getOkrMasterData } from '../../../../../action/myGoal';
import { useDispatch, useSelector } from 'react-redux';

export const AssignUsersTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`assign-user-${index}`}
			aria-labelledby={`assign-user-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	);
};

AssignUsersTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `assign-user-tabpanel-${index}`,
		'aria-controls': `assign-user-tabpanel-${index}`,
	};
}

export const AssignUsersTab: React.FC<any> = (props) => {
	const {
		handleAssignUsersTabChange = {},
		selectedAssignUsersTab = 0,
		handleUsersSave,
		handleAllToggleCheck,
		handleUsersCancel,
		setSelectedUsersOptions,
		disableToggle,
		goalCycleData,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [toggle, setToggle] = useState<boolean>(false);
	const [totalUserCount, setTotalUserCount] = useState<number>(0);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	useEffect(() => {
		dispatch(getOkrMasterData());
		setTotalUserCount(okrMasterData?.totalUserCount || 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setToggle(goalCycleData.allUsersChecked);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleData.allUsersChecked]);

	const handleIsAllEmployees = async (e: any, isChecked = false) => {
		try {
			// e.preventDefault();
			// e.stopPropagation();
			setToggle(isChecked);
			handleAllToggleCheck(isChecked);
		} catch (error) {
			console.error(error);
			setSelectedUsersOptions([]);
		}
	};

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	return (
		<>
			<Tabs
				className='okr-tab-button okr-tab-white-button'
				value={selectedAssignUsersTab}
				onChange={handleAssignUsersTabChange}
				aria-label='Assign user tabs'
			>
				<Tab
					icon={<NameRenameIcon />}
					label={t('byName')}
					value={0}
					{...a11yProps(0)}
					disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
				/>
				{/* <Tab icon={<DummyIcon />} label={t('byGoalForm')} value={1} {...a11yProps(1)} /> */}
				{/* <Tab
					icon={<CalendarIcon />}
					label={t('byGoalsRoles')}
					value={2}
					{...a11yProps(2)}
					disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
				/> */}
				{/* 	<Tab
					icon={<DummyIcon />}
					label={t('assignBulkUserLabel')}
					value={3}
					{...a11yProps(3)}
					disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
				/> */}
			</Tabs>
			<Box className='so-setting-switch switch-button'>
				<FormControlLabel
					control={<Switch name='isAllEmployees' checked={toggle === true ? true : false} />}
					label={`${t('fetchAllUsersLevel')} ${
						goalCycleData?.goalForm?.assigedUsersCount ? '(' + goalCycleData?.goalForm?.assigedUsersCount + ')' : ''
					}`}
					className='toggle-label'
					checked={goalCycleData.allUsersChecked === true ? true : false}
					disabled={disableToggle || !goalCycleData.goalFormCycleId || goalCycleData.status === 4}
					onChange={(e: any) => {
						handleIsAllEmployees(e, e.target.checked);
					}}
				/>
				<HeaderTooltip infoTooltipClass='all-users-info-tooltip' tooltipText={t('fetchAllUsersInfoLevel')} />
			</Box>
		</>
	);
};
