import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { CategoryColoredIcon } from '../../../config/svg/MyGoalSvg';
import { ReviewConversations } from './ReviewConversations';
import { useSelector } from 'react-redux';
import LinearLoader from '../../Common/Loader';
import { getMonthDateYear, getUserName } from '../../../config/utils';
import { CalendarIcon } from '../../../config/svg/CommonSvgIcon';
import { FinalRatingLog } from './FinalRatingLog';

export const ReviewSettingsTabPanel: React.FC<any> = (props: any) => {
	const { drUserData, children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`category-settings-tab-${index}`}
			aria-labelledby={`category-settings-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

ReviewSettingsTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `category-settings-tabpanel-${index}`,
		'aria-controls': `category-settings-tabpanel-${index}`,
	};
}

export const ReviewSettingsDrawer: React.FC<any> = (props) => {
	const { t, handleTabChange = {}, tabSelected = 0, loader, setLoader, kr } = props;
	const [goalDocumentCount, setGoalDocumentCount] = useState<number>(0);
	const { goalAttachmentCont } = useSelector((state: any) => state?.adminSettingReducer);
	useEffect(() => {
		//setGoalDocumentCount(props.kr.documentCount);
	}, []);
	return (
		<>
			<Box className='review-for-info'>
				<Box className='user-info v-center'>
					<Box className='user-img'>
						<Avatar
							className='avatar-default'
							key={`user-icon-${kr.usersDetails.employeeId}`}
							src={kr.usersDetails.imagePath || ''}
						>
							{getUserName({
								firstName: kr.usersDetails.firstName || '',
								lastName: kr.usersDetails.lastName || '',
								fullName: kr.usersDetails.firstName + ' ' + kr.usersDetails.lastName || '',
							})}
						</Avatar>
					</Box>
					<Box className='user-info-details'>
						<Tooltip arrow title={kr.usersDetails.firstName + ' ' + kr.usersDetails.lastName}>
							<Typography>{kr.usersDetails.firstName + ' ' + kr.usersDetails.lastName || ''}</Typography>
						</Tooltip>
					</Box>
				</Box>
				<Box className='review-period'>
					<CalendarIcon />
					{getMonthDateYear(kr.teamReviewUserListResponse?.startDate) +
						' - ' +
						getMonthDateYear(kr.teamReviewUserListResponse?.endDate)}
				</Box>
			</Box>

			<Tabs className='okr-tabs drawer-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab className='arrow-tab' label={t('conversationLabel')} value={0} {...a11yProps(0)} />
				<Tab className='arrow-tab' label={t('finalRatingLogLabel')} value={1} {...a11yProps(1)} />
			</Tabs>
			{loader && <LinearLoader />}
			<Box className={`drawer-tabs-panel`}>
				<ReviewSettingsTabPanel value={tabSelected} index={0}>
					<ReviewConversations {...props} tabSelected={tabSelected} kr={kr} />
				</ReviewSettingsTabPanel>
				<ReviewSettingsTabPanel value={tabSelected} index={1}>
					<FinalRatingLog {...props} tabSelected={tabSelected} />
				</ReviewSettingsTabPanel>
			</Box>
		</>
	);
};
