import React, { useState } from 'react';
import { Box, ClickAwayListener, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { DownArrowIcon, SortingUpArrow, SortingDownArrow, UpArrowIcon } from '../../../config/svg/ArrowSvg';
import { SortingButtonIcon } from '../../../config/svg/CommonSvgIcon';
import { useSelector } from 'react-redux';

export const ProgressSorting: React.FC<any> = (props: any) => {
	const { handleSortingFilterClick } = props;
	const { peopleProgressFilters } = useSelector((state: any) => state?.okrReducer);

	const { t } = useTranslation();
	const [openSorting, setOpenSorting] = React.useState<Boolean>(false);

	const handleClick = () => {
		setOpenSorting((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpenSorting(false);
	};

	return (
		<Box className={`okr-team-dropdown sorting-dropdown ${openSorting ? 'active' : ''} `}>
			<OKRButton
				className={`task-sorting-btn ${openSorting ? 'active' : ''}`}
				icon={<SortingButtonIcon />}
				text={
					<span className='filter-txt'>
						{t('sortingLabel')}
						{openSorting ? <UpArrowIcon /> : <DownArrowIcon />}
					</span>
				}
				handleClick={handleClick}
				id='task-sorting-btn'
			/>
			{openSorting && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='task-sorting-list'>
						<Box className='task-sorting-list-inner'>
							<List component='div' disablePadding>
								<ListItem
									component='div'
									className={`sorting-due-on ${peopleProgressFilters?.filterDetails?.sortingText === 'progress' ? 'active' : ''}`}
									onClick={(e) => {
										handleClickAway();
										handleSortingFilterClick(
											'progress',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 'desc' : 'asc',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 1 : 2
										);
									}}
								>
									<ListItemText primary={t('Progress')} />
									<ListItemIcon>
										{peopleProgressFilters?.filterDetails?.sortingText === '' || peopleProgressFilters?.filterDetails?.sortingText === 'progress' ? (
											<>
												{peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? (
													<SortingDownArrow />
												) : (
													<SortingUpArrow />
												)}
											</>
										) : (
											<SortingDownArrow />
										)}
									</ListItemIcon>
								</ListItem>
								<ListItem
									component='div'
									className={`sorting-created-on ${peopleProgressFilters?.filterDetails?.sortingText === 'lastProgressDate' ? 'active' : ''}`}
									onClick={(e) => {
										handleClickAway();
										handleSortingFilterClick(
											'lastProgressDate',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 'desc' : 'asc',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 3 : 4
										);
									}}
								>
									<ListItemText primary={t('lastProgressDate')} />
									<ListItemIcon>
										{peopleProgressFilters?.filterDetails?.sortingText === '' || peopleProgressFilters?.filterDetails?.sortingText === 'lastProgressDate' ? (
											<>
												{peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? (
													<SortingDownArrow />
												) : (
													<SortingUpArrow />
												)}
											</>
										) : (
											<SortingDownArrow />
										)}
									</ListItemIcon>
								</ListItem>
								<ListItem
									component='div'
									className={`sorting-created-on ${peopleProgressFilters?.filterDetails?.sortingText === 'aToZ' ? 'active' : ''}`}
									onClick={(e) => {
										handleClickAway();
										handleSortingFilterClick(
											'aToZ',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 'desc' : 'asc',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 5 : 6
										);
									}}
								>
									<ListItemText primary={t('aToZLevel')} />
									<ListItemIcon>
										{peopleProgressFilters?.filterDetails?.sortingText === '' || peopleProgressFilters?.filterDetails?.sortingText === 'aToZ' ? (
											<>
												{peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? (
													<SortingDownArrow />
												) : (
													<SortingUpArrow />
												)}
											</>
										) : (
											<SortingDownArrow />
										)}
									</ListItemIcon>
								</ListItem>
								{/*<ListItem
									component='div'
									className={`sorting-created-on ${
										peopleProgressFilters?.filterDetails?.sortingText === 'lastProgressDate2' ? 'active' : ''
									}`}
									onClick={(e) => {
										handleClickAway();
										handleSortingFilterClick(
											'lastProgressDate2',
											peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '' ? 'desc' : 'asc',
											4
										);
									}}
								>
									<ListItemText primary={t('zToALevel')} />
									<ListItemIcon>
										{peopleProgressFilters?.filterDetails?.sortingText === 'lastProgressDate2' && (peopleProgressFilters?.filterDetails?.order === 'asc' || peopleProgressFilters?.filterDetails?.order === '') ? (
											<SortingUpArrow />
										) : (
											<SortingDownArrow />
										)}
									</ListItemIcon>
								</ListItem>*/}
							</List>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
