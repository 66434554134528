/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../styles/pages/reports/orgreport.scss';
import HeatChart from '../Charts/HeatChart';
import { getLocalStorageItem } from '../../../services/StorageService';
import { HeaderTooltip } from '../HeaderTooltip';
import { useSnackbar } from 'notistack';
import { ReportsGaugeChart } from '../HighchartReports/ReportsGaugeChart';
import { Enums } from '../../../config/enums';

const OrgReports: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const [orgData, setOrgData] = useState<any>(null);
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		props.getOrgReportsDetails('cycleId=' + cycleId);
	}, []);

	useEffect(() => {
		setOrgData(props.orgReport);
	}, [props.orgReport]);
	useEffect(() => {
		if (props.orgReportStatus === 'failed') {
			enqueueSnackbar(t('internalServerError'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	}, [props.orgReportStatus]);

	return (
		<>
			<Box className='reports-head'>
				<Box className='reports-head-left'>
					<Typography variant='h2'>{t('orgReportTitle')}</Typography>
					<HeaderTooltip tooltipText={t('orgProgressReportTooltipText')} btnId={'org-report-tooltip'} />
				</Box>
			</Box>
			{props.orgReportStatus === 'pending' || props.orgReportStatus === 'failed' || !orgData ? (
				<Box className='reports-card col-full'>
					<Box className='org-loading'>
						<Box textAlign='center'>
							<Typography>{t('loading')}</Typography>
						</Box>
					</Box>
				</Box>
			) : (
				<Box className='reports-card col-full'>
					<Box className='report-col col-one-third'>
						<Box className='org-radial-report'>
							<Typography variant='h4'>{t('teamsAvgProgressLabel')}</Typography>
							<ReportsGaugeChart
								isOrgReport={true}
								progress={orgData?.entity?.teamProgress}
								memberCount={orgData?.entity?.memberCount}
								okrCount={orgData?.entity?.okrCount}
								krCount={orgData?.entity?.krCount}
								opacity={0.5}
								radialCount={Enums.ONE}
								colors={['#E3E0AF']}
								labels={['Performance']}
								chartHight={'140'}
								paneBackgroundColor={'rgba(244, 241, 248, 0.5)'}
							/>
						</Box>
					</Box>
					<Box className='report-col col-two-thirds'>
						<HeatChart t={t} heatData={orgData?.entity?.teamProgressHeats} />
					</Box>
				</Box>
			)}
		</>
	);
};

export default OrgReports;
