import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { PipBlock } from './PipBlock';

export const PipMyList = (props: any) => {
	const { performanceData, setPerformanceData, handlePIPItemClick, headingLabel, showLoadMore, loadMore } = props;
	const { t } = useTranslation();
	return (
		<Box className='pip-main-content-section'>
			<PipBlock
				performanceData={performanceData}
				setPerformanceData={setPerformanceData}
				handlePIPItemClick={handlePIPItemClick}
				showLoadMore={showLoadMore}
				loadMore={loadMore}
			/>
		</Box>
	);
};
