import React, { useEffect, useState } from 'react';
import { Avatar, Box, Chip, ClickAwayListener, Paper, Typography } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { ErrorIcon, LeaderIcon } from '../../../config/svg/formElementIcons';
import { SearchUserPopover } from '../../Common/SearchUserPopover';
import { SearchUsers } from '../../Common/SearchUsers';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';
import { getUserName } from '../../../config/utils';

export const QuickSearchPopover: React.FC<any> = (props) => {
	const { onSelectedUser, leader, error } = props;
	const [addUserAnchorEl, setAddUserAnchorEl] = useState<HTMLElement | null>(null);
	const [addUserOpen, setAddUserOpen] = useState<boolean>(false);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const { t } = useTranslation();

	const handleAddUsersClick = (event: any) => {
		setAddUserAnchorEl(addUserAnchorEl ? null : event.currentTarget);
		setAddUserOpen(true);
		const element = document.getElementById('leader-btn');
		if (element) {
			//element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
		}
	};
	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const handleClose = (event: any) => {
		setAddUserAnchorEl(null);
		setAddUserOpen(false);
	};
	return (
		<>
			<Box className='leader-detail user-quick-search-type1'>
				{leader && leader.employeeId && (leader?.firstName || leader?.fullName) ? (
					<Box>
						<Chip
							avatar={
								<Avatar src={leader.imagePath}>
									{getUserName({
										firstName: leader.firstName || '',
										lastName: leader.lastName || '',
										fullName: leader.label || leader.fullName || '',
									})}
								</Avatar>
							}
							label={leader.fullName ? leader.fullName : `${leader.firstName} ${leader.lastName}`}
							className='chip-avatar'
							deleteIcon={
								<span>
									<CrossIcon />
								</span>
							}
							onDelete={() => {
								onSelectedUser(
									{
										firstName: '',
										lastName: '',
										employeeId: '',
										imagePath: '',
									},
									'delete'
								);
							}}
						/>
					</Box>
				) : (
					<OKRButton
						id='leader-btn'
						className={`btn-link ${addUserOpen ? 'active' : ''}`}
						icon={<LeaderIcon />}
						text={'Leader'}
						handleClick={(e) => handleAddUsersClick(e)}
					/>
				)}
				{addUserOpen && (
					<ClickAwayListener onClickAway={handleClose}>
						<Box className='user-quick-search-popover'>
							<Box className='connnector-icon'></Box>
							<Box className='search-user-inner'>
								<SearchUsers
									module={'myGoal'}
									{...props}
									onSelectedUser={(user: any) => {
										onSelectedUser(user[0], 'update');
										setAddUserOpen(false);
									}}
									addUserInRole={true}
									defaultFocus={true}
									onInputChange={handleSearchUserInputChange}
									placeHolderText={t('searchLeader')}
								/>
							</Box>
						</Box>
					</ClickAwayListener>
				)}
				{error && (
					<Typography className='search-error'>
						<ErrorIcon />
						{error}
					</Typography>
				)}
			</Box>
		</>
	);
};
