import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import AppRoutes from './config/routes';
import ResponsiveImg from '../src/images/responsive-img.svg';
import './styles/syncfusion.css';
import { GAinitialize, GApageView, isTrialVersion } from './config/utils';
import './styles/base.scss';
import AlertDialog from './components/Common/Dialog';
import { clearLocalStorage } from './services/StorageService';
import { WhatsNewAlert } from './components/Common/WhatsNewAlert';
import { AuthProvider } from 'react-oidc-context';
import { oidcConfigData } from './config/oidcAuthConfig';

isTrialVersion() && GAinitialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
const App = (props) => {
	const { t } = useTranslation();
	const isSessionExpire = useSelector((state) => state?.commonReducer?.isSessionExpire);

	useEffect(() => {
		if (window.location.origin.includes('.unlockokr.com')) {
			window.location.replace(window.location.origin.replaceAll('.unlockokr.com', '.unlocku.com'));
		}
	}, []);

	useEffect(() => {
		isTrialVersion() && GApageView(window.location.pathname + window.location.search);
		//console.log = console.warn = console.error = () => {};
	}, []);
	// useEffect(() => {
	// 	GApageView(window.location.pathname + window.location.search);
	// 	//console.log = console.warn = console.error = () => {};
	// }, []);
	const theme = createTheme({
		typography: {
			fontFamily: ['Lato', 'sans-serif'].join(','),
		},
	});
	const handleCloseModal = (e, _type) => {
		e.preventDefault();
		e.stopPropagation();
		reloadApplication();
	};

	const reloadApplication = () => {
		clearLocalStorage();
		sessionStorage.clear();
		window.location.href = window.location.origin;
	};

	const oidcConfig = oidcConfigData();
	return (
		<Fragment>
			<AuthProvider {...oidcConfig}>
				{/* <MsalProvider instance={msalInstance}> */}
				<ThemeProvider theme={theme}>
					{!(window !== window.parent && !window.opener) && <AppRoutes {...props} t={t} />}

					<div className='screenlock-message'>
						<Box className='screenlock-img'>
							<img src={ResponsiveImg} alt='responsive' />
						</Box>
						<Typography>
							Oops! We are not compatible with mobile or tablet devices yet. We will get there soon.
							<br />
							However, if you are observing this issue in your desktop or laptop, then it could be because your browser
							zoom setting may have got expanded to more than what the current resolution is.
						</Typography>

						<Typography>
							We recommend using desktop or laptops with display settings set to greater than 1024 pixels.
						</Typography>
					</div>
				</ThemeProvider>

				{/* </MsalProvider> */}
				{isSessionExpire && (
					<AlertDialog
						module='relogin'
						message={t('reloginMessage')}
						handleCloseModal={handleCloseModal}
						modalOpen={isSessionExpire}
					/>
				)}
				<WhatsNewAlert {...props} />
			</AuthProvider>
		</Fragment>
	);
};

export default App;
