import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { ssoLoginAction } from '../../action/ssoLogin';
import SsoLogin from '../../components/SsoLogin';
import { updateRoute, showLoader, updatePage } from '../../action/common';

const mapStateToProps = (state) => {
	return {
		ssoLoginStatus: state.ssoLoginReducer.ssoLoginStatus,
		pending: state.ssoLoginReducer.pending,
		messageList: state.ssoLoginReducer.messageList,
		entity: state.ssoLoginReducer.entity,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			ssoLoginAction,
			updateRoute,
			showLoader,
			updatePage,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SsoLogin));
