import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ReportFilter from '../Filters';
import { getLocalStorageItem } from '../../../services/StorageService';
import { HeaderTooltip } from '../HeaderTooltip';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { useEffect } from 'react';
import { ReportsLineChart } from '../HighchartReports/ReportsLineChart';
import { TEAMS_CHART_COLOR } from '../../../config/constant';
import { ReportsColumnChart } from '../HighchartReports/ReportsColumnChart';

const TeamEngagementReport: React.FC<any> = (props: any) => {
	const {
		getLeadingTeamsDetails,
		leadingTeams,
		headTitle,
		headTitleTooltip,
		color1,
		color2,
		color3,
		getTeamEngagementOverallProgress,
		tabSelectedTeam,
		isEngagement,
	} = props;
	const { t } = useTranslation();
	const [nature, setNature] = React.useState<any>([]);
	const [teams, setTeams] = React.useState<any>([]);
	const [isFilterChanged, setIsFilterChanged] = React.useState<boolean>(false);
	const [teamsPreviousState, setTeamsPreviousState] = React.useState<any>([]);
	const [maxOverAllProgress, setMaxOverAllProgress] = React.useState<number>(0);
	const [filterSelectionError, setFilterSelectionError] = React.useState<boolean>(false);
	const currentCycleId = getLocalStorageItem('currentCycleId') || 0;
	const [reportsData, setReportsData] = React.useState<any>({});
	const [engagementData, setEngagementData] = React.useState<any>({});
	const [maxValueRecord, setMaxValueRecord] = React.useState<any>({});
	const [dataLoaded, setDataLoaded] = React.useState<boolean>(false);
	const [resetClicked, setResetClicked] = React.useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || '';
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || '';

	React.useEffect(() => {
		let allTeams;
		if (teams.length) {
			validateFilter();
			allTeams = teams.filter((item: any) => item.isChecked).length === teams.length;
			if (!allTeams) {
				setIsFilterChanged(true);
			} else {
				setIsFilterChanged(false);
			}
		}
		setTeams(teams);
	}, [teams]);

	useEffect(() => {
		if (resetClicked) {
			getOverAllProgress();
			handleFilterBtnClick();
		}
	}, [resetClicked]);

	const handleFilterBtnClick = () => {
		setAnchorEl((prev) => !prev);
	};
	const handleClose = (event: any) => {
		setAnchorEl(false);
		setTeams(teamsPreviousState);
	};

	React.useEffect(() => {
		//Call Team data
		if (anchorEl) {
			let data = `cycleId=${currentCycleId}`;
			if (!leadingTeams.length) {
				getLeadingTeamsDetails(data);
			}
		}
	}, [anchorEl]);

	React.useEffect(() => {
		if (cycleStartDate && cycleEndDate) {
			getOverAllProgress();
		}
	}, []);

	React.useEffect(() => {
		if (leadingTeams.length) {
			let checkItem = leadingTeams.map((item: any) => {
				return {
					...item,
					isChecked: true,
				};
			});
			setTeams(checkItem);
			setTeamsPreviousState(checkItem);
		}
	}, [leadingTeams]);

	React.useEffect(() => {
		if (tabSelectedTeam) {
			getOverAllProgress();
			updateFilter();
		}
	}, [tabSelectedTeam]);

	const updateFilter = () => {
		// teams, setTeams
		let allTeams = [...teams];

		// let updatedTeam = allTeams.filter((team) => team.teamId === tabSelectedTeam);
		let updatedTeam = allTeams.map((team) => {
			return {
				...team,
				isChecked: team.teamId === tabSelectedTeam ? true : false,
			};
		});
		setTeamsPreviousState(updatedTeam);
		setTeams(updatedTeam);
	};

	const preparePerformanceData = (data: any) => {
		let responseData = { ...data };
		delete responseData.teamEngagementProgressOverall.progressDate;
		let overAllData = [];
		for (let key in responseData.teamEngagementProgressOverall) {
			if (responseData.teamEngagementProgressOverall.hasOwnProperty(key)) {
				overAllData.push({
					color:
						key === 'myEngagementProgress'
							? '#8FC3F6'
							: key === 'myTeamMemberEngagementProgress'
							? '#45E7BA'
							: key === 'myOrgMemberEngagementProgress'
							? '#FA989D'
							: '',
					month:
						key === 'myEngagementProgress'
							? 'Average Engagement score of my teams'
							: key === 'myTeamMemberEngagementProgress'
							? 'Engagement score of most engaged team(s)'
							: key === 'myOrgMemberEngagementProgress'
							? 'Average Engagement score of users in the org'
							: '',
					sales: responseData.teamEngagementProgressOverall[key],
				});
			}
		}
		let getMax = _(responseData.teamEngagementProgressOverall).values().max();
		setMaxOverAllProgress(getMax);
		let setOrder = [...overAllData];
		setOrder[1] = overAllData[2];
		setOrder[2] = overAllData[1];
		responseData.teamEngagementProgressOverall = setOrder;
		return responseData;
	};

	const prepareChartData = (chartData: any) => {
		let data = chartData.map((org: any) => {
			// return {
			// 	...org,
			// 	x: new Date(org.date),
			// 	y: org.progress,
			// };
			if (moment(org.date) > moment(new Date())) {
				return {
					...org,
					x: new Date(org.date),
				};
			} else {
				return {
					...org,
					x: new Date(org.date),
					y: org.progress,
				};
			}
		});
		return data;
	};

	const getOverAllProgress = async () => {
		let data = {};
		let teamList = [];
		if (tabSelectedTeam && tabSelectedTeam != 'All' && tabSelectedTeam !== 0) {
			teamList.push(tabSelectedTeam);
		}
		if (anchorEl) {
			let selectedTeams = [...teams];
			let getFilterList = _.filter(selectedTeams, 'isChecked');
			data = {
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: _.map(getFilterList, 'teamId') || [],
			};
		} else {
			data = {
				cycleStartDate: cycleStartDate.split('T')[0],
				cycleEndDate: cycleEndDate.split('T')[0],
				teamIds: teamList,
			};
		}
		setResetClicked(false);
		const apiRes = await getTeamEngagementOverallProgress(data);
		if (apiRes && (apiRes.data.status === 200 || apiRes.data.status === 204)) {
			let res = apiRes.data?.entity;
			setEngagementData(preparePerformanceData(apiRes.data.entity));
			let allRecords = [
				...res.teamOrgMemberEngagementDetails,
				...res.teamEngagementProgressDetails,
				...res.teamMemberEngagementDetails,
			];
			let maxVal = _.maxBy(allRecords, 'progress');
			setMaxValueRecord(maxVal || {});
			setDataLoaded(true);
			setReportsData({
				myOrgMemberProgressDetails: prepareChartData(res.teamMemberEngagementDetails),
				myProgressDetails: prepareChartData(res.teamEngagementProgressDetails),
				myTeamMemberProgressDetails: prepareChartData(res.teamOrgMemberEngagementDetails),
			});
		} else {
			enqueueSnackbar(t('internalServerError'), {
				variant: 'error',
				autoHideDuration: 2000,
			});
		}
	};

	const handleFilterOption = (e: any, filterType: string, filterId?: number) => {
		// e.preventDefault();
		const { checked } = e.target;
		let natures = [...nature];
		let allTeams = [...teams];
		if (filterType === 'natureOfOkr') {
			let updateNature = natures.map((item: any) => {
				if (item.okrNatureId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setNature(updateNature);
		} else if (filterType === 'selectAllNature') {
			let updateNature = natures.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setNature(updateNature);
		} else if (filterType === 'teams') {
			let updateTeams = allTeams.map((item: any) => {
				if (item.teamId === filterId) {
					return {
						...item,
						isChecked: checked,
					};
				}
				return item;
			});
			setTeams(updateTeams);
		} else if (filterType === 'selectAllTeam') {
			let updateTeams = allTeams.map((item: any) => {
				return {
					...item,
					isChecked: checked,
				};
			});
			setTeams(updateTeams);
		}
	};

	const validateFilter = () => {
		let findFilteredTeams = teams.filter((item: any) => item.isChecked);
		if (!findFilteredTeams.length) {
			setFilterSelectionError(true);
		} else {
			setFilterSelectionError(false);
		}
	};

	const handleApplyFilter = () => {
		setTeamsPreviousState(teams);
		handleFilterBtnClick();
		getOverAllProgress();
	};

	const handleResetFilter = () => {
		let updateTeams = teams.map((item: any) => {
			return {
				...item,
				isChecked: true,
			};
		});
		setTeams(updateTeams);
		setResetClicked(true);
	};

	return (
		<>
			<Box className='reports-head'>
				<Box className='reports-head-left'>
					<Typography variant='h2'>{headTitle}</Typography>
					<HeaderTooltip tooltipText={headTitleTooltip} btnId={'team-eng-reports-tooltip'} />
				</Box>
				<Box className='reports-head-right'>
					{leadingTeams && leadingTeams.length > 1 && (
						<ReportFilter
							{...props}
							toolTipText={t('filterTeamsEngagementTooltip')}
							isSencondFilters={false}
							secondFiltersOption={nature}
							teams={teams}
							handleFilterOption={handleFilterOption}
							handleApplyFilter={handleApplyFilter}
							handleResetFilter={handleResetFilter}
							handleFilterBtnClick={handleFilterBtnClick}
							handleClose={handleClose}
							filterSelectionError={filterSelectionError}
							anchorEl={anchorEl}
							isFilterChanged={isFilterChanged}
							filterId={'team-eng'}
						/>
					)}
				</Box>
			</Box>
			<Box className='reports-card col-full'>
				<Box className='report-col col-one-third'>
					<Typography variant='h4'>
						{t('overallPerformanceLabel')}
						<span>{t('overAllCurrentDate', { currentDate: moment(new Date()).format('MMMM D, YYYY') })}</span>
					</Typography>
					<ReportsColumnChart
						{...props}
						t={t}
						progress={maxOverAllProgress}
						engagementDataOverall={engagementData?.teamEngagementProgressOverall}
						engagementData={engagementData}
						colors={TEAMS_CHART_COLOR}
						labels={[t('myTeamsLabel'), t('orgLabel'), t('mostEngaggedTeamLabel')]}
						yAxisText={'Engagement Count'}
						isEngagement={isEngagement}
					/>
				</Box>
				<Box className='report-col col-two-thirds'>
					<Typography variant='h4' className='title-center'>
						{t('fourWeekReportLabel')}
					</Typography>
					<ReportsLineChart
						{...props}
						t={t}
						colors={TEAMS_CHART_COLOR}
						showLegend={true}
						dataLoaded={dataLoaded}
						maxValueRecord={maxValueRecord}
						reportsData={reportsData}
						labels={[t('myTeamsLabel'), t('orgLabel'), t('mostEngaggedTeamLabel')]}
						xAxisText={'Weeks'}
						yAxisText={'Engagement'}
						isEngagement={isEngagement}
					/>
				</Box>
			</Box>
		</>
	);
};

export default TeamEngagementReport;
