import React from 'react';
import { AppBar, Box, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import { Logo } from '../../config/svg/GlobalSvg';
import '../../styles/pages/static-pages/staticPage.scss';

export default function PrivacyPolicy() {
	return (
		<Box className='static-page-wrapper wrapper'>
			<header className='app-header-secondary'>
				<Box className='header-left'>
					<Box className='app-logo' id='logo'>
						<Logo />
					</Box>
				</Box>
			</header>
			<Box className='main-wrapper'>
				<Box className='inner-wrapper'>
					<Typography variant='h2'>Privacy Policy</Typography>
					<Typography>
						Infopro Learning, Inc. ("Infopro", "we", "our", or "us"), doing business as Unlock, is committed to
						protecting your privacy. This Privacy Policy governs the manner in which we collect, use, maintain, and
						disclose information collected from users (each, a "User") of the website{' '}
						<a target='_blank' rel='noopener noreferrer' href='https://www.unlocku.com/'>
							www.unlocku.com
						</a>{' '}
						(the "Site") and all products and services, including the Unlock:U SaaS platform (the "Services").
					</Typography>
					<Typography variant='h3'>Personal identification information</Typography>
					<Typography>
						We may collect personal identification information from Users in a variety of ways, including, but not
						limited to, when Users visit our site, subscribe to the newsletter, fill out a form, and in connection with
						other activities, services, features, or resources we make available on our Site. Users may be asked for, an
						appropriate, name and email address. We store the data for the duration of the contract term and
						subsequently, if applicable, for the duration of statutory retention periods. We will delete your account if
						you wish to do so after cancelling your subscription. Users may, however, visit our Site anonymously. We
						will collect personal identification information from Users only if they voluntarily submit such information
						to us. Users can always refuse to supply personally identification information, except that it may prevent
						them from engaging in certain Site related activities.
					</Typography>
					<Typography variant='h3'>Non-personal identification information</Typography>
					<Typography>
						We may collect non-personal identification information about Users whenever they interact with our Site.
						Non-personal identification information may include the browser name, the type of computer, technical
						information about Users means of connection to our Site, such as the operating system and the Internet
						service providers utilised, and other similar information activities.
					</Typography>
					<Typography variant='h3'>Web browser cookies</Typography>
					<Typography>
						Our Site may use "cookies" to enhance User experience. User’s web browser places cookies on their hard drive
						for record-keeping purposes and sometimes to track information about them. User may choose to set their web
						browser to refuse cookies or to alert you when cookies are being sent. If they do so, note that some parts
						of the Site may not function properly.
					</Typography>
					<Typography variant='h3'>How we use collected information</Typography>
					<Typography>We may collect and use Users personal information for the following purposes:</Typography>
					<List>
						<ListItem>
							<Typography variant='h4'>To improve customer service</Typography>
							<Typography>
								The information you provide helps us respond to your customer service requests and support needs more
								efficiently.
							</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>To improve our Site</Typography>
							<Typography>We may use the feedback you provide to improve our products and services.</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>To send periodic emails</Typography>
							<Typography>
								We may use the email address to send User information and updates pertaining to their order. It may also
								be used to respond to their inquiries, questions, and/or other requests. If User decides to opt-in to
								our mailing list, they will receive emails that may include company news, updates, related product or
								service information, etc.
							</Typography>
						</ListItem>
					</List>
					<Typography variant='h3'>How we protect your information</Typography>
					<Typography>
						We adopt appropriate data collection, storage, and processing practices and security measures to protect
						against unauthorized access, alteration, disclosure, or destruction of your personal information, username,
						password, transaction information, and data stored on our Site. The transmission of sensitive information
						(such as sign-in credentials) is encrypted using secure socket layer technology (SSL). We follow generally
						accepted standards to protect the personal data submitted to us, both during transmission and once we
						receive it. Although we make every effort to ensure the security of User information, it's important to note
						that no electronic or email transmission or digital storage mechanism is ever fully secure or error-free. If
						you have any questions about security on our website or application, you can contact us at
						<a href='mailto:support@unlocku.com'> support@unlocku.com</a>.
					</Typography>
					<Typography>
						To learn more about current practices and policies regarding security, confidentiality, backups, and client
						data storage, please see our Security Policy. We keep that document updated as these practices evolve over
						time.
					</Typography>
					<Typography>
						To learn more about how we comply with GDPR, please see our GDPR page, which also contains information about
						our partners (Data Processors) used to deliver the smooth operation of our Site or Services.
					</Typography>
					<Typography variant='h3'>Sharing your personal information</Typography>
					<Typography>
						We do not sell, trade, or rent Users personal identification information to others. We may share generic
						aggregated demographic information not linked to any personal identification information regarding visitors
						and users with our business partners, trusted affiliates, and advertisers for the purposes outlined above.
						We may use third party service providers to help us operate our business and the Site or administer
						activities on our behalf, such as sending out newsletters or surveys. These third parties may have access to
						your information only for the purposes of performing these tasks on our behalf and under obligations similar
						to those in this Privacy Policy. We require our service providers to agree to take reasonable steps to keep
						the personal information that we provide to them secure. We do not authorize them to use or disclose your
						personal information except in connection with providing their services. We may share your information with
						these third parties for those limited purposes, provided that you have given us your permission.
					</Typography>
					<Typography>
						Regardless of any choices you make regarding your personal information (as described below), We may disclose
						personal information if it believes in good faith that such disclosure is necessary: (a) in connection with
						any legal investigation; (b) to comply with relevant laws or to respond to subpoenas or warrants served on
						Us; (c) to investigate or assist in preventing any violation or potential violation of the law, this Privacy
						Policy, or our Terms of Use.
					</Typography>
					<Typography variant='h3'>Data Storage and Data Protection Compliance</Typography>
					<Typography>
						Your data will be processed in data centers located in the United States of America, and as such, it may be
						subject to U.S. laws and it may be accessible to the U.S. government, tribunals, law enforcement, and
						regulatory agencies. The level of data protection established in the United States of America is lower than
						the one established within the European Union. We will take measures to ensure that your personal
						information is stored safely with us; in particular, we maintain compliance with US Data Protection Acts and
						the General Data Protection Regulation within the EU.
					</Typography>
					<Typography variant='h3'>Your Choices Regarding Your Information</Typography>
					<Typography>
						You have several choices regarding use of your personal information on our Services. Under certain
						circumstances, for example, where you are located in the European Economic Area ("EEA") or if you are a
						California resident, you may have certain rights relating to your personal information provided for under
						applicable law, including the right:
					</Typography>
					<Typography>
						To request access to your personal information (known as a "data subject access request" under the EU
						General Data Protection Regulation (the "GDPR), or for California residents, "the right to know"). If you
						are a California resident, you may be entitled to obtain the personal information about you that we collect,
						use, and disclose;
					</Typography>
					<Typography>
						To delete your personal information. You may be entitled to ask us to delete or remove personal data. For
						California residents, this right applies to personal information we collect directly from you. Individuals
						located in the EEA may have the right to ask us to delete or remove your personal information where you have
						successfully exercised your right to object to processing (see below), where we may have processed your
						personal information unlawfully, or where we are required to erase your personal information to comply with
						local law. Note, however, that we may not always be able to comply with your request to delete for specific
						legal reasons, which will be notified to you, if applicable, at the time of your request.
					</Typography>
					<Typography>
						You have the right not to receive discriminatory treatment by the business for the exercise of the privacy
						rights conferred by California law.
					</Typography>
					<Typography variant='h3'>Email Communications</Typography>
					<Typography>
						We will periodically send you free newsletters and emails that directly promote the use of our Services.
						When you receive promotional communications from us, you may indicate a preference to stop receiving further
						promotional communications from us, and you will have the opportunity to "opt-out" by following the
						unsubscribe instructions provided in the e-mail you receive or by contacting us directly (please see contact
						information below). Despite your indicated email preferences, we may send you service-related
						communications, including notices of any updates to our Terms of Use or Privacy Policy.
					</Typography>
					<Typography variant='h3'>Changing or deleting your personal information</Typography>
					<Typography>
						You may change any of your personal information in your Account by editing your settings within your Account
						or by sending an email to us at the address listed below. You may request deletion of your personal
						information by us, and we will use commercially reasonable efforts to honor your request, but please note
						that we may be required to keep such information and not delete it (or to keep this information for a
						certain time, in which case we will comply with your deletion request only after we have fulfilled such
						requirements). When we delete any information, it will be deleted from the active database, but may remain
						in our archives or backups, and we will maintain your email address in our database for our future
						reference.
					</Typography>
					<Typography variant='h3'>Web Browser Controls</Typography>
					<Typography>
						You can prevent the use of certain tracking tools, such as cookies, on a device-by-device basis using the
						controls in your web browser. These controls can be found in the Tools {'>'} Internet Options (or similar)
						for your browser, or as otherwise directed by your browser's support feature. Through your web browser, you
						may be able to:
					</Typography>
					<List>
						<ListItem>
							<Typography variant='h4'>Delete existing tracking tools.</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>Disable future tracking tools.</Typography>
						</ListItem>
						<ListItem>
							<Typography variant='h4'>
								Set your browser to provide you with a warning each time a cookie or certain other tracking tools are
								being set
							</Typography>
						</ListItem>
					</List>
					<Typography variant='h3'>Do not track signals</Typography>
					<Typography>
						Some web browsers may transmit "do not track" signals to the websites and other online services with which
						your web browser communicates. There is no standard that governs what, if anything, websites should do when
						they receive these signals. We currently do not take action in response to these signals. If and when a
						standard is established, we may revise our policy on responding to these signals.
					</Typography>
					<Typography variant='h3'>California Online Privacy Protection Act Compliance</Typography>
					<Typography>
						Because we value your privacy, we have taken the necessary precautions to be in compliance with the
						California Online Privacy Protection Act. We therefore will not distribute your personal data to outside
						parties without your consent.
					</Typography>
					<Typography variant='h3'>Service Quality Monitoring</Typography>
					<Typography>
						Many of our interactions may involve you calling us or us calling you. Please be aware that it is our
						general practice to monitor and, in some cases, record such calls for quality assurance purposes.
					</Typography>
					<Typography variant='h3'>Third-party websites</Typography>
					<Typography>
						Users may find advertising or other content on our Site that links to the sites and services of our
						partners, suppliers, advertisers, sponsors, licensors, and other third parties. We do not control the
						content or links that appear on these sites and are not responsible for the practices employed by websites
						linked to or from our Site. In addition, these sites or services, including their content and links, may be
						constantly changing. These sites and services may have their own privacy policies and customer service
						policies. Browsing and interaction on any other website, including websites which have a link to our Site,
						is subject to that website's own terms and policies.
					</Typography>
					<Typography variant='h3'>Changes to this Privacy Policy</Typography>
					<Typography>
						This privacy policy is subject to occasional revision. If we make any material changes in the way we use
						your personal information, we will notify existing users of our Site or Services or others who previously
						shared their personal information with us by sending the email to the last e-mail address provided to us
						and/or by prominently posting notice of the changes on our Sites. We will notify others by prominently
						posting notice of the changes on our Sites. Any changes to this Privacy Policy will be effective upon the
						earlier of thirty (30) calendar days following our dispatch of an e-mail notice or thirty (30) calendar days
						following our posting of notice of the changes on our Site. These changes will be effective immediately for
						new users of our Site or Services. Please note that at all times you are responsible for updating your
						personal information to provide us with your most current e-mail address. In the event that the last e-mail
						address that you have provided us is not valid, or, for any reason, is not capable of delivering to you the
						notice described above, our dispatch of the email containing such a notice will nonetheless constitute
						effective notice of the changes described in the notice. If you do not wish to permit changes in our use of
						your personal information, you must notify us prior to the effective date of the changes that you wish to
						deactivate your account with us. Continued use of our Sites or Services, following notice of such changes
						shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of
						such changes.
					</Typography>
					<Typography variant='h3'>Contact Us</Typography>
					<Typography>
						If you have any questions about this privacy policy, please contact us at the following address or email:
					</Typography>
					<Typography>
						Infopro Learning, Inc.
						<br /> 103 Morgan Lane, Suite # 102, <br />
						Plainsboro, NJ 08536.
						<br /> <a href='mailto:support@unlocku.com'> support@unlocku.com</a>
					</Typography>
					<Typography variant='h3'>Policy on Information Collected from Children</Typography>
					<Typography>
						We do not intentionally gather personal information from visitors who are under the age of 16. If a child
						under 16 submits personal information to us and we learn that the personal information is the information of
						a child under 16, we will attempt to delete the information as soon as possible. If you believe that we
						might have any personal information from a child under 16, please contact us at:
						<a href='mailto:support@unlocku.com'> support@unlocku.com</a>
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
