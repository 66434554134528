import { getRequest, postRequest, putRequest, deleteRequest } from '../config/auth';
import {
	LIST_ROLES,
	ADD_ROLES,
	EDIT_ROLES,
	ASSIGN_ROLES,
	UPDATE_ROLES_STATUS,
	UPDATE_ROLES_PERMISSION,
	ADD_ROLES_PERMISSION,
	ADMIN_ROLES_AND_PERMISSION,
	GET_ROLES,
	GET_PERMISSIONS,
	EMPLOYEE_PERMISSION,
	UPDATE_ROLE,
	GET_ROLE,
	UPDATE_ROLENAME,
	UPDATE_PERMISSION,
	DELETE_ROLE,
	CLONE_ROLE,
	ASSIGN_USER_TO_ROLE,
	GET_EMPLOYEE_PERMISSION,
	SAVE_EMPLOYEE_PERMISSIONS,
	GET_ROLE_PERMISSION,
} from '../config/api-url';

export const getRolesListApi = (data) => getRequest(`${LIST_ROLES}?${data}`, data);
export const addRolesAPI = (data) => postRequest(`${ADD_ROLES}`, data);
export const editRolesAPI = (data) => putRequest(`${EDIT_ROLES}`, data);
export const assignRolesAPI = (data) => postRequest(`${ASSIGN_ROLES}`, data);
export const changeRoleStatusAPI = (data) => putRequest(`${UPDATE_ROLES_STATUS}?${data}`, data);
export const changeRolePermissionAPI = (data) => putRequest(`${UPDATE_ROLES_PERMISSION}?${data}`, data);
export const addRolesPermissionAPI = (data) => postRequest(`${ADD_ROLES_PERMISSION}`, data);
export const getRolesAndPermissionAPI = (data) => getRequest(`${ADMIN_ROLES_AND_PERMISSION}`, data);
export const getRolesAPI = (data) => getRequest(`${GET_ROLES}`, data);
export const getAllPermissionAPI = (data) => getRequest(`${GET_PERMISSIONS}`, data);
export const getEmployeePermissionAPI = (data) => getRequest(`${EMPLOYEE_PERMISSION}`, data);
export const updateRoleAPI = (data) => putRequest(`${UPDATE_ROLE}`, data);
export const getRoleByIdAPI = (data) => getRequest(`${GET_ROLE}/${data}`, data);
export const updateRoleNameAPI = (data) => putRequest(`${UPDATE_ROLENAME}`, data);
export const updatePermissionAPI = (data) => postRequest(`${UPDATE_PERMISSION}`, data);
export const deleteRoleAPI = (data) => deleteRequest(`${DELETE_ROLE}?roleId=${data}`);
export const cloneRoleAPI = (data) => postRequest(`${CLONE_ROLE}`, data);
export const getRolesListAPI = (data) => getRequest(`${ADD_ROLES_PERMISSION}`, data);
export const assignRoleAPI = (data) => postRequest(`${ASSIGN_USER_TO_ROLE}`, data);
export const employeePermissionsAPI = (data) => postRequest(`${SAVE_EMPLOYEE_PERMISSIONS}`, data);
export const getEmployeePermissionByIdAPI = (data) => getRequest(`${GET_EMPLOYEE_PERMISSION}/${data}`, data);
export const getRolePermissionByRoleIdAPI = (data) => getRequest(`${GET_ROLE_PERMISSION}/${data}`, data);
