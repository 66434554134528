import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	recognizePostResponse: null,
	recognizeBadges: [],
	recognizeBadgesMapping: {},
	recognizePagingInfo: {},
	fameWallBadgesResponse: null,
	fameBadgesBadgesMapping: {},
	recognizeId: null,
};

export default function recognizeReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GET_RECOGNIZE_LIST_PENDING:
			return {
				...state,
				recognizePostResponse: [],
				recognizePagingInfo: {},
				recognizePostResponseStatus: 'pending',
			};
		case actionTypes.GET_RECOGNIZE_LIST_SUCCESS:
			return {
				...state,
				recognizePostResponse: action.payload.entityList,
				recognizePagingInfo: action.payload.pagingInfo,
				recognizePostResponseStatus: 'success',
			};
		case actionTypes.GET_RECOGNIZE_LIST_FAILED:
			return {
				...state,
				recognizePostResponse: [],
				recognizePagingInfo: {},
				recognizePostResponseStatus: 'failed',
			};

		case actionTypes.GET_FAME_BADGES_PENDING:
			return {
				...state,
				fameWallBadgesResponse: null,
				fameBadgesBadgesMapping: {},
			};
		case actionTypes.GET_FAME_BADGES_SUCCESS:
			return {
				...state,
				fameWallBadgesResponse: action.payload.entityList,
			};
		case actionTypes.GET_FAME_BADGES_FAILED:
			return {
				...state,
				fameWallBadgesResponse: [],
				fameBadgesBadgesMapping: {},
			};

		case actionTypes.GET_RECOGNIZE_BADGES_PENDING:
			return {
				...state,
				recognizeBadges: [],
				recognizeBadgesMapping: {},
			};
		case actionTypes.GET_RECOGNIZE_BADGES_SUCCESS:
			return {
				...state,
				recognizeBadges: action.payload.entityList,
				recognizeBadgesMapping: action.payload.entityMapping,
			};
		case actionTypes.GET_RECOGNIZE_BADGES_FAILED:
			return {
				...state,
				recognizeBadges: [],
				recognizeBadgesMapping: {},
			};
		case actionTypes.PRAISE_RECOGNIZE_ID:
			return { ...state, recognizeId: action.payload };
		default:
			return state;
	}
}
