import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, Link, Paper, Popper, TextField, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../config/svg/Action';
import { AddLinkIcon, DeleteIcon, EditIcon, LinkIcon, LinkKrIcon } from '../../config/svg/CommonSvg';
import { urlPatternValidation } from '../../config/utils';
import { ErrorIcon } from '../../config/svg/formElementIcons';

export const TaskLinkPopper: React.FC<any> = (props: any) => {
	const { callingFrom, handleTaskLinkChange, formDetails, isCreateTask, isActionable } = props;
	const { t } = useTranslation();
	const inputRef: any = useRef(null);
	const staticErrorObject = {
		error: '',
		helperText: '',
		type: '',
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = useState(false);
	const [editTaskLinkId, setEditTaskLinkId] = useState<any>({});
	const [linkAddedOrEdited, setLinkAddedOrEdited] = useState<boolean>(false);
	const [linkList, setLinkList] = useState<any>([]);
	const [errorMessage, setErrorMessage] = useState<any>(staticErrorObject);
	const [linkText, setLinkText] = useState('');

	useEffect(() => {
		setLinkList(formDetails.link || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 100);
	}, [open]);

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen((prev) => !prev);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
		//setFormDetails({ ...formDetails, link: linkList });
		if (linkAddedOrEdited) {
			handleTaskLinkChange(linkList);
		}
		setLinkText('');
		setErrorMessage(staticErrorObject);
		setEditTaskLinkId({});
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
			}
		}, 100);
	};

	const handleLinkChange = (e: any) => {
		setLinkText(e.target.value);
		setErrorMessage(staticErrorObject);
	};

	const addLinkTask = (isEdit = false) => {
		if (!urlPatternValidation(linkText)) {
			setErrorMessage({
				error: true,
				helperText: 'Please enter valid link',
				type: 'oneToOneTitle',
			});
			setTimeout(() => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}, 100);
		} else {
			if (isEdit) {
				let linkListCopy = [...linkList];
				linkListCopy[editTaskLinkId.linkIndex].link = linkText;
				setLinkList(linkListCopy);
				setLinkText('');
				setErrorMessage(staticErrorObject);
				setEditTaskLinkId({});
			} else {
				const linkObject = { taskLinkMappingId: 0, link: linkText, taskId: 0 };
				setLinkList([...linkList, linkObject]);
				setLinkText('');
			}
			setLinkAddedOrEdited(true);
		}
	};

	const handleEditTaskLink = (editTaskLinkId: any, linkIndex: any) => {
		setEditTaskLinkId({ ...editTaskLinkId, linkIndex: linkIndex });
		setTimeout(() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		}, 100);
	};
	const handleDeleteTaskLink = (linkIndex: any) => {
		let linkListCopy = [...linkList];
		linkListCopy.splice(linkIndex, 1);
		setLinkList(linkListCopy);
		setLinkAddedOrEdited(true);
	};
	const isDisable = () => {
		const disabled = isCreateTask ? false : !isActionable;
		return disabled;
	};
	return (
		<>
			<Box
				onClick={(e: any) => {
					if (!isDisable() || formDetails.link.length > 0) {
						handleClick(e);
						handlePopperScroll(e, 'taskLinkTextPopup');
						setLinkAddedOrEdited(false);
						setLinkList(formDetails.link || []);
					}
				}}
				className={`text-linking-wrap ${isDisable() ? 'disabled' : ''}`}
			>
				{formDetails && formDetails?.link && formDetails?.link?.length > 0 ? (
					<Box className={`selected-item selected-chip `}>
						<Tooltip arrow classes={{ popper: 'tooltip-layout12' }} title={'Links'}>
							<Box className={`chip-tag ${isDisable() ? 'disable-chip' : ''}`}>
								<AddLinkIcon />
								<Typography variant='h6'>{formDetails?.link?.length}</Typography>
							</Box>
						</Tooltip>
					</Box>
				) : (
					<OKRButton
						className='blue-btn link-icon-btn'
						icon={<AddLinkIcon />}
						title={
							isDisable()
								? formDetails?.isCompleted
									? t('disableQuickLinkTitleForCompleted')
									: t('disableQuickLinkTitle')
								: t('addLink')
						}
						disabled={isDisable() ? true : false}
						buttonType={'disableButtonTooltip'}
						wrapClassName={'disable-button-wrap'}
						// handleClick={(e: any) => {
						// 	handleClick(e);
						// 	handlePopperScroll(e, 'taskLinkTextPopup');
						// 	setLinkAddedOrEdited(false);
						// 	setLinkList(formDetails.link || []);
						// }}
					/>
				)}
			</Box>
			{open && (
				<Popper
					className={`task-kr-link-popup task-link-text-popup ${
						callingFrom === 'GlobalTask' || callingFrom === 'OneOnOneTask' || callingFrom === 'CheckInTask'
							? ''
							: 'kr-drawer-link-popup'
					}`}
					open={open}
					anchorEl={anchorEl}
					placement={'bottom-start'}
					modifiers={{
						flip: {
							enabled: false,
						},
					}}
					id={'taskLinkTextPopup'}
					container={
						callingFrom === 'GlobalTask' || callingFrom === 'OneOnOneTask' || callingFrom === 'CheckInTask'
							? document.body
							: document.getElementById('kr-task-drawer')
					}
				>
					<Paper>
						<ClickAwayListener
							onClickAway={() => {
								handleClose();
							}}
						>
							<Box className='task-popper-link'>
								{editTaskLinkId && (editTaskLinkId.linkIndex || editTaskLinkId.linkIndex === 0) ? (
									<> </>
								) : (
									<Box className='link-textfield textfield-wrap'>
										{!isDisable() ? (
											<TextField
												inputRef={inputRef}
												className='user-search-field helperText-bottom'
												id='add-link-field'
												placeholder={t('addLinkPlaceholder')}
												fullWidth
												onChange={(e) => handleLinkChange(e)}
												value={linkText}
												error={errorMessage?.error || false}
												helperText={
													(errorMessage?.error && (
														<>
															<ErrorIcon />
															{errorMessage?.helperText || ''}
														</>
													)) ||
													''
												}
											/>
										) : (
											<></>
										)}
										{!isDisable() ? (
											<Box className='action-btn-wrap'>
												<Box className='textfield-action-btn'>
													<OKRButton
														className='cancel-btn'
														id={'cancel-btn'}
														icon={<CancelIcon />}
														//handleClick={handleCancelCreateTaskClick}
														disabled={linkText.trim() === ''}
														handleClick={() => {
															setLinkText('');
															setEditTaskLinkId({});
															setErrorMessage(staticErrorObject);
														}}
													/>
													<OKRButton
														className='save-btn'
														id={'save-btn'}
														icon={<TickIcon />}
														handleClick={() => {
															addLinkTask();
														}}
														disabled={linkText.trim() === ''}
													/>
												</Box>
											</Box>
										) : (
											<></>
										)}
										{/* <OKRButton
											text={'Add'}
											className='btn-secondary'
											
											handleClick={() => {
												addLinkTask();
											}}
										/> */}
									</Box>
								)}
								<Box className='added-link-list'>
									{linkList.length > 0 &&
										linkList.map((linkData: any, index: any) => {
											return editTaskLinkId.linkIndex === index ? (
												<Box className='link-textfield textfield-wrap'>
													<TextField
														inputRef={inputRef}
														className='user-search-field helperText-bottom'
														id='add-link-field'
														placeholder={t('addLinkPlaceholder')}
														fullWidth
														onChange={(e) => handleLinkChange(e)}
														//value={linkText}
														defaultValue={linkData.link}
														error={errorMessage?.error || false}
														helperText={
															(errorMessage?.error && (
																<>
																	<ErrorIcon />
																	{errorMessage?.helperText || ''}
																</>
															)) ||
															''
														}
													/>
													{!isDisable() ? (
														<Box className='action-btn-wrap'>
															<Box className='textfield-action-btn'>
																<OKRButton
																	className='cancel-btn'
																	id={'cancel-btn'}
																	icon={<CancelIcon />}
																	disabled={linkText.trim() === ''}
																	handleClick={() => {
																		setLinkText('');
																		setEditTaskLinkId({});
																		setErrorMessage(staticErrorObject);
																	}}
																/>
																<OKRButton
																	className='save-btn'
																	id={'save-btn'}
																	icon={<TickIcon />}
																	disabled={linkText.trim() === ''}
																	handleClick={() => {
																		addLinkTask(true);
																	}}
																/>
															</Box>
														</Box>
													) : (
														<></>
													)}
													{/* <OKRButton
														text={'Update'}
														className='btn-secondary'
														disabled={linkText.trim() === ''}
														handleClick={() => {
															addLinkTask(true);
														}}
													/>
													<OKRButton
														text={'Cancel'}
														className='btn-secondary'
														disabled={linkText.trim() === ''}
														handleClick={() => {
															setLinkText('');
															setEditTaskLinkId({});
														}}
													/> */}
												</Box>
											) : (
												<Box className='link-row'>
													<Box className='link-text'>
														<Link target='_blank' rel='noopener noreferrer' href={linkData.link}>
															{linkData.link}
														</Link>
													</Box>
													{!isDisable() ? (
														<Box className='btn-wrap'>
															<OKRButton
																id={'edit-btn'}
																className={'editIcon'}
																icon={<EditIcon />}
																title={t('editLabel')}
																handleClick={() => {
																	handleEditTaskLink(linkData, index);
																}}
															/>
															<OKRButton
																id={'delete-btn'}
																className={'editIcon'}
																icon={<DeleteIcon />}
																title={t('delete')}
																handleClick={() => {
																	handleDeleteTaskLink(index);
																}}
															/>
														</Box>
													) : (
														<></>
													)}
												</Box>
											);
										})}
								</Box>
							</Box>
						</ClickAwayListener>
					</Paper>
				</Popper>
			)}
		</>
	);
};
