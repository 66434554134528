import { Box, Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography, Tooltip } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { DeleteIcon, EditIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { getTime, getUserDetails, getUserName } from '../../../config/utils';
import { deleteCommentsPost, getCommentsList, getLikesList, likeRecognization } from '../../../action/recognize';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { useSnackbar } from 'notistack';
import { CreateReply } from './CreateReply';
import AlertDialog from '../../Common/Dialog';
import { getLocalStorageItem } from '../../../services/StorageService';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import LikeImg from '../../../images/Like.svg';

export const ReplyList = (props: any) => {
	const {
		commentsData,
		updateCount,
		showReplyBox,
		setShowReplyBox,
		setReplyHighlight,
		replyHighlight,
		replyUser,
		setPrefilledUser,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const { t } = useTranslation();
	const [open, setOpen] = useState<boolean>(false);
	const [apiLoading, setApiLoading] = useState<boolean>(false);

	const [postText, setPostText] = React.useState<any>('');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [replyListData, setReplyListData] = useState<any[]>([]);
	const [likedId, setLikedId] = useState<number>(0);

	const [pagingInfo, setPagingInfo] = useState<any>({
		pageIndex: 1,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
	});
	const loggedInUserDetail = getUserDetails();
	const [likeAnimation, setLikeAnimation] = useState<number>(0);
	const [editCommentId, setEditEditId] = useState<number>(0);
	const [modalProps, setModalProps] = React.useState<any>({
		open: false,
		data: {},
	});
	const [likeData, setLikeData] = useState<any[]>([]);
	const [savedId, setSavedId] = useState(0);

	let userDetail = getUserDetails();

	useEffect(() => {
		if (replyHighlight) {
			getReplies(1, 99999);
			setReplyHighlight(false);
		} else {
			getReplies();
		}
	}, []);

	const getReplies = async (pageIndex = 1, pageSize = 5, scrollToEnd = false, isCounterAdd = true) => {
		setEditEditId(0);
		setApiLoading(true);
		const response: any = await dispatch(
			getCommentsList(
				`moduleDetailsId=${props.commentDetailsId}&pageIndex=${pageIndex}&pageSize=${pageSize}&moduleId=2`
			)
		);
		if (Boolean(response) && response.data.status === 200) {
			setPagingInfo(response.data.pagingInfo ? response.data.pagingInfo : pagingInfo);
			if (response.data.pagingInfo?.pageIndex === 1) {
				setReplyListData(response.data.entity.commentDetailResponses);
				updateCount(props.commentDetailsId, isCounterAdd, 'comment', response.data.pagingInfo?.totalRecords);
			} else {
				if (response.data.entity.commentDetailResponses && response.data.pagingInfo?.pageIndex > 1) {
					setReplyListData([...replyListData, ...response.data.entity.commentDetailResponses]);
				} else if (
					response.data.entity.commentDetailResponses === null ||
					response.data.entity.commentDetailResponses.length === 0
				) {
					setReplyListData([]);
				}
				if (!response.data.pagingInfo?.pageIndex) {
					updateCount(props.commentDetailsId, isCounterAdd, 'comment', 0);
				}
			}

			//comment highlight
			let recognitionCommentHighlight: any = getLocalStorageItem('recognitionCommentHighlight');

			if (Boolean(recognitionCommentHighlight)) {
				recognitionCommentHighlight = JSON.parse(recognitionCommentHighlight);
				setSavedId(recognitionCommentHighlight.replyDetailsId);
				const selectedEl = document.getElementById(`${'commentPost-' + recognitionCommentHighlight.replyDetailsId}`);
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}
			setApiLoading(false);
			if (scrollToEnd) {
				let responseIndex = response.data.entity.commentDetailResponses.length - 1;
				if (
					responseIndex >= 0 &&
					response?.data?.entity?.commentDetailResponses &&
					response.data.entity.commentDetailResponses[responseIndex]
				) {
					setTimeout(() => {
						const selectedEl = document.getElementById(
							`${'commentPost-' + response.data.entity.commentDetailResponses[responseIndex].commentDetailsId}`
						);
						if (selectedEl) {
							selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
						}
					}, 500);
				}
			} else if (showReplyBox) {
				scrollTo('create-reply-box');
			}

			setTimeout(() => {
				setSavedId(0);
			}, 7000);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setModalProps({
			open: false,
			data: {},
		});
	};
	const handleDeleteComment = (handleDeleteComment: any) => {
		setModalProps({
			open: true,
			data: handleDeleteComment,
		});
		handleClose();
	};
	const handleConfirmClick = async () => {
		let data = `commentDetailsId=${modalProps.data.commentDetailsId}`;
		const response: any = await dispatch(deleteCommentsPost(data));

		if (Boolean(response) && response.data.status === 200) {
			// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
			// 	variant: 'success',
			// 	autoHideDuration: 5000,
			// });
			getReplies(1, pagingInfo.pageIndex * 5, false, false);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleEditComment = (editCommentId: any) => {
		setEditEditId(editCommentId.commentDetailsId);
	};

	const likeReplyList = async (item: any, like: any) => {
		setLikeAnimation(item.commentDetailsId);
		const response: any = await dispatch(
			likeRecognization({
				moduleDetailsId: item.commentDetailsId,
				moduleId: 4,
				employeeId: userDetail.employeeId,
				isActive: like,
			})
		);

		if (Boolean(response) && response.data.status === 200) {
			let counter = like ? 1 : -1;
			let replyListDataCopy = replyListData.map((listData: any) => listData);
			const index = replyListDataCopy.findIndex((listData: any) => item.commentDetailsId === listData.commentDetailsId);
			if (index > -1) {
				replyListDataCopy[index].isLiked = like;
				replyListDataCopy[index].totalLikes += counter;
			}
			setReplyListData(replyListDataCopy);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleOpen = async (event: any, item: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		let data = `moduleDetailsId=${item.commentDetailsId}&moduleId=4`;
		const response: any = await dispatch(getLikesList(data));
		if (Boolean(response) && response.data.status === 200) {
			setLikeData(response.data.entity.recognitionLikeResponses);
			setLikedId(item.commentDetailsId);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
		setLikeData([]);
		setLikedId(0);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 100 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};
	return (
		<>
			{replyListData.length > 0 &&
				replyListData.map((replyData: any) => {
					return replyData.commentDetailsId === editCommentId ? (
						<Box className='recog-post-comment-list'>
							<Box className='recog-comment-top'>
								<Box className='recog-user-info'>
									<Box className='user-img'>
										{replyData && replyData.imagePath ? (
											<Avatar
												className='avatar-default'
												alt={`${replyData.firstName} ${replyData.lastName}`}
												src={replyData.imagePath}
											/>
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													firstName: replyData.firstName,
													lastName: replyData.lastName,
													fullName: replyData.fullname,
												})}
											</Avatar>
										)}
									</Box>
								</Box>
								<CreateReply
									getReplies={getReplies}
									replyData={replyData}
									commentDetailsId={props.commentDetailsId}
									replyUser={replyData}
									updateCount={updateCount}
									replyPageIndex={pagingInfo.pageIndex}
									setShowReplyBox={setShowReplyBox}
									setEditEditId={setEditEditId}
								/>
							</Box>
						</Box>
					) : (
						<Box className='recog-replies-list'>
							<Box className='recog-comment-top'>
								<Box className='recog-user-info'>
									<Box className='user-img'>
										{replyData?.imagePath ? (
											<Avatar src={replyData?.imagePath} />
										) : (
											<Avatar>
												{getUserName({
													fullName: '',
													firstName: replyData?.firstName,
													lastName: replyData?.lastName,
												})}
											</Avatar>
										)}
									</Box>
								</Box>
								<Box className='recog-post-box'>
									<Box
										className={`${
											savedId === replyData.commentDetailsId ? 'recog-post-box-inner highlight' : 'recog-post-box-inner'
										}`}
									>
										<Box className='recog-post-head'>
											<Box className='recog-post-head-left'>
												<Typography>{replyData.fullName}</Typography>
												<Typography variant='h6' className='font-weight-normal'>
													{replyData.updatedOn ? getTime(replyData.updatedOn) : getTime(replyData.createdOn)}{' '}
													{replyData.isEdited ? `(${t('editedLabel')})` : ''}
												</Typography>
											</Box>
											<Box className='recog-post-head-right'>
												{replyData.employeeId === userDetail.employeeId && (
													<PopperMenu
														popperClassName={'popper-menu recog-post-popper-menu'}
														anchorEl={anchorEl}
														handleClose={handleClose}
														includeKebabMenu={true}
														menuClicked={popperMenuClicked}
														setPopperMenuClicked={setPopperMenuClicked}
														{...props}
														children={
															<>
																<ListItem
																	onClick={() => {
																		handleEditComment(replyData);
																	}}
																>
																	<ListItemAvatar>
																		<EditIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('editLabel')} />
																</ListItem>

																<ListItem
																	onClick={() => {
																		handleDeleteComment(replyData);
																	}}
																>
																	<ListItemAvatar>
																		<DeleteIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('delete')} />
																</ListItem>
															</>
														}
													/>
												)}
											</Box>
										</Box>
										<Box className='recog-post-content long-text' id={`${'commentPost-' + replyData.commentDetailsId}`}>
											<Typography
												variant='h5'
												className='font-weight-normal'
												dangerouslySetInnerHTML={{ __html: replyData.comments }}
											></Typography>
										</Box>
									</Box>
								</Box>
							</Box>
							<Box className='recog-comment-btm'>
								<Box className='recog-comment-btm-left'>
									{!replyData.isLiked ? (
										<OKRButton
											className='like-btn'
											handleClick={() => {
												likeReplyList(replyData, true);
											}}
											text={t('likeLevel')}
										/>
									) : (
										<Box className='like-filled-btn'>
											<OKRButton
												className={`like-btn ${likeAnimation === replyData.commentDetailsId ? 'btn-anim' : ''}`}
												icon={
													<>
														{likeAnimation === replyData.commentDetailsId && (
															<AnimatedIcon
																className='like-animation-img'
																width='60'
																type='image/svg+xml'
																alt='No user'
																imagePath={LikeImg}
															/>
														)}
													</>
												}
												text={<span className='remove-img'>{t('likedLevel1')}</span>}
												handleClick={() => {
													likeReplyList(replyData, false);
													setLikeAnimation(0);
												}}
											/>
										</Box>
									)}
									<OKRButton
										className='reply-btn'
										text={t('reply')}
										handleClick={() => {
											setShowReplyBox(true);
											setPrefilledUser(replyData);
											scrollTo('create-reply-box');
										}}
									/>
								</Box>
								<Box className='recog-comment-btm-right'>
									<List disablePadding>
										{
											<ListItem
												aria-owns={open ? 'mouse-over-popover' : undefined}
												aria-haspopup='true'
												onMouseEnter={(e: any) => {
													replyData.totalLikes > 0 && handleOpen(e, replyData);
												}}
												onMouseLeave={handleClose}
												className={replyData.totalLikes > 0 ? 'active' : ''}
											>
												<ListItemText
													className={replyData.totalLikes > 0 ? 'like-popup' : ''}
													primary={`${replyData.totalLikes} ${
														replyData.totalLikes > 1 ? t('likedLevel') : t('likeLevel')
													}`}
												/>
												{open && replyData.commentDetailsId === likedId && (
													<Box>
														<PopperMenu
															{...props}
															children={
																<>
																	{likeData.length > 0 &&
																		likeData.map((likeList: any) => {
																			return (
																				<ListItem>
																					<Box className='user-info'>
																						<Box className='user-img'>
																							{likeList?.imagePath ? (
																								<Avatar src={likeList?.imagePath} />
																							) : (
																								<Avatar>
																									{getUserName({
																										fullName: '',
																										firstName: likeList?.firstName,
																										lastName: likeList?.lastName,
																									})}
																								</Avatar>
																							)}
																						</Box>
																						<Box className='user-info-details'>
																							{likeList.fullName.length > 30 ? (
																								<Tooltip title={likeList.fullName} arrow>
																									<Typography variant='subtitle2'>
																										{likeList.fullName.substring(0, 30)}
																										...
																									</Typography>
																								</Tooltip>
																							) : (
																								<Typography>{likeList.fullName}</Typography>
																							)}

																							{likeList.emailId.length > 28 ? (
																								<Tooltip title={likeList.emailId} arrow>
																									<Typography variant='subtitle2'>
																										{likeList.emailId.substring(0, 28)}
																										...
																									</Typography>
																								</Tooltip>
																							) : (
																								<Typography variant='subtitle2'>{likeList.emailId}</Typography>
																							)}
																						</Box>
																					</Box>
																				</ListItem>
																			);
																		})}
																</>
															}
															popperClassName={'recog-liked-by-dropdown'}
															anchorEl={anchorEl}
															handleClose={handleClose}
															popperPlacement={'bottom'}
															open={open}
														/>
													</Box>
												)}
											</ListItem>
										}
									</List>
								</Box>
							</Box>
						</Box>
					);
				})}
			{showReplyBox && !apiLoading && (
				<Box className='recog-post-comment-list'>
					<Box className='recog-comment-top'>
						<Box className='recog-user-info'>
							<Box className='user-img'>
								{loggedInUserDetail && loggedInUserDetail.imagePath ? (
									<Avatar
										className='avatar-default'
										alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
										src={loggedInUserDetail.imagePath}
									/>
								) : (
									<Avatar className='avatar-default'>
										{getUserName({
											firstName: loggedInUserDetail.firstName,
											lastName: loggedInUserDetail.lastName,
											fullName: loggedInUserDetail.fullname,
										})}
									</Avatar>
								)}
							</Box>
						</Box>

						<CreateReply
							getReplies={getReplies}
							commentDetailsId={props.commentDetailsId}
							replyUser={replyUser}
							updateCount={updateCount}
							replyPageIndex={pagingInfo.pageIndex}
							setShowReplyBox={setShowReplyBox}
							setEditEditId={setEditEditId}
						/>
					</Box>
				</Box>
			)}

			<Box className='load-more-area'>
				{pagingInfo?.totalPages > 1 && pagingInfo?.totalPages !== pagingInfo.pageIndex && (
					<Box className='load-more'>
						<OKRButton
							handleClick={(e) => {
								getReplies(pagingInfo.pageIndex + 1 || 1, 5);
							}}
							className={`load-more-btn btn-small-text`}
							text={t('viewMoreReply')}
						/>
					</Box>
				)}
				<Box className='view-less'>
					<OKRButton
						handleClick={() => {
							props.setReplyCommentId(0);
							scrollTo(`${'recogCommentPost-' + props.commentDetailsId}`);
						}}
						className={`load-more-btn btn-small-text`}
						text={t('hideReply')}
					/>
				</Box>
			</Box>

			{modalProps.open && (
				<AlertDialog
					module='information'
					message={t('commentDelete')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={modalProps.open}
				/>
			)}
			{/* <OKRButton className='btn-small-text' text={t('viewMoreComments')} /> */}
		</>
	);
};
