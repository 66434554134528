import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { Collapse } from '@material-ui/core';

export const CommonMessages: React.FC<any> = (props) => {
	const { snackbarProps, setSnackbarProps } = props;
	const { show, message, variant } = snackbarProps;
	const { enqueueSnackbar } = useSnackbar();

	useEffect(() => {
		enqueueSnackbar(message, {
			variant: variant,
			autoHideDuration: 2000,
			TransitionComponent: Collapse,
		});
		return () => {
			setSnackbarProps({});
		};
	}, [show, enqueueSnackbar, message, setSnackbarProps, variant]);
	return null;
};
