import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	entity: {},
	entityList: [],
	forgotPasswordStatus: '',
	pending: false,
	messageList: [],
	error: {},
};

export default function forgotPasswordReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FORGOT_PASSWORD_PENDING:
			return {
				...state,
				pending: true,
				messageList: [],
			};
		case actionTypes.FORGOT_PASSWORD_SUCCESS:
			return {
				...state,
				pending: false,
				entity: action.payload.entity,
				forgotPasswordStatus: action.payload.status,
				messageList: action.payload.messageList,
			};
		case actionTypes.FORGOT_PASSWORD_FAILED:
			return {
				...state,
				pending: false,
				forgotPasswordStatus: 401,
				error: action.error,
			};
		case actionTypes.UPDATE_STATUS: {
			return {
				...state,
				entity: {},
				entityList: [],
				forgotPasswordStatus: '',
				pending: false,
				messageList: [],
				error: {},
			};
		}
		default:
			return state;
	}
}
