import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, Chip, FormLabel, List, ListItem, Tab, Tabs, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CrossIcon, InformationIcon, ReportsTooltipIcon, SortingIcon, TickIcon } from '../../config/svg/CommonSvgIcon';
import { OKRButton } from '../Common/OKRButton';
import { NoSearchRecord } from '../Admin/CommonComponent/NoSearchRecord';
import { ErrorIcon, NameIcon } from '../../config/svg/formElementIcons';
import { MultiSelect } from '../Admin/CommonComponent/MultiSelect';
import { CancelIcon } from '../../config/svg/Action';
import { useDispatch } from 'react-redux';
import { deleteNomineeData, getNomineeData, validateNominee } from '../../action/perform';
import { getUserDetails, getUserName } from '../../config/utils';
import { globalSearchAPIWithTeam } from '../../action/search';
import { NoRecord } from '../Admin/CommonComponent/NoRecord';
import { useSnackbar } from 'notistack';
import AlertDialog from '../Common/Dialog';
import { DeleteIcon } from '../../config/svg/CommonSvg';

export const NominateDrawer: React.FC<any> = (props: any) => {
	const {
		nominatedUser,
		appendSelectedUser,
		selectedUser,
		setSelectedUser,
		checkinSettingList,
		paggingInfo,
		getNominee,
		performanceNomineeId,
		setCheckinSettingList,
		setSelectedUserList,
		selectedUserList,
	} = props;
	const [modalProps, setModalProps] = React.useState<any>({
		open: false,
		data: {},
	});
	const { t } = useTranslation();
	const [nominateTabSelected, setNominateTabSelected] = useState<any>({ activeTab: 0 });
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});
	let userDetail = getUserDetails();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const handleTabChange = (event: any, newValue: Number) => {
		setNominateTabSelected({ activeTab: newValue });
	};

	function a11yProps(index: number) {
		return {
			id: `nominate-tab-${index}`,
			'aria-controls': `nominate-tabpanel-${index}`,
		};
	}

	const globalSearchWithTeam = async (data: any, finder: any, pageIndex: number, pageSize: number) => {
		return dispatch(globalSearchAPIWithTeam(finder, pageIndex, pageSize, 1));
	};

	const validateSelectedUser = async (users: any) => {
		if (users.length > 0) {
			let user: any = users[users.length - 1];
			if (users.length < selectedUser.length) {
				setSelectedUser(users);
			} else {
				const index = checkinSettingList.findIndex((item: any) => item.employeeId === user.employeeId);
				if (index > -1) {
					let usersList: any[] = [...users];
					delete usersList[users.length - 1];
					setSelectedUser(usersList);
					setReportingToOptions([]);
					setEmptyRecords({ ...emptyRecords, reportingTo: false });
					enqueueSnackbar(t('userAlreadyNominated'), {
						variant: 'error',
						autoHideDuration: 5000,
					});
					return;
				}
				let data = `performanceCycleId=${props.performanceNomineeId}&nomineeEmployeeId=${user.employeeId}&nomineeForEmployeeId=${nominatedUser?.employeeId}`;
				const response: any = await dispatch(validateNominee(data));
				if (Boolean(response) && response.data.status === 200) {
					if (response.data.entity) {
						let usersList: any[] = [...users];
						delete usersList[users.length - 1];
						setSelectedUser(usersList);
						setReportingToOptions([]);
						setEmptyRecords({ ...emptyRecords, reportingTo: false });
						enqueueSnackbar(t('userAlreadyNominated'), {
							variant: 'error',
							autoHideDuration: 5000,
						});
					} else {
						setSelectedUser(users);
						setReportingToOptions([]);
						setEmptyRecords({ ...emptyRecords, reportingTo: false });
					}
				} else {
					enqueueSnackbar(t('someErrorOccurred'), {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		} else {
			setSelectedUser([]);
		}
	};

	const saveSelectedUser = () => {
		appendSelectedUser();
	};

	const handleDelete = (user: any) => {
		setModalProps({
			open: true,
			data: user,
		});
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			deleteSelectedUser(modalProps.data);
		}
		setModalProps({
			open: false,
			data: {},
		});
	};
	const deleteSelectedUser = async (user: any) => {
		if (user.isNew) {
			let selectedIds = [...checkinSettingList];
			const index = selectedIds.findIndex((item) => item.employeeId === user.employeeId);
			if (index > -1) {
				selectedIds.splice(index, 1);
			}
			setCheckinSettingList(selectedIds);

			let selectedTempIds = [...selectedUserList];
			const tempIndex = selectedTempIds.findIndex((item) => item.employeeId === user.employeeId);
			if (tempIndex > -1) {
				selectedTempIds.splice(tempIndex, 1);
			}
			setSelectedUserList(selectedTempIds);
			return;
		}

		let data = `performanceCycleId=${props.performanceNomineeId}&nomineeEmployeeId=${user.employeeId}&nomineeForEmployeeId=${nominatedUser?.employeeId}`;
		const response: any = await dispatch(deleteNomineeData(data));

		if (Boolean(response) && response.data.status === 200) {
			enqueueSnackbar(response?.data?.messageList?.nomineeEmployeeId, {
				variant: 'success',
				autoHideDuration: 5000,
			});
			getNominee(1, 10);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	return (
		<Fragment>
			<Tabs
				className='okr-tabs drawer-tabs'
				value={nominateTabSelected?.activeTab || 0}
				onChange={handleTabChange}
				aria-label='Nominate Tabs'
			>
				<Tab label={<>{t('manageNominees')}</>} value={0} {...a11yProps(0)} />
			</Tabs>

			<Box className='drawer-tabs-panel'>
				<Box className='nominate-drawer-panel'>
					<Box className='information-message'>
						<Typography variant='h6' className='no-sentence-case'>
							<ReportsTooltipIcon />
							<span dangerouslySetInnerHTML={{ __html: t('nominateInfoText') }}></span>
						</Typography>
					</Box>

					{nominateTabSelected?.activeTab === 0 && (
						<form className='drawer-form-fields' noValidate autoComplete='off'>
							<Box className='drawer-input-field'>
								<Box className='user-info selected-user-info'>
									<Box className='user-img'>
										<Avatar
											className='avatar-default'
											key={`user-icon-${nominatedUser.employeeId}`}
											src={nominatedUser.imagePath || ''}
										>
											{getUserName({
												firstName: nominatedUser.firstName || '',
												lastName: nominatedUser.lastName || '',
												fullName: nominatedUser.label || '',
											})}
										</Avatar>
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4' className='font-weight-normal'>
											{nominatedUser?.firstName} {nominatedUser?.lastName}
										</Typography>
										{/* <Typography variant='h6'>{nominatedUser?.emailId}</Typography> */}
									</Box>
								</Box>
							</Box>
							<Box className='drawer-input-field'>
								<Box
									className={`helperText-bottom ${
										selectedUser && selectedUser.length > 0 ? 'nominate-search-active' : ''
									}`}
								>
									<FormLabel>
										<NameIcon /> <span dangerouslySetInnerHTML={{ __html: t('assessee360degree') }}></span>
									</FormLabel>
									<MultiSelect
										key={'reportingTo'}
										id='recognize-to-dropdown'
										selectedOptions={selectedUser}
										optionsList={reportingToOptions}
										onSelectOption={(value: any) => {
											validateSelectedUser(value);
										}}
										blurInputOnSelect={false}
										isMulti={true}
										customFilter={() => {}}
										setOptionsList={setReportingToOptions}
										placeHolder={t('srcNominatePlaceholder')}
										noOptionsMessage={<NoSearchRecord />}
										showNoOptions={emptyRecords.reportingTo === true}
										fetchAsyncData={true}
										isSingleSelection={false}
										closeMenuOnSelect={true}
										labelTemplate={'avatarLabel'}
										selectClassName={`select-search-dropdown select-search-icon`}
										selectClassNamePrefix={'react-select'}
										error={''}
										isLoadMore={true}
										helperText={''}
										onInputFocus={() => {}}
										disabled={props.disabled}
										hideLoggedIn={true}
										loggedInUserIds={[userDetail.employeeId, nominatedUser?.employeeId]}
										performSearch={globalSearchWithTeam}
									/>
									{selectedUser && selectedUser.length > 0 && (
										<Box className='textfield-action-btn nominate-actions'>
											{/* <OKRButton className='btn-secondary' text={'Add'} /> */}
											<OKRButton
												className='cancel-btn'
												id={'cancel-btn'}
												handleClick={() => {
													setSelectedUser([]);
												}}
												icon={<CancelIcon />}
											/>
											<OKRButton
												className={`save-btn`}
												handleClick={() => {
													saveSelectedUser();
												}}
												id={'save-btn'}
												icon={<TickIcon />}
											/>
										</Box>
									)}
								</Box>
							</Box>

							<Box className='drawer-input-field drawer-blue-bg-full'>
								{checkinSettingList && checkinSettingList.length > 0 ? (
									<Box className={'users-listing users-listing-three-col'}>
										<Box className='userCount'>
											<Typography variant='subtitle2'>{`(${nominatedUser.nomineeApproveCount}/${
												paggingInfo.totalRecords + selectedUserList.length
											} ${t('assessed')})`}</Typography>
										</Box>

										<Fragment>
											<Box className='okr-list-panel-head'>
												<List component='ul' disablePadding>
													<ListItem className='user-col1'>
														<Typography
															variant='body2'
															className={`sort-title ${props.sortOrder === 'asc' ? 'desc' : ''}`}
															id='sort-name'
														>
															<span className='sortable-label'>
																<span className='txt-wrap'>{t('nameLabel')}</span>
															</span>
														</Typography>
													</ListItem>
													<ListItem className='user-col2'>
														<Typography variant='body2' className={'sort-title'} id='sort-role'>
															<span className={`sortable-label `}>
																<span className='txt-wrap'>{t('Status')}</span>
															</span>
														</Typography>
													</ListItem>
													<ListItem className='user-col3'>
														<Typography variant='body2' className={'sort-title'} id='sort-email'>
															<span className={`sortable-label `}>
																<span className='txt-wrap'>{t('emailLabel')}</span>
															</span>
														</Typography>
													</ListItem>
													<ListItem className='user-col4'></ListItem>
												</List>
											</Box>
											<Box className='users-list-content'>
												<Fragment>
													{checkinSettingList &&
														checkinSettingList.length > 0 &&
														checkinSettingList.map((item: any) => {
															return (
																<Box className='users-list-row' key={`selectedUser`} id={`selected-user-`}>
																	<List component='ul' disablePadding>
																		<ListItem className='user-col1'>
																			<Box className='user-info v-center'>
																				<Box className='user-img'>
																					<Avatar
																						className='avatar-default'
																						key={`user-icon-${item.employeeId}`}
																						src={item.imagePath || ''}
																					>
																						{getUserName({
																							firstName: item.firstName || '',
																							lastName: item.lastName || '',
																							fullName: item.fullName || '',
																						})}
																					</Avatar>
																				</Box>
																				<Box className='user-info-details'>
																					{(item.firstName + item.lastName).length > 26 ? (
																						<Tooltip title={item.firstName + ' ' + item.lastName} arrow>
																							<Typography variant='h4' className='font-weight-normal'>
																								{(item.firstName + ' ' + item.lastName).substring(0, 26)}...
																							</Typography>
																						</Tooltip>
																					) : (
																						<Typography
																							variant='h4'
																							className='font-weight-normal'
																						>{`${item.firstName} ${item.lastName}`}</Typography>
																					)}
																					{/* {item?.designation && item?.designation.length > 35 ? (
																						<Tooltip arrow title={item?.designation} aria-label='designation'>
																							<Typography variant='subtitle2'>
																								{item?.designation.substring(0, 35)}...
																							</Typography>
																						</Tooltip>
																					) : (
																						<Typography variant='subtitle2'>{item?.designation}</Typography>
																					)} */}
																				</Box>
																			</Box>
																		</ListItem>
																		<ListItem className='user-col2'>
																			<Box className='chip-group'>
																				{item.isNew ? (
																					<Typography variant='h4' className='font-weight-normal blank-dash'>
																						- -
																					</Typography>
																				) : (
																					<Chip
																						className={'chip'}
																						label={item.status === 2 ? 'Assessment Done' : 'Assessment Pending'}
																						component='h2'
																					/>
																				)}
																			</Box>
																		</ListItem>
																		<ListItem className='user-col3'>
																			{item?.emailId && item?.emailId.length > 32 ? (
																				<Tooltip arrow title={item?.emailId} aria-label='Email'>
																					<Typography variant='h4' className='font-weight-normal'>
																						{item?.emailId.substring(0, 32)}...
																					</Typography>
																				</Tooltip>
																			) : (
																				<Typography variant='h4' className='font-weight-normal'>
																					{item?.emailId}
																				</Typography>
																			)}
																		</ListItem>
																		<ListItem className='user-col4'>
																			<Tooltip arrow title={''}>
																				<OKRButton
																					title={t('delete')}
																					icon={<DeleteIcon />}
																					className={'btn-cross'}
																					handleClick={() => {
																						handleDelete(item);
																					}}
																				/>
																			</Tooltip>
																		</ListItem>
																	</List>
																</Box>
															);
														})}
												</Fragment>
												{paggingInfo?.totalPages > 1 && paggingInfo?.totalPages !== paggingInfo.page && (
													<Box className='list-loader-wrap'>
														<List className='load-more-ul' disablePadding>
															<ListItem className='load-more'>
																<OKRButton
																	handleClick={(e) => {
																		getNominee(paggingInfo.page + 1 || 1, 10);
																	}}
																	className={`load-more-btn btn-small-text`}
																	text={t('loadMore')}
																/>
															</ListItem>
														</List>
													</Box>
												)}
											</Box>
										</Fragment>
									</Box>
								) : (
									<NoRecord noRecordMessage={'You can nominate multiple members'} subHead={' '} />
								)}
							</Box>
						</form>
					)}
				</Box>
			</Box>

			{modalProps.open && (
				<AlertDialog
					module='information'
					message={modalProps?.data?.status === 2 ? t('deleteNominee') : t('deleteNonNominee')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={modalProps.open}
				/>
			)}
		</Fragment>
	);
};
