import React, { Fragment, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	FormLabel,
	List,
	ListItem,
	Popper,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DefaultObjTab, DefaultObjTabPanel } from './DefaultObjTab';
import { DesignationRole } from './DesignationRole';
import { JobLevelRole } from './JobLevelRole';
import { UsersRole } from './UsersRole';
import { Enums } from '../../../../../../config/enums';
import { DialogComponent } from '../../../../CommonComponent/DialogComponent';
import AlertDialog from '../../../../../Common/Dialog';

export const AssignDefaultObjDrawer: React.FC<any> = (props: any) => {
	const {
		selectedAssignTab,
		handleTabChange,
		defaultOkrDesignationDetails,
		defaultOkPerformanceRoleDetails,
		setDefaultOkrFormEdited,
		selectedDesignationOptions,
		setSelectedDesignationOptions,
		selectedPerformanceRoleOptions,
		setSelectedPerformanceRoleOptions,
		selectedRoleTab,
		setSelectedRoleTab,
		selectedUsersOptions,
		setSelectedUsersOptions,
		handleUpdateAssignIds,
		defaultOkrFormDetails,
		allEmployeeChecked,
		handleAllEmployeeSelection,
		setAllEmployeeChecked,
		handleSelectedRoleDeletion,
	} = props;
	const { t } = useTranslation();

	const [modalPerformanceProps, setModalPerformanceProps] = useState<any>({
		open: false,
		message: '',
		details: '',
		type: '',
	});
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });

	useEffect(() => {
		if (defaultOkrFormDetails && defaultOkrFormDetails?.assignType) {
			if (defaultOkrFormDetails?.assignType === Enums.ONE) {
				setSelectedRoleTab(Enums.ZERO);
				const updateDetails: any = [];

				defaultOkrDesignationDetails &&
					defaultOkrDesignationDetails?.length &&
					defaultOkrDesignationDetails.forEach((item: any) => {
						const isExist = defaultOkrFormDetails?.assignIds?.find((rec: any) => rec?.assignId === item?.designationId);
						if (isExist) {
							updateDetails.push({
								label: item?.designationName || '',
								value: item?.designationId || 0,
								userCount: item?.userCount || 0,
								id: item?.designationId || 0,
							});
						}
					});
				setSelectedDesignationOptions(updateDetails);
			} else if (defaultOkrFormDetails?.assignType === Enums.TWO) {
				setSelectedRoleTab(Enums.ONE);
				const updateDetails: any = [];

				defaultOkPerformanceRoleDetails &&
					defaultOkPerformanceRoleDetails?.length &&
					defaultOkPerformanceRoleDetails.forEach((item: any) => {
						const isExist = defaultOkrFormDetails?.assignIds?.find(
							(rec: any) => rec?.assignId === item?.performanceRoleId
						);
						if (isExist) {
							updateDetails.push({
								label: item?.performanceRoleName || '',
								value: item?.performanceRoleId || 0,
								userCount: item?.userCount || 0,
								id: item?.performanceRoleId || 0,
							});
						}
					});
				setSelectedPerformanceRoleOptions(updateDetails);
			} else if (defaultOkrFormDetails?.assignType === Enums.THREE) {
				setSelectedRoleTab(Enums.TWO);
				const updateDetails = defaultOkrFormDetails?.assignIds?.map((item: any) => {
					return {
						label: item?.name || '',
						value: item?.assignId || 0,
						userCount: item?.userCount || 0,
						id: item?.assignId || 0,
						firstName: item?.firstName || '',
						lastName: item?.lastName || '',
						employeeId: item?.employeeId || '',
						imagePath: item?.imagePath || '',
						emailId: item?.emailId || '',
						isActive: item?.isActive,
					};
				});
				setSelectedUsersOptions(updateDetails);
			} else if (defaultOkrFormDetails?.assignType === Enums.FOUR) {
				setSelectedRoleTab(Enums.ZERO);
				setAllEmployeeChecked(true);
			} else {
				setSelectedRoleTab(Enums.ZERO);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const a11yProps = (index: number) => {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	};

	const handleConfirmationCloseModal = async (e: any, type: any) => {
		try {
			// e.preventDefault();
			if (type === 1) {
				const { details } = modalProps || {};
				if (details && details?.deleteType === 'OkrRoleTabChange') {
					const { newValue, callType } = details || {};
					if (callType) {
						handleAllEmployeeSelection(newValue); // newValue is event for this
					} else {
						setTabValue(newValue);
					}
				}
				setModalProps({ open: false, message: '', details: '' });
			} else {
				setModalProps({ open: false, message: '', details: '' });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalPerformanceProps?.type === 'OkrRoleTabChange') {
				const { newValue, callType } = modalPerformanceProps?.details || {};
				if (callType) {
					handleAllEmployeeSelection(newValue); // newValue is event for this
				} else {
					setTabValue(newValue);
				}
			}

			setModalPerformanceProps({ open: false, message: '', details: '', type: '' });
		} else {
			setModalPerformanceProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const showConfirmationAlert = (newValue: Number, callType: boolean) => {
		if (callType) {
			if (defaultOkrFormDetails?.status === Enums.TWO) {
				setModalProps({
					open: true,
					message: '',
					details: {
						deleteType: 'OkrRoleTabChange',
						headingText: t('changingTabOnRoleDrawerEdit'),
						subHeading: t('Do you still want to change it?'),
						confirmMsg: 'To confirm, please enter ',
						matchString: Enums.CHANGE_TEXT,
						newValue,
						callType,
					},
				});
			} else {
				setModalPerformanceProps({
					open: true,
					message: t('changingTabOnRoleDrawer'),
					details: { newValue, callType },
					type: 'OkrRoleTabChange',
				});
			}
		} else {
			if (defaultOkrFormDetails?.status === Enums.TWO) {
				setModalProps({
					open: true,
					message: '',
					details: {
						deleteType: 'OkrRoleTabChange',
						headingText: t('changingTabOnRoleDrawerEdit'),
						subHeading: t('Do you still want to change it?'),
						confirmMsg: 'To confirm, please enter ',
						matchString: Enums.CHANGE_TEXT,
						newValue,
					},
				});
			} else {
				setModalPerformanceProps({
					open: true,
					message: t('changingTabOnRoleDrawer'),
					details: { newValue },
					type: 'OkrRoleTabChange',
				});
			}
		}
	};
	const setTabValue = (newValue: Number) => {
		// console.log(newValue);
		setSelectedRoleTab(newValue);
		setSelectedDesignationOptions([]);
		setSelectedPerformanceRoleOptions([]);
		setSelectedUsersOptions([]);
	};
	const handleAllEmployeeSelectionDetails = (e: any) => {
		try {
			const { checked } = e?.target || {};
			if (
				(selectedPerformanceRoleOptions && selectedPerformanceRoleOptions?.length) ||
				(selectedDesignationOptions && selectedDesignationOptions?.length) ||
				(selectedUsersOptions && selectedUsersOptions?.length)
			) {
				showConfirmationAlert(checked, true);
			} else {
				handleAllEmployeeSelection(checked);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleRoleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		if (newValue === Enums.ZERO) {
			if (
				allEmployeeChecked ||
				(selectedPerformanceRoleOptions && selectedPerformanceRoleOptions?.length) ||
				(selectedUsersOptions && selectedUsersOptions?.length)
			) {
				showConfirmationAlert(newValue, false);
			} else {
				setTabValue(newValue);
			}
		} else if (newValue === Enums.ONE) {
			if (
				allEmployeeChecked ||
				(selectedDesignationOptions && selectedDesignationOptions?.length) ||
				(selectedUsersOptions && selectedUsersOptions?.length)
			) {
				showConfirmationAlert(newValue, false);
			} else {
				setTabValue(newValue);
			}
		} else if (newValue === Enums.TWO) {
			if (
				allEmployeeChecked ||
				(selectedPerformanceRoleOptions && selectedPerformanceRoleOptions?.length) ||
				(selectedDesignationOptions && selectedDesignationOptions?.length)
			) {
				showConfirmationAlert(newValue, false);
			} else {
				setTabValue(newValue);
			}
		}
	};
	const getSelectedRecordCount = () => {
		let selectedCount = 0;
		if (selectedRoleTab === Enums.ZERO) {
			selectedCount = selectedDesignationOptions ? selectedDesignationOptions?.length : 0;
		} else if (selectedRoleTab === Enums.ZERO) {
			selectedCount = selectedPerformanceRoleOptions ? selectedPerformanceRoleOptions?.length : 0;
		} else if (selectedRoleTab === Enums.ZERO) {
			selectedCount = selectedUsersOptions ? selectedUsersOptions?.length : 0;
		}
		return selectedCount ? `(${selectedCount})` : '';
	};

	return (
		<>
			<Box className='drawer-input-field'>
				<Tabs
					className='okr-tabs drawer-tabs'
					value={selectedAssignTab}
					onChange={handleTabChange}
					aria-label='Admin Tabs'
				>
					<Tab label={`Assign${getSelectedRecordCount()}`} value={0} {...a11yProps(0)} />
				</Tabs>
			</Box>

			<Box className='drawer-tabs-panel default-obj-role-drawer'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<Box className='drawer-field-space role-tab-selection'>
						<DefaultObjTab
							{...props}
							selectedRoleTab={selectedRoleTab}
							handleRoleTabChange={handleRoleTabChange}
							allEmployeeChecked={allEmployeeChecked}
							handleAllEmployeeSelection={handleAllEmployeeSelectionDetails}
						/>
					</Box>
					<Box className=''>
						<DefaultObjTabPanel value={selectedRoleTab} index={0}>
							<DesignationRole
								defaultOkrDesignationDetails={defaultOkrDesignationDetails}
								setDefaultOkrFormEdited={setDefaultOkrFormEdited}
								selectedDesignationOptions={selectedDesignationOptions}
								setSelectedDesignationOptions={setSelectedDesignationOptions}
								handleUpdateAssignIds={handleUpdateAssignIds}
								defaultOkrFormDetails={defaultOkrFormDetails}
								allEmployeeChecked={allEmployeeChecked}
								handleSelectedRoleDeletion={handleSelectedRoleDeletion}
							/>
						</DefaultObjTabPanel>
						<DefaultObjTabPanel value={selectedRoleTab} index={1}>
							<JobLevelRole
								defaultOkPerformanceRoleDetails={defaultOkPerformanceRoleDetails}
								setDefaultOkrFormEdited={setDefaultOkrFormEdited}
								selectedPerformanceRoleOptions={selectedPerformanceRoleOptions}
								setSelectedPerformanceRoleOptions={setSelectedPerformanceRoleOptions}
								handleUpdateAssignIds={handleUpdateAssignIds}
								defaultOkrFormDetails={defaultOkrFormDetails}
								allEmployeeChecked={allEmployeeChecked}
								handleSelectedRoleDeletion={handleSelectedRoleDeletion}
							/>
						</DefaultObjTabPanel>
						<DefaultObjTabPanel value={selectedRoleTab} index={2}>
							<UsersRole
								setDefaultOkrFormEdited={setDefaultOkrFormEdited}
								selectedUsersOptions={selectedUsersOptions}
								setSelectedUsersOptions={setSelectedUsersOptions}
								handleUpdateAssignIds={handleUpdateAssignIds}
								defaultOkrFormDetails={defaultOkrFormDetails}
								allEmployeeChecked={allEmployeeChecked}
								handleSelectedRoleDeletion={handleSelectedRoleDeletion}
							/>
						</DefaultObjTabPanel>
					</Box>
				</form>
			</Box>
			{modalPerformanceProps && modalPerformanceProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalPerformanceProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalPerformanceProps.open}
				/>
			)}
			{modalProps && modalProps.open && (
				<DialogComponent
					module={modalProps.module || 'defaultKR'}
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleConfirmationCloseModal(e, 2)}
					matchString={modalProps?.details?.matchString || Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleConfirmationCloseModal(e, 1)}
					confirmMsg={modalProps?.details?.confirmMsg || ''}
					headingText={modalProps?.details?.headingText || t('deletePerformanceCycle')}
					subHeading={modalProps?.details?.subHeading || t('deletePerformanceCycleSubHead')}
				/>
			)}
		</>
	);
};
