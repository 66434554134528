import { getRequest, postRequest, putRequest, deleteRequest } from '../config/auth';
import {
	LIST_ORG,
	ADD_ORG,
	ADD_PARENT,
	GET_ORGANIZATION,
	ADD_CHILD,
	UPDATE_ORGANIZATION,
	DELETE_ORGANIZATION,
	UPLOAD_LOGO,
	GET_ALL_USERS,
	EDIT_CHILD,
	GET_PARENT_TEAM,
	GET_CYCLE_DATA,
	GET_PARENT_ORGANSATION,
	UPDATE_PARENT_ORGANSATION,
	ADD_TEAM,
	DELETE_TEAM,
	ASSIGNED_EMPLOYEE_TEAM,
	ORG_CHART_VIEW,
	CHECKIN_MASTER_DATA,
	MANAGE_LIST_ORG,
	ADD_BULK_TEAM,
	GET_MANAGE_BADGES,
	ADD_BADGES,
	BADGES_STATUS,
	DELETE_BADGES,
	UPDATE_ALIGN_SETTINGS,
	UPDATE_ENGAGE_SETTINGS
} from '../config/api-url';

export const getOrgListApi = (data) => getRequest(`${LIST_ORG}`, data);
export const getOrgViewApi = (data) => getRequest(`${ORG_CHART_VIEW}`, data);
export const addOrgAPI = (data) => postRequest(`${ADD_ORG}`, data);
export const addParentAPI = (data) => postRequest(`${ADD_PARENT}`, data);
export const getOrganizationAPI = (data) => getRequest(`${GET_ORGANIZATION}?${data}`, data);
export const addChildAPI = (data) => postRequest(`${ADD_CHILD}`, data);
export const updateChildAPI = (data) => putRequest(`${EDIT_CHILD}`, data);
export const updateOrganizationAPI = (data) => putRequest(`${UPDATE_ORGANIZATION}`, data);
export const deleteOrganizationAPI = (data) => deleteRequest(`${DELETE_ORGANIZATION}?${data}`, data);
export const uploadLogoAPI = (data) => postRequest(`${UPLOAD_LOGO}`, data);
export const getAllUsersAPI = (data) => getRequest(`${GET_ALL_USERS}?${data}`);
export const getParentTeamsAPI = (data) => getRequest(`${GET_PARENT_TEAM}?${data}`);
export const getCycleMasterDataAPI = (data) => getRequest(`${GET_CYCLE_DATA}`);
export const getParentOrganizationAPI = (data) => getRequest(`${GET_PARENT_ORGANSATION}`, data);
export const updateParentOrganizationAPI = (data) => putRequest(`${UPDATE_PARENT_ORGANSATION}`, data);
export const addTeamAPI = (data) => postRequest(`${ADD_TEAM}`, data);
export const updateTeamAPI = (data) => putRequest(`${ADD_TEAM}`, data);
export const deleteTeamAPI = (data) => deleteRequest(`${DELETE_TEAM}?${data}`, data);
export const assignedEmployeeTeamAPI = (data) => getRequest(`${ASSIGNED_EMPLOYEE_TEAM}/${data}`);
export const getCheckinMasterDataAPI = (data) => getRequest(`${CHECKIN_MASTER_DATA}`);
export const getManageOrgListAPI = (data) => postRequest(`${MANAGE_LIST_ORG}`, data);
export const addBulkTeamUploadAPI = (data) => postRequest(`${ADD_BULK_TEAM}`, data);
export const getManageBadgesAPI = (data) => postRequest(`${GET_MANAGE_BADGES}`, data);
export const addBadgesAPI = (data) => postRequest(`${ADD_BADGES}`, data);
export const badgesStatusApi = (data) => postRequest(`${BADGES_STATUS}`, data);
export const deleteBadgesApi = (data) => deleteRequest(`${DELETE_BADGES}?${data}`, data);
export const updatedManageBadgesAPI = (data) => putRequest(`${ADD_BADGES}`, data);
export const updateAlignSettingsAPI = (data) => putRequest(`${UPDATE_ALIGN_SETTINGS}`, data);
export const updateEngageSettingsAPI = (data) => putRequest(`${UPDATE_ENGAGE_SETTINGS}`, data);
