import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';

import { Enums } from '../../../../config/enums';
import { DueDateIcon, ResolveIcon } from '../../../../config/svg/CommonSvg';
import { FeedbackResolve } from './FeedbackResolve';
import { FeedbackReviewersComment } from './FeedbackReviewersComment';

export const FeedbackViewTabs = (props: any) => {
	const {
		feedbackFormType,
		feedbackDetails,
		creatorDetailsVal,
		reviewerDetailsVal,
		loader,
		setIsFormEdited,
		setLoader,
		showMsg,
		userDetails,
		resolveStatusTypes,
		getFeedbackViewDetails,
		select1on1Tab,
		setSelect1on1Tab,
		isUserHaveFeedbackEditPermission,
		handleTabChange,
		feedbackItem,
	} = props;
	const { t } = useTranslation();

	function a11yProps(index: number) {
		return {
			id: `user-tab-${index}`,
			'aria-controls': `user-tab-tabpanel-${index}`,
		};
	}

	return (
		<Box className='pip-view-tabs'>
			<Box className='pip-view-tab-head'>
				<Tabs
					id={'feedback_tas_main_component'}
					className='okr-tab-button okr-tab-white-button'
					value={select1on1Tab}
					onChange={handleTabChange}
					aria-label='User Tabs'
				>
					<Tab
						label={
							<>
								<DueDateIcon />
								{t('reviews')}
							</>
						}
						value={Enums.ONE}
						{...a11yProps(Enums.ONE)}
					/>
					{feedbackDetails?.nomineeDetails?.employeeId !== userDetails?.employeeId &&
					(feedbackDetails?.feedbackReviewerResponse?.find((rec: any) => rec?.reviewerId === userDetails?.employeeId) ||
						isUserHaveFeedbackEditPermission()) && (feedbackDetails?.resolvedOn || select1on1Tab === Enums.TWO)
					 &&
						feedbackDetails.isFeedbackEditRights &&
					feedbackDetails?.timeframeStartDate &&
					feedbackDetails?.timeframeEndDate ? (
						<Tab
							label={
								<>
									<ResolveIcon />
									{t('resolve')}
								</>
							}
							value={Enums.TWO}
							{...a11yProps(Enums.TWO)}
						/>
					) : (
						<></>
					)}
				</Tabs>
			</Box>
			<Box className='pip-view-tab-content'>
				{select1on1Tab === Enums.ONE && (
					<>
						<FeedbackReviewersComment
							loader={loader}
							feedbackItem={feedbackItem}
							feedbackDetails={feedbackDetails}
							creatorDetailsVal={creatorDetailsVal}
							reviewerDetailsVal={reviewerDetailsVal}
							setIsFormEdited={setIsFormEdited}
							setLoader={setLoader}
							showMsg={showMsg}
							userDetails={userDetails}
							feedbackFormType={feedbackFormType}
						/>
					</>
				)}
				{select1on1Tab === Enums.TWO && (
					<>
						<FeedbackResolve
							setIsFormEdited={setIsFormEdited}
							loader={loader}
							setLoader={setLoader}
							showMsg={showMsg}
							resolveStatusTypes={resolveStatusTypes}
							handleTabChange={handleTabChange}
							feedbackDetails={feedbackDetails}
							getFeedbackViewDetails={getFeedbackViewDetails}
							feedbackFormType={feedbackFormType}
						/>
					</>
				)}
			</Box>
		</Box>
	);
};
