import React, { useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { DASHBOARD, HOME, LOGIN_INITIATOR_URL, ORGANIZATIONS, PERFORM, RECOGNIZE } from '../../config/app-url';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
	currentDashboardTab,
	currentDashboardTabForSO,
	setNomineeDetailsForRedirection,
	updateRoute,
} from '../../action/common';

export default function SsoLogin(props) {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let history = useHistory();
	useEffect(() => {
		//clearLocalStorage();
		removeLocalStorageItem('currentUser');
		let queryString = props.location.search.split('&appid=');
		let appId = queryString[1] ? queryString[1] : '';
		let qsessionId = queryString[0].split('?sessionid=');
		let sessionId = qsessionId[1] ? qsessionId[1] : '';

		if (sessionId && appId) {
			let data = {
				sessionId: sessionId,
				appId: appId,
			};
			ssoLogin(data);
		} else {
			window.location = LOGIN_INITIATOR_URL;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history, props.location.search]);

	const ssoLogin = async (data) => {
		try {
			const resp = await props.ssoLoginAction(data);
			if (resp.status === 200 && resp.data.status === 200) {
				setLocalStorageItem('signInStatus', resp.data.status);
				setLocalStorageItem('userDetail', JSON.stringify(resp.data.entity));
				setLocalStorageItem('tokenId', JSON.stringify(resp.data.entity.tokenId));
				setLocalStorageItem('accessTokenId', JSON.stringify(resp.accessToken));
				setLocalStorageItem('isAbleToDesign', false);

				let redirectUrl = getLocalStorageItem('redirectUrl');
				const empId = getLocalStorageItem('empId');
				if (Boolean(redirectUrl)) {
					redirectUrl = JSON.parse(redirectUrl);
					if (redirectUrl.url && redirectUrl.url.includes('/')) {
						if (
							redirectUrl.url.includes('Organization') &&
							resp.data.entity.loggedInAs &&
							resp.data.entity.loggedInAs === 'Admin'
						) {
							setLocalStorageItem('LoginAs', 'admin');
							props.history.push(ORGANIZATIONS);
						} else if (
							redirectUrl.url.includes('perform') &&
							empId &&
							parseInt(empId) === resp.data.entity.employeeId
						) {
							const urlItem = redirectUrl?.url.split('/');
							const performanceCycleId = urlItem[1] ? parseInt(urlItem[1]) : 0;
							const threeSixtyStatus = urlItem[2] ? parseInt(urlItem[2]) : 0;
							const nomineeForEmpId = urlItem[3] ? parseInt(urlItem[3]) : 0;
							const payload = {
								cycleId: performanceCycleId,
								employeeId: empId,
								threeSixtyStatus,
								nomineeForEmpId,
							};
							setTimeout(() => {
								dispatch(currentDashboardTabForSO(false));
								threeSixtyStatus === 3 && dispatch(setNomineeDetailsForRedirection(payload));
								dispatch(currentDashboardTab(threeSixtyStatus === 3 ? 2 : 0));
								dispatch(updateRoute(PERFORM));
							}, 3000);
							history.push(PERFORM);
						} else if (
							redirectUrl.url.includes('recognize') &&
							empId &&
							parseInt(empId) === resp.data.entity.employeeId
						) {
							const itemArray = redirectUrl.url.split('/');
							let recognitionId = 0;
							let commentDetailsId = 0;
							if (itemArray.length > 0) {
								recognitionId = itemArray[1] ? parseInt(itemArray[1]) : 0;
								setLocalStorageItem(
									'recognitionHighlight',
									JSON.stringify({
										recognitionId: recognitionId,
									})
								);

								if (itemArray.length > 1) {
									commentDetailsId = itemArray[2] ? parseInt(itemArray[2]) : 0;
									setLocalStorageItem(
										'recognitionCommentHighlight',
										JSON.stringify({
											recognitionId: recognitionId,
											commentDetailsId: commentDetailsId,
										})
									);
								}
							}

							history.push(RECOGNIZE);
						} else {
							//== if redirect url is set and redirection
							//== is not for login user
							if (empId && parseInt(empId) !== resp.data.entity.employeeId) {
								removeLocalStorageItem('redirectUrl');
								removeLocalStorageItem('empId');
							}

							setLocalStorageItem('LoginAs', 'user');

							//== if Contributors is set in the redirect url
							if (redirectUrl.url.includes('Contributors')) {
								const itemArray = redirectUrl.url.split('/');
								const goalId = itemArray[1] ? itemArray[1] : 0;
								setLocalStorageItem('highlightGoal', goalId);
								removeLocalStorageItem('redirectUrl');
							}
							// props.history.push(HOME);
							props.history.push(DASHBOARD);
						}
					} else {
						setLocalStorageItem('LoginAs', 'user');
						props.history.push(redirectUrl.url);
					}
					removeLocalStorageItem('empId');
				} else {
					setLocalStorageItem('LoginAs', 'user');
					// props.history.push(HOME);
					props.history.push(DASHBOARD);
				}
				window.location.reload();
			} else {
				window.location = LOGIN_INITIATOR_URL;
			}
		} catch (err) {
			window.location = LOGIN_INITIATOR_URL;
		}
	};

	return <Fragment>{t('ssoLoginRedirectingMsg')}</Fragment>;
}
