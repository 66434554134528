import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
	Avatar,
	Badge,
	Box,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	Typography,
} from '@material-ui/core';
import '../../styles/pages/recognize/recognition.scss';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { OKRButton } from '../Common/OKRButton';
import { Enums } from '../../config/enums';
import { ResetIcon } from '../../config/svg/CommonSvgIcon';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import {
	formatFeedbackDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import GlobalSearch from '../Common/GlobalSearch';
import { globalSearchAPIWithTeam, recognitionSearch, searchEmployee } from '../../action/search';
import { getRecognizationBadges, getRecognizationPostListing } from '../../action/recognize';
import { CreatePost } from './RecognizePost/createPost';
import { RecognizeList } from './RecognizeListing/recognizeList';
import { FameBadgesWall } from './WallOfFame/WallOfFameBadges';
import { TeamLeaderBoard } from './TeamLeaderBoard/TeamLeaderBoard';
import LinearLoader from '../Common/Loader';
import Header from '../../containers/Header';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { isRecognizeRefresh } from '../../action/signalR';
import { TeamEmployeeSearch } from './TeamEmployeeSearch';
import { TeamIcon } from '../../config/svg/CommonSvg';
import { LeaderIcon } from '../../config/svg/formElementIcons';

export const Recognize = (props: any) => {
	const { isRecognizeRefreshValue } = useSelector((state: any) => state.signalRReducer);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [pageIndex, setPageIndex] = useState(1);
	const [isMyPost, setIsMyPost] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [selectedUser, setSelectedUser] = useState<any>({});
	const [loader, setLoader] = useState<boolean>(false);
	const [searchedType, setSearchedType] = useState<Number>(0);
	const [commentRecognizationId, setCommentRecognizationId] = useState<number>(0);
	const { recognizePostResponse } = useSelector((state: any) => state.recognizeReducer);

	useEffect(() => {
		dispatch(getRecognizationBadges(`empId=${userDetail.employeeId}`));
	}, []);

	useEffect(() => {
		if (!recognizePostResponse) {
			getAllOrgRecognize();
		}
	}, []);

	useEffect(() => {
		if (isRecognizeRefreshValue) {
			getAllOrgRecognize();
			dispatch(isRecognizeRefresh(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isRecognizeRefreshValue]);

	const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(recognitionSearch(searchStr, page, pageSize, 1));
	};

	const getAllOrgRecognize = (
		pageIndex = 1,
		pageSize = 10,
		isMyPost = false,
		employeeId = 0,
		employeeSearch = false,
		searchType = 1
	) => {
		setCommentRecognizationId(0);
		//notification highlight
		let recognitionId: any = 0;
		let recognitionHighlight: any = getLocalStorageItem('recognitionHighlight');
		if (Boolean(recognitionHighlight)) {
			recognitionHighlight = JSON.parse(recognitionHighlight);
			if (recognitionHighlight.recognitionId > 0) {
				recognitionId = recognitionHighlight.recognitionId;
			}
		} else {
			removeLocalStorageItem('isAllLinkShow');
		}

		setPageIndex(pageIndex);
		setIsMyPost(isMyPost);
		dispatch(
			getRecognizationPostListing({
				id: employeeSearch
					? employeeId
					: selectedUser?.teamId
					? selectedUser.teamId
					: selectedUser.employeeId
					? selectedUser?.employeeId
					: 0,
				recognitionId: recognitionId,
				isMyPost: isMyPost,
				pageIndex: pageIndex,
				pageSize: pageSize,
				searchType: employeeSearch ? searchType : searchedType,
			})
		);
	};

	const onSelectedUser = (user: any) => {
		let sType: number = 1;
		if (user.teamId) {
			sType = 2;
			user.employeeId = user.teamId;
			user.fullName = user.teamName;
		}
		setSelectedUser(user);
		setSearchedType(sType);
		getAllOrgRecognize(1, 10, false, user.employeeId ? user.employeeId : 0, true, sType);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});

	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-recog-subheader');
		if (window.pageYOffset >= 88) {
			objElement && objElement.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement && objElement.classList.remove('sticky-header');
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='page-subheader page-subheader-notification' id='page-recog-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('praiseWallLevel')}</Typography>
						</Box>
					</Box>
					<Box className='page-subheader-right'>
						<Box className='primary-search-field'>
							<TeamEmployeeSearch
								{...props}
								searchCustomEmployee={searchCustomEmployee}
								selectedUser={[]}
								setSelectedUser={onSelectedUser}
							/>
						</Box>
						<Box className='range-selector-calender'></Box>
						<Header {...props} fromTab={true} isRecognize={true} />
					</Box>
				</Box>
			</Box>
			<Box className='pad-container'>
				<Box className='recog-area'>
					{selectedUser && selectedUser.employeeId && (
						<Box className='recog-people-info'>
							<Box className='back-to-main'>
								<OKRButton
									id='back-btn'
									icon={<BackArrow />}
									text={t('back')}
									handleClick={() => {
										onSelectedUser([]);
									}}
								/>
							</Box>
							{selectedUser?.teamId > 0 ? (
								<Box className='team-details'>
									{selectedUser?.imagePath ? (
										<Avatar className='team-avatar' src={selectedUser?.imagePath} />
									) : (
										<Avatar
											className='team-avatar'
											style={{
												backgroundColor: getRandomBackgroundColor(selectedUser.backGroundColorCode),
												color: getRandomColor(selectedUser.colorCode),
											}}
										>
											{getUserName({
												fullName: selectedUser?.fullName ? selectedUser?.fullName : '',
												firstName: selectedUser?.firstName,
												lastName: selectedUser?.lastName,
											})}
										</Avatar>
									)}
									<Box className='team-info-details'>
										<Typography variant='h4'>
											{selectedUser?.fullName
												? selectedUser?.fullName
												: selectedUser.firstName + ' ' + selectedUser.lastName}
										</Typography>
										<Box className='team-info-details-inner'>
											<Typography variant='h6'>
												<TeamIcon />
												{selectedUser?.employeeCount}
											</Typography>
											<Typography variant='h6'>
												<LeaderIcon />
												{selectedUser?.leaderName}
											</Typography>
										</Box>
									</Box>
								</Box>
							) : (
								<Box className='user-info'>
									<Box className='user-img'>
										{selectedUser?.imagePath ? (
											<Avatar src={selectedUser?.imagePath} />
										) : (
											<Avatar>
												{getUserName({
													fullName: selectedUser?.fullName ? selectedUser?.fullName : '',
													firstName: selectedUser?.firstName,
													lastName: selectedUser?.lastName,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4'>
											{selectedUser?.fullName
												? selectedUser?.fullName
												: selectedUser.firstName + ' ' + selectedUser.lastName}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					)}
					<Box className='recog-post-area'>
						<Box className='recog-post-left'>
							<CreatePost
								getAllOrgRecognize={getAllOrgRecognize}
								selectedUser={selectedUser && selectedUser.employeeId ? selectedUser : null}
								isMyPost={isMyPost}
							/>
							<RecognizeList
								getAllOrgRecognize={getAllOrgRecognize}
								pageIndex={pageIndex}
								isMyPost={isMyPost}
								canEdit={selectedUser && selectedUser.employeeId ? false : true}
								selectedUser={selectedUser && selectedUser.employeeId ? selectedUser : null}
								setLoader={setLoader}
								commentRecognizationId={commentRecognizationId}
								setCommentRecognizationId={setCommentRecognizationId}
							/>
						</Box>
						<Box className='recog-post-right'>
							<FameBadgesWall
								selectedUser={selectedUser && selectedUser.employeeId ? selectedUser.employeeId : userDetail.employeeId}
								selectedUserDetail={selectedUser}
								setLoader={setLoader}
							/>
							<TeamLeaderBoard
								selectedUser={selectedUser && selectedUser.employeeId ? selectedUser.employeeId : userDetail.employeeId}
								selectedUserDetail={selectedUser}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
