import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	List,
	Typography,
	Avatar,
	ListItem,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	Link,
	Button,
} from '@material-ui/core';
import {
	getEmployeesFinalRating,
	getMultipleAssessment,
	getTeamAssessmentList,
	resetTeamAssessmentList,
	setTeamUserDataCompleted,
} from '../../../action/perform';
import {
	getMonthDateYear,
	getMonthDateYearTime,
	getUserDetails,
	getUserName,
	formatDateWithoutSpace,
} from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import { DownloadIcon, Perform360Icon, SortingIcon, TickIcon } from '../../../config/svg/CommonSvgIcon';
import {
	performanceAssessmentDetailedStatusReport,
	performanceAssessmentQuickStatusReport,
	showLoader,
} from '../../../action/common';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
// import { ReportingToIcon } from '../../../config/svg/formElementIcons';
import '../../../styles/pages/user-performance/userTeamsAssessment.scss';
import {
	AlertInfoIcon,
	DirectReportReviewIcon,
	FinalAssessmentIcon,
	PerformUserMgtIcon,
	// UserMgtIcon,
} from '../../../config/svg/CommonSvg';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
// import { MultipleAssessment } from './MultipleAssessment';
import { MultipleFinalAssessment } from './MultipleFinalAssessment';
// import { BackArrow } from '../../../config/svg/GlobalSvg';
import { supportModuleAlignOkr } from '../../Admin/AdminConstant';
import assessmentYetToBegin from '../../../images/assessment-yet-to-begin.svg';
import { getOkrMasterData } from '../../../action/myGoal';
import { TeamNomineeeAssessment } from '../../Perform/ThreeSixtyAssessment/TeamNomineeeAssessment';

// import { UserSnapshotDrawer } from '../../Perform/Snapshot/UserSnapshotDrawer';
// import { Assessment360DegreeIcon } from '../../../config/svg/AssessmentSvg';
import { NominateMainDrawer } from '../NominateMainDrawer';
import { UserSnapshot } from '../../Common/UserSnapshot';
import { TeamAssessmentDetail } from './TeamAssessmentDetail';
import { Enums } from '../../../config/enums';
import { MultipleUserAssessmentForm } from './MultipleUserAssessmentForm';
import { NoRecordPerformance } from '../NoRecordPerformance';
import { MultipleThreeSixtyAssessmentDetails } from '../UserThreeSixtyAssessment/MultipleThreeSixtyAssessmentDetails';
import { ThreeSixtyAssessmentDetail } from '../UserThreeSixtyAssessment/ThreeSixtyAssessmentDetail';
import { InfoprolearningDomainName } from '../../../config/constant';
import { getMeetingNotes } from '../../../action/oneOnOneRequest';
import { useSnackbar } from 'notistack';
import { AssessmentDetailPdf } from './AssessmentDetailPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { getDownloadAssessmentData } from '../../../action/UserPerformance';

export const TeamsAssessment = (props: any) => {
	const {
		tabSelected,
		cycleData,
		handleOneOnOneClick,
		loader,
		setLoader,
		performanceCycle,
		getSelfAssessmentDetails,
		getEmployeeAssessmentDetails,
	} = props;
	// const { userSelectedCycle, currentEngageTab, dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);
	const {
		teamAssessmentResponse,
		multipleAssessmentResponse,
		// employeeAssessmentCycleList,
		performanceAlert,
		teamAssessmentResponseStatus,
		employeeAssessmentResponse,
		teamAssessmentResponseDone,
	} = useSelector((state: any) => state.performReducer);
	const [assesmentPdfData, setAssesmentPdfData] = useState<any>(null); // or useState({})
	const { enqueueSnackbar } = useSnackbar();
	const [showDownload, setShowDownload] = useState(false);

	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { redirectedEmployeeDetails } = useSelector((state: any) => state.commonReducer);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let userDetail = getUserDetails();
	const [userList, setUserList] = useState<any>([]);
	const [sortOrder, setSortOrder] = useState('ASC');
	const [sortingColumn, setSortingColumn] = useState('firstName');
	const [showBulkOption, setShowBulkOption] = useState(false);
	const [showManagerAssessment, setShowManagerAssessment] = useState(false);
	const [checkedUser, setCheckedUser] = useState(0);
	const [openSnapshot, setOpenSnapshot] = useState(false);
	const [selectedUser, setSelectedUser] = useState({});
	const [showNote, setShowNote] = useState(false);
	const [showMultipleFinalAssessment, setShowMultipleFinalAssessment] = useState(false);
	const [showTeamAssessment, setShowTeamAssessment] = useState(true);
	const [performanceRoleId, setPerformanceRoleId] = useState(0);
	const [showFinalAssessmentBtn, setShowFinalAssessmentBtn] = useState(false);
	const [showAssessment, setShowAssessment] = useState(false);
	const [managerRatingDateExceeded, setManagerRatingDateExceeded] = useState<boolean>(false);
	const [isFinalRating, setIsFinalRating] = useState(false);
	const [selectedUserAssessment, setSelectedUserAssessment] = useState<any>({});
	const [listFilter, setListFilters] = useState<any>([]);
	const [isDownloadIconDisabled, setIsDownloadIconDisabled] = useState<boolean>(false);
	const [performanceRatings, setPerformanceRatings] = useState<any>([]);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const [showNomineeThreeSixty, setShowNomineeThreeSixty] = useState<boolean>(false);
	const [threeSixtyNominee, setThreeSixtyNominee] = useState<any>({});
	const [openNominateDrawer, setOpenNominateDrawer] = useState<boolean>(false);
	const [reloadRequired, setReloadRequired] = useState<boolean>(false);
	const [nominatedUser, setNominatedUser] = React.useState<any>({});
	const [selfAssessmentSelectedUser, setSelfAssessmentSelectedUser] = useState<any>(null);
	const [selfAssessmentView, setSelfAssessmentView] = useState<boolean>(false);

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		if (performanceCycle?.performanceCycleId) {
			dispatch(resetTeamAssessmentList());
			handleBack();
			dispatch(showLoader(true));
			// setUserList([]);
			getTeamAssessList();
			if (!Boolean(okrMasterData)) {
				dispatch(getOkrMasterData());
			}
			setReloadRequired(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [performanceCycle]);

	useEffect(() => {
		if (reloadRequired) {
			dispatch(resetTeamAssessmentList());
			handleBack();
			dispatch(showLoader(true));
			// setUserList([]);
			getTeamAssessList(performanceRoleId);
			if (!Boolean(okrMasterData)) {
				dispatch(getOkrMasterData());
			}
			setReloadRequired(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadRequired]);

	useEffect(() => {
		if (okrMasterData?.performanceRatings) {
			setPerformanceRatings(okrMasterData.performanceRatings);
		}
	}, [okrMasterData]);

	useEffect(() => {
		setUserList([]);
	}, [performanceCycle]);

	useEffect(() => {
		if (teamAssessmentResponse?.myTeamAssessmentDetails && teamAssessmentResponse?.myTeamAssessmentDetails.length) {
			let findDisabled = teamAssessmentResponse.myTeamAssessmentDetails.find((item: any) => item.finalRatingId > 0);
			setShowNote(findDisabled);
			if (
				teamAssessmentResponse.performanceRoleEmployeeResponse &&
				teamAssessmentResponse.performanceRoleEmployeeResponse.length &&
				performanceRoleId === 0
			) {
				setPerformanceRoleId(
					teamAssessmentResponse.performanceRoleEmployeeResponse.length
						? teamAssessmentResponse.performanceRoleEmployeeResponse[0].performanceRoleId
						: 0
				);
			}

			setUserList(teamAssessmentResponse.myTeamAssessmentDetails);
			setListFilters(teamAssessmentResponse?.performanceRoleEmployeeResponse);
			dispatch(showLoader(false));
			if (redirectedEmployeeDetails && redirectedEmployeeDetails.employeeId) {
				let findSelectedUser: any = teamAssessmentResponse.myTeamAssessmentDetails.find(
					(item: any) => item.employeeId === redirectedEmployeeDetails.employeeId
				);
				if (findSelectedUser && findSelectedUser?.employeeId) {
					teamTakeAssessment({}, findSelectedUser);
				}
			}
		}
		// else {
		// 	setUserList([]);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [teamAssessmentResponse]);

	useEffect(() => {
		if (userList.length > 0) {
			let findChecked = userList.filter((item: any) => item.isChecked);
			if (findChecked.length > 1) {
				if (teamAssessmentResponse?.myTeamsAssessment?.isSelfColumnVisible) {
					let findStatus: any = userList.filter((user: any) => {
						if (user.isChecked && user.isManagerAssessmentCompleted && user.isSelfAssessmentCompleted) {
							return user;
						}
					});
					if (findStatus.length === findChecked.length) {
						setShowFinalAssessmentBtn(true);
					} else {
						setShowFinalAssessmentBtn(false);
					}
				} else {
					let findChecked = userList.filter((item: any) => item.isChecked);
					let findStatus = userList.filter((user: any) => {
						if (user.isChecked && user.isManagerAssessmentCompleted) {
							return user;
						}
					});
					if (findStatus.length === findChecked.length) {
						setShowFinalAssessmentBtn(true);
					} else {
						setShowFinalAssessmentBtn(false);
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userList]);

	const fetchOkrDetail = async (e: any, user: any) => {
		try {
			const requestParams = `empId=${user?.employeeId}&performanceCycleId=${performanceCycle?.performanceCycleId}`;
			const response1: any = await dispatch(getDownloadAssessmentData(requestParams));
			if (response1 && response1?.data?.status === Enums.STATUS_SUCCESS) {
				const updatedData = { ...response1.data.entity }; // Make a shallow copy of the data to update
				//updated selfAssessment isSelected flag based on rating value
				updatedData.selfAssessment.performanceRoleLinkedFormSection.forEach((section: any) => {
					section.performanceRoleLinkedFormDetails.forEach((detail: any) => {
						const { performanceRoleLinkedFormScales, ratingValue } = detail;

						if (performanceRoleLinkedFormScales && ratingValue) {
							const scaleDetails = performanceRoleLinkedFormScales.performanceRoleLinkedFormScaleDetails;

							// Using a for loop for early exit and to avoid multiple iterations
							for (const scaleDetail of scaleDetails) {
								if (scaleDetail.name.toLowerCase() === ratingValue.toLowerCase()) {
									scaleDetail.isSelected = true;
									break; // Stop loop once we find the matching scale detail
								}
							}
						}
					});
				});
				//updated managerAssessment isSelected flag based on rating value
				updatedData.managerAssessment.performanceRoleLinkedFormSection.forEach((section: any) => {
					section.performanceRoleLinkedFormDetails.forEach((detail: any) => {
						const { performanceRoleLinkedFormScales, ratingValue } = detail;

						if (performanceRoleLinkedFormScales && ratingValue) {
							const scaleDetails = performanceRoleLinkedFormScales.performanceRoleLinkedFormScaleDetails;

							// Using a for loop for early exit and to avoid multiple iterations
							for (const scaleDetail of scaleDetails) {
								if (scaleDetail.name.toLowerCase() === ratingValue.toLowerCase()) {
									scaleDetail.isSelected = true;
									break; // Stop loop once we find the matching scale detail
								}
							}
						}
					});
				});
				setAssesmentPdfData({
					...updatedData,
					loggedInEmployeeTasks: [],
					otherEmployeeTasks: [],
					userDetails: user,
				});
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {
			setLoader(false);
			setShowDownload(false);
			//setMeetingDetail('');
			enqueueSnackbar('Error occurred while downloading Meeting Notes', {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};

	const textInput: any = useRef();
	useEffect(() => {
		if (assesmentPdfData) {
			setShowDownload(true);
			setTimeout(() => {
				textInput.current.click();
				setLoader(false);
			}, 1000);
		}
	}, [assesmentPdfData]);
	const downloadPdf = async (e: any, user: any) => {
		setLoader(true);
		setAssesmentPdfData(null);
		setShowDownload(false);
		fetchOkrDetail(e, user); // Ensure fetchOkrDetail completes
	};
	const showThreeSixtyForUser = (user: any) => {
		try {
			if (user?.employeeId) {
				dispatch(showLoader(true));
				// const userFilter: any = [];
				// userList.forEach((item: any) => {
				// 	if (item.isChecked) {
				// 		userFilter.push(item.employeeId);
				// 	}
				// });
				const data = {
					performanceCycleId: performanceCycle?.performanceCycleId,
					employeeIds: [],
					nomineeForEmployeeId: user?.employeeId,
					performanceRoleId:
						teamAssessmentResponse.performanceRoleEmployeeResponse &&
						teamAssessmentResponse.performanceRoleEmployeeResponse.length
							? user?.performanceRoleId || 0
							: Enums.ZERO,
					ratingAssessmentType: Enums.THREE,
				};
				dispatch(setTeamUserDataCompleted(user));
				dispatch(getMultipleAssessment(data));
				setThreeSixtyNominee(user);
				setShowNomineeThreeSixty(true);
			}
		} catch (e) {
			console.error(e);
		}
	};
	const checkSupportPermission = (moduleId: any) => {
		const permission =
			tenantData && tenantData.modules.length && tenantData.modules.filter((item: any) => item === moduleId);
		if (permission.length) {
			return true;
		} else {
			return false;
		}
	};

	const getTeamAssessList = (performId?: any) => {
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			performanceRoleId: performId || 0,
			reportingManagerId: userDetail.employeeId,
			sortingText: '',
			order: '',
			finder: '',
		};
		dispatch(showLoader(true));
		dispatch(getTeamAssessmentList(data));
	};

	const onSelectAllClick = (e: any) => {
		let { checked } = e.target;
		let updateCheck =
			userList &&
			userList?.map((item: any) => {
				return {
					...item,
					isChecked:
						item.finalRatingId > 0 || (item?.isManagerAssessmentCompleted && item?.managerAssessmentScore)
							? false
							: checked,
				};
			});
		setUserList(updateCheck);
		let checkedLength = updateCheck.filter((user: any) => user.isChecked);
		setCheckedUser(checkedLength.length);
		if (checked && userList.length >= 2) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
	};

	const onUserSelect = (e: any, index: number) => {
		let { checked } = e.target;
		let updateCheck = [...userList];
		updateCheck[index].isChecked = checked;
		let findChecked = updateCheck.filter((item: any) => item.isChecked).length;
		setCheckedUser(findChecked);
		if (findChecked > 1) {
			setShowBulkOption(true);
		} else {
			setShowBulkOption(false);
		}
		setUserList(updateCheck);
	};

	const handleIndeterminate = () => {
		let findChecked = userList.filter((item: any) => item.isChecked && item.finalRatingId === 0);
		let checkableItems = userList.filter((item: any) => item.finalRatingId === 1);
		if (findChecked.length > 0 && findChecked.length !== checkableItems.length) {
			return true;
		} else {
			return false;
		}
	};

	const handleAllChecked = () => {
		let findChecked = userList.filter((item: any) => item.isChecked && item.finalRatingId === 0);
		let checkableItems = userList.filter((item: any) => item.finalRatingId < 1);
		if (findChecked.length === checkableItems.length) {
			return true;
		} else {
			return false;
		}
	};

	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		setSortingColumn(column);
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			performanceRoleId: 0,
			reportingManagerId: userDetail.employeeId,
			sortingText: column,
			order: newSortOrder,
			finder: '',
		};
		dispatch(showLoader(true));
		dispatch(getTeamAssessmentList(data));
	};

	const handleTeamFilter = (e: any, item: any) => {
		if (item.performanceRoleId !== performanceRoleId) {
			let data = {
				performanceCycleId: performanceCycle?.performanceCycleId,
				performanceRoleId: item.performanceRoleId,
				reportingManagerId: userDetail.employeeId,
				sortingText: sortingColumn,
				order: sortOrder,
				finder: '',
			};
			setPerformanceRoleId(item.performanceRoleId);
			dispatch(showLoader(true));
			dispatch(getTeamAssessmentList(data));
		}
	};

	const resetCheckList = () => {
		let updateList = userList.map((item: any) => {
			return {
				...item,
				isChecked: false,
			};
		});
		setUserList(updateList);
		setShowBulkOption(false);
	};

	const handleMultipleManagerAssessment = () => {
		dispatch(showLoader(true));
		const userFilter: any = [];
		userList.forEach((item: any) => {
			if (item.isChecked) {
				userFilter.push(item.employeeId);
			}
		});
		const data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			employeeIds: userFilter,
			performanceRoleId:
				teamAssessmentResponse.performanceRoleEmployeeResponse &&
				teamAssessmentResponse.performanceRoleEmployeeResponse.length
					? userList && userList?.length
						? userList[0].performanceRoleId
						: 0
					: Enums.ZERO,
			ratingAssessmentType: Enums.TWO,
		};
		dispatch(getMultipleAssessment(data));
		setShowManagerAssessment(true);
		resetCheckList();
	};

	useEffect(() => {
		if (
			showManagerAssessment &&
			multipleAssessmentResponse &&
			multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections &&
			multipleAssessmentResponse?.multiplePerformanceRoleLinkedFormSections.length
		) {
			// setShowManagerAssessment(true);
			dispatch(showLoader(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [multipleAssessmentResponse]);

	const handleBack = () => {
		resetCheckList();
		setShowManagerAssessment(false);
		setShowTeamAssessment(true);
		setShowMultipleFinalAssessment(false);
		setShowNomineeThreeSixty(false);
	};

	const fetchFinalAssessment = (empId: number) => {
		let userFilter: any = [empId];
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			employeeId: userFilter,
		};
		dispatch(getEmployeesFinalRating(data));
	};

	const handleMultipleFinalAssessment = (e: any) => {
		dispatch(showLoader(true));
		let userFilter: any = [];
		let empData = userList.filter((item: any) => {
			if (item.isChecked) {
				userFilter.push(item.employeeId);
			}
		});
		let data = {
			performanceCycleId: performanceCycle?.performanceCycleId,
			employeeId: userFilter,
		};
		dispatch(getEmployeesFinalRating(data));
		setShowMultipleFinalAssessment(true);
		setShowManagerAssessment(false);
		setShowTeamAssessment(false);
		resetCheckList();
	};

	const handleSnapshot = (e: any, user: any) => {
		setSelectedUser(user);
		setOpenSnapshot(true);
	};

	const handleDrawerClose = (e: any, type: any) => {
		setOpenSnapshot(false);
	};
	const teamTakeAssessment = (e: any, user: any, finalRating = false) => {
		setSelectedUserAssessment(user);
		setShowAssessment(true);
		setIsFinalRating(finalRating);
		dispatch(setTeamUserDataCompleted(user));
		if (finalRating || (user?.isManagerAssessmentCompleted && user?.isSelfAssessmentCompleted)) {
			fetchFinalAssessment(user?.employeeId);
		}
	};

	const backClicked = (resetData = false) => {
		setSelectedUserAssessment({});
		setShowAssessment(false);
		setManagerRatingDateExceeded(false);
		setShowManagerAssessment(false);
		setSelfAssessmentSelectedUser(null);
		setSelfAssessmentView(false);
		if (resetData) {
			getTeamAssessList(performanceRoleId);
		}
	};

	const performanceAssessmentReport = async (type: number) => {
		try {
			/**
			 * type = 1 for Quick Report
			 * type = 2 for Detailed Report
			 */
			setIsDownloadIconDisabled(true);
			setLoader && setLoader(true);
			const performanceCycleId = performanceCycle?.performanceCycleId || 0;
			const requestParam = `empId=${userDetail?.employeeId}&performanceCycleId=${performanceCycleId}`;
			let response: any = null;
			if (type === 1) {
				response = await dispatch(performanceAssessmentQuickStatusReport(requestParam));
			} else if (type === 2) {
				response = await dispatch(performanceAssessmentDetailedStatusReport(requestParam));
			}
			if (response) {
				const defaultName =
					type === 1 ? 'PerformanceAssessmentQuickStatusReport.xlsx' : 'PerformanceAssessmentDetailedStatusReport.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			setLoader && setLoader(false);
			setIsDownloadIconDisabled(false);
		} catch (error) {
			console.error(error);
		}
	};
	const handleClickNominate = (event: any, user: any, isManaged: boolean) => {
		setOpenNominateDrawer(true);
		setNominatedUser({ ...user, isManaged });
	};

	const selfAssessmentViewOpen = (e: any, user: any, editType: string) => {
		setSelfAssessmentSelectedUser(user);
		setSelfAssessmentView(true);
	};

	const handleBackClicked = () => {
		setShowNomineeThreeSixty(false);
	};

	return (
		<>
			{!teamAssessmentResponseDone ? (
				<Box className='loading-center-global'>
					<Typography>Loading...</Typography>
				</Box>
			) : (
				<>
					{selfAssessmentView ? (
						<ThreeSixtyAssessmentDetail
							performanceCycle={performanceCycle}
							loader={loader}
							setLoader={setLoader}
							employeeId={selfAssessmentSelectedUser?.employeeId}
							backClicked={backClicked}
							selectedUserAssessment={selfAssessmentSelectedUser}
							getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
							ratingAssessmentType={Enums.ONE}
							// okrMasterData={okrMasterData}
							// performanceRatings={performanceRatings}
							// setPerformanceRatings={setPerformanceRatings}
							// handleOneOnOneClick={handleOneOnOneClick}
							// performanceRoleId={performanceRoleId}
							// getThreeSixtyAssessment={getTeamAssessList}
							// canEditQuestionsWithRating={!performanceCycle?.isCycleCompleted}
							// assessmentType={assessmentType}
						/>
					) : (
						<></>
					)}
					{!showNomineeThreeSixty && !showAssessment && showMultipleFinalAssessment && !selfAssessmentView && (
						<MultipleFinalAssessment
							teamAssessmentResponse={teamAssessmentResponse}
							getTeamAssessList={getTeamAssessList}
							handleBack={handleBack}
							checkSupportPermission={checkSupportPermission}
							performanceCycle={performanceCycle}
							performanceRoleId={performanceRoleId}
							{...props}
						/>
					)}
					{!showNomineeThreeSixty && showAssessment && !selfAssessmentView && (
						<>
							{/* <EmployeeAssessment
						{...props}
						employeeId={selectedUserAssessment?.employeeId}
						fromTeamAssessment={true}
						backClicked={backClicked}
						isFinalRating={isFinalRating}
						handleOneOnOneClick={handleOneOnOneClick}
						performanceRoleId={performanceRoleId}
						getTeamAssessList={getTeamAssessList}
						selectedUserAssessment={selectedUserAssessment}
					/> */}
							<TeamAssessmentDetail
								{...props}
								assessmentType={isFinalRating ? 'FinalAssessment' : 'Manager'}
								performanceCycle={performanceCycle}
								employeeId={selectedUserAssessment?.employeeId}
								fromTeamAssessment={true}
								backClicked={backClicked}
								isFinalRating={isFinalRating}
								handleOneOnOneClick={handleOneOnOneClick}
								performanceRoleId={performanceRoleId}
								getTeamAssessList={getTeamAssessList}
								selectedUserAssessment={selectedUserAssessment}
								setSelectedUserAssessment={setSelectedUserAssessment}
								managerRatingDateExceeded={managerRatingDateExceeded}
								handleSnapshot={handleSnapshot}
								getSelfAssessmentDetails={getSelfAssessmentDetails}
								performanceRatings={performanceRatings}
							/>
						</>
					)}

					{showNomineeThreeSixty && (
						<>
							{/* <TeamNomineeeAssessment
						{...props}
						employeeId={threeSixtyNominee?.employeeId}
						fromTeamAssessment={true}
						showNomineeThreeSixty={showNomineeThreeSixty}
						backClicked={backClicked}
						setLoader={setLoader}
						handleBackClicked={handleBackClicked}
						isFinalRating={isFinalRating}
						handleOneOnOneClick={handleOneOnOneClick}
						performanceRoleId={performanceRoleId}
						getTeamAssessList={getTeamAssessList}
						selectedUserAssessment={selectedUserAssessment}
					/> */}
							<MultipleThreeSixtyAssessmentDetails
								callingType={'MyTeamAssessment'}
								performanceCycle={performanceCycle}
								loader={loader}
								setLoader={setLoader}
								backClicked={backClicked}
								employeeId={threeSixtyNominee?.employeeId}
								selectedUserAssessment={threeSixtyNominee}
								handleBack={() => {
									backClicked(true);
									handleBack();
								}}
								userDetail={userDetail}
								multipleAssessmentResponse={multipleAssessmentResponse}
								performanceCycleId={performanceCycle?.performanceCycleId}
								getTeamAssessList={getTeamAssessList}
								performanceRoleId={performanceRoleId}
								performanceRatings={performanceRatings}
								handleOneOnOneClick={handleOneOnOneClick}
								handleSnapshot={handleSnapshot}
								getSelfAssessmentDetails={getSelfAssessmentDetails}
								isViewMode={true}
								assessmentType={isFinalRating ? 'FinalAssessment' : 'Manager'}
								fromTeamAssessment={true}
								isFinalRating={isFinalRating}
								getEmployeeAssessmentDetails={getEmployeeAssessmentDetails}
								employeeAssessmentResponse={employeeAssessmentResponse}
							/>
						</>
					)}
					{!showNomineeThreeSixty && !showAssessment && showTeamAssessment && !selfAssessmentView && (
						<>
							{showManagerAssessment ? (
								<MultipleUserAssessmentForm
									handleBack={handleBack}
									backClicked={backClicked}
									userDetail={userDetail}
									multipleAssessmentResponse={multipleAssessmentResponse}
									performanceCycleId={performanceCycle?.performanceCycleId}
									getTeamAssessList={getTeamAssessList}
									performanceCycle={performanceCycle}
									performanceRoleId={performanceRoleId}
									performanceRatings={performanceRatings}
									handleOneOnOneClick={handleOneOnOneClick}
									handleSnapshot={handleSnapshot}
									getSelfAssessmentDetails={getSelfAssessmentDetails}
									loader={loader}
									setLoader={setLoader}
								/>
							) : (
								<>
									{!performanceCycle || (teamAssessmentResponseStatus && userList.length === 0) ? (
										<NoRecordPerformance message={'No data found team'} handleOneOnOneClick={handleOneOnOneClick} />
									) : (
										<>
											<Box className='my-teams-assessment-head'>
												<Box className='my-teams-assessment-head-left'>
													<Box className='my-teams-assessment-head-left-inner'>
														{performanceAlert?.isManagerAssessmentAlert ? (
															<Typography className='alert-text' variant='h6'>
																{performanceAlert.managerRemainingDaysMessage && (
																	<>
																		<AlertInfoIcon /> {performanceAlert.managerRemainingDaysMessage}
																	</>
																)}
															</Typography>
														) : (
															<></>
														)}
														<List disablePadding>
															{listFilter.length > 0 &&
																listFilter.map((item: any, index: any) => (
																	<ListItem key={index}>
																		<OKRButton
																			disabled={performanceRoleId === item.performanceRoleId}
																			className={
																				performanceRoleId === item.performanceRoleId
																					? 'filter-grey-btn active'
																					: 'filter-grey-btn'
																			}
																			handleClick={(e) => handleTeamFilter(e, item)}
																			text={
																				<>
																					{item.performanceRoleName}
																					<span className='count'> {item.employeeCount}</span>
																				</>
																			}
																		/>
																	</ListItem>
																))}
														</List>
													</Box>
													{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
														<Typography variant='h6' className='font-weight-normal'>
															{t('myTeamAssessmentInfoMssg')}
														</Typography>
													) : (
														<></>
													)}
												</Box>
												{performanceCycle?.performanceCycleId ? (
													<Box className='my-teams-assessment-head-right'>
														<OKRButton
															disabled={isDownloadIconDisabled}
															handleClick={() => performanceAssessmentReport(2)}
															className='btn-circle-white'
															icon={<DownloadIcon />}
															title={t('downloadReport')}
														/>
													</Box>
												) : (
													<></>
												)}
											</Box>

											<TableContainer className='team-assessment-listing my-team-assessment-listing'>
												{userList && userList.length > 0 ? (
													<Table>
														<TableHead>
															<TableRow>
																<TableCell align='center' id={`team-assessment-cell-1`} className='user-col1 '>
																	<Box className='cell-wrap'>
																		<FormControlLabel
																			control={
																				<Checkbox
																					icon={<BorderCheckboxIcon />}
																					checkedIcon={<CheckedIcon />}
																					indeterminateIcon={<UnCheckedIcon />}
																					indeterminate={handleIndeterminate()}
																					checked={handleAllChecked()}
																					onChange={onSelectAllClick}
																					inputProps={{ 'aria-label': 'select Name' }}
																					disabled={
																						performanceCycle?.isCycleCompleted ||
																						!teamAssessmentResponse?.myTeamsAssessment?.isManagerAssessmentPeriodOpen
																					}
																				/>
																			}
																			label=''
																		/>
																		<Typography
																			variant='body2'
																			className={
																				sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'
																			}
																			onClick={(e) => sortUserList(e, 'firstName')}
																			id={'sort-name'}
																		>
																			<span className='sortable-label'>
																				<span className='txt-wrap'>{t('nameLabel')}</span> <SortingIcon />
																			</span>
																		</Typography>
																	</Box>
																</TableCell>
																{teamAssessmentResponse?.myTeamsAssessment?.isSelfColumnVisible && (
																	<TableCell
																		align='center'
																		id={`team-assessment-cell-2`}
																		className='user-col2 col-small self-assessment-col'
																	>
																		<Box className='cell-wrap'>
																			<Typography
																				variant='body2'
																				className={
																					sortingColumn === 'isSelfAssessmentCompleted'
																						? `sort-title ${sortOrder}`
																						: 'sort-title asc'
																				}
																				onClick={(e) => sortUserList(e, 'isSelfAssessmentCompleted')}
																				id={'isSelfAssessmentCompleted'}
																			>
																				<span className='sortable-label'>
																					<span className='txt-wrap'>{t('selfAssessment')}</span> <SortingIcon />
																				</span>
																			</Typography>
																		</Box>
																	</TableCell>
																)}
																<TableCell
																	align='center'
																	id={`team-assessment-cell-3`}
																	className='user-col3 manage-assessment-col'
																>
																	<Box className='cell-wrap'>
																		<Typography
																			variant='body2'
																			className={
																				sortingColumn === 'isManagerAssessmentCompleted'
																					? `sort-title ${sortOrder}`
																					: 'sort-title asc'
																			}
																			onClick={(e) => sortUserList(e, 'isManagerAssessmentCompleted')}
																			id={'sort-name'}
																		>
																			<span className='sortable-label'>
																				<span className='txt-wrap'>{t('managerAssessment')}</span> <SortingIcon />
																			</span>
																		</Typography>
																	</Box>
																</TableCell>
																{(teamAssessmentResponse?.myTeamsAssessment?.isThreeSixtyColumnVisible ||
																	teamAssessmentResponse?.myTeamsAssessment?.isThreeSixtyAssessmentPeriodOpen) && (
																	<TableCell
																		align='center'
																		id={`team-assessment-cell-3-1`}
																		className='user-col3-1 col-small'
																	>
																		<Box className='cell-wrap'>
																			<Typography variant='body2' className={'sort-title asc'} id={'sort-name'}>
																				<span className='sortable-label'>
																					<span
																						className='txt-wrap'
																						dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}
																					></span>
																					<SortingIcon />
																				</span>
																			</Typography>
																		</Box>
																	</TableCell>
																)}
																{checkSupportPermission(supportModuleAlignOkr) &&
																	teamAssessmentResponse?.myTeamsAssessment?.isOkrColumnVisible && (
																		<TableCell
																			align='center'
																			id={`team-assessment-cell-4`}
																			className='user-col4 col-small'
																		>
																			<Box className='cell-wrap'>
																				<Typography
																					variant='body2'
																					className={
																						sortingColumn === 'okrScore' ? `sort-title ${sortOrder}` : 'sort-title asc'
																					}
																					onClick={(e) => sortUserList(e, 'okrScore')}
																					id={'sort-name'}
																				>
																					<span className='sortable-label'>
																						<span className='txt-wrap'>{t('committedOKRs')}</span> <SortingIcon />
																					</span>
																				</Typography>
																			</Box>
																		</TableCell>
																	)}
																<TableCell align='center' id={`team-assessment-cell-5`} className='user-col5 col-small'>
																	<Box className='cell-wrap'>
																		<Typography
																			variant='body2'
																			className={
																				sortingColumn === 'finalScore' ? `sort-title ${sortOrder}` : 'sort-title asc'
																			}
																			onClick={(e) => sortUserList(e, 'finalScore')}
																			id={'sort-name'}
																		>
																			<span className='sortable-label'>
																				<span className='txt-wrap'>{t('finalAssessment')}</span> <SortingIcon />
																			</span>
																		</Typography>
																	</Box>
																</TableCell>
																<TableCell
																	align='center'
																	id={`team-assessment-cell-6`}
																	className='user-col6'
																></TableCell>
															</TableRow>
														</TableHead>
														<TableBody>
															{userList &&
																userList.map((user: any, index: number) => (
																	<Fragment key={`permission${index}`}>
																		<TableRow
																			className={`${
																				user.finalRatingId > 0 ? 'team-assessment-row' : 'team-assessment-row'
																			}`}
																		>
																			<TableCell className='user-col1'>
																				<Box className='cell-wrap'>
																					<FormControlLabel
																						control={
																							<Checkbox
																								icon={<BorderCheckboxIcon />}
																								checkedIcon={<CheckedIcon />}
																								name={'list' + index}
																								checked={user?.isChecked === undefined ? false : user?.isChecked}
																								onChange={(e) => onUserSelect(e, index)}
																								key={index}
																								disabled={
																									user.isPerformanceCycleDone ||
																									performanceCycle?.isCycleCompleted ||
																									!teamAssessmentResponse?.myTeamsAssessment
																										?.isManagerAssessmentPeriodOpen ||
																									(user?.isManagerAssessmentCompleted &&
																										user?.managerAssessmentScore &&
																										user.finalRatingId > 0)
																								}
																							/>
																						}
																						label=''
																					/>
																					<Box className='user-info'>
																						{/* <Box className='user-img'>
																					{user.imagePath ? (
																						<Avatar src={user.imagePath} />
																					) : (
																						<Avatar>
																							{getUserName({
																								fullName: '',
																								firstName: user.firstName,
																								lastName: user.lastName,
																							})}
																						</Avatar>
																					)}
																				</Box> */}
																						<Box className='user-info-details'>
																							<Tooltip title={user.firstName + ' ' + user.lastName} arrow>
																								<Typography
																									variant='h4'
																									className='font-weight-normal'
																								>{`${user.firstName} ${user.lastName}`}</Typography>
																							</Tooltip>
																						</Box>
																					</Box>
																				</Box>
																			</TableCell>
																			{teamAssessmentResponse?.myTeamsAssessment?.isSelfColumnVisible && (
																				<TableCell className='user-col2 col-small self-assessment-col'>
																					<Box className='cell-wrap'>
																						{!user.isSelfFormVisble && user.isSelfScoreVisible ? (
																							<Typography>{user.selfAssessmentScore}</Typography>
																						) : user.isSelfScoreVisible ? (
																							<Tooltip
																								arrow
																								title={
																									<>
																										<span
																											dangerouslySetInnerHTML={{ __html: t('viewAssessments') }}
																										></span>
																									</>
																								}
																							>
																								<Typography
																									onClick={(e: any) => {
																										selfAssessmentViewOpen(e, user, 'show');
																									}}
																									className='cursor-pointer clickable-text'
																								>
																									{user.selfAssessmentScore}
																								</Typography>
																							</Tooltip>
																						) : user.isSelfFormVisble && user.selfAssessmentScore > 0 ? (
																							<Typography
																								onClick={(e: any) => {
																									selfAssessmentViewOpen(e, user, 'show');
																								}}
																								className='cursor-pointer clickable-text'
																							>
																								Done
																							</Typography>
																						) : !user.isSelfScoreVisible && user.selfAssessmentScore > 0 ? (
																							<Typography>Done</Typography>
																						) : (
																							<Typography className='blank-dash'>--</Typography>
																						)}
																					</Box>
																				</TableCell>
																			)}
																			<TableCell className='user-col3 manage-assessment-col'>
																				<Box className='cell-wrap'>
																					{user.isManagerScoreVisible && user.isManagerAssessmentCompleted ? (
																						<>
																							<Tooltip
																								arrow
																								title={
																									<>
																										<span
																											dangerouslySetInnerHTML={{ __html: t('viewAssessments') }}
																										></span>
																									</>
																								}
																							>
																								<Typography
																									className='cursor-pointer clickable-text'
																									onClick={(e: any) => {
																										const isViewModeLocal =
																											performanceCycle?.isCycleCompleted ||
																											!teamAssessmentResponse?.myTeamsAssessment
																												?.isManagerAssessmentPeriodOpen;
																										setManagerRatingDateExceeded(isViewModeLocal);
																										teamTakeAssessment(e, user);
																									}}
																								>
																									{user.managerAssessmentScore}
																								</Typography>
																							</Tooltip>
																						</>
																					) : user.isManagerFormVisible &&
																					  !user.isManagerScoreVisible &&
																					  user.isManagerAssessmentCompleted ? (
																						<Typography
																							onClick={(e: any) => {
																								const isViewModeLocal =
																									performanceCycle?.isCycleCompleted ||
																									!teamAssessmentResponse?.myTeamsAssessment
																										?.isManagerAssessmentPeriodOpen;
																								setManagerRatingDateExceeded(isViewModeLocal);
																								teamTakeAssessment(e, user);
																							}}
																							className='cursor-pointer clickable-text'
																						>
																							Done
																						</Typography>
																					) : (
																						<>
																							{user.isManagerAssessmentCompleted ? (
																								<>
																									<Tooltip
																										arrow
																										title={
																											<>
																												<span
																													dangerouslySetInnerHTML={{ __html: t('viewAssessments') }}
																												></span>
																											</>
																										}
																									>
																										<Typography
																											className='cursor-pointer clickable-text'
																											onClick={(e: any) => {
																												const isViewModeLocal =
																													performanceCycle?.isCycleCompleted ||
																													!teamAssessmentResponse?.myTeamsAssessment
																														?.isManagerAssessmentPeriodOpen;
																												setManagerRatingDateExceeded(isViewModeLocal);
																												teamTakeAssessment(e, user);
																											}}
																										>
																											{user.managerAssessmentScore}
																										</Typography>
																									</Tooltip>
																								</>
																							) : (
																								<OKRButton
																									className={`${
																										performanceCycle?.isCycleCompleted ||
																										!teamAssessmentResponse?.myTeamsAssessment
																											?.isManagerAssessmentPeriodOpen
																											? 'grey-btn'
																											: ''
																									} btn-link`}
																									text={
																										user?.managerStatus === Enums.ZERO
																											? t('takeAssessment')
																											: t('Resume Assessment')
																									}
																									buttonType={
																										performanceCycle?.isCycleCompleted ||
																										!teamAssessmentResponse?.myTeamsAssessment
																											?.isManagerAssessmentPeriodOpen
																											? 'disableButtonTooltip'
																											: null
																									}
																									handleClick={(e: any) => {
																										teamTakeAssessment(e, user);
																									}}
																									icon={''}
																									title={
																										performanceCycle?.isCycleCompleted
																											? 'Cannot take assessment as the performance cycle is over'
																											: !teamAssessmentResponse?.myTeamsAssessment
																													?.isManagerAssessmentPeriodOpen
																											? teamAssessmentResponse?.myTeamsAssessment
																													?.isManagerAssessmentPeriodOpenForFuture
																												? 'Assessment is yet to begin'
																												: 'Cannot take the assessment as the last day for manager assessment is over'
																											: null
																									}
																									disabled={
																										performanceCycle?.isCycleCompleted ||
																										!teamAssessmentResponse?.myTeamsAssessment
																											?.isManagerAssessmentPeriodOpen
																									}
																								/>
																							)}
																						</>
																					)}
																				</Box>
																			</TableCell>
																			{(teamAssessmentResponse?.myTeamsAssessment?.isThreeSixtyColumnVisible ||
																				teamAssessmentResponse?.myTeamsAssessment
																					?.isThreeSixtyAssessmentPeriodOpen) && (
																				<TableCell className='user-col3-1 three-sixty-column col-small'>
																					<Box className='cell-wrap'>
																						<Box className='inner-wrap'>
																							<Box className='btn-wrap-box'>
																								{user?.threeSixtyScore > 0 ? (
																									!user.isThreeSixtyScoreVisible ? (
																										<Typography>Done</Typography>
																									) : (
																										<Tooltip
																											arrow
																											title={
																												<>
																													<span
																														dangerouslySetInnerHTML={{ __html: t('viewAssessments') }}
																													></span>
																												</>
																											}
																										>
																											<Typography
																												className='cursor-pointer clickable-text'
																												onClick={(e: any) => {
																													showThreeSixtyForUser(user);
																												}}
																											>
																												{user?.threeSixtyScore}
																											</Typography>
																										</Tooltip>
																									)
																								) : user?.threeSixtyScore === 0 && user?.nomineeTotalCount > 0 ? (
																									<Typography className='blank-dash'>--</Typography>
																								) : !user.isThreeSixtyScoreVisible && user?.threeSixtyScore > 0 ? (
																									<Typography>Done</Typography>
																								) : (
																									<OKRButton
																										className={'btn-link'}
																										text={t('Nominate')}
																										buttonType={
																											!teamAssessmentResponse?.myTeamsAssessment
																												?.isThreeSixtyAssessmentPeriodOpen ||
																											performanceCycle?.isCycleCompleted
																												? 'disableButtonTooltip'
																												: null
																										}
																										title={
																											performanceCycle?.isCycleCompleted
																												? 'Cannot nominate as the performance cycle is over'
																												: !teamAssessmentResponse?.myTeamsAssessment
																														?.isThreeSixtyAssessmentPeriodOpen
																												? teamAssessmentResponse?.myTeamsAssessment
																														?.isThreeSixtyAssessmentPeriodOpenForFuture
																													? 'Assessment is yet to begin'
																													: 'Cannot nominate as the last day for the 360 assessment is over'
																												: null
																										}
																										disabled={
																											!teamAssessmentResponse?.myTeamsAssessment
																												?.isThreeSixtyAssessmentPeriodOpen ||
																											performanceCycle?.isCycleCompleted
																										}
																										handleClick={(e: any) => handleClickNominate(e, user, false)}
																									/>
																								)}
																							</Box>
																							{user?.nomineeTotalCount && user?.nomineeTotalCount > 0 ? (
																								<Box className='btn-wrap-box'>
																									<OKRButton
																										className={'btn-link view-button'}
																										title={
																											<>
																												<span
																													dangerouslySetInnerHTML={{ __html: t('manageNominees') }}
																												></span>
																											</>
																										}
																										handleClick={(e: any) => {
																											handleClickNominate(e, user, true);
																										}}
																										icon={<Perform360Icon />}
																										text={`${user?.nomineeApproveCount}/${user?.nomineeTotalCount}`}
																									/>
																									{/* {teamAssessmentResponse?.myTeamsAssessment?.isThreeSixtyAssessmentPeriodOpen &&
																							!performanceCycle?.isCycleCompleted ? (
																								<OKRButton
																									className={'btn-link edit-button'}
																									text={t('manageText')}
																									handleClick={(e: any) => handleClickNominate(e, user, true)}
																								/>
																							) : (
																								''
																							)} */}
																								</Box>
																							) : (
																								<></>
																							)}
																						</Box>
																					</Box>
																				</TableCell>
																			)}
																			{checkSupportPermission(supportModuleAlignOkr) &&
																				teamAssessmentResponse?.myTeamsAssessment?.isOkrColumnVisible && (
																					<TableCell className='user-col4 blue-color-column col-small'>
																						<Box className='cell-wrap'>
																							<Typography>{user.okrScore}%</Typography>
																						</Box>
																					</TableCell>
																				)}
																			<TableCell className='user-col5 col-small'>
																				<Box className='cell-wrap'>
																					{teamAssessmentResponse?.myTeamsAssessment
																						?.isFinalRatingAssessmentPeriodOpen ? (
																						user.isManagerAssessmentCompleted &&
																						(user.isSelfAssessmentCompleted ||
																							!teamAssessmentResponse?.myTeamsAssessment?.isSelfColumnVisible) ? (
																							!user.isPerformanceCycleDone ? (
																								<Box className='text-wrap-box'>
																									{/* <Box className='btn-wrap-box '>
																								<Typography>{user.finalScore} </Typography>
																							</Box> */}
																									<Box className='btn-wrap-box full-width'>
																										<OKRButton
																											handleClick={(e: any) => {
																												teamTakeAssessment(e, user, true);
																											}}
																											className={`${
																												performanceCycle?.isCycleCompleted && 'grey-btn'
																											} btn-link`}
																											text={t('takeFinalAssessment')}
																											buttonType={
																												performanceCycle?.isCycleCompleted
																													? 'disableButtonTooltip'
																													: null
																											}
																											icon={''}
																											title={
																												performanceCycle?.isCycleCompleted ||
																												!teamAssessmentResponse?.myTeamsAssessment
																													?.isFinalRatingAssessmentPeriodOpen
																													? teamAssessmentResponse?.myTeamsAssessment
																															?.isFinalRatingAssessmentPeriodOpenForFuture
																														? 'Assessment is yet to begin'
																														: 'Cannot take assessment as the performance cycle is over'
																													: null
																											}
																											disabled={
																												performanceCycle?.isCycleCompleted ||
																												!teamAssessmentResponse?.myTeamsAssessment
																													?.isFinalRatingAssessmentPeriodOpen
																											}
																										/>
																									</Box>
																								</Box>
																							) : (
																								<Box
																									className='text-wrap-box'
																									onClick={(e) => teamTakeAssessment(e, user, true)}
																								>
																									{teamAssessmentResponse?.isFinalScoreVisible ? (
																										<>
																											<Box className='btn-wrap-box '>
																												<Typography className='cursor-pointer clickable-text'>
																													{user.finalScore}{' '}
																												</Typography>
																											</Box>
																											<Box className='btn-wrap-box '>
																												<span className='short-text'>{user.finalRating}</span>
																											</Box>
																										</>
																									) : (
																										<Typography className='cursor-pointer clickable-text'>
																											Done
																										</Typography>
																									)}
																								</Box>
																							)
																						) : (
																							''
																						)
																					) : (
																						<>
																							<Box
																								className='text-wrap-box'
																								onClick={(e) => teamTakeAssessment(e, user, true)}
																							>
																								<Typography className='cursor-pointer clickable-text'>Done</Typography>
																							</Box>
																							{/* <OKRButton
																								className={`${
																									performanceCycle?.isCycleCompleted && 'grey-btn'
																								} btn-link`}
																								text={t('Done')}
																								buttonType={'disableButtonTooltip'}
																								icon={''}
																								handleClick={(e) => teamTakeAssessment(e, user, true)}
																							/> */}
																						</>
																					)}
																				</Box>
																			</TableCell>
																			<TableCell className='user-col6'>
																				<Box className='cell-wrap'>
																					<OKRButton
																						className='icon-btn'
																						icon={<PerformUserMgtIcon />}
																						title={t('viewPerformanceSnapshot')}
																						//tooltipClassName='tooltip-layout3'
																						// disabled={user.finalRatingId > 0}
																						handleClick={(e) => handleSnapshot(e, user)}
																					/>
																					{teamAssessmentResponse &&
																					teamAssessmentResponse?.myTeamsAssessment?.isOneToOneReview ? (
																						<OKRButton
																							className='icon-btn'
																							icon={<RequestOneOnOneIcon2 />}
																							title={t('requestOnetoOne')}
																							handleClick={(e) =>
																								handleOneOnOneClick(e, 'MyAssessmentList', user?.employeeId)
																							}
																						/>
																					) : (
																						<></>
																					)}
																					<Tooltip title={t('Download Employee Overview')} arrow>
																						<Button
																							className='download-btn btn-link'
																							onClick={(e) => downloadPdf(e, user)}
																						>
																							<DownloadIcon />
																						</Button>
																					</Tooltip>
																				</Box>
																			</TableCell>
																		</TableRow>
																	</Fragment>
																))}
														</TableBody>
													</Table>
												) : (
													<></>
												)}
											</TableContainer>

											{userList && userList.length && teamAssessmentResponse?.myTeamsAssessment?.scoreCalculatedUpon ? (
												<Box className='team-assessment-note'>
													<Box className='note-box'>
														<Typography variant='h6'>Note:</Typography>
														<List>
															{teamAssessmentResponse?.myTeamsAssessment ? (
																<ListItem>
																	<Box className='team-assessment-note'>
																		<Typography variant='h6' className='font-weight-normal'>
																			{t('Due dates for assessments')}:
																		</Typography>
																		<Box className='note-list'>
																			{teamAssessmentResponse?.myTeamsAssessment?.isSelfColumnVisible && (
																				<Typography variant='h6' className='font-weight-normal'>
																					{t('selfLabel')}
																					<span className='dash'> - </span>
																					{`${getMonthDateYear(
																						teamAssessmentResponse?.myTeamsAssessment?.selfRatingStartDate
																					)} to ${getMonthDateYear(
																						teamAssessmentResponse?.myTeamsAssessment?.selfRatingEndDate
																					)}`}
																				</Typography>
																			)}
																			<Typography variant='h6' className='font-weight-normal'>
																				{t('managerLabel')}
																				<span className='dash'> - </span>
																				{`${getMonthDateYear(
																					teamAssessmentResponse?.myTeamsAssessment?.managerRatingStartDate
																				)} to ${getMonthDateYear(
																					teamAssessmentResponse?.myTeamsAssessment?.managerRatingEndDate
																				)}`}
																			</Typography>
																			{teamAssessmentResponse?.myTeamsAssessment?.isThreeSixtyColumnVisible && (
																				<Typography variant='h6' className='font-weight-normal'>
																					<Typography
																						component={'span'}
																						variant='h6'
																						className='font-weight-normal'
																						dangerouslySetInnerHTML={{
																							__html: t('threeSixty'),
																						}}
																					></Typography>
																					<span className='dash'> - </span>
																					{`${getMonthDateYear(
																						teamAssessmentResponse?.myTeamsAssessment?.threeSixtyStartDate
																					)} to ${getMonthDateYear(
																						teamAssessmentResponse?.myTeamsAssessment?.threeSixtyEndDate
																					)}`}
																				</Typography>
																			)}
																			<Typography variant='h6' className='font-weight-normal'>
																				{t('FinalLabel')}
																				<span className='dash'> - </span>
																				{`${getMonthDateYear(
																					teamAssessmentResponse?.myTeamsAssessment?.finalRatingStartDate
																				)} to ${getMonthDateYear(
																					teamAssessmentResponse?.myTeamsAssessment?.finalRatingEndDate
																				)}`}
																			</Typography>
																		</Box>
																	</Box>
																</ListItem>
															) : (
																<></>
															)}
															<ListItem>
																<Typography
																	variant='h6'
																	className='font-weight-normal'
																	dangerouslySetInnerHTML={{
																		__html: t('threeSixtyNote', {
																			count:
																				teamAssessmentResponse?.myTeamsAssessment?.scoreCalculatedUpon || Enums.ZERO,
																		}),
																	}}
																></Typography>
															</ListItem>
															<ListItem>
																<Typography variant='h6' className='font-weight-normal'>
																	{t('threeSixtyNote1')}
																</Typography>
															</ListItem>{' '}
															<ListItem>
																<Typography variant='h6' className='font-weight-normal'>
																	{t('threeSixtyNote2')}
																</Typography>
															</ListItem>
															<ListItem>
																<Typography variant='h6' className='font-weight-normal'>
																	{t('threeSixtyNote3')}
																</Typography>
															</ListItem>
														</List>
													</Box>
												</Box>
											) : (
												<></>
											)}
											{/* <Box className={showBulkOption ? 'user-note bulk-active' : 'user-note'}>
										<Box className='note-box'>
											<Typography variant='h6' className='font-weight-normal'>
												{t('teamAssessmentNote')}
											</Typography>
										</Box>
									</Box> */}
											{/* {showNote && !performanceCycle?.isCycleCompleted && (
										<Box className={showBulkOption ? 'user-note bulk-active' : 'user-note'}>
											<Box className='note-box'>
												<Typography variant='h6' className='font-weight-normal'>
													{t('teamAssessmentNote')}
												</Typography>
											</Box>
										</Box>
									)} */}

											{showBulkOption && (
												<Box className='bulk-user-edit-bar bulk-user-edit-bar-type1'>
													<Box className='bulk-user-selected'>
														<Typography variant='body1'>
															<TickIcon />
															{checkedUser} {t('selectedLabel')}
														</Typography>
													</Box>
													<Box className='bulk-user-action'>
														<List component='ul' disablePadding>
															<ListItem>
																<OKRButton
																	icon={<DirectReportReviewIcon />}
																	title={
																		performanceCycle.status === 3 ? t('managerAssessment') : t('takeManagerAssessment')
																	}
																	handleClick={handleMultipleManagerAssessment}
																	className='action-btn'
																></OKRButton>
															</ListItem>

															{showFinalAssessmentBtn ? (
																<ListItem>
																	<OKRButton
																		icon={<FinalAssessmentIcon />}
																		title={t('takeFinalAssessment')}
																		handleClick={(e) => handleMultipleFinalAssessment(e)}
																		className='action-btn'
																	></OKRButton>
																</ListItem>
															) : (
																<></>
															)}
														</List>
													</Box>
												</Box>
											)}
										</>
									)}
								</>
							)}
						</>
					)}

					{openNominateDrawer && (
						<NominateMainDrawer
							{...props}
							setNominatedUser={setNominatedUser}
							nominatedUser={nominatedUser}
							performanceNomineeId={performanceCycle?.performanceCycleId}
							setOpenNominateDrawer={setOpenNominateDrawer}
							setReloadRequired={setReloadRequired}
							openNominateDrawer={openNominateDrawer}
						/>
					)}
					{/* <UserSnapshotDrawer
				openSnapshot={openSnapshot}
				selectedUser={selectedUser}
				performanceCycle={performanceCycle}
				handleDrawerClose={handleDrawerClose}
				isTeamAssessment={true}
			/> */}
					{openSnapshot ? (
						<UserSnapshot
							openSnapshot={openSnapshot}
							selectedUser={selectedUser}
							// performanceCycle={performanceCycle}
							handleDrawerClose={handleDrawerClose}
							// isTeamAssessment={props.fromTeamAssessment ? true : false}
						/>
					) : (
						<></>
					)}
				</>
			)}
			{showDownload && assesmentPdfData && (
				<PDFDownloadLink
					document={assesmentPdfData && <AssessmentDetailPdf data={assesmentPdfData} {...props} />}
					fileName={`${formatDateWithoutSpace(new Date())}_${
						assesmentPdfData?.userDetails?.firstName + '_' + assesmentPdfData?.userDetails?.lastName
					}_Overview.pdf`}
					style={{ display: 'none' }}
				>
					{
						<Tooltip title={'Employee_Overview'} arrow>
							<Button className='download-btn' ref={textInput}>
								<DownloadIcon />
							</Button>
						</Tooltip>
					}
				</PDFDownloadLink>
			)}
		</>
	);
};
