import { deleteRequest, getRequest, postRequest, putRequest } from '../config/auth';
import {
	GET_RECOMMENDATION_FILTER,
	AI_OKR_LIST,
	GENERATE_MORE_OKR,
	AI_ADD_OBJECTIVE,
	AI_ADD_KEYRESULT,
	AI_KR_LIST,
	GENERATE_MORE_KR
	
} from '../config/api-url';

export const getRecommendationFilterAPI = (data) => getRequest(`${GET_RECOMMENDATION_FILTER}`, data);
export const aiOkrListingApi = (data) => postRequest(`${AI_OKR_LIST}`, data);
export const aiKrListingApi = (data) => postRequest(`${AI_KR_LIST}`, data);
export const generateMoreOkrApi = (data) => postRequest(`${GENERATE_MORE_OKR}`, data);
export const generateMoreKrApi = (data) => postRequest(`${GENERATE_MORE_KR}`, data);
export const aiAddObjectiveApi = (data) => postRequest(`${AI_ADD_OBJECTIVE}`, data);
export const aiAddKeyResultApi = (data) => postRequest(`${AI_ADD_KEYRESULT}`, data);

