import React, { Fragment, useEffect } from 'react';
import {
	Avatar,
	Badge,
	Box,
	Chip,
	ClickAwayListener,
	FormControlLabel,
	IconButton,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Popper,
	Switch,
	Tooltip,
	Typography,
} from '@material-ui/core';
import {
	abbreviateNumber,
	getOkrProgressDetails,
	getRandomBackgroundColor,
	getRandomColor,
	getSignByType,
	getTime,
	getUserDetails,
	getUserName,
} from '../../config/utils';
import {
	AlertInfoIcon,
	ClapAnimationIcon,
	ClapIcon,
	EditIcon,
	ExternalContributorIcon,
	LinkIcon,
	VirtualLinkContributorIcon,
} from '../../config/svg/CommonSvg';
import { USER_VIEW } from '../../config/app-url';
import { setLocalStorageItem } from '../../services/StorageService';
import { OKRButton } from './OKRButton';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { Enums } from '../../config/enums';
import { start } from 'repl';
import { AnimatedIcon } from './AnimatedIcon';
import clapAnim from '../../images/clapAnim.svg';
import { GroupTargetIcon } from '../../config/svg/MyGoalSvg';
import { CancelIcon } from '../../config/svg/Action';

interface ContributorDetailsProps {
	employeeId: String | Number;
	firstName?: any;
	lastName?: any;
	imagePath?: string;
	image?: string;
	fullName?: String;
	designation?: String;
	contributorsContribution?: String | Number;
	updatedOn?: any;
	isSource?: boolean;
	userType?: String;
	scaleValue?: any;
	alignmentMapPopover?: any;
	isExternal?: boolean;
	teamName?: string;
	backGroundColorCode?: any;
	colorCode?: any;
	startValue?: string;
	targetValue?: string;
	metricId?: any;
	currencyId?: any;
	popoverNoModifiers?: any;
	actionLevel?: any;
	emailId?: any;
	fullEmailId?: boolean;
	ownStartValue?: string;
	ownTargetValue?: string;
	isActive?: boolean;
	isDeleteDisabled?: boolean;
	handleRemoveReviewer?: (event: any, user: any) => void;
}

export const ContributorsListPopover: React.FC<any> = (props) => {
	const {
		contributorDetails,
		open,
		anchorEl,
		setOpen,
		setAnchorEl,
		loginUserAvatar,
		currentUser,
		poperContainer,
		contributorClassName,
		scaleValue,
		alignmentMapPopover,
		popupView,
		handleTabChange,
		okr,
		virtualAlignmentListPopUp,
		t,
		hideLinks,
		hideScore,
		doNotAllowUserSelection,
		showTeamDetails,
		popoverNoModifiers,
		actionLevel,
		handlePraise,
		krObj,
		fullEmailId = false,
		handleRemoveReviewer = () => {},
	} = props;

	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleListKeyDown = (event: any) => {
		if (event.key === 'Tab') {
			event.preventDefault();
			setOpen(false);
		}
	};
	const userDetail = getUserDetails();
	const loggedInUserDetail = currentUser ? currentUser : userDetail;
	const okrOwnerInfo =
		okr?.contributors && okr?.contributors.length > 0
			? okr?.contributors[0]?.emailId
			: loggedInUserDetail?.emailId || '';

	const getScoreDetails = (kr: any) => {
		const { color } = getOkrProgressDetails({
			value: kr.contributorsContribution,
			dueDate: kr.dueDate,
		});
		const { okrProgress } = getOkrProgressDetails({
			value: kr.contributorsContribution,
			dueDate: kr.dueDate,
		});
		const currencyClass = kr.currencyId === 1 ? 'dollarCurrency' : '';
		const krUpdate = props.showTarget ? kr.updatedOn && kr.contributorsContribution !== 0 : kr.updatedOn;
		return (
			<Box className='contributordetails-right'>
				{
					<>
						{krUpdate ? (
							<Box className='kr-recognition'>
								<Box className='kr-clap-icon'>
									{userDetail.employeeId !== kr.employeeId && kr?.praiseType !== Enums.PRAISE_HIDE && (
										<>
											{kr?.praiseType === Enums.PRAISE_SEND ? (
												<Tooltip
													arrow
													title={
														<>
															{t('sendPraiseTooltip')} <br /> {`"${kr?.firstName} ${kr?.lastName}"`}
														</>
													}
												>
													<IconButton onClick={() => handlePraise(kr, krObj)}>
														<ClapIcon />
													</IconButton>
												</Tooltip>
											) : kr?.praiseType === Enums.PRAISE_SENT ? (
												<Tooltip
													arrow
													title={
														<>
															{t('praiseSentTooltip')} <br /> {`"${kr?.firstName} ${kr?.lastName}"`}
														</>
													}
												>
													<IconButton>
														<AnimatedIcon
															className=''
															width='26'
															type='image/svg+xml'
															alt={t('globalSearchNoRecord')}
															imagePath={clapAnim}
														/>
														{/* <ClapAnimationIcon /> */}
													</IconButton>
												</Tooltip>
											) : (
												<></>
											)}
										</>
									)}
								</Box>
								<Box className='kr-progress-col'>
									<Typography variant='body2' style={{ color }} className={okrProgress + ' ' + currencyClass}>
										{`${
											props.showTarget && kr.metricId === Enums.FOUR ? t('achieved') : kr.contributorsContribution + '%'
										}`}
										{(props.showTarget && kr.metricId === Enums.ONE) ||
										(props.showTarget && kr.metricId === Enums.TWO) ||
										(props.showTarget && kr.metricId === Enums.THREE)
											? getKrContributionValueFormated(kr.myScore || 0, kr.metricId, kr.currencyId)
											: ''}
									</Typography>
									<Typography variant='body2'>{getTime(kr.updatedOn)}</Typography>
								</Box>
							</Box>
						) : kr.contributorsContribution === 0 ? (
							<Fragment>
								<Typography variant='body2' style={{ color }} className={props.showTarget ? 'not-achieved' : ''}>
									{`${
										props.showTarget && kr.metricId === Enums.FOUR
											? t('notAchieved')
											: props.showTarget && kr.metricId === Enums.FIVE
											? t('noUnits')
											: kr.contributorsContribution + '%'
									}`}
								</Typography>
								<Typography variant='body2'>{t('MyGoalPdfNotStarted')}</Typography>
							</Fragment>
						) : null}
					</>
				}
			</Box>
		);
	};

	const onSelectedUser = (selected: any) => {
		if (
			Boolean(selected) &&
			loggedInUserDetail.employeeId !== selected.employeeId &&
			doNotAllowUserSelection !== true &&
			selected.isActive === true
		) {
			setLocalStorageItem('currentUser', JSON.stringify(selected));
			props?.resetOkr && props.resetOkr();
			props?.history && props.history.push(USER_VIEW);
			props?.updateRoute && props.updateRoute(USER_VIEW);
		}
	};
	const scaleStyle = {
		transform: `scale(${scaleValue})`,
	};

	const getTargetValueFormated = (targetValue: any, metricId: any, currencyId: any) => {
		return (
			<>
				{metricId !== Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''}
				{metricId === Enums.FIVE ? 'No Unit' : metricId === Enums.FOUR ? 'Yes/No' : abbreviateNumber(targetValue)}
				{metricId === Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''}
			</>
		);
	};
	const getKrContributionValueFormated = (myScore: any, metricId: any, currencyId: any) => {
		return (
			<>
				{' '}
				({metricId !== Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''}
				{metricId === Enums.ONE || metricId === Enums.TWO || metricId === Enums.THREE ? abbreviateNumber(myScore) : ''}
				{metricId === Enums.ONE ? getSignByType(metricId, currencyId, props.okrMasterData) : ''})
			</>
		);
	};
	// console.log('ContributorsListPopover ===> ', krObj, okr);

	return (
		<Popper
			className={
				contributorClassName && alignmentMapPopover
					? contributorClassName
					: props.viewType && okr?.virtualAlignmentCount
					? 'contributor-details-popover virtual-contributors-list'
					: props.viewType === 'okrList'
					? 'contributor-details-popover obj-contributors-list'
					: props.viewType === 'pipReviewers'
					? 'contributor-details-popover reviewers-details-popover'
					: 'contributor-details-popover'
			}
			open={open}
			anchorEl={anchorEl}
			transition
			placement={
				popoverNoModifiers && alignmentMapPopover && actionLevel < 0 && scaleValue > 1.08
					? 'bottom-start'
					: popoverNoModifiers && alignmentMapPopover
					? 'bottom-end'
					: 'bottom-start'
			}
			modifiers={{
				preventOverflow: {
					enabled: popoverNoModifiers && alignmentMapPopover ? true : alignmentMapPopover ? false : true,
					boundariesElement:
						popoverNoModifiers && alignmentMapPopover
							? 'scrollParent'
							: alignmentMapPopover
							? 'window'
							: 'scrollParent',
				},
			}}
			container={poperContainer === true ? anchorEl : document.body}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Box className='contributor-details-inner' onKeyDown={handleListKeyDown} style={scaleStyle}>
					{props.viewType && props.viewType !== 'okrList' && props.viewType !== 'pipReviewers' && (
						<Box className='contributors-details-head'>
							{popupView === 1 && (
								<OKRButton
									className='back-btn'
									id={'back-btn'}
									handleClick={(e) => handleTabChange(e, 0)}
									icon={<BackArrow />}
									text={t('back')}
								/>
							)}
							{popupView !== 1 && (
								<Box className='contributor-label'>
									<Typography variant='body2'>{t('contributorsLabel')}</Typography>
								</Box>
							)}
							{okr.isVirtualAlignment && (
								<Box className={`virtual-link-btn ${popupView === 1 ? 'active' : ''}`}>
									<OKRButton
										className='btn-link'
										id={'virtual-link-btn'}
										icon={<VirtualLinkContributorIcon />}
										text={t('virtuallylinked', { count: okr.virtualAlignmentCount })}
										handleClick={(e) => {
											handleTabChange(e, 1);
										}}
									/>
								</Box>
							)}
						</Box>
					)}

					{props.showEditHeader && (
						<Box className='contributors-details-head'>
							<Typography>{'Your all Contributor(s)'}</Typography>
							<OKRButton
								className='btn-link'
								text={'Edit'}
								icon={<EditIcon />}
								handleClick={() => {
									props.onEditHeaderclick && props.onEditHeaderclick();
								}}
							/>
						</Box>
					)}
					{krObj?.isGroupTarget && (
						<Box className='group-target-details'>
							<Box className='group-target-info'>
								<Typography variant='h6'>
									<GroupTargetIcon />
									{`${t('groupTargetLabel')} ${
										krObj?.isGroupTarget ? `(KR Target: ${krObj?.startValue} - ${krObj?.targetValue})` : ''
									}`}
								</Typography>
								{/* <FormControlLabel
									control={<Switch checked={true} name='isGroupTarget' inputProps={{ 'aria-label': 'controlled' }} />}
									label={`${t('groupTargetLabel')} ${
										krObj?.isGroupTarget ? `(# KR Target: ${krObj?.startValue} - ${krObj?.targetValue})` : ''
									}`}
									labelPlacement='end'
								/> */}
							</Box>
							<Box className='contributor-label'>
								<Typography
									variant='h6'
									className={
										krObj?.progressColorCode === 'FF0000'
											? 'at-risk'
											: krObj?.progressColorCode === 'FF9401'
											? 'lagging'
											: krObj?.progressColorCode === '07A363'
											? 'on-track'
											: 'no-started'
									}
								>
									{t('progress')} {t('groupLabel')}
									{krObj?.myScore === 0 || krObj?.score === 0 ? (
										<span style={{ color: '#ff0000' }}> 0%</span>
									) : (
										<span style={{ color: `#${krObj?.progressColorCode}` }}>
											{` ${krObj?.myScore || krObj?.score || ''}%`}
											{krObj.metricId === Enums.ONE || krObj.metricId === Enums.TWO || krObj.metricId === Enums.THREE
												? getKrContributionValueFormated(krObj.currentValue || 0, krObj.metricId, krObj.currencyId)
												: ''}
										</span>
									)}
								</Typography>
							</Box>
						</Box>
					)}
					{props.showTarget && (
						<Box className='contributors-details-head contributors-details-targethead'>
							<Box className='col-1'>
								<Box className='contributor-label'>
									<Typography variant='h6'>{`${t('contributorsText')}`}</Typography>
								</Box>
							</Box>
							{krObj?.isGroupTarget ? null : (
								<Box className='col-2'>
									<Box className='contributor-label'>
										<Typography variant='h6'>{t('startTargetHyphen')}</Typography>
									</Box>
								</Box>
							)}
							{krObj?.isGroupTarget ? null : (
								<Box className='col-3'>
									<Box className='contributor-label'>
										<Typography variant='h6'>
											{t('progress')} {t('soloLabel')}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					)}

					{popupView === 0 && (
						<MenuList
							className={`contributor-details-list contributorsList ${hideLinks === true ? 'hide-linking' : ''} ${
								props.showTarget && !krObj?.isGroupTarget ? 'show-target' : 'show-target obj-without-target'
							} ${props.viewType !== 'okrList' && hideScore !== true ? 'two-col-popup' : ''}`}
						>
							{loginUserAvatar && (
								<MenuItem
									id={`login-contributor-${loginUserAvatar.employeeId}`}
									className={`${loginUserAvatar.isActive === false ? 'disabled-row' : ''}`}
								>
									<Box className='contributordetails-left' onClick={() => onSelectedUser(loginUserAvatar)}>
										<ListItemAvatar
											className={
												loginUserAvatar.employeeId === userDetail.employeeId ? 'owner-avatar' : 'parent-avatar'
											}
										>
											<>
												{loginUserAvatar.imagePath || loginUserAvatar.image ? (
													<Avatar
														className='avatar-default'
														alt={`${loginUserAvatar.firstName} ${loginUserAvatar.lastName}`}
														src={loginUserAvatar.imagePath || loginUserAvatar.image}
													/>
												) : (
													<Avatar className='avatar-default'>
														{getUserName({
															firstName: loginUserAvatar.firstName || '',
															lastName: loginUserAvatar.lastName || '',
															fullName: loginUserAvatar.fullName || '',
														})}
													</Avatar>
												)}

												{loginUserAvatar.isActive === false ? (
													<Tooltip
														placement='top'
														arrow
														classes={{ popper: 'tooltip-alert' }}
														title={`${
															props.viewType === 'pipReviewers' ? t('userDeleted') : t('pendingTargetDistributionMsg')
														}`}
													>
														<Badge
															className={'badge-icon okr-badge-icon'}
															overlap='rectangular'
															badgeContent={<AlertInfoIcon />}
														></Badge>
													</Tooltip>
												) : (
													<></>
												)}
											</>
										</ListItemAvatar>
										<Box
											className={`${
												loginUserAvatar?.isActive === false
													? 'contributor-info contributor-info-parent disabled-row'
													: 'contributor-info contributor-info-parent'
											}`}
										>
											<ListItemText
												className='contributor-name'
												primary={
													// props.showTarget &&
													// loginUserAvatar?.firstName &&
													// loginUserAvatar?.lastName &&
													// (loginUserAvatar?.firstName + loginUserAvatar?.lastName).length > 15 ? (
													// 	<Tooltip title={loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName} arrow>
													// 		<Typography className='tooltip-text' component='span' variant='h4'>
													// 			{loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName}
													// 		</Typography>
													// 	</Tooltip>
													// ) : loginUserAvatar?.firstName &&
													//   loginUserAvatar?.lastName &&
													//   (loginUserAvatar?.firstName + loginUserAvatar?.lastName).length > 15 ? (
													// 	<Tooltip title={loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName} arrow>
													// 		<Typography className='tooltip-text' component='span' variant='h4'>
													// 			{loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName}
													// 		</Typography>
													// 	</Tooltip>
													// ) : (
													// 	<Typography variant='h4'>
													// 		{loginUserAvatar?.firstName} {loginUserAvatar?.lastName}
													// 	</Typography>
													// )
													<Tooltip
														title={
															loginUserAvatar?.firstName
																? loginUserAvatar?.firstName + ' ' + loginUserAvatar?.lastName
																: loginUserAvatar.fullName
														}
														arrow
													>
														<Typography variant='h4' className='contributor-name-truncate'>
															{loginUserAvatar?.firstName ? (
																<>
																	{loginUserAvatar?.firstName} {loginUserAvatar?.lastName}
																</>
															) : (
																loginUserAvatar.fullName
															)}
														</Typography>
													</Tooltip>
												}
												secondary={
													Boolean(loginUserAvatar.userType) ||
													loginUserAvatar?.userType === '' ||
													loginUserAvatar?.userType === null ? (
														loginUserAvatar.userType === null ? (
															''
														) : (
															loginUserAvatar.userType
														)
													) : loginUserAvatar.employeeId === loggedInUserDetail.employeeId ? (
														<Fragment>
															{props?.viewType === 'pipReviewers' ? (
																<Typography variant='inherit'>Initiator</Typography>
															) : (
																<>
																	<LinkIcon /> <Typography variant='inherit'>Parent</Typography>
																</>
															)}
														</Fragment>
													) : (
														<Fragment>
															{props?.viewType === 'pipReviewers' ? (
																<Typography variant='inherit'>Initiator</Typography>
															) : (
																<>
																	<LinkIcon /> <Typography variant='inherit'>Parent</Typography>
																</>
															)}
														</Fragment>
													)
												}
											/>
											{/* {fullEmailId === true ? (
												<>
													<Typography variant='subtitle2'>{okrOwnerInfo || loginUserAvatar?.emailId}</Typography>
												</>
											) : props.viewType === 'okrList' ? (
												okrOwnerInfo && okrOwnerInfo.length > 25 ? (
													<Tooltip title={okrOwnerInfo} arrow>
														<Typography variant='subtitle2'>{okrOwnerInfo.substring(0, 25) + '...'}</Typography>
													</Tooltip>
												) : (
													<Typography variant='subtitle2'>{okrOwnerInfo}</Typography>
												)
											) : loginUserAvatar?.emailId && loginUserAvatar?.emailId.length > 25 ? (
												<Tooltip title={loginUserAvatar.emailId} arrow>
													<Typography variant='subtitle2'>
														{loginUserAvatar.emailId.substring(0, 25) + '...'}
													</Typography>
												</Tooltip>
											) : (
												<Typography variant='subtitle2'>{loginUserAvatar?.emailId}</Typography>
											)} */}
										</Box>
									</Box>
									{props.showTarget && !krObj?.isGroupTarget && (
										<Box className='contributordetails-middle'>
											<Typography variant='body2'>
												{getTargetValueFormated(
													loginUserAvatar?.startValue,
													loginUserAvatar?.metricId,
													loginUserAvatar?.currencyId
												)}
												<span className='slash'>-</span>
												{getTargetValueFormated(
													loginUserAvatar?.targetValue,
													loginUserAvatar?.metricId,
													loginUserAvatar?.currencyId
												)}
											</Typography>
										</Box>
									)}
									{!krObj?.isGroupTarget &&
										(props.showTarget && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
											<Box className='contributordetails-right'>
												<Typography variant='h6'>{t('notSubmitted')}</Typography>
											</Box>
										) : props.viewType !== 'okrList' ? (
											getScoreDetails(loginUserAvatar)
										) : (
											<></>
										))}
								</MenuItem>
							)}
							{contributorDetails ? (
								contributorDetails.map(
									(item: ContributorDetailsProps) =>
										item && (
											<MenuItem
												key={`cd${item.employeeId}`}
												id={`contributor-detail-${item.employeeId}`}
												className={`${item.isActive === false ? 'disabled-row' : ''}`}
											>
												<Box
													className={`contributordetails-left ${item.isActive === false ? 'disabled' : ''}`}
													onClick={() => onSelectedUser(item)}
												>
													<ListItemAvatar
														className={
															item.employeeId === userDetail.employeeId && props?.viewType !== 'pipReviewers'
																? 'owner-avatar'
																: ''
														}
													>
														<>
															{item.imagePath || item.image ? (
																<Avatar
																	className='avatar-default'
																	alt={`${item.firstName} ${item.lastName}`}
																	src={item.imagePath || item.image}
																/>
															) : (
																<Avatar className='avatar-default'>
																	{getUserName({
																		firstName: item.firstName || '',
																		lastName: item.lastName || '',
																		fullName: item.fullName || '',
																	})}
																</Avatar>
															)}
															{item.isActive === false ? (
																<Tooltip
																	placement='top'
																	arrow
																	classes={{ popper: 'tooltip-alert' }}
																	title={`${
																		props.viewType === 'pipReviewers'
																			? t('userDeleted')
																			: t('pendingTargetDistributionMsg')
																	}`}
																>
																	<Badge
																		className={'badge-icon okr-badge-icon'}
																		overlap='rectangular'
																		badgeContent={<AlertInfoIcon />}
																	></Badge>
																</Tooltip>
															) : (
																<></>
															)}
														</>
													</ListItemAvatar>
													{fullEmailId === true ? (
														<Box className='contributor-info'>
															<ListItemText
																className='contributor-name'
																primary={
																	<Tooltip
																		title={
																			item?.firstName ? item?.firstName + ' ' + item?.lastName : item.fullName + ''
																		}
																		arrow
																	>
																		<Typography variant='h4' className='contributor-name-truncate'>
																			{item?.firstName ? (
																				<>
																					{item?.firstName} {item?.lastName}
																				</>
																			) : (
																				item.fullName
																			)}
																		</Typography>
																	</Tooltip>
																}
																secondary={`${
																	Boolean(item?.userType) || item?.userType === '' || item?.userType === null
																		? item.userType === null
																			? ''
																			: item.userType
																		: item.employeeId === loggedInUserDetail.employeeId
																		? ''
																		: ''
																}`}
															/>
															{/* <Typography variant='subtitle2'>{item?.emailId}</Typography> */}
															{showTeamDetails && item.teamName && (
																<Box className='teams-chips'>
																	{item?.isExternal && (
																		<Tooltip title={t('externalContributorTooltip')} arrow>
																			<span className='external-link'>
																				{' '}
																				<ExternalContributorIcon />{' '}
																			</span>
																		</Tooltip>
																	)}
																	<Tooltip title={item.teamName} arrow>
																		<Chip
																			className='chip'
																			style={{
																				backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																				color: getRandomColor(item?.colorCode),
																			}}
																			label={item.teamName}
																		/>
																	</Tooltip>
																</Box>
															)}
														</Box>
													) : (
														<Box className='contributor-info'>
															<ListItemText
																className='contributor-name'
																primary={
																	// props.showTarget &&
																	// item?.firstName &&
																	// item?.lastName &&
																	// (item?.firstName + item?.lastName).length > 15 ? (
																	// 	<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																	// 		<Typography className='tooltip-text' component='span' variant='h4'>
																	// 			{item?.firstName + ' ' + item?.lastName}
																	// 		</Typography>
																	// 	</Tooltip>
																	// ) : item?.firstName &&
																	//   item?.lastName &&
																	//   (item?.firstName + item?.lastName).length > 15 ? (
																	// 	<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																	// 		<Typography className='tooltip-text' component='span' variant='h4'>
																	// 			{item?.firstName + ' ' + item?.lastName}
																	// 		</Typography>
																	// 	</Tooltip>
																	// ) : (
																	// 	<Typography variant='h4'>
																	// 		{item?.firstName} {item?.lastName}
																	// 	</Typography>
																	// )
																	<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																		<Typography variant='h4' className='contributor-name-truncate'>
																			{item?.firstName} {item?.lastName}
																		</Typography>
																	</Tooltip>
																}
																secondary={`${
																	Boolean(item?.userType) || item?.userType === '' || item?.userType === null
																		? item.userType === null
																			? ''
																			: item.userType
																		: item.employeeId === loggedInUserDetail.employeeId
																		? ''
																		: ''
																}`}
															/>
															{/* {item?.emailId && item?.emailId.length > 25 ? (
																<Tooltip title={item.emailId} arrow>
																	<Typography variant='subtitle2'>{item.emailId.substring(0, 25) + '...'}</Typography>
																</Tooltip>
															) : (
																<Typography variant='subtitle2'>{item?.emailId}</Typography>
															)} */}
															{showTeamDetails && item.teamName && (
																<Box className='teams-chips'>
																	{item?.isExternal && (
																		<Tooltip title={t('externalContributorTooltip')} arrow>
																			<span className='external-link'>
																				{' '}
																				<ExternalContributorIcon />{' '}
																			</span>
																		</Tooltip>
																	)}
																	<Tooltip title={item.teamName} arrow>
																		<Chip
																			className='chip'
																			style={{
																				backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																				color: getRandomColor(item?.colorCode),
																			}}
																			label={item.teamName}
																		/>
																	</Tooltip>
																</Box>
															)}
														</Box>
													)}
												</Box>
												{props.showTarget && !krObj?.isGroupTarget && (
													<Box className='contributordetails-middle'>
														<Typography variant='body2'>
															{getTargetValueFormated(item?.startValue, item?.metricId, item?.currencyId)}
															<span className='slash'>-</span>
															{getTargetValueFormated(item?.targetValue, item?.metricId, item?.currencyId)}
														</Typography>
													</Box>
												)}
												{!krObj?.isGroupTarget &&
													(props.showTarget && okr?.goalStatusId === Enums.GOAL_STATUS_DRAFT ? (
														<Box className='contributordetails-right'>
															<Typography variant='h6'>{t('notSubmitted')}</Typography>
														</Box>
													) : (
														props.viewType !== 'okrList' &&
														hideScore !== true &&
														props.viewType !== 'pipReviewers' &&
														getScoreDetails(item)
													))}
												{/* {console.log(userDetail, item, props.viewType, props?.isDisabled)} */}
												{props.viewType === 'pipReviewers' &&
												!props?.isDisabled &&
												!item.isDeleteDisabled &&
												userDetail.employeeId !== item.employeeId ? (
													<Box className='contributordetails-right'>
														<OKRButton
															className='remove-reviewer-btn'
															title='Remove'
															icon={<CancelIcon />}
															handleClick={(e: any) => handleRemoveReviewer(e, item)}
														/>
													</Box>
												) : (
													<></>
												)}
											</MenuItem>
										)
								)
							) : (
								<Typography></Typography>
							)}
						</MenuList>
					)}
					{popupView === 1 && (
						<MenuList className='contributor-details-list virtualAlignment'>
							<Box className='virtualAlignments'>
								{virtualAlignmentListPopUp && virtualAlignmentListPopUp.length ? (
									virtualAlignmentListPopUp.map(
										(item: any, index: any) =>
											item && (
												<MenuItem id={`virtual-card-${index}`}>
													<Box
														className={`${
															item.isActive === false
																? ' contributordetails-left disabled-row'
																: 'contributordetails-left'
														}`}
														onClick={() => onSelectedUser(item)}
													>
														<Box className='user-info'>
															<Box className='user-img'>
																<>
																	{item.imagePath || item.image ? (
																		<Avatar
																			className='avatar-default'
																			alt={`${item.firstName} ${item.lastName}`}
																			src={item.imagePath || item.image}
																		/>
																	) : (
																		<Avatar className='avatar-default'>
																			{getUserName({
																				firstName: item.firstName || '',
																				lastName: item.lastName || '',
																				fullName: item.fullName || '',
																			})}
																		</Avatar>
																	)}
																	{item.isActive === false ? (
																		<Tooltip
																			placement='top'
																			arrow
																			classes={{ popper: 'tooltip-alert' }}
																			title={`${
																				props.viewType === 'pipReviewers'
																					? t('userDeleted')
																					: t('pendingTargetDistributionMsg')
																			}`}
																		>
																			<Badge
																				className={'badge-icon okr-badge-icon'}
																				overlap='rectangular'
																				badgeContent={<AlertInfoIcon />}
																			></Badge>
																		</Tooltip>
																	) : (
																		<></>
																	)}
																</>
															</Box>
															{fullEmailId === true ? (
																<Box className='user-info-details'>
																	<ListItemText
																		className='contributor-name'
																		primary={
																			<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																				<Typography variant='h4' className='contributor-name-truncate'>
																					{item.firstName} {item.lastName}
																				</Typography>
																			</Tooltip>
																		}
																		secondary={`${
																			Boolean(item?.userType) || item?.userType === '' || item?.userType === null
																				? item.userType === null
																					? ''
																					: item.userType
																				: ''
																		}`}
																	/>
																	{/* <Typography variant='subtitle2'>{item?.emailId}</Typography> */}
																	<Box className='teams-chips'>
																		<Tooltip title={item.teamName} arrow>
																			<Chip
																				className='chip'
																				style={{
																					backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																					color: getRandomColor(item?.colorCode),
																				}}
																				label={item.teamName}
																			/>
																		</Tooltip>
																	</Box>
																</Box>
															) : (
																<Box className='user-info-details'>
																	<ListItemText
																		className='contributor-name'
																		primary={
																			<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																				<Typography variant='h4' className='contributor-name-truncate'>
																					{item.firstName} {item.lastName}
																				</Typography>
																			</Tooltip>
																		}
																		secondary={`${
																			Boolean(item?.userType) || item?.userType === '' || item?.userType === null
																				? item.userType === null
																					? ''
																					: item.userType
																				: ''
																		}`}
																	/>
																	{/* {item?.emailId && item?.emailId.length > 25 ? (
																		<Tooltip title={item.emailId} arrow>
																			<Typography variant='subtitle2'>
																				{item.emailId.substring(0, 25) + '...'}
																			</Typography>
																		</Tooltip>
																	) : (
																		<Typography variant='subtitle2'>{item?.emailId}</Typography>
																	)} */}
																	<Box className='teams-chips'>
																		<Tooltip title={item.teamName} arrow>
																			<Chip
																				className='chip'
																				style={{
																					backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																					color: getRandomColor(item?.colorCode),
																				}}
																				label={item.teamName}
																			/>
																		</Tooltip>
																	</Box>
																</Box>
															)}
														</Box>
													</Box>
													{props.viewType !== 'okrList' && getScoreDetails(item)}
												</MenuItem>
											)
									)
								) : (
									<Typography></Typography>
								)}
							</Box>
						</MenuList>
					)}

					{popupView === 1 && props.viewType && virtualAlignmentListPopUp && virtualAlignmentListPopUp.length > 0 && (
						<Box className='virtual-contributor-actions'>
							<OKRButton
								handleClick={(e) => {
									handleTabChange(e, 0);
									handleClose();
								}}
								className='btn-link'
								text={t('viewAll')}
								id={'view-all-btn'}
							/>
						</Box>
					)}
				</Box>
			</ClickAwayListener>
		</Popper>
	);
};
