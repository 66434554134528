import { Box, Tabs, Tab } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateBulkUserForm } from './CreateBulkUserForm';
import { BulkEmailUpdateForm } from './BulkEmailUpdateForm';
import { BulkGoalRoleUpdate } from './BulkGoalRoleUpdate';
import { CreateUserForm } from './CreateUserForm';
import { checkSupportPermission } from '../../../../config/utils';
import { supportModuleAlignGoals } from '../../AdminConstant';

export const CreateUserDrawerForm: React.FC<any> = (props) => {
	const {
		selectedAddNewUserTab,
		handleTabChange,
		userFormData,
		setUserFormData,
		formError,
		setFormError,
		setFormEdited,
		isEditForm,
		errorLogMessage,
		setErrorLogMessage,
		uploadFileData,
		setUploadFileData,
		setLoader,
	} = props;
	const { t } = useTranslation();
	const handleChange = (e: any) => {
		e.preventDefault();
		const { target } = e;
	};
	function a11yProps(index: number) {
		return {
			id: `admin-tab-${index}`,
			'aria-controls': `admin-tabpanel-${index}`,
		};
	}

	return (
		<Fragment>
			{isEditForm === true ? (
				<Tabs
					className='okr-tabs drawer-tabs'
					value={selectedAddNewUserTab}
					onChange={handleTabChange}
					aria-label='Admin Tabs'
				>
					<Tab label={t('editUserDetailsText')} value={0} {...a11yProps(0)} />
				</Tabs>
			) : (
				<Tabs
					className='okr-tabs drawer-tabs'
					value={selectedAddNewUserTab}
					onChange={handleTabChange}
					aria-label='Admin Tabs'
				>
					<Tab label={t('addUserTabLabelText')} value={0} {...a11yProps(0)} />
					<Tab label={t('addBulkUserLabel')} value={1} {...a11yProps(1)} />
					<Tab label={t('bulkEmailUpdateLabel')} value={2} {...a11yProps(2)} />
					{checkSupportPermission(supportModuleAlignGoals) && (
						<Tab label={t('bulkGoalFormUpdateLabel')} value={3} {...a11yProps(3)} />
					)}
				</Tabs>
			)}
			<Box className={`drawer-tabs-panel ${selectedAddNewUserTab === 0 ? 'add-user-tab-panel' : ''}`}>
				{selectedAddNewUserTab === 0 && (
					<CreateUserForm
						{...props}
						userFormData={userFormData}
						setUserFormData={setUserFormData}
						formError={formError}
						setFormError={setFormError}
						setFormEdited={setFormEdited}
						isEditForm={isEditForm}
						setLoader={setLoader}
					/>
				)}
				{selectedAddNewUserTab === 1 && (
					<CreateBulkUserForm
						{...props}
						uploadFileData={uploadFileData}
						setUploadFileData={setUploadFileData}
						errorLogMessage={errorLogMessage}
						setErrorLogMessage={setErrorLogMessage}
						formError={formError}
						setLoader={setLoader}
					/>
				)}
				{selectedAddNewUserTab === 2 && (
					<BulkEmailUpdateForm
						{...props}
						uploadFileData={uploadFileData}
						setUploadFileData={setUploadFileData}
						errorLogMessage={errorLogMessage}
						setErrorLogMessage={setErrorLogMessage}
						formError={formError}
					/>
				)}
				{selectedAddNewUserTab === 3 && (
					<BulkGoalRoleUpdate
						{...props}
						uploadFileData={uploadFileData}
						setUploadFileData={setUploadFileData}
						errorLogMessage={errorLogMessage}
						setErrorLogMessage={setErrorLogMessage}
						formError={formError}
					/>
				)}
			</Box>
		</Fragment>
	);
};
