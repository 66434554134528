import React, { useEffect, useState } from 'react';
import { Box, Chip, FormControlLabel, List, ListItem, Switch, TextField, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { AddIconSvg, DeleteIcon, EditIcon, TickIcon } from '../../../../../config/svg/CommonSvgIcon';
import { CancelIcon } from '../../../../../config/svg/Action';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Enums } from '../../../../../config/enums';
import { useSnackbar } from 'notistack';
import {
	deleteFeedbackCategory,
	reloadFeebackCategoryList,
	updateFeedbackCategoryEnable,
} from '../../../../../action/adminPerformanceDevelopment';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';
import { FeedbackTimeFrame, FeedbackTimeFrameType } from '../../../../../config/constant';
import { AnimatedIcon } from '../../../CommonComponent/AnimatedIcon';
import noRecordFound from '../../../../../images/my-goal-bg.svg';

export const FeedbackCategorySettings: React.FC<any> = (props: any) => {
	const { feedbackCategoryData, setLoader, setAddFeedbackCategoryDrawer, feedbackTypeId } = props;
	const { t } = useTranslation();
	const [disableToggle, setDisableToggle] = useState<boolean>(false);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [feedbackTypeList, setFeedbackTypeList] = useState<any>(null);
	const [noRecords, setNoRecords] = useState<any>(false);
	const [modalOpen, setModalOpen] = useState<any>({ open: false, item: null });

	const showMsg = (variant: any, data: any) => {
		const { messageList } = data;
		const keys = messageList ? Object.keys(messageList) : [];
		const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
		enqueueSnackbar(messages || `Error occurred while performing action`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};
	const enableFeedbackCategory = async (e: any, item: any, isChecked = false) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			setDisableToggle(true);
			const response: any = await dispatch(
				updateFeedbackCategoryEnable({
					feedbackCategoryId: item.feedbackCategoryId,
					isEnabled: isChecked,
				})
			);
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setDisableToggle(false);
				dispatch(reloadFeebackCategoryList(item.feedbackTypeId));
				showMsg('success', response?.data || {});
			} else {
				setLoader(false);
				setDisableToggle(false);
				showMsg('error', response?.data || {});
			}
		} catch (error) {
			console.error(error);
			setNoRecords(false);
			setFeedbackTypeList([]);
			setLoader(false);
		}
	};
	const handleDelete = async (item: any) => {
		setModalOpen({ open: true, item });
	};
	const deleteCategory = async (item: any) => {
		try {
			setLoader(true);
			const response: any = await dispatch(deleteFeedbackCategory(`feedbackCategoryId=${item.feedbackCategoryId}`));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				showMsg('success', response?.data || {});
				dispatch(reloadFeebackCategoryList(item.feedbackTypeId));
				setLoader(false);
			} else {
				showMsg('error', response?.data || {});
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			deleteCategory(modalOpen.item);
		}
		setModalOpen({ open: false, item: null });
	};
	const getFeedbackCategoryTimeFrame = (feedbackRec: any) => {
		if (feedbackRec.timeframe && FeedbackTimeFrame.length > 0) {
			let selectedRecord = FeedbackTimeFrame.filter((item: any) => item.id === feedbackRec.timeframe);
			if (selectedRecord && selectedRecord.length > 0) {
				return selectedRecord[0].name;
			}
		} else {
			return FeedbackTimeFrame[0].name;
		}
	};

	const getFeedbackCategoryTimeFrameType = (feedbackRec: any) => {
		if (feedbackRec.timeframeType && FeedbackTimeFrameType.length > 0) {
			let selectedRecord = FeedbackTimeFrameType.filter((item: any) => item.id === feedbackRec.timeframeType);
			if (selectedRecord && selectedRecord.length > 0) {
				return selectedRecord[0].name;
			}
		} else {
			return FeedbackTimeFrameType[0].name;
		}
	};
	return (
		<Box className='feedback-category-area'>
			{feedbackCategoryData && feedbackCategoryData?.length ? (
				<Box className='okr-list-panel-head'>
					<List component='ul'>
						<ListItem className='feedback-category-col1'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('categoryName')}</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='feedback-category-col2'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('createdBy')}</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='feedback-category-col3'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('statusLabel')}</span>
								</span>
							</Typography>
						</ListItem>
						{/* <ListItem className='feedback-category-col4'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('timeFrameText')}</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='feedback-category-col5'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('timeframeType')}</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='feedback-category-col6'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('durationLevel')}</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='feedback-category-col7'>
							<Typography variant='body2' className='sort-title'>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('notificationDuration')}</span>
								</span>
							</Typography>
						</ListItem> */}
					</List>
				</Box>
			) : (
				<></>
			)}
			{feedbackCategoryData && feedbackCategoryData.length > 0 ? (
				feedbackCategoryData.map((item: any, index: number) => (
					<Box className='feedback-category-listing-content'>
						<List component='ul'>
							<ListItem className='feedback-category-col1'>
								<Typography>{item.name}</Typography>
								<Box className='feedback-category-listing-actions'>
									<OKRButton
										icon={<EditIcon />}
										title={t('editLabel')}
										handleClick={() => {
											setAddFeedbackCategoryDrawer({
												open: true,
												typeData: { feedbackTypeId: feedbackTypeId },
												categoryData: item,
											});
										}}
									/>
									<OKRButton icon={<DeleteIcon />} title={t('delete')} handleClick={(e) => handleDelete(item)} />
								</Box>
							</ListItem>
							<ListItem className='feedback-category-col2'>
								<Box className='feedback-category-chip'>
									{<Chip label={item.isDefault ? t('defaultLabel') : t('Created by admin')} />}
								</Box>
							</ListItem>
							<ListItem className='feedback-category-col3'>
								<Box className='status-switch'>
									<FormControlLabel
										control={<Switch name='feedbackTypeStatus' checked={item.isEnabled} />}
										label={''}
										disabled={disableToggle}
										checked={item.isEnabled}
										onChange={(e: any) => {
											enableFeedbackCategory(e, item, e.target.checked);
										}}
									/>
								</Box>
							</ListItem>
							{/* <ListItem className='feedback-category-col4'>
								<Typography>{getFeedbackCategoryTimeFrame(item)}</Typography>
							</ListItem>
							<ListItem className='feedback-category-col5'>
								<Typography>{getFeedbackCategoryTimeFrameType(item)}</Typography>
							</ListItem>
							<ListItem className='feedback-category-col6'>
								<Typography>{item.timeframeDuration ? item.timeframeDuration : 'NA'}</Typography>
							</ListItem>
							<ListItem className='feedback-category-col7'>
								<Typography>{item.reminderDuration ? item.reminderDuration : 'NA'}</Typography>
							</ListItem> */}
						</List>
					</Box>
				))
			) : (
				<Box className='no-record-message'>
					<Typography variant='h2' component='h2'>
						{t('NoRecord')}
					</Typography>
					<AnimatedIcon
						className='no-record-img'
						width='250'
						type='image/svg+xml'
						alt={t('globalSearchNoRecord')}
						imagePath={noRecordFound}
					/>
				</Box>
			)}

			{modalOpen.open && (
				<DialogComponent
					module='information'
					message={t('deleteFeedbackCategory', {
						feedbackCategoryName: modalOpen?.item?.name ? '"' + modalOpen?.item?.name + '"' : '',
					})}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen.open}
				/>
			)}
		</Box>
	);
};
