import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Chip,
	InputAdornment,
	List,
	ListItem,
	TextField,
	Tooltip,
	Typography,
	TablePagination,
} from '@material-ui/core';
import { CrossIcon, DeleteIcon, EditIcon, SearchIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/manageOrganization.scss';
import { TeamColumn } from './TeamColumn';
import { getMonthDateYear, getRandomBackgroundColor, getRandomColor, getUserName } from '../../../../config/utils';
import { UserSearchListPopover } from '../../CommonComponent/UserSearchListPopover';
import { OKRButton } from '../../../Common/OKRButton';
import { AnimatedIcon } from '../../CommonComponent/AnimatedIcon';
import noRecordFound from '../../../../images/search-placeholder.svg';
import { AddTeam } from '../AddTeam/AddTeam';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { Enums } from '../../../../config/enums';
import { useSnackbar } from 'notistack';
import CreateOrganization from '../CreateOrganization';
import { EditOrganization } from '../EditOrganization/EditOrganization';

export const ManageOrganization: React.FC<any> = (props: any) => {
	const {
		isOnHoverVisible = true,
		openAddTeamDrawer,
		handleMenuClick,
		tabSelected,
		manageOrgResult,
		manageOrgPending,
		manageTeamBox,
	} = props;
	const { t } = useTranslation();
	const [pageIndex, setPageIndex] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(10);
	const [sortOrder, setSortOrder] = useState<string>('ASC');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [searchTextArray, setSearchTextArray] = useState<any>([]);
	const [organizationListDetail, setOrganizationListDetail] = useState<any>([]);
	const [organizationBox, setOrganizationBox] = useState<any>({});
	const [searchValue, setSearchValue] = useState('');
	const [anchorElUserSearch, setAnchorElUserSearch] = useState(null);
	const [openUserSearch, setOpenUserSearch] = useState<any>(false);
	const [drawerProps, setDrawerProps] = useState<any>({ createType: '', header: '', callApi: false });
	const [totalUser, setTotalUser] = useState(0);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '' });
	const { enqueueSnackbar } = useSnackbar();

	const handleUserOpen = (event: any) => {
		setAnchorElUserSearch(event.currentTarget);
		setOpenUserSearch(true);
	};
	const handleUserClose = () => {
		setOpenUserSearch(false);
		setAnchorElUserSearch(null);
	};

	/**
	 * initial state of payload
	 */
	const payload = {
		pageIndex: pageIndex,
		pageSize: pageSize,
		sortingText: sortingColumn,
		order: sortOrder,
		finder: searchTextArray,
	};
	/**
	 * Fetch org list
	 */
	const fetchOrgList = (payload: any) => {
		props.getManageOrgList(payload);
	};
	const teamBox = Object.keys(organizationBox);

	useEffect(() => {
		fetchOrgList(payload);
	}, []);

	useEffect(() => {
		if (manageOrgResult?.records?.organizationListDetailResponse) {
			setOrganizationListDetail(manageOrgResult.records.organizationListDetailResponse);
			setTotalUser(manageOrgResult.pagingInfo && manageOrgResult.pagingInfo.totalRecords);
		}
	}, [manageOrgResult]);

	useEffect(() => {
		setOrganizationBox(manageTeamBox);
	}, [manageTeamBox]);

	const handleSearchOrg = (e: any) => {
		setSearchValue(e.target.value);
	};

	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleOrgSearch(e.target.value);
			}
			setSearchValue('');
		}
	};

	const handleOrgSearch = (value: any) => {
		setPageIndex(1);
		const updatedValue: any = [...searchTextArray, value];
		setSearchTextArray(updatedValue);
		let payload = {
			pageIndex: 1,
			pageSize: pageSize,
			sortingText: '',
			order: '',
			finder: updatedValue,
		};
		fetchOrgList(payload);
	};

	const clearSearch = (searchText: any) => {
		if (searchText === 'all') {
			setPageIndex(1);
			setSearchTextArray([]);
			let payload = {
				pageIndex: 1,
				pageSize: pageSize,
				sortingText: '',
				order: '',
				finder: [],
			};
			fetchOrgList(payload);
		} else {
			const newArray = searchTextArray.filter((item: any) => item !== searchText);
			setPageIndex(1);
			setSearchTextArray(newArray);
			let payload = {
				pageIndex: 1,
				pageSize: pageSize,
				sortingText: '',
				order: '',
				finder: newArray,
			};
			fetchOrgList(payload);
		}
	};

	const sortUserList = (e: any, column: any, team?: any) => {
		let newSortOrder = 'desc';
		if (team) {
			newSortOrder = team;
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			if (column === sortingColumn) {
				setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
				newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
			} else {
				setSortOrder('desc');
			}
		}

		let payload = {
			pageIndex: 1,
			pageSize: pageSize,
			sortingText: column,
			order: newSortOrder,
			finder: searchTextArray,
		};
		fetchOrgList(payload);
		setSortingColumn(column);
	};

	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		let payload = {
			pageIndex: newPage,
			pageSize: pageSize,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
		};
		fetchOrgList(payload);
	};

	const closeDrawer = () => {
		setDrawerProps({ createType: '', header: '' });
	};

	const handleChangeRowsPerPage = async (event: any) => {
		let pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);

		let payload = {
			pageIndex: 1,
			pageSize: pSize,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
		};
		fetchOrgList(payload);
	};

	const handleDeleteOrganization = (e: any, organisation: any) => {
		e.preventDefault();
		e.stopPropagation();
		let isCancel = false;
		let message = '';
		if (organisation._children && organisation._children.length > 0) {
			isCancel = true;
			message = t('deleteNestedOrganisation');
			setModalProps({
				open: true,
				openDeleteModal: false,
				message: message,
				organisation,
				isCancel,
			});
		} else if (organisation.parentId !== 0) {
			setModalProps({
				openDeleteModal: true,
				open: false,
				organisation,
			});
			// message = 'If you will delete this Organization, All the users will be moved to parent organization';
		} else if (organisation.parentId === 0 && organisation.employeeCount > 0) {
			isCancel = true;
			message =
				'Go and change the organization of all the user aligned to this organization first otherwise all the user aligned to organization will be deleted on the deletion of this organization.';
		} else {
			// this.setState({
			// 	...this.state,
			// 	showDeleteDialog: true,
			// });
			message = `Are you sure you want to delete this organization? This action can not be undone!`;
		}
	};

	const handleDialogCancelClick = () => {
		setModalProps({
			openDeleteModal: false,
			open: false,
		});
	};

	const handleConfirmClick = async () => {
		let data = `teamId=${modalProps.organisation.teamId}`;
		let response = await props.deleteTeam(data);
		if (response.data.status === 200) {
			enqueueSnackbar(t('teamDeleteSuccess'), {
				variant: 'success',
				autoHideDuration: 5000,
			});
			fetchOrgList(payload);
		} else {
			if (response.data.messageList.teamId) {
				enqueueSnackbar(response.data.messageList.teamId, {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
		setModalProps({
			openDeleteModal: false,
			open: false,
		});
	};
	const handleOrgUpdate = () => {
		fetchOrgList(payload);
	};
	//console.log('test', manageOrgResult);
	return (
		<Box className='manage-org-area'>
			{/* Remove the above box when this component bind under org Tab */}
			<Box className='admin-tabpanel-inner'>
				{teamBox && teamBox.length ? <TeamColumn {...props} organizationBox={organizationBox} /> : <></>}
				<Box className='admin-tabpanel-head'>
					<Box className='admin-tabpanel-head-left'>
						<Typography variant='h2'>{t('manageOrgnizationsLevel')}</Typography>
					</Box>
					<Box className='admin-tabpanel-head-right'>
						{totalUser ? (
							<TablePagination
								component='div'
								className='user-list-pagination'
								id='org-list-pagination'
								count={totalUser}
								page={pageIndex > 1 ? pageIndex - 1 : 0}
								onPageChange={handleChangePage}
								rowsPerPage={pageSize}
								onRowsPerPageChange={handleChangeRowsPerPage}
								SelectProps={{
									MenuProps: {
										PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
									},
								}}
							/>
						) : (
							<></>
						)}
						<Box className='user-search'>
							<TextField
								className='search-text-field'
								id='user-src-field'
								placeholder={t('searchText')}
								fullWidth
								value={searchValue}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								onChange={(e) => handleSearchOrg(e)}
								onKeyDown={(e) => searchClicked(e)}
							/>

							{searchTextArray && searchTextArray.length > 0 && (
								<Box className='user-search-chip'>
									<List>
										{searchTextArray.map((user: any, idx: number) => {
											if (idx < 3)
												return (
													<ListItem key={`user${user}${idx}`} id={`user-chip-${user}${idx}`}>
														{user.length > 20 ? (
															<Tooltip title={user} arrow>
																<Chip
																	label={user.substring(0, 20) + '...'}
																	onDelete={(e) => clearSearch(user)}
																	deleteIcon={
																		<span>
																			<CrossIcon />
																		</span>
																	}
																/>
															</Tooltip>
														) : (
															<Chip
																label={user}
																onDelete={(e) => clearSearch(user)}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														)}
													</ListItem>
												);
										})}
										{searchTextArray && searchTextArray.length > 3 && (
											<>
												<ListItem
													className='search-more'
													id='src-more'
													aria-owns={openUserSearch ? 'mouse-over-popover' : undefined}
													aria-haspopup='true'
													onMouseOver={isOnHoverVisible && handleUserOpen}
													onMouseLeave={isOnHoverVisible && handleUserClose}
												>
													<Typography>{searchTextArray.length - 3}+</Typography>
													<UserSearchListPopover
														{...props}
														open={openUserSearch}
														setOpen={setOpenUserSearch}
														anchorEl={anchorElUserSearch}
														setAnchorEl={setAnchorElUserSearch}
														searchTextArray={searchTextArray}
														clearSearch={clearSearch}
													/>
												</ListItem>
												<ListItem className='clear-all'>
													<OKRButton
														className='btn-link'
														id='clear-all'
														handleClick={() => clearSearch('all')}
														text={'Clear All'}
													></OKRButton>
												</ListItem>
											</>
										)}
									</List>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
				{organizationListDetail && organizationListDetail.length ? (
					<>
						<Box className='okr-list-panel-head'>
							<List component='ul'>
								<ListItem className='org-col1'>
									<Typography
										variant='body2'
										className={sortingColumn === 'teamName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										id={'sort-team-name'}
										onClick={(e) => sortUserList(e, 'teamName')}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('teamNameLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='org-col2'>
									<Typography
										variant='body2'
										className={
											sortingColumn === 'teamHeadResponse.firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'
										}
										onClick={(e) => sortUserList(e, 'teamHeadResponse.firstName')}
										id={'team-leader'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('teamLeaderLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='org-col3'>
									<Typography
										variant='body2'
										className={
											sortingColumn === 'parentTeamResponse.teamName' ? `sort-title ${sortOrder}` : 'sort-title asc'
										}
										onClick={(e) => sortUserList(e, 'parentTeamResponse.teamName')}
										id={'parent-team-name'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('parentTeamNameLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='org-col4'>
									<Typography
										variant='body2'
										className={sortingColumn === 'employeeCount' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										onClick={(e) => sortUserList(e, 'employeeCount')}
										id={'no-of-member'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('noOfMembersLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='org-col5'>
									<Typography
										variant='body2'
										className={sortingColumn === 'createdOn' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										onClick={(e) => sortUserList(e, 'createdOn')}
										id={'created-on'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('createOnLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
								<ListItem className='org-col6'>
									<Typography
										variant='body2'
										className={sortingColumn === 'updatedOn' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										onClick={(e) => sortUserList(e, 'updatedOn')}
										id={'last-modified'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>{t('lastModifiedLabel')}</span> <SortingIcon />
										</span>
									</Typography>
								</ListItem>
							</List>
						</Box>
						<Box className='org-listing-area'>
							{organizationListDetail.length > 0 &&
								organizationListDetail.map((item: any, index: any) => (
									<Box className='org-listing-content' key={item?.teamId} id={`org-list-item-${index}`}>
										<List component='ul'>
											<ListItem className='org-col1'>
												<Box className='org-team-col'>
													<Box className='org-team-item'>
														<Chip
															style={{
																backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																color: getRandomBackgroundColor(item?.colorcode),
															}}
															className='org-team-chip'
															id={`org-team-chip-${index}`}
															avatar={
																item?.logoImagePath ? (
																	<Avatar className='avatar-default' alt={item?.teamName} src={item?.logoImagePath} />
																) : (
																	<Avatar
																		style={{
																			backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																			color: getRandomBackgroundColor(item?.colorcode),
																		}}
																		src={item?.logoImagePath}
																	>
																		{getUserName({
																			firstName: '',
																			lastName: '',
																			fullName: item?.teamName,
																		})}
																	</Avatar>
																)
															}
															label={
																item?.teamName?.length > 15 ? (
																	<Tooltip title={item?.teamName} arrow>
																		<span>{item?.teamName.substring(0, 15)}...</span>
																	</Tooltip>
																) : (
																	item?.teamName
																)
															}
														/>
													</Box>
													<Box className='org-actions'>
														<OKRButton
															className='org-edit-btn'
															icon={<EditIcon />}
															title={t('editTeamLabel')}
															id={`edit-org-btn-${item?.teamId}`}
															handleClick={() => {
																setDrawerProps({
																	createType: 'editChild', //item.parentId === 0 ? 'editOrg' : 'editChild',
																	header: 'Edit Child',
																	currentItem: item,
																});
															}}
														/>
														{item.parentId !== 0 && (
															<OKRButton
																className='org-delete-btn'
																icon={<DeleteIcon />}
																title={t('deletTeamLabel')}
																id={`delete-org-btn-${item?.teamId}`}
																handleClick={(e) => handleDeleteOrganization(e, item)}
															/>
														)}
													</Box>
												</Box>
											</ListItem>
											<ListItem className='org-col2'>
												{item?.teamHeadResponse && item?.teamHeadResponse.firstName ? (
													<Box className='org-user-info'>
														<Box className='org-user-img' id={`org-user-img-${index}`}>
															{item &&
															item?.teamHeadResponse?.imagePath &&
															item?.teamHeadResponse?.imagePath.trim() !== '' ? (
																<Avatar src={item?.teamHeadResponse?.imagePath} />
															) : item && item?.teamHeadResponse?.firstName ? (
																<Avatar>
																	{getUserName({
																		firstName: item?.teamHeadResponse?.firstName,
																		lastName: item?.teamHeadResponse?.lastName,
																		fullName: '',
																	})}
																</Avatar>
															) : (
																<Avatar></Avatar>
															)}
														</Box>
														<Box className='org-user-info-details' id={`org-user-info-${index}`}>
															{(item?.teamHeadResponse?.firstName + item?.teamHeadResponse?.lastName).length > 20 ? (
																<Tooltip
																	title={item?.teamHeadResponse?.firstName + ' ' + item?.teamHeadResponse?.lastName}
																	arrow
																>
																	<Typography>
																		{(
																			item?.teamHeadResponse?.firstName +
																			' ' +
																			item?.teamHeadResponse?.lastName
																		).substring(0, 20)}
																		...
																	</Typography>
																</Tooltip>
															) : (
																<Typography>
																	{item?.teamHeadResponse?.firstName} {item?.teamHeadResponse?.lastName}
																</Typography>
															)}
															{item?.teamHeadResponse?.designation?.length > 25 ? (
																<Tooltip title={item?.teamHeadResponse?.designation} arrow>
																	<Typography variant='subtitle2'>
																		{item?.teamHeadResponse?.designation.substring(0, 25)}...
																	</Typography>
																</Tooltip>
															) : (
																<Typography variant='subtitle2'>{item?.teamHeadResponse?.designation}</Typography>
															)}
															{item?.teamHeadResponse?.emailId?.length > 30 ? (
																<Tooltip title={item?.teamHeadResponse?.emailId} arrow>
																	<Typography className='subtitle3'>
																		{item?.teamHeadResponse?.emailId.substring(0, 30)}...
																	</Typography>
																</Tooltip>
															) : (
																<Typography className='subtitle3'>{item?.teamHeadResponse?.emailId}</Typography>
															)}
														</Box>
													</Box>
												) : (
													<></>
												)}
											</ListItem>
											<ListItem className='org-col3'>
												{item?.parentTeamResponse && item?.parentTeamResponse.teamName ? (
													<Box className='org-team-col'>
														<Box className='org-team-item'>
															<Chip
																style={{
																	backgroundColor: getRandomBackgroundColor(
																		item?.parentTeamResponse?.backGroundColorCode
																	),
																	color: getRandomBackgroundColor(item?.parentTeamResponse?.colorcode),
																}}
																className='org-team-chip'
																id={`parent-team-chip-${index}`}
																avatar={
																	item?.parentTeamResponse?.logoImagePath ? (
																		<Avatar
																			className='avatar-default'
																			alt={item?.parentTeamResponse?.teamName}
																			src={item?.parentTeamResponse?.logoImagePath}
																		/>
																	) : (
																		<Avatar
																			style={{
																				backgroundColor: getRandomBackgroundColor(
																					item?.parentTeamResponse?.backGroundColorCode
																				),
																				color: getRandomBackgroundColor(item?.parentTeamResponse?.colorcode),
																			}}
																			src={item?.parentTeamResponse?.logoImagePath}
																		>
																			{getUserName({
																				firstName: '',
																				lastName: '',
																				fullName: item?.parentTeamResponse?.teamName,
																			})}
																		</Avatar>
																	)
																}
																label={
																	item?.parentTeamResponse?.teamName?.length > 15 ? (
																		<>
																			<Tooltip title={item?.parentTeamResponse?.teamName} arrow>
																				<span>{item?.parentTeamResponse?.teamName.substring(0, 15)}...</span>
																			</Tooltip>
																		</>
																	) : (
																		item?.parentTeamResponse?.teamName
																	)
																}
															/>
														</Box>
													</Box>
												) : (
													<></>
												)}
											</ListItem>
											<ListItem className='org-col4'>
												<Typography variant='h4' id={`employee-count-${index}`}>
													{item?.employeeCount === 0 ? '-' : item?.employeeCount}
												</Typography>
											</ListItem>
											<ListItem className='org-col5'>
												<Box className='created-on'>
													<Typography variant='h4' id={`created-on-${index}`}>
														{getMonthDateYear(item?.createdOn)}
													</Typography>
													{item?.createdByName?.trim()?.length > 0 ? (
														<Typography variant='subtitle2'>
															{item?.createdByName?.length > 20 ? (
																<Tooltip title={item?.createdByName} arrow>
																	<span>{`By: ${item?.createdByName.substring(0, 20)}...`}</span>
																</Tooltip>
															) : (
																<>{`By: ${item?.createdByName} `}</>
															)}
														</Typography>
													) : (
														<></>
													)}
												</Box>
											</ListItem>
											<ListItem className='org-col6'>
												<Box className='last-modified'>
													<Typography variant='h4' id={`updated-on-${index}`}>
														{getMonthDateYear(item?.updatedOn)}
													</Typography>
													{item?.updatedByName?.trim()?.length > 0 ? (
														<Typography variant='subtitle2'>
															{item?.updatedByName?.length > 20 ? (
																<Tooltip title={item?.updatedByName} arrow>
																	<span>{`By: ${item?.updatedByName.substring(0, 20)}...`}</span>
																</Tooltip>
															) : (
																<>{`By: ${item?.updatedByName} `}</>
															)}
														</Typography>
													) : (
														<></>
													)}
													{/* <Typography variant='subtitle2' id={`updated-by-${index}`}>
														{item?.updatedByName?.trim()?.length > 0 ? `By: ${item?.updatedByName}` : ''}
													</Typography> */}
												</Box>
											</ListItem>
										</List>
									</Box>
								))}
						</Box>
					</>
				) : manageOrgPending ? (
					<>
						<Box className='org-loading-txt'>
							<Typography>{t('loading')}</Typography>
						</Box>
					</>
				) : (
					<Box className='search-no-record-global'>
						<Box className='no-record-message'>
							<Typography variant='h2' component='h2'>
								{t('findMatch')}
							</Typography>
							<Typography variant='h4'>
								{t('donotBeSad')}&#x1F641;,{t('tryAgain')}
							</Typography>
							<AnimatedIcon className='' width='350' type='image/svg+xml' alt={'nofound'} imagePath={noRecordFound} />
						</Box>
					</Box>
				)}
			</Box>
			{drawerProps.createType === 'editOrg' && (
				<EditOrganization
					{...props}
					handleClose={closeDrawer}
					openEditOrgDrawer={closeDrawer}
					currentOrg={drawerProps.currentItem}
					handleOrgUpdate={handleOrgUpdate}
				/>
			)}
			{drawerProps.createType === 'editChild' && (
				<AddTeam
					{...props}
					isEditForm={true}
					refreshList={true}
					teamDetails={drawerProps.currentItem}
					handleMenuClick={closeDrawer}
					fetchOrgList={fetchOrgList}
					payload={payload}
					openAddTeamDrawer={drawerProps.createType === 'editChild'}
				/>
			)}
			{modalProps.openDeleteModal && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.openDeleteModal}
					handleCloseModal={handleDialogCancelClick}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={handleConfirmClick}
					headingText={t('deleteOrgHeading')}
					subHeading={t('deleteOrgSubHeading')}
				/>
			)}
			{openAddTeamDrawer && (
				<AddTeam
					{...props}
					fetchOrganization={props.fetchOrganization}
					handleMenuClick={handleMenuClick}
					openAddTeamDrawer={openAddTeamDrawer}
					refreshList={tabSelected === 2}
					fetchOrgList={fetchOrgList}
					payload={payload}
				/>
			)}
		</Box>
	);
};
