import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, ClickAwayListener, List, ListItem, Popper, Tooltip, Typography } from '@material-ui/core';

import { OKRButton } from '../../../Common/OKRButton';
import { ReportsTooltipIcon } from '../../../../config/svg/CommonSvgIcon';

export const SelectedParameterTooltip: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const { okrRecommendationRequest } = props;

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	return (
		<>
			<Box
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup='true'
				onClick={handleOpen}
				//onMouseLeave={handleClose}
				component={'span'}
				className='filter-info-box'
			>
				<OKRButton id={'btnId'} icon={<ReportsTooltipIcon />} className={'info-icon'} />
				<Popper
					className={'role-description-popup report-header-tooltip selected-tooltip-wrap'}
					open={open}
					anchorEl={anchorEl}
					transition
					placement={'bottom-start'}
				>
					<ClickAwayListener onClickAway={handleClose}>
						<Box className='textarea-expand generate-okr-filter-selected'>
							<List component='div' disablePadding>
								<ListItem component='div'>
									<Typography variant='h5'>{t('strategicObjLabel')}: </Typography>
									<Typography variant='body1'>
										{okrRecommendationRequest.so ? okrRecommendationRequest.so : '--'}
									</Typography>
								</ListItem>
								<ListItem component='div'>
									<Typography variant='h5'>{t('designationLabel')}: </Typography>
									<Typography variant='body1'>
										{okrRecommendationRequest.designation ? okrRecommendationRequest.designation : '--'}
									</Typography>
								</ListItem>
								<ListItem component='div'>
									<Typography variant='h5'>{t('departmentLabel')}: </Typography>
									<Typography variant='body1'>
										{okrRecommendationRequest.department ? okrRecommendationRequest.department : '--'}
									</Typography>
								</ListItem>
								<ListItem component='div'>
									<Typography variant='h5'>{t('industryLabel')}: </Typography>
									<Typography variant='body1'>
										{okrRecommendationRequest.industry ? okrRecommendationRequest.industry : '--'}
									</Typography>
								</ListItem>
							</List>
						</Box>
					</ClickAwayListener>
				</Popper>
			</Box>
		</>
	);
};
