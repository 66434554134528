import React, { useState, useEffect } from 'react';
import {
	Box,
	ClickAwayListener,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { useTranslation } from 'react-i18next';
import { DownFilledArrow } from '../../config/svg/ArrowSvg';
import { AlertInfoIcon, CycleIcon, PastCycleIcon } from '../../config/svg/CommonSvg';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import { Enums } from '../../config/enums';
import { BackArrow } from '../../config/svg/GlobalSvg';

export const CycleDropdownData: React.FC<any> = (props) => {
	const { allCycleDetails, currentCycleId, isSoObj, handleChange, redirectTo, selectedCycle, callingFrom = '' } = props;
	const { t } = useTranslation();

	const [pastCycles, setPastCycles] = useState(false);

	const handlePastCycles = () => {
		setPastCycles(!pastCycles);
	};

	return (
		<>
			{pastCycles ? (
				<>
					<Box className='back-to-main'>
						<Typography variant='body2'>
							<Link id='back-btn' onClick={handlePastCycles}>
								<BackArrow /> {t('back')}
							</Link>
							{t('yourPastOKRsCycles')}
						</Typography>
					</Box>
					{allCycleDetails &&
						allCycleDetails.cycleDetails &&
						allCycleDetails.cycleDetails
							.map((keys: any, idx: Number) => {
								return (
									(keys.cycleStatus === Enums.PAST_CYCLE_ID || keys.cycleStatus === Enums.CURRENT_CYCLE_ID) && (
										<ExpansionPanel key={`navigation-${idx}`} onChange={handleChange(keys)} id={`navigation-${idx}`}>
											<ExpansionPanelSummary
												expandIcon={<RightArrowIcon />}
												aria-controls='panel1a-content'
												id='panel1a-header'
											>
												<Typography>
													Year {keys.year}
													{keys.isOkrAvailable && <CycleIcon />}
													{callingFrom === 'DefaultOkr' ? (
														<>{keys?.isDefaultOkrAvailable ? <CycleIcon /> : <></>}</>
													) : (
														<></>
													)}
													{callingFrom === 'DefaultOkr' ? (
														<>{keys?.isUnpublished ? <AlertInfoIcon /> : <></>}</>
													) : (
														<></>
													)}
												</Typography>
											</ExpansionPanelSummary>
											<ExpansionPanelDetails>
												<List component='nav' aria-label={t('HeaderTenureNavigation')}>
													{keys.quarterDetails &&
														keys.quarterDetails
															.map((quarter: any, qid: any) => {
																return (
																	quarter.cycleStatus === Enums.PAST_CYCLE_ID && (
																		<ListItem
																			className={
																				selectedCycle &&
																				selectedCycle.organisationCycleId &&
																				selectedCycle.organisationCycleId === quarter.organisationCycleId
																					? 'active'
																					: ''
																			}
																			button
																			key={`tenure${quarter.symbol}${keys.year}${qid}`}
																			id={`tenure-${quarter.symbol}${keys.year}${qid}`}
																		>
																			<ListItemText
																				// className={`${quarter.isCurrentQuarter && 'active'}`}
																				primary={`${quarter.symbol}, ${keys.year}`}
																				secondary={
																					<>
																						{quarter.isOkrAvailable ? <CycleIcon /> : <></>}{' '}
																						{callingFrom === 'DefaultOkr' ? (
																							<>{quarter?.isDefaultOkrAvailable ? <CycleIcon /> : <></>}</>
																						) : (
																							<></>
																						)}
																						{callingFrom === 'DefaultOkr' &&
																						selectedCycle?.organisationCycleId !== quarter.organisationCycleId ? (
																							<>{quarter?.isUnpublished ? <AlertInfoIcon /> : <></>}</>
																						) : (
																							<></>
																						)}
																					</>
																				}
																				onClick={() => redirectTo(`${qid + 1}${keys.year}`, quarter, keys.year)}
																			/>
																			{quarter.isCurrentQuarter && (
																				<Typography variant='subtitle2' className='current-cycle-tag'>
																					{t('currentCycle')}
																				</Typography>
																			)}
																		</ListItem>
																	)
																	// )
																);
															})
															.reverse()}
												</List>
											</ExpansionPanelDetails>
										</ExpansionPanel>
									)
								);
							})
							.reverse()}
				</>
			) : null}
			{!pastCycles ? (
				<>
					{allCycleDetails &&
						allCycleDetails.cycleDetails &&
						allCycleDetails.cycleDetails.map((keys: any, idx: Number) => {
							return (
								(keys.cycleStatus === Enums.CURRENT_CYCLE_ID ||
									keys.cycleStatus === Enums.FUTURE_CYCLE_ID ||
									(isSoObj && keys.cycleStatus === Enums.PAST_CYCLE_ID)) && (
									<ExpansionPanel key={`navigation-${idx}`} onChange={handleChange(keys)} id={`navigation-${idx}`}>
										<ExpansionPanelSummary
											expandIcon={<RightArrowIcon />}
											aria-controls='panel1a-content'
											id='panel1a-header'
										>
											<Typography>
												Year {keys.year}
												{keys.isOkrAvailable && <CycleIcon />}
												{callingFrom === 'DefaultOkr' ? (
													<>{keys?.isDefaultOkrAvailable ? <CycleIcon /> : <></>}</>
												) : (
													<></>
												)}
												{callingFrom === 'DefaultOkr' ? <>{keys?.isUnpublished ? <AlertInfoIcon /> : <></>}</> : <></>}
											</Typography>
										</ExpansionPanelSummary>
										<ExpansionPanelDetails>
											<List component='nav' aria-label={t('HeaderTenureNavigation')}>
												{keys.quarterDetails &&
													keys.quarterDetails
														.map((quarter: any, qid: any) => {
															return (
																(quarter.cycleStatus === 2 ||
																	quarter.cycleStatus === 3 ||
																	(isSoObj && quarter.cycleStatus === 1)) && (
																	<ListItem
																		className={
																			selectedCycle &&
																			selectedCycle.organisationCycleId &&
																			selectedCycle.organisationCycleId === quarter.organisationCycleId
																				? 'active'
																				: ''
																		}
																		button
																		key={`tenure${quarter.symbol}${keys.year}${qid}`}
																		id={`tenure-${quarter.symbol}${keys.year}${qid}`}
																	>
																		<ListItemText
																			// className={`${quarter.isCurrentQuarter && 'active'}`}
																			primary={`${quarter.symbol}, ${keys.year}`}
																			secondary={
																				<>
																					{quarter.isOkrAvailable ? <CycleIcon /> : <></>}{' '}
																					{callingFrom === 'DefaultOkr' ? (
																						<>{quarter?.isDefaultOkrAvailable ? <CycleIcon /> : <></>}</>
																					) : (
																						<></>
																					)}
																					{callingFrom === 'DefaultOkr' &&
																					selectedCycle?.organisationCycleId !== quarter.organisationCycleId ? (
																						<>{quarter?.isUnpublished ? <AlertInfoIcon /> : <></>}</>
																					) : (
																						<></>
																					)}
																				</>
																			}
																			onClick={() => redirectTo(`${qid + 1}${keys.year}`, quarter, keys.year)}
																		/>
																		{quarter.isCurrentQuarter && (
																			<Typography variant='subtitle2' className='current-cycle-tag'>
																				{t('currentCycle')}
																			</Typography>
																		)}
																	</ListItem>
																)
																// )
															);
														})
														.reverse()}
											</List>
										</ExpansionPanelDetails>
									</ExpansionPanel>
								)
							);
						})}
					{callingFrom === 'DefaultOkr' ? (
						<Box className='past-cycle-link' onClick={handlePastCycles}>
							<PastCycleIcon />
							{t('pastCycles')}
						</Box>
					) : null}
				</>
			) : null}
		</>
	);
};
