import React from 'react';
import './index.css';
// import { tree, tree1, tree2, tree3, tree4 } from './Tree';
import avatarPersonnel from './assets/avatar-personnel.svg';
import { init } from './orgChart/chart';
import OrgChart from './orgChart/react/org-chart';
import { Box, Typography } from '@material-ui/core';
import { getLevelOneData } from '../../../config/utils';

export default class OrganizationChart extends React.Component {
	constructor(props) {
		super(props);

		this.props = props;
		this.state = {
			tree: getLevelOneData(props.organizationList),
			downloadingChart: false,
			config: {},
			highlightPostNumbers: [1],
			completeChartData: props.organizationList,
		};
		// OrgChart.init = init;
	}

	getChild = (d) => {
		return getLevelOneData(this.state.completeChartData, d);
	};

	getParent = (d) => {
		return d;
	};

	handleDownload = () => {
		this.setState({ downloadingChart: false });
	};

	handleOnChangeConfig = (config) => {
		this.setState({ config: config });
	};

	handleLoadConfig = () => {
		const { config } = this.state;
		return config;
	};
	resetOrgChart = () => {
		// this.setState = {
		// 	tree: getLevelOneData(this.props.organizationList),
		// };
	};

	render() {
		OrgChart.init = init;
		const { tree } = this.state; // , downloadingChart
		const { handleViewOrganization, isOrgChart } = this.props;

		//For downloading org chart as image or pdf based on id
		const downloadImageId = 'download-image';
		const downloadPdfId = 'download-pdf';

		return (
			<Box height={'100vh'} className={`org-chart-wrapper ${isOrgChart ? 'org-chart-dashboard' : ''}`}>
				<Box className='org-chart-head'>
					<Typography variant='h2'>
						{isOrgChart ? this.props.t('OrganizationStructure') : this.props.t('orgStructure')}
					</Typography>
				</Box>

				<OrgChart
					id={'orgChart-root'}
					tree={tree}
					downloadImageId={downloadImageId}
					downloadPdfId={downloadPdfId}
					isOrgChart={isOrgChart}
					handleViewOrganization={handleViewOrganization}
					resetOrgChart={() => {
						this.resetOrgChart();
					}}
					onConfigChange={(config) => {
						this.handleOnChangeConfig(config);
					}}
					loadConfig={(d) => {
						let configuration = this.handleLoadConfig(d);
						if (configuration) {
							return configuration;
						}
					}}
					downlowdedOrgChart={(d) => {
						this.handleDownload();
					}}
					loadImage={(d) => {
						return Promise.resolve(avatarPersonnel);
					}}
					loadParent={(d) => {
						const parentData = this.getParent(d);
						return parentData;
					}}
					loadChildren={(d) => {
						const childrenData = this.getChild(d);
						return childrenData;
					}}
				/>
			</Box>
		);
	}
}
