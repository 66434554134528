import React, { Fragment, useState, useEffect } from 'react';
import { Box, ClickAwayListener, FormLabel, List, ListItem, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { BottomArrowIcon, RatingIcon, TemplateIcon, TickCircleIcon } from '../../../../config/svg/CommonSvgIcon';
// import { InformationMessage } from '../../CommonComponent/InformationMessage';
import { AssessmentQuesList } from './AssessmentQuesList';
import { OKRButton } from '../../../Common/OKRButton';
import { useSnackbar } from 'notistack';

export const AssessmentAddForm: React.FC<any> = (props: any) => {
	const {
		assessmentFormData,
		handleAssessmentFormChange,
		assessmentFormNameError,
		handleSelectQuestionType,
		evaluationQuestion,
		setEvaluationQuestion,
		feedbackQuestion,
		setFeedbackQuestion,
		assessmentEvaluationQuesError,
		setAssessmentEvaluationQuesError,
	} = props;

	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const [open, setOpen] = useState<Boolean>(false);

	useEffect(() => {
		if (
			assessmentEvaluationQuesError &&
			assessmentEvaluationQuesError?.error &&
			assessmentEvaluationQuesError?.type === 'evaluationQuestions'
		) {
			enqueueSnackbar(assessmentEvaluationQuesError?.error || '', {
				variant: 'error',
				autoHideDuration: 5000,
			});
			setTimeout(() => {
				setAssessmentEvaluationQuesError({
					error: '',
					helperText: '',
					type: '',
				});
			}, 3000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [assessmentEvaluationQuesError]);

	const handleClick = (type: number) => {
		if (type !== 0) {
			handleSelectQuestionType(type);
		}
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};

	const handleAddNewQuestion = (questionType: string) => {
		if (questionType === 'Evaluation') {
			const newListIndexData = evaluationQuestion.slice(-1);
			const newListIndex = newListIndexData && newListIndexData.length ? newListIndexData[0].listIndex : 1;
			setEvaluationQuestion([
				...evaluationQuestion,
				{ listIndex: newListIndex + 1, questionsName: '', questionsType: 1 },
			]);
		} else if (questionType === 'Feedback') {
			const newListIndexData = feedbackQuestion.slice(-1);
			const newListIndex = newListIndexData && newListIndexData.length ? newListIndexData[0].listIndex : 1;
			setFeedbackQuestion([...feedbackQuestion, { listIndex: newListIndex + 1, questionsName: '', questionsType: 2 }]);
		}
	};
	const handleQuestionNameChange = (e: any, item: any, questionType: string, index: number) => {
		const { value } = e?.target;
		if (questionType === 'Evaluation') {
			const updatedEvaluationQuestion = evaluationQuestion.map((rec: any, ind: number) => {
				if (ind === index) {
					// rec?.listIndex === item?.listIndex &&
					rec.questionsName = value;
				}
				return rec;
			});
			setEvaluationQuestion([...updatedEvaluationQuestion]);
		} else if (questionType === 'Feedback') {
			const updatedFeedbackQuestion = feedbackQuestion.map((rec: any, ind: number) => {
				if (ind === index) {
					// rec?.listIndex === item?.listIndex &&
					rec.questionsName = value;
				}
				return rec;
			});
			setFeedbackQuestion([...updatedFeedbackQuestion]);
		}
	};
	const handleQuestionDelete = (e: any, item: any, questionType: string, index: number) => {
		if (questionType === 'Evaluation') {
			const updatedEvaluationQuestion = [...evaluationQuestion];
			updatedEvaluationQuestion.splice(index, 1);
			setEvaluationQuestion([...updatedEvaluationQuestion]);
		} else if (questionType === 'Feedback') {
			const updatedFeedbackQuestion = [...feedbackQuestion];
			updatedFeedbackQuestion.splice(index, 1);
			setFeedbackQuestion([...updatedFeedbackQuestion]);
		}
	};

	return (
		<>
			{/* <InformationMessage messageText={t('addFormInfo')} icon={true} /> */}
			<Box className='assessment-add-form'>
				<Box className='drawer-input-field'>
					<TextField
						fullWidth
						className='helperText-bottom'
						label={
							<Fragment>
								<TemplateIcon />
								{t('formName')}
							</Fragment>
						}
						placeholder={t('formNamePlaceHolder')}
						name='formName'
						value={assessmentFormData?.formName || ''}
						onChange={handleAssessmentFormChange}
						InputLabelProps={{
							shrink: true,
						}}
						InputProps={{ 'aria-label': t('formNamePlaceHolder'), disableUnderline: true }}
						error={(assessmentFormNameError?.type === 'formName' && assessmentFormNameError?.error) || ''}
						helperText={
							(assessmentFormNameError?.type === 'formName' && assessmentFormNameError?.helperText && (
								<>
									<ErrorIcon />
									{assessmentFormNameError?.helperText}
								</>
							)) ||
							''
						}
					/>
				</Box>
				<Box className='drawer-blue-bg-full'>
					<Box className='drawer-form-fields-group'>
						<Box className='drawer-input-field '>
							<Typography variant='h5'>{t('performEvalueQes')}</Typography>
							<Box className={`ques-type ${open ? 'active' : ''}`}>
								<FormLabel>
									<TickCircleIcon />
									{t('quesType')}
								</FormLabel>
								<Box className={`ques-type-selection ${open ? 'active' : ''}`}>
									<OKRButton
										className={'dropdown-button'}
										text={'Rating'}
										icon={<BottomArrowIcon />}
										handleClick={() => handleClick(0)}
									></OKRButton>
									{open && (
										<ClickAwayListener onClickAway={handleClickAway}>
											<Box className='ques-type-dropdown '>
												<List component='div' disablePadding>
													<ListItem component='div' onClick={() => handleClick(1)}>
														<Typography>Rating</Typography>
														<Typography variant='h6'>Rating answer</Typography>
														<Box className='star-rating'>
															<RatingIcon />
															<RatingIcon />
															<RatingIcon />
															<RatingIcon />
															<RatingIcon />
														</Box>
													</ListItem>
												</List>
											</Box>
										</ClickAwayListener>
									)}
								</Box>
							</Box>
							<AssessmentQuesList
								{...props}
								questionType={'Evaluation'}
								genericQuestionList={evaluationQuestion}
								handleAddNewQuestion={handleAddNewQuestion}
								handleQuestionNameChange={handleQuestionNameChange}
								handleQuestionDelete={handleQuestionDelete}
							/>
						</Box>
					</Box>
					<Box className='drawer-form-fields-group'>
						<Box className='drawer-input-field '>
							<Typography variant='h5'>{t('performFeedbackQes')}</Typography>
							<Typography className='short-des-text'>{t('quesCommentBox')}</Typography>
							<AssessmentQuesList
								{...props}
								questionType={'Feedback'}
								genericQuestionList={feedbackQuestion}
								handleAddNewQuestion={handleAddNewQuestion}
								handleQuestionNameChange={handleQuestionNameChange}
								handleQuestionDelete={handleQuestionDelete}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='drawer-input-field '>
					<Typography className='short-des-text'>{t('addFormNote')}</Typography>
				</Box>
			</Box>
		</>
	);
};
