/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, Typography, Popper, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReportsGaugeChart } from '../HighchartReports/ReportsGaugeChart';
import { Enums } from '../../../config/enums';

const HeatChartTooltip: React.FC<any> = (props: any) => {
	const { open, anchorEl, setOpen, setAnchorEl, cellData, getProgressClass } = props;
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const { t } = useTranslation();
	return (
		<Popper
			className={'heat-chart-popover'}
			open={open}
			anchorEl={anchorEl}
			transition
			placement={'bottom-start'}
			container={anchorEl}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Box className={`${getProgressClass(cellData.teamProgress)} heat-chart-tooltip`}>
					<Box className='tooltip-head'>
						<Typography>{cellData.teamName}</Typography>
						{/* <Box className='tooltip-head-right'>
							<OKRButton icon={<FavoriteIcon />} className='favorite-icon'></OKRButton>
							<OKRButton icon={<KababIcon />} className='kabab-icon'></OKRButton>
						</Box> */}
					</Box>
					<ReportsGaugeChart
						isOrgReport={true}
						progress={cellData?.teamProgress}
						memberCount={cellData?.memberCount}
						okrCount={cellData?.okrCount}
						krCount={cellData?.krCount}
						opacity={0.1}
						radialCount={Enums.ONE}
						colors={['#ffffff']}
						labels={['Performance']}
						chartHight={'140'}
						paneBackgroundColor={'rgba(244, 241, 248, 0.2)'}
					/>
				</Box>
			</ClickAwayListener>
		</Popper>
	);
};

export default HeatChartTooltip;
