import React, { useState, useEffect } from 'react';
import {
	Box,
	Typography,
	Popper,
	ClickAwayListener,
	Grow,
	TextField,
	InputAdornment,
	Slider,
	Paper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Enums } from '../../config/enums';
import { OKRButton } from '../Common/OKRButton';
import { CancelIcon } from '../../config/svg/Action';
import { TickIcon } from '../../config/svg/CommonSvgIcon';
import { NumberFormatComponent } from '../Common/NumberFormat';

export const SOProgress: React.FC<any> = (props: any) => {
	const {
		openProgressDetail,
		anchorEl,
		isAPIPending,
		handleSaveProgress,
		handleCloseProgressBar,
		handleSliderChange,
		handleFieldChange,
		calculateScorePercent,
	} = props;
	const { t } = useTranslation();

	return (
		<>
			{openProgressDetail && (
				<Popper
					className='popper-progress-bar popper-progress-bar-bottom popper-so-progress'
					open={openProgressDetail ? true : false}
					anchorEl={anchorEl}
					placement='bottom-end'
					modifiers={{
						preventOverflow: {
							enabled: false,
							boundariesElement: window,
						},
						flip: {
							enabled: false, // true by default
						},
					}}
					id={`so-progress-popper}`}
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<ClickAwayListener onClickAway={(event) => handleCloseProgressBar(event, 'cancel')}>
								<Box>
									<Paper>
										<Box className='popup-progress-top'>
											<Box className='popup-progress-area'>
												<Typography variant='h5' className='popper-progress-title'>
													{t('updateProgressLabel')}
												</Typography>
												<Box className='popup-progress-box'>
													<Typography variant='h6'>
														{calculateScorePercent(openProgressDetail?.updatedScore)}%
													</Typography>
													<Box className='popup-progress-track'>
														<Box className='popup-progress-value'>
															<Typography variant='subtitle2'>%{'0'}</Typography>
															<Typography variant='subtitle2'>%{'100'}</Typography>
														</Box>
														<Box className='popup-progress-rail'>
															<Slider
																aria-label={t('openProgressDetail')}
																style={{ color: openProgressDetail?.progressCode || '' }}
																value={openProgressDetail?.updatedScore || 0}
																min={0}
																max={100}
																onChange={(e, val) => handleSliderChange(e, val)}
																className={'show-blue-slider'}
															/>
														</Box>
													</Box>
												</Box>
												<Box className='progress-input-area'>
													<Box className='progress-link-icon'></Box>
													<Box className='progress-input-box'>
														<TextField
															placeholder={'100.00'}
															value={openProgressDetail?.updatedScore || 0}
															inputProps={{
																maxLength: Enums.MAX_LENGTH_CURRENT_TARGET,
																inputType: 'numberFieldProgress',
															}}
															onChange={(e: any) => handleFieldChange(e.target.value)}
															name='percentage'
															InputProps={{
																inputComponent: NumberFormatComponent,
																startAdornment: <InputAdornment position='start'>%</InputAdornment>,
															}}
														/>
													</Box>
												</Box>
											</Box>

											{isAPIPending ? (
												<Box className='progress-save-loader'>
													<Box className='spinner'>
														<Box className='bounce1'></Box>
														<Box className='bounce2'></Box>
														<Box className='bounce3'></Box>
													</Box>
												</Box>
											) : (
												<></>
											)}
										</Box>
										<Box className='progress-popup-actions'>
											<OKRButton
												className='progress-cancel'
												id={'so-progress-cancel'}
												icon={<CancelIcon />}
												handleClick={(event) => handleCloseProgressBar(event, 'cancel')}
												disabled={isAPIPending}
											/>
											<OKRButton
												className={`progress-save }`}
												id={'so-progress-save'}
												icon={<TickIcon />}
												disabled={isAPIPending}
												handleClick={(e: any) => handleSaveProgress(e, openProgressDetail)}
											/>
										</Box>
									</Paper>
								</Box>
							</ClickAwayListener>
						</Grow>
					)}
				</Popper>
			)}
		</>
	);
};
