import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MultiSelect } from '../../../../CommonComponent/MultiSelect';
import { ListRole } from './ListRole';
import { OKRButton } from '../../../../../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../../../../../config/svg/Action';
import { Enums } from '../../../../../../config/enums';

export const JobLevelRole: React.FC<any> = (props: any) => {
	const {
		defaultOkPerformanceRoleDetails,
		setDefaultOkrFormEdited,
		selectedPerformanceRoleOptions,
		setSelectedPerformanceRoleOptions,
		// handleUpdateAssignIds,
		defaultOkrFormDetails,
		allEmployeeChecked,
		handleSelectedRoleDeletion,
	} = props;
	const { t } = useTranslation();
	const [selectedUser, setSelectedUser] = useState([]);

	const updateValue = () => {
		const defaultOkrDesignationData =
			defaultOkPerformanceRoleDetails && defaultOkPerformanceRoleDetails?.length
				? [...defaultOkPerformanceRoleDetails]
				: [];
		const uniqueItem: any = [];
		defaultOkrDesignationData &&
			defaultOkrDesignationData?.length &&
			defaultOkrDesignationData.forEach((item: any) => {
				const isExist = selectedPerformanceRoleOptions?.find(
					(rec: any) => rec?.value === item?.value || rec?.value === item?.performanceRoleId
				);
				if (!isExist) {
					uniqueItem.push({ ...item });
				}
			});
		const updateDetails = [...uniqueItem].map((item: any) => {
			return {
				label: item?.performanceRoleName || '',
				value: item?.performanceRoleId || 0,
				userCount: item?.userCount || 0,
				id: item?.performanceRoleId || 0,
			};
		});

		return updateDetails;
	};
	const onSelectEmployee = (selectedUserValue: any, actionMeta: any) => {
		setSelectedUser(selectedUserValue);
		setDefaultOkrFormEdited(true);
	};
	const customFilter = (option: any, searchText: any) => {
		if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
			return true;
		}
	};
	const handlePerformanceRoleSave = () => {
		if (selectedPerformanceRoleOptions && selectedPerformanceRoleOptions?.length) {
			const uniqueItem: any = [...selectedPerformanceRoleOptions];
			selectedUser &&
				selectedUser?.length &&
				selectedUser.forEach((item: any) => {
					const isExist = selectedPerformanceRoleOptions?.find((rec: any) => rec?.value === item?.value);
					if (!isExist) {
						uniqueItem.push({ ...item });
					}
				});

			setSelectedPerformanceRoleOptions(uniqueItem);
		} else {
			setSelectedPerformanceRoleOptions(selectedUser);
		}
		setSelectedUser([]);
	};
	const handlePerformanceRoleCancel = () => {
		setSelectedUser([]);
	};
	// console.log('JobLevelRole ===> ', selectedPerformanceRoleOptions, selectedUser);

	return (
		<>
			<Box className={`select-role-fields ${selectedUser && selectedUser?.length ? 'selected' : ''}`}>
				<FormControl variant='outlined'>
					<MultiSelect
						key={'employeeSelect'}
						{...props}
						isMulti={true}
						selectedOptions={selectedUser}
						optionsList={updateValue()}
						onSelectOption={onSelectEmployee}
						customFilter={customFilter}
						placeHolder={t('jobLevelPlaceholder')}
						noOptionsMessage={t('jobLevelNotFound')}
						showNoOptions={true}
						performSearch={() => {}}
						labelTemplate={'numberUserLabel'}
						id={'employee-select'}
						selectClassName={`select-search-dropdown select-search-icon`}
						selectClassNamePrefix={'react-select'}
						disableSearch={allEmployeeChecked}
						disabled={allEmployeeChecked}
					/>
					{selectedUser && selectedUser?.length ? (
						<Box className='textfield-action-btn'>
							<OKRButton
								className='cancel-btn'
								id={'cancel-btn'}
								icon={<CancelIcon />}
								handleClick={handlePerformanceRoleCancel}
							/>
							<OKRButton
								className='save-btn'
								id={'save-btn'}
								icon={<TickIcon />}
								handleClick={handlePerformanceRoleSave}
							/>
						</Box>
					) : (
						<></>
					)}
				</FormControl>
				{/* <Box className='action-btn'>
			<Typography variant='subtitle2' className='action-btn-count'>
				{!selectedUserList.length && employeeList.length > 0 && `(${employeeList.length} ${t('membersLabel')})`}
			</Typography>
			<OKRButton
				disabled={!employeeList.length}
				className='btn-secondary'
				id='action-add-btn'
				text={t('addBtn')}
				handleClick={handleAddUserNextClick}
			/>
		</Box> */}
			</Box>
			<Box className='drawer-blue-bg-full'>
				<ListRole
					titleHead={t('jobLevels')}
					listHeadCol2={t('numberUser')}
					selectedItemList={selectedPerformanceRoleOptions || []}
					defaultOkrFormDetails={defaultOkrFormDetails}
					assignType={Enums.TWO}
					allEmployeeChecked={allEmployeeChecked}
					handleSelectedRoleDeletion={handleSelectedRoleDeletion}
				/>
			</Box>
		</>
	);
};
