import React from 'react';
import { Typography, Box, Tooltip } from '@material-ui/core';
import { UserReportingToIcon } from '../../../../config/svg/CommonSvgIcon';
import { ProfileIcon, TeamIcon } from '../../../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';

export const ChatTile = (props) => {
	const { nodeWidth, nodeHeight, record, handleViewOrganization, isOrgChart } = props;
	const { t } = useTranslation();

	return (
		<foreignObject width={nodeWidth} height={nodeHeight}>
			<Box className={`org-chart-card ${isOrgChart && record?.isLoggedInUserTeam ? 'highlight' : ''}`}>
				{isOrgChart ? (
					<Tooltip
						arrow
						title={record?.teamName?.length > 25 ? record?.teamName : ''}
						classes={{ popper: 'custom-org-tooltip' }}
					>
						<Typography
							variant='h4'
							className='team-name team-name-link'
							onClick={(event) => {
								const element = document.querySelector('.custom-org-tooltip');
								if (element) {
									element.style.display = 'none';
								}

								handleViewOrganization(event, record);
								setTimeout(() => {
									if (element) {
										element.remove();
									}
								}, 2000);
							}}
						>
							{record?.teamName?.length > 25 ? `${record?.teamName.substring(0, 25)}...` : record?.teamName}
						</Typography>
					</Tooltip>
				) : (
					<Tooltip arrow title={record?.teamName?.length > 25 ? record?.teamName : ''}>
						<Typography variant='h4' className='team-name'>
							{record?.teamName?.length > 25 ? `${record?.teamName.substring(0, 25)}...` : record?.teamName}
						</Typography>
					</Tooltip>
				)}
				<Box className={`team-leader-name ${!record?.teamHeadName ? 'no-data' : ''}`}>
					<UserReportingToIcon />
					{record?.teamHeadName ? (
						<Tooltip arrow title={record?.teamHeadName?.length > 25 ? record?.teamHeadName : ''}>
							<Typography variant='h5'>
								{record?.teamHeadName?.length > 25
									? `${record?.teamHeadName.substring(0, 25)}...`
									: record?.teamHeadName}
							</Typography>
						</Tooltip>
					) : (
						<Typography variant='h5'>{t('noLeader')}</Typography>
					)}
				</Box>

				<Box className='team-info'>
					<Typography variant='h5' className={`team-count ${!record?.childTeamsCount ? 'no-data' : ''}`}>
						<TeamIcon />
						{record?.childTeamsCount ? `${record?.childTeamsCount} ${t('teamsLabel')}` : `${t('noTeams')}`}
					</Typography>
					<Typography variant='h5' className={`team-member-count ${!record?.employeeCount ? 'no-data' : ''}`}>
						<ProfileIcon />
						{record?.employeeCount ? `${record?.employeeCount} ${t('membersLabel')}` : `${t('noMembers')}`}
					</Typography>
				</Box>
			</Box>
		</foreignObject>
	);
};
