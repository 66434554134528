import * as React from 'react';
import { Box, Typography, FormControl, Tabs, Tab, List, ListItem, Avatar, Tooltip } from '@material-ui/core';
import { CrossIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { NoRecord } from '../../CommonComponent/NoRecord';
import '../../../../styles/pages/admin/users.scss';
import LinearLoader from '../../../Common/Loader';
import { getUserName } from '../../../../config/utils';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { InformationMessage } from '../../CommonComponent/InformationMessage';

const AddUserDrawer: React.FC<any> = (props) => {
	const {
		employeeList,
		userList,
		t,
		selectedUserList,
		handleAddUserDelete,
		handleSortData,
		onSelectEmployee,
		customFilter,
		handleAddUserNextClick,
		noRecordMessage,
		currentRole,
	} = props;
	const [loader, setLoader] = React.useState<boolean>(true);

	React.useEffect(() => {
		userList.length && setLoader(false);
	}, [userList]);

	return (
		<React.Fragment>
			<Tabs className='okr-tabs drawer-tabs' value={0} aria-label='Admin Tabs'>
				<Tab label={t('addMembersLabel')} value={0} />
			</Tabs>
			{loader && <LinearLoader data={userList.length > 0} />}
			<Box className='drawer-tabs-panel'>
				{!loader && currentRole && currentRole.roleId && (
					<InformationMessage messageText={t('adduserInfoText', { roleName: `${currentRole.roleName}` })} icon={true} />
				)}
				<Box className='users-select-fields'>
					<FormControl variant='outlined'>
						<MultiSelect
							key={'employeeSelect'}
							{...props}
							isMulti={true}
							selectedOptions={employeeList}
							optionsList={userList}
							onSelectOption={onSelectEmployee}
							customFilter={customFilter}
							placeHolder={t('selectFromList')}
							noOptionsMessage={t('userNotFound')}
							showNoOptions={true}
							performSearch={() => {}}
							id={'employee-select'}
						/>
					</FormControl>
					<Box className='action-btn'>
						<Typography variant='subtitle2' className='action-btn-count'>
							{!selectedUserList.length && employeeList.length > 0 && `(${employeeList.length} ${t('membersLabel')})`}
						</Typography>
						<OKRButton
							disabled={!employeeList.length}
							className='btn-secondary'
							id='action-add-btn'
							text={t('addBtn')}
							handleClick={handleAddUserNextClick}
						/>
					</Box>
				</Box>
				{selectedUserList && selectedUserList.length ? (
					<Box className='users-listing'>
						<Box className='userCount'>
							<Typography variant='subtitle2'>
								{selectedUserList.length > 0 && `(${selectedUserList.length} ${t('membersLabel')})`}
							</Typography>
						</Box>
						<Box className='okr-list-panel-head'>
							<List component='ul' disablePadding>
								<ListItem className='user-col1'>
									<Typography
										variant='body2'
										className={`sort-title ${props.currentSort === 'label' && props.sortOrder === 'asc' ? 'desc' : ''}`}
										onClick={() => handleSortData('label')}
										id={'name-sort'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>
												{t('nameLabel')} <SortingIcon />
											</span>
										</span>
									</Typography>
								</ListItem>
								<ListItem className='user-col2'>
									<Typography
										variant='body2'
										className={`sort-title ${props.currentSort === 'email' && props.sortOrder === 'asc' ? 'desc' : ''}`}
										onClick={(e) => handleSortData('emailId')}
										id={'emailid-sort'}
									>
										<span className='sortable-label'>
											<span className='txt-wrap'>
												{t('emailLabel')} <SortingIcon />
											</span>
										</span>
									</Typography>
								</ListItem>
							</List>
						</Box>
						<Box className='users-list-content'>
							{selectedUserList.length > 0 &&
								selectedUserList.map((item: any, index: number) => (
									<Box
										className='users-list-row'
										key={`selectedUser${item.employeeId}`}
										id={`selected-user-${item.employeeId}`}
									>
										<List component='ul' disablePadding>
											<ListItem className='user-col1'>
												<Box className='user-info'>
													<Box className='user-img'>
														<Avatar
															className='avatar-default'
															key={`user-icon-${item.employeeId}`}
															src={item.imagePath || ''}
														>
															{getUserName({
																firstName: item.firstName || '',
																lastName: item.lastName || '',
																fullName: item.label || '',
															})}
														</Avatar>
													</Box>
													<Box className='user-info-details'>
														<Typography variant='h4'>{item?.label}</Typography>
														<Typography variant='subtitle2'>
															{item.designation ? item.designation : t('notAvailable')}
														</Typography>
													</Box>
												</Box>
											</ListItem>
											<ListItem className='user-col2'>
												{item?.emailId && item?.emailId.length > 35 ? (
													<Tooltip arrow title={item?.emailId} aria-label='Email'>
														<Typography variant='h4'>{item?.emailId.substring(0, 35)}...</Typography>
													</Tooltip>
												) : (
													<Typography variant='h4'>{item?.emailId}</Typography>
												)}
												<OKRButton
													icon={<CrossIcon />}
													className={'btn-cross'}
													id={'btn-close'}
													handleClick={(e) => handleAddUserDelete(e, index)}
												/>
											</ListItem>
										</List>
									</Box>
								))}
						</Box>
					</Box>
				) : (
					<NoRecord noRecordMessage={noRecordMessage} />
				)}
			</Box>
		</React.Fragment>
	);
};

export default AddUserDrawer;
