import {
	getRequest,
	postRequest,
	deleteRequest,
	putRequest,
	postRequestFreeTrial,
	downloadXLSFile,
} from '../config/auth';
import {
	OKR_LISTING,
	ADD_GOALS,
	UPDATE_KR_PROGRESS,
	CONTRIBUTORS,
	UNLOCK_REQUEST,
	ALIGN_GOALS,
	OKR_STATUS,
	EMPLOYEE_VIEW,
	DASHBOARD_LIST,
	ARCHIVE_LIST,
	ALIGNMENT_LIST,
	ALIGNMENT_DETAIL_LIST,
	ALIGNMENT_PARENT_DETAIL,
	ALIGN_STATUS,
	DOWNLOAD_GOAL_PDF,
	QUARTER_REPORT,
	STATUS_REPORT,
	PROGRESS_REPORT,
	PERSONAL_FEEDBACK_REPORT,
	GET_OWNER_TEAMS,
	MY_GOAL_DETAIL,
	ALIGNMENT_GRAPH_DETAIL,
	UPDATE_OKR_SEQUENCE,
	OKR_MASTER_DATA,
	KR_CONTRIBUTORS,
	UPDATE_GOAL_KR_NAME,
	UPDATE_GOAL_ATTRIBUTES,
	UPDATE_KR_ATTRIBUTES,
	ACCEPT_KR,
	UPDATE_KR_PROGRESS_VALUE,
	BECOME_CONTRIBUTOR,
	GET_KR_DETAIL,
	GET_OKR_DETAIL,
	GET_USER_TEAMS,
	MY_TEAM_OKR_VIEW,
	NUDGE_TEAM,
	NUDGE_PERSON,
	MY_TEAM_OKR_DETAILS_VIEW,
	PEOPLE_MAP_LIST,
	UPDATE_DUE_DATE,
	RESET_OBJECTIVE,
	GET_LINK_OWNER_DATA,
	ALIGNMENT_DIRECT_REPORTS_LIST,
	GET_LINK_OBJECTIVE_DATA,
	OWNER_CHANGE_DATA,
	UPDATE_PROGRESS,
	DELTA_SCORE,
	RECENT_CONTRIBUTION_LIST,
	VIRTUAL_ALIGNMENT,
	DASHBOARD_PROGRESS,
	TEAMSOKR_PROGRESS,
	OBJECTIVE_DETAILS,
	OBJECTIVE_TASK,
	DELETE_TASK,
	UPDATE_TASK,
	TASK_COMPLETED,
	POST_PROGRESS_CONVERSATION,
	GET_NOTEBY_ID,
	GET_CONVERSATION_BY_ID,
	TEAM_VIEW_LIST,
	CHECKINSTATUS,
	CHECKINDATES,
	CHECKINDATA,
	DIRECT_REPORTEES,
	UPDATE_CONFIDENCE,
	GET_WEEKLY_TREND_DETAILS,
	GET_CONFIDENCE_ALERT,
	FREE_TRIAL,
	FREE_TRIAL_EMAILID,
	FREE_TRIAL_DOMAIN,
	FREE_TRIAL_PASSWORD,
	PEOPLEVIEW_PERSONAL_FEEDBACK,
	OKR_IMPORT,
	OKR_CATEGORY_IMPORT,
	MY_OKR_IMPORT,
	CHECKIN_VISIBILTY_UPDATE_DATA,
	POST_RECOGNITION,
	KR_LISTING,
	GET_TASK_LIST,
	POST_TASK_IMPORT,
	STATUS_REPORT_ENGAGEMENT,
	UNDER_ASSIGNED,
	GET_ASSESSMENT_TEAMLIST,
	GET_EMPLOYEE_ASSESSMENT,
	MULTIPLE_EMPLOYEE_ASSESSMENT,
	SAVE_RATING_FEEDBACK,
	GET_PERFORMANCE_CYCLE,
	GET_PERFORMANCE_ALERT,
	GET_FINAL_RATING,
	GET_EMPLOYEES_FINAL_RATING,
	SAVE_EMPLOYEES_FINAL_RATING,
	SAVE_MULTIPLE_EMPLOYEES_MANAGER_ASSESSMENT,
	SAVE_SELF_ASSESSMENT,
	GET_SNAPSHOT_DATA,
	IS_USER_EXLUDED,
	DASHBOARD_CHECKIN_DETAILS,
	CHECKINSETTINGLIST,
	SAVECHECKINDATA,
	GET_THREE_SIXTY_ASSESSMENT_LIST,
	GET_NOMINEE_LIST,
	ADD_NOMINEE,
	DELETE_NOMINEE,
	NOMINEE_CHECK,
	MANAGER_ACCESSOR_VIEW,
	VALIDATE_IS_NOMINEE_DELETED,
	GET_ALIGNMENT_LIST,
	GET_ALL_ALIGNMENT_LIST,
	GET_ALIGNMENT_RECENT_SEARCH_DIRECT_REPORTER_LIST,
	DELETE_CHECKINDATA,
	CHECKINALREADY_ADDED,
	TENANT_DOWNTIME,
	GET_ASSESSMENT_VISIBILITY,
	PIP_REPORT,
	UPDATEDFEEDBACKREPORTS,
	SAVE_GOAL_COMMENT,
	LIKE_CONVERSATION_GOAL,
	GET_CONVERSATION_GOAL,
	DR_VIEW,
	DR_TEAM_VIEW
} from '../config/api-url';

export const getObjectiveListApi = (data) => getRequest(`${OKR_LISTING}?${data}`, data);
export const getEmployeeViewGoalsApi = (data) => getRequest(`${EMPLOYEE_VIEW}?${data}`, data);

export const addGoalsAPI = (data) => postRequest(`${ADD_GOALS}`, data);
export const alignGoalsAPI = (data) => getRequest(`${ALIGN_GOALS}?${data}`, data);
export const updateGoalsAPI = (data) => putRequest(`${ADD_GOALS}`, data);
export const deleteGoalsAPI = (data) => deleteRequest(`${ADD_GOALS}?${data}`, data);
export const deleteContributorAPI = (data) => deleteRequest(`${CONTRIBUTORS}?${data}`, data);
export const checkAlignStatusAPI = (data) => getRequest(`${ALIGN_STATUS}?${data}`, data);

export const updateKrProgressAPI = (data) => putRequest(`${UPDATE_KR_PROGRESS}`, data);
export const unlockRequestAPI = (data) => postRequest(`${UNLOCK_REQUEST}`, data);
export const getOkrStatusListApi = (data) => getRequest(`${OKR_STATUS}?${data}`, data);

export const dashboardMyGoalAPI = (data) => getRequest(`${DASHBOARD_LIST}?${data}`, data);
export const getGoalsDetailsAPI = (data) => getRequest(`${MY_GOAL_DETAIL}?${data}`, data);
export const getMyTeamDetailsAPI = (data) => getRequest(`${MY_TEAM_OKR_VIEW}?${data}`, data);
export const updateNudgeTeamsAPI = (data) => postRequest(`${NUDGE_TEAM}`, data);
export const updateNudgePersonAPI = (data) => postRequest(`${NUDGE_PERSON}?${data}`, {});
export const getMyTeamOKRDetailsAPI = (data) => getRequest(`${MY_TEAM_OKR_DETAILS_VIEW}?${data}`, data);
export const getMyDeltaScoreAPI = (data) => getRequest(`${DELTA_SCORE}?${data}`, data);

export const alignmentMapAPI = (data) => getRequest(`${ALIGNMENT_LIST}?${data}`, data);
export const getChildByObjective = (data) => getRequest(`${ALIGNMENT_DETAIL_LIST}?${data}`, data);
export const alignmentMapGraphAPI = (data) => getRequest(`${ALIGNMENT_GRAPH_DETAIL}?${data}`, data);
export const alignmentTeamMapAPI = (data) => getRequest(`${ALIGNMENT_LIST}?${data}&isTeams=true`, data);
export const alignmentDirectsReportsMapAPI = (data) => getRequest(`${ALIGNMENT_DIRECT_REPORTS_LIST}?${data}`, data);
export const updateDueDateAPI = (data) => putRequest(`${UPDATE_DUE_DATE}`, data);
// People map
export const peopleMapAPI = (data) => getRequest(`${PEOPLE_MAP_LIST}?${data}`, data);

export const getAllParentByObjective = (data) => getRequest(`${ALIGNMENT_PARENT_DETAIL}?${data}`, data);
export const downloadPdfAPI = (data) => postRequest(`${DOWNLOAD_GOAL_PDF}?${data}`, data);

export const fetchQuarterReportAPI = (data) => downloadXLSFile(`${QUARTER_REPORT}?${data}`, data);
export const fetchStatusReportAPI = (data) => getRequest(`${STATUS_REPORT}?${data}`, data);
export const fetchEngagementReportAPI = (data) => downloadXLSFile(`${STATUS_REPORT_ENGAGEMENT}?${data}`, data);
export const fetchProgressReportAPI = (data) => getRequest(`${PROGRESS_REPORT}?${data}`, data);
export const fetchFeedbackReportAPI = (data) => downloadXLSFile(`${PERSONAL_FEEDBACK_REPORT}?${data}`, data);
export const updateSequenceAPI = (data) => putRequest(`${UPDATE_OKR_SEQUENCE}`, data);
export const underAssignAPI = (data) => postRequest(`${UNDER_ASSIGNED}`, data);

/** okr master data API */
export const getOkrMasterDataApi = (data) => getRequest(`${OKR_MASTER_DATA}`, data);
export const getKrContributorsApi = (data) => getRequest(`${KR_CONTRIBUTORS}`, data);
export const updateGoalAndKrNameApi = (data) => putRequest(`${UPDATE_GOAL_KR_NAME}`, data);
export const updateGoalAttributesApi = (data) => putRequest(`${UPDATE_GOAL_ATTRIBUTES}`, data);
export const updateKrAttributesApi = (data) => putRequest(`${UPDATE_KR_ATTRIBUTES}`, data);
export const acceptKrApi = (data) => putRequest(`${ACCEPT_KR}`, data);
export const updateKrProgressValueApi = (data) => putRequest(`${UPDATE_KR_PROGRESS_VALUE}`, data);
export const becomeContributorApi = (data) => postRequest(`${BECOME_CONTRIBUTOR}`, data);
export const getKrDetailAPI = (data) => getRequest(`${GET_KR_DETAIL}/${data}`, data);
export const getOkrDetailAPI = (data) => getRequest(`${GET_OKR_DETAIL}/${data}`, data);
export const getOwnerTeamsDataApi = (data) => getRequest(`${GET_OWNER_TEAMS}${data}`, data);
export const getUserTeamsDataApi = (data) => getRequest(`${GET_USER_TEAMS}${data}`, data);
export const resetObjectiveApi = (data) => putRequest(`${RESET_OBJECTIVE}?${data}`, data);
export const getLinkOwnerDataApi = (data) => getRequest(`${GET_LINK_OWNER_DATA}?${data}`, data);

export const getLinkObjectivedDataApi = (data) => getRequest(`${GET_LINK_OBJECTIVE_DATA}/${data}`, data);
export const changeOwnerDataApi = (data) => putRequest(`${OWNER_CHANGE_DATA}/${data}`, data);
export const updateProgressApi = (data) => putRequest(`${UPDATE_PROGRESS}`, data);
export const recentContributorAPI = (data) => getRequest(`${RECENT_CONTRIBUTION_LIST}?${data}`, data);
export const virtualAlignmentAPI = (data) => getRequest(`${VIRTUAL_ALIGNMENT}?${data}`, data);
export const peopleViewFeedbackAPI = (data) => getRequest(`${PEOPLEVIEW_PERSONAL_FEEDBACK}?${data}`, data);

// Archve details

export const archieveAPI = (data) => getRequest(`${ARCHIVE_LIST}?${data}`, data);

// Dashboard progress summary
export const dashboardProgressAPI = (data) => getRequest(`${DASHBOARD_PROGRESS}?${data}`, data);
// Dashboard progress for Teams okr Tab
export const teamOkrProgressAPI = (data) => getRequest(`${TEAMSOKR_PROGRESS}?${data}`, data);
export const getObjectiveDetailsAPI = (data) => getRequest(`${OBJECTIVE_DETAILS}?${data}`, data);

//CFR
export const getTaskAPI = (data) => getRequest(`${OBJECTIVE_TASK}?${data}`, data);
export const deleteTaskAPI = (data) => deleteRequest(`${DELETE_TASK}?${data}`, data);
export const updateTaskAPI = (data) => putRequest(`${UPDATE_TASK}`, data);
export const postTaskAPI = (data) => postRequest(`${UPDATE_TASK}`, data);
export const taskCompletedAPI = (data) => putRequest(`${TASK_COMPLETED}`, data);
export const postProgressConversationAPI = (data) => postRequest(`${POST_PROGRESS_CONVERSATION}`, data);
export const getNoteByIdAPI = (data) => getRequest(`${GET_NOTEBY_ID}?${data}`, data);
export const getConversationByIdAPI = (data) => getRequest(`${GET_CONVERSATION_BY_ID}?${data}`, data);

export const myTeamGoalAPI = (data) => getRequest(`${TEAM_VIEW_LIST}?${data}`, data);
export const myDrAPI = (data) => postRequest(`${DR_VIEW}`, data);
export const otherTeamDrAPI = (data) => postRequest(`${DR_TEAM_VIEW}`, data);

//Checkin
export const checkInStatus = (data) => getRequest(`${CHECKINSTATUS}`, data);
export const checkInDates = (data) => getRequest(`${CHECKINDATES}?${data}`, data);
export const checkInData = (data) => getRequest(`${CHECKINDATA}?${data}`, data);
export const getReportingEmployeeDataAPI = (data) => getRequest(`${DIRECT_REPORTEES}/${data}`, data);
export const postCheckInAPI = (data) => postRequest(`${CHECKINDATA}`, data);
export const updateCheckInVisibilityDataApi = (data) => putRequest(`${CHECKIN_VISIBILTY_UPDATE_DATA}?${data}`, data);
export const checkinSettingData = (data) => getRequest(`${CHECKINSETTINGLIST}?${data}`, data);
export const saveSettingCheckInAPI = (data) => postRequest(`${SAVECHECKINDATA}`, data);
export const deleteSettingCheckInAPI = (data) => deleteRequest(`${DELETE_CHECKINDATA}?${data}`, data);
export const checkinAlreadyAdded = (data) => getRequest(`${CHECKINALREADY_ADDED}?${data}`, data);

//Confidence
export const updateConfidenceApi = (data) => putRequest(`${UPDATE_CONFIDENCE}`, data);
export const getWeeklyTrendDetailsAPI = (data) => getRequest(`${GET_WEEKLY_TREND_DETAILS}?${data}`, data);
export const getConfidenceAlertAPI = (data) => getRequest(`${GET_CONFIDENCE_ALERT}?${data}`, data);

//Free Trial
export const freeTrialApi = (data) => postRequestFreeTrial(`${FREE_TRIAL}`, data, { isProfileUrl: true });
export const freeTrialApiEmailId = (data) =>
	postRequestFreeTrial(`${FREE_TRIAL_EMAILID}`, data, { isProfileUrl: true });
export const freeTrialApiDomain = (data) => postRequestFreeTrial(`${FREE_TRIAL_DOMAIN}`, data, { isProfileUrl: true });
export const freeTrialApiPassword = (data) =>
	postRequestFreeTrial(`${FREE_TRIAL_PASSWORD}`, data, { isProfileUrl: true });

export const getOkrImportApi = (data) => getRequest(`${OKR_IMPORT}`);
export const getOkrImportCategoryApi = (data) => getRequest(`${OKR_CATEGORY_IMPORT}`);
export const getKRListingApi = (data) => getRequest(`${KR_LISTING}?${data}`, data);
export const postOkrImportApi = (data) => postRequest(`${OKR_IMPORT}`, data);
export const postMyOkrImportApi = (data) => postRequest(`${MY_OKR_IMPORT}`, data);
export const postRecognitionApi = (data) => postRequest(`${POST_RECOGNITION}?${data}`, data);

export const getTaskListAPI = (data) => getRequest(`${GET_TASK_LIST}?${data}`, data);
export const postCheckInImportAPI = (data) => postRequest(`${POST_TASK_IMPORT}`, data);
export const checkInDetailsDashboardAPI = (data) => getRequest(`${DASHBOARD_CHECKIN_DETAILS}?${data}`);

/** Perform */
export const getAssessmentTeamAPI = (data) => postRequest(`${GET_ASSESSMENT_TEAMLIST}`, data);
export const GetMultipleAssessmentAPI = (data) => postRequest(`${MULTIPLE_EMPLOYEE_ASSESSMENT}`, data);
export const saveRatingFeedbackAPI = (data) => postRequest(`${SAVE_RATING_FEEDBACK}`, data);
export const getEmployeeAssessmentAPI = (data) => getRequest(`${GET_EMPLOYEE_ASSESSMENT}?${data}`, data);
export const getPerformCycleAPI = () => getRequest(`${GET_PERFORMANCE_CYCLE}`);
export const getPerformAlertAPI = () => getRequest(`${GET_PERFORMANCE_ALERT}`);
export const getFinalRatingMasterDataAPI = () => getRequest(`${GET_FINAL_RATING}`);
export const getEmployeesFinalRatingAPI = (data) => postRequest(`${GET_EMPLOYEES_FINAL_RATING}`, data);
export const updateEmployeesFinalRatingAPI = (data) => postRequest(`${SAVE_EMPLOYEES_FINAL_RATING}`, data);
export const getThreeSixtyAssessmentListAPI = (data) => postRequest(`${GET_THREE_SIXTY_ASSESSMENT_LIST}`, data);
export const validateIsNomineeDeletedAPI = (data) => getRequest(`${VALIDATE_IS_NOMINEE_DELETED}?${data}`, data);
export const getAssessmentVisibility = (data) => getRequest(`${GET_ASSESSMENT_VISIBILITY}?${data}`, data);

export const multipleEmployeesManagerRatingAPI = (data) =>
	postRequest(`${SAVE_MULTIPLE_EMPLOYEES_MANAGER_ASSESSMENT}`, data);
export const getSnapshotDataAPI = (data) => postRequest(`${GET_SNAPSHOT_DATA}`, data);
export const saveSelfAssessmentAPI = (data) => postRequest(`${SAVE_SELF_ASSESSMENT}`, data);
export const isUserExludedAPI = (data) => getRequest(`${IS_USER_EXLUDED}?${data}`);
export const getNomineeList = (data) => getRequest(`${GET_NOMINEE_LIST}?${data}`);
export const saveNomineeApi = (data) => postRequest(`${ADD_NOMINEE}`, data);
export const deleteNomineeApi = (data) => deleteRequest(`${DELETE_NOMINEE}?${data}`, data);
export const validateNomineeApi = (data) => getRequest(`${NOMINEE_CHECK}?${data}`);
export const ManagerNomineeApi = (data) => getRequest(`${MANAGER_ACCESSOR_VIEW}?${data}`);
export const getAlignmentListData = (data) => getRequest(`${GET_ALIGNMENT_LIST}?${data}`);
export const getAllAlignmentListData = (data) => getRequest(`${GET_ALL_ALIGNMENT_LIST}?${data}`);
export const downloadFileApi = (path) => downloadXLSFile(`${path}`);

export const getRecentSearchAndDirectReporterListAPI = (data) =>
	getRequest(`${GET_ALIGNMENT_RECENT_SEARCH_DIRECT_REPORTER_LIST}?${data}`);
export const fetchUpdatedFeedbackReportAPI = (data) => downloadXLSFile(`${UPDATEDFEEDBACKREPORTS}?${data}`, data);
export const fetchPipReportAPI = (data) => downloadXLSFile(`${PIP_REPORT}?${data}`, data);

export const saveLikeGoal = (data) => postRequest(`${LIKE_CONVERSATION_GOAL}`, data);
export const saveCommentGoal = (data) => postRequest(`${SAVE_GOAL_COMMENT}`, data);
export const getCommentGoal = (data) => getRequest(`${GET_CONVERSATION_GOAL}?${data}`, data);
