import React, { useState, useEffect } from 'react';
import { Avatar, Box, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import { CrossIcon } from '../../config/svg/CommonSvgIcon';
import { OKRButton } from './OKRButton';
import '../../styles/pages/user-performance/AssessmentPopup.scss';
import { Enums } from '../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionTypeMasterData } from '../../action/adminSettings';
import { UserAssessmentForm } from '../UserPerformance/UserSelfAssessment/UserAssessmentForm';
import { getFullName, getUserName } from '../../config/utils';

export const AssessmentPopup: React.FC<any> = (props: any) => {
	const {
		handleCloseDialog,
		assessmentPopup,
		getSelfAssessmentDetails,
		performanceCycle,
		performanceCycleId,
		loader,
		setLoader,
		selectedUserDetails,
		formAssessmentData,
		callingFrom = '',
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { questionTypeMasterData } = useSelector((state: any) => state?.adminSettingReducer);
	const {
		// employeeAssessmentResponse,
		// employeeAssessmentResponseStatus,
		selfAssessmentResponse,
		// selfAssessmentResponseStatus,
	} = useSelector((state: any) => state?.userPerformanceReducer);

	const [assessmentFormDetails, setAssessmentFormDetails] = useState<any>(null);
	const [defaultAssessmentFormDetails, setDefaultAssessmentFormDetails] = useState<any>(null);
	const [totalSection, setTotalSection] = useState<number>(0);
	const [totalQuestion, setTotalQuestion] = useState<number>(0);
	// const [fullAssessmentFormDetails, setFullAssessmentFormDetails] = useState<any>(null);
	// const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

	useEffect(() => {
		if (formAssessmentData?.performanceRoleLinkedFormSections && callingFrom === 'AdminAssessmentView') {
			updateSectionAndQuestionCount(formAssessmentData?.performanceRoleLinkedFormSections);
			setLoader(false);
			setAssessmentFormDetails(addQuestionNumber(formAssessmentData?.performanceRoleLinkedFormSections));
			setDefaultAssessmentFormDetails(formAssessmentData?.performanceRoleLinkedFormSections);
		}
	}, [formAssessmentData]);

	useEffect(() => {
		if (selfAssessmentResponse?.performanceRoleLinkedFormSection && callingFrom !== 'AdminAssessmentView') {
			updateSectionAndQuestionCount(selfAssessmentResponse?.performanceRoleLinkedFormSection);
			setLoader(false);
			setAssessmentFormDetails(addQuestionNumber(selfAssessmentResponse?.performanceRoleLinkedFormSection));
			setDefaultAssessmentFormDetails(selfAssessmentResponse?.performanceRoleLinkedFormSection);
		}
		// if (selfAssessmentResponse) {
		// 	setFullAssessmentFormDetails(selfAssessmentResponse);
		// }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selfAssessmentResponse]);

	useEffect(() => {
		if (!questionTypeMasterData) {
			dispatch(getQuestionTypeMasterData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (performanceCycleId) {
			setLoader(true);
			// { SelfAssessment = 1, ManagerAssessment = 2, ThreeSixtyAssessment = 3 }
			getSelfAssessmentDetails(
				selectedUserDetails?.employeeId || Enums.ZERO,
				performanceCycleId || Enums.ZERO,
				Enums.ONE
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [performanceCycleId]);

	const addQuestionNumber = (details: any) => {
		let quesNumber = 0;
		const updatedDetails =
			details && details?.length
				? [...details].map((section: any, sectionIndex: number) => {
						if (section?.performanceRoleLinkedFormDetails && section?.performanceRoleLinkedFormDetails?.length) {
							if (sectionIndex === 0) {
								section.isSectionExpand = true;
							}
							section.performanceRoleLinkedFormDetails = section?.performanceRoleLinkedFormDetails.map(
								(question: any) => {
									quesNumber = quesNumber + 1;
									question.questionNumber = quesNumber;

									return question;
								}
							);
						}
						return section;
				  })
				: details;
		return updatedDetails;
	};

	const updateSectionAndQuestionCount = (details: any) => {
		try {
			if (details && details?.length) {
				let questionCount = 0;
				setTotalSection(details?.length || 0);
				details.forEach((section: any) => {
					const countVal = section?.performanceRoleLinkedFormDetails?.length;
					questionCount = questionCount + countVal;
				});
				setTotalQuestion(questionCount);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleSectionToggle = (e: any, sectionIndex: number) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			updateAssessmentFormDetails(sectionIndex);
		} catch (error) {
			console.error(error);
		}
	};
	const updateAssessmentFormDetails = (sectionIndex: number) => {
		try {
			if (assessmentFormDetails && assessmentFormDetails?.length) {
				let updatedAssessmentFormDetails = [...assessmentFormDetails].map((item: any) => {
					const data = { ...item };
					return data;
				});
				// updatedAssessmentFormDetails = updatedAssessmentFormDetails?.map((section: any, indexVal: number) => {
				// 	if (sectionIndex === indexVal) {
				// 		section.isSectionExpand = !section.isSectionExpand;
				// 	} else {
				// 		section.isSectionExpand = false;
				// 	}
				// 	return section;
				// });
				updatedAssessmentFormDetails[sectionIndex].isSectionExpand =
					!updatedAssessmentFormDetails[sectionIndex]?.isSectionExpand;
				setAssessmentFormDetails([...updatedAssessmentFormDetails]);
			}
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Dialog
			onClose={(e) => handleCloseDialog(e, false)}
			aria-labelledby='simple-dialog-title'
			open={assessmentPopup?.open}
			className='assessment-popup'
		>
			<Box className='dialog-title'>
				<Box className='title-left'>
					<Typography variant='h2'>
						{callingFrom === 'AdminAssessmentView' && formAssessmentData
							? formAssessmentData?.assessmentFormName || t('selfAssessment')
							: t('selfAssessment')}
					</Typography>
					<List>
						{selectedUserDetails && (
							<ListItem>
								<Box className='user-info v-center'>
									<Box className='user-img'>
										{selectedUserDetails?.imagePath ? (
											<Avatar className='avatar-default' src={selectedUserDetails.imagePath} />
										) : (
											<Avatar className='avatar-default'>
												{getUserName({
													fullName: '',
													firstName: selectedUserDetails?.firstName,
													lastName: selectedUserDetails?.lastName,
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Typography variant='h4'>{getFullName(selectedUserDetails)}</Typography>
									</Box>
								</Box>
							</ListItem>
						)}
						<ListItem>
							<ListItemText primary={`Total Sections ${totalSection || 0}`} />
						</ListItem>
						<ListItem>
							<ListItemText primary={`Total Questions ${totalQuestion || 0}`} />
						</ListItem>
					</List>
				</Box>
				<OKRButton className='close-assessment-popup' icon={<CrossIcon />} handleClick={handleCloseDialog} />
			</Box>
			<DialogContent dividers>
				<Box className=''>
					<UserAssessmentForm
						callingFrom={callingFrom}
						isViewMode={true}
						performanceCycle={performanceCycle}
						questionTypeMasterData={questionTypeMasterData}
						assessmentFormDetails={assessmentFormDetails}
						defaultAssessmentFormDetails={defaultAssessmentFormDetails}
						handleClearAssessmentForm={() => {}}
						handleSaveAsDraftAssessmentForm={() => {}}
						handleSubmitAssessmentForm={() => {}}
						handleChangeQuestionComment={() => {}}
						handleChangeShortQuestionValue={() => {}}
						handleChangeStarQuestionValue={() => {}}
						handleChangeStarQuestionHoverValue={() => {}}
						handleChangeScaleQuestionValue={() => {}}
						handleChangeMultiChoiceQuestionValue={() => {}}
						handleCommentsToggle={() => {}}
						loader={loader}
						isFormEdited={false}
						isAssessmentPopup={true}
						handleSectionToggle={handleSectionToggle}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};
