import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import NofoundImg from '../../../../images/no-feedback.svg';
import { CalendarIcon } from '../../../../config/svg/CommonSvgIcon';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import { LinkFeedbackPopup } from '../../Common/LinkFeedbackPopup';
import { getMonthDateYearTime } from '../../../../config/utils';
import { Enums } from '../../../../config/enums';
import animatedTag from '../../../../images/tag_animation.svg';

export const LinkedFeedbackList = (props: any) => {
	const { pipDetails, resolveStatusTypes, loader, setLoader, feedbackItem, showMsg, userDetails } = props;
	const { t } = useTranslation();

	const [linkFeedbackPopup, setLinkFeedbackPopup] = useState<any>({ open: false, details: null });

	const handlePopupClick = (e: any, details: any) => {
		setLinkFeedbackPopup({ open: true, details: details });
	};
	const handleCloseDialog = () => {
		setLinkFeedbackPopup({ open: false, details: null });
	};

	return (
		<Box className='pip-linked-feedback-box full-rectangular-card'>
			{pipDetails?.linkedFeedbacks?.length ? (
				<>
					<Typography variant='h6'>Linked Feedback ({pipDetails?.linkedFeedbacks?.length})</Typography>
					<Box className='pip-feedback-list'>
						<List disablePadding>
							{pipDetails?.linkedFeedbacks?.length ? (
								pipDetails?.linkedFeedbacks?.map((feedback: any, feedbackIndex: number) => {
									const {
										feedbackId,
										// feedbackTypeId,
										feedbackType,
										timeframeStartDate,
										timeframeEndDate,
										title,
										feedbackStatusId,
										feedbackStatus,
										// summary,
										feedbackCategory,
										// feedbackCategoryId,
									} = feedback;

									return (
										<>
											<ListItem
												key={`feedback_item_${feedbackId}_${feedbackIndex}`}
												id={`feedback_item_${feedbackId}_${feedbackIndex}`}
												onClick={(e) => handlePopupClick(e, feedback)}
											>
												<Box className='pip-status'>
													{/* <Chip className='lagging-chip' label={feedbackStatus || ''} /> */}
													{timeframeStartDate && timeframeEndDate ? (
														<>
															{feedbackStatusId === Enums.ONE ? (
																<Chip className='draft-chip' label={feedbackStatus} />
															) : feedbackStatusId === Enums.TWO ? (
																<Chip className='lagging-chip' label={feedbackStatus} />
															) : feedbackStatusId === Enums.THREE ? (
																<Chip className='lagging-chip' label={feedbackStatus} />
															) : feedbackStatusId === Enums.FOUR ? (
																<Box className='timeframe-animation'>
																	<AnimatedIcon
																		className=''
																		width='480'
																		type='image/svg+xml'
																		alt={t('globalSearchNoRecord')}
																		imagePath={animatedTag}
																	/>
																	<Chip className='on-track-chip' label={feedbackStatus} />
																</Box>
															) : feedbackStatusId === Enums.FIVE ? (
																<Chip className='at-risk-chip' label={feedbackStatus} />
															) : (
																<></>
															)}
														</>
													) : (
														<></>
													)}
													<Tooltip
														arrow
														title={<>{`${feedbackType || ''}${feedbackCategory ? '・' + feedbackCategory : ''}`}</>}
														placement='top'
													>
														{feedbackType || feedbackCategory ? (
															<Box className='feedback-type-chip'>
																{feedbackType ? (
																	<Chip
																		className='review-chip'
																		label={
																			<>
																				{feedbackType ? `${feedbackType}` : ''}
																				{feedbackCategory ? <em></em> : ''}
																				{feedbackCategory ? `${feedbackCategory}` : ''}
																			</>
																		}
																	/>
																) : (
																	<></>
																)}
															</Box>
														) : (
															<></>
														)}
													</Tooltip>
												</Box>
												<Typography variant='h4'>{title || ''}</Typography>

												{timeframeStartDate && timeframeEndDate ? (
													<Box className='feedback-date-range'>
														<Typography variant='h6'>
															<CalendarIcon />{' '}
															{timeframeStartDate && timeframeEndDate ? (
																`${getMonthDateYearTime(timeframeStartDate)} - ${getMonthDateYearTime(
																	timeframeEndDate
																)}`
															) : (
																<></>
															)}
														</Typography>
													</Box>
												) : (
													<></>
												)}
											</ListItem>
										</>
									);
								})
							) : (
								<></>
							)}
						</List>
					</Box>
				</>
			) : (
				<></>
			)}
			{/* No Record content */}
			{!pipDetails?.linkedFeedbacks || pipDetails?.linkedFeedbacks?.length === 0 ? (
				<Box className='user-no-record'>
					<Typography variant='h2'>{t('noFeedbackLinked')}</Typography>
					<Typography>{t('noAssociatedFeedback')}</Typography>
					<AnimatedIcon
						className=''
						width='150'
						type='image/svg+xml'
						alt={t('globalSearchNoRecord')}
						imagePath={NofoundImg}
					/>
				</Box>
			) : (
				<></>
			)}
			{linkFeedbackPopup && linkFeedbackPopup?.open ? (
				<LinkFeedbackPopup
					setLinkFeedbackPopup={setLinkFeedbackPopup}
					linkFeedbackPopup={linkFeedbackPopup}
					handleCloseDialog={handleCloseDialog}
					pipDetails={pipDetails}
					loader={loader}
					setLoader={setLoader}
					feedbackItem={linkFeedbackPopup?.details}
					showMsg={showMsg}
					userDetails={userDetails}
					resolveStatusTypes={resolveStatusTypes}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
