import React from 'react';
import { Badge, Button, Tooltip, withStyles } from '@material-ui/core';
import { minTwoDigits } from '../../config/utils';

type Color = 'inherit' | 'primary' | 'secondary' | 'default';

interface OKRButtonProps {
	className?: string;
	title?: React.ReactNode | String;
	text?: React.ReactNode | String;
	handleClick?: (event: any) => void;
	disabled?: boolean;
	icon?: React.ReactNode;
	color?: Color;
	percentage?: any;
	style?: any;
	handleOver?: (event: any) => void;
	handleLeave?: (event: any) => void;
	tooltipClassName?: string;
	buttonType?: any;
	id?: string;
	tooltipPlacement?: any;
	disableRipple?: boolean;
	wrapClassName?: string;
	openTooltip?: boolean;
}

export const OKRButton: React.FC<OKRButtonProps> = (props) => {
	const {
		className,
		percentage,
		text,
		handleClick,
		disabled,
		icon,
		title,
		style,
		handleOver,
		handleLeave,
		tooltipClassName,
		buttonType,
		id,
		tooltipPlacement,
		disableRipple,
		wrapClassName,
		openTooltip = false,
	} = props;

	const getChildren = () => {
		return buttonType === 'createOkr' || buttonType === 'disableButtonTooltip' ? (
			<span className={wrapClassName || ''}>
				<Button
					className={className || ''}
					onClick={handleClick}
					disabled={disabled || false}
					style={style || {}}
					onMouseOver={handleOver}
					onMouseLeave={handleLeave}
					id={id ? id : ''}
					disableRipple={disableRipple}
					aria-label='button'
				>
					<span className='icon'>{icon}</span> {text || ''}
					{percentage && !isNaN(percentage?.teamScore) && (
						<span style={{ background: `#${percentage.progressCode}` }} className='percentage'>
							{minTwoDigits(percentage.teamScore)}%
						</span>
					)}
				</Button>
			</span>
		) : buttonType === 'hasBadge' ? (
			<Badge variant='dot' className='dot-bubble' overlap='circular'>
				<Button
					className={className || ''}
					onClick={handleClick}
					disabled={disabled || false}
					style={style || {}}
					onMouseOver={handleOver}
					onMouseLeave={handleLeave}
					id={id ? id : ''}
					disableRipple={disableRipple}
					aria-label='button'
				>
					<span className='icon'>{icon}</span> {text || ''}
					{percentage && !isNaN(percentage?.teamScore) && (
						<span style={{ background: `#${percentage.progressCode}` }} className='percentage'>
							{minTwoDigits(percentage.teamScore)}%
						</span>
					)}
				</Button>
			</Badge>
		) : (
			<Button
				className={className || ''}
				onClick={handleClick}
				disabled={disabled || false}
				style={style || {}}
				onMouseOver={handleOver}
				onMouseLeave={handleLeave}
				id={id ? id : ''}
				disableRipple={disableRipple}
				aria-label='button'
			>
				<span className='icon'>{icon}</span> {text || ''}
				{percentage && !isNaN(percentage?.teamScore) && (
					<span style={{ background: `#${percentage.progressCode}` }} className='percentage'>
						{minTwoDigits(percentage.teamScore)}%
					</span>
				)}
			</Button>
		);
	};
	return openTooltip ? (
		<TooltipSection
			title={title || ''}
			tooltipClassName={tooltipClassName}
			tooltipPlacement={tooltipPlacement}
			getChildren={getChildren}
		/>
	) : (
		<Tooltip title={title || ''} arrow classes={{ popper: tooltipClassName || '' }} placement={tooltipPlacement}>
			{getChildren()}
		</Tooltip>
	);
};

export const TooltipSection: React.FC<any> = (props: any) => {
	const { title, tooltipClassName, tooltipPlacement } = props;

	return (
		<Tooltip
			title={title || ''}
			arrow
			classes={{ popper: tooltipClassName || '' }}
			placement={tooltipPlacement}
			open={true}
		>
			{props.getChildren()}
		</Tooltip>
	);
};
