import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Collapse,
	FormControlLabel,
	Switch,
	TablePagination,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { GenerateOKRColorIcon, UpdateIcon } from '../../../../config/svg/CommonSvg';
import { OKRButton } from '../../../Common/OKRButton';
import { RightArrowIcon } from '../../../../config/svg/ArrowSvg';
import { SelectedParameterTooltip } from './SelectedParameterTooltip';
import { getUserDetails, getUserName } from '../../../../config/utils';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import noRecordFound from '../../../../images/ChatLoader.svg';

export const GenerateKrList: React.FC<any> = (props) => {
	const {
		okrListData,
		getMoreOkrData,
		pageNumbers,
		pageSize,
		handlePageChange,
		moreOkrApiPending,
		addObjective,
		removeObjective,
		addKeyResult,
		removeKeyResult,
	} = props;
	const { t } = useTranslation();
	const loggedInUserDetail = getUserDetails();
	const [krToggle, setKrToggle] = useState<any[]>([]);

	const handleKrToggle = (e: any, item: any) => {
		let list = Object.assign([], krToggle);
		const elementIndex = list.indexOf(item.krId);
		if (elementIndex !== -1) {
			removeKeyResult(item);
			list.splice(elementIndex, 1);
		} else {
			addKeyResult(item);
			list.push(item.krId);
		}
		setKrToggle(list);
	};

	return (
		<>
			{/* section row repeat start*/}
			{okrListData.map((section: any, index: any) => {
				const dataArray = section.keyResults;
				const totalItems = dataArray.length;
				const totalPages = Math.ceil(totalItems / pageSize);
				const pageNumber = pageNumbers[index];
				const startIndex = (pageNumber - 1) * pageSize;
				const items = dataArray.slice(startIndex, startIndex + pageSize);
				return (
					<Box className='generate-okr-row'>
						<Box className='generate-section-head'>
							<Box className='user-info v-center'>
								<Box className='user-img'>
									{section.isDefaultSection ? (
										<Avatar className='recommended-default'>
											<GenerateOKRColorIcon />
										</Avatar>
									) : (
										<>
											{loggedInUserDetail && loggedInUserDetail.imagePath ? (
												<Avatar
													className='avatar-default'
													alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
													src={loggedInUserDetail.imagePath}
												/>
											) : (
												<Avatar className='avatar-default'>
													{getUserName({
														firstName: loggedInUserDetail.firstName,
														lastName: loggedInUserDetail.lastName,
														fullName: loggedInUserDetail.fullname,
													})}
												</Avatar>
											)}
										</>
									)}
								</Box>
								<Box className='user-info-details'>
									<Typography variant='body2'>
										{section.isDefaultSection ? (
											<span>Unlock Recommended</span>
										) : (
											<>
												{section.sectionName ? (
													<span>{section.sectionName}</span>
												) : (
													<>
														<span>Based on searched parameters</span>
													</>
												)}
											</>
										)}
										<SelectedParameterTooltip {...props} okrRecommendationRequest={section.okrRecommendationRequest} />
									</Typography>
								</Box>
							</Box>
						</Box>
						<Box className='generate-section-content'>
							<Box className='gen-more-okr'>
								<OKRButton
									className={'btn-link-type1'}
									text={t('generateMoreKRs')}
									icon={<UpdateIcon />}
									handleClick={(e) => getMoreOkrData(section.sectionId)}
									disabled={moreOkrApiPending !== ''}
								/>
								<TablePagination
									component='div'
									className='user-list-pagination'
									count={section.keyResults.length}
									page={pageNumber - 1}
									onPageChange={(e, newPage: number) => {
										handlePageChange(newPage + 1, index);
									}}
									rowsPerPage={pageSize}
									rowsPerPageOptions={[]}

									//onRowsPerPageChange={handleChangeRowsPerPage}
									// SelectProps={{
									// 	MenuProps: {
									// 		PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
									// 	},
									// }}
								/>
							</Box>
							{moreOkrApiPending !== section.sectionId ? (
								<>
									{items.map((item: any) => (
										<Box className={`import-okr-list import-myokr-list`}>
											{/* row repeat start */}
											<Box className='import-kr-list'>
												<Box className='import-kr-content'>
													<Box className='import-kr-row'>
														<Box className='import-kr-col'>
															<Box className='kr-title-section'>
																<Typography>{item.keyResult}</Typography>
															</Box>
														</Box>
														<Box className='import-kr-progress-col'>
															<Box className='link-toggle'>
																<Tooltip arrow title={t('addDashboard')}>
																	<FormControlLabel
																		control={
																			<Switch
																				checked={krToggle.indexOf(item.krId) !== -1}
																				onChange={(e) => handleKrToggle(e, item)}
																			/>
																		}
																		label={''}
																		className='toggle-label toggle-no-label'
																	/>
																</Tooltip>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
										</Box>
									))}
								</>
							) : (
								<Box className='generate-okr-loader'>
									<AnimatedIcon
										className=''
										width='300'
										type='image/svg+xml'
										alt={t('globalSearchNoRecord')}
										imagePath={noRecordFound}
									/>
								</Box>
							)}
						</Box>
					</Box>
				);
			})}
		</>
	);
};
