import React from 'react';
import { Box, ClickAwayListener, List, ListItem, ListItemText, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../config/enums';

export const TaskGroupBy: React.FC<any> = (props: any) => {
	const { filterDetails, handleGroupByFilterClick, callingFrom } = props;
	const { t } = useTranslation();
	const [open, setOpen] = React.useState<Boolean>(false);
	const taskGroupByType: any = { 0: 'None', 1: 'Delegate', 2: 'Priority', 3: 'Category', 4: 'Week' };
	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};

	return (
		<Box className={`okr-team-dropdown group-by-dropdown ${open ? 'active' : ''} `}>
			<Typography variant='h5' className='font-weight-normal'>
				{t('groupBySources')}:
			</Typography>
			<OKRButton
				className={'dropdown-button placeholder-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={taskGroupByType[`${filterDetails?.taskGroupByType || Enums.ZERO}`]}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='group-by-dropdown-list'>
						<Box className='group-by-dropdown-list-inner'>
							<List component='div' disablePadding>
								<ListItem
									className={filterDetails?.taskGroupByType === Enums.ZERO ? 'active' : ''}
									component='div'
									onClick={(e) => {
										handleClickAway();
										handleGroupByFilterClick(Enums.ZERO);
									}}
								>
									<ListItemText>{taskGroupByType[Enums.ZERO]}</ListItemText>
								</ListItem>
								<ListItem
									className={filterDetails?.taskGroupByType === Enums.ONE ? 'active' : ''}
									component='div'
									onClick={(e) => {
										handleClickAway();
										handleGroupByFilterClick(Enums.ONE);
									}}
								>
									<ListItemText>{taskGroupByType[Enums.ONE]}</ListItemText>
								</ListItem>
								{/* 
									Not in Scope
								<ListItem component='div'>
									<ListItemText>Key Results</ListItemText>
								</ListItem> 
								*/}
								{callingFrom === 'GlobalTask' ? (
									<>
										<ListItem
											className={filterDetails?.taskGroupByType === Enums.TWO ? 'active' : ''}
											component='div'
											onClick={(e) => {
												handleClickAway();
												handleGroupByFilterClick(Enums.TWO);
											}}
										>
											<ListItemText>{taskGroupByType[Enums.TWO]}</ListItemText>
										</ListItem>
										<ListItem
											className={filterDetails?.taskGroupByType === Enums.THREE ? 'active' : ''}
											component='div'
											onClick={(e) => {
												handleClickAway();
												handleGroupByFilterClick(Enums.THREE);
											}}
										>
											<ListItemText>{taskGroupByType[Enums.THREE]}</ListItemText>
										</ListItem>
										<ListItem
											className={filterDetails?.taskGroupByType === Enums.FOUR ? 'active' : ''}
											component='div'
											onClick={(e) => {
												handleClickAway();
												handleGroupByFilterClick(Enums.FOUR);
											}}
										>
											<ListItemText>{taskGroupByType[Enums.FOUR]}</ListItemText>
										</ListItem>
									</>
								) : (
									<></>
								)}
							</List>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
