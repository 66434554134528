import React, { Fragment, useEffect, useState } from 'react';
import { Badge, Box, ClickAwayListener, List, ListItem, Paper, Popper, Tooltip, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import {
	checkInReports,
	directReportsFeedbackReport,
	goalCycleStatusReport,
	oneOnOneReports,
	performanceAssessmentDetailedStatusReports,
	performanceAssessmentQuickStatusReports,
	performanceImprovementPlanReport,
	recognitionReports,
	supportModuleAlignGoals,
	supportModulePerformAssessment,
	supportModuleRecognize,
	userDetailsReport,
	userWiseActivityReport,
	userWiseOkrProgressReport,
} from '../Admin/AdminConstant';
import {
	AlignIcon,
	DashboardIcon,
	DownloadIcon,
	EngageIcon,
	LearningPlusIcon,
	LearnLinkIcon,
	OrgChartIcon,
	PerformanceIcon,
	ReportsLinkIcon,
	RewardsIcon,
	SelfReviewIcon,
	StrategicObjIcon,
	TeamIcon,
	TeamReviewIcon,
	LearnLinkIconWhite,
	SkillsIcon,
	LetterIcon,
	TasksIcon,
} from '../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import { LeftMenuItem } from './LeftMenuItem';
import {
	CheckInsIcon,
	CreateOKRIcon,
	DirectReportsIcon,
	GoalCycleIcon,
	ObjectiveIcon,
	PeopleProgressIcon,
} from '../../config/svg/MyGoalSvg';
import {
	HOME,
	ALIGNMENTMAPS,
	ADMIN,
	REPORTS,
	DASHBOARD,
	FEEDBACK,
	ENGAGE,
	PERFORM,
	RECOGNIZE,
	PERFORM_DEVELOPMENT,
	LETTER_GEN,
	GLOBAL_Task,
	GOAL_REVIEW,
} from '../../config/app-url';
import { GiveFeedbackIcon, RequestOneOnOneIcon } from '../../config/svg/PersonalFeedbackSvg';
import {
	OrgReportsIcon,
	Perform360Icon,
	PipIcon,
	ReportsIcon,
	TeamReportsIcon,
	TeamUserIcon,
} from '../../config/svg/CommonSvgIcon';
import { LearningIcon } from '../../config/svg/DrawerSvg';
import { Enums } from '../../config/enums';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { currentDashboardTabForSO, currentEngageTabs, showOnCreatePopup } from '../../action/common';
import { getCurrentUserDetails, getUserDetails } from '../../config/utils';
import {
	supportModuleAlignOkr,
	supportModuleEngage,
	supportModuleLearn,
	supportModulePerformDevelopment,
	supportModuleLetterGeneration,
} from '../Admin/AdminConstant';
import { getPerfomanceAlert } from '../../action/perform';
import { getFeedbackAlertData, getPipAlertData } from '../../action/performanceImprovementPlan';
import { checkAnyPermission } from '../../config/rolePermission';
import { getEmployeePermission } from '../../action/roles';
import { getOkrMasterData } from '../../action/myGoal';
import { getGoalAlertConversation } from '../../action/adminSettings';
export const LeftTopMenu: React.FC<any> = (props: any) => {
	const { currentRoute, updateRoute, location, resetOkr, checkSupportPermission } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { currentEngageTab, dashboardCurrentTabForSO, okrCreationInProgress } = useSelector(
		(state: any) => state.commonReducer
	);
	const { soPermissionDetails } = useSelector((state: any) => state.strategicObjectiveReducer);
	const tenantData: any = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const history = useHistory();
	const currentUser: any = getCurrentUserDetails();
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const { performanceAlert } = useSelector((state: any) => state.performReducer);
	const { pipAlert, feedbackAlert } = useSelector((state: any) => state.performanceImprovementPlanReducer);
	const { goalConversationAlert } = useSelector((state: any) => state.adminSettingReducer);
	const userDetail = getUserDetails();
	const [menuOpen, setMenuOpen] = React.useState('');
	const [isHovered, setIsHovered] = useState<boolean>(false);
	const [isLgAllowed, setIsLgAllowed] = useState<boolean>(false);
	const { getPermissionResult } = useSelector((state: any) => state.rolesReducer);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	useEffect(() => {
		if (
			getPermissionResult &&
			getPermissionResult.employeePermissions &&
			!getPermissionResult.employeePermissions.length
		) {
			getEmployeePermission(undefined, history);
		}
	}, [history.location]);

	const handleMenuClick = (e: any, tabToOpen: number, routeToOpen?: string, typeForSO?: any) => {
		if (okrCreationInProgress) {
			e.preventDefault();
			e.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...e } }));
			return false;
		}
		if (routeToOpen === 'REPORTS') {
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(tabToOpen);
			props.updateRoute('/reports');
			props.switchUser(e, 'user', REPORTS);
		} else if (routeToOpen === ENGAGE) {
			dispatch(currentDashboardTabForSO(false));
			dispatch(currentEngageTabs(tabToOpen));
			props.updateRoute(ENGAGE);
			props.switchUser(e, 'user', ENGAGE);
		} else if (routeToOpen === PERFORM_DEVELOPMENT) {
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(tabToOpen);
			props.updateRoute(PERFORM_DEVELOPMENT);
			props.switchUser(e, 'user', PERFORM_DEVELOPMENT);
		} else if (routeToOpen === PERFORM) {
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(tabToOpen);
			props.updateRoute(PERFORM);
			props.switchUser(e, 'user', PERFORM);
		} else if (routeToOpen === RECOGNIZE) {
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(tabToOpen);
			props.updateRoute(RECOGNIZE);
			props.switchUser(e, 'user', RECOGNIZE);
		} else if (routeToOpen === LETTER_GEN) {
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(tabToOpen);
			props.updateRoute(LETTER_GEN);
			props.switchUser(e, 'user', LETTER_GEN);
		} else if (routeToOpen === GOAL_REVIEW) {
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(tabToOpen);
			props.updateRoute(GOAL_REVIEW);
			props.switchUser(e, 'user', GOAL_REVIEW);
		} else {
			removeLocalStorageItem('backUrl');
			if (tabToOpen === 0 && routeToOpen === HOME && typeForSO === 'SO') {
				dispatch(currentDashboardTabForSO(true));
			} else {
				dispatch(currentDashboardTabForSO(false));
			}
			props.currentDashboardTab(tabToOpen);
			props.updateRoute(HOME);
			props.switchUser(e, 'user', HOME);
		}
		// if (props.currentRoute !== HOME) {
		// }
	};

	useEffect(() => {
		handleLeftMenuAlerts();
		setIsLgAllowed(JSON.parse(getLocalStorageItem('userDetail') || '').isLGAllowed);
	}, []);

	useEffect(() => {
		if (
			props.userImpersonated &&
			props.userImpersonated.userDetails &&
			Object.keys(props.userImpersonated.userDetails).length
		) {
			const confidenceData = `cycle=${cycleId}`;
			dispatch(getGoalAlertConversation());
			dispatch(getPerfomanceAlert());
			dispatch(getFeedbackAlertData());
			dispatch(getPipAlertData());
			props.isCheckinSubmitted();
			//props.getConfidenceAlertDetails(confidenceData);
		}
	}, [props.userImpersonated]);

	const handleGlobalTaskClick = (e: any) => {
		dispatch(currentDashboardTabForSO(false));
		props.updateRoute(GLOBAL_Task);
		props.switchUser(e, 'user', GLOBAL_Task);
	};

	const handleLeftMenuAlerts = () => {
		const path = location && location.pathname ? location.pathname : '';
		resetOkr();
		updateRoute(path);

		if (!performanceAlert) {
			dispatch(getPerfomanceAlert());
		}

		if (!goalConversationAlert) {
			dispatch(getGoalAlertConversation());
		}
		if (!feedbackAlert) {
			dispatch(getFeedbackAlertData());
		}

		if (!pipAlert) {
			dispatch(getPipAlertData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
		if (props.checkInResult && Object.keys(props.checkInResult).length === 0) {
			!currentUser && props.isCheckinSubmitted();
		}
		if (!props.confidenceAlertData?.isNewOkrAlert) {
			const confidenceData = `cycle=${cycleId}`;
			//props.getConfidenceAlertDetails(confidenceData);
		}
	};

	const getActiveClassDashboard = (tab: any) => {
		if (props.currentRoute === HOME) {
			if (props.dashboardCurrentTab === tab) {
				return 'active';
			}
		} else {
			return '';
		}
	};

	return (
		<Box>
			<List className='left-menu-links' disablePadding>
				<ListItem>
					<OKRButton
						handleClick={(e) => {
							if (okrCreationInProgress) {
								e.preventDefault();
								e.stopPropagation();
								dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...e } }));
								return false;
							}
							dispatch(currentDashboardTabForSO(false));
							props.currentDashboardTab(0);
							props.updateRoute(DASHBOARD);
							history.push(DASHBOARD);
						}}
						icon={<DashboardIcon />}
						title={t('dashboardLabel')}
						tooltipPlacement={'right'}
						id={'dashboard-link'}
						className={`${props.currentRoute === DASHBOARD ? 'active' : ''}`}
					/>
				</ListItem>
				{(checkSupportPermission(supportModuleAlignOkr) || checkSupportPermission(supportModuleAlignGoals)) && (
					<ListItem>
						<LeftMenuItem
							btnIcon={<AlignIcon />}
							btnText={
								<Badge
									invisible={
										(!props.confidenceAlertData?.isNewOkrAlert && !goalConversationAlert?.isConversationRedDotAlert) ||
										props.currentRoute === HOME
									}
									//invisible={!props.confidenceAlertData?.isNewOkrAlert}
									variant='dot'
									className='dot-bubble'
									overlap='circular'
								></Badge>
							}
							btnTooltip={t('alignLabel')}
							btnId={'align-link'}
							isActive={props.currentRoute === HOME}
							children={
								<>
									{checkSupportPermission(supportModuleAlignOkr) && (
										<List className='left-sub-menu' disablePadding>
											<ListItem
												className={getActiveClassDashboard(0)}
												onClick={(e) => handleMenuClick(e, 0, HOME)}
												id='my-okr-link'
											>
												<CreateOKRIcon />
												<Typography variant='h5'>
													{/* {props.confidenceAlertData?.isNewOkrAlert || props.confidenceAlertData?.isConfidenceAlert ? ( */}
													{props.confidenceAlertData?.isNewOkrAlert ? (
														<Tooltip
															placement='top'
															arrow
															classes={{ popper: 'tooltip-alert' }}
															title={
																// props.confidenceAlertData?.isNewOkrAlert && props.confidenceAlertData?.isConfidenceAlert ? (
																// 	props.confidenceAlertData?.isNewOkrAlert && props.confidenceAlertData?.isConfidenceAlert ? (
																// 	<List>
																// 		<ListItem>{t('receivedNewRequest')}</ListItem>
																// 		<ListItem>{t('updateConfidence')}</ListItem>
																// 	</List>
																// ) :
																props.confidenceAlertData?.isNewOkrAlert ? t('receivedNewRequest') : <></>
															}
														>
															<Badge
																variant='dot'
																invisible={!props.confidenceAlertData}
																className='dot-bubble'
																overlap='circular'
															></Badge>
														</Tooltip>
													) : (
														''
													)}
													{t('myOkrsLabel')}
												</Typography>
											</ListItem>
											<ListItem
												className={getActiveClassDashboard(1)}
												onClick={(e) => handleMenuClick(e, 1, HOME)}
												id='team-okr-link'
											>
												<TeamIcon />
												<Typography variant='h5'>{t('TeamOkrsLabel')}</Typography>
											</ListItem>
											<ListItem
												className={getActiveClassDashboard(5)}
												onClick={(e) => handleMenuClick(e, 5, HOME)}
												id='alignment-map-link'
											>
												<AlignIcon />
												<Typography variant='h5'>{t('alignmentMaps')}</Typography>
											</ListItem>
											{props.userSelectedCycle?.cycleStatus !== Enums.FUTURE_CYCLE_ID && (
												<ListItem
													className={getActiveClassDashboard(4)}
													onClick={(e) => handleMenuClick(e, 4, HOME)}
													id='org-chart-link'
												>
													<OrgChartIcon />
													<Typography variant='h5'>{t('orgChartLabel')}</Typography>
												</ListItem>
											)}
											{/* {props?.userDetail?.directReports && ( */}
											<ListItem
												className={getActiveClassDashboard(2)}
												onClick={(e) => handleMenuClick(e, 2, HOME)}
												id='direct-report-link'
											>
												<PeopleProgressIcon />
												<Typography variant='h5'>{t('peoplesProgress')}</Typography>
											</ListItem>
											{/* )} */}
											{soPermissionDetails && soPermissionDetails?.isSoVisibilityRights ? (
												<ListItem
													className={`so-obj-link ${dashboardCurrentTabForSO ? getActiveClassDashboard(0) : ''}`}
													onClick={(e) => handleMenuClick(e, 0, HOME, 'SO')}
												>
													<StrategicObjIcon />
													<Typography variant='h5'>{t('strategicObjLabel')}</Typography>
												</ListItem>
											) : soPermissionDetails && soPermissionDetails?.isMissionVissionAdded ? (
												<ListItem
													className={`so-obj-link ${dashboardCurrentTabForSO ? getActiveClassDashboard(0) : ''}`}
													onClick={(e) => handleMenuClick(e, 0, HOME, 'SO')}
												>
													<StrategicObjIcon />
													<Typography variant='h5'>{t('missionVisionLabel')}</Typography>
												</ListItem>
											) : (
												<></>
											)}
										</List>
									)}
									{checkSupportPermission(supportModuleAlignGoals) && (
										<List className='left-sub-menu' disablePadding>
											<ListItem
												className={getActiveClassDashboard(6)}
												onClick={(e) => handleMenuClick(e, 6, HOME)}
												id='my-goals-link'
											>
												<GoalCycleIcon />
												<Typography variant='h5'>
													{goalConversationAlert?.isConversationRedDotAlert && (
														<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
													)}
													{t('myGoalsLabel')}
												</Typography>
											</ListItem>
											{okrMasterData?.isGoalCycleDrAvailable && (
												<ListItem
													className={getActiveClassDashboard(7)}
													onClick={(e) => handleMenuClick(e, 7, HOME)}
													id='team-goals-link'
												>
													<TeamIcon />
													<Typography variant='h5'>{t('directReportsGoals')}</Typography>
												</ListItem>
											)}
										</List>
									)}
								</>
							}
						/>
					</ListItem>
				)}
				{checkSupportPermission(supportModuleEngage) && (
					<ListItem>
						<LeftMenuItem
							btnIcon={<EngageIcon />}
							btnText={
								<Badge
									invisible={!props.checkInResult?.isAlert}
									variant='dot'
									className='dot-bubble'
									overlap='circular'
								></Badge>
							}
							btnTooltip={t('engageLabel')}
							btnId={'engage-link'}
							isActive={props.currentRoute === ENGAGE}
							children={
								<List className='left-sub-menu' disablePadding>
									{/* <ListItem
									id='one-to-one-link'
									className={props.currentRoute === ENGAGE && currentEngageTab === 0 ? 'active' : ''}
									onClick={(e) => handleMenuClick(e, 0, ENGAGE)}
								>
									<RequestOneOnOneIcon />
									<Typography variant='h5'>{t('requestOnLabel')}</Typography>
								</ListItem> */}
									{/* <ListItem
										id='feedback-link'
										className={props.currentRoute === ENGAGE && currentEngageTab === 1 ? 'active' : ''}
										onClick={(e) => handleMenuClick(e, 1, ENGAGE)}
									>
										<GiveFeedbackIcon />
										<Typography variant='h5'>{t('feedbackLabel')}</Typography>
									</ListItem> */}
									{!props.isFutureCycle && (
										<ListItem
											id='check-ins-link'
											className={props.currentRoute === ENGAGE && currentEngageTab === 2 ? 'active' : ''}
											onClick={(e) => handleMenuClick(e, 2, ENGAGE)}
										>
											<CheckInsIcon />
											<Typography variant='h5'>
												<Tooltip placement='top' arrow classes={{ popper: 'tooltip-alert' }} title={''}>
													<Badge
														invisible={!props.checkInResult?.isAlert}
														variant='dot'
														className='dot-bubble'
														overlap='circular'
													></Badge>
												</Tooltip>
												{t('checkInsLabel')}
											</Typography>
										</ListItem>
									)}
									<ListItem
										id='one-to-one-link'
										className={props.currentRoute === ENGAGE && currentEngageTab === 3 ? 'active' : ''}
										onClick={(e) => handleMenuClick(e, 3, ENGAGE)}
									>
										<RequestOneOnOneIcon />
										<Typography variant='h5'>{t('requestOnetoOne')}</Typography>
									</ListItem>
									<ListItem
										id='global-task'
										className={props.currentRoute === ENGAGE && currentEngageTab === 4 ? 'active' : ''}
										onClick={(e) => handleGlobalTaskClick(e)}
									>
										<TasksIcon />
										<Typography variant='h5'>{t('tasksLabel')}</Typography>
									</ListItem>
								</List>
							}
						/>
					</ListItem>
				)}

				{checkSupportPermission(supportModulePerformAssessment) ||
				checkSupportPermission(supportModulePerformDevelopment) ? (
					<ListItem>
						<LeftMenuItem
							btnIcon={<PerformanceIcon />}
							btnTooltip={t('performanceLabel')}
							btnId={'perform-link'}
							isActive={props.currentRoute === PERFORM || props.currentRoute === PERFORM_DEVELOPMENT}
							menuOpen={menuOpen}
							setMenuOpen={setMenuOpen}
							btnText={
								<Badge
									invisible={
										(performanceAlert &&
											!performanceAlert?.isManagerAssessmentAlert &&
											!performanceAlert?.isSelfAssessmentAlert &&
											!performanceAlert?.isThreeSixtyAssessmentAlert &&
											pipAlert &&
											!pipAlert?.isNewComment &&
											!pipAlert?.isTimeFrameAlert &&
											feedbackAlert &&
											!feedbackAlert.isTimeFrameAlert &&
											!feedbackAlert.isNewComment) ||
										props.currentRoute === PERFORM ||
										props.currentRoute === PERFORM_DEVELOPMENT ||
										menuOpen === 'perform-link'
									}
									variant='dot'
									className='dot-bubble'
									overlap='circular'
								></Badge>
							}
							children={
								<>
									{checkSupportPermission(supportModulePerformDevelopment) && (
										<Box className='left-sub-menu-row'>
											<Typography variant='h6'>{t('prsDevelopment')}</Typography>
											<List className='left-sub-menu' disablePadding>
												{checkSupportPermission(supportModuleAlignGoals) ? (
													<ListItem
														id='ThreeSixty-link'
														className={`${
															props.currentRoute === PERFORM_DEVELOPMENT && props.dashboardCurrentTab === 0
																? 'active'
																: ''
														}`}
														onClick={(e) => handleMenuClick(e, 0, PERFORM_DEVELOPMENT)}
													>
														<GiveFeedbackIcon />
														<Typography
															variant='h5'
															dangerouslySetInnerHTML={{ __html: t('threeSixetyExperience') }}
														></Typography>
														<Tooltip
															title={
																feedbackAlert && feedbackAlert?.isNewComment && feedbackAlert?.isTimeFrameAlert ? (
																	<>
																		{t('timeFramePassed')} <br /> {t('newComment')}
																	</>
																) : feedbackAlert && feedbackAlert?.isNewComment ? (
																	t('newComment')
																) : feedbackAlert && feedbackAlert?.isTimeFrameAlert ? (
																	t('timeFramePassed')
																) : (
																	<></>
																)
															}
															arrow
														>
															<Badge
																invisible={feedbackAlert && !feedbackAlert.isThreeSixtyExperienceNewComment}
																variant='dot'
																className='dot-bubble'
																overlap='circular'
															></Badge>
														</Tooltip>
													</ListItem>
												) : (
													<></>
												)}

												<ListItem
													id='feedback-link'
													className={`${
														props.currentRoute === PERFORM_DEVELOPMENT && props.dashboardCurrentTab === 1
															? 'active'
															: ''
													}`}
													onClick={(e) => handleMenuClick(e, 1, PERFORM_DEVELOPMENT)}
												>
													<GiveFeedbackIcon />
													<Typography variant='h5'>{t('feedbackLabel')}</Typography>
													<Tooltip
														title={
															feedbackAlert && feedbackAlert?.isNewComment && feedbackAlert?.isTimeFrameAlert ? (
																<>
																	{t('timeFramePassed')} <br /> {t('newComment')}
																</>
															) : feedbackAlert && feedbackAlert?.isNewComment ? (
																t('newComment')
															) : feedbackAlert && feedbackAlert?.isTimeFrameAlert ? (
																t('timeFramePassed')
															) : (
																<></>
															)
														}
														arrow
													>
														<Badge
															invisible={
																feedbackAlert && !feedbackAlert.isTimeFrameAlert && !feedbackAlert.isNewComment
															}
															variant='dot'
															className='dot-bubble'
															overlap='circular'
														></Badge>
													</Tooltip>
												</ListItem>
												<ListItem
													id='prs-improvement-plan'
													className={`${
														props.currentRoute === PERFORM_DEVELOPMENT && props.dashboardCurrentTab === 2
															? 'active'
															: ''
													}`}
													onClick={(e) => handleMenuClick(e, 2, PERFORM_DEVELOPMENT)}
												>
													<PipIcon />
													<Typography variant='h5'>{t('prsImprovementPlan')}</Typography>
													<Tooltip
														title={
															pipAlert && pipAlert?.isNewComment && pipAlert?.isTimeFrameAlert ? (
																<>
																	{t('timeFramePassed')} <br /> {t('newComment')}
																</>
															) : pipAlert && pipAlert?.isNewComment ? (
																t('newComment')
															) : pipAlert && pipAlert?.isTimeFrameAlert ? (
																t('timeFramePassed')
															) : (
																<></>
															)
														}
														arrow
													>
														<Badge
															invisible={pipAlert && !pipAlert?.isNewComment && !pipAlert?.isTimeFrameAlert}
															variant='dot'
															className='dot-bubble'
															overlap='circular'
														></Badge>
													</Tooltip>
												</ListItem>
											</List>
										</Box>
									)}
									{/* {checkSupportPermission(supportModuleAlignGoals) && ( */}
									<Box className='left-sub-menu-row'>
										<Typography variant='h6'>{t('performanceAssessment')}</Typography>
										<List className='left-sub-menu' disablePadding>
											<ListItem
												className={`${
													props.currentRoute === GOAL_REVIEW && props.dashboardCurrentTab === 0 ? 'active' : ''
												}`}
												id='self-review'
												onClick={(e) => handleMenuClick(e, 0, GOAL_REVIEW)}
											>
												<SelfReviewIcon />
												<Typography variant='h5'>{t('selfReviewLevel')}</Typography>
											</ListItem>
											{/* <ListItem
													id='team-review'
													className={`${
														props.currentRoute === GOAL_REVIEW && props.dashboardCurrentTab === 1 ? 'active' : ''
													}`}
													onClick={(e) => handleMenuClick(e, 1, GOAL_REVIEW)}
												>
													<TeamUserIcon />
													<Typography variant='h5'>{t('teamReviewLevel')}</Typography>
												</ListItem> */}
											{okrMasterData?.performanceGoalReviewPermission?.isTeamReview && (
												<ListItem
													id='team-review'
													className={`${
														props.currentRoute === GOAL_REVIEW && props.dashboardCurrentTab === 1 ? 'active' : ''
													}`}
													onClick={(e) => handleMenuClick(e, 1, GOAL_REVIEW)}
												>
													<TeamUserIcon />
													<Typography variant='h5'>{t('teamReviewLevel')}</Typography>
												</ListItem>
											)}
											{okrMasterData?.performanceGoalReviewPermission?.isApprovals && (
												<ListItem
													className={`${
														props.currentRoute === GOAL_REVIEW && props.dashboardCurrentTab === 2 ? 'active' : ''
													}`}
													id='review-approvals'
													onClick={(e) => handleMenuClick(e, 2, GOAL_REVIEW)}
												>
													<Perform360Icon />
													<Typography variant='h5'>{t('Approvals')}</Typography>
												</ListItem>
											)}
										</List>
									</Box>
									{/* )} */}
									{checkSupportPermission(supportModulePerformAssessment) && (
										<Box className='left-sub-menu-row'>
											<Typography variant='h6'>{t('performanceAssessment')}</Typography>
											<List className='left-sub-menu' disablePadding>
												<ListItem
													className={`${
														props.currentRoute === PERFORM && props.dashboardCurrentTab === 0 ? 'active' : ''
													}`}
													id='my-assessment-link'
													onClick={(e) => handleMenuClick(e, 0, PERFORM)}
												>
													<SelfReviewIcon />

													<Typography variant='h5'>{t('myAssessment')}</Typography>
													{((props.currentRoute === PERFORM && props.dashboardCurrentTab !== 0) ||
														props.currentRoute !== PERFORM) && (
														<Badge
															invisible={performanceAlert && !performanceAlert?.isSelfAssessmentAlert}
															variant='dot'
															className='dot-bubble'
															overlap='circular'
														></Badge>
													)}
												</ListItem>
												{props?.userDetail?.directReports && (
													<ListItem
														id='my-team-assessment-link'
														className={`${
															props.currentRoute === PERFORM && props.dashboardCurrentTab === 1 ? 'active' : ''
														}`}
														onClick={(e) => handleMenuClick(e, 1, PERFORM)}
													>
														<TeamUserIcon />
														<Typography variant='h5'>{t('myTeamsAssessment')}</Typography>
														{((props.currentRoute === PERFORM && props.dashboardCurrentTab !== 1) ||
															props.currentRoute !== PERFORM) && (
															<Badge
																invisible={performanceAlert && !performanceAlert?.isManagerAssessmentAlert}
																variant='dot'
																className='dot-bubble'
																overlap='circular'
															></Badge>
														)}
													</ListItem>
												)}
												<ListItem
													className={`${
														props.currentRoute === PERFORM && props.dashboardCurrentTab === 2 ? 'active' : ''
													}`}
													id='assessment-three-sixty-link'
													onClick={(e) => handleMenuClick(e, 2, PERFORM)}
												>
													<Perform360Icon />
													<Typography
														variant='h5'
														dangerouslySetInnerHTML={{ __html: t('assessment360degree') }}
													></Typography>
													{((props.currentRoute === PERFORM && props.dashboardCurrentTab !== 2) ||
														props.currentRoute !== PERFORM) && (
														<Badge
															invisible={performanceAlert && !performanceAlert?.isThreeSixtyAssessmentAlert}
															variant='dot'
															className='dot-bubble'
															overlap='circular'
														></Badge>
													)}
												</ListItem>
											</List>
										</Box>
									)}
								</>
							}
						/>
					</ListItem>
				) : (
					<></>
				)}
				{checkSupportPermission(supportModuleRecognize) ? (
					<ListItem>
						<OKRButton
							icon={<RewardsIcon />}
							title={t('rewardsLabel')}
							tooltipPlacement={'right'}
							id={'recognize-link'}
							handleClick={(e) => {
								handleMenuClick(e, 1, RECOGNIZE);
							}}
							className={`${props.currentRoute === RECOGNIZE ? 'active' : ''}`}
						/>
					</ListItem>
				) : (
					<></>
				)}

				{checkSupportPermission(supportModuleLetterGeneration) && isLgAllowed ? (
					<ListItem>
						<OKRButton
							icon={<LetterIcon />}
							title={t('letterGenTitle')}
							tooltipPlacement={'right'}
							id={'letter-link'}
							handleClick={(e) => {
								handleMenuClick(e, 1, LETTER_GEN);
							}}
							className={`${props.currentRoute === LETTER_GEN ? 'active' : ''}`}
						/>
					</ListItem>
				) : (
					<></>
				)}
				{/* 	{checkSupportPermission(supportModuleLearn) ? (
					<ListItem>
						<OKRButton
							icon={<LearnLinkIcon />}
							title={t('learnLabel')}
							tooltipPlacement={'right'}
							handleClick={(e) => {
								props.handleNavigateToUnlockU(e);
							}}
						/>
					</ListItem>
				) : (
					<></>
				)} */}
				{/* {checkSupportPermission(supportModuleLearn) && (
					<>
						{tenantData && tenantData.paidServiceTypeId > 0 && (
							<ListItem>
								<LeftMenuItem
									btnIcon={<LearnLinkIcon />}
									btnTooltip={t('learnLabel')}
									btnId={'learn-link'}
									children={
										<List className='left-sub-menu' disablePadding>
											<ListItem id='academy-link' onClick={props.handleNavigateToUnlockU}>
												<LearningIcon />
												<Typography variant='h5'>{t('academyLabel')}</Typography>
											</ListItem>
											<ListItem id='academy-plus-link' onClick={props.handleNavigateToLearning}>
										<LearningPlusIcon />
										<Typography variant='h5'>{t('academyPlusLabel')}</Typography>
									</ListItem> 
										</List>
									}
								/>
							</ListItem>
						)}
					</>
				)} */}
				{checkSupportPermission(supportModuleAlignOkr) ||
				checkAnyPermission(getPermissionResult?.employeePermissions, [
					userWiseActivityReport,
					userWiseOkrProgressReport,
					directReportsFeedbackReport,
					performanceImprovementPlanReport,
					performanceAssessmentQuickStatusReports,
					performanceAssessmentDetailedStatusReports,
					userDetailsReport,
					oneOnOneReports,
					recognitionReports,
					checkInReports,
					goalCycleStatusReport,
				]) ? (
					<ListItem>
						<LeftMenuItem
							btnIcon={<ReportsLinkIcon />}
							btnId={'report-link'}
							btnTooltip={t('reports')}
							isActive={props.currentRoute === REPORTS}
							children={
								<List className='left-sub-menu' disablePadding>
									{checkSupportPermission(supportModuleAlignOkr) && (
										<>
											<ListItem
												className={`${
													props.currentRoute === REPORTS && props.dashboardCurrentTab === 0 ? 'active' : ''
												}`}
												onClick={(e) => {
													handleMenuClick(e, 0, 'REPORTS');
												}}
												id='my-report-link'
											>
												<ReportsIcon />
												<Typography variant='h5'>{t('myReportsLabel')}</Typography>
											</ListItem>
											<ListItem
												className={`${
													props.currentRoute === REPORTS && props.dashboardCurrentTab === 1 ? 'active' : ''
												}`}
												onClick={(e) => {
													handleMenuClick(e, 1, 'REPORTS');
												}}
												id='team-report-link'
											>
												<TeamReportsIcon />
												<Typography variant='h5'>{t('teamReportsLabel')}</Typography>
											</ListItem>
											<ListItem
												className={`${
													props.currentRoute === REPORTS && props.dashboardCurrentTab === 2 ? 'active' : ''
												}`}
												onClick={(e) => {
													props.updateRoute('/reports');
													handleMenuClick(e, 2, 'REPORTS');
												}}
												id='org-report-link'
											>
												<OrgReportsIcon />
												<Typography variant='h5'>{t('orgReportLabel')}</Typography>
											</ListItem>
										</>
									)}

									{checkAnyPermission(getPermissionResult?.employeePermissions, [
										userWiseActivityReport,
										userWiseOkrProgressReport,
										directReportsFeedbackReport,
										performanceImprovementPlanReport,
										performanceAssessmentQuickStatusReports,
										performanceAssessmentDetailedStatusReports,
										userDetailsReport,
										oneOnOneReports,
										recognitionReports,
										checkInReports,
										goalCycleStatusReport,
									]) ? (
										<ListItem
											className={`${props.currentRoute === REPORTS && props.dashboardCurrentTab === 3 ? 'active' : ''}`}
											onClick={(e) => {
												props.updateRoute('/reports');
												handleMenuClick(e, 3, 'REPORTS');
											}}
											id='download-link'
										>
											<DownloadIcon />
											<Typography variant='h5'>{t('downloadableReports')}</Typography>
										</ListItem>
									) : (
										<></>
									)}
								</List>
							}
						/>
					</ListItem>
				) : (
					<></>
				)}

				<hr className='menu_divider' />

				{checkSupportPermission(supportModuleLearn) ? (
					<ListItem
						className='learn_skills'
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<OKRButton
							icon={<LearnLinkIconWhite />}
							title={
								<Box>
									{isHovered && (
										<span style={{ display: 'flex', alignItems: 'center' }} className='skill-icon'>
											{t('skillLabel')}
											{/* Add your icon here */}
											<SkillsIcon />
										</span>
									)}
								</Box>
							}
							tooltipPlacement={'right'}
							handleClick={(e) => {
								props.handleNavigateToUnlockU(e, true);
							}}
						/>
					</ListItem>
				) : (
					<></>
				)}
			</List>
		</Box>
	);
};
