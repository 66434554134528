import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { ProgressTabPanel, ReflectionsTabs } from './ReflectionsTabs';
import { OKRButton } from '../OKRButton';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';
import { AnimatedIcon } from '../AnimatedIcon';
import NotesImg from '../../../images/no-found-notes.svg';

export const ReflectionCheckInDetails: React.FC<any> = (props) => {
	const { t, middleComponentDetails, handleHideReflectionsClick } = props;

	const [tabSelected, setTabSelected] = useState<number>(0);

	return (
		<>
			<Box className='popup-head'>
				<Box className='popup-title'>
					<Typography variant='body2'>Reflections</Typography>
					<OKRButton icon={<CrossIcon />} className='cross-btn' handleClick={handleHideReflectionsClick} />
				</Box>
				<ReflectionsTabs
					tabSelected={tabSelected}
					setTabSelected={setTabSelected}
					tabDetails={middleComponentDetails.performanceSnapshotCheckInResponse.checkInWeeklyDatesResponse}
				/>
			</Box>
			<Box className='popup-content long-text'>
				<Box>
					{middleComponentDetails.performanceSnapshotCheckInResponse.checkInWeeklyDatesResponse[tabSelected]
						?.reflections ? (
						<Box
							dangerouslySetInnerHTML={{
								__html:
									middleComponentDetails.performanceSnapshotCheckInResponse.checkInWeeklyDatesResponse[tabSelected]
										?.reflections,
							}}
						></Box>
					) : (
						<Box className='not-found'>
							<AnimatedIcon
								className='no-task-image'
								width='100x'
								type='image/svg+xml'
								alt={t('noContent')}
								imagePath={NotesImg}
							/>
							<Box className='right-not-found'>
								<Typography variant='body2'>No Reflection this week</Typography>
								<Typography variant='body2' className='font-weight-normal'>
									No achievements or roadblocks added by user for this week.
								</Typography>
							</Box>
						</Box>
					)}
				</Box>
				{/* {middleComponentDetails.performanceSnapshotCheckInResponse.checkInWeeklyDatesResponse?.map(
					(tabData: any, indexVal: number) => {
						return (
							<ProgressTabPanel value={tabSelected} index={indexVal}>
								{tabData?.reflections ? (
									<Box dangerouslySetInnerHTML={{ __html: tabData?.reflections }}></Box>
								) : (
									<Box className='not-found'>
										<AnimatedIcon
											className='no-task-image'
											width='100x'
											type='image/svg+xml'
											alt={t('noContent')}
											imagePath={NotesImg}
										/>
										<Box className='right-not-found'>
											<Typography variant='body2'>No Reflection this week</Typography>
											<Typography variant='body2' className='font-weight-normal'>
												No achievements or roadblocks added by user for this week.
											</Typography>
										</Box>
									</Box>
								)}
							</ProgressTabPanel>
						);
					}
				)} */}
			</Box>
		</>
	);
};
