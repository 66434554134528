import {
	Box,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	List,
	ListItem,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { SearchIcon, SortingIcon, TickIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';

export const PeopleProgressLists = (props: any) => {
	const {
		drOtherData,
		selectedUsers,
		setSelectedUsers,
		showLoadMore,
		loadMore,
		pageIndex,
		checkAll,
		setCheckAll,
		totalPages,
		handleInputThrottled,
		searchValue,
		totalRecords,
		sortList,
		sorting,
	} = props;

	const { t } = useTranslation();
	const [loader, setLoader] = useState(false);
	const onUserSelect = (e: any, item: any, index: any) => {
		let selectedUsersCpy: any = { ...selectedUsers };
		if (selectedUsersCpy[item.teamId] === undefined) {
			selectedUsersCpy[item.teamId] = item;
		} else {
			delete selectedUsersCpy[item.teamId];
		}
		setSelectedUsers(selectedUsersCpy);
	};
	const handleAllChecked = (e: any) => {
		if (e.target.checked) {
			setCheckAll(true);
			let selectedUsersCpy: any = { ...selectedUsers };
			drOtherData.forEach((item: any, index: any) => {
				if (selectedUsersCpy[item.teamId] === undefined) {
					selectedUsersCpy[item.teamId] = item;
				}
			});
			setSelectedUsers(selectedUsersCpy);
		} else {
			setCheckAll(false);
			setSelectedUsers({});
		}
	};
	return (
		<>
			<Tabs className='okr-tabs drawer-tabs' value={0} aria-label='Other Teams Tabs'>
				<Tab label={t('selectTeamLabel')} value={0} />
			</Tabs>
			<Box className='drawer-tabs-panel'>
				<Box className='users-progress-listing'>
					<Box className='users-progress-list-notes'>
						<Typography variant='h6' className='font-weight-normal'>
							<sup>*</sup> {t('asOnTodayMssg')}
						</Typography>
					</Box>
					<Box className='users-progress-listing-top'>
						<Box className='team-count'>
							<Typography variant='subtitle2'>{`${t('totalTeams')} (${totalRecords})`}</Typography>
						</Box>
						<Box className='user-search'>
							<TextField
								className='search-text-field'
								id='user-src-field'
								placeholder={t('searchText')}
								fullWidth
								value={searchValue}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								onChange={(e: any) => handleInputThrottled(e.target.value)}
							/>

							{/* {searchTextArray && (
								<Box className='user-search-chip'>
									<List>
										<ListItem>
											{searchTextArray.length > 20 ? (
												<Tooltip title={searchTextArray} arrow>
													<Chip
														label={searchTextArray.substring(0, 20) + '...'}
														onDelete={clearSearch}
														deleteIcon={
															<span>
																<CrossIcon />
															</span>
														}
													/>
												</Tooltip>
											) : (
												<Chip
													label={searchTextArray}
													onDelete={clearSearch}
													deleteIcon={
														<span>
															<CrossIcon />
														</span>
													}
												/>
											)}
										</ListItem>
									</List>
								</Box>
							)} */}
						</Box>
					</Box>
					<Box className='okr-list-panel-head'>
						<List component='ul' disablePadding>
							<ListItem className='users-progress-col1'>
								<FormControlLabel
									control={
										<Checkbox
											checked={checkAll}
											onChange={(e) => handleAllChecked(e)}
											icon={<BorderCheckboxIcon />}
											checkedIcon={<CheckedIcon />}
											indeterminateIcon={<UnCheckedIcon />}
											// indeterminate={
											// 	checkedUser &&
											// 	checkedUser.length > 0 &&
											// 	getSelectedUserLength() > 0 &&
											// 	localUserList.length > getSelectedUserLength()
											// }
											//disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
										/>
									}
									label=''
								/>
								<Typography
									variant='body2'
									onClick={(e) => sortList(e, 'teamName', sorting.order === 'asc' ? 'desc' : 'asc')}
									className={`sort-title ${
										sorting.sortingText === 'teamName' && sorting.order === 'asc' ? 'desc' : ''
									}`}
									id='sort-name'
								>
									<span className='sortable-label'>
										<span className='txt-wrap'>
											{t('teamNameLabel')} <SortingIcon />
										</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='users-progress-col2'>
								<Typography
									onClick={(e) => sortList(e, 'parentTeamName', sorting.order === 'asc' ? 'desc' : 'asc')}
									className={`sort-title ${
										sorting.sortingText === 'parentTeamName' && sorting.order === 'asc' ? 'desc' : ''
									}`}
									variant='body2'
								>
									<span className='sortable-label'>
										<span className='txt-wrap'>
											{t('parentTeamNameLabel')} <SortingIcon />
										</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='users-progress-col3'>
								<Typography
									onClick={(e) => sortList(e, 'employeeCount', sorting.order === 'asc' ? 'desc' : 'asc')}
									className={`sort-title ${
										sorting.sortingText === 'employeeCount' && sorting.order === 'asc' ? 'desc' : ''
									}`}
									variant='body2'
								>
									<span className={`sortable-label `}>
										<span className='txt-wrap'>
											{t('noOfMembersLabel')} <sup>*</sup>
											<SortingIcon />
										</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='users-progress-col4'>
								<Typography
									onClick={(e) => sortList(e, 'score', sorting.order === 'asc' ? 'desc' : 'asc')}
									className={`sort-title ${sorting.sortingText === 'score' && sorting.order === 'asc' ? 'desc' : ''}`}
									variant='body2'
								>
									<span className={`sortable-label `}>
										<span className='txt-wrap'>
											{t('aggregatedProgress')} <SortingIcon />
										</span>
									</span>
								</Typography>
							</ListItem>
						</List>
					</Box>
					<Box className='users-progress-list-content'>
						{drOtherData.map((item: any, index: any) => (
							<>
								<Box
									className='users-list-row'
									key={`selected_user_progress_index_${index}`}
									id={`selected_user_progress_index_${index}`}
								>
									<List component='ul' disablePadding>
										<ListItem className='users-progress-col1'>
											<FormControlLabel
												control={
													<Checkbox
														checked={selectedUsers[item.teamId] !== undefined}
														onChange={(e) => onUserSelect(e, item, index)}
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														indeterminateIcon={<UnCheckedIcon />}
														//disabled={goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed}
													/>
												}
												label=''
											/>
											<Tooltip arrow title={item.teamName}>
												<Typography>{item.teamName}</Typography>
											</Tooltip>
										</ListItem>
										<ListItem className='users-progress-col2'>
											{item.parentTeamName ? (
												<Tooltip arrow title={item.parentTeamName}>
													<Typography>{item.parentTeamName}</Typography>
												</Tooltip>
											) : (
												<Typography>--</Typography>
											)}
										</ListItem>
										<ListItem className='users-progress-col3'>
											<Typography variant='body1'>{item.employeeCount}</Typography>
										</ListItem>
										<ListItem className='users-progress-col4'>
											<Typography variant='subtitle1'>
												{item.score}
												<sup>%</sup>
											</Typography>
										</ListItem>
									</List>
								</Box>
							</>
						))}
						{showLoadMore && (
							<Box className='view-more-teams'>
								<OKRButton
									handleClick={(e) => {
										loadMore(pageIndex + 1 || 1);
									}}
									className={`load-more-btn btn-link-type1`}
									text={t('viewMoreTeams')}
								/>
							</Box>
						)}
					</Box>
				</Box>
				{selectedUsers && selectedUsers?.length > 1 ? (
					<Box className='bulk-user-edit-bar'>
						<Box className='bulk-user-selected'>
							<Typography variant='body1'>
								<TickIcon />
								{selectedUsers?.length} {t('selectedLabel')}
							</Typography>
						</Box>
					</Box>
				) : (
					<></>
				)}
			</Box>
		</>
	);
};
