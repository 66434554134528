import React from 'react';

export const ReCenterIcon = () => (
	<svg width='30' height='31' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M15.6046 2V9.48193M15.6046 22.494V29M2 16.2426H9.5M21.5854 16.0316H28.5001M15.6046 25.5776C10.2769 25.5776 5.95804 21.3108 5.95804 16.0473C5.95804 10.7839 10.2769 6.51701 15.6046 6.51701C20.9322 6.51701 25.2511 10.7839 25.2511 16.0473C25.2511 21.3108 20.9322 25.5776 15.6046 25.5776Z'
			stroke='#292929'
			strokeWidth='3'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const FitToScreenIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M21.6668 1.875L15.0002 8.95833L8.3335 1.875'
			stroke='#292929'
			strokeWidth='3'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M8.33317 28.1251L14.9998 21.0418L21.6665 28.1251'
			stroke='#292929'
			strokeWidth='3'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M1.875 8.33329L8.95833 15L1.875 21.6666'
			stroke='#292929'
			strokeWidth='3'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M28.125 21.6666L21.0417 14.9999L28.125 8.33325'
			stroke='#292929'
			strokeWidth='3'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);
export const FullScreen = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M0.375244 3.625C0.375244 2.24429 1.49453 1.125 2.87524 1.125H10.6555C11.484 1.125 12.1555 1.79657 12.1555 2.625C12.1555 3.45343 11.484 4.125 10.6555 4.125H3.37524V11.2925C3.37524 12.121 2.70367 12.7925 1.87524 12.7925C1.04682 12.7925 0.375244 12.121 0.375244 11.2925V3.625Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M2.875 28.9498C1.49429 28.9498 0.375 27.8305 0.375 26.4498L0.375 19.167C0.375 18.3385 1.04657 17.667 1.875 17.667C2.70343 17.667 3.375 18.3385 3.375 19.167L3.375 25.9498L10.6553 25.9498C11.4837 25.9498 12.1553 26.6214 12.1553 27.4498C12.1553 28.2783 11.4837 28.9498 10.6553 28.9498L2.875 28.9498Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M29.625 26.4498C29.625 27.8305 28.5057 28.9498 27.125 28.9498L18.9438 28.9498C18.1154 28.9498 17.4438 28.2783 17.4438 27.4498C17.4438 26.6214 18.1154 25.9498 18.9438 25.9498L26.625 25.9498L26.625 19.1669C26.625 18.3385 27.2966 17.6669 28.125 17.6669C28.9534 17.6669 29.625 18.3385 29.625 19.1669L29.625 26.4498Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M27.125 1.125C28.5057 1.125 29.625 2.24429 29.625 3.625L29.625 10.4011C29.625 11.2295 28.9534 11.9011 28.125 11.9011C27.2966 11.9011 26.625 11.2295 26.625 10.4011L26.625 4.125L18.9438 4.125C18.1153 4.125 17.4438 3.45343 17.4438 2.625C17.4438 1.79657 18.1153 1.125 18.9438 1.125L27.125 1.125Z'
			fill='#292929'
		/>
	</svg>
);
export const ExitFullScreen = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M12.1558 10.2926C12.1558 11.6733 11.0365 12.7926 9.65576 12.7926L1.87544 12.7926C1.04701 12.7926 0.37544 12.121 0.37544 11.2926C0.375439 10.4642 1.04701 9.7926 1.87544 9.7926L9.15576 9.7926L9.15576 2.62504C9.15576 1.79661 9.82733 1.12504 10.6558 1.12504C11.4842 1.12504 12.1558 1.79661 12.1558 2.62504L12.1558 10.2926Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M9.65527 17.667C11.036 17.667 12.1553 18.7863 12.1553 20.167L12.1553 27.4499C12.1553 28.2783 11.4837 28.9499 10.6553 28.9499C9.82685 28.9499 9.15527 28.2783 9.15527 27.4499L9.15527 20.667L1.87495 20.667C1.04652 20.667 0.37495 19.9954 0.37495 19.167C0.37495 18.3386 1.04652 17.667 1.87495 17.667L9.65527 17.667Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M17.4438 20.167C17.4438 18.7863 18.5631 17.667 19.9438 17.667H28.125C28.9534 17.667 29.625 18.3386 29.625 19.167C29.625 19.9954 28.9534 20.667 28.125 20.667H20.4438V27.4499C20.4438 28.2783 19.7723 28.9499 18.9438 28.9499C18.1154 28.9499 17.4438 28.2783 17.4438 27.4499V20.167Z'
			fill='#292929'
		/>
		<path
			fill-rule='evenodd'
			clip-rule='evenodd'
			d='M19.9438 11.9011C18.5631 11.9011 17.4438 10.7818 17.4438 9.40112L17.4438 2.62501C17.4438 1.79658 18.1154 1.12501 18.9438 1.12501C19.7723 1.12501 20.4438 1.79658 20.4438 2.62501L20.4438 8.90112L28.1251 8.90112C28.9535 8.90112 29.6251 9.5727 29.6251 10.4011C29.6251 11.2295 28.9535 11.9011 28.1251 11.9011L19.9438 11.9011Z'
			fill='#292929'
		/>
	</svg>
);
