import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	Box,
	List,
	ListItem,
	TextField,
	Typography,
	InputAdornment,
	FormLabel,
	Avatar,
	Chip,
	Tooltip,
	MenuList,
	MenuItem,
	Badge,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { AlertInfoIcon, DeleteIcon, EditIcon, ExternalContributorIcon, SortIcon } from '../../../config/svg/CommonSvg';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import {
	abbreviateNumber,
	getCurrentQuarterAndYear,
	getKRStatus,
	getMonthDate,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { NumbersIcon, PercentageIcon } from '../../../config/svg/MyGoalSvg';
import { getLocalStorageItem } from '../../../services/StorageService';
import '../../../styles/pages/drawer/contributorsListPanel.scss';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import { Enums } from '../../../config/enums';
import { checkNumber } from '../../../config/utils';
import AlertDialog from '../../Common/Dialog';

export const KrContributorList: React.FC<any> = (props) => {
	const {
		t,
		keyData,
		keyAttribute,
		objectiveEndDate,
		getCurrencyIcon,
		okrMasterData,
		onDeleteContributor,
		sortContributorsList,
		sortingColumn,
		sortOrder,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		anchorElCallouts,
		updatedContributorList,
		setUpdatedContributorList,
		targetValue,
		krStartValue,
		updateGroupTargetValue,
		isLocked,
	} = props;
	const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const [filteredConList, setFilteredConList] = useState<any[]>([]);
	const [selectedMetricDetail, setSelectedMetricDetail] = useState<any>(null);
	const [selectedCurrencyData, setSelectedCurrencyData] = useState<any>([]);
	const [isGroupTarget, setIsGroupTarget] = useState<boolean>(
		keyAttribute?.id?.includes('-') ? keyAttribute?.isGroupTarget : keyAttribute?.isGroupTarget || false
	);
	const [groupTargetChangeProps, setGroupTargetChangeProps] = useState<any>({ open: false, message: '', details: {} });

	useEffect(() => {
		// if (selectedMetricDetail?.metricId !== Enums.ONE) {
		setIsGroupTarget(
			keyAttribute?.id?.includes('-') ? keyAttribute?.isGroupTarget : keyAttribute?.isGroupTarget || false
		);
		// } else {
		// 	setIsGroupTarget(false);
		// }
	}, [selectedMetricDetail, keyAttribute]);
	useEffect(() => {
		const { selectedMetric, selectedCurrencyDetail } = getMetricDetails();
		setSelectedMetricDetail(selectedMetric);
		setSelectedCurrencyData(selectedCurrencyDetail);
	}, []);

	useEffect(() => {
		// setFilteredConList(updatedContributorList);
		if (updatedContributorList && updatedContributorList.length > 0) {
			const isGroup = keyAttribute?.id?.includes('-')
				? keyAttribute?.isGroupTarget
				: keyAttribute?.isGroupTarget || false;
			const updatedContributorListData = updatedContributorList?.map((item: any) => {
				if (isGroup) {
					item.targetValue = targetValue;
					item.startValue = krStartValue;
					item.isGroupTarget = isGroup;
				}
				return item;
			});
			setFilteredConList(updatedContributorListData);
		}
	}, [updatedContributorList]);

	const changeGroupTargetDetail = (name: any, checked: any) => {
		filteredConList.length > 0 &&
			filteredConList.forEach((selectedUser: any, index: number) => {
				const startValueD = checked
					? krStartValue
					: selectedUser?.employeeId === userDetail?.employeeId
					? krStartValue
					: 0;
				const targetValueD = checked
					? targetValue
					: selectedUser?.employeeId === userDetail?.employeeId
					? targetValue
					: 0;
				selectedUser[name] = checked;
				updateValue('startValue', startValueD, index);
				setTimeout(() => {
					updateValue('targetValue', targetValueD, index);
				}, 1000);
			});
		setIsGroupTarget(checked || false);
		updateGroupTargetValue && updateGroupTargetValue(checked);
	};
	const handleGroupTargetClick = (e: any) => {
		const { name, checked } = e?.target || {};
		if (keyAttribute?.goalKeyId && keyAttribute?.goalStatusId !== Enums.GOAL_STATUS_DRAFT) {
			setGroupTargetChangeProps({ open: true, message: t('groupTargetChangeMessage'), details: { name, checked } });
		} else {
			changeGroupTargetDetail(name, checked);
		}
	};
	const handleCloseGroupTargetModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			const { name, checked } = groupTargetChangeProps?.details || {};
			changeGroupTargetDetail(name, checked);
			setGroupTargetChangeProps({ open: false, message: '', details: {} });
		} else {
			setGroupTargetChangeProps({ open: false, message: '', details: {} });
		}
	};
	const getMetricDetails = () => {
		const { metricMasters } = okrMasterData;
		const selectedMetricId = keyAttribute?.metricId
			? keyAttribute.metricId
			: keyData?.metricId === 0
			? 5
			: keyData?.metricId;
		const selectedCurrencyId = keyAttribute?.currencyId ? keyAttribute.currencyId : keyData?.currencyId;
		let selectedCurrencyDetail: any = null;
		const selectedMetric =
			metricMasters && metricMasters.length
				? metricMasters.find((item: any) => item.metricId === selectedMetricId)
				: null;
		if (selectedMetric && selectedMetric?.name === 'Currency') {
			const { metricDataMaster } = selectedMetric;
			const selectedCurrency =
				metricDataMaster && metricDataMaster.length
					? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
					: null;
			selectedCurrencyDetail = selectedCurrency;
		}
		return { selectedMetric, selectedCurrencyDetail };
	};
	const getMetricCurrencyIconString = (value: any) => {
		let component: string = '';
		if (selectedMetricDetail?.name === 'Currency') {
			component = (selectedCurrencyData?.symbol ? selectedCurrencyData?.symbol : 'Dollar') + '' + value;
		} else if (selectedMetricDetail?.name === 'Percentage') {
			component = value + '%';
		} else if (selectedMetricDetail?.name === 'Numbers') {
			component = '#' + value;
		} else if (selectedMetricDetail?.metricId === 4 || selectedMetricDetail?.metricId === 5) {
			component = selectedMetricDetail.description + ' ' + value;
		}
		return component;
	};
	const getMetricCurrencyIcon = (item: any) => {
		let component: React.ReactNode = <></>;
		if (selectedMetricDetail.name === 'Currency') {
			component = <>{getCurrencyIcon(selectedCurrencyData?.name ? selectedCurrencyData.name : 'Dollar')}</>;
		} else if (selectedMetricDetail.name === 'Percentage') {
			component = (
				<>
					<PercentageIcon />
				</>
			);
		} else if (selectedMetricDetail.name === 'Numbers') {
			component = (
				<>
					<NumbersIcon />
				</>
			);
		} else if (selectedMetricDetail.metricId === 4 || selectedMetricDetail.metricId === 5) {
			component = <>{`${selectedMetricDetail.description}`}</>;
		}

		return component;
	};

	//callouts
	const refcontributorList: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel('krContributorDrawer');
		}, 500);

		return () => {
			props.updateCalloutPanel && props.updateCalloutPanel('');
		};
	}, []);
	useEffect(() => {
		if (
			getLocalStorageItem('showCallouts') &&
			refcontributorList.current &&
			props.activeOnboardingPanel === 'krContributorDrawer' &&
			getLocalStorageItem('krContributorDrawercalloutDone') === null
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(refcontributorList.current);
				setCallOut && setCallOut(calloutsData[9]);
			}, 800);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [props.activeOnboardingPanel]);

	const updateValue = (key: string, keyValue: any, keyIndex: number) => {
		let list = [...updatedContributorList];
		list[keyIndex][key] = checkNumber(key, keyValue, selectedCurrencyData);
		delete list[keyIndex].targetErrorText;
		delete list[keyIndex].startErrorText;
		setUpdatedContributorList(list);
	};
	// const userDetail: any = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	return (
		<>
			<Box className='drawer-inner-content'>
				<Box className='drawer-content-subpanel okr-drawer-form-area'>
					<Box className='contributors-list-panel' {...({ ref: refcontributorList } as any)}>
						{(keyAttribute?.importedId === Enums.ZERO && keyAttribute?.id?.includes('-')) ||
						(keyAttribute?.importedId === Enums.ZERO && !keyAttribute?.id?.includes('-') && keyAttribute?.goalKeyId) ? (
							<Box className='group-target-switch'>
								<FormControlLabel
									control={
										<Switch
											checked={isGroupTarget || false}
											name='isGroupTarget'
											inputProps={{ 'aria-label': 'controlled' }}
											onClick={handleGroupTargetClick}
											disabled={isLocked}
										/>
									}
									label={t('groupTargetLabel')}
									labelPlacement='end'
									className='toggle-label'
								/>
							</Box>
						) : (
							<></>
						)}
						<Box className='target-assignment-msg'>
							{/* <Typography variant='h5' className='under-assigned'>
							{t('underAssigned')}
						</Typography> */}
							{!isGroupTarget && keyAttribute?.keyValueDeviation > 0 && (
								<Typography variant='h5' className='over-assigned'>
									{t('overAssigned')} {getMetricCurrencyIconString(keyAttribute?.keyValueDeviation)}
								</Typography>
							)}
						</Box>
						{filteredConList && filteredConList.length ? (
							<Box
								className={`kr-contributor-listing ${isGroupTarget ? 'group-contributor-listing' : ''}`}
								onClick={() => {}}
							>
								<Box className='kr-contributor-list-head'>
									<List component='ul' disablePadding>
										<ListItem className='user-col1'>
											<Typography
												variant='body2'
												className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
												onClick={(e) => sortContributorsList(e, 'firstName')}
											>
												<span className='sortable-label'>
													<span className='txt-wrap'>{t('nameLabel')}</span> <SortIcon />
												</span>
											</Typography>
										</ListItem>
										{isGroupTarget ? null : (
											<ListItem className='user-col2'>
												<Typography
													variant='body2'
													className={sortingColumn === 'targetValue' ? `sort-title ${sortOrder}` : 'sort-title asc'}
													onClick={(e) => sortContributorsList(e, 'targetValue')}
												>
													<span className='sortable-label'>
														<span className='txt-wrap'>{t('startTarget')}</span>
														<SortIcon />
													</span>
												</Typography>
											</ListItem>
										)}
										<ListItem className='user-col3'>
											<Typography
												variant='body2'
												className={sortingColumn === 'dueDate' ? `sort-title ${sortOrder}` : 'sort-title asc'}
												onClick={(e) => sortContributorsList(e, 'dueDate')}
											>
												<span className='sortable-label'>
													<span className='txt-wrap'>{t('dueDate')}</span>
													<SortIcon />
												</span>
											</Typography>
										</ListItem>
										{/* <ListItem className='user-col4'></ListItem> */}
									</List>
								</Box>
								<Box className='kr-contributor-list-content'>
									{filteredConList.length ? (
										filteredConList.map((item: any, itemIndex: number) => (
											<Box
												key={item.employeeId}
												className={`kr-contributor-list-row`}
												id={`kr-contributor-list-${item.employeeId}`}
											>
												<Box
													className={`contributor-expand-collapse-view ${item.isActive === false ? 'disabled' : ''}`}
												>
													<List component='ul'>
														<ListItem className='user-col1'>
															<Box className='user-info'>
																{item.isActive === false ? (
																	<Tooltip
																		placement='top'
																		arrow
																		classes={{ popper: 'tooltip-alert' }}
																		title={t('pendingTargetDistributionMsg')}
																	>
																		<Badge
																			className={'badge-icon okr-badge-icon'}
																			overlap='rectangular'
																			badgeContent={<AlertInfoIcon />}
																		></Badge>
																	</Tooltip>
																) : (
																	<></>
																)}
																<UserAvatarGroup
																	contributorDetails={[{ ...item }]}
																	avatarClassName=''
																	isButtonVisible={false}
																	isButtonDisable={true}
																	avatarGroupClassName='contributor-user-avatar'
																	max={1}
																/>
																<Box className='user-info-details'>
																	{item?.fullName ? (
																		item?.fullName.length > 22 && !isGroupTarget ? (
																			<Tooltip title={item?.fullName} arrow>
																				<Typography variant='h4'>{item.fullName.substring(0, 22) + '...'}</Typography>
																			</Tooltip>
																		) : (
																			<Typography variant='h4'>{item.fullName}</Typography>
																		)
																	) : item?.firstName ? (
																		(item?.firstName + item?.lastName).length > 22 && !isGroupTarget ? (
																			<Tooltip title={item?.firstName + ' ' + item?.lastName} arrow>
																				<Typography variant='h4'>
																					{(item?.firstName + ' ' + item?.lastName).substring(0, 22)}...
																				</Typography>
																			</Tooltip>
																		) : (
																			<Typography variant='h4'>{`${item?.firstName} ${item?.lastName}`}</Typography>
																		)
																	) : (
																		<></>
																	)}
																	{/* <Typography variant='h4'>
																	{item.fullName ? item.fullName : `${item?.firstName} ${item?.lastName}`}
																</Typography> */}
																	{!item.isKrOwner &&
																		(item?.emailId && item?.emailId.length > 30 && !isGroupTarget ? (
																			<Tooltip title={item.emailId} arrow>
																				<Typography variant='subtitle2'>
																					{item.emailId.substring(0, 30) + '...'}
																				</Typography>
																			</Tooltip>
																		) : (
																			<Typography variant='subtitle2'>{item.emailId}</Typography>
																		))}
																	{/* {item?.designation && item?.designation.length > 25 ? (
																	<Tooltip title={item.designation} arrow>
																		<Typography variant='subtitle2'>
																			{item.designation.substring(0, 25) + '...'}
																		</Typography>
																	</Tooltip>
																) : (
																	<Typography variant='subtitle2'>{item.designation}</Typography>
																)} */}
																	{item.isKrOwner ? (
																		<>
																			{userDetail?.emailId && userDetail?.emailId.length > 25 && !isGroupTarget ? (
																				<Tooltip title={userDetail.emailId} arrow>
																					<Typography variant='subtitle2'>
																						{userDetail.emailId.substring(0, 25) + '...'}
																					</Typography>
																				</Tooltip>
																			) : (
																				<Typography variant='subtitle2'>{userDetail.emailId}</Typography>
																			)}
																			<Box className='owner-tag'>
																				<span className='owner-chip'>{t('mySelfTab')}</span>
																			</Box>
																		</>
																	) : (
																		item &&
																		item?.teamName &&
																		item?.isExternal && (
																			<Box className='teams-chips' id={`kr-contributor-team-${item.employeeId}`}>
																				<Tooltip
																					title={
																						!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																							? t('externalContributorTooltip')
																							: ''
																					}
																					arrow
																				>
																					<span className='external-link'>
																						{' '}
																						<ExternalContributorIcon />{' '}
																					</span>
																				</Tooltip>
																			</Box>
																		)
																	)}
																	{/* {item && item.teamName && (
																	<Box className='teams-chips'>
																		{item?.isExternal && (
																			<Tooltip
																				title={
																					!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																						? t('externalContributorTooltip')
																						: ''
																				}
																				arrow
																			>
																				<span className='external-link'>
																					{' '}
																					<ExternalContributorIcon />{' '}
																				</span>
																			</Tooltip>
																		)}
																		<Tooltip
																			title={
																				!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																					? item.teamName
																					: ''
																			}
																			arrow
																		>
																			<Chip
																				className='chip'
																				style={{
																					backgroundColor: getRandomBackgroundColor(item?.backGroundColorCode),
																					color: getRandomColor(item?.colorCode),
																				}}
																				label={item.teamName}
																			/>
																		</Tooltip>
																	</Box>
																)} */}
																</Box>
															</Box>
														</ListItem>
														{isGroupTarget ? null : (
															<ListItem className='user-col2'>
																{/* added assign target input field start here*/}
																<Box className='manage-contributor-metric-field'>
																	<Box className='kr-metrics-fileds kr-metrics-full-width'>
																		<Box className='kr-metrics-fileds-inner'>
																			<Box display='flex'>
																				<TextField
																					className='current-value helperText-bottom'
																					id='current-value'
																					placeholder={'0'}
																					value={item.startValue}
																					fullWidth
																					onChange={(event: any) => {
																						updateValue('startValue', event.target.value, itemIndex);
																					}}
																					inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
																					InputProps={{
																						startAdornment: (
																							<InputAdornment position='start'>
																								{getMetricCurrencyIcon(item)}
																							</InputAdornment>
																						),
																					}}
																					error={item.startErrorText}
																					helperText={
																						item.startErrorText && (
																							<>
																								<ErrorIcon /> {item.startErrorText}
																							</>
																						)
																					}
																					disabled={isLocked || item.isActive === false ? true : false}
																				/>
																				<Box className='matrics-field-seprator' component='span'>
																					{'/'}
																				</Box>
																				<TextField
																					className='target-value helperText-bottom'
																					id='target-value'
																					placeholder={'0'}
																					value={item.targetValue}
																					fullWidth
																					onChange={(event: any) => {
																						updateValue('targetValue', event.target.value, itemIndex);
																					}}
																					inputProps={{}}
																					InputProps={{
																						startAdornment: (
																							<InputAdornment position='start'>
																								{getMetricCurrencyIcon(item)}
																							</InputAdornment>
																						),
																					}}
																					error={item.targetErrorText}
																					helperText={
																						item.targetErrorText && (
																							<>
																								<ErrorIcon /> {item.targetErrorText}
																							</>
																						)
																					}
																					disabled={isLocked || item.isActive === false ? true : false}
																				/>
																			</Box>
																		</Box>
																	</Box>
																</Box>
																{/* added assign target input field start here*/}
																{/* <Tooltip
															title={
																!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																	? t('metricNotChangeMessage')
																	: ''
															}
															arrow
														>
															<Typography variant='body1'>{getMetricCurrencyIcon(item)}</Typography>
														</Tooltip> */}
															</ListItem>
														)}
														<ListItem className='user-col3'>
															<Box className='date'>
																<Typography variant='body1'>
																	{getMonthDate(item?.dueDate || objectiveEndDate)}
																</Typography>
																<Typography variant='h6'>
																	{props.goalDetail && props.goalDetail?.okr?.goalTypeId
																		? props.goalDetail.okr.dueCycle
																		: props.userSelectedCycle?.symbol + ', ' + props.userSelectedCycle?.year}
																</Typography>
															</Box>
															{!isLocked && !item.isKrOwner && (
																<OKRButton
																	className='delete-btn'
																	id={`kr-contributor-delete-${item.employeeId}`}
																	disabled={false}
																	icon={<DeleteIcon />}
																	handleClick={(event: any) =>
																		onDeleteContributor(event, item, 'Delete', isGroupTarget)
																	}
																	title={
																		!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('delete') : ''
																	}
																/>
															)}
														</ListItem>

														{/* <ListItem className='user-col4'>
														<Box className='btn-wrap'>
															<OKRButton
																className='edit-btn'
																id={`kr-contributor-edit-${item.employeeId}`}
																disabled={false}
																icon={<EditIcon />}
																handleClick={(event: any) => onEditContributor(event, item, 'Edit')}
																title={
																	!getLocalStorageItem('showCallouts') || anchorElCallouts === null
																		? t('editLabel')
																		: ''
																}
															/>
															<OKRButton
																className='delete-btn'
																id={`kr-contributor-delete-${item.employeeId}`}
																disabled={false}
																icon={<DeleteIcon />}
																handleClick={(event: any) => onDeleteContributor(event, item, 'Delete')}
																title={
																	!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('delete') : ''
																}
															/>
														</Box>
													</ListItem> */}
													</List>
												</Box>
											</Box>
										))
									) : (
										<></>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</Box>
			</Box>
			{groupTargetChangeProps && groupTargetChangeProps?.open && (
				<AlertDialog
					module='myGoal'
					message={groupTargetChangeProps?.message || t('groupTargetChangeMessage')}
					handleCloseModal={handleCloseGroupTargetModal}
					modalOpen={groupTargetChangeProps?.open || false}
				/>
			)}
		</>
	);
};
