import { getRequest, postRequest } from '../config/auth';
import {
	GET_EMPLOYEE_ASSESSMENT,
	GET_SELF_ASSESSMENT,
	SAVE_EMPLOYEE_ASSESSMENT,
	SAVE_MULTIPLE_EMPLOYEE_ASSESSMENT,
	GET_ASSESSMENT_DATA,
} from '../config/api-url';

export const getSelfAssessmentAPI = (data) => getRequest(`${GET_SELF_ASSESSMENT}?${data}`, data);
export const getEmployeeAssessmentDetailsAPI = (data) => getRequest(`${GET_EMPLOYEE_ASSESSMENT}?${data}`, data);
export const saveAssessmentFormAPI = (data) => postRequest(`${SAVE_EMPLOYEE_ASSESSMENT}`, data);
export const saveMultipleAssessmentFormAPI = (data) => postRequest(`${SAVE_MULTIPLE_EMPLOYEE_ASSESSMENT}`, data);
export const getAllAssessmentData = (data) => getRequest(`${GET_ASSESSMENT_DATA}?${data}`, data);
