import React, { Fragment, useState, useEffect } from 'react';
import { Box, Chip, List, ListItem, Typography, Tooltip, ListItemAvatar, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CloneIcon, DownloadIcon, EditIcon, SortingIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../../Common/OKRButton';
// import { NoRecord } from '../../CommonComponent/NoRecord';
import { Visibility } from '../../../../../../config/svg/ProfileSvg';
import { DeleteIcon, KebabIcon } from '../../../../../../config/svg/CommonSvg';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteGoalForm,
	exportEachGoalFormReport,
	getPerformanceGoalTabCount,
	goalFormList,
	performanceGoalFormDeleteReport,
	ratingFormDelete,
	reloadGoalFormList,
	setHighlightGoalFormId,
	setPaginationData,
} from '../../../../../../action/adminSettings';
import { Enums } from '../../../../../../config/enums';
import { getFullName, getMonthDateYear } from '../../../../../../config/utils';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../../../CommonComponent/DialogComponent';
import { NoRecord } from '../../../../CommonComponent/NoRecord';
import NoDataImg from '../../../../../../images/my-goal-bg.svg';
import CheckBoxAlertDialog from '../../../../../Common/CheckBoxDialog';
import { PopperMenu } from '../../../../CommonComponent/PopperMenu';

export const GoalsFormsList: React.FC<any> = (props: any) => {
	const {
		setLoader,
		sortAssessmentListOrder,
		setSortAssessmentListOrder,
		handleGoalFormAction,
		setHighlightRecordId,
		goalCycleSearchTextArray,
		pageIndex,
		pageSize,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const maxLen = 15;
	const [goalFormListData, setGoalFormListData] = useState<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [sortingData, setSortingData] = useState<any>({ column: '', order: '' });
	const { highlightGoalFormId, reloadGoalFormListData } = useSelector((state: any) => state?.adminSettingReducer);
	const [checkBoxListData, setCheckBoxListData] = useState<any[]>([
		{ id: 1, name: t('goalFormDailogCheckbox1') },
		{ id: 2, name: t('goalFormDailogCheckbox2') },
		{ id: 3, name: t('goalFormDailogCheckbox3'), onlyAllowed: true },
	]);
	const [anchorEl, setAnchorEl] = useState(null);

	const [goalSubmitPopup, setGoalSubmitPopup] = useState<any>({
		open: false,
		message: '',
		data: {},
	});
	useEffect(() => {
		getGoalFormListDetails({
			finder: goalCycleSearchTextArray,
			pageIndex: pageIndex,
			sortingText: sortingData.column,
			order: sortingData.order,
			pageSize: pageSize,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleSearchTextArray, pageIndex, pageSize]);

	useEffect(() => {
		if (reloadGoalFormListData === true) {
			getGoalFormListDetails({
				finder: '',
				pageIndex: 1,
				sortingText: '',
				order: '',
				pageSize: pageSize,
			});
			dispatch(getPerformanceGoalTabCount());
			dispatch(reloadGoalFormList(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadGoalFormListData]);

	useEffect(() => {
		if (goalFormListData && goalFormListData?.records && goalFormListData?.records?.length) {
			setTimeout(() => {
				dispatch(setHighlightGoalFormId(null));
			}, 10000);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalFormListData]);

	const getGoalFormListDetails = async (requestPayload: any) => {
		setLoader(true);
		const response: any = await dispatch(goalFormList(requestPayload));
		if (response) {
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				let records: any[] = [];
				if (entity) {
					records = entity.records;
				}
				const updatedData = { records: records };
				setGoalFormListData(updatedData);
				let responseData = { ...entity };
				delete responseData.records;
				dispatch(setPaginationData(responseData));
				//setTotalAssessmentListUser(entity && entity.totalRecords);
				setLoader(false);
			} else {
				setLoader(false);
			}
		}
		setLoader(false);
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingData.column) {
			newSortOrder = sortingData.order === 'asc' ? 'desc' : 'asc';
		}
		setSortingData({ column: column, order: newSortOrder });

		getGoalFormListDetails({
			finder: goalCycleSearchTextArray,
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			pageSize: pageSize,
		});
	};

	const handleCloseModal = async (e: any, type: any) => {
		//e.preventDefault();
		if (type === 1) {
			callDeleteGoalFormAPI();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleDeleteDownloadLink = async (performanceGoalFormId: any) => {
		try {
			const response: any = await dispatch(performanceGoalFormDeleteReport(`formId=${performanceGoalFormId}`));
			if (response) {
				const defaultName = 'linkedUserList.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			// setListLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteRecordClick = (e: any, record: any, type: string) => {
		e.preventDefault();
		e.stopPropagation();
		if (record && record.performanceGoalFormId) {
			if (type === 'Delete') {
				setModalProps({
					open: true,
					message:
						record.status === 1 || !record.isFormMappedWithEmployee ? (
							t('goalDraftDelete')
						) : (
							<>
								{t('goalDeleteFormFirstPart')}
								<span onClick={() => handleDeleteDownloadLink(record.performanceGoalFormId)}>{t('downloadLink')}.</span>
								{t('goalDeleteFormLastPart')}
							</>
						),
					details: record,
				});
			} else if (type === 'Clone') {
				handleGoalFormAction({ ...record, name: 'Cloned ' + record?.name }, true, type);
			} else if (type === 'Edit') {
				if (record.status === 1 || !record.isFormMappedWithEmployee) {
					handleGoalFormAction(record, true, type);
				} else {
					setGoalSubmitPopup({
						open: true,
						message: t('goalFormEditDialogContent'),
						data: { record, actionType: true, type },
					});
				}
			} else if (type === 'View') {
				// handleGoalFormAction({}, true, type);
			}
		}
	};

	const handleGoalSubmitPopup = (e: any, type: any, data: any) => {
		e.preventDefault();
		if (type === 1) {
			let formData = { ...goalSubmitPopup.data.record };
			if (data[1] === true) {
				formData.formMappedWithGoalCycleStatusSubmitted = false;
				formData.formMappedWithGoalCycleStatusOngoing = true;
			}
			if (data[2] === true) {
				formData.formMappedWithGoalCycleStatusSubmitted = true;
				formData.formMappedWithGoalCycleStatusOngoing = false;
			}
			if (data[3] === true) {
				formData.formMappedWithGoalCycleStatusSubmitted = false;
				formData.formMappedWithGoalCycleStatusOngoing = false;
			}
			handleGoalFormAction({ ...formData }, goalSubmitPopup.data.actionType, goalSubmitPopup.data.type);
		}
		setGoalSubmitPopup({ open: false, message: '', data: {} });
	};

	const callDeleteGoalFormAPI = async () => {
		if (modalProps.details && modalProps.details.performanceGoalFormId) {
			setLoader(true);
			const queryParams = `performanceGoalFormId=${modalProps.details.performanceGoalFormId}`;
			setModalProps({ open: false, message: '', details: '' });
			const response: any = await dispatch(deleteGoalForm(queryParams));
			if (response) {
				if (response && response?.data && response.data.status === 200) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Goal form deleted successfully', {
						variant: 'success',
						autoHideDuration: 3000,
					});
					setLoader(false);
					getGoalFormListDetails({
						finder: goalCycleSearchTextArray,
						pageIndex: pageIndex,
						sortingText: sortingData.column,
						order: sortingData.order,
						pageSize: pageSize,
					});
					dispatch(getPerformanceGoalTabCount());
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while deleting goal form', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setLoader(false);
			}
		}
	};

	const handleGoalFormMenuClose = () => {
		setAnchorEl(null);
	};

	const handleExportGoalFormReport = async (performanceGoalFormId: any) => {
		try {
			const response: any = await dispatch(exportEachGoalFormReport(`formId=${performanceGoalFormId}`));
			if (response) {
				const defaultName = 'goalForm.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
				const matches = filenameRegex.exec(response.headers['content-disposition']);
				link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
			// setListLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Box className='goal-forms-listing'>
			{goalFormListData && goalFormListData?.records && goalFormListData?.records.length > 0 ? (
				<Box className='okr-list-panel-head'>
					<List component='ul' disablePadding>
						<ListItem className='goal-forms-col1'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'name')}
								className={sortingData.column === 'name' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>
										{t('goalFormName')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-forms-col2'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'status')}
								className={sortingData.column === 'status' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('statusLabel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-forms-col3'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'totalCategoryCount')}
								className={
									sortingData.column === 'totalCategoryCount' ? `sort-title ${sortingData.order}` : 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('totalCategories')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-forms-col4'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'totalGoalCount')}
								className={
									sortingData.column === 'totalGoalCount' ? `sort-title ${sortingData.order}` : 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('totalGoals')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-forms-col5'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'createdOn')}
								className={sortingData.column === 'createdOn' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('submittedDate')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-forms-col6'>
							<Typography
								variant='body2'
								onClick={(e) => sortUserList(e, 'updatedOn')}
								className={sortingData.column === 'updatedOn' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('lastUpdatedDate')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
					</List>
				</Box>
			) : (
				<></>
			)}
			{goalFormListData && goalFormListData?.records && goalFormListData?.records.length > 0 ? (
				goalFormListData?.records.map((record: any, index: number) => {
					const creatureName = getFullName(record?.submittedByDetails);
					const userFullName = getFullName(record?.updatedByDetails);
					return (
						<Box
							className={`goal-forms-list-content ${
								highlightGoalFormId === record?.performanceGoalFormId ? 'heighlight-row' : ''
							}`}
						>
							<List component='ul' disablePadding key={`review-form-list-${record?.ratingFormId}`}>
								<ListItem className='goal-forms-col1'>
									{record?.name && record?.name.length > 35 ? (
										<Tooltip arrow title={record?.name || ''}>
											<Typography>{record?.name.substring(0, 35)}...</Typography>
										</Tooltip>
									) : (
										<Typography>{record?.name || ''}</Typography>
									)}
									{
										<Box className='goal-forms-list-actions'>
											<OKRButton
												handleClick={(event: any) => handleDeleteRecordClick(event, record, 'Clone')}
												icon={<CloneIcon />}
												title={t('clone')}
											/>
											{record?.status === Enums.THREE ? (
												<OKRButton
													handleClick={(event: any) => handleDeleteRecordClick(event, record, 'View')}
													icon={<Visibility />}
													title={'View'}
												/>
											) : (
												<OKRButton
													handleClick={(event: any) => handleDeleteRecordClick(event, record, 'Edit')}
													icon={<EditIcon />}
													title={t('editLabel')}
												/>
											)}

											<PopperMenu
												popperClassName={'popper-menu feedback-type-menu'}
												className={`type-more-menu`}
												anchorEl={anchorEl}
												handleClose={handleGoalFormMenuClose}
												includeKebabMenu={true}
												btnId={`type-more-btn`}
												{...props}
												children={
													<>
														<ListItem
															className='goal-form-download'
															onClick={() => handleExportGoalFormReport(record.performanceGoalFormId)}
														>
															<ListItemAvatar>
																<DownloadIcon />
															</ListItemAvatar>
															<ListItemText primary={t('download')} />
														</ListItem>
														<ListItem
															className='goal-form-delete'
															onClick={(event: any) => handleDeleteRecordClick(event, record, 'Delete')}
														>
															<ListItemAvatar>
																<DeleteIcon />
															</ListItemAvatar>
															<ListItemText primary={t('delete')} />
														</ListItem>
													</>
												}
											/>
										</Box>
									}
								</ListItem>
								<ListItem className='goal-forms-col2'>
									{record?.status === 2 ? (
										<Chip className='goal-forms-chip' label={t('publishedLevel')} />
									) : record?.status === 1 ? (
										<Chip className='goal-forms-chip draft-chip' label={t('draftLevel')} />
									) : (
										<></>
									)}
								</ListItem>
								<ListItem className='goal-forms-col3'>
									<Typography>{record?.totalCategoryCount}</Typography>
								</ListItem>
								<ListItem className='goal-forms-col4'>
									<Typography>{record?.totalGoalCount}</Typography>
								</ListItem>
								<ListItem className='goal-forms-col5'>
									<Box>
										<Typography>{record?.submittedDate ? getMonthDateYear(record?.submittedDate) : ''}</Typography>
										{/* <Typography variant='body2'>{getFullName(record?.createdByDetails)}</Typography> */}
										{creatureName && creatureName.length > maxLen ? (
											<Tooltip arrow title={creatureName} aria-label='Email'>
												<Typography variant='body2'>{creatureName.substring(0, maxLen)}...</Typography>
											</Tooltip>
										) : (
											<Typography variant='body2'>{creatureName}</Typography>
										)}
									</Box>
								</ListItem>
								<ListItem className='goal-forms-col6'>
									<Box className=''>
										<Typography>{getMonthDateYear(record?.updatedOn)}</Typography>
										{userFullName && userFullName.length > maxLen ? (
											<Tooltip arrow title={userFullName} aria-label='Email'>
												<Typography variant='body2'>{userFullName.substring(0, maxLen)}...</Typography>
											</Tooltip>
										) : (
											<Typography variant='body2'>{userFullName}</Typography>
										)}
									</Box>
								</ListItem>
							</List>
						</Box>
					);
				})
			) : !goalCycleSearchTextArray || goalCycleSearchTextArray.length === 0 ? (
				<NoRecord
					noRecordMessage={t('noGoalFormsCreatedYet')}
					subHead={t('clickPlusIconToAddForms')}
					noDataImgWidth={'516'}
					noImg={NoDataImg}
					{...props}
				/>
			) : (
				<NoRecord
					noRecordMessage={t('noRecordPip')}
					subHead={t('noRecordPipText')}
					noDataImgWidth={'516'}
					noImg={NoDataImg}
					{...props}
				/>
			)}

			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					//headingText={t('deleteAssessmentForm')}
					subHeading={modalProps.message}
				/>
			)}

			{goalSubmitPopup && goalSubmitPopup.open && (
				<CheckBoxAlertDialog
					module='goalCycleCreate'
					isCancel={false}
					message={goalSubmitPopup?.message || ''}
					handleCloseModal={handleGoalSubmitPopup}
					modalOpen={goalSubmitPopup.open}
					checkBoxListData={checkBoxListData}
				/>
			)}
		</Box>
	);
};
