import React, { useState } from 'react';
import { Box, Chip, InputAdornment, List, ListItem, TextField, Tooltip } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { SearchIcon } from '../../../config/svg/CommonSvg';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';

export const TaskSearch: React.FC<any> = (props: any) => {
	const { searchTextArray, setSearchTextArray, handleSearchText } = props;
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState('');

	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				setSearchTextArray(e.target.value);
				handleSearchText(e.target.value);
			}
			setSearchValue('');
		}
	};
	const handleSearchClicked = (e: any) => {
		setSearchValue(e.target.value);
	};

	const clearSearch = () => {
		setSearchTextArray('');
		handleSearchText('');
	};
	return (
		<Box className='user-search'>
			<TextField
				className='search-text-field'
				id='user-src-field'
				placeholder={t('searchText')}
				fullWidth
				value={searchValue}
				onChange={(e) => handleSearchClicked(e)}
				onKeyDown={(e) => searchClicked(e)}
				InputProps={{
					startAdornment: (
						<InputAdornment position='start'>
							<SearchIcon />
						</InputAdornment>
					),
				}}
			/>
			{searchTextArray && (
				<Box className='user-search-chip'>
					<List>
						<ListItem>
							{searchTextArray.length > 20 ? (
								<Tooltip title={searchTextArray} arrow>
									<Chip
										label={searchTextArray.substring(0, 20) + '...'}
										onDelete={clearSearch}
										deleteIcon={
											<span>
												<CrossIcon />
											</span>
										}
									/>
								</Tooltip>
							) : (
								<Chip
									label={searchTextArray}
									onDelete={clearSearch}
									deleteIcon={
										<span>
											<CrossIcon />
										</span>
									}
								/>
							)}
						</ListItem>
					</List>
				</Box>
			)}
		</Box>
	);
};
