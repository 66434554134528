import React, { useState, useRef, useEffect } from 'react';
import lodash from 'lodash';
import { OKRButton } from '../Common/OKRButton';
import { Box } from '@material-ui/core';
import { USER_VIEW } from '../../config/app-url';
import { setLocalStorageItem } from '../../services/StorageService';

export const ReadMore = (props) => {
	const [clamped, setClamped] = useState(true);
	const [showButton, setShowButton] = useState(true);
	const containerRef = useRef(null);
	const handleClick = () => setClamped(!clamped);

	useEffect(() => {
		const hasClamping = (el) => {
			const { clientHeight, scrollHeight } = el;
			return clientHeight !== scrollHeight;
		};

		const checkButtonAvailability = () => {
			if (containerRef.current) {
				// Save current state to reapply later if necessary.
				const hadClampClass = containerRef.current.classList.contains('truncate');
				// Make sure that CSS clamping is applied if aplicable.
				if (!hadClampClass) containerRef.current.classList.add('truncate');
				// Check for clamping and show or hide button accordingly.
				setShowButton(hasClamping(containerRef.current));
				// Sync clamping with local state.
				if (!hadClampClass) containerRef.current.classList.remove('truncate');
			}
		};

		const debouncedCheck = lodash.debounce(checkButtonAvailability, 50);
		checkButtonAvailability();
		window.addEventListener('load', debouncedCheck);
		window.addEventListener('resize', debouncedCheck);
		return () => {
			window.removeEventListener('resize', debouncedCheck);
			window.removeEventListener('load', debouncedCheck);
		};
	}, [containerRef, props.text]);

	const redirectPeopleTag = (e) => {
		const hasClass = e.target.classList.contains('tagged-name');
		if (hasClass) {
			let taggedUser = e.target.getAttribute('rel');
			props.getUserDetails(`empId=${taggedUser}`).then((resp) => {
				if (resp && resp.status === 200) {
					if (resp.data.entityList && resp.data.entityList.length > 0) {
						setLocalStorageItem('currentUser', JSON.stringify(resp.data.entityList[0]));
						props.history.push(USER_VIEW);
						props.updateRoute(USER_VIEW);
					}
				}
			});
		}
	};
	return (
		<>
			{props.isHtmlContent ? (
				<Box
					ref={containerRef}
					className={`long-text ${clamped ? 'truncate' : ''}`}
					dangerouslySetInnerHTML={{ __html: props.text }}
					onClick={(e) => {
						redirectPeopleTag(e);
					}}
				></Box>
			) : (
				<Box ref={containerRef} className={`long-text ${clamped ? 'truncate' : ''}`}>
					{props.text}
				</Box>
			)}

			{showButton && (
				<OKRButton className='btn-link' handleClick={handleClick} text={`Read ${clamped ? 'more' : 'less'}`} />
			)}
		</>
	);
};
