import { getRequest, deleteRequest, putRequest } from '../config/auth';
import { NOTIFICATION, UNREAD_NOTIFICATION, NOTIFICATION_ALL_LISTING } from '../config/api-url';

export const userUnreadNotificationAPI = (data) => getRequest(`${UNREAD_NOTIFICATION}?${data}&AppId=3`, '');
export const readSingleNotificationAPI = (data) => putRequest(`${NOTIFICATION}/${data}`, data);
export const deleteNotificationAPI = (data) => deleteRequest(`${NOTIFICATION}/${data}`, data);
// New API format
export const fetchNotificationForTabsAPI = (data) => getRequest(`${NOTIFICATION_ALL_LISTING}?${data}`, '');
export const readNotificationAPI = (data, requestBody) => putRequest(`${NOTIFICATION}?${data}`, requestBody);
export const deleteMultiNotificationAPI = (data, requestBody) => deleteRequest(`${NOTIFICATION}?${data}`, requestBody);
