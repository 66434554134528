import React, { Fragment, useState } from 'react';
import {
	ClickAwayListener,
	Grow,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from '@material-ui/core';
import { KebabIcon, NudgeTeamIcon, ViewOkrIcon } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import Feedback from '../../Feedback';
import { getUserDetails } from '../../../config/utils';
import { checkPermission } from '../../../config/rolePermission';
import { feedbackModule } from '../../Admin/AdminConstant';

export const TeamsPopperMenu: React.FC<any> = (props) => {
	const { t, currentUser, handleMenuAction, team, type, getPermissionResult } = props;
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [feedback, setFeedback] = useState<any>({ type: 0, open: false });
	const userDetail: any = getUserDetails();

	const handleToggle = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		setAnchorEl(anchorEl ? null : e.currentTarget);
		setOpenMenu(anchorEl ? false : true);
	};

	const handleClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setAnchorEl(null);
		setOpenMenu(false);
	};

	return (
		<Fragment>
			{!currentUser && type === 'teams' && checkPermission(getPermissionResult?.employeePermissions, feedbackModule) ? (
				<OKRButton
					id='team-menu-btn'
					title={t('moreOptions')}
					className='kebab-icon'
					icon={<KebabIcon />}
					handleClick={handleToggle}
				/>
			) : (
				<></>
			)}
			<Popper
				className='popper-menu-list okr-list-popper-menu'
				open={openMenu}
				anchorEl={anchorEl}
				placement='bottom-end'
				transition
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='menu-list-grow'>
									{!currentUser &&
										type === 'teams' && [
											checkPermission(getPermissionResult?.employeePermissions, feedbackModule) ? (
												<MenuItem
													key={'view-okr'}
													id={'view-okr-link'}
													onClick={(event: any) => {
														event.preventDefault();
														event.stopPropagation();
														setAnchorEl(null);
														setOpenMenu(false);
														handleMenuAction('viewOKR', team, type);
													}}
												>
													<ListItemIcon>
														<ViewOkrIcon />
													</ListItemIcon>
													<ListItemText primary={t('viewOkr')} />
												</MenuItem>
											) : (
												<></>
											),
										]}
									{/* {!currentUser && team?.keyCount && team?.keyCount > 0
										? [
												<MenuItem
													key={'nudge-team'}
													id={'nudge-team-link'}
													onClick={(event: any) => {
														event.preventDefault();
														event.stopPropagation();
														setAnchorEl(null);
														setOpenMenu(false);
														handleMenuAction('nudgeTeam', team, type);
													}}
												>
													<ListItemIcon>
														<NudgeTeamIcon />
													</ListItemIcon>
													<ListItemText primary={t('nudgeTeam')} />
												</MenuItem>,
										  ]
										: []} */}
									{/* {!currentUser &&
										isTeamsDashboard && [
											checkPermission(getPermissionResult?.employeePermissions, feedbackModule) ? (
												<MenuItem
													key={'req-feedback-okr'}
													onClick={(event) => {
														event.preventDefault();
														event.stopPropagation();
														setAnchorEl(null);
														setOpenMenu(false);
														setFeedback({ open: true, openGiveFeedback: false, reqType: 1 });
													}}
												>
													<ListItemIcon>
														<AskFeedbackIcon />
													</ListItemIcon>
													<ListItemText primary={t('requestFeedback')} />
												</MenuItem>
											) : (
												<></>
											),
										]} */}
									{/* {team.leaderEmployeeId === userDetail.employeeId && [
										checkPermission(getPermissionResult?.employeePermissions, feedbackModule) ? (
											<MenuItem
												key={'giveFeedBack-teams'}
												onClick={(event) => {
													event.preventDefault();
													event.stopPropagation();
													setAnchorEl(null);
													setOpenMenu(false);
													setFeedback({ openGiveFeedback: true, open: false, reqType: 1 });
												}}
											>
												<ListItemIcon>
													<GiveFeedbackIcon />
												</ListItemIcon>
												<ListItemText primary={t('giveFeedback')} />
											</MenuItem>
										) : (
											<></>
										),
									]} */}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
			{feedback.open && (
				<Feedback
					feedbackOld={true}
					openFeedbackType={feedback.reqType}
					objective={team}
					isTeamsFeedback={true}
					objId={feedback.goalId}
					setFeedback={setFeedback}
					userDetail={userDetail}
					type={'teamsOkrCard'}
					currentUser={currentUser}
					{...props}
				/>
			)}

			{feedback.openGiveFeedback && (
				<Feedback
					feedbackOld={true}
					openFeedbackType={feedback.reqType}
					objective={team}
					isTeamsFeedback={true}
					objId={feedback.goalId}
					isAskFeedback={false}
					setFeedback={setFeedback}
					userDetail={userDetail}
					type={'teamsOkrCardGiveFeedBack'}
					teamsGiveFeedBack={true}
					{...props}
				/>
			)}
		</Fragment>
	);
};
