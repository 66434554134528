import {
	createUpdateAdminLGSettingsAPI,
	getAdminLGSettingsAPI,
	removeAdminLGSettingsAPI,	
	getTemplateListAPI,
	getTemplateColumnListByTemplateIdAPI,
	addColumnInTemplateAPI,
	addNewTemplateAPI,
	getSampleTemplateByTemplateIdAPI,
	uploadTemplateDataAPI,
	getGeneratedLetterAPI,
	getUploadedLetterInformationByParametersAPI,
	getPreviewLetterAPI,
	deleteLetterInformationAPI,
	sendLetterEmailAPI,
	validateLetterAPI,
	scheduleLetterEmailAPI,
	downloadZipAPI,

} from '../services/LetterGeneration';

export const createUpdateAdminLGSettings = (payload) => {
	return () => {
		return createUpdateAdminLGSettingsAPI(payload);
	};
};

export const removeAdminLGSettings = (payload) => {
	return () => {
		return removeAdminLGSettingsAPI(payload);
	};
};

export const getAdminLGSettings = (payload) => {
	return () => {
		return getAdminLGSettingsAPI(payload);
	};
};

export const getTemplateList = (data) => {
	return () => {
		return getTemplateListAPI(data);
	};
};

export const getTemplateColumnListByTemplateId = (data) => {
	return () => {
		return getTemplateColumnListByTemplateIdAPI(data);
	};
};

export const addColumnInTemplate = (data) => {
	return () => {
		return addColumnInTemplateAPI(data);
	};
};

export const addNewTemplate = (data) => {
	return () => {
		return addNewTemplateAPI(data);
	};
};

export const getSampleTemplateByTemplateId = (data) => {
	return () => {
		return getSampleTemplateByTemplateIdAPI(data);
	};
};

export const uploadTemplateData = (data) => {
	return () => {
		return uploadTemplateDataAPI(data);
	};
};

export const getGeneratedLetter = (data) => {
	return () => {
		return getGeneratedLetterAPI(data);
	};
};

export const getUploadedLetterInformationByParameters = (data) => {
	return () => {
		return getUploadedLetterInformationByParametersAPI(data);
	};
};

export const getPreviewLetter = (data) => {
	return () => {
		return getPreviewLetterAPI(data);
	};
};

export const deleteLetterInformation = (data) => {
	return () => {
		return deleteLetterInformationAPI(data);
	};
};

export const sendLetterEmail = (data) => {
	return () => {
		return sendLetterEmailAPI(data);
	};
};


export const validateLetter = (data) => {
	return () => {
		return validateLetterAPI(data);
	};
};

export const scheduleLetterEmail = (data) => {
	return () => {
		return scheduleLetterEmailAPI(data);
	};
};

export const downloadZip = (data) => {
	return () => {
		return downloadZipAPI(data);
	};
};

