import React, { useState, useEffect } from 'react';
import { Box, Typography, Tooltip, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../styles/components/_chatBot.scss';
import { CommentIcon } from '../../config/svg/CommonSvg';
import { dateDifferenceInDays, getDueDays, getUserDetails } from '../../config/utils';
import moment from 'moment';
import NoHistoryImg from '../../images/add-user-img.svg';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import { useDispatch } from 'react-redux';
import { getHistoryChatBot } from '../../action/common';
import { DefaultOrigin } from '../../config/constant';
import { OKRButton } from '../Common/OKRButton';
import { AddIconSvg } from '../../config/svg/CommonSvgIcon';
import ChatBotResponseLoader from '../../images/chatbot-response-loader.svg';

export const ChatBotHistory: React.FC<any> = (props) => {
	const { showHistoryPrompt, historyToAdd, setHistoryToAdd, initiateNewChat } = props;
	const { t } = useTranslation();
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string>('');
	const dispatch = useDispatch();
	const [chatHistoryListData, setChatHistoryListData] = useState<any[]>([]);
	const userDetails = getUserDetails();

	useEffect(() => {
		chatBotResponse();
	}, []);
	useEffect(() => {
		if (historyToAdd) {
			let sortData: any = chatHistoryListData.map((element: any) => element);
			if (!sortData[0]) {
				sortData[0] = {};
				sortData[0].data = [];
			}

			const selectedChatIndex = sortData[0].data.findIndex(
				(chatHistoryRec: any) => chatHistoryRec.ChatId === historyToAdd?.ChatId
			);
			if (selectedChatIndex !== -1) {
				let chatData = { ...sortData[0].data[selectedChatIndex] };
				let existingChat = JSON.parse(chatData.Chat);
				existingChat = existingChat.concat(historyToAdd.chat);
				sortData[0].data[selectedChatIndex].Chat = JSON.stringify(existingChat);
			} else {
				historyToAdd.Chat = JSON.stringify(historyToAdd.chat);
				sortData[0].data = [historyToAdd].concat(sortData[0].data);
			}
			setChatHistoryListData(sortData);
			setHistoryToAdd(null);
		}
	}, [historyToAdd]);
	const sortHistoryData = (data: any[]) => {
		let sortData: any = [];
		const today = moment().startOf('day');
		data = [...data].sort((a, b) => b.HistoryId - a.HistoryId);
		data.forEach((element) => {
			const inputDate = moment(element.CreatedOn).startOf('day');
			let arrIndex = '1';
			if (inputDate.isSame(today, 'day')) {
				arrIndex = '0';
			}
			if (!sortData[arrIndex]) {
				sortData[arrIndex] = {};
				sortData[arrIndex].data = [];
			}
			sortData[arrIndex].data.push(element);
		});
		setChatHistoryListData(sortData);
	};
	const chatBotResponse = async () => {
		setLoading(true);
		let data: any = {
			tenantid: '6lnUyLOcUUylp3sxtddFA',
			subdomain: window.location.origin.indexOf('localhost') >= 0 ? DefaultOrigin : window.location.origin,
			useremailid: userDetails.emailId,
		};
		const response: any = await dispatch(getHistoryChatBot(data));
		if (response.status === 200) {
			setLoading(false);
			sortHistoryData(response.data);
		}
	};
	const addToUI = (historyData: any) => {
		if (historyData.Chat) {
			setSelectedId(historyData.ChatId);
			let jsonData = JSON.parse(historyData.Chat);
			showHistoryPrompt({ ...historyData, chatData: jsonData });
		}
	};
	return (
		<Box className='chat-bot-history-sec'>
			<Box className='chat-bot-history-sec-head'>
				<Typography variant='h2'>{t('historyLabel')}</Typography>
				<OKRButton
					className='btn-small-text'
					id={'new-chat-btn'}
					icon={<AddIconSvg />}
					text={t('newChat')}
					handleClick={() => {
						setSelectedId('');
						initiateNewChat();
					}}
				/>
			</Box>
			<Box className='chat-bot-history-block'>
				{chatHistoryListData.length > 0 ? (
					chatHistoryListData.map((historyData: any, index: number) => {
						return (
							<Box className='chat-bot-history-list'>
								<Typography variant='h5'>{index === 0 ? 'Today' : 'Past 7 days'}</Typography>
								<List component='ul' disablePadding>
									{historyData?.data.map((history: any) => {
										return (
											<ListItem
												className={`${history.ChatId === selectedId ? 'active' : ''} listclass`}
												onClick={() => {
													addToUI(history);
												}}
											>
												<ListItemIcon>
													<CommentIcon />
												</ListItemIcon>
												<Tooltip arrow title={history.ChatTitle}>
													<ListItemText>{history.ChatTitle}</ListItemText>
												</Tooltip>
											</ListItem>
										);
									})}
								</List>
							</Box>
						);
					})
				) : loading ? (
					<Box className='no-history-area'>
						<Box className='response-loader-img'>
							<AnimatedIcon
								className='chat-bot-response-loader'
								width='50'
								type='image/svg+xml'
								alt='Loading Response'
								imagePath={ChatBotResponseLoader}
							/>
						</Box>
					</Box>
				) : (
					<Box className='no-history-area'>
						<Typography variant='h4'>{t('noHistory')}</Typography>
						<Typography variant='body2'>{t('conversationHistoryMsg')}</Typography>
						<AnimatedIcon
							className='no-history-img'
							width='157'
							type='image/svg+xml'
							alt='No History'
							imagePath={NoHistoryImg}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
};
