import React, { Fragment, useEffect, useState } from 'react';
import { Box, Tabs, Tab } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Attributes } from './Attributes';
import { SearchSO } from './SearchSO';
import { LinkSO } from './LinkSO';

export const SoCreate: React.FC<any> = (props: any) => {
	const {
		checkAttributesValidation,
		soObjCreateTabSelected,
		setSoObjCreateTabSelected,
		searchSoTabSelected,
		setSearchSoTabSelected,
		disableLinkOkr,
		soPermissionDetails,
		totalSelectedCount,
		objectiveListPeopleTab,
		setObjectiveListPeopleTab,
		objectiveListMyTab,
		setObjectiveListMyTab,
	} = props;
	const { t } = useTranslation();
	const updateOKRList = (okrList: any) => {
		const updatedList = [...okrList].map((item: any) => {
			const krItems =
				item?.linkedKrResponses && item?.linkedKrResponses.length
					? item?.linkedKrResponses.map((record: any) => {
							return { ...record, okrDetails: item, isLinkTab: false };
					  })
					: [];
			return { ...item, isLinkTab: false, linkedKrResponses: krItems };
		});
		return updatedList;
	};
	const handleTabChange = (event: any, newValue: Number) => {
		if (soObjCreateTabSelected === 2 && newValue !== soObjCreateTabSelected) {
			const updatedMyTabList =
				objectiveListMyTab && objectiveListMyTab?.length ? updateOKRList([...objectiveListMyTab]) : objectiveListMyTab;
			const updatedPeopleTabList =
				objectiveListPeopleTab && objectiveListPeopleTab?.length
					? updateOKRList([...objectiveListPeopleTab])
					: objectiveListPeopleTab;
			setObjectiveListMyTab(updatedMyTabList);
			setObjectiveListPeopleTab(updatedPeopleTabList);
		}
		setSoObjCreateTabSelected(newValue);
	};
	const a11yProps = (index: number) => {
		return {
			id: `so-obj-create-tabpanel-${index}`,
			'aria-controls': `so-obj-create-tabpanel-${index}`,
		};
	};
	return (
		<Fragment>
			<Tabs
				className='okr-tabs drawer-tabs'
				value={soObjCreateTabSelected}
				onChange={handleTabChange}
				aria-label='Admin Tabs'
			>
				<Tab label={t('attributesLabel')} value={0} {...a11yProps(0)} />
				{soPermissionDetails?.isCreatorRights ? (
					<Tab label={t('srcLinkOkrs')} disabled={checkAttributesValidation('save')} value={1} {...a11yProps(1)} />
				) : null}
				<Tab
					label={
						<>
							{t('linkedOKRs')}
							{!totalSelectedCount ? '' : <>({totalSelectedCount})</>}
						</>
					}
					disabled={checkAttributesValidation('save') || !disableLinkOkr}
					value={2}
					{...a11yProps(2)}
				/>
			</Tabs>
			<Box className='drawer-tabs-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{soObjCreateTabSelected === 0 && (
						<>
							<Attributes {...props} />
						</>
					)}
					{soPermissionDetails?.isCreatorRights && soObjCreateTabSelected === 1 && (
						<>
							<SearchSO {...props} />
						</>
					)}
					{soObjCreateTabSelected === 2 && (
						<>
							<LinkSO {...props} />
						</>
					)}
				</form>
			</Box>
		</Fragment>
	);
};
