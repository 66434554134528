import { deleteRequest, getRequest, postRequest, putRequest ,postRequestZip} from '../config/auth';
import {
	LG_CREATE_PERMISSION,
	LG_PERMISSION_SETTINGS,
	LG_REMOVE_PERMISSION,
	GET_TEMPLATE_LIST,
	GET_TEMPLATE_COLUMN_LIST_BY_TEMPLATE_ID,
	ADD_COLUMN_IN_TEMPLATE,
	ADD_NEW_TEMPLATE,
	GENERATE_SAMPLE_TEMPLATE_BY_TEMPLATE_ID,
	UPLOAD_TEMPLATE_DATA,
	GENERATE_LETTER,
	GET_UPLOADED_LETTER_INFORMATION_BY_PARAMETERS,
	PREVIEW_LETTER,
	DELETE_LETTER_INFORMATION,
	SEND_LETTER_EMAIL,
	VALIDATE_LETTER,
	SCHEDULE_LETTER_EMAIL,
	DOWNLOAD_ZIP,

} from '../config/api-url';

export const createUpdateAdminLGSettingsAPI = (data) => postRequest(`${LG_CREATE_PERMISSION}`, data);
export const removeAdminLGSettingsAPI = (data) => postRequest(`${LG_REMOVE_PERMISSION}`, data);
export const getAdminLGSettingsAPI = (data) => getRequest(`${LG_PERMISSION_SETTINGS}`, data);
export const getTemplateListAPI = (data) => getRequest(`${GET_TEMPLATE_LIST}`, data);
export const getTemplateColumnListByTemplateIdAPI = (data) => getRequest(`${GET_TEMPLATE_COLUMN_LIST_BY_TEMPLATE_ID}?templateId=${data.templateId}`, data);
export const addColumnInTemplateAPI = (data) => postRequest(`${ADD_COLUMN_IN_TEMPLATE}`, data);
export const addNewTemplateAPI = (data) => postRequest(`${ADD_NEW_TEMPLATE}`, data);
export const getSampleTemplateByTemplateIdAPI = (data) => getRequest(`${GENERATE_SAMPLE_TEMPLATE_BY_TEMPLATE_ID}?templateId=${data.templateId}`, data);
export const uploadTemplateDataAPI = (data) => postRequest(`${UPLOAD_TEMPLATE_DATA}?templateId=${data.templateId}`, data.formData);
export const getGeneratedLetterAPI = (data) => postRequest(`${GENERATE_LETTER}`, data);
export const getPreviewLetterAPI = (data) => getRequest(`${PREVIEW_LETTER}?letterInformationId=${data.letterInformationId}`, data);
export const getUploadedLetterInformationByParametersAPI = (data) => getRequest(`${GET_UPLOADED_LETTER_INFORMATION_BY_PARAMETERS}?templateId=${data.templateId}&year=${data.year}`, data);
export const deleteLetterInformationAPI = (data) => postRequest(`${DELETE_LETTER_INFORMATION}`, data);
export const sendLetterEmailAPI = (data) => postRequest(`${SEND_LETTER_EMAIL}`, data);
export const validateLetterAPI = (data) => postRequest(`${VALIDATE_LETTER}?templateId=${data.templateId}`, data.formData);
export const scheduleLetterEmailAPI = (data) => postRequest(`${SCHEDULE_LETTER_EMAIL}`, data);
export const downloadZipAPI = (data) => postRequestZip(`${DOWNLOAD_ZIP}`, data);

