import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Box, Slider, IconButton, Typography, Icon } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import AvatarEditor from 'react-avatar-editor';
import { useSnackbar } from 'notistack';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { DeleteIcon } from '../../config/svg/CommonSvg';
import AlertDialog from '../Common/Dialog';
import { useTranslation } from 'react-i18next';
import { getRequestImage } from '../../config/auth';

export const ImageEditor = (props) => {
	const { showImageEditor, setProfileDetail, profileDetail } = props;
	const userDetails = getLocalStorageItem('userDetail') && JSON.parse(getLocalStorageItem('userDetail'));
	const { imagePath } = userDetails;
	const [image, setImage] = useState(imagePath);
	const [sliderValue, setSliderValue] = React.useState(1);
	const [straightenValue, setStraightenValue] = useState(0);
	const [imageName, setImageName] = useState('');
	const [isImageUpload, setIsImageUpload] = useState(true);
	const [uploadingPic, setUploadingPic] = useState(false);
	const avatarEditorRef = useRef();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	useEffect(() => {
		imagePath && getUpdatedImage(imagePath);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imagePath]);

	const createFile = async (filePath) => {
		try {
			const response = await getRequestImage(filePath, undefined, { responseType: 'blob', isProfileUrl: true });
			return new File([response.data], 'file.jpg', { type: 'image/jpeg' });
		} catch (error) {
			return null;
		}
	};

	const getUpdatedImage = (imgPath) => {
		createFile(imgPath).then((image) => {
			if (image) {
				setImage(image);
			}
		});
	};

	const uploadImage = (event) => {
		props.setFormDataUpdated(true);
		const file = event.target.files[0];
		const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif'];
		try {
			if (file) {
				const extension = file.name.split('.').slice(-1).pop();
				const fSize = file.size;
				const fileSize = Math.round(fSize / 1000);
				if (fileSize > 5000) {
					enqueueSnackbar(t('ProfileImageSizeText'), { variant: 'error', autoHideDuration: 5000 });
					event.target.value = '';
				} else if (!allowedExtensions.includes(extension ? extension.toLowerCase() : '')) {
					enqueueSnackbar(`${t('ProfileImageFileTypeText')} ${allowedExtensions.toString()}`, {
						variant: 'error',
						autoHideDuration: 5000,
					});
					event.target.value = '';
				} else {
					setImageName(file.name);
					setImage(file);
					setIsImageUpload(false);
					event.target.value = '';
				}
			} else {
				event.target.value = '';
			}
		} catch (err) {}
	};
	const handleSliderChange = (event, newValue) => {
		setSliderValue(newValue);
	};
	const handleStraightenSliderChange = (event, newValue) => {
		setStraightenValue(newValue);
	};
	const onRefreshIconClick = () => {
		setSliderValue(1);
		setStraightenValue(0);
	};
	const DataURIToBlob = (dataURI) => {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	};
	const onClickSave = () => {
		setUploadingPic(true);
		try {
			if (avatarEditorRef && avatarEditorRef.current) {
				const imageBase64 = avatarEditorRef.current.getImageScaledToCanvas().toDataURL();
				const file = DataURIToBlob(imageBase64);
				updateImage(file);
			}
		} catch (err) {
			setUploadingPic(false);
		}
	};
	const updateImage = async (file) => {
		const formData = new FormData();
		const name = imageName ? imageName : `${userDetails.firstName}_${Math.random(0, 10)}.png`;
		formData.append('file', file, name);

		const resp = await props.updateProfilePic(formData);
		if (resp.data.status === 200) {
			setUploadingPic(false);
			props.setFormDataUpdated(false);
			// OP-9655 - removed get profile API call after update profile pic
			if (resp && resp.data && resp.data.entity) {
				setProfileDetail({ ...profileDetail, imagePath: resp.data.entity || '' });
				const UserDetailUpdate = {
					...userDetails,
					imagePath: resp.data.entity,
				};
				setLocalStorageItem('userDetail', JSON.stringify(UserDetailUpdate));
				setIsImageUpload(true);
				showImageEditor({}, false);
			}
			enqueueSnackbar(t('ProfileImageUpdateText'), { variant: 'success', autoHideDuration: 5000 });
		} else {
			setUploadingPic(false);
			const responseAPI = resp.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};
	const onDeleteIconClick = () => {
		if (isImageUpload) {
			props
				.deleteProfilePic()
				.then((response) => {
					if (response && response.data && response.data.entity) {
						setProfileDetail({ ...props.profileDetail, imagePath: '' });
						const UserDetailUpdate = {
							...userDetails,
							imagePath: '',
						};
						setLocalStorageItem('userDetail', JSON.stringify(UserDetailUpdate));
						setIsImageUpload(true);
						setImage(null);
						showImageEditor({}, false);
						enqueueSnackbar(t('OrgProfileLogoDelete'), {
							variant: 'success',
							autoHideDuration: 5000,
						});
					}
				})
				.catch(() => {});
		} else {
			setImage(imagePath);
			setIsImageUpload(true);
		}
	};

	const [modalOpen, setModalOpen] = useState(false);
	const handleCloseModal = (e, type) => {
		e.preventDefault();
		if (type === 1) {
			onDeleteIconClick();
		}
		setModalOpen(false);
	};
	return (
		<Fragment>
			<Box className='image-edit-area' style={{ backgroundColor: '#292929' }}>
				<Box className='upload-action'>
					<div className='upload-button' id='upload-btn'>
						<label htmlFor='file-input-for-editor' className='upload-image-label'>
							{t('ProfileImageLabelText')}
						</label>
						<input
							className='upload-image-file-input'
							accept='image/x-png,image/gif,image/jpeg'
							type='file'
							id='file-input-for-editor'
							onChange={uploadImage}
						/>
					</div>
					<Box>
						{Boolean(imagePath) ? (
							<IconButton size='small' className='deleteIcon' onClick={() => setModalOpen(true)}>
								<DeleteIcon />
							</IconButton>
						) : image ? (
							<IconButton
								size='small'
								className='deleteIcon'
								onClick={() => {
									if (image) {
										setImage(null);
										setIsImageUpload(true);
										enqueueSnackbar(t('OrgProfileLogoDelete'), {
											variant: 'success',
											autoHideDuration: 5000,
										});
									}
								}}
							>
								<DeleteIcon />
							</IconButton>
						) : (
							<Icon size='small' className='deleteIcon'>
								<DeleteIcon />
							</Icon>
						)}
					</Box>
				</Box>
				<Box display='flex' alignItems='center' justifyContent='center'>
					<Box className='profile-avatar'>
						<AvatarEditor
							ref={avatarEditorRef}
							image={image}
							width={320}
							height={320}
							border={0}
							borderRadius={250}
							scale={sliderValue}
							rotate={straightenValue}
							disableBoundaryChecks={false}
							disableHiDPIScaling={false}
							// crossOrigin={'anonymous'}
							className={image ? 'image-section-white-bg' : 'image-section'}
							onImageReady={(event) => event}
							onLoadSuccess={(imgInfo) => imgInfo}
							onLoadFailure={(event) => event}
						/>
					</Box>
				</Box>
				<Box display='flex' alignItems='center' justifyContent='space-between' className='image-setting'>
					<Box width={'100%'} p={2}>
						<Typography>{t('zoomLabel')}</Typography>
						<Slider
							step={0.1}
							min={1}
							max={5}
							value={typeof sliderValue === 'number' ? sliderValue : 1}
							onChange={handleSliderChange}
							aria-labelledby='input-slider'
						/>
					</Box>
					<Box width={'100%'} p={2}>
						<Typography>{t('ProfileImageStraightenText')}</Typography>
						<Slider
							min={0}
							max={360}
							value={typeof straightenValue === 'number' ? straightenValue : 0}
							onChange={handleStraightenSliderChange}
							aria-labelledby='input-slider'
						/>
					</Box>
					<Box>
						<IconButton className='reset-btn' size='small' onClick={onRefreshIconClick} style={{ marginTop: 10 }}>
							<RefreshIcon />
						</IconButton>
					</Box>
				</Box>
			</Box>
			<Box className='image-edit-action'>
				<div className='okr-form-action'>
					<Button
						id='cancel-btn'
						className='cancel-btn btn-link'
						color='primary'
						onClick={() => showImageEditor({}, false)}
					>
						{t('cancelBtn')}
					</Button>
					<Button
						id='save-btn'
						className='submitBtn btn-primary'
						color='primary'
						onClick={onClickSave}
						disabled={uploadingPic}
					>
						{t('saveAndExitBtn')}
					</Button>
				</div>
			</Box>

			{modalOpen && (
				<AlertDialog
					module='delete'
					message={t('OrgLogoRemoveAlert')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};
