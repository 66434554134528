/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// import GlobalSearch from '../Common/GlobalSearch';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import NotesImg from '../../images/no-direct-reportees.svg';

export const DirectReportDrawer: React.FC<any> = (props) => {
	const {
		recentSearchUserList,
		directReportList,
		RenderUserAvatar,
		RenderUserName,
		RenderEmailAddress,
		handleRecentSearchItemClick,
	} = props;
	const { t } = useTranslation();

	return (
		<Box className='drawer-inner-content'>
			<Box className='drawer-content-panel'>
				<Box className='alignment-dr-wrapper'>
					<Box className='reportees-members'>
						<>
							<Typography variant='h5'>{t('Direct Reportees')}</Typography>

							{directReportList && directReportList.length ? (
								<List component='ul' disablePadding className='reportees-members-list'>
									{[...directReportList].map((rec: any) => (
										<ListItem className={''} onClick={(event: any) => handleRecentSearchItemClick(event, rec)}>
											<Box className='user-info'>
												<Box className='user-img'>
													<RenderUserAvatar rec={rec} />
												</Box>
												<Box className='user-info-details'>
													<RenderUserName rec={rec} />
													<RenderEmailAddress rec={rec} />
												</Box>
											</Box>
										</ListItem>
									))}
								</List>
							) : directReportList && directReportList.length === 0 ? (
								<Box className='no-record'>
									<Typography variant='h2'>No Direct Reportees</Typography>
									<Typography>However you can search for anyone and view their alignment map</Typography>
									<AnimatedIcon
										className='no-record-img'
										width={'200'}
										type='image/svg+xml'
										alt={'No Conversation Available'}
										imagePath={NotesImg}
									/>
								</Box>
							) : (
								<></>
							)}
						</>
						{recentSearchUserList && recentSearchUserList.length ? (
							<>
								<Box className='other-member-section'>
									<Typography variant='h5' className='other-member-head'>
										<span>{t('Recent Searches')}</span>
									</Typography>
								</Box>
								<List component='ul' disablePadding className='reportees-members-list'>
									{[...recentSearchUserList].map((rec: any) => (
										<ListItem className={''} onClick={(event: any) => handleRecentSearchItemClick(event, rec)}>
											<Box className='user-info'>
												<Box className='user-img'>
													<RenderUserAvatar rec={rec} />
												</Box>
												<Box className='user-info-details'>
													<RenderUserName rec={rec} />
													<RenderEmailAddress rec={rec} />
												</Box>
											</Box>
										</ListItem>
									))}
								</List>
							</>
						) : (
							<></>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
