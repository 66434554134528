import React from 'react';
import { Box, Chip, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const OkrStatus: React.FC<any> = (props) => {
	const { data, countBg, subHead = true } = props;

	const { t } = useTranslation();
	return (
		<Box className='okr-progress-status'>
			{subHead && <Typography variant='h6'>{t('keyResultStatus')}</Typography>}
			<List disablePadding>
				<ListItem>
					<Typography variant='h5' className={`${countBg ? 'count' : ''}`}>
						{data?.onTrack}
					</Typography>
					<Chip className='chip-text on-track' label={t('InsightsCategoriesOnTrack')} />
				</ListItem>
				<ListItem>
					<Typography variant='h5' className={`${countBg ? 'count' : ''}`}>
						{data?.atRisk}
					</Typography>
					<Chip className='chip-text at-risk' label={t('InsightsCategoriesAtRisk')} />
				</ListItem>
				<ListItem>
					<Typography variant='h5' className={`${countBg ? 'count' : ''}`}>
						{data?.lagging}
					</Typography>
					<Chip className='chip-text lagging' label={t('InsightsCategoriesLagging')} />
				</ListItem>
				<ListItem>
					<Typography variant='h5' className={`${countBg ? 'count' : ''}`}>
						{data?.notStarted}
					</Typography>
					<Chip className='chip-text not-started' label={t('MyGoalPdfNotStarted')} />
				</ListItem>
			</List>
		</Box>
	);
};
