import {
	allRecognizeListApi,
	saveRecognizeApi,
	getAllBadges,
	deleteRecognizePostApi,
	allCommentsListApi,
	saveCommentsApi,
	deleteCommentsPostApi,
	updateRecognizationPostAPI,
	fameBadgesApi,
	allLikeRecognitionApi,
	getAllRecogTeamsApi,
	getAllTeamDataApi,
} from '../services/RecognizeService';
import { likeConversationAPI,getConversationReplyAPI } from '../services/cfrService';

import {
	GET_RECOGNIZE_LIST_PENDING,
	GET_RECOGNIZE_LIST_SUCCESS,
	GET_RECOGNIZE_LIST_FAILED,
	GET_RECOGNIZE_BADGES_PENDING,
	GET_RECOGNIZE_BADGES_SUCCESS,
	GET_RECOGNIZE_BADGES_FAILED,
	GET_FAME_BADGES_FAILED,
	GET_FAME_BADGES_PENDING,
	GET_FAME_BADGES_SUCCESS,
	PRAISE_RECOGNIZE_ID,
} from './actionTypes';

const GetRecognizeDataPending = () => ({
	type: GET_RECOGNIZE_LIST_PENDING,
});
const GetRecognizeDataCompleted = (payload) => ({
	type: GET_RECOGNIZE_LIST_SUCCESS,
	payload,
});
const GetRecognizeDataFailed = (error) => ({
	type: GET_RECOGNIZE_LIST_FAILED,
	payload: error,
});

const GetFameBadgesPending = () => ({
	type: GET_FAME_BADGES_PENDING,
});
const GetFameBadgesCompleted = (payload) => ({
	type: GET_FAME_BADGES_SUCCESS,
	payload,
});
const GetFameBadgesFailed = (error) => ({
	type: GET_FAME_BADGES_FAILED,
	payload: error,
});

export const getFameOfWallBadges = (data) => {
	return (dispatch) => {
		dispatch(GetFameBadgesPending());
		return fameBadgesApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(GetFameBadgesCompleted(response.data));
				} else {
					dispatch(GetFameBadgesFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(GetFameBadgesFailed(error));
			});
	};
};

export const getRecognizationPostListing = (data) => {
	return (dispatch) => {
		dispatch(GetRecognizeDataPending());
		return allRecognizeListApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(GetRecognizeDataCompleted(response.data));
				} else {
					dispatch(GetRecognizeDataFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(GetRecognizeDataFailed(error));
			});
	};
};

export const saveRecognizePost = (data) => {
	return () => {
		return saveRecognizeApi(data);
	};
};

export const deleteCommentsPost = (data) => {
	return () => {
		return deleteCommentsPostApi(data);
	};
};
export const getLikesList = (data) => {
	return () => {
		return allLikeRecognitionApi(data);
	};
};
export const getTeamsByEmployeeList = (data) => {
	return () => {
		return getAllRecogTeamsApi(data);
	};
};
export const getAllTeamData = (data) => {
	return () => {
		return getAllTeamDataApi(data);
	};
};
export const saveCommentsPost = (data) => {
	return () => {
		return saveCommentsApi(data);
	};
};
export const updateRecogPost = (data) => {
	return () => {
		return updateRecognizationPostAPI(data);
	};
};
export const getCommentsList = (data) => {
	return () => {
		return allCommentsListApi(data);
	};
};

export const getConversationReplyList = (data) => {
	return () => {
		return getConversationReplyAPI(data);
	};
};

export const likeRecognization = (data) => {
	return () => {
		return likeConversationAPI(data);
	};
};

export const deleteRecognizePost = (data) => {
	return () => {
		return deleteRecognizePostApi(data);
	};
};

const GetRecognizeBadgesPending = () => ({
	type: GET_RECOGNIZE_BADGES_PENDING,
});
const GetRecognizeBadgesCompleted = (payload) => ({
	type: GET_RECOGNIZE_BADGES_SUCCESS,
	payload,
});
const GetRecognizeBadgesFailed = (error) => ({
	type: GET_RECOGNIZE_BADGES_FAILED,
	payload: error,
});

export const getRecognizationBadges = (data) => {
	return (dispatch) => {
		dispatch(GetRecognizeBadgesPending());
		return getAllBadges(data)
			.then((response) => {
				if (response.status === 200) {
					let badgesData = {};
					response.data.entityList.forEach((element) => {
						badgesData[element.recognitionCategoryId] = element;
					});
					response.data.entityMapping = badgesData;
					dispatch(GetRecognizeBadgesCompleted(response.data));
				} else {
					dispatch(GetRecognizeBadgesFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(GetRecognizeDataFailed(error));
			});
	};
};

export const praiseRecognizeId = (payload) => ({
	type: PRAISE_RECOGNIZE_ID,
	payload,
});
