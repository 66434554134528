import { Avatar, Box, ClickAwayListener, List, ListItem, Paper, Popper, Tooltip, Typography } from '@material-ui/core';
import * as React from 'react';
import { Fragment, useState } from 'react';
import { ProgressUpArrow } from '../../config/svg/ArrowSvg';
import { RecentRedIcon } from '../../config/svg/CommonSvg';
import { getUserName, getRandomColor, getRandomBackgroundColor } from '../../config/utils';

const DeltaKrPopper: React.FC<any> = (props: any) => {
	const { recentContribution } = props;
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleClose = (event: any) => {
		event.preventDefault();
		setAnchorEl(null);
		setOpenFilter(false);
	};

	const showDelta = (event: any) => {
		if (recentContribution?.contributorsRecentProgress.length !== 0) {
			setAnchorEl(event.currentTarget);
			setOpenFilter(true);
		}
	};

	return (
		<Fragment>
			<Box className='delta-settings' onMouseLeave={handleClose} onMouseOver={showDelta}>
				{recentContribution?.recentContribution >= 0 ? <ProgressUpArrow /> : <RecentRedIcon />}
				<Typography variant='body2'>
					{recentContribution.recentContribution >= 0 ? (
						<span> {recentContribution.recentContribution}% </span>
					) : (
						<span className='red-text'> {recentContribution.recentContribution}% </span>
					)}
				</Typography>
			</Box>
			<Popper
				className='okr-filter alignmentmap-filter-list deltaKr recent-contribution'
				open={openFilter}
				anchorEl={anchorEl}
				id='filtersPopper'
				placement='bottom-end'
			>
				<Box>
					<ClickAwayListener onClickAway={handleClose}>
						{/* 22027 */}
						<Paper>
							<Typography className='recentContrib'>Recent Contributions by your Colleagues</Typography>
							<List>
								{recentContribution?.contributorsRecentProgress.map((item: any, index: number) => (
									<ListItem key={index}>
										<Box display='flex' alignItems='center' className='usersList'>
											<Box className='userDetails'>
												{item.imagePath ? (
													<Avatar
														className='avatar-default'
														alt={`${item.firstName} ${item.lastName}`}
														src={item.imagePath}
													/>
												) : (
													<Avatar
														style={{
															backgroundColor: getRandomBackgroundColor(item.backGroundColorCode),
															color: getRandomColor(item.colorCode),
														}}
													>
														{getUserName({
															firstName: item.firstName,
															lastName: item.lastName,
															fullName: '',
														})}
													</Avatar>
												)}
												<Box className='employeeDetail'>
													<Typography className='detail name'>
														<Tooltip title={`${item.firstName} ${item.lastName}`} arrow>
															<span>{`${item.firstName} ${item.lastName}`}</span>
														</Tooltip>
													</Typography>
													<Typography className='detail designaton'>
														<Tooltip title={`${item.designation}`} arrow>
															<span>{`${item.designation}`}</span>
														</Tooltip>
													</Typography>
												</Box>
											</Box>
											<Box className='scoreCount'>
												{item.contributorsContribution >= 0 ? <ProgressUpArrow /> : <RecentRedIcon />}
												{item.contributorsContribution >= 0 ? (
													<span> {item.contributorsContribution}% </span>
												) : (
													<span className='red-text'> {item.contributorsContribution}% </span>
												)}
											</Box>
										</Box>
									</ListItem>
								))}
							</List>
						</Paper>
					</ClickAwayListener>
				</Box>
			</Popper>
		</Fragment>
	);
};

export default DeltaKrPopper;
