import React, { Fragment, useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PerformanceReview } from './PerformanceReview';
import { RecognizeSettings } from './Recognize/RecognizeSettings';
import PropTypes from 'prop-types';
import { CoreValuesIcon, PerformReviewRolesIcon } from '../../../config/svg/CommonSvgIcon';
import { updateCurrenTabSelection } from '../../../action/adminSettings';
import { useDispatch, useSelector } from 'react-redux';
import { checkSupportPermissionType } from '../../../config/utils';
import { supportModulePerformAssessment } from '../AdminConstant';
import { StrategicObjectiveSetting } from './StrategicObjective';
import { AlignSetting } from './AlignSetting';
import { EngageSetting } from './EngageSetting';
import { AdminPerformanceDevelopment } from './AdminPerformanceDevelopment';
import { AlignGoalsSetting } from './AlignGoalsSetting';
import { LetterGeneration } from './LetterGeneration';
import { GoalReviewSettings } from './GoalReviewSettings';

export const SettingsTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`settings-tab-${index}`}
			aria-labelledby={`settings-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

SettingsTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `settings-tabpanel-${index}`,
		'aria-controls': `settings-tabpanel-${index}`,
	};
}

export const Settings: React.FC<any> = (props: any) => {
	const {
		settingDropdownSelected,
		setSettingDropdownSelected,
		setOpenAssessmentForm,
		openAssessmentForm,
		setAssessmentTabSelected,
		assessmentTabSelected,
		setOpenRecognizeDrawer,
		openRecognizeDrawer,
		assessmentSettingTabSelected,
		setAssessmentSettingTabSelected,
		setOpenAssessmentSettingForm,
		openAssessmentSettingForm,
		defaultOkrOpen,
		setDefaultOkrOpen,
		handleClickDefaultOkr,
		openImportOkr,
		setOpenImportOkr,
		goalFormOpen,
		setGoalFormOpen,
		handleGoalFormOpen,
		goalCycleOpen,
		setGoalCycleOpen,
		handleGoalCycleOpen,
		workflowReversalOpen,
		setWorkflowReversalOpen,
		handleWorkflowReversalOpen,
		goalReviewOpen,
		setGoalReviewOpen,
		handleGoalReviewOpen,
	} = props;
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = useState<Number>(
		checkSupportPermissionType(supportModulePerformAssessment) ? 0 : 1
	);
	const dispatch = useDispatch();
	const { currentSettingTab } = useSelector((state: any) => state.commonReducer);

	useEffect(() => {
		setTabSelected(0);
	}, [currentSettingTab]);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
		dispatch(updateCurrenTabSelection(newValue === 1 ? 'recognize' : 'performReview'));
	};
	return (
		<Box className='settings-page-area'>
			<Box className='settings-tab-panel-wrap'>
				{currentSettingTab === 'strategicObj' ? <StrategicObjectiveSetting {...props} /> : <></>}
				{currentSettingTab === 'align' ? (
					<AlignSetting
						{...props}
						tabSelected={tabSelected}
						handleTabChange={handleTabChange}
						defaultOkrOpen={defaultOkrOpen}
						setDefaultOkrOpen={setDefaultOkrOpen}
						a11yProps={a11yProps}
						setTabSelected={setTabSelected}
						handleClickDefaultOkr={handleClickDefaultOkr}
						openImportOkr={openImportOkr}
						setOpenImportOkr={setOpenImportOkr}
					/>
				) : (
					<></>
				)}
				{currentSettingTab === 'alignGoals' ? (
					<AlignGoalsSetting
						setTabSelected={setTabSelected}
						tabSelected={tabSelected}
						handleTabChange={handleTabChange}
						a11yProps={a11yProps}
						goalFormOpen={goalFormOpen}
						setGoalFormOpen={setGoalFormOpen}
						handleGoalFormOpen={handleGoalFormOpen}
						goalCycleOpen={goalCycleOpen}
						setGoalCycleOpen={setGoalCycleOpen}
						handleGoalCycleOpen={handleGoalCycleOpen}
						workflowReversalOpen={workflowReversalOpen}
						setWorkflowReversalOpen={setWorkflowReversalOpen}
						handleWorkflowReversalOpen={handleWorkflowReversalOpen}
						{...props}
					/>
				) : (
					<></>
				)}
				{currentSettingTab === 'goalReviews' ? (
					<GoalReviewSettings
						{...props}
						setTabSelected={setTabSelected}
						tabSelected={tabSelected}
						handleTabChange={handleTabChange}
						a11yProps={a11yProps}
						goalReviewOpen={goalReviewOpen}
						setGoalReviewOpen={setGoalReviewOpen}
						handleGoalReviewOpen={handleGoalReviewOpen}
					/>
				) : (
					<></>
				)}
				{currentSettingTab === 'engage' ? <EngageSetting {...props} /> : <></>}

				{checkSupportPermissionType(supportModulePerformAssessment) ? (
					currentSettingTab === 'performReview' ? (
						<PerformanceReview
							{...props}
							settingDropdownSelected={settingDropdownSelected}
							setSettingDropdownSelected={setSettingDropdownSelected}
							setOpenAssessmentForm={setOpenAssessmentForm}
							openAssessmentForm={openAssessmentForm}
							setAssessmentTabSelected={setAssessmentTabSelected}
							assessmentTabSelected={assessmentTabSelected}
							tabSelected={tabSelected}
							assessmentSettingTabSelected={assessmentSettingTabSelected}
							setAssessmentSettingTabSelected={setAssessmentSettingTabSelected}
							setOpenAssessmentSettingForm={setOpenAssessmentSettingForm}
							openAssessmentSettingForm={openAssessmentSettingForm}
							handleTabChange={handleTabChange}
							a11yProps={a11yProps}
						/>
					) : (
						<></>
					)
				) : (
					<></>
				)}
				{currentSettingTab === 'adminPerformanceDevlopment' ? (
					<AdminPerformanceDevelopment
						{...props}
						tabSelected={tabSelected}
						handleTabChange={handleTabChange}
						a11yProps={a11yProps}
					/>
				) : (
					<></>
				)}
				{currentSettingTab === 'recognition' ? (
					<RecognizeSettings
						{...props}
						setOpenRecognizeDrawer={setOpenRecognizeDrawer}
						openRecognizeDrawer={openRecognizeDrawer}
					/>
				) : (
					<></>
				)}

				{currentSettingTab === 'lettergen' ? <LetterGeneration 
					{...props}/> : <></>}
			</Box>
		</Box>
	);
};
