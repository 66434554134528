import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Chip, Collapse, List, ListItem, Slider, Tooltip, Typography } from '@material-ui/core';
import { SearchField } from '../../Common/SearchField';
import { CycleDropdownQuickLinks } from '../../Common/CycleDropdownQuickLinks';
import { OKRButton } from '../../Common/OKRButton';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { BorderCheckboxIcon, CheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { NoRecord } from '../../Admin/CommonComponent/NoRecord';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { getRandomBackgroundColor, getRandomColor, getUserDetails, getUserName } from '../../../config/utils';
import { OkrProgressBar } from '../../OkrAction/OkrProgressBar';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
//import '../../Goals/OkrListing/style-old.scss';

export const ImportList: React.FC<any> = (props) => {
	const {
		t,
		okrKrSelectionData,
		setOkrKrSelectionData,
		fetchMyOkrData,
		modalPropsAlert,
		setModalPropsAlert,
		clearPeopleSearch,
		selectedUserDetail,
	} = props;
	const [expandedKrList, setExpandedKrList] = useState<number[]>([]);
	const handleExpandKeyResult = (e: any, okr: any) => {
		let list = Object.assign([], expandedKrList);
		const elementIndex = list.indexOf(okr.goalObjectiveId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(okr.goalObjectiveId);
		}
		setExpandedKrList(list);
	};
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [pageIndex, setPageIndex] = useState(1);
	const [searchText, setSearchText] = useState('');
	const [isCurrentCycleImport, setIsCurrentCycleImport] = useState<string>('');
	const [isPreviousCycleImport, setIsPreviousCycleImport] = useState<any[]>([]);
	const [pastCycles, setPastCycles] = useState<any[]>([]);
	const [futureCycles, setFutureCycles] = useState<any[]>([]);
	const [goalNatureData, setGoalNatureData] = useState<any>({});
	const [currentCycleSelection, setCurrentCycleSelection] = useState<any[]>([]);
	const [currentCycleSelectionData, setCurrentCycleSelectionData] = useState<any>({
		type: 0,
		cycleId: [],
		cycleLabel: '',
	});
	const [selectedCycleLabel, setSelectedCycleLabel] = useState<string>('');
	const [filteredList, setFilteredList] = useState<any[]>([]);
	const [listPending, setListPending] = useState<boolean>(true);
	let userCycleDetails = JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const [cycleDetails, setCycleDetails] = useState<any>({});
	const [cycleSelection, setCycleSelection] = useState<number>(1);

	useEffect(() => {
		if (props.myOkrImportDataStatus === 'success') {
			const list: any = Object.assign([], props.myOkrImportData?.records);
			if (searchText) {
				setPageIndex(props.myOkrImportData.pageIndex);
				if (
					props.myOkrImportData?.totalPages > 1 &&
					props.myOkrImportData?.totalPages > props.myOkrImportData?.pageIndex
				) {
					setShowLoadMore(true);
				} else {
					setShowLoadMore(false);
				}
				if (props.myOkrImportData?.pageIndex === 1) {
					setFilteredList(list);
				} else {
					if (props.myOkrImportData.records) {
						setFilteredList([...filteredList, ...props.myOkrImportData.records]);
					} else if (props.conversationList.entityList === null) {
						setFilteredList([]);
					}
				}
				listPending && setListPending(false);
			} else {
				setFilteredList(list);
				setListPending(false);
			}
		}
	}, [props.myOkrImportDataStatus]);

	useEffect(() => {
		if (selectedUserDetail) {
			getCycleList(selectedUserDetail);
		} else {
			setCycleDetails(userCycleDetails);
		}
	}, [selectedUserDetail]);

	useEffect(() => {
		if (cycleDetails && Object.keys(cycleDetails).length > 0) {
			let myOkrImportDropdown: any = [];
			let lastTwoPreviousYear: any = [];
			let dataFound = false;
			let past: any[] = [];
			let future: any[] = [];
			setListPending(true);
			cycleDetails?.cycleDetails?.forEach((cycleData: any) => {
				let yearCycleData = { ...cycleData, quarterDetails: [] };
				cycleData.quarterDetails.reverse()?.forEach((cycleOkrData: any) => {
					if (cycleOkrData.isCurrentQuarter === true) {
						lastTwoPreviousYear = myOkrImportDropdown.slice(-2);
						dataFound = true;
						setIsCurrentCycleImport(cycleOkrData.organisationCycleId);
						past.push({ ...yearCycleData });
						yearCycleData.quarterDetails = [];
					} else {
						yearCycleData.quarterDetails.push(cycleOkrData);
						!dataFound && myOkrImportDropdown.push(cycleOkrData.organisationCycleId);
					}
				});
				if (!dataFound) {
					past.push(yearCycleData);
				} else {
					if (yearCycleData.quarterDetails.length > 0) {
						future.push(yearCycleData);
					}
				}
			});
			setFutureCycles(future);
			setPastCycles(past.reverse());
			setIsPreviousCycleImport(lastTwoPreviousYear);
			callFetchOkr(lastTwoPreviousYear, '', 15, 1, currentCycleSelectionData.cycleLabel);
			let natureD: any = {};
			props.okrMasterData?.okrNature.forEach((item: any, index: number) => {
				natureD[item.okrNatureId] = item.name;
			});
			setGoalNatureData(natureD);
		}
	}, [cycleDetails]);

	const handleCycleClick = (type: number, cycleId = 0, cycleLabel: string) => {
		let cycleData: any = [isCurrentCycleImport];
		if (type === 1) {
			cycleData = isPreviousCycleImport;
		} else if (type === 3 || type === 4) {
			cycleData = [cycleId];
		}
		if (Object.keys(okrKrSelectionData).length > 0) {
			setModalPropsAlert({
				...modalPropsAlert,
				open: true,
				message: t('unSavedImportAlert'),
				type: type,
				value: cycleData,
				cycleLabel: cycleLabel,
			});
		} else {
			handleCycleChange(type, cycleData, cycleLabel);
		}
	};

	const handleCycleChange = (type: number, cycleData: any, cycleLabel: string) => {
		setListPending(true);
		callFetchOkr(cycleData, '', 15, 1, cycleLabel, type);
		setCycleSelection(type);
		setSelectedCycleLabel(cycleLabel);
	};

	const performSearch = (searchString: string, currentCycleS: any, clearSearch: boolean) => {
		if (!searchText || !searchString) {
			setListPending(true);
		}
		callFetchOkr(
			!searchString ? currentCycleS : [],
			searchString,
			15,
			1,
			currentCycleSelectionData.cycleLabel,
			currentCycleSelectionData.type
		);
		setShowLoadMore(!clearSearch);
	};
	const parentSelected = (item: any, e: any) => {
		const okrKey = item.goalObjectiveId;
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, okrKrSelectionData);
		if (isChecked === true) {
			okrKrSelectionL[okrKey] = {
				okrName: item.objectiveName,
				krList: {},
				goalNatureId: item.goalNatureId,
			};
			item.importKrResponses.forEach((child: any) => {
				okrKrSelectionL[okrKey].krList[child.goalkeyId] = {
					krName: child.keyDescription,
					krMetric: child.metricId,
					krCurrencyId: child.currencyId,
					krCurrencyCode: child.currencyCode,
				};
			});
		} else {
			if (okrKrSelectionL[okrKey] !== undefined) {
				delete okrKrSelectionL[okrKey];
			}
		}
		setOkrKrSelectionData(okrKrSelectionL);
	};

	const childSelected = (krData: any, okrData: any, e: any) => {
		const krKey = krData.goalkeyId;
		const okrKey = okrData.goalObjectiveId;
		let isChecked: boolean = e.target.checked;
		let okrKrSelectionL: any[] = Object.assign({}, okrKrSelectionData);
		if (isChecked === true) {
			if (okrKrSelectionL[okrKey] === undefined) {
				okrKrSelectionL[okrKey] = {
					okrName: okrData.objectiveName,
					krList: {},
					goalNatureId: okrData.goalNatureId,
				};
			}
			okrKrSelectionL[okrKey].krList[krKey] = {
				krName: krData.keyDescription,
				krMetric: krData.metricId,
				krCurrencyId: krData.currencyId,
				krCurrencyCode: krData.currencyCode,
			};
		} else {
			if (okrKrSelectionL[okrKey] && okrKrSelectionL[okrKey].krList[krKey]) {
				delete okrKrSelectionL[okrKey].krList[krKey];
				if (Object.keys(okrKrSelectionL[okrKey].krList).length === 0) {
					delete okrKrSelectionL[okrKey];
				}
			}
		}
		setOkrKrSelectionData(okrKrSelectionL);
	};
	const callFetchOkr = (
		cycleId: any,
		searchString: string,
		pageSize: number,
		pageIdx: number,
		cycleLabel: string = '',
		type = 1
	) => {
		if (!searchString) {
			setCurrentCycleSelection(cycleId);
			setCurrentCycleSelectionData({
				type,
				cycleId: cycleId,
				cycleLabel,
			});
		}
		setSearchText(searchString);
		setPageIndex(pageIdx);
		fetchMyOkrData(cycleId, searchString, pageSize, pageIdx);
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalPropsAlert.type <= 4) {
				handleCycleChange(modalPropsAlert.type, modalPropsAlert.value, modalPropsAlert.cycleLabel);
			} else if (modalPropsAlert.type === 6) {
				performSearch('', modalPropsAlert.cycle, true);
			} else if (modalPropsAlert.type === 8) {
				clearPeopleSearch();
			}
			setOkrKrSelectionData([]);
		}
		setModalPropsAlert({
			...modalPropsAlert,
			open: false,
			message: '',
			type: 0,
			value: '',
			cycleLabel: '',
			cycle: '',
		});
	};
	const checkSelection = (e: any, currentCycleSelection: any) => {
		if (Object.keys(okrKrSelectionData).length > 0) {
			setModalPropsAlert({
				...modalPropsAlert,
				open: true,
				message: t('unSavedImportAlert'),
				type: 5,
				value: currentCycleSelection,
				cycleLabel: currentCycleSelectionData.cycleLabel,
			});
		}
	};
	const getCycleList = (userData: any) => {
		props
			.getCycleDetails(`organisationId=${userData.organisationId}&empId=${userData.employeeId}`)
			.then((resp: any) => {
				if (resp.status === 200) {
					try {
						if (resp.data.entity && resp.data.entity.cycleStart === 'Active') {
							setCycleDetails(resp.data.entity);
						}
					} catch (err) {
						setCycleDetails({});
					}
				} else {
					setCycleDetails({});
				}
			});
	};

	const clearSelection = (e: any, currentCycleSelection: any) => {
		if (Object.keys(okrKrSelectionData).length > 0) {
			e.preventDefault();
			e.stopPropagation();
			setModalPropsAlert({
				...modalPropsAlert,
				open: true,
				message: t('unSavedImportAlert'),
				type: 6,
				value: '',
				cycleLabel: currentCycleSelectionData.cycleLabel,
				cycle: currentCycleSelection,
			});
		} else {
			performSearch('', currentCycleSelection, true);
		}
	};
	return (
		<Box className='drawer-form-fields'>
			<Box className='drawer-input-field'>
				<Box className='src-okr-head'>
					<SearchField
						{...props}
						checkSelection={checkSelection}
						clearSelection={clearSelection}
						currentCycleSelection={currentCycleSelection}
						performSearch={performSearch}
						searchText={searchText}
						setSearchText={setSearchText}
					/>
					{!searchText && (
						<CycleDropdownQuickLinks
							{...props}
							futureCyclesList={futureCycles}
							pastCyclesList={pastCycles}
							handleCycleClick={handleCycleClick}
							cycleSelection={cycleSelection}
							selectedCycleLabel={selectedCycleLabel}
							searchText={searchText}
							currentCycleSelectionData={currentCycleSelectionData}
						/>
					)}
				</Box>
			</Box>
			<Box className='drawer-input-field'>
				{!listPending ? (
					filteredList.length > 0 ? (
						filteredList.map((myCycleData: any) => (
							<Box className='department-content'>
								<Box className='department-title'>
									<Typography variant='h6'>
										<Typography className='department-name' component={'span'}>
											{myCycleData.dueCycle}
										</Typography>
									</Typography>
								</Box>
								{myCycleData.importOkrResponses.map((myOkrData: any) => (
									<Box
										className={`import-okr-list import-myokr-list ${
											expandedKrList.indexOf(myOkrData.goalObjectiveId) !== -1 ? 'expanded-okr' : ''
										}`}
									>
										<Box className='okr-status-info'>
											{myOkrData.isDraft && <Chip className='draft-chip' label={t('draft')} />}
											<Box className='okr-nature-type-tag'>
												<Typography variant='subtitle2' className='operational'>
													{goalNatureData[myOkrData.goalNatureId]}
												</Typography>
											</Box>
										</Box>
										<Box className='import-obj-row'>
											<Box className='import-obj-col'>
												<Box className='import-obj-title'>
													<Checkbox
														checkedIcon={<CheckedIcon />}
														aria-label='OKR Checkbox'
														indeterminateIcon={<UnCheckedIcon />}
														icon={<BorderCheckboxIcon />}
														onChange={(e) => {
															parentSelected(myOkrData, e);
														}}
														checked={okrKrSelectionData[myOkrData.goalObjectiveId] !== undefined ? true : false}
														indeterminate={
															okrKrSelectionData &&
															okrKrSelectionData[myOkrData.goalObjectiveId] &&
															okrKrSelectionData[myOkrData.goalObjectiveId].krList?.length !== 0 &&
															Object.keys(okrKrSelectionData[myOkrData.goalObjectiveId].krList).length !==
																myOkrData.importKrResponses.length
																? true
																: false
														}
													/>
													<Typography variant='h4'>{myOkrData.objectiveName}</Typography>
												</Box>
												<Box className='import-okr-actions'>
													<OKRButton
														className={`btn-blue-chip ${
															expandedKrList.indexOf(myOkrData.goalObjectiveId) !== -1 ? 'expand' : 'collapse'
														}`}
														icon={<RightArrowIcon />}
														text={`${myOkrData.importKrResponses.length} Key Results`}
														handleClick={(e) => handleExpandKeyResult(e, myOkrData)}
													/>
													{myOkrData.teamName && (
														<Box className='teams-info-chip'>
															<Tooltip title={myOkrData.teamName} arrow>
																<Chip
																	className='team-avatar-smaller'
																	style={{
																		backgroundColor: getRandomBackgroundColor(myOkrData?.backGroundColorCode),
																		color: getRandomColor(myOkrData?.colorCode),
																	}}
																	label={getUserName({
																		firstName: '',
																		lastName: '',
																		fullName: myOkrData.teamName || '',
																	})}
																/>
															</Tooltip>
														</Box>
													)}
												</Box>
											</Box>
											<Box className='import-obj-progress-col'>
												<Box className='import-progress '>
													<OkrProgressBar
														{...props}
														parentOkr={[]}
														okr={myOkrData}
														type={'okr'}
														t={t}
														showArchiveList={false}
														updateProgress={() => {}}
														showEditLoader={false}
														currentUser={{}}
														okrMasterData={props.okrMasterData}
														isScoreLocked={true}
														enqueueSnackbar={() => {}}
														hideProgressText={true}
													/>
												</Box>
											</Box>
										</Box>

										<Collapse
											className='department-kr-collapsible-content'
											in={expandedKrList.indexOf(myOkrData.goalObjectiveId) !== -1}
											timeout='auto'
											unmountOnExit
										>
											{myOkrData.importKrResponses.map((myKrData: any) => (
												<Box className='import-kr-list'>
													<Checkbox
														checkedIcon={<CheckedIcon />}
														aria-label='KR Checkbox'
														icon={<BorderCheckboxIcon />}
														onChange={(e) => {
															childSelected(myKrData, myOkrData, e);
														}}
														checked={
															okrKrSelectionData[myOkrData.goalObjectiveId] &&
															okrKrSelectionData[myOkrData.goalObjectiveId].krList &&
															okrKrSelectionData[myOkrData.goalObjectiveId].krList[myKrData.goalkeyId] !== undefined
																? true
																: false
														}
													/>
													<Box className='import-kr-content'>
														<Box className='import-kr-row'>
															<Box className='import-kr-col'>
																<Typography>{myKrData.keyDescription}</Typography>
																<Box className='import-kr-actions'>
																	<OKRButton
																		title={t('weightHover')}
																		className='btn-oval-shape'
																		text={`${myKrData.krWeight}x`}
																		buttonType={'disableButtonTooltip'}
																		wrapClassName='btn-weight'
																		disabled={true}
																	/>
																	{myKrData.teamName && (
																		<Box className='teams-info-chip'>
																			<Tooltip title={myKrData.teamName} arrow>
																				<Chip
																					className='team-avatar-smaller'
																					style={{
																						backgroundColor: getRandomBackgroundColor(myKrData?.backGroundColorCode),
																						color: getRandomColor(myKrData?.colorCode),
																					}}
																					label={getUserName({
																						firstName: '',
																						lastName: '',
																						fullName: myKrData.teamName || '',
																					})}
																				/>
																			</Tooltip>
																		</Box>
																	)}
																</Box>
															</Box>
															<Box className='import-kr-progress-col'>
																<Box className='import-progress import-kr-progress'>
																	<OkrProgressBar
																		{...props}
																		parentOkr={myOkrData}
																		okr={myKrData}
																		type={'kr'}
																		t={t}
																		updateProgress={() => {}}
																		showEditLoader={false}
																		currentUser={{}}
																		okrMasterData={props.okrMasterData}
																		teamsOkr={false}
																		showArchiveList={false}
																		isScoreLocked={true}
																		enqueueSnackbar={() => {}}
																		hideProgressText={true}
																	/>
																</Box>
															</Box>
														</Box>
													</Box>
												</Box>
											))}
										</Collapse>
									</Box>
								))}
							</Box>
						))
					) : (
						<NoSearchRecord />
					)
				) : (
					<Box className='loading-center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				)}
			</Box>
			{!listPending && showLoadMore && filteredList.length > 0 && (
				<Box className='drawer-input-field'>
					<List className='load-more-ul' disablePadding>
						<ListItem className='load-more'>
							<OKRButton
								handleClick={(e) => {
									callFetchOkr(
										[],
										searchText,
										15,
										pageIndex + 1 || 1,
										currentCycleSelectionData.cycleLabel,
										currentCycleSelectionData.type
									);
								}}
								className={`load-more-btn`}
								text={t('loadMore')}
							/>
						</ListItem>
					</List>
				</Box>
			)}
			{modalPropsAlert.open && (
				<AlertDialog
					message={modalPropsAlert.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalPropsAlert.open}
				/>
			)}
		</Box>
	);
};
