import React, { useEffect } from 'react';
import { Badge, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { FaqIcon, GlossaryIcon, HayHandIcon, OKRExamIcon, SupportIcon } from '../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import { OpinionIcon, WhatsNewIcon } from '../../config/svg/DrawerSvg';
import { LeftMenuItem } from './LeftMenuItem';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageItem } from '../../services/StorageService';
import { updateGetUserVersion } from '../../action/common';

export default function LeftBottomMenu(props) {
	const { t } = useTranslation();
	const { checkEditOkr } = props;
	const dispatch = useDispatch();
	const { userVersion } = useSelector((state) => state?.commonReducer);
	const userDetail = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	const openJiraIssueCollector = (e) => {
		let navigate = checkEditOkr(e);
		if (navigate) {
			e.preventDefault();
			e.stopPropagation();
			// props.handleToggle(2);

			document.getElementById('atlwdg-trigger').click();
		}
	};

	useEffect(() => {
		const script = document.createElement('script');
		const script2 = document.createElement('script');

		script.type = 'text/javascript';
		script.src =
			'https://servicedesk.compunnel.com/s/d41d8cd98f00b204e9800998ecf8427e-CDN/8bkccv/814001/1b2186679a631144916156ab6aec18b5/2.2.4.7/_/download/batch/com.atlassian.plugins.jquery:jquery/com.atlassian.plugins.jquery:jquery.js?collectorId=7dfe95aa';
		script.async = true;
		script2.type = 'text/javascript';
		script2.src =
			'https://servicedesk.compunnel.com/s/ba53967f66ec0096b89bb4fd64ec1120-T/8bkccv/814001/1b2186679a631144916156ab6aec18b5/4.0.3/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=en-US&collectorId=7dfe95aa';
		script2.async = true;

		document.head.append(script);
		document.head.append(script2);

		window.ATL_JQ_PAGE_PROPS = {
			triggerFunction: function (showCollectorDialog) {
				showCollectorDialog();
			},
			fieldValues: {
				fullname: (userDetail && userDetail.firstName) + ' ' + (userDetail && userDetail.lastName),
				email: userDetail && userDetail.emailId,
				customfield_11308: window.location.hostname,
			},
		};
	}, []);

	const handleWhatsNewOnClick = () => {
		const showTooltip = false;
		const requestParam = `versionId=${userVersion.versionId}&isRead=${showTooltip}`;
		dispatch(updateGetUserVersion(requestParam, showTooltip));
		window.open(process.env.REACT_APP_WHATS_NEW, '_blank');
	};

	return (
		<Box>
			<List className='left-menu-links' disablePadding>
				<ListItem className='help-support-link' style={{'display':'none'}}>
					<LeftMenuItem
						btnIcon={<SupportIcon />}
						btnText={
							userVersion?.showTooltip ? (
								<Tooltip
									open={true}
									placement='right'
									arrow
									classes={{ popper: 'custom-tooltip-icon custom-tooltip-btn' }}
									title={
										userVersion?.showTooltip ? (
											<Box className='tooltip-text'>
												<HayHandIcon />
												<Typography dangerouslySetInnerHTML={{ __html: t('whatsNewTooltip') }}></Typography>
											</Box>
										) : (
											<></>
										)
									}
								>
									<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
								</Tooltip>
							) : userVersion?.isRead ? (
								<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
							) : (
								<></>
							)
						}
						btnTooltip={t('helpSupportLabel')}
						btnId={'help-support-link'}
						children={
							<List className='left-sub-menu' disablePadding>
								{/* <ListItem id='okr-exm-link'>
									<a href='https://unlockokr.com/okr-examples/' target='_blank' rel='noreferrer noopener'>
										<OKRExamIcon />
										<Typography variant='h5'>{t('okrExamples')}</Typography>
									</a>
								</ListItem> */}
								<ListItem id='faq-link'>
									<a href='https://www.unlocku.com/faqs/' target='_blank' rel='noreferrer noopener'>
										<FaqIcon />
										<Typography variant='h5'>{t('productFaqLabel')}</Typography>
									</a>
								</ListItem>
								{/* <ListItem id='glossary-link'>
									<a href='https://unlockokr.com/glossary/' target='_blank' rel='noreferrer noopener'>
										<GlossaryIcon />
										<Typography variant='h5'>{t('glossary')}</Typography>
									</a>
								</ListItem> */}
								<ListItem
									id='whats-new'
									onClick={(e) => {
										handleWhatsNewOnClick(e);
									}}
								>
									{/* <a rel='noreferrer' target='_blank' href={process.env.REACT_APP_WHATS_NEW}> */}
									<WhatsNewIcon />
									<Typography variant='h5'>
										{userVersion?.isRead ? (
											<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
										) : (
											<></>
										)}
										{/* <Tooltip
												placement='bottom'
												arrow
												classes={{ popper: 'custom-tooltip-icon' }}
												title={
													<Box className='tooltip-text'>
														<HayHandIcon />
														<Typography dangerouslySetInnerHTML={{ __html: t('whatsNewTooltip') }}></Typography>
													</Box>
												}
											>
												<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
											</Tooltip> */}
										{t('whatsNew')}
									</Typography>
									{/* </a> */}
								</ListItem>
							</List>
						}
					/>
					<Typography >Help & Support</Typography>
				</ListItem>
				

				<ListItem onClick={(e) => { openJiraIssueCollector(e) }} id='improvements-link'>
					<Box className='link-txt'>
						<OKRButton

							icon={<OpinionIcon />}
						// title={t('productFeedback')}
						// tooltipPlacement={'right'}
						// handleClick={(e) => openJiraIssueCollector(e)}
						/>
						<Typography>{t('productFeedback')}</Typography>
					</Box>
				</ListItem>
			</List>
		</Box>
	);
}
