import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { InformationIcon } from '../../../config/svg/CommonSvgIcon';

export const InformationMessage: React.FC<any> = (props) => {
	const { messageText, title, icon, toolTipClassName, html, className, iconImage } = props;
	const { t } = useTranslation();

	return (
		<Box className={className || 'information-message'}>
			<Tooltip
				title={title || ''}
				arrow
				classes={{
					popper: toolTipClassName || '',
				}}
			>
				<Typography variant='h6' className='no-sentence-case'>
					{icon ? iconImage || <InformationIcon /> : ''}
					{html ? <span dangerouslySetInnerHTML={{ __html: t('infoVisibilitySettings') }}></span> : t(messageText)}
				</Typography>
			</Tooltip>
		</Box>
	);
};
