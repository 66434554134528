import React, { Fragment, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SoManageUser } from './SoManageUser';
import { SoProgressManageUser } from './SoProgressManageUser';

export const SoAddUserDrawer: React.FC<any> = (props: any) => {
	const { settingType } = props;
	const { t } = useTranslation();

	const [tabSelected, setTabSelected] = useState<Number>(0);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};
	const a11yProps = (index: number) => {
		return {
			id: `exclude-tabpanel-${index}`,
			'aria-controls': `exclude-tabpanel-${index}`,
		};
	};

	return (
		<Fragment>
			<Tabs className='okr-tabs drawer-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab label={t('manageUserLabel')} value={0} {...a11yProps(0)} />
			</Tabs>
			<Box className='drawer-tabs-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{tabSelected === 0 && (
						<Box className='exclude-mng-user'>
							{settingType === 'soVisibility' ? <SoManageUser {...props} /> : <></>}
							{settingType === 'progressVisibility' ? <SoProgressManageUser {...props} /> : <></>}
						</Box>
					)}
				</form>
			</Box>
		</Fragment>
	);
};
