import { postRequest, getRequest, postRequestFreeTrial, postRequestOKR } from '../config/auth';
import {
	SIGN_IN,
	SIGN_IN_DB,
	CREATE_PASSWORD,
	FORGET_PASSWORD,
	SSO_LOGIN,
	LOGOUT,
	AZURE_SIGN_IN,
	TENANT_DATA,
	TENANT_DATA_OKR,
	ORG_CURRENT_CYCLE,
	IMPERSONATE_USER,
	OKR_TENANTS_DETAILS,
} from '../config/api-url';

export const signInAPI = (data) => postRequest(`${SIGN_IN}`, data);
export const createPasswordAPI = (data) => postRequest(`${CREATE_PASSWORD}`, data);
export const forgetPasswordAPI = (data) => postRequest(`${FORGET_PASSWORD}`, data);
export const signInDbAPI = (data) => postRequest(`${SIGN_IN_DB}`, data);
export const ssoLoginAPI = (data) => postRequest(`${SSO_LOGIN}`, data);
export const logoutAPI = (data) => postRequest(`${LOGOUT}`, data);
export const azureSignInAPI = (data) => getRequest(`${AZURE_SIGN_IN}`, data);
export const tenantAPI = (data) => postRequest(`${TENANT_DATA}`, data);
export const tenantAPIOKR = (data) => postRequestOKR(`${TENANT_DATA_OKR}`, data);
export const orgCurrentCycleAPI = (data) => getRequest(`${ORG_CURRENT_CYCLE}`, data);
export const impersonateUserAPI = (data) => postRequest(`${IMPERSONATE_USER}`, data);

export const getAllVersionForUserAPI = (data) => postRequestFreeTrial(`${OKR_TENANTS_DETAILS}`, data);
