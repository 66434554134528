import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, TextareaAutosize, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Rating } from '@material-ui/lab';
import { StarBorderIcon, StarFilledIcon } from '../../../../config/svg/RatingSvg';
import { getPerformanceRatings } from '../../../../config/utils';

export const AssessmentPreview: React.FC<any> = (props: any) => {
	const { assessmentPreview, performanceRatings } = props;
	const { t } = useTranslation();
	const [evaluationQuestionList, setEvaluationQuestionList] = useState<any>([]);
	const [feedbackQuestionList, setFeedbackQuestionList] = useState<any>([]);

	useEffect(() => {
		if (assessmentPreview && assessmentPreview?.details) {
			const { formQuestionListRequest } = assessmentPreview?.details;
			if (formQuestionListRequest && formQuestionListRequest.length > 0) {
				const evaluationList: any = [];
				const feedbackList: any = [];
				formQuestionListRequest.map((record: any) => {
					if (record?.questionsType === 1) {
						evaluationList.push(record);
					}
					if (record?.questionsType === 2) {
						feedbackList.push(record);
					}
					return record;
				});
				setEvaluationQuestionList([...evaluationList]);
				setFeedbackQuestionList([...feedbackList]);
			}
		}
	}, [assessmentPreview]);

	const handleQuestionValueChange = (e: any, newValue: any, item: any) => {
		const { value } = e?.target;

		if (item?.questionsType === 1) {
			const updatedEvaluationQuestion = evaluationQuestionList.map((rec: any) => {
				if (rec?.ratingFormDetailsId === item?.ratingFormDetailsId) {
					rec.questionsValue = newValue;
				}
				return rec;
			});
			setEvaluationQuestionList([...updatedEvaluationQuestion]);
		} else if (item?.questionsType === 2) {
			const updatedFeedbackQuestion = feedbackQuestionList.map((rec: any) => {
				if (rec?.ratingFormDetailsId === item?.ratingFormDetailsId) {
					rec.questionsValue = value;
				}
				return rec;
			});
			setFeedbackQuestionList([...updatedFeedbackQuestion]);
		}
	};

	return (
		<Box className='assessment-preview-area'>
			<Box className='assessment-preview-head'>
				<Typography variant='h2'>{assessmentPreview?.details?.name || t('annualPerformanceReview')}</Typography>
				<Typography variant='body2'>{t('performanceReviewForm')}</Typography>
			</Box>
			<Box className='assessment-preview-list'>
				{evaluationQuestionList && evaluationQuestionList.length ? (
					evaluationQuestionList.map((rec: any, index: number) => (
						<Box className='assessment-preview-list-inner' key={`evaluation_rating_${rec?.ratingFormDetailsId}`}>
							<Typography className='assessment-preview-index'>{index + 1}</Typography>
							<Box className='assessment-preview-row'>
								<List disablePadding>
									<ListItem className='assessment-preview-col1'>
										<Typography variant='h4'>{rec?.questionsName || ''}</Typography>
									</ListItem>
									<ListItem className='assessment-preview-col2'>
										<Box className='assessment-rating-area'>
											<Rating
												name={`evaluation_rating_name_${index + 1}`}
												size='large'
												disabled={true}
												icon={<StarFilledIcon />}
												emptyIcon={<StarBorderIcon />}
												value={rec.questionsValue || 0}
												onChange={(event: any, newValue: any) => {
													handleQuestionValueChange(event, newValue, rec);
												}}
												// onChangeActive={(event: any, newHover: any) => {
												// 	setHover(newHover);
												// }}
											/>
											{rec.questionsValue !== null && (
												<Typography variant='h6'>
													{getPerformanceRatings(performanceRatings, rec.questionsValue)}
												</Typography>
											)}
										</Box>
									</ListItem>
								</List>
							</Box>
						</Box>
					))
				) : (
					<></>
				)}
				{feedbackQuestionList && feedbackQuestionList.length ? (
					feedbackQuestionList.map((rec: any, index: number) => {
						const lastIndex = evaluationQuestionList && evaluationQuestionList.length;

						return (
							<Box className='assessment-preview-list-inner' key={`feedback_rating_${rec?.ratingFormDetailsId}`}>
								<Typography className='assessment-preview-index'>{lastIndex + index + 1}</Typography>
								<Box className='assessment-preview-row'>
									<List disablePadding>
										<ListItem className='assessment-preview-col1'>
											<Typography variant='h4'>{rec?.questionsName || ''}</Typography>
										</ListItem>
										<ListItem className='assessment-preview-col2'>
											<TextareaAutosize
												className='response-input-field'
												aria-label='minimum height'
												minRows={3}
												readOnly={true}
												disabled={true}
												placeholder='Write your response'
												value={rec.questionsValue || ''}
												onChange={(event: any) => handleQuestionValueChange(event, '', rec)}
											/>
										</ListItem>
									</List>
								</Box>
							</Box>
						);
					})
				) : (
					<></>
				)}
				{evaluationQuestionList &&
				evaluationQuestionList.length === 0 &&
				feedbackQuestionList &&
				feedbackQuestionList.length === 0 ? (
					<>No Record Found</>
				) : (
					<></>
				)}
				{/* <Box className='assessment-preview-list-inner'>
					<Typography className='assessment-preview-index'>1</Typography>
					<Box className='assessment-preview-row'>
						<List disablePadding>
							<ListItem className='assessment-preview-col1'>
								<Typography variant='h4'>Taking initiative & Responsibility</Typography>
							</ListItem>
							<ListItem className='assessment-preview-col2'>
								<Box className='assessment-rating-area'>
									<Rating
										name='assessment-1'
										size='large'
										icon={<StarFilledIcon />}
										emptyIcon={<StarBorderIcon />}
										value={value}
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
									/>
								</Box>
							</ListItem>
						</List>
					</Box>
				</Box>
				<Box className='assessment-preview-list-inner'>
					<Typography className='assessment-preview-index'>2</Typography>
					<Box className='assessment-preview-row'>
						<List disablePadding>
							<ListItem className='assessment-preview-col1'>
								<Typography variant='h4'>Taking initiative & Responsibility</Typography>
							</ListItem>
							<ListItem className='assessment-preview-col2'>
								<Box className='assessment-rating-area'>
									<Rating
										name='assessment-2'
										size='large'
										icon={<StarFilledIcon />}
										emptyIcon={<StarBorderIcon />}
										value={value}
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
									/>
								</Box>
							</ListItem>
						</List>
					</Box>
				</Box>
				<Box className='assessment-preview-list-inner'>
					<Typography className='assessment-preview-index'>3</Typography>
					<Box className='assessment-preview-row'>
						<List disablePadding>
							<ListItem className='assessment-preview-col1'>
								<Typography variant='h4'>Delivery Quality Results</Typography>
							</ListItem>
							<ListItem className='assessment-preview-col2'>
								<Box className='assessment-rating-area'>
									<Rating
										name='assessment-3'
										size='large'
										icon={<StarFilledIcon />}
										emptyIcon={<StarBorderIcon />}
										value={value}
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
									/>
								</Box>
							</ListItem>
						</List>
					</Box>
				</Box>
				<Box className='assessment-preview-list-inner'>
					<Typography className='assessment-preview-index'>4</Typography>
					<Box className='assessment-preview-row'>
						<List disablePadding>
							<ListItem className='assessment-preview-col1'>
								<Typography variant='h4'>Delivery Quality Results</Typography>
							</ListItem>
							<ListItem className='assessment-preview-col2'>
								<TextareaAutosize
									className='response-input-field'
									aria-label='minimum height'
									minRows={3}
									placeholder='Write your response'
								/>
							</ListItem>
						</List>
					</Box>
				</Box>
				<Box className='assessment-preview-list-inner'>
					<Typography className='assessment-preview-index'>5</Typography>
					<Box className='assessment-preview-row'>
						<List disablePadding>
							<ListItem className='assessment-preview-col1'>
								<Typography variant='h4'>Delivery Quality Results</Typography>
							</ListItem>
							<ListItem className='assessment-preview-col2'>
								<TextareaAutosize
									className='response-input-field'
									aria-label='minimum height'
									minRows={3}
									placeholder='Write your response'
								/>
							</ListItem>
						</List>
					</Box>
				</Box> */}
			</Box>
		</Box>
	);
};
