import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import { MainDrawer } from '../../Admin/CommonComponent/MainDrawer';
import { RequestOneOnOneForm } from './RequestOneOnOneForm';
import { MissionVisionIcon } from '../../../config/svg/CommonSvg';
import AlertDialog from '../../Common/Dialog';
import {
	combineDateTime,
	convertDateTimeToUTC,
	generateDefaultTimeOptions,
	getUserDetails,
	urlPatternValidation,
} from '../../../config/utils';
import { createOneOneRequest } from '../../../action/oneOnOneRequest';
import { getFeedbackOneToOneSetting } from '../../../action/adminPerformanceDevelopment';

export const CreateOneOnOne: React.FC<any> = (props) => {
	const { requestOneOnOneDrawer, setRequestOneOnOneDrawer, isDirect = 0, callingType = '' } = props;

	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const userDetail = getUserDetails();
	const staticTitleText = '1-on-1 Meeting';
	const defaultTimeOptions = generateDefaultTimeOptions();
	const staticData = {
		requestId: 0,
		requestedTo: 0,
		onetoOneRemark: '',
		oneToOneTitle: staticTitleText,
		isQuickLaunch: false,
		selectedUser: [],
		meetingLink: '',
		meetingStartDate: new Date(),
		meetingEndDate: new Date(),
		meetingStartTime: defaultTimeOptions[0],
		meetingEndTime: defaultTimeOptions[1],
	};
	const staticErrorObject = {
		error: '',
		helperText: '',
		type: '',
	};

	const [loader, setLoader] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });
	const [oneToOneTitleError, setOneToOneTitleError] = useState<any>(staticErrorObject);
	const [oneToOneUserError, setOneToOneUserError] = useState<any>(staticErrorObject);
	const [oneToOneMeetingLinkError, setOneToOneMeetingLinkError] = useState<any>(staticErrorObject);
	const [isFormEdited, setIsFormEdited] = useState<any>(false);
	const [formData, setFormData] = useState<any>(staticData);
	const [formDataUpdated, setFormDataUpdated] = useState<any>(false);
	const [oneToOneAgendaError, setOneToOneAgendaError] = useState<any>(staticErrorObject);
	const [oneToOneMeetingDateError, setOneToOneMeetingDateError] = useState<any>(staticErrorObject);
	const { oneToOneSettings } = useSelector((state: any) => state?.adminPerformanceDevelopmentReducer);
	useEffect(() => {
		if (!oneToOneSettings) {
			dispatch(getFeedbackOneToOneSetting());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const validateDetails = (requestPayload: any) => {
		if (!requestPayload?.requestedTo) {
			setOneToOneUserError({
				error: true,
				helperText: 'Please select a user',
				type: 'requestedTo',
			});
			return false;
		}
		if (!requestPayload?.oneToOneTitle) {
			setOneToOneTitleError({
				error: true,
				helperText: 'Please enter title',
				type: 'oneToOneTitle',
			});
			return false;
		}
		if (requestPayload?.meetingLink && !urlPatternValidation(requestPayload?.meetingLink)) {
			setOneToOneMeetingLinkError({
				error: true,
				helperText: 'Please enter valid meeting link',
				type: 'oneToOneTitle',
			});
			return false;
		}
		if (!requestPayload?.meetingStartTime || !requestPayload?.meetingEndTime) {
			setOneToOneMeetingDateError({
				error: true,
				helperText: 'Please enter valid meeting date or time',
				type: 'meetingEndTime',
			});
			return false;
		}
		const startDateTime = combineDateTime(requestPayload?.meetingStartDate, requestPayload?.meetingStartTime);
		const endDateTime = combineDateTime(requestPayload?.meetingEndDate, requestPayload?.meetingEndTime);
		if (endDateTime <= startDateTime) {
			setOneToOneMeetingDateError({
				error: true,
				helperText: 'Please enter valid meeting date or time',
				type: 'meetingEndTime',
			});
			return false;
		} else {
			return true;
		}
	};
	const handleSubmitOneOnOneRequest = async (type: string, requestPayload: any, requestType: string) => {
		if (!requestPayload?.oneToOneTitle) {
			requestPayload.oneToOneTitle = staticTitleText;
		}
		if (validateDetails(requestPayload)) {
			setLoader(true);
			delete requestPayload?.selectedUser;
			delete requestPayload?.isError;
			let response: any = null;

			let meetingStartDateTime = convertDateTimeToUTC(requestPayload.meetingStartDate, requestPayload.meetingStartTime);
			let meetingEndDateTime = convertDateTimeToUTC(requestPayload.meetingEndDate, requestPayload.meetingEndTime);
			requestPayload.meetingStartDate = meetingStartDateTime[0] || '';
			requestPayload.meetingStartTime = meetingStartDateTime[1] || '';
			requestPayload.meetingEndDate = meetingEndDateTime[0] || '';
			requestPayload.meetingEndTime = meetingEndDateTime[1] || '';

			if (requestType === 'Direct') {
				response = await dispatch(createOneOneRequest(requestPayload));
				handleAPIResponse(response, requestType, type);
			} else {
				setFormData(staticData);
				setLoader(false);
			}
		}
	};
	const handleAPIResponse = (response: any, requestType: string, type: string) => {
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				setRequestOneOnOneDrawer({ open: false, type: '' });
				setFormData(staticData);
				// const message = '1-on-1 request has been sent successfully';
				// const message = (
				// 	<span>
				// 		1-on-1 Scheduled successfully!
				// 		<br />
				// 		Go to 1-on-1 to start the meeting.
				// 	</span>
				// );
				if (callingType === 'PIP' || callingType === 'Feedback') {
					const msg = (
						<span>
							Meeting created successfully.
							<br />
							Check your 1-on-1 tab under Engage to launch or view details
						</span>
					);
					enqueueSnackbar(msg, {
						variant: 'success',
						autoHideDuration: 3000,
					});
				}
				setLoader(false);
			} else {
				setLoader(false);
				enqueueSnackbar(`Error occurred while sending 1-on-1`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		} else {
			setLoader(false);
			setFormData(staticData);
			enqueueSnackbar(`Error occurred while sending 1-on-1`, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
	const handleDrawerClose = (event: any, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			setOneToOneTitleError(staticErrorObject);
			setOneToOneUserError(staticErrorObject);
			setOneToOneMeetingLinkError(staticErrorObject);
			setOneToOneMeetingDateError(staticErrorObject);
			const requestPayload = { ...formData, isQuickLaunch: false };
			handleSubmitOneOnOneRequest(type, requestPayload, 'Direct');
			setIsFormEdited(false);
		} else {
			if (isFormEdited) {
				setModalProps({
					open: true,
					type: 'unSaveChangeOneOnOne',
					message: t('unSavedItemAlert'),
					details: null,
				});
			} else {
				setOneToOneUserError(staticErrorObject);
				setOneToOneTitleError(staticErrorObject);
				setOneToOneMeetingLinkError(staticErrorObject);
				setOneToOneMeetingDateError(staticErrorObject);
				setRequestOneOnOneDrawer({ open: false, type: '' });
				setIsFormEdited(false);
			}
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChangeOneOnOne') {
				setOneToOneUserError(staticErrorObject);
				setOneToOneTitleError(staticErrorObject);
				setOneToOneMeetingLinkError(staticErrorObject);
				setOneToOneMeetingDateError(staticErrorObject);
				setRequestOneOnOneDrawer({ open: false, type: '' });
				setIsFormEdited(false);

				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setOneToOneUserError(staticErrorObject);
			setOneToOneTitleError(staticErrorObject);
			setOneToOneMeetingLinkError(staticErrorObject);

			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};

	return (
		<>
			<MainDrawer
				open={requestOneOnOneDrawer?.open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('requestOnetoOne')}
				loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<RequestOneOnOneForm
							{...props}
							formData={formData}
							setFormData={setFormData}
							formDataUpdated={formDataUpdated}
							setFormDataUpdated={setFormDataUpdated}
							setIsFormEdited={setIsFormEdited}
							oneToOneTitleError={oneToOneTitleError}
							oneToOneUserError={oneToOneUserError}
							setOneToOneUserError={setOneToOneUserError}
							setOneToOneTitleError={setOneToOneTitleError}
							oneToOneMeetingLinkError={oneToOneMeetingLinkError}
							setOneToOneMeetingLinkError={setOneToOneMeetingLinkError}
							setOneToOneMeetingDateError={setOneToOneMeetingLinkError}
							isEdit={false}
							editableDetails={null}
							isDirect={isDirect ? false : true}
							directEmpDetails={requestOneOnOneDrawer?.empInfo || null}
							staticTitleText={staticTitleText}
							userDetail={userDetail}
							setOneToOneAgendaError={setOneToOneAgendaError}
							oneToOneAgendaError={oneToOneAgendaError}
							oneOnOneSettings={oneToOneSettings}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				draftButtonText={t('launch')}
				draftButtonIcon={<MissionVisionIcon />}
				rightCloseBtn={false}
				isSaveDraft={false}
				isSaveButtonVisible={true}
				isSaveButtonDisabled={loader}
				isDraftButtonDisabled={loader}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
			/>

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
