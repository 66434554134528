import { forgotPasswordAPI } from '../services/ForgotPasswordService';
import * as actionTypes from './actionTypes';

const forgotPasswordPending = () => ({
	type: actionTypes.FORGOT_PASSWORD_PENDING,
});

const forgotPasswordCompleted = (payload) => ({
	type: actionTypes.FORGOT_PASSWORD_SUCCESS,
	payload,
});

const forgotPasswordFailed = (error) => ({
	type: actionTypes.FORGOT_PASSWORD_FAILED,
	error,
});

export const forgotPasswordAction = (data) => {
	return (dispatch) => {
		dispatch(forgotPasswordPending());
		return forgotPasswordAPI(data)
			.then((response) => {
				dispatch(forgotPasswordCompleted(response.data));
			})
			.catch((error) => {
				dispatch(forgotPasswordFailed(error));
			});
	};
};

export const updateStatus = () => ({
	type: actionTypes.UPDATE_STATUS,
});
