import React, { useState } from 'react';
import { Avatar, Box, Checkbox, Chip, Collapse, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TaskListDetails } from './TaskListDetails';
import { AvatarGroup } from '@material-ui/lab';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { HighPriorityIcon, LowPriorityIcon, MediumPriorityIcon } from '../../../config/svg/CommonSvg';
import { Enums } from '../../../config/enums';
import { checkSupportPermission, getUserDetails, getUserName } from '../../../config/utils';
import { TaskListItem } from './TaskListItem';
import { OKRButton } from '../../Common/OKRButton';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NotesImg from '../../../images/no-found-notes.svg';
import { supportModuleAlignOkr } from '../../Admin/AdminConstant';

export const GroupByTaskList: React.FC<any> = (props: any) => {
	const {
		filterDetails,
		taskGroupByList,
		allTaskList,
		showLoadMore,
		pageIndexVal,
		fetchGlobalTask,
		loader,
		handleCollapseTask,
		openGroupByTask,
		callingFrom,
		globalTaskRequest,
	} = props;
	const { t } = useTranslation();
	const userDetail: any = getUserDetails();
	// const taskGroupByType: any = { 0: 'None', 1: 'Delegate', 2: 'Priority', 3: 'Source', 4: 'Week' };

	const RenderNoDataMessage = () => {
		return (
			<>
				{(!globalTaskRequest?.startDate && !globalTaskRequest?.endDate) || callingFrom === 'CheckInTask' ? (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
							<Typography>{t('noTaskFoundSubTitle')}</Typography>
							<AnimatedIcon
								className='no-task-image'
								width='323px'
								type='image/svg+xml'
								alt={t('noTaskHead')}
								imagePath={NotesImg}
							/>
						</Box>
					</Box>
				) : (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{'No records found in the selected date range'}</Typography>
							{/* <Typography>{t('noTaskFoundSubTitle')}</Typography> */}
							<AnimatedIcon
								className='no-task-image'
								width='323px'
								type='image/svg+xml'
								alt={t('noTaskHead')}
								imagePath={NotesImg}
							/>
						</Box>
					</Box>
				)}
			</>
		);
	};

	return (
		<>
			{/* Group By Delegate */}
			{filterDetails?.taskGroupByType === Enums.ONE ? (
				<>
					{taskGroupByList && taskGroupByList?.length ? (
						taskGroupByList.map((record: any, indexVal: number) => (
							<Box
								className='tasks-collapsible full-rectangular-card group-by-delegate'
								key={`deligate_groupby_item_${indexVal}`}
								id={`deligate_groupby_item_${indexVal}`}
							>
								<Box
									className={`tasks-collapsible-body ${
										openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById
											? 'expand-row'
											: ''
									}`}
								>
									<Box className='tasks-collapsible-head' onClick={(e) => handleCollapseTask(e, record.taskGroupById)}>
										<Box className='tasks-collapsible-head-content'>
											<Box className='expand-collapse-icon'>
												{openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById ? '' : ''}
											</Box>
											<Box className='rotating-arrow'>
												<RightArrowIcon />
											</Box>
											<Box className='task-list-panel-head'>
												<Box className='user-info'>
													<Box className='user-img'>
														{record?.imagePath && record?.imagePath.trim() !== '' ? (
															<Avatar
																src={record?.imagePath}
																className={record?.isActive === false ? 'disabled-user' : ''}
															/>
														) : record?.taskGroupByName ? (
															<Avatar className={record?.isActive === false ? 'disabled-user' : ''}>
																{getUserName({ firstName: '', lastName: '', fullName: record?.taskGroupByName })}
															</Avatar>
														) : (
															<></>
														)}
													</Box>
													<Box className='user-info-details'>
														<Typography variant='h4' className={record?.isActive === false ? 'disabled-user' : ''}>
															{record?.taskGroupByName || ''}
														</Typography>
													</Box>
													{/* {callingFrom !== 'CheckInTask' ? ( */}
													<>
														{record?.taskGroupById === userDetail?.employeeId ? (
															<Typography component='span' className='owner-chip' variant='body2'>
																{t('mySelfLabel')}
															</Typography>
														) : record?.isContributor ? (
															<Typography component='span' className='owner-chip' variant='body2'>
																{callingFrom === 'CheckInTask' ? t('directReportLabel') : t('contributorLabel')}
															</Typography>
														) : (
															<></>
														)}
													</>
													{/* ) : (
														<></>
													)} */}
												</Box>
												{record?.isActive === false ? <>Deleted</> : <></>}
												<Typography variant='h6'>Tasks ({record?.taskCount})</Typography>
											</Box>
										</Box>
									</Box>
									<Collapse
										className='tasks-collapsible-content'
										in={openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById}
										timeout='auto'
										unmountOnExit
									>
										<Box className='task-list-panel-inner'>
											{allTaskList && allTaskList?.length ? (
												allTaskList?.map((task: any, index: number) => (
													<TaskListItem {...props} task={task} index={index} />
												))
											) : allTaskList && allTaskList?.length === Enums.ZERO ? (
												<RenderNoDataMessage />
											) : (
												<></>
											)}
										</Box>
										{showLoadMore ? (
											<Box className='load-more-area'>
												<OKRButton
													className='load-more-btn btn-small-text'
													handleClick={() => {
														fetchGlobalTask(pageIndexVal || 0, 0);
													}}
													text={
														loader ? (
															<Box className='spinner'>
																<Box className='bounce1'></Box>
																<Box className='bounce2'></Box>
																<Box className='bounce3'></Box>
															</Box>
														) : (
															t('viewMoreTasks')
														)
													}
												/>
											</Box>
										) : (
											<></>
										)}
									</Collapse>
								</Box>
							</Box>
						))
					) : taskGroupByList && taskGroupByList?.length === Enums.ZERO ? (
						<RenderNoDataMessage />
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
			{/* Group By Priority */}
			{filterDetails?.taskGroupByType === Enums.TWO ? (
				<>
					{taskGroupByList && taskGroupByList?.length ? (
						taskGroupByList.map((record: any, indexVal: number) => (
							<Box
								className='tasks-collapsible full-rectangular-card group-by-kr'
								key={`priority_groupby_item_${indexVal}`}
								id={`priority_groupby_item_${indexVal}`}
							>
								<Box
									className={`tasks-collapsible-body ${
										openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById
											? 'expand-row'
											: ''
									}`}
								>
									<Box className='tasks-collapsible-head' onClick={(e) => handleCollapseTask(e, record.taskGroupById)}>
										<Box className='tasks-collapsible-head-content'>
											<Box className='expand-collapse-icon'>
												{openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById ? '' : ''}
											</Box>
											<Box className='rotating-arrow'>
												<RightArrowIcon />
											</Box>
											<Box className='task-list-panel-head'>
												<Box className='priority-chip'>
													{record?.taskGroupById === Enums.THREE ? (
														<Chip className='high-priority' icon={<HighPriorityIcon />} label='High' />
													) : (
														<></>
													)}
													{record?.taskGroupById === Enums.TWO ? (
														<Chip className='medium-priority' icon={<MediumPriorityIcon />} label='Medium' />
													) : (
														<></>
													)}
													{record?.taskGroupById === Enums.ONE ? (
														<Chip className='low-priority' icon={<LowPriorityIcon />} label='Low' />
													) : (
														<></>
													)}
												</Box>
												<Typography variant='h6'>Tasks ({record?.taskCount})</Typography>
											</Box>
										</Box>
									</Box>
									<Collapse
										className='tasks-collapsible-content'
										in={openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById}
										timeout='auto'
										unmountOnExit
									>
										<Box className='task-list-panel-inner'>
											{allTaskList && allTaskList?.length ? (
												allTaskList?.map((task: any, index: number) => (
													<TaskListItem {...props} task={task} index={index} />
												))
											) : allTaskList && allTaskList?.length === Enums.ZERO ? (
												<RenderNoDataMessage />
											) : (
												<></>
											)}
										</Box>
										{showLoadMore ? (
											<Box className='load-more-area'>
												<OKRButton
													className='load-more-btn btn-small-text'
													handleClick={() => {
														fetchGlobalTask(pageIndexVal || 0, 0);
													}}
													text={
														loader ? (
															<Box className='spinner'>
																<Box className='bounce1'></Box>
																<Box className='bounce2'></Box>
																<Box className='bounce3'></Box>
															</Box>
														) : (
															t('viewMoreTasks')
														)
													}
												/>
											</Box>
										) : (
											<></>
										)}
									</Collapse>
								</Box>
							</Box>
						))
					) : taskGroupByList && taskGroupByList?.length === Enums.ZERO ? (
						<RenderNoDataMessage />
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
			{/* Group By Source */}
			{filterDetails?.taskGroupByType === Enums.THREE ? (
				<>
					{taskGroupByList && taskGroupByList?.length ? (
						taskGroupByList
							.filter((record: any, indexVal: number) => {
								return !(record?.taskGroupByName === 'OKRs' && !checkSupportPermission(supportModuleAlignOkr));
							})
							.map((record: any, indexVal: number) => (
								<Box
									className='tasks-collapsible full-rectangular-card group-by-source'
									key={`source_groupby_item_${indexVal}`}
									id={`source_groupby_item_${indexVal}`}
								>
									<Box
										className={`tasks-collapsible-body ${
											openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById
												? 'expand-row'
												: ''
										}`}
									>
										<Box
											className='tasks-collapsible-head'
											onClick={(e) => handleCollapseTask(e, record.taskGroupById)}
										>
											<Box className='tasks-collapsible-head-content'>
												<Box className='expand-collapse-icon'>
													{openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById
														? ''
														: ''}
												</Box>
												<Box className='rotating-arrow'>
													<RightArrowIcon />
												</Box>
												<Box className='task-list-panel-head'>
													<Typography variant='h4'>{record?.taskGroupByName || ''}</Typography>
													<Typography variant='h6'>Tasks ({record?.taskCount})</Typography>
												</Box>
											</Box>
										</Box>
										<Collapse
											className='tasks-collapsible-content'
											in={openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById}
											timeout='auto'
											unmountOnExit
										>
											<Box className='task-list-panel-inner'>
												{allTaskList && allTaskList?.length ? (
													allTaskList?.map((task: any, index: number) => (
														<TaskListItem {...props} task={task} index={index} />
													))
												) : allTaskList && allTaskList?.length === Enums.ZERO ? (
													<RenderNoDataMessage />
												) : (
													<></>
												)}
											</Box>
											{showLoadMore ? (
												<Box className='load-more-area'>
													<OKRButton
														className='load-more-btn btn-small-text'
														handleClick={() => {
															fetchGlobalTask(pageIndexVal || 0, 0);
														}}
														text={
															loader ? (
																<Box className='spinner'>
																	<Box className='bounce1'></Box>
																	<Box className='bounce2'></Box>
																	<Box className='bounce3'></Box>
																</Box>
															) : (
																t('viewMoreTasks')
															)
														}
													/>
												</Box>
											) : (
												<></>
											)}
										</Collapse>
									</Box>
								</Box>
							))
					) : taskGroupByList && taskGroupByList?.length === Enums.ZERO ? (
						<RenderNoDataMessage />
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
			{/* Group By Week */}
			{filterDetails?.taskGroupByType === Enums.FOUR ? (
				<>
					{taskGroupByList && taskGroupByList?.length ? (
						taskGroupByList.map((record: any, indexVal: number) => (
							<Box
								className='tasks-collapsible full-rectangular-card group-by-source'
								key={`week_groupby_item_${indexVal}`}
								id={`week_groupby_item_${indexVal}`}
							>
								<Box
									className={`tasks-collapsible-body ${
										openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById
											? 'expand-row'
											: ''
									}`}
								>
									<Box className='tasks-collapsible-head' onClick={(e) => handleCollapseTask(e, record.taskGroupById)}>
										<Box className='tasks-collapsible-head-content'>
											<Box className='expand-collapse-icon'>
												{openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById ? '' : ''}
											</Box>
											<Box className='rotating-arrow'>
												<RightArrowIcon />
											</Box>
											<Box className='task-list-panel-head'>
												<Typography variant='h4'>{record?.taskGroupByName || ''}</Typography>
												<Typography variant='h6'>Tasks ({record?.taskCount})</Typography>
											</Box>
										</Box>
									</Box>
									<Collapse
										className='tasks-collapsible-content'
										in={openGroupByTask.taskExpand && openGroupByTask.taskGroupById === record.taskGroupById}
										timeout='auto'
										unmountOnExit
									>
										<Box className='task-list-panel-inner'>
											{allTaskList && allTaskList?.length ? (
												allTaskList?.map((task: any, index: number) => (
													<TaskListItem {...props} task={task} index={index} />
												))
											) : allTaskList && allTaskList?.length === Enums.ZERO ? (
												<RenderNoDataMessage />
											) : (
												<></>
											)}
										</Box>
										{showLoadMore ? (
											<Box className='load-more-area'>
												<OKRButton
													className='load-more-btn btn-small-text'
													handleClick={() => {
														fetchGlobalTask(pageIndexVal || 0, 0);
													}}
													text={
														loader ? (
															<Box className='spinner'>
																<Box className='bounce1'></Box>
																<Box className='bounce2'></Box>
																<Box className='bounce3'></Box>
															</Box>
														) : (
															t('viewMoreTasks')
														)
													}
												/>
											</Box>
										) : (
											<></>
										)}
									</Collapse>
								</Box>
							</Box>
						))
					) : taskGroupByList && taskGroupByList?.length === Enums.ZERO ? (
						<RenderNoDataMessage />
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</>
	);
};
