import {
	SET_CYCLE_DETAILS_DEFAULT_OKR,
	FAIL_CYCLE_DETAILS_DEFAULT_OKR,
	PENDING_CYCLE_DETAILS_DEFAULT_OKR,
	SET_DESIGNATION_DETAILS_DEFAULT_OKR,
	FAIL_DESIGNATION_DETAILS_DEFAULT_OKR,
	PENDING_DESIGNATION_DETAILS_DEFAULT_OKR,
	SET_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	FAIL_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	PENDING_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR,
	OPEN_ADMIN_COPY_OKR,
	SET_TOTAL_EMPLOYEE_COUNT,
	REFRESH_ADMIN_DEFAULT_OKR,
	PAST_SELECTED_CYCLE,
	ALIGN_SELECTED_CYCLE,
} from '../action/actionTypes';

const INITIAL_STATE = {
	cycleDetailsForDefaultOkr: null,
	designationDetailsForDefaultOkr: null,
	performanceRoleDetailsForDefaultOkr: null,
	openCopyOkr: false,
	refreshDefaultOkr: false,
	totalUserCount: 0,
	selectedPastCycle:false,
	alignSelectedCycle:{}
};

export default function adminDefaultOkrReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_CYCLE_DETAILS_DEFAULT_OKR:
			return { ...state, cycleDetailsForDefaultOkr: action.payload };
		case FAIL_CYCLE_DETAILS_DEFAULT_OKR:
			return { ...state, cycleDetailsForDefaultOkr: null };
		case PENDING_CYCLE_DETAILS_DEFAULT_OKR:
			return { ...state, cycleDetailsForDefaultOkr: null };
		case SET_DESIGNATION_DETAILS_DEFAULT_OKR:
			return { ...state, designationDetailsForDefaultOkr: action.payload };
		case FAIL_DESIGNATION_DETAILS_DEFAULT_OKR:
			return { ...state, designationDetailsForDefaultOkr: null };
		case PENDING_DESIGNATION_DETAILS_DEFAULT_OKR:
			return { ...state, designationDetailsForDefaultOkr: null };
		case SET_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR:
			return { ...state, performanceRoleDetailsForDefaultOkr: action.payload };
		case FAIL_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR:
			return { ...state, performanceRoleDetailsForDefaultOkr: null };
		case PENDING_PERFORMANCE_ROLE_DETAILS_DEFAULT_OKR:
			return { ...state, performanceRoleDetailsForDefaultOkr: null };
		case OPEN_ADMIN_COPY_OKR:
			return { ...state, openCopyOkr: action.payload };
		case REFRESH_ADMIN_DEFAULT_OKR:
			return { ...state, refreshDefaultOkr: action.payload };
		case SET_TOTAL_EMPLOYEE_COUNT:
			return { ...state, totalUserCount: action.payload };
		case PAST_SELECTED_CYCLE:
			return { ...state, selectedPastCycle: action.payload };
		case ALIGN_SELECTED_CYCLE:
			return { ...state, alignSelectedCycle: action.payload };
		default:
			return { ...state };
	}
}
