import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link, Tab, Tabs, Typography } from '@material-ui/core';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { DeleteIcon, DownloadIcon } from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { BulkUserDefaultCsvTemplateUrl } from '../../../../../config/url';
import UploadIcon from '../../../../../images/upload-img-new.svg';
import { useSnackbar } from 'notistack';
import { validateDefaultOkrReport } from '../../../../../action/adminDefaultOkr';
import { useDispatch } from 'react-redux';
import exportFromJSON from 'export-from-json';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';

export const ImportAdminOkr: React.FC<any> = (props) => {
	const { mainTabSelected, handleMainTabChange, setLoader, uploadFileData, setUploadFileData,errorLogMessage, setErrorLogMessage } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const preventDefault = (event: any) => event.preventDefault();

	function a11yMainProps(index: number) {
		return {
			id: `import-okr-tabpanel-${index}`,
			'aria-controls': `import-okr-tabpanel-${index}`,
		};
	}
	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};
	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		e.target.value = '';
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		];
		if (size >= 1024) {
			enqueueSnackbar(t('fileSize1MB'), { variant: 'error', autoHideDuration: 5000 });
		} else if (!format.includes(fileType.toLowerCase())) {
			enqueueSnackbar(t('fileTypeDefaultBulkUpload'), { variant: 'error', autoHideDuration: 5000 });
		} else {
			//setLoader(true);
			let fileObj = { filePath: file, fileName: file.name, fileUploaded: true };
			validateBulkUsers(fileObj);
		}
		setTimeout(() => {
			const selectedEl = document.getElementById('uploaded-file-details');
			if (selectedEl) {
				selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			}
		}, 15);

		setErrorLogMessage([]);
	};

	const validateBulkUsers = async (file: any) => {
		if (file.fileName) {
			const formData = new FormData();
			formData.append('formFile', file.filePath);
			const response: any = await dispatch(validateDefaultOkrReport(formData));
			//setLoader(true);
			if (response.data && response.data.status === 200) {
				//	setLoader(false);
				setUploadFileData({ ...uploadFileData, filePath: file.filePath, fileName: file.fileName, fileUploaded: true });
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, {
					variant: 'success',
					autoHideDuration: 5000,
				});
				return response.data;
			} else if (response.data.status === 400) {
				if (response.data.entityList && response.data.entityList.length > 0) {
					const errorDetail = response.data.entityList;
					let err: any[] = [];
					errorDetail.forEach((element:any) => {
						element.bulkErrors.forEach((er:any) => {
							err.push({
								sheetName: element.sheetName,
								key: er.key,
								value: er.value
							})
						})
					});
					setErrorLogMessage(err);
				}
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, {
					variant: 'error',
					autoHideDuration: 5000,
				});
				//setLoader(false);
			}
		}
	};

	const exportToExcel = () => {
		const updatedErrorLogMessage = errorLogMessage.map((item: any) => {
			return {
				'Record Row & Name': item.key, // Replace 'key' with 'Record Row & Name'
				'Error Description': item.value, // Replace 'value' with 'Error Description'
			};
		});
		exportFromJSON({ data: updatedErrorLogMessage, fileName: 'error_report', exportType: 'csv' });
	};

	const removeFile = () => {
		setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'deleteFile') {
				removeFile();
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};
	return (
		<>
			<Box className='drawer-input-field'>
				<Tabs
					className='okr-tabs drawer-tabs import-okr-tab'
					value={mainTabSelected}
					onChange={handleMainTabChange}
					aria-label='Import Tabs'
				>
					<Tab label={t('importOkrs')} value={0} {...a11yMainProps(0)} />
				</Tabs>
			</Box>
			<Box className='drawer-tabs-panel default-okr-import-panel'>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					<input ref={inputFileRef} type='file' style={{ display: 'none' }} onChange={handleFileChange} />
					<Box className='bulk-user-content'>
						<Box className='upload-head'>
							<Typography variant='h5'>
								<Link id={'download-temp'} target='_blank' rel='noopener noreferrer' href={BulkUserDefaultCsvTemplateUrl}>
									{t('downloadTemplate')}
								</Link>{' '}
								{/* <span dangerouslySetInnerHTML={{ __html: t('bulkUploadHeading') }}></span> */}
							</Typography>
						</Box>
						<Box
							className='upload-file'
							onClick={(event: any) => {
								uploadFile(event);
							}}
						>
							<Box className='upload-file-inner'>
								<img src={UploadIcon} alt='Upload File' className='upload-img' />
								<Typography variant='h4'>
									<Link id={'browse-btn'} href='#' onClick={preventDefault}>
										{t('UsersBulkUploadBrowseFile')}
									</Link>
								</Typography>
							</Box>
						</Box>
						{uploadFileData.fileName !== '' ? (
							<Box className='uploading-content'>
								<Box className='uploading-bar'>
									<Box className='bar'>
										<Box className='success'></Box>
										<Box className='inner-bar'>
											<Typography>
												{t('UsersBulkUploadFileName')} : {uploadFileData.fileName ? uploadFileData.fileName : ''}
											</Typography>
											<Button
												className='delete-icon'
												onClick={() =>
													setModalProps({
														open: true,
														module: 'delete',
														type: 'deleteFile',
														message: t('UsersBulkUploadDeleteFile'),
													})
												}
											>
												<DeleteIcon />
											</Button>
										</Box>
									</Box>
								</Box>
							</Box>
						) : (
							<></>
						)}
						{errorLogMessage && errorLogMessage.length > 0 && (
							<Box className='upload-result'>
								<Box className='no-error-mssg'>
									<ErrorIcon />
									<Typography variant='h5'>
										{t('noRecordsAdded')}
									</Typography>
								</Box>
								<Box className='download-error-link' onClick={exportToExcel}>
									<DownloadIcon />
									<Link>{t('downloadErrorReportsText')}</Link>
								</Box>
							</Box>
						)}
					</Box>
				</form>
			</Box>
			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
