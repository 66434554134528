import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemText, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { OKRButton } from '../../../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../../../config/svg/CommonSvgIcon';

export const ResponseTypeDropdown: React.FC<any> = (props: any) => {
	const { selectedVal, optionsList, handleChange } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [selectedValue, setSelectedValue] = useState<any>({});

	/**
	 * auto status true if any user assigned
	 */
	useEffect(() => {
		if (selectedVal) {
			let selectedItem = optionsList.filter((item: any) => item.responseTypeId === selectedVal);
			setSelectedValue(selectedItem.length > 0 ? selectedItem[0] : optionsList[0]);
		} else {
			setSelectedValue(optionsList[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVal, optionsList]);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};

	const handleSelection = (selectedValue: any) => {
		handleClickAway();
		setSelectedValue(selectedValue);
		handleChange(selectedValue);
	};
	return (
		<Box className={`okr-team-dropdown response-type-dropdown  ${open ? 'active' : ''} `}>
			<OKRButton
				className={'dropdown-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				disabled={false}
				text={selectedValue && selectedValue?.responseTypeName ? selectedValue?.responseTypeName : t('noneLable')}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								{optionsList.length > 0 ? (
									<List component='div' disablePadding>
										{optionsList?.map((item: any) => (
											<ListItem
												component='div'
												className={selectedValue?.responseTypeId === item?.responseTypeId ? 'active' : ''}
												onClick={() => {
													handleSelection(item);
												}}
											>
												<ListItemText>{item.responseTypeName}</ListItemText>
											</ListItem>
										))}
									</List>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
