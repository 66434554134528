import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CfrNoRecord } from '../CfrNoRecord';
import NotesImg from '../../../../images/no-found-notes.svg';
import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { OKRButton } from '../../../Common/OKRButton';
import { KebabIcon } from '../../../../config/svg/CommonSvg';
import { PopperMenu } from '../../../Admin/CommonComponent/PopperMenu';
import { DeleteIcon, EditIcon } from '../../../../config/svg/CommonSvgIcon';
import { ReadMore } from '../ReadMore';
import { AddText } from '../AddText';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../../config/enums';
import { getCurrentUserDetails, getTime, GAevent, isTrialVersion } from '../../../../config/utils';
import { EditNotes } from './EditNotes';
import AlertDialog from '../../../Common/Dialog';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../../../services/StorageService';

export const Notes: React.FC<any> = (props) => {
	const {
		tabSelected,
		okr,
		type,
		kr,
		notesCount,
		setNotesCount,
		setLoader,
		setIsCfrUpdated,
		setIsTaskEdited,
		isTaskEdited,
		isPastCycle,
		setUnsavedCfrProps,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [showEditor, setShowEditor] = useState(false);
	const [savedId, setSavedId] = useState(0);
	const [notesContent, setNotesContent] = React.useState<string>('');
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [clickedData, setClickedData] = useState<any>({});
	const [editedNote, setEditedNote] = useState<any>({});
	const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
	const [notesAction, setnotesAction] = useState<any>('');
	const [imageList, setImageList] = useState<any[]>([]);
	const currentUser = getCurrentUserDetails();
	const [pageIndex, setPageIndex] = useState(1);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [listData, setListData] = useState<any[]>([]);
	const [disableBtn, setDisableBtn] = useState<boolean>(false);
	const [listPending, setListPending] = useState<boolean>(true);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {} });

	React.useEffect(() => {
		setListPending(true);
		getNotes();
	}, []);

	React.useEffect(() => {
		if (props.getNotesStatus === 'success') {
			setNotesCount(props.notesList.pagingInfo ? props.notesList.pagingInfo.totalRecords : 0);
			setPageIndex(props.notesList.pagingInfo ? props.notesList.pagingInfo.pageIndex : 1);
			if (
				props.notesList.pagingInfo?.totalPages > 1 &&
				props.notesList.pagingInfo?.totalPages > props.notesList.pagingInfo?.pageIndex
			) {
				setShowLoadMore(true);
			} else {
				setShowLoadMore(false);
			}

			if (props.notesList.pagingInfo?.pageIndex === 1) {
				setListData(props.notesList.entityList);
				let notesHighlight: any = getLocalStorageItem('notesHighlight');
				if (Boolean(notesHighlight)) {
					notesHighlight = JSON.parse(notesHighlight);
					if (notesHighlight.noteId > 0) {
						setSavedId(notesHighlight.noteId);
					}
				}
				setTimeout(() => {
					setSavedId(0);
					removeLocalStorageItem('isNoteRedirection');
					removeLocalStorageItem('notesHighlight');
				}, 7000);
			} else {
				if (props.notesList.entityList) {
					setListData([...listData, ...props.notesList.entityList]);
				} else if (props.notesList.entityList === null) {
					setListData([]);
				}
			}
			setLoader(false);
			setIsTaskEdited(false);
			listPending && setListPending(false);
		} else {
			if (props.getNotesStatus === 'pending') {
				setLoader(true);
			}
		}
	}, [props.getNotesStatus]);

	React.useEffect(() => {
		if (showEditor === true) {
			setEditedNote({});
		}
	}, [showEditor]);

	const getNotes = (page = 1) => {
		let data = '';
		if (type === 1) {
			data = `goalId=${okr.goalObjectiveId}&goalTypeId=1&pageSize=10&pageIndex=${page}`;
		} else if (type === 2) {
			data = `goalId=${kr.goalKeyId}&goalTypeId=2&pageSize=10&pageIndex=${page}`;
		}
		props.getNotes(data);
	};

	const canEditOrModify = () => {
		if (currentUser) {
			return false;
		}
		return true;
	};

	const handleClick = (event: any, data: any) => {
		if (isTaskEdited) {
			setModalProps({ open: true, event: event });
		} else {
			setModalProps({ open: false, event: {} });
			setAnchorEl(anchorEl ? null : event.currentTarget);
			setOpen(true);
			setClickedData(data);
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
		setClickedData({});
	};

	const createNote = (content = '', editedImageList = []) => {
		let payLoad: any = {
			description: notesContent,
			goalTypeId: type === 1 ? 1 : 2,
			goalId: type === 1 ? okr.goalObjectiveId : kr.goalKeyId,
			assignedFiles: [],
			employeeTags: [],
		};
		if (editedNote.noteId) {
			payLoad = {
				description: content,
				noteId: editedNote.noteId,
				assignedFiles: [],
				employeeTags: [],
				isActive: true,
			};
			saveNotes(payLoad, editedImageList);
		} else {
			isTrialVersion() && GAevent('NotesSubmit', 'Clicked tick in Notes to submit', 'Clicked on tick');
			saveNotes(payLoad, imageList);
		}
	};
	const saveNotes = async (payload: any, images: any[]) => {
		if (
			payload.description.trim() === '' ||
			payload.description.trim() === '<p></p>' ||
			payload.description.trim() === '<p><br></p>'
		) {
			return false;
		}
		const elem = document.createElement('span');
		elem.innerHTML = payload.description;
		let tagged = elem.getElementsByClassName('tagged-name');
		for (let i = 0; i < tagged.length; i++) {
			let employeeId: any = tagged[i].getAttribute('rel');
			if (employeeId) {
				let searchType = 1;
				if (employeeId.includes('_teamId')) {
					searchType = 2;
					employeeId = employeeId.replace('_teamId', '');
				}
				payload.employeeTags.push({
					id: parseInt(employeeId),
					searchType: searchType,
				});
			}
		}
		images.forEach((value: any) => {
			if (payload.description.includes(value.filePath)) {
				payload.assignedFiles.push({
					storageFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					fileName: value.fileName,
					filePath: value.filePath,
				});
			}
		});
		let response: any = {};
		if (editedNote.noteId) {
			response = await props.UpdateNotes(payload);
		} else {
			response = await props.addNotes(payload);
		}
		if (response.data.status === Enums.STATUS_SUCCESS) {
			setIsCfrUpdated(true);
			if (!editedNote.noteId) {
				setSavedId(response.data?.entity?.noteId);
				setTimeout(function () {
					setSavedId(0);
				}, 5000);
			}
			setEditedNote({});
			// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
			// 	variant: 'success',
			// 	autoHideDuration: 5000,
			// });
			getNotes();
			hideAddEditor();
			setDisableBtn(false);
		} else {
			// enqueueSnackbar(response?.data?.messageList.message, {
			// 	variant: 'error',
			// 	autoHideDuration: 5000,
			// });
		}
	};
	const hideAddEditor = () => {
		setNotesContent('');
		setShowEditor(false);
	};
	const editNotes = (notes: any) => {
		handleClose();
		hideAddEditor();
		setTimeout(function () {
			setEditedNote(notes);
		}, 200);
	};
	const handleDeleteNote = (note: any) => {
		setEditedNote(note);
		setOpenDeleteDialog(!openDeleteDialog);
		handleClose();
		setnotesAction('delete');
		hideAddEditor();
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			handleConfirmClick();
		}
		setOpenDeleteDialog(!openDeleteDialog);
		setEditedNote({});
		setnotesAction('');
	};

	const handleConfirmClick = async () => {
		let data = `noteId=${editedNote.noteId}`;
		const deleteTaskResponse = await props.deleteNotes(data);
		if (Boolean(deleteTaskResponse) && deleteTaskResponse.data.status === 200) {
			setIsCfrUpdated(true);
			// enqueueSnackbar(t('noteDeleted'), {
			// 	variant: 'success',
			// 	autoHideDuration: 5000,
			// });
			getNotes();
		} else {
			// enqueueSnackbar(t('someErrorOccurred'), {
			// 	variant: 'error',
			// 	autoHideDuration: 5000,
			// });
		}
	};

	const handleCloseModal = (event: any, type: number) => {
		setModalProps({ open: false, event: {} });
		if (type === 1) {
			setIsTaskEdited(false);
			setShowEditor(false);
			setNotesContent('');
		}
	};
	return (
		<>
			<Box className='cfr-tab-inner-head'>
				{canEditOrModify() ? (
					<AddText
						{...props}
						tabSelected={tabSelected}
						showEditor={showEditor}
						setShowEditor={setShowEditor}
						setImageList={setImageList}
						imageList={imageList}
						showAvatar={false}
						placeholderText={t('notesPlaceholdarText')}
						setEditorText={setNotesContent}
						editorText={notesContent}
						handleSave={createNote}
						handleCancel={() => {
							setNotesContent('');
							setEditedNote({});
						}}
						errorMessage={t('notesRequired')}
						showHelpText={true}
						disableBtn={disableBtn}
						setDisableBtn={setDisableBtn}
						isAdded={true}
						isTaskEdited={isTaskEdited}
						setIsTaskEdited={setIsTaskEdited}
						setUnsavedCfrProps={setUnsavedCfrProps}
					/>
				) : null}
				<Box className='count-head'>
					<Typography variant='body2'>
						{notesCount} {t('notesCountLabel')}
					</Typography>
				</Box>
			</Box>
			<Box className={`tab-panel-inner ${listData && listData.length > 0 ? ' ' : 'no-record-available'}`}>
				{listPending ? (
					<Box textAlign='center'>
						<Typography>{t('loading')}</Typography>
					</Box>
				) : (
					<>
						{' '}
						{listData && listData.length > 0 ? (
							<>
								<List className='cfr-list' disablePadding>
									{listData.map((item: any, index: any) => (
										<ListItem className={`${savedId === item.noteId ? 'highlight' : ''}`}>
											<Box className='cfr-list-inner'>
												{editedNote.noteId && item.noteId === editedNote.noteId && notesAction !== 'delete' ? (
													<EditNotes
														{...props}
														createNote={createNote}
														description={item.description}
														editedNote={editedNote}
														setEditedNote={setEditedNote}
														isTaskEdited={isTaskEdited}
														setIsTaskEdited={setIsTaskEdited}
														setUnsavedCfrProps={setUnsavedCfrProps}
													/>
												) : (
													<>
														<Box className='cfr-list-content'>
															<Box className='collapse-content'>
																<ReadMore {...props} text={item.description} isHtmlContent={true} />
															</Box>

															<Box className='timestamp'>
																<Typography variant='body2'>{getTime(item.createdOn)}</Typography>{' '}
																{item.isEdited && canEditOrModify() ? (
																	<Typography variant='body2'>{t('editedLabel')}</Typography>
																) : (
																	<></>
																)}
															</Box>
														</Box>

														{canEditOrModify() && (
															<OKRButton
																title={t('moreOptions')}
																className={`menu-btn ${open && clickedData.noteId === item.noteId ? 'active' : ''}`}
																handleClick={(e: any) => {
																	handleClick(e, item);
																}}
																icon={<KebabIcon />}
															/>
														)}
													</>
												)}
											</Box>
										</ListItem>
									))}
								</List>
								{showLoadMore && (
									<List className='load-more-ul' disablePadding>
										<ListItem className='load-more'>
											<OKRButton
												handleClick={(e) => {
													getNotes(pageIndex + 1 || 1);
												}}
												className={`load-more-btn`}
												text={t('loadMore')}
											/>
										</ListItem>
									</List>
								)}
							</>
						) : (
							<CfrNoRecord
								{...props}
								noRecordHead={t('noNotesHead')}
								noRecordMsg={!canEditOrModify() ? '' : t('noNotesMessage')}
								noRecordImg={NotesImg}
								noImgWidth={148}
							/>
						)}
					</>
				)}
				<PopperMenu
					anchorEl={anchorEl}
					open={open}
					handleClose={handleClose}
					popperClassName={'popper-menu popper-menu-type2 popper-cfr-menu'}
					{...props}
					children={
						<>
							<ListItem
								onClick={() => {
									editNotes(clickedData);
								}}
							>
								<ListItemAvatar>
									<EditIcon />
								</ListItemAvatar>

								<ListItemText primary={t('editNotes')} />
							</ListItem>
							<ListItem
								onClick={() => {
									handleDeleteNote(clickedData);
								}}
							>
								<ListItemAvatar>
									<DeleteIcon />
								</ListItemAvatar>
								<ListItemText primary={t('delete')} />
							</ListItem>
						</>
					}
				></PopperMenu>
			</Box>

			{modalProps.open && (
				<AlertDialog message={t('unSavedItemAlert')} handleCloseModal={handleCloseModal} modalOpen={modalProps.open} />
			)}
			{openDeleteDialog && (
				<AlertDialog
					module='information'
					message={t('deleteNote')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog}
				/>
			)}
		</>
	);
};
