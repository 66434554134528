import React, { Fragment } from 'react';
import { List, ListItem, Typography, Button, Avatar, Box } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { Skeleton } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import noRecordFound from '../../images/search-placeholder.svg';
import { AnimatedIcon } from '../Common/AnimatedIcon';

export default function NoDataFound(props) {
	const { t } = useTranslation();
	return (
		<Fragment>
			{props.loader ? (
				<Box className='okr-listing-content okr-no-listing'>
					<List component='ul'>
						<ListItem className='okr-col1'>
							<Box className='okr-objective'>
								<Skeleton width='100%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
							<Box className='okr-keys'>
								<Skeleton width='25%'>
									<Button className='btn-blue-chip'></Button>
								</Skeleton>
							</Box>
						</ListItem>
						<ListItem className='okr-col2'>
							<Box className='contributors'>
								<Box className='contributors-avatar-group'>
									<Skeleton variant='circle'>
										<Avatar className='contributor-owner' />
									</Skeleton>
									<AvatarGroup max={3} className='contributor-list'>
										<Skeleton variant='circle'>
											<Avatar />
										</Skeleton>
										<Skeleton variant='circle'>
											<Avatar />
										</Skeleton>
										<Skeleton variant='circle'>
											<Avatar />
										</Skeleton>
									</AvatarGroup>
								</Box>
								<Skeleton variant='circle' width={30} height={30}>
									<Button className='add-btn2'></Button>
								</Skeleton>
							</Box>
						</ListItem>
						<ListItem className='okr-col3'>
							<Skeleton width='40%'>
								<Typography>.</Typography>
							</Skeleton>
							<Skeleton width='60%'>
								<Typography>.</Typography>
							</Skeleton>
						</ListItem>
						<ListItem className='okr-col4'>
							<Box className='okr-progress'>
								<Skeleton width='10%'>
									<Typography>.</Typography>
								</Skeleton>
								<Skeleton width='100%' />
								<Skeleton width='60%'>
									<Typography>.</Typography>
								</Skeleton>
							</Box>
						</ListItem>
					</List>
				</Box>
			) : (
				<Box className='no-record-message' align='center'>
					<Typography variant='h2' component='h2'>
						{t('NoRecord')}
					</Typography>
					<Typography variant='h3'>{t('allDone')}</Typography>
					{/* <Typography>{t('noOkr')}</Typography> */}
					<AnimatedIcon
						className='no-record-img'
						width='250'
						type='image/svg+xml'
						alt={t('globalSearchNoRecord')}
						imagePath={noRecordFound}
					/>
				</Box>
			)}
		</Fragment>
	);
}
