import React, { Fragment, useState } from 'react';
import { Box, ClickAwayListener, FormLabel, Grid, Popper, Switch, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ScaleTypeDropdown } from '../ScaleTypeDropdown';
import { PointIcon, ReportsTooltipIcon, ScaleName, ScaleType } from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { numberOnly } from '../../../../../config/utils';
import AlertDialog from '../../../../Common/Dialog';
import { RatingScaleList } from './RatingScaleList';

export const CustomScaleForm: React.FC<any> = (props: any) => {
	const {
		customScaleDetails,
		// setCustomScaleDetails,
		handleScaleNameChange,
		handleScaleActiveToggleClick,
		customScaleFormError,
		// setCustomScaleFormError,
		updateRatingScale,
		handleConfirmDeleteRatingScaleClick,
		// isCustomScaleEdited,
		// setIsCustomScaleEdited,
		customScaleType,
		// scaleDetails,
		handleUpdateDragDropSequence,
		scaleDetailsFromAssessmentForm,
	} = props;
	const { t } = useTranslation();
	// const ratingScaleNameMaxLength = 30;

	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, details: null });

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const checkRatingScaleLength = () => {
		const val =
			customScaleDetails?.ratingScaleDetails && customScaleDetails?.ratingScaleDetails?.length
				? customScaleDetails?.ratingScaleDetails.filter((rec: any) => !rec?.isDisable)
				: [];
		return val && val?.length > 2 ? true : false;
	};
	const handleRatingScaleNameChange = (event: any, scale: any) => {
		const { value } = event?.target || {};
		updateRatingScale(scale, 'ratingScale', value);
	};
	const handleRatingScalePointChange = (event: any, scale: any) => {
		const { value } = event?.target || {};
		// Validate input using regular expression
		if (numberOnly(value)) {
			updateRatingScale(scale, 'point', value ? parseInt(value) : value);
		}
	};
	const handleDeleteRatingScaleItem = (event: any, scale: any) => {
		setOpenDeleteDialog({ open: true, details: { scale } });
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			const { scale } = openDeleteDialog?.details || {};
			if (scale) {
				setOpenDeleteDialog({ open: false, details: null });
				handleConfirmDeleteRatingScaleClick(e, scale);
			}
		} else {
			setOpenDeleteDialog({ open: false, details: null });
		}
	};

	return (
		<>
			<Box className='custom-scale-form'>
				<Box className='custom-scale-top'>
					<Box className='drawer-input-field helperText-bottom '>
						<FormLabel
							id='scale-type'
							component='legend'
							className={`${
								customScaleType === 'View' ||
								customScaleType === 'Edit' ||
								(scaleDetailsFromAssessmentForm && scaleDetailsFromAssessmentForm?.callingFrom === 'AssessmentForm')
									? 'disable-label-txt'
									: ''
							}`}
						>
							<ScaleType />
							{t('scaleType')}
						</FormLabel>
						<ScaleTypeDropdown
							{...props}
							customScaleType={
								scaleDetailsFromAssessmentForm && scaleDetailsFromAssessmentForm?.callingFrom === 'AssessmentForm'
									? 'View'
									: customScaleType
							}
							callingFrom={'manageScale'}
						/>
					</Box>

					<Box className='scale-name-field'>
						<Box className='drawer-input-field '>
							<TextField
								id='scaleNameCustomScale'
								className='field-col2 helperText-bottom'
								label={
									<Fragment>
										<ScaleName />
										{t('scaleName')}
									</Fragment>
								}
								placeholder={t('scaleNamePlaceholder')}
								disabled={customScaleType === 'View' ? true : false}
								value={customScaleDetails?.scaleName || ''}
								name={'scaleName'}
								error={customScaleFormError?.scaleName || false}
								helperText={
									customScaleFormError?.scaleName ? (
										<>
											<ErrorIcon /> {customScaleFormError?.errorMessage || ''}
										</>
									) : null
								}
								onChange={handleScaleNameChange}
								inputProps={{ maxLength: 150 }}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{ 'aria-label': t('scaleNamePlaceholder'), disableUnderline: true }}
							/>
							<Box className={`role-status-field ${customScaleType === 'View' ? 'disable-radio' : ''}`}>
								<Grid component='label' container>
									<Grid item>
										<Box>
											<Switch
												id='isScaleActive'
												name='isScaleActive'
												disabled={customScaleType === 'View' ? true : false}
												checked={customScaleDetails?.isScaleActive || false}
												onChange={handleScaleActiveToggleClick}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										</Box>
									</Grid>
									<Grid item>{t('Active')}</Grid>
								</Grid>
							</Box>
						</Box>
						{/* <Typography variant='h6'>
							{t('charactersRemaining')}: {getRemainingCharacter(customScaleDetails?.scaleName, 150)}
						</Typography> */}
					</Box>
				</Box>
				<Box className='drawer-blue-bg-full'>
					<Box className='rating-type-listing'>
						<Box className='rating-type-head'>
							<Box className='rating-row'>
								<Box className='col-1'></Box>
								<Box className='col-2'>
									<Box className='weightage-head'>
										<Typography variant='h5'>
											<PointIcon />
											{t('points')}
										</Typography>
										<Box
											aria-owns={open ? 'mouse-over-popover' : undefined}
											aria-haspopup='true'
											onClick={handleOpen}
											//onMouseLeave={handleClose}
											component={'div'}
											className='filter-info-box'
										>
											<OKRButton id={'btnId'} icon={<ReportsTooltipIcon />} className={'info-icon'} />
											<Popper
												className={'role-description-popup point-tooltip'}
												open={open}
												anchorEl={anchorEl}
												transition
												placement={'bottom-end'}
											>
												<ClickAwayListener onClickAway={handleClose}>
													<Box className='textarea-expand generate-okr-filter-selected'>
														<Typography variant='body1'>{t('pointsTooltip')}</Typography>
													</Box>
												</ClickAwayListener>
											</Popper>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
						{/* Label Description - Rating List Component */}
						<RatingScaleList
							customScaleDetails={customScaleDetails}
							customScaleType={customScaleType}
							handleRatingScaleNameChange={handleRatingScaleNameChange}
							handleRatingScalePointChange={handleRatingScalePointChange}
							checkRatingScaleLength={checkRatingScaleLength}
							handleDeleteRatingScaleItem={handleDeleteRatingScaleItem}
							handleUpdateDragDropSequence={handleUpdateDragDropSequence}
						/>
					</Box>
				</Box>
				<Box className='note-text'>
					<Typography variant='h6'>{t('customScaleNote')}</Typography>
				</Box>
			</Box>
			{openDeleteDialog && openDeleteDialog?.open && (
				<AlertDialog
					module='deleteMyObjective'
					message={t('deleteRatingScale')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={openDeleteDialog?.open}
				/>
			)}
		</>
	);
};
