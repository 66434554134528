import { getLocalStorageItem } from '../services/StorageService';
import { unlockLearnChangeUrlForDomains } from './constant';

const tenantData = JSON.parse(getLocalStorageItem('TenantData')) || {};
export const BaseUrl = process.env.REACT_APP_API_BASEURL;
export const BaseBlobUrl = process.env.REACT_APP_BLOB_BASEURL;
export const CoursesToken = process.env.REACT_APP_COURSES_TOKEN;
export const coursesBaseURL = process.env.REACT_APP_COURSES_API_ENDPOINT;
export const BaseUnlockU = Object.values(unlockLearnChangeUrlForDomains).includes(window.location.host.split('.')[0])
	? process.env.REACT_APP_UNLOCK_U_SERVER_INFO
	: tenantData?.learnUrl
	? `${tenantData?.learnUrl}/${process.env.REACT_APP_UNLOCK_U_SERVER}`
	: '';
export const BaseUnlockUToken = process.env.REACT_APP_UNLOCK_U_TOKEN;
export const apiEndpointSuffix =
	process.env.REACT_APP_API_END_POINT_SUFFIX === '-' ? '' : process.env.REACT_APP_API_END_POINT_SUFFIX || '';

export const TrialEnvApiUrl = 'https://unlockokr-apim-trial.azure-api.net';
/** notification */
export const NOTIFICATION_BASE_URL = `${BaseUrl}/notification${apiEndpointSuffix}/api/v2/OkrNotifications`;

/** user management  */
export const OkrUserManagement = `${BaseUrl}/user${apiEndpointSuffix}`;
export const OkrAuth = `${BaseUrl}/user${apiEndpointSuffix}`;
export const UserManagement = `${BaseUrl}/admin${apiEndpointSuffix}/api`;
export const TeamManagement = `${BaseUrl}/user${apiEndpointSuffix}`;
export const UnlockUServer = `${BaseUnlockU}`;

//Template Controller Letter
export const TemplateController = `${BaseUrl}/letter${apiEndpointSuffix}`;

/** okr management  */
export const OkrManagement = `${BaseUrl}/okr${apiEndpointSuffix}/api`;
export const OkrDBLogin = `${BaseUrl}?/Identity?`;

/** report  */
export const Reports = 'https://okr-dev-reporting.compunnel.com/api/';

/** feedback */
export const FEEDBACK_BASE_URL = `${BaseUrl}/feedback${apiEndpointSuffix}/api`;

/** alignment */
export const AlignmentMap = `${BaseUrl}/okr${apiEndpointSuffix}/api`;

/** tenant */
export const Tenant = `${BaseUrl}/tenant${apiEndpointSuffix}`;

/**Guided Tour */
export const GuideTour = `${BaseUrl}/guidedtour${apiEndpointSuffix}/api/GuidedTour`;

/**Onboarding */
export const Onboarding = `${BaseUrl}/guidedtour${apiEndpointSuffix}/api/OnBoarding`;

/** Admin */
export const AdminRoles = `${BaseUrl}/user${apiEndpointSuffix}`;

/**Bilk CSV Template url */
export const BulkCsvTemplateUrl = `${BaseBlobUrl}/common/BulkUploadEmployeeTemplate.csv`;

/**Bulk Emails CSV Template url */
export const BulkEmailCsvTemplateUrl = `${BaseBlobUrl}/common/BulkEmailUpdateTemplate.csv`;

/**Bulk Goal Role CSV Template url */
export const BulkGoalRoleCsvTemplateUrl = `${BaseBlobUrl}/common/BulkGoalRoleUploadTemplate.csv`;

/**Bulk Assign Users CSV Template url */
export const BulkAssignUsersCsvTemplateUrl = `${BaseBlobUrl}/common/AssignBulkUsersTemplate.csv`;

/**Bulk Team CSV Template url */
export const BulkTeamCsvTemplateUrl = `${BaseBlobUrl}/common/TeamBulkUploadTemplate.csv`;

/**Self Assessment Guide Template Url */
export const SelfAssessmentGuideTemplateUrl = `https://unlockokrblobcdnprod.azureedge.net/common/Guide_for_Self_Assessment_Rubrics.pdf`;

/**Manager Assessment Guide Template Url */
export const ManagerAssessmentGuideTemplateUrl = `https://unlockokrblobcdnprod.azureedge.net/common/Guide_for_Managers_Enabling_questions.pdf`;

/**Final Assessment Guide Template Url*/
export const FinalAssessmentGuideTemplateUrl = `https://unlockokrblobcdnprod.azureedge.net/common/Guide_for_Potential_Rating.pdf`;

/** team management  */
export const OkrTeamManagement = `${BaseUrl}/user${apiEndpointSuffix}`;

/** okr reports  */
export const OkrReports = `${BaseUrl}/report${apiEndpointSuffix}`;

/** Task */
export const OkrTask = `${BaseUrl}/task${apiEndpointSuffix}`;

/** Notes */
export const okrNote = `${BaseUrl}/conversation${apiEndpointSuffix}`;

/** Conversations */
export const okrConversation = `${BaseUrl}/conversation${apiEndpointSuffix}`;

/** Checkins */
export const okrCheckins = `${BaseUrl}/conversation${apiEndpointSuffix}`;

// Trial Env Api
export const OkrTrialEnv = `${BaseUrl}/okrtrial${apiEndpointSuffix}`;

// Get about us details
export const versionDetails = `${UserManagement}/Master`;
//bulk user default okr
export const BulkUserDefaultCsvTemplateUrl = `${BaseBlobUrl}/common/ImportDefaultOKRTemplate.xlsx`;
/**Bilk CSV Template url */
export const BulkCsvGoalFormUrl = `${BaseBlobUrl}/common/BulkGoalFormUpload.csv`;

//aiBot

export const ubotApiEndpointSuffix =
	process.env.REACT_APP_UBOT_API_END_POINT_SUFFIX === '-' ? '' : process.env.REACT_APP_UBOT_API_END_POINT_SUFFIX || '';
export const aiBotBaseUrl = `https://unlockokr-apim-prod.azure-api.net`;
export const aiBotOKRBaseUrl = `https://aiauthor-api${ubotApiEndpointSuffix}.unlocku.com`;
export const ubotOKRORGID = process.env.REACT_APP_UBOT_ORGID;
export const ubotOKRProjectID = process.env.REACT_APP_UBOT_PROJECTID;
export const ubotOKRORGSecretKey = process.env.REACT_APP_UBOT_ORGSECRETKEY;

/**Synergita Feedback Backup Report Url*/
export const SynergitaFeedbackDumpReportUrl = `https://unlockokrblobcdnprod.azureedge.net/common/Feedback_Dump_Report.xlsx`;
export const SynergitaPIPSummaryReportUrl = `https://unlockokrblobcdnprod.azureedge.net/common/PIP_Summary_Report.xlsx`;
export const SynergitaPromotionStatusReportUrl = `https://unlockokrblobcdnprod.azureedge.net/common/Promotion_Status_Report.xlsx`;
export const SynergitaContinuousFeedbackReportUrl = `https://unlockokrblobcdnprod.azureedge.net/common/Continuous_Feedback_Report.xlsx`;
export const SynergitaPIPPDFReportUrl = `https://unlockokrblobcdnprod.azureedge.net/common/PIP_PDF_Report.zip`;
