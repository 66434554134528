import * as React from 'react';
import { Avatar } from '@material-ui/core';
import { AvatarGroup, Skeleton } from '@material-ui/lab';

interface SkeletonAvatarProps {}

const SkeletonAvatar: React.FC<SkeletonAvatarProps> = () => {
	return (
		<AvatarGroup max={3} className='contributor-list'>
			<Skeleton variant='circle' animation={false}>
				<Avatar />
			</Skeleton>
			<Skeleton variant='circle' animation={false}>
				<Avatar />
			</Skeleton>
		</AvatarGroup>
	);
};

export default SkeletonAvatar;
