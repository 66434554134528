/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import {
	Typography,
	List,
	ListItem,
	Avatar,
	FormControlLabel,
	Checkbox,
	TablePagination,
	Tooltip,
	Box,
	Chip,
	ListItemAvatar,
	ListItemText,
	TextField,
	InputAdornment,
	IconButton,
	Popper,
	ClickAwayListener,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import {
	getRandomBackgroundColor,
	getRandomColor,
	getTooltipLicense,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import '../../../styles/pages/admin/users.scss';
import { useTranslation } from 'react-i18next';
import { checkPermission } from '../../../config/rolePermission';
import { deleteUsers, editUsers } from '../AdminConstant';
import { NoAdminRecords } from '../CommonComponent/NoAdminRecords';
import { BorderCheckboxIcon, CheckedIcon, LeaderIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { OKRButton } from '../../Common/OKRButton';
import {
	UnLockIcon,
	LockIcon,
	SortingIcon,
	EditIcon,
	DeleteIcon,
	EditUserIcon,
	SearchIcon,
	CrossIcon,
	StarIcon,
	InviteUserIcon,
} from '../../../config/svg/CommonSvgIcon';
import { UserTeamSearchFilter } from './UserTeamsSearchFilter';
import AlertDialog from '../../Common/Dialog';
import { DialogComponent } from '../CommonComponent/DialogComponent';
import { Enums } from '../../../config/enums';
import { PopperMenu } from '../CommonComponent/PopperMenu';

import { BulkUserEdit } from './BulkUserEdit';
import { ManageUserPermission } from './ManageUserPermission';
import { CreateUser } from './CreateUser/CreateUser';
import { UserSearchListPopover } from '../CommonComponent/UserSearchListPopover';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import UserImage from '../../../images/my-user-bg.svg';
import { OKRPopper } from '../../Common/OKRPopper';
import { TeamList } from '../../Common/TeamList';
import { AddTeam } from '../Organizations/AddTeam/AddTeam';

export const User: React.FC<any> = (props) => {
	const {
		licenseDetails,
		tabSelected,
		addUserClick,
		getPermissionResult,
		openCreateUserDrawer,
		setOpenCreateUserDrawer,
		isOnHoverVisible = true,
	} = props;
	const loginUser = getUserDetails();

	const initialUserDetail = { employeeId: 0, currentCycleYear: 0, currentCycleId: 0 };
	const [totalUser, setTotalUser] = useState(0);
	const [isApiCalled, setIsApiCalled] = useState(false);
	const [userList, setUserList] = useState([]);
	const [checkedUser, setCheckedUser] = useState<any>([]);
	const [checkedAll, setCheckedAll] = useState(false);
	const [userDetail, setUserDetail] = useState(initialUserDetail);
	const [modalProps, setModalProps] = useState({ open: false, message: '', type: '' });
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [selectedUser, setSelectedUser] = useState<any>({});
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedTeams, setSelectedTeams] = useState<any>([]);
	const [openManageUserPermDrawer, setOpenManageUserPermDrawer] = useState<boolean>(false);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [userRoleUpdated, setUserRoleUpdated] = useState<boolean>(false);
	const [userBulkGoalFormUpdated, setUserBulkGoalFormUpdated] = useState<boolean>(false);
	const [showLoading, setShowLoading] = useState<boolean>(false);

	const [editUserDetails, setEditUserDetail] = useState<any>({});
	const [isUserEdited, setIsUserEdited] = useState(false);
	const [anchorTeamEl, setAnchorTeamEl] = useState<HTMLElement | null>(null);
	const [openPopper, setOpenPopper] = useState<boolean>(false);
	const [currentUser, setCurrentUser] = useState<any>(0);

	useEffect(() => {
		if (props.listUsersPending && (!props.listUsersResult || props.listUsersResult.length === 0)) {
			setShowLoading(true);
		} else {
			setShowLoading(false);
		}
	}, [tabSelected]);

	useEffect(() => {
		if (openCreateUserDrawer === false) {
			setIsUserEdited(false);
			setEditUserDetail({});
		}
	}, [openCreateUserDrawer]);
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (tabSelected === 0) {
			props.getLicenseDetails();
		}
	}, [tabSelected]);

	useEffect(() => {
		if (props.listUsersSuccess && tabSelected === 0) {
			if (
				props.listUsersResult.pagingInfo &&
				props.listUsersResult.pagingInfo.totalPages < pageIndex &&
				props.listUsersResult.pagingInfo.totalPages > 0
			) {
				fetchUsers({
					listingPageIndex: props.listUsersResult.pagingInfo.totalPages,
					lisingPageSize: pageSize,
					sortingColumn: sortingColumn,
					sortOrder: sortOrder,
					teamToFilter: selectedTeams,
					finder: searchTextArray,
				});
			} else {
				setUserList(props.listUsersResult.records);
				setIsApiCalled(false);
				setTotalUser(props.listUsersResult.pagingInfo && props.listUsersResult.pagingInfo.totalRecords);
			}
		}
	}, [props.listUsersSuccess]);

	useEffect(() => {
		if (
			tabSelected === 0 &&
			!props.listUsersPending &&
			(!props.listUsersResult || props.listUsersResult.length === 0)
		) {
			resetListing();
		}
	}, [props.listUsersResult, props.location]);

	useEffect(() => {
		if (userRoleUpdated || userBulkGoalFormUpdated) {
			fetchUsers({
				listingPageIndex: pageIndex,
				lisingPageSize: pageSize,
				sortingColumn: sortingColumn,
				sortOrder: sortOrder,
				teamToFilter: selectedTeams,
				finder: searchTextArray,
			});
			setUserRoleUpdated(false);
			setUserBulkGoalFormUpdated(false);
		}
	}, [userRoleUpdated, userBulkGoalFormUpdated]);

	const resetListing = () => {
		// setUserList([]);
		setCheckedAll(false);
		setCheckedUser([]);
		fetchUsers({
			listingPageIndex: pageIndex,
			lisingPageSize: pageSize,
			sortingColumn: sortingColumn,
			sortOrder: sortOrder,
			teamToFilter: selectedTeams,
			finder: searchTextArray,
		});
	};

	const fetchUsers = ({
		listingPageIndex,
		lisingPageSize,
		sortingColumn = '',
		sortOrder = '',
		teamToFilter = [],
		finder = [],
	}: {
		listingPageIndex: number;
		lisingPageSize: number;
		sortingColumn: string;
		sortOrder: string;
		teamToFilter: any;
		finder: any;
	}) => {
		listingPageIndex = listingPageIndex > 0 ? listingPageIndex : 1;
		props.getUsersList({
			pageIndex: listingPageIndex,
			pageSize: lisingPageSize,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: finder,
			teamToFilter: teamToFilter,
		});
	};

	/*=========== bulk update ================*/
	const handleMenuClick = (e: any, type: any, userDetail: any) => {
		if (type === 'unlockOkr') {
			setUserDetail(userDetail);
			setModalProps({ open: true, message: t('confirmUnlock'), type: 'unlockOkr' });
		}
		if (type === 'editUser') {
			setOpenCreateUserDrawer(true);
			setEditUserDetail(userDetail);
			setIsUserEdited(true);
		}
	};
	const handleChecked = (e: any, user: any) => {
		if (e.target.checked) {
			setCheckedUser([
				...checkedUser,
				{
					employeeId: user.employeeId,
					// year: user.currentCycleYear,
					// cycle: user.currentCycleId,
					reportingTo: user.reportingTo,
				},
			]);
		} else {
			const newCheckedUser = checkedUser.filter((item: any) => item.employeeId !== user.employeeId);
			setCheckedUser(newCheckedUser);
		}
	};
	const handleAllChecked = (e: any) => {
		if (e.target.checked) {
			setCheckedAll(true);
			//======= filter Already selected userId
			const newUserList = userList.filter((user: any) => {
				if (typeof checkedUser.find((item: any) => item.employeeId === user.employeeId) === 'undefined') {
					return true;
				} else {
					return false;
				}
			});
			//====== get all userId in the page
			let newList = newUserList.map(
				(user: any) =>
					typeof checkedUser.find((item: any) => item.employeeId === user.employeeId) === 'undefined' && {
						employeeId: user.employeeId,
						// year: user.currentCycleYear,
						// cycle: user.currentCycleId,
						reportingTo: user.reportingTo,
					}
			);
			setCheckedUser(newList.concat(checkedUser) || []);
		} else {
			setCheckedAll(false);

			const filterUnselected = checkedUser.filter((chkUser: any) => {
				if (typeof userList.find((item: any) => item.employeeId === chkUser.employeeId) === 'undefined') {
					return true;
				} else {
					return false;
				}
			});
			setCheckedUser(filterUnselected);
		}
	};

	const handleInviteUser = async (e: any, user: any) => {
		e.preventDefault();
		if (user) {
			let response = await props.inviteUser({
				employeeId: user.employeeId,
				emailId: user.emailId,
				employeeCode: user.employeeCode,
			});
			if (response.data.status === 200) {
				enqueueSnackbar('User invited successfully', { variant: 'success', autoHideDuration: 3000 });
				setCheckedAll(false);
				setCheckedUser([]);
				resetListing();
			} else {
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 3000 });
			}
		}
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'unlockOkr') {
				let data = [
					{
						employeeId: userDetail.employeeId,
						// year: userDetail.currentCycleYear,
						// cycle: userDetail.currentCycleId,
					},
				];
				const response = await props.unlockUsers(data);
				if (response.data.status === 200) {
					enqueueSnackbar('User unlocked successfully', { variant: 'success', autoHideDuration: 3000 });
					setCheckedAll(false);
					setCheckedUser([]);
					resetListing();
				} else if (response.data.status === 400) {
					const responseAPI = response.data.messageList;
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 3000 });
				}
			}
		}
		setUserDetail(initialUserDetail);
		setModalProps({ open: false, message: '', type: '' });
	};
	/*=========== bulk update End ================*/

	/*========= Sorting ============*/
	const [sortOrder, setSortOrder] = useState('ASC');
	const [sortingColumn, setSortingColumn] = useState('');
	const sortUserList = (e: any, column: any, team?: any) => {
		let newSortOrder = 'desc';
		if (team) {
			newSortOrder = team;
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		} else {
			if (column === sortingColumn) {
				setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
				newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
			} else {
				setSortOrder('desc');
			}
		}

		fetchUsers({
			listingPageIndex: 1,
			lisingPageSize: pageSize,
			sortingColumn: column,
			sortOrder: newSortOrder,
			teamToFilter: selectedTeams,
			finder: searchTextArray,
		});
		setSortingColumn(column);
	};
	/*========= Sorting End ============*/

	/***** ==========  Pagination =========== *****/
	const [pageIndex, setPageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(10);

	const handleChangePage = (event: any, newPage: number) => {
		setCheckedAll(false);
		newPage = newPage + 1;
		setPageIndex(newPage);
		fetchUsers({
			listingPageIndex: newPage,
			lisingPageSize: pageSize,
			sortingColumn: sortingColumn,
			sortOrder: sortOrder,
			teamToFilter: selectedTeams,
			finder: searchTextArray,
		});
	};

	const handleChangeRowsPerPage = async (event: any) => {
		setCheckedAll(false);
		let pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		fetchUsers({
			listingPageIndex: 1,
			lisingPageSize: pSize,
			sortingColumn: sortingColumn,
			sortOrder: sortOrder,
			teamToFilter: selectedTeams,
			finder: searchTextArray,
		});
	};
	/***** ==========  Pagination End =========== *****/

	/***** ========== Top User Searching =========== *****/
	const [searchTextArray, setSearchTextArray] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const handleChange = (e: any) => {
		setSearchValue(e.target.value);
	};
	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleSearch(e.target.value);
			}
			setSearchValue('');
		}
	};
	const handleSearch = (value: any) => {
		setCheckedAll(false);
		const updatedValue: any = [...searchTextArray, value];
		setPageIndex(1);
		setSearchTextArray(updatedValue);
		fetchUsers({
			listingPageIndex: 1,
			lisingPageSize: pageSize,
			sortingColumn: '',
			sortOrder: '',
			teamToFilter: [],
			finder: updatedValue,
		});
	};
	const clearSearch = (searchText: any) => {
		setCheckedAll(false);
		if (searchText === 'all') {
			setPageIndex(1);
			setSearchTextArray([]);
			fetchUsers({
				listingPageIndex: 1,
				lisingPageSize: pageSize,
				sortingColumn: '',
				sortOrder: '',
				teamToFilter: [],
				finder: [],
			});
		} else {
			const newArray = searchTextArray.filter((item) => item !== searchText);
			setPageIndex(1);
			setSearchTextArray(newArray);
			fetchUsers({
				listingPageIndex: 1,
				lisingPageSize: pageSize,
				sortingColumn: '',
				sortOrder: '',
				teamToFilter: [],
				finder: newArray,
			});
		}
	};
	/***** ========== Top User Searching End =========== *****/

	/***** ========== User Delete =========== *****/
	const handleDeleteUser = (e: any, user: any) => {
		setOpenDeleteDialog(true);
		setSelectedUser(user);
	};
	const handleDialogCancelClick = () => {
		setOpenDeleteDialog(false);
	};
	const handleConfirmClick = async () => {
		const response = await props.deleteUsers([selectedUser.employeeId]);
		if (response && response.data && response.data.status === 200) {
			setOpenDeleteDialog(false);
			enqueueSnackbar(t('userDeletedSuccess'), { variant: 'success', autoHideDuration: 3000 });
			props.getAllOrganisations();
			// fetchUsers({
			// 	listingPageIndex: props.listUsersResult.pagingInfo.totalPages,
			// 	lisingPageSize: pageSize,
			// 	sortingColumn: sortingColumn,
			// 	sortOrder: sortOrder,
			// 	teamToFilter: selectedTeams,
			// 	finder: searchTextArray,
			// });
			fetchUsers({
				listingPageIndex: pageIndex,
				lisingPageSize: pageSize,
				sortingColumn: sortingColumn,
				sortOrder: sortOrder,
				teamToFilter: selectedTeams,
				finder: searchTextArray,
			});
			setSelectedUser({});
			props.getLicenseDetails();
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 3000 });
			setSelectedUser({});
			setOpenDeleteDialog(false);
		}
	};
	/***** ========== User Delete End =========== *****/

	const getTranslationText = (txt: string) => {
		return t(txt);
	};

	const getSelectedUserLength = () => {
		const selectedUserCount = userList.filter((chkUser: any) => {
			if (typeof checkedUser.find((item: any) => item.employeeId === chkUser.employeeId) === 'undefined') {
				return false;
			} else {
				return true;
			}
		});
		return selectedUserCount.length;
	};
	/***** ========== Search List User Popover Start =========== *****/
	const [anchorElUserSearch, setAnchorElUserSearch] = useState(null);
	const [openUserSearch, setOpenUserSearch] = useState<any>(false);
	const handleUserOpen = (event: any) => {
		setAnchorElUserSearch(event.currentTarget);
		setOpenUserSearch(true);
	};
	const handleUserClose = () => {
		setOpenUserSearch(false);
		setAnchorElUserSearch(null);
	};

	const handleManagePermission = (e: any, user: any) => {
		setOpenManageUserPermDrawer(!openManageUserPermDrawer);
		setSelectedUser(user);
	};

	/***** ========== Search List User Popover End =========== *****/
	const handleTeamOpen = (event: any, employeeId: any) => {
		setAnchorTeamEl(event.currentTarget);
		setOpenPopper(true);
		setCurrentUser(employeeId);
	};
	const handleTeamClose = () => {
		setOpenPopper(false);
		setAnchorTeamEl(null);
		setCurrentUser(0);
	};

	const teamDisplayLogic = (team: any, charLength: any) => {
		return (
			<Box className='user-team-item'>
				{team.isTeamLeader && (
					<IconButton className='leader-icon'>
						<LeaderIcon />
					</IconButton>
				)}
				<Chip
					className='user-team-chip'
					style={{
						backgroundColor: getRandomBackgroundColor(team?.backGroundColorCode),
						color: getRandomColor(team?.colorCode),
					}}
					avatar={
						team?.imagePath ? (
							<Avatar className='avatar-default' alt={team?.teamName} src={team?.imagePath} />
						) : (
							<Avatar
								style={{
									backgroundColor: getRandomBackgroundColor(team?.backGroundColorCode),
									color: getRandomColor(team?.colorCode),
								}}
								src={team?.imagePath}
							>
								{getUserName({
									firstName: '',
									lastName: '',
									fullName: team?.teamName,
								})}
							</Avatar>
						)
					}
					label={
						team?.teamName.length > charLength ? <>{team?.teamName.substring(0, charLength)}...</> : team?.teamName
					}
				/>
			</Box>
		);
	};

	return (
		<Box className='admin-tabpanel-inner'>
			<Box className='admin-tabpanel-head'>
				<Box className='admin-tabpanel-head-left'>
					<Typography variant='h2'>{t('manageUserLabel')}</Typography>
					{licenseDetails && licenseDetails.purchaseLicense >= 0 ? (
						<Tooltip
							classes={{
								popper:
									licenseDetails.availableLicense === 0 ||
									(licenseDetails.availableLicense === 0 && licenseDetails.bufferLicense === 0)
										? 'tooltip-layout4'
										: 'tooltip-layout2',
							}}
							title={`${getTooltipLicense(licenseDetails.availableLicense, licenseDetails.bufferLicense, t)}`}
							arrow
						>
							<Box className='user-licenses-details'>
								<Typography variant='subtitle2'>{t('seatsLabel')}</Typography>

								<Typography
									variant='subtitle2'
									className={licenseDetails.availableLicense > 5 ? 'green-color' : 'red-color'}
								>
									{licenseDetails.availableLicense}
								</Typography>

								<Typography variant='subtitle2'>{`${t('ofLabel')} ${licenseDetails.purchaseLicense}`}</Typography>
							</Box>
						</Tooltip>
					) : (
						<></>
					)}
				</Box>
				<Box className='admin-tabpanel-head-right'>
					{totalUser ? (
						<TablePagination
							component='div'
							className='user-list-pagination'
							count={totalUser}
							page={pageIndex > 1 ? pageIndex - 1 : 0}
							onPageChange={handleChangePage}
							rowsPerPage={pageSize}
							onRowsPerPageChange={handleChangeRowsPerPage}
							SelectProps={{
								MenuProps: {
									PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
								},
							}}
						/>
					) : (
						<></>
					)}
					<Box className='user-search'>
						<TextField
							className='search-text-field'
							id='user-src-field'
							placeholder={t('searchText')}
							fullWidth
							value={searchValue}
							onChange={(e) => handleChange(e)}
							onKeyDown={(e) => searchClicked(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
						{searchTextArray && searchTextArray.length > 0 && (
							<Box className='user-search-chip'>
								<List>
									{searchTextArray.map((user: any, idx) => {
										if (idx < 3)
											return (
												<ListItem key={`user${user}${idx}`} id={`user-chip-${user}${idx}`}>
													{user.length > 20 ? (
														<Tooltip title={user} arrow>
															<Chip
																label={user.substring(0, 20) + '...'}
																onDelete={(e) => clearSearch(user)}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														</Tooltip>
													) : (
														<Chip
															label={user}
															onDelete={(e) => clearSearch(user)}
															deleteIcon={
																<span>
																	<CrossIcon />
																</span>
															}
														/>
													)}
												</ListItem>
											);
									})}
									{searchTextArray && searchTextArray.length > 3 && (
										<>
											<ListItem
												className='search-more'
												id='src-more'
												aria-owns={openUserSearch ? 'mouse-over-popover' : undefined}
												aria-haspopup='true'
												onMouseOver={isOnHoverVisible && handleUserOpen}
												onMouseLeave={isOnHoverVisible && handleUserClose}
											>
												<Typography>{searchTextArray.length - 3}+</Typography>
												<UserSearchListPopover
													{...props}
													open={openUserSearch}
													setOpen={setOpenUserSearch}
													anchorEl={anchorElUserSearch}
													setAnchorEl={setAnchorElUserSearch}
													searchTextArray={searchTextArray}
													clearSearch={clearSearch}
												/>
											</ListItem>
											<ListItem className='clear-all'>
												<OKRButton
													className='btn-link'
													id='clear-all'
													handleClick={() => clearSearch('all')}
													text={'Clear All'}
												></OKRButton>
											</ListItem>
										</>
									)}
								</List>
							</Box>
						)}
					</Box>
				</Box>
				{checkedUser && checkedUser.length > 0 && (
					<BulkUserEdit {...props} checkedUser={checkedUser} resetListing={resetListing} />
				)}
			</Box>
			{props.listUsersResult.records && props.listUsersResult.records.length > 0 ? (
				<Box className='okr-list-panel-head'>
					<List component='ul'>
						<ListItem className='user-col1'>
							{checkPermission(getPermissionResult?.employeePermissions, editUsers) ||
							checkPermission(getPermissionResult?.employeePermissions, deleteUsers) ? (
								<FormControlLabel
									control={
										<Checkbox
											checked={checkedAll}
											onChange={(e) => handleAllChecked(e)}
											icon={<BorderCheckboxIcon />}
											checkedIcon={<CheckedIcon />}
											indeterminateIcon={<UnCheckedIcon />}
											indeterminate={
												checkedUser &&
												checkedUser.length > 0 &&
												getSelectedUserLength() > 0 &&
												userList.length > getSelectedUserLength()
											}
										/>
									}
									label=''
								/>
							) : (
								<></>
							)}
							<Typography
								variant='body2'
								className={sortingColumn === 'firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
								onClick={(e) => sortUserList(e, 'firstName')}
								id={'sort-name'}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('nameLabel')}</span> <SortingIcon />
								</span>
							</Typography>
						</ListItem>

						<ListItem className='user-col2'>
							<Typography
								variant='body2'
								className={sortingColumn === 'roleName' ? `sort-title ${sortOrder}` : 'sort-title asc'}
								onClick={(e) => sortUserList(e, 'roleName')}
								id={'sort-role-name'}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('roleLabel')}</span> <SortingIcon />
								</span>
							</Typography>
						</ListItem>
						<ListItem className='user-col3'>
							<Typography
								variant='body2'
								className={sortingColumn === 'employeeCode' ? `sort-title ${sortOrder}` : 'sort-title asc'}
								onClick={(e) => sortUserList(e, 'employeeCode')}
								id={'sort-emp'}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('employeeId')}</span> <SortingIcon />
								</span>
							</Typography>
						</ListItem>
						<ListItem className='user-col4'>
							<Typography
								variant='body2'
								className={
									sortingColumn === 'reportingManagerResponse.firstName' ? `sort-title ${sortOrder}` : 'sort-title asc'
								}
								onClick={(e) => sortUserList(e, 'reportingManagerResponse.firstName')}
								id={'sort-reportto'}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('reportingToLabel')}</span> <SortingIcon />
								</span>
							</Typography>
						</ListItem>
						<ListItem className='user-col5'>
							<Typography
								variant='body2'
								className={sortingColumn === 'team' ? `sort-title ${sortOrder}` : 'sort-title asc'}
								id={'sort-team'}
								// onClick={(e) => sortUserList(e, 'reportingToFirstName')}
							>
								<UserTeamSearchFilter
									{...props}
									fetchUsers={fetchUsers}
									sortUserList={sortUserList}
									pageSize={pageSize}
									sortingColumn={sortingColumn}
									setSortingColumn={setSortingColumn}
									setSortOrder={setSortOrder}
									sortOrder={sortOrder}
									setSelectedTeams={setSelectedTeams}
									pageIndex={pageIndex}
									setPageIndex={setPageIndex}
								/>
							</Typography>
						</ListItem>
						{/* Out of scope for 2.9.12 */}
						{/* <ListItem className='user-col6'>
							<Typography
								variant='body2'
								className={sortingColumn === 'recentSession' ? `sort-title ${sortOrder}` : 'sort-title asc'}
								onClick={(e) => sortUserList(e, 'recentSession')}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>{t('recentSession')}</span> <SortingIcon />
								</span>
							</Typography>
						</ListItem> */}
					</List>
				</Box>
			) : (
				<>
					{showLoading && (
						<Box textAlign='center' paddingTop={5}>
							<Typography>{t('loading')}</Typography>
						</Box>
					)}
				</>
			)}

			{props.listUsersResult.records && props.listUsersResult.records.length > 0 ? (
				<Box className='user-listing-area'>
					{userList &&
						userList.map((user: any) => (
							<Box
								key={`userList${user?.employeeId}`}
								className='user-listing-content'
								id={`user-list-${user?.employeeId}`}
							>
								<List component='ul'>
									<ListItem className='user-col1'>
										<Box className='user-col1-left' display='flex' alignItems='center'>
											{checkPermission(getPermissionResult?.employeePermissions, editUsers) ||
											checkPermission(getPermissionResult?.employeePermissions, deleteUsers) ? (
												<>
													{loginUser && loginUser.employeeId === user?.employeeId ? (
														<FormControlLabel
															control={
																<Checkbox
																	id={`userChk${user?.employeeId}`}
																	checked={
																		typeof checkedUser.find((item: any) => item.employeeId === user?.employeeId) !==
																		'undefined'
																	}
																	value={user?.employeeId}
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																	indeterminateIcon={<UnCheckedIcon />}
																/>
															}
															label=''
															disabled={isApiCalled}
														/>
													) : (
														<FormControlLabel
															control={
																<Checkbox
																	id={`userChk${user?.employeeId}`}
																	checked={
																		typeof checkedUser.find((item: any) => item.employeeId === user?.employeeId) !==
																		'undefined'
																	}
																	onChange={(e) => handleChecked(e, user)}
																	value={user?.employeeId}
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																	indeterminateIcon={<UnCheckedIcon />}
																/>
															}
															label=''
														/>
													)}
												</>
											) : (
												<></>
											)}

											<Box className='user-info'>
												<Box className='user-img'>
													{user && user.imagePath && user.imagePath.trim() !== '' ? (
														<Avatar src={user.imagePath} />
													) : user && user.firstName ? (
														<Avatar>{getUserName(user)}</Avatar>
													) : (
														<Avatar></Avatar>
													)}
												</Box>
												<Box className='user-info-details'>
													<Typography variant='h4'>
														{user.firstName} {user.lastName}
													</Typography>
													<Typography variant='subtitle2'>{user.designation}</Typography>
													<Typography className='subtitle3'>{user.emailId}</Typography>
												</Box>
											</Box>
										</Box>
										<Box className='user-actions'>
											{
												// checkPermission(getPermissionResult?.employeePermissions, editUsers) ? (
												// 	<>
												// 		{user && user.lockStatus === -1 && (
												// 			//lockStatus = -1 - for first 15 day of quatrer start - We are using lock gray ICON with confirmation msg
												// 			<OKRButton
												// 				title={getTranslationText('userAlreadyUnlocked')}
												// 				className='unlock-purple-btn'
												// 				icon={<UnLockIcon />}
												// 				id={`unlock-icon-${user?.employeeId}`}
												// 			/>
												// 		)}
												// 		{user && user.lockStatus === 0 && (
												// 			//lockStatus = 0 (default) - action btn manual unlock - We are using lock gray ICON
												// 			<Tooltip title={getTranslationText('userUnclokedLabel')} arrow>
												// 				<OKRButton
												// 					handleClick={(e) => handleMenuClick(e, 'unlockOkr', user)}
												// 					icon={<LockIcon />}
												// 					id={`lock-icon-${user?.employeeId}`}
												// 				/>
												// 			</Tooltip>
												// 		)}
												// 		{user && user.lockStatus === 1 && (
												// 			//lockStatus = 1 - action btn approve users unlock request - We are using lock purple ICON
												// 			<OKRButton
												// 				title={getTranslationText('userUnclokedLabel')}
												// 				handleClick={(e) => handleMenuClick(e, 'unlockOkr', user)}
												// 				icon={<LockIcon />}
												// 				id={`lock-icon-${user?.employeeId}`}
												// 			/>
												// 		)}
												// 		{/* lockStatus = 2 - user request Approved or manually unlocked - We are using unlock purple ICON */}
												// 		{user && user.lockStatus === 2 && (
												// 			<OKRButton
												// 				title={getTranslationText('userAlreadyUnlocked')}
												// 				className='unlock-purple-btn'
												// 				icon={<UnLockIcon />}
												// 				id={`unlock-icon-${user?.employeeId}`}
												// 			/>
												// 		)}
												// 	</>
												// ) : (
												// 	<></>
												// )
											}

											{checkPermission(getPermissionResult.employeePermissions, editUsers) ? (
												<OKRButton
													className='user-edit-icon'
													handleClick={(e) => handleMenuClick(e, 'editUser', user)}
													icon={<EditIcon />}
													title={t('editUserDetailsText')}
													id={`edit-user-icon-${user?.employeeId}`}
												/>
											) : (
												<></>
											)}
											<PopperMenu
												className={'user-menu-btn'}
												anchorEl={anchorEl}
												handleClose={handleClose}
												includeKebabMenu={true}
												menuClicked={popperMenuClicked}
												setPopperMenuClicked={setPopperMenuClicked}
												btnId={`more-btn-${user?.employeeId}`}
												{...props}
												children={
													<>
														{checkPermission(getPermissionResult?.employeePermissions, editUsers) ? (
															<ListItem
																id={`mng-permission-${user?.employeeId}`}
																onClick={(e) => {
																	handleManagePermission(e, user);
																	setPopperMenuClicked(!popperMenuClicked);
																}}
															>
																<ListItemAvatar>
																	<EditUserIcon />
																</ListItemAvatar>
																<ListItemText primary={t('managePermissionsLabel')} />
															</ListItem>
														) : (
															<></>
														)}
														<ListItem
															id={`mng-permission-${user?.employeeId}`}
															onClick={(e) => {
																handleInviteUser(e, user);
																setPopperMenuClicked(!popperMenuClicked);
															}}
														>
															<ListItemAvatar>
																<InviteUserIcon />
															</ListItemAvatar>
															{user?.isInviteEmailSend ? (
																<ListItemText primary={t('inviteAgainKebabLabel')} />
															) : (
																<ListItemText primary={t('inviteUserKebabLabel')} />
															)}
														</ListItem>
														{checkPermission(getPermissionResult?.employeePermissions, deleteUsers) ? (
															<ListItem
																id={`delete-user-${user?.employeeId}`}
																onClick={(e) => handleDeleteUser(e, user)}
															>
																<ListItemAvatar>
																	<DeleteIcon />
																</ListItemAvatar>
																<ListItemText primary={t('delete')} />
															</ListItem>
														) : (
															<></>
														)}
													</>
												}
											></PopperMenu>
										</Box>
									</ListItem>

									<ListItem className='user-col2'>
										<Chip
											//add class when starIcon is visable className='chip-star'
											className={user?.isIndividualPermission ? 'chip-star' : ''}
											label={
												<>
													{user.roleName}
													{user?.isIndividualPermission && (
														<sup>
															<StarIcon />
														</sup>
													)}
												</>
											}
										/>
									</ListItem>
									<ListItem className='user-col3'>
										<Typography variant='h4'>{user?.employeeCode}</Typography>
									</ListItem>
									<ListItem className='user-col4'>
										<Box className='reporting-to-box'>
											<Typography variant='h4'>
												{user?.reportingManagerResponse?.firstName} {user?.reportingManagerResponse?.lastName}
											</Typography>
											<Typography variant='subtitle2'>{user?.reportingManagerResponse?.designation}</Typography>
											<Typography className='subtitle3'>{user?.reportingManagerResponse?.emailId}</Typography>
										</Box>
									</ListItem>
									<ListItem className='user-col5'>
										<Box
											className={
												user?.employeeTeamResponses.length > 1 ? 'user-team-col user-team-multi-col' : 'user-team-col'
											}
											aria-owns={openPopper ? 'mouse-over-popover' : undefined}
											aria-haspopup='true'
											onMouseOver={(e: any) => handleTeamOpen(e, user?.employeeId)}
											onMouseLeave={handleTeamClose}
										>
											<Box className='team-row'>
												{user?.employeeTeamResponses.length > 0 &&
													user?.employeeTeamResponses.map((team: any, index: number) => (
														<Fragment>
															{index <= 1 && teamDisplayLogic(team, user?.employeeTeamResponses.length === 1 ? 14 : 5)}
														</Fragment>
													))}
											</Box>

											<Box className='team-row'>
												{user?.employeeTeamResponses.length >= 3 &&
													user?.employeeTeamResponses.map((team: any, index: number) => (
														<Fragment>{index === 2 && teamDisplayLogic(team, 5)}</Fragment>
													))}
												{user?.employeeTeamResponses.length > 3 && (
													<Box className='user-team-item'>
														<OKRButton className='btn-count' text={`+${user?.employeeTeamResponses.length - 3}`} />
													</Box>
												)}
											</Box>
											<Popper
												key={user?.employeeCode}
												open={openPopper && user?.employeeId === currentUser}
												anchorEl={anchorTeamEl}
												transition
												className={`popper-wrap`}
												placement='bottom-start'
												//container={anchorTeamEl}
											>
												<ClickAwayListener onClickAway={handleClose}>
													<TeamList userList={user?.employeeTeamResponses} user={user} />
												</ClickAwayListener>
											</Popper>
										</Box>
									</ListItem>
									{/* Out of scope for 2.9.12 */}
									{/* <ListItem className='user-col6'>
										<Typography variant='body2'>Oct 3, 2021</Typography>
										<Typography variant='subtitle1'>20:45:47 | EST (UTC -5)</Typography>
										<Typography variant='subtitle1'>NY, USA</Typography>
									</ListItem> */}
								</List>
							</Box>
						))}
				</Box>
			) : props.listUsersSuccess === true && searchTextArray && searchTextArray.length === 0 ? (
				props.listUsersSuccess && <NoAdminRecords addUserClick={addUserClick} {...props} />
			) : props.listUsersSuccess === true ? (
				<Box className='search-not-found'>
					<AnimatedIcon className='' width='369' type='image/svg+xml' alt='UserImage' imagePath={UserImage} />
					<Typography variant='h2'>{t('noUserFound')}</Typography>
				</Box>
			) : (
				<></>
			)}

			{modalProps.open && (
				<AlertDialog
					module='user'
					isCancel={modalProps.type === 'unlockOkr' ? false : true}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}

			{/* Delete user dialog */}
			<DialogComponent
				module='confirmation'
				modalOpen={openDeleteDialog}
				handleCloseModal={handleDialogCancelClick}
				matchString={Enums.DELETE_TEXT}
				handleConfirmClick={handleConfirmClick}
				headingText={t('deleteUser')}
				subHeading={t('deleteUserSubHead')}
			/>

			{openManageUserPermDrawer && (
				<ManageUserPermission
					{...props}
					selectedUser={selectedUser}
					openManageUserPermDrawer={openManageUserPermDrawer}
					setOpenManageUserPermDrawer={setOpenManageUserPermDrawer}
					setUserRoleUpdated={setUserRoleUpdated}
				/>
			)}
			{openCreateUserDrawer && (
				<CreateUser
					{...props}
					handleMenuClick={addUserClick}
					editUserDetails={editUserDetails}
					isEditForm={isUserEdited}
					setUserBulkGoalFormUpdated={setUserBulkGoalFormUpdated}
				/>
			)}
			{props.openAddTeamDrawer && (
				<AddTeam
					{...props}
					handleMenuClick={props.handleMenuClick}
					openAddTeamDrawer={props.openAddTeamDrawer}
					refreshList={false}
					fetchOrgList={() => {}}
				/>
			)}
		</Box>
	);
};
