import { deleteRequest, getRequest, postRequest, putRequest } from '../config/auth';
import {
	GET_ALL_PERSPECTIVE,
	SO_LINKED_OJB_KR,
	GET_ALL_SO_OKR_LIST,
	GET_ALL_SO_TIME_FRAME,
	GET_MISSION_VISION,
	SO_OKR_CREATE_DELETE_UPDATE,
	GET_SO_LINKED_OKR_LIST,
	DELETE_SO_TIME_FRAME,
	GET_ALL_SO_PERMISSION,
	GET_CYCLE_DETAIL_FOR_SO,
	UPDATE_SO_PROGRESS,
	ADMIN_SO_PERSPECTIVE,
	IS_ADMIN_SO_PERSPECTIVE,
	ADMIN_SO_MISSION_VISION,
	ADMIN_SO_MISSION_VISION_HISTORY,
	ADMIN_SO_GET_MISSION_VISION_DETAILS,
	ADMIN_SO_SETTINGS,
	SO_VISIBILITY_USERS,
	SO_EDIT_TIME_FRAME,
	SO_PROGRESS_VISIBILITY_USERS,
} from '../config/api-url';

export const getAllPerspectiveAPI = (data) => postRequest(`${GET_ALL_PERSPECTIVE}`, data);
export const searchObjectiveAPI = (data) => postRequest(`${SO_LINKED_OJB_KR}`, data);
export const getAllSoOkrListAPI = (data) => getRequest(`${GET_ALL_SO_OKR_LIST}?${data}`, data);
export const getAllSoTimeFrameAPI = (data) => getRequest(`${GET_ALL_SO_TIME_FRAME}`, data);
export const landingMissionVisionAPI = (data) => getRequest(`${GET_MISSION_VISION}`, data);
export const createSoAPI = (data) => postRequest(`${SO_OKR_CREATE_DELETE_UPDATE}`, data);
export const deleteSoAPI = (data) => deleteRequest(`${SO_OKR_CREATE_DELETE_UPDATE}?${data}`, data);
export const updateSoAPI = (data) => putRequest(`${SO_OKR_CREATE_DELETE_UPDATE}`, data);
export const getSOLinkedOKRListAPI = (data) => getRequest(`${GET_SO_LINKED_OKR_LIST}?${data}`, data);
export const deleteSoTimeFrameAPI = (data) => deleteRequest(`${DELETE_SO_TIME_FRAME}?${data}`, data);
export const getSoALllPermissionAPI = (data) => getRequest(`${GET_ALL_SO_PERMISSION}`, data);
export const getCycleDetailForSoAPI = (data) => postRequest(`${GET_CYCLE_DETAIL_FOR_SO}`, data);
export const updateSoProgressAPI = (data, queryParam) => putRequest(`${UPDATE_SO_PROGRESS}?${queryParam}`, data);
export const editSoTimeFrameAPI = (data) => putRequest(`${SO_EDIT_TIME_FRAME}`, data);

/**
 * SO Admin Related API Calls
 */
export const CreateUpdateAdminSOMissionVisionAPI = (data) => postRequest(`${ADMIN_SO_MISSION_VISION}`, data);
export const getAdminSOMissionVisionHistoryAPI = (data) => postRequest(`${ADMIN_SO_MISSION_VISION_HISTORY}`, data);
export const getAdminSOMissionVisionDetailsAPI = (data) => getRequest(`${ADMIN_SO_GET_MISSION_VISION_DETAILS}`, data);
export const getAdminSOSettingsAPI = (data) => getRequest(`${ADMIN_SO_SETTINGS}`, data);
export const CreateUpdateAdminSOSettingsAPI = (data) => postRequest(`${ADMIN_SO_SETTINGS}`, data);
export const isPerspectiveVisibleAPI = (data) => postRequest(`${IS_ADMIN_SO_PERSPECTIVE}`, data);
// export const getAdminSOPerspectiveAPI = (data) => getRequest(`${ADMIN_SO_PERSPECTIVE}?${data}`, data);
export const CreateAdminSOPerspectiveAPI = (data) => postRequest(`${ADMIN_SO_PERSPECTIVE}`, data);
export const updateAdminSOPerspectiveAPI = (data) => putRequest(`${ADMIN_SO_PERSPECTIVE}?${data}`, data);
export const deleteAdminSOPerspectiveAPI = (data) => deleteRequest(`${ADMIN_SO_PERSPECTIVE}?${data}`, data);
export const deleteSoVisibilityUserAPI = (data) => deleteRequest(`${SO_VISIBILITY_USERS}`, data);
export const deleteSoProgressVisibilityUserAPI = (data) => deleteRequest(`${SO_PROGRESS_VISIBILITY_USERS}`, data);
