import { getRequest, postRequest, putRequest, deleteRequest } from '../config/auth';
import {
	LIST_USER,
	SEARCH_USER,
	ADD_USER,
	ADD_AD_USER,
	EDIT_USER,
	DELETE_USER,
	UNLOCK_USER,
	DOWNLOAD_USER_SAMPLE,
	UPLOAD_BULK_USER,
	CHANGE_ROLE,
	CHANGE_REPORTING,
	CHANGE_ORGANIZATION,
	GET_DESIGNATION,
	UPDATE_PROFILE,
	GET_PROFILE,
	UPDATE_PROFILE_PIC,
	UPDATE_PROFILE_PASSWORD,
	DELETE_PROFILE_PIC,
	LICENSE_DETAILS,
	VALIDATE_BULK_USER,
	VALIDATE_BULK_EMAILS,
	UPLOAD_BULK_EMAILS,
	GET_USER_DETAILS,
	INVITE_USER,
	ANY_DEFAULT_OKR,
	VALIDATE_BULK_GOAL_ROLE,
	UPLOAD_BULK_GOAL_ROLE,
} from '../config/api-url';

export const getUsersListAPI = (data) => postRequest(`${LIST_USER}`, data);
export const getSearchUsersListAPI = (data) => getRequest(`${SEARCH_USER}?${data}`, data);
export const addUsersAPI = (data) => postRequest(`${ADD_USER}`, data);
export const addAdUsersAPI = (data) => postRequest(`${ADD_AD_USER}`, data);
export const editUsersAPI = (data) => putRequest(`${EDIT_USER}`, data);
export const deleteUsersAPI = (data) => deleteRequest(`${DELETE_USER}`, data);
export const unlockUsersAPI = (data) => postRequest(`${UNLOCK_USER}`, data);
export const changeUsersRoleAPI = (data) => postRequest(`${CHANGE_ROLE}`, data);
export const changeUsersReportingToAPI = (data) => putRequest(`${CHANGE_REPORTING}`, data);
export const changeUsersOrganizationAPI = (data) => putRequest(`${CHANGE_ORGANIZATION}`, data);
export const downloadUserSampleAPI = (data) => getRequest(`${DOWNLOAD_USER_SAMPLE}`, data);
export const uploadUserAPI = (data) => postRequest(`${UPLOAD_BULK_USER}`, data);
export const uploadEmailsAPI = (data) => postRequest(`${UPLOAD_BULK_EMAILS}`, data);
export const getDesignationAPI = (data) => getRequest(`${GET_DESIGNATION}?${data}`, data);
export const getLicenseAPI = (data) => getRequest(`${LICENSE_DETAILS}`, data);
export const inviteUsersAPI = (data) => postRequest(`${INVITE_USER}`, data);

export const getProfileAPI = (data) => getRequest(`${GET_PROFILE}?${data}`, data);
export const updateProfileAPI = (data) => putRequest(`${UPDATE_PROFILE}`, data);
export const updateProfilePicAPI = (data) => putRequest(`${UPDATE_PROFILE_PIC}`, data);
export const updateProfilePasswordAPI = (data) => putRequest(`${UPDATE_PROFILE_PASSWORD}`, data);
export const deleteProfilePicAPI = (data) => deleteRequest(`${DELETE_PROFILE_PIC}`, data);
export const userLogin = (data, queryString) => postRequest(`${LIST_USER}?${queryString}`, data);
export const validateBulkFileUploadAPI = (data) => postRequest(`${VALIDATE_BULK_USER}`, data);
export const validateBulkEmailsUploadAPI = (data) => postRequest(`${VALIDATE_BULK_EMAILS}`, data);
export const getUserDetailsApi = (data) => getRequest(`${GET_USER_DETAILS}?${data}`, data);
export const resetAnyDefaultOkr = (data) => getRequest(`${ANY_DEFAULT_OKR}?${data}`, data);
export const validateBulkGoalRoleUploadAPI = (data) => postRequest(`${VALIDATE_BULK_GOAL_ROLE}`, data);
export const uploadGoalRoleAPI = (data) => postRequest(`${UPLOAD_BULK_GOAL_ROLE}`, data);
