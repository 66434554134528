import React from 'react';
import { Typography, List, ListItem, ButtonGroup, Button } from '@material-ui/core';
import { Avatar, Box } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { getMonthDate } from '../../../config/utils';
// import OkrProgress from '../../MyGoal/OkrProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useTranslation } from 'react-i18next';

const getUserInitials = (firstName, lastName) => `${lastName.charAt(0)}${firstName.charAt(0)}`;

const ChartHeader = ({ showDetails, setShowDetails, objectiveDetails, selectedButton, getReportDetail }) => {
	const { t } = useTranslation();

	return (
		<div className='okr-report-header-wrap'>
			<Box className='okr-report-header-bg'></Box>
			<Box className='okr-report-header'>
				<Typography variant='h4'>{selectedButton === 0 ? t('objectives') : t('KeyResults')}</Typography>
				<ButtonGroup size='large' color='primary' aria-label='button group' className='okr-filter-group'>
					<Button className={selectedButton === 0 ? 'contained-button' : ''} onClick={() => getReportDetail('okr')}>
						{t('objectives')}
					</Button>
					<Button className={selectedButton === 1 ? 'contained-button' : ''} onClick={() => getReportDetail('kr')}>
						{t('keyResultTab')}
					</Button>
				</ButtonGroup>
			</Box>
			<div className='graph-header'>
				{objectiveDetails && (
					<div className='okr-listing-content'>
						{showDetails && (
							<div className='back-btn'>
								<ArrowBackIcon
									onClick={() => setShowDetails(false)}
									color='disabled'
									style={{ fontSize: 16 }}
									className='arrow-back'
								/>
								<Typography onClick={() => setShowDetails(false)} variant='body2'>
									{t('back')}
								</Typography>
							</div>
						)}
						<List component='ul'>
							<ListItem className='okr-col1'>
								<Typography className='graph-description'>
									{selectedButton === 0 ? objectiveDetails.objectiveName : objectiveDetails.keyDescription}
								</Typography>
							</ListItem>
							<ListItem className='okr-col2'>
								<div className='contributors'>
									<AvatarGroup className='avatar-group' max={5}>
										{objectiveDetails.contributors &&
											objectiveDetails.contributors.map((contributor) => (
												<Avatar alt='' src={contributor.imagePath ? contributor.imagePath : ''}>
													{contributor.imagePath ? '' : getUserInitials(contributor.lastName, contributor.firstName)}
												</Avatar>
											))}
									</AvatarGroup>
								</div>
							</ListItem>
							<ListItem className='okr-col3'>
								{objectiveDetails.endDate && (
									<Typography className='okr-date'>{getMonthDate(objectiveDetails.endDate)}</Typography>
								)}
								<Typography className='okr-cycle' variant='body2'>
									{objectiveDetails.dueCycle ? `${t('cycle')}: ${objectiveDetails.dueCycle}` : ''}
								</Typography>
							</ListItem>
							<ListItem className='okr-col4'>
								<Box style={{ width: '100%' }}>
									{/* {objectiveDetails.endDate && (
										<OkrProgress
											type='okr'
											id={selectedButton === 0 ? objectiveDetails.goalObjectiveId : objectiveDetails.goalKeyId}
											okr={objectiveDetails}
											value={objectiveDetails.score}
											dueDate={objectiveDetails.endDate}
										/>
									)} */}
									{objectiveDetails.updatedOn && (
										<Typography variant='body2' className='okr-update'>
											{objectiveDetails.updatedOn
												? `${t('updatedOn')} ${getMonthDate(objectiveDetails.updatedOn)}`
												: ''}
										</Typography>
									)}
								</Box>
							</ListItem>
						</List>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChartHeader;
