import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MultiSelect } from '../../../../CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../../../CommonComponent/NoSearchRecord';
import { getUserListUsingSearchForDefaultOkr } from '../../../../../../action/adminDefaultOkr';
import { Enums } from '../../../../../../config/enums';
import { getUserDetails } from '../../../../../../config/utils';
import { ListRole } from './ListRole';
import { OKRButton } from '../../../../../Common/OKRButton';
import { CancelIcon, TickIcon } from '../../../../../../config/svg/Action';

export const UsersRole: React.FC<any> = (props: any) => {
	const {
		setDefaultOkrFormEdited,
		selectedUsersOptions,
		setSelectedUsersOptions,
		// handleUpdateAssignIds,
		defaultOkrFormDetails,
		allEmployeeChecked,
		handleSelectedRoleDeletion,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetail = getUserDetails();

	const [selectedUser, setSelectedUser] = useState([]);
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [focussed, setFocussed] = React.useState<boolean>(false);
	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});

	// const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
	// 	return dispatch(recognitionSearch(searchStr, page, pageSize, 1));
	// };
	const onSelectedUser = (user: any) => {
		setSelectedUser(user);
		setReportingToOptions([]);
		setDefaultOkrFormEdited(true);
		// setFocussed(true);
		setEmptyRecords({ ...emptyRecords, reportingTo: false });
	};
	const getTeamData = (data: any) => {
		const page = Enums.ONE;
		const pageSize = Enums.MAX_USER_SIZE;
		const type = Enums.ONE;
		const searchText =
			data && data.startsWith('finder=') && data.includes('&') ? data.split('&')[0].split('finder=')[1] : '';

		return dispatch(getUserListUsingSearchForDefaultOkr(searchText, page, pageSize, type));
	};
	const handleUsersSave = () => {
		if (selectedUsersOptions && selectedUsersOptions?.length) {
			const uniqueItem: any = [...selectedUsersOptions];
			selectedUser &&
				selectedUser?.length &&
				selectedUser.forEach((item: any) => {
					const isExist = selectedUsersOptions?.find((rec: any) => rec?.value === item?.value);
					if (!isExist) {
						uniqueItem.push({ ...item });
					}
				});

			setSelectedUsersOptions(uniqueItem);
		} else {
			setSelectedUsersOptions(selectedUser);
		}
		setSelectedUser([]);
	};
	const handleUsersCancel = () => {
		setSelectedUser([]);
	};
	// console.log('UsersRole ==>', selectedUsersOptions);

	return (
		<Box className='user-role-tab'>
			<Box className={`select-role-fields ${selectedUser && selectedUser?.length ? 'selected' : ''}`}>
				<MultiSelect
					key={'default_okr_assign_users_search'}
					id='default_okr_assign_users_search'
					selectedOptions={selectedUser}
					optionsList={reportingToOptions}
					onSelectOption={(value: any) => {
						onSelectedUser(value);
					}}
					blurCalled={(value: any) => {
						setReportingToOptions([]);
					}}
					blurInputOnSelect={true}
					isMulti={true}
					customFilter={() => {}}
					setOptionsList={setReportingToOptions}
					isLoadMore={true}
					placeHolder={t('userPlaceholder')}
					noOptionsMessage={t('userNotFound')}
					showNoOptions={emptyRecords.reportingTo === true}
					fetchAsyncData={true}
					isSingleSelection={false}
					closeMenuOnSelect={true}
					labelTemplate={'avatarLabel'}
					selectClassName={`select-search-dropdown select-search-icon ${
						selectedUser.length === 0 && focussed ? 'error' : ''
					}`}
					selectClassNamePrefix={'react-select'}
					onInputFocus={() => {}}
					performSearch={getTeamData}
					hideLoggedIn={true}
					loggedInUserIds={[]}
					disableSearch={allEmployeeChecked}
					disabled={allEmployeeChecked}
					emptyRecords={emptyRecords}
					setEmptyRecords={setEmptyRecords}
				/>
				{selectedUser && selectedUser?.length ? (
					<Box className='textfield-action-btn'>
						<OKRButton className='cancel-btn' id={'cancel-btn'} icon={<CancelIcon />} handleClick={handleUsersCancel} />
						<OKRButton className='save-btn' id={'save-btn'} icon={<TickIcon />} handleClick={handleUsersSave} />
					</Box>
				) : (
					<></>
				)}
			</Box>
			<Box className='drawer-blue-bg-full'>
				<ListRole
					titleHead={'User'}
					listHeadCol2={t('emailLabel')}
					selectedItemList={selectedUsersOptions || []}
					defaultOkrFormDetails={defaultOkrFormDetails}
					assignType={Enums.THREE}
					allEmployeeChecked={allEmployeeChecked}
					handleSelectedRoleDeletion={handleSelectedRoleDeletion}
				/>
			</Box>
		</Box>
	);
};
