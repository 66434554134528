import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { MultiSelect } from '../../../CommonComponent/MultiSelect';
import { EnumEmailRecipients, Enums } from '../../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailRecipient, updateEmailRecipient } from '../../../../../action/adminPerformanceDevelopment';
import { useSnackbar } from 'notistack';
import { getOkrMasterData } from '../../../../../action/myGoal';

export const EmailRecipientSetting: React.FC<any> = (props: any) => {
	const { t, loader, messageNote, emailModuleId, setLoader } = props;

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	const [emailRecipientOptions, setEmailRecipientOptions] = useState<any>([]);
	const [isEmailRecipientUpdated, setIsEmailRecipientUpdated] = useState<boolean>(false);
	const [emailRecipientDetails, setEmailRecipientDetails] = useState<any>({
		emailModuleId: emailModuleId,
		receipientId: [EnumEmailRecipients.DefaultOnly],
		recipients: [{ label: t('defaultOnlyLabel'), value: EnumEmailRecipients.DefaultOnly, isNotDeletable: true }],
	});

	useEffect(() => {
		dispatch(getOkrMasterData());
		renderDefaultData();
		if (okrMasterData && okrMasterData?.emailReceipientResponse) {
			setEmailRecipientOptions(
				okrMasterData?.emailReceipientResponse.map((data: any) => {
					return {
						...data,
						label: data.name,
						value: data.emailReceipientId,
						isNotDeletable: data.emailReceipientId === EnumEmailRecipients.DefaultOnly,
					};
				})
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderDefaultData = async () => {
		try {
			setLoader(true);
			const emailRecipientRequestParams = `emailModuleId=${emailModuleId}`;
			Promise.allSettled([dispatch(getEmailRecipient(emailRecipientRequestParams))])
				.then((response: any) => {
					if (response) {
						const performancePlanEmailRecipient =
							Boolean(response) && response.length && response[0] && response[0].value ? response[0].value : null;

						if (
							Boolean(performancePlanEmailRecipient) &&
							performancePlanEmailRecipient?.data?.status === Enums.STATUS_SUCCESS
						) {
							const emailRecipient = performancePlanEmailRecipient?.data?.entityList?.map((data: any) => {
								return {
									...data,
									label: data.name,
									value: data.emailReceipientId,
									isNotDeletable: data.emailReceipientId === EnumEmailRecipients.DefaultOnly,
								};
							});
							emailRecipientDetails.recipientsHistory = emailRecipient || [];
							setEmailRecipientDetails({ ...emailRecipientDetails, recipients: emailRecipient || null });
						}
						setLoader(false);
					}
				})
				.catch((error) => {
					console.error(error);
					setEmailRecipientDetails(null);
					setLoader(false);
				});
		} catch (error) {
			console.error(error);
			setEmailRecipientDetails(null);
			setLoader(false);
		}
	};

	const handleEmailRecipientData = (field: string, value: any) => {
		setEmailRecipientDetails({ ...emailRecipientDetails, [field]: value });
		setIsEmailRecipientUpdated(true);
	};

	const updateEmailRecipientAPICall = async () => {
		try {
			if (emailRecipientDetails?.recipients && emailRecipientDetails?.recipients.length > 0) {
				emailRecipientDetails.receipientId = emailRecipientDetails.recipients?.map((data: any) => {
					return data.value;
				});
			}
			const requestParams = emailRecipientDetails ? { ...emailRecipientDetails, emailModuleId: emailModuleId } : null;
			if (requestParams) {
				delete requestParams.recipientsHistory;
				setLoader(true);
				const response: any = await dispatch(updateEmailRecipient(requestParams));
				if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('success', messages || `Performance improvement plan settings updated successfully.`);
					const settingDetails = requestParams
						? {
								...requestParams,
								recipientsHistory: requestParams?.recipients || [],
						  }
						: null;
					setEmailRecipientDetails(settingDetails);
					setIsEmailRecipientUpdated(false);
					setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const resetEmailRecipient = () => {
		try {
			const settingDetails = emailRecipientDetails
				? {
						...emailRecipientDetails,
						recipients: emailRecipientDetails?.recipientsHistory || [],
				  }
				: null;
			setEmailRecipientDetails(settingDetails);
			setIsEmailRecipientUpdated(false);
		} catch (error) {
			console.error(error);
		}
	};

	const showMsg = (variant: any, messages: any) => {
		enqueueSnackbar(messages || `Error occurred while fetching comments`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};

	return (
		<>
			{emailRecipientDetails ? (
				<>
					<Box className='performance-development-card full-rectangular-card pip-send-update-settings'>
						<Box className='pd-card-header'>
							<Typography variant='h4' className='font-weight-normal'>
								{t('sendUpdateEmailsTo')}
							</Typography>
							<Typography variant='h6'>{messageNote}</Typography>
						</Box>
						<MultiSelect
							key={'receipientId'}
							id='receipient_Id_dropdown'
							selectedOptions={emailRecipientDetails?.recipients}
							optionsList={emailRecipientOptions}
							onSelectOption={(value: any) => {
								handleEmailRecipientData('recipients', value);
							}}
							customFilter={(option: any, searchText: any) => {
								return true;
							}}
							placeHolder={t('searchText')}
							isMulti={true}
							noOptionsMessage={''}
							showNoOptions={true}
							fetchAsyncData={false}
							isSingleSelection={false}
							performSearch={(searchText: string) => {}}
							blurCalled={() => {}}
							closeMenuOnSelect={true}
							labelTemplate={'teamAvatarLabel'}
							selectClassName={'select-search-dropdown select-search-team-dropdown'}
							selectClassNamePrefix={'react-select'}
						/>
						<Box className='pd-setting-card-actions'>
							<Box className='pd-setting-card-actions-left'></Box>
							<Box className='pd-setting-card-actions-right'>
								<OKRButton
									className='btn-link'
									text={t('cancelBtn')}
									disabled={loader || !isEmailRecipientUpdated}
									handleClick={resetEmailRecipient}
								/>
								<OKRButton
									className='btn-primary'
									text={t('saveBtn')}
									disabled={loader || !isEmailRecipientUpdated}
									handleClick={updateEmailRecipientAPICall}
								/>
							</Box>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
		</>
	);
};
