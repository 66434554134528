import React from 'react';
import NumberFormat from 'react-number-format';

export const NumberFormatComponent = (props: any) => {
	const { inputRef, onChange, name, ...other } = props;
	const handleValue = (values: any, value: any) => {
		if (value === '0' || value === '') {
			return value;
		} else {
			return values.floatValue;
		}
	};
	return (
		<NumberFormat
			{...other}
			getInputRef={inputRef}
			decimalScale={props?.inputType === 'numberFieldProgress' ? 2 : 0}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: handleValue(values, values.value),
					},
				});
			}}
			thousandSeparator={name === 'currency' ? true : false}
			isNumericString
			allowNegative={false}
			allowLeadingZeros={true}
		/>
	);
};
