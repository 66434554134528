import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';
import { ImportOkrTabs, ImportOkrTabPanel } from './ImportOkrTabs';
import { OkrLibrary } from './OkrLibrary';
import { MyOkrs } from './MyOkrs';
import { PeopleSearch } from './PeopleSearch';
import { GenerateOKRs } from './GenerateOKRs';

export const ImportOKR: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const {
		tabSelected,
		setTabSelected,
		setModalProps,
		modalProps,
		mainTabSelected,
		setMainTabSelected,
		fromCreateImport,
		titleValue,
		okrKrSelectionData,
	} = props;

	const handleTabChange = (event: any, newValue: any) => {
		setTabSelected(newValue);
	};
	const handleMainTabChange = (event: any, newValue: Number) => {
		if (Object.keys(okrKrSelectionData).length > 0) {
			setModalProps({
				...modalProps,
				open: true,
				message: t('unSavedImportAlert'),
				type: 'tab',
				event: { ...event },
				data: newValue,
			});
		} else {
			setMainTabSelected(newValue);
		}
	};
	function a11yMainProps(index: number) {
		return {
			id: `gen-okr-tabpanel-${index}`,
			'aria-controls': `gen-okr-tabpanel-${index}`,
		};
	}
	return (
		<>
			<Tabs
				className='okr-tabs drawer-tabs copy-okr-tab'
				value={mainTabSelected}
				onChange={handleMainTabChange}
				aria-label='Copy Tabs'
			>
				<Tab label={t('generateOKRs')} value={0} {...a11yMainProps(0)} />
				{!fromCreateImport && <Tab label={t('copyOKRs')} value={1} {...a11yMainProps(1)} />}
				{mainTabSelected === 1 ? (
					(titleValue.okrCount > 0 || titleValue.krCount > 0) && (
						<span className='import-okr-count'>{`(Selected ${titleValue.okrCount} Objectives and ${titleValue.krCount} Key Results)`}</span>
					)
				) : (
					<></>
				)}
			</Tabs>
			<Box className='drawer-tabs-panel generate-okr-tabpanel'>
				{mainTabSelected === 0 ? (
					<GenerateOKRs {...props} mainTabSelected={mainTabSelected} />
				) : mainTabSelected === 1 ? (
					<>
						<ImportOkrTabs t={t} tabSelected={tabSelected} handleTabChange={handleTabChange} {...props} />
						<Box className='import-okr-tabpanel'>
							<ImportOkrTabPanel value={tabSelected} index={0} className='my-okrs-tab'>
								<MyOkrs {...props} t={t} />
							</ImportOkrTabPanel>
							<ImportOkrTabPanel value={tabSelected} index={1} className='people-search-tab'>
								<PeopleSearch t={t} {...props} modalProps={modalProps} setModalProps={setModalProps} />
							</ImportOkrTabPanel>
							<ImportOkrTabPanel value={tabSelected} index={2} className='okr-library-tab'>
								<OkrLibrary {...props} t={t} />
							</ImportOkrTabPanel>
						</Box>
					</>
				) : (
					<></>
				)}
			</Box>
		</>
	);
};
