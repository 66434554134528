import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Typography } from '@material-ui/core';
import { ActiveUserIcon, AddUserIcon } from '../../../../config/svg/CommonSvgIcon';

export const TeamColumn: React.FC<any> = (props: any) => {
	const { organizationBox } = props;
	const { t } = useTranslation();
	return (
		<List component='div' className='manage-org-team-box'>
			<ListItem component='div' className='manage-org-team-column' id='org-user-box'>
				<Typography variant='h2' className='mng-org-head'>
					<ActiveUserIcon />
					{organizationBox?.activeUserCount}
				</Typography>

				<Typography variant='h5' className='mng-org-middle'>
					{t('activeUsers')}
				</Typography>

				<Typography variant='h6' className='mng-org-bottom'>
					{t('numberActiveUsers')}
				</Typography>
			</ListItem>
			<ListItem component='div' className='manage-org-team-column' id='org-team-box'>
				<Typography variant='h2' className='mng-org-head'>
					<AddUserIcon /> {organizationBox?.activeTeamCount}
				</Typography>

				<Typography variant='h5' className='mng-org-middle'>
					{t('teamsLabel')}
				</Typography>

				<Typography variant='h6' className='mng-org-bottom'>
					{t('numberTeamOrg')}
				</Typography>
			</ListItem>
		</List>
	);
};
