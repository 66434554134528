import { Box } from '@material-ui/core';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AddUserDrawer from '../../RolesAndPermissions/SystemRolesPermissions/AddUserDrawer';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { AddTeamForm } from './AddTeamForm';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { Enums } from '../../../../config/enums';
import ImageEditor from '../ImageEditor';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import AvatarEditor from 'react-avatar-editor';
import { createUUID, GAevent, isTrialVersion } from '../../../../config/utils';
import { useDispatch } from 'react-redux';
import { addBulkTeamUpload } from '../../../../action/organization';
import exportFromJSON from 'export-from-json';
import { getLocalStorageItem } from '../../../../services/StorageService';

export const AddTeam: React.FC<any> = (props) => {
	const {
		openAddTeamDrawer,
		handleMenuClick,
		isEditRole,
		isEditForm,
		teamDetails,
		refreshList,
		parentDetails,
		isChildTeam,
	} = props;
	const { t } = useTranslation();
	const [teamFormData, setTeamFormData] = useState<any>({
		employeeCount: 0,
		imagePath: '',
		teamName: '',
		parentId: 0,
		teamLeaderEmployeeId: 0,
		teamLeaderId: {
			firstName: '',
			lastName: '',
			employeeId: 0,
			imagePath: '',
		},
	});
	const [selectedAddNewUserTab, setselectedAddNewUserTab] = useState<any>(0);
	const [openUserDrawer, setOpenUserDrawer] = useState<boolean>(false);
	const [assignedUser, setAssignedUser] = useState<any>([]);
	const [assignedUsersCopy, setAssignedUsersCopy] = useState<any>([]);
	const [selectedUserList, setSelectedUserList] = useState<any>([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [userFormEdited, setUserFormEdited] = useState<boolean>(false);
	const [userList, setUserList] = useState<any>([]);
	const { enqueueSnackbar } = useSnackbar();
	const [employeeList, setEmployeeList] = useState<any>([]);
	const [sortOrder, setSortOrder] = useState<any>('asc');
	const [currentSort, setCurrentSort] = useState<any>('');
	const [originalList, setOriginalList] = useState<any>([]);
	const [searchEmployeeText, setSearchEmployeeText] = useState<any>('');
	const [isImageEditor, setIsImageEditor] = useState(false);
	const [loader, setLoader] = useState(false);

	//== ====Upload Logo Functions==== ==//
	const imagePath: any = '';
	const [uploadingPic, setUploadingPic] = useState(false);
	const avatarEditorRef = useRef<AvatarEditor>(null);
	const [imageName, setImageName] = useState('');
	const [image, setImage] = useState(imagePath);
	const [formError, setFormError] = useState({});
	const [teamData, setTeamData] = useState({ imagePath: '', imageName: '' });
	const [uploadedImage, setUploadedImage] = useState('');
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [isSaveInProgress, setIsSaveInProgress] = useState(false);
	const [tlStatus, setTlStatus] = useState(false);

	//=======Bulk Upload state ===========//
	const [uploadFileData, setUploadFileData] = useState({
		filePath: '',
		fileName: '',
		fileSize: 0,
		fileType: '',
		fileUploaded: false,
	});
	const [statusLogMessage, setStatusLogMessage] = useState([]);
	const [bulkTeamEdited, setBulkTeamEdited] = useState<boolean>(false);
	const [downloadButtonClass, setDownloadButtonClass] = useState<boolean>(false);

	useEffect(() => {
		if (isEditForm === true && teamDetails) {
			getAssignedUsers(teamDetails.teamId);
			let teamFormDetails = Object.assign({}, teamFormData);
			teamFormDetails.employeeCount = teamDetails.employeeCount;
			teamFormDetails.teamName = teamDetails.teamName;
			teamFormDetails.imagePath = teamDetails.logoImagePath;
			setImage(teamDetails.logoImagePath);
			setUploadedImage(teamDetails.logoImagePath);
			if (teamDetails.teamHeadResponse) {
				teamFormDetails.teamLeaderEmployeeId = teamDetails.teamHeadResponse.employeeId;
				teamFormDetails.teamLeaderId = {
					fullName: teamDetails.teamHeadResponse.firstName + ' ' + teamDetails.teamHeadResponse.lastName,
					imagePath: teamDetails.teamHeadResponse.imagePath,
					employeeId: teamDetails.teamHeadResponse.employeeId,
				};
				setTlStatus(true);
			}
			if (teamDetails.parentTeamResponse && teamDetails.parentTeamResponse.teamName) {
				teamFormDetails.parentId = [
					{
						label: teamDetails.parentTeamResponse.teamName,
						value: teamDetails.parentTeamResponse.teamId,
						imagePath: teamDetails.parentTeamResponse.logoImagePath,
						secondaryText: teamDetails.parentTeamResponse.teamHeadName,
						count: teamDetails.parentTeamResponse.employeeCount,
						colorCode: teamDetails.parentTeamResponse.colorcode,
						backGroundColorCode: teamDetails.parentTeamResponse.backGroundColorCode,
					},
				];
			}
			setTeamFormData(teamFormDetails);
		}
	}, [teamDetails]);

	const getAssignedUsers = async (teamId: any) => {
		let response = await props.assignedEmployeeTeam(teamDetails.teamId);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			let usersList = response?.data?.entity?.getAssignUsers;
			let updateList = [];
			updateList =
				usersList.length > 0
					? usersList.map((item: any) => {
							return { ...item, alreadyAssigned: true };
					  })
					: [];
			setAssignedUser(updateList);
			setAssignedUsersCopy(updateList);
		}
	};

	useEffect(() => {
		if (parentDetails) {
			let teamFormDetails = Object.assign({}, teamFormData);
			if (parentDetails && parentDetails.teamName) {
				teamFormDetails.parentId = [
					{
						label: parentDetails.teamName,
						value: parentDetails.teamId,
						imagePath: parentDetails.logoImagePath,
						secondaryText: parentDetails.teamHeadName,
						count: parentDetails.employeeCount,
					},
				];
			}
			setTeamFormData(teamFormDetails);
		}
	}, [parentDetails]);

	const showImageEditor = (open: any) => {
		setIsImageEditor(open);
	};

	const updateImageDataImagePath = (formData: any, imageBase64: any) => {
		if (formData && imageBase64) {
			formData && setTeamData({ ...teamData, imagePath: formData });
			imageBase64 && setUploadedImage(imageBase64);
			setImage(imageBase64);
		} else {
			setTeamData({ ...teamData, imagePath: '' });
			setUploadedImage('');
			setImage('');
		}
	};

	const updateImage = async (file: any, imageBase64: any) => {
		if (file && imageBase64) {
			const formData = new FormData();
			const name = imageName && !imageName.includes('svg') ? imageName : createUUID() + '.png';
			formData.append('formFile', file, name);
			updateImageDataImagePath(formData, imageBase64);
		} else {
			updateImageDataImagePath('', '');
		}
	};
	const DataURIToBlob = (dataURI: any) => {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	};

	const handleImageEditiorClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			setUploadingPic(true);
			try {
				if (image && avatarEditorRef && avatarEditorRef.current) {
					setFormEdited(true);
					const imageBase64 = avatarEditorRef.current?.getImageScaledToCanvas().toDataURL(image.type || 'image/png');
					const file = DataURIToBlob(imageBase64);
					updateImage(file, imageBase64);
				} else {
					updateImage('', '');
				}
				showImageEditor(false);
			} catch (err) {
				setUploadingPic(false);
				// console.log(err);
			}
		} else {
			showImageEditor(false);
			setImage(uploadedImage);
		}
	};

	const handleDeleteLogo = () => {
		let organisation = { ...teamFormData };
		organisation.logoImagePath = '';
		setTeamFormData(organisation);
		updateImage('', '');
	};

	const handleFileChange = async (e: any, files: any) => {
		setFormError({});
		const file = files ? files : e.target.files[0];
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = ['image/jpg', 'image/svg+xml', 'image/png', 'image/jpeg'];
		if (size >= 1024) {
			setFormError({ fileSize: true });
		} else if (!format.includes(fileType.toLowerCase())) {
			setFormError({ fileType: true });
		} else {
			let fileData = { ...teamData, imageName: file.name, imagePath: file };
			fileData = files ? fileData : { ...teamData, imagePath: file };
			setFormError({});
			setTeamData(fileData);
		}
	};
	const uploadLogoHandler = async () => {
		if (teamData.imagePath) {
			if (typeof teamData.imagePath === 'string') {
				return teamData.imagePath;
			} else {
				const response = await props.uploadLogo(teamData.imagePath);
				if (Boolean(response) && response.data && response.data.entity) {
					setTeamData({ ...teamData, imagePath: response.data.entity });
					return response.data.entity;
				}
			}
		}
		return '';
	};

	const validateForm = () => {
		let errorList: any = {};
		let isValid = true;
		if (teamFormData.teamName.trim() === '') {
			errorList['teamName'] = 'Please enter Team Name';
			isValid = false;
		}
		/*if (!teamFormData.teamLeaderId || !teamFormData.teamLeaderId.employeeId) {
			errorList['teamLeaderId'] = 'Please select Team Leader';
			isValid = false;
		}
		if (!teamFormData.parentId || teamFormData.parentId.length === 0) {
			errorList['parentId'] = 'Please select Parent Team';
			isValid = false;
		}*/
		setFormError(errorList);
		return isValid;
	};
	const createTeam = async (event: any) => {
		setSearchEmployeeText('');
		setAssignedUser(assignedUsersCopy);
		if (validateForm()) {
			let teamImagePath = await uploadLogoHandler();
			let createPayload: any = {};
			createPayload.teamName = teamFormData.teamName;
			if (teamFormData.teamLeaderId && teamFormData.teamLeaderId.employeeId) {
				createPayload.teamLeaderId = teamFormData.teamLeaderId.employeeId;
			}
			createPayload.parentId =
				teamFormData.parentId && teamFormData.parentId.length > 0 ? teamFormData.parentId[0].value : 0;
			createPayload.assignedUsers = [];
			if (assignedUsersCopy && assignedUsersCopy.length > 0) {
				createPayload.assignedUsers = assignedUsersCopy.map((user: any) => {
					return {
						employeeId: user.employeeId,
						isActive: user.isRemoved ? false : true,
					};
				});
			}
			createPayload.logoImagePath = teamImagePath;
			let response: any = {};
			setIsSaveInProgress(true);
			setLoader(true);
			if (isEditForm === true) {
				createPayload.teamId = teamDetails.teamId;
				setLoader(true);
				response = await props.updateTeam(createPayload);
			} else {
				response = await props.addTeam(createPayload);
			}
			if (response.data.status === Enums.STATUS_SUCCESS) {
				setIsSaveInProgress(false);
				setLoader(false);
				setFormError({});
				showApiMsgs(response?.data?.messageList?.messageSuccess, 'success');
				if (refreshList === true) {
					props.fetchOrgList(props.payload);
				}
				handleMenuClick(event, 'addNewTeam');
			} else {
				setIsSaveInProgress(false);
				setLoader(false);
				if (response?.data?.messageList) {
					if (response?.data?.messageList.message) {
						showApiMsgs(response?.data?.messageList.message, 'error');
					} else {
						const responseAPI = response?.data?.messageList;
						const keys = Object.keys(responseAPI);
						const messages = keys.map((item) => responseAPI[item]);
						//showApiMsgs(`${messages} `, 'error');
						setFormError(response?.data?.messageList);
					}
				}
			}
		}
		if (
			((isEditForm && tlStatus) || !isEditForm) &&
			teamFormData?.teamLeaderId?.firstName === '' &&
			teamFormData?.teamLeaderId?.lastName === '' &&
			(teamFormData?.teamLeaderId?.employeeId === '' || teamFormData?.teamLeaderId?.employeeId === 0) &&
			teamFormData?.teamLeaderId?.imagePath === '' &&
			teamFormData?.parentId !== 0
		) {
			setTimeout(() => {
				showApiMsgs(t('unlockValetAddMsg'), 'success');
			}, 1000);
		}
		setTlStatus(false);
	};
	//=================================//

	/**
	 * Handle drawer actions
	 */
	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			if (isTrialVersion()) {
				GAevent('AddTeam', 'Clicked Add Team', 'Add Team');
			}
			if (selectedAddNewUserTab === 2) {
				uploadBulkTeam(event);
			} else {
				createTeam(event);
			}
			// process.env.REACT_APP_ENV === "trial" && GAevent('AddTeam', 'Clicked Add Team', 'Add Team');
			// createTeam(event);
		} else if (type === 'cancel' || type === 'close') {
			if (formEdited) {
				setModalOpen(true);
			} else if (bulkTeamEdited && selectedAddNewUserTab === 2 && !uploadFileData.fileUploaded) {
				setModalOpen(true);
			} else {
				handleMenuClick(event, 'addNewTeam');
			}
		}
	};
	/**
	 * Handle Role detail and Manage User tab change
	 * @param event HTML Event
	 * @param newValue Number
	 */
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setselectedAddNewUserTab(newValue);
		setStatusLogMessage([]);
		setUploadFileData({
			filePath: '',
			fileName: '',
			fileSize: 0,
			fileType: '',
			fileUploaded: false,
		});
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const handleAddUserDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			setFormEdited(true);
			setAssignedUser([...assignedUser, ...selectedUserList]);
			setAssignedUsersCopy([...assignedUser, ...selectedUserList]);
			setOpenUserDrawer(!openUserDrawer);
			setUserFormEdited(true);
			if (selectedUserList.length) {
				showApiMsgs(t('wellDoneMsg', { numberOfUser: selectedUserList.length }), 'success');
			}
		} else if (type === 'close') {
			if (userFormEdited) {
				setModalOpen(true);
			} else {
				setOpenUserDrawer(!openUserDrawer);
			}
		} else {
			setSelectedUserList(assignedUser);
			setOpenUserDrawer(!openUserDrawer);
		}
	};

	/**
	 * Handle when user click on any records
	 * @param selectedOptions Array
	 */
	const onSelectEmployee = (selectedOptions: any, actionMeta: any) => {
		setUserFormEdited(true);
		setEmployeeList(selectedOptions);
	};

	/**Add users to list panel*/
	const handleAddUserNextClick = () => {
		let filterByEmp = userList.filter((user: any) => {
			return !employeeList.some((item: any) => item.employeeId === user.employeeId);
		});
		let mergeList = [...employeeList, ...selectedUserList];
		setSelectedUserList(mergeList);
		setUserList(filterByEmp);
		setEmployeeList([]);
	};

	/**
	 * Handle delete from add user drawer
	 * @param event HTML Event
	 * @param index Number
	 */
	const handleAddUserDelete = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const allSelectedEmployee = [...selectedUserList];
		const originalListCopy = [...originalList];
		allSelectedEmployee.splice(index, 1);
		let removeSelectedRecords = originalListCopy.filter((x: any) => {
			return !allSelectedEmployee.some((t: any) => t.value === x.value);
		});
		setUserList(removeSelectedRecords);
		setSelectedUserList(allSelectedEmployee);
	};

	/**
	 * Handle sorting in user list drawer
	 * @param type string
	 */
	const handleSortData = (type: any) => {
		let list = _.orderBy(selectedUserList, [(user: any) => user[type].toLowerCase()], sortOrder);
		setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
		setCurrentSort(type === 'label' ? 'label' : 'email');
		setSelectedUserList(list);
	};

	/**
	 * Handle filter in add user drawer
	 * @param option Object
	 * @param searchText string
	 * @returns boolean
	 */
	const customFilter = (option: any, searchText: any) => {
		if (
			option.label.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.emailId.toLowerCase().includes(searchText.toLowerCase()) ||
			option.data.employeeId === parseInt(searchText)
		) {
			return true;
		}
	};

	/**
	 * Toggle assign user drawer
	 */
	const handleAssignModal = () => {
		setSortOrder('asc');
		setSearchEmployeeText('');
		setAssignedUser(assignedUsersCopy);
		setOpenUserDrawer(!openUserDrawer);
	};

	/**
	 * Handle user input in manage user drawer
	 * @param e HTML Event
	 */
	const searchUser = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		let originalAssignedUserList = [...assignedUsersCopy];
		setSearchEmployeeText(value);
		let filtered = originalAssignedUserList.filter((item: any) => {
			if (
				`${item.firstName} ${item.lastName}`.toLowerCase().includes(value.toLowerCase()) ||
				item.emailId.toLowerCase().includes(value.toLowerCase()) ||
				item.employeeId === parseInt(value)
			) {
				return item;
			}
		});
		setAssignedUser(filtered);
	};

	/**
	 * Handle user delete
	 * @param event HTML Event
	 * @param index Number
	 */
	const handleDeleteUser = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const assignedUserList = [...assignedUser];
		if (teamDetails && teamDetails.teamId > 0) {
			if (assignedUserList[index].alreadyAssigned) {
				assignedUserList[index].isRemoved = true;
			} else {
				assignedUserList.splice(index, 1);
			}
		} else {
			assignedUserList.splice(index, 1);
		}
		setAssignedUser(assignedUserList);
		setUserFormEdited(true);
	};

	/**
	 * Fetch all users
	 */
	const fetchUsers = () => {
		const data = `pageIndex=0&pageSize=${Enums.MAX_USER_SIZE}`;
		props.getAllUsers(data);
	};

	/**
	 * fetch user if not avalable in store
	 */
	useEffect(() => {
		if (openUserDrawer && !props.fetchAllUserStatus) {
			if (!props.allUsers?.records?.length) {
				fetchUsers();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openUserDrawer]);

	useEffect(() => {
		if (openUserDrawer && props.allUsers?.records?.length) {
			let allUsersClone = JSON.parse(JSON.stringify(props.allUsers));
			const activeUser = allUsersClone.records.filter((user: any) => user.isActive);
			let filterByEmp = [];
			let updatedList = [];
			filterByEmp = activeUser.filter((user: any) => {
				return assignedUser.length ? !assignedUser.some((item: any) => item.employeeId === user.employeeId) : [];
			});
			updatedList = filterByEmp.map((item: any) => {
				return { ...item, label: `${item.firstName} ${item.lastName}`, value: item.employeeId };
			});
			setOriginalList(updatedList);
			setUserList(updatedList);
		}
		if (!openUserDrawer) {
			setSelectedUserList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.allUsers, openUserDrawer]);

	/**
	 * Handle alert dialog box
	 * @param e HTMLInputElement
	 * @param type Number
	 */
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (openUserDrawer) {
			if (type === 1) {
				setUserFormEdited(false);
				setBulkTeamEdited(false);
				setOpenUserDrawer(false);
			}
			setModalOpen(false);
		} else if (openAddTeamDrawer) {
			if (type === 1) {
				handleMenuClick(e, 'addNewTeam');
			}
			setModalOpen(false);
		} else {
			if (type === 1) {
				handleMenuClick();
			}
			setModalOpen(false);
		}
	};

	//==============Bulk Team Functions start===================//

	/**
	 * Used hooks to dispatch action
	 */
	const dispatch = useDispatch();
	/**
	 * handle Upload Bulk Team API response
	 */
	const validateBulkTeam = async (file: any) => {
		const formData = new FormData();
		formData.append('formFile', file.filePath);
		setLoader(true);
		const response: any = await dispatch(addBulkTeamUpload(formData));
		if (response.data && response.data.status === 200) {
			setLoader(false);
			setUploadFileData({
				...props.uploadFileData,
				filePath: file.filePath,
				fileName: file.fileName,
				fileUploaded: true,
			});
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages} `, 'success');
			if (response.data.entityList && response.data.entityList.length > 0) {
				const errorDetail = response.data.entityList;
				const isErrorFound = errorDetail.some((element: any) => {
					if (element.status === 'Error') {
						return true;
					}
					return false;
				});
				setDownloadButtonClass(isErrorFound);
				exportFromJSON({ data: errorDetail, fileName: 'status_report', exportType: 'csv' });
				setStatusLogMessage(errorDetail);
			}
			setTimeout(() => {
				const selectedEl = document.getElementById('bottom-scroll');
				if (selectedEl) {
					selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
				}
			}, 15);
			return response.data;
		} else if (response.data.status === 400) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages} `, 'error');
			setLoader(false);
		}
	};

	/**
	 * handle Upload Bulk Team on click of save
	 */
	const uploadBulkTeam = async (e: any) => {
		if (uploadFileData.fileSize >= 1024) {
			enqueueSnackbar(t('fileSize1MB'), { variant: 'error', autoHideDuration: 5000 });
		} else {
			let fileObj = { filePath: uploadFileData.filePath, fileName: uploadFileData.fileName };
			validateBulkTeam(fileObj);
		}
	};
	//==============Bulk Team Functions end===================//
	return (
		<Fragment>
			<MainDrawer
				open={openAddTeamDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={isEditForm ? t('editTeamLabel') : t('addTeamLabel')}
				loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<AddTeamForm
							{...props}
							assignedUser={assignedUser}
							selectedAddNewUserTab={selectedAddNewUserTab}
							handleTabChange={handleTabChange}
							searchEmployeeText={searchEmployeeText}
							searchUser={searchUser}
							handleDeleteUser={handleDeleteUser}
							handleAssignModal={handleAssignModal}
							showImageEditor={showImageEditor}
							teamFormData={teamFormData}
							setTeamFormData={setTeamFormData}
							formError={formError}
							setFormError={setFormError}
							image={image}
							setFormEdited={setFormEdited}
							isEditForm={isEditForm}
							isChildTeam={isChildTeam}
							uploadedImage={uploadedImage}
							setUploadFileData={setUploadFileData}
							uploadFileData={uploadFileData}
							statusLogMessage={statusLogMessage}
							setStatusLogMessage={setStatusLogMessage}
							setBulkTeamEdited={setBulkTeamEdited}
							downloadButtonClass={downloadButtonClass}
						/>
					</Box>
				}
				isSaveButtonDisabled={
					isSaveInProgress === true ||
					(selectedAddNewUserTab === 2 && uploadFileData.fileName === '') ||
					(selectedAddNewUserTab === 2 && uploadFileData.fileUploaded)
				}
				saveButtonText={isEditForm ? t('update') : t('saveBtn')}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
			/>

			<MainDrawer
				open={openUserDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				children={
					<Box className='drawer-inner-content'>
						<AddUserDrawer
							t={t}
							userList={userList}
							onSelectEmployee={onSelectEmployee}
							handleAddUserNextClick={handleAddUserNextClick}
							selectedUserList={selectedUserList}
							employeeList={employeeList}
							handleAddUserDelete={handleAddUserDelete}
							handleSortData={handleSortData}
							sortOrder={sortOrder}
							customFilter={customFilter}
							currentSort={currentSort}
							noRecordMessage={t('addUserToTeamMsg')}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				rightCloseBtn={true}
				isSaveButtonDisabled={!selectedUserList.length}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				handleDrawerClose={(event: any) => handleAddUserDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleAddUserDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleAddUserDrawerClose(event, 'cancel')}
			/>
			<MainDrawer
				open={isImageEditor}
				transitionDuration={{ enter: 500, exit: 1000 }}
				children={
					<Box className='drawer-inner-content'>
						<ImageEditor
							{...props}
							imagePath={imagePath}
							handleFileChange={handleFileChange}
							removeLogo={() => {}}
							setImage={setImage}
							image={image}
							avatarEditorRef={avatarEditorRef}
							handleDeleteLogo={handleDeleteLogo}
						/>
					</Box>
				}
				saveButtonText={t('saveBtn')}
				rightCloseBtn={true}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				handleDrawerClose={(event: any) => handleImageEditiorClose(event, 'close')}
				handleSaveClick={(event: any) => handleImageEditiorClose(event, 'save')}
				handleCancelClick={(event: any) => handleImageEditiorClose(event, 'cancel')}
			/>

			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};
