import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/admin/settings/strategicObjective.scss';
import { MissionVision } from './MissionVision';
import LinearLoader from '../../../Common/Loader';
import { PerspectiveList } from './PerspectiveList';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { Enums } from '../../../../config/enums';
import { StrategicObjectiveSettingDetails } from './StrategicObjectiveSettingDetails';
import SoAdminSetting from '../../../../containers/SoAdminSetting';

export const StrategicObjectiveSetting: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const [loader, setLoader] = useState<boolean>(false);
	// const [deleteModalProps, setDeleteModalProps] = useState<any>({ open: false, message: '', details: '', type: '' });

	// const handleCloseModal = async (e: any, type: any) => {
	// 	if (type === 1) {
	// 		if (deleteModalProps?.type === 'Perspective') {
	// 			setDeleteModalProps({ open: false, message: '', details: '', type: '' });
	// 		}
	// 	} else {
	// 		setDeleteModalProps({ open: false, message: '', details: '', type: '' });
	// 	}
	// };

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='so-admin-settings'>
				<Box className='admin-tabpanel-inner'>
					<Box className='admin-tabpanel-head'>
						<Box className='admin-tabpanel-head-left'>
							<Typography variant='h2'>{t('strategicObjectiveSetting')}</Typography>
						</Box>
						<Box className='admin-tabpanel-head-right'></Box>
					</Box>
					<MissionVision t={t} loader={loader} setLoader={setLoader} />
					<SoAdminSetting />
					<PerspectiveList t={t} loader={loader} setLoader={setLoader} />

					{/* {deleteModalProps && deleteModalProps.open && (
						<DialogComponent
							module='confirmation'
							modalOpen={deleteModalProps.open}
							handleCloseModal={(e: any) => handleCloseModal(e, 2)}
							matchString={Enums.DELETE_TEXT}
							handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
							headingText={deleteModalProps?.message?.msg1 || t('soDeleteMsg1')}
							subHeading={deleteModalProps?.message?.msg2 || t('soDeleteMsg2')}
						/>
					)} */}
				</Box>
			</Box>
		</>
	);
};
