import React, { Fragment, useEffect, useState } from 'react';
import { Box, Tabs, Tab, Collapse, List, Checkbox, Typography, Link, ListItem, Avatar } from '@material-ui/core';
import { CheckedIcon, PartialCheckedIcon, UnCheckedIcon } from '../../../config/svg/formElementIcons';
import { useTranslation } from 'react-i18next';
import { ResetIcon, RightArrowIcon, StarIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { MultiSelect } from '../CommonComponent/MultiSelect';
import { NoSearchRecord } from '../CommonComponent/NoSearchRecord';
import { USER_ROLE_ID } from '../../../config/users';
import '../../../styles/pages/admin/manageUserPermissionDrawer.scss';
import { getUserName } from '../../../config/utils';

export const ManageUserPermissionForm: React.FC<any> = (props) => {
	const {
		selectedRole,
		selectedUser,
		handleChangePermissions,
		handleModuleReset,
		handleChangeModule,
		permissionsTree,
		rolesOptions,
		handleRoleChangeDropDown,
		getUserPermissionsStatus,
	} = props;

	const { t } = useTranslation();
	const [open, setOpen] = React.useState({ permissionOpen: false, id: 0 });

	const handleManagePermission = (e: any, roleId: number) => {
		// setOpen((prev) => !prev);
		setOpen({ permissionOpen: roleId === open.id ? !open.permissionOpen : true, id: roleId });
	};

	const stopBubbling = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	return (
		<Fragment>
			<Tabs className='okr-tabs drawer-tabs' value={0} aria-label='Admin Tabs'>
				<Tab label={t('permissionsText')} value={0} />
			</Tabs>

			<Box className='drawer-tabs-panel'>
				<Box className='mng-perm-top'>
					<Box className='user-info'>
						<Box className='user-img'>
							<Avatar
								className='avatar-type2'
								key={`user-icon-${selectedUser.employeeId}`}
								src={selectedUser.imagePath || ''}
							>
								{getUserName({
									firstName: selectedUser.firstName || '',
									lastName: selectedUser.lastName || '',
									fullName: `${selectedUser.firstName} ${selectedUser.lastName}`,
								})}
							</Avatar>
						</Box>

						<Box className='user-info-details'>
							<Typography variant='h4'>
								{selectedUser.firstName} {selectedUser.lastName}
							</Typography>
							<Typography variant='subtitle2'>{selectedUser.designation}</Typography>
						</Box>
					</Box>
					<Box className='user-role-detail'>
						<Box className='user-role-dropdown'>
							<MultiSelect
								key={'selectedRole.roleId'}
								id={'select-user-role'}
								selectedOptions={selectedRole}
								onSelectOption={(value: any) => {
									handleRoleChangeDropDown(value);
								}}
								customFilter={(option: any, searchText: any) => {
									if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
										return true;
									}
								}}
								optionsList={rolesOptions}
								placeHolder={t('assignRoleLabel')}
								noOptionsMessage={<NoSearchRecord />}
								fetchAsyncData={false}
								isSingleSelection={false}
								performSearch={() => {}}
								closeMenuOnSelect={true}
								labelTemplate={'onlyLabel'}
								isMulti={false}
								selectClassName={'user-role-dropdown user-role-dropdown-nobg'}
								selectClassNamePrefix={'user-role'}
								disableSearch={true}
							/>
						</Box>
						{selectedRole && selectedRole[0]?.roleDescription ? (
							<Box className='role-description'>
								<Box dangerouslySetInnerHTML={{ __html: selectedRole[0]?.roleDescription }}></Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</Box>
				<Box className='user-permissions-panel'>
					<Box className='user-permissions-list'>
						<Box className='user-permissions-head'>
							<Typography variant='h5'>{t('userPermissionsLabel')}</Typography>
							<Link id={'reset-all'} onClick={() => handleModuleReset()}>
								<ResetIcon />
								{t('resetAllLabel')}
							</Link>
						</Box>
						{props.getUserPermissionsStatus === 'success' ? (
							<Box className='permission-collapsible'>
								{permissionsTree &&
									permissionsTree.length > 0 &&
									permissionsTree.map((role: any, roleIndex: number) => (
										<Box
											key={roleIndex}
											id={`permission-list-${roleIndex}`}
											className={`permission-collapsible-body ${
												open.permissionOpen && open.id === role.moduleId ? 'expand-row' : ''
											}`}
										>
											<Box className='permission-collapsible-head'>
												<Box className='permission-collapsible-head-content'>
													<Box className='rotating-arrow' onClick={(e) => handleManagePermission(e, role.moduleId)}>
														<RightArrowIcon />
													</Box>
													<Checkbox
														key={role.moduleId}
														id={`permission-chk-${role.moduleId}`}
														checkedIcon={<CheckedIcon />}
														aria-label='Permission Checkbox'
														indeterminate={role.isActive === null}
														indeterminateIcon={<UnCheckedIcon />}
														checked={role.isActive}
														onChange={(e) => handleChangeModule(e, roleIndex)}
														icon={<PartialCheckedIcon />}
													/>
													<Box>
														<Typography variant='body1'>
															{role.moduleName}
															{role.isIndividual && (
																<sup>
																	<StarIcon />
																</sup>
															)}
														</Typography>
														<Typography className='subtitle3'>{role.description}</Typography>
													</Box>
												</Box>
												<Box onClick={(e) => stopBubbling(e)}>
													{role.isIndividual && (
														<OKRButton
															className='reset-btn'
															handleClick={(e) => handleModuleReset(e, role.moduleId)}
															icon={<ResetIcon />}
														/>
													)}
												</Box>
											</Box>
											<Collapse
												className='permission-collapsible-content'
												in={open.permissionOpen && open.id === role.moduleId}
												timeout='auto'
												unmountOnExit
											>
												<List>
													{role.permissions.length > 0 &&
														role.permissions.map((permission: any, permissionIndex: number) => (
															<ListItem id={`permission-sub-module-${permissionIndex}`}>
																<Checkbox
																	key={permission.permissionId}
																	checkedIcon={<CheckedIcon />}
																	icon={<PartialCheckedIcon />}
																	aria-label='Permission Checkbox'
																	checked={permission.isActive}
																	disabled={!permission.isEditable}
																	onChange={(e) => handleChangePermissions(e, roleIndex, permissionIndex)}
																/>
																<Typography>{permission.permissionName}</Typography>
															</ListItem>
														))}
												</List>
											</Collapse>
										</Box>
									))}
							</Box>
						) : (
							<Box textAlign='center' paddingTop={5}>
								<Typography>{t('loading')}</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
};
