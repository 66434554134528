/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';
import { Box } from '@material-ui/core';
import { ReportHeader } from './Header';
import { ReportTabPanel } from './HeaderTabs';
import { useTranslation } from 'react-i18next';
import '../../styles/pages/reports/reportLayout.scss';
import MyTeamsProgress from './MyTeamProgress';
import OrgReports from './OrgReports';
import TeamReports from './TeamReports';
import MyEngagementReport from './MyEngagementReport';
import NoReports from './NoReports';
import TeamLoadDistribution from './TeamLoadDistribution';
import { GAevent, checkSupportPermission, isTrialVersion } from '../../config/utils';
import { Enums } from '../../config/enums';
import DownloadReports from '../Admin/Header/DownloadReports';
import { currentDashboardTabForSO } from '../../action/common';
import { useDispatch } from 'react-redux';
import { supportModuleAlignGoals } from '../Admin/AdminConstant';
// import { ReportsPart2 } from './ReportsPart2';

const Reports = (props: any) => {
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const [tabSelectedTeam, setTabSelectedTeam] = useState<any>(0);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isReportDataAvailable, setIsReportDataAvailable] = useState<boolean>(true);
	const handleTabChange = (event: any, newValue: Number) => {
		if (newValue === 1) {
			isTrialVersion() && GAevent('TeamReports', 'Clicked Team Reports Inside Reports Section', 'Team Reports');
		} else if (newValue === 2) {
			isTrialVersion() && GAevent('OrgReports', 'Clicked Org Reports Inside Reports Section', 'Org Reports');
		}
		setTabSelected(newValue);
		dispatch(currentDashboardTabForSO(false));
		props.currentDashboardTab(newValue);
	};

	useEffect(() => {
		return () => {
			props.resetLeadingTeam();
		};
	}, []);

	useEffect(() => {
		if (checkSupportPermission(supportModuleAlignGoals)) {
			setTabSelected(3); // if supportModuleAlignGoals then downloadable reports will be selected
		} else {
			setTabSelected(props.dashboardCurrentTab);
		}
	}, [props.dashboardCurrentTab]);

	return (
		<>
			{isReportDataAvailable ? (
				<Fragment>
					<ReportHeader
						tabSelected={tabSelected}
						setTabSelectedTeam={setTabSelectedTeam}
						handleTabChange={handleTabChange}
						{...props}
					/>
					<Box className='page-content-wrapper' key={props.userSelectedCycle?.organisationCycleId}>
						<Box className='okr-reports-area'>
							<ReportTabPanel value={tabSelected} index={0}>
								<MyTeamsProgress {...props} setIsReportDataAvailable={setIsReportDataAvailable} />
								<MyEngagementReport
									{...props}
									headTitle={t('myEngagementReportLabel')}
									headTitleTooltip={t('engagementProgressReportTooltipText')}
									color1={'#6C5BFA'}
									color2={'#8FC3F6'}
									color3={'#FA989D'}
									isEngagement={true}
								/>
							</ReportTabPanel>
							<ReportTabPanel value={tabSelected} index={1}>
								<TeamReports
									{...props}
									tabSelectedTeam={tabSelectedTeam}
									setIsReportDataAvailable={setIsReportDataAvailable}
								/>
							</ReportTabPanel>
							<ReportTabPanel value={tabSelected} index={2}>
								<OrgReports {...props} />
							</ReportTabPanel>
							{/* <ReportTabPanel value={tabSelected} index={3}>
								<ReportsPart2 {...props} />
							</ReportTabPanel> */}
							<ReportTabPanel value={tabSelected} index={3}>
								<DownloadReports {...props} />
							</ReportTabPanel>
						</Box>
					</Box>
				</Fragment>
			) : (
				<NoReports t={t} />
			)}
		</>
	);
};

export default Reports;
