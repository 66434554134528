import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Badge,
	Box,
	ClickAwayListener,
	Grow,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Paper,
	Popper,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@material-ui/core';

import { Enums } from '../../../../config/enums';
import { DueDateIcon, ResolveIcon, TasksIcon } from '../../../../config/svg/CommonSvg';
import {
	AreaOfConcernIcon,
	BottomArrowIcon,
	CalendarIcon,
	DocumentIcon,
	ImprovementGoalsIcon,
	ObjectiveAndExpectationsIcon,
	RoleExpectationsIcon,
} from '../../../../config/svg/CommonSvgIcon';
import { Resolve } from './Resolve';
import { ReviewersComment } from './ReviewersComment';
import { getMonthDateYearTime } from '../../../../config/utils';
import { PipTaskDetails } from '../CreatePip/PipTaskDetails';
import AlertDialog from '../../../Common/Dialog';

export const PipViewTabs = (props: any) => {
	const {
		pipDetails,
		select1on1Tab,
		setSelect1on1Tab,
		reviewDates,
		setReviewDates,
		reviewDateComments,
		getPipCommentsApiCall,
		loader,
		setLoader,
		isUserHavePIPPermission,
		isUserHavePIPEditPermission,
		userDetails,
		showMsg,
		handleBackToListClick,
		pipFormType,
		allTaskList,
		setAllTaskList,
		getPIPViewDetails,
		resolveStatusTypes,
		setIsFormEdited,
		isFormEdited,
	} = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<any>({ show: false, type: null });
	const [reviewDateDetails, setReviewDateDetails] = useState<any>(null);
	const [reviewDateTabLabel, setReviewDateTabLabel] = useState<string>('');
	const [selectedReviewDateData, setSelectedReviewDateData] = useState<any>(null);
	const [expanded, setExpanded] = useState<any>([1]);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });

	useEffect(() => {
		if (reviewDates && reviewDates?.length) {
			getSelectedReviewDate(reviewDates);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewDates]);
	useEffect(() => {
		setReviewDateDetails(reviewDateComments);
	}, [reviewDateComments]);

	const handleTabChange = (event: any, newValue: number) => {
		if (newValue !== Enums.THREE) {
			if (isFormEdited) {
				setModalProps({
					open: true,
					details: { newValue, type: 'direct' },
					type: 'UnSaveTabChanges',
					message: t('unSavedItemAlert'),
				});
			} else {
				setSelectedReviewDateData(null);
				setSelect1on1Tab(newValue);
				setIsFormEdited(false);
			}
		}
	};
	function a11yProps(index: number) {
		return {
			id: `user-tab-${index}`,
			'aria-controls': `user-tab-tabpanel-${index}`,
		};
	}
	const handleClick = (event: any, tabId: any) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
		setOpen({ show: true, type: tabId });
	};
	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpen({ show: false, type: null });
	};
	const getSelectedReviewDate = (reviewDatesData: any) => {
		let selectedReviewDate =
			reviewDatesData && reviewDatesData?.length ? reviewDatesData?.find((item: any) => item?.isActive) : '';
		selectedReviewDate = selectedReviewDate
			? selectedReviewDate
			: reviewDatesData && reviewDatesData?.length
			? reviewDatesData[0]
			: '';
		setReviewDateTabLabel(
			`Reviews (${selectedReviewDate?.reviewDate ? getMonthDateYearTime(selectedReviewDate?.reviewDate) : ''})`
		);
	};
	const handleReviewDateSelection = (e: any, date: any, dateIndex: number) => {
		try {
			if (isFormEdited) {
				setModalProps({
					open: true,
					details: { newValue: Enums.THREE, type: 'dateChange', e, date, dateIndex },
					type: 'UnSaveTabChanges',
					message: t('unSavedItemAlert'),
				});
			} else {
				handleReviewDateSelectionUpdate(e, date, dateIndex);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleReviewDateSelectionUpdate = (e: any, date: any, dateIndex: number) => {
		try {
			if (date?.performanceImprovementPlanReviewDateId) {
				let updatedReviewDates = [...reviewDates];
				updatedReviewDates = updatedReviewDates?.map((item: any) => {
					const data = { ...item };
					if (data?.performanceImprovementPlanReviewDateId === date?.performanceImprovementPlanReviewDateId) {
						data.isActive = true;
					} else {
						data.isActive = false;
					}
					return data;
				});
				setSelectedReviewDateData(date);
				getSelectedReviewDate(updatedReviewDates);
				refreshCommentList(date?.performanceImprovementPlanReviewDateId, updatedReviewDates);
				handleClose(e);
				setSelect1on1Tab(Enums.THREE);
				setIsFormEdited(false);
				setLoader(true);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const refreshCommentList = (reviewDateId: any, reviewDatesData: any) => {
		getPipCommentsApiCall(reviewDateId, pipDetails, reviewDatesData);
	};
	const handleChange = (event: any, newExpanded: any) => {
		let updatedExpanded = [...expanded];
		if (updatedExpanded.includes(newExpanded)) {
			updatedExpanded = updatedExpanded?.filter((rec: any) => rec !== newExpanded);
		} else {
			updatedExpanded = [...updatedExpanded, newExpanded];
		}
		setExpanded(updatedExpanded);
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'UnSaveTabChanges') {
				const { newValue, type } = modalProps?.details || {};
				if (newValue) {
					if (type === 'direct') {
						setSelectedReviewDateData(null);
						setSelect1on1Tab(newValue);
						setIsFormEdited(false);
					} else if (type === 'dateChange') {
						const { e, date, dateIndex } = modalProps?.details || {};
						handleReviewDateSelectionUpdate(e, date, dateIndex);
					}
				}
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};
	// console.log('reviewDateDetails ==>', reviewDateDetails, reviewDateTabLabel);

	return (
		<>
			<Box className='pip-view-tabs'>
				<Box className='pip-view-tab-head'>
					<Tabs
						className='okr-tab-button okr-tab-white-button'
						value={select1on1Tab}
						onChange={handleTabChange}
						aria-label='User Tabs'
					>
						<Tab
							label={
								<>
									<ObjectiveAndExpectationsIcon />
									{t('objectiveAndExpectations')}
								</>
							}
							value={Enums.ONE}
							{...a11yProps(Enums.ONE)}
						/>
						<Tab
							label={
								<>
									<TasksIcon />
									{t('tasksLabel')}
								</>
							}
							value={Enums.TWO}
							{...a11yProps(Enums.TWO)}
						/>
						<Tab
							className='arrow-tab'
							label={
								<>
									<DueDateIcon />
									{reviewDateTabLabel}
									{/* <Tooltip arrow title={'New Comment'} classes={{ popper: 'tooltip-alert' }} placement='top'>
									<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
								</Tooltip> */}

									<span className='arrowIcon'>
										<BottomArrowIcon />
									</span>
								</>
							}
							value={Enums.THREE}
							onClick={(e) => handleClick(e, 1)}
							{...a11yProps(Enums.THREE)}
						/>
						{(pipDetails?.distinctPerformanceImprovementPlanReviewers?.find(
							(rec: any) => rec?.reviewerId === userDetails?.employeeId
						) ||
							isUserHavePIPEditPermission()) &&
						(pipDetails?.resolvedOn || select1on1Tab === Enums.FOUR) ? (
							<Tab
								label={
									<>
										<ResolveIcon />
										{t('resolve')}
									</>
								}
								value={Enums.FOUR}
								{...a11yProps(Enums.FOUR)}
							/>
						) : (
							<></>
						)}
					</Tabs>
					{open.show && open.type === 1 ? (
						<Popper
							className={'popper-menu tabs-dropdown-menu pip-tabs-dropdown'}
							id={'reviewer_date_tab_dropdown'}
							open={open.show ? true : false}
							anchorEl={anchorEl}
							placement={'bottom-end'}
							modifiers={{
								preventOverflow: {
									enabled: false,
									boundariesElement: window,
								},

								flip: {
									enabled: false, // true by default
								},
							}}
							transition
						>
							{({ TransitionProps }) => (
								<Grow {...TransitionProps}>
									<Paper>
										<ClickAwayListener
											onClickAway={(e) => {
												handleClose(e);
											}}
										>
											<Box className='popper-menu-inner'>
												<List>
													{reviewDates && reviewDates?.length ? (
														reviewDates?.map((date: any, dateIndex: number) => {
															return (
																<ListItem
																	className={date?.isActive ? 'active' : ''}
																	key={date?.performanceImprovementPlanReviewDateId}
																	onClick={(e: any) => handleReviewDateSelection(e, date, dateIndex)}
																>
																	<ListItemAvatar>
																		<CalendarIcon />
																	</ListItemAvatar>
																	<ListItemText
																		primary={
																			<>
																				{date.isReviewDateAlert ? (
																					<Tooltip arrow title={date.isReviewDateAlert ? t('newComment') : <></>}>
																						<Badge variant='dot' className='dot-bubble' overlap='circular'>
																							{date?.reviewDate
																								? getMonthDateYearTime(date?.reviewDate)
																								: date?.reviewDate}
																						</Badge>
																					</Tooltip>
																				) : (
																					<>
																						{date?.reviewDate
																							? getMonthDateYearTime(date?.reviewDate)
																							: date?.reviewDate}
																					</>
																				)}
																				{/* <Tooltip
																				arrow
																				title={'New Comment'}
																				classes={{ popper: 'tooltip-alert' }}
																				placement='top'
																			>
																				<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
																			</Tooltip> */}
																			</>
																		}
																	/>
																</ListItem>
															);
														})
													) : (
														<></>
													)}
												</List>
											</Box>
										</ClickAwayListener>
									</Paper>
								</Grow>
							)}
						</Popper>
					) : null}
				</Box>
				<Box className='pip-view-tab-content'>
					{select1on1Tab === Enums.ONE && (
						<>
							<Box className='pip-section-panel full-rectangular-card'>
								<Box className='pip-editor-panel' id='area-of-concern'>
									<Typography className='editor-view-label'>
										<AreaOfConcernIcon />
										{t('areaOfConcern')}
									</Typography>
									{pipDetails?.areasOfConcern ? (
										<Box className='long-text'>
											<Typography dangerouslySetInnerHTML={{ __html: pipDetails?.areasOfConcern || '' }}></Typography>
										</Box>
									) : (
										<></>
									)}
								</Box>
								<Box className='pip-editor-panel' id='role-expectations'>
									<Typography className='editor-view-label'>
										<RoleExpectationsIcon />
										{t('roleExpectationsLabel')}
									</Typography>
									{pipDetails?.roleExpectations ? (
										<Box className='long-text'>
											<Typography dangerouslySetInnerHTML={{ __html: pipDetails?.roleExpectations || '' }}></Typography>
										</Box>
									) : (
										<></>
									)}
								</Box>
								<Box className='pip-editor-panel' id='improvement-goals'>
									<Typography className='editor-view-label'>
										<ImprovementGoalsIcon />
										{t('improvementGoalsLabel')}
									</Typography>
									{pipDetails?.improvementGoals ? (
										<Box className='long-text'>
											<Typography dangerouslySetInnerHTML={{ __html: pipDetails?.improvementGoals || '' }}></Typography>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</Box>
							{/* <Accordion
								className='pip-accordion-panel full-rectangular-card'
								square
								expanded={expanded.includes(1)}
								onChange={(e: any) => handleChange(e, 1)}
							>
								<AccordionSummary expandIcon={<RightArrowIcon />} aria-controls='panel2d-content' id='panel2d-header'>
									<Typography className='font-weight-normal' variant='h4'>
										{t('areaOfImprovements')}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box className='pip-improvements-area' id='pip_role_expectations_container'>
										<Typography className='font-weight-normal' variant='h4'>
											{t('roleExpectationsLabel')}
										</Typography>
										<Box className='long-text'>
											<Typography dangerouslySetInnerHTML={{ __html: pipDetails?.roleExpectations || '' }}></Typography>
										</Box>
										<Typography className='font-weight-normal' variant='h4'>
											{t('areaOfConcernLabel')}
										</Typography>
										<Box className='long-text'>
											<Typography dangerouslySetInnerHTML={{ __html: pipDetails?.areasOfConcern || '' }}></Typography>
										</Box>
										<Typography className='font-weight-normal' variant='h4'>
											{t('improvementGoalsLabel')}
										</Typography>
										<Box className='long-text'>
											<Typography dangerouslySetInnerHTML={{ __html: pipDetails?.improvementGoals || '' }}></Typography>
										</Box>
									</Box>
								</AccordionDetails>
							</Accordion> */}
							{/* <Accordion
								className='pip-accordion-panel full-rectangular-card'
								square
								expanded={expanded.includes(2)}
								onChange={(e: any) => handleChange(e, 2)}
							>
								<AccordionSummary expandIcon={<RightArrowIcon />} aria-controls='panel3d-content' id='panel3d-header'>
									<Typography className='font-weight-normal' variant='h4'>
										{t('tasksLabel')}
									</Typography>
								</AccordionSummary>
								<AccordionDetails>
									<Box className='pip-tasks-area'>
										{pipDetails?.performanceImprovementPlanId ? (
											<PipTaskDetails
												t={t}
												pipFormType={pipFormType}
												loader={loader}
												setLoader={setLoader}
												pipFormDetails={pipDetails}
												// setPIPFormDetails={setPIPFormDetails}
												// updatePipFormDetails={updatePipFormDetails}
												allTaskList={allTaskList}
												setAllTaskList={setAllTaskList}
												showMsg={showMsg}
												setIsFormEdited={setIsFormEdited}
											/>
										) : (
											<></>
										)}
									</Box>
								</AccordionDetails>
							</Accordion> */}
						</>
					)}
					{select1on1Tab === Enums.TWO && (
						<>
							{pipDetails?.performanceImprovementPlanId ? (
								<PipTaskDetails
									t={t}
									pipFormType={pipFormType}
									loader={loader}
									setLoader={setLoader}
									pipFormDetails={pipDetails}
									// setPIPFormDetails={setPIPFormDetails}
									// updatePipFormDetails={updatePipFormDetails}
									allTaskList={allTaskList}
									setAllTaskList={setAllTaskList}
									showMsg={showMsg}
									setIsFormEdited={setIsFormEdited}
								/>
							) : (
								<></>
							)}
						</>
					)}
					{select1on1Tab === Enums.THREE && (
						<>
							<ReviewersComment
								pipDetails={pipDetails}
								reviewDateDetails={reviewDateDetails}
								showMsg={showMsg}
								selectedReviewDateData={selectedReviewDateData}
								reviewDates={reviewDates}
								refreshCommentList={refreshCommentList}
								userDetails={userDetails}
								loader={loader}
								setLoader={setLoader}
								isUserHavePIPPermission={isUserHavePIPPermission}
								isUserHavePIPEditPermission={isUserHavePIPEditPermission}
								setIsFormEdited={setIsFormEdited}
							/>
						</>
					)}
					{select1on1Tab === Enums.FOUR && (
						<>
							<Resolve
								loader={loader}
								pipDetails={pipDetails}
								setLoader={setLoader}
								isUserHavePIPPermission={isUserHavePIPPermission}
								isUserHavePIPEditPermission={isUserHavePIPEditPermission}
								handleBackToListClick={handleBackToListClick}
								pipFormType={pipFormType}
								handleTabChange={handleTabChange}
								getPIPViewDetails={getPIPViewDetails}
								resolveStatusTypes={resolveStatusTypes}
								showMsg={showMsg}
								setIsFormEdited={setIsFormEdited}
								userDetails={userDetails}
							/>
						</>
					)}
				</Box>
			</Box>

			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
