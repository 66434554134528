import React, { Fragment } from 'react';
import {
	Popover,
	Typography,
	Box,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Avatar,
	Tooltip,
} from '@material-ui/core';
import { getUserName, getFullName } from '../../../config/utils';
// import OkrProgress from '../../MyGoal/OkrProgress';
import { LeftArrow, RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { USER_VIEW } from '../../../config/app-url';
import { setLocalStorageItem } from '../../../services/StorageService';
import { useHistory } from 'react-router-dom';
import './styles.scss';
import { useTranslation } from 'react-i18next';

const KRContributors = (props) => {
	const {
		contributorList,
		anchorEl,
		onContributorNameClick,
		handleClose,
		objectiveName,
		keyDescription,
		isWeekly,
		objectivesCount,
		selectedObjective,
		setSelectedObjective,
		customClass,
	} = props;
	const history = useHistory();

	const onContributorClick = (event, contributorDetails) => {
		event.preventDefault();
		onContributorNameClick && onContributorNameClick(event, contributorDetails);
		changeRoute(event, contributorDetails);
	};
	const changeRoute = (e, option) => {
		e.preventDefault();
		e.stopPropagation();
		setLocalStorageItem('currentUser', JSON.stringify(option));
		props.resetOkr();
		props.updatePrevRoute(props.currentRoute);
		history.push(USER_VIEW);
		props.updateRoute(USER_VIEW);
	};
	const { t } = useTranslation();
	return (
		<Fragment>
			<Popover
				className='chart-contributors-list'
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				anchorReference='anchorPosition'
				anchorPosition={{
					top: anchorEl ? anchorEl.clientY : 0,
					left: anchorEl ? anchorEl.clientX : 0,
				}}
			>
				<Box className={customClass ? `${customClass} chart-contributors-list-inner` : 'chart-contributors-list-inner'}>
					<Box>
						{objectivesCount > 1 && selectedObjective >= 0 ? (
							<div className='contributor-pagination-wrapper'>
								<div className='contributor-pagination-arrow-wrapper'>
									{selectedObjective > 0 ? (
										<div
											className='contributor-pagination-arrow'
											onClick={() => setSelectedObjective(selectedObjective - 1)}
										>
											<LeftArrow />
										</div>
									) : (
										<div className='contributor-pagination-arrow disabled'>
											<LeftArrow />
										</div>
									)}
								</div>
								<Typography variant='subtitle2'>{`${selectedObjective + 1}/${objectivesCount} ${t(
									'objective'
								)}`}</Typography>
								<div className='contributor-pagination-arrow-wrapper'>
									{selectedObjective < objectivesCount - 1 ? (
										<div
											className='contributor-pagination-arrow'
											onClick={() => setSelectedObjective(selectedObjective + 1)}
										>
											<RightArrowIcon />
										</div>
									) : (
										<div className='contributor-pagination-arrow disabled'>
											<RightArrowIcon />
										</div>
									)}
								</div>
							</div>
						) : (
							''
						)}
						<Box>
							<Tooltip title={objectiveName} arrow>
								<Typography color='secondary' className='objective-name' variant='body2' component='span'>
									{objectiveName.substring(0, 60) || ''}
									{objectiveName.length > 60 ? '...' : ''}
								</Typography>
							</Tooltip>
						</Box>
						<Box>
							<Tooltip title={keyDescription} arrow>
								<Typography color='primary' variant='body1' component='span'>
									{keyDescription.substring(0, 100) || ''}
									{keyDescription.length > 100 ? '...' : ''}
								</Typography>
							</Tooltip>
						</Box>
					</Box>
					<Box>
						{/* {isProgress ? <OkrProgress type='okr' id={'19293'} okr={{}} value={score} dueDate={dueDate} /> : ''} */}

						{contributorList && contributorList.length > 0 ? (
							<List className='kr-contributors-list' id='custom-scroll'>
								{contributorList.map((item) => (
									<ListItem key={item.id}>
										<ListItemAvatar>
											<Avatar
												alignItems='flex-start'
												aria-label='user-image'
												src={item.imagePath || ''}
												className={item.imagePath ? 'user-img' : ''}
											>
												{getUserName(item)}
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={
												!item.owner ? (
													<Typography
													// href='#'
													// onClick={(event) => onContributorClick(event, item)}
													>
														{getFullName(item)}
													</Typography>
												) : (
													<Typography color='primary' variant='body1'>
														<Box fontWeight='fontWeightBold'>{getFullName(item)}</Box>
													</Typography>
												)
											}
										/>
										<ListItemSecondaryAction>
											{isWeekly ? `${item.score}${t('percentage')}` : item.krUpdates}
										</ListItemSecondaryAction>
									</ListItem>
								))}
							</List>
						) : (
							<Typography>{t('InsightsNoContributor')}</Typography>
						)}
					</Box>
				</Box>
			</Popover>
		</Fragment>
	);
};

export default KRContributors;
