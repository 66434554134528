import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
//import './style.scss';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { CreateAlignOkr } from '../../OkrAction/CreateAlignOkr';
import { checkPermission } from '../../../config/rolePermission';
import { createOkrs } from '../../Admin/AdminConstant';
import { SECRET_LOGIN } from '../../../config/app-url';
import { clearLocalStorage, getLocalStorageItem } from '../../../services/StorageService';
import { DownloadIcon } from '../../../config/svg/CommonSvg';
import { exportMyOkrReport } from '../../../action/common';
import { useDispatch } from 'react-redux';
import { getUserDetails } from '../../../config/utils';

export const OkrListingHead: React.FC<any> = (props) => {
	const { t } = useTranslation();
	// Check whether Token is Expired or not
	const isAuthenticated = getLocalStorageItem('isAuthenticated');
	if (!isAuthenticated) {
		clearLocalStorage();
		props.history.push(SECRET_LOGIN);
	}
	const {
		showArchiveList,
		isExpandAll,
		expandCollapseAll,
		showAddGoal,
		isFutureCycle,
		myArchiveGoalList,
		getFilter,
		myGoalList,
		setOpenImportDrawer,
		year,
		cycleId,
		showApiMsgs,
		setLoader,
		handleOpenImportDrawer,
		openImportDrawer,
		showAddGoalFormAndUpdateList,
		HOME,
		setTabValue,
		getPermissionResult,
		tabValue,
		currentRoute,
		isLocked
	} = props;
	const dispatch = useDispatch();
	const userDetail: any = getUserDetails();

	const handleExportMyOkrReport = async () => {
		try {
			if (cycleId && userDetail?.employeeId) {
				setLoader(true);
				let userId =
					props.currentUser && props.currentUser.employeeId ? props.currentUser.employeeId : userDetail?.employeeId;
				let requestParam = `cycleId=${cycleId}&employeeId=${userId}`;
				if (props.currentOrgData && props.currentOrgData.teamId) {
					requestParam += `&teamId=${props.currentOrgData.teamId}`;
				} else if (props?.teamsOKR && props?.teamsOKR?.teamId) {
					requestParam += `&teamId=${props?.teamsOKR?.teamId}`;
				}
				const response: any = await dispatch(exportMyOkrReport(requestParam));
				if (response) {
					const defaultName = 'myOkrReport.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
					const matches = filenameRegex.exec(response.headers['content-disposition']);
					link.setAttribute('download', matches && matches.length > 0 ? matches[1] : defaultName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				}
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<Box className='okr-listing-head pad-container'>
			<Box className='okr-listing-head-left'>
				<Box className='expand-all'>
					{myGoalList.length > 0 && (
						<OKRButton
							className={isExpandAll ? 'expand-all-inner collapse-active' : 'expand-all-inner'}
							handleClick={() => {
								showArchiveList ? expandCollapseAll(2) : expandCollapseAll(1);
							}}
							title={
								<Typography variant='body2' className='expand-txt'>
									{t('expandAndCollapseLabel')}
								</Typography>
							}
							text={<RightArrowIcon />}
						/>
					)}
					<Typography className='sort-obj-title' variant='h2'>
						{t('OkrLabel')}
					</Typography>
				</Box>
			</Box>
			<Box className='okr-listing-head-right'>
				{!showAddGoal && isFutureCycle !== true && (
					<Box className='goal-okr-filter alignment-map-filter'>
						{showArchiveList && myArchiveGoalList.length > 0 ? getFilter() : !showArchiveList && getFilter()}
					</Box>
				)}
				{tabValue === 0 || tabValue === 1 ? (
					<OKRButton
						handleClick={handleExportMyOkrReport}
						className='btn-circle-white download-btn'
						icon={<DownloadIcon />}
						title={t('downloadOKRs')}
					></OKRButton>
				) : (
					<></>
				)}
{/* getPermissionResult && checkPermission(getPermissionResult?.employeePermissions, createOkrs)				{!isLocked ? (
 */}
				{!isLocked ? (
					currentRoute !== '/team-view' && (
						<CreateAlignOkr
							{...props}
							setOpenImportDrawer={setOpenImportDrawer}
							year={year}
							cycleId={cycleId}
							showApiMsgs={showApiMsgs}
							setLoader={setLoader}
							handleOpenImportDrawer={handleOpenImportDrawer}
							openImportDrawer={openImportDrawer}
							showAddGoalForm={showAddGoalFormAndUpdateList}
							panelOpenFrom={HOME}
							setTabValue={setTabValue}
							myGoalList={myGoalList}
							tabValue={tabValue}
						/>
					)
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
};
