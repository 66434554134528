import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	Box,
	Chip,
	FormControlLabel,
	List,
	ListItem,
	Switch,
	Tooltip,
	Typography,
	TablePagination,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/admin/settings/recognizeSettings.scss';
import { OKRButton } from '../../../Common/OKRButton';
import { CreateBadge } from './CreateBadge';
import { deleteBadges, getManageBadges, manageBadgesStatus } from '../../../../action/organization';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { DeleteIcon, EditIcon, SearchIcon } from '../../../../config/svg/CommonSvg';
import LinearLoader from '../../../Common/Loader';
import { EnumEmailModule, Enums } from '../../../../config/enums';
import { CrossIcon, SortingIcon } from '../../../../config/svg/CommonSvgIcon';
import { UserSearchListPopover } from '../../CommonComponent/UserSearchListPopover';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { AnimatedIcon } from '../../CommonComponent/AnimatedIcon';
import noRecordFound from '../../../../images/search-placeholder.svg';
import { EmailRecipientSetting } from '../AdminPerformanceDevelopment/Common/EmailRecipientSetting';

export const RecognizeSettings: React.FC<any> = (props: any) => {
	const { setOpenRecognizeDrawer, openRecognizeDrawer } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [badgesListData, setBadgesListData] = useState<any>(null);
	const { enqueueSnackbar } = useSnackbar();
	/*const [pagingInfo, setPagingInfo] = useState<any>({
		pageIndex: 1,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
	});*/
	const [totalUser, setTotalUser] = useState(0);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [searchTextArray, setSearchTextArray] = useState<string[]>([]);
	const [searchValue, setSearchValue] = useState<string>('');
	const [anchorElUserSearch, setAnchorElUserSearch] = useState(null);
	const [openUserSearch, setOpenUserSearch] = useState<any>(false);
	const [loader, setLoader] = useState<any>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [editableDetails, setEditableDetails] = useState<any>(null);

	useEffect(() => {
		// getBadgesListing(pageIndex + 1, pageSize);
		handleGetManagesList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleGetManagesList = () => {
		setLoader(true);
		setPageIndex(1);
		setSearchTextArray([]);
		setSortingColumn('');
		setSortOrder('asc');

		getBadgesListing({
			pageIndex: 1,
			sortingText: '',
			order: 'asc',
			finder: [],
			pageSize: pageSize,
		});
	};

	const getBadgesListing = async (requestPayload: any) => {
		try {
			setLoader(true);
			const response: any = await dispatch(getManageBadges(requestPayload));
			if (Boolean(response) && response.data.status === 200) {
				const { totalRecords, records } = response?.data?.entity || {};
				setTotalUser(totalRecords || 0);
				records && records.length ? setBadgesListData(records) : setBadgesListData([]);
				/*
			setPagingInfo({
				pageIndex: response.data.entity.pageIndex,
				pageSize: response.data.entity.pageSize,
				totalPages: response.data.entity.totalPages,
				totalRecords: response.data.entity.totalRecords,
			});
			if (response.data.entity.pageIndex === 1) {
				setBadgesListData(response.data.entity.records);
			} else {
				if (response.data.entity.records) {
					setBadgesListData([...badgesListData, ...response.data.entity.records]);
				} else if (response.data.entity.records === null) {
					setBadgesListData([]);
				}
			}
			*/
			} else {
				setBadgesListData([]);
				// enqueueSnackbar(t('someErrorOccurred'), {
				// 	variant: 'error',
				// 	autoHideDuration: 5000,
				// });
			}
			setLoader(false);
		} catch (error) {
			setBadgesListData([]);
			console.error(error);
		}
	};
	const toggleStatusBadges = async (item: any, e: any) => {
		const response: any = await dispatch(
			manageBadgesStatus({
				recognitionCategoryId: item.recognitionCategoryId,
				isActive: !item.isActive,
				isOnlyManager: item?.isOnlyManager || false,
			})
		);

		if (Boolean(response) && response.data.status === 200) {
			const updatedList = badgesListData.map((managedData: any) => {
				if (item.recognitionCategoryId === managedData.recognitionCategoryId) {
					managedData.isActive = !managedData.isActive;
				}
				return managedData;
			});
			setBadgesListData(updatedList);
			enqueueSnackbar(response?.data?.messageList?.IsRecognitionCategory, {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		// setLoader(true);
		getBadgesListing({
			pageIndex: newPage,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
			pageSize: pageSize,
		});
	};

	const handleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		// setLoader(true);
		getBadgesListing({
			pageIndex: 1,
			sortingText: sortingColumn,
			order: sortOrder,
			finder: searchTextArray,
			pageSize: pSize,
		});
	};

	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}

		getBadgesListing({
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			finder: searchTextArray,
			pageSize: pageSize,
		});
		setSortingColumn(column);
	};
	const handleChange = (e: any) => {
		setSearchValue(e.target.value);
	};
	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleSearch(e.target.value);
			}
			setSearchValue('');
		}
	};
	const handleSearch = (value: any) => {
		setPageIndex(1);
		const updatedValue: any = [...searchTextArray, value];
		// setSearchTextArray(value);
		setSearchTextArray(updatedValue);
		// setLoader(true);
		getBadgesListing({
			pageIndex: 1,
			sortingText: '',
			order: '',
			finder: updatedValue,
			pageSize: pageSize,
		});
	};
	const clearSearch = (searchText: string) => {
		if (searchText === 'all') {
			setPageIndex(1);
			setSearchTextArray([]);
			getBadgesListing({
				pageIndex: 1,
				sortingText: '',
				order: '',
				finder: [],
				pageSize: pageSize,
			});
		} else {
			const newArray = searchTextArray.filter((item) => item !== searchText);
			setPageIndex(1);
			setSearchTextArray(newArray);
			// setLoader(true);
			getBadgesListing({
				pageIndex: 1,
				sortingText: '',
				order: '',
				finder: newArray,
				pageSize: pageSize,
			});
		}
	};
	const handleUserOpen = (event: any) => {
		setAnchorElUserSearch(event.currentTarget);
		setOpenUserSearch(true);
	};
	const handleUserClose = () => {
		setOpenUserSearch(false);
		setAnchorElUserSearch(null);
	};

	const editSelectBadge = (e: any, selectedBadge: any) => {
		e.preventDefault();
		setEditableDetails(selectedBadge);
		setOpenRecognizeDrawer(true);
	};
	const deleteSelectBadge = (e: any, selectedBadge: any) => {
		e.preventDefault();
		setModalProps({ open: true, message: '', details: selectedBadge });
	};
	const handleCloseModal = async (e: any, type: any) => {
		// e.preventDefault();
		if (type === 1) {
			callDeleteBadgeAPI();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const callDeleteBadgeAPI = async () => {
		try {
			setLoader(true);
			const { details } = modalProps || {};
			setModalProps({ open: false, message: '', details: '' });
			if (details && details?.recognitionCategoryId) {
				const queryParams = `recognitionCategoryId=${details?.recognitionCategoryId || 0}`;
				const response: any = await dispatch(deleteBadges(queryParams));
				if (response) {
					if (response && response?.data && response.data.status === 200) {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						enqueueSnackbar(messages || 'Recognition badge deleted successfully', {
							variant: 'success',
							autoHideDuration: 3000,
						});

						handleGetManagesList();
					} else {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						enqueueSnackbar(messages || 'Error occurred while deleting recognition badge record', {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
					setLoader(false);
				} else {
					enqueueSnackbar(t('someErrorOccurred'), {
						variant: 'error',
						autoHideDuration: 5000,
					});
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='admin-tabpanel-inner'>
				<Box className='admin-tabpanel-head'>
					<Box className='admin-tabpanel-head-left'>
						<Typography variant='h2'>{t('recognition')}</Typography>
					</Box>

					<Box className='admin-tabpanel-head-right'></Box>
				</Box>
				<Box className='recognize-send-email-panel'>
					<EmailRecipientSetting
						t={t}
						loader={loader}
						messageNote={t('sendUpdateEmailsToNoteRecognition')}
						emailModuleId={EnumEmailModule.RECOGNITION}
						setLoader={setLoader}
					/>
				</Box>
				<Box className='admin-tabpanel-head'>
					<Box className='admin-tabpanel-head-left'></Box>
					<Box className='admin-tabpanel-head-right'>
						<Box>
							{totalUser ? (
								<TablePagination
									component='div'
									className='user-list-pagination'
									count={totalUser}
									page={pageIndex > 1 ? pageIndex - 1 : 0}
									onPageChange={handleChangePage}
									rowsPerPage={pageSize}
									onRowsPerPageChange={handleChangeRowsPerPage}
									SelectProps={{
										MenuProps: {
											PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
										},
									}}
								/>
							) : (
								<></>
							)}
						</Box>
						<Box className='user-search'>
							<TextField
								className='search-text-field'
								id='user-src-field'
								placeholder={t('searchText')}
								fullWidth
								value={searchValue}
								onChange={(e) => handleChange(e)}
								onKeyDown={(e) => searchClicked(e)}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
							{searchTextArray && searchTextArray.length > 0 ? (
								<Box className='user-search-chip'>
									<List>
										{searchTextArray.map((text: any, idx) => {
											if (idx < 3) {
												return (
													<ListItem key={`user${text}${idx}`}>
														{text.length > 20 ? (
															<Tooltip title={text} arrow>
																<Chip
																	label={text.substring(0, 20) + '...'}
																	onDelete={(e) => clearSearch(text)}
																	deleteIcon={
																		<span>
																			<CrossIcon />
																		</span>
																	}
																/>
															</Tooltip>
														) : (
															<Chip
																label={text}
																onDelete={(e) => clearSearch(text)}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														)}
													</ListItem>
												);
											}
										})}
										{searchTextArray && searchTextArray.length > 3 && (
											<>
												<ListItem
													className='search-more'
													id='src-more'
													aria-owns={openUserSearch ? 'mouse-over-popover' : undefined}
													aria-haspopup='true'
													onMouseOver={handleUserOpen}
													onMouseLeave={handleUserClose}
												>
													<Typography>{searchTextArray.length - 3}+</Typography>
													<UserSearchListPopover
														{...props}
														open={openUserSearch}
														setOpen={setOpenUserSearch}
														anchorEl={anchorElUserSearch}
														setAnchorEl={setAnchorElUserSearch}
														searchTextArray={searchTextArray}
														clearSearch={clearSearch}
													/>
												</ListItem>
												<ListItem className='clear-all'>
													<OKRButton
														className='btn-link'
														id='clear-all'
														handleClick={() => clearSearch('all')}
														text={'Clear All'}
													></OKRButton>
												</ListItem>
											</>
										)}
									</List>
								</Box>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</Box>
				<Box className='okr-list-panel-head'>
					{badgesListData && badgesListData.length > 0 ? (
						<List component='ul'>
							<ListItem className='recognize-col1'>
								<Typography
									onClick={(e) => sortUserList(e, 'name')}
									variant='body2'
									className={sortingColumn === 'name' ? `sort-title ${sortOrder}` : 'sort-title asc'}
									id={'badge-name'}
								>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('badgeName')}</span> <SortingIcon />
									</span>
								</Typography>
							</ListItem>
							<ListItem className='recognize-col2'>
								<Typography
									onClick={(e) => sortUserList(e, 'isDefault')}
									variant='body2'
									className={sortingColumn === 'isDefault' ? `sort-title ${sortOrder}` : 'sort-title asc'}
									id={'category'}
								>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('categoryLevel')}</span> <SortingIcon />
									</span>
								</Typography>
							</ListItem>
							<ListItem className='recognize-col3'>
								<Typography
									onClick={(e) => sortUserList(e, 'IsOnlyManager')}
									variant='body2'
									className={sortingColumn === 'IsOnlyManager' ? `sort-title ${sortOrder}` : 'sort-title asc'}
									id={'allow-to-post-by'}
								>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('allowToPostBy')}</span> <SortingIcon />
									</span>
								</Typography>
							</ListItem>
							<ListItem className='recognize-col4'>
								<Typography variant='body2' className='sort-title' id={'status'}>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('statusLabel')}</span>
									</span>
								</Typography>
							</ListItem>
						</List>
					) : (
						<></>
					)}
				</Box>
				<Box className='recognize-listing-area'>
					{badgesListData && badgesListData.length > 0 ? (
						badgesListData.map((manageBadgesData: any) => {
							return (
								<Box
									className='recognize-listing-content'
									key={`badge_item_index_${manageBadgesData?.recognitionCategoryId}`}
								>
									<List component='ul'>
										<ListItem className='recognize-col1'>
											<Box className='recognize-badge-details'>
												<Box className='badge-icon'>
													<img src={manageBadgesData.filePath} alt='Thank You' />
												</Box>
												<Box className='badge-name'>
													{manageBadgesData?.name && manageBadgesData?.name.length > 35 ? (
														<Tooltip arrow title={manageBadgesData?.name || ''}>
															<Typography>{manageBadgesData?.name.substring(0, 35)}...</Typography>
														</Tooltip>
													) : (
														<Typography>{manageBadgesData?.name || ''}</Typography>
													)}
												</Box>
											</Box>

											<Box className='recognize-badge-actions'>
												<OKRButton
													handleClick={(e) => editSelectBadge(e, manageBadgesData)}
													icon={<EditIcon />}
													title={t('editLabel')}
												/>
												{manageBadgesData?.isDefault ? (
													<></>
												) : (
													<OKRButton
														handleClick={(e) => deleteSelectBadge(e, manageBadgesData)}
														icon={<DeleteIcon />}
														title={t('delete')}
													/>
												)}
											</Box>
										</ListItem>
										<ListItem className='recognize-col2'>
											<Box className='recognize-review-status'>
												{manageBadgesData.isDefault ? (
													<Chip label={t('defaultLabel')} />
												) : (
													<Chip label={t('addedByAdmin')} />
												)}
											</Box>
										</ListItem>
										<ListItem className='recognize-col3'>
											{manageBadgesData.isOnlyManager ? (
												<Typography variant='subtitle1'>Managers</Typography>
											) : (
												<Typography variant='subtitle1'>All</Typography>
											)}
										</ListItem>
										<ListItem className='recognize-col4'>
											<Box className='status-switch'>
												<FormControlLabel
													control={
														<Switch
															name='isMyPostFilter'
															checked={manageBadgesData.isActive}
															value={manageBadgesData.isActive}
															onChange={(e) => toggleStatusBadges(manageBadgesData, e)}
														/>
													}
													label={''}
													className='toggle-label toggle-no-label'
												/>
											</Box>
										</ListItem>
									</List>
								</Box>
							);
						})
					) : (
						<></>
					)}
					{badgesListData && badgesListData.length === 0 ? (
						<Box className='search-no-record-global'>
							<Box className='no-record-message'>
								<Typography variant='h2' component='h2'>
									{t('findMatch')}
								</Typography>
								<Typography variant='h4'>
									{t('donotBeSad')}&#x1F641;, {t('tryAgain')}
								</Typography>
								<AnimatedIcon className='' width='350' type='image/svg+xml' alt={'nofound'} imagePath={noRecordFound} />
							</Box>
						</Box>
					) : (
						<></>
					)}
				</Box>
				{openRecognizeDrawer ? (
					<CreateBadge
						{...props}
						handleGetManagesList={handleGetManagesList}
						editableDetails={editableDetails}
						setEditableDetails={setEditableDetails}
						setLoader={setLoader}
					/>
				) : (
					<></>
				)}
				{/* {pagingInfo?.totalPages > 1 && pagingInfo?.totalPages !== pagingInfo.pageIndex && (
				<List className='load-more-ul' disablePadding>
					<ListItem className='load-more'>
						<OKRButton
							handleClick={(e) => {
								getBadgesListing(pagingInfo.pageIndex + 1 || 1, 10);
							}}
							className={`load-more-btn btn-small-text`}
							text={t('loadMore')}
						/>
					</ListItem>
				</List>
			)} */}
			</Box>
			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					headingText={t('deleteBadge')}
					subHeading={''}
				/>
			)}
		</>
	);
};
