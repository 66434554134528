import React from 'react';
import { Drawer, Box, Typography, Button, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { DrawerTransitionSetting } from '../../../config/utils';
import { useTranslation } from 'react-i18next';
import LinearLoader from '../../Common/Loader';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { DraftIcon } from '../../../config/svg/CommonSvg';

interface TransitionDurationProps {
	enter?: number;
	exit?: number;
}
interface MainDrawerProps {
	drawerClassName?: string;
	open?: boolean;
	transitionDuration?: TransitionDurationProps;
	headerTitle?: any;
	children?: React.ReactNode;
	handleDrawerClose?: (event: any) => void;
	handleSaveClick?: (event: any) => void;
	handleCancelClick?: (event: any) => void;
	isSaveButtonDisabled?: boolean;
	loader?: boolean;
	cancelBtnText?: String;
	saveButtonText?: String;
	cancelBtnClass?: string;
	cancelBtnIcon?: React.ReactNode;
	isCancelButtonDisabled?: boolean;
	rightCloseBtn?: boolean;
	addAnother?: boolean;
	showAddAnother?: boolean;
	addAnotherTooltipText?: any;
	handleAddAnother?: (event: any) => void;
	isSaveDraft?: boolean;
	isFooterVisible?: boolean;
	handleDraftButtonClick?: (event: any) => void;
	isSaveButtonVisible?: boolean;
	isDraftButtonDisabled?: boolean;
	draftButtonText?: String;
	draftButtonIcon?: any;
	isShowSeparator?: boolean | false;
	separatorContent?: any;
}

export const MainDrawer: React.FC<MainDrawerProps> = (props) => {
	const {
		drawerClassName,
		open,
		headerTitle,
		children,
		handleDrawerClose,
		handleSaveClick,
		handleCancelClick,
		isSaveButtonDisabled,
		loader,
		cancelBtnText,
		saveButtonText,
		cancelBtnClass,
		cancelBtnIcon,
		isCancelButtonDisabled,
		rightCloseBtn = false,
		addAnother,
		handleAddAnother,
		showAddAnother,
		addAnotherTooltipText,
		isSaveDraft = false,
		isFooterVisible = true,
		handleDraftButtonClick,
		isSaveButtonVisible = true,
		isDraftButtonDisabled,
		draftButtonText,
		draftButtonIcon,
		isShowSeparator,
		separatorContent,
	} = props;
	const { t } = useTranslation();

	return (
		<Drawer
			className={drawerClassName || 'main-drawer-panel'}
			onClose={handleDrawerClose}
			anchor='right'
			open={open || false}
			transitionDuration={DrawerTransitionSetting}
			keepMounted={false}
			disableEnforceFocus
		>
			{rightCloseBtn && (
				<Button className='drawer-close-btn' id='drawer-close-btn' onClick={handleDrawerClose}>
					<RightArrowIcon />
				</Button>
			)}
			<Box className='drawer-area'>
				{headerTitle && (
					<Box className='drawer-head'>
						<Box className='drawer-head-left'>
							<Typography variant='h3'>{headerTitle}</Typography>
						</Box>
						{/* <Box className='drawer-head-right'>
							<OKRButton className={'btn-close'} text={t('closeLabel')} handleClick={handleDrawerClose} />
						</Box> */}
					</Box>
				)}
				<Box className='drawer-content'>
					{loader && (
						<Box className='loader-wrap-type1'>
							<LinearLoader />
						</Box>
					)}
					{/* Need to render children */}
					{children}
				</Box>
				{isFooterVisible && (
					<Box className='drawer-footer-actions'>
						<Box>
							<OKRButton
								icon={cancelBtnIcon}
								disabled={isCancelButtonDisabled || false}
								className={cancelBtnClass || 'btn-link'}
								text={cancelBtnText || t('cancelBtn')}
								handleClick={handleCancelClick}
								id='cancel-btn'
							/>
						</Box>
						<Box>
							{showAddAnother && (
								<Tooltip title={addAnotherTooltipText || ''} arrow classes={{ popper: 'add-another-tooltip' }}>
									<FormControlLabel
										control={
											<Checkbox
												checked={addAnother}
												onChange={handleAddAnother}
												icon={<BorderCheckboxIcon />}
												checkedIcon={<CheckedIcon />}
											/>
										}
										label={t('addAnotherLabel')}
										className='add-another-option'
										id='add-another-option'
									/>
								</Tooltip>
							)}
							{isSaveDraft && (
								<OKRButton
									disabled={isDraftButtonDisabled || false}
									handleClick={handleDraftButtonClick}
									icon={draftButtonIcon || <DraftIcon />}
									className={'btn-link'}
									id={'btn-save-draft'}
									text={draftButtonText || t('saveAsDraft')}
								/>
							)}
							{isShowSeparator ? <span className='sep-or'>{separatorContent}</span> : <></>}
							{isSaveButtonVisible ? (
								<OKRButton
									disabled={isSaveButtonDisabled || false}
									className='btn-primary'
									text={saveButtonText || t('saveBtn')}
									handleClick={handleSaveClick}
									id='save-btn'
								/>
							) : (
								<></>
							)}
						</Box>
					</Box>
				)}
			</Box>
		</Drawer>
	);
};
