import React, { useEffect, useState } from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import '../../../styles/pages/global-task/globalTask.scss';
import { TaskFilter } from './TaskFilter';
import { TaskGroupBy } from './TaskGroupBy';
import { TaskSorting } from './TaskSorting';
import { TaskSearch } from './TaskSearch';
import { TaskListDetails } from './TaskListDetails';
import {
	getGlobalTaskDaysFilterNewDetails,
	getGlobalTaskPriorityDetails,
	getGlobalTaskSourceDetails,
} from '../../../config/utils';
import { getAllGlobalTask } from '../../../action/globalTask';
import { useDispatch } from 'react-redux';
import { Enums } from '../../../config/enums';
import { GroupByTaskList } from './GroupByTaskList';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { getLocalStorageItem } from '../../../services/StorageService';
import { ResetIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NotesImg from '../../../images/no-found-notes.svg';
import AlertDialog from '../../Common/Dialog';

export const TaskList: React.FC<any> = (props: any) => {
	const {
		allTaskList,
		completedTaskCount,
		toDoTaskCount,
		handleFilterCompletedOnly,
		filterDetails,
		handleGroupByFilterClick,
		handleSortingFilterClick,
		tabSelected,
		setLoader,
		globalTaskRequest,
		setGlobalTaskRequest,
		setPageIndex,
		showLoadMore,
		pageIndexVal,
		fetchGlobalTask,
		loader,
		handleTaskNameClick,
		handleTaskChange,
		handleTaskOnEnterEdit,
		handleEditTaskClose,
		handleTaskUpdate,
		setIsLaunchFormEdited,
		handlePriorityChangeOnEdit,
		handleAddTaskDueDateChangeOnEdit,
		handleTaskLinkChange,
		handleResetButtonClickOnEdit,
		onSelectedUserOnEdit,
		handleRemoveAssigneeOnEdit,
		handleKRLinkOnEdit,
		handleRemoveKROnEdit,
		taskGroupByList,
		handleCollapseTask,
		openGroupByTask,
		setOpenGroupByTask,
		handleCompleteTaskOnEdit,
		handleDeleteTaskOnEdit,
		defaultRecentUserList,
		searchTextArray,
		setSearchTextArray,
		handleSearchText,
		minDateValue,
		maxDateValue,
		setIsDateRangeChanged,
		selectedFilterDateRange,
		setSelectedFilterDateRange,
		resetCalendarDate,
		setIsCreateLinkPopupOpen,
		callingFrom,
		highlightRedirectedTask,
		callForOther = false,
		searchedInEmpId = '',
		checkInUserEmpId = 0,
		taskPagingInfo = {},
		dueDateError = null,
		setDueDateError = () => {},
		handleCheckInSelectionOnEdit = () => {},
		handleRemoveCheckInSelectionOnEdit = () => {},
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentCycleDetails = getLocalStorageItem('currentCycle');
	const curCycleData = currentCycleDetails ? JSON.parse(currentCycleDetails) : {};
	const startDateValue = new Date();
	startDateValue.setMonth(startDateValue.getMonth() - 6);

	const [filterCondition, setFilterCondition] = useState<any>({
		days: [],
		source: [],
		filterData: [],
		priority: [],
	});
	const [localFilterCondition, setLocalFilterCondition] = useState<any>({
		days: [],
		source: [],
		filterData: [],
		priority: [],
	});
	const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
	const [dueDateRemovalModalProps, setDueDateRemovalModalProps] = useState<any>({
		open: false,
		message: '',
		details: '',
	});

	/** Task  filter start */
	const handleApplyFilterClick = (e: any) => {
		const { days, source, filterData, priority } = filterCondition;
		setAppliedFilter(true);
		setLocalFilterCondition({
			...localFilterCondition,
			days: days && days.length === 1 && days[0] === 0 ? [] : [...days],
			source: [...source],
			filterData: [...filterData],
			priority: [...priority],
		});
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask && setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			taskFilter: {
				dueDate:
					filterCondition?.days && filterCondition?.days.length === 1 && filterCondition?.days[0] === 0
						? []
						: filterCondition?.days,
				taskType: filterCondition?.source,
				priority: filterCondition?.priority,
			},
			pageIndex: 1,
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	const handleFilterChanged = (e: any, id: any, type: string) => {
		let filterDays = [...filterCondition.days];
		let filterSource = [...filterCondition.source];
		let filterData = [...filterCondition.filterData];
		let filterPriority = [...filterCondition.priority];

		if (type === 'days') {
			let item = filterDays.indexOf(id);
			if (filterDays.includes(id)) {
				if (filterDays.length === 1) {
					filterDays.splice(item, 1);
					filterDays.push(0);
				} else {
					filterDays.splice(item, 1);
				}

				const filterDataItem = filterData.find((item: any) => {
					return item.id === id ? filterData.indexOf(item) : -1;
				});
				filterData.splice(filterDataItem, 1);
			} else {
				if (filterDays.length === 1 && filterDays[0] === 0) {
					filterDays.splice(0, 1);
				}
				filterDays.push(id);
				filterData.push({ id: id, name: getGlobalTaskDaysFilterNewDetails(id), type: type });
			}
		}

		if (type === 'source') {
			let item = filterSource.indexOf(id);
			if (filterSource.includes(id)) {
				filterSource.splice(item, 1);
				const filterDataItem = filterData.find((item: any) => {
					return item.id === id ? filterData.indexOf(item) : -1;
				});
				filterData.splice(filterDataItem, 1);
			} else {
				filterSource.push(id);
				filterData.push({ id: id, name: getGlobalTaskSourceDetails(id), type: type });
			}
		}
		if (type === 'priority') {
			//filterPriority
			let item = filterPriority.indexOf(id);
			if (filterPriority.includes(id)) {
				filterPriority.splice(item, 1);
				const filterDataItem = filterData.find((item: any) => {
					return item.id === id ? filterData.indexOf(item) : -1;
				});
				filterData.splice(filterDataItem, 1);
			} else {
				filterPriority.push(id);
				filterData.push({ id: id, name: getGlobalTaskPriorityDetails(id), type: type });
			}
		}

		// if (filterSource.length > 0 || (filterDays.length > 0 && filterDays[0] !== 0) || filterPriority?.length > 0) {
		// 	setAppliedFilter(true);
		// } else {
		// 	setAppliedFilter(false);
		// }

		setFilterCondition({
			...filterCondition,
			days: filterDays,
			source: filterSource,
			filterData: filterData,
			priority: filterPriority,
		});
	};
	const handleCloseChip = (filter: any) => {
		let filterDays = [...filterCondition.days];
		let filterSource = [...filterCondition.source];
		let filterData = [...filterCondition.filterData];
		let filterPriority = [...filterCondition.priority];

		if (filter.type === 'days') {
			let item = filterDays.indexOf(filter.id);
			if (filterDays.includes(filter.id)) {
				filterDays.splice(item, 1);
			}
		} else if (filter.type === 'source') {
			let item = filterSource.indexOf(filter.id);
			if (filterSource.includes(filter.id)) {
				filterSource.splice(item, 1);
			}
		} else if (filter.type === 'priority') {
			let item = filterPriority.indexOf(filter.id);
			if (filterPriority.includes(filter.id)) {
				filterPriority.splice(item, 1);
			}
		}

		let filterDataItem = filterData.findIndex((item: any) => item.id === filter.id && item.type === filter.type);
		if (filterData.length === 1) {
			filterData.splice(filterDataItem, 1);
			setAppliedFilter(false);
			setFilterCondition({
				...filterCondition,
				days: filterDays,
				source: filterSource,
				filterData: [],
				priority: filterPriority,
			});
			setLocalFilterCondition({
				...localFilterCondition,
				days: filterDays,
				source: filterSource,
				filterData: [],
				priority: filterPriority,
			});
		} else {
			filterData.splice(filterDataItem, 1);
			setFilterCondition({
				...filterCondition,
				days: filterDays,
				source: filterSource,
				filterData: filterData ? filterData : [],
				priority: filterPriority,
			});
			setLocalFilterCondition({
				...localFilterCondition,
				days: filterDays,
				source: filterSource,
				filterData: filterData ? filterData : [],
				priority: filterPriority,
			});
		}
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask && setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			taskFilter: {
				dueDate: filterDays && filterDays.length === 1 && filterDays[0] === 0 ? [] : filterDays,
				taskType: filterSource,
				priority: filterPriority,
			},
			pageIndex: 1,
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	const clearFilter = () => {
		setAppliedFilter(false);
		setFilterCondition({
			...filterCondition,
			days: [],
			source: [],
			filterData: [],
			priority: [],
		});
		setLocalFilterCondition({
			...localFilterCondition,
			days: [],
			source: [],
			filterData: [],
			priority: [],
		});
		setLoader(true);
		setPageIndex(1);
		setOpenGroupByTask && setOpenGroupByTask({ taskExpand: false, taskGroupById: 0 });
		const data = {
			...globalTaskRequest,
			taskGroupById: Enums.ZERO,
			taskFilter: {
				dueDate: [],
				taskType: [],
				priority: [],
			},
			// taskType: [],
			// dueDateFilter: [],
			pageIndex: 1,
		};
		setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};

	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (dueDateRemovalModalProps?.details) {
				handleResetButtonClickOnEdit(dueDateRemovalModalProps?.details);
			}
			setDueDateRemovalModalProps({ open: false, message: '', details: '' });
		} else {
			setDueDateRemovalModalProps({ open: false, message: '', details: '' });
		}
	};

	return (
		<Box className='task-list-area'>
			{dueDateRemovalModalProps && dueDateRemovalModalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={dueDateRemovalModalProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={dueDateRemovalModalProps.open}
				/>
			)}
			{callingFrom === 'OneOnOneTask' ? (
				<></>
			) : (
				<Box className='task-list-head'>
					<Box className='task-list-head-left'>
						{callingFrom === 'GlobalTask' || callingFrom === 'KrTask' || callingFrom === 'CheckInTask' ? (
							<TaskGroupBy
								callingFrom={callingFrom}
								filterDetails={filterDetails}
								handleGroupByFilterClick={handleGroupByFilterClick}
							/>
						) : (
							<></>
						)}
						<Box className='is-complted-switch'>
							<FormControlLabel
								control={
									<Switch
										name='isCompleted'
										checked={Boolean(filterDetails?.isCompleted)}
										onChange={handleFilterCompletedOnly}
									/>
								}
								label={`${t('completedLevel')} (${completedTaskCount})`}
								//labelPlacement='start'
								className='toggle-label'
							/>
						</Box>
					</Box>

					<Box className='task-list-head-right'>
						{callingFrom === 'GlobalTask' ? (
							<Box className='range-selector-calender'>
								<ReactDateRangePicker
									type={'ContributorList'}
									selectedCycleDetails={curCycleData || {}}
									startDateValue={null}
									endDateValue={null}
									minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
									maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
									handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
										range && setIsDateRangeChanged(true);
										range && setSelectedFilterDateRange(range);
									}}
									isDateRangeTextFieldVisible={true}
									isOnlyDueDateVisible={false}
									allCycleDetails={[]}
									isCycleVisible={false}
									isEndOfMonthVisible={false}
									numberOfCalendars={Enums.TWO}
									selectionType={'range'}
									selectedDateRange={selectedFilterDateRange}
									setSelectedDateRange={setSelectedFilterDateRange}
									placeholderText={'Select date range'}
								/>
								{selectedFilterDateRange ? (
									<OKRButton
										className='btn-circle-blue reset-btn'
										icon={<ResetIcon />}
										title={'Reset Dates'}
										handleClick={(e: any) => resetCalendarDate(true)}
									/>
								) : (
									<></>
								)}
							</Box>
						) : (
							<></>
						)}
						{/* {callingFrom === 'GlobalTask' ? ( */}
						<TaskFilter
							{...props}
							t={t}
							handleApplyFilterClick={handleApplyFilterClick}
							handleFilterChanged={handleFilterChanged}
							handleCloseChip={handleCloseChip}
							clearFilter={clearFilter}
							appliedFilter={appliedFilter}
							filterCondition={filterCondition}
							localFilterCondition={localFilterCondition}
							selectTaskTab={tabSelected}
						/>
						{/* ) : (
						<></>
					)} */}
						{callingFrom === 'GlobalTask' || callingFrom === 'OneOnOneTask' || callingFrom === 'CheckInTask' ? (
							<TaskSorting filterDetails={filterDetails} handleSortingFilterClick={handleSortingFilterClick} />
						) : (
							<></>
						)}
						<TaskSearch
							searchTextArray={searchTextArray}
							setSearchTextArray={setSearchTextArray}
							handleSearchText={handleSearchText}
						/>
					</Box>
				</Box>
			)}
			{allTaskList && allTaskList.length === Enums.ZERO && taskGroupByList && taskGroupByList?.length === Enums.ZERO ? (
				<>
					{/* {(!globalTaskRequest?.startDate && !globalTaskRequest?.endDate) || callingFrom === 'CheckInTask' ? (
						<Box className='welcome-content'>
							<Box textAlign='center'>
								<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
								<Typography>{t('noTaskFoundSubTitle')}</Typography>
								<AnimatedIcon
									className='no-task-image'
									width='323px'
									type='image/svg+xml'
									alt={t('noTaskHead')}
									imagePath={NotesImg}
								/>
							</Box>
						</Box>
					) : ( */}
					<Box className='welcome-content'>
						<Box textAlign='center'>
							<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
							<Typography>{t('noTaskFoundSubTitle')}</Typography>
							<AnimatedIcon
								className='no-task-image'
								width='323px'
								type='image/svg+xml'
								alt={t('noTaskHead')}
								imagePath={NotesImg}
							/>
						</Box>
					</Box>
					{/* )} */}
				</>
			) : (
				<>
					{filterDetails && filterDetails?.taskGroupByType === Enums.ZERO ? (
						<TaskListDetails
							allTaskList={allTaskList}
							taskPagingInfo={taskPagingInfo}
							showLoadMore={showLoadMore}
							pageIndexVal={pageIndexVal}
							fetchGlobalTask={fetchGlobalTask}
							filterDetails={filterDetails}
							loader={loader}
							handleTaskNameClick={handleTaskNameClick}
							handleTaskChange={handleTaskChange}
							handleTaskOnEnterEdit={handleTaskOnEnterEdit}
							handleEditTaskClose={handleEditTaskClose}
							handleTaskUpdate={handleTaskUpdate}
							setIsLaunchFormEdited={setIsLaunchFormEdited}
							handlePriorityChangeOnEdit={handlePriorityChangeOnEdit}
							handleAddTaskDueDateChangeOnEdit={handleAddTaskDueDateChangeOnEdit}
							handleTaskLinkChange={handleTaskLinkChange}
							handleResetButtonClickOnEdit={(task: any) => {
								if (callingFrom === 'CheckInTask' || !task?.isCheckedInLinked) {
									handleResetButtonClickOnEdit(task);
								} else {
									setDueDateRemovalModalProps({
										open: true,
										message:
											'As the task is added to your check-in, removing the due date will also remove it from check-in. Do you wish to proceed?',
										details: task,
									});
								}
							}}
							onSelectedUserOnEdit={onSelectedUserOnEdit}
							handleRemoveAssigneeOnEdit={handleRemoveAssigneeOnEdit}
							handleKRLinkOnEdit={handleKRLinkOnEdit}
							handleRemoveKROnEdit={handleRemoveKROnEdit}
							handleCompleteTaskOnEdit={handleCompleteTaskOnEdit}
							handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
							defaultRecentUserList={defaultRecentUserList}
							setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
							callingFrom={callingFrom}
							globalTaskRequest={globalTaskRequest}
							highlightRedirectedTask={highlightRedirectedTask}
							callForOther={callForOther || false}
							searchedInEmpId={searchedInEmpId}
							completedTaskCount={completedTaskCount}
							checkInUserEmpId={checkInUserEmpId}
							dueDateError={dueDateError}
							setDueDateError={setDueDateError}
							handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
							handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
						/>
					) : (
						<>
							<GroupByTaskList
								allTaskList={allTaskList}
								showLoadMore={showLoadMore}
								pageIndexVal={pageIndexVal}
								fetchGlobalTask={fetchGlobalTask}
								filterDetails={filterDetails}
								loader={loader}
								handleTaskNameClick={handleTaskNameClick}
								handleTaskChange={handleTaskChange}
								handleTaskOnEnterEdit={handleTaskOnEnterEdit}
								handleEditTaskClose={handleEditTaskClose}
								handleTaskUpdate={handleTaskUpdate}
								setIsLaunchFormEdited={setIsLaunchFormEdited}
								handlePriorityChangeOnEdit={handlePriorityChangeOnEdit}
								handleAddTaskDueDateChangeOnEdit={handleAddTaskDueDateChangeOnEdit}
								handleResetButtonClickOnEdit={(task: any) => {
									if (callingFrom === 'CheckInTask' || !task?.isCheckedInLinked) {
										handleResetButtonClickOnEdit(task);
									} else {
										setDueDateRemovalModalProps({
											open: true,
											message:
												'As the task is added to your check-in, removing the due date will also remove it from check-in. Do you wish to proceed?',
											details: task,
										});
									}
								}}
								onSelectedUserOnEdit={onSelectedUserOnEdit}
								handleRemoveAssigneeOnEdit={handleRemoveAssigneeOnEdit}
								handleKRLinkOnEdit={handleKRLinkOnEdit}
								handleRemoveKROnEdit={handleRemoveKROnEdit}
								handleCompleteTaskOnEdit={handleCompleteTaskOnEdit}
								handleDeleteTaskOnEdit={handleDeleteTaskOnEdit}
								taskGroupByList={taskGroupByList}
								handleCollapseTask={handleCollapseTask}
								openGroupByTask={openGroupByTask}
								defaultRecentUserList={defaultRecentUserList}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
								callingFrom={callingFrom}
								globalTaskRequest={globalTaskRequest}
								highlightRedirectedTask={highlightRedirectedTask}
								callForOther={callForOther || false}
								searchedInEmpId={searchedInEmpId}
								completedTaskCount={completedTaskCount}
								handleTaskLinkChange={handleTaskLinkChange}
								checkInUserEmpId={checkInUserEmpId}
								dueDateError={dueDateError}
								setDueDateError={setDueDateError}
								handleCheckInSelectionOnEdit={handleCheckInSelectionOnEdit}
								handleRemoveCheckInSelectionOnEdit={handleRemoveCheckInSelectionOnEdit}
							/>
						</>
					)}
				</>
			)}
		</Box>
	);
};
