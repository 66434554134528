import React, { useState, ChangeEvent, FormEvent, useEffect } from 'react';
import { Box, Input, Button, FormControl, Select, MenuItem, Typography, TextField, InputAdornment, Popper, TablePagination, ListItem, FormControlLabel, Checkbox, List, Paper, Tooltip, Link } from '@material-ui/core';

import LetterTable from './LetterTable';
import Header from '../../containers/Header';
import { SearchIcon } from '../../config/svg/CommonSvg';
import LetterDrawer from './LetterDrawer';
import { OKRButton } from '../Common/OKRButton';
import { NewFilterIcon, ResetIcon } from '../../config/svg/CommonSvgIcon';
import { DownArrowIcon, UpArrowIcon } from '../../config/svg/ArrowSvg';
import { BorderCheckboxIcon, CheckedIcon } from '../../config/svg/formElementIcons';
import { useTranslation } from 'react-i18next';
import LetterColumn from './LetterColumn';
import LinearLoader from '../Common/Loader';
import { CrossIcon } from '../../config/svg/CommonSvgIcon';
import { CancelIcon } from '../../config/svg/Action';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { getLocalStorageItem } from '../../services/StorageService';
import { Enums } from '../../config/enums';

const LetterGen: React.FC = (props: any) => {
	const {
		selectedFilterDateRange,
		setSelectedFilterDateRange = () => { },
		type = 'lg',
	} = props;
	const minDateValue = new Date();
	minDateValue.setFullYear(minDateValue.getFullYear() - 50);
	const maxDateValue = new Date();
	maxDateValue.setFullYear(maxDateValue.getFullYear() + 5);

	interface Template {
		id: string;
		name: string;
	}

	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const [selectTemplate, setSelectTemplate] = useState<string>('Select Template');
	const { t } = useTranslation();
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [page, setPage] = React.useState(2);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [loader, setLoader] = useState<boolean>(false);
	const [shouldUpdateData, setShouldUpdateData] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [filterType, setFilterType] = useState<string[]>([]);
	const [sortKey, setSortKey] = useState('name');
	const [sortDirection, setSortDirection] = useState('asc');
	const [templates, setTemplates] = useState<Template[]>([]);
	const [filterTrigger, setFilterTrigger] = useState(0);
	const [recordsAvailable, setRecordsAvailable] = useState<boolean>(true);
	const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
	const [isDateRangeChanged, setIsDateRangeChanged] = useState<any>(null);
	const [selectedEndDate, setSelectedEndDate] = useState<any>(null);


	const dateChanged = (range: any) => {
		range && setIsDateRangeChanged(true);
		range && setSelectedFilterDateRange(range);
		range && setSelectedEndDate(range);
		applyFilters();
	};
	const resetCalendarDate = () => {
		setIsDateRangeChanged(false);
		setSelectedFilterDateRange(null);
		setSelectedEndDate(null);
	};


	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
	};

	const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {

		const templateName = event.target.value;
		const isChecked = event.target.checked;
		setFilterType((prevFilterTypes) => {
			if (isChecked) {
				// Add to filter types if checked
				return [...prevFilterTypes, templateName];
			} else {
				// Remove from filter types if unchecked
				return prevFilterTypes.filter((type) => type !== templateName);
			}
		});
		setSelectedTemplates((prevSelected) =>
			isChecked ? [...prevSelected, templateName] : prevSelected.filter((name) => name !== templateName)
		);
	};

	const handleSortChange = (key: string) => {
		setSortKey(key);
		setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
	};

	const applyFilters = () => {
		setFilterTrigger(prev => prev + 1);


	};
	const refreshData = () => {
		setShouldUpdateData(true);
	};

	const handleToggleFilter = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpenFilter((prev) => !prev);
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files?.[0];
		setSelectedFile(file || null);
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (selectedFile) {
			console.log('Selected File:', selectedFile);
		} else {
			console.log('No file selected');
		}

		setSelectedFile(null);
	};

	const handleSelectChange = (e: ChangeEvent<{ value: unknown }>) => {
		const selectedValue = e.target.value as string;
		setSelectTemplate(selectedValue);
	};

	const handleDownload = () => {
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const scrollPageHeader = (e: any) => {
		const objElement = document.getElementById('page-engagement-subheader');
		if (window.pageYOffset >= 88) {
			objElement && objElement.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement && objElement.classList.remove('sticky-header');
		}
	};

	const handleChangePage = (
		event: React.MouseEvent<HTMLButtonElement> | null,
		newPage: number,
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleCloseChip = () => {

	}

	const clearFilter = () => {

	}

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='page-subheader page-subheader-notification' id='page-engagement-subheader'>
				<Box className='page-subheader-inner' style={{ 'height': '68px' }}>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>Letter Generation</Typography>
						</Box>
					</Box>
					<Box className='page-subheader-right'>
						<Box className='page-subheader-right-inner'>
							<Header {...props} fromTab={true} isLetter={true} />
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='letter-container'>
				<Box className='letter-head-top okr-listing-head'>
					<Box className='letter-head-left okr-listing-head-left'></Box>
					<Box className='letter-head-right okr-listing-head-right'>
						{/* <TablePagination
							component='div'
							className='user-list-pagination lg-pagination'
							count={100}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPage={rowsPerPage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/> */}
						

						<Box className='lg-area-cal'>
							<Box className='range-selector-calender'>
								<ReactDateRangePicker
									type={'PipDateRange'}
									tooltipText={type !== 'feedback' ? t('PipListDateRangeText') : t('FeedbackListDateRangeText')}
									hideTooltip={type !== 'feedback'}
									selectedCycleDetails={{}}
									minimumDate={minDateValue}
									maximumDate={maxDateValue}
									handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
										dateChanged(range);
									}}
									isDateRangeTextFieldVisible={true}
									isOnlyDueDateVisible={false}
									allCycleDetails={[]}
									isCycleVisible={false}
									isEndOfMonthVisible={false}
									numberOfCalendars={Enums.TWO}
									selectionType={'range'}
									selectedDateRange={selectedEndDate || null}
									setSelectedDateRange={(range: any) => {
										dateChanged(range);
									}}
									placeholderText={t('dateRangeText')}
								/>
								{selectedFilterDateRange ? (
									<OKRButton
										className='btn-circle-blue reset-btn'
										icon={<ResetIcon />}
										title={t('resetDates')}
										handleClick={(e: any) => resetCalendarDate()}
									/>
								) : (
									<></>
								)}
							</Box>
						</Box>

						<Box className='goal-okr-filter alignment-map-filter lg-okr-filter' style={{ 'display': 'none' }}>
							<Box className={`filter-chip `}>

								<List>
									{selectedTemplates.map((templateName) => (
										<ListItem>
											<Tooltip arrow title={templateName}>
												<Typography variant='body2'>{templateName}</Typography>
											</Tooltip>
											<Button className='cancel-btn' onClick={() => handleCloseChip()}>
												<CancelIcon />
											</Button>
										</ListItem>


									))}
									<ListItem className='clear-all'>
										<Link onClick={clearFilter}>{t('clearAll')}</Link>
									</ListItem>

								</List>
							</Box>
						</Box>
						<Box>
							<OKRButton
								className={`list-filter-btn ${openFilter ? 'active' : ''}`}
								icon={<NewFilterIcon />}
								text={
									<span className='filter-txt'>
										{t('filterLabel')}
										{openFilter ? <UpArrowIcon /> : <DownArrowIcon />}
									</span>
								}
								handleClick={handleToggleFilter}
								id='dashboard-filter-btn'
							/>
							<Popper id={id}
								className={`okr-filter alignmentmap-filter-list dashboard-filter-list letter-filter`}
								open={openFilter}
								anchorEl={anchorEl}
								placement={'bottom-end'}
							>
								<Paper>
									<Box className='alignmentFilterWrap letter-filter'>
										<Box className='okr-type-filter'>
											<Typography variant='body2'>{t('byTypeLabel')}:</Typography>
											<List>
												{templates.map((template) => (
													<ListItem key={template.id}>
														<FormControlLabel
															control={
																<Checkbox
																	onChange={handleFilterChange}
																	key={template.id}
																	value={template.name}
																	checked={filterType.includes(template.name)}
																	icon={<BorderCheckboxIcon />}
																	checkedIcon={<CheckedIcon />}
																	color='primary'
																	disabled={props.alignStatus === 'pending'}
																/>} label={template.name} />
													</ListItem>))}
											</List>
										</Box>
										<Box className='action-btn'>
											<OKRButton
												className='btn-secondary'
												text={t('applyLabel')}
												handleClick={applyFilters}
											// handleClick={(e: any) => {
											// 	handleClose(e);
											// 	applyFilters;
											// }}
											/>
										</Box>
									</Box>
								</Paper>
							</Popper>

						</Box>

						<Box className='user-search'>
							<TextField
								className='search-text-field'
								id='user-src-field'
								placeholder='Search'
								value={searchTerm}
								onChange={handleSearchChange}
								fullWidth
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Box>

						<LetterColumn />
						<LetterDrawer onRefreshData={refreshData} templates={templates} setTemplates={setTemplates} />

					</Box>
				</Box>
				<Box className='form-outer' style={{ 'display': 'none' }}>
					<form onSubmit={handleSubmit}>
						<Box>
							<Box className='select-template full-rectangular-card'>
								<Typography variant='h5' className='font-weight-normal'>
									Select Template
								</Typography>
								<Box className='select-template_inner'>
									<FormControl>
										<Select className='select_field' value={selectTemplate} onChange={handleSelectChange}>
											<MenuItem value='Select Template' disabled>
												Select Template
											</MenuItem>
											<MenuItem value='Salary Revision Template'>Salary Revision Template</MenuItem>
											<MenuItem value='Promotion Letter Template'>Promotion Letter Template</MenuItem>
										</Select>
									</FormControl>

									{selectTemplate && (
										<div>
											<Button className='btn-primary' variant='contained' onClick={handleDownload}>
												Download
											</Button>
										</div>
									)}
								</Box>
							</Box>
						</Box>

						<Box>
							<Box mt={2} className='select-template full-rectangular-card'>
								<Typography variant='h5' className='font-weight-normal'>
									Choose Template
								</Typography>
								<Box className='select-template_inner'>
									<Input type='file' inputProps={{ accept: '*/*' }} onChange={handleFileChange} />
									<Button className='btn-primary' variant='contained' type='submit'>
										Submit
									</Button>
								</Box>
							</Box>
						</Box>
					</form>
				</Box>
				<Box>

					{recordsAvailable ? (
						<LetterTable searchTerm={searchTerm}
							filterType={filterType}
							sortKey={sortKey}
							sortDirection={sortDirection}
							handleSortChange={handleSortChange}
							shouldUpdateData={shouldUpdateData}
							onUpdated={() => setShouldUpdateData(false)}
							filterTrigger={filterTrigger}
							selectedDateRange={selectedEndDate}
						/>
					) : (
						<Box>No match found</Box>
					)}

				</Box>


			</Box>

		</>
	);
};

export default LetterGen;
