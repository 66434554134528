import React, { Fragment, useState } from 'react';
import { DefaultOkrList } from './DefaultOkrList';
import CloneDefaultOkrDialog from './CloneDefaultOkrDialog';

export const DefaultOkr: React.FC<any> = (props: any) => {
	const { handleClickDefaultOkr } = props;
	// const { enqueueSnackbar } = useSnackbar();
	// const { t } = useTranslation();
	const [openCloneDialog, setOpenCloneDialog] = useState<any>({
		open: false,
		openId: 0,
	});
	const handleCloneOkr = (e: any, defaultGoalObjectiveId: number) => {
		setOpenCloneDialog({
			...openCloneDialog,
			open: !openCloneDialog.open,
			openId: defaultGoalObjectiveId,
		});
	};
	const handleCloneSubmit = async (e: React.ChangeEvent<HTMLInputElement>, cloneObj: any) => {};
	return (
		<>
			<DefaultOkrList
				{...props}
				// selectedCycleDetails={selectedCycleDetails}
				handleCloneOkr={handleCloneOkr}
				// setShowLoader={setShowLoader}
				handleClickDefaultOkr={handleClickDefaultOkr}
			/>
			<CloneDefaultOkrDialog
				modalOpen={openCloneDialog}
				handleCloseModal={handleCloneOkr}
				handleCloneSubmit={handleCloneSubmit}
			/>
		</>
	);
};
