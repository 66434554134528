import React, { Fragment, useState, useEffect } from 'react';
import {
	Box,
	Chip,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Typography,
	TablePagination,
	Tooltip,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';
import {
	AddIconSvg,
	CloneIcon,
	CrossIcon,
	// CrossIcon,
	EditIcon,
	SortingIcon,
	TickIcon,
} from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
// import { NoRecord } from '../../CommonComponent/NoRecord';
import { Visibility } from '../../../../config/svg/ProfileSvg';
import { DeleteIcon } from '../../../../config/svg/CommonSvg';
import { PopperMenu } from '../../CommonComponent/PopperMenu';
import { useDispatch } from 'react-redux';
import { ratingFormDelete, ratingFormList } from '../../../../action/adminSettings';
import { Enums } from '../../../../config/enums';
import { getFullName, getMonthDateYear } from '../../../../config/utils';
import { CloseIcon } from '../../../../config/svg/GlobalSvg';
import { AssessmentPreview } from './AssessmentPreview';
import { useSnackbar } from 'notistack';
import AlertDialog from '../../../Common/Dialog';
import { DialogComponent } from '../../CommonComponent/DialogComponent';

export const AssessmentPreviewDialog: React.FC<any> = (props: any) => {
	const { handleCloseDialog, assessmentPreview, performanceRatings } = props;
	// const { t } = useTranslation();

	return (
		<Dialog
			onClose={(e) => handleCloseDialog(e, false)}
			aria-labelledby='simple-dialog-title'
			open={assessmentPreview?.open}
			className='assessment-preview-popup'
		>
			<OKRButton className='close-assessment-popup' icon={<CrossIcon />} handleClick={handleCloseDialog} />
			<Box className=''>
				<AssessmentPreview assessmentPreview={assessmentPreview} performanceRatings={performanceRatings} />
			</Box>
		</Dialog>
	);
};

export const AssessmentManageForm: React.FC<any> = (props: any) => {
	const {
		setLoader,
		openAssessmentForm,
		setAssessmentTabSelected,
		handleSelectedManageRatingItem,
		// assessmentPreview,
		setAssessmentPreview,
	} = props;
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const maxLen = 15;

	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [reviewFormList, setReviewFormList] = useState<any>(null);
	const [totalUser, setTotalUser] = useState(0);
	const [pageIndex, setPageIndex] = useState<any>(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const publishType = openAssessmentForm?.ratingFormType === 'direct' ? 2 : 1;

	useEffect(() => {
		getRatingFormListDetails({
			publishType: publishType || 1, // 1 = ALL, 2 = Published, 3 = Unpublished || Draft
			finder: searchTextArray,
			pageIndex: 1,
			sortingText: sortingColumn,
			order: sortOrder,
			pageSize: pageSize,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getRatingFormListDetails = async (requestPayload: any) => {
		setLoader(true);
		const response: any = await dispatch(ratingFormList(requestPayload));
		if (response) {
			!reviewFormList && setReviewFormList({ records: [] });
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				const { records } = entity;
				const updatedData = { records: records };
				setReviewFormList(updatedData);
				setTotalUser(entity && entity.totalRecords);
				setLoader(false);
			} else {
				setLoader(false);
			}
		}
		setLoader(false);
	};
	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		getRatingFormListDetails({
			publishType: publishType || 1,
			finder: searchTextArray,
			pageIndex: newPage,
			sortingText: sortingColumn,
			order: sortOrder,
			pageSize: pageSize,
		});
	};

	const handleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		getRatingFormListDetails({
			publishType: publishType || 1,
			finder: searchTextArray,
			pageIndex: 1,
			sortingText: sortingColumn,
			order: sortOrder,
			pageSize: pSize,
		});
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}

		getRatingFormListDetails({
			publishType: publishType || 1,
			finder: searchTextArray,
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			pageSize: pageSize,
		});
		setSortingColumn(column);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpenAssessmentPreview = (event: any, record: any) => {
		setAssessmentPreview({ open: true, details: record });
	};

	const handleSelectRatingItem = (e: any, record: any, index: number, performanceCompetencyRecord: any) => {
		const updatedReviewFormList =
			reviewFormList && reviewFormList?.records && reviewFormList?.records?.length
				? reviewFormList.records.map((item: any) => {
						if (item.ratingFormId === record.ratingFormId) {
							item.selectedItem = true;
						} else {
							item.selectedItem = false;
						}

						return item;
				  })
				: [];

		handleSelectedManageRatingItem({ ...record, selectedItem: true }, performanceCompetencyRecord);

		setReviewFormList({ records: [...updatedReviewFormList] });
	};

	const handleCloseModal = async (e: any, type: any) => {
		//e.preventDefault();
		if (type === 1) {
			callDeleteRatingFormAPI();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleDeleteRecordClick = (e: any, record: any, type: string) => {
		e.preventDefault();
		e.stopPropagation();
		if (record && record.ratingFormId) {
			if (type === 'delete') {
				setModalProps({
					open: true,
					message: t('deleteConfirmationForAlignment', {
						type: 'assessment rating form',
					}),
					details: record,
				});
			} else if (type === 'clone') {
				setAssessmentTabSelected({ activeTab: 1, cloneDetails: record });
			} else if (type === 'edit') {
				setAssessmentTabSelected({ activeTab: 1, editDetails: record });
			}
		}
	};

	const callDeleteRatingFormAPI = async () => {
		if (modalProps.details && modalProps.details.ratingFormId) {
			setLoader(true);
			const queryParams = `ratingFormId=${modalProps.details.ratingFormId}`;
			setModalProps({ open: false, message: '', details: '' });
			const response: any = await dispatch(ratingFormDelete(queryParams));
			if (response) {
				if (response && response?.data && response.data.status === 200) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Rating form deleted successfully', {
						variant: 'success',
						autoHideDuration: 3000,
					});
					getRatingFormListDetails({
						publishType: publishType || 1, // 1 = ALL, 2 = Published, 3 = Unpublished || Draft
						finder: searchTextArray,
						pageIndex: 1,
						sortingText: sortingColumn,
						order: sortOrder,
						pageSize: pageSize,
					});
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while deleting assessment form', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setLoader(false);
			}
		}
	};

	return (
		<Box className='assessment-manage-form'>
			<Box className='drawer-input-field sub-head-button'>
				{openAssessmentForm?.ratingFormType === 'direct' ? (
					<></>
				) : (
					<Box className='add-user-field'>
						<OKRButton
							handleClick={() => {
								setAssessmentTabSelected({ activeTab: 1 });
							}}
							className='add-btn2'
							id={'add-member-btn'}
							icon={<AddIconSvg />}
							color='primary'
						/>
						<Typography variant='subtitle1'>
							<em>{t('addForm')}</em>
						</Typography>
					</Box>
				)}
				<>
					{totalUser ? (
						<TablePagination
							component='div'
							className='user-list-pagination'
							count={totalUser}
							page={pageIndex > 1 ? pageIndex - 1 : 0}
							onPageChange={handleChangePage}
							rowsPerPage={pageSize}
							onRowsPerPageChange={handleChangeRowsPerPage}
							SelectProps={{
								MenuProps: {
									PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
								},
							}}
						/>
					) : (
						<></>
					)}
				</>
			</Box>
			<Fragment>
				<Box
					className={
						openAssessmentForm?.ratingFormType === 'direct'
							? 'assessment-listing assessment-template-listing'
							: 'assessment-listing'
					}
				>
					<Fragment>
						{reviewFormList && reviewFormList?.records && reviewFormList?.records.length > 0 ? (
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='assessment-col1'>
										<Typography
											variant='body2'
											onClick={(e) => sortUserList(e, 'name')}
											className={sortingColumn === 'name' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('formName')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='assessment-col2'>
										<Typography
											variant='body2'
											onClick={(e) => sortUserList(e, 'isPublish')}
											className={sortingColumn === 'isPublish' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{'Status'} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='assessment-col3'>
										<Typography
											variant='body2'
											onClick={(e) => sortUserList(e, 'createdOn')}
											className={sortingColumn === 'createdOn' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{'Created'} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='assessment-col4'>
										<Typography
											variant='body2'
											onClick={(e) => sortUserList(e, 'updatedOn')}
											className={sortingColumn === 'updatedOn' ? `sort-title ${sortOrder}` : 'sort-title asc'}
										>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>
													{'Last Updated'} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
								</List>
							</Box>
						) : (
							<></>
						)}
						{reviewFormList && reviewFormList?.records && reviewFormList?.records.length > 0 ? <></> : <></>}
						{reviewFormList?.records.map((record: any, index: number) => {
							const creatureName = getFullName(record?.createdByDetails);
							const userFullName = getFullName(record?.updatedByDetails);

							return (
								<Box className='assessment-list-content'>
									<List component='ul' disablePadding key={`review-form-list-${record?.ratingFormId}`}>
										<ListItem className='assessment-col1'>
											{openAssessmentForm?.ratingFormType === 'direct' ? (
												<OKRButton
													handleClick={(e: any) => handleSelectRatingItem(e, record, index, openAssessmentForm?.record)}
													className={record?.selectedItem ? 'apply-btn active' : 'apply-btn'}
													icon={<TickIcon />}
													title={t('applyLabel')}
												/>
											) : (
												<></>
											)}
											{record?.name && record?.name.length > 35 ? (
												<Tooltip arrow title={record?.name || ''}>
													<Typography>{record?.name.substring(0, 35)}...</Typography>
												</Tooltip>
											) : (
												<Typography>{record?.name || ''}</Typography>
											)}
										</ListItem>
										<ListItem className='assessment-col2'>
											{record?.isPublish ? (
												<Chip label={t('publishedLevel')} />
											) : (
												<Chip className='draft-chip' label={t('draftLevel')} />
											)}
										</ListItem>
										<ListItem className='assessment-col3'>
											<Box>
												<Typography>{getMonthDateYear(record?.createdOn)}</Typography>
												{/* <Typography variant='body2'>{getFullName(record?.createdByDetails)}</Typography> */}
												{creatureName && creatureName.length > maxLen ? (
													<Tooltip arrow title={creatureName} aria-label='Email'>
														<Typography variant='body2'>{creatureName.substring(0, maxLen)}...</Typography>
													</Tooltip>
												) : (
													<Typography variant='body2'>{creatureName}</Typography>
												)}
											</Box>
										</ListItem>
										<ListItem className='assessment-col4'>
											<Box className=''>
												<Typography>{getMonthDateYear(record?.updatedOn)}</Typography>
												{userFullName && userFullName.length > maxLen ? (
													<Tooltip arrow title={userFullName} aria-label='Email'>
														<Typography variant='body2'>{userFullName.substring(0, maxLen)}...</Typography>
													</Tooltip>
												) : (
													<Typography variant='body2'>{userFullName}</Typography>
												)}
											</Box>
											<Box className='assessment-actions'>
												<OKRButton
													handleClick={(event: any) => handleOpenAssessmentPreview(event, record)}
													icon={<Visibility />}
													title={t('preview')}
												/>
												{openAssessmentForm?.ratingFormType === 'direct' ? (
													<></>
												) : (
													<OKRButton
														handleClick={(e: any) => handleDeleteRecordClick(e, record, 'edit')}
														icon={<EditIcon />}
														title={t('editLabel')}
													/>
												)}
												{openAssessmentForm?.ratingFormType === 'direct' ? (
													<></>
												) : (
													<PopperMenu
														popperClassName={'popper-menu manage-form-popper-menu'}
														anchorEl={anchorEl}
														handleClose={handleClose}
														includeKebabMenu={true}
														menuClicked={popperMenuClicked}
														setPopperMenuClicked={setPopperMenuClicked}
														{...props}
														children={
															<>
																<ListItem onClick={(e: any) => handleDeleteRecordClick(e, record, 'clone')}>
																	<ListItemAvatar>
																		<CloneIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('clone')} />
																</ListItem>
																<ListItem onClick={(e: any) => handleDeleteRecordClick(e, record, 'delete')}>
																	<ListItemAvatar>
																		<DeleteIcon />
																	</ListItemAvatar>
																	<ListItemText primary={t('delete')} />
																</ListItem>
															</>
														}
													/>
												)}
												{/* {openAssessmentForm?.ratingFormType === 'direct' ? (
													<></>
												) : (
													<OKRButton icon={<TickIcon />} title={t('moreActionsLevel')} />
												)} */}
											</Box>
										</ListItem>
									</List>
								</Box>
							);
						})}
					</Fragment>
				</Box>
				{/* <NoRecord noRecordMessage={t('noExcludeMainHead')} subHead={t('noExcludeSubHead')} {...props} /> */}
			</Fragment>

			{/* {modalProps && modalProps.open && (
				<AlertDialog
					module='ratingForm'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)} */}
			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					headingText={t('deleteAssessmentForm')}
					subHeading={t('deleteAssessmentFormSubHead')}
				/>
			)}
		</Box>
	);
};
