import * as React from 'react';
import { Box, Typography } from '@material-ui/core';
import { AnimatedIcon } from '../Common/AnimatedIcon';

export const CfrNoRecord: React.FC<any> = (props) => {
	const { noRecordHead, noRecordMsg, noRecordImg, noImgWidth } = props;

	return (
		<Box className='cfr-no-record'>
			<Box className='no-record-area'>
				<AnimatedIcon
					className='no-record-img'
					width={noImgWidth || '193'}
					type='image/svg+xml'
					alt={'No Conversation Available'}
					imagePath={noRecordImg}
				/>
				<Typography variant='h2'>{noRecordHead}</Typography>
				<Typography>{noRecordMsg}</Typography>
			</Box>
		</Box>
	);
};
