import React, { useEffect, useState } from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Chip,
	FormControlLabel,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { AddIconSvg, DeleteIcon, EditIcon, RightArrowIcon, TickIcon } from '../../../../../config/svg/CommonSvgIcon';
import { CancelIcon } from '../../../../../config/svg/Action';
import { FeedbackCategorySettings } from './FeedbackCategorySettings';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	deleteFeedbackType,
	getFeedbackCategoryData,
	getFeedbackType,
	insertFeedbackType,
	reloadFeebackCategoryList,
	updateFeedbackType,
	updateFeedbackTypeEnable,
} from '../../../../../action/adminPerformanceDevelopment';
import { Enums } from '../../../../../config/enums';
import { PopperMenu } from '../../../CommonComponent/PopperMenu';
import { CreateFeedbackcategory } from './CreateFeedbackCategory';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';

export const FeedbackTypesSettings: React.FC<any> = (props: any) => {
	const { loader, setLoader } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [expanded, setExpanded] = useState<any>([1]);
	const [disableToggle, setDisableToggle] = useState<boolean>(false);
	const [feedbackTypeList, setFeedbackTypeList] = useState<any>(null);
	const [noRecords, setNoRecords] = useState<any>(false);
	const [isEditFeedbackType, setIsEditFeedbackType] = useState<any>(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const { reloadCategory } = useSelector((state: any) => state?.adminPerformanceDevelopmentReducer);
	const { enqueueSnackbar } = useSnackbar();
	const defaultPostData: any = {
		feedbackTypeId: 0,
		name: '',
		description: '',
		isCategoryRequired: false,
		isDefault: false,
		isEnabled: true,
	};
	const [createEditData, setCreateEditData] = useState<any>(defaultPostData);
	const [isCreateFeedbackType, setIsCreateFeedbackType] = useState<boolean>(false);
	const [addFeedbackCategoryDrawer, setAddFeedbackCategoryDrawer] = useState<any>({
		open: false,
		typeData: {},
	});
	const [feedbackTypeMappingData, setFeedbackTypeMappingData] = useState<any>({});
	const [modalOpen, setModalOpen] = useState<any>({ open: false, item: null });

	const handleFeedbackMenuClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		getFeedbackTypeData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (reloadCategory && reloadCategory !== 0) {
			getFeedbackTypeCategoryData(reloadCategory);
			dispatch(reloadFeebackCategoryList(0));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadCategory]);

	const getFeedbackTypeData = async () => {
		try {
			setLoader(true);
			Promise.allSettled([dispatch(getFeedbackType())])
				.then((response: any) => {
					if (response) {
						let myResp: any = response[0];
						if (myResp && myResp?.value?.data && myResp?.value?.data?.status === Enums.STATUS_SUCCESS) {
							const { entityList } = myResp?.value?.data || {};
							setFeedbackTypeList(entityList);
							if (entityList.length === 0) {
								setNoRecords(true);
							}
						}
						setLoader(false);
					}
				})
				.catch((error) => {
					console.error(error);
					setNoRecords(false);
					setFeedbackTypeList([]);
					setLoader(false);
				});
		} catch (error) {
			console.error(error);
			setNoRecords(false);
			setFeedbackTypeList([]);
			setLoader(false);
		}
	};
	const checkElementInClass = (event: any, className: string) => {
		let elementExists = false;
		let elementsList: any = document.getElementsByClassName(className);
		if (elementsList.length > 0) {
			for (let i = 0; i < elementsList.length; i++) {
				if (elementsList[i]?.contains(event.target)) {
					elementExists = true;
				}
			}
		}
		return elementExists;
	};
	const handleChange = (event: any, isExpanded: any, panel: any, item: any) => {
		event.stopPropagation();
		event.preventDefault();
		if (
			document.getElementById('feedback-types-text-field')?.contains(event.target) ||
			document.getElementById('edit-feedback-types-actions')?.contains(event.target) ||
			document.getElementById('feedback-types-listing-actions')?.contains(event.target) ||
			checkElementInClass(event, 'type-more-menu') ||
			checkElementInClass(event, 'status-switch') ||
			checkElementInClass(event, 'add-category-item')
		) {
		} else {
			setIsEditFeedbackType(null);
			if (isExpanded && !feedbackTypeMappingData[item.feedbackTypeId]) {
				getFeedbackTypeCategoryData(item.feedbackTypeId, panel);
			} else {
				setExpanded(isExpanded ? panel : false);
			}
		}
	};

	const editFeedbackType = (e: any, item: any) => {
		e.preventDefault();
		e.stopPropagation();
		setIsEditFeedbackType(item);
		cancelCreateFeedbackType({});
	};
	const cancelEditFeedbackType = async (e: any, isSave = false) => {
		e.preventDefault();
		e.stopPropagation();
		if (!isSave) {
			setIsEditFeedbackType(null);
		} else {
			if (isEditFeedbackType.name.trim() === '') {
				return false;
			} else {
				try {
					setLoader(true);
					const response: any = await dispatch(updateFeedbackType(isEditFeedbackType));
					if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
						setIsEditFeedbackType(null);
						getFeedbackTypeData();
						showMsg('success', response?.data || {});
					} else {
						setLoader(false);
						showMsg('error', response?.data || {});
					}
				} catch (error) {
					console.error(error);
					setNoRecords(false);
					setFeedbackTypeList([]);
					setLoader(false);
				}
			}
		}
	};
	const getFeedbackTypeCategoryData = async (feedbackTypeId: number, panel = '') => {
		try {
			setLoader(true);
			let requestParams: any = `feedbackTypeId=${feedbackTypeId}&isThreeSixtyExperience=false`;
			const response: any = await dispatch(getFeedbackCategoryData(requestParams));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				let feedbackCatData = response?.data.entityList;
				setFeedbackTypeMappingData({ ...feedbackTypeMappingData, [feedbackTypeId]: feedbackCatData });
				panel && setExpanded(panel);
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setNoRecords(false);
			setFeedbackTypeList([]);
			setLoader(false);
		}
	};
	const handleFeedbackCategoryDrawerOpen = (e: any, item: any) => {
		setAddFeedbackCategoryDrawer({ open: true, typeData: item });
		setPopperMenuClicked(false);
		handleFeedbackMenuClose();
	};
	const handleFeedbackCategoryDrawerClose = (event: any, type: string, data = {}) => {
		if (type === 'save') {
			showMsg('success', data);
		}
		setAddFeedbackCategoryDrawer({ open: false, typeData: {} });
	};
	const cancelCreateFeedbackType = async (e: any, isSave = false) => {
		e?.preventDefault && e.preventDefault();
		if (!isSave) {
			if (createEditData.name.trim() !== '') {
				setModalOpen({ open: true, item: { createUnsaved: true } });
			} else {
				setModalOpen({ open: false, item: null });
				setIsCreateFeedbackType(false);
			}
		} else {
			setModalOpen({ open: false, item: null });
			if (createEditData.name.trim() === '') {
				return false;
			} else {
				try {
					setLoader(true);
					let postData = { ...createEditData };
					const response: any = await dispatch(insertFeedbackType(postData));
					if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
						setIsCreateFeedbackType(false);
						getFeedbackTypeData();
						showMsg('success', response?.data || {});
					} else {
						setLoader(false);
						showMsg('error', response?.data || {});
					}
				} catch (error) {
					console.error(error);
					setNoRecords(false);
					setFeedbackTypeList([]);
					setLoader(false);
				}
			}
		}
	};
	const enableFeedbackType = async (e: any, item: any, isChecked = false) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			setDisableToggle(true);
			const response: any = await dispatch(
				updateFeedbackTypeEnable({
					feedbackTypeId: item.feedbackTypeId,
					isEnabled: isChecked,
				})
			);
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				getFeedbackTypeData();
				setDisableToggle(false);
				showMsg('success', response?.data || {});
			} else {
				setLoader(false);
				setDisableToggle(false);
				showMsg('error', response?.data || {});
			}
		} catch (error) {
			console.error(error);
			setNoRecords(false);
			setFeedbackTypeList([]);
			setLoader(false);
		}
	};
	const showMsg = (variant: any, data: any) => {
		const { messageList } = data;
		const keys = messageList ? Object.keys(messageList) : [];
		const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
		enqueueSnackbar(messages || `Error occurred while performing action`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};
	const deleteFeedbackTypeData = async (item: any) => {
		try {
			setLoader(true);
			setDisableToggle(true);
			const response: any = await dispatch(deleteFeedbackType(`feedbackTypeId=${item.feedbackTypeId}`));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				getFeedbackTypeData();
				setDisableToggle(false);
				showMsg('success', response?.data || {});
			} else {
				setLoader(false);
				setDisableToggle(false);
				showMsg('error', response?.data || {});
			}
		} catch (error) {
			console.error(error);
			setNoRecords(false);
			setFeedbackTypeList([]);
			setLoader(false);
		}
	};

	const handleDelete = async (e: any, item: any) => {
		e.preventDefault();
		e.stopPropagation();
		setModalOpen({ open: true, item });
	};

	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			if (modalOpen?.item.createUnsaved) {
				setIsCreateFeedbackType(false);
			} else {
				deleteFeedbackTypeData(modalOpen.item);
			}
		}
		setModalOpen({ open: false, item: null });
	};
	const createFeedbackType = () => {
		setCreateEditData(defaultPostData);
		setIsCreateFeedbackType(true);
	};
	return (
		<>
			<Box className='performance-development-card full-rectangular-card feedback-type-settings'>
				<Box className='pd-card-header'>
					<Box>
						<Typography variant='h4' className='font-weight-normal'>
							{t('typeAndTheirMappings')}
						</Typography>
					</Box>
					<OKRButton
						className='btn-link add-feedback-type-btn'
						icon={<AddIconSvg />}
						text={t('addFeedbackType')}
						title={t('addFeedbackType')}
						handleClick={(e) => createFeedbackType()}
					/>
				</Box>
				<Box className='feedback-types-area'>
					<Box className='okr-list-panel-head'>
						<List component='ul'>
							<ListItem className='feedback-types-col1'>
								<Typography variant='body2' className='sort-title'>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('feedbackTypeName')}</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='feedback-types-col2'>
								<Typography variant='body2' className='sort-title'>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('createdBy')}</span>
									</span>
								</Typography>
							</ListItem>
							<ListItem className='feedback-types-col3'>
								<Typography variant='body2' className='sort-title'>
									<span className='sortable-label'>
										<span className='txt-wrap'>{t('statusLabel')}</span>
									</span>
								</Typography>
							</ListItem>
						</List>
					</Box>
					{isCreateFeedbackType && (
						<Box className='feedback-types-listing-content'>
							<Accordion expanded={true}>
								<AccordionSummary expandIcon={``} aria-controls='panel1bh-content' id='panel1bh-header'>
									<List component='ul'>
										<ListItem className={`feedback-types-col1 feedback-types-edit-col`}>
											<TextField
												className='feedback-types-text-field'
												id='feedback-types-text-field'
												placeholder={t('addFeedbackType')}
												fullWidth
												defaultValue={createEditData.name}
												inputProps={{ maxLength: Enums.MAX_LENGTH_FEEDBACK_NAME, 'aria-label': 'feedback-type' }}
												onChange={(e: any) => {
													setCreateEditData({ ...createEditData, name: e.target.value });
												}}
											/>
										</ListItem>
										<ListItem className='feedback-types-col2'>
											<Box className='feedback-types-chip'>
												<Chip label={createEditData.isDefault ? t('defaultLabel') : t('Created by admin')} />
											</Box>
										</ListItem>
										<ListItem className='feedback-types-col3'>
											<Box className='status-switch'>
												<FormControlLabel
													control={
														<Switch
															name='feedbackTypeStatus'
															onChange={(e: any) => {
																setCreateEditData({ ...createEditData, isEnabled: e.target.checked });
															}}
															checked={createEditData.isEnabled}
														/>
													}
													label={''}
												/>
											</Box>
										</ListItem>
									</List>
									<Box className='pd-setting-card-actions'>
										<Box className='pd-setting-card-actions-left'></Box>
										<Box className='pd-setting-card-actions-right'>
											<OKRButton
												handleClick={(e) => cancelCreateFeedbackType(e, false)}
												className='btn-link'
												text={t('cancelBtn')}
											/>
											<OKRButton
												handleClick={(e) => cancelCreateFeedbackType(e, true)}
												className='btn-primary'
												text={t('addBtn')}
											/>
										</Box>
									</Box>
								</AccordionSummary>
							</Accordion>
						</Box>
					)}
					{noRecords
						? 'No Records'
						: feedbackTypeList &&
						  feedbackTypeList.map((item: any, index: number) => (
								<Box className='feedback-types-listing-content'>
									<Accordion
										expanded={expanded === `panel${index + '_' + item.feedbackTypeId}`}
										onChange={(event: any, expanded: boolean) =>
											handleChange(event, expanded, `panel${index + '_' + item.feedbackTypeId}`, item)
										}
									>
										<AccordionSummary
											expandIcon={<RightArrowIcon />}
											aria-controls='panel1bh-content'
											id='panel1bh-header'
										>
											<List component='ul'>
												<ListItem
													className={`feedback-types-col1 ${
														isEditFeedbackType && isEditFeedbackType.feedbackTypeId === item.feedbackTypeId
															? 'feedback-types-edit-col'
															: ''
													}`}
												>
													{isEditFeedbackType && isEditFeedbackType.feedbackTypeId === item.feedbackTypeId ? (
														<>
															<TextField
																className='feedback-types-text-field'
																id='feedback-types-text-field'
																placeholder={t('addFeedbackType')}
																fullWidth
																defaultValue={isEditFeedbackType.name}
																inputProps={{
																	maxLength: Enums.MAX_LENGTH_FEEDBACK_NAME,
																	'aria-label': 'feedback-type',
																}}
																onChange={(e: any) => {
																	setIsEditFeedbackType({ ...isEditFeedbackType, name: e.target.value });
																}}
															/>
															<Box className='edit-feedback-types-actions' id='edit-feedback-types-actions'>
																<OKRButton
																	handleClick={(e) => cancelEditFeedbackType(e, false)}
																	className='feedback-types-cancel-btn'
																	icon={<CancelIcon />}
																/>
																<OKRButton
																	handleClick={(e) => cancelEditFeedbackType(e, true)}
																	className='feedback-types-save-btn'
																	icon={<TickIcon />}
																/>
															</Box>
														</>
													) : (
														<>
															<Typography>{item.name}</Typography>
															<Box className='feedback-types-listing-actions' id='feedback-types-listing-actions'>
																{!item.isDefault && (
																	<OKRButton
																		handleClick={(e) => editFeedbackType(e, item)}
																		icon={<EditIcon />}
																		title={t('editLabel')}
																	/>
																)}
																{!item.isDefault && (
																	<OKRButton
																		icon={<DeleteIcon />}
																		title={t('delete')}
																		handleClick={(e) => handleDelete(e, item)}
																	/>
																)}
																<PopperMenu
																	popperClassName={'popper-menu feedback-type-menu'}
																	className={`type-more-menu`}
																	anchorEl={anchorEl}
																	handleClose={handleFeedbackMenuClose}
																	includeKebabMenu={true}
																	menuClicked={popperMenuClicked}
																	setPopperMenuClicked={setPopperMenuClicked}
																	btnId={`type-more-btn`}
																	{...props}
																	children={
																		<>
																			<ListItem
																				onClick={(e) => {
																					handleFeedbackCategoryDrawerOpen(e, item);
																				}}
																				className='add-category-item'
																			>
																				<ListItemAvatar>
																					<AddIconSvg />
																				</ListItemAvatar>
																				<ListItemText primary={t('addCategory')} />
																			</ListItem>
																		</>
																	}
																/>
															</Box>
														</>
													)}
												</ListItem>
												<ListItem className='feedback-types-col2'>
													<Box className='feedback-types-chip'>
														<Chip label={item.isDefault ? t('defaultLabel') : t('createdByAdmin')} />
													</Box>
												</ListItem>
												<ListItem className='feedback-types-col3'>
													<Box className='status-switch'>
														<FormControlLabel
															control={<Switch name='feedbackTypeStatus' />}
															label={''}
															disabled={disableToggle}
															checked={item.isEnabled}
															onChange={(e: any) => {
																enableFeedbackType(e, item, e.target.checked);
															}}
														/>
													</Box>
												</ListItem>
											</List>
										</AccordionSummary>
										<AccordionDetails>
											<FeedbackCategorySettings
												feedbackTypeId={item.feedbackTypeId}
												feedbackCategoryData={feedbackTypeMappingData[item.feedbackTypeId]}
												addFeedbackCategoryDrawer={addFeedbackCategoryDrawer}
												setAddFeedbackCategoryDrawer={setAddFeedbackCategoryDrawer}
												{...props}
											/>
										</AccordionDetails>
									</Accordion>
								</Box>
						  ))}
				</Box>
				{addFeedbackCategoryDrawer.open ? (
					<CreateFeedbackcategory
						{...props}
						addFeedbackCategoryDrawer={addFeedbackCategoryDrawer}
						handleAdminFeedbackDrawerClose={handleFeedbackCategoryDrawerClose}
						setLoader={setLoader}
					/>
				) : (
					<></>
				)}

				{modalOpen.open && (
					<DialogComponent
						module='information'
						message={
							modalOpen?.item.createUnsaved
								? t('unSavedItemAlert')
								: t('deleteFeedback', {
										feedbackName: modalOpen?.item?.name ? '"' + modalOpen?.item?.name + '"' : '',
								  })
						}
						handleCloseModal={handleCloseAlertModal}
						modalOpen={modalOpen.open}
					/>
				)}
			</Box>
		</>
	);
};
