export const freeTrialDataFields = {
	firstName: 'firstName',
	lastName: 'lastName',
	emailId: 'emailId',
	companyName: 'companyName',
	domain: 'domain',
	password: 'password',
};

export interface FreeTrialDataProps {
	firstName: string;
	lastName: string;
	emailId: string;
	companyName: string;
	domain: string;
	password: string;
}

export const defaultFreeTrialData: FreeTrialDataProps = {
	firstName: '',
	lastName: '',
	emailId: '',
	companyName: '',
	domain: '',
	password: '',
};

export const freeTrialDataRequiredFields = ['firstName', 'lastName', 'emailId', 'companyName', 'domain', 'password'];

export const FreeTrialRequiredFieldsErrorMsg: any = {
	firstName: 'firstNameRequired',
	lastName: 'lastNameRequired',
	emailId: 'businessEmailRequired',
	companyName: 'businessNameRequired',
	domain: 'businessDomainNameRequired',
	password: 'passwordRequired',
	emailIdInvalid: '',
	domainExists: '',
};

export const isFieldValid = async (field: string, fieldValue: any, t: any, apis: any) => {
	switch (field) {
		case freeTrialDataFields.firstName:
		case freeTrialDataFields.lastName:
		case freeTrialDataFields.companyName:
			if (fieldValue.trim() === '') {
				return t(FreeTrialRequiredFieldsErrorMsg[field]);
			}
			return '';
		case freeTrialDataFields.domain:
			if (fieldValue.trim() === '') {
				return t(FreeTrialRequiredFieldsErrorMsg[field]);
			} else {
				const api = apis.domain;
				return await api({ domain: fieldValue });
			}
			return '';
		case freeTrialDataFields.emailId:
			if (fieldValue.trim() === '') {
				return t(FreeTrialRequiredFieldsErrorMsg[field]);
			} else {
				const api = apis.email;
				return await api({ emailId: fieldValue });
			}
			return '';
		case freeTrialDataFields.password:
			if (fieldValue.trim() === '') {
				return t(FreeTrialRequiredFieldsErrorMsg[field]);
			} else {
				const api = apis.password;
				return await api({ password: fieldValue });
			}
			return '';
		default:
			return '';
	}
};

export const prepareApiPayload = (payload: any): FreeTrialDataProps => {
	let createFreeTrialPayload: FreeTrialDataProps = Object.assign({}, defaultFreeTrialData);
	createFreeTrialPayload.firstName = payload.firstName;
	createFreeTrialPayload.lastName = payload.lastName;
	createFreeTrialPayload.emailId = payload.emailId;
	createFreeTrialPayload.companyName = payload.companyName;
	createFreeTrialPayload.domain = payload.domain;
	createFreeTrialPayload.password = payload.password;
	return createFreeTrialPayload;
};
