import React from 'react';
import { Box, Typography, Link, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../Common/OKRButton';
import { BottomArrowIcon, CalendarIcon } from '../../../../config/svg/CommonSvgIcon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';

export const SODropdown: React.FC<any> = (props: any) => {
	const { soDetails, setSoItem, selectedSo } = props;
	const { t } = useTranslation();
	const [open, setOpen] = React.useState<Boolean>(false);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const selectSoItem = (item: any) => {
		setSoItem(item);
		handleClick();
	};
	return (
		<Box className={`okr-team-dropdown dropdown-list-item ${open ? 'active' : ''} `}>
			<OKRButton
				className={'dropdown-button placeholder-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={
					selectedSo && selectedSo.soName ? (
						selectedSo.soName && selectedSo.soName.length > 15 ? (
							<Tooltip arrow title={selectedSo.soName} aria-label='Email'>
								<span>{selectedSo.soName.substring(0, 15)}...</span>
							</Tooltip>
						) : (
							<span>{selectedSo.soName}</span>
						)
					) : (
						'Select'
					)
				}
				//title={'Full text'}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<List component='div' disablePadding>
							<ListItem
											component='div'
											key={`key-current-`}
											id={`time-frame-current-`}
											onClick={(event) => {
												selectSoItem({});
											}}
										>
											
												<ListItemText>Select</ListItemText>
										
										</ListItem>
								{soDetails.map((item: any) => {
									return (
										<ListItem
											component='div'
											key={`key-current-`}
											id={`time-frame-current-`}
											className={item.soDetailsId === selectedSo?.soDetailsId ? 'active' : ''}
											onClick={(event) => {
												selectSoItem(item);
											}}
										>
											{item?.soName && item?.soName.length > 55 ? (
												<Tooltip arrow title={item?.soName} aria-label='soName'>
													<ListItemText>{item?.soName.substring(0, 55)}...</ListItemText>
												</Tooltip>
											) : (
												<ListItemText>{item?.soName}</ListItemText>
											)}
										</ListItem>
									);
								})}
							</List>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
