import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../../../../../styles/pages/admin/settings/default-okr/createDefaultOkr.scss';
import { Box, FormLabel, Grid, Switch, TextField, Typography } from '@material-ui/core';
import {
	AddIconSvg,
	DeleteIcon,
	DocumentIcon,
	ScaleType,
	WeightageIcon,
} from '../../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../../Common/OKRButton';
import { CreateOKRIcon } from '../../../../../../config/svg/MyGoalSvg';
import { ResponseTypeDropdown } from './ResponseTypeDropdown';
import { CreateGoalSettings } from './CreateGoalSettings';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

export const CreateGoalSettingsWrapper: React.FC<any> = (props: any) => {
	const {
		handleAddNewCategory,
		handleAddNewGoal,
		category,
		indexVal,
		goalFormList,
		onDragEnd,
		getItemStyle,
		goalFormData,
		setGoalFormData,
		goalLength,
		handleDeleteGoal,
		responseTypeList,
	} = props;

	const { t } = useTranslation();
	const updateGoalDetails = (key: string, value: any, updateType: any, goalIndex: number) => {
		try {
			const { performanceGoalFormCategoryRequest } = goalFormData;
			let copyPerformanceGoalFormCategoryRequest =
				performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
					? performanceGoalFormCategoryRequest
					: [];
			if (updateType === 'goal') {
				const goalDetails = copyPerformanceGoalFormCategoryRequest[indexVal];
				let { performanceGoalFormDetailRequest } = goalDetails || {};
				if (key === 'weightage') {
					performanceGoalFormDetailRequest = performanceGoalFormDetailRequest.map((rec: any) => {
						rec.isWeightageError = false;
						rec.isWeightageErrorMessage = '';
						return rec;
					});
				}
				const copyPerformanceGoalFormDetailRequest =
					performanceGoalFormDetailRequest && performanceGoalFormDetailRequest?.length
						? performanceGoalFormDetailRequest[goalIndex]
						: null;
				if (copyPerformanceGoalFormDetailRequest) {
					if (key === 'name') {
						copyPerformanceGoalFormDetailRequest.name = value;
						copyPerformanceGoalFormDetailRequest.isGoalQuestionNameError = false;
						copyPerformanceGoalFormDetailRequest.sectionQuestionNameErrorMessage = '';
					} else if (key === 'weightage') {
						copyPerformanceGoalFormDetailRequest.weightage = value;
						copyPerformanceGoalFormDetailRequest.isWeightageError = false;
						copyPerformanceGoalFormDetailRequest.isWeightageErrorMessage = '';
					} else {
						copyPerformanceGoalFormDetailRequest[key] = value;
					}
					copyPerformanceGoalFormDetailRequest.isEdited = true;
				}
			}
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
			});
		} catch (error) {
			console.error(error);
		}
	};
	return (
		<>
			{goalFormList && goalFormList?.length ? (
				<Fragment>
					{/*<DragDropContext onDragEnd={(result: any) => onDragEnd(result, goalFormList, indexVal)}>
						<Droppable droppableId='list'>
							{(provided: any) => (
								<div ref={provided.innerRef} className='' {...provided.droppableProps}>*/}
					{goalFormList && goalFormList?.length ? (
						goalFormList.map((goalItem: any, goalIndex: number) => (
							<>
								{
									/*<Draggable
												key={`draggable_${goalItem.sequence}_index_${goalIndex}_section_${indexVal}`}
												draggableId={`draggable_${goalItem.sequence}_questionIndex_${goalIndex}`}
												index={goalIndex}
												isDragDisabled={false}
											>
												{(provided: any, snapshot: any) => (*/
									<Fragment>
										<>
											<CreateGoalSettings
												handleAddNewCategory={handleAddNewCategory}
												handleAddNewGoal={handleAddNewGoal}
												category={category}
												indexVal={indexVal}
												goalIndex={goalIndex}
												goalItem={goalItem}
												//provided={provided}
												getItemStyle={getItemStyle}
												//snapshot={snapshot}
												updateGoalDetails={updateGoalDetails}
												handleDeleteGoal={handleDeleteGoal}
												goalLength={goalLength}
												goalFormData={goalFormData}
												responseTypeList={responseTypeList}
											/>
										</>
									</Fragment>
									/*)}
										</Draggable>*/
								}
							</>
						))
					) : (
						<></>
					)}
					{/*</div>
							)}
						</Droppable>
					</DragDropContext>*/}
				</Fragment>
			) : (
				<></>
			)}
		</>
	);
};
