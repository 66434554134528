import React, { Fragment, useEffect, useState } from 'react';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { updateCurrenTabSelection } from '../../../action/adminSettings';
import { useDispatch, useSelector } from 'react-redux';
import { checkSupportPermissionType } from '../../../config/utils';
import { supportModulePerformAssessment } from '../AdminConstant';
import { SystemRolesPermissions } from './SystemRolesPermissions';
import { AdditionalPermissions } from './AdditionalPermissions';

export const RolesAndPermissionsTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`roles-permissions-tab-${index}`}
			aria-labelledby={`roles-permissions-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

RolesAndPermissionsTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `roles-permissions-tabpanel-${index}`,
		'aria-controls': `roles-permissions-tabpanel-${index}`,
	};
}

export const RolesAndPermissions: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = useState<Number>(
		checkSupportPermissionType(supportModulePerformAssessment) ? 0 : 1
	);
	const dispatch = useDispatch();
	const { currentRolePermissionsTab } = useSelector((state: any) => state.commonReducer);

	useEffect(() => {
		setTabSelected(0);
	}, [currentRolePermissionsTab]);

	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};

	return (
		<Box className='settings-page-area'>
			<Box className='settings-tab-panel-wrap'>
				{currentRolePermissionsTab === 'systemRolesPermissions' ? <SystemRolesPermissions {...props} /> : <></>}
				{currentRolePermissionsTab === 'additionalPermissions' ? (
					<AdditionalPermissions
						{...props}
						tabSelected={tabSelected}
						a11yProps={a11yProps}
						handleTabChange={handleTabChange}
					/>
				) : (
					<></>
				)}
			</Box>
		</Box>
	);
};
