import { Font, StyleSheet } from '@react-pdf/renderer';
// import OKR_Arial from '../../../fonts/arial.ttf';
// import OKR_ArialBold from '../../../fonts/arialbd.ttf';
// import OKR_ArialItalic from '../../../fonts/ariali.ttf';
// import OKR_ArialBoldItalic from '../../../fonts/arialbi.ttf';
// Font.register({
// 	family: 'arial',
// 	format: 'truetype',
// 	fonts: [
// 		{ src: OKR_Arial, fontWeight: 400 }, // font-style: normal, font-weight: normal
// 		{ src: OKR_ArialBold, fontWeight: 700 },
// 		{ src: OKR_ArialItalic, fontStyle: 'italic' },
// 		{ src: OKR_ArialBoldItalic, fontWeight: 700, fontStyle: 'italic' },
// 	],
// });
export const styles = StyleSheet.create({
	pageFirst: {
		padding: 15,
		backgroundColor: '#ffffff',
		fontFamily: 'Roboto',
		fontWeight: 'normal',
		fontSize: 12,
	},
	pageFirst_wrap: {
		padding: 30,
		borderRadius: 6,
		backgroundColor: '#f4f8ff',
	},
	unlockULogo: {
		width: 118,
		height: 30,
		marginBottom: 10,
	},
	performancePeriodDetail: {
		paddingBottom: 10,
	},
	performancePeriodTitle: {
		paddingBottom: 5,
		fontSize: 12,
		color: '#416390',
	},
	performancePeriod: {
		display: 'flex',
		flexDirection: 'row',
	},
	performancePeriodHead: {
		fontSize: 9,
		color: '#416390',
	},
	performancePeriodTime: {
		fontSize: 9,
	},
	fontRegular: {
		fontFamily: 'Roboto',
		fontWeight: 'normal',
	},
	fontMedium: {
		fontFamily: 'Roboto',
		fontWeight: 'medium',
	},
	fontBold: {
		fontFamily: 'Roboto',
		fontWeight: 'bold',
	},
	employeeRow: {
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 20,
	},
	employeeCol: {
		width: '50%',
		paddingRight: 10,
	},
	employeeColTitle: {
		paddingBottom: 3,
		fontSize: 9,
		color: '#416390',
	},
	employeeColText: {
		fontSize: 11,
	},
	page: {
		backgroundColor: '#ffffff',
		paddingTop: 35,
		paddingBottom: 35,
		fontFamily: 'Roboto',
		fontWeight: 'normal',
		fontSize: 12,
	},
	page_wrap: {
		paddingTop: 0,
		paddingHorizontal: 0,
		paddingLeft: 30,
		paddingRight: 30,
	},
	header: {
		position: 'absolute',
		top: 15,
		left: 30,
		right: 30,
		height: 45,
		paddingBottom: 5,
	},
	headerText: {
		fontSize: 12,
		color: '#d5dae1',
	},
	footer: {
		position: 'absolute',
		bottom: 15,
		left: 30,
		right: 30,
		height: 35,
		borderTop: '1 solid #dcdbdc',
		paddingTop: 5,
	},
	footerInner: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
	},
	unlockUfooterLogo: {
		width: 70,
		height: 18,
	},
	infoProLearn_text: {
		paddingTop: 10,
		paddingBottom: 10,
		color: '#000000',
		fontSize: 8,
	},
	assessmentSectionTitle: {
		paddingBottom: 5,
		marginBottom: 15,
		borderBottom: '1 solid #dcdbdc',
	},
	assessmentSectionTitleText: {
		fontSize: 12,
		paddingBottom: 3,
	},
	assessmentBlockHead: {
		backgroundColor: '#e5f0ff',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 5,
	},
	assessmentBlockHeadTitle: {
		fontSize: 11,
		paddingBottom: 2,
	},
	assessmentBlockHeadDesc: {
		fontSize: 9,
		color: '#416390',
		lineHeight: 1.5,
	},
	assessmentQues: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		backgroundColor: '#f4f8ff',
	},
	question: {
		fontSize: 11,
	},
	assessmentAns: {
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10,
	},
	assessmentAnsTitle: {
		paddingBottom: 5,
		color: '#416390',
		fontSize: 9,
	},
	assessmentScaleSection: {
		paddingRight: 10,
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
		flexWrap: 'wrap',
	},
	shortDescAnswer: {
		paddingRight: 10,
	},
	scaleDetails: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexDirection: 'row',
	},
	scaleText: {
		fontSize: 9,
		lineHeight: 1.5,
		color: '#949494',
	},
	scaleTextSelected: {
		fontSize: 9,
		lineHeight: 1.5,
		color: '#292929',
	},
	scaleTextSeparator: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: 9,
		width: 3,
		height: 3,
		borderRadius: 3,
		backgroundColor: '#e5f0ff',
	},
	assessmentCommentSection: {
		paddingLeft: 10,
		paddingRight: 10,
		paddingTop: 10,
		paddingBottom: 10,
	},
	comments_title: {
		paddingBottom: 5,
		color: '#416390',
		fontSize: 9,
	},

	h1: {
		fontSize: 12,
		margin: 0,
		color: '#292929',
	},
	h2: {
		fontSize: 12,
		margin: 0,
		color: '#292929',
	},
	h3: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	h4: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	h5: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	h6: {
		fontSize: 10,
		margin: 0,
		color: '#292929',
	},
	p: {
		margin: '0 0 10px 0px',
		padding: 0,
		fontSize: 10,
		fontWeight: 400,
		color: '#292929',
		lineHeight: 1.5,
	},
	a: {
		color: '#0664B1',
		cursor: 'pointer',
	},
	b: {
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	strong: {
		fontWeight: 700,
		fontFamily: 'Helvetica-Bold',
		color: '#292929',
	},
	i: {
		fontStyle: 'italic',
		fontFamily: 'Helvetica-Oblique',
		color: '#292929',
	},
	ul: {
		margin: '5px 0 10px 2px',
		padding: 0,
		fontSize: 9,
		fontWeight: 400,
		lineHeight: 1.5,
		color: '#292929',
		//width: '100px',
		maxWidth: '85%',
	},
	li: {
		fontSize: 9,
		fontWeight: 400,
		lineHeight: 1.5,
		color: '#292929',
	},
	ol: {
		margin: '5px 0 10px 2px',
		padding: 0,
		fontSize: 9,
		lineHeight: 1.5,
		fontWeight: 400,
		color: '#292929',
		//width: '100px',
	},
	table: {
		borderCollapse: 'collapse',
		width: '100%',
	},

	td: {
		padding: '8px',
		borderCollapse: 'collapse',
		fontSize: 9,
		fontWeight: 400,
		color: '#292929',
	},
	th: {
		padding: '8px',
		borderCollapse: 'collapse',
		fontSize: 9,
		color: '#292929',
	},
	table: {
		marginBottom: 15,
		display: 'table',
		width: 'auto',
		borderStyle: 'solid',
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
		borderColor: '#cee3ff',
		backgroundColor: '#ffffff',
	},
	tableRow: {
		margin: 'auto',
		flexDirection: 'row',
	},
	tableColfull: {
		width: '70%',
	},
	tableCol: {
		width: '15%', // Adjusted width for 3 equal columns
		borderStyle: 'solid',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: '#cee3ff',
	},
	tableCell: {
		marginTop: 5,
		fontSize: 9,
		paddingLeft: 5,
		paddingRight: 5,
		textAlign: 'left',
	},
	tableCellCurrencyText: {
		fontFamily: 'Roboto',
		fontSize: 9,
	},
	tableHeader: {
		fontSize: 9,
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		textAlign: 'left',
		color: '#416390',
	},
	tableColFullBlank: {
		width: '100%',
		padding: 10,
		borderStyle: 'solid',
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
		borderColor: '#cee3ff',
	},
	tableKrDetails: {
		paddingLeft: 5,
		paddingRight: 5,
		paddingTop: 3,
		paddingBottom: 5,
		display: 'flex',
		justifyContent: 'flex-start',
		flexDirection: 'row',
		alignItems: 'center',
	},
	tableInnerCell: {
		marginRight: 5,
		fontSize: 9,
	},
	tableInnerCellBold: {
		color: '#416390',
	},
	tableInnerCellSeparator: {
		marginLeft: 5,
		marginRight: 5,
		fontSize: 9,
		width: 3,
		height: 3,
		borderRadius: 3,
		backgroundColor: '#e5f0ff',
	},

	quarterTitle: {
		fontSize: 9,
		color: '#416390',
	},
	quarterDetails: {
		backgroundColor: '#e5f0ff',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 5,
	},
	okrPanel: {
		paddingBottom: 15,
	},
	ObjectiveDetails: {
		paddingTop: 5,
		paddingBottom: 5,
		paddingLeft: 10,
		paddingRight: 10,
		backgroundColor: '#f4f8ff',
	},
	ObjectiveHead: {
		paddingTop: 5,
		paddingBottom: 5,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	ObjectiveTitle: {
		width: '70%',
		fontSize: 10,
	},
	ObjectiveProgress: {
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		alignItems: 'center',
	},
	ObjectivePercentage: {
		marginRight: 10,
		color: '#416390',
		fontSize: 10,
	},
	noContentBlock: {
		paddingBottom: 10,
	},
	noContentText: {
		fontSize: 9,
		color: '#949494',
	},

	// quarterDetails: {
	// 	position: 'relative',
	// 	marginTop: 10,
	// 	marginBottom: 10,
	// },
	// cycleText: {
	// 	color: '#416390',
	// 	fontSize: 10,
	// 	lineHeight: 1.5,
	// 	fontWeight: 700,
	// 	fontFamily: 'Helvetica-Bold',
	// },
	// okrPanel: {
	// 	padding: 10,
	// 	backgroundColor: '#FFFFFF',
	// 	borderRadius: 5,
	// 	marginBottom: 15,
	// 	width: '100%',
	// },
	// objectivePanel: {
	// 	marginBottom: 10,
	// 	width: '100%',
	// 	flexDirection: 'row',
	// 	display: 'flex',
	// 	justifyContent: 'space-between',
	// },
	// objectiveTitleCol: {
	// 	width: '70%',
	// },
	// objectiveTitle: {
	// 	fontSize: 10,
	// 	fontWeight: 700,
	// 	fontFamily: 'Helvetica-Bold',
	// },
	// numberOfKrs: {
	// 	width: 80,
	// 	marginTop: 8,
	// 	fontSize: 8,
	// 	paddingTop: 2,
	// 	paddingBottom: 2,
	// 	paddingLeft: 10,
	// 	paddingRight: 10,
	// 	color: '#0664b1',
	// 	backgroundColor: '#cee3ff',
	// 	borderRadius: 20,
	// 	textTransform: 'uppercase',
	// },
	// objProgressCol: {
	// 	width: '25%',
	// 	flexDirection: 'row',
	// 	display: 'flex',
	// 	justifyContent: 'flex-start',
	// },
	// objProgressPercent: {
	// 	marginTop: 2,
	// 	width: 30,
	// 	fontSize: 10,
	// 	fontWeight: 700,
	// 	fontFamily: 'Helvetica-Bold',
	// },
	// objProgressBar: {
	// 	position: 'relative',
	// 	width: 100,
	// 	height: 15,
	// 	borderRadius: 20,
	// 	backgroundColor: '#d5dae1',
	// },
	// krPanel: {
	// 	padding: 10,
	// 	marginBottom: 10,
	// 	borderRadius: 5,
	// 	backgroundColor: '#efefef',
	// 	width: '100%',
	// 	flexDirection: 'row',
	// 	display: 'flex',
	// 	justifyContent: 'space-between',
	// },
	// krTitleCol: {
	// 	width: '70%',
	// },
	// krTitle: {
	// 	paddingBottom: 5,
	// 	fontSize: 10,
	// },
	// krProgressCol: {
	// 	width: '20%',
	// },
	// krStartTargetPercent: {
	// 	flexDirection: 'row',
	// 	display: 'flex',
	// 	justifyContent: 'space-between',
	// },
	// krStartText: {
	// 	fontSize: 10,
	// 	fontFamily: 'Roboto',
	// },
	// krTargetText: {
	// 	fontSize: 10,
	// 	fontFamily: 'Roboto',
	// },
	// krProgressBar: {
	// 	position: 'relative',
	// 	width: 100,
	// 	height: 15,
	// 	borderRadius: 20,
	// 	backgroundColor: '#d5dae1',
	// },
	// krAssignedBy: {
	// 	flexDirection: 'row',
	// 	display: 'flex',
	// },
	// assignedByTitle: {
	// 	marginRight: 3,
	// 	fontSize: 8,
	// 	color: '#416390',
	// },
	// assignedByContent: {
	// 	fontSize: 8,
	// },
});
