import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AddEditContributorDetail } from './AddEditContributorDetail';
import { KrContributorList } from '../KeyResult/KrContributorList';
import { OKRDrawer } from '../../Common/OKRDrawer';
import _ from 'lodash';
import AlertDialog from '../../Common/Dialog';
import { Enums } from '../../../config/enums';

export const ManageContributorList: React.FC<any> = (props) => {
	const {
		open,
		setOpen,
		t,
		keyData,
		keyAttribute,
		contributorDetails,
		objectiveEndDate,
		getCurrencyIcon,
		onDeleteContributor,
		goalTypeId,
		durationCycleDetail,
		objectiveStartDate,
		setContributorDetails,
		updateKeyAttrDetail,
		setIsContributorUpdated,
		teamData,
		dueCycle,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		onSaveContributorDetails,
		selectedMetricDetail,
		updateGroupTargetValue,
		isLocked,
	} = props;

	const [currentScreen, setCurrentScreen] = useState<number>(0);
	const [selectedContibutorD, setSelectedContibutorD] = useState<any>({});
	const [teamDetails, setTeamDetails] = useState<any>({});
	const [sortOrder, setSortOrder] = useState('asc');
	const [sortingColumn, setSortingColumn] = useState('');
	const [subModalProps, setSubModalProps] = useState<any>(null);
	const [updatedContributorList, setUpdatedContributorList] = useState<any[]>([]);
	const [targetValue, setTargetValue] = useState<number>(0);
	const [krStartValue, setKRStartValue] = useState<number>(0);
	const [defaultIsGroupTargetValue, setDefaultIsGroupTargetValue] = useState<boolean>(
		keyAttribute?.isGroupTarget || false
	);

	useEffect(() => {
		setDefaultIsGroupTargetValue(keyAttribute?.isGroupTarget || false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (
			selectedMetricDetail &&
			selectedMetricDetail.selectedMetric &&
			selectedMetricDetail.selectedMetric.metricId !== 5 &&
			selectedMetricDetail.selectedMetric.metricId !== 4
		) {
			setTargetValue(keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue);
			setKRStartValue(keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue);
		} else {
			setTargetValue(0);
			setKRStartValue(0);
		}
	}, [keyAttribute, keyData, selectedMetricDetail]);
	useEffect(() => {
		const contributorsList = contributorDetails.map((cont: any) => ({ ...cont }));
		const isGroup =
			selectedMetricDetail.selectedMetric?.metricId === Enums.ONE ? false : keyAttribute?.isGroupTarget || false;
		const updatedContributorList = contributorsList?.map((item: any) => {
			if (isGroup) {
				item.targetValue = targetValue;
				item.startValue = krStartValue;
				item.isGroupTarget = isGroup;
			}
			return item;
		});
		setUpdatedContributorList(updatedContributorList);
	}, [contributorDetails]);

	useEffect(() => {
		if (teamData && teamData !== '') {
			setTeamDetails({
				teamName: teamData.teamName,
				backGroundColorCode: teamData.backGroundColorCode,
				colorCode: teamData.colorCode,
			});
		}
	}, [teamData]);

	const handleClose = () => {
		setOpen(false);
		setCurrentScreen(0);
	};

	const handleCancel = () => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		props.updateCalloutPanel && props.updateCalloutPanel('');
		setTimeout(() => {
			props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
		}, 500);
		setCurrentScreen(0);
		handleClose();
	};

	const sortContributorsList = (e: any, column: any) => {
		let sortClass: any = 'desc';
		if (column === sortingColumn) {
			sortClass = sortOrder === 'asc' ? 'desc' : 'asc';
		}
		let list = _.orderBy(updatedContributorList, column, sortClass);
		setUpdatedContributorList(list);
		setSortOrder(sortClass);
		setSortingColumn(column);
	};
	const checkContributorsUpdated = (e: any) => {
		let list = _.orderBy(updatedContributorList, 'firstName', 'asc');
		let list2 = _.orderBy(contributorDetails, 'firstName', 'asc');
		if (_.isEqual(list, list2)) {
			setOpen(false);
			updateGroupTargetValue && updateGroupTargetValue(defaultIsGroupTargetValue);
		} else {
			setSubModalProps(true);
		}
	};
	const handleCloseSubModal = (event: any, type: number) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 1) {
			setOpen(false);
			updateGroupTargetValue && updateGroupTargetValue(defaultIsGroupTargetValue);
		}
		setSubModalProps(false);
	};

	const validateSave = () => {
		let dis: boolean = false;
		let list = [...updatedContributorList];
		let isPositiveFlow = parseInt(keyAttribute.startValue || 0) < parseInt(keyAttribute.targetValue || 0);

		list.forEach((item: any) => {
			const start = parseInt(item.startValue === '' ? 0 : item.startValue);
			const target = parseInt(item.targetValue === '' ? 0 : item.targetValue);
			if ((item.isKrOwner && target > 0 && start === target) || (!item.isKrOwner && start === target)) {
				item.targetErrorText = t('startTargetSame');
				dis = true;
			}
			if (
				(isPositiveFlow && start > target) ||
				(!isPositiveFlow && start < target)
			) {
				if (isPositiveFlow) {
					item.targetErrorText = t('startValueCannotBeGreater');
				} else {
					item.startErrorText = t('startValueCannotBeLesser');
				}
				dis = true;
			}
		});
		setUpdatedContributorList(list);
		return dis;
	};

	return (
		<Fragment>
			<OKRDrawer
				open={open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={t('contributorsLabel')}
				children={
					<Box className='okr-drawer-content'>
						<KrContributorList
							{...props}
							keyAttribute={keyAttribute}
							keyData={keyData}
							objectiveEndDate={objectiveEndDate}
							getCurrencyIcon={getCurrencyIcon}
							onDeleteContributor={onDeleteContributor}
							teamDetails={teamDetails}
							t={props.t}
							goalTypeId={goalTypeId}
							dueCycle={dueCycle}
							sortContributorsList={sortContributorsList}
							sortOrder={sortOrder}
							sortingColumn={sortingColumn}
							setCallOut={setCallOut}
							setAnchorElCallouts={setAnchorElCallouts}
							initialCallout={initialCallout}
							calloutsData={calloutsData}
							anchorElCallouts={anchorElCallouts}
							updatedContributorList={updatedContributorList}
							setUpdatedContributorList={setUpdatedContributorList}
							selectedMetricDetail={selectedMetricDetail}
							targetValue={targetValue}
							krStartValue={krStartValue}
							isLocked={isLocked}
						/>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={checkContributorsUpdated}
				handleSaveClick={(event: any) => {
					if (!validateSave()) {
						onSaveContributorDetails(updatedContributorList, 'manage');
					}
				}}
				handleCancelClick={checkContributorsUpdated}
				hideDefaultButton={false}
				isSaveButtonDisabled={isLocked}
				saveButtonText={'Save'}
				showBackButton={currentScreen === 1}
				drawerClassName={'main-drawer-panel main-drawer-subpanel'}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
			{subModalProps && (
				<AlertDialog
					module='myGoal'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseSubModal}
					modalOpen={subModalProps}
				/>
			)}
		</Fragment>
	);
};
