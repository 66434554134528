import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Avatar,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Popper,
	Grow,
	IconButton,
	Paper,
	Tooltip,
	Typography,
	Badge,
	Link as MaterialLink,
} from '@material-ui/core';
import Profile from '../Profile';
import { LogoutIcon } from '../../config/svg/ProfileSvg';
import { clearLocalStorage, getLocalStorageItem } from '../../services/StorageService';
import { getUserName } from '../../config/utils';
import PersonalFeedbackMenu from '../PersonalFeedback';
import { PersonalFeedbackIcon } from '../../config/svg/PersonalFeedbackSvg';
import { ProfileIcon } from '../../config/svg/CommonSvg';
import { AZURE_LOGOUTURI } from '../../config/constant';
import { ProfileInfoDrawer } from '../Common/ProfileInfoDrawer';
import { getEncryptString, getQuarterStartEnd } from '../../config/utils';
import { getVersionDetails, showOnCreatePopup } from '../../action/common';
import Dialog from '@material-ui/core/Dialog';
import '../../styles/pages/about/about.scss';
import { Link } from 'react-router-dom';
import { CloseIcon } from '../../config/svg/GlobalSvg';
import { useDispatch, useSelector } from 'react-redux';
import Logo from '../../images/okr-logo.svg';
import { OKRButton } from '../Common/OKRButton';

function SimpleDialog(props) {
	const { openAboutUs, aboutUsOpen } = props;
	const dispatch = useDispatch();
	const { aboutUsDetails } = useSelector((state) => state?.commonReducer);
	const { t } = useTranslation();
	let { currentYear } = getQuarterStartEnd();
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const [aboutUsData, setAboutUsData] = useState(null);

	useEffect(() => {
		dispatch(getVersionDetails());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (aboutUsDetails) {
			const { entity } = aboutUsDetails;
			entity && setAboutUsData(entity);
		}
	}, [aboutUsDetails]);




	return (
		<Dialog
			onClose={(e) => openAboutUs(e, false)}
			aria-labelledby='simple-dialog-title'
			open={aboutUsOpen}
			className='about-main-wrapper'
		>
			<Box className='close-icon-right'>
				<OKRButton
					//id='close-icon'
					icon={<CloseIcon />}
					className='close-icon'
					handleClick={(e) => openAboutUs(e, false)}
				/>
			</Box>

			<div className='about-us-wrapper'>
				<div className='logo-img'>
					<img src={Logo} alt={t('HeaderDrawerListLogo')} />
				</div>
				<div className='inner'>
					<div className='about-info'>
						<Typography>{`${t('version')} ${aboutUsData ? aboutUsData?.version : userDetail?.version}`}</Typography>
						{/* <p>Product ID: {userDetail.productID}</p> */}
						<Typography>{`${t('license')} ${aboutUsData ? aboutUsData?.license : userDetail?.license}`}</Typography>
						{/* <Typography>{`${t('belongsTo')} ${userDetail.belongsTo}`}</Typography> */}
						<Typography className='about-copy-reserve'>
							&copy; {currentYear} {t('infoproRights')}
						</Typography>
						<p>
							{/* <Link onClick={(e) => handleMenuClick(e)}>{t('licenseTerms')}</Link>{' '} */}
							{/* |{' '}
							<a href='' target='_blank'>
								{t('thirdPartyNotice')}
							</a> */}
							<Link id='terms-of-use' target='_blank' to='/terms-of-use'>
								{t('licenseTerms')}
							</Link>
							<span className='seprator'>|</span>
							<Link id='privacy-policy' target='_blank' to='/privacy-policy'>
								{t('privacyPolicy')}
							</Link>
						</p>
					</div>
					<div className='warning-info'>{t('warningAboutUs')}</div>
				</div>
			</div>
		</Dialog>
	);
}

export default function MyAccount(props) {
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const { profileOpen, setProfileOpen } = props;
	const anchorRef = useRef(null);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [feedbackRedirection, setFeedbackRedirection] = useState(false);
	const [showPersonalFeedback, setShowPersonalFeedback] = useState('');
	const [aboutUsOpen, setAboutUsOpen] = useState(false);
	const dispatch = useDispatch();
	const { okrCreationInProgress } = useSelector((state) => state.commonReducer);
	const { userVersion } = useSelector((state) => state?.commonReducer);

	const openAboutUs = (e, value) => {
		e.preventDefault();
		e.stopPropagation();
		setAboutUsOpen(value);
		handleClose();
	};

	const handleMenuClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		return false;
	};

	useEffect(() => {
		if (!props.isFederationUserSuccess) {
			props.isFederationUser(userDetail.emailId);
		}
	}, []);

	const handleClickAway = (e) => {
		setOpen(false);
	};

	const handleOpen = (event, isOpen) => {
		if (0 && okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		setOpen(isOpen);
	};
	const handleClose = (event) => {
		if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	const openPersonalFeedback = () => {
		setFeedbackRedirection(true);
		setShowPersonalFeedback('view');
	};

	const resetPFData = () => {
		setFeedbackRedirection(false);
		setShowPersonalFeedback('');
	};

	return (
		<>
			<Box className='user-tab whats-new-status'>
				<Tooltip
					title={userDetail?.isImpersonatedUser ? `${userDetail.firstName} ${userDetail.lastName}` : t('meLabel')}
					arrow
					placement='right'
				>
					<IconButton
						className='profile-btn check-un-save'
						type='button'
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						onClick={(e) => {
							handleOpen(e, open ? false : true);
						}}
						id='profile-btn'
					>
						{userDetail && userDetail.imagePath && userDetail.imagePath.trim() !== '' ? (
							<Avatar className='check-un-save' src={userDetail.imagePath} />
						) : userDetail && userDetail.firstName ? (
							<Avatar className='check-un-save'>{getUserName(userDetail)}</Avatar>
						) : (
							<Avatar></Avatar>
						)}
					</IconButton>
				</Tooltip>

				{userVersion?.isRead ? (
					<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
				) : (
					<></>
				)}
			</Box>
			{open && (
				<ClickAwayListener
					onClickAway={(e) => {
						handleClickAway(e);
					}}
				>
					<Box>
						{open && (
							<ProfileInfoDrawer
								{...props}
								open={open}
								handleClose={handleClose}
								profileOpen={profileOpen}
								setProfileOpen={setProfileOpen}
								openAboutUs={openAboutUs}
							/>
						)}
					</Box>
				</ClickAwayListener>
			)}
			{feedbackRedirection && Boolean(showPersonalFeedback) && (
				<PersonalFeedbackMenu
					{...props}
					loggedInUser={true}
					feedbackRedirection={feedbackRedirection}
					showPersonalFeedback={showPersonalFeedback}
					userDetail={userDetail}
					resetPFData={resetPFData}
					setFeedbackData={() => { }}
				/>
			)}



			{profileOpen && <Profile {...props} setProfileOpen={setProfileOpen} open={profileOpen} />}

			<SimpleDialog aboutUsOpen={aboutUsOpen} openAboutUs={openAboutUs} handleMenuClick={handleMenuClick} />
		</>
	);
}
