import { resetPasswordAPI, generateResetPasswordMailAPI } from '../services/ResetPasswordService';
import * as actionTypes from './actionTypes';

const resetPasswordPending = () => ({
	type: actionTypes.RESET_PASSWORD_PENDING,
});

const resetPasswordCompleted = (payload) => ({
	type: actionTypes.RESET_PASSWORD_SUCCESS,
	payload,
});

const resetPasswordFailed = (error) => ({
	type: actionTypes.RESET_PASSWORD_FAILED,
	error,
});

export const resetPasswordAction = (data) => {
	return (dispatch) => {
		dispatch(resetPasswordPending());
		return resetPasswordAPI(data)
			.then((response) => {
				dispatch(resetPasswordCompleted(response.data));
			})
			.catch((error) => {
				dispatch(resetPasswordFailed(error));
			});
	};
};

export const updateStatus = () => ({
	type: actionTypes.UPDATE_STATUS,
});

export const generateResetPasswordMail = () => {
	return () => {
		return generateResetPasswordMailAPI();
	};
};
