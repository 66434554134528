import { Box } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { CreateUserDrawerForm } from './CreateUserDrawerForm';
import '../../../../styles/pages/admin/createUserDrawer.scss';
import {
	defaultUserData,
	userDataRequiredFields,
	prepareApiPayload,
	prepareAddAnotherFormData,
	isFieldValid,
	prepareEditUserFormData,
	freeTrialsRequiredFields,
} from '../UsersHelper';
import { Enums } from '../../../../config/enums';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { USER_ROLE_ID } from '../../../../config/users';
import { getUserDetails, GAevent, isTrialVersion } from '../../../../config/utils';
import { getLocalStorageItem } from '../../../../services/StorageService';
import { useDispatch, useSelector } from 'react-redux';
import { isNewPerformanceRole, performanceCycleActiveCycle } from '../../../../action/adminSettings';
import AlertDialog from '../../../Common/Dialog';
import { userResetDefaultOkr } from '../../../../action/users';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

export const CreateUser: React.FC<any> = (props) => {
	const {
		openCreateUserDrawer,
		handleMenuClick,
		addUsers,
		updateStatus,
		isEditForm,
		editUserDetails,
		editUsers,
		setUserBulkGoalFormUpdated,
	} = props;
	const moment = extendMoment(Moment);
	const { t } = useTranslation();
	const { okrMasterData } = useSelector((state: any) => state.okrReducer);
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [selectedAddNewUserTab, setSelectedAddNewUserTab] = useState<any>(0);
	const [userFormData, setUserFormData] = useState<any>(defaultUserData);
	const [formError, setFormError] = useState<any>({});
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [validationInProgress, setValidationInProgress] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });
	const [loader, setLoader] = useState(false);
	const [addAnother, setAddAnother] = useState<boolean>(false);
	const [uploadFileData, setUploadFileData] = useState({
		filePath: '',
		fileName: '',
		fileUploaded: false,
	});
	const [roleChangeModalProps, setRoleChangeModalProps] = useState<any>({ open: false, type: '', message: '' });
	const [resetModal, setResetModal] = useState<any>({ open: false, type: '', message: '', event: {} });
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [errorLogMessage, setErrorLogMessage] = useState([]);
	const [isInviteUser, setIsInviteUser] = useState<boolean>(false);
	const loggedInUserDetail = getUserDetails();
	const isGuidedTourShow = process.env.REACT_APP_ISGUIDEDTOURSHOW;
	const [isExcludeFromCurrentPerformanceCycleShow, setIsExcludeFromCurrentPerformanceCycleShow] =
		useState<boolean>(false);

	useEffect(() => {
		checkPerformanceCycleIsActive();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isEditForm === true && editUserDetails) {
			setUserFormData(prepareEditUserFormData(editUserDetails));
			if (editUserDetails?.joiningDate) {
				setSelectedDateRange(
					moment(editUserDetails?.joiningDate ? new Date(editUserDetails?.joiningDate) : new Date())
				);
			}
		}
	}, [editUserDetails]);

	const checkPerformanceCycleIsActive = async () => {
		const response: any = await dispatch(performanceCycleActiveCycle());
		if (response && response?.data && response?.data?.status === 200) {
			const { entity } = response?.data;
			setIsExcludeFromCurrentPerformanceCycleShow(entity || false);
		} else {
			setIsExcludeFromCurrentPerformanceCycleShow(false);
		}
	};
	/**
	 * Handle Role Changes flow at the time of Update User
	 */
	const handleRoleChangeCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			createUser(roleChangeModalProps?.event || e, userFormData);
			setRoleChangeModalProps({ open: false, type: '', message: '' });
		} else {
			setRoleChangeModalProps({ open: false, type: '', message: '' });
		}
	};
	const handleResetCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			let editUserDetailsDataRoleId: any = resetModal.data.editUserDetailsData.performanceRoleId;
			let designationName: any = resetModal.data.payload.designation;
			const resp: any = await dispatch(
				userResetDefaultOkr(`designationName=${designationName}&roleId=${editUserDetailsDataRoleId}`)
			);
			if (resp && resp?.data && resp?.data?.status === 200) {
				createUser(resetModal.event, userFormData);
			}
			setResetModal({ open: false, type: '', message: '', event: {} });
		} else {
			//createUser(resetModal.event, { ...userFormData, designation: editUserDetails.designation, performanceRoleName: editUserDetails.performanceRoleName });
			setResetModal({ open: false, type: '', message: '', event: {} });
		}
	};
	const checkChangedRoleIsNewRole = async (performanceRoleName: string) => {
		if (performanceRoleName) {
			const requestParams = `performanceRole=${performanceRoleName}`;
			const resp: any = await dispatch(isNewPerformanceRole(requestParams));
			if (resp && resp?.data && resp?.data?.status === 200) {
				return resp?.data?.entity || false;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};
	/**
	 * Handle drawer actions
	 */
	const handleDrawerClose = async (event: React.ChangeEvent<HTMLInputElement>, type: string, noCheck = false) => {
		event.preventDefault();
		let createUserPayload: any = prepareApiPayload(userFormData);

		if (type === 'save') {
			if (
				isEditForm &&
				(createUserPayload?.designation !== editUserDetails.designation ||
					createUserPayload?.performanceRoleName !== editUserDetails.performanceRoleName)
			) {
				setResetModal({
					open: true,
					type: 'resetDefault',
					message: t('resetDefaultOkr', { defaultOKRs: okrMasterData.defaultOkrTagName }),
					data: {
						payload: createUserPayload,
						editUserDetailsData: editUserDetails,
					},
					event,
				});
				return false;
			}

			if (selectedAddNewUserTab === 0) {
				// process.env.REACT_APP_ENV === "trial" && GAevent('AddUser', 'Clicked Add Button To Add User', 'Add User');
				isTrialVersion() && GAevent('AddUser', 'Clicked Add Button To Add User', 'Add User');

				const isNewPerformanceRole = await checkChangedRoleIsNewRole(createUserPayload?.performanceRoleName);
				if (
					isEditForm &&
					createUserPayload?.performanceRoleName !== editUserDetails.performanceRoleName &&
					isNewPerformanceRole
				) {
					setRoleChangeModalProps({
						open: true,
						event: event,
						type: 'roleChanged',
						message: t('editUserPMSRoleChangeConfirmation'),
					});
				} else {
					createUser(event, userFormData);
				}
			} else if (selectedAddNewUserTab === 1) {
				uploadBulkUser(event);
				setValidationInProgress(true);
			} else if (selectedAddNewUserTab === 2) {
				uploadBulkEmails(event);
				setValidationInProgress(true);
			} else if (selectedAddNewUserTab === 3) {
				uploadBulkGoalRole(event);
				setValidationInProgress(true);
			}
		} else if (type === 'cancel' || type === 'close') {
			if (formEdited) {
				setModalProps({
					open: true,
					module: 'information',
					type: 'addUser',
					message: t('unSavedItemAlert'),
				});
			} else {
				handleMenuClick(event, 'addNewUsers');
			}
		}
	};

	/**
	 * validate user form
	 * @returns boolean
	 */
	const validateUserForm = (): boolean => {
		let isValid = true;
		let errorList: any = {};
		let requireFields = userDataRequiredFields;
		if (isTrialVersion() && isEditForm !== true) {
			requireFields = freeTrialsRequiredFields;
		}
		requireFields.forEach((fieldName: string) => {
			let fieldError = isFieldValid(fieldName, userFormData[fieldName], t);
			if (fieldError !== '') {
				errorList[fieldName] = fieldError;
			}
			isValid = isValid && fieldError === '';
		});
		setFormError(errorList);
		return isValid;
	};

	/**
	 * Validate bulk users
	 */
	const validateBulkUsers = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response = await props.validateBulkFileUpload(formData);
			if (Boolean(response) && response.data && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};

	/**
	 * perform create user api call and show message on error / success
	 * @param event
	 */
	const createUser = async (event: any, userData: any) => {
		if (validateUserForm() === true) {
			let createUserPayload: any = prepareApiPayload(userData);
			if (isTrialVersion() && isEditForm !== true) {
				createUserPayload.roleId = USER_ROLE_ID;
				createUserPayload.reportingTo = loggedInUserDetail.employeeId;
				createUserPayload.teamId = [loggedInUserDetail.organisationId];
			}
			if (isEditForm === true) {
				createUserPayload.isDesignationChanged = false;
				createUserPayload.isPerformanceRoleChanged = false;
				if (createUserPayload.designation !== editUserDetails.designation) {
					createUserPayload.isDesignationChanged = true;
				}
				if (createUserPayload.performanceRoleId !== editUserDetails.performanceRoleId) {
					//createUserPayload.isPerformanceRoleChanged = true;
				}
			}
			let response: any = {};
			setLoader(true);
			if (isEditForm === true) {
				createUserPayload.employeeId = editUserDetails.employeeId;
				response = await editUsers(createUserPayload);
			} else {
				response = await addUsers(createUserPayload);
			}
			if (response.data.status === Enums.STATUS_SUCCESS) {
				if (!isEditForm) {
					props.refetchLicenceDetails();
					refreshOrgList();
				}
				setFormError({});
				updateStatus();
				setLoader(false);
				enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
					variant: 'success',
					autoHideDuration: 5000,
				});

				if (addAnother === true) {
					setAddAnother(!addAnother);
					setUserFormData(prepareAddAnotherFormData(userFormData));
				} else {
					handleMenuClick(event, 'addNewUsers');
				}
			} else {
				if (response?.data?.messageList) {
					if (response?.data?.messageList.message) {
						enqueueSnackbar(response?.data?.messageList.message, {
							variant: 'error',
							autoHideDuration: 5000,
						});
					} else {
						setFormError(response?.data?.messageList);
					}
				}
				setLoader(false);
			}
		}
	};

	/**
	 * alert box button click event
	 * @param e
	 * @param type
	 */
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'bulkUserUpload' && uploadFileData.fileName != '') {
				uploadBulkUser(e);
			} else if (modalProps.type === 'bulkEmailsUpload' && uploadFileData.fileName != '') {
				uploadBulkEmails(e);
			} else {
				handleMenuClick(e, 'addNewUsers');
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	/**
	 * Handle Role detail and Manage User tab change
	 * @param event HTML Event
	 * @param newValue Number
	 */
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		setSelectedAddNewUserTab(newValue);
		setErrorLogMessage([]);
		setUploadFileData({
			filePath: '',
			fileName: '',
			fileUploaded: false,
		});
	};

	const uploadFileHandler = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			formData.append('isEmailSend', isInviteUser ? 'true' : 'false');
			const response = await props.uploadUser(formData);
			if (Boolean(response) && response.data && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};
	const refreshOrgList = () => {
		props.getManageOrgList();
	};
	const uploadBulkUser = async (e: any) => {
		if (uploadFileData.fileName && uploadFileData.fileUploaded) {
			try {
				const fileUploadDetails = await uploadFileHandler();

				const responseAPI = fileUploadDetails?.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				setLoader(true);
				if (fileUploadDetails.status === 200) {
					enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					props.getLicenseDetails();
					refreshOrgList();
					handleMenuClick(e, 'addNewUsers');
					setFormError({});
					setLoader(false);
				} else {
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					setFormError({ bulkUserError: messages });
					setLoader(false);
				}
				if (
					fileUploadDetails.entity &&
					fileUploadDetails.entity.bulkErrors &&
					fileUploadDetails.entity.bulkErrors.length > 0
				) {
					const errorDetail = fileUploadDetails.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
					setLoader(false);
				}
			} catch (err) {
				enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const uploadEmailsHandler = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response = await props.uploadEmails(formData);
			if (Boolean(response) && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				return response.data;
			}
		}
	};

	const uploadBulkEmails = async (e: any) => {
		if (uploadFileData.fileName && uploadFileData.fileUploaded) {
			try {
				const fileUploadDetails = await uploadEmailsHandler();

				const responseAPI = fileUploadDetails?.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				setLoader(true);
				if (fileUploadDetails.status === 200) {
					enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					props.getLicenseDetails();
					handleMenuClick(e, 'addNewUsers');
					setFormError({});
					setLoader(false);
				} else {
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					setFormError({ bulkUserError: messages });
					setLoader(false);
				}
				if (
					fileUploadDetails.entity &&
					fileUploadDetails.entity.bulkErrors &&
					fileUploadDetails.entity.bulkErrors.length > 0
				) {
					const errorDetail = fileUploadDetails.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
					setLoader(false);
				}
			} catch (err) {
				enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	const uploadGoalRoleHandler = async () => {
		if (uploadFileData.filePath) {
			const formData = new FormData();
			formData.append('formFile', uploadFileData.filePath);
			const response = await props.uploadGoalRole(formData);
			if (Boolean(response) && response.data) {
				setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				setUserBulkGoalFormUpdated(true);
				return response.data;
			}
		}
	};

	const uploadBulkGoalRole = async (e: any) => {
		if (uploadFileData.fileName && uploadFileData.fileUploaded) {
			try {
				const fileUploadDetails = await uploadGoalRoleHandler();

				const responseAPI = fileUploadDetails?.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				setLoader(true);
				if (fileUploadDetails.status === 200) {
					enqueueSnackbar(`${messages} `, { variant: 'success', autoHideDuration: 5000 });
					props.getLicenseDetails();
					refreshOrgList();
					handleMenuClick(e, 'addNewUsers');
					setFormError({});
					setLoader(false);
				} else {
					enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
					setFormError({ bulkUserError: messages });
					setLoader(false);
				}
				if (
					fileUploadDetails.entity &&
					fileUploadDetails.entity.bulkErrors &&
					fileUploadDetails.entity.bulkErrors.length > 0
				) {
					const errorDetail = fileUploadDetails.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
					setLoader(false);
				}
			} catch (err) {
				enqueueSnackbar(t('errorUploadingFile'), { variant: 'error', autoHideDuration: 5000 });
			}
		} else {
			enqueueSnackbar(`${t('UsersBulkUpload')}`, { variant: 'error', autoHideDuration: 5000 });
		}
	};

	return (
		<Fragment>
			<MainDrawer
				open={openCreateUserDrawer}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={isEditForm === true ? t('editUserHeader') : t('addUserTabLabelText')}
				loader={loader}
				children={
					<Box className='drawer-inner-content'>
						<CreateUserDrawerForm
							{...props}
							selectedAddNewUserTab={selectedAddNewUserTab}
							handleTabChange={handleTabChange}
							userFormData={userFormData}
							setUserFormData={setUserFormData}
							isFieldValid={isFieldValid}
							formError={formError}
							setFormError={setFormError}
							setFormEdited={setFormEdited}
							uploadFileData={uploadFileData}
							setUploadFileData={setUploadFileData}
							errorLogMessage={errorLogMessage}
							setErrorLogMessage={setErrorLogMessage}
							validateBulkUsers={validateBulkUsers}
							setValidationInProgress={setValidationInProgress}
							setLoader={setLoader}
							setIsInviteUser={setIsInviteUser}
							isInviteUser={isInviteUser}
							isExcludeFromCurrentPerformanceCycleShow={isExcludeFromCurrentPerformanceCycleShow}
							selectedDateRange={selectedDateRange}
							setSelectedDateRange={setSelectedDateRange}
						/>
					</Box>
				}
				saveButtonText={isEditForm ? t('update') : t('addBtn')}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
				showAddAnother={
					!isEditForm && selectedAddNewUserTab !== 1 && selectedAddNewUserTab !== 2 && selectedAddNewUserTab !== 3
				}
				addAnother={addAnother}
				isSaveButtonDisabled={(isEditForm && !formEdited) || validationInProgress}
				addAnotherTooltipText={t('addAnotherTooltipText')}
				handleAddAnother={() => {
					setAddAnother(!addAnother);
				}}
			/>
			{roleChangeModalProps && roleChangeModalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={roleChangeModalProps?.message || ''}
					handleCloseModal={handleRoleChangeCloseModal}
					modalOpen={roleChangeModalProps.open}
				/>
			)}

			{modalProps.open && (
				<DialogComponent
					module={modalProps.module}
					message={modalProps.message}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalProps.open}
				/>
			)}

			{resetModal && resetModal.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={resetModal?.message || ''}
					handleCloseModal={handleResetCloseModal}
					modalOpen={resetModal.open}
				/>
			)}
		</Fragment>
	);
};
