import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';
import { GiveFeedbackIcon, RequestOneOnOneIcon } from '../../../../config/svg/PersonalFeedbackSvg';
import { PerformanceAssessmentIcon, PipIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/additionalPermissions.scss';
import LinearLoader from '../../../Common/Loader';
import { RolesAndPermissionsTabPanel } from '..';
import { GoalCycleIcon } from '../../../../config/svg/MyGoalSvg';
import { GoalSettingsPermissions } from './GoalSettingsPermissions';
import { FeedbackPermissions } from './FeedbackPermissions';
import { useDispatch } from 'react-redux';
import { getSystemPermissionAdminData } from '../../../../action/adminSettings';
import { PipPermissions } from './PipPermissions';
import { GoalReviewPermissions } from './GoalReviewPermissions';
import { OneOnOnePermissions } from './OneOnOnePermissions';
import { checkUnSavePopUpVisible } from '../../../../config/utils';
import AlertDialog from '../../../Common/Dialog';

export const AdditionalPermissions: React.FC<any> = (props: any) => {
	const { tabSelected, a11yProps, handleTabChange } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const tabToModuleMapping: any = {
		0: 1,
		1: 2,
		2: 3,
		3: 4,
		4: 5,
	};
	const [loader, setLoader] = useState<any>(false);
	const [formPermissionData, setFormPermissionData] = useState<any>([]);
	useEffect(() => {
		getData(tabSelected);
	}, [tabSelected]);

	const [isFormEdited, setIsFormEdited] = React.useState<boolean>(false);
	const ref = useRef<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });

	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);

	const handleCheckUnSaveData = (event: any) => {
		// if (ref3?.current?.contains(event?.target)) {
		// 	//
		// } else {
		const id = document.getElementById('createLinkPopupDetail');
		const id2 = document.getElementById('back-to-main');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			checkUnSavePopUpVisible(event) &&
			isFormEdited &&
			((!ref.current?.contains(event.target) &&
				!id?.contains(event.target) &&
				!(class1.length > 0 && class1[0].contains(event.target))) ||
				id2?.contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
				});
			}
		} else {
		}
		// }
	};

	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsFormEdited(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
				// resetTabDetails(false);
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	const getData = async (moduleId: number) => {
		setLoader(true);
		setFormPermissionData([]);
		const response: any = await dispatch(getSystemPermissionAdminData('moduleId=' + tabToModuleMapping[moduleId]));
		if (response?.data?.status === 200) {
			let formResponse: any[] = [];
			response.data.entityList.forEach((element: any, index: number) => {
				element.additionalPermissionGrantToMapping = element.additionalPermissionGrantToMapping.map((item: any) => {
					return { ...item, value: item.grantPermissionToId };
				});

				element.additionalPermissionAccessOnMapping = element.additionalPermissionAccessOnMapping.map((item: any) => {
					return { ...item, value: item.accessOnId };
				});

				element.additionalPermissionCategoriesMapping = element.additionalPermissionCategoriesMapping.map(
					(item: any) => {
						return { ...item, value: item.feedbackCategoryId };
					}
				);
				formResponse[index] = element;
			});
			setFormPermissionData(formResponse);
			setLoader(false);
		} else {
			setLoader(false);
		}
	};
	const resetTabDetailsAndChange = (event: any, newValue: Number) => {
		if (isFormEdited) {
			setModalProps({
				open: true,
				type: 'unSaveChange',
				message: t('unSavedItemAlert'),
				details: null,
				event: { ...event },
			});
		} else {
			handleTabChange(event, newValue);
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}

			<Box className='performance-development-area'>
				<div ref={ref}>
					<Box className='admin-tabpanel-inner'>
						<Box className='admin-tabpanel-head'>
							<Box className='admin-tabpanel-head-left'>
								<Tabs
									className='okr-tab-button'
									value={tabSelected}
									onChange={resetTabDetailsAndChange}
									aria-label='Admin Tabs'
								>
									<Tab icon={<GoalCycleIcon />} label={t('alignGoalsLabel')} value={0} {...a11yProps(0)}></Tab>
									<Tab icon={<GiveFeedbackIcon />} label={t('feedbackLabel')} value={1} {...a11yProps(1)}></Tab>
									<Tab icon={<PipIcon />} label={t('prsImprovementPlan')} value={2} {...a11yProps(2)}></Tab>
									<Tab icon={<RequestOneOnOneIcon />} label={t('requestOnetoOne')} value={3} {...a11yProps(3)}></Tab>
									<Tab
										icon={<PerformanceAssessmentIcon />}
										label={t('goalReviewsLevel')}
										value={4}
										{...a11yProps(4)}
									></Tab>
								</Tabs>
							</Box>
							<Box className='admin-tabpanel-head-right'></Box>
						</Box>
						<RolesAndPermissionsTabPanel value={tabSelected} index={0}>
							<GoalSettingsPermissions
								formPermissionData={formPermissionData}
								setFormPermissionData={setFormPermissionData}
								getData={getData}
								tabSelected={tabSelected}
								loader={loader}
								setLoader={setLoader}
								isFormEdited={isFormEdited}
								setIsFormEdited={setIsFormEdited}
							/>
						</RolesAndPermissionsTabPanel>
						<RolesAndPermissionsTabPanel value={tabSelected} index={1}>
							<FeedbackPermissions
								formPermissionData={formPermissionData}
								setFormPermissionData={setFormPermissionData}
								getData={getData}
								tabSelected={tabSelected}
								loader={loader}
								setLoader={setLoader}
								isFormEdited={isFormEdited}
								setIsFormEdited={setIsFormEdited}
							/>
						</RolesAndPermissionsTabPanel>
						<RolesAndPermissionsTabPanel value={tabSelected} index={2}>
							<PipPermissions
								formPermissionData={formPermissionData}
								setFormPermissionData={setFormPermissionData}
								getData={getData}
								tabSelected={tabSelected}
								loader={loader}
								setLoader={setLoader}
								isFormEdited={isFormEdited}
								setIsFormEdited={setIsFormEdited}
							/>
						</RolesAndPermissionsTabPanel>
						<RolesAndPermissionsTabPanel value={tabSelected} index={3}>
							<OneOnOnePermissions
								formPermissionData={formPermissionData}
								setFormPermissionData={setFormPermissionData}
								getData={getData}
								tabSelected={tabSelected}
								loader={loader}
								setLoader={setLoader}
								isFormEdited={isFormEdited}
								setIsFormEdited={setIsFormEdited}
							/>
						</RolesAndPermissionsTabPanel>
						<RolesAndPermissionsTabPanel value={tabSelected} index={4}>
							<GoalReviewPermissions
								formPermissionData={formPermissionData}
								setFormPermissionData={setFormPermissionData}
								getData={getData}
								tabSelected={tabSelected}
								loader={loader}
								setLoader={setLoader}
								isFormEdited={isFormEdited}
								setIsFormEdited={setIsFormEdited}
							/>
						</RolesAndPermissionsTabPanel>
					</Box>
				</div>
			</Box>
			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
