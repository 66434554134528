import React, { useState, useEffect, Fragment, useRef } from 'react';
import {
	Avatar,
	Badge,
	Box,
	ClickAwayListener,
	Collapse,
	Fade,
	Grow,
	InputAdornment,
	Paper,
	Popper,
	Slide,
	Slider,
	TextField,
	Typography,
	Zoom,
	Tooltip,
	ListItem,
	List,
	ListItemText,
	IconButton,
	Chip,
} from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { AlignNewArrow, LeftArrow, RightArrowIcon } from '../../config/svg/ArrowSvg';
import { useTranslation } from 'react-i18next';
import { ClapIcon, ConversationIcon, KebabIcon } from '../../config/svg/CommonSvg';
import { CancelIcon, TickIcon } from '../../config/svg/Action';
import { CreateOKRIcon, NumbersIcon, PercentageIcon } from '../../config/svg/MyGoalSvg';
import clapAnim from '../../images/clapAnim.svg';
import { AnimatedIcon } from './AnimatedIcon';
import {
	abbreviateNumber,
	getCurrencyIcon,
	getCurrencyName,
	getFullName,
	getOkrProgressDetails,
	getPercentageOfKr,
	getPercentageUpperValue,
	getTargetValueFormated,
	getUserDetails,
	getUserName,
	getValueFromPercentage,
} from '../../config/utils';
import { OkrProgressBar } from '../OkrAction/OkrProgressBar';
import { Enums } from '../../config/enums';
import { AvatarUser } from './User/AvatarUser';
import { NumberFormatComponent } from './NumberFormat';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { USER_VIEW } from '../../config/app-url';
import { useHistory } from 'react-router-dom';
import { dragDisabled } from '../../action/myGoal';
import { useDispatch } from 'react-redux';

export const OKRProgressGroupTarget: React.FC<any> = (props) => {
	const {
		loginUserAvatar,
		contributorDetails,
		okr,
		kr,
		updateProgress,
		showEditLoader,
		currentUser,
		okrMasterData,
		showArchiveList,
		listOkrResult,
		enqueueSnackbar,
		handlePraise,
		krObj,
		setOpenKrId,
		isScoreLocked,
		handleCfrOpen,
		parentOkr,
		type,
		newKr = false,
		handleMenuClick,
		setAnchorEl,
		anchorEl,
		openProgressDetail,
		setOpenProgressDetail,
		isProgressUpdated,
		setIsProgressUpdated,
		popperError,
		setPopperError,
		isEditable,
		setErrorOpen,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [progressValuePercentage, setProgressValuePercentage] = useState<any>(kr?.myScore);

	useEffect(() => {
		dispatch(dragDisabled(openProgressDetail ? (kr.id ? kr.id : kr.goalKeyId) : 0));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openProgressDetail]);

	const userDetail = getUserDetails();

	//NEW
	const [progressPercentageValue, setProgressPercentageValue] = useState<number>();
	const [progressValue, setProgressValue] = useState<any>(kr?.myScore);
	const [isReset, setIsReset] = useState<boolean>(false);
	const [scoreInNumber, setScoreInNumber] = useState<any>(0);
	let currentYear = getLocalStorageItem('currentYear');
	useEffect(() => {
		if (anchorEl !== null) {
			setScoreInNumber(kr?.currentValue);
			let percentage: any;
			if (kr?.ownStartValue === 0 && kr?.ownTargetValue === 0) {
				percentage = getPercentageOfKr(kr?.startValue, kr?.currentValue, kr?.targetValue);
			} else {
				percentage = getPercentageOfKr(kr?.ownStartValue, kr?.currentValue, kr?.ownTargetValue);
			}
			//setProgressValuePercentage(loginUserAvatar.realScore);

			if (loginUserAvatar.employeeId === userDetail.employeeId) {
				setProgressValuePercentage(loginUserAvatar.contributorsContribution);
				setProgressValue(loginUserAvatar.contributorsContribution);
			} else {
				let curContributor =
					contributorDetails && contributorDetails.length > 0
						? contributorDetails.filter((item: any) => item.employeeId === userDetail.employeeId)
						: [];
				if (curContributor.length > 0) {
					setProgressValuePercentage(curContributor[0].contributorsContribution);
					setProgressValue(curContributor[0].contributorsContribution);
				}
			}
			//setProgressValue(Number(percentage) < 0 ? 0 : Number(percentage));
			//setProgressValue(loginUserAvatar.contributorsContribution);
			setOpenKrId(kr.goalKeyId);
			setIsProgressUpdated(false);
			setErrorOpen(false);
		} else {
			setOpenKrId(0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchorEl]);

	const resetProgress = () => {
		setIsReset(true);
		if (kr?.isScored) {
			setScoreInNumber(loginUserAvatar?.currentValue);
		} else {
			setScoreInNumber('');
		}

		let percentage = getPercentageOfKr(
			loginUserAvatar?.ownStartValue,
			loginUserAvatar?.currentValue,
			loginUserAvatar?.ownTargetValue
		);
		setProgressValuePercentage(Number(percentage) < 0 ? 0 : Number(percentage));
		setProgressValue(Number(percentage) < 0 ? 0 : Number(percentage));
		setIsProgressUpdated(false);
		setErrorOpen(false);
		setPopperError(false);
	};

	const handleFieldChange = (newValue: any) => {
		if (newValue === scoreInNumber) {
			return true;
		}
		let assignmentTypeId = kr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		const regexp = /^[0-9\\.b]+$/;
		if (newValue === '' || regexp.test(newValue)) {
			newValue = !Boolean(newValue) ? '' : newValue;
			// setProgressValue(newValue);
			setProgressPercentageValue(newValue);
			if (!isReset) {
				setIsProgressUpdated(true);
				setErrorOpen(true);
			} else {
				setIsReset(false);
			}
			setScoreInNumber(newValue);
			let score: any = 0;
			let percentage: any = 0;
			if (kr?.ownStartValue === 0 && kr?.ownTargetValue === 0) {
				score = getPercentageOfKr(kr?.startValue, newValue, kr?.targetValue);
				percentage = getPercentageOfKr(kr?.startValue, newValue, kr?.targetValue);
			} else {
				score = getPercentageOfKr(kr.ownStartValue, newValue, kr.ownTargetValue);
				percentage = getPercentageOfKr(kr.ownStartValue, newValue, kr.ownTargetValue);
			}
			setProgressValuePercentage(Number(percentage) < 0 ? 0 : Number(percentage));
			setProgressValue(score < 0 ? 0 : score);
			setPopperError(false);
		}
	};

	const handleSliderChange = (event: any, newValue: number | number[]) => {
		let assignmentTypeId = kr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		let score: any = getValueFromPercentage(kr.ownTargetValue, newValue, kr.ownStartValue, kr.currentValue);
		let percentage: any = getPercentageOfKr(kr.ownStartValue, score, kr.ownTargetValue);
		if (Number(score) === 0) {
			setScoreInNumber(0);
		} else {
			setScoreInNumber(score);
		}
		setProgressPercentageValue(percentage);
		setProgressValuePercentage(Number(percentage) < 0 ? 0 : Number(percentage));
		setIsProgressUpdated(true);
		setErrorOpen(true);
	};

	const saveProgress = () => {
		let assignmentTypeId = kr.assignmentTypeId;
		if (Boolean(parentOkr) && Boolean(parentOkr.goalObjectiveId)) {
			assignmentTypeId = parentOkr.assignmentTypeId;
		}
		if (parseFloat(kr.currentValue) !== parseFloat(scoreInNumber) || !kr?.isScored) {
			updateProgress(kr.goalKeyId || kr.krId, assignmentTypeId, scoreInNumber || 0, currentYear, parentOkr);
			setAnchorEl(null);
			setOpenProgressDetail(false);
			setIsProgressUpdated(false);
			setErrorOpen(false);
		}
	};
	const editAllowed = () => {
		return !isScoreLocked && !currentUser && !props.isTeamView && isEditable;
	};
	return (
		<Box className='group-progress-block'>
			<Box className={`group-progress-top ${!currentUser && !props.isTeamView && isEditable ? '' : 'no-editable'} `}>
				<Box className='group-progress-left'>
					<Typography>Group Progress</Typography>
				</Box>
				<Box className='group-progress-right'>
					<Box className='progress-contributor-progress-inner'>
						<Box className='popup-progress-box'>
							<Box className='popup-progress-track'>
								<Box className='popup-progress-value'>
									<Typography variant='h6'>
										{getTargetValueFormated(
											loginUserAvatar?.ownStartValue,
											kr?.metricId,
											kr?.currencyId,
											props.okrMasterData
										)}
									</Typography>
									<Typography variant='h6'>
										{getTargetValueFormated(
											loginUserAvatar?.ownTargetValue,
											kr?.metricId,
											kr?.currencyId,
											props.okrMasterData
										)}
									</Typography>
								</Box>
								<Box className='popup-progress-rail'>
									<Slider
										aria-label={t('MyGoalProgressSlider')}
										value={!currentUser && !props.isTeamView ? progressValue : loginUserAvatar.contributorsContribution}
										min={0}
										max={100}
										disabled={!editAllowed()}
										className={'show-blue-slider'}
										onChange={(e, val) => {
											if (editAllowed()) {
												setProgressValue(val);
												handleSliderChange(e, val);
											}
										}}
									/>
								</Box>
							</Box>
							<Typography variant='h6'>
								{progressValuePercentage ? getPercentageUpperValue(progressValuePercentage) : 0}%
							</Typography>
						</Box>
						{isEditable && (
							<Box className='progress-input-area'>
								{editAllowed() ? (
									<Box className={`progress-input-box ${isProgressUpdated ? 'action-btn-show' : ''}`}>
										<TextField
											placeholder={'100'}
											inputProps={{
												inputType: 'numberFieldProgress',
											}}
											className={`${popperError ? 'progress-input-unsaved' : ''}`}
											value={scoreInNumber}
											name='percentage'
											InputProps={{
												inputComponent: NumberFormatComponent,
												startAdornment: (
													<InputAdornment position='start'>
														{kr.metricId === 1
															? '%'
															: kr.metricId === 2
															? getCurrencyIcon(getCurrencyName(kr?.metricId, kr?.currencyId, props.okrMasterData))
															: '#'}
													</InputAdornment>
												),
											}}
											onChange={(e: any) => {
												handleFieldChange(e.target.value);
											}}
										/>
										{isProgressUpdated && (
											<Box className='textfield-action-btn'>
												<OKRButton
													className='cancel-btn'
													id={'cancel-btn'}
													icon={<CancelIcon />}
													handleClick={() => resetProgress()}
												/>
												<OKRButton
													className='save-btn'
													id={'save-btn'}
													icon={<TickIcon />}
													disabled={!isProgressUpdated}
													handleClick={(e: any) => {
														saveProgress();
													}}
												/>
											</Box>
										)}
									</Box>
								) : (
									<Box className='popup-progress-value-bottom'>
										<Typography variant='h6'>
											{getTargetValueFormated(
												loginUserAvatar?.currentValue,
												kr?.metricId,
												kr?.currencyId,
												props.okrMasterData
											)}
										</Typography>
									</Box>
								)}
								<Box className='progress-link-icon'></Box>
							</Box>
						)}
					</Box>
				</Box>
			</Box>

			<Box className='group-progress-bottom'>
				<Box className='group-progress-contributor-list'>
					<Box className='group-progress-contributor-row'>
						{/* add deleted-user class for deleted user */}
						<Box
							className={`${
								loginUserAvatar.isActive === false
									? 'progress-contributor-info deleted-user'
									: 'progress-contributor-info'
							}`}
						>
							<Box className='user-info'>
								<Box className='user-img'>
									<AvatarUser userDetail={loginUserAvatar} className='' />
								</Box>
								<Box className='user-info-details'>
									<Tooltip title={getFullName(loginUserAvatar)} arrow>
										<Typography variant='body1' className='user-name'>
											{getFullName(loginUserAvatar)}
										</Typography>
									</Tooltip>
									<Typography className='parent-chip'>{t('parentLabel')}</Typography>
									{loginUserAvatar.isActive === false && <Chip className='delete-chip' label={t('Deleted')} />}
								</Box>
							</Box>
						</Box>
					</Box>
					{contributorDetails && contributorDetails.length > 0 ? (
						contributorDetails.map(
							(item: any) =>
								item && (
									<Box className='group-progress-contributor-row'>
										{/* add deleted-user class for deleted user */}
										<Box
											className={`${
												item.isActive === false ? 'progress-contributor-info deleted-user' : 'progress-contributor-info'
											}`}
										>
											<Box className='user-info'>
												<Box className='user-img'>
													{(item.isAssigned || !isEditable) && (
														<Tooltip title={t('givenByYou')} arrow>
															<Box className='arrow-icon assign-arrow-icon'>
																<AlignNewArrow />
															</Box>
														</Tooltip>
													)}
													{item.isAligned && (
														<Tooltip title={t('selfAlignedLabel')} arrow>
															<Box className='arrow-icon'>
																<AlignNewArrow />
															</Box>
														</Tooltip>
													)}
													<AvatarUser userDetail={item} className='' />
												</Box>
												<Box className='user-info-details'>
													<Tooltip title={getFullName(item)} arrow>
														<Typography variant='body1' className='user-name'>
															{getFullName(item)}
														</Typography>
													</Tooltip>
													{item.employeeId === loginUserAvatar.employeeId && (
														<Typography className='parent-chip'>{t('parentLabel')}</Typography>
													)}
													{item.isActive === false && <Chip className='delete-chip' label={t('Deleted')} />}
													{item.employeeId !== userDetail.employeeId && isEditable && (
														<Box className='progress-kebab-icon'>
															<OKRButton
																className={'more-btn'}
																title={t('moreActionsLevel')}
																icon={<KebabIcon />}
																handleClick={(e: any) => handleMenuClick(e, item)}
																tooltipPlacement={'top'}
															/>
														</Box>
													)}
												</Box>
											</Box>
										</Box>
									</Box>
								)
						)
					) : (
						<></>
					)}
				</Box>
			</Box>
		</Box>
	);
};
