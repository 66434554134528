import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Box, Tab, Tabs, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ConversationIcon, NotesIcon, TasksIcon } from '../../../config/svg/CommonSvg';
import { getCurrentUserDetails, isConversationDisabled, isConversationUnread } from '../../../config/utils';

export const CfrTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`cfr-tab-${index}`}
			aria-labelledby={`cfr-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

CfrTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `cfr-tabpanel-${index}`,
		'aria-controls': `cfr-tabpanel-${index}`,
	};
}

export const CfrTabs: React.FC<any> = (props) => {
	const { handleTabChange = {}, tabSelected = 0, taskCount, notesCount, type, okr, kr } = props;
	const { t } = useTranslation();
	const currentUser = getCurrentUserDetails();
	const onlyConversationVisible = () => {
		return type === 4;
	};
	return (
		<Tabs className='okr-drawer-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Cfr Tabs'>
			{isConversationDisabled(type, okr, kr, currentUser) ? (
				<Tooltip arrow title={`${t('conversationDisabledDrawer')} `}>
					<Box className='drawer-conversation-tab'>
						<Tab
							// icon={
							// 	<Badge
							// 		variant='dot'
							// 		className='dot-bubble'
							// 		color='secondary'
							// 		invisible={isConversationUnread(type, okr, kr) ? false : true}
							// 	>
							// 		<ConversationIcon />
							// 	</Badge>
							// }
							label={
								<Badge
									variant='dot'
									className='dot-bubble'
									color='secondary'
									invisible={isConversationUnread(type, okr, kr) ? false : true}
								>
									{t('conversationLabel')}
								</Badge>
							}
							value={0}
							disabled={isConversationDisabled(type, okr, kr, currentUser)}
							{...a11yProps(0)}
						/>
					</Box>
				</Tooltip>
			) : (
				<Tab
					// icon={
					// 	<Badge
					// 		variant='dot'
					// 		className='dot-bubble'
					// 		color='secondary'
					// 		invisible={isConversationUnread(type, okr, kr) ? false : true}
					// 	>
					// 		<ConversationIcon />
					// 	</Badge>
					// }
					label={
						<Badge
							variant='dot'
							className='dot-bubble'
							color='secondary'
							invisible={isConversationUnread(type, okr, kr) ? false : true}
						>
							{t('conversationLabel')}
						</Badge>
					}
					value={0}
					disabled={isConversationDisabled(type, okr, kr, currentUser)}
					{...a11yProps(0)}
				/>
			)}

			{/* <Tab icon={<PersonalFeedbackIcon />} label={t('feedbackLabel')} value={1} {...a11yProps(1)} /> */}
			{type !== 1 && !onlyConversationVisible() && (
				<Tab
					// icon={
					// 	<Badge variant='dot' className='dot-bubble' color='secondary' invisible={true}>
					// 		<TasksIcon />
					// 	</Badge>
					// }
					label={
						<>
							<Badge variant='dot' className='dot-bubble' color='secondary' invisible={true}>
								{t('tasksLabel')}
							</Badge>

							{/* <span className='count-text'>
								({taskCount.taskCompleted}/{taskCount.totalTask})
							</span> */}
						</>
					}
					value={2}
					{...a11yProps(2)}
				/>
			)}
			{!onlyConversationVisible() && (
				<Tab
					// icon={
					// 	<Badge variant='dot' className='dot-bubble' color='secondary' invisible={true}>
					// 		<NotesIcon />
					// 	</Badge>
					// }
					label={
						<>
							<Badge variant='dot' className='dot-bubble' color='secondary' invisible={true}>
								{t('notesLabel')}
							</Badge>

							{/* <span className='count-text'>({notesCount})</span> */}
						</>
					}
					value={3}
					{...a11yProps(3)}
				/>
			)}
		</Tabs>
	);
};
