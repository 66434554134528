import React, { useEffect, useState } from 'react';
import {
	Popper,
	Grow,
	Typography,
	List,
	ListItem,
	Checkbox,
	Paper,
	ClickAwayListener,
	FormControlLabel,
	Button,
	Link,
	Box,
	Tooltip,
} from '@material-ui/core';
// import '../../AlignmentMap/AlignmentMapFilter/style.scss';
import { useTranslation } from 'react-i18next';
import { CancelIcon } from '../../../config/svg/Action';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { OKRButton } from '../../Common/OKRButton';
import { NewFilterIcon } from '../../../config/svg/CommonSvgIcon';
import { DownArrowIcon, UpArrowIcon } from '../../../config/svg/ArrowSvg';
import {
	globalTaskDaysNewFilterLabels,
	globalTaskPriorityLabels,
	globalTaskSourceLabels,
} from '../../../config/constant';
import { checkSupportPermission, getPriorityClass, getPriorityIcon } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { supportModuleAlignOkr } from '../../Admin/AdminConstant';

export const TaskFilter: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const {
		handleApplyFilterClick,
		handleFilterChanged,
		handleCloseChip,
		clearFilter,
		appliedFilter,
		filterCondition,
		localFilterCondition,
		selectTaskTab,
		callingFrom,
		globalTaskRequest,
	} = props;

	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

	const handleToggleFilter = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpenFilter((prev) => !prev);
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};

	return (
		<Box className='goal-okr-filter alignment-map-filter'>
			<OKRButton
				className={`list-filter-btn ${openFilter ? 'active' : ''}`}
				icon={<NewFilterIcon />}
				text={
					callingFrom === 'GlobalTask' || callingFrom === 'OneOnOneTask' || callingFrom === 'CheckInTask' ? (
						<span className='filter-txt'>
							{t('filterLabel')}
							{openFilter ? <UpArrowIcon /> : <DownArrowIcon />}
						</span>
					) : (
						<></>
					)
				}
				handleClick={handleToggleFilter}
				id='dashboard-filter-btn'
			/>
			<Box className={`filter-chip `}>
				{appliedFilter && (
					<List>
						{localFilterCondition && localFilterCondition.filterData.length > 0 ? (
							<>
								{localFilterCondition.filterData.slice(0, 1).map((item: any, index: any) => (
									<ListItem key={index}>
										<Tooltip arrow title={item.name}>
											<Typography variant='body2'>{item.name}</Typography>
										</Tooltip>
										<Button className='cancel-btn' onClick={() => handleCloseChip(item)}>
											<CancelIcon />
										</Button>
									</ListItem>
								))}
								{localFilterCondition.filterData.length > 1 && (
									<ListItem className='more-filter-count'>
										<Typography variant='body2'>+{localFilterCondition.filterData.length - 1}</Typography>
									</ListItem>
								)}

								<ListItem className='clear-all'>
									<Link onClick={clearFilter}>{t('clearAll')}</Link>
								</ListItem>
							</>
						) : (
							<></>
						)}
					</List>
				)}
			</Box>
			<Popper
				className={`okr-filter alignmentmap-filter-list task-filter-popup ${
					callingFrom === 'GlobalTask' || callingFrom === 'OneOnOneTask' || callingFrom === 'CheckInTask'
						? ''
						: 'task-drawer-filter-popup'
				}`}
				open={openFilter}
				anchorEl={anchorEl}
				id='filtersPopper'
				placement={'bottom-start'}
				modifiers={{
					flip: {
						enabled: false,
					},
				}}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps}>
						<ClickAwayListener onClickAway={handleClose}>
							<Paper>
								<Box className='task-filter-wrap'>
									<Box className='task-filter-wrap-inner'>
										<Box className='task-due-date-filter'>
											<Typography variant='body2'>{t('byDueDate')}:</Typography>
											<List>
												{Object.values(globalTaskDaysNewFilterLabels).map((value, index) => {
													return (
														<>
															{/* {selectTaskTab === 1 && value === 'Overdue' ? (
																<></>
															) : ( */}
															{(callingFrom === 'CheckInTask' && index === Enums.ZERO) ||
															(globalTaskRequest?.isCompleted && index === Enums.ONE) ||
															(!globalTaskRequest?.isCompleted && index === Enums.FIVE) ? (
																<></>
															) : (
																<ListItem key={`type-filter-date-list-${index}`}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				icon={<BorderCheckboxIcon />}
																				checkedIcon={<CheckedIcon />}
																				checked={filterCondition.days.includes(index + 1)}
																				value={index}
																				onChange={(e) => handleFilterChanged(e, index + 1, 'days')}
																				color='primary'
																			/>
																		}
																		label={value}
																	/>
																</ListItem>
															)}
															{/* )} */}
														</>
													);
												})}
											</List>
										</Box>
										{callingFrom === 'GlobalTask' ? (
											<Box className='task-source-filter'>
												<Typography variant='body2'>{t('byCategory')}:</Typography>
												<List>
													{Object.values(globalTaskSourceLabels)
														.filter((key, value) => {
															return !(key === 'OKRs' && !checkSupportPermission(supportModuleAlignOkr));
														})
														.map((value, index) => {
															return (
																<ListItem key={`type-filter-source-list-${index}`}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				icon={<BorderCheckboxIcon />}
																				checkedIcon={<CheckedIcon />}
																				checked={filterCondition.source.includes(index + 1)}
																				value={index}
																				onChange={(e) => handleFilterChanged(e, index + 1, 'source')}
																				color='primary'
																			/>
																		}
																		label={value}
																	/>
																</ListItem>
															);
														})}
												</List>
											</Box>
										) : (
											<></>
										)}
										<Box className='task-priority-filter'>
											<Typography variant='body2'>{t('priorityLabel')}:</Typography>
											<List>
												{Object.values(globalTaskPriorityLabels).map((value, index) => {
													return (
														<ListItem
															className={getPriorityClass(index + 1)}
															key={`type-filter-priority-list-${index}`}
														>
															<FormControlLabel
																control={
																	<Checkbox
																		icon={<BorderCheckboxIcon />}
																		checkedIcon={<CheckedIcon />}
																		checked={filterCondition.priority.includes(index + 1)}
																		value={index}
																		onChange={(e) => handleFilterChanged(e, index + 1, 'priority')}
																		color='primary'
																	/>
																}
																label={
																	<span>
																		{getPriorityIcon(index + 1)}
																		{value}
																	</span>
																}
															/>
														</ListItem>
													);
												})}
											</List>
										</Box>
									</Box>
									<Box className='task-filter-actions'>
										<OKRButton
											className='apply-btn btn-primary'
											text={t('applyLabel')}
											handleClick={(e: any) => {
												handleClose(e);
												handleApplyFilterClick(e);
											}}
										/>
									</Box>
								</Box>
							</Paper>
						</ClickAwayListener>
					</Grow>
				)}
			</Popper>
		</Box>
	);
};
