import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemText, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { OKRButton } from '../../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../../config/svg/CommonSvgIcon';

export const ReviewWorkflowGroup: React.FC<any> = (props: any) => {
	const { handleChange, statusValue, selValue, reviewWorkflowGroupList, isDisabled } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [selectedValue, setSelectedValue] = useState<any>({});
	//const [reviewWorkflowGroupList, setReviewWorkflowGroupList] = useState<any>([]);

	useEffect(() => {
		if (statusValue && statusValue?.status) {
			setSelectedValue(statusValue);
		} else {
			setSelectedValue({});
		}
	}, [statusValue]);

	useEffect(() => {
		if (selValue && selValue?.performanceGoalReviewWorkFlowId) {
			setSelectedValue(selValue);
		} else {
			setSelectedValue({});
		}
	}, [selValue]);
	const handleClick = () => {
		if (!isDisabled) {
			setOpen((prev: Boolean) => !prev);
		}
	};
	const handleClickAway = () => {
		setOpen(false);
	};

	const handleSelection = (selectedValue: any) => {
		handleClickAway();
		setSelectedValue(selectedValue);
		handleChange(selectedValue);
	};
	return (
		<Box
			className={`okr-team-dropdown review-workflow-dropdown ${open ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
		>
			<OKRButton
				className={'dropdown-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				disabled={false}
				text={selectedValue && selectedValue?.name ? selectedValue?.name : t('Select Status')}
			/>

			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								{reviewWorkflowGroupList.length > 0 ? (
									<List component='div' disablePadding>
										{reviewWorkflowGroupList?.map((item: any) => (
											<ListItem
												component='div'
												className={
													selectedValue?.performanceGoalReviewWorkFlowId === item?.performanceGoalReviewWorkFlowId
														? 'active'
														: ''
												}
												onClick={() => {
													handleSelection(item);
												}}
											>
												<ListItemText>{item.name}</ListItemText>
											</ListItem>
										))}
									</List>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
