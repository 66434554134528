import React, { useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { GenerateKr } from './GenerateOKRs/GenerateKr';

export const GenerateKrDrawer: React.FC<any> = (props) => {
	const { t, open, setOpenGenerateKrDrawer, aiObjective } = props;
	const [tabSelected, setTabSelected] = React.useState<Number>(0);
	const handleGenerateDrawerAction = (event: any, type: string) => {
		setOpenGenerateKrDrawer(false);
	};
	return (
		<>
			<OKRDrawer
				open={open}
				//loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={`main-drawer-panel main-drawer-no-footer`}
				headerTitle={<>{t('generateKeyResult')}</>}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-panel'>
							<GenerateKr {...props} aiObjective={aiObjective} />
						</Box>
					</Box>
				}
				//isSaveButtonDisabled={Object.keys(okrKrSelectionData).length === 0 || importButtonDisabled}
				moreButton={false}
				saveButtonText={t('copyBtnLevel')}
				isFooterVisible={false}
				handleDrawerClose={(event) => handleGenerateDrawerAction(event, 'cancel')}
				handleSaveClick={(event) => handleGenerateDrawerAction(event, 'save')}
				handleCancelClick={(event) => handleGenerateDrawerAction(event, 'cancel')}
			/>
		</>
	);
};
