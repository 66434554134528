import React from 'react';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { Box, ClickAwayListener, Typography } from '@material-ui/core';
import { UserIcon } from '../../../../config/svg/CommonSvgIcon';
import { ErrorIcon, RoleIcon } from '../../../../config/svg/formElementIcons';
import { OKRButton } from '../../../Common/OKRButton';
import '../../../../styles/pages/admin/bulkUserEdit.scss';
import { Enums } from '../../../../config/enums';

export const ChangeReportingTo: React.FC<any> = (props) => {
	const {
		setOpenChangeReportingTo,
		checkedUser,
		t,
		selectedReportingTo,
		setSelectedReportingTo,
		reportingToOptions,
		setReportingToOptions,
		confirmUpdate,
		searchEmployee,
	} = props;

	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			const resp = await searchEmployee(value, 1, Enums.GLOBAL_SEARCH_PAGE_SIZE);
			let list = resp.data.entityList;
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: `${userData.firstName}  ${userData.lastName}`,
							value: userData.employeeId,
							secondaryText: userData.designation,
						};
					})
				);
			} else {
				setReportingToOptions([]);
			}
		}
	};
	return (
		<Box className='bulk-user-popover'>
			<Box className='connnector-icon'></Box>
			<ClickAwayListener onClickAway={() => setOpenChangeReportingTo(false)}>
				<Box className='change-role-popup'>
					<Box className='bulk-user-popup-head'>
						<Typography variant='h3'>{t('changeReportingLabel')}</Typography>
					</Box>
					<Box className='bulk-user-popup-content'>
						<Box className='selected-user-message'>
							<ErrorIcon />
							<Typography variant='body2'>{t('selectedUsersReportingMessage')}</Typography>
						</Box>
						<Box className='user-selected-info'>
							<UserIcon />
							<Typography>
								{checkedUser && checkedUser.length} {`${t('RoleListUsers')} ${t('selectedLabel')}`}
							</Typography>
						</Box>
						<Box className='user-role-dropdown'>
							<Box className='select-user-role'>
								<RoleIcon />
								<Typography>{t('reportingToLabel')}</Typography>
							</Box>
							<MultiSelect
								key={'reportingTo'}
								id='change-reporting'
								selectedOptions={selectedReportingTo}
								optionsList={reportingToOptions}
								onSelectOption={(value: any) => {
									setSelectedReportingTo(value);
									setReportingToOptions([]);
								}}
								isMulti={true}
								placeHolder={t('searchReportingTo')}
								noOptionsMessage={<NoSearchRecord />}
								fetchAsyncData={true}
								isSingleSelection={true}
								performSearch={searchReportingTo}
								closeMenuOnSelect={true}
								labelTemplate={'avatarLabel'}
								selectClassName={'select-search-dropdown select-search-dropdown-top select-search-icon'}
								selectClassNamePrefix={'react-select'}
								blurCalled={() => {
									setReportingToOptions([]);
								}}
							/>
						</Box>
					</Box>
					<Box className='bulk-user-popup-actions'>
						<OKRButton className='btn-link' text={t('cancelBtn')} handleClick={() => setOpenChangeReportingTo(false)} />
						<OKRButton
							disabled={selectedReportingTo.length <= 0}
							className='btn-primary'
							text={t('update')}
							handleClick={() => (selectedReportingTo.length > 0 ? confirmUpdate(selectedReportingTo) : {})}
						/>
					</Box>
				</Box>
			</ClickAwayListener>
		</Box>
	);
};
