import { getRequest, postRequest } from '../config/auth';
import {
	AVG_OKR_REPORT,
	WEEKLY_KR_REPORT,
	MOST_FEEDBACK_REPORT,
	WEEKLY_KR_GROWTH_REPORT,
	LEAST_RISK_OKR_REPORT,
	LEAST_RISK_KR_REPORT,
	GET_TEAMS_PERFORMANCE,
	GET_TEAMS_PROGRESS,
	GET_LEADING_TEAMS,
	ORG_REPORTS,
	MY_PROGRESS_REPORTS,
	OVERALL_PERFORMANCE,
	MY_TEAM_PROGRESS_REPORTS,
	OVERALL_TEAM_PERFORMANCE,
	LEADING_TEAM,
	GET_MY_LEADING_TEAMS,
	GET_OKR_DETAILS_DASHOBOARD,
} from '../config/api-url';

export const getAvgOkrReportApi = (data) => getRequest(`${AVG_OKR_REPORT}?${data}`, data);
export const getMostWeeklyKrReportApi = (data) => getRequest(`${WEEKLY_KR_REPORT}?${data}`, data);
export const getMostFeedbackReportApi = (data) => getRequest(`${MOST_FEEDBACK_REPORT}?${data}`, data);
export const getWeeklyKrGrowthReportApi = (data) => getRequest(`${WEEKLY_KR_GROWTH_REPORT}?${data}`, data);
export const getLeastRiskOkrReportApi = (data) => getRequest(`${LEAST_RISK_OKR_REPORT}?${data}`, data);
export const getLeastRiskKrReportApi = (data) => getRequest(`${LEAST_RISK_KR_REPORT}?${data}`, data);
export const getTeamsPerformanceApi = (data) => getRequest(`${GET_TEAMS_PERFORMANCE}?${data}`, data);

export const getTeamsProgressApi = (data) => getRequest(`${GET_TEAMS_PROGRESS}?${data}`, data);
export const getLeadingTeamsApi = (data) => getRequest(`${GET_LEADING_TEAMS}?${data}`, data);
export const getMyLeadingTeamsApi = (data) => getRequest(`${GET_MY_LEADING_TEAMS}?${data}`, data);

/** New APIS */
export const getOrgReportsApi = (data) => getRequest(`${ORG_REPORTS}?${data}`, data);
export const getMyOkrProgressReportsApi = (data) => postRequest(`${MY_PROGRESS_REPORTS}`, data);
export const getOkrDetailsDashboardAPI = (data) => getRequest(`${GET_OKR_DETAILS_DASHOBOARD}?${data}`);

export const getEngagementOverallPerformance = (data) => postRequest(`${OVERALL_PERFORMANCE}`, data);
export const getTeamOkrProgressReportsApi = (data) => postRequest(`${MY_TEAM_PROGRESS_REPORTS}`, data);
export const getTeamEngagementOverallProgressApi = (data) => postRequest(`${OVERALL_TEAM_PERFORMANCE}`, data);
export const getLeadingTeamsReportsApi = (data) => getRequest(`${LEADING_TEAM}?${data}`, data);
