import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { TimeIcon } from '../../config/svg/CommonSvgIcon';
import { BackArrow, GreyLogo, Logo } from '../../config/svg/GlobalSvg';
import { getLearningUUrl, getServerUrl, getUserDetails, openPost, unlockEncryptString } from '../../config/utils';
import { BaseUnlockUToken, UnlockUServer } from '../../config/url';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { RightArrowIcon } from '../../config/svg/ArrowSvg';
import {
	FREE_COURSES_UNLOCKU_TOCKEN,
	LEARNINGU,
	UNLOCKAPPNAME,
	UNLOCKUTOKEN,
	UNLOCKACADEMYACTION,
} from '../../config/constant';
import { getLocalStorageItem } from '../../services/StorageService';
import { Skeleton } from '@material-ui/lab';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import noAcademiesImg from '../../images/no-academies.svg';

export const DashboardRecommendedCourses: React.FC<any> = (props: any) => {
	const { setCoursesDataLoaded } = props;
	const loginUser = getUserDetails();
	const { t } = useTranslation();
	const [recommendedCourses, setRecommendedCourses] = useState([]);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const userDetail = JSON.parse(getLocalStorageItem('userDetail') || '{}');
	let timeFormat = (n: any) => `0${(n / 60) ^ 0}`.slice(-2) + ':' + ('0' + (n % 60)).slice(-2);

	useEffect(() => {
		getCourses();
	}, []);

	const getCourses = () => {
		let data: any = new FormData();
		data.append('wstoken', BaseUnlockUToken);
		data.append('wsfunction', FREE_COURSES_UNLOCKU_TOCKEN);
		data.append('wsrestformat', 'json');
		data.append('username', loginUser.emailId);
		// data.append('typeflag', 0);
		const finalUrl = UnlockUServer ? UnlockUServer : getServerUrl() || '';
		fetch(finalUrl, {
			body: data,
			method: 'post',
		})
			.then((res: any) => res.json())
			.then((coursesResponse: any) => {
				if (coursesResponse?.length) {
					setRecommendedCourses(coursesResponse);
					setCoursesDataLoaded(true);
				} else {
					setRecommendedCourses([]);
				}
			});
	};

	function SampleNextArrow(props: any) {
		const { className, onClick } = props;
		return <OKRButton icon={<RightArrowIcon />} className={className} handleClick={onClick} />;
	}

	function SamplePrevArrow(props: any) {
		const { className, onClick } = props;
		return <OKRButton icon={<RightArrowIcon />} className={className} handleClick={onClick} />;
	}
	const settings = {
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		cssEase: 'linear',
		nextArrow: <SampleNextArrow />,
		prevArrow: <SamplePrevArrow />,
		customPaging: (i: number) => {
			return (
				<span>
					{i + 1} of {recommendedCourses && recommendedCourses.length}
				</span>
			);
		},
	};

	const handleViewCourse = (e: any, courseLink: any) => {
		// const username = unlockEncryptString(tenantData.userEmail);
		let username = unlockEncryptString(tenantData.userEmail);
		if (userDetail?.isImpersonatedUser && userDetail?.emailId) {
			username = unlockEncryptString(userDetail?.emailId);
		}
		const company_identifier = unlockEncryptString(tenantData.tenantId);
		const static_token = unlockEncryptString(UNLOCKUTOKEN);
		const app_name = unlockEncryptString(UNLOCKAPPNAME);
		const course_accesslink = unlockEncryptString(encodeURI(courseLink));
		const payloadToSend = `?username=${username}&company_identifier=${company_identifier}&token=${static_token}&app_name=${app_name}&course_link=${course_accesslink}${
			'&okrCurrentState=' + unlockEncryptString(window.location.href)
		}`;
		const params: any = [];
		const finalUrl = LEARNINGU ? LEARNINGU : getLearningUUrl();
		params['requestParams'] = payloadToSend;
		openPost(finalUrl, params, true);
	};
	const handleNavigateToUnlockU = () => {
		let username = unlockEncryptString(tenantData.userEmail);
		if (userDetail?.isImpersonatedUser && userDetail?.emailId) {
			username = unlockEncryptString(userDetail?.emailId);
		}
		const company_identifier = unlockEncryptString(tenantData.tenantId);
		const static_token = unlockEncryptString(UNLOCKUTOKEN);
		const app_name = unlockEncryptString(UNLOCKAPPNAME);
		const action = unlockEncryptString(UNLOCKACADEMYACTION);

		const payloadToSend = `?username=${username}&company_identifier=${company_identifier}&token=${static_token}&app_name=${app_name}&action=${action}${
			'&okrCurrentState=' + unlockEncryptString(window.location.href)
		}`;
		const params: any = [];
		const finalUrl = LEARNINGU ? LEARNINGU : getLearningUUrl();
		params['requestParams'] = payloadToSend;
		openPost(finalUrl, params, true);
	};

	return (
		<>
			<Box className='recommended-head'>
				<Typography variant='h6'>{t('recommendedCourses')}</Typography>
				<Box className='recommended-head-right'>
					{recommendedCourses && recommendedCourses.length > 1 ? <span className='dot-sep'></span> : <></>}
					{recommendedCourses && recommendedCourses.length ? (
						<OKRButton
							className='btn-link-type1 '
							text={`${t('allAcademiesLabel')} `}
							handleClick={handleNavigateToUnlockU}
						/>
					) : (
						<></>
					)}
				</Box>
			</Box>
			{recommendedCourses && (
				<Box className='course-list'>
					{recommendedCourses && recommendedCourses.length ? (
						<Slider {...settings}>
							{recommendedCourses.map((course: any, index: any) => (
								<Box key={index} className='slider-row'>
									<Box className='slider-row-inner'>
										<Box className='courses-img'>
											<img src={course?.image_path} alt={course?.title} />
										</Box>
										<Box className='courses-content'>
											<Box className='content-top'>
												<Typography variant='h4'>{course?.title}</Typography>
												{course?.description.length > 150 ? (
													<Typography variant='h5' className='font-weight-normal'>
														{course?.description.substring(0, 150)}...
													</Typography>
												) : (
													<Typography variant='h5' className='font-weight-normal'>
														{course?.description}
													</Typography>
												)}
											</Box>
											<Box className='content-bottom'>
												{/* {course.content_duration > 0 && (
												<Box className='courses-status'>
													<Typography variant='h6'>
														<>
															<TimeIcon />
															{course.content_duration ? `${timeFormat(course.content_duration)} mins` : ''}
														</>
													</Typography>
												</Box>
											)} */}
												<OKRButton
													handleClick={(e) => handleViewCourse(e, course?.redirect_link)}
													className='btn-link view-course-btn'
													icon={<BackArrow />}
													text={t('viewCourse')}
												/>
											</Box>
										</Box>
									</Box>
								</Box>
							))}
							{/* {recommendedCourses && recommendedCourses.length === 1 && (
								<Box className='slider-row coming-soon-row'>
									<Box className='slider-row-inner'>
										<Box className='courses-img no-course-img'>
											<Box className='no-img-content'>
												<GreyLogo />
												<Typography variant='h6'>{t('moreAacademiesText')}</Typography>
											</Box>
										</Box>
										<Box className='courses-content'>
											<Box className='content-top'>
												<Typography variant='h4'>
													<Skeleton animation={false} width='40%'>
														<Typography>.</Typography>
													</Skeleton>
												</Typography>
												<Skeleton animation={false} width='100%'>
													<Typography>.</Typography>
												</Skeleton>
												<Skeleton animation={false} width='100%'>
													<Typography>.</Typography>
												</Skeleton>
												<Box className='last-content'>
													<Skeleton animation={false} width='20%'>
														<Typography>.</Typography>
													</Skeleton>
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							)} */}
						</Slider>
					) : (
						<Box className='slider-row coming-soon-row'>
							<Box className='slider-row-inner'>
								<Box className='user-no-record' textAlign='center'>
									<Typography variant='h4'>{t('noAcademiesMainHead')}</Typography>
									<Typography variant='h5' className='font-weight-normal'>
										{t('noAcademiesSubHead')}
									</Typography>
									<AnimatedIcon
										className=''
										width='200'
										type='image/svg+xml'
										alt='No Academies '
										imagePath={noAcademiesImg}
									/>
								</Box>
								{/* <Box className='courses-img no-course-img'>
									<Box className='no-img-content'>
										<GreyLogo />
										<Typography variant='h6'>{t('moreAacademiesText')}</Typography>
									</Box>
								</Box>
								<Box className='courses-content'>
									<Box className='content-top'>
										<Typography variant='h4'>
											<Skeleton animation={false} width='40%'>
												<Typography>.</Typography>
											</Skeleton>
										</Typography>
										<Skeleton animation={false} width='100%'>
											<Typography>.</Typography>
										</Skeleton>
										<Skeleton animation={false} width='100%'>
											<Typography>.</Typography>
										</Skeleton>
										<Box className='last-content'>
											<Skeleton animation={false} width='20%'>
												<Typography>.</Typography>
											</Skeleton>
										</Box>
									</Box>
								</Box> */}
							</Box>
						</Box>
					)}
				</Box>
			)}
		</>
	);
};
