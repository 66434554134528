import React, { useState, useEffect } from 'react';
import {
	Box,
	ClickAwayListener,
	ExpansionPanel,
	ExpansionPanelDetails,
	ExpansionPanelSummary,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { useTranslation } from 'react-i18next';
import { DownFilledArrow } from '../../config/svg/ArrowSvg';
import { CycleIcon } from '../../config/svg/CommonSvg';
import { RightArrowIcon } from '../../config/svg/GlobalSvg';
import { Enums } from '../../config/enums';
import { CycleDropdownData } from './CycleDropdownData';

export const CycleDropdown: React.FC<any> = (props) => {
	const {
		allCycleDetails,
		handleCycleChange,
		currentCycleId,
		currentSelectedCycleYear,
		cycleDuration,
		selectedCycleDetails,
		currentCycleYear,
		isCurrentCycle,
		isCycleDisable,
		resToUseInCalendar,
		editModee,
		isSoObj,
		callingFrom = ''
	} = props;
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState<Boolean>(false);
	const [open, setOpen] = React.useState<Boolean>(false);
	const [selectedCycle, setSelectedCycle] = useState<any>({
		organisationCycleId: currentCycleId,
		currentSelectedCycleYear,
		cycleDuration,
	});
	const [notsource, setNotSource] = useState<boolean>(false);

	useEffect(() => {
		if (editModee) {
			const okrSource = resToUseInCalendar?.source !== 0;
			setNotSource(okrSource);
		}
	}, [resToUseInCalendar]);

	const handleChange = (panel: any) => (event: any, isExpanded: any) => {
		setExpanded(isExpanded ? panel : false);
	};
	const handleClick = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		!notsource && setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const redirectTo = (id: any, quarterData: any, year: any) => {
		handleCycleChange(id, quarterData, year);
		setOpen(false);
		setExpanded(false);
		setSelectedCycle({
			organisationCycleId: quarterData?.organisationCycleId || currentCycleId,
			currentCycleYear: year || currentSelectedCycleYear,
			cycleDuration: quarterData?.cycleDuration || cycleDuration,
		});
	};

	return (
		<Box className='cycle-dropdown'>
			<OKRButton
				title={
					notsource
						? t('contributorCannot')
						: parseInt(selectedCycleDetails?.organisationCycleId) > parseInt(isCurrentCycle?.organisationCycleId)
						? `${t('futureCycle')}`
						: `${t('currentCycle')}`
				}
				text={`${t('cycle')}: ${
					selectedCycleDetails
						? `${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year || currentSelectedCycleYear}`
						: ''
				}`}
				className={`cycle-dropdown-btn ${
					parseInt(selectedCycleDetails?.organisationCycleId) > parseInt(isCurrentCycle?.organisationCycleId) &&
					!isCycleDisable
						? 'not-current-cycle'
						: ''
				} ${open ? 'open' : ''}`}
				id='cycle-dropdown-btn'
				icon={!isCycleDisable ? !notsource && <DownFilledArrow /> : <></>}
				handleClick={handleClick}
				disabled={isCycleDisable}
				disableRipple={notsource}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='tenure-list' id='cycle-dropdown-wrapper'>
						{/* <ExpansionPanel key={`cycle-panel-`} onChange={handleChange('panel1')} id={`cycle-panel-`}>
							<ExpansionPanelSummary
								expandIcon={<RightArrowIcon />}
								aria-controls='panel1a-content'
								id='cycle-panel-header'
							>
								<Typography>Year 2022</Typography>
							</ExpansionPanelSummary>
							<ExpansionPanelDetails>
								<List component='nav' aria-label={t('HeaderTenureNavigation')}>
									<ListItem button key={`cycle-list-item`} id={`cycle-list-item`}>
										<ListItemText
											// className={'active'}
											primary={`Q2 2022`}
										/>
										<Typography variant='subtitle2' className='current-cycle-tag'>
											{t('currentCycle')}
										</Typography>
									</ListItem>
								</List>
							</ExpansionPanelDetails>
						</ExpansionPanel> */}
						<CycleDropdownData
							allCycleDetails={allCycleDetails}
							currentCycleId={currentCycleId}
							isSoObj={true}
							handleChange={handleChange}
							redirectTo={redirectTo}
							selectedCycle={selectedCycle}
							callingFrom={callingFrom}
						/>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
