import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import UnauthorizedAccess from '../../../components/UnauthorizedAccess';
import { getEmployeePermission } from '../../../action/roles';

const mapStateToProps = (state) => {
	return {
		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getEmployeePermission,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UnauthorizedAccess));
