import React, { useState } from 'react';
import { Avatar, Box, ClickAwayListener, List, ListItem, Popper, Tooltip, Typography } from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
// import { HappyEmoji, NuetralEmoji, SadEmoji, NotSetEmoji } from '../../config/svg/CommonSvg';
import { AvatarGroup } from '@material-ui/lab';
import { OKRButton } from './OKRButton';
import { confidenceIcon, getOkrProgressDetails, getUserName } from '../../config/utils';
import { Enums } from '../../config/enums';
interface KrTrendContributorsProps {
	ownerData: any;
	contributorList: any;
	max: number;
	confidences: any;
	dueDate: any;
}

export const KrTrendContributors: React.FC<KrTrendContributorsProps> = (props) => {
	const { contributorList, max, confidences, ownerData, dueDate } = props;

	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};

	const getConfidenceIcon = (rec: any) => {
		const myConfidence =
			confidences && confidences.length
				? confidences.find((item: any) => item?.confidenceValue === rec?.confidenceId)
				: { confidenceValue: 0 };
		return confidenceIcon(myConfidence?.confidenceValue || 0);
	};

	const getContributorProgressColor = (progress: any) => {
		const { color } = getOkrProgressDetails({
			value: progress,
			dueDate: dueDate,
		});

		return color || '';
	};

	const HoverListItem = ({ rec, isOwner }: any) => {
		return (
			<ListItem className={isOwner ? 'owner' : ''}>
				<Box className='user-info'>
					<Box className='user-img'>
						<Avatar
							className={contributorList.length <= max ? 'removeBlue' : ''}
							key={'user-icon'}
							alt={'user-icon'}
							src={rec?.imagePath || ''}
						>
							{getUserName({ fullName: rec?.fullName || '', firstName: '', lastName: '' })}
						</Avatar>
					</Box>
					<Box className='user-info-details'>
						{rec?.fullName && rec?.fullName.length > 16 ? (
							<Tooltip arrow title={rec?.fullName} aria-label='Full Name'>
								<Typography variant='h5'>{rec?.fullName.substring(0, 16)}...</Typography>
							</Tooltip>
						) : (
							<Typography variant='h5'>{rec?.fullName || ''}</Typography>
						)}
					</Box>
				</Box>
				<Box className='col'>
					<Typography
						variant='h5'
						style={{ color: getContributorProgressColor(rec?.progress) || '' }}
					>{`${rec?.progress}%`}</Typography>
					<OKRButton icon={getConfidenceIcon(rec)} />
				</Box>
			</ListItem>
		);
	};

	return (
		<Box className='trend-contributors'>
			<Box
				display='flex'
				className='trend-contributors-group'
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup='true'
				onMouseOver={handleOpen}
				onMouseLeave={handleClose}
			>
				{ownerData && ownerData?.employeeId ? (
					<Avatar
						className={'contributors-owner'}
						key={'owner-user-icon'}
						alt={'owner-user-icon'}
						src={ownerData?.imagePath || ''}
					>
						{getUserName({ fullName: ownerData?.fullName || '', firstName: '', lastName: '' })}
					</Avatar>
				) : (
					<></>
				)}
				{/* /${assignedUserDetails.length - (max - 1) > 9 ? 'not-two-digit' : ''} */}
				{/* removeBlue */}
				<AvatarGroup
					max={max || Enums.THREE}
					className={`contributors-group-list ${contributorList.length - (max - Enums.ONE) > 9 ? 'not-two-digit' : ''}`}
				>
					{contributorList && contributorList.length ? (
						contributorList.map((rec: any) => (
							<Avatar
								className={contributorList.length <= max ? 'removeBlue' : ''}
								key={'user-icon'}
								alt={'user-icon'}
								src={rec?.imagePath || ''}
							>
								{getUserName({ fullName: rec?.fullName || '', firstName: '', lastName: '' })}
							</Avatar>
						))
					) : (
						<></>
					)}
				</AvatarGroup>
				<Popper
					open={open}
					anchorEl={anchorEl}
					transition
					container={anchorEl}
					className={'trend-contributors-popper'}
					placement='bottom-start'
				>
					<ClickAwayListener onClickAway={handleClose}>
						<List disablePadding>
							{ownerData && ownerData?.employeeId ? <HoverListItem rec={ownerData} isOwner={true} /> : <></>}
							{contributorList && contributorList.length ? (
								contributorList.map((rec: any) => <HoverListItem rec={rec} isOwner={false} />)
							) : (
								<></>
							)}
						</List>
					</ClickAwayListener>
				</Popper>
			</Box>
		</Box>
	);
};
