import React, { Fragment, useEffect } from 'react';
import { Box, List, Link, ListItem, Typography, Avatar } from '@material-ui/core';
import { getFullName, getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';
import { useTranslation } from 'react-i18next';
import { ALIGNMENTMAPS, DASHBOARD, HOME, USER_VIEW } from '../../config/app-url';
import { PersonalFeedbackAction } from '../PersonalFeedback/PersonalFeedbackAction';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { currentDashboardTab, currentDashboardTabForSO, updateCurrentCycleId } from '../../action/common';

export const PeopleHeader: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { currentUser, listOkrResult, listOkrStatusResult, isTeamHeader, userDetail } = props;
	const history: any = useHistory();
	const location: any = useLocation();
	const dispatch = useDispatch();
	const { okrStatusDetails } = listOkrStatusResult;
	const goBack = () => {
		removeLocalStorageItem('currentUser');
		removeLocalStorageItem('currentOrganisation');
		if (location?.state?.route) {
			dispatch(currentDashboardTabForSO(false));
			dispatch(currentDashboardTab(0));
			props.history.push(location.state.route);
			props.updateRoute(location.state.route);
		} else {
			// props.updatedOrganizationList();
			props.resetOkr();
			const backUrl = JSON.parse(getLocalStorageItem('backUrl') || '{}');
			if (props.prevRoute && props.prevRoute !== USER_VIEW) {
				props.history.push(props.prevRoute);
				props.updateRoute(props.prevRoute);
			} else if (backUrl && backUrl.url === ALIGNMENTMAPS) {
				dispatch(currentDashboardTabForSO(false));
				props.currentDashboardTab(5);
				props.history.push(HOME);
				props.updateRoute(HOME);
			} else {
				let cycleObj = JSON.parse(JSON.stringify(props.cycleIdDetails));
				dispatch(updateCurrentCycleId(cycleObj));
				props.history.push(HOME);
				props.updateRoute(HOME);
			}
		}
	};
	const getStatusName = (code: any, statusName: any) => {
		return (
			<Fragment>
				<Typography variant='body2'>{typeof listOkrResult[code] !== 'object' && listOkrResult[code]}</Typography>
				<Typography>{statusName}</Typography>
			</Fragment>
		);
	};
	useEffect(() => {
		document.body.classList.remove('guideme-home');
	});

	return (
		<Fragment>
			<Box className='back-to-main back-to-main-people'>
				<Typography variant='body2'>
					<Link onClick={goBack} id='back-btn'>
						<BackArrow /> {isTeamHeader ? t('back') : t('back')}
					</Link>{' '}
					{t('watchingObjective', { name: currentUser.firstName })}
				</Typography>
			</Box>
			<Box className='view-people-info' id='people-info-header'>
				<Box className='view-people-details'>
					<Box className='view-people-info-wrap'>
						{currentUser.imagePath ? (
							<Avatar
								alt=''
								src={currentUser.imagePath}
								className='view-people-img'
								//onClick={handleAvatarOpen}
							/>
						) : (
							<Avatar
								className='view-people-img'
								style={{
									backgroundColor: getRandomBackgroundColor(currentUser.backGroundColorCode),
									color: getRandomColor(currentUser.colorCode),
								}}
							>
								{getUserName(currentUser)}
							</Avatar>
						)}
						<Box className='view-profile-user'>
							<Box className='profile-user-text'>
								<Typography variant='h4'>{getFullName(currentUser)}</Typography>

								<List className='view-profile-des'>
									<ListItem>{listOkrResult.designation}</ListItem>
									{/* <ListItem>{listOkrResult.department}</ListItem> */}
								</List>
							</Box>
							<List className='view-profiles-okrs'>
								<ListItem>
									{listOkrResult.avgScore}
									{t('percentScore')}
								</ListItem>
								<ListItem>
									{listOkrResult.okrCount} {t('objectives')}
								</ListItem>
								<ListItem>
									{listOkrResult.keyCount} {t('keyResults')}
								</ListItem>
							</List>
							<Box className='view-people-status '>
								<List className='view-profile-progress'>
									{okrStatusDetails &&
										okrStatusDetails
											.slice(0)
											.reverse()
											.map((item: any) => (
												<ListItem className={item.code}>{getStatusName(item.code, item.statusName)}</ListItem>
											))}
								</List>
							</Box>
						</Box>
					</Box>
					{isTeamHeader ? (
						<></>
					) : (
						<Box className='people-header-right'>
							{/* <PersonalFeedbackAction
								{...props}
								loggedInUser={!currentUser}
								userDetail={userDetail}
								listOkrResult={listOkrResult}
							/> */}
						</Box>
					)}
				</Box>
			</Box>

			{/* <Box className='view-people-info'>
				{currentUser.imagePath ? (
					<Avatar
						alt=''
						src={currentUser.imagePath}
						className='view-people-img'
						//onClick={handleAvatarOpen}
					/>
				) : (
					<Avatar
						className='view-people-img'
						style={{
							backgroundColor: getRandomBackgroundColor(currentUser.backGroundColorCode),
							color: getRandomColor(currentUser.colorCode),
						}}
					>
						{getUserName(currentUser)}
					</Avatar>
				)}

				<Box className='view-people-details'>
					<Box className='back-to-main'>
						<Typography variant='body2'>
							<Link onClick={goBack} id='back-btn'>
								<BackArrow /> {isTeamHeader ? t('backToOrgMap') : t('back')}
							</Link>{' '}
							{t('watchingObjective', { name: currentUser.firstName })}
						</Typography>
					</Box>
					<Box className='view-profile-user'>
						<Box className='profile-user-text'>
							<Typography variant='h4'>{getFullName(currentUser)}</Typography>
						</Box>
						<List>
							<ListItem>{listOkrResult.designation}</ListItem>
							<ListItem>{listOkrResult.department}</ListItem>
						</List>
					</Box>
					<List className='view-profiles-okrs'>
						<ListItem>
							{listOkrResult.avgScore}
							{t('percentScore')}
						</ListItem>
						<ListItem>
							{listOkrResult.okrCount} {t('objectives')}
						</ListItem>
						<ListItem>
							{listOkrResult.keyCount} {t('keyResults')}
						</ListItem>
					</List>
					<List className='view-profile-progress'>
						{okrStatusDetails &&
							okrStatusDetails
								.slice(0)
								.reverse()
								.map((item: any) => (
									<ListItem className={item.code}>{getStatusName(item.code, item.statusName)}</ListItem>
								))}
					</List>
				</Box>
			</Box> */}
		</Fragment>
	);
};
