import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Badge } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AlertInfoIcon } from '../../config/svg/CommonSvg';
import { useSelector } from 'react-redux';

export const EngagementTabPanel: React.FC<any> = (props: any) => {
	const { children, value, index, ...other } = props;
	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`engagement-tabpanel-${index}`}
			aria-labelledby={`engagement-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

EngagementTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `engagement-tab-${index}`,
		'aria-controls': `engagement-tabpanel-${index}`,
	};
}

export const EngagementTabs: React.FC<any> = (props) => {
	const { tabSelected = 0, handleTabChange } = props;
	const { t } = useTranslation();
	const { checkInData } = useSelector((state: any) => state.okrReducer);
	return (
		<Tabs className='okr-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Engagement Tabs'>
			{/* <Tab value={1} label={t('feedbackLabel')} {...a11yProps(1)} /> */}
			<Tab
				label={
					<>
						<Badge
							className='badge-icon'
							overlap='rectangular'
							badgeContent={checkInData?.isAlert && <AlertInfoIcon />}
						>
							{t('checkInsLabel')}
						</Badge>
					</>
				}
				value={2}
				// label={t('checkInsLabel')}
				{...a11yProps(2)}
			/>
			<Tab value={3} label={t('requestOnetoOne')} {...a11yProps(3)} />
		</Tabs>
	);
};
