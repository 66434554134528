import React, { useState, useEffect } from 'react';
import { Typography, Button } from '@material-ui/core';
import { RefreshIcon } from '../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';

const Header = (props) => {
	const [scrolled, setScrolled] = useState(false);

	const handleScroll = () => {
		const offset = window.scrollY;
		if (offset > 0) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};
	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
	});

	let x = ['navbar'];
	if (scrolled) {
		x.push('scrolled');
	}
	const { t } = useTranslation();
	return (
		<div className={x.join(' ')}>
			<div className='global-header'>
				<div className='global-head-info'>
					<div className='global-head-title'>
						<Typography variant='h2'>{t('insights')}</Typography>
					</div>
				</div>
				<div className='global-head-action'>
					<Button className='btn-circle-white' onClick={props.callApis}>
						<RefreshIcon />
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Header;
