import React from 'react';
import ReactDOM from 'react-dom';
import d3 from 'd3';
// import { wrapText, helpers, covertImageToBase64 } from '../utils';
import wrapText from '../utils/wrapText';
import { getCursorForNode } from '../utils/helpers'; //getTextForTitle
// import covertImageToBase64 from '../utils/covertImageToBase64';
import renderLines from './renderLines';
import exportOrgChartImage from './exportOrgChartImage';
import exportOrgChartPdf from './exportOrgChartPdf';
import onClick from './onClick';
// import { iconLink, profileLink } from './components/iconLink';
import supervisorIcon from './components/supervisorIcon';
// import profileIcon from '../../assets/profileIcon.svg';
// import teamIcon from '../../assets/teamIcon.svg';
// import userIcon from '../../assets/userIcon.svg';
import plusIcon from '../../assets/plusIcon.svg';
import minusIcon from '../../assets/minusIcon.svg';
import { ChatTile } from '../ChatTile';
// import { id } from 'date-fns/locale';

const CHART_NODE_CLASS = 'org-chart-node';
// const PERSON_LINK_CLASS = 'org-chart-person-link';
const PERSON_NAME_CLASS = 'org-chart-person-name';
const PERSON_TITLE_CLASS = 'org-chart-person-title';
const PERSON_HIGHLIGHT = 'org-chart-person-highlight';
// const PERSON_REPORTS_CLASS = 'org-chart-person-reports';

export default function render(config) {
	const {
		svgroot,
		nodePaddingX,
		nameColor,
		titleColor,
		reportsColor,
		onPersonLinkClick,
		loadImage,
		elemWidth,
		margin,
		svg,
		tree,
		animationDuration,
		nodeWidth,
		nodeHeight,
		nodePaddingY,
		nodeBorderRadius,
		backgroundColor,
		borderColor,
		avatarWidth,
		lineDepthY,
		treeData,
		sourceNode,
		downloadImageId,
		downloadPdfId,
		onConfigChange,
		handleViewOrganization,
		isOrgChart,
	} = config;

	// Compute the new tree layout.
	const nodes = tree.nodes(treeData).reverse();
	const links = tree.links(nodes);
	config.links = links;
	config.nodes = nodes;

	// Normalize for fixed-depth.
	nodes.forEach(function (d) {
		d.y = d.depth * lineDepthY;
	});

	// Update the nodes
	const node = svg.selectAll('g.' + CHART_NODE_CLASS).data(
		nodes.filter((d) => d.id),
		(d) => d.id
	);

	const parentNode = sourceNode || treeData;

	svg.selectAll('#supervisorIcon').remove();

	supervisorIcon({
		svg: svg,
		config,
		treeData,
		x: 70,
		y: -24,
	});

	// Enter any new nodes at the parent's previous position.
	const nodeEnter = node
		.enter()
		.insert('g')
		.attr('class', CHART_NODE_CLASS)
		.attr('transform', `translate(${parentNode.x0}, ${parentNode.y0})`);

	// Person Card Shadow
	nodeEnter
		.append('rect')
		.attr('width', nodeWidth)
		.attr('height', nodeHeight)
		.attr('fill', backgroundColor)
		.attr('stroke', borderColor)
		.attr('stroke-width', '2')
		.attr('rx', nodeBorderRadius)
		.attr('ry', nodeBorderRadius)
		.attr('fill-opacity', 0.05)
		.attr('stroke-opacity', 1);
	//.attr('filter', 'url(#boxShadow)');

	// Person Card Container
	nodeEnter
		.append('rect')
		.attr('class', (d) => (d.isHighlight ? `${PERSON_HIGHLIGHT} box` : 'box'))
		.attr('width', nodeWidth)
		.attr('height', nodeHeight)
		.attr('id', (d) => d.id)
		.attr('fill', backgroundColor)
		.attr('stroke', borderColor)
		.attr('rx', nodeBorderRadius)
		.attr('ry', nodeBorderRadius)
		.style('cursor', getCursorForNode);

	// console.log('node ==> ', node, tree, nodes);

	nodeEnter.append('g').attr('id', (d) => (d.id ? `my-custom-chart_${d.id}` : 'my-custom-class'));

	const namePos = {
		x: nodeWidth / 2,
		y: nodePaddingY * 1.8 + avatarWidth,
	};

	const avatarPos = {
		x: nodeWidth / 2 - avatarWidth / 2,
		y: nodePaddingY / 2,
	};

	nodes.forEach(function (d) {
		document.getElementById(`my-custom-chart_${d.id}`) &&
			ReactDOM.render(
				<ChatTile
					nodeWidth={nodeWidth}
					nodeHeight={nodeHeight}
					record={d}
					handleViewOrganization={handleViewOrganization}
					isOrgChart={isOrgChart}
				/>,
				document.getElementById(`my-custom-chart_${d.id}`)
			);
	});

	const heightForTitle = 10; // getHeightForText(d.person.title)
	nodeEnter
		.append('image')
		.attr('width', 18)
		.attr('height', 18)
		.attr('id', (d) => 'expandImg_' + d.id)
		.attr('x', nodeWidth / 2 - 9)
		.attr('y', namePos.y + nodePaddingY - heightForTitle)
		.attr('stroke', borderColor)
		.attr('href', (d) => (d?.children ? minusIcon : plusIcon))
		.attr('src', plusIcon)
		.style('cursor', 'pointer')
		.style('display', (d) => (d?.hasChild ? '' : 'none'))
		.on('click', onClick(config));

	// Transition nodes to their new position.
	const nodeUpdate = node
		.transition()
		.duration(animationDuration)
		.attr('transform', (d) => `translate(${d.x},${d.y})`);

	nodeUpdate.select('rect.box').attr('fill', backgroundColor).attr('stroke', borderColor);

	// Transition exiting nodes to the parent's new position.
	const nodeExit = node
		.exit()
		.transition()
		.duration(animationDuration)
		.attr('transform', (d) => `translate(${parentNode.x},${parentNode.y})`)
		.remove();

	// Update the links
	const link = svg.selectAll('path.link').data(links, (d) => d.target.id);

	// Wrap the title texts
	const wrapWidth = 124;
	svg.selectAll('text.unedited.' + PERSON_NAME_CLASS).call(wrapText, wrapWidth);
	svg.selectAll('text.unedited.' + PERSON_TITLE_CLASS).call(wrapText, wrapWidth);

	// Render lines connecting nodes
	renderLines(config);

	// Stash the old positions for transition.
	nodes.forEach(function (d) {
		d.x0 = d.x;
		d.y0 = d.y;
	});

	let nodeLeftX = -70;
	let nodeRightX = 70;
	let nodeY = 200;
	nodes.map((d) => {
		nodeLeftX = d.x < nodeLeftX ? d.x : nodeLeftX;
		nodeRightX = d.x > nodeRightX ? d.x : nodeRightX;
		nodeY = d.y > nodeY ? d.y : nodeY;
	});

	config.nodeRightX = nodeRightX;
	config.nodeY = nodeY;
	config.nodeLeftX = nodeLeftX * -1;

	d3.select(downloadImageId).on('click', function () {
		exportOrgChartImage(config);
	});

	d3.select(downloadPdfId).on('click', function () {
		exportOrgChartPdf(config);
	});
	onConfigChange(config);
}
// module.exports = render;
