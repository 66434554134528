import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Tab, Tabs } from '@material-ui/core';
import { GoalCycleIcon, GoalFormIcon, GoalWorkflowIcon } from '../../../../config/svg/MyGoalSvg';
import { getPerformanceGoalTabCount } from '../../../../action/adminSettings';
import { Enums } from '../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';

export const AlignGoalsSettingsTab: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { a11yProps, tabSelected, handleTabChange } = props;

	const { goalTabCount } = useSelector((state: any) => state.adminSettingReducer);

	useEffect(() => {
		dispatch(getPerformanceGoalTabCount());
	}, []);

	const handleTabClick = (event: any, newValue: Number) => {
		event.preventDefault();
		event.stopPropagation();
		handleTabChange(event, newValue);
	};

	return (
		<>
			<Tabs className='okr-tab-button align-setting-tab-button ' value={tabSelected} aria-label='Align Tabs'>
				<Tab
					icon={<GoalCycleIcon />}
					label={`${t('goalCycleLabel')} (${goalTabCount?.goalCycleCount || 0})`}
					onClick={(e) => handleTabClick(e, 0)}
					value={0}
					{...a11yProps(0)}
				/>
				<Tab
					icon={<GoalFormIcon />}
					label={`${t('goalFormLabel')} (${goalTabCount?.goalFormCount || 0})`}
					onClick={(e) => handleTabClick(e, 1)}
					value={1}
					{...a11yProps(1)}
				/>
				<Tab
					icon={<GoalWorkflowIcon />}
					label={`${t('workflowReversal')}`}
					onClick={(e) => handleTabClick(e, 2)}
					value={2}
					{...a11yProps(2)}
				/>
			</Tabs>
		</>
	);
};
