import React, { useEffect } from 'react';
import { Box, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { checkSupportPermission, getUserDetails } from '../../config/utils';
import { checkDetailsDashboard } from '../../action/myGoal';
import { Enums } from '../../config/enums';
import { CheckinsColorIcon } from '../../config/svg/AssessmentSvg';
import { supportModuleAlignOkr } from '../Admin/AdminConstant';

export const CheckinWidget: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const { checkInData, handleCardClick } = props;
	return (
		<Box className='checkin-widget box-rectangle-info' onClick={(e) => handleCardClick(e, 'checkins')}>
			{checkInData && checkInData?.checkInStatus ? (
				<>
					<Box className='box-head'>
						<Box className='box-head-title'>
							<CheckinsColorIcon />
							<Typography variant='h2'>
								{checkInData.isAlert && !checkInData.isCheckInSubmitted ? (
									<>
										<span className='count-days'>{checkInData.remaingDaysLeft}</span>
										{checkInData.remaingDaysLeft > 1 ? <>{t('daysLeft')}</> : <>{t('dayLeft')}</>}
									</>
								) : checkInData.isCheckInSubmitted ? (
									<>{t('checkedInText')}</>
								) : (
									<>
										{checkInData.remaingDaysLeft} {t('daysLeft')}
									</>
								)}
							</Typography>
						</Box>
						{/* {checkInData.isCheckInSubmitted ? (
							<Typography variant='h5' className='font-weight-normal'>
								Check-in submitted for the current week. {checkInData.remaingDaysLeft}
								{` ${checkInData.remaingDaysLeft > 1 ? 'days' : 'day'}`} remaining to edit your answers
							</Typography>
						) : checkInData.isAlert ? (
							<Typography variant='h5' className='font-weight-normal'>
								{t('fillOutCheckin')}
							</Typography>
						) : (
							<Typography variant='h5' className='font-weight-normal'>
								{t('fillOutCheckin')}
							</Typography>
						)} */}
					</Box>

					<Box className='box-content'>
						{/* <Typography variant='h6'>{t('statusCheckins')}</Typography> */}
						<List disablePadding>
							{checkSupportPermission(supportModuleAlignOkr) ? (
								checkInData &&
								checkInData.checkInStatus &&
								checkInData.checkInStatus.length > 0 && (
									<>
										{checkInData.checkInStatus.map((item: any, index: number) => (
											<ListItem>
												<Typography variant='body2'>
													{item.checkInStatusId === Enums.STATUS_CHECKED_ID ? (
														<>
															<span className='week-status checked-in'></span> {t('checkedInLabel')}
														</>
													) : item.checkInStatusId === Enums.NOT_YET_CHECKED_IN ? (
														<>
															<span className='week-status not-yet-checked'></span> {t('notYetCheckedInLabel')}
														</>
													) : item.checkInStatusId === Enums.CHECKED_IN_MISSED ? (
														<>
															<span className='week-status checked-in-missed'></span> {t('checkedInMissedLabel')}
														</>
													) : (
														<></>
													)}
												</Typography>
												<Typography variant='h6' className='font-weight-normal'>
													{item.displayDate}
												</Typography>
											</ListItem>
										))}
									</>
								)
							) : (
								<ListItem>
									<Typography variant='body2'>
										{checkInData.checkInStatus[0].checkInStatusId === Enums.STATUS_CHECKED_ID ? (
											<>
												<span className='week-status checked-in'></span> {t('checkedInLabel')}
											</>
										) : checkInData.checkInStatus[0].checkInStatusId === Enums.NOT_YET_CHECKED_IN ? (
											<>
												<span className='week-status not-yet-checked'></span> {t('notYetCheckedInLabel')}
											</>
										) : checkInData.checkInStatus[0].checkInStatusId === Enums.CHECKED_IN_MISSED ? (
											<>
												<span className='week-status checked-in-missed'></span> {t('checkedInMissedLabel')}
											</>
										) : (
											<></>
										)}
									</Typography>
									<Typography variant='h6' className='font-weight-normal'>
										{checkInData.checkInStatus[0].displayDate}
									</Typography>
								</ListItem>
							)}
						</List>
					</Box>
				</>
			) : (
				<Box className='widgetLoading'>{t('loading')}</Box>
			)}
		</Box>
	);
};
