import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import noRecordFound from '../../../images/search-placeholder.svg';
import { AnimatedIcon } from './AnimatedIcon';

export const NoSearchRecord: React.FC<any> = (props) => {
	const { selectForm = false, noRecordsTitle = '', noRecordsMessage = '' } = props;
	const { t } = useTranslation();

	return (
		<Box className='search-no-record-global'>
			<Box className='no-record-message'>
				<Typography variant='h2' component='h2'>
					{!noRecordsTitle || noRecordsTitle == '' ? (selectForm ? t('noSearch') : t('CommonOOPS')) : noRecordsTitle}
				</Typography>
				<Typography>
					{!noRecordsMessage || noRecordsMessage == ''
						? selectForm
							? t('differentKeyword')
							: t('globalSearchNoRecord')
						: noRecordsMessage}
				</Typography>

				<AnimatedIcon
					className=''
					width='230'
					type='image/svg+xml'
					alt={t('globalSearchNoRecord')}
					imagePath={noRecordFound}
				/>
			</Box>
		</Box>
	);
};
