import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OrganizationChart from './OrganizationChart';
import { AddTeam } from '../Organizations/AddTeam/AddTeam';

export const OrgChartWrapper = (props) => {
	const { getOrgList, tabSelected, fetchOrgFailed, showLoader, isOrgChart, openAddTeamDrawer, handleMenuClick } = props;

	const { t } = useTranslation();

	const [organizationList, setOrganizationList] = useState([]);

	useEffect(() => {
		if (tabSelected === 2) {
			showLoader(true);
			fetchOrganization();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tabSelected]);

	useEffect(() => {
		if (tabSelected === 2) {
			const myElement = document.getElementById('okr-app');
			myElement.classList.add('org-chart-main');
			return () => {
				myElement.classList.remove('org-chart-main');
			};
		}
	}, [tabSelected]);

	const fetchOrganization = () => {
		getOrgList(isOrgChart)
			.then((response) => {
				if (response.status === 200) {
					showLoader(false);
					setOrganizationList([]);
					if (response.data && response.data.entityList) {
						const orgChartList = response.data.entityList;
						const updatedList =
							orgChartList && orgChartList.length
								? orgChartList.map((rec, index) => {
										rec.id = index + 1;
										return rec;
								  })
								: orgChartList;
						setOrganizationList(updatedList);
					} else {
						fetchOrgFailed('error');
					}
				} else {
					showLoader(false);
				}
			})
			.catch((error) => {
				showLoader(false);
				fetchOrgFailed(error);
			});
	};

	return (
		<>
			{organizationList && organizationList.length ? (
				<OrganizationChart {...props} tabSelected={tabSelected} organizationList={organizationList} t={t} />
			) : (
				<></>
			)}
			{organizationList && organizationList.length > 0 && (
				<div className='scroll-drag-info'>
					<Typography variant='body2'>{t('scrollZoomLabel')}</Typography>
					<Typography variant='body2'>{t('dragToMoveView')}</Typography>
				</div>
			)}
			{openAddTeamDrawer && (
				<AddTeam
					{...props}
					fetchOrganization={fetchOrganization}
					handleMenuClick={handleMenuClick}
					openAddTeamDrawer={openAddTeamDrawer}
					refreshList={tabSelected === 2}
					fetchOrgList={fetchOrganization}
				/>
			)}
		</>
	);
};
