import React, { useState } from 'react';
import { Box, ClickAwayListener, Paper, Popper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { Enums } from '../../config/enums';
import { getPriorityClass, getPriorityIcon, getPriorityName } from '../../config/utils';
import { globalTaskCreateLinkUnSaveCheck, globalTaskCreatePopupDetails } from '../../action/globalTask';
import { useDispatch } from 'react-redux';

export const TaskPriorityPopper: React.FC<any> = (props: any) => {
	const {
		handlePriorityChange,
		formDetails,
		uniqueId,
		isActionable,
		isCreateTask,
		setIsCreateLinkPopupOpen,
		callingFrom,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = useState(false);

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
		if (isCreateTask || callingFrom === 'OneOnOneTask') {
			setIsCreateLinkPopupOpen(true);
			dispatch(globalTaskCreateLinkUnSaveCheck(true));
			dispatch(globalTaskCreatePopupDetails('priorityPopup'));
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
		if (isCreateTask || callingFrom === 'OneOnOneTask') {
			setIsCreateLinkPopupOpen(false);
			dispatch(globalTaskCreateLinkUnSaveCheck(false));
			dispatch(globalTaskCreatePopupDetails(null));
		}
	};
	const getPriorityActive = (priority: number) => {
		if (priority === formDetails?.priority) {
			return 'active';
		} else {
			return '';
		}
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
			}
		}, 100);
	};

	return (
		<>
			{formDetails?.priority === Enums.ONE ? (
				<OKRButton
					className={`blue-btn ${getPriorityClass(formDetails?.priority)} ${isActionable ? '' : 'disable-priority'}`}
					icon={getPriorityIcon(formDetails?.priority)}
					title={`${getPriorityName(t, formDetails?.priority)}`}
					id={uniqueId ? `${uniqueId}_priority_btn` : 'priority_btn'}
					disabled={isCreateTask ? false : !formDetails?.isEditEnable}
					handleClick={(e: any) => {
						if (isActionable) {
							handleClick(e);
							handlePopperScroll(e, 'priorityPopup');
						}
					}}
					buttonType={'disableButtonTooltip'}
					wrapClassName={'disable-button-wrap'}
				/>
			) : (
				<Box className='selected-item selected-priority'>
					<OKRButton
						className={`blue-btn ${getPriorityClass(formDetails?.priority)}`}
						icon={getPriorityIcon(formDetails?.priority)}
						title={`${getPriorityName(t, formDetails?.priority)}`}
						id={uniqueId ? `${uniqueId}_'priority_btn'` : 'priority_btn'}
						disabled={isCreateTask ? false : !formDetails?.isEditEnable}
						handleClick={(e: any) => {
							if (isActionable) {
								handleClick(e);
								handlePopperScroll(e, 'priorityPopup');
							}
						}}
						buttonType={'disableButtonTooltip'}
						wrapClassName={'disable-button-wrap'}
					/>
					{/* <OKRButton
						className='cancel-btn'
						icon={<CancelIcon />}
						handleClick={(e: any) => handlePriorityChange(e, Enums.ONE)}
					/> */}
				</Box>
			)}

			<Popper
				className='task-quick-link-popup priority-popup'
				open={open}
				anchorEl={anchorEl}
				placement='bottom-start'
				modifiers={{
					flip: {
						enabled: false,
					},
				}}
				id={'priorityPopup'}
				container={callingFrom === 'GlobalTask' ? document.body : document.getElementById('kr-task-drawer')}
			>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<Box className='priority-popup-inner'>
							<Typography variant='h6' className=''>
								Priority
							</Typography>
							<Box className='priority-type'>
								<OKRButton
									className={`${getPriorityActive(Enums.THREE)} ${getPriorityClass(Enums.THREE)}`}
									handleClick={(e: any) => {
										handleClose();
										handlePriorityChange(e, Enums.THREE);
									}}
									text={getPriorityName(t, Enums.THREE)}
									icon={getPriorityIcon(Enums.THREE)}
									id={`btn_${getPriorityName(t, Enums.THREE)}`}
								/>
								<OKRButton
									className={`${getPriorityActive(Enums.TWO)} ${getPriorityClass(Enums.TWO)}`}
									handleClick={(e: any) => {
										handleClose();
										handlePriorityChange(e, Enums.TWO);
									}}
									text={getPriorityName(t, Enums.TWO)}
									icon={getPriorityIcon(Enums.TWO)}
									id={`btn_${getPriorityName(t, Enums.TWO)}`}
								/>
								<OKRButton
									className={`${getPriorityActive(Enums.ONE)} ${getPriorityClass(Enums.ONE)}`}
									handleClick={(e: any) => {
										handleClose();
										handlePriorityChange(e, Enums.ONE);
									}}
									text={getPriorityName(t, Enums.ONE)}
									icon={getPriorityIcon(Enums.ONE)}
									id={`btn_${getPriorityName(t, Enums.ONE)}`}
								/>
							</Box>
						</Box>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
};
