import React, { Fragment, useEffect, useState } from 'react';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchConversationGoalCompleted } from '../../../action/perform';
import { GoalSettingsDrawer } from './GoalSettingsDrawer';

export const GoalSettings: React.FC<any> = (props) => {
	const { t, goalSettingsOpen, handleGoalSettingsDrawerClose, selectTab, performanceGoalsList } = props;
	const [tabSelected, setTabSelected] = useState<Number>(0);
	const [loader, setLoader] = useState<boolean>(false);
	const handleTabChange = (event: any, newValue: Number) => {
		setTabSelected(newValue);
	};
	const dispatch = useDispatch();

	useEffect(() => {
		if (selectTab === 'attachments') {
			setTabSelected(0);
		} else if (selectTab === 'conversation') {
			dispatch(
				fetchConversationGoalCompleted({
					entity: {
						commentDetailResponses: [],
					},
					pagingInfo: {},
				})
			);
			setTabSelected(1);
		}
	}, [selectTab]);
	return (
		<Fragment>
			<OKRDrawer
				open={goalSettingsOpen.open}
				drawerClassName='main-drawer-panel main-drawer-no-footer category-settings-drawer'
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={<>{t('goalLabel')} </>}
				children={
					<Box className='drawer-inner-content'>
						<GoalSettingsDrawer
							{...props}
							kr={{
								...goalSettingsOpen.data,
								goalSourceId: goalSettingsOpen.data.performanceGoalLinkedFormCategoryId,
							}}
							t={t}
							handleTabChange={handleTabChange}
							tabSelected={tabSelected}
							loader={loader}
							setLoader={setLoader}
						/>
					</Box>
				}
				moreButton={<></>}
				handleDrawerClose={(event) => handleGoalSettingsDrawerClose(event)}
				handleSaveClick={(event) => handleGoalSettingsDrawerClose(event)}
				handleCancelClick={(event) => handleGoalSettingsDrawerClose(event)}
				hideDefaultButton={false}
				loader={loader}
				isFooterVisible={false}
				isSaveButtonDisabled={false}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			/>
		</Fragment>
	);
};
