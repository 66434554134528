import React, { Fragment, useState } from 'react';
import { Avatar, Badge, Box, Typography } from '@material-ui/core';
import { AddIconSvg, ProfileIcon } from '../../config/svg/CommonSvg';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { OKRButton } from './OKRButton';
import { AvatarGroup } from '@material-ui/lab';
import { ContributorsListPopover } from './ContributorsListPopover';
import { checkPermission } from '../../config/rolePermission';
import { assignOkr } from '../Admin/AdminConstant';

interface ContributorDetailsProps {
	employeeId: String | Number;
	firstName?: String;
	lastName?: String;
	imagePath?: string;
	image?: string;
	fullName?: String;
	isSource?: boolean;
	teamId?: String | Number;
	krStatusId?: String | Number;
}
interface UserAvatarGroupProps {
	contributorDetails?: Array<ContributorDetailsProps>;
	handleAddUserClick?: (event: any) => void;
	addButtonClassName?: string;
	avatarClassName?: string;
	avatarGroupClassName?: string;
	isButtonVisible?: Boolean;
	isButtonDisable?: boolean;
	max: number;
	loginUserAvatar?: any;
	showLoginUserAvatar?: any;
	currentUser?: any;
	contributorDetailsType?: boolean;
	isOnHoverVisible?: Boolean;
	poperContainer?: Boolean;
	contributorClassName?: any;
	diagramInstance?: any;
	alignmentMapTab?: any;
	virtualAlignmentList?: any;
	virtualAlignmentListPopUp?: any;
	getVirtuals?: any;
	showEditHeader?: boolean;
	onEditHeaderclick?: (event: any) => void;
	hideScore?: boolean;
	hideLinks?: boolean;
	doNotAllowUserSelection?: boolean;
	showTeamDetails?: boolean;
	isCoach?: boolean;
	t?: (event: any) => void;
	showTarget?: any;
	popoverNoModifiers?: any;
	actionLevel?: any;
	getPermissionResult?: any;
	tooltipText?: string;
	buttonType?: string;
	kr?: any;
	fullEmailId?: boolean;
	avatarView?: string;
	showContributorGroup?: boolean;
}

export const UserAvatarGroup: React.FC<UserAvatarGroupProps> = (props) => {
	const {
		contributorDetails,
		handleAddUserClick,
		addButtonClassName,
		avatarClassName,
		isButtonVisible,
		isButtonDisable,
		max,
		loginUserAvatar,
		currentUser,
		contributorDetailsType,
		isOnHoverVisible = true,
		showLoginUserAvatar = true,
		poperContainer,
		contributorClassName,
		diagramInstance,
		alignmentMapTab,
		virtualAlignmentList,
		virtualAlignmentListPopUp,
		getVirtuals,
		onEditHeaderclick,
		hideLinks,
		hideScore,
		doNotAllowUserSelection,
		showTeamDetails,
		t,
		isCoach,
		showTarget,
		popoverNoModifiers,
		actionLevel,
		getPermissionResult,
		tooltipText,
		buttonType,
		kr,
		fullEmailId,
		avatarView = 'avatar',
		showContributorGroup = true,
	} = props;
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [scaleValue, setScaleValue] = useState<any>(1);
	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
		if (diagramInstance) {
			setScaleValue(scaleValue ? diagramInstance.scrollSettings.currentZoom : 1);
		}
	};
	const [popupView, setPopupView] = useState<Number>(0);

	const handleTabChange = (e: any, view: Number) => {
		e.preventDefault();
		setPopupView(view);
		if (view === 1) {
			getVirtuals();
		}
	};

	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
		setPopupView(0);
	};
	const onEditHeaderclicked = (e: any) => {
		if (onEditHeaderclick) {
			handleClose();
			onEditHeaderclick(e);
		}
	};

	return (
		<Box className='contributors'>
			<Box
				display='flex'
				className='contributors-avatar-group'
				aria-owns={open ? 'mouse-over-popover' : undefined}
				aria-haspopup='true'
				onMouseOver={isOnHoverVisible && handleOpen}
				onMouseLeave={isOnHoverVisible && handleClose}
			>
				{avatarView === 'badge'
					? Boolean(loginUserAvatar) &&
					  loginUserAvatar &&
					  (loginUserAvatar.imagePath || loginUserAvatar.image ? (
							<>
								<Badge
									color='primary'
									badgeContent={
										contributorDetails && contributorDetails.length > 0 ? (
											<Typography component='span'>+{contributorDetails && contributorDetails.length}</Typography>
										) : (
											<></>
										)
									}
									invisible={contributorDetails && contributorDetails.length > 0 ? false : true}
								>
									<Avatar
										className='contributor-owner'
										key={`user-icon-${loginUserAvatar.employeeId}`}
										alt={`${loginUserAvatar.firstName} ${loginUserAvatar.lastName}`}
										src={loginUserAvatar.imagePath || loginUserAvatar.image || ''}
									>
										{getUserName({
											firstName: loginUserAvatar.firstName || '',
											lastName: loginUserAvatar.lastName || '',
											fullName: loginUserAvatar.fullName || '',
										})}
									</Avatar>
								</Badge>
							</>
					  ) : (
							<>
								<Badge
									color='primary'
									badgeContent={
										contributorDetails && contributorDetails.length > 0 ? (
											<Typography component='span'>+{contributorDetails && contributorDetails.length}</Typography>
										) : (
											<></>
										)
									}
									invisible={contributorDetails && contributorDetails.length > 0 ? false : true}
								>
									<Avatar>
										{getUserName({
											firstName: loginUserAvatar.firstName || '',
											lastName: loginUserAvatar.lastName || '',
											fullName: loginUserAvatar.fullName || '',
										})}
									</Avatar>
								</Badge>
							</>
					  ))
					: Boolean(loginUserAvatar) &&
					  loginUserAvatar &&
					  (loginUserAvatar.imagePath || loginUserAvatar.image ? (
							<Avatar
								className='contributor-owner'
								key={`user-icon-${loginUserAvatar.employeeId}`}
								alt={`${loginUserAvatar.firstName} ${loginUserAvatar.lastName}`}
								src={loginUserAvatar.imagePath || loginUserAvatar.image || ''}
							/>
					  ) : (
							<Avatar className='contributor-owner' key={`user-icon-${loginUserAvatar.employeeId}`}>
								{getUserName({
									firstName: loginUserAvatar.firstName || '',
									lastName: loginUserAvatar.lastName || '',
									fullName: loginUserAvatar.fullName || '',
								})}
							</Avatar>
					  ))}
				{Boolean(isCoach) &&
					contributorDetails &&
					contributorDetails.length &&
					(contributorDetails[0].imagePath || contributorDetails[0].image ? (
						<Avatar
							className='contributor-owner'
							key={`user-icon-${contributorDetails[0].employeeId}`}
							alt={`${contributorDetails[0].firstName} ${contributorDetails[0].lastName}`}
							src={contributorDetails[0].imagePath || contributorDetails[0].image || ''}
						/>
					) : (
						<Avatar className='contributor-owner' key={`user-icon-${contributorDetails[0].employeeId}`}>
							{getUserName({
								firstName: contributorDetails[0].firstName || '',
								lastName: contributorDetails[0].lastName || '',
								fullName: contributorDetails[0].fullName || '',
							})}
						</Avatar>
					))}
				{showContributorGroup && !isCoach && (
					<Fragment>
						{contributorDetails && contributorDetails.length ? (
							<AvatarGroup
								max={max || 10}
								className={`contributor-list ${
									contributorDetails && contributorDetails.length - (max - 1) > 9 ? 'not-two-digit' : ''
								}`}
							>
								{contributorDetails.map((item: ContributorDetailsProps) =>
									props.showTarget && item.krStatusId === 3 ? (
										<Avatar className='avatar-decline'></Avatar>
									) : item && !item.isSource && (item.employeeId || item.teamId) && (item.imagePath || item.image) ? (
										<Avatar
											className={`${
												contributorDetails.length <= 3 || contributorDetails.length <= 10
													? 'removeBlue'
													: avatarClassName
											}`}
											key={`user-icon-${item.employeeId}`}
											alt={`${item.firstName} ${item.lastName}`}
											src={item.imagePath || item.image || ''}
										/>
									) : item.imagePath || item.image ? (
										<Avatar
											className={`${
												contributorDetails.length <= 3 || contributorDetails.length <= 10
													? 'removeBlue'
													: avatarClassName
											}`}
											key={`user-icon-${item.employeeId}`}
											alt={`${item.firstName} ${item.lastName}`}
											src={item.imagePath || item.image || ''}
										/>
									) : (
										<Avatar
											className={`${
												contributorDetails.length <= 3 || contributorDetails.length <= 10
													? 'removeBlue'
													: avatarClassName
											}`}
											key={`user-icon-${item.employeeId}`}
										>
											{getUserName({
												firstName: item.firstName || '',
												lastName: item.lastName || '',
												fullName: item.fullName || '',
											})}
										</Avatar>
									)
								)}
							</AvatarGroup>
						) : (
							!loginUserAvatar &&
							showLoginUserAvatar && (
								<Avatar className='avatar-default'>
									<ProfileIcon />
								</Avatar>
							)
						)}
					</Fragment>
				)}
				{contributorDetailsType && (
					<ContributorsListPopover
						{...props}
						t={t}
						open={open}
						setOpen={setOpen}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
						contributorDetails={contributorDetails}
						loginUserAvatar={loginUserAvatar}
						poperContainer={poperContainer}
						contributorClassName={contributorClassName}
						scaleValue={scaleValue}
						alignmentMapPopover={alignmentMapTab ? true : false}
						popupView={popupView}
						handleTabChange={handleTabChange}
						virtualAlignmentListPopUp={virtualAlignmentListPopUp}
						onEditHeaderclick={onEditHeaderclicked}
						hideScore={hideScore}
						hideLinks={hideLinks}
						doNotAllowUserSelection={doNotAllowUserSelection}
						showTeamDetails={showTeamDetails}
						showTarget={showTarget ? true : false}
						popoverNoModifiers={popoverNoModifiers ? true : false}
						actionLevel={actionLevel}
						fullEmailId={fullEmailId}
					/>
				)}
			</Box>
			{checkPermission(getPermissionResult?.employeePermissions, assignOkr) && isButtonVisible && !currentUser ? (
				<OKRButton
					className={`add-btn2 ${isButtonDisable ? addButtonClassName : ''}`}
					icon={<AddIconSvg />}
					color='primary'
					title={tooltipText || 'Assign a contributor'}
					handleClick={handleAddUserClick}
					disabled={isButtonDisable}
					buttonType={buttonType}
					id={`assign-contributor-btn-${kr.goalKeyId}`}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
