import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	fetchFeedbackPending: false,
	fetchFeedbackSuccess: false,
	fetchFeedbackResult: [],
	fetchFeedbackError: {},
	giveFeedbackStatus: '',
	askFeedbackStatus: '',
	feedback1To1Status: '',
	commentFeedback: false,
};

export default function feedbackReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_FEEDBACK_PENDING:
			return {
				...state,
				fetchFeedbackPending: true,
				fetchFeedbackSuccess: false,
			};
		case actionTypes.FETCH_FEEDBACK_SUCCESS:
			return {
				...state,
				fetchFeedbackPending: false,
				fetchFeedbackSuccess: true,
				fetchFeedbackResult: action.payload.entity,
			};
		case actionTypes.FETCH_FEEDBACK_FAILED:
			return {
				...state,
				fetchFeedbackPending: false,
				fetchFeedbackSuccess: false,
				fetchFeedbackError: action.error,
			};
		case actionTypes.GIVE_FEEDBACK_PENDING:
			return {
				...state,
				giveFeedbackStatus: 'pending',
			};
		case actionTypes.GIVE_FEEDBACK_SUCCESS:
			return {
				...state,
				giveFeedbackStatus: action.payload.status === 200 ? 'success' : 'error',
			};
		case actionTypes.GIVE_FEEDBACK_FAILED:
			return {
				...state,
				giveFeedbackStatus: 'error',
			};
		case actionTypes.ASK_FEEDBACK_PENDING:
			return {
				...state,
				askFeedbackStatus: 'pending',
			};
		case actionTypes.ASK_FEEDBACK_SUCCESS:
			return {
				...state,
				askFeedbackStatus: action.payload.status === 200 ? 'success' : 'error',
			};
		case actionTypes.ASK_FEEDBACK_FAILED:
			return {
				...state,
				askFeedbackStatus: 'error',
			};
		case actionTypes.FEEDBACK_1_TO_1_PENDING:
			return {
				...state,
				feedback1To1Status: 'pending',
			};
		case actionTypes.FEEDBACK_1_TO_1_SUCCESS:
			return {
				...state,
				feedback1To1Status: action.payload.status === 200 ? 'success' : 'error',
			};
		case actionTypes.FEEDBACK_1_TO_1_FAILED:
			return {
				...state,
				feedback1To1Status: 'error',
			};
		case actionTypes.COMMENT_FEEDBACK_PENDING:
			return {
				...state,
				commentFeedbackStatus: 'pending',
			};
		case actionTypes.COMMENT_FEEDBACK_SUCCESS:
			return {
				...state,
				commentFeedbackStatus: action.payload.status === 200 ? 'success' : 'error',
			};
		case actionTypes.COMMENT_FEEDBACK_FAILED:
			return {
				...state,
				commentFeedbackStatus: 'error',
			};
		case actionTypes.RESET_FEEDBACK:
			return {
				...INITIAL_STATE,
			};
		default:
			return state;
	}
}
