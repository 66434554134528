import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Collapse,
	Slider,
	Avatar,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
} from '@material-ui/core';

import { CommentIcon, SelfReviewIcon } from '../../../config/svg/CommonSvg';

import CkEditor from '../CkEditor/CkEditor';
import { OKRButton } from '../OKRButton';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';
import { PerformanceIcon } from '../../../config/svg/AssessmentSvg';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { getFullName, getUserName } from '../../../config/utils';

export const SliderRating = (props: any) => {
	const {
		t,
		section,
		sectionIndex,
		questionIndex,
		question,
		getQuestionNumber,
		isViewMode,
		handleCommentsToggle,
		handleChangeQuestionComment,
		getSelectedScaleValueIndex,
		getDynamicMarks,
		handleChangeScaleQuestionValue,
		isMultipleUserAssessment,
		employeeDetails = {},
		handleEmployeeActionClick,
		isActionMenuHidden = false,
		callingFrom = '', // AdminAssessmentView
	} = props;

	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);
	const [sliderWidth, setSliderWidth] = useState<any>();

	const handleClose = () => {
		setPopperMenuClicked(true);
		setAnchorEl(null);
	};

	const getDetailsBasedOnKey = (keyName: string) => {
		const selectedKeyValue = isMultipleUserAssessment ? employeeDetails[keyName] : question[keyName];

		return isMultipleUserAssessment && keyName === 'ratingAssessmentId'
			? `${employeeDetails?.employeeId}_${sectionIndex}_${questionIndex}`
			: selectedKeyValue || '';
	};
	useEffect(() => {
		setSliderWidth(question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.length + '0%');
	}, [question]);

	const getSliderRatingValue = () => {
		return getSelectedScaleValueIndex(
			getDetailsBasedOnKey('ratingValue')
				? parseInt(getDetailsBasedOnKey('ratingValue'))
				: getDetailsBasedOnKey('ratingValue'),
			question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails
		);
	};
	// const ValueLabelComponent = (props: any) => {
	// 	const { children, open, value } = props;
	// 	// console.log('ValueLabelComponent ===> ', question?.questionsName, value);
	// 	return (
	// 		<Tooltip open={open} enterTouchDelay={0} placement='top' title={value}>
	// 			{children}
	// 		</Tooltip>
	// 	);
	// };
	// const ThumbComponent = (props: any) => {
	// 	const { children, open, value } = props;
	// 	console.log('ThumbComponent ===> ', question?.questionsName, value, props);
	// 	return <span>{children}</span>;
	// };

	return (
		<>
			<Box className={`${isMultipleUserAssessment ? 'user-multiple-row' : 'user-assess-ques-panel light-blue-panel'}`}>
				<Box className='user-assess-ques-type user-assess-ques-slider'>
					{!isMultipleUserAssessment ? (
						<Box className='user-assess-ques-title'>
							{getDetailsBasedOnKey('isQuestionError') ? (
								<Box className='error-field'>
									<Typography variant='body2' className='helperText-bottom'>
										{getDetailsBasedOnKey('isQuestionErrorMsg')}
									</Typography>
								</Box>
							) : (
								<></>
							)}
							{/* {!isMultipleUserAssessment ? ( */}
							<Typography variant='h4' className='ques-title font-weight-normal'>
								<span>{getQuestionNumber(section, question, sectionIndex, questionIndex)}</span>
								{question?.questionsName || ''}
								{question?.isQuestionsMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
							</Typography>
							{/* ) : (
								<></>
							)}{' '} */}
						</Box>
					) : (
						<></>
					)}
					<Box className='user-assess-ques-des'>
						<Box className='user-assess-ques-row'>
							{isMultipleUserAssessment ? (
								<Box className='user-assess-ques-left'>
									<Box className='user-info'>
										{getDetailsBasedOnKey('isQuestionError') ? (
											<Box className='error-field'>
												<Typography variant='body2' className='helperText-bottom'>
													{getDetailsBasedOnKey('isQuestionErrorMsg')}
												</Typography>
											</Box>
										) : (
											<></>
										)}
										<Box className='user-img'>
											{employeeDetails?.imagePath ? (
												<Avatar className='avatar-default' src={employeeDetails.imagePath} />
											) : (
												<Avatar className='avatar-default'>
													{getUserName({
														fullName: '',
														firstName: employeeDetails?.firstName,
														lastName: employeeDetails?.lastName,
													})}
												</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{getFullName(employeeDetails)}</Typography>
											{!isActionMenuHidden ? (
												<PopperMenu
													className={'user-menu-btn'}
													anchorEl={anchorEl}
													handleClose={handleClose}
													includeKebabMenu={true}
													menuClicked={popperMenuClicked}
													setPopperMenuClicked={setPopperMenuClicked}
													btnId={`more-btn-`}
													popperPlacement={'bottom-start'}
													popperClassName={'popper-menu'}
													// {...props}
													children={
														<>
															<ListItem
																id={`self_assessment_preview_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		1,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<SelfReviewIcon />
																</ListItemAvatar>
																<ListItemText primary={t('Self Assessment')} />
															</ListItem>
															<ListItem
																id={`user_performance_snapshot_drawer_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		2,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<PerformanceIcon />
																</ListItemAvatar>
																<ListItemText primary={t('performanceSnapshot')} />
															</ListItem>
															<ListItem
																id={`one_on_one_with_employee_${getDetailsBasedOnKey(
																	'performanceRoleLinkedFormDetailsId'
																)}_${getDetailsBasedOnKey('performanceRoleLinkedFormScaleId')}`}
																onClick={(e) => {
																	handleClose();
																	handleEmployeeActionClick(
																		e,
																		3,
																		section,
																		sectionIndex,
																		question,
																		questionIndex,
																		employeeDetails
																	);
																}}
															>
																<ListItemAvatar>
																	<RequestOneOnOneIcon2 />
																</ListItemAvatar>
																<ListItemText primary={t('requestOnetoOneText')} />
															</ListItem>
														</>
													}
												></PopperMenu>
											) : (
												<></>
											)}
										</Box>
									</Box>
								</Box>
							) : (
								<></>
							)}
							<Box className='user-assess-ques-right'>
								{question?.performanceRoleLinkedFormScales &&
								question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails &&
								question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.length ? (
									<Box
										className={`assessment-slider-box ${
											getDetailsBasedOnKey('isQuestionError') ? 'assessment-slider-box-error' : ''
										} ${
											callingFrom === 'AdminAssessmentView' && isViewMode
												? 'show-slider-label'
												: getSliderRatingValue()
												? 'show_selected_label'
												: 'show-start-end-label'
										}`}
										style={{ width: sliderWidth }}
									>
										<Slider
											defaultValue={0}
											// name={`${name}`}
											// aria-labelledby={`${name}-slider-small-steps`}
											step={1}
											value={getSliderRatingValue()}
											// ValueLabelComponent={ValueLabelComponent}
											// ThumbComponent={ThumbComponent}
											min={0}
											max={
												question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails?.length || 0
											}
											valueLabelDisplay={callingFrom === 'AdminAssessmentView' && isViewMode ? 'auto' : 'off'}
											marks={getDynamicMarks(
												question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails,
												getSliderRatingValue()
											)}
											onChangeCommitted={(event: any, value: any) => {
												// console.log('onChangeCommitted ===>', value);
												// onChangeGrowthOpportunityRating(event, value, name)
												handleChangeScaleQuestionValue(
													event,
													value,
													value
														? question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails[
																value - 1
														  ]?.performanceRoleLinkedFormScaleDetailId
														: 0,
													question,
													questionIndex,
													section,
													sectionIndex,
													true
												);
											}}
											onChange={(e: any, value: any) => {
												// console.log('onChange ===>', value);
												// setSliderValue(value);
												handleChangeScaleQuestionValue(
													e,
													value,
													value
														? question?.performanceRoleLinkedFormScales?.performanceRoleLinkedFormScaleDetails[
																value - 1
														  ]?.performanceRoleLinkedFormScaleDetailId
														: 0,
													question,
													questionIndex,
													section,
													sectionIndex,
													false
												);
											}}
											disabled={isViewMode}
										/>
										{/* {Boolean(formData) && formData.submit && !selectedValue ? (
																	<Typography variant='caption' color='error' className='error-field'>
																		{t('giveForPleaseSelect')}
																	</Typography>
																) : (
																	''
																)} */}
									</Box>
								) : (
									<></>
								)}
								{callingFrom === 'AdminAssessmentView' && isViewMode ? (
									<Box className={'assess-comment-box'}>
										<OKRButton
											id='comment-btn'
											className='btn-small-text assess-comments-btn'
											icon={<CommentIcon />}
											disabled={true}
											text={
												<>
													{t('Comments')} {question?.isCommentBoxMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
												</>
											}
											handleClick={(e: any) => {}}
										/>
										<Box className='assess-comment-field editior-pane preview-overlay'>
											<CkEditor
												placeholder={t('writeYourComments')}
												showEmoticons={true}
												value={''}
												handleEditorChange={(value: string) => {}}
											/>
										</Box>
									</Box>
								) : question?.isCommentBoxAvailable && getDetailsBasedOnKey('questionCommentBox') && isViewMode ? (
									<Box className='view-only-comment long-text'>
										<Box
											dangerouslySetInnerHTML={{
												__html: getDetailsBasedOnKey('questionCommentBox') || '-',
											}}
										></Box>
									</Box>
								) : question?.isCommentBoxAvailable && !isViewMode ? (
									<Box
										className={`assess-comment-box ${
											getDetailsBasedOnKey('isQuestionCommentError') ? 'assess-comment-box-error' : ''
										}`}
									>
										{getDetailsBasedOnKey('isQuestionCommentError') ? (
											<Box className='error-field'>
												<Typography variant='body2' className='helperText-bottom'>
													{getDetailsBasedOnKey('isQuestionCommentErrorMsg')}
												</Typography>
											</Box>
										) : (
											<></>
										)}
										<OKRButton
											id='comment-btn'
											className='btn-small-text assess-comments-btn'
											icon={<CommentIcon />}
											text={
												<>
													{t('Comments')} {question?.isCommentBoxMandatory ? <i style={{ color: 'red' }}>*</i> : <></>}
												</>
											}
											handleClick={(e: any) =>
												handleCommentsToggle(
													e,
													getDetailsBasedOnKey('performanceRoleLinkedFormDetailsId'),
													question,
													questionIndex,
													section,
													sectionIndex
												)
											}
										/>
										<Collapse in={getDetailsBasedOnKey('isCommentExpand')} className='assessment-comments-field'>
											<Box className='assess-comment-field editior-pane'>
												{/* {isViewMode ? (
												<Box
													dangerouslySetInnerHTML={{
														__html: question?.questionCommentBox || '',
													}}
												></Box>
											) : ( */}
												<CkEditor
													placeholder={t('writeYourComments')}
													showEmoticons={true}
													value={getDetailsBasedOnKey('questionCommentBox') || ''}
													handleEditorChange={(value: string) => {
														if (getDetailsBasedOnKey('questionCommentBox') !== value) {
															handleChangeQuestionComment(value, question, questionIndex, section, sectionIndex);
														}
													}}
												/>
												{/* )} */}
											</Box>
										</Collapse>
									</Box>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
