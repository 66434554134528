import React, { Fragment, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	FormLabel,
	List,
	ListItem,
	Popper,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddIconSvg, TeamUserIcon } from '../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../Common/OKRButton';
import { Enums } from '../../../../../config/enums';
import { getUserName } from '../../../../../config/utils';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';

export const AssignChip: React.FC<any> = (props: any) => {
	const { handleDrawerOpen, defaultOkrFormDetails, hideLabelButton = false, okrRoleError } = props;
	const { t } = useTranslation();
	const maxChipCount = 9;

	const [anchorTeamEl, setAnchorTeamEl] = useState<HTMLElement | null>(null);
	const [openPopper, setOpenPopper] = useState<boolean>(false);
	const [hoverIndex, setHoverIndex] = useState<any>(null);

	const handleTeamOpen = (event: any, indexVal: number) => {
		setAnchorTeamEl(event.currentTarget);
		setOpenPopper(true);
		setHoverIndex(indexVal);
	};
	const handleClose = () => {
		setAnchorTeamEl(null);
	};
	const handleTeamClose = () => {
		setOpenPopper(false);
		setAnchorTeamEl(null);
		setHoverIndex(null);
		//setCurrentUser(0);
	};
	const getChipItems = () => {
		try {
			const assignIds = defaultOkrFormDetails?.assignIds || [];
			const max9Items = assignIds && assignIds?.length > maxChipCount ? assignIds.slice(0, maxChipCount) : assignIds;

			return max9Items;
		} catch (error) {
			return [];
		}
	};

	return (
		<Box className='default-assign'>
			{!hideLabelButton && (
				<FormLabel id='set-a-duration' component='legend' className='custom-form-label'>
					<TeamUserIcon />
					{t('assignRole')}
				</FormLabel>
			)}
			<Box className='default-assign-role'>
				{defaultOkrFormDetails?.assignType === Enums.ZERO ? (
					<Box className='no-assign-role'>
						<Box className='chip-placeholder'></Box>
						<Box className='helperText-bottom'>
							{!hideLabelButton ? (
								<OKRButton
									id='add-member'
									className={`add-btn2 ${okrRoleError?.error ? 'error-btn' : ''}`}
									icon={<AddIconSvg />}
									handleClick={handleDrawerOpen}
								/>
							) : (
								<></>
							)}
							{okrRoleError?.error ? (
								<Typography className='error-field'>
									<ErrorIcon /> {okrRoleError?.errorText || ''}
								</Typography>
							) : (
								<></>
							)}
						</Box>
					</Box>
				) : defaultOkrFormDetails?.assignType === Enums.FOUR ? (
					<Box className='list-assign-role all-emp'>
						<Box className='list-item'>
							<Chip label={<span>{'All Employee'}</span>} className={`default-chip-avatar default-chip`} />
						</Box>
						{!hideLabelButton ? (
							<OKRButton id='add-member' className='add-btn2' icon={<AddIconSvg />} handleClick={handleDrawerOpen} />
						) : (
							<></>
						)}
					</Box>
				) : (
					<Box className='list-assign-role'>
						{getChipItems()?.length ? (
							getChipItems().map((rec: any, indexVal: number) => {
								return (
									<Box
										className='list-item'
										aria-owns={openPopper && indexVal === hoverIndex ? 'mouse-over-popover' : undefined}
										aria-haspopup='true'
										//onMouseOver={(e: any) => handleTeamOpen(e, indexVal)}
										//onMouseLeave={handleTeamClose}
									>
										<Tooltip arrow title={rec?.name || ''}>
											<Chip
												avatar={
													defaultOkrFormDetails?.assignType === Enums.THREE ? (
														<Avatar className='avatar-default' src={rec?.imagePath || ''}>
															{getUserName({
																firstName: rec?.firstName || '',
																lastName: rec?.lastName || '',
																fullName: rec?.name || '',
															})}
														</Avatar>
													) : (
														<></>
													)
												}
												label={
													<>
														<span>{rec?.name || ''}</span>
													</>
												}
												className={`default-chip-avatar ${
													defaultOkrFormDetails?.assignType === Enums.THREE ? '' : 'default-chip'
												} ${defaultOkrFormDetails?.assignType === Enums.THREE && !rec?.isActive ? 'deleted-chip' : ''}`}
												// deleted-chip
											/>
										</Tooltip>
										{/* {openPopper && indexVal === hoverIndex ? (
											<Popper
												open={openPopper}
												anchorEl={anchorTeamEl}
												transition
												className={`popper-wrap default-okr-popper`}
												placement='bottom-start'
											>
												<ClickAwayListener onClickAway={handleClose}>
													<List className='team-chip-hover popper-inner' component='div' disablePadding>
														{defaultOkrFormDetails?.assignIds && defaultOkrFormDetails?.assignIds?.length ? (
															defaultOkrFormDetails?.assignIds.map((rec: any) => {
																return (
																	<ListItem
																		component='div'
																		className={
																			defaultOkrFormDetails?.assignType === Enums.THREE && !rec?.isActive
																				? 'deleted-user-row'
																				: ''
																		}
																	>
																		<Box
																			className={`user-info ${
																				defaultOkrFormDetails?.assignType === Enums.THREE ? 'v-center' : ''
																			}`}
																		>
																			{defaultOkrFormDetails?.assignType === Enums.THREE ? (
																				<Box className='user-img'>
																					<Avatar className='avatar-default' src={rec?.imagePath || ''}>
																						{getUserName({
																							firstName: rec?.firstName || '',
																							lastName: rec?.lastName || '',
																							fullName: rec?.name || '',
																						})}
																					</Avatar>
																				</Box>
																			) : (
																				<></>
																			)}
																			<Box className='user-info-details'>
																				<Typography variant='h4'>
																					<span>{rec?.name || ''}</span>
																				</Typography>
																				{defaultOkrFormDetails?.assignType === Enums.THREE && !rec?.isActive ? (
																					<Box className='deleted-chip-wrap'>
																						<Chip className='delete-chip' label={t('deletedLabel')} />
																					</Box>
																				) : (
																					<></>
																				)}
																			</Box>
																		</Box>
																	</ListItem>
																);
															})
														) : (
															<></>
														)}
													</List>
												</ClickAwayListener>
											</Popper>
										) : (
											<></>
										)} */}
									</Box>
								);
							})
						) : (
							<></>
						)}

						{defaultOkrFormDetails?.assignIds && defaultOkrFormDetails?.assignIds?.length > maxChipCount ? (
							<>
								<Box
									className='list-item'
									aria-owns={openPopper && hoverIndex === 999999 ? 'mouse-over-popover' : undefined}
									aria-haspopup='true'
									onMouseOver={(e: any) => handleTeamOpen(e, 999999)}
									onMouseLeave={handleTeamClose}
								>
									<OKRButton
										className='btn-count'
										text={`+${defaultOkrFormDetails?.assignIds?.length - maxChipCount}`}
									/>
									{openPopper && hoverIndex === 999999 ? (
										<Popper
											open={openPopper}
											anchorEl={anchorTeamEl}
											transition
											className={`popper-wrap default-okr-popper`}
											placement='bottom-start'
										>
											<ClickAwayListener onClickAway={handleClose}>
												<List className='team-chip-hover popper-inner' component='div' disablePadding>
													{defaultOkrFormDetails?.assignIds && defaultOkrFormDetails?.assignIds?.length ? (
														defaultOkrFormDetails?.assignIds.slice(9).map((rec: any) => {
															return (
																<ListItem
																	component='div'
																	className={
																		defaultOkrFormDetails?.assignType === Enums.THREE && !rec?.isActive
																			? 'deleted-user-row'
																			: ''
																	}
																>
																	<Box
																		className={`user-info ${
																			defaultOkrFormDetails?.assignType === Enums.THREE ? 'v-center' : ''
																		}`}
																	>
																		{defaultOkrFormDetails?.assignType === Enums.THREE ? (
																			<Box className='user-img'>
																				<Avatar className='avatar-default' src={rec?.imagePath || ''}>
																					{getUserName({
																						firstName: rec?.firstName || '',
																						lastName: rec?.lastName || '',
																						fullName: rec?.name || '',
																					})}
																				</Avatar>
																			</Box>
																		) : (
																			<></>
																		)}
																		<Box className='user-info-details'>
																			<Typography variant='h4'>
																				<span>{rec?.name || ''}</span>
																			</Typography>
																			{defaultOkrFormDetails?.assignType === Enums.THREE && !rec?.isActive ? (
																				<Box className='deleted-chip-wrap'>
																					<Chip className='delete-chip' label={t('deletedLabel')} />
																				</Box>
																			) : (
																				<></>
																			)}
																		</Box>
																	</Box>
																</ListItem>
															);
														})
													) : (
														<></>
													)}
												</List>
											</ClickAwayListener>
										</Popper>
									) : (
										<></>
									)}
								</Box>
							</>
						) : (
							<></>
						)}
						{!hideLabelButton && (
							<OKRButton id='add-member' className='add-btn2' icon={<AddIconSvg />} handleClick={handleDrawerOpen} />
						)}
					</Box>
				)}
			</Box>
		</Box>
	);
};
