import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { saveCommentsPost } from '../../../action/recognize';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { enableTaggingWithImage } from '../../Common/CkEditor/CkEditorEnums';
import { recognitionSearch } from '../../../action/search';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import AlertDialog from '../../Common/Dialog';

export const CreateReply = (props: any) => {
	const { replyUser, updateCount, replyPageIndex, setShowReplyBox , setEditEditId} = props;
	const { t } = useTranslation();
	const [postText, setPostText] = React.useState<any>('');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [imageList, setImageList] = useState<any[]>([]);
	const [saveClicked, setSaveClicked] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<any>({ open: false, message: '', type: '' });
	const [isFormEdited, setIsFormEdited] = React.useState<boolean>(false);

	useEffect(() => {
		if (props.replyData && props.replyData.commentDetailsId) {
			setPostText(props.replyData.comments);
		} else {
			if (replyUser !== null) {
				let userId = replyUser.firstName + '' + replyUser.lastName;
				setPostText(
					'<p><a class="mention tagged-name" data-mention="@' +
						userId +
						'" data-user-id="' +
						replyUser.employeeId +
						'" rel="' +
						replyUser.employeeId +
						'">@' +
						userId +
						'</a></p>'
				);
			} else {
				setPostText('');
			}
		}
	}, [props.replyData, replyUser]);
	const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(recognitionSearch(searchStr, page, pageSize, 1));
	};
	const handleCancel = () => {
		setEditEditId(0);
		setPostText('');
		setIsFormEdited(false);
		setShowReplyBox(false)
	};
	const cancelReply = () => {
		if (isFormEdited) {
			setModalOpen({ open: true, message: t('unSavedItemAlert'), type: 'unsaved' });
		} else {
			handleCancel()
		}
	};
	const saveReply = async () => {
		if (postText) {
			setSaveClicked(true);
			let data: any = {
				commentDetailsId: props.replyData && props.replyData.commentDetailsId ? props.replyData.commentDetailsId : 0,
				comments: postText,
				moduleDetailsId: props.commentDetailsId,
				recognitionImageRequests: [],
				recognitionEmployeeTags: [],
				moduleId: 2,
			};
			const elem = document.createElement('span');
			elem.innerHTML = data.comments;
			let tagged = elem.getElementsByClassName('tagged-name');
			for (let i = 0; i < tagged.length; i++) {
				let employeeId: any = tagged[i].getAttribute('rel');
				if (employeeId) {
					let searchType = 1;
					if (employeeId.includes('_teamId')) {
						searchType = 2;
						employeeId = employeeId.replace('_teamId', '');
					}
					data.recognitionEmployeeTags.push({
						id: parseInt(employeeId),
						searchType: searchType,
					});
				}
			}
			imageList.forEach((value: any) => {
				if (postText.includes(value.filePath)) {
					data.recognitionImageRequests.push({
						fileName: value.fileName,
						guidFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					});
				}
			});
			let response: any = await dispatch(saveCommentsPost(data));
			if (response.data.status === Enums.STATUS_SUCCESS) {
				dispatch(showLoader(false));
				props.getReplies(1,9999, data.commentDetailsId === 0, true);
				setPostText('');
				setShowReplyBox(false);
				// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
				// 	variant: 'success',
				// 	autoHideDuration: 5000,
				// });
				setSaveClicked(false);
			} else {
				setSaveClicked(false);
				if (response?.data?.messageList.BlockedWords) {
					setModalOpen({ open: true, message: response?.data?.messageList.BlockedWords });
				} else {
					enqueueSnackbar(response?.data?.messageList.message, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (modalOpen.type === 'unsaved' && type === 1) {
			handleCancel();
			setIsFormEdited(false);
		}
		setModalOpen({ open: false, message: '' });
	};
	return (
		<>
			<>
				<Box className='recog-post-box recog-post-editor-box' id='create-reply-box'>
					{' '}
					<Box className='editor-pane'>
						<Box className='cfr-control-section' id='rteImage'>
							<CkEditor
								{...props}
								enableTagging={true}
								focusOnLoad={true}
								placeholder={''}
								handleEditorChange={(value: string) => {
									if (value && (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>')) {
										//setPostText('');
										setIsFormEdited(true);
									} else {
										//setIsTaskEdited(true);
										if (postText !== value) {
											setIsFormEdited(true);
										}
										setPostText(value);
									}

									//setEditorText(value);
								}}
								value={postText}
								setImageList={setImageList}
								imageList={imageList}
								uploadCase={'CONVERSATION'}
								uploadType={'3'}
								removedPlugins={enableTaggingWithImage}
								uploadImage={true}
								globalSearchAPIWithTeam={searchCustomEmployee}
								showEmoticons={true}
							/>
						</Box>
					</Box>
					<Box className='recog-add-post-action'>
					<OKRButton
								className='btn-primary'
								text={'Cancel'}
								handleClick={cancelReply}
							//disabled={!postText || saveClicked ? true : false}
							/>
						<OKRButton
							className='btn-primary'
							text={t('reply')}
							handleClick={saveReply}
							disabled={!postText || saveClicked ? true : false}
						/>
					</Box>
				</Box>
			</>
			{modalOpen.open ? (
				<AlertDialog
					module={modalOpen?.type === 'unsaved' ? 'user' : ''}
					message={modalOpen?.message || ''}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen?.open}
					isCancel={modalOpen?.type !== 'unsaved'}
				/>
			) : (
				<></>
			)}
		</>
	);
};
