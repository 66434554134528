import { Box, Typography } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { CfrTabPanel, CfrTabs } from './CfrTabs';
import '../../../styles/pages/cfr/cfr.scss';
import { Conversations } from './Conversations';
import { Notes } from './Notes';
import { Tasks } from './Tasks';
import { useSnackbar } from 'notistack';
import LinearLoader from '../../Common/Loader';
import AlertDialog from '../../Common/Dialog';
import { useEffect } from 'react';
import { removeLocalStorageItem } from '../../../services/StorageService';
import { OkrIcon } from '../../../config/svg/CommonSvg';
import { KeyResultIcon, ObjectiveIcon, TaskColoredIcon } from '../../../config/svg/MyGoalSvg';

export const Cfr: React.FC<any> = (props) => {
	const {
		okr,
		kr,
		type,
		selectTab,
		isCfrUpdated,
		setIsTaskModified,
		openCFR,
		setOpenCFR,
		setUnsavedCfrProps,
		unsavedCfrProps,
		taggedUser,
	} = props;
	const { t } = useTranslation();
	const [tabSelected, setTabSelected] = React.useState<Number>();
	const [loader, setLoader] = React.useState<boolean>(false);
	const [taskCount, setTaskCount] = React.useState<any>({
		totalTask: 0,
		taskCompleted: 0,
	});
	const [notesCount, setNotesCount] = React.useState<Number>(0);
	const [conversationCount, setConversationCount] = React.useState<Number>(0);
	const [isTaskEdited, setIsTaskEdited] = React.useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const [hideBox, setHideBox] = React.useState<boolean>(false);
	const [userTagged, setUserTaggged] = React.useState<any>({});

	useEffect(() => {
		setUserTaggged(taggedUser);
	}, [taggedUser]);

	useEffect(() => {
		setIsTaskModified(isTaskEdited);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isTaskEdited]);

	const handleTabChange = (event: any, newValue: Number) => {
		if (isTaskEdited) {
			event.preventDefault();
			event.stopPropagation();
			setUnsavedCfrProps({ open: true, event: { ...event }, source: '' });
		} else {
			setTabSelected(newValue);
			if (newValue !== 0) {
				if (type === 1 && okr?.isUnreadConversation === true) {
					okr.isUnreadConversation = false;
				} else if (type === 2 && kr?.isUnreadConversation === true) {
					kr.isUnreadConversation = false;
				}
			}
		}
	};

	React.useEffect(() => {
		props.resetCount && props.resetCount();
		return () => {
			props.resetTasks && props.resetTasks();
			removeLocalStorageItem('conversationHighlight');
			removeLocalStorageItem('notesHighlight');
			removeLocalStorageItem('isConversationRedirection');
		};
	}, []);

	React.useEffect(() => {
		if (selectTab === 'task') {
			setTabSelected(2);
		} else if (selectTab === 'notes') {
			setTabSelected(3);
		} else if (selectTab === 'conversation') {
			setTabSelected(0);
		}
	}, [selectTab]);

	const showApiMsgs = (msg: string, variant: any) => {
		if (variant === 'error') {
			enqueueSnackbar(msg, {
				variant: variant,
				autoHideDuration: 5000,
			});
		}
	};

	React.useEffect(() => {
		if (tabSelected !== 2 && !isCfrUpdated) {
			if (type === 2) {
				setTaskCount({
					...taskCount,
					totalTask: kr?.totalTask,
					taskCompleted: kr?.taskCompleted,
				});
				setNotesCount(kr.totalNotes);
			} else if (type === 1) {
				setTaskCount({
					...taskCount,
					totalTask: okr.totalTask,
					taskCompleted: okr.taskCompleted,
				});
				setNotesCount(okr.totalNotes);
			}
		}
	}, [tabSelected]);

	const handleCloseModal = async (ev: any, type: number) => {
		if (type === 1) {
			// await setIsTaskEdited(false);
			triggerClickEvent();
		} else {
			setUnsavedCfrProps({ open: false, event: null, source: '' });
		}
	};
	const triggerClickEvent = () => {
		setHideBox(true);
		if (unsavedCfrProps.event) {
			const { target } = unsavedCfrProps.event;
			const clonedNativeEvent = document.createEvent('HTMLEvents');
			clonedNativeEvent.initEvent('click', true, false);
			target.dispatchEvent(clonedNativeEvent);
		} else if (unsavedCfrProps.source === 'close') {
			setOpenCFR(false);
		}
		setUnsavedCfrProps({ open: false, event: null, source: '' });
		setIsTaskEdited(false);
	};
	return (
		<>
			<Box className='drawer-inner-content '>
				{loader && <LinearLoader />}
				<Box className='drawer-content-panel'>
					<Box className='cfr-head'>
						<Box className='okr-name'>
							<Typography variant='h2'>
								{type === 2 ? t('keyResult') : type === 4 ? t('taskLabel') : t('objectiveName')}
							</Typography>
							<Typography className='title-name'>
								{type === 2 ? (
									<>
										<KeyResultIcon /> {kr?.keyDescription}
									</>
								) : type === 4 ? (
									<>
										<TaskColoredIcon /> {kr.taskName}
									</>
								) : (
									<>
										<ObjectiveIcon /> {okr?.objectiveName}
									</>
								)}
							</Typography>
						</Box>
						<CfrTabs
							taskCount={taskCount}
							notesCount={notesCount}
							{...props}
							handleTabChange={handleTabChange}
							tabSelected={tabSelected}
							type={type}
							okr={okr}
							kr={kr}
						/>
					</Box>
					<Box className='tabs-panel-content'>
						<CfrTabPanel value={tabSelected} index={0} className='conversation-tab'>
							<Conversations
								{...props}
								setLoader={setLoader}
								tabSelected={tabSelected}
								conversationCount={conversationCount}
								setConversationCount={setConversationCount}
								isTaskEdited={isTaskEdited}
								setIsTaskEdited={setIsTaskEdited}
								setUnsavedCfrProps={setUnsavedCfrProps}
								setHideBox={setHideBox}
								hideBox={hideBox}
								userTagged={userTagged}
								setUserTaggged={setUserTaggged}
							/>
						</CfrTabPanel>
						<CfrTabPanel value={tabSelected} index={1}>
							<Box className='tab-panel-inner'>Feedback</Box>
						</CfrTabPanel>
						<CfrTabPanel value={tabSelected} index={2}>
							<Tasks
								{...props}
								tabSelected={tabSelected}
								setTaskCount={setTaskCount}
								taskCount={taskCount}
								setLoader={setLoader}
								loader={loader}
								showApiMsgs={showApiMsgs}
								isTaskEdited={isTaskEdited}
								setIsTaskEdited={setIsTaskEdited}
								setUnsavedCfrProps={setUnsavedCfrProps}
								setHideBox={setHideBox}
								hideBox={hideBox}
							/>
						</CfrTabPanel>
						<CfrTabPanel value={tabSelected} index={3}>
							<Notes
								{...props}
								setLoader={setLoader}
								tabSelected={tabSelected}
								notesCount={notesCount}
								setNotesCount={setNotesCount}
								isTaskEdited={isTaskEdited}
								setIsTaskEdited={setIsTaskEdited}
								setUnsavedCfrProps={setUnsavedCfrProps}
							/>
						</CfrTabPanel>
					</Box>
				</Box>
			</Box>
			{unsavedCfrProps.open && (
				<AlertDialog
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseModal}
					modalOpen={unsavedCfrProps.open}
				/>
			)}
		</>
	);
};
