import React, { useState, useEffect } from 'react';
import { Badge, Box, IconButton, Typography, Tooltip } from '@material-ui/core';
import moment from 'moment';
import Feedback from './Feedback';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { Enums } from '../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { ResetIcon } from '../../config/svg/CommonSvgIcon';
import { EngagementTabs } from './EngagementTabs';
import { EngagementTabPanel } from './EngagementTabs';
import WeeklyCheckIns from '../../containers/WeeklyCheckIns';
import { currentEngageTabs } from '../../action/common';
import '../../styles/pages/engagement/feedback.scss';
import { getUserDetails } from '../../config/utils';
import { Request1on1 } from './Request1on1';
import Notification from '../../containers/Notification';
import Header from '../../containers/Header';

export const Engagement = (props) => {
	const { userSelectedCycle, currentEngageTab } = useSelector((state) => state.commonReducer);
	const { checkInData } = useSelector((state) => state.okrReducer);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	const [tabSelected, setTabSelected] = useState(2);

	const handleTabChange = (event, newValue) => {
		dispatch(currentEngageTabs(newValue));
		setTabSelected(newValue);
	};
	useEffect(() => {
		const tabVal = !currentEngageTab || currentEngageTab === 0 || currentEngageTab === 1 ? 2 : currentEngageTab;
		setTabSelected(tabVal);
	}, [currentEngageTab]);

	useEffect(() => {
		window.addEventListener('scroll', scrollPageHeader);
		return () => {
			window.removeEventListener('scroll', scrollPageHeader);
		};
	});
	const scrollPageHeader = (e) => {
		const objElement = document.getElementById('page-engagement-subheader');
		if (window.pageYOffset >= 88) {
			objElement && objElement.classList.add('sticky-header');
		}
		if (window.pageYOffset < 70) {
			objElement && objElement.classList.remove('sticky-header');
		}
	};

	return (
		<>
			<Box className='page-subheader page-subheader-notification' id='page-engagement-subheader'>
				<Box className='page-subheader-inner'>
					<Box className='page-subheader-left'>
						<Box className='page-subheader-title'>
							<Typography variant='h2'>{t('engageLabel')}</Typography>
						</Box>
						<EngagementTabs tabSelected={tabSelected} handleTabChange={handleTabChange} {...props} />
					</Box>
					<Box className='page-subheader-right'>
						<Box className='page-subheader-right-inner'>
							{userSelectedCycle && (tabSelected === 1 || tabSelected === 3) ? (
								<>
									<Box className='range-selector-calender'></Box>
								</>
							) : (
								<></>
							)}

							<Header {...props} fromTab={true} isEngage={true} />
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='pad-container'>
				{/* <EngagementTabPanel value={tabSelected} index={0}>
					<></>
				</EngagementTabPanel>
				<EngagementTabPanel value={tabSelected} index={1}>
					{<Feedback {...props} />}
				</EngagementTabPanel> */}
				<EngagementTabPanel value={tabSelected} index={2}>
					<WeeklyCheckIns checkInResult={checkInData} />
				</EngagementTabPanel>
				<EngagementTabPanel value={tabSelected} index={3}>
					<Request1on1 {...props} tabSelected={tabSelected} />
				</EngagementTabPanel>
			</Box>
		</>
	);
};
