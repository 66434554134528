import { connect } from 'react-redux';
import {
	getOrgList,
	addOrg,
	fetchOrgCompleted,
	fetchOrgFailed,
	addOrgCompleted,
	addOrgFailed,
	addParent,
	addChild,
	getOrganization,
	updateOrganization,
	deleteOrganization,
	uploadLogo,
	getAllUsers,
	callAPI,
	getAllUsersList,
	fetchOrganizationsList,
	updatedOrganizationList,
	getAllOrganisations,
	getParentTeam,
	getCyclesData,
	getParentOrganization,
	updateParentOrganisation,
	resetParentOrganisation,
	addTeam,
	updateTeam,
	deleteTeam,
	assignedEmployeeTeam,
	getCheckinMasterData,
	getManageOrgList,
	updateAlignSettings,
	updateEngageSettings,
} from '../../action/organization';
import {
	addRolePermission,
	getRolesAndPermission,
	getRoles,
	getPermissions,
	updateRole,
	getRole,
	getRoleListing,
	getEmployeePermission,
	resetRole,
	resetAllRoleStatus,
	updateRoleName,
	updatePermission,
	deleteRole,
	resetRoleStatus,
	cloneRole,
	getActiveRoles,
	updatedRolesList,
	assignRole,
	getEmployeePermissionsById,
	saveEmployeePermissions,
	getRolePermissionByRoleId,
} from '../../action/roles';
import {
	getUsersList,
	addUsers,
	addAdUsers,
	changeUsersOrganization,
	getDesignation,
	getSearchUsersList,
	editUsers,
	updateStatus,
	deleteUsers,
	unlockUsers,
	changeUsersRole,
	changeUsersReportingTo,
	uploadUser,
	uploadEmails,
	downloadUserSample,
	getLicenseDetails,
	refetchLicenceDetails,
	refetchUserDetails,
	validateBulkFileUpload,
	validateBulkEmailsUpload,
	validateBulkGoalRoleUpload,
	inviteUser,
	uploadGoalRole,
} from '../../action/users';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { searchEmployee } from '../../action/search';
import { updatePage, updateRoute, getUserById, showLoader, currentDashboardTab } from '../../action/common';
import { Admin } from '../../components/Admin';
import { globalSearchAPIWithTeam, searchTeamEmpAPI, searchEmployeeInRole } from '../../action/search';
import { getOkrMasterData } from '../../action/myGoal';
const mapStateToProps = (state) => {
	return {
		listOrgPending: state.organizationReducer.listOrgPending,
		listOrgResult: state.organizationReducer.listOrgResult,
		listOrgSuccess: state.organizationReducer.listOrgSuccess,
		listOrgError: state.organizationReducer.listOrgError,
		addUpdateOrgStatus: state.organizationReducer.listOrgError,
		addOrgSuccess: state.organizationReducer.addUpdateOrgStatus,
		addOrgPending: state.organizationReducer.addOrgPending,
		addOrgError: state.organizationReducer.addOrgError,
		searchResult: state.searchReducer.searchResult,
		listMasterDataPending: state.commonReducer.listMasterDataPending,
		listMasterDataSuccess: state.commonReducer.listMasterDataSuccess,
		listMasterDataError: state.commonReducer.listMasterDataError,
		loaderStatus: state.commonReducer.loaderStatus,
		addUsersSuccess: state.usersReducer.addUsersSuccess,
		addUsersPending: state.usersReducer.addUsersPending,
		addUsersError: state.usersReducer.addUsersError,
		addUsersStatus: state.usersReducer.addUsersStatus,
		callApi: state.organizationReducer.callApi,
		licenseDetails: state.usersReducer.licenseDetails,
		licenseDetailsStatus: state.usersReducer.licenseDetailsStatus,
		fetchAllUserStatus: state.organizationReducer.fetchAllUsersSuccess,
		allUsers: state.organizationReducer.allUsers,

		//Roles Dependency
		globalSearchPage: state.commonReducer.globalSearchPage,
		listRolesResult: state.rolesReducer.listRolesResult,
		listRolesSuccess: state.rolesReducer.listRolesSuccess,
		listRolesError: state.rolesReducer.listRolesError,
		addRolesSuccess: state.rolesReducer.addRolesSuccess,
		addRolesPending: state.rolesReducer.addRolesPending,
		addRolesError: state.rolesReducer.addRolesError,
		addRolesStatus: state.rolesReducer.addRolesStatus,
		editRolesSuccess: state.rolesReducer.editRolesSuccess,
		editRolesPending: state.rolesReducer.editRolesPending,
		editRolesError: state.rolesReducer.editRolesError,
		editRolesStatus: state.rolesReducer.editRolesStatus,
		assignRolesError: state.rolesReducer.assignRolesError,
		assignRolesPending: state.rolesReducer.assignRolesPending,
		assignRolesSuccess: state.rolesReducer.assignRolesSuccess,
		assignRolesStatus: state.rolesReducer.assignRolesStatus,
		updateRoleNameError: state.rolesReducer.updateRoleNameError,
		updateRoleNamePending: state.rolesReducer.updateRoleNamePending,
		updateRoleNameSuccess: state.rolesReducer.updateRoleNameSuccess,
		updateRoleNameFailed: state.rolesReducer.updateRoleNameFailed,
		roleNameData: state.rolesReducer.roleNameData,

		//Users Dependency
		listUsersPending: state.usersReducer.listUsersPending,
		listUsersResult: state.usersReducer.listUsersResult,
		listUsersSuccess: state.usersReducer.listUsersSuccess,
		listUsersError: state.usersReducer.listUsersError,
		editUsersSuccess: state.usersReducer.editUsersSuccess,
		editUsersPending: state.usersReducer.editUsersPending,
		editUsersError: state.usersReducer.editUsersError,
		editUsersStatus: state.usersReducer.editUsersStatus,
		permissions: state.rolesReducer.permissions,
		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,
		currentRole: state.rolesReducer.currentRole,
		currentRoleStatus: state.rolesReducer.currentRoleStatus,
		updatedRoleStatus: state.rolesReducer.updateRoleStatus,
		updateRoleResponse: state.rolesReducer.updateRoleResponse,
		rolesDataPending: state.rolesReducer.rolesDataPending,
		rolesDataSuccess: state.rolesReducer.rolesDataSuccess,
		rolesDataResult: state.rolesReducer.rolesDataResult,
		rolesDataError: state.rolesReducer.rolesDataError,
		allOrganisations: state.organizationReducer.organisations,
		getUserPermissionsStatus: state.rolesReducer.getUserPermissionsStatus,
		userPermissions: state.rolesReducer.userPermissions,
		getRolePermission: state.rolesReducer.getRolePermission,
		cycleMasterData: state.organizationReducer.cycleMasterData,
		parentOrganisation: state.organizationReducer.parentOrganisation,
		currentRoute: state.commonReducer.currentRoute,
		checkinMasterData: state.organizationReducer.checkinMasterData,
		manageOrgResult: state.organizationReducer.manageOrgResult,
		manageOrgPending: state.organizationReducer.manageOrgPending,
		manageTeamBox: state.organizationReducer.manageTeamBox,
		dashboardCurrentTab: state.commonReducer.dashboardCurrentTab,
		okrMasterData: state.okrReducer.okrMasterData,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getOrgList,
			addOrg,
			fetchOrgCompleted,
			fetchOrgFailed,
			searchEmployee,
			updatePage,
			updateRoute,
			addOrgCompleted,
			addOrgFailed,
			addParent,
			addChild,
			getOrganization,
			updateOrganization,
			deleteOrganization,
			uploadLogo,
			getUsersList,
			getUserById,
			addUsers,
			addAdUsers,
			getAllUsers,
			showLoader,
			getDesignation,
			changeUsersOrganization,
			callAPI,
			getLicenseDetails,

			addRolePermission,
			getRolesAndPermission,
			getRoles,
			getRoleListing,

			getSearchUsersList,
			editUsers,
			updateStatus,
			deleteUsers,
			unlockUsers,
			changeUsersRole,
			changeUsersReportingTo,
			uploadUser,
			uploadEmails,
			uploadGoalRole,
			downloadUserSample,
			getPermissions,
			getEmployeePermission,
			updateRole,
			getRole,
			resetAllRoleStatus,
			resetRoleStatus,
			updateRoleName,

			updatePermission,
			deleteRole,
			resetRole,
			getAllUsersList,
			cloneRole,
			getActiveRoles,
			fetchOrganizationsList,
			updatedOrganizationList,
			updatedRolesList,
			globalSearchAPIWithTeam,
			searchTeamEmpAPI,
			assignRole,
			searchEmployeeInRole,
			getAllOrganisations,
			refetchLicenceDetails,
			refetchUserDetails,
			getEmployeePermissionsById,
			saveEmployeePermissions,
			getRolePermissionByRoleId,
			getParentTeam,
			getCyclesData,
			getParentOrganization,
			updateParentOrganisation,
			updateAlignSettings,
			updateEngageSettings,
			resetParentOrganisation,
			addTeam,
			updateTeam,
			deleteTeam,
			assignedEmployeeTeam,
			validateBulkFileUpload,
			validateBulkEmailsUpload,
			inviteUser,
			getCheckinMasterData,
			getManageOrgList,
			currentDashboardTab,
			getOkrMasterData,
			validateBulkGoalRoleUpload,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Admin));
