import React, { Fragment } from 'react';
import { Box, Slider, Typography } from '@material-ui/core';
import '../Goals/OkrListing/style-old.scss';
import { getOkrProgressDetails } from '../../config/utils';
import _ from 'lodash';

export const ProgressBar: React.FC<any> = (props) => {
	const { score, t, dueDate } = props;

	const { color } = getOkrProgressDetails({
		value: score,
		dueDate: dueDate || null,
	});
	return (
		<Fragment>
			<Box className={`kr-progress`}>
				<Box className='kr-progress-slider'>
					<Slider
						aria-label={''}
						style={{ color }}
						value={score}
						valueLabelDisplay='off'
						marks={false}
						step={10}
						disabled
					/>
					<Box className='progress-timestamp-info'>
						<Typography variant='h5'>
							{score || 0}
							<sup>%</sup>
						</Typography>
					</Box>
				</Box>
			</Box>
		</Fragment>
	);
};
