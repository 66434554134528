import React, { useEffect, useState } from 'react';
import {
	Box,
	Typography,
	Avatar,
	TextField,
	Tabs,
	Tab,
	InputAdornment,
	Collapse,
	Checkbox,
	Tooltip,
	FormControlLabel,
	Switch,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ContentCopyIcon, DueDateIcon, SearchIcon } from '../../../config/svg/CommonSvg';
import ReactDateRangePicker from '../..//Common/ReactDateRangePicker';
import { Enums } from '../../../config/enums';
import { CrossIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { AlignNewArrow, UpArrowIcon, RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import noRecordFound from '../../../images/search-placeholder.svg';
import { completedOneOneOneTask, getAllMomDetails } from '../../../action/oneOnOneRequest';
import { useDispatch, useSelector } from 'react-redux';
import {
	convertTaskDetailsForQuickLinks,
	copyTextToClipboard,
	formatOneOnOneRequestDate,
	getMonthDateYear,
	getUserDetails,
	getUserName,
	convertDateTimeToUserTimezone,
	generateDefaultTimeOptions,
} from '../../../config/utils';
import noRecordImg from '../../../images/no-found-notes.svg';
import { useSnackbar } from 'notistack';
import { TaskDelegatePopper } from '../../GlobalTask/TaskDelegatePopper';
import { getRecentAssignee } from '../../../action/globalTask';
import { MomDrawerQuickLinks } from './MomDrawerQuickLinks';

export const MomDrawer = (props: any) => {
	const { loader, setLoader, currentMeetingDetails, copiedTaskList, setCopiedTaskList, setHeaderDetails } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { userSelectedCycle } = useSelector((state: any) => state.commonReducer);
	const currentDate = new Date();
	const startDateValue = new Date();
	startDateValue.setMonth(startDateValue.getMonth() - 3);

	const userDetail = getUserDetails();

	const staticTitleText = '1-on-1 Meeting';
	const defaultTimeOptions = generateDefaultTimeOptions();
	const staticData = {
		requestId: 0,
		requestedTo: 0,
		onetoOneRemark: '',
		oneToOneTitle: staticTitleText,
		isQuickLaunch: false,
		selectedUser: [],
		meetingLink: '',
		meetingStartDate: new Date(),
		meetingEndDate: new Date(),
		meetingStartTime: defaultTimeOptions[0],
		meetingEndTime: defaultTimeOptions[1],
	};

	const [select1on1Tab, setSelect1on1Tab] = useState<any>(0);
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [isDateRangeChanged, setIsDateRangeChanged] = useState<boolean>(false);
	const [momDetails, setMomDetails] = useState<any>(null);
	const [searchText, setSearchText] = useState<any>('');
	const [expandedList, setExpandedList] = useState<any[]>([]);
	const [pageIndexVal, setPageIndex] = useState<number>(1);
	const [showLoadMore, setShowLoadMore] = useState(false);

	useEffect(() => {
		getAllMomDetailsAPICall(Enums.ZERO, '');
		setSearchText('');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getAllMomDetailsAPICall = async (pgIndex: any, searchTextValue = '') => {
		try {
			setLoader(true);
			const pageIndex = pgIndex + 1;
			const requestPayload = {
				pageIndex: pageIndex,
				pageSize: Enums.TEN,
				sortingText: '',
				order: '',
				finder: searchTextValue ? [searchTextValue] : [],
				// startDate: null,
				// endDate: null,
				oneToOneDetailId: currentMeetingDetails?.oneToOneDetailId || 0,
				employeeId: userDetail?.employeeId || 0,
			};
			setPageIndex(pageIndex);
			const resp: any = await dispatch(getAllMomDetails(requestPayload));
			if (resp && resp?.data && resp?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = resp?.data || {};
				const { results, totalPages } = entity || {};
				const updatedTaskListings = results && results.length ? results : [];

				if (updatedTaskListings.length) {
					updatedTaskListings.map((data: any) => {
						let localStartDateTime = convertDateTimeToUserTimezone(
							data.meetingStartDate || '',
							data.meetingStartTime || ''
						);
						let localEndDateTime = convertDateTimeToUserTimezone(data.meetingEndDate || '', data.meetingEndTime || '');
						data.meetingStartDate = localStartDateTime[0] || staticData.meetingStartDate;
						data.meetingStartTime = localStartDateTime[1] || staticData.meetingStartTime;
						data.meetingEndDate = localEndDateTime[0] || staticData.meetingEndDate;
						data.meetingEndTime = localEndDateTime[1] || staticData.meetingEndTime;
					});
				}

				if (totalPages > Enums.ONE && totalPages > pageIndex) {
					setShowLoadMore(true);
				} else {
					setShowLoadMore(false);
				}
				if (pageIndex === Enums.ZERO || pageIndex === Enums.ONE) {
					setMomDetails(updatedTaskListings);
				} else {
					if (momDetails && momDetails.length) {
						setMomDetails([...momDetails, ...updatedTaskListings]);
					} else {
						setMomDetails(updatedTaskListings);
					}
				}
				setLoader(false);
			} else {
				setShowLoadMore(false);
				setMomDetails([]);
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setMomDetails([]);
			setLoader(false);
		}
	};
	const handleTabChange = (event: any, newValue: Number) => {
		setSelect1on1Tab(newValue);
	};
	function a11yProps(index: number) {
		return {
			id: `1-on-1-tab-${index}`,
			'aria-controls': `1-on-1-tabpanel-${index}`,
		};
	}
	const handleManageDepartment = (e: any, departmentId: number) => {
		let list = Object.assign([], expandedList);
		const elementIndex = list.indexOf(departmentId);
		if (elementIndex !== -1) {
			list.splice(elementIndex, 1);
		} else {
			list.push(departmentId);
		}
		setExpandedList(list);
	};
	const handleSearchChange = (e: any) => {
		const { value } = e?.target || {};
		setSearchText(value ? value : '');
	};
	const clearSearchText = (e: any) => {
		setSearchText('');
		getAllMomDetailsAPICall(Enums.ZERO, '');
	};
	const handleSearchTextOnEnter = (e: any) => {
		if (e.keyCode === 13 && searchText && searchText.trim() !== '') {
			getAllMomDetailsAPICall(Enums.ZERO, searchText);
		} else if (e.keyCode === 13 && searchText.trim() === '') {
			getAllMomDetailsAPICall(Enums.ZERO, '');
		}
	};
	const handleTaskCompleteFromMom = async (e: any, record: any) => {
		try {
			setLoader(true);
			const { checked } = e?.target || {};
			if (record?.taskId) {
				const requestPayload = { taskId: record?.taskId, isCompleted: checked };
				const taskCompleteResponse: any = await dispatch(completedOneOneOneTask(requestPayload));
				if (Boolean(taskCompleteResponse) && taskCompleteResponse?.data?.status === Enums.STATUS_SUCCESS) {
					// getAllMomDetailsAPICall();
					// setSearchText('');
					// let updatedMomDetails = momDetails && momDetails.length ? [...momDetails] : [];
					// momDetails &&
					// 	momDetails.length &&
					// 	momDetails.forEach((item: any) => {
					// 		const { taskListings } = item || {};
					// 		item.taskListings =
					// 			taskListings && taskListings.length
					// 				? taskListings?.map((fromTask: any) => {
					// 						if (fromTask?.taskId === record?.taskId) {
					// 							fromTask.isCompleted = checked;
					// 						}
					// 						return fromTask;
					// 				  })
					// 				: [];
					// 	});
					momDetails &&
						momDetails.length &&
						momDetails.forEach((item: any) => {
							const { requestedFromTasks, requestedToTasks } = item || {};
							item.requestedFromTasks =
								requestedFromTasks && requestedFromTasks.length
									? requestedFromTasks?.map((fromTask: any) => {
											if (fromTask?.taskId === record?.taskId) {
												fromTask.isCompleted = checked;
											}
											return fromTask;
									  })
									: [];
							item.requestedToTasks =
								requestedToTasks && requestedToTasks.length
									? requestedToTasks?.map((toTask: any) => {
											if (toTask?.taskId === record?.taskId) {
												toTask.isCompleted = checked;
											}
											return toTask;
									  })
									: [];
						});

					enqueueSnackbar(t('taskUpdate'), {
						variant: 'success',
						autoHideDuration: 3000,
					});
				} else if (Boolean(taskCompleteResponse)) {
					const responseAPI = taskCompleteResponse?.data?.messageList || {};
					const keys = Object.keys(responseAPI);
					const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
					enqueueSnackbar(messages, {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setLoader(false);
		}
	};
	// TBD - Commented Method call for Copy notes
	const handleCopyToClipBoard = (e: any, record: any, type: string, id: string) => {
		// navigator.clipboard.writeText(record || '');
		copyTextToClipboard(record || '')
			.then(() => {
				const documentEle: any = document.getElementById(id);
				if (documentEle && documentEle?.style?.display) {
					documentEle.style.display = '';
					setTimeout(() => {
						documentEle.style.display = 'none';
					}, 5000);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	};
	const handleTaskCopyClick = (e: any, record: any, type: string) => {
		try {
			const { checked } = e?.target || {};
			if (record?.taskId) {
				let updatedCopiedTaskList = copiedTaskList && copiedTaskList.length ? [...copiedTaskList] : [];
				if (updatedCopiedTaskList && updatedCopiedTaskList.length) {
					if (checked) {
						setCopiedTaskList([...copiedTaskList, { ...record, recentAdded: true, recentTaskId: record?.taskId || 0 }]);
					} else {
						const recIndex = updatedCopiedTaskList.findIndex(
							(rec: any) => rec?.recentTaskId === record?.taskId && rec?.recentAdded
						);

						updatedCopiedTaskList = updatedCopiedTaskList.filter((rec: any, index: number) => index !== recIndex);
						setCopiedTaskList([...updatedCopiedTaskList]);
					}
				} else {
					checked &&
						setCopiedTaskList([...copiedTaskList, { ...record, recentAdded: true, recentTaskId: record?.taskId || 0 }]);
				}
				// momDetails &&
				// 	momDetails.length &&
				// 	momDetails.forEach((item: any) => {
				// 		const { taskListings } = item || {};
				// 		item.taskListings =
				// 			taskListings && taskListings.length
				// 				? taskListings?.map((fromTask: any) => {
				// 						if (fromTask?.taskId === record?.taskId) {
				// 							fromTask.isCopied = checked;
				// 						}
				// 						return fromTask;
				// 				  })
				// 				: [];
				// 	});
				momDetails &&
					momDetails.length &&
					momDetails.forEach((item: any) => {
						const { requestedFromTasks, requestedToTasks } = item || {};
						item.requestedFromTasks =
							requestedFromTasks && requestedFromTasks.length
								? requestedFromTasks?.map((fromTask: any) => {
										if (fromTask?.taskId === record?.taskId) {
											fromTask.isCopied = checked;
										}
										return fromTask;
								  })
								: [];
						item.requestedToTasks =
							requestedToTasks && requestedToTasks.length
								? requestedToTasks?.map((toTask: any) => {
										if (toTask?.taskId === record?.taskId) {
											toTask.isCopied = checked;
										}
										return toTask;
								  })
								: [];
					});
			}
		} catch (error) {
			console.error(error);
		}
	};
	const RenderSelfDetails = ({ record, type, indexVal }: any) => {
		let publicNotes = '';
		let privateNotes = '';
		const {
			requestedTo,
			requestedToEmployee,
			requestedToNotes,
			requestedToTasks,
			// requestedFrom,
			requestedFromEmployee,
			requestedFromNotes,
			requestedFromTasks,
			isInitiatorOnly,
			// taskListings,
		} = record || {};
		let myDetails = requestedFromEmployee;
		let myNotes = requestedFromNotes;
		let myTasks = requestedFromTasks;
		let otherDetails = requestedToEmployee;
		let otherNotes = requestedToNotes;
		let otherTasks = requestedToTasks;
		if (userDetail?.employeeId === requestedTo) {
			myDetails = requestedToEmployee;
			myNotes = requestedToNotes;
			myTasks = requestedToTasks;
		} else {
			myDetails = requestedFromEmployee;
			myNotes = requestedFromNotes;
			myTasks = requestedFromTasks;
		}
		if (userDetail?.employeeId !== requestedTo) {
			otherDetails = requestedToEmployee;
			otherNotes = requestedToNotes;
			otherTasks = requestedToTasks;
		} else {
			otherDetails = requestedFromEmployee;
			otherNotes = requestedFromNotes;
			otherTasks = requestedFromTasks;
		}
		myNotes &&
			myNotes.length &&
			myNotes.forEach((item: any) => {
				if (item?.isPrivate) {
					privateNotes = item?.description;
				} else {
					publicNotes = item?.description;
				}
			});
		const completedTasks = myTasks && myTasks.length ? myTasks.filter((item: any) => item?.isCompleted) : [];

		return (
			<>
				{!isInitiatorOnly ? (
					<>
						{publicNotes || privateNotes || (myTasks && myTasks?.length) ? (
							<Box className='meeting-participant-info'>
								<Box className='participants-user-head'>
									<Box className='user-info v-center'>
										<Box className='user-img'>
											{Boolean(myDetails?.imagePath) ? (
												<Avatar className='avatar-default' alt={myDetails?.employeeName} src={myDetails?.imagePath} />
											) : (
												<Avatar>
													{getUserName({ firstName: '', lastName: '', fullName: myDetails?.employeeName })}
												</Avatar>
											)}
										</Box>
										<Box className='user-info-details'>
											<Typography variant='h4'>{myDetails?.employeeName || ''}</Typography>
										</Box>
										{myDetails?.employeeId === userDetail?.employeeId && (
											<Typography className='owner-chip'>{t('mySelfTab')}</Typography>
										)}
									</Box>
									{publicNotes ? <Box className='user-copy-btn'></Box> : null}
								</Box>
								<Box className='participants-notes-section'>
									{publicNotes ? (
										<Box className='participants-user-notes'>
											<Box className='user-notes-head'>
												<Typography variant='h6'>{t('meetingNotes')}</Typography>
											</Box>
											<Box className='long-text' dangerouslySetInnerHTML={{ __html: publicNotes }} />
										</Box>
									) : (
										<></>
									)}
									{privateNotes ? (
										<Box className='participants-private-notes-wrap'>
											<Box className='user-notes-head'>
												<Typography variant='h6'>{t('privateNotes')}</Typography>

												<Box className='user-copy-btn'>
													{/* <Typography variant='h6' style={{ display: 'none' }} id={`${type}_${indexVal}_private`}>
												{t('copiedText')}
											</Typography>
											<OKRButton
												handleClick={(e: any) =>
													handleCopyToClipBoard(e, privateNotes, type, `${type}_${indexVal}_private`)
												}
												className={'btn-link-type1'}
												title={t('copyNotesClipboard')}
												//text={t('copyClipboard')}
												icon={<ContentCopyIcon />}
											/> */}
												</Box>
											</Box>
											<Box className='participants-private-notes'>
												<Box className='long-text' dangerouslySetInnerHTML={{ __html: privateNotes }} />
											</Box>
										</Box>
									) : (
										<></>
									)}
								</Box>
								{myTasks && myTasks?.length ? (
									<Box className='participants-task-section'>
										<Box className='task-count-head'>
											<Typography variant='h6'>
												{completedTasks?.length || 0} of {myTasks?.length || 0} Task(s) Completed
											</Typography>
										</Box>
										<Box className='participants-task-list'>
											{myTasks?.map((item: any) => (
												<Box className='task-row'>
													<Box className='col-1 task-info'>
														<Box className='task-title'>
															<Checkbox
																// disabled
																checked={item?.isCompleted || false}
																className='task-checkbox'
																icon={<BorderCheckboxIcon />}
																checkedIcon={<CheckedIcon />}
																disabled={Boolean(item?.assignedBy?.employeeId !== userDetail?.employeeId)}
																aria-label='Task Checkbox'
																onChange={(e: any) => handleTaskCompleteFromMom(e, item)}
															/>
															<Typography variant='subtitle1' component={'p'}>
																{item?.taskName || ''}
															</Typography>
														</Box>
														<MomDrawerQuickLinks {...props} item={item} />
													</Box>
													<Box className='col-2 task-assigned-user'>
														{/* <Tooltip arrow title={item?.employeeName || ''}>
													{Boolean(item?.imagePath) ? (
														<Avatar className='avatar-default' alt={item?.employeeName} src={item?.imagePath} />
													) : (
														<Avatar>
															{getUserName({ firstName: '', lastName: '', fullName: item?.employeeName })}
														</Avatar>
													)}
												</Tooltip> */}
														<Box className='user-avatar-group'>
															{item?.assignedBy?.employeeId && item?.assignedTo?.employeeId ? (
																<Box className='task-aligned-users'>
																	{item?.assignedBy?.employeeId !== item?.assignedTo?.employeeId ? (
																		<Box className='task-assigned-by'>
																			<Tooltip
																				arrow
																				title={`${item?.assignedBy?.firstName} ${item?.assignedBy?.lastName} delegated the task to ${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}`}
																			>
																				{item?.assignedBy?.imagePath && item?.assignedBy?.imagePath.trim() !== '' ? (
																					<Avatar
																						src={item?.assignedBy?.imagePath}
																						className={item?.assignedBy?.isActive ? '' : 'disable-user'}
																					/>
																				) : item?.assignedBy?.firstName ? (
																					<Avatar className={item?.assignedBy?.isActive ? '' : 'disable-user'}>
																						{getUserName(item?.assignedBy)}
																					</Avatar>
																				) : (
																					<></>
																				)}
																			</Tooltip>

																			<Box className='align-arrow'>
																				<AlignNewArrow />
																			</Box>
																		</Box>
																	) : (
																		<Box className='task-assigned-by'>
																			<Tooltip arrow title={`Delegated to me`}>
																				{item?.assignedBy?.imagePath && item?.assignedBy?.imagePath.trim() !== '' ? (
																					<Avatar
																						src={item?.assignedBy?.imagePath}
																						className={item?.assignedBy?.isActive ? '' : 'disable-user'}
																					/>
																				) : item?.assignedBy?.firstName ? (
																					<Avatar className={item?.assignedBy?.isActive ? '' : 'disable-user'}>
																						{getUserName(item?.assignedBy)}
																					</Avatar>
																				) : (
																					<></>
																				)}
																			</Tooltip>
																		</Box>
																	)}
																	{item?.assignedBy?.employeeId !== item?.assignedTo?.employeeId ? (
																		<Box className='task-assigned-to'>
																			<Tooltip
																				arrow
																				title={`${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}`}
																			>
																				{item?.assignedTo?.imagePath && item?.assignedTo?.imagePath.trim() !== '' ? (
																					<Avatar src={item?.assignedTo?.imagePath} />
																				) : item?.assignedTo?.firstName ? (
																					<Avatar>{getUserName(item?.assignedTo)}</Avatar>
																				) : (
																					<></>
																				)}
																			</Tooltip>
																		</Box>
																	) : (
																		<></>
																	)}
																</Box>
															) : (
																<></>
															)}
														</Box>
													</Box>
													<Box className='col-3 task-copy'>
														<Tooltip
															classes={{
																popper: item?.isCopied ? 'tooltip-layout3' : '',
															}}
															arrow
															title={item?.isCopied ? t('removeCurrentMeeting') : t('copyCurrentMeeting')}
														>
															<FormControlLabel
																control={
																	<Switch
																		checked={item?.isCopied || false}
																		onChange={(e) => handleTaskCopyClick(e, item, type)}
																	/>
																}
																label={''}
																className='toggle-label toggle-no-label'
															/>
														</Tooltip>
													</Box>
												</Box>
											))}
										</Box>
									</Box>
								) : (
									<></>
								)}
							</Box>
						) : (
							<></>
						)}
						<RenderOthersDetail
							record={record}
							type={'Other'}
							myDetails={otherDetails}
							myNotes={otherNotes}
							myTasks={otherTasks}
							indexVal={indexVal}
						/>
					</>
				) : (
					<RenderOthersDetail
						record={record}
						type={'Other'}
						myDetails={requestedFromEmployee}
						myNotes={requestedFromNotes}
						myTasks={requestedFromTasks}
						indexVal={indexVal}
					/>
				)}
			</>
		);
	};
	const RenderOthersDetail = ({ record, type, myDetails, myNotes, myTasks, indexVal }: any) => {
		let publicNotes = '';
		let privateNotes = '';

		myNotes &&
			myNotes.length &&
			myNotes.forEach((item: any) => {
				if (item?.isPrivate) {
					privateNotes = item?.description;
				} else {
					publicNotes = item?.description;
				}
			});
		const completedTasks = myTasks && myTasks.length ? myTasks.filter((item: any) => item?.isCompleted) : [];

		return publicNotes || (myTasks && myTasks?.length) ? (
			<Box className='meeting-participant-info'>
				<Box className='participants-user-head'>
					<Box className='user-info v-center'>
						<Box className='user-img'>
							{Boolean(myDetails?.imagePath) ? (
								<Avatar className='avatar-default' alt={myDetails?.employeeName} src={myDetails?.imagePath} />
							) : (
								<Avatar>{getUserName({ firstName: '', lastName: '', fullName: myDetails?.employeeName })}</Avatar>
							)}
						</Box>
						<Box className='user-info-details'>
							<Typography variant='h4'>{myDetails?.employeeName || ''}</Typography>
						</Box>
						{myDetails?.employeeId === userDetail?.employeeId && (
							<Typography className='owner-chip'>{t('mySelfTab')}</Typography>
						)}
					</Box>
					{publicNotes ? (
						<Box className='user-copy-btn'>
							{/* <Typography variant='h6' style={{ display: 'none' }} id={`${type}_${indexVal}_public`}>
								{t('copiedText')}
							</Typography>
							<OKRButton
								handleClick={(e: any) => handleCopyToClipBoard(e, publicNotes, type, `${type}_${indexVal}_public`)}
								className={'btn-link-type1'}
								title={t('copyNotesClipboard')}
								//text={t('copyClipboard')}
								icon={<ContentCopyIcon />}
							/> */}
						</Box>
					) : null}
				</Box>
				<Box className='participants-notes-section'>
					{publicNotes ? (
						<Box className='participants-user-notes'>
							<Box className='user-notes-head'>
								<Typography variant='h6'>{t('meetingNotes')}</Typography>
							</Box>
							<Box className='long-text' dangerouslySetInnerHTML={{ __html: publicNotes }} />
						</Box>
					) : (
						<></>
					)}
				</Box>
				{myTasks && myTasks?.length ? (
					<Box className='participants-task-section'>
						<Box className='task-count-head'>
							<Typography variant='h6'>
								{completedTasks?.length || 0} of {myTasks?.length || 0} Task(s) Completed
							</Typography>
						</Box>
						<Box className='participants-task-list'>
							{myTasks?.map((item: any) => (
								<Box className='task-row'>
									<Box className='col-1 task-info'>
										<Box className='task-title'>
											<Checkbox
												disabled={Boolean(item?.assignedBy?.employeeId !== userDetail?.employeeId)}
												checked={item?.isCompleted || false}
												className='task-checkbox'
												icon={<BorderCheckboxIcon />}
												checkedIcon={<CheckedIcon />}
												aria-label='Task Checkbox'
											/>
											<Typography variant='subtitle1' component={'p'}>
												{item?.taskName || ''}
											</Typography>
										</Box>
										<MomDrawerQuickLinks {...props} item={item} />
									</Box>
									<Box className='col-2 task-assigned-user'>
										{/* <Tooltip arrow title={item?.employeeName || ''}>
											{Boolean(item?.imagePath) ? (
												<Avatar className='avatar-default' alt={item?.employeeName} src={item?.imagePath} />
											) : (
												<Avatar>{getUserName({ firstName: '', lastName: '', fullName: item?.employeeName })}</Avatar>
											)}
										</Tooltip> */}
										<Box className='user-avatar-group'>
											{item?.assignedBy?.employeeId && item?.assignedTo?.employeeId ? (
												<Box className='task-aligned-users'>
													{item?.assignedBy?.employeeId !== item?.assignedTo?.employeeId ? (
														<Box className='task-assigned-by'>
															<Tooltip
																arrow
																title={`${item?.assignedBy?.firstName} ${item?.assignedBy?.lastName} delegated the task to ${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}`}
															>
																{item?.assignedBy?.imagePath && item?.assignedBy?.imagePath.trim() !== '' ? (
																	<Avatar
																		src={item?.assignedBy?.imagePath}
																		className={item?.assignedBy?.isActive ? '' : 'disable-user'}
																	/>
																) : item?.assignedBy?.firstName ? (
																	<Avatar className={item?.assignedBy?.isActive ? '' : 'disable-user'}>
																		{getUserName(item?.assignedBy)}
																	</Avatar>
																) : (
																	<></>
																)}
															</Tooltip>

															<Box className='align-arrow'>
																<AlignNewArrow />
															</Box>
														</Box>
													) : (
														<Box className='task-assigned-by'>
															<Tooltip arrow title={`Delegated to me`}>
																{item?.assignedBy?.imagePath && item?.assignedBy?.imagePath.trim() !== '' ? (
																	<Avatar
																		src={item?.assignedBy?.imagePath}
																		className={item?.assignedBy?.isActive ? '' : 'disable-user'}
																	/>
																) : item?.assignedBy?.firstName ? (
																	<Avatar className={item?.assignedBy?.isActive ? '' : 'disable-user'}>
																		{getUserName(item?.assignedBy)}
																	</Avatar>
																) : (
																	<></>
																)}
															</Tooltip>
														</Box>
													)}
													{item?.assignedBy?.employeeId !== item?.assignedTo?.employeeId ? (
														<Box className='task-assigned-to'>
															<Tooltip arrow title={`${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}`}>
																{item?.assignedTo?.imagePath && item?.assignedTo?.imagePath.trim() !== '' ? (
																	<Avatar src={item?.assignedTo?.imagePath} />
																) : item?.assignedTo?.firstName ? (
																	<Avatar>{getUserName(item?.assignedTo)}</Avatar>
																) : (
																	<></>
																)}
															</Tooltip>
														</Box>
													) : (
														<></>
													)}
												</Box>
											) : (
												<></>
											)}
										</Box>
									</Box>
									<Box className='col-3 task-copy'>
										<Tooltip
											arrow
											classes={{
												popper: item?.isCopied ? 'tooltip-layout3' : '',
											}}
											title={item?.isCopied ? t('removeCurrentMeeting') : t('copyCurrentMeeting')}
										>
											<FormControlLabel
												control={
													<Switch
														checked={item?.isCopied || false}
														onChange={(e) => handleTaskCopyClick(e, item, type)}
													/>
												}
												label={''}
												className='toggle-label toggle-no-label'
											/>
										</Tooltip>
									</Box>
								</Box>
							))}
						</Box>
					</Box>
				) : (
					<></>
				)}
			</Box>
		) : (
			<></>
		);
	};
	const moveScrollToBox = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<>
			<Tabs className='okr-tabs drawer-tabs' value={select1on1Tab} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab label={t('meetings')} value={0} {...a11yProps(0)} />
			</Tabs>
			<Box className='drawer-tabs-panel drawer-mom-meeting-panel'>
				<OKRButton
					handleClick={(e: any) => moveScrollToBox('mom_header_top')}
					icon={<UpArrowIcon />}
					className={'scroll-to-top'}
					text={'Top'}
				/>
				<Box className='drawer-form-fields'>
					<Box className='drawer-input-field' id={'mom_header_top'}>
						<Box className='mom-head'>
							<Box className='mom-head-right'>
								{/* <Box className='range-selector-calender'>
									<ReactDateRangePicker
										type={'ContributorList'}
										selectedCycleDetails={userSelectedCycle || {}}
										startDateValue={startDateValue || new Date()}
										endDateValue={currentDate || new Date()}
										minimumDate={
											userDetail && userDetail?.organisationCreatedOnDate
												? new Date(userDetail?.organisationCreatedOnDate)
												: startDateValue
												? new Date(startDateValue)
												: new Date()
										}
										maximumDate={currentDate ? new Date(currentDate) : new Date()}
										handleDateSelection={(
											range: any,
											start: any,
											end: any,
											selectionType: any,
											isCycleChanged: any
										) => {
											range && setIsDateRangeChanged(true);
											range && setSelectedDateRange(range);
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'range'}
										selectedDateRange={selectedDateRange}
										setSelectedDateRange={setSelectedDateRange}
									/>
								</Box> */}
								<Box className='user-search'>
									<TextField
										className='search-text-field'
										placeholder={t('searchText')}
										fullWidth
										value={searchText}
										onChange={handleSearchChange}
										onKeyDown={handleSearchTextOnEnter}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SearchIcon />
												</InputAdornment>
											),
											endAdornment: searchText ? (
												<OKRButton
													handleClick={clearSearchText}
													className='editIcon'
													icon={<CrossIcon />}
													title={t('Reset')}
												/>
											) : null,
										}}
									/>
								</Box>
							</Box>
						</Box>
					</Box>

					<Box className='mom-list-outer'>
						{momDetails && momDetails?.length === 0 ? (
							<Box className='search-no-record-global'>
								<Box className='no-record-message'>
									<Typography variant='h2' component='h2'>
										{t('NoRecord')}
									</Typography>
									{/* <Typography variant='h6' className='no-sentence-case'>
											{t('searchHighlightNotFound')}
										</Typography> */}
									<AnimatedIcon
										className=''
										width='230'
										type='image/svg+xml'
										alt={t('globalSearchNoRecord')}
										imagePath={noRecordFound}
									/>
								</Box>
							</Box>
						) : null}
						{momDetails && momDetails?.length ? (
							<Box className='mom-meeting-list'>
								{/* <Box className='mom-meeting-head'>
										{isDateRangeChanged ? (
											<Typography variant='body2' className='short-des-text'>
												{t('momDateSelected')}
											</Typography>
										) : (
											<Typography variant='body2' className='short-des-text'>
												{t('momLastMonths')}
											</Typography>
										)}
									</Box> */}
								{/* tile repeat start */}
								{momDetails?.map((item: any, index: number) => {
									return (
										<Box className='mom-meeting-list-row'>
											<Box
												className={`mom-meeting-list-head ${
													expandedList.indexOf(item?.oneToOneDetailId) !== -1 ? 'expand-head' : 'collapse-head'
												}`}
												onClick={(e) => handleManageDepartment(e, item?.oneToOneDetailId)}
											>
												<Box className='mom-meeting-list-head-inner'>
													<Tooltip
														arrow
														title={
															expandedList.indexOf(item?.oneToOneDetailId) !== -1
																? t('collapseLabel')
																: t('expendLabel')
														}
														placement={'top'}
													>
														<Box
															className={`rotating-arrow ${
																expandedList.indexOf(item?.oneToOneDetailId) !== -1 ? 'expand' : 'collapse'
															}`}
														>
															<RightArrowIcon />
														</Box>
													</Tooltip>
													<Box className='mom-meeting-title'>
														<Typography>{item?.oneToOneTitle}</Typography>
														{item?.meetingStartDate === item?.meetingEndDate ? (
															<Box className='meeting-schedule-calender meeting-schedule-calender-single'>
																<Box className='meeting-schedule-date'>
																	<Typography className='schedule-date font-weight-normal'>
																		{item?.meetingStartDate}
																	</Typography>
																	<Typography className='schedule-time font-weight-normal'>
																		{item?.meetingStartTime + ' - '}
																	</Typography>
																	<Typography className='schedule-time font-weight-normal'>
																		{item?.meetingEndTime}
																	</Typography>
																</Box>
															</Box>
														) : (
															<Box className='meeting-schedule-calender'>
																<Box className='meeting-schedule-date'>
																	<Typography className='schedule-date font-weight-normal'>
																		{item?.meetingStartDate + ' - '}
																	</Typography>
																	<Typography className='schedule-time font-weight-normal'>
																		{item?.meetingStartTime}
																	</Typography>
																	<Typography className='schedule-time font-weight-normal'>
																		{item?.meetingEndTime}
																	</Typography>
																</Box>
																<Typography className='meeting-to-txt font-weight-normal'>to</Typography>
																<Box className='meeting-schedule-date'>
																	<Typography className='schedule-date font-weight-normal'>
																		{item?.meetingEndDate + ' - '}
																	</Typography>
																	<Typography className='schedule-time font-weight-normal'>
																		{item?.meetingEndTime}
																	</Typography>
																</Box>
															</Box>
														)}

														{/* <Typography variant='h6'>
															{item?.meetingStartDate + ' ' + item?.meetingStartTime}
														</Typography>
														<Typography variant='h6'>{item?.meetingEndDate + ' ' + item?.meetingEndTime}</Typography> */}
													</Box>
												</Box>
											</Box>
											<Collapse
												className='mom-meeting-list-content'
												in={expandedList.indexOf(item?.oneToOneDetailId) !== -1}
											>
												{item?.isNotesTask ? (
													<RenderSelfDetails record={item} type={'Self'} indexVal={index} />
												) : (
													<Box className='meeting-participant-info'>
														<Box className='participants-no-data'>
															<Box className='no-task-content'>
																<Typography variant='h6'>{t('noNotesTask')}</Typography>
															</Box>
														</Box>
													</Box>
												)}
											</Collapse>
										</Box>
									);
								})}
								{/* tile repeat end */}
								{showLoadMore ? (
									<Box className='load-more-area'>
										<OKRButton
											className='load-more-btn btn-small-text'
											handleClick={() => {
												getAllMomDetailsAPICall(pageIndexVal || Enums.ZERO, '');
											}}
											text={
												loader ? (
													<Box className='spinner'>
														<Box className='bounce1'></Box>
														<Box className='bounce2'></Box>
														<Box className='bounce3'></Box>
													</Box>
												) : (
													t('Load more')
												)
											}
										/>
									</Box>
								) : (
									<></>
								)}
							</Box>
						) : null}
					</Box>
				</Box>
			</Box>
		</>
	);
};
