import React, { Fragment, useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AssessmentManageForm } from './AssessmentManageForm';
import { AssessmentAddForm } from './AssessmentAddForm';

export const AssessmentFormsDrawer: React.FC<any> = (props: any) => {
	const { assessmentTabSelected, setAssessmentTabSelected, openAssessmentForm } = props;
	const { t } = useTranslation();
	const handleTabChange = (event: any, newValue: Number) => {
		setAssessmentTabSelected({ activeTab: newValue });
	};
	function a11yProps(index: number) {
		return {
			id: `assessment-tabpanel-${index}`,
			'aria-controls': `assessment-tabpanel-${index}`,
		};
	}

	return (
		<Fragment>
			{openAssessmentForm?.ratingFormType === 'direct' ? (
				<>
					<Tabs className='okr-tabs drawer-tabs' value={0} onChange={handleTabChange} aria-label='Admin Tabs'>
						<Tab label={t('assessmentFormLevel')} value={0} {...a11yProps(0)} />
					</Tabs>
					<Box className='drawer-tabs-panel'>
						<form className='drawer-form-fields' noValidate autoComplete='off'>
							<AssessmentManageForm {...props} />
						</form>
					</Box>
				</>
			) : (
				<>
					<Tabs
						className='okr-tabs drawer-tabs'
						value={assessmentTabSelected?.activeTab || 0}
						onChange={handleTabChange}
						aria-label='Admin Tabs'
					>
						<Tab label={t('manageForms')} value={0} {...a11yProps(0)} />
						<Tab
							label={
								assessmentTabSelected?.cloneDetails
									? t('cloneForms')
									: assessmentTabSelected?.editDetails
									? t('editForms')
									: t('addForm')
							}
							value={1}
							{...a11yProps(1)}
						/>
					</Tabs>
					<Box className='drawer-tabs-panel'>
						<form className='drawer-form-fields' noValidate autoComplete='off'>
							{assessmentTabSelected?.activeTab === 0 && (
								<>
									<AssessmentManageForm {...props} />
								</>
							)}
							{assessmentTabSelected?.activeTab === 1 && (
								<>
									<AssessmentAddForm {...props} />
								</>
							)}
						</form>
					</Box>
				</>
			)}
		</Fragment>
	);
};
