import * as actionTypes from './actionTypes';
import {
	globalSearchAPI,
	globalSearchStringAPI,
	globalSearchAPIWithTeamAPI,
	searchEmployeeRoleAPI,
	searchTeamEmployeeAPI,
	recognitionSearchAPI,
	recognitionSearchAPIData
} from '../services/GlobalSearchService';

const searchPending = () => ({
	type: actionTypes.SEARCH_PENDING,
});
export const searchCompleted = (data) => ({
	type: actionTypes.SEARCH_SUCCESS,
	payload: data,
});
export const searchFailed = () => ({
	type: actionTypes.SEARCH_FAILED,
});

export const searchEmployee = (searchStr, page, pageSize) => {
	return (dispatch) => {
		dispatch(searchPending());
		return globalSearchAPI(searchStr, page, pageSize);
	};
};

export const searchEmployeeOnString = (searchStr, page, pageSize) => {
	return (dispatch) => {
		dispatch(searchPending());
		return globalSearchStringAPI(searchStr, page, pageSize);
	};
};
export const globalSearchAPIWithTeam = (searchStr, page, pageSize, type) => {
	return (dispatch) => {
		dispatch(searchPending());
		return globalSearchAPIWithTeamAPI(searchStr, page, pageSize, type);
	};
};

export const recognitionSearch = (searchStr, page, pageSize, type) => {
	return (dispatch) => {
		dispatch(searchPending());
		return recognitionSearchAPI(searchStr, page, pageSize, type);
	};
};

export const recognitionSearchWithTeam = (data) => {
	return () => {
		return recognitionSearchAPIData(data);
	};
};

export const searchTeamEmpAPI = (searchStr, teamId, page, pageSize) => {
	return (dispatch) => {
		dispatch(searchPending());
		return searchTeamEmployeeAPI(searchStr, teamId, page, pageSize);
	};
};

export const searchEmployeeInRole = (searchStr, page, pageSize) => {
	return (dispatch) => {
		dispatch(searchPending());
		return searchEmployeeRoleAPI(searchStr, page, pageSize);
	};
};
