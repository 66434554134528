import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	reloadCategory: 0,
	oneToOneSettings: null,
};

export default function adminPerformanceDevelopmentReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.PERFORMANCE_IMPROVEMENT_PLAN_STATUS:
			return {
				...state,
				performanceImprovementPlanStatusData: action.payload,
			};
		case actionTypes.RELOAD_FEEDBACK_CATEGORY_LIST:
			return {
				...state,
				reloadCategory: action.payload,
			};
		case actionTypes.FEEDBACK_ONE_TO_ONE_SETTINGS_SUCCESS:
			return {
				...state,
				oneToOneSettings: action.payload,
			};
		case actionTypes.FEEDBACK_ONE_TO_ONE_SETTINGS_FAILED:
			return {
				...state,
				oneToOneSettings: action.payload,
			};
		default:
			return state;
	}
}
