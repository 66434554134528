import React, { Fragment, useState, useEffect, useRef } from 'react';
import {
	Avatar,
	Box,
	Checkbox,
	ClickAwayListener,
	Grow,
	List,
	ListItem,
	ListItemAvatar,
	ListItemIcon,
	ListItemText,
	Paper,
	Popper,
	Typography,
	TextField,
	InputAdornment,
} from '@material-ui/core';
import {
	AtoZSortIcon,
	BottomArrowIcon,
	SearchIcon,
	TeamUserIcon,
	UserReportingToIcon,
	ZtoASortIcon,
} from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import _ from 'lodash';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../../config/utils';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../../config/enums';
import { NoSearchRecord } from '../CommonComponent/NoSearchRecord';

export const UserTeamSearchFilter: React.FC<any> = (props) => {
	const { allOrganisations, sortingColumn, sortOrder, setSortOrder, setSelectedTeams, setSortingColumn, setPageIndex } =
		props;
	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [noRecord, setNoRecord] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [organisations, setOrganisations] = useState<any>([]);
	const [organisationsCopy, setOrganisationsCopy] = useState<any>([]);
	const [searchTeamText, setSearchTeamText] = useState<any>('');
	const [teamError, setTeamError] = useState<any>('');
	const { t } = useTranslation();
	const [selectedOrganisation, setSelectedOrganisation] = useState<any>([]);
	const myRef = useRef<any>(null || {});

	const handleToggleFilter = (event?: any) => {
		if (event) {
			event.stopPropagation();
			setAnchorEl(event.currentTarget);
		}
		setOpenFilter((prev) => !prev);
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};

	useEffect(() => {
		if (openFilter && allOrganisations && !allOrganisations.length) {
			getOrganisations();
		}
	}, [openFilter]);

	useEffect(() => {
		if (allOrganisations.length) {
			updateOrganisation();
		}
	}, [allOrganisations]);

	const updateOrg = (orgs: any) => {
		let result = allOrganisations.map((item: any) => {
			return {
				...item,
				isChecked: (orgs.find((org: any) => org.teamId === item.teamId) || {}).isChecked || false,
			};
		});
		return _.orderBy(result, 'isChecked', ['desc']);
	};

	const updateOrganisation = () => {
		setOrganisations(updateOrg);
		setOrganisationsCopy(updateOrg);
	};

	const getOrganisations = () => {
		props.getAllOrganisations();
	};

	/** Handle teams checkbox */
	const handleOrganisationCheck = (event: React.ChangeEvent<HTMLInputElement>, teamId: number) => {
		const { checked } = event.target;
		let org = [...organisations];
		let filterOrganisationById = org.map((item: any) => {
			if (item.teamId === teamId) {
				return {
					...item,
					isChecked: checked,
				};
			}
			return item;
		});

		//Update copy object
		let copyArr = organisationsCopy.map((item: any) => {
			if (item.teamId === teamId) {
				return {
					...item,
					isChecked: checked,
				};
			}
			return item;
		});
		let getCheckedOrgs = _.filter(filterOrganisationById, ['isChecked', true]);
		setSelectedOrganisation(getCheckedOrgs);
		let sortByChecked = _.orderBy(filterOrganisationById, 'isChecked', ['desc']);
		setOrganisations(sortByChecked);
		setOrganisationsCopy(copyArr);
		setTeamError('');
		myRef.current.scrollTo(0, 0);
	};

	/**
	 * Handle search button click
	 * @param event HTML event
	 */
	const searchUserByOrganisation = (event?: React.MouseEvent) => {
		let org = [...organisationsCopy];
		let setOrder = _.orderBy(org, 'isChecked', ['desc']);
		let getFilterList = _.filter(org, 'isChecked');
		setSearchTeamText('');
		setSortingColumn(Enums.USER_TEAM_SORTING);
		setSortOrder('ASC');
		if (getFilterList.length > 0) {
			let getAllSelectedIds = _.map(getFilterList, 'teamId');
			setOrganisations(setOrder);
			props.fetchUsers({
				listingPageIndex: 1,
				lisingPageSize: props.pageSize,
				sortingColumn: Enums.USER_TEAM_SORTING,
				sortOrder: sortOrder,
				teamToFilter: getAllSelectedIds,
			});
			setSelectedTeams(getAllSelectedIds);
			setPageIndex(1);
			handleToggleFilter();
		} else {
			props.fetchUsers({
				listingPageIndex: 1,
				lisingPageSize: props.pageSize,
				sortingColumn: Enums.USER_TEAM_SORTING,
				sortOrder: sortOrder,
				teamToFilter: [],
			});
			setPageIndex(1);
			handleToggleFilter();
			setSelectedTeams([]);
			// setTeamError(t('selectOrg'));
		}
	};

	const sortTeams = (e: any, team: any, sortType: string) => {
		let org = [...organisationsCopy];
		let setOrder = _.orderBy(org, 'isChecked', ['desc']);
		let getFilterList = _.filter(org, 'isChecked');
		setSearchTeamText('');

		let getAllSelectedIds = _.map(getFilterList, 'teamId');
		setOrganisations(setOrder);
		setSortingColumn(Enums.USER_TEAM_SORTING);
		setSortOrder(sortType);
		props.fetchUsers({
			listingPageIndex: 1,
			lisingPageSize: props.pageSize,
			sortingColumn: Enums.USER_TEAM_SORTING,
			sortOrder: sortType,
			teamToFilter: getAllSelectedIds || [],
		});
		setSelectedTeams(getAllSelectedIds);
		setPageIndex(1);
	};

	const handleOrganisationSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		if (value === '') {
			let sortByChecked = _.orderBy(organisationsCopy, 'isChecked', ['desc']);
			setOrganisations(sortByChecked);
			setSearchTeamText(value);
			setNoRecord(false);
			return;
		}
		setSearchTeamText(value);
		let org = [...organisationsCopy];
		let filteredOrganisation = org.filter((item: any) => {
			if (`${item.teamName}`.toLowerCase().includes(value.toLowerCase())) {
				return item;
			}
		});
		if (!filteredOrganisation.length) {
			setNoRecord(true);
		} else {
			setNoRecord(false);
		}
		setOrganisations(filteredOrganisation);
	};

	const handleClearAllOrg = (event: any) => {
		let newOrgs = [];
		newOrgs = organisationsCopy.map((item: any) => {
			return {
				...item,
				isChecked: false,
			};
		});
		setSelectedOrganisation([]);
		setSearchTeamText('');
		setOrganisations(newOrgs);
		setOrganisationsCopy(newOrgs);
	};

	return (
		<Fragment>
			<span className='sortable-label'>
				<span onClick={handleToggleFilter}>
					<span className='txt-wrap'>{t('teamLabel')}</span>
					<OKRButton className='team-filter-btn' id='team-filter-btn' icon={<BottomArrowIcon />} />
				</span>
			</span>
			{openFilter ? (
				<Box className='user-search-filter-popover' id='filtersPopper'>
					<ClickAwayListener onClickAway={handleClose}>
						<Paper>
							<Box className='sorting-options'>
								<List disablePadding>
									<ListItem
										id='sort-atoz-label'
										onClick={(e) => {
											sortTeams(e, 'team', 'asc');
											setSortOrder('asc');
											handleToggleFilter(e);
										}}
									>
										<ListItemIcon>
											<AtoZSortIcon />
										</ListItemIcon>
										<ListItemText disableTypography>
											<Typography>{t('sortAtoZLabel')}</Typography>
										</ListItemText>
									</ListItem>
									<ListItem
										id='sort-ztoa-label'
										onClick={(e) => {
											sortTeams(e, 'team', 'desc');
											setSortOrder('desc');
											handleToggleFilter(e);
										}}
									>
										<ListItemIcon>
											<ZtoASortIcon />
										</ListItemIcon>
										<ListItemText disableTypography>
											<Typography>{t('sortZtoALabel')}</Typography>
										</ListItemText>
									</ListItem>
								</List>
								{/* <Typography>Select All</Typography>
									<Checkbox indeterminate={selectAllOrganisation === null} checked={selectAllOrganisation} onChange={(e) => handleSelectAllOrg(e)} /> */}
							</Box>
							<Box className='team-search-bar'>
								<TextField
									className='team-search-field'
									id='team-src-field'
									fullWidth
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<SearchIcon />
											</InputAdornment>
										),
									}}
									value={searchTeamText}
									onChange={handleOrganisationSearch}
								/>
							</Box>
							<Box className='team-list-actions'>
								{organisations && organisations.length > 0 && selectedOrganisation.length > 0 ? (
									<Box display='flex' alignItems='center'>
										{/* <Checkbox
													onChange={(e) => handleSelectAllOrg(e)}
													checked={selectAllOrganisation}
													icon={<BorderCheckboxIcon />}
													checkedIcon={<CheckedIcon />}
													indeterminateIcon={<UnCheckedIcon />}
													indeterminate={selectAllOrganisation === null}
													/> */}

										<OKRButton
											className='cancle-btn'
											id='cancel-btn'
											text={t('clearSelectedItems')}
											handleClick={(e) => handleClearAllOrg(e)}
										/>
									</Box>
								) : (
									<Box></Box>
								)}
								{!noRecord && (
									<OKRButton
										className='search-btn-dummy'
										id={'apply-btn'}
										handleClick={(e) => searchUserByOrganisation(e)}
										text={'Apply'}
									/>
								)}
							</Box>
							<Box className='user-team-lists'>
								<List ref={myRef} className='user-team-lists-inner' component='div' disablePadding>
									{organisations &&
										organisations.length > 0 &&
										organisations.map((organisation: any, index: any) => (
											<ListItem component='div' key={index} id={`team-list-${index}`}>
												<ListItemIcon>
													<Checkbox
														onChange={(e) => handleOrganisationCheck(e, organisation.teamId)}
														checked={organisation.isChecked || false}
														icon={<BorderCheckboxIcon />}
														checkedIcon={<CheckedIcon />}
														id={`org-checkbox-${index}`}
													/>
												</ListItemIcon>
												<ListItemText className='team-details' disableTypography>
													<ListItemAvatar>
														<Avatar
															className='team-avatar'
															style={{
																backgroundColor: getRandomBackgroundColor(
																	organisation.employeeTeamResponses?.backGroundColorCode
																),
																color: getRandomColor(organisation.employeeTeamResponses?.colorCode),
															}}
															src={organisation.orgLogo || ''}
														>
															{getUserName({
																firstName: '',
																lastName: '',
																fullName: organisation.teamName,
															})}
														</Avatar>
													</ListItemAvatar>
													<Box className='team-info'>
														<Typography variant='h4'>{organisation.teamName}</Typography>
														<Box display='flex'>
															<Typography variant='subtitle2'>
																<TeamUserIcon /> {organisation.employeeCount}
															</Typography>
															<Typography variant='subtitle2'>
																<UserReportingToIcon />
																{organisation.teamHeadName}
															</Typography>
														</Box>
													</Box>
												</ListItemText>
											</ListItem>
										))}

									{noRecord && <NoSearchRecord />}

									<Typography>{teamError}</Typography>
								</List>
							</Box>
						</Paper>
					</ClickAwayListener>
				</Box>
			) : (
				<></>
			)}
		</Fragment>
	);
};
