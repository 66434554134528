import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemText, List } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { OKRButton } from '../../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../../config/svg/CommonSvgIcon';

export const ReversalStageDropdown: React.FC<any> = (props: any) => {
	const { handleChange, statusValue, type, reversalStatus, errorMessage } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [selectedValue, setSelectedValue] = useState<any>({});
	const [optionsList, setOptionsList] = useState<any>([]);
	const optionsListTypeOne = [{ status: 0, statusName: 'Not Set (blank)' }];
	const optionsListTypeTwo = [
		{ status: 0, statusName: 'Not Set (blank)' },
		{ status: 1, statusName: 'Draft' },
		{ status: 2, statusName: 'Sent for Approval' },
	];

	useEffect(() => {
		setOptionsList(type === 1 ? optionsListTypeOne : reversalStatus);
	}, [type, reversalStatus]);
	useEffect(() => {
		if (statusValue && statusValue?.status) {
			setSelectedValue(statusValue);
		} else {
			setSelectedValue({});
		}
	}, [statusValue]);
	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};

	const handleSelection = (selectedValue: any) => {
		handleClickAway();
		setSelectedValue(selectedValue);
		handleChange(selectedValue);
	};
	return (
		<Box
			className={`okr-team-dropdown response-type-dropdown  ${open ? 'active' : ''} ${
				errorMessage || statusValue?.status === 0 ? 'helperText-bottom' : ''
			}`}
		>
			<OKRButton
				className={'dropdown-button'}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				disabled={false}
				text={
					selectedValue && selectedValue?.statusName
						? selectedValue?.statusName
						: statusValue?.status === 0
						? ' No option - Cannot be reverted'
						: t('Select Status')
				}/>
			{errorMessage && errorMessage !== '' ? <p className='MuiFormHelperText-root Mui-error'>{errorMessage}</p> : <></>}
			{type !== 1 && statusValue?.status === 0 ? (
				<p className='MuiFormHelperText-root Mui-error'>{'This is the first stage, no reversal possible'}</p>
			) : (
				<></>
			)}
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								{optionsList.length > 0 ? (
									<List component='div' disablePadding>
										{optionsList?.map((item: any) => (
											<ListItem
												component='div'
												className={selectedValue?.status === item?.status ? 'active' : ''}
												onClick={() => {
													handleSelection(item);
												}}
											>
												<ListItemText>{item.statusName}</ListItemText>
											</ListItem>
										))}
									</List>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
