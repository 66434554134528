import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	sigInAction,
	signInActionDB,
	tenantAction,
	tenantActionOKR,
	orgCurrentCycleAction,
} from '../../../action/signin';
import CustomLogin from './../../../components/Guest/CustomDomain/index';

const mapStateToProps = (state) => {
	return {
		signInStatus: state.signReducer.signInStatus,
		pending: state.signReducer.pending,
		messageList: state.signReducer.messageList,
		entity: state.signReducer.entity,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			tenantAction,
			tenantActionOKR,
			sigInAction,
			signInActionDB,
			orgCurrentCycleAction,
		},
		dispatch
	);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomLogin));
