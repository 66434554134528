import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, InputAdornment, TextField, Typography } from '@material-ui/core';

import { AddIconSvg } from '../../../../config/svg/CommonSvg';
import CkEditor from '../../../Common/CkEditor/CkEditor';
import { OKRButton } from '../../../Common/OKRButton';

export const FeedbackReviewerCommentForm = (props: any) => {
	const {
		loader,
		reviewFormData,
		handleChangeCommentValue,
		handleCancelCommentClick,
		handleSubmitCommentClick,
		showEditor,
		setShowEditor,
	} = props;
	const { t } = useTranslation();

	return (
		<>
			{!showEditor && (
				<TextField
					id='add-comment-field'
					className='add-comment-field'
					value={''}
					fullWidth
					placeholder={t('Write a comment')}
					InputProps={{
						startAdornment: (
							<InputAdornment position='start'>
								<AddIconSvg />
							</InputAdornment>
						),
					}}
					onClick={(e) => {
						setShowEditor(true);
					}}
				/>
			)}
			{showEditor && (
				<>
					<Box className='reviewer-post-editor-box'>
						{' '}
						<Box className='editor-pane'>
							<Box className='cfr-control-section' id='rteImage'>
								<CkEditor
									{...props}
									// enableTagging={true}
									// focusOnLoad={true}
									// placeholder={''}
									// handleEditorChange={(value: string) => {}}
									// uploadCase={'CONVERSATION'}
									// uploadType={'3'}
									// uploadImage={true}
									// showEmoticons={true}

									placeholder={t('Write your comment')}
									showEmoticons={true}
									value={reviewFormData?.comments || ''}
									handleEditorChange={(value: string) => {
										if (reviewFormData?.comments !== value) {
											handleChangeCommentValue(value);
										}
									}}
								/>
								{reviewFormData?.commentsError ? (
									<Typography className='error-field'>{reviewFormData?.commentsError}</Typography>
								) : (
									<></>
								)}
							</Box>
						</Box>
						<Box className='reviewer-post-action'>
							<OKRButton
								disabled={Boolean(loader)}
								className='btn-link'
								text={t('cancelBtn')}
								handleClick={handleCancelCommentClick}
							/>
							<OKRButton
								disabled={Boolean(loader)}
								className='btn-primary'
								text={t('postLabel')}
								handleClick={handleSubmitCommentClick}
							/>
						</Box>
					</Box>
				</>
			)}
		</>
	);
};
