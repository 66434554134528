import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { EditIcon } from '../../../config/svg/CommonSvg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import GlobalSearch from '../../Common/GlobalSearch';
import { OKRButton } from '../../Common/OKRButton';
import { SOObjectiveList } from './SOObjectiveList';
import NofoundImg from '../../../images/no-found-conversation.svg';
import { CancelIcon } from '../../../config/svg/Action';
import { useDispatch } from 'react-redux';
import { globalSearchAPIWithTeam, searchEmployee } from '../../../action/search';
import { getUserById, updatePage } from '../../../action/common';
import { getCycleDefaultSelection, getFullName, getUserName } from '../../../config/utils';

export const SearchSOPeopleOkr: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const {
		objectiveListPeopleTab,
		setObjectiveListPeopleTab,
		cycleId,
		cycleDetail,
		currentCycleYear,
		cycleDuration,
		getObjectiveListBasedOnCycleChange,
		okrMasterData,
		getObjectiveListForPeopleSearch,
		getLinkedKeyResultCount,
		setIsDetailsEdited,
		soPermissionDetails,
	} = props;
	const dispatch = useDispatch();

	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [searchText, setSearchText] = useState('');
	const [currentCycleSelection, setCurrentCycleSelection] = useState<any[]>([]);
	const [myGoalList, setMyGoalList] = useState<any>(null);
	const [selectedUseDetail, setSelectedUseDetail] = useState<any>(null);

	useEffect(() => {
		if (cycleDetail && cycleDetail.cycleDetails && cycleDetail.cycleDetails.length) {
			let currentQuarterDetails: any = getCycleDefaultSelection(cycleDetail.cycleDetails, cycleId);
			if (currentQuarterDetails) {
				setSelectedCycleDetails({ ...currentQuarterDetails });
				setIsCurrentCycle({ ...currentQuarterDetails });
				setCurrentSelectedCycleYear(currentQuarterDetails?.year || currentCycleYear);
				setCurrentCycleId(currentQuarterDetails?.organisationCycleId || currentCycleId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (objectiveListPeopleTab && objectiveListPeopleTab.length) {
			const filterData =
				objectiveListPeopleTab && objectiveListPeopleTab.length
					? objectiveListPeopleTab.filter(
							(item: any) =>
								item?.customDetails?.cycleId === selectedCycleDetails?.organisationCycleId &&
								item?.customDetails?.employeeId === selectedUseDetail?.employeeId
					  )
					: [];
			setMyGoalList(filterData);
		} else {
			setMyGoalList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveListPeopleTab, selectedCycleDetails]);

	const handleCycleChange = (id: any, quarterData: any, year: any) => {
		updateCycleDetails(quarterData, year);
	};
	const updateCycleDetails = (quarterData: any, year: any) => {
		quarterData?.organisationCycleId && setCurrentCycleId(quarterData?.organisationCycleId);
		year && setCurrentSelectedCycleYear(year);
		setSelectedCycleDetails({ ...quarterData, year });
		const selectedCycleDetail = {
			...quarterData,
			year,
		};
		getObjectiveListForPeopleSearch(selectedCycleDetail, 'PeopleTab', selectedUseDetail?.employeeId || 0);
	};

	const handlePeopleEdit = () => {
		setSelectedUseDetail(null);
		setMyGoalList([]);
	};
	const onSelectedUser = async (selected: any) => {
		if (selected.length > 0) {
			const userDetails = { ...selected[0] };
			setSelectedUseDetail(userDetails);
			getObjectiveListForPeopleSearch(selectedCycleDetails, 'PeopleTab', userDetails?.employeeId || 0);
		} else {
			setSelectedUseDetail(null);
			setMyGoalList([]);
		}
	};
	const searchCustomEmployee = (searchStr: any, page: any, pageSize: any) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page: any) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr: any, page: any, pageSize: any, type: any) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data: any) => {
		return dispatch(getUserById(data));
	};

	const handleObjectiveKRLinkingChange = (event: any, linkingType: string, okr: any, kr: any) => {
		try {
			const { checked } = event?.target || {};
			let updatedMainList = objectiveListPeopleTab && objectiveListPeopleTab.length ? [...objectiveListPeopleTab] : [];
			// console.log('handleObjectiveKRLinkingChange', myGoalList, selectedCycleDetails, okr, kr);
			if (linkingType === 'OKR') {
				updatedMainList = [...updatedMainList].map((obj: any) => {
					const data = { ...obj };
					if (obj.goalObjectiveId === okr.goalObjectiveId) {
						data.linkedKrResponses =
							data.linkedKrResponses && data.linkedKrResponses.length
								? data.linkedKrResponses.map((krData: any) => {
										const krObj = { ...krData };
										krObj.isSelected = checked || false;
										krObj.isLinked = checked || false;

										return krObj;
								  })
								: [];

						data.isSelected = checked || false;
						data.isLinked = checked || false;
					}

					return { ...data };
				});
			} else if (linkingType === 'KR') {
				updatedMainList = [...updatedMainList].map((obj: any) => {
					const data = { ...obj };
					if (obj.goalObjectiveId === okr.goalObjectiveId) {
						data.linkedKrResponses =
							data.linkedKrResponses && data.linkedKrResponses.length
								? data.linkedKrResponses.map((krData: any) => {
										const krObj = { ...krData };
										if (krObj?.goalkeyId === kr?.goalkeyId) {
											krObj.isSelected = checked || false;
											krObj.isLinked = checked || false;
										}

										return krObj;
								  })
								: [];

						data.isSelected = false; // checked ? validateAllKRCheck(data) : false;
					}

					return { ...data };
				});
			} else {
				updatedMainList =
					objectiveListPeopleTab && objectiveListPeopleTab.length
						? [...objectiveListPeopleTab]
						: objectiveListPeopleTab;
			}
			setObjectiveListPeopleTab(updatedMainList);
			setIsDetailsEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const validateAllKRCheck = (okr: any) => {
		const krList = okr.linkedKrResponses && okr.linkedKrResponses.length ? okr.linkedKrResponses : [];
		const selectedKR = krList.filter((item: any) => item?.isSelected);

		return krList.length === 0 ? false : selectedKR.length === krList.length ? true : false;
	};

	// console.log('selectedUseDetail===>', selectedUseDetail);
	return (
		<>
			<Box className='drawer-form-fields'>
				{selectedUseDetail ? (
					<Box className='drawer-input-field import-src-people'>
						<Box className='searched-people'>
							<Box className='user-info'>
								<Box className='user-img'>
									{selectedUseDetail?.imagePath ? (
										<Avatar className='avatar-default' src={selectedUseDetail?.imagePath} />
									) : (
										<Avatar className='avatar-default'>{getUserName(selectedUseDetail || {})}</Avatar>
									)}
								</Box>
								<Box className='user-info-details'>
									<Typography variant='h4'>{getFullName(selectedUseDetail)}</Typography>
									{selectedUseDetail?.emailId && selectedUseDetail?.emailId.length > 100 ? (
										<Tooltip arrow title={selectedUseDetail?.emailId} aria-label='Email'>
											<Typography variant='h6'>{selectedUseDetail?.emailId.substring(0, 100)}...</Typography>
										</Tooltip>
									) : (
										<Typography variant='h6'>{selectedUseDetail?.emailId || ''}</Typography>
									)}
								</Box>
							</Box>
							<OKRButton
								handleClick={handlePeopleEdit}
								className='editIcon'
								icon={<CancelIcon />}
								title={t('cancelBtn')}
								tooltipPlacement={'top'}
							/>
						</Box>
					</Box>
				) : (
					<></>
				)}
				{selectedUseDetail ? (
					<></>
				) : (
					<Box className='drawer-input-field '>
						<GlobalSearch
							{...props}
							module={'myGoal'}
							customClass={'user-quick-search-type2'}
							placeHolderText={t('srcUser')}
							customId={'import-user-src'}
							fetchCycleUsers={true}
							onSelectedUser={onSelectedUser}
							multiple={false}
							defaultValue={null}
							defaultFocus={null}
							searchEmployee={searchCustomEmployee}
							updatePage={updateCustomPage}
							globalSearchAPIWithTeam={globalSearchWithTeam}
							getUserById={getCustomUserById}
						/>
					</Box>
				)}
				<>
					{selectedUseDetail ? (
						<SOObjectiveList
							cycleId={cycleId}
							cycleDetail={cycleDetail}
							currentCycleYear={currentCycleYear}
							cycleDuration={cycleDuration}
							getObjectiveListBasedOnCycleChange={getObjectiveListBasedOnCycleChange}
							okrMasterData={okrMasterData}
							selectedCycleDetails={selectedCycleDetails}
							currentCycleSelection={currentCycleSelection}
							currentCycleId={currentCycleId}
							currentSelectedCycleYear={currentSelectedCycleYear}
							handleCycleChange={handleCycleChange}
							isCurrentCycle={isCurrentCycle}
							searchText={searchText}
							setSearchText={setSearchText}
							myGoalList={myGoalList}
							setMyGoalList={setMyGoalList}
							handleObjectiveKRLinkingChange={handleObjectiveKRLinkingChange}
							getLinkedKeyResultCount={getLinkedKeyResultCount}
							soPermissionDetails={soPermissionDetails}
						/>
					) : myGoalList && myGoalList.length === 0 ? (
						<Box className='search-no-record-global'>
							<Box className='no-record-message'>
								<Typography variant='h2' component='h2'>
									{t('peopleOkrHead')}
								</Typography>
								<Typography dangerouslySetInnerHTML={{ __html: t('peopleSoOkrDes') }}></Typography>
								<AnimatedIcon
									className=''
									width='230'
									type='image/svg+xml'
									alt={t('globalSearchNoRecord')}
									imagePath={NofoundImg}
								/>
							</Box>
						</Box>
					) : (
						<></>
					)}
				</>
			</Box>
		</>
	);
};
