import React, { useState, useEffect } from 'react';
import { Avatar, Box, ClickAwayListener, List, ListItem, Typography, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { MissionVisionIcon } from '../../../config/svg/CommonSvg';
import { RequestFeedbackAgain } from '../../../config/svg/PersonalFeedbackSvg';
import NoTeamMemberImg from '../../../images/no-direct-reportees.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { getFullName, getMonthDateYear, getUserName } from '../../../config/utils';
import AlertDialog from '../../Common/Dialog';

export const TeamListRightPanel: React.FC<any> = (props: any) => {
	const { oneOnOneTeamList, handleRequestOneOnOneCall, handleQuickLaunchCall, handleEmployeeClick } = props;

	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [selectedTeam, setSelectedTeam] = useState<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });

	useEffect(() => {
		if (oneOnOneTeamList && oneOnOneTeamList.length > 0) {
			setSelectedTeam(oneOnOneTeamList[0]);
		}
	}, [oneOnOneTeamList]);

	const handleClickAway = () => {
		setOpen(false);
	};
	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleTeamSelectionClick = (event: any, record: any) => {
		event.preventDefault();
		setOpen(false);
		setSelectedTeam(record);
	};
	const handleQuickLaunchClick = (event: any, empRecord: any) => {
		event.preventDefault();
		// console.log('handleQuickLaunchClick ==>', empRecord, selectedTeam);
		setModalProps({ open: false, type: '', message: '', details: '' });
		handleQuickLaunchCall(event, empRecord);
	};
	const handleRequestOneOnOneClick = (event: any, empRecord: any) => {
		event.preventDefault();
		// console.log('handleRequestOneOnOneClick ==>', empRecord, selectedTeam);
		handleRequestOneOnOneCall(event, empRecord);
	};
	const handleClickQuickLink = (e: any, record: any) => {
		// setModalProps({
		// 	open: true,
		// 	type: 'requestStartLaunch',
		// 	message: t('alertLaunchStart'),
		// 	details: record,
		// });
		record && handleQuickLaunchClick(e, record);
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'requestStartLaunch') {
				handleQuickLaunchClick(e, modalProps?.details);
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	return (
		<Box className={'team-list-right-panel'}>
			{oneOnOneTeamList && oneOnOneTeamList.length > 0 ? (
				<Box className='team-box-head'>
					<Typography variant='h5'>{t('selectTeamLabel')}:</Typography>
					<Box className={`custom-dropdown custom-type-dropdown-top ${open ? 'active' : ''}`}>
						<OKRButton
							className={'dropdown-button button-placeholder'}
							text={
								(
									<>
										<span className='team-name-txt'>{selectedTeam?.teamName}</span>
									</>
								) || ''
							}
							icon={<BottomArrowIcon />}
							handleClick={() => handleClick()}
						></OKRButton>
						{open && (
							<ClickAwayListener onClickAway={handleClickAway}>
								<Box className='ques-type-dropdown'>
									<List component='div' disablePadding>
										{oneOnOneTeamList?.map((item: any) => (
											<ListItem
												component='div'
												key={item.teamId}
												onClick={(e: any) => handleTeamSelectionClick(e, item)}
												className={selectedTeam?.teamName === item?.teamName ? 'active' : ''}
											>
												<Typography>{item?.teamName || ''}</Typography>
											</ListItem>
										))}
									</List>
								</Box>
							</ClickAwayListener>
						)}
					</Box>
				</Box>
			) : (
				<></>
			)}
			<Box className='team-member-list'>
				{selectedTeam && selectedTeam?.oneToOneTeamEmployees && selectedTeam?.oneToOneTeamEmployees.length > 0 ? (
					<>
						{/* <Box className='team-list-head'>
							<List component='ul' disablePadding>
								<ListItem className='team-col1'>
									<Typography variant='h6' id={'my-team'}>
										{t('myTeamsLabel')}
									</Typography>
								</ListItem>
								<ListItem className='team-col2'></ListItem>
							</List>
						</Box> */}
						<Box className='team-list-area'>
							{selectedTeam?.oneToOneTeamEmployees.map((item: any) => (
								<Box className='team-list-row' key={`selected_team_employee_${item?.employeeId}`}>
									<List component='ul' disablePadding>
										<ListItem className='team-col1' onClick={(e: any) => handleEmployeeClick(e, item)}>
											<Box className='user-info'>
												<Box className='user-img'>
													{/* <Avatar>SH</Avatar> */}
													{Boolean(item.imagePath) ? (
														<Avatar alt={`${item?.firstName} ${item?.lastName}`} src={item.imagePath} />
													) : (
														<Avatar>{getUserName(item)}</Avatar>
													)}
												</Box>
												<Box className='user-info-details'>
													{/* <Typography variant='h4'>
														{item?.firstName || ''} {item?.lastName || ''}
													</Typography> */}
													{/* {getFullName(item) && getFullName(item).length > 20 ? (
														<Tooltip arrow title={getFullName(item) || ''}>
															<Typography variant='h4'>{getFullName(item).substring(0, 20)}...</Typography>
														</Tooltip>
													) : ( */}
													<Box>
														<Tooltip arrow title={getFullName(item) || ''}>
															<Typography variant='h4'>{getFullName(item) || ''}</Typography>
														</Tooltip>
													</Box>
													{/* )} */}
													{/* {item?.emailId && item?.emailId.length > 26 ? (
														<Tooltip arrow title={item?.emailId || ''}>
															<Typography variant='subtitle2'>{item?.emailId.substring(0, 26)}...</Typography>
														</Tooltip>
													) : ( */}
													<Box>
														<Tooltip arrow title={item?.emailId || ''}>
															<Typography variant='subtitle2'>{item?.emailId || ''}</Typography>
														</Tooltip>
													</Box>
													{/* )} */}
													{item?.lastMeetingDate ? (
														<Typography variant='h6'>
															<span className='last-meeting-date'>
																{t('lastMeeting')} : {getMonthDateYear(item?.lastMeetingDate)}
															</span>
														</Typography>
													) : (
														<></>
													)}
												</Box>
											</Box>
										</ListItem>
										<ListItem className='team-col2'>
											<OKRButton
												handleClick={(e: any) => handleClickQuickLink(e, item)}
												className={'icon-btn icon-btn-blue'}
												icon={<MissionVisionIcon />}
												title={t('quickLaunch')}
											/>
											<OKRButton
												handleClick={(e: any) => handleRequestOneOnOneClick(e, item)}
												className={'icon-btn icon-btn-blue'}
												icon={<RequestFeedbackAgain />}
												title={t('sendRequestOneOnOne')}
											/>
										</ListItem>
									</List>
								</Box>
							))}
						</Box>
					</>
				) : selectedTeam && selectedTeam?.oneToOneTeamEmployees && selectedTeam?.oneToOneTeamEmployees.length === 0 ? (
					<Box className='no-record'>
						<Typography variant='h2'>{'No members in this team'}</Typography>

						<AnimatedIcon
							className='no-record-img'
							width={'300'}
							type='image/svg+xml'
							alt={'No Conversation Available'}
							imagePath={NoTeamMemberImg}
						/>
					</Box>
				) : (
					<></>
				)}
				{oneOnOneTeamList && oneOnOneTeamList.length === 0 ? (
					<Box className='no-record'>
						<Typography variant='h2'>{'No members in this team'}</Typography>

						<AnimatedIcon
							className='no-record-img'
							width={'300'}
							type='image/svg+xml'
							alt={'No Conversation Available'}
							imagePath={NoTeamMemberImg}
						/>
					</Box>
				) : (
					<></>
				)}
			</Box>
			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</Box>
	);
};
