import {
	PERFORMANCE_IMPROVEMENT_PLAN_EMAIL_CONF,
	PERFORMANCE_IMPROVEMENT_PLAN_SETTING,
	PIP_RESOLVE_PLAN_STATUS,
	PERFORMANCE_IMPROVEMENT_PLAN_STATUS_ENABLE,
	FEEDBACK_SETTING,
	FEEDBACK_STATUS,
	FEEDBACK_STATUS_ENABLE,
	PERFORMANCE_IMPROVEMENT_PLAN_UPDATE_EMAIL_CONF,
	FEEDBACK_TYPE,
	FEEDBACK_TYPE_ENABLE,
	FEEDBACK_CATEGORY,
	FEEDBACK_CATEGORY_ENABLE,
	FEEDBACK_ONETOONE_SETTINGS,
	PERFORMANCE_IMPROVEMENT_PLAN_EMAILRECIPIENT,
	PERFORMANCE_IMPROVEMENT_PLAN_UPDATE_EMAILRECIPIENT,
} from '../config/api-url';
import { deleteRequest, getRequest, postRequest, putRequest } from '../config/auth';

export const getEmailRecipientAPI = (data) =>
	getRequest(`${PERFORMANCE_IMPROVEMENT_PLAN_EMAILRECIPIENT}?${data}`, data);
export const updateEmailRecipientAPI = (data) => postRequest(PERFORMANCE_IMPROVEMENT_PLAN_UPDATE_EMAILRECIPIENT, data);

export const getPerformancePlanSettingAPI = (data) => getRequest(PERFORMANCE_IMPROVEMENT_PLAN_SETTING, data);
export const updatePerformancePlanSettingAPI = (data) => postRequest(PERFORMANCE_IMPROVEMENT_PLAN_SETTING, data);

export const getPerformancePlanStatusAPI = (data) => getRequest(PIP_RESOLVE_PLAN_STATUS, data);
export const insertPerformancePlanStatusAPI = (data) => postRequest(PIP_RESOLVE_PLAN_STATUS, data);
export const updatePerformancePlanStatusAPI = (data) => putRequest(PIP_RESOLVE_PLAN_STATUS, data);
export const deletePerformancePlanStatusAPI = (data) => deleteRequest(`${PIP_RESOLVE_PLAN_STATUS}?${data}`, data);

export const updatePerformancePlanStatusEnableAPI = (data) =>
	putRequest(PERFORMANCE_IMPROVEMENT_PLAN_STATUS_ENABLE, data);

export const getPerformancePlanEmailConfAPI = (data) => getRequest(PERFORMANCE_IMPROVEMENT_PLAN_EMAIL_CONF, data);
export const updatedPerformancePlanEmailConfAPI = (data) =>
	postRequest(PERFORMANCE_IMPROVEMENT_PLAN_UPDATE_EMAIL_CONF, data);
// ============================================================================
// ============================================================================
export const getFeedbackSettingAPI = (data) => getRequest(FEEDBACK_SETTING, data);
export const updateFeedbackSettingAPI = (data) => postRequest(FEEDBACK_SETTING, data);

export const getFeedbackStatusAPI = (data) => getRequest(FEEDBACK_STATUS, data);
export const insertFeedbackStatusAPI = (data) => postRequest(FEEDBACK_STATUS, data);
export const updateFeedbackStatusAPI = (data) => putRequest(FEEDBACK_STATUS, data);
export const deleteFeedbackStatusAPI = (data) => deleteRequest(`${FEEDBACK_STATUS}?${data}`, data);

export const updateFeedbackStatusEnableAPI = (data) => putRequest(FEEDBACK_STATUS_ENABLE, data);

export const getFeedbackTypeAPI = (data) => getRequest(FEEDBACK_TYPE, data);
export const insertFeedbackTypeAPI = (data) => postRequest(FEEDBACK_TYPE, data);
export const updateFeedbackTypeAPI = (data) => putRequest(FEEDBACK_TYPE, data);
export const deleteFeedbackTypeAPI = (data) => deleteRequest(`${FEEDBACK_TYPE}?${data}`, data);

export const updateFeedbackTypeEnableAPI = (data) => putRequest(FEEDBACK_TYPE_ENABLE, data);

export const getFeedbackCategoryAPI = (data) => getRequest(`${FEEDBACK_CATEGORY}?${data}`, data);
export const insertFeedbackCategoryAPI = (data) => postRequest(FEEDBACK_CATEGORY, data);
export const updateFeedbackCategoryAPI = (data) => putRequest(FEEDBACK_CATEGORY, data);
export const deleteFeedbackCategoryAPI = (data) => deleteRequest(`${FEEDBACK_CATEGORY}?${data}`, data);

export const updateFeedbackCategoryEnableAPI = (data) => putRequest(FEEDBACK_CATEGORY_ENABLE, data);

export const getFeedbackOneToOneSettingAPI = (data) => getRequest(FEEDBACK_ONETOONE_SETTINGS, data);
export const saveFeedbackOneToOneSettingAPI = (data) => postRequest(FEEDBACK_ONETOONE_SETTINGS, data);
