import React, { useEffect, useState } from 'react';
import { Box, ListItem, ListItemText, List, ClickAwayListener } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../../../config/svg/CommonSvgIcon';

export const FeedbackCategoryDropdown: React.FC<any> = (props: any) => {
	const { feedbackTypesOptions, setSelectedValue, selectedValue } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [selectedData, setSelectedData] = useState<any>({});

	useEffect(() => {
		if (selectedValue && feedbackTypesOptions.length > 0) {
			let selectedRecord = feedbackTypesOptions.filter((item: any) => item.id === selectedValue);
			if (selectedRecord && selectedRecord.length > 0) {
				setSelectedData(selectedRecord[0]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const handleSelection = (selectedValue: any) => {
		handleClickAway();
		setSelectedValue(selectedValue.id);
	};

	return (
		<Box className={`okr-team-dropdown feedback-type-dropdown  ${open ? 'active' : ''} `}>
			<OKRButton
				className={'dropdown-button'}
				disabled={false}
				icon={<BottomArrowIcon />}
				handleClick={handleClick}
				text={selectedData && selectedData?.name ? selectedData?.name : t('selectLabel')}
			/>
			{open && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='team-dropdown'>
						<Box className='team-dropdown-inner'>
							<Box className='dropdown-multi-list-sub-panel'>
								{feedbackTypesOptions.length > 0 ? (
									<List component='div' disablePadding>
										{feedbackTypesOptions?.map((item: any) => (
											<ListItem
												component='div'
												className={selectedValue?.name === item?.name ? 'active' : ''}
												onClick={() => {
													handleSelection(item);
												}}
											>
												<ListItemText>{item.name}</ListItemText>
											</ListItem>
										))}
									</List>
								) : (
									<></>
								)}
							</Box>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
