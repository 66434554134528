import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { OKRDrawer } from '../Common/OKRDrawer';
import { useTranslation } from 'react-i18next';
import { getFullName, getMonthDateYear, getUserName } from '../../config/utils';
import { Enums } from '../../config/enums';

export const OneOnOneDrawer: React.FC<any> = (props: any) => {
	const { openOneOnOneDrawer, handleDrawerClose, oneOnOneEntity } = props;
	const { t } = useTranslation();

	return (
		<Fragment>
			<OKRDrawer
				open={openOneOnOneDrawer}
				//loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel drawer-no-footer'}
				headerTitle={t('requestOnetoOne')}
				children={
					<Box className='drawer-inner-content'>
						<Box className='drawer-content-panel redirection-on-on-one-drawer'>
							<Box className='redirection-on-on-one-drawer-inner meeting-listing'>
								<Box className='okr-list-panel-head'>
									<List component='ul'>
										<ListItem className='meeting-col1'>
											<Typography variant='body2'>
												<span className='sortable-label'>
													<span className='txt-wrap'>{t('oneOnOneWith')}</span>
												</span>
											</Typography>
										</ListItem>

										<ListItem className='meeting-col3'>
											<Typography variant='body2'>
												<span className='sortable-label'>
													<span className='txt-wrap'>{t('scheduledOnText')}</span>
												</span>
											</Typography>
										</ListItem>
										<ListItem className='meeting-col4'>
											<Typography variant='body2'>
												<span className='sortable-label'>
													<span className='txt-wrap'>{t('lastModifiedLabel')}</span>
												</span>
											</Typography>
										</ListItem>
									</List>
								</Box>
								<Box
									className={`meeting-listing-content `}
									key={`one_on_one_request_item_${oneOnOneEntity?.oneToOneDetailId}`}
								>
									<Box className='one-on-one-tile'>
										<List component='ul'>
											<ListItem className='meeting-col1'>
												<Box className='user-info'>
													<Box className='user-img'>
														{Boolean(oneOnOneEntity.imagePath) ? (
															<Avatar
																alt={`${oneOnOneEntity?.firstName} ${oneOnOneEntity?.lastName}`}
																src={oneOnOneEntity.imagePath}
															/>
														) : (
															<Avatar>{getUserName(oneOnOneEntity)}</Avatar>
														)}
													</Box>
													<Box className='user-info-details'>
														{oneOnOneEntity?.firstName &&
														getFullName(oneOnOneEntity) &&
														getFullName(oneOnOneEntity).length > 50 ? (
															<Tooltip arrow title={getFullName(oneOnOneEntity) || ''}>
																<Typography variant='h4'>{getFullName(oneOnOneEntity).substring(0, 50)}...</Typography>
															</Tooltip>
														) : (
															<Typography variant='h4'>
																{oneOnOneEntity?.firstName || ''} {oneOnOneEntity?.lastName || ''}
															</Typography>
														)}
														{/* <Typography variant='h4'>
															{oneOnOneEntity?.firstName || ''} {oneOnOneEntity?.lastName || ''}
														</Typography> */}
														{oneOnOneEntity?.emailId && oneOnOneEntity?.emailId.length > 50 ? (
															<Tooltip arrow title={oneOnOneEntity?.emailId || ''}>
																<Typography variant='subtitle2'>
																	{oneOnOneEntity?.emailId.substring(0, 50)}...
																</Typography>
															</Tooltip>
														) : (
															<Typography variant='subtitle2'>{oneOnOneEntity?.emailId || ''}</Typography>
														)}
													</Box>
												</Box>
											</ListItem>

											<ListItem className='meeting-col3'>
												{/* <Box className='date-txt'>
													<Typography>
														{oneOnOneEntity?.meetingStartDate && oneOnOneEntity?.meetingStartTime
															? oneOnOneEntity?.meetingStartDate + ' ' + oneOnOneEntity?.meetingStartTime
															: ''}
													</Typography>
													<Typography>
														{oneOnOneEntity?.meetingEndDate && oneOnOneEntity?.meetingEndTime
															? oneOnOneEntity?.meetingEndDate + ' ' + oneOnOneEntity?.meetingEndTime
															: ''}
													</Typography>
												</Box> */}
												{oneOnOneEntity?.meetingStartDate === oneOnOneEntity?.meetingEndDate ? (
													<>
														<Box className='meeting-schedule-date meeting-schedule-single-date'>
															<Typography className='schedule-date'>{oneOnOneEntity?.meetingStartDate}</Typography>
															<Typography className='schedule-time'>
																{oneOnOneEntity?.meetingStartTime + ' - ' + oneOnOneEntity?.meetingEndTime}
															</Typography>
														</Box>
													</>
												) : (
													<>
														<Box className='meeting-schedule-date'>
															<Typography className='schedule-date'>
																{oneOnOneEntity?.meetingStartDate + ' - '}{' '}
															</Typography>
															<Typography className='schedule-time'>{oneOnOneEntity?.meetingStartTime}</Typography>
														</Box>
														<Box className='meeting-schedule-date'>
															<Typography className='schedule-date'>
																{oneOnOneEntity?.meetingEndDate + ' - '}
															</Typography>
															<Typography className='schedule-time'>{oneOnOneEntity?.meetingEndTime}</Typography>
														</Box>
													</>
												)}
											</ListItem>
											<ListItem className='meeting-col4'>
												<Box className='date-txt'>
													<Typography>
														{oneOnOneEntity?.updatedOn ? getMonthDateYear(oneOnOneEntity?.updatedOn) : ''}
													</Typography>
												</Box>
											</ListItem>
										</List>
									</Box>
									<Box className='expanded-agenda-box'>
										<Box className='expanded-row'>
											<Typography variant='h4'>{t('titleLabel')}</Typography>
											<Typography>{oneOnOneEntity?.title || ''}</Typography>
										</Box>
										<Box className='expanded-row'>
											<Typography variant='h4'>{t('natureOfMeeting')}</Typography>
											<Typography>
												{oneOnOneEntity?.natureOfMeeting === Enums.ONE
													? 'Online'
													: oneOnOneEntity?.natureOfMeeting === Enums.TWO
													? 'In-person'
													: '' || ''}
											</Typography>
										</Box>
										<Box className='expanded-row'>
											<Typography variant='h4'>{t('meetingLink')}</Typography>
											<Typography>{oneOnOneEntity?.meetingLink || '--'}</Typography>
										</Box>
										<Box className='expanded-row'>
											<Typography variant='h4'>{t('agendaLabel')}</Typography>
											<Box className='long-text'>
												{oneOnOneEntity?.oneToOneRemark ? (
													<Box dangerouslySetInnerHTML={{ __html: oneOnOneEntity?.oneToOneRemark || '- -' }}></Box>
												) : (
													<Typography variant='h2'>- -</Typography>
												)}
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(event) => handleDrawerClose(event)}
				handleSaveClick={(event) => handleDrawerClose(event)}
				handleCancelClick={(event) => handleDrawerClose(event)}
				hideDefaultButton={true}
			/>
		</Fragment>
	);
};
