import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	searchPending: false,
	searchResult: [],
	searchSuccess: false,
	searchError: '',
};

export default function searchReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.SEARCH_PENDING:
			return {
				...state,
				searchPending: true,
			};
		case actionTypes.SEARCH_SUCCESS:
			return {
				...state,
				searchPending: false,
				searchResult: action.payload.entityList,
			};
		case actionTypes.SEARCH_FAILED:
			return {
				...state,
				searchPending: false,
				searchError: action.error,
			};
		default:
			return state;
	}
}
