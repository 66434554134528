import * as actionTypes from './../action/actionTypes';

const INITIAL_STATE = {
	onboardingResult: [],
	onboardingStatus: '',
	onboardingCount: { skipCount: 0, readyCount: 0 },
	activeOnboardingPanel: '',
};

export default function onboardingReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.FETCH_ONBOARDING_PENDING:
			return {
				...state,
				onboardingStatus: 'pending',
			};
		case actionTypes.FETCH_ONBOARDING_SUCCESS:
			return {
				...state,
				onboardingStatus: 'success',
				onboardingResult: action.payload.entity,
			};
		case actionTypes.FETCH_ONBOARDING_FAILED:
			return {
				...state,
				onboardingStatus: 'error',
			};
		case actionTypes.UPDATE_ONBOARDING_COUNT:
			return { ...state, onboardingCount: action };
		case actionTypes.UPDATE_CALLOUT_PANEL:
			return { ...state, activeOnboardingPanel: action.payload };
		default:
			return state;
	}
}
