import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { OKRButton } from '../../../Common/OKRButton';
import { AddIconSvg, UserCirclePlaceholderIcon } from '../../../../config/svg/CommonSvg';
import { UserAvatarGroup } from '../../../Common/UserAvatarGroup';
import { SearchUserPopover } from '../../../Common/SearchUserPopover';
import { globalSearchAPIWithTeam } from '../../../../action/search';
import { useDispatch } from 'react-redux';
import { updatePage } from '../../../../action/common';
import { getUserDetails, getUserName } from '../../../../config/utils';
import { Enums } from '../../../../config/enums';
import AlertDialog from '../../../Common/Dialog';
import ReactDateRangePicker from '../../../Common/ReactDateRangePicker';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { CloneIcon2, DeleteIcon } from '../../../../config/svg/CommonSvgIcon';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';

export const ReviewDates = (props: any) => {
	const {
		t,
		pipFormType,
		pipFormDetails,
		updatePipFormDetails,
		minDateValue,
		maxDateValue,
		hideDate,
		hideMoreDated,
		showMsg,
		setIsFormEdited = () => {},
		isFormCleared,
		setIsFormCleared,
		hideCloneIcon,
		noValidate = false,
		feedbackReviewersList = [],
	} = props;
	const dispatch = useDispatch();
	const userDetail = getUserDetails();
	const moment = extendMoment(Moment);
	const reviewChangeMessage =
		'Modifying review dates or updating reviewers list will overwrite existing dates and reviewers in the review comments section. Are you sure you want to proceed?';

	const reviewDateStaticObj = {
		performanceImprovementPlanReviewDateId: Enums.ZERO,
		reviewDate: null, // formatDate(new Date())
		reviewerIds: [userDetail?.employeeId || Enums.ZERO], // [1, 2, 3]
		performanceImprovementPlanReviewers: [{ ...userDetail, isCreator: true }],
		reviewDateRange: null,
		reviewDateError: '',
	};
	const [addReviewerAnchorEl, setAddReviewerAnchorEl] = useState<HTMLElement | null>(null);
	const [addReviewerOpen, setAddReviewerOpen] = useState<boolean>(false);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [selectedReviewItemIndex, setSelectedReviewItemIndex] = useState<any>(null);
	const [reviewDateDetails, setReviewDateDetails] = useState<any>([]);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: null, message: '', details: null });
	const [reviewDateDetailsForAddUser, setReviewDateDetailsForAddUser] = useState<any>(null);

	useEffect(() => {
		if (pipFormDetails?.performanceImprovementPlanReviewDates?.length) {
			setReviewDateDetails(pipFormDetails?.performanceImprovementPlanReviewDates);
		} else {
			setReviewDateDetails([reviewDateStaticObj]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (isFormCleared) {
			setReviewDateDetails([reviewDateStaticObj]);
			setIsFormCleared(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormCleared]);
	useEffect(() => {
		if (!addReviewerOpen) {
			setSelectedReviewItemIndex(null);
		}
	}, [addReviewerOpen]);
	useEffect(() => {
		if (reviewDateDetails?.length) {
			updatePipFormDetails('performanceImprovementPlanReviewDates', reviewDateDetails);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reviewDateDetails]);

	const handleAddReviewerClick = (event: any, reviewItemIndex: number, reviewDetail: number) => {
		setSelectedReviewItemIndex(reviewItemIndex);
		setAddReviewerAnchorEl(addReviewerAnchorEl ? null : event.currentTarget);
		setReviewDateDetailsForAddUser(reviewDetail);
		setAddReviewerOpen(true);
	};
	const handleReviewItemCloneClick = (event: any, indexVal: number, reviewItem: any) => {
		setIsFormEdited(true);
		setReviewDateDetails([
			...reviewDateDetails,
			{ ...reviewItem, performanceImprovementPlanReviewDateId: Enums.ZERO, reviewDate: null, reviewDateRange: null },
		]);
	};
	const handleReviewItemDeleteClick = (event: any, indexVal: number, reviewItem: any) => {
		setModalProps({
			open: true,
			type: 'ReviewItemDelete',
			message: 'Are you sure you want to delete this review date?',
			details: { reviewItemIndex: indexVal, reviewItem: reviewItem },
		});
	};

	const onSelectedUser = async (selected: any) => {
		if (Boolean(selected) && selected.length) {
			const selectedUserDetails = selected[0];
			let updatedReviewDateDetails = reviewDateDetails?.length ? [...reviewDateDetails] : [];
			const contributors: any =
				updatedReviewDateDetails[selectedReviewItemIndex]?.performanceImprovementPlanReviewers &&
				updatedReviewDateDetails[selectedReviewItemIndex]?.performanceImprovementPlanReviewers?.length
					? updatedReviewDateDetails[selectedReviewItemIndex]?.performanceImprovementPlanReviewers
					: [];
			const isExist =
				contributors && contributors.length
					? contributors.find((item: any) => item.employeeId === selectedUserDetails.employeeId)
					: null;

			const isReviewerExist =
				feedbackReviewersList && feedbackReviewersList.length
					? feedbackReviewersList.find((item: any) => item.id === selectedUserDetails.employeeId)
					: null;
			if (!isExist && !isReviewerExist) {
				updatedReviewDateDetails[selectedReviewItemIndex].performanceImprovementPlanReviewers = [
					...updatedReviewDateDetails[selectedReviewItemIndex].performanceImprovementPlanReviewers,
					{ ...selectedUserDetails },
				];
				updatedReviewDateDetails[selectedReviewItemIndex].reviewerIds = [
					...updatedReviewDateDetails[selectedReviewItemIndex].reviewerIds,
					selectedUserDetails?.employeeId || Enums.ZERO,
				];
				setIsFormEdited(true);
				setReviewDateDetails([...updatedReviewDateDetails]);
			}
			setReviewDateDetailsForAddUser(null);
			setAddReviewerOpen(false);
			setAddReviewerAnchorEl(null);
			setSelectedReviewItemIndex(null);
			setCustomStyle(false);
		}
	};
	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};
	const globalSearchAPIForReviewers = async (query: any, page: any, pageLimit: any, type: any) => {
		return await dispatch(globalSearchAPIWithTeam(query, page, pageLimit, type));
	};
	const updatePageForReviewers = async (page: any) => {
		return await dispatch(updatePage(page));
	};
	const handleAddMoreReviewClick = (event: any) => {
		setIsFormEdited(true);
		setReviewDateDetails([...reviewDateDetails, reviewDateStaticObj]);
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'ReviewItemDelete') {
				const { reviewItemIndex, reviewItem } = modalProps?.details || {};
				let updatedReviewDateDetails = reviewDateDetails?.length ? [...reviewDateDetails] : [];
				updatedReviewDateDetails = updatedReviewDateDetails?.filter(
					(rec: any, index: number) => index !== reviewItemIndex
				);
				setIsFormEdited(true);
				setReviewDateDetails([...updatedReviewDateDetails]);
			} else if (modalProps?.type === 'ReviewDateChange') {
				setTimeout(() => {
					const { range, start, reviewDetail, indexVal } = modalProps?.details || {};
					if (pipFormDetails?.timeframeStartDate && pipFormDetails?.timeframeEndDate) {
						// const isBetween = moment(new Date(start)).isBetween(
						// 	new Date(pipFormDetails?.timeframeStartDate),
						// 	new Date(pipFormDetails?.timeframeEndDate)
						// );
						const range1 = moment().range(
							new Date(pipFormDetails?.timeframeStartDate),
							new Date(pipFormDetails?.timeframeEndDate)
						);
						const isBetween = range1.contains(new Date(start));
						const message = 'Review date falls out of the timeframe date range do you want to proceed?';
						if (!isBetween) {
							setModalProps({
								open: true,
								type: 'ReviewItemDateChange',
								message: message,
								details: { range, start, reviewDetail, indexVal },
							});
						} else {
							setIsFormEdited(true);
							handleReviewDateSelection(range, start, reviewDetail, indexVal);
						}
					} else {
						setIsFormEdited(true);
						handleReviewDateSelection(range, start, reviewDetail, indexVal);
					}
				}, 1000);
			} else if (modalProps?.type === 'ReviewerChange') {
				const { selected } = modalProps?.details || {};
				onSelectedUser(selected);
			} else if (modalProps?.type === 'ReviewerRemove') {
				const { e, selectedUserData, reviewDetail, indexVal } = modalProps?.details || {};
				handleRemoveReviewer(e, selectedUserData, reviewDetail, indexVal);
			} else if (modalProps?.type === 'ReviewItemDateChange') {
				const { range, start, reviewDetail, indexVal } = modalProps?.details || {};
				setIsFormEdited(true);
				updateReviewDate(range, start, indexVal);
			}
		}
		setModalProps({ open: false, type: null, message: '', details: null });
	};
	const handleReviewDateSelection = (range: any, start: any, reviewDetail: any, indexVal: number) => {
		try {
			updateReviewDate(range, start, indexVal);
		} catch (error) {
			console.error(error);
		}
	};
	const updateReviewDate = (range: any, start: any, indexVal: number) => {
		let updatedReviewDateDetails = reviewDateDetails?.length ? [...reviewDateDetails] : [];
		if (start) {
			updatedReviewDateDetails[indexVal].reviewDate = start;
		}
		if (range) {
			updatedReviewDateDetails[indexVal].reviewDateRange = range;
		}
		setReviewDateDetails([...updatedReviewDateDetails]);
	};
	const handleRemoveReviewer = (e: any, selectedUserItem: any, reviewDetail: any, indexVal: number) => {
		try {
			const selectedUserDetails = selectedUserItem;
			let updatedReviewDateDetails = reviewDateDetails?.length ? [...reviewDateDetails] : [];
			const contributors: any =
				updatedReviewDateDetails[indexVal]?.performanceImprovementPlanReviewers &&
				updatedReviewDateDetails[indexVal]?.performanceImprovementPlanReviewers?.length
					? updatedReviewDateDetails[indexVal]?.performanceImprovementPlanReviewers
					: [];
			const filterData =
				contributors && contributors.length
					? contributors.filter((item: any) => item.employeeId !== selectedUserDetails.employeeId)
					: null;
			updatedReviewDateDetails[indexVal].performanceImprovementPlanReviewers = [...filterData];
			updatedReviewDateDetails[indexVal].reviewerIds =
				updatedReviewDateDetails[indexVal].reviewerIds && updatedReviewDateDetails[indexVal].reviewerIds?.length
					? updatedReviewDateDetails[indexVal].reviewerIds?.filter(
							(rec: any) => rec !== selectedUserDetails?.employeeId
					  )
					: [];
			setIsFormEdited(true);
			setReviewDateDetails([...updatedReviewDateDetails]);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Box className='review-date-area'>
				{reviewDateDetails && reviewDateDetails?.length ? (
					reviewDateDetails?.map((reviewDetail: any, indexVal: number) => {
						const userList =
							reviewDetail?.performanceImprovementPlanReviewers &&
							reviewDetail?.performanceImprovementPlanReviewers?.length
								? reviewDetail?.performanceImprovementPlanReviewers?.map((item: any) => {
										const data = { ...item };
										data.employeeId = data?.reviewerId || data?.employeeId;
										return data;
								  })
								: [];
						const reviewerList = userList?.filter(
							(rec: any) => (pipFormType === 'Add' && !rec?.isCreator) || pipFormDetails?.createdBy !== rec?.reviewerId
						);
						const creatorDetail = userList?.find(
							(rec: any) => (pipFormType === 'Add' && rec?.isCreator) || pipFormDetails?.createdBy === rec?.reviewerId
						);
						const reviewDateError = pipFormDetails?.performanceImprovementPlanReviewDates?.length
							? pipFormDetails?.performanceImprovementPlanReviewDates[indexVal]?.reviewDateError
							: '';
						return (
							<Box className='review-date-box' key={`pip_review_date_${indexVal}`} id={`pip_review_date_${indexVal}`}>
								<Box className='review-date-action'>
									{/* {reviewDetail?.reviewDate ? ( */}
									{!hideCloneIcon && (
										<OKRButton
											title={t('clone')}
											icon={<CloneIcon2 />}
											handleClick={(event: any) => handleReviewItemCloneClick(event, indexVal, reviewDetail)}
										/>
									)}
									{/* ) : (
										<></>
									)} */}
									{reviewDateDetails?.length > 1 ? (
										<OKRButton
											title={t('delete')}
											icon={<DeleteIcon />}
											handleClick={(event: any) => handleReviewItemDeleteClick(event, indexVal, reviewDetail)}
										/>
									) : (
										<></>
									)}
								</Box>
								{!hideDate && (
									<Box className='set-timeline-area'>
										<Box className='calender-date'>
											<Box className='timeline-field'>
												<Box className='calendar-btn helperText-bottom'>
													<ReactDateRangePicker
														type={'PipReviewDates'}
														startDateValue={reviewDetail?.reviewDate || ''}
														selectedCycleDetails={{}}
														minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
														maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
														handleDateSelection={(
															range: any,
															start: any,
															_end: any,
															_selectionType: any,
															_isCycleChanged: any
														) => {
															if (range) {
																if (
																	!noValidate &&
																	pipFormType === 'Edit' &&
																	pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE &&
																	reviewDetail?.performanceImprovementPlanReviewDateId
																) {
																	setModalProps({
																		open: true,
																		type: 'ReviewDateChange',
																		message: reviewChangeMessage,
																		details: { range, start, reviewDetail, indexVal },
																	});
																} else {
																	if (pipFormDetails?.timeframeStartDate && pipFormDetails?.timeframeEndDate) {
																		// const isBetween = moment(new Date(start)).isBetween(
																		// 	new Date(pipFormDetails?.timeframeStartDate),
																		// 	new Date(pipFormDetails?.timeframeEndDate)
																		// );
																		const range1 = moment().range(
																			new Date(pipFormDetails?.timeframeStartDate),
																			new Date(pipFormDetails?.timeframeEndDate)
																		);
																		const isBetween = range1.contains(new Date(start));
																		const message =
																			'Review date falls out of the timeframe date range do you want to proceed?';
																		if (!isBetween) {
																			setModalProps({
																				open: true,
																				type: 'ReviewItemDateChange',
																				message: message,
																				details: { range, start, reviewDetail, indexVal },
																			});
																		} else {
																			handleReviewDateSelection(range, start, reviewDetail, indexVal);
																		}
																	} else {
																		setIsFormEdited(true);
																		handleReviewDateSelection(range, start, reviewDetail, indexVal);
																	}
																}
															}
														}}
														isDateRangeTextFieldVisible={true}
														isOnlyDueDateVisible={false}
														allCycleDetails={[]}
														isCycleVisible={false}
														isEndOfMonthVisible={false}
														numberOfCalendars={Enums.ONE}
														selectionType={'single'}
														selectedDateRange={reviewDetail?.reviewDateRange || null}
														setSelectedDateRange={(range: any) =>
															handleReviewDateSelection(range, null, reviewDetail, indexVal)
														}
														placeholderText={t('selectedDate')}
														isDatePickerDisable={false}
													/>
													{reviewDateError ? (
														<Typography className='error-field'>
															<ErrorIcon /> {reviewDateError}
														</Typography>
													) : (
														<></>
													)}
												</Box>
											</Box>
										</Box>
									</Box>
								)}
								<Box className='pip-reviewers-list'>
									{!reviewerList?.length && !feedbackReviewersList.length ? (
										<Box className='review-initiator'>
											{creatorDetail ? (
												<Tooltip arrow title={`${creatorDetail?.firstName || ''} ${creatorDetail?.lastName || ''}`}>
													{creatorDetail?.imagePath ? (
														<Avatar className='avatar-default' src={creatorDetail.imagePath} />
													) : (
														<Avatar className={'avatar-default'}>
															{getUserName({
																firstName: creatorDetail?.firstName || '',
																lastName: creatorDetail?.lastName || '',
																fullName: creatorDetail?.fullName || '',
															})}
														</Avatar>
													)}
												</Tooltip>
											) : (
												<></>
											)}
										</Box>
									) : (
										<></>
									)}
									<Box className='review-others'>
										{reviewerList?.length || feedbackReviewersList.length ? (
											<UserAvatarGroup
												{...props}
												contributorClassName={'contributor-details-popover'}
												isButtonVisible={false}
												handleAddUserClick={() => {}}
												contributorDetailsType={true}
												isOnHoverVisible={true}
												loginUserAvatar={creatorDetail}
												contributorDetails={[...feedbackReviewersList, ...reviewerList]}
												isUserPlaceholder={true}
												subTitle={' '}
												helpText={' '}
												max={5}
												//isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
												//handleUserAvatarClick={handleUserAvatarClick}
												isDisabled={pipFormType === 'View' ? true : false}
												viewType='pipReviewers'
												handleRemoveReviewer={(e: any, selectedUserData: any) => {
													if (
														!noValidate &&
														pipFormType === 'Edit' &&
														pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE &&
														reviewDetail?.performanceImprovementPlanReviewDateId
													) {
														setModalProps({
															open: true,
															type: 'ReviewerRemove',
															message: reviewChangeMessage,
															details: { e, selectedUserData, reviewDetail, indexVal },
														});
													} else {
														handleRemoveReviewer(e, selectedUserData, reviewDetail, indexVal);
													}
												}}
											/>
										) : (
											<Box className='review-others-placeholder'>
												<UserCirclePlaceholderIcon />
											</Box>
										)}

										<OKRButton
											className='add-btn2'
											icon={<AddIconSvg />}
											handleClick={(event: any) => handleAddReviewerClick(event, indexVal, reviewDetail)}
										/>
									</Box>
								</Box>
							</Box>
						);
					})
				) : (
					<></>
				)}

				{!hideMoreDated && (
					<Box className='review-date-box more-dates-box'>
						<OKRButton
							className='more-dates-btn'
							text={t('More Dates')}
							icon={<AddIconSvg />}
							handleClick={handleAddMoreReviewClick}
						/>
					</Box>
				)}
				{addReviewerOpen && (
					<SearchUserPopover
						{...props}
						addContributorAnchorEl={addReviewerAnchorEl}
						addContributorOpen={addReviewerOpen}
						setAddContributorOpen={setAddReviewerOpen}
						setAddContributorAnchorEl={setAddReviewerAnchorEl}
						isCustomStyle={customStyle}
						onSelectedUser={(selected: any) => {
							if (Boolean(selected) && selected.length) {
								const selectedUserDetails = selected[0];
								let updatedReviewDateDetails = reviewDateDetails?.length ? [...reviewDateDetails] : [];
								const contributors: any =
									updatedReviewDateDetails[selectedReviewItemIndex]?.performanceImprovementPlanReviewers &&
									updatedReviewDateDetails[selectedReviewItemIndex]?.performanceImprovementPlanReviewers?.length
										? updatedReviewDateDetails[selectedReviewItemIndex]?.performanceImprovementPlanReviewers
										: [];
								const isExist =
									contributors && contributors.length
										? contributors.find((item: any) => item.employeeId === selectedUserDetails.employeeId)
										: null;
								if (!isExist) {
									if (
										!noValidate &&
										pipFormType === 'Edit' &&
										pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE &&
										reviewDateDetailsForAddUser?.performanceImprovementPlanReviewDateId
									) {
										setModalProps({
											open: true,
											type: 'ReviewerChange',
											message: reviewChangeMessage,
											details: { selected },
										});
									} else {
										onSelectedUser(selected);
									}
								} else {
									showMsg('info', 'Reviewer already added in the reviewer list');
								}
							}
						}}
						handleSearchUserInputChange={handleSearchUserInputChange}
						popperAddClassName={'user-quick-search-popover check-ins-quick-search'}
						customId={'role-card-src-field'}
						isOwnerSearch={false}
						globalSearchAPIWithTeam={globalSearchAPIForReviewers}
						updatePage={updatePageForReviewers}
						nomineeId={pipFormDetails?.nomineeId}
					/>
				)}
			</Box>
			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
