import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Checkbox,
	Chip,
	ClickAwayListener,
	FormControlLabel,
	Grow,
	InputAdornment,
	Link,
	List,
	ListItem,
	Paper,
	Popper,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageItem } from '../../../services/StorageService';
import { OKRButton } from '../../Common/OKRButton';
import { CrossIcon, NewFilterIcon, ResetIcon } from '../../../config/svg/CommonSvgIcon';
import { SearchIcon } from '../../../config/svg/CommonSvg';
import { DownArrowIcon, UpArrowIcon } from '../../../config/svg/ArrowSvg';
import { CancelIcon } from '../../../config/svg/Action';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import _ from 'lodash';
import { TeamsFilterOptions } from '../../../config/constant';

export const GoalDrFilters: React.FC<any> = (props: any) => {
	const {
		minDateValue,
		maxDateValue,
		selectedFilterDateRange,
		setSelectedFilterDateRange = () => {},
		searchTextArray,
		setSearchTextArray = () => {},
		handleSearchText,
		finalFilterCondition,
		setFinalFilterCondition,
		defaultFinalSettings,
		searchValue,
		setSearchValue,
		defaultLocalSettings,
		pipFiltersList,
		type = 'pip',
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentCycleDetails = getLocalStorageItem('currentCycle');
	const curCycleData = currentCycleDetails ? JSON.parse(currentCycleDetails) : {};
	const startDateValue = new Date();
	startDateValue.setMonth(startDateValue.getMonth() - 6);

	const [isDateRangeChanged, setIsDateRangeChanged] = useState<any>(null);

	const dateChanged = (range: any) => {
		range && setIsDateRangeChanged(true);
		range && setSelectedFilterDateRange(range);
	};
	const resetCalendarDate = () => {
		setIsDateRangeChanged(false);
		setSelectedFilterDateRange(null);
	};

	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				setSearchTextArray(e.target.value);
				handleSearchText(e.target.value);
			}
			setSearchValue('');
		}
	};

	const delayedQuery = useCallback(
		_.debounce((q) => _handleSearch(q), 500),
		[]
	);
	const handleInputThrottled = (value: any) => {
		setSearchValue(value);
		delayedQuery(value);
	};
	const _handleSearch = (e: any) => {
		handleSearchText(e);
	};

	const clearSearch = () => {
		setSearchTextArray('');
		handleSearchText('');
	};

	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
	const [localFilterCondition, setLocalFilterCondition] = useState<any>(defaultLocalSettings);
	const handleToggleFilter = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpenFilter((prev) => !prev);
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};
	const handleCloseChip = (value: any) => {
		let filterData: any = [...localFilterCondition];
		let indexParent = filterData.indexOf(value.id);
		if (indexParent !== -1) {
			filterData.splice(indexParent, 1);
		}
		setLocalFilterCondition(filterData);
		handleApplyFilterClick(filterData);
	};
	const handleFilterChanged = (value: number) => {
		let filterData: any = [...localFilterCondition];
		let indexParent = filterData.indexOf(value);
		if (indexParent === -1) {
			filterData.push(value);
		} else {
			filterData.splice(indexParent, 1);
		}
		setLocalFilterCondition(filterData);
	};
	const clearFilter = () => {
		setLocalFilterCondition(defaultLocalSettings);
		setFinalFilterCondition(defaultFinalSettings);
	};

	const handleApplyFilterClick = (filtersData: any) => {
		let filters: any = [...defaultLocalSettings];
		TeamsFilterOptions.map((value: any, index: number) => {
			if (filtersData && filtersData.indexOf(value.id) !== -1) {
				filters.push({
					...value,
				});
			}
		});
		setAppliedFilter(true);
		setFinalFilterCondition([...filters]);
	};
	return (
		<>
			<Box className='goal-okr-filter alignment-map-filter'>
				<OKRButton
					className={`list-filter-btn ${openFilter ? 'active' : ''}`}
					icon={<NewFilterIcon />}
					text={
						<span className='filter-txt'>
							{t('filterLabel')}
							{openFilter ? <UpArrowIcon /> : <DownArrowIcon />}
						</span>
					}
					handleClick={handleToggleFilter}
					id='dashboard-filter-btn'
				/>
				<Box className={`filter-chip `}>
					{appliedFilter && (
						<List>
							{finalFilterCondition && finalFilterCondition.length > 0 ? (
								<>
									{finalFilterCondition.slice(0, 1).map((item: any, index: any) => (
										<ListItem key={index}>
											<Tooltip arrow title={item.label}>
												<Typography variant='body2'>{item.label}</Typography>
											</Tooltip>
											<Button className='cancel-btn' onClick={() => handleCloseChip(item)}>
												<CancelIcon />
											</Button>
										</ListItem>
									))}
									{finalFilterCondition.length > 1 && (
										<ListItem className='more-filter-count'>
											<Typography variant='body2'>+{finalFilterCondition.length - 1}</Typography>
										</ListItem>
									)}

									<ListItem className='clear-all'>
										<Link onClick={clearFilter}>{t('clearAll')}</Link>
									</ListItem>
								</>
							) : (
								<></>
							)}
						</List>
					)}
				</Box>
				<Popper
					className={`okr-filter alignmentmap-filter-list task-filter-popup teams-goals-filter-popup`}
					open={openFilter}
					anchorEl={anchorEl}
					id='filtersPopper'
					placement={'bottom-start'}
					modifiers={{
						flip: {
							enabled: false,
						},
					}}
				>
					{({ TransitionProps }) => (
						<Grow {...TransitionProps}>
							<ClickAwayListener onClickAway={handleClose}>
								<Paper>
									<Box className='task-filter-wrap'>
										<Box className='task-filter-wrap-inner'>
											{TeamsFilterOptions && TeamsFilterOptions.length > 0 && (
												<Box className='task-priority-filter'>
													{/* <Typography variant='body2'>{'test'}</Typography> */}
													<List>
														{TeamsFilterOptions.map((value: any, index: number) => {
															return (
																<ListItem key={`type-filter-date-list-${value.id}`}>
																	<FormControlLabel
																		control={
																			<Checkbox
																				icon={<BorderCheckboxIcon />}
																				checkedIcon={<CheckedIcon />}
																				checked={localFilterCondition && localFilterCondition.includes(value.id)}
																				value={value.id}
																				onChange={(e) => handleFilterChanged(value.id)}
																				color='primary'
																			/>
																		}
																		label={value.label}
																	/>
																</ListItem>
															);
														})}
													</List>
												</Box>
											)}
										</Box>
										<Box className='task-filter-actions'>
											<OKRButton
												className='apply-btn btn-primary'
												text={t('applyLabel')}
												handleClick={(e: any) => {
													handleClose(e);
													handleApplyFilterClick(localFilterCondition);
												}}
											/>
										</Box>
									</Box>
								</Paper>
							</ClickAwayListener>
						</Grow>
					)}
				</Popper>
			</Box>
			<Box className='user-search'>
				{type === 'feedback' ? (
					<TextField
						className='search-text-field'
						id='user-src-field'
						placeholder={'Search by user'}
						fullWidth
						value={searchValue}
						onChange={(e: any) => handleInputThrottled(e.target.value)}
						onKeyDown={(e: any) => searchClicked(e)}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				) : (
					<Tooltip title={t('searchUserForPip')} arrow>
						<TextField
							className='search-text-field'
							id='user-src-field'
							placeholder={t('searchByNominee')}
							fullWidth
							value={searchValue}
							onChange={(e: any) => handleInputThrottled(e.target.value)}
							onKeyDown={(e: any) => searchClicked(e)}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
						/>
					</Tooltip>
				)}
				{searchTextArray && (
					<Box className='user-search-chip'>
						<List>
							<ListItem>
								{searchTextArray.length > 20 ? (
									<Tooltip title={searchTextArray} arrow>
										<Chip
											label={searchTextArray.substring(0, 20) + '...'}
											onDelete={clearSearch}
											deleteIcon={
												<span>
													<CrossIcon />
												</span>
											}
										/>
									</Tooltip>
								) : (
									<Chip
										label={searchTextArray}
										onDelete={clearSearch}
										deleteIcon={
											<span>
												<CrossIcon />
											</span>
										}
									/>
								)}
							</ListItem>
						</List>
					</Box>
				)}
			</Box>
		</>
	);
};
