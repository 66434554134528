import React, { Fragment, useState, useEffect } from 'react';
import { CssBaseline, Popover } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import OkrProgressView from './OkrProgressView';
import { getLocalStorageItem } from '../../../services/StorageService';
import AlertDialog from '../../Common/Dialog';
import { useTranslation } from 'react-i18next';

const OkrProgressChart = (props) => {
	const { enqueueSnackbar } = useSnackbar();

	let userDetail = JSON.parse(getLocalStorageItem('userDetail'));

	const { chartData, showLoader } = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [highestSeries, setHighestSeries] = useState([]);
	const [lowestSeries, setLowestSeries] = useState([]);
	const [apiDetails, setAPIDetails] = useState([]);
	const [contributorDetails, setContributorDetails] = useState();

	const [modalOpen, setModalOpen] = React.useState(false);
	const [feedbackDetail, setFeedbackDetail] = React.useState({ id: 0, employeeId: 0 });
	useEffect(() => {
		getReportDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [chartData]);

	const [score, setScore] = useState({});

	const getReportDetails = async () => {
		try {
			if (chartData.status === 'fulfilled') {
				const avgOkrReportData = chartData.value;

				if (avgOkrReportData && avgOkrReportData.data && avgOkrReportData.data.entity) {
					const details = avgOkrReportData.data.entity;
					const { contributorsKrResponse, avgOrganisationalScore, minimumOrganisationThreshold } = details;
					const scoreReportData = contributorsKrResponse;
					const chartDetails = scoreReportData;
					const scoreRangeLowest =
						chartDetails && chartDetails.length && chartDetails.filter((item) => item.scoreRange === 'Lowest');
					const scoreRangeHighest =
						chartDetails && chartDetails.length && chartDetails.filter((item) => item.scoreRange === 'Highest');
					setScore({ avgOrganisationalScore, minimumOrganisationThreshold });
					const lowRangeData =
						scoreRangeLowest &&
						scoreRangeLowest.length &&
						scoreRangeLowest.map((item, index) => ({
							x: item.goalKeyId,
							y: item.score,
							name: item.goalKeyId,
							id: item.goalKeyId,
						}));
					const hightRangeData =
						scoreRangeHighest &&
						scoreRangeHighest.length &&
						scoreRangeHighest.map((item, index) => ({
							x: item.goalKeyId,
							y: item.score,
							name: item.goalKeyId,
							id: item.goalKeyId,
						}));
					setAPIDetails(scoreReportData);
					setHighestSeries(hightRangeData);
					setLowestSeries(lowRangeData);
				}
			}
		} catch (err) {
			showLoader(false);
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleMenuClickHandler = (event, actionName) => {
		setAnchorEl(null);
	};
	const openOkrProgressView = (event, category, yAxis) => {
		const { point } = event;
		const memberList = apiDetails && apiDetails.length ? apiDetails.find((item) => item.goalKeyId === point.id) : [];
		setContributorDetails(memberList);
		setAnchorEl(event);
	};

	const handlePeopleRequestOneOnOne = (e, type, objective, currentUser) => {
		e.preventDefault();
		e.stopPropagation();
		const typeId = type === 'okr' ? 1 : 2;
		const { employeeId } = currentUser;
		const id = objective.goalObjectiveId;

		setFeedbackDetail({ typeId, id, employeeId, type: 1 });
		setModalOpen(true);
	};
	const { t } = useTranslation();
	/** request 1:1 api calling */
	const handleCloseModal = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		if (type === 1) {
			const data = {
				requestId: feedbackDetail.id,
				requestType: feedbackDetail.typeId,
				requestedTo: [feedbackDetail.employeeId],
				requestedFrom: userDetail.employeeId,
				onetoOneRemark: feedbackDetail.message ? feedbackDetail.message : t('requestOneOnOneText'),
			};
			setModalOpen(false);
			props
				.requestOneToOneFeedback(data)
				.then((response) => {
					if (response.status === 200) {
						enqueueSnackbar(t('requestRaisedSuccessfully'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					} else {
						enqueueSnackbar(t('errorRaisingRequest'), {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				})
				.catch((error) => {
					enqueueSnackbar(t('errorRaisingRequest'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		}
		setModalOpen(false);
	};

	const scatterChartData = {
		chart: {
			type: 'scatter',
			zoomType: 'xy',
			height: 340,
		},
		title: {
			text: '',
		},
		credits: {
			enabled: false,
		},
		subtitle: {
			text: '',
		},
		xAxis: {
			title: {
				enabled: false,
				text: '',
			},
			visible: false,
			enabled: false,
			startOnTick: true,
			endOnTick: true,
			showLastLabel: true,
		},
		yAxis: {
			categories: ['', '20', '40', '60', '80', '100'],
			minTickInterval: 20,
			max: 100,
			min: 0,
			labels: {
				formatter: function () {
					return this.value + `${this.value ? `${t('percentage')}` : ''}`;
				},
			},
			// gridLineColor: "#CB00FF", // #e6e6e6 // High -- #BF3002
			title: {
				enabled: false,
				text: '',
			},
			startOnTick: true,
			endOnTick: true,
			showLastLabel: true,
			lineWidth: 1,
			plotLines: [
				{
					color: '#BF3002',
					width: 1,
					value: score.avgOrganisationalScore ? score.avgOrganisationalScore : 0,
				},
				{
					color: '#CB00FF',
					width: 2,
					value: score.minimumOrganisationThreshold ? score.minimumOrganisationThreshold : 40,
				},
			],
		},
		legend: {
			enabled: true,
			backgroundColor: Highcharts.defaultOptions.chart.backgroundColor,
			borderWidth: 1,
		},
		tooltip: {
			enabled: false,
		},
		plotOptions: {
			series: {
				cursor: 'pointer',
				point: {
					events: {
						click: function (event) {
							openOkrProgressView(event, this.category, this.y);
						},
					},
				},
			},
			scatter: {
				marker: {
					radius: 5,
					states: {
						hover: {
							enabled: true,
							lineColor: 'rgb(100,100,100)',
						},
					},
				},
				states: {
					hover: {
						marker: {
							enabled: false,
						},
					},
				},
			},
		},
		series: [
			{
				name: t('InsightsHighest'),
				color: '#0664B1',
				marker: {
					symbol: 'circle',
				},
				data: highestSeries,
			},
			{
				name: t('InsightsLowest'),
				color: '#A1ABB9',
				marker: {
					symbol: 'circle',
				},
				data: lowestSeries,
			},
		],
	};

	return (
		<Fragment>
			<CssBaseline />
			<HighchartsReact highcharts={Highcharts} options={scatterChartData} />
			<Popover
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				anchorReference='anchorPosition'
				anchorPosition={{
					top: anchorEl ? anchorEl.clientY : 0,
					left: anchorEl ? anchorEl.clientX : 0,
				}}
			>
				<OkrProgressView
					contributorDetails={contributorDetails}
					handleMenuClickHandler={handleMenuClickHandler}
					dueDate={contributorDetails ? contributorDetails.dueDate : null}
					isProgress={true}
					handlePeopleRequestOneOnOne={handlePeopleRequestOneOnOne}
					{...props}
				/>
			</Popover>
			{modalOpen && (
				<AlertDialog
					module='oneToOne'
					message={t('requestOnToOneMsg')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};

export default OkrProgressChart;
