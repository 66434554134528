// UserSnapshotDetails.tsx
import {
	Avatar,
	Box,
	Chip,
	ClickAwayListener,
	Collapse,
	Popper,
	Tab,
	Tabs,
	Tooltip,
	Typography,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OkrSnapshotProgressChart } from './OkrSnapshotProgressChart';
import { StatusChart } from './StatusChart';
import { PerformanceChart } from './PerformanceChart';
import { TaskProgressChart } from './TaskProgressChart';
import { CheckInCoverageChart } from './CheckInCoverageChart';
import '../../../styles/pages/userPerformanceSnapshot.scss';
import { getMonthDateYear, getUserName } from '../../../config/utils';
import { OKRButton } from '../OKRButton';
import { HighPriorityIcon, LowPriorityIcon, MediumPriorityIcon, RewardsIcon } from '../../../config/svg/CommonSvg';
import { CalanderColorIcon, CrossIcon, ReportsTooltipIcon } from '../../../config/svg/CommonSvgIcon';
import NotesImg from '../../../images/no-found-notes.svg';
import { ProgressTabPanel, ReflectionsTabs } from './ReflectionsTabs';
import { AnimatedIcon } from '../AnimatedIcon';
// import { HeaderTooltip } from '../../Reports/HeaderTooltip';
import { FeedbackTile } from './FeedbackTile';
import {
	DirectReportsIcon,
	FeedbackAddressedIcon,
	GiveFeedbackIcon,
	RecognizedIcon,
	RequestOneOnOneIcon2,
	ViewFeedback,
} from '../../../config/svg/PersonalFeedbackSvg';
import { useDispatch } from 'react-redux';
import { getEngagement, getFeedbackDetails, getOkrProgressStatusAndPerformance } from '../../../action/common';
import { Enums } from '../../../config/enums';
import { SnapshotDropdown } from './SnapshotDropdown';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';
import { ReflectionCheckInDetails } from './ReflectionCheckInDetails';

export const UserSnapshotDetails = (props: any) => {
	const { selectedUser, setLoader } = props; // performanceCycle, isTeamAssessment,
	const PERFORMANCE_CHART_MAX_VAL = 100;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [startComponentDetails, setStartComponentDetails] = useState<any>(null);
	const [middleComponentDetails, setMiddleComponentDetails] = useState<any>(null);
	const [lastComponentDetails, setLastComponentDetails] = useState<any>(null);
	const [showReflections, setShowReflections] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const [selectedStatusType, setSelectedStatusType] = useState<any>(Enums.ONE);
	const [selectedStatusOkrChartData, setSelectedStatusOkrChartData] = useState<any>([]);
	const [selectedStatusKrChartData, setSelectedStatusKrChartData] = useState<any>([]);

	useEffect(() => {
		setLoader(true);
		setStartComponentDetails(null);
		setMiddleComponentDetails(null);
		setLastComponentDetails(null);
		getSnapshotOkrProgressStatusAndPerformanceData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get First Section Data
	const getSnapshotOkrProgressStatusAndPerformanceData = async () => {
		try {
			if (selectedUser && selectedUser?.employeeId) {
				const requestParam = `employeeId=${selectedUser.employeeId}`;
				const response: any = await dispatch(getOkrProgressStatusAndPerformance(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response.data;
					getSnapshotEngagementData(entity);
				} else {
					console.error('Something went wrong!');
					getSnapshotEngagementData(null);
				}
			}
		} catch (error) {
			getSnapshotEngagementData(null);
			console.error(error);
		}
	};
	// Get Middle Section Data
	const getSnapshotEngagementData = async (firstSec: any) => {
		try {
			if (selectedUser && selectedUser?.employeeId) {
				const requestParam = `employeeId=${selectedUser.employeeId}`;
				const response: any = await dispatch(getEngagement(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response.data;
					getSnapshotFeedbackData(firstSec, entity);
				} else {
					console.error('Something went wrong!');
					getSnapshotFeedbackData(firstSec, null);
				}
			}
		} catch (error) {
			getSnapshotFeedbackData(firstSec, null);
			console.error(error);
		}
	};
	// Get Last Section Data
	const getSnapshotFeedbackData = async (firstSec: any, secondSec: any) => {
		try {
			if (selectedUser && selectedUser?.employeeId) {
				const requestParam = `employeeId=${selectedUser.employeeId}`;
				const response: any = await dispatch(getFeedbackDetails(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response.data;
					setFormDetails(firstSec, secondSec, entity);
				} else {
					console.error('Something went wrong!');
					setFormDetails(firstSec, secondSec, null);
				}
			}
		} catch (error) {
			setFormDetails(firstSec, secondSec, null);
			console.error(error);
		}
	};

	const setFormDetails = (firstSec: any, secondSec: any, thirdSec: any) => {
		try {
			setSelectedStatusOkrChartData(firstSec?.okrStatusResponses || null);
			setSelectedStatusKrChartData(firstSec?.krStatusResponses || null);
			setStartComponentDetails(firstSec || null);

			setMiddleComponentDetails(secondSec || null);

			setLastComponentDetails(thirdSec || null);

			setLoader(false);
		} catch (error) {
			console.log(error);
			setLoader(false);
		}
	};

	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleShowReflectionsClick = (e: any) => {
		setShowReflections(true);
	};
	const handleHideReflectionsClick = (e: any) => {
		setShowReflections(false);
	};
	const getSeriesData = (okrProgressResponses: any) => {
		const updatedData =
			okrProgressResponses && okrProgressResponses?.length
				? [...okrProgressResponses].reverse().map((data: any) => {
						const item = { ...data };
						if (item?.name === 'Progress') {
							return {
								...item,
								type: 'spline',
								color: '#FA989D',
								borderWidth: 0,
								marker: {
									lineWidth: 2,
									lineColor: 'rgba(255,255,255,0.6)',
									fillColor: '#FA989D',
								},
							};
						} else if (item?.name === 'Committed') {
							return { ...item, type: 'column', color: '#45E7BA' };
						} else if (item?.name === 'Aspirational') {
							return { ...item, type: 'column', color: '#39A3FA' };
						} else {
							return { ...item };
						}
				  })
				: [];
		return updatedData;
	};
	const getStatusSeriesData = (statusResponses: any) => {
		const updatedData =
			statusResponses && statusResponses?.length
				? [...statusResponses].reverse().map((data: any) => {
						let item = { ...data };
						if (item?.name === 'Not Started') {
							item = { ...item, borderRadiusTopLeft: 6, borderRadiusTopRight: 6 };
						}
						return { ...item };
				  })
				: [];

		return updatedData;
	};

	const handleStatusTypeSelection = async (e: any, type: any) => {
		try {
			setSelectedStatusOkrChartData(startComponentDetails?.okrStatusResponses || null);
			setSelectedStatusKrChartData(startComponentDetails?.krStatusResponses || null);
			setSelectedStatusType(type);
		} catch (error) {
			console.error(error);
			setSelectedStatusType(type);
		}
	};

	return (
		<>
			{/* <Tabs className='okr-tabs drawer-tabs' value={0} aria-label='Admin Tabs'>
				<Tab label={t('performanceTextLabel')} value={0} />
			</Tabs> */}
			<Box className='drawer-content-panel user-snapshot-content-panel'>
				<InformationMessage
					className={'information-message-user'}
					messageText={t('snapshotInfomsg')}
					icon={true}
					iconImage={<ReportsTooltipIcon />}
				/>
				<Box className='user-snapshot-drawer-content drawer-form-fields'>
					<Box className='snapshot-user-info drawer-horizontal-space'>
						<Box className='snapshot-user-details'>
							<Box className='user-img'>
								{Boolean(selectedUser?.imagePath) ? (
									<Avatar alt={`${selectedUser?.firstName} ${selectedUser?.lastName}`} src={selectedUser?.imagePath} />
								) : (
									<Avatar>{getUserName(selectedUser)}</Avatar>
								)}
							</Box>
							<Typography variant='h4'>
								{selectedUser?.firstName || ''} {selectedUser?.lastName || ''}
							</Typography>
						</Box>
					</Box>
					<Box className='drawer-blue-bg-full'>
						{startComponentDetails && startComponentDetails?.okrProgressResponses ? (
							<Box className='okr-progress-chart'>
								<Box className='okr-progress-graph'>
									<Box className='okr-tile-head'>
										<Typography variant='h5'>OKRs Progress</Typography>
									</Box>
									<OkrSnapshotProgressChart
										chartData={{
											series: getSeriesData(startComponentDetails?.okrProgressResponses),
											categories: startComponentDetails?.okrProgressXAxis || [],
										}}
									/>
								</Box>
								<Box className='status-progress-graph'>
									<Box className='okr-tile-head'>
										<Typography variant='h5'>Status</Typography>
										<SnapshotDropdown
											handleStatusTypeSelection={handleStatusTypeSelection}
											selectedStatusType={selectedStatusType}
										/>
									</Box>
									{selectedStatusType === Enums.ONE ? (
										<StatusChart
											statusChartKey={`status_Chart_for_Okr_and_KR_1`}
											chartData={{
												series: getStatusSeriesData(
													startComponentDetails?.okrStatusResponses || selectedStatusOkrChartData
												),
												categories: startComponentDetails?.statusXAxis || [],
											}}
										/>
									) : (
										<StatusChart
											statusChartKey={`status_Chart_for_Okr_and_KR_2`}
											chartData={{
												series: getStatusSeriesData(
													selectedStatusKrChartData || startComponentDetails?.krStatusResponses
												),
												categories: startComponentDetails?.statusXAxis || [],
											}}
										/>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
						<Box className='progress-chart-section '>
							{/* Progress Chart Box for 2 cycle and Progress Chart Box */}
							{startComponentDetails &&
							startComponentDetails?.performanceDetails &&
							startComponentDetails?.performanceDetails?.length === Enums.TWO ? (
								<>
									<Box className='progress-chart-tile'>
										<Typography variant='h5'>{t('performanceScoreText')}</Typography>
										{startComponentDetails?.performanceDetails.map((item: any) => {
											return (
												<Box className='progress-chart-top'>
													{!item?.isAlertMessage ? (
														<Typography variant='h1'>{`${item?.score || Enums.ZERO} / ${item?.totalScore}`}</Typography>
													) : (
														<></>
													)}

													{item?.isAlertMessage ? (
														<Typography variant='body2' className='text-with-icon red-color'>
															<ReportsTooltipIcon /> {item?.finalRating || ''}
														</Typography>
													) : (
														<Chip className='default-gray-chip' label={item?.finalRating || ''} />
													)}
													<Box className='duration-text'>
														<Typography variant='body2'>
															<CalanderColorIcon />{' '}
															{`${getMonthDateYear(item?.startDate)} - ${getMonthDateYear(item?.endDate)}`}
														</Typography>
													</Box>
												</Box>
											);
										})}
									</Box>
								</>
							) : startComponentDetails &&
							  startComponentDetails?.performanceDetails &&
							  startComponentDetails?.performanceDetails?.length === Enums.ONE ? (
								startComponentDetails?.performanceDetails.map((item: any) => {
									return (
										<Box className='progress-chart-tile'>
											<Typography variant='h5'>{t('performanceScoreText')}</Typography>
											<Box className='progress-chart-content'>
												<PerformanceChart
													chartData={{
														...item,
														score:
															item?.score && item?.totalScore
																? (item.score / item?.totalScore) * PERFORMANCE_CHART_MAX_VAL
																: item.score,
														totalValue: PERFORMANCE_CHART_MAX_VAL,
													}}
												/>
												<Typography variant='h6'>
													<Chip className='default-gray-chip' label={item?.finalRating || ''} />
												</Typography>
											</Box>
											<Box className='progress-bottom'>
												<Typography variant='h6'>{t('assessmentPeriodText')}</Typography>
												<Box className='duration-text'>
													<Typography variant='body2'>
														<CalanderColorIcon />{' '}
														{`${getMonthDateYear(item?.startDate)} - ${getMonthDateYear(item?.endDate)}`}
													</Typography>
												</Box>
											</Box>
										</Box>
									);
								})
							) : (
								<></>
							)}
							{/* Check-in Chart Box */}
							{middleComponentDetails ? (
								<Box
									className={`progress-chart-tile checkin-coverage ${showReflections ? 'active' : ''}`}
									onClick={handleShowReflectionsClick}
								>
									<Typography variant='h5'>{t('checkinCoverage')}</Typography>
									<Box className='progress-chart-content'>
										<CheckInCoverageChart
											chartData={middleComponentDetails?.performanceSnapshotCheckInResponse || null}
										/>
										<Typography variant='h6'>{`${
											middleComponentDetails?.performanceSnapshotCheckInResponse?.checkInCompletionPercentage ||
											Enums.ZERO
										}% of last ${
											middleComponentDetails?.performanceSnapshotCheckInResponse?.totalWeekCount || Enums.ZERO
										} weeks submitted`}</Typography>
									</Box>
									<Box className='progress-bottom'>
										<Typography variant='h6'>{t('reflectionsText')}</Typography>
										<Box className='duration-text'>
											<Typography variant='body2'>
												<CalanderColorIcon /> Last 3 weeks
											</Typography>
											<OKRButton
												text={t('viewCourse')}
												className='btn-small-text'
												handleClick={handleShowReflectionsClick}
											/>
										</Box>
									</Box>
								</Box>
							) : (
								<></>
							)}
							{/* Task Progress Box */}
							{middleComponentDetails ? (
								<Box className='progress-chart-tile'>
									<Typography variant='h5'>{t('tasksProgress')}</Typography>
									<Box className='progress-chart-content'>
										<TaskProgressChart chartData={middleComponentDetails?.performanceSnapshotTaskResponse || null} />
										<Typography variant='h6'>
											{`${
												middleComponentDetails?.performanceSnapshotTaskResponse?.taskCompletionPercentage || Enums.ZERO
											}% of ${
												middleComponentDetails?.performanceSnapshotTaskResponse?.totalTaskCount || Enums.ZERO
											}	 tasks completed`}
											<Box className='info-link'>
												<OKRButton icon={<ReportsTooltipIcon />} handleClick={handleOpen} />
												<Popper
													className={'role-description-popup white-tooltip'}
													open={open}
													anchorEl={anchorEl}
													transition
													placement={'bottom-start'}
													modifiers={{
														flip: {
															enabled: false, // true by default
														},
													}}
												>
													<ClickAwayListener onClickAway={handleClose}>
														<Box className='textarea-expand'>
															<Typography>
																Tasks without due date
																<br /> are not considered
															</Typography>
														</Box>
													</ClickAwayListener>
												</Popper>
											</Box>
										</Typography>
									</Box>
									<Box className='progress-bottom'>
										<Typography variant='h6'>{t('completedBeforeText')}</Typography>
										<Box className='priority-text'>
											<Box className='col'>
												<Tooltip
													arrow
													title={`${
														middleComponentDetails?.performanceSnapshotTaskResponse?.lowPriorityTaskCompletionCount ||
														Enums.ZERO
													} of ${
														middleComponentDetails?.performanceSnapshotTaskResponse?.totalTaskCount || Enums.ZERO
													} low priority`}
												>
													<Typography className='low-priority'>
														<LowPriorityIcon />
														{middleComponentDetails?.performanceSnapshotTaskResponse?.lowPriorityTaskCompletionCount ||
															Enums.ZERO}
													</Typography>
												</Tooltip>
											</Box>
											<Box className='col'>
												<Tooltip
													arrow
													title={`${
														middleComponentDetails?.performanceSnapshotTaskResponse
															?.mediumPriorityTaskCompletionCount || Enums.ZERO
													} of ${
														middleComponentDetails?.performanceSnapshotTaskResponse?.totalTaskCount || Enums.ZERO
													} medium priority`}
												>
													<Typography className='medium-priority'>
														<MediumPriorityIcon />
														{middleComponentDetails?.performanceSnapshotTaskResponse
															?.mediumPriorityTaskCompletionCount || Enums.ZERO}
													</Typography>
												</Tooltip>
											</Box>
											<Box className='col'>
												<Tooltip
													arrow
													title={`${
														middleComponentDetails?.performanceSnapshotTaskResponse?.highPriorityTaskCompletionCount ||
														Enums.ZERO
													} of ${
														middleComponentDetails?.performanceSnapshotTaskResponse?.totalTaskCount || Enums.ZERO
													} high priority`}
												>
													<Typography className='high-priority'>
														<HighPriorityIcon />
														{middleComponentDetails?.performanceSnapshotTaskResponse?.highPriorityTaskCompletionCount ||
															Enums.ZERO}
													</Typography>
												</Tooltip>
											</Box>
										</Box>
									</Box>
								</Box>
							) : (
								<></>
							)}
						</Box>
						<Collapse in={showReflections}>
							<Box className='progress-reflection-popup'>
								{middleComponentDetails?.performanceSnapshotCheckInResponse?.checkInWeeklyDatesResponse &&
								middleComponentDetails?.performanceSnapshotCheckInResponse?.checkInWeeklyDatesResponse?.length ? (
									<>
										<ReflectionCheckInDetails
											t={t}
											middleComponentDetails={middleComponentDetails}
											handleHideReflectionsClick={handleHideReflectionsClick}
										/>
										{/* <Box className='popup-head'>
											<Box className='popup-title'>
												<Typography variant='body2'>Reflections</Typography>
												<OKRButton
													icon={<CrossIcon />}
													className='cross-btn'
													handleClick={handleHideReflectionsClick}
												/>
											</Box>
											<ReflectionsTabs
												tabSelected={tabSelected}
												setTabSelected={setTabSelected}
												tabDetails={
													middleComponentDetails.performanceSnapshotCheckInResponse.checkInWeeklyDatesResponse
												}
											/>
										</Box>
										<Box className='popup-content'>
											{middleComponentDetails.performanceSnapshotCheckInResponse.checkInWeeklyDatesResponse?.map(
												(tabData: any, indexVal: number) => {
													return (
														<ProgressTabPanel value={tabSelected} index={indexVal}>
															{tabData?.reflections ? (
																<Box dangerouslySetInnerHTML={{ __html: tabData?.reflections }}></Box>
															) : (
																<Box className='not-found'>
																	<AnimatedIcon
																		className='no-task-image'
																		width='100x'
																		type='image/svg+xml'
																		alt={t('noContent')}
																		imagePath={NotesImg}
																	/>
																	<Box className='right-not-found'>
																		<Typography variant='body2'>No Reflection this week</Typography>
																		<Typography variant='body2' className='font-weight-normal'>
																			No achievements or roadblocks added by user for this week.
																		</Typography>
																	</Box>
																</Box>
															)}
														</ProgressTabPanel>
													);
												}
											)}
										</Box> */}
									</>
								) : (
									<></>
								)}
							</Box>
						</Collapse>
					</Box>
					<Box className='drawer-input-field'>
						{lastComponentDetails ? (
							<Box className='feedback-snapshot-area'>
								{lastComponentDetails?.userDirectReportees?.length ? (
									<FeedbackTile
										tileIcon={<DirectReportsIcon />}
										tileScore={lastComponentDetails?.userDirectReportees?.length || Enums.ZERO}
										tileTitle={t('DirectReportsLable')}
										tileSubHead={''}
										directReporteeList={lastComponentDetails?.userDirectReportees || []}
									/>
								) : (
									<></>
								)}
								<FeedbackTile
									tileIcon={<ViewFeedback />}
									tileScore={lastComponentDetails?.feedbackReceived?.totalFeedbackReceivedCount || Enums.ZERO}
									tileTitle={t('Feedback Received')}
									tileSubHead={`${
										lastComponentDetails?.feedbackReceived?.feedbackReceivedFromDirectReportees || Enums.ZERO
									} of ${
										lastComponentDetails?.feedbackReceived?.totalFeedbackReceivedCount || Enums.ZERO
									} feedback/s received from me`}
								/>
								{/* <FeedbackTile
									tileIcon={<GiveFeedbackIcon />}
									tileScore={lastComponentDetails?.feedbackGiven?.totalFeedbackGivenCount || Enums.ZERO}
									tileTitle={t('Feedback  Given')}
									tileSubHead={`${
										lastComponentDetails?.feedbackGiven?.feedbackGivenFromDirectReportees || Enums.ZERO
									} of ${
										lastComponentDetails?.feedbackGiven?.totalFeedbackGivenCount || Enums.ZERO
									} feedback/s given to me`}
								/>
								<FeedbackTile
									tileIcon={<FeedbackAddressedIcon />}
									tileScore={
										<>
											{lastComponentDetails?.feedbackAddressed?.totalFeedbackAddressedCount || Enums.ZERO}
											<sup>
												{'/'}{' '}
												{lastComponentDetails?.feedbackAddressed?.totalFeedbackAddressedPendingCount || Enums.ZERO}
											</sup>
										</>
									}
									tileTitle={t('Feedback Addressed')}
									tileSubHead={`${
										lastComponentDetails?.feedbackAddressed?.feedbackAddressedFromDirectReportees || Enums.ZERO
									} of ${
										lastComponentDetails?.feedbackAddressed?.totalFeedbackAddressedPendingCount || Enums.ZERO
									} feedback/s requested by me`}
								/> */}
								<FeedbackTile
									tileIcon={<RequestOneOnOneIcon2 />}
									tileScore={lastComponentDetails?.oneToOneConducted?.totalOneToOneConductedCount || Enums.ZERO}
									tileTitle={t('1-on-1 Attended')}
									tileSubHead={`${
										lastComponentDetails?.oneToOneConducted?.oneToOneConductedFromDirectReportees || Enums.ZERO
									} of ${
										lastComponentDetails?.oneToOneConducted?.totalOneToOneConductedCount || Enums.ZERO
									}  meeting/s attended with me`}
								/>
								<FeedbackTile
									tileIcon={<RecognizedIcon />}
									tileSecondIcon={<RewardsIcon />}
									tooltipLeftIcon={t('praisesReceived')}
									tooltipRightIcon={t('badgesReceived')}
									tileScore={
										<>
											{lastComponentDetails?.praiseReceived?.totalPraisedReceivedCount &&
											lastComponentDetails?.praiseReceived?.totalPraisedReceivedCount > 9
												? 9
												: lastComponentDetails?.praiseReceived?.totalPraisedReceivedCount || Enums.ZERO}{' '}
											{lastComponentDetails?.praiseReceived?.totalPraisedReceivedCount > 9 ? <sup>+</sup> : <></>}
										</>
									}
									tileScoreLeft={
										<>
											{lastComponentDetails?.praiseReceived?.totalPraisedReceivedWithBadgesCount &&
											lastComponentDetails?.praiseReceived?.totalPraisedReceivedWithBadgesCount > 9
												? 9
												: lastComponentDetails?.praiseReceived?.totalPraisedReceivedWithBadgesCount || Enums.ZERO}{' '}
											{lastComponentDetails?.praiseReceived?.totalPraisedReceivedWithBadgesCount > 9 ? (
												<sup>+</sup>
											) : (
												<></>
											)}
										</>
									}
									tileTitle={t('Praise Received')}
									tileSubHead={`${
										lastComponentDetails?.praiseReceived?.praisedReceivedFromDirectReportees || Enums.ZERO
									}  recognition/s and ${
										lastComponentDetails?.praiseReceived?.praisedReceivedWithBadgesFromDirectReportees || Enums.ZERO
									} badge/s received from me`}
								/>
							</Box>
						) : (
							<></>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
