import { Box } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserSnapshotDetails } from './UserSnapshotDetails';
import { MainDrawer } from '../../Admin/CommonComponent/MainDrawer';

export const UserSnapshot = (props: any) => {
	const { openSnapshot, handleDrawerClose } = props;
	const { t } = useTranslation();
	const [loader, setLoader] = useState(false);

	return (
		<MainDrawer
			open={openSnapshot}
			transitionDuration={{ enter: 500, exit: 1000 }}
			headerTitle={t('performanceSnapshot')}
			loader={loader}
			children={
				<Box className='drawer-inner-content'>
					<UserSnapshotDetails {...props} setLoader={setLoader} />
				</Box>
			}
			isFooterVisible={false}
			rightCloseBtn={true}
			drawerClassName={'main-drawer-panel main-drawer-titlepanel drawer-no-footer'}
			handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
			handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
			handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
		/>
	);
};
