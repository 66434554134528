import React, { useEffect, useState } from 'react';
import { Avatar, Box, ListItem, ListItemAvatar, ListItemText, TextField, Tooltip, Typography } from '@material-ui/core';
import '../../../../styles/pages/admin/rolesPage.scss';
import { OKRButton } from '../../../Common/OKRButton';
import {
	KababIcon,
	EditIcon,
	CloneIcon,
	DeleteIcon,
	UserAvtarIcon,
	SettingIcon,
	AddIconSvg,
	AddManageUserIcon,
} from '../../../../config/svg/CommonSvgIcon';
import { useTranslation } from 'react-i18next';
import { PopperMenu } from '../../CommonComponent/PopperMenu';
import { AvatarGroup } from '@material-ui/lab';
import { Enums } from '../../../../config/enums';
import { DescriptionTooltip } from '../../CommonComponent/DescriptionTooltip';
import { checkPermission } from '../../../../config/rolePermission';
import { addNewRole, deleteRole, editExistingRole } from '../../AdminConstant';
import { SearchUserPopover } from '../../../Common/SearchUserPopover';

export const RoleCard: React.FC<any> = (props) => {
	const {
		handleManageUsersTab,
		handleEditRole,
		roleDetail,
		getPermissionResult,
		clonedRoleId,
		setClonedRoleId,
		activeRoleId,
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [roleName, setRoleName] = useState<string>(roleDetail.roleName);
	const [addUserAnchorEl, setAddUserAnchorEl] = useState<HTMLElement | null>(null);
	const [addUserOpen, setAddUserOpen] = useState<boolean>(false);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);

	useEffect(() => {
		if (props.updateRoleNameFailed === true && props?.updateRoleNameError?.roleName) {
			setRoleName(roleDetail.roleName);
		}
	}, [props.updateRoleNameFailed]);

	const handleClick = (event: any) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
		setOpen(true);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	const { t } = useTranslation();
	const saveRoleName = () => {
		if (roleName && roleName.trim() !== '' && roleName.trim() !== roleDetail.roleName.trim()) {
			props.updateRoleName({ roleId: roleDetail.roleId, roleName: roleName });
		} else if (roleName.trim() === '') {
			setRoleName(roleDetail.roleName);
		}
	};

	const handleAddUsersClick = (event: any) => {
		setAddUserAnchorEl(addUserAnchorEl ? null : event.currentTarget);
		setAddUserOpen(true);
	};

	const onSelectedUser = async (selected: any) => {
		let data = {
			roleId: roleDetail.roleId,
			employeeIds: [selected[0].employeeId],
		};
		setAddUserOpen(false);
		const response = await props.assignRole(data);
		if (response && response.data.status === 200) {
			props.showApiMsgs(t('userAdded', { roleName: roleDetail.roleName }), 'success');
			setAddUserAnchorEl(null);
			props.fetchRolePermission();
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			setAddUserOpen(false);
			setAddUserAnchorEl(null);
			props.showApiMsgs(`${messages}`, 'error');
		}
	};

	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	return (
		<Box className='role-card'>
			<Box className='role-head'>
				<Box className='role-head-left'>
					<Box className='role-title'>
						<Typography variant='h3' className='extra-bold-font'>
							{roleDetail.roleName.substring(0, Enums.MAX_LENGTH_ROLE_NAME)}

							{roleDetail.isEditable && checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ? (
								<Tooltip
									{...(roleDetail.roleId === activeRoleId ? { open: true } : {})}
									title={roleDetail.roleId === clonedRoleId ? `${t('manageDetailAndUsers')}` : `${t('clickEditText')}`}
									placement={roleDetail.roleId === clonedRoleId ? 'top-start' : 'top-end'}
									classes={{ popper: roleDetail.roleId === clonedRoleId ? 'tooltip-layout1' : '' }}
									arrow
								>
									<TextField
										key={roleDetail.roleId}
										id={`role-name-${roleDetail.roleId}`}
										placeholder=''
										fullWidth
										inputProps={{ maxLength: Enums.MAX_LENGTH_ROLE_NAME, 'aria-label': 'role' }}
										InputLabelProps={{
											shrink: true,
										}}
										className={
											clonedRoleId === roleDetail.roleId ? 'role-title-text-field opacity1' : 'role-title-text-field'
										}
										disabled={!checkPermission(getPermissionResult?.employeePermissions, editExistingRole)}
										value={roleName}
										onChange={(e: any) => {
											setRoleName(e.target.value);
										}}
										onKeyDown={(e: any) => {
											if (e.key === 'Enter') {
												saveRoleName();
											}
										}}
										onBlur={(e: any) => {
											saveRoleName();
											setClonedRoleId(0);
										}}
										autoFocus={roleDetail.roleId === clonedRoleId}
									/>
								</Tooltip>
							) : (
								<></>
							)}
						</Typography>
						{roleDetail.roleDescription !== '' && (
							<DescriptionTooltip
								{...props}
								name={roleDetail.roleName || ''}
								description={roleDetail.roleDescription || ''}
							/>
						)}
					</Box>
				</Box>
				<Box className={`role-head-right ${open ? 'active' : ''}`}>
					{checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ? (
						<OKRButton
							className='edit-role-btn'
							handleClick={() => handleEditRole(roleDetail)}
							icon={<EditIcon />}
							title={t('editDetailsTooltipText')}
							id={`edit-role-name-${roleDetail.roleId}`}
						/>
					) : (
						<></>
					)}

					{checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ||
					checkPermission(getPermissionResult?.employeePermissions, deleteRole) ? (
						<OKRButton
							className='role-menu-btn'
							id={`role-kabab-icon-${roleDetail.roleId}`}
							handleClick={handleClick}
							icon={<KababIcon />}
						/>
					) : (
						<></>
					)}

					<PopperMenu
						anchorEl={anchorEl}
						open={open}
						handleClose={handleClose}
						{...props}
						children={
							<>
								{checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ? (
									<>
										<ListItem onClick={() => handleEditRole(roleDetail)} id={`edit-role-${roleDetail.roleId}`}>
											<ListItemAvatar>
												<SettingIcon />
											</ListItemAvatar>
											<ListItemText primary={t('editDetailsTooltipText')} />
										</ListItem>
										<ListItem onClick={() => handleManageUsersTab(roleDetail)} id={`add-mng-user-${roleDetail.roleId}`}>
											<ListItemAvatar>
												<AddManageUserIcon />
											</ListItemAvatar>
											<ListItemText primary={t('addManageUsers')} />
										</ListItem>
									</>
								) : (
									<></>
								)}

								{checkPermission(getPermissionResult?.employeePermissions, addNewRole) ? (
									<ListItem
										disabled={!roleDetail.isEditable}
										onClick={() => props.handleCloneRole(roleDetail)}
										id={`clone-role-${roleDetail.roleId}`}
										className={!roleDetail.isEditable ? 'non-Clickable' : ''}
									>
										<ListItemAvatar>
											<CloneIcon />
										</ListItemAvatar>
										{!roleDetail.isEditable ? (
											<Tooltip title={`${t('ceoCloneMsg')}`} placement='top-end' arrow>
												<ListItemText primary={t('cloneThisRole')} />
											</Tooltip>
										) : (
											<ListItemText primary={t('cloneThisRole')} />
										)}
									</ListItem>
								) : (
									<></>
								)}

								{checkPermission(getPermissionResult?.employeePermissions, deleteRole) && !roleDetail.isDefault ? (
									<ListItem onClick={() => props.handleDeleteRole(roleDetail)} id={`delete-role-${roleDetail.roleId}`}>
										<ListItemAvatar>
											<DeleteIcon />
										</ListItemAvatar>
										<ListItemText primary={t('delete')} />
									</ListItem>
								) : (
									<></>
								)}
							</>
						}
					></PopperMenu>
				</Box>
			</Box>
			<Box className='role-content'>
				<Box className='user-role-info'>
					<Box className='user-avatar-count' onClick={() => handleManageUsersTab(roleDetail)}>
						<AvatarGroup className='avatar-count-group' max={1}>
							<Avatar className='avatar-default-grey' alt='User Avtar'>
								<UserAvtarIcon />
							</Avatar>
							<Avatar
								alt='User Avtar Count'
								className={roleDetail.totalAssignedEmployees.toString().length > 2 ? 'count-more-then-two' : ''}
							>
								{roleDetail.totalAssignedEmployees}
							</Avatar>
						</AvatarGroup>
					</Box>
					<Box className='user-quick-search'>
						{checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ? (
							<OKRButton
								className='add-btn2'
								icon={<AddIconSvg />}
								handleClick={(e) => handleAddUsersClick(e)}
								id={`quick-add-${roleDetail.roleId}`}
							/>
						) : (
							<></>
						)}
						{addUserOpen && (
							<SearchUserPopover
								{...props}
								addContributorAnchorEl={addUserAnchorEl}
								addContributorOpen={addUserOpen}
								setAddContributorOpen={setAddUserOpen}
								setAddContributorAnchorEl={setAddUserAnchorEl}
								isCustomStyle={customStyle}
								onSelectedUser={onSelectedUser}
								handleSearchUserInputChange={handleSearchUserInputChange}
								addUserInRole={true} //Flag to role only
								popperAddClassName={'user-quick-search-popover'}
								customId={'role-card-src-field'}
							/>
						)}
					</Box>
				</Box>
				<Box className='role-status'>
					<Typography variant='subtitle2' className={roleDetail.isActive ? '' : 'inactive'}>
						{roleDetail.isActive ? t('activeLabel') : t('inactiveLabel')}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};
