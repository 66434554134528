import React, { useState, useEffect, Fragment } from 'react';
import {
	Avatar,
	Box,
	Button,
	Link,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextareaAutosize,
} from '@material-ui/core';
import { AlertInfoIcon, DraftIcon, EditIcon } from '../../../config/svg/CommonSvg';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { Rating } from '@material-ui/lab';
import { StarBorderIcon, StarFilledIcon } from '../../../config/svg/RatingSvg';
import { useSnackbar } from 'notistack';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { getPerformanceRatings, getUserDetails, getUserName } from '../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import { resetTeamAssessmentList, saveMultipleEmployeesRating, saveSelfAssessment } from '../../../action/perform';
import _ from 'lodash';
import { CancelIcon } from '../../../config/svg/Action';
import { getLocalStorageItem } from '../../../services/StorageService';
import { getOkrMasterData } from '../../../action/myGoal';

export const AssessmentQuestion = (props: any) => {
	const { t } = useTranslation();
	const { employeeAssessmentResponse, employeeAssessment, performanceRatings } = props;
	const [questionList, setQuestionList] = useState<any>({});
	const [multipleAssessResponse, setMultipleAssessResponse] = useState<any>([]);
	const [employeeList, setEmployeeList] = useState<any>([]);
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [finalRatingList, setFinalRatingList] = React.useState<any>([]);
	const loggedInUserDetail = getUserDetails();
	const { performanceCycle, performanceAlert } = useSelector((state: any) => state.performReducer);
	const [canEdit, setCanEdit] = React.useState<boolean>(false);
	const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	useEffect(() => {
		if (employeeAssessment) {
			let ratingQuestions: any = [];
			let feedbackQuestions: any = [];
			let responseData = JSON.parse(JSON.stringify(employeeAssessment));
			let getRatingQuestion = responseData.filter((question: any, index: number) => {
				if (index === 0) {
					question.employeeQuestions.filter((item: any) => {
						if (item.questionsType === 1) {
							ratingQuestions.push(item);
							return item;
						} else if (item.questionsType === 2) {
							feedbackQuestions.push(item);
							return item;
						}
					});
				}
				// return findQues;
			});
			setQuestionList({
				ratingQuestions: ratingQuestions,
				feedbackQuestions: feedbackQuestions,
			});

			let getEmployees = responseData.map((data: any) => {
				return data.employeeDetailsResponse;
			});
			setEmployeeList(getEmployees);
			setMultipleAssessResponse(responseData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employeeAssessment]);

	const handleUpdateRating = (
		e: any,
		value: any,
		item: any,
		questionIndex: number,
		indexRating: number,
		type?: any
	) => {
		let dataObj: any = [...multipleAssessResponse];
		dataObj[questionIndex].employeeQuestions[indexRating].ratingValue = value;
		//let empoyeeId = dataObj[questionIndex].employeeDetailsResponse.employeeId;
		if (type === 'feedback') {
			if (employeeAssessment[questionIndex].employeeQuestions[indexRating].ratingValue !== value) {
				setMultipleAssessResponse(dataObj);
				//saveRatingAndFeedback(item, empoyeeId);
			}
		} else {
			setMultipleAssessResponse(dataObj);
			//saveRatingAndFeedback(item, empoyeeId);
		}
	};

	const handleFeedbackUpdate = (e: any, value: any, item: any, questionIndex: number, index: number) => {
		let dataObj: any = [...multipleAssessResponse];
		dataObj[questionIndex].employeeQuestions[index].ratingValue = value;
		setMultipleAssessResponse(dataObj);
		props.setIsFormEdited(true);
		// saveRatingAndFeedback(item);
	};

	const saveRating = async (saveLater: boolean) => {
		setButtonDisabled(true);
		dispatch(showLoader(true));
		if (0 && props.fromTeamAssessment) {
			handleSubmit(saveLater);
		} else {
			let data = {
				ratingAssessmentId: employeeAssessmentResponse.ratingAssessmentId,
				employeeId: employeeAssessmentResponse.employeeId,
				reportingTo: employeeAssessmentResponse.reportingTo,
				performanceCycleId: performanceCycle?.performanceCycleId,
				isRequestOneToOneDone: employeeAssessmentResponse.isRequestOneToOne,
				isSelfAssessmentDone: employeeAssessmentResponse.isSelfAssessmentDone,
				selfAssessmentScore: employeeAssessmentResponse.selfAssessmentScore,
				isManagerAssessmentDone: employeeAssessmentResponse.isManagerAssessmentDone,
				managerSelfAssessmentScore: employeeAssessmentResponse.managerAssessmentScore,
				commitedOkrScore: employeeAssessmentResponse.okrScore,
				finalRatingId: employeeAssessmentResponse.finalRatingId,
				finalScore: employeeAssessmentResponse.finalScore,
				status: saveLater ? 1 : 2,
				ratingAssessmentType: props.fromTeamAssessment ? 2 : 1,
				selfRatingAssessmentDetailRequest: multipleAssessResponse[0].employeeQuestions.map((item: any) => {
					return {
						...item,
						ratingAssessmentType: props.fromTeamAssessment ? 2 : 1,
						ratingValue: item.ratingValue + '',
					};
				}),
			};
			let response: any = await dispatch(saveSelfAssessment(data));
			if (response.data.status === Enums.STATUS_SUCCESS) {
				dispatch(showLoader(false));
				props.setIsFormEdited(false);
				if (props.fromTeamAssessment) {
					if (!saveLater) {
						enqueueSnackbar(`${t('managerAssessmentSaved')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					} else {
						enqueueSnackbar(`${t('dataSavedSuccess')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					}
				} else {
					if (!saveLater) {
						enqueueSnackbar(`${t('selfAssessmentSaved')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					} else {
						enqueueSnackbar(`${t('dataSavedSuccess')}`, {
							variant: 'success',
							autoHideDuration: 5000,
						});
					}
				}

				props.getEmployeeList();
				setButtonDisabled(false);
			}
		}
	};
	const handleSubmit = async (saveLater: boolean) => {
		dispatch(showLoader(true));
		let saveDataObj = multipleAssessResponse[0].employeeQuestions.map((ques: any) => {
			return {
				...ques,
				status: saveLater ? 1 : 2,
				employeeId: employeeAssessmentResponse.employeeId,
				ratingAssessmentType: 2,
				ratingValue: ques.ratingValue.toString(),
				performanceCycleId: performanceCycle.performanceCycleId,
			};
		});
		let flatArray = _.flatten(saveDataObj);
		let response: any = await dispatch(saveMultipleEmployeesRating(flatArray));
		if (response.data.status === Enums.STATUS_SUCCESS) {
			props.getEmployeeList();
			if (!saveLater) {
				enqueueSnackbar(`${t('managerAssessmentSaved')}`, {
					variant: 'success',
					autoHideDuration: 5000,
				});
			} else {
				enqueueSnackbar(`${t('dataSavedSuccess')}`, {
					variant: 'success',
					autoHideDuration: 5000,
				});
			}
		} else {
			if (response?.data?.messageList) {
				if (response?.data?.messageList.message) {
					enqueueSnackbar(`${response?.data?.messageList.message}`, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				} else {
					const responseAPI = response?.data?.messageList;
					const keys = Object.keys(responseAPI);
					const messages = keys.map((item) => responseAPI[item]);
					enqueueSnackbar(`${messages}`, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		}
	};
	const getRatingValue = (ratingValue: any, type = 1) => {
		let ratingVal = type === 1 ? getPerformanceRatings(performanceRatings, ratingValue) : ratingValue;
		return !props.fromTeamAssessment ? (
			!employeeAssessmentResponse.isVisibleIfEachCompleted &&
			!employeeAssessmentResponse.isVisibleIfFinalCompleted &&
			!employeeAssessmentResponse.isVisibleOnlyFinalIfFinalCompleted ? (
				<>{employeeAssessmentResponse.isManagerAssessmentDone ? 'Done' : '- -'}</>
			) : employeeAssessmentResponse.isVisibleIfFinalCompleted ? (
				<>
					{!employeeAssessmentResponse.isManagerAssessmentDone
						? '- -'
						: employeeAssessmentResponse.finalRatingId !== 0 && employeeAssessmentResponse.isManagerAssessmentDone
						? ratingVal
						: 'Done'}
				</>
			) : employeeAssessmentResponse.isVisibleOnlyFinalIfFinalCompleted ? (
				<>{employeeAssessmentResponse.isManagerAssessmentDone ? 'Done' : '- -'}</>
			) : employeeAssessmentResponse.isVisibleIfEachCompleted && employeeAssessmentResponse.isManagerAssessmentDone ? (
				ratingVal
			) : employeeAssessmentResponse.isSelfAssessmentDone ? ratingVal : (
				<></>
			)
		) : props.fromTeamAssessment && ratingValue ? (
			ratingVal
		) : (
			'- -'
		);
	};
	return (
		<>
			<Box className='perform-assess-head'>
				<Box className='perform-assess-head-left'>
					{props.fromTeamAssessment ? (
						<Typography variant='h2'>
							{t('managerAssessment')} {props.showManagerEvaluation && t('vsSelf')}
						</Typography>
					) : !employeeAssessmentResponse?.isSelfRatingPercentage &&
					  employeeAssessmentResponse?.isManagerAssessmentDone ? (
						<Typography variant='h2'>{t('managerAssessment')}</Typography>
					) : (
						<Typography variant='h2'>
							{t('selfAssessment')} {props.showManagerEvaluation && t('vsManager')}
						</Typography>
					)}
					{!props.fromTeamAssessment && performanceAlert?.isSelfAssessmentAlert ? (
						<Typography className='alert-text' variant='h6'>
							{performanceAlert.selfRemainingDaysMessage && (
								<>
									<AlertInfoIcon /> {performanceAlert.selfRemainingDaysMessage}
								</>
							)}
						</Typography>
					) : props.fromTeamAssessment && performanceAlert?.isManagerAssessmentAlert ? (
						<Typography className='alert-text' variant='h6'>
							{performanceAlert.managerRemainingDaysMessage && (
								<>
									<AlertInfoIcon /> {performanceAlert.managerRemainingDaysMessage}
								</>
							)}
						</Typography>
					) : (
						<></>
					)}
				</Box>
				{tenantData.tenantId === '66f0a6ae-1457-4b6f-a197-1bebd770bbdf' && (
					<Box className='perform-assess-head-right'>
						<Link
							className='help-doc'
							id='help-document'
							target='_blank'
							href='https://unlockokrblobcdnprod.azureedge.net/common/PSC_Launch_V_1.pdf'
						>
							{t('helpDocument')}
						</Link>
					</Box>
				)}
			</Box>
			<Box className='perform-assess-content-area'>
				<Box className='assessment-ques-list-area self-assessment-list'>
					<TableContainer>
						<Table stickyHeader>
							<TableHead>
								<TableRow className='assessment-table-head'>
									<TableCell>
										<Box className='cell-wrap'>
											<Typography variant='subtitle1'></Typography>
										</Box>
									</TableCell>
									<TableCell>
										<Box className='cell-wrap'>
											<Typography variant='subtitle1'></Typography>
										</Box>
									</TableCell>
									{employeeList &&
										employeeList.length > 0 &&
										(props.fromTeamAssessment || employeeList.length !== 1) &&
										employeeList.map((user: any, index: number) => (
											<TableCell key={index}>
												<Box className='cell-wrap'>
													<Box className='user-info'>
														<Box className='user-info-details'>
															<Box className='user-img'>
																{user?.imagePath ? (
																	<Avatar src={user?.imagePath} />
																) : (
																	<Avatar>
																		{getUserName({
																			fullName: '',
																			firstName: user?.firstName,
																			lastName: user?.lastName,
																		})}
																	</Avatar>
																)}
															</Box>
															<Typography>{`${user?.firstName} ${user?.lastName}`}</Typography>
															{/* {user.employeeId === loggedInUserDetail.employeeId && (
																<Typography component='span' className='myself-txt' variant='body2'>
																	{t('mySelfLabel')}
																</Typography>
															)} */}
														</Box>
														{user?.employeeId !== loggedInUserDetail.employeeId && !props.isFinalRating ? (
															<OKRButton
																className='cancel-btn'
																icon={<CancelIcon />}
																title={t('cancelBtn')}
																handleClick={() => {
																	props.setShowManagerEvaluation(!props.showManagerEvaluation);
																}}
															/>
														) : (
															!canEdit &&
															employeeAssessmentResponse.isManagerAssessmentDone &&
															props.fromTeamAssessment &&
															!props.isFinalRating &&
															employeeAssessmentResponse.finalRatingId === 0 &&
															index === 0 &&
															props.canEditQuestionsWithRating && (
																<OKRButton
																	className='edit-btn'
																	icon={<EditIcon />}
																	title={t('editLabel')}
																	handleClick={() => {
																		setCanEdit(true);
																	}}
																/>
															)
														)}
													</Box>
												</Box>
											</TableCell>
										))}
								</TableRow>
							</TableHead>
							<TableBody>
								<Fragment>
									<TableRow className='assessment-ques-header'>
										<TableCell colSpan={employeeList.length + 2}>
											<TableRow>
												<TableCell>
													<Box className='cell-wrap'>
														<Typography variant='subtitle1'>Performance Evaluation Questions</Typography>
													</Box>
												</TableCell>
											</TableRow>
										</TableCell>
									</TableRow>
									<TableRow className='assessment-ques-list'>
										<TableCell colSpan={employeeList.length + 2}>
											<Table style={{ width: '100%' }}>
												{questionList?.ratingQuestions && questionList?.ratingQuestions.length ? (
													questionList.ratingQuestions.map((question: any, index: number) => (
														<>
															<TableRow key={index} className='assessment-ques-row'>
																<TableCell>
																	<Box className='cell-wrap cell-wrap-number'>
																		<Typography className='assessment-preview-index'>{index + 1}</Typography>
																	</Box>
																</TableCell>
																<TableCell>
																	<Box className='cell-wrap'>
																		<Typography>{question.question}</Typography>
																	</Box>
																</TableCell>

																{multipleAssessResponse.length > 0 &&
																	multipleAssessResponse.map((values: any, questionIndex: number) => (
																		<TableCell>
																			<Box className='cell-wrap'>
																				{values.employeeQuestions.map(
																					(item: any, indexRating: number) =>
																						question.performanceRoleLinkedFormDetailsId ===
																							item.performanceRoleLinkedFormDetailsId && (
																							<Box className='assessment-rating-box'>
																								{props.canEditQuestionsWithRating &&
																								(!props.finalSubmitted || canEdit) &&
																								questionIndex === 0 ? (
																									<>
																										<Rating
																											name={
																												'rating' + item.ratingAssessmentId + indexRating + questionIndex
																											}
																											// defaultValue={0}
																											value={item.ratingValue}
																											precision={1}
																											size='large'
																											icon={<StarFilledIcon />}
																											emptyIcon={<StarBorderIcon />}
																											onChange={(e, value) => {
																												handleUpdateRating(e, value, item, questionIndex, indexRating);
																												props.setIsFormEdited(true);
																											}}
																										/>
																										{item.ratingValue !== null && (
																											<Typography variant='h6'>
																												{getPerformanceRatings(performanceRatings, item.ratingValue)}
																											</Typography>
																										)}
																									</>
																								) : !props.canEditQuestionsWithRating && !props.finalSubmitted ? (
																									<>Rating Not Done</>
																								) : item.ratingValue !== null ? (
																									<Typography variant='h5'>
																										{getRatingValue(item.ratingValue, 1)}
																									</Typography>
																								) : (
																									'--'
																								)}
																							</Box>
																						)
																				)}
																			</Box>
																		</TableCell>
																	))}
															</TableRow>
															<TableRow>
																<TableCell colSpan={employeeList.length + 2} className='row-space'></TableCell>
															</TableRow>
														</>
													))
												) : (
													<></>
												)}
											</Table>
										</TableCell>
									</TableRow>
									{questionList?.feedbackQuestions && questionList?.feedbackQuestions.length > 0 && (
										<>
											<TableRow className='assessment-ques-header'>
												<TableCell colSpan={employeeList.length + 2}>
													<TableRow>
														<TableCell>
															<Box className='cell-wrap'>
																<Typography variant='subtitle1'>Performance Feedback Questions</Typography>
															</Box>
														</TableCell>
													</TableRow>
												</TableCell>
											</TableRow>
											<TableRow className='assessment-ques-list'>
												<TableCell colSpan={employeeList.length + 2}>
													<Table style={{ width: '100%' }}>
														{questionList?.feedbackQuestions &&
															questionList?.feedbackQuestions.length &&
															questionList?.feedbackQuestions.map((question: any, index: number) => (
																<>
																	<TableRow key={index} className='assessment-ques-row'>
																		<TableCell>
																			<Box className='cell-wrap cell-wrap-number'>
																				<Typography className='assessment-preview-index'>{index + 1}</Typography>
																			</Box>
																		</TableCell>
																		<TableCell>
																			<Box className='cell-wrap'>
																				<Typography>{question.question}</Typography>
																			</Box>
																		</TableCell>
																		{multipleAssessResponse.length > 0 &&
																			multipleAssessResponse.map((values: any, questionIndex: number) => (
																				<TableCell>
																					<Box className='cell-wrap'>
																						{values.employeeQuestions.map(
																							(item: any, indexRating: number) =>
																								question.performanceRoleLinkedFormDetailsId ===
																									item.performanceRoleLinkedFormDetailsId && (
																									<>
																										{props.canEditQuestionsWithRating &&
																										(!props.finalSubmitted || canEdit) &&
																										questionIndex == 0 ? (
																											<TextareaAutosize
																												className='response-input-field'
																												aria-label='minimum height'
																												minRows={3}
																												placeholder='Write your response'
																												onChange={(e) =>
																													handleFeedbackUpdate(
																														e,
																														e.target.value,
																														item,
																														questionIndex,
																														indexRating
																													)
																												}
																												onBlur={(e) => {
																													/* handleUpdateRating(
																											e,
																											e.target.value,
																											item,
																											questionIndex,
																											indexRating,
																											'feedback'
																										) */
																												}}
																												name={'feedback' + indexRating}
																												value={item.ratingValue}
																											/>
																										) : !props.canEditQuestionsWithRating && !props.finalSubmitted ? (
																											<>Rating Not Done</>
																										) : item.ratingValue !== null ? (
																											<Typography variant='h5' className='font-weight-normal'>
																												{getRatingValue(item.ratingValue, 2)}
																											</Typography>
																										) : (
																											<Typography variant='h5' className='font-weight-normal'>
																												{'- -'}
																											</Typography>
																										)}
																									</>
																								)
																						)}
																					</Box>
																				</TableCell>
																			))}
																	</TableRow>
																	<TableRow>
																		<TableCell colSpan={employeeList.length + 2} className='row-space'></TableCell>
																	</TableRow>
																</>
															))}
													</Table>
												</TableCell>
											</TableRow>
										</>
									)}
								</Fragment>
							</TableBody>
						</Table>
					</TableContainer>
					{props.canEditQuestionsWithRating && (
						<Box className='assessment-table-footer'>
							<Box className='assessment-notes-txt'>
								<Typography variant='h6' className='font-weight-normal'>
									{t('assessmentNoteText')}
								</Typography>
							</Box>
							{(!props.finalSubmitted || canEdit) && (
								<Box className='assessment-footer-action'>
									{!props.finalSubmitted && (
										<OKRButton
											className='btn-link'
											icon={<DraftIcon />}
											id={'btn-save-later'}
											text={t('saveSubmitLater')}
											handleClick={() => saveRating(true)}
											disabled={buttonDisabled}
										/>
									)}
									<OKRButton
										className='btn-primary'
										id={'btn-save'}
										text={'Submit'}
										handleClick={() => saveRating(false)}
										disabled={
											(multipleAssessResponse &&
												multipleAssessResponse.length > 0 &&
												multipleAssessResponse[0].employeeQuestions.filter((item: any) => {
													return !item.ratingValue || (item.questionsType === 1 && item.ratingValue === '0');
												}).length) ||
											buttonDisabled
										}
									/>
								</Box>
							)}
						</Box>
					)}
				</Box>
			</Box>
		</>
	);
};
