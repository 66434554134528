import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Enums } from '../../../config/enums';
import { TaskListItem } from './TaskListItem';
import { OKRButton } from '../../Common/OKRButton';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NotesImg from '../../../images/no-found-notes.svg';

export const TaskListDetails: React.FC<any> = (props: any) => {
	const {
		allTaskList,
		showLoadMore,
		pageIndexVal,
		fetchGlobalTask,
		loader,
		globalTaskRequest,
		handleTaskLinkChange,
		taskPagingInfo,
	} = props;
	const { t } = useTranslation();

	return allTaskList && allTaskList.length === Enums.ZERO ? (
		<>
			{/* {!globalTaskRequest?.startDate && !globalTaskRequest?.endDate ? (
				<Box className='welcome-content'>
					<Box textAlign='center'>
						<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
						<Typography>{t('noTaskFoundSubTitle')}</Typography>
						<AnimatedIcon
							className='no-task-image'
							width='323px'
							type='image/svg+xml'
							alt={t('noTaskHead')}
							imagePath={NotesImg}
						/>
					</Box>
				</Box>
			) : ( */}
			<Box className='welcome-content'>
				<Box textAlign='center'>
					<Typography variant='h2'>{t('noTaskFoundTitle')}</Typography>
					<Typography>{t('noTaskFoundSubTitle')}</Typography>
					<AnimatedIcon
						className='no-task-image'
						width='323px'
						type='image/svg+xml'
						alt={t('noTaskHead')}
						imagePath={NotesImg}
					/>
				</Box>
			</Box>
			{/* )} */}
		</>
	) : (
		<Box className='task-list-panel-area full-rectangular-card'>
			{allTaskList && allTaskList.length ? (
				<>
					<Box className='task-list-panel-head'>
						<Typography variant='h6'>{`Tasks (${
							taskPagingInfo && taskPagingInfo?.taskLength ? taskPagingInfo.taskLength : allTaskList.length
						} ${taskPagingInfo && taskPagingInfo.totalRecords ? '/' + taskPagingInfo.totalRecords : ''})`}</Typography>
					</Box>
					<Box className='task-list-panel-inner'>
						{allTaskList?.map((task: any, index: number) => (
							<TaskListItem {...props} task={task} index={index} handleTaskLinkChange={handleTaskLinkChange} />
						))}
					</Box>
					{showLoadMore ? (
						<Box className='load-more-area'>
							<OKRButton
								className='load-more-btn btn-small-text'
								handleClick={() => {
									fetchGlobalTask(pageIndexVal || 0, 0);
								}}
								text={
									loader ? (
										<Box className='spinner'>
											<Box className='bounce1'></Box>
											<Box className='bounce2'></Box>
											<Box className='bounce3'></Box>
										</Box>
									) : (
										t('viewMoreTasks')
									)
								}
							/>
						</Box>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</Box>
	);
};
