/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
	clearLocalStorage,
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../../../services/StorageService';
import '../../../styles/pages/login/login.scss';
import { ORGANIZATIONS, LOGOUT_INITIATOR_URL, DASHBOARD, PERFORM, RECOGNIZE, HOME } from '../../../config/app-url';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { WithoutHttpDefaultOrigin } from '../../../config/constant';
import { getDecryptString, getEncryptString, getLastParamFromCurrentUrl } from '../../../config/utils';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import {
	currentDashboardTab,
	currentDashboardTabForSO,
	setNomineeDetailsForRedirection,
	setPerformanceGoalForRedirection,
	updateRoute,
} from '../../../action/common';

export default function Login(props) {
	const history = useHistory();
	const auth = useAuth();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const origin = window.location.host.indexOf('localhost') >= 0 ? WithoutHttpDefaultOrigin : window.location.host;
	const paramState = window.location.search.split('state=');
	const paramsCode = window.location.search.split('?code=');
	const [loadingMessage, setLoadingMessage] = useState('');
	const [isInActiveDomain, setIsInActiveDomain] = useState(false);

	useEffect(() => {
		let queryString = props.location.search.split('?code=');
		let id = queryString[1] ? queryString[1] : '';
		let isImpersonateDetail = {};

		if (id && window.location.href.indexOf('&state=') === -1) {
			let urlDetail = getDecryptString(id);
			if (urlDetail) {
				if (!getLocalStorageItem('isStorageClear')) {
					//clear local storage
					clearLocalStorage();
					sessionStorage.clear();
					setLocalStorageItem('isStorageClear', true);
				}
				try {
					const obj = JSON.parse(urlDetail);
					if (obj) {
						isImpersonateDetail.emailId = obj.emailId;
						isImpersonateDetail.isImpersonate = obj.isImpersonate;
						isImpersonateDetail.impersonatedByUser = obj.impersonatedByUser;
						isImpersonateDetail.impersonatedById = 1; //support User
						isImpersonateDetail.impersonatedByUserName = isImpersonateDetail.impersonatedByUser;

						setLocalStorageItem('authEmailId', isImpersonateDetail.emailId);
						setLocalStorageItem('authName', isImpersonateDetail.impersonatedByUserName);
					}
				} catch (e) {
					console.log(e);
					const urlParams = new URLSearchParams(urlDetail);
					isImpersonateDetail.emailId = urlParams.get('emailid');
					isImpersonateDetail.password = urlParams.get('password');
					isImpersonateDetail.isSSO = urlParams.get('IsSSO');
					isImpersonateDetail.forgetPassword = urlParams.get('ForgetPassword');
					isImpersonateDetail.isResetPassword = urlParams.get('isResetPassword');
				}
				setLocalStorageItem('impersonateUserDetail', getEncryptString(JSON.stringify(isImpersonateDetail)));
			} else {
				setLocalStorageItem('impersonateUserDetail', '');
			}
		}
		tenantActionOkrAPI();
	});

	const tenantActionOkrAPI = () => {
		const isAuthenticated = getLocalStorageItem('isAuthenticated');
		const isSSO = getLocalStorageItem('isSSO');
		let impersonateUserDetail = getLocalStorageItem('impersonateUserDetail')
			? JSON.parse(getDecryptString(getLocalStorageItem('impersonateUserDetail')))
			: '';
		if (!isAuthenticated && !isSSO) {
			let emailId = impersonateUserDetail && impersonateUserDetail.emailId ? impersonateUserDetail.emailId : false;
			props
				.tenantActionOKR({
					origin: origin,
					emaiId: emailId,
				})
				.then((resp) => {
					if (resp.data.isSuccess) {
						setLocalStorageItem('idpId', resp.data.entity.idpId);
						setLocalStorageItem('isSSO', resp.data.entity.isSSO);
						setLocalStorageItem('isLGAllowed', resp.data.entity.isLGAllowed);
						setLocalStorageItem('subDomain', resp.data.entity.subDomain);
						setLocalStorageItem('TenantId', resp.data.entity.tenant);
						setLocalStorageItem('tokenId', JSON.stringify(resp.data.entity.accessToken));
						setLocalStorageItem('accessTokenId', JSON.stringify(resp.data.entity.accessToken));
						setLocalStorageItem('isAuthUserValidate', resp.data.entity.isAuthenticated);

						if (
							(impersonateUserDetail.isResetPassword === 'true' || impersonateUserDetail.forgetPassword === 'true') &&
							impersonateUserDetail.isSSO === 'false'
						) {
							if (resp.data.entity.isAuthenticated) {
								props.history.push('/custom-login');
								props.updateStatus();
								removeLocalStorageItem('isStorageClear');
							} else {
								setLocalStorageItem('isResetLink', impersonateUserDetail.emailId);
								props.history.push('/reset-password');
								props.updateStatus();
								removeLocalStorageItem('isStorageClear');
							}
							window.location.reload();
						} else {
							if (impersonateUserDetail && impersonateUserDetail.emailId && !impersonateUserDetail.isImpersonate) {
								//Instance creation db/SSO login
								authenticatedTenantAPI(impersonateUserDetail.emailId);
							}
							if (impersonateUserDetail && impersonateUserDetail.emailId && impersonateUserDetail.isImpersonate) {
								//Impersonate from support portal
								authenticatedTenantAPI(impersonateUserDetail.emailId);
							} else if (resp.data.entity.isSSO) {
								auth.signinRedirect();
							} else {
								//normal db login
								props.history.push('/custom-login');
								props.updateStatus();
								removeLocalStorageItem('isStorageClear');
							}
						}
					} else {
						setIsInActiveDomain(true);
					}
				});
		} else if (
			getLocalStorageItem('authEmailId') &&
			!auth.isAuthenticated &&
			(isSSO === 'true' || impersonateUserDetail.isImpersonate)
		) {
			authenticatedTenantAPI(getLocalStorageItem('authEmailId'));
		} else if (!auth.isAuthenticated && isSSO === 'false') {
			props.history.push('/custom-login');
			removeLocalStorageItem('isStorageClear');
		} else if (!auth.isAuthenticated && !(paramState[1] || paramsCode[1]) && getLocalStorageItem('isSSO') === 'true') {
			removeLocalStorageItem('isSSO');
		}
	};

	const authenticatedTenantAPI = (emailId) => {
		setLocalStorageItem('signInStatus', 200);
		setLocalStorageItem('isAuthenticated', true);

		if (
			!getLocalStorageItem('TenantData') ||
			(getLocalStorageItem('TenantData') &&
				(!getLocalStorageItem('userDetail') || !getLocalStorageItem('currentCycle')))
		) {
			props.tenantAction({ subDomain: origin, emailId: emailId }).then((resp) => {
				if (resp.data.isSuccess) {
					setLocalStorageItem('TenantData', JSON.stringify(resp.data.entity));
					//call user by token API
					userValidationAPI();
				} else {
					setLoadingMessage(resp.data.messageList.record);
					setTimeout(function () {
						logoutUser();
					}, 3000);
				}
			});
		} else if (
			getLocalStorageItem('TenantData') &&
			getLocalStorageItem('userDetail') &&
			getLocalStorageItem('currentCycle')
		) {
			props.history.push(DASHBOARD);
			props.updateStatus();
		}
	};

	const userValidationAPI = () => {
		//call UserValidation API
		let impersonateUserDetail = getLocalStorageItem('impersonateUserDetail')
			? JSON.parse(getDecryptString(getLocalStorageItem('impersonateUserDetail')))
			: '';
		impersonateUserDetail && impersonateUserDetail.isImpersonate ? impersonateUserAPI() : signInActionDBAPI();
		//props.updateStatus();
	};

	const impersonateUserAPI = () => {
		let impersonateUserDetail = getLocalStorageItem('impersonateUserDetail')
			? JSON.parse(getDecryptString(getLocalStorageItem('impersonateUserDetail')))
			: '';
		let impersonateUserObj = {
			isImpersonate: true,
			impersonateUser: impersonateUserDetail.emailId,
			impersonatedByUser: impersonateUserDetail.impersonatedByUser,
			impersonatedById: impersonateUserDetail.impersonatedById,
			impersonatedByUserName: impersonateUserDetail.impersonatedByUserName,
			impersonatedBySupport: true,
		};
		props.impersonateUser(impersonateUserObj).then((resp) => {
			if (resp.data.status === 200) {
				setLocalStorageItem('signInStatus', resp.data.status);
				setLocalStorageItem('userDetail', JSON.stringify(resp.data.entity));
				setLocalStorageItem('isAbleToDesign', false);
				setLocalStorageItem('organisationId', resp.data.entity.organisationId);

				//call employee permissions
				getEmployeePermissions();
			} else if (resp.data.status === 400) {
				const responseAPI = props.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
			}
		});
	};

	const signInActionDBAPI = () => {
		let impersonateUserDetail = getLocalStorageItem('impersonateUserDetail')
			? JSON.parse(getDecryptString(getLocalStorageItem('impersonateUserDetail')))
			: '';
		let emailId = impersonateUserDetail ? impersonateUserDetail?.emailId : getLocalStorageItem('authEmailId');
		let password = impersonateUserDetail ? impersonateUserDetail?.password : 'password';

		let signInActionDBObj = { userEmailId: emailId, userPassword: password };

		props.signInActionDB(signInActionDBObj).then((resp) => {
			if (resp.data.status === 200) {
				setLocalStorageItem('signInStatus', resp.data.status);
				setLocalStorageItem('userDetail', JSON.stringify(resp.data.entity));
				setLocalStorageItem('isAbleToDesign', false);
				setLocalStorageItem('organisationId', resp.data.entity.organisationId);

				//call employee permissions
				getEmployeePermissions();
			} else if (resp.data.status === 400) {
				const responseAPI = props.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				enqueueSnackbar(`${messages} `, { variant: 'error', autoHideDuration: 5000 });
			}
		});
	};

	const getEmployeePermissions = () => {
		//==== Get Employee profile related permission
		props.getEmployeePermission(undefined, history);

		//bind current cycle data
		if (getLocalStorageItem('organisationId')) {
			orgCurrentCycleAPI();
		}

		//== update onboarding count and call onboarding API if applicable
		props.updateOnboardingCount({ skipCount: props.entity.skipCount, readyCount: props.entity.readyCount });
	};

	const orgCurrentCycleAPI = () => {
		props.orgCurrentCycleAction().then((response) => {
			if (!getLocalStorageItem('cycleDetail')) {
				props.getCycleDetails(`organisationId=${response.data.entity.organisationCycleId}`).then((resp) => {
					setLocalStorageItem('cycleDetail', JSON.stringify(resp.data.entity));
				});
			}
			setLocalStorageItem('currentCycle', JSON.stringify(response.data.entity));
			setLocalStorageItem('cycleId', JSON.stringify(response.data.entity.organisationCycleId));
			setLocalStorageItem('year', response.data.entity.cycleYear);
			setLocalStorageItem('currentYear', response.data.entity.cycleYear);

			props.currentCycleStatus(response.data.entity);

			//manage Redirect urls
			manageRedirectUrls();
		});

		props.updateStatus();
	};

	const manageRedirectUrls = () => {
		let redirectUrl = getLocalStorageItem('redirectUrl');
		const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
		const empId = getLocalStorageItem('empId');
		if (Boolean(redirectUrl)) {
			redirectUrl = JSON.parse(redirectUrl);
			if (redirectUrl.url && redirectUrl.url.includes('/')) {
				if (redirectUrl.url.includes('Organization') && userDetail.loggedInAs && userDetail.loggedInAs === 'Admin') {
					setLocalStorageItem('LoginAs', 'admin');
					props.history.push(ORGANIZATIONS);
				} else if (
					redirectUrl?.url.split('/')[0] === 'performance-goal' &&
					empId &&
					parseInt(empId) === userDetail?.employeeId
				) {
					const urlItem = redirectUrl?.url.split('/');
					const tabId = urlItem[1] ? parseInt(urlItem[1]) : 0;
					const cycleId = urlItem[2] ? parseInt(urlItem[2]) : 0;
					const employeeId = urlItem[3] ? parseInt(urlItem[3]) : 0;
					const categoryId = urlItem[4] ? parseInt(urlItem[4]) : 0;
					const conversationId = urlItem[5] ? parseInt(urlItem[5]) : 0;
					const conversationsReplyId = urlItem[6] ? parseInt(urlItem[6]) : 0;
					//handle conversation redirection
					if (conversationId > 0 || conversationsReplyId > 0) {
						setLocalStorageItem(
							'conversationHighlight',
							JSON.stringify({
								conversationId: conversationId,
								employeeId: employeeId,
								conversationsReplyId: conversationsReplyId,
							})
						);
					}
					setTimeout(() => {
						const payload = {
							tabId, // 1 for my goal, 2 for team goal
							cycleId,
							employeeId,
							categoryId,
							conversationId,
							conversationsReplyId,
						};
						dispatch(setPerformanceGoalForRedirection(payload));
						dispatch(currentDashboardTabForSO(false));
						dispatch(currentDashboardTab(tabId === 1 ? 6 : 7));
						dispatch(updateRoute(HOME));
					}, 3000);
					history.push(HOME);
				} else if (
					redirectUrl?.url.split('/')[0] === 'perform' &&
					empId &&
					parseInt(empId) === userDetail?.employeeId
				) {
					const urlItem = redirectUrl?.url.split('/');
					const performanceCycleId = urlItem[1] ? parseInt(urlItem[1]) : 0;
					const threeSixtyStatus = urlItem[2] ? parseInt(urlItem[2]) : 0;
					const nomineeForEmpId = urlItem[3] ? parseInt(urlItem[3]) : 0;
					const payload = {
						cycleId: performanceCycleId,
						employeeId: empId,
						threeSixtyStatus,
						nomineeForEmpId,
					};
					setTimeout(() => {
						threeSixtyStatus === 3 && dispatch(setNomineeDetailsForRedirection(payload));
						dispatch(currentDashboardTabForSO(false));
						dispatch(currentDashboardTab(threeSixtyStatus === 3 ? 2 : 0));
						dispatch(updateRoute(PERFORM));
					}, 3000);
					history.push(PERFORM);
				} else if (redirectUrl.url.includes('recognize') && empId && parseInt(empId) === userDetail?.employeeId) {
					const itemArray = redirectUrl.url.split('/');
					let recognitionId = 0;
					let commentDetailsId = 0;
					if (itemArray.length > 0) {
						recognitionId = itemArray[1] ? parseInt(itemArray[1]) : 0;
						setLocalStorageItem(
							'recognitionHighlight',
							JSON.stringify({
								recognitionId: recognitionId,
							})
						);

						if (itemArray.length > 1) {
							commentDetailsId = itemArray[2] ? parseInt(itemArray[2]) : 0;
							setLocalStorageItem(
								'recognitionCommentHighlight',
								JSON.stringify({
									recognitionId: recognitionId,
									commentDetailsId: commentDetailsId,
								})
							);
						}
					}

					history.push(RECOGNIZE);
				} else {
					//== if redirect url is set and redirection
					//== is not for login user
					if (empId && parseInt(empId) !== userDetail.employeeId) {
						removeLocalStorageItem('redirectUrl');
						removeLocalStorageItem('empId');
					}

					setLocalStorageItem('LoginAs', 'user');

					//== if Contributors is set in the redirect url
					if (redirectUrl.url.includes('Contributors')) {
						const itemArray = redirectUrl.url.split('/');
						const goalId = itemArray[1] ? itemArray[1] : 0;
						setLocalStorageItem('highlightGoal', goalId);
						removeLocalStorageItem('redirectUrl');
					}
					// props.history.push(HOME);
					props.history.push(DASHBOARD);
					removeLocalStorageItem('impersonateUserDetail');
				}
			} else {
				setLocalStorageItem('LoginAs', 'user');
				props.history.push(redirectUrl.url);
			}
			removeLocalStorageItem('empId');
			removeLocalStorageItem('isStorageClear');
		} else {
			setLocalStorageItem('LoginAs', 'user');
			props.history.push(DASHBOARD);
			removeLocalStorageItem('isStorageClear');
			//removeLocalStorageItem('impersonateUserDetail');
		}

		props.updateStatus();
	};

	const logoutUser = () => {
		const isSSO = getLocalStorageItem('isSSO');
		const idpId = getLocalStorageItem('idpId');
		if (isSSO === 'false') {
			window.location.href = window.location.origin + LOGOUT_INITIATOR_URL;
		} else if (idpId === '3') {
			//Google Login
			clearLocalStorage();
			sessionStorage.clear();
			window.open('https://accounts.google.com/logout', '', 'width=100,height=100');
			window.location.href = window.location.origin + LOGOUT_INITIATOR_URL;
		} else {
			auth.removeUser();
			auth.signoutRedirect();
		}
		clearLocalStorage();
		sessionStorage.clear();
	};

	if (auth.isAuthenticated && !getLocalStorageItem('isAuthenticated')) {
		setLocalStorageItem('authName', auth.user?.profile.name);
		const emailId = auth.user?.profile?.email || auth.user?.profile?.unique_name || auth.user?.profile?.upn;
		setLocalStorageItem('authEmailId', emailId);
		authenticatedTenantAPI(emailId);
	}
	if (isInActiveDomain) {
		return (
			<section className='sign-in-area'>
				<h5 className='card-title'>
					Domain inactive. Contact Administrator for assistance in unlocking and reactivating the domain access.
				</h5>
			</section>
		);
	}

	if (auth.isLoading || getLastParamFromCurrentUrl() === 'secretlogin') {
		return (
			<section className='sign-in-area'>
				<h5 className='card-title'>Loading...</h5>
			</section>
		);
	}

	if ((paramState[1] || paramsCode[1]) && getLocalStorageItem('authName')) {
		return (
			<section className='sign-in-area'>
				<h5 className='card-title'>Welcome... {getLocalStorageItem('authName')}</h5>
				<h6>{loadingMessage}</h6>
			</section>
		);
	}
}
