import * as actionTypes from './actionTypes';
import {
	getRecommendationFilterAPI,
	aiOkrListingApi,
	generateMoreOkrApi,
	aiAddObjectiveApi,
	aiAddKeyResultApi,
	aiKrListingApi,
	generateMoreKrApi,
	
} from '../services/OkrAi';

export const getAiFilter = (payload) => {
	return () => {
		return getRecommendationFilterAPI(payload);
	};
};

export const aiOkrData = (payload) => {
	return () => {
		return aiOkrListingApi(payload);
	};
};

export const generateMoreOkrData = (payload) => {
	return () => {
		return generateMoreOkrApi(payload);
	};
};

export const generateMoreKrData = (payload) => {
	return () => {
		return generateMoreKrApi(payload);
	};
};
export const aiAddObjective = (payload) => {
	return () => {
		return aiAddObjectiveApi(payload);
	};
};

export const aiAddKeyResult = (payload) => {
	return () => {
		return aiAddKeyResultApi(payload);
	};
};

export const aiListKeyResult = (payload) => {
	return () => {
		return aiKrListingApi(payload);
	};
};


export const updateAiKr = (payload) => ({
	type: actionTypes.UPDATE_AI_KR,
	payload,
});

export const removeAiKr = (payload) => ({
	type: actionTypes.REMOVE_AI_KR,
	payload,
});

export const updateAiEditKr = (payload) => ({
	type: actionTypes.UPDATE_AI_EDIT_KR,
	payload,
});

export const removeAiEditKr = (payload) => ({
	type: actionTypes.REMOVE_AI_EDIT_KR,
	payload,
});