import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
	Typography,
	FormControl,
	Input,
	InputAdornment,
	IconButton,
	FormControlLabel,
	Checkbox,
	Button,
	FormHelperText,
} from '@material-ui/core';
import LinearLoader from '../../Common/Loader';
import {
	clearLocalStorage,
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../../../services/StorageService';
import Logo from '../../../images/okr-logo.svg';
import LoginImage from '../../../images/login-bg.svg';
import { VisibilityOff, Visibility } from '../../../config/svg/ProfileSvg';
import { ProfileIcon } from '../../../config/svg/CommonSvg';
import { Link } from 'react-router-dom';
import { getQuarterStartEnd, getEncryptString } from '../../../config/utils';
import '../../../styles/pages/login/login.scss';
import { ClientLogo } from '../../Common/ClientLogo';
import { useSnackbar } from 'notistack';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { SIGN_IN_DB, TENANT_DATA, ORG_CURRENT_CYCLE } from '../../../config/api-url';
import { SECRET_LOGIN } from '../../../config/app-url';
import { URL_REGEX } from '../../../config/constant';

export default function CustomDomain(props) {
	const history = useHistory();

	let { currentYear } = getQuarterStartEnd();
	const { t } = useTranslation();
	const host = window.location.origin;
	const { enqueueSnackbar } = useSnackbar();
	const [isFormSubmit, setIsFormSubmit] = useState(false);
	const tokenId = JSON.parse(getLocalStorageItem('tokenId'));
	const userDetailsString = getLocalStorageItem('userDetail');
	const [loader, setLoader] = useState(false);
	if (!tokenId && !userDetailsString) {
		window.location.href = host + SECRET_LOGIN;
	}

	const [formData, setFormData] = useState({
		url: '',
		password: '',
		username: '',
		showPassword: false,
		formValid: false,
		isUrlValid: false,
		messageList: {},
	});

	const handleChange = (prop) => (event) => {
		const value = event.target.value;
		if (prop === 'url') {
			let urlValid = value.match(URL_REGEX) ? true : false;
			setFormData({
				...formData,
				url: value.trim(),
				isUrlValid: urlValid,
				formValid: value.trim() && urlValid,
			});
		} else {
			setFormData({ ...formData, [prop]: event.target.value });
		}
	};

	const handleClickShowPassword = () => {
		setFormData({ ...formData, showPassword: !formData.showPassword });
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	function navigateToResetPass() {
		window.location.href = window.location.href = host + '/reset-password';
	}

	async function logincall(event) {
		setLoader(true);
		if (formData.username && formData.password) {
			const subDomain = getLocalStorageItem('subDomain');
			props
				.tenantAction({ subDomain: subDomain, emailId: formData.username })
				.then((resp) => {
					//avoid multiple calls
					if (resp.data.status === 200) {
						setLocalStorageItem(
							'TenantData',
							resp !== null && resp.data !== null && resp.data.entity !== null ? JSON.stringify(resp.data.entity) : ''
						);
						try {
							// Custom Login API Calling...
							props
								.signInActionDB({ userEmailId: resp.data.entity.userEmail, userPassword: formData.password })
								.then((result) => {
									if (result.data.status === 200) {
										setLocalStorageItem('CustomLogin-Connect', JSON.stringify(result));
										setLocalStorageItem('userDetail', JSON.stringify(result.data.entity));

										props
											.orgCurrentCycleAction()
											.then((resultCycle) => {
												if (resultCycle.data.status === 200) {
													setLocalStorageItem('currentCycle', JSON.stringify(resultCycle.data.entity));
													setLoader(false);
													if (result.data.entity != null) {
														setLocalStorageItem('isAuthenticated', true);
														setLocalStorageItem('signInStatus', 200);
														setLocalStorageItem('isAbleToDesign', false);

														window.location.href = window.location.href = host + '/dashboard';
													}
												}
											})
											.catch((e) => {
												// console.log(e);
											});
									} else if (result.data.status === 204) {
										setLoader(false);
										enqueueSnackbar(result.data.messageList.message, {
											variant: 'error',
											autoHideDuration: 5000,
										});
									}
								})
								.catch((e) => {
									// console.log(e);
								});
						} catch (err) {
							setLoader(false);
							enqueueSnackbar(t('InternalServerErrorMsg'), {
								variant: 'error',
								autoHideDuration: 5000,
							});
						}
					}
				})
				.catch((e) => {
					// console.log(e);
				});
		} else {
			setLoader(false);
		}
	}

	const submitForm = (e) => {
		e.preventDefault();
		setIsFormSubmit(true);
		//if (formData.formValid) {
		// const data = {
		// 	url: formData.url,
		// };
	};

	return (
		<Fragment>
			<section className='sign-in-area'>
				{loader && <LinearLoader />}
				<div className='login-screen'>
					<div className='inner'>
						<div className='img-inner-wrapper'>
							<ClientLogo className='client-logo' />
							<AnimatedIcon className='' width='348' type='image/svg+xml' alt='Login' imagePath={LoginImage} />
						</div>
					</div>

					<div className='inner login-screen'>
						<div className='login-inner-wrapper custom-domain-content custom-login'>
							<img src={Logo} className='logo' alt='' />
							{/* <Typography variant='h2' className='welcomeText'>Welcome to <span>Unlock:OKR</span></Typography> */}
							<Typography className='welcomeSubText'>Please login to your account.</Typography>
							<form className='login-inner-form domain-form ' onSubmit={submitForm}>
								<div className='custom-login-form'>
									<FormControl variant='outlined' style={{ width: '100%' }} error={isFormSubmit && !formData.username}>
										<Input
											id='input-with-icon-adornment'
											placeholder='User Name'
											value={formData.username}
											onChange={handleChange('username')}
											endAdornment={
												<InputAdornment position='end'>
													<ProfileIcon />
												</InputAdornment>
											}
										/>
										{isFormSubmit && !formData.username && <FormHelperText>{t('enterUsername')}</FormHelperText>}
									</FormControl>
									<FormControl variant='outlined' style={{ width: '100%' }} error={isFormSubmit && !formData.password}>
										<Input
											id='standard-adornment-password'
											placeholder='Password'
											type={formData.showPassword ? 'text' : 'password'}
											value={formData.password}
											onChange={handleChange('password')}
											endAdornment={
												<InputAdornment position='end'>
													<IconButton
														disableRipple='true'
														aria-label='toggle password visibility'
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
													>
														{formData.showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											}
										/>
										{isFormSubmit && !formData.password && <FormHelperText>{t('enterPassword')}</FormHelperText>}
									</FormControl>
									{/* <FormControlLabel
										value='remember_me'
										control={<Checkbox color='primary' />}
										label='Remember me'
										labelPlacement='end'
										className='rememberMe_box'
									/> */}

									<Button type='submit' className='login-btn btn-primary' color='primary' onClick={logincall}>
										Log In
									</Button>
									<div className='forgotRow'>
										<Link className='forgotLink' href='#' onClick={navigateToResetPass} underline='none'>
											Forgot Password?
										</Link>
									</div>
								</div>
							</form>

							<div className='footer-content'>
								<Typography variant='body2'>
									&copy; {currentYear} {t('infoproRights')}
									{/* {' | '}
									<Link target='_blank' to='/privacy-policy'>
										{t('privacyPolicy')}
									</Link> */}
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Fragment>
	);
}
