import React, { Fragment } from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { AddIconSvg, DocumentIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { DeleteIcon } from '../../../../config/svg/CommonSvg';

export const AssessmentQuesList: React.FC<any> = (props: any) => {
	const { questionType, handleAddNewQuestion, genericQuestionList, handleQuestionNameChange, handleQuestionDelete } =
		props;
	const { t } = useTranslation();

	const addNewQuestion = (e: any) => {
		handleAddNewQuestion(questionType);
	};

	return (
		<>
			{genericQuestionList && genericQuestionList.length > 0 ? (
				genericQuestionList.map((item: any, index: number) => (
					<Box className='ques-row'>
						<TextField
							id='question-name'
							fullWidth
							className='helperText-bottom'
							label={
								<Fragment>
									<DocumentIcon />
									{`${t('questionLabel')} ${index + 1}`}
									{genericQuestionList.length > 1 ? (
										<OKRButton
											handleClick={(e: any) => handleQuestionDelete(e, item, questionType, index)}
											className={'delete-icon'}
											icon={<DeleteIcon />}
											id='delete-icon'
										/>
									) : (
										<></>
									)}
								</Fragment>
							}
							placeholder={t('enterQuestion')}
							value={item?.questionsName || ''}
							onChange={(e: any) => handleQuestionNameChange(e, item, questionType, index)}
							name={`${questionType}_${item?.listIndex || index}`}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{ 'aria-label': t('enterQuestion'), disableUnderline: true }}
							error={item?.error || false}
							helperText={
								(item?.error && (
									<>
										<ErrorIcon />
										{item?.helperText || ''}
									</>
								)) ||
								''
							}
						/>
					</Box>
				))
			) : (
				<></>
			)}
			<Box className='add-user-field'>
				<OKRButton
					handleClick={(e: any) => addNewQuestion(e)}
					className='add-btn2'
					id={'add-ques-btn'}
					icon={<AddIconSvg />}
					color='primary'
				/>
				<Typography variant='subtitle1'>
					<em>{t('addQuestion')}</em>
				</Typography>
			</Box>
		</>
	);
};
