import React, { useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, InputAdornment, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { AddIconSvg } from '../../config/svg/CommonSvgIcon';
import CkEditor from './CkEditor/CkEditor';
import { goalRemovePlugin } from './CkEditor/CkEditorEnums';

export const CommonEditorBox: React.FC<any> = (props) => {
	const { placeholderText, editorText, setEditorText, maxLength, handleEditorChange, disabled = false } = props;
	const { t } = useTranslation();
	const [showEditor, setShowEditor] = useState(false);
	const toggleEditor = () => {
		if (!disabled) {
			setShowEditor(!showEditor);
		}
	};
	const handleClickAway = () => {
		setShowEditor(false);
	};

	return (
		<Box className={`add-editor-box ${showEditor ? 'editor-visible' : ''}`}>
			{!showEditor &&
				(editorText ? (
					<Box className={'user-goal-description-saved'} onClick={toggleEditor}>
						<Box
							dangerouslySetInnerHTML={{
								__html: editorText || '',
							}}
						></Box>
					</Box>
				) : (
					<Box className={'user-goal-description-saved'} onClick={toggleEditor}>
						<Typography className='placeholder-text'>{placeholderText}</Typography>
					</Box>
				))}

			{showEditor && (
				<ClickAwayListener onClickAway={(e) => handleClickAway()}>
					<Box className='editior-pane'>
						<Box className='editor-control-section' id='editor-control-section'>
							<CkEditor
								{...props}
								enableTagging={false}
								focusOnLoad={true}
								placeholder={placeholderText}
								value={editorText}
								uploadType={'4'}
								uploadImage={false}
								showEmoticons={true}
								removedPlugins={goalRemovePlugin}
								removedPluginsList={[...goalRemovePlugin, 'insertTable']}
								handleEditorChange={handleEditorChange}
							/>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
