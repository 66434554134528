import React, { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import { Box, FormLabel, InputAdornment, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { BooleanIcon, CurrencyIcon, NoUnitsIcon, NumbersIcon, PercentageIcon } from '../../../config/svg/MyGoalSvg';
import { OKRButton } from '../../Common/OKRButton';
import { Enums } from '../../../config/enums';
import { formatDate, getDueDays } from '../../../config/utils';
import '../style.scss';
import { UserAvatarGroup } from '../../Common/UserAvatarGroup';
import { NumberFormatComponent } from '../../Common/NumberFormat';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { KrWeight } from '../../Common/KrWeight';
import { KrConfidence } from '../../Common/KrConfidence';

export const AddContributorDetail: React.FC<any> = (props) => {
	const {
		keyData,
		durationCycleDetail,
		selectedContributorDetails,
		okrMasterData,
		keyAttribute,
		getCurrencyIcon,
		objectiveDetail,
		t,
		type,
		setKRContributorDetailDashboard,
		krContributorDetailDashboard,
		defaultKRDashboardDateError,
		setAssignmentTypeId,
		// goalTypeId,
		setCallOut,
		setAnchorElCallouts,
		initialCallout,
		calloutsData,
		hideKrConfidence,
	} = props;
	const defaultKRDateError = { error: '', helperText: '', type: '' };
	const [krContributorDetail, setKRContributorDetail] = useState<any>(selectedContributorDetails);
	const [isWithParentObjective, setIsWithParentObjective] = useState<Boolean>(false);
	const [krAssignmentTypes, setKRAssignmentTypes] = useState<any>(selectedContributorDetails);
	const [contributorDetail, setContributorDetail] = useState<any>([]);
	const [krAttrDateError, setKRAttrDateError] = useState<any>(defaultKRDateError);

	useEffect(() => {
		const { startDate, dueDate, metricId, keyDescription, isGroupTarget, startValue, targetValue } = keyAttribute;
		const contributorDefaultData = selectedContributorDetails ? selectedContributorDetails : krContributorDetail;

		let myObj: any = {
			keyResult: selectedContributorDetails?.keyResult || keyData?.keyDescription,
			objectiveName: selectedContributorDetails?.objectiveName || objectiveDetail?.objectiveName || '',
			startDate: getDateDifference(keyData?.startDate || selectedContributorDetails?.startDate, 'startDate'),
			dueDate: getDateDifference(keyData?.dueDate || selectedContributorDetails?.endDate, 'dueDate'),
		};
		if (keyData) {
			myObj = {
				...myObj,
				keyResult: selectedContributorDetails?.keyResult || keyData?.keyDescription,
				objectiveName: selectedContributorDetails?.objectiveName || objectiveDetail?.objectiveName || '',
				startDate: getDateDifference(keyData?.startDate, 'startDate'),
				dueDate: getDateDifference(keyData?.dueDate, 'dueDate'),
			};
		}
		if (keyDescription && type !== 2) {
			myObj = {
				...myObj,
				keyResult: keyDescription,
			};
		}
		if (startDate) {
			myObj = {
				...myObj,
				startDate: getDateDifference(startDate, 'startDate'),
			};
		}
		if (dueDate) {
			myObj = {
				...myObj,
				dueDate: getDateDifference(dueDate, 'dueDate'),
			};
		}
		const { metricMasters } = okrMasterData;
		const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 5 : keyData?.metricId;
		const selectedCurrencyId = keyAttribute?.currencyId ? keyAttribute.currencyId : keyData?.currencyId;
		let selectedCurrencyDetail: any = {};
		const selectedMetric =
			metricMasters && metricMasters.length
				? metricMasters.find((item: any) => item.metricId === selectedMetricId)
				: null;
		if (selectedMetric && selectedMetric?.name === 'Currency') {
			const { metricDataMaster } = selectedMetric;
			const selectedCurrency =
				metricDataMaster && metricDataMaster.length
					? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
					: null;
			selectedCurrencyDetail = selectedCurrency;
		}
		const updatedStartValue = isGroupTarget ? startValue : 0;
		const updatedTargetValue = isGroupTarget ? targetValue : 0;

		if (type === 3) {
			setKRContributorDetail({
				...contributorDefaultData,
				...myObj,
				//assignmentTypeId: Enums.ONE,
				assignmentTypeId: Enums.TWO,
				goalObjectiveId: Enums.ZERO,
				metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
				currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
				historyStartValue: contributorDefaultData.historyStartValue || contributorDefaultData.startValue,
				historyTargetValue: contributorDefaultData.historyTargetValue || contributorDefaultData.targetValue,
				startValue: updatedStartValue,
				targetValue: updatedTargetValue,
				isGroupTarget: isGroupTarget || false,
			});
			setIsWithParentObjective(false);
		} else if (type === 2 || type === 1) {
			if (contributorDefaultData.goalTypeId === 1) {
				setKRContributorDetail({
					...contributorDefaultData,
					...myObj,
					assignmentTypeId: Enums.TWO,
					metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
					currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
					historyStartValue: contributorDefaultData.historyStartValue || contributorDefaultData.startValue,
					historyTargetValue: contributorDefaultData.historyTargetValue || contributorDefaultData.targetValue,
					startValue: updatedStartValue,
					targetValue: updatedTargetValue,
					isGroupTarget: isGroupTarget || false,
				});
				//Do not set assignment in case of team objective
			} else {
				if (type === 1) {
					setKRContributorDetail({
						...contributorDefaultData,
						...myObj,
						assignmentTypeId: Enums.TWO,
						//assignmentTypeId: Enums.ONE,
						metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
						currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
						historyStartValue: contributorDefaultData.historyStartValue || contributorDefaultData.startValue,
						historyTargetValue: contributorDefaultData.historyTargetValue || contributorDefaultData.targetValue,
						startValue: updatedStartValue,
						targetValue: updatedTargetValue,
						isGroupTarget: isGroupTarget || false,
					});
					setAssignmentTypeId(Enums.ONE);
					setAssignmentTypeId(Enums.TWO);
				} else {
					setKRContributorDetail({
						...contributorDefaultData,
						...myObj,
						metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
						currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
						historyStartValue: contributorDefaultData.historyStartValue || contributorDefaultData.startValue,
						historyTargetValue: contributorDefaultData.historyTargetValue || contributorDefaultData.targetValue,
						startValue: updatedStartValue,
						targetValue: updatedTargetValue,
						isGroupTarget: isGroupTarget || false,
					});
				}
			}
		} else {
			setKRContributorDetail({
				...contributorDefaultData,
				...myObj,
				assignmentTypeId: Enums.TWO,
				//assignmentTypeId: Enums.ONE,
				metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
				currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
				historyStartValue: contributorDefaultData.historyStartValue || contributorDefaultData.startValue,
				historyTargetValue: contributorDefaultData.historyTargetValue || contributorDefaultData.targetValue,
				startValue: updatedStartValue,
				targetValue: updatedTargetValue,
				isGroupTarget: isGroupTarget || false,
			});
		}

		setKRContributorDetailDashboard({
			...contributorDefaultData,
			...myObj,
			metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
			currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
			historyStartValue: contributorDefaultData.historyStartValue || contributorDefaultData.startValue,
			historyTargetValue: contributorDefaultData.historyTargetValue || contributorDefaultData.targetValue,
			startValue: updatedStartValue,
			targetValue: updatedTargetValue,
			isGroupTarget: isGroupTarget || false,
		});

		if (okrMasterData) {
			const { assignmentTypes } = okrMasterData;
			const updatedData =
				assignmentTypes && assignmentTypes.length
					? assignmentTypes.map((item: any) => {
							item.activeButton = '';
							if (item.isDefault && selectedContributorDetails?.assignmentTypeId === Enums.ZERO) {
								item.activeButton = Enums.ACTIVE;
							}
							return item;
					  })
					: [];
			setKRAssignmentTypes([...updatedData]);
		}
		if (selectedContributorDetails.goalTypeId === 1) {
			setKRContributorDetail({
				...krContributorDetail,
				//assignmentTypeId: selectedContributorDetails.assignmentTypeId,
				assignmentTypeId: Enums.TWO,
				historyStartValue: krContributorDetail.historyStartValue || krContributorDetail.startValue,
				historyTargetValue: krContributorDetail.historyTargetValue || krContributorDetail.targetValue,
				startValue: krContributorDetail?.isGroupTarget ? krContributorDetail.startValue : 0,
				targetValue: krContributorDetail?.isGroupTarget ? krContributorDetail.targetValue : 0,
			});
			setIsWithParentObjective(true);
		}
		selectedContributorDetails && setContributorDetail([{ ...selectedContributorDetails }]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [keyAttribute, keyData, selectedContributorDetails, okrMasterData, type]);

	const isStartTargetDisable = () => {
		const { metricId, isGroupTarget } = keyAttribute;
		if (isGroupTarget) {
			return true;
		}
		return false;
	};

	const getDateDifference = (date: any, keyName: string) => {
		const datesDifferenceInDays = moment(new Date(krContributorDetail[keyName] || new Date())).isAfter(
			new Date(formatDate(date) || new Date()),
			'days'
		);
		const daysDiff = moment(new Date(formatDate(date) || new Date())).diff(
			moment(new Date(krContributorDetail[keyName] || new Date())),
			'days'
		);
		return keyName === 'dueDate'
			? daysDiff < 0
				? date
				: krContributorDetail[keyName]
			: !datesDifferenceInDays
			? date
			: krContributorDetail[keyName];
	};

	const { metricMasters } = okrMasterData;
	const selectedMetricId = keyAttribute?.metricId
		? keyAttribute.metricId
		: keyData?.metricId === 0
		? 5
		: keyData?.metricId;
	const selectedCurrencyId = keyAttribute?.currencyId ? keyAttribute.currencyId : keyData?.currencyId;
	let selectedCurrencyDetail: any = {};
	const selectedMetric =
		metricMasters && metricMasters.length
			? metricMasters.find((item: any) => item.metricId === selectedMetricId)
			: null;
	if (selectedMetric && selectedMetric?.name === 'Currency') {
		const { metricDataMaster } = selectedMetric;
		const selectedCurrency =
			metricDataMaster && metricDataMaster.length
				? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
				: null;
		selectedCurrencyDetail = selectedCurrency;
	}

	const krAssignType: any = useRef();
	useEffect(() => {
		props.updateCalloutPanel('');
		if (
			type === 2 &&
			krAssignType.current &&
			contributorDetail &&
			contributorDetail.length &&
			krContributorDetail?.goalTypeId !== 1
		) {
			setTimeout(() => {
				setAnchorElCallouts && setAnchorElCallouts(krAssignType.current);
				setCallOut && setCallOut(calloutsData[10]);
			}, 800);
		} else {
			setAnchorElCallouts && setAnchorElCallouts(null);
			setCallOut && setCallOut(initialCallout);
		}
	}, [contributorDetail && contributorDetail.length]);

	useEffect(() => {
		const startDateValue = krContributorDetail.startDate;
		const endDateValue = krContributorDetail.dueDate;
		updateDueDays(startDateValue, endDateValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [krContributorDetail.startDate, krContributorDetail.dueDate]);

	const updateDueDays = (startDt: any, endDt: any) => {
		const diffInDays = getDueDays(startDt, endDt, props.userSelectedCycle);
		setDueDaysDiff(diffInDays);
	};
	const dateChangeHandler = (range: any, start: any, end: any, selectionType: any) => {
		setKRContributorDetail({
			...krContributorDetail,
			startDate: formatDate(new Date(start)),
			dueDate: formatDate(new Date(end)),
		});
		setKRAttrDateError(defaultKRDateError);
		setKRContributorDetailDashboard({
			...krContributorDetailDashboard,
			startDate: formatDate(new Date(start)),
			dueDate: formatDate(new Date(end)),
		});
	};
	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [dueDaysDiff, setDueDaysDiff] = useState<any>(0);

	const handelStartValueChange = (event: any) => {
		let { value } = event?.target;
		if (value === '0') {
			value = 0;
		}
		checkNumber(value, 'startValue');
	};

	const handelTargetValueChange = (event: any) => {
		let { value } = event?.target;
		if (value === '0') {
			value = 0;
		}
		checkNumber(value, 'targetValue');
	};

	const checkNumber = (value: any, keyName: String) => {
		const regexp = /^[0-9\b]+$/;
		const regexpWithDecimal = /^[0-9\\.b]+$/;
		if (selectedMetric.name === 'Percentage') {
			// this is for decimal
			if (value === '' || regexpWithDecimal.test(value)) {
				updateKRContributorDetail(keyName, value);
			}
		} else {
			// this is for number only
			if (value === '' || regexp.test(value)) {
				updateKRContributorDetail(keyName, value);
			}
		}
	};

	const updateKRContributorDetail = useCallback((key: any, data: any) => {
		setKRContributorDetail((attr: any) => {
			return { ...attr, [key]: data || 0 };
		});
		setKRContributorDetailDashboard((attr: any) => {
			return { ...attr, [key]: data || 0 };
		});
	}, []);

	const getBecomeAContributor = () => {
		return (
			<Box>
				<form className='drawer-form-fields' noValidate autoComplete='off'>
					{type === 2 && contributorDetail && contributorDetail.length && contributorDetail[0]?.firstName && (
						<Box className='drawer-field-bottom'>
							<Box className='user-info'>
								<UserAvatarGroup
									contributorDetails={contributorDetail}
									avatarClassName='contributor-people-img'
									isButtonVisible={false}
									isButtonDisable={true}
									avatarGroupClassName='contributor-user-avatar'
									max={1}
								/>
								{contributorDetail && contributorDetail.length ? (
									<Box className='user-info-details'>
										<Typography variant='h4'>{`${contributorDetail[0]?.firstName} ${contributorDetail[0]?.lastName}`}</Typography>
										<Typography variant='subtitle2'>{contributorDetail[0]?.designation}</Typography>
									</Box>
								) : (
									<> </>
								)}
							</Box>
						</Box>
					)}
					{1 && (
						<Box className='drawer-input-field helperText-bottom' id={'obj-name'}>
							<TextField
								value={krContributorDetail?.objectiveName || ''}
								id={'obj-name-field'}
								onChange={(event: any) => {
									const { value } = event.target;
									setKRContributorDetail({ ...krContributorDetail, objectiveName: value });
									setKRContributorDetailDashboard({
										...krContributorDetailDashboard,
										objectiveName: value,
									});
									setKRAttrDateError(defaultKRDateError);
								}}
								multiline
								error={
									(defaultKRDashboardDateError.type === 'objectiveName' && defaultKRDashboardDateError?.error) || ''
								}
								helperText={
									(defaultKRDashboardDateError.type === 'objectiveName' && defaultKRDashboardDateError?.helperText && (
										<>
											<ErrorIcon />
											{defaultKRDashboardDateError?.helperText}
										</>
									)) ||
									''
								}
								rows={2}
								label={t('objective')}
								InputProps={{ 'aria-label': 'Objective', disableUnderline: true }}
								fullWidth
							/>
						</Box>
					)}
					<Box className='drawer-input-field helperText-bottom' id={'kr-name'}>
						<TextField
							value={krContributorDetail.keyResult}
							id={'key-result-field'}
							onChange={(event: any) => {
								const { value } = event.target;
								setKRContributorDetail({ ...krContributorDetail, keyResult: value });
								setKRAttrDateError(defaultKRDateError);
								setKRContributorDetailDashboard({
									...krContributorDetailDashboard,
									keyResult: value,
								});
							}}
							multiline
							error={(defaultKRDashboardDateError.type === 'keyResult' && defaultKRDashboardDateError?.error) || ''}
							helperText={
								(defaultKRDashboardDateError.type === 'keyResult' && defaultKRDashboardDateError?.helperText && (
									<>
										<ErrorIcon />
										{defaultKRDashboardDateError?.helperText}
									</>
								)) ||
								''
							}
							rows={2}
							label={t('keyResult')}
							InputProps={{ 'aria-label': 'key result', disableUnderline: true }}
							fullWidth
						/>
					</Box>
					<Box className='drawer-input-field helperText-bottom'>
						<KrWeight keyAttribute={keyAttribute} {...props} viewOnly={true} />
					</Box>
					<Box className='drawer-input-field drawer-date-field helperText-bottom'>
						<FormLabel component='legend'>{t('setATimeframe')}</FormLabel>
						<ReactDateRangePicker
							type={'AddContributor'}
							currentCycleEndDate={new Date(keyAttribute?.dueDate || keyData?.dueDate || new Date())}
							durationCycleDetail={durationCycleDetail}
							startDateValue={krContributorDetail.startDate}
							endDateValue={krContributorDetail.dueDate}
							minimumDate={new Date(keyAttribute?.startDate || keyData?.startDate || new Date())}
							maximumDate={new Date(keyAttribute?.dueDate || keyData?.dueDate || new Date())}
							isDateRangeTextFieldVisible={true}
							handleDateSelection={(
								range: any,
								start: any,
								end: any,
								selectionType: String,
								isCycleChanged: Boolean
							) => {
								if (selectionType === 'range') {
									const startDt = moment(start);
									const endDt = moment(end);
									const daysDiff = getDueDays(startDt, endDt, props.userSelectedCycle);
									setDueDaysDiff(daysDiff);
								}
								setSelectedDateRange(range);
								dateChangeHandler(range, start, end, selectionType);
							}}
							bottomChildComponent={<></>}
							allCycleDetails={[]}
							dueDaysDiff={dueDaysDiff}
							setDueDaysDiff={setDueDaysDiff}
							isCurrentCycle={props.userSelectedCycle.organisationCycleId}
							isCycleVisible={false}
							isEndOfMonthVisible={false}
							numberOfCalendars={Enums.TWO}
							selectedDateRange={selectedDateRange}
							setSelectedDateRange={setSelectedDateRange}
							showBottomText={true}
							selectedCycleDetails={props.userSelectedCycle}
						/>
					</Box>
					{!hideKrConfidence && (
						<Box className='drawer-input-field drawer-date-field helperText-bottom'>
							<KrConfidence
								{...props}
								confidenceLabel={true}
								handleConfidenceClick={(confidenceValue: number) => {
									setKRContributorDetail({
										...krContributorDetail,
										confidenceId: confidenceValue,
									});

									setKRContributorDetailDashboard({
										...krContributorDetailDashboard,
										confidenceId: confidenceValue,
									});
								}}
								confidenceValue={krContributorDetail.confidenceId}
								showWeekelyTrend={false}
							/>
						</Box>
					)}
					<Box className='drawer-input-field kr-measure-unit become-measure-unit'>
						<FormLabel component='legend'>
							{t('manageProgress')}
							<Typography className='short-des-text'>
								{krContributorDetail?.isGroupTarget ? t('becomeContributorGroupTargetMessage') : null}
							</Typography>
							{/* {!krContributorDetail?.isGroupTarget ? (
								<Typography className='short-des-text'>
									({t('ownerStartLabel')}: {krContributorDetail.historyStartValue}, {t('ownerTargetLabel')}:{' '}
									{krContributorDetail.historyTargetValue})
								</Typography>
							) : (
								<></>
							)} */}
						</FormLabel>
						<Box display='flex'>
							<OKRButton
								className={`measure-btn active`}
								id={`measure-btn-${selectedMetric && selectedMetric?.name}`}
								icon={
									selectedMetric && selectedMetric?.name === 'Percentage' ? (
										<PercentageIcon />
									) : selectedMetric && selectedMetric?.name === 'Numbers' ? (
										<NumbersIcon />
									) : selectedMetric && selectedMetric?.name === 'Currency' ? (
										<CurrencyIcon />
									) : selectedMetric && selectedMetric?.name === 'Boolean' ? (
										<BooleanIcon />
									) : selectedMetric && selectedMetric?.name === 'NoUnits' ? (
										<NoUnitsIcon />
									) : (
										<></>
									)
								}
								text={selectedMetric?.description}
								disabled={true}
							/>
							{selectedMetric?.name === 'NoUnits' || selectedMetric?.name === 'Boolean' ? (
								<></>
							) : (
								<Box className='kr-metrics-fileds kr-parent-target' id={'error-occur'}>
									<Box className='kr-metrics-fileds-inner'>
										<TextField
											disabled={isStartTargetDisable()}
											value={krContributorDetail.startValue || ''}
											className='current-value helperText-bottom target-txt-column'
											id='kr-contributor-start-value'
											label={t('start')}
											placeholder={keyData.currencyId === 1 ? '0' : '0'}
											fullWidth
											error={
												(defaultKRDashboardDateError.type === 'startValue' && defaultKRDashboardDateError?.error) || ''
											}
											helperText={
												(defaultKRDashboardDateError.type === 'startValue' &&
													defaultKRDashboardDateError?.helperText && (
														<>
															<ErrorIcon />
															{defaultKRDashboardDateError?.helperText}
														</>
													)) ||
												''
											}
											onChange={(event: any) => {
												handelStartValueChange(event);
											}}
											InputLabelProps={{
												shrink: true,
											}}
											name={selectedMetric.name !== 'Percentage' ? 'currency' : ''}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
											InputProps={{
												...(selectedMetric.name !== 'Percentage' && {
													inputComponent: NumberFormatComponent,
												}),
												startAdornment: (
													<InputAdornment position='start'>
														{selectedMetric.name === 'Percentage' ? (
															<PercentageIcon />
														) : selectedMetric.name === 'Numbers' ? (
															<NumbersIcon />
														) : (
															getCurrencyIcon(selectedCurrencyDetail?.name ? selectedCurrencyDetail.name : 'Dollar')
														)}
													</InputAdornment>
												),
											}}
										/>
										<Box className='matrics-field-seprator' component='span'>
											{'/'}
										</Box>
										<TextField
											disabled={isStartTargetDisable()}
											value={krContributorDetail.targetValue || ''}
											className='target-value helperText-bottom target-txt-column'
											id='kr-contributor-target-value'
											label={t('target')}
											placeholder={keyData.currencyId === 1 ? '0' : '0'}
											fullWidth
											error={
												(defaultKRDashboardDateError.type === 'targetValue' && defaultKRDashboardDateError?.error) || ''
											}
											helperText={
												(defaultKRDashboardDateError.type === 'targetValue' &&
													defaultKRDashboardDateError?.helperText && (
														<>
															<ErrorIcon />
															{defaultKRDashboardDateError?.helperText}
														</>
													)) ||
												''
											}
											onChange={(event: any) => {
												setKRAttrDateError(defaultKRDateError);
												handelTargetValueChange(event);
											}}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
											name={selectedMetric.name !== 'Percentage' ? 'currency' : ''}
											InputProps={{
												...(selectedMetric.name !== 'Percentage' && {
													inputComponent: NumberFormatComponent,
												}),
												startAdornment: (
													<InputAdornment position='start'>
														{selectedMetric.name === 'Percentage' ? (
															<PercentageIcon />
														) : selectedMetric.name === 'Numbers' ? (
															<NumbersIcon />
														) : (
															getCurrencyIcon(selectedCurrencyDetail?.name ? selectedCurrencyDetail.name : 'Dollar')
														)}
													</InputAdornment>
												),
											}}
										/>
									</Box>
									<Box className='group-target-value'>
										<Typography className='short-des-text'>
											{t('ownerStartLabel')}: {krContributorDetail.historyStartValue}
										</Typography>
										<Typography className='short-des-text'>
											{t('ownerTargetLabel')}: {krContributorDetail.historyTargetValue}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				</form>
			</Box>
		);
	};
	return <Fragment>{type === 1 || type === 3 ? getBecomeAContributor() : <>{getBecomeAContributor()}</>}</Fragment>;
};
