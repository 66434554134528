import React, { Fragment, useEffect, useRef, useState } from 'react';
import {
	Box,
	Collapse,
	FormControlLabel,
	FormLabel,
	Link,
	List,
	ListItem,
	ListItemText,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../../../../config/svg/GlobalSvg';
import '../../../../../../styles/pages/admin/settings/default-okr/createDefaultOkr.scss';
import {
	AddIconSvg,
	DeleteIcon,
	RightArrowIcon,
	TickIcon,
	WeightageIcon,
} from '../../../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../../../Common/OKRButton';
import { CancelIcon } from '../../../../../../config/svg/Action';
import { CreateGoalSettings } from './CreateGoalSettings';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AlertInfoIcon, DragIndicator } from '../../../../../../config/svg/CommonSvg';
import { ErrorIcon } from '../../../../../../config/svg/formElementIcons';
import { CreateGoalSettingsWrapper } from './CreateGoalSettingsWrapper';
import AlertDialog from '../../../../../Common/Dialog';
import { Enums } from '../../../../../../config/enums';
import { NumberFormatComponent } from '../../../../../Common/NumberFormat';
import { useDispatch } from 'react-redux';
import {
	getManageScaleListWIthReduxState,
	postPerformanceGoalForm,
	putPerformanceGoalForm,
	getPerformanceGoalResponseType,
	setHighlightGoalFormId,
} from '../../../../../../action/adminSettings';
import { useSnackbar } from 'notistack';
import CheckBoxAlertDialog from '../../../../../Common/CheckBoxDialog';
import { checkUnSavePopUpVisible } from '../../../../../../config/utils';

export const CreateGoalForm: React.FC<any> = (props: any) => {
	const { setGoalFormOpen, setTabSelected, refObject, goalFormOpen } = props;

	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);

	const defaultGoalFormData: any = {
		name: '',
		isCategoryWeightage: true,
		isQuestionWeightage: true,
		status: 0,
		performanceGoalFormCategoryRequest: [
			{
				name: '',
				description: '',
				weightage: 0,
				sequence: 1,
				performanceGoalFormDetailRequest: [
					{
						name: '',
						description: '',
						weightage: 0,
						questionType: 1,
						isMandatory: true,
						sequence: 1,
					},
				],
			},
		],
	};
	const [goalFormData, setGoalFormData] = useState<any>(defaultGoalFormData);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<any>({ open: false, message: '', details: null, type: '' });
	const [responseTypeList, setResponseTypeList] = useState<any[]>([]);

	useEffect(() => {
		//if (!(goalFormOpen && goalFormOpen.data && Object.keys(goalFormOpen.data).length > 0)) {
		//  console.log('KKR');
		getResponseTypeList();
		scrollTo('top-section');
		//}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('click', handleCheckUnSaveData, true);
		return () => {
			document.removeEventListener('click', handleCheckUnSaveData, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited]);
	const ref = useRef<any>(null);
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '', isBack: false });
	const handleCheckUnSaveData = (event: any) => {
		const id = document.getElementById('goal-form-section');
		const class1 = document.getElementsByClassName('ck-body-wrapper');
		if (
			ref.current &&
			!ref.current?.contains(event.target) &&
			checkUnSavePopUpVisible(event) &&
			isFormEdited &&
			!id?.contains(event.target) &&
			!(class1.length > 0 && class1[0].contains(event.target))
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({
					open: true,
					type: 'unSaveChange',
					message: t('unSavedItemAlert'),
					details: null,
					event: event,
					isBack: false,
				});
			}
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'unSaveChange') {
				await setIsFormEdited(false);
				if (modalProps.isBack) {
					setGoalFormOpen({ open: false, data: {} });
					setTabSelected(1);
				} else {
					const { target } = modalProps?.event || {};
					if (target) {
						const clonedNativeEvent = document.createEvent('HTMLEvents');
						clonedNativeEvent.initEvent('click', true, false);
						target.dispatchEvent(clonedNativeEvent);
					}
				}
				setModalProps({ open: false, type: '', message: '', details: '' });
			}
		} else {
			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};

	const scrollTo = (scrollId = '') => {
		if (scrollId) {
			let element = document.getElementById(scrollId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 100 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				//if (scrollId === 'top-section') {
				window.scroll({ top: topPos, behavior: 'smooth' });
				//}
			}
		}
	};
	useEffect(() => {
		if (goalFormOpen && goalFormOpen.data && Object.keys(goalFormOpen.data).length > 0) {
			let goalFormDataCopy: any = {
				...goalFormData,
				performanceGoalFormCategoryRequest: [],
			};
			if (goalFormOpen.type === 'Clone') {
				goalFormDataCopy = {
					...goalFormDataCopy,
					name: goalFormOpen.data.name,
					isCategoryWeightage: goalFormOpen.data.isCategoryWeightage,
					isQuestionWeightage: goalFormOpen.data.isQuestionWeightage,
					performanceGoalFormCategoryRequest: [],
				};
				goalFormOpen.data.performanceGoalFormCategoryRequest.map((catItem: any, catIndex: number) => {
					let goalsList: any[] = [];
					catItem.performanceGoalFormDetailRequest.map((goalItem: any) => {
						goalsList.push({
							name: goalItem.name,
							description: goalItem.description,
							weightage: goalItem.weightage,
							questionType: goalItem.questionType,
							isMandatory: goalItem.isMandatory,
							sequence: goalItem.sequence,
						});
					});
					goalFormDataCopy.performanceGoalFormCategoryRequest.push({
						name: catItem.name,
						description: catItem.description,
						weightage: catItem.weightage,
						sequence: catItem.sequence,
						performanceGoalFormDetailRequest: goalsList,
					});
				});
			} else {
				goalFormDataCopy = {
					...goalFormData,
					...goalFormOpen.data,
				};
				if (
					!goalFormDataCopy.performanceGoalFormCategoryRequest ||
					goalFormDataCopy.performanceGoalFormCategoryRequest.length === 0
				) {
					goalFormDataCopy.performanceGoalFormCategoryRequest = [
						...defaultGoalFormData.performanceGoalFormCategoryRequest,
					];
				}
			}
			setGoalFormData(goalFormDataCopy);
		} else {
			setGoalFormData({ ...defaultGoalFormData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalFormOpen]);

	const handleBackButtonOnClick = (event: any) => {
		if (isFormEdited) {
			setModalProps({
				open: true,
				type: 'unSaveChange',
				message: t('unSavedItemAlert'),
				details: null,
				event: event,
				isBack: true,
			});
		} else {
			setGoalFormOpen({ open: false, data: {} });
			setTabSelected(1);
		}
	};

	const handleAddNewCategory = (event: any, category: any, catIndex: number) => {
		try {
			const catObjectToInsert = {
				name: '',
				description: '',
				weightage: 0,
				sequence: 0,
				performanceGoalFormDetailRequest: [
					{
						name: '',
						weightage: 0,
						questionType: 1,
						isMandatory: true,
						sequence: 0,
					},
				],
			};
			const goalFormDataCopy = { ...goalFormData };
			const { performanceGoalFormCategoryRequest } = goalFormDataCopy || {};
			let performanceGoalFormCategoryRequestCopy: any[] = [];
			if (performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length) {
				performanceGoalFormCategoryRequestCopy = [...performanceGoalFormCategoryRequest];
				performanceGoalFormCategoryRequestCopy.splice(catIndex + 1, 0, catObjectToInsert);
				performanceGoalFormCategoryRequestCopy = performanceGoalFormCategoryRequestCopy?.map(
					(item: any, indexVal: number) => {
						item.sequence = indexVal + 1;
						return item;
					}
				);
			}
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...performanceGoalFormCategoryRequestCopy],
			});
			setIsFormEdited(true);
		} catch (error) {
			// setIsAssessmentFormEdited(true);
			console.error(error);
		}
	};

	const handleAddNewGoal = (event: any, category: any, catIndex: number) => {
		try {
			const updatedAssessmentFormDetails = { ...goalFormData };
			const { performanceGoalFormCategoryRequest } = updatedAssessmentFormDetails || {};
			let currentCatCopy: any = { ...performanceGoalFormCategoryRequest[catIndex] };
			currentCatCopy.performanceGoalFormDetailRequest?.push({
				name: '',
				weightage: 0,
				questionType: 1,
				isMandatory: true,
				sequence: currentCatCopy && currentCatCopy.sequence ? currentCatCopy?.sequence + 1 : 1,
			});
			let copyPerformanceGoalFormCategoryRequest = [...performanceGoalFormCategoryRequest];
			copyPerformanceGoalFormCategoryRequest[catIndex] = currentCatCopy;
			if (copyPerformanceGoalFormCategoryRequest && copyPerformanceGoalFormCategoryRequest?.length) {
				setGoalFormData({
					...goalFormData,
					performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
				});
			}
			setIsFormEdited(true);
		} catch (error) {
			// setIsAssessmentFormEdited(true);
			console.error(error);
		}
	};
	const getItemStyle = (isDragging: any, draggableStyle: any) => ({
		// styles we need to apply on draggable
		...draggableStyle,
		...(isDragging && {
			background: 'rgb(235,235,235)',
		}),
	});
	const reorder = (list: any, startIndex: number, endIndex: number) => {
		try {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);
			return result;
		} catch (e) {
			console.error(e);
			return [];
		}
	};
	const onDragEndCategory = (result: any, sectionList: any[]) => {
		try {
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}

			const items = reorder(sectionList, result.source.index, result.destination.index);
			const data = items.map((ele: any, indexVal: number) => {
				ele.sequence = indexVal + 1;
				return ele;
			});

			const updatedAssessmentFormDetails = { ...goalFormData };
			const { performanceGoalFormCategoryRequest } = updatedAssessmentFormDetails || {};
			let copyPerformanceGoalFormCategoryRequest = data && data?.length ? data : performanceGoalFormCategoryRequest;
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
			});
			setIsFormEdited(true);
		} catch (e) {
			console.error(e);
		}
	};

	const handleEditCategoryName = (event: any, section: any, indexVal: number) => {
		event.preventDefault();
		event.stopPropagation();
		const updatedAssessmentFormDetails = { ...goalFormData };
		updatedAssessmentFormDetails.performanceGoalFormCategoryRequest[indexVal].isCategoryEditable = true;
		setGoalFormData({ ...updatedAssessmentFormDetails });
		setIsFormEdited(true);
	};

	const handleFormCategoryNameChange = (event: any, category: any) => {
		event.preventDefault();
		event.stopPropagation();
		const { value } = event?.target || {};
		updateFormCategory(category, 'name', value, 'category');
		// setOpen(true);
	};

	const updateFormCategory = (category: any, key: string, value: any, updateType: any) => {
		try {
			const { performanceGoalFormCategoryRequest } = goalFormData;
			let copyPerformanceGoalFormCategoryRequest =
				performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
					? [...performanceGoalFormCategoryRequest]
					: [];
			if (updateType === 'category') {
				copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest.map((rec: any) => {
					if (rec?.sequence === category?.sequence) {
						if (key === 'name') {
							rec.name = value;
							rec.isCategoryNameError = false;
							rec.categoryNameErrorMessage = '';
						}
						if (key === 'description') {
							rec.description = value;
							rec.isCategoryDescriptionError = false;
							rec.categoryNameErrorMessage = '';
						}

						if (key === 'weightage') {
							rec.weightage = value;
							rec.isWeightageError = false;
							rec.isWeightageErrorMessage = '';
						}
						rec.isExpand = true;
					}
					if (key === 'weightage') {
						rec.isWeightageError = false;
						rec.isWeightageErrorMessage = '';
					}
					return rec;
				});
			}
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
			});
			setIsFormEdited(true);
		} catch (error) {
			// setIsAssessmentFormEdited(true);
			console.error(error);
		}
	};

	const onDragEnd = (result: any, goalsList: any, categoryIndex: number) => {
		try {
			if (!result.destination) {
				return;
			} else if (result.source?.index === result.destination?.index) {
				return;
			}

			const items = reorder(goalsList, result.source.index, result.destination.index);
			const data = items.map((ele: any, indexVal: number) => {
				ele.sequence = indexVal + 1;
				return ele;
			});

			const updatedAssessmentFormDetails = { ...goalFormData };
			const { performanceGoalFormCategoryRequest } = updatedAssessmentFormDetails || {};
			let copyPerformanceGoalFormCategoryRequest =
				performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
					? [...performanceGoalFormCategoryRequest]
					: [];
			copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest?.map(
				(item: any, indexVal: number) => {
					if (indexVal === categoryIndex) {
						item.ratingFormDetailRequest = data && data?.length ? data : item.ratingFormDetailRequest;
					}

					return item;
				}
			);
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
			});
			setIsFormEdited(true);
		} catch (e) {
			console.error(e);
		}
	};

	const handleSectionClick = (e: any, catIndex: number, catId: any) => {
		e.preventDefault();
		e.stopPropagation();
		let element = document.getElementById(catId);
		let currentTop = 0;
		if (element) {
			currentTop = element.getBoundingClientRect().top;
		}
		const updatedGoalFormData = { ...goalFormData };
		const expandDetails = updatedGoalFormData.performanceGoalFormCategoryRequest[catIndex].isExpand;
		updatedGoalFormData.performanceGoalFormCategoryRequest[catIndex].isExpand =
			!updatedGoalFormData.performanceGoalFormCategoryRequest[catIndex].isExpand;
		setGoalFormData({ ...updatedGoalFormData });
		if (!expandDetails) {
			setTimeout(() => {
				if (element) {
					const newTop = element.getBoundingClientRect().top;
					const scrollDifference = newTop - currentTop;

					window.scrollBy(0, scrollDifference);
				}
			}, 400);
		}
		//setOpen(!open);
	};

	const handleDeleteGoal = (e: any, goal: any, goalIndex: number, category: any, catIndex: number) => {
		e.preventDefault();
		e.stopPropagation();
		setOpenDeleteDialog({
			open: true,
			message: t('deleteRatingScale'),
			details: { goal, goalIndex, category, catIndex },
			type: 'goal',
		});
	};
	const handleDeleteCategoryDetails = (category: any, catIndex: number) => {
		try {
			const updatedGoalFormData = { ...goalFormData };
			const { performanceGoalFormCategoryRequest } = updatedGoalFormData || {};
			let copyPerformanceGoalFormCategoryRequest =
				performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
					? [...performanceGoalFormCategoryRequest]
					: [];
			copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest
				.filter((itemX: any, indexVal: number) => indexVal !== catIndex)
				?.map((item: any, indexVal: number) => {
					item.sequence = indexVal + 1;
					return item;
				});
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
			});
			setIsFormEdited(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handleDeleteGoalDetails = (goal: any, goalIndex: number, category: any, catIndex: number) => {
		try {
			const updatedAssessmentFormDetails = { ...goalFormData };
			const { performanceGoalFormCategoryRequest } = updatedAssessmentFormDetails || {};
			let copyPerformanceGoalFormCategoryRequest =
				performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
					? [...performanceGoalFormCategoryRequest]
					: [];
			copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest?.map(
				(item: any, secIndex: number) => {
					if (secIndex === catIndex) {
						item.performanceGoalFormDetailRequest = item?.performanceGoalFormDetailRequest
							?.filter((itemX: any, indexVal: number) => indexVal !== goalIndex)
							?.map((item: any, indexVal: number) => {
								item.sequence = indexVal + 1;
								return item;
							});
					}
					return item;
				}
			);
			setGoalFormData({
				...goalFormData,
				performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
			});
			setIsFormEdited(true);
		} catch (e) {
			// setIsAssessmentFormEdited(true);
			console.error(e);
		}
	};
	const handleDialogDeleteClick = (e: any, type: any) => {
		e.preventDefault();
		if (type === 2) {
			if (openDeleteDialog?.type === 'category') {
				const { category, catIndex } = openDeleteDialog?.details || {};
				if (category) {
					setOpenDeleteDialog({ open: false, message: '', details: null, type: '' });
					handleDeleteCategoryDetails(category, catIndex);
				}
			} else if (openDeleteDialog?.type === 'goal') {
				const { goal, goalIndex, category, catIndex } = openDeleteDialog?.details || {};
				if (goal && category) {
					setOpenDeleteDialog({ open: false, message: '', details: null, type: '' });
					handleDeleteGoalDetails(goal, goalIndex, category, catIndex);
				}
			}
		} else {
			setOpenDeleteDialog({ open: false, message: '', details: null, type: '' });
		}
	};

	const getResponseTypeList = async () => {
		try {
			let response: any = await dispatch(getPerformanceGoalResponseType());
			if (response && response.data && response.data.status === 200) {
				setResponseTypeList(response?.data?.entityList ? response.data.entityList : []);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const validateForm = (isDraft = false) => {
		const updatedGoalFormData = { ...goalFormData };
		const { performanceGoalFormCategoryRequest } = updatedGoalFormData || {};
		let copyPerformanceGoalFormCategoryRequest =
			performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
				? [...performanceGoalFormCategoryRequest]
				: [];
		let totalSum = 0;
		let isError: boolean = false;
		let scrollToId = '';
		if (updatedGoalFormData.name.trim() === '') {
			updatedGoalFormData.isNameError = true;
			isError = true;
			updatedGoalFormData.nameErrorMessage = 'Please enter Goal Form name';
			scrollToId = 'goal-form-name';
		}
		if (!isDraft) {
			copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest?.map(
				(item: any, catIndex: number) => {
					totalSum += parseFloat(item.weightage);
					if (item.name.trim() === '') {
						isError = true;
						item.isCategoryNameError = true;
						item.isExpand = true;
						item.categoryNameErrorMessage = 'Please enter Category name';
						if (scrollToId === '') {
							scrollToId = 'form_section_item_index_' + catIndex;
						}
					}
					let goalSum = 0;
					item.performanceGoalFormDetailRequest = item?.performanceGoalFormDetailRequest?.map(
						(goalItem: any, goalIndex: number) => {
							goalSum += parseFloat(goalItem.weightage);
							if (goalItem.name.trim() === '') {
								isError = true;
								goalItem.isGoalQuestionNameError = true;
								goalItem.isExpand = true;
								goalItem.sectionQuestionNameErrorMessage = 'Please enter Goal name';
								if (scrollToId === '') {
									scrollToId = 'form_section_item_index_' + catIndex;
									//scrollToId = 'form_section_item_index_' + catIndex + '_question_index_' + goalIndex;
								}
							}
							return goalItem;
						}
					);
					if (updatedGoalFormData.isQuestionWeightage && goalSum !== 100) {
						isError = true;
						item.isExpand = true;
						item.performanceGoalFormDetailRequest = item?.performanceGoalFormDetailRequest?.map((goalItem: any) => {
							goalItem.isWeightageError = true;
							goalItem.isWeightageErrorMessage =
								'Sum of weightages of all Goals defined within a Category should be equal to 100';
							return goalItem;
						});
						if (scrollToId === '') {
							scrollToId = 'form_section_item_index_' + catIndex;
							//scrollToId = 'form_section_item_index_' + catIndex + '_question_index_0';
						}
					}
					return item;
				}
			);
			if (updatedGoalFormData.isCategoryWeightage && totalSum !== 100) {
				isError = true;
				copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest?.map((item: any) => {
					item.isWeightageError = true;
					item.isExpand = true;
					item.isWeightageErrorMessage = 'Sum of weightages of all Categories defined should be equal to 100';
					return item;
				});

				if (scrollToId === '') {
					scrollToId = 'form_section_item_index_0';
				}
			}
		}
		if (scrollToId !== '') {
			scrollTo(scrollToId);
		}
		setGoalFormData({
			...updatedGoalFormData,
			performanceGoalFormCategoryRequest: copyPerformanceGoalFormCategoryRequest,
		});
		return !isError;
	};

	const handleSave = (saveAsDraft = false) => {
		handleSaveForm(saveAsDraft);
	};
	const handleSaveForm = (saveAsDraft = false) => {
		let goalFormCopy = { ...goalFormData };
		goalFormCopy.status = saveAsDraft ? 1 : 2;
		if (validateForm(saveAsDraft)) {
			saveForm(goalFormCopy);
		}
	};
	const saveForm = async (goalData: any) => {
		setIsButtonDisabled(true);
		let response: any = null;
		if (goalData.status === 1 && goalFormOpen.type !== 'Edit') {
			goalData.performanceGoalFormCategoryRequest = goalData.performanceGoalFormCategoryRequest.filter(
				(item: any) => item.name !== null && item.name != ''
			);
		}
		if (goalData.performanceGoalFormId) {
			response = await dispatch(putPerformanceGoalForm(goalData));
		} else {
			response = await dispatch(postPerformanceGoalForm(goalData));
		}
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				setIsButtonDisabled(false);
				const { messageList, isSuccess, entity } = response.data;
				if (isSuccess) {
					dispatch(setHighlightGoalFormId(entity));
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages && messages.length > 0 ? messages : `Goal form created successfully`, {
						variant: 'success',
						autoHideDuration: 3000,
					});
					setGoalFormOpen({ open: false, data: {} });
					setTabSelected(1);
				} else {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while adding performance review', {
						variant: 'error',
						autoHideDuration: 3000,
					});
					setIsButtonDisabled(false);
				}
			} else {
				setIsButtonDisabled(false);
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || 'Error occurred while adding performance review', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		}
	};
	return (
		<>
			<div ref={ref}>
				<Box className='create-goal-form' id='goal-form-section'>
					<Box className='admin-tabpanel-inner'>
						<Box className='admin-tabpanel-head' id='top-section'>
							<Box className='back-to-main' onClick={handleBackButtonOnClick}>
								<Typography variant='body2'>
									<Link id='back-btn'>
										<BackArrow /> {t('back')}
									</Link>
								</Typography>
							</Box>
							<Typography variant='h2'>{t('goalFormLabel')}</Typography>
						</Box>
						{(goalFormData.formMappedWithGoalCycleStatusSubmitted ||
							goalFormData.formMappedWithGoalCycleStatusOngoing ||
							(!goalFormData.formMappedWithGoalCycleStatusOngoing &&
								!goalFormData.formMappedWithGoalCycleStatusSubmitted)) &&
						goalFormData.status !== 1 &&
						goalFormData.isFormMappedWithEmployee &&
						goalFormOpen.type === 'Edit' ? (
							<Box className='goal-form-ticker' id='scroll-section'>
								<AlertInfoIcon />
								<Box>
									{goalFormData.formMappedWithGoalCycleStatusSubmitted &&
									goalFormData.formMappedWithGoalCycleStatusOngoing ? (
										<Box className='goal-form-ticker-content'>
											<Typography>{t('goalFormTickerCase3Text1')}</Typography>
											<List>
												<ListItem>
													<ListItemText primary={t('goalFormTickerCase3Text2')} />
												</ListItem>
												<ListItem>
													<ListItemText primary={t('goalFormTickerCase3Text3')} />
												</ListItem>
											</List>
										</Box>
									) : goalFormData.formMappedWithGoalCycleStatusSubmitted ? (
										<Box className='goal-form-ticker-content'>
											<Typography>{t('goalFormTickerCase1Text1')}</Typography>
											<List>
												<ListItem>
													<ListItemText primary={t('goalFormTickerCase1Text2')} />
												</ListItem>
											</List>
										</Box>
									) : goalFormData.formMappedWithGoalCycleStatusOngoing ? (
										<Box className='goal-form-ticker-content'>
											<Typography>{t('goalFormTickerCase2Text1')}</Typography>
											<List>
												<ListItem>
													<ListItemText primary={t('goalFormTickerCase2Text2')} />
												</ListItem>
											</List>
										</Box>
									) : !goalFormData.formMappedWithGoalCycleStatusSubmitted &&
									  !goalFormData.formMappedWithGoalCycleStatusOngoing ? (
										<Box className='goal-form-ticker-content'>
											<Typography>{t('goalFormTickerCase4Text1')}</Typography>
											<List>
												<ListItem>
													<ListItemText primary={t('goalFormTickerCase4Text2')} />
												</ListItem>
												<ListItem>
													<ListItemText primary={t('goalFormTickerCase4Text3')} />
												</ListItem>
											</List>
										</Box>
									) : (
										<></>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
					</Box>
					<Box className='full-rectangular-card create-goal-form-card'>
						<Box className='add-goal-form-wrapper'>
							{/* <Box className='add-goal-form-field-edit'>
                            <Typography variant='h4'>Bench Sales</Typography>
                        </Box> */}
							<Box className='helperText-bottom add-goal-form-field' id='goal-form-name'>
								<TextField
									className='add-goal-field'
									placeholder={t('addGoalFormName')}
									fullWidth
									value={goalFormData.name}
									onChange={(e: any) => {
										setGoalFormData({
											...goalFormData,
											name: e?.target?.value,
											nameErrorMessage: false,
											isNameError: false,
										});
										setIsFormEdited(true);
									}}
									error={goalFormData?.isNameError || false}
									helperText={
										goalFormData?.isNameError && goalFormData?.nameErrorMessage ? (
											<>
												<ErrorIcon /> {goalFormData?.nameErrorMessage || ''}
											</>
										) : null
									}
								/>
								{/*<Box className='add-goal-form-actions'>
                                <OKRButton className='add-goal-cancel-btn' icon={<CancelIcon />} />
                                <OKRButton className='add-goal-save-btn' icon={<TickIcon />} />
                            </Box>*/}
							</Box>
						</Box>
						<Box className='weightage-toggle-wrapper'>
							<Box className='weightage-switch'>
								<FormControlLabel
									control={<Switch name='categoryWeightage' checked={goalFormData.isCategoryWeightage} />}
									label={t('categoryWeightage')}
									labelPlacement='end'
									className='toggle-label'
									checked={goalFormData.isCategoryWeightage}
									onChange={(e: any) => {
										if (!e.target.checked) {
											const updatedGoalFormData = { ...goalFormData };
											const { performanceGoalFormCategoryRequest } = updatedGoalFormData || {};
											let copyPerformanceGoalFormCategoryRequest =
												performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
													? [...performanceGoalFormCategoryRequest]
													: [];
											copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest?.map(
												(item: any) => {
													item.weightage = 0;
													return item;
												}
											);
											setGoalFormData({
												...goalFormData,
												isCategoryWeightage: e.target.checked,
												performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
											});
										} else {
											setGoalFormData({
												...goalFormData,
												isCategoryWeightage: e.target.checked,
											});
										}
										setIsFormEdited(true);
									}}
								/>
							</Box>
							<Box className='weightage-switch'>
								<FormControlLabel
									control={<Switch name='goalWeightage' checked={goalFormData.isQuestionWeightage} />}
									label={t('goalWeightage')}
									labelPlacement='end'
									className='toggle-label'
									checked={goalFormData.isQuestionWeightage}
									onChange={(e: any) => {
										if (!e.target.checked) {
											const updatedAssessmentFormDetails = { ...goalFormData };
											const { performanceGoalFormCategoryRequest } = updatedAssessmentFormDetails || {};
											let copyPerformanceGoalFormCategoryRequest =
												performanceGoalFormCategoryRequest && performanceGoalFormCategoryRequest?.length
													? [...performanceGoalFormCategoryRequest]
													: [];
											copyPerformanceGoalFormCategoryRequest = copyPerformanceGoalFormCategoryRequest?.map(
												(item: any, secIndex: number) => {
													item.performanceGoalFormDetailRequest = item?.performanceGoalFormDetailRequest?.map(
														(goalItem: any) => {
															goalItem.weightage = 0;
															return goalItem;
														}
													);
													return item;
												}
											);
											setGoalFormData({
												...goalFormData,
												performanceGoalFormCategoryRequest: [...copyPerformanceGoalFormCategoryRequest],
												isQuestionWeightage: e.target.checked,
											});
										} else {
											setGoalFormData({
												...goalFormData,
												isQuestionWeightage: e.target.checked,
											});
										}
										setIsFormEdited(true);
									}}
								/>
							</Box>
						</Box>
					</Box>
					{goalFormData &&
					goalFormData?.performanceGoalFormCategoryRequest &&
					goalFormData?.performanceGoalFormCategoryRequest?.length ? (
						<Fragment>
							{/*<DragDropContext
                            onDragEnd={(result: any) => onDragEndCategory(result, goalFormData?.performanceGoalFormCategoryRequest)}
                        >
                            <Droppable droppableId='list'>
                                {(provided: any) => (
                                    <div ref={provided.innerRef} className='' {...provided.droppableProps}>*/}
							{goalFormData.performanceGoalFormCategoryRequest.map((catItem: any, catIndex: number) => (
								<>
									{
										/*<Draggable
                                key={`draggable_${catItem.sequence}_index_${catIndex}_section_`}
                                draggableId={`draggable_${catItem.sequence}_questionIndex_`}
                                index={catIndex}
                                isDragDisabled={false}
                            >
                                {(provided: any, snapshot: any) => (*/
										<Fragment>
											<Box
												//ref={provided.innerRef}
												//{...provided.draggableProps}
												//{...provided.dragHandleProps}
												//style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
												className='assessment-section-list-row'
												id={`form_section_item_index_${catIndex}`}
											>
												{/*<Tooltip arrow title={t('dragDropLabel')}>
                                                <span className='drag-icon'>
                                                    <DragIndicator />
                                                </span>
                                </Tooltip>*/}
												<Box
													className={`full-rectangular-card create-cat-goal-list-row ${
														catItem.isExpand ? 'expand-row' : ''
													}`}
												>
													<Box className='create-category-head'>
														<Box
															className={`rotating-arrow`}
															onClick={(e: any) =>
																handleSectionClick(e, catIndex, `form_section_item_index_${catIndex}`)
															}
														>
															<RightArrowIcon />
														</Box>
														<Box className='create-category-title'>
															<Typography variant='subtitle2' className='section-count input-label-pad'>
																{`${t('categoryLabel')} ${catItem.sequence} of ${
																	goalFormData.performanceGoalFormCategoryRequest?.length
																}`}
															</Typography>
															{0 && catItem?.isEditable ? (
																<Box className='section-title-wrap'>
																	<Box
																		className='title-read-mode'
																		onClick={(e: any) => handleEditCategoryName(e, catItem, catIndex)}
																	>
																		<Typography>{catItem?.name || ''}</Typography>
																	</Box>
																	{/* <OKRButton icon={<DeleteIcon />} className={'editIcon'} title={t('delete')} /> */}
																</Box>
															) : (
																<></>
															)}
															{1 || catItem?.isEditable ? (
																<Box className='section-title-wrap'>
																	<Box className='title-edit-mode'>
																		<TextField
																			id='section-title'
																			className='helperText-bottom'
																			placeholder={t('addCategoryName')}
																			name={t('addCategoryName')}
																			InputLabelProps={{
																				shrink: true,
																			}}
																			InputProps={{
																				'aria-label': t('addCategoryName'),
																				disableUnderline: true,
																			}}
																			value={catItem?.name || ''}
																			error={catItem?.isCategoryNameError || false}
																			helperText={
																				catItem?.isCategoryNameError && catItem?.categoryNameErrorMessage ? (
																					<>
																						<ErrorIcon /> {catItem?.categoryNameErrorMessage || ''}
																					</>
																				) : null
																			}
																			onClick={(event: any) => {
																				event.preventDefault();
																				event.stopPropagation();
																			}}
																			onChange={(e: any) => handleFormCategoryNameChange(e, catItem)}
																		/>
																	</Box>
																</Box>
															) : (
																<></>
															)}
														</Box>
														{goalFormData.isCategoryWeightage && (
															<Box className='weightage-wrapper'>
																<Box
																	className={`weightage-field ${
																		catItem?.isWeightageError && catItem?.isWeightageErrorMessage
																			? 'custom-error-field'
																			: ''
																	}`}
																>
																	<TextField
																		fullWidth
																		className='helperText-bottom'
																		placeholder={t('enterWeightage')}
																		label={
																			<>
																				<WeightageIcon /> {t('weightageLabel')}
																			</>
																		}
																		name={t('weightageLabel')}
																		InputLabelProps={{
																			shrink: true,
																		}}
																		inputProps={{
																			maxLength: 5,
																			inputType: 'numberFieldProgress',
																			inputComponent: NumberFormatComponent,
																		}}
																		InputProps={{
																			'aria-label': t('weightageLabel'),
																			disableUnderline: true,
																			inputComponent: NumberFormatComponent,
																		}}
																		error={catItem?.isWeightageError || false}
																		helperText={
																			catItem?.isWeightageError && catItem?.isWeightageErrorMessage ? (
																				<>
																					<ErrorIcon /> {catItem?.isWeightageErrorMessage || ''}
																				</>
																			) : null
																		}
																		value={catItem.weightage}
																		onChange={(e: any) => {
																			const regexpNumber = /^[0-9\b]+$/;
																			let flValue = e?.target?.value;
																			//if (flValue === '' || (regexpNumber.test(flValue) && flValue <= 100)) {
																			updateFormCategory(catItem, 'weightage', flValue || '', 'category');
																			//}
																		}}
																	/>
																</Box>
																{goalFormData?.performanceGoalFormCategoryRequest?.length > 1 && (
																	<OKRButton
																		icon={<DeleteIcon />}
																		className='editIcon'
																		title={t('delete')}
																		handleClick={(e: any) => {
																			e.preventDefault();
																			e.stopPropagation();
																			setOpenDeleteDialog({
																				open: true,
																				message: t('deleteRatingScale'),
																				details: { goal: null, goalIndex: 0, category: catItem, catIndex },
																				type: 'category',
																			});
																		}}
																	/>
																)}
															</Box>
														)}
													</Box>
													<Collapse className='create-goal-content' in={catItem.isExpand} timeout='auto'>
														<Box className='create-goal-content-inner'>
															<CreateGoalSettingsWrapper
																goalFormList={catItem.performanceGoalFormDetailRequest}
																category={catItem}
																indexVal={catIndex}
																onDragEnd={onDragEnd}
																getItemStyle={getItemStyle}
																goalFormData={goalFormData}
																setGoalFormData={setGoalFormData}
																goalLength={catItem?.performanceGoalFormDetailRequest?.length}
																handleDeleteGoal={handleDeleteGoal}
																responseTypeList={responseTypeList}
															/>
															<Box className='section-actions'>
																<OKRButton
																	className='btn-add-key'
																	icon={<AddIconSvg />}
																	text={t('addGoalLabel')}
																	id={'add-goal-btn'}
																	handleClick={(e: any) => handleAddNewGoal(e, catItem, catIndex)}
																/>

																<OKRButton
																	className='btn-text'
																	text={t('addCategoryLabel')}
																	id={'add-category-btn'}
																	handleClick={(e: any) => handleAddNewCategory(e, catItem, catIndex)}
																/>
															</Box>
														</Box>
													</Collapse>
												</Box>
											</Box>
										</Fragment>
										/*)}
                                </Draggable>*/
									}
								</>
							))}
							{/*</div>
                                </DragDropContext>)}
                            </Droppable>
                        </DragDropContext>*/}
						</Fragment>
					) : (
						<></>
					)}
					<Box className='create-goal-form-actions'>
						{goalFormData.status !== 2 && (
							<OKRButton
								className='btn-link'
								id='save-as-draft'
								text={t('saveAsDraft')}
								handleClick={() => handleSave(true)}
								disabled={isButtonDisabled}
							/>
						)}
						<OKRButton
							className='btn-primary'
							id='save-btn'
							text={t('saveBtn')}
							handleClick={() => handleSave(false)}
							disabled={isButtonDisabled}
						/>
					</Box>
					{openDeleteDialog && openDeleteDialog?.open && (
						<AlertDialog
							module='deleteMyObjective'
							message={openDeleteDialog?.message || t('deleteRatingScale')}
							handleCloseModal={handleDialogDeleteClick}
							modalOpen={openDeleteDialog?.open}
						/>
					)}
				</Box>
				{modalProps && modalProps.open && (
					<AlertDialog
						module='PerformanceReviewList'
						isCancel={false}
						message={modalProps?.message || ''}
						handleCloseModal={handleCloseModal}
						modalOpen={modalProps.open}
					/>
				)}
			</div>
		</>
	);
};
