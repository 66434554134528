import React, { Fragment, useState } from 'react';
import { Avatar, Box, Tooltip, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import {
	CheckinLinkIcon,
	CompletedDateIcon,
	CreatedDateIcon,
	DueDateIcon,
	LinkIcon,
	LinkKrIcon,
	LowPriorityIcon,
	NoUserIcon,
} from '../../config/svg/CommonSvg';
import { CancelIcon } from '../../config/svg/Action';
import { OKRButton } from '../Common/OKRButton';
import { TaskPriorityPopper } from './TaskPriorityPopper';
import { Enums } from '../../config/enums';
import ReactDateRangePicker from '../Common/ReactDateRangePicker';
import { TaskDelegatePopper } from './TaskDelegatePopper';
import { getLocalStorageItem } from '../../services/StorageService';
import { TaskKrLinkPopper } from './TaskKrLinkPopper';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { getMonthDateYearTime, checkSupportPermission } from '../../config/utils';
import { globalTaskSourceLabels } from '../../config/constant';
import { ErrorIcon } from '../../config/svg/formElementIcons';
import { TaskLinkPopper } from './TaskLinkPopper';
import { RequestOneOnOneIcon, RequestOneOnOneIcon2 } from '../../config/svg/PersonalFeedbackSvg';
import { supportModuleAlignOkr } from '../Admin/AdminConstant';
import { Conversation } from '../Goals/Cfr/Conversations/Conversation';
import { OKRDrawer } from '../Common/OKRDrawer';
import { Cfr } from '../Goals/Cfr';
import {
	UpdateConversation,
	addConversation,
	deleteConversation,
	getConversation,
	likeConversation,
} from '../../action/cfr';
import { useDispatch, useSelector } from 'react-redux';

export const TaskQuickLinks: React.FC<any> = (props: any) => {
	const {
		isLaunchFormEdited,
		setIsLaunchFormEdited,
		formDetails,
		taskData,
		setFormDetails,
		handleAddTaskDueDateChange,
		curCycleData,
		startDateValue,
		maxDateValue,
		completeByDate,
		setCompleteBy,
		handleResetButtonClick,
		onSelectedUser,
		handleRemoveAssignee,
		defaultRecentUserList,
		isCreateTask = false,
		uniqueId,
		isActionable,
		setIsCreateLinkPopupOpen,
		callingFrom = 'GlobalTask',
		isKRandDueDateRemove,
		dueDateErrorMessage,
		setDueDateErrorMessage,
		dueDateErrorId = '',
		isDateError = false,
		handleTaskLinkChange,
		handleCheckInSelection = () => {},
		handleRemoveCheckInSelection = () => {},
		dueDateError = null,
	} = props;

	const { t } = useTranslation();
	const getTaskName = (typeId: number) => {
		const globalTaskSourceLabelsVal: any = { ...globalTaskSourceLabels };
		return globalTaskSourceLabelsVal && globalTaskSourceLabelsVal[typeId];
	};
	const getTitleName = (sender: any, receiver: any) => {
		if (sender && sender?.toLowerCase() === 'you' && receiver) {
			return `1-on-1 between ${sender} and ${receiver}`;
		} else {
			return sender && receiver ? `1-on-1 between ${sender} and ${receiver}` : null;
		}
	};
	const isDisable = () => {
		const disabled = isCreateTask ? false : !isActionable;
		return disabled;
	};

	const [openCFR, setOpenCFR] = useState<boolean>(false);
	const [isCfrUpdated, setIsCfrUpdated] = useState<boolean>(false);
	const [modalCfrData, setModalCfrData] = useState<any>({ type: 1, okr: {}, kr: {}, taggedUser: {} });
	const [isTaskModified, setIsTaskModified] = useState<boolean>(false);
	const [unsavedCfrProps, setUnsavedCfrProps] = React.useState<any>({ open: false, event: null, source: '' });
	const handleCfrOpen = (event: any, type: number, kr: any, okr: any, tabToOpen?: string, taggedUser = {}) => {
		if (Boolean(okr) || Boolean(kr)) {
			setOpenCFR(true);
			setModalCfrData({
				type: type,
				okr: okr,
				kr: kr,
				taskData: taskData,
				taggedUser: taggedUser,
			});
		}
	};

	const handleCfrDrawerClose = (event: any, type: string) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'save') {
			event.preventDefault();
			setOpenCFR(false);
			if (modalCfrData.type === 1 && modalCfrData?.okr?.isUnreadConversation === true) {
				modalCfrData.okr.isUnreadConversation = false;
			} else if (modalCfrData.type === 2 && modalCfrData?.kr?.isUnreadConversation === true) {
				modalCfrData.kr.isUnreadConversation = false;
			}
			//props.resetConversation();
			//props.resetNotes();
		} else if (type === 'cancel') {
			if (isTaskModified) {
				setUnsavedCfrProps({ open: true, event: null, source: 'close' });
			} else {
				setOpenCFR(false);
				setUnsavedCfrProps({ open: false, event: null, source: '' });
			}
		}
	};

	const dispatch = useDispatch();
	const getConversationData = (data: any) => {
		dispatch(getConversation(data));
	};
	const addConversationData = (data: any) => {
		return dispatch(addConversation(data));
	};
	const updateConversationData = (data: any) => {
		return dispatch(UpdateConversation(data));
	};
	const likeConversationData = (data: any) => {
		return dispatch(likeConversation(data));
	};
	const deleteConversationData = (data: any) => {
		return dispatch(deleteConversation(data));
	};
	const { conversationList, getConversationStatus } = useSelector((state: any) => state?.cfrReducer);
	return (
		<Box className='quick-links-btn'>
			<Box className='links-col priority-links'>
				<TaskPriorityPopper
					{...props}
					isCreateTask={isCreateTask}
					setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
				/>
			</Box>
			{isCreateTask ? (
				<Box className='links-col delegate-to-links'>
					<TaskDelegatePopper
						formDetails={formDetails}
						onSelectedUser={onSelectedUser}
						handleRemoveAssignee={handleRemoveAssignee}
						defaultRecentUserList={defaultRecentUserList}
						isActionable={true}
						isCreateTask={isCreateTask}
						setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
					/>
				</Box>
			) : (
				<></>
			)}
			{!isCreateTask ? (
				<Box className='links-col created-on-links'>
					<OKRButton
						className='created-btn btn-link-type1'
						icon={<CreatedDateIcon />}
						text={formDetails?.createdOn ? getMonthDateYearTime(formDetails?.createdOn) : ''}
						title={t('createOnLabelNew')}
					/>
				</Box>
			) : (
				<></>
			)}
			{/* {callingFrom === 'GlobalTask' ? ( */}
			<Box className='links-col due-on-links'>
				<Box
					className={`due-timeline-area ${
						dueDateErrorMessage && dueDateErrorMessage?.isError
							? 'custom-error-field helperText-bottom'
							: dueDateErrorId && isDateError
							? 'custom-error-field helperText-bottom'
							: !formDetails?.taskDueDate && dueDateError && dueDateError?.isError
							? 'custom-error-field helperText-bottom duedate-error'
							: ''
					}`}
				>
					<ReactDateRangePicker
						type={'GlobalTask'}
						{...props}
						calendarTitle={t('dueDateCalenderTitle')}
						startDateValue={formDetails?.taskDueDate ? new Date(formDetails?.taskDueDate) : null}
						selectedCycleDetails={curCycleData || {}}
						minimumDate={startDateValue ? new Date(startDateValue) : new Date()}
						maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
						handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
							if (start && range) {
								setDueDateErrorMessage && setDueDateErrorMessage({ isError: false, errMsg: '' });
								handleAddTaskDueDateChange(start, range);
							}
						}}
						isDateRangeTextFieldVisible={true}
						isOnlyDueDateVisible={false}
						allCycleDetails={[]}
						isCycleVisible={false}
						isEndOfMonthVisible={false}
						numberOfCalendars={Enums.ONE}
						selectionType={'single'}
						selectedDateRange={completeByDate}
						setSelectedDateRange={setCompleteBy}
						placeholderText={t('selectedDate')}
						isDatePickerDisable={isCreateTask ? false : !isActionable}
						isResetButtonVisible={true}
						handleResetButtonClick={handleResetButtonClick}
						isCreateTaskDetails={{
							isCreateTask: isCreateTask || false,
							dueDateText: formDetails?.dueDateText || '',
							isOverDue: formDetails?.isOverDue || false,
							overDueText: formDetails?.overDueText || '',
							isKRandDueDateRemove: isKRandDueDateRemove || false,
							isDueDateUpdated: formDetails?.isDueDateUpdated || false,
							dueDateHistoryList: formDetails?.dueDateHistoryList || [],
						}}
					/>
					{dueDateErrorMessage && dueDateErrorMessage?.isError ? (
						<Typography className='error-field'>
							<ErrorIcon /> {dueDateErrorMessage?.errMsg}
						</Typography>
					) : (
						<></>
					)}
					{dueDateErrorId && isDateError ? (
						<Typography id={dueDateErrorId} style={{ display: 'none' }} className='error-field'>
							<ErrorIcon /> Please select a due date
						</Typography>
					) : (
						<></>
					)}
					{!formDetails?.taskDueDate && dueDateError && dueDateError?.isError ? (
						<Typography className='error-field'>
							<ErrorIcon /> <span dangerouslySetInnerHTML={{ __html: t('checkInValidation') }}></span>
						</Typography>
					) : (
						<></>
					)}
				</Box>
			</Box>
			{/* // ) : (
			// 	<></>
			// )} */}
			{!isCreateTask && formDetails?.completeDate && (callingFrom === 'GlobalTask' || callingFrom === 'CheckInTask') && (
				<Box className='links-col created-on-links'>
					<OKRButton
						className='created-btn btn-link-type1'
						icon={<CompletedDateIcon />}
						text={formDetails?.completeDate ? getMonthDateYearTime(formDetails?.completeDate) : ''}
						title={t('completedDate')}
					/>
				</Box>
			)}
			{/* {callingFrom === 'GlobalTask' || callingFrom === 'OneOnOneTask' || callingFrom === 'CheckInTask' ? ( */}
			{isCreateTask && callingFrom === 'KrTask' ? (
				<></>
			) : (
				<>
					{checkSupportPermission(supportModuleAlignOkr) ? (
						<Box className='links-col kr-linking'>
							<TaskKrLinkPopper
								{...props}
								isCreateTask={isCreateTask}
								setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
							/>
						</Box>
					) : (
						<></>
					)}
				</>
			)}
			{/* ) : (
				<></>
			)} */}
			{isCreateTask && callingFrom === 'CheckInTask' ? (
				<></>
			) : (
				<Box className='links-col checkin-tag-link '>
					<Box className={`selected-item selected-chip `}>
						{formDetails?.isCheckedInLinked ? (
							<>
								<Tooltip
									arrow
									classes={{ popper: 'tooltip-layout12' }}
									title={<Box className='tooltip-text'>{t('addedCheckIn')}</Box>}
								>
									<Box className={`chip-tag ${isCreateTask || isActionable ? '' : 'disable-chip'}`}>
										<CheckinLinkIcon />
										<Typography variant='h6'>{t('checkInsLabel')}</Typography>
									</Box>
								</Tooltip>
								{isCreateTask || isActionable ? (
									<OKRButton
										handleClick={(e: any) => handleRemoveCheckInSelection(e, false)}
										className='cancel-btn'
										icon={<CancelIcon />}
										title={t('removeCheckIn')}
									/>
								) : (
									<></>
								)}
							</>
						) : (
							<OKRButton
								disabled={isCreateTask ? false : !isActionable}
								handleClick={(e: any) => handleCheckInSelection(e, true)}
								className='blue-btn link-icon-btn'
								icon={<CheckinLinkIcon />}
								title={
									isDisable()
										? formDetails?.isCompleted
											? t('disableQuickLinkTitleForCompleted')
											: t('disableQuickLinkTitle')
										: t('addCheckIn')
								}
								buttonType={'disableButtonTooltip'}
								wrapClassName={'disable-button-wrap'}
							/>
						)}
					</Box>
				</Box>
			)}
			<Box className='links-col text-linking' id='text-linking'>
				<TaskLinkPopper
					{...props}
					handleTaskLinkChange={handleTaskLinkChange}
					formDetails={formDetails}
					setFormDetails={setFormDetails}
				/>
			</Box>
			{!isCreateTask ? (
				<Box className='links-col task-conversation-action'>
					<Conversation
						selectTab={'conversation'}
						handleCfrOpen={handleCfrOpen}
						modalCfrData={modalCfrData}
						okr={formDetails}
						type={4}
						{...props}
					/>
				</Box>
			) : (
				<></>
			)}
			{openCFR && (
				<OKRDrawer
					open={openCFR}
					drawerClassName={'main-drawer-panel main-drawer-titlepanel ok-cfr-panel'}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={''}
					drawerHead={false}
					children={
						<Cfr
							isCfrUpdated={isCfrUpdated}
							setIsCfrUpdated={setIsCfrUpdated}
							{...props}
							kr={modalCfrData.taskData}
							okr={modalCfrData.okr}
							type={modalCfrData.type}
							taggedUser={modalCfrData.taggedUser}
							setIsTaskModified={setIsTaskModified}
							openCFR={openCFR}
							setOpenCFR={setOpenCFR}
							setUnsavedCfrProps={setUnsavedCfrProps}
							unsavedCfrProps={unsavedCfrProps}
							getConversation={getConversationData}
							addConversation={addConversationData}
							UpdateConversation={updateConversationData}
							likeConversation={likeConversationData}
							deleteConversation={deleteConversationData}
							conversationList={conversationList}
							getConversationStatus={getConversationStatus}
							selectTab={'conversation'}
						/>
					}
					moreButton={false}
					handleDrawerClose={(event: any) => handleCfrDrawerClose(event, 'cancel')}
					handleSaveClick={(event: any) => handleCfrDrawerClose(event, 'save')}
					handleCancelClick={(event: any) => handleCfrDrawerClose(event, 'cancel')}
					hideDefaultButton={true}
					isAnyDrawerOpened={props.isAnyDrawerOpened}
					appDrawerInfo={props.appDrawerInfo}
				/>
			)}
			{!isCreateTask && (
				<>
					{/* {formDetails?.requestLinkDetails && formDetails?.requestLinkDetails?.reciverName ? (
						<Box className='links-col one-on-one-link'>
							<Tooltip
								arrow
								classes={{ popper: 'tooltip-layout12' }}
								title={
									formDetails?.requestLinkDetails?.isLinkGreyedOut ? (
										<>
											{formDetails?.requestLinkDetails?.toolTipText
												? formDetails?.requestLinkDetails?.toolTipText
												: getTitleName(
														formDetails?.requestLinkDetails?.senderName || 'You',
														formDetails?.requestLinkDetails?.reciverName
												  )}
											<br />
											{`"${formDetails?.requestLinkDetails?.oneToOneTitel}"`}
										</>
									) : (
										<>
											{formDetails?.requestLinkDetails?.toolTipText
												? formDetails?.requestLinkDetails?.toolTipText
												: getTitleName(
														formDetails?.requestLinkDetails?.senderName || 'You',
														formDetails?.requestLinkDetails?.reciverName
												  )}
											<br />
											{`"${formDetails?.requestLinkDetails?.oneToOneTitel}"`}
										</>
									)
								}
							>
								<Box
									className={`task-1-on-1-list ${
										formDetails?.requestLinkDetails?.isLinkGreyedOut ? 'disable-chip' : ''
									}`}
								>
									<span className='requested-by'>{formDetails?.requestLinkDetails?.senderName || 'You'}</span>
									<span className='request-arrow arrow-left'>
										<BackArrow />
									</span>
									<span className='requested-to'>{formDetails?.requestLinkDetails?.reciverName}</span>

									{formDetails?.requestLinkDetails?.oneToOneTitel ? (
										<span className='meeting-title'>{formDetails?.requestLinkDetails?.oneToOneTitel}</span>
									) : (
										<></>
									)}
								</Box>
							</Tooltip>
						</Box>
					) : (
						<></>
					)} */}
					{formDetails?.taskTypeId && formDetails?.taskTypeId === Enums.THREE ? (
						<Tooltip
							arrow
							classes={{ popper: 'tooltip-layout12' }}
							title={
								formDetails?.requestLinkDetails?.isLinkGreyedOut ? (
									<>
										{formDetails?.requestLinkDetails?.toolTipText
											? formDetails?.requestLinkDetails?.toolTipText
											: getTitleName(
													formDetails?.requestLinkDetails?.senderName || 'You',
													formDetails?.requestLinkDetails?.reciverName
											  )}
										<br />
										{formDetails?.requestLinkDetails?.oneToOneTitel
											? `"${formDetails?.requestLinkDetails?.oneToOneTitel}"`
											: null}
									</>
								) : (
									<>
										{formDetails?.requestLinkDetails?.toolTipText
											? formDetails?.requestLinkDetails?.toolTipText
											: getTitleName(
													formDetails?.requestLinkDetails?.senderName || 'You',
													formDetails?.requestLinkDetails?.reciverName
											  )}
										<br />
										{formDetails?.requestLinkDetails?.oneToOneTitel
											? `"${formDetails?.requestLinkDetails?.oneToOneTitel}"`
											: null}
									</>
								)
							}
						>
							<Box className='links-col task-source'>
								<Box
									className={`chip-tag source-tag ${
										formDetails?.taskTypeId === Enums.ONE || formDetails?.taskTypeId === Enums.THREE
											? 'no-uppercase'
											: ''
									}`}
								>
									<RequestOneOnOneIcon2 />
									<Typography variant='h6'>{getTaskName(formDetails?.taskTypeId) || ''}</Typography>
								</Box>
							</Box>
						</Tooltip>
					) : (
						<></>
					)}
				</>
			)}
		</Box>
	);
};
