import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';

export const DashboardPillerBox: React.FC<any> = (props: any) => {
	const {
		icon,
		mainHead,
		subHead,
		description,
		bgColor,
		tileImg,
		feebackData,
		isAssessment,
		type,
		checkInData,
		widgetData,
		isDirectReports,
		assessmentText,
		handleCardClick,
		assessmentFinalRatingText,
		customClassName,
		takeAssessmentText,
	} = props;
	const { t } = useTranslation();
	return (
		<Box
			className={`box-rectangle-info ${bgColor ? bgColor : ''} ${customClassName ? customClassName : ''}`}
			onClick={(e) => {
				type === 'taskLeft'
					? handleCardClick(e, 'checkins')
					: type === 'requestOneOnOne'
					? handleCardClick(e, 'requestOneOnOne')
					: type === 'feedBackRequest'
					? handleCardClick(e, 'feedBackRequest')
					: type === 'assessment' && isDirectReports
					? handleCardClick(e, 'teamAssessment')
					: type === 'assessment' && !isDirectReports
					? handleCardClick(e, 'assessment')
					: handleCardClick(e, '');
			}}
		>
			{tileImg ? (
				<img src={tileImg} alt='tile-img' />
			) : (
				<Box className='box-head'>
					<Box className='box-head-title'>
						{icon || ''}
						<Typography variant='h2'>
							{type === 'requestOneOnOne' ? (
								<>{feebackData?.request1On1 || 0}</>
							) : type === 'feedBackRequest' ? (
								<>{feebackData?.totalFeedbackReceive || 0}</>
							) : type === 'taskLeft' ? (
								<>{checkInData?.taskCount || 0}</>
							) : isAssessment ? (
								<>
									{/*isDirectReports && widgetData?.isDirectReportees && widgetData?.isActiveCycle ? (
										widgetData.pendingDirectReporteesCount
									) : (
										<>{assessmentText}</>
									)*/}
								</>
							) : (
								<></>
							)}

							{/* Days Left */}
							{/* {isDirectReports ? <></> : widgetData?.isSelfAssessmentDone ? <></> : <></>} */}
						</Typography>
						{isAssessment && (
							<>
								{takeAssessmentText ? (
									takeAssessmentText
								) : (
									<>
										<OKRButton className={`btn-link take-assessment`} text={t('takeAssessment')} icon={''} />
										<Typography variant='h2' className='blank-text'>
											- -
										</Typography>
									</>
								)}
							</>
						)}
					</Box>
					{/* {isAssessment && (
						<Typography variant='h6' className='final-rating-text'>
							<>
								{!isDirectReports && widgetData.finalScore && widgetData.finalRating ? (
									<>{widgetData.finalRating}</>
								) : !isDirectReports ? (
									<>{t('assessmentPending')}</>
								) : (
									<>{assessmentFinalRatingText}</>
								)}
							</>
						</Typography>
					)} */}
				</Box>
			)}
			<Box className='box-content'>
				{mainHead && <Typography variant='h5' dangerouslySetInnerHTML={{ __html: mainHead }}></Typography>}
				{subHead && (
					<Typography variant='h5' className='font-weight-normal'>
						{subHead}
					</Typography>
				)}
				{description && (
					<Typography variant='h6' className='font-weight-normal'>
						{description || ''}
					</Typography>
				)}
			</Box>
		</Box>
	);
};
