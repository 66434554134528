import React, { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	TextField,
	Typography,
	Avatar,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import Slider from '@material-ui/core/Slider';
import { getTime, getUserName } from '../../config/utils';
import AlertDialog from '../Common/Dialog';
import { labels, marks } from '../../config/constant';
import { ErrorIcon } from '../../config/svg/formElementIcons';

export const ReplyPersonalFeedback = (props) => {
	const {
		expandGivenFeedbackDetails,
		data,
		userDetail,
		formData,
		onChangePraiseRating,
		onChangeGrowthOpportunityRating,
		setFormDataUpdated,
		commentFeedback,
		fetchGiveFeedback,
		setLoader,
		loader,
		isPeoplePage,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const defaultData = { sharedRemark: '', isError: false };
	const [isReply, setIsReply] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [replyFormData, setReplyFormData] = useState(defaultData);

	const setMessageValue = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setFormDataUpdated(true);
		setReplyFormData({ ...replyFormData, sharedRemark: event.target.value, isError: false });
	};
	const handleReplyToFeedback = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setIsReply((prev) => !prev);
	};
	const handleRequestOneOnOne = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setModalOpen(true);
	};
	/** request 1:1 api calling */
	const handleCloseModal = (e, type) => {
		e.preventDefault();
		e.stopPropagation();
		const { userDetail } = props;
		if (type === 1) {
			const reqData = {
				requestType: 4,
				requestedTo: [Boolean(data) && data.employeeId ? data.employeeId : 0],
				requestedFrom: userDetail.employeeId,
				onetoOneRemark: t('thankyouFeedback'),
			};
			setModalOpen(false);
			props
				.requestOneToOnePersonalFeedback(reqData)
				.then((response) => {
					if (Boolean(response) && response.data && response.data.status === 200) {
						enqueueSnackbar(t('requestRaisedSuccessfully'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					} else {
						const responseAPI = Boolean(response) && response.data && response.data.messageList;
						const keys = Boolean(responseAPI) && Object.keys(responseAPI);
						const messages = Boolean(keys) && keys.length && keys.map((item) => responseAPI[item]);
						enqueueSnackbar(messages, {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				})
				.catch((error) => {
					enqueueSnackbar(t('errorRaisingRequest'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				});
		}
		setModalOpen(false);
	};
	const FeedbackPraiseRatingComponent = ({ name, label, criteriaMasterId, disable, defaultValue }) => {
		const selectedValue =
			Boolean(formData.criteriaFeedbackMappingRequests) &&
			formData.criteriaFeedbackMappingRequests.length &&
			formData.criteriaFeedbackMappingRequests.find((item) => item.criteriaMasterId === criteriaMasterId);
		const selectedRating = Boolean(defaultValue) ? defaultValue : Boolean(selectedValue) ? selectedValue.score : 0;

		return (
			<Box className='praise-rating'>
				<Typography component='legend'>{label}</Typography>
				<Box display='flex' alignItems='center'>
					<Rating
						name={name}
						defaultValue={0}
						value={selectedRating}
						getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
						precision={1}
						onChange={onChangePraiseRating}
						disabled={disable}
					/>
					{Boolean(selectedRating) && <Typography variant='body2'>{labels[parseInt(selectedRating)]}</Typography>}
				</Box>
			</Box>
		);
	};
	const FeedbackGrowthOpportunity = ({ name, label, criteriaMasterId, disable, defaultValue }) => {
		const selectedValue =
			Boolean(formData.criteriaFeedbackMappingRequests) &&
			formData.criteriaFeedbackMappingRequests.length &&
			formData.criteriaFeedbackMappingRequests.find((item) => item.criteriaMasterId === criteriaMasterId);

		return (
			<Box className='growth-ratings' display='flex' justifyContent='space-between'>
				<Box className='growth-ratings-label'>
					<Typography id={`${name}-slider-small-steps`} gutterBottom>
						{label}
					</Typography>
				</Box>
				<Box className='growth-ratings-slider'>
					<Slider
						defaultValue={0}
						name={`${name}`}
						aria-labelledby={`${name}-slider-small-steps`}
						step={1}
						value={Boolean(defaultValue) ? defaultValue : Boolean(selectedValue) ? selectedValue.score : 0}
						min={0}
						max={5}
						valueLabelDisplay='off'
						marks={marks}
						onChange={(event, value) => onChangeGrowthOpportunityRating(event, value, name)}
						disabled={disable}
					/>
				</Box>
			</Box>
		);
	};
	const deleteJsonKey = (data) => {
		delete data['isError'];
		return data;
	};
	const handleSendReplyFeedback = (event) => {
		event.preventDefault();
		event.stopPropagation();
		try {
			deleteJsonKey(replyFormData);
			if (
				Boolean(replyFormData) &&
				replyFormData.sharedRemark &&
				replyFormData.sharedRemark.trim() !== '' &&
				Boolean(data) &&
				data.criteriaTypeId &&
				data.feedbackDetailId
			) {
				setLoader(true);
				const requestData = {
					comments: replyFormData.sharedRemark,
					feedbackDetailId: data.feedbackDetailId ? data.feedbackDetailId : 0,
					parentCommentId: 0,
				};
				commentFeedback(requestData)
					.then((response) => {
						if (Boolean(response) && response.status === 200) {
							enqueueSnackbar(t('commentAddedSuccess'), {
								variant: 'success',
								autoHideDuration: 3000,
							});
							setReplyFormData(defaultData);
							fetchGiveFeedback(true, '', 0);
							setIsReply(false);
							setFormDataUpdated(false);
						} else {
							enqueueSnackbar(t('errorAddingComment'), {
								variant: 'error',
								autoHideDuration: 3000,
							});
						}
						setLoader(false);
					})
					.catch((err) => {
						setLoader(false);
						enqueueSnackbar(t('errorAddingComment'), {
							variant: 'error',
							autoHideDuration: 3000,
						});
					});
			} else {
				setReplyFormData({ ...replyFormData, isError: true });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleCancelReplyFeedback = (event) => {
		event.preventDefault();
		event.stopPropagation();
		setIsReply((prev) => !prev);
		setReplyFormData(defaultData);
	};

	return (
		<Fragment>
			<Box className='comments-zoom-in animate animate-fadeIn'>
				<List className='comments-list'>
					<ListItem style={{ cursor: 'pointer' }} onClick={() => expandGivenFeedbackDetails(data)}>
						<ListItemAvatar>
							{Boolean(data.imagePath) ? (
								<Avatar alt={`${data.firstName} ${data.lastName}`} src={data.imagePath} />
							) : (
								<Avatar>{getUserName(data)}</Avatar>
							)}
						</ListItemAvatar>
						<ListItemText
							primary={
								<Typography variant='h4'>
									{data.firstName} {data.lastName}
								</Typography>
							}
							secondary={
								<Fragment>
									<Typography variant='subtitle2'>{getTime(data.date)}</Typography>
								</Fragment>
							}
						/>
					</ListItem>
					<List className='comments-sub-list'>
						<ListItem>
							<Box className='feedback-ratings-area' id={`view-pf-${data.feedbackDetailId}`}>
								<FormControl variant='outlined' style={{ width: '100%' }}>
									<InputLabel htmlFor='ask-feedback-from'>
										{`${t('giveForRatedOn')}: `}
										{Boolean(data.criteriaName)
											? data.criteriaName
											: Boolean(data.feedbackCriteriaName)
											? data.feedbackCriteriaName
											: ''}
									</InputLabel>
									{data.criteriaTypeId === 1 ? (
										<Box className='praise-area'>
											{Boolean(data.criteriaFeedbackMappingResponses) &&
											data.criteriaFeedbackMappingResponses &&
											data.criteriaFeedbackMappingResponses.length
												? data.criteriaFeedbackMappingResponses.map((praise) => (
														<FeedbackPraiseRatingComponent
															name={`feedbackPraise-${praise.criteriaName}`}
															label={`${praise.criteriaName}`}
															criteriaMasterId={praise.criteriaMasterId}
															disable={true}
															defaultValue={praise.score}
														/>
												  ))
												: ''}
										</Box>
									) : (
										<Box className='growth-opportuniy-area'>
											{Boolean(data.criteriaFeedbackMappingResponses) &&
											data.criteriaFeedbackMappingResponses &&
											data.criteriaFeedbackMappingResponses.length
												? data.criteriaFeedbackMappingResponses.map((growthOpportunity) => (
														<FeedbackGrowthOpportunity
															name={`feedbackGO-${growthOpportunity.criteriaName}`}
															label={`${growthOpportunity.criteriaName}`}
															criteriaMasterId={growthOpportunity.criteriaMasterId}
															disable={true}
															defaultValue={growthOpportunity.score}
														/>
												  ))
												: ''}
										</Box>
									)}
								</FormControl>
							</Box>
						</ListItem>
						{Boolean(data.askByRequestRemark) && (
							<ListItem>
								<Box className='feedback-message requested-mssg'>
									<Typography>{t('RequestedMsg')}</Typography>
									<Typography>{data.askByRequestRemark}</Typography>
								</Box>
							</ListItem>
						)}

						<ListItem>
							<Box className='feedback-message'>
								<Typography>{t('commentLabel')}</Typography>
								<Typography>{data.sharedRemark}</Typography>
							</Box>
						</ListItem>
						{Boolean(data) && data?.feedbackComments && data.feedbackComments.length
							? data.feedbackComments.map((comment) => (
									<ListItem
										alignItems='flex-start'
										key={`comment${comment.commentId}`}
										id={`comment-${comment.commentId}`}
									>
										<ListItemAvatar>
											{comment.imagePath ? <Avatar src={comment.imagePath} /> : <Avatar>{getUserName(comment)}</Avatar>}
										</ListItemAvatar>
										<ListItemText
											primary={
												<Typography variant='h4'>
													{' '}
													{comment.firstName} {comment.lastName}{' '}
												</Typography>
											}
											secondary={
												<Fragment>
													<Typography variant='subtitle2'>{getTime(comment.createdOn)}</Typography>
													<Typography component='span'>{comment.comment}</Typography>
												</Fragment>
											}
										/>
									</ListItem>
							  ))
							: ''}
						{isReply ? (
							<form className='okr-form-fields' noValidate autoComplete='off'>
								<ListItem className='pf-conversation' alignItems='flex-start'>
									<ListItemAvatar>
										{userDetail.imagePath ? (
											<Avatar src={userDetail.imagePath} />
										) : (
											<Avatar>{getUserName(userDetail)}</Avatar>
										)}
									</ListItemAvatar>
									<ListItemText
										primary={
											<Typography variant='h4'>
												{userDetail.firstName} {userDetail.lastName}
											</Typography>
										}
										secondary={
											<Fragment>
												<FormControl
													className='feedback-message helperText-bottom'
													variant='outlined'
													style={{ width: '100%' }}
												>
													<TextField
														id={`reply-text-${data.feedbackDetailId}`}
														placeholder={t('typeMessageHere')}
														inputProps={{ 'aria-label': t('FeedbackViewAriaLabel') }}
														onChange={setMessageValue}
														error={Boolean(replyFormData) && replyFormData.isError}
														helperText={
															Boolean(replyFormData) && replyFormData.isError ? (
																<>
																	<ErrorIcon />
																	{t('enterValidMessage')}
																</>
															) : (
																''
															)
														}
													/>
												</FormControl>
											</Fragment>
										}
									/>
								</ListItem>
								<Box className='okr-form-action'>
									<Button
										id={`view-pf-cancel-${data.feedbackDetailId}`}
										className='cancel-btn btn-link'
										color='primary'
										onClick={handleCancelReplyFeedback}
									>
										{t('cancelBtn')}
									</Button>
									<Button
										className='submitBtn btn-primary'
										id={`view-pf-submit-${data.feedbackDetailId}`}
										type='submit'
										color='primary'
										onClick={handleSendReplyFeedback}
										disabled={(Boolean(replyFormData) && replyFormData.isError) || loader}
									>
										{t('send')}
									</Button>
								</Box>
							</form>
						) : (
							<ListItem className='okr-form-action-wrap'>
								<Box className='okr-form-action'>
									{/* {!isPeoplePage && (
										<Button
											id={`view-pf-requestbtn-${data.feedbackDetailId}`}
											className='request-btn'
											color='primary'
											onClick={handleRequestOneOnOne}
										>
											{t('requestOnetoOne')}
										</Button>
									)} */}
									{(!isPeoplePage || (isPeoplePage && data.isFeedbackEditable)) && (
										<Button
											className='submitBtn btn-primary '
											type='submit'
											color='primary'
											onClick={handleReplyToFeedback}
											id={`view-pf-replybtn-${data.feedbackDetailId}`}
										>
											{t('reply')}
										</Button>
									)}
								</Box>
							</ListItem>
						)}
					</List>
				</List>
			</Box>
			{modalOpen && (
				<AlertDialog
					module='oneToOne'
					message={t('requestOnToOneMsg')}
					handleCloseModal={handleCloseModal}
					modalOpen={modalOpen}
				/>
			)}
		</Fragment>
	);
};
