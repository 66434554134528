import React, { useState, useEffect } from 'react';
import {
	Box,
	ClickAwayListener,
	InputAdornment,
	List,
	ListItem,
	Paper,
	Popper,
	Tooltip,
	Typography,
	TextField,
	Chip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CancelIconSvg, LinkIcon, LinkKrIcon, SearchIcon } from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import { CancelIcon } from '../../config/svg/Action';
import { getRandomBackgroundColor, getRandomColor, getUserName } from '../../config/utils';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import noRecordFound from '../../images/search-placeholder.svg';
import { globalTaskCreateLinkUnSaveCheck, globalTaskCreatePopupDetails } from '../../action/globalTask';
import { useDispatch } from 'react-redux';

export const TaskKrLinkPopper: React.FC<any> = (props: any) => {
	const {
		krList,
		handleKRLink,
		formDetails,
		handleRemoveKR,
		isCreateTask,
		uniqueId,
		setIsCreateLinkPopupOpen,
		isActionable,
		callingFrom,
	} = props;
	// console.log('KR List ===> ', krList, loader, formDetails);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [open, setOpen] = useState(false);
	const [allKRList, setAllKRList] = useState<any>(null);
	const [searchText, setSearchText] = useState<string>('');

	useEffect(() => {
		if (krList && krList.length && formDetails?.goalId) {
			let updatedKrList = addSelectedKrAtFirst(krList);
			setAllKRList(updatedKrList);
		} else {
			setAllKRList(krList);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [krList, formDetails]);

	const handleClick = (event: any) => {
		const isEditable = isCreateTask ? true : checkIsDisable();
		if (isEditable) {
			setAnchorEl(anchorEl ? null : event.currentTarget);
			if (isCreateTask || callingFrom === 'OneOnOneTask') {
				setIsCreateLinkPopupOpen(true);
				dispatch(globalTaskCreateLinkUnSaveCheck(true));
				dispatch(globalTaskCreatePopupDetails('krLinkPopup'));
			}
			setOpen(true);
		} else {
			// No action needed
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
		if (isCreateTask || callingFrom === 'OneOnOneTask') {
			setIsCreateLinkPopupOpen(false);
			dispatch(globalTaskCreateLinkUnSaveCheck(false));
			dispatch(globalTaskCreatePopupDetails(null));
		}
		setOpen(false);
		clearKrListSearch();
	};
	const addSelectedKrAtFirst = (krListData: any) => {
		let updatedKrList = krListData && krListData.length ? [...krListData] : [];
		const newData = formDetails?.goalId
			? updatedKrList.filter((kr: any) => formDetails?.goalId !== kr?.goalKeyId)
			: [...updatedKrList];
		updatedKrList = formDetails?.linkedKRDetails ? [formDetails?.linkedKRDetails, ...newData] : [...newData];
		return updatedKrList;
	};
	const handleKrSearchChange = (e: any) => {
		try {
			const { value } = e?.target || {};
			let updatedKrList = krList && krList.length ? [...krList] : [];
			if (value && value?.trim() !== '') {
				updatedKrList = updatedKrList.filter((rec: any) =>
					rec?.keyDescription?.toLocaleLowerCase().includes(value.trim().toLocaleLowerCase())
				);
				// updatedKrList = addSelectedKrAtFirst(updatedKrList);
				setAllKRList(updatedKrList);
				setSearchText(value);
			} else {
				setSearchText('');
				setAllKRList(addSelectedKrAtFirst(krList));
			}
		} catch (e) {
			console.error(e);
			setSearchText('');
			setAllKRList(addSelectedKrAtFirst(krList));
		}
	};
	const clearKrListSearch = () => {
		setSearchText('');
		setAllKRList(addSelectedKrAtFirst(krList));
	};
	const checkIsDisable = () => {
		if (isActionable || formDetails?.linkedKRDetails?.isLinkGreyedOut) {
			return true;
		} else {
			return false;
		}
	};
	const handlePopperScroll = (event: any, elementId: any) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
			}
		}, 100);
	};

	return (
		<>
			{formDetails?.goalId ? (
				<Box className={`selected-item selected-kr-linking ${open ? 'active-popup' : ''}`}>
					<Tooltip
						arrow
						classes={{ popper: 'tooltip-layout12' }}
						title={
							<Box className='tooltip-text'>
								{formDetails?.linkedKRDetails?.toolTipText || 'Your Key Result'} <br />
								{`"${formDetails?.linkedKRDetails?.keyDescription || ''}"`}
							</Box>
						}
					>
						<Box
							className={`kr-linking-name ${
								isCreateTask || (formDetails?.isEditEnable && !formDetails?.linkedKRDetails?.isLinkGreyedOut)
									? ''
									: 'disable-chip'
							}`}
							onClick={(e) => {
								if (isCreateTask || (isActionable && !formDetails?.linkedKRDetails?.isLinkGreyedOut)) {
									handleClick(e);
									handlePopperScroll(e, 'krLinkPopup');
								}
							}}
							id={uniqueId ? `${uniqueId}_link_btn` : 'kr_link_btn'}
						>
							<LinkKrIcon />
							<Typography variant='h6'>{formDetails?.linkedKRDetails?.keyDescription || ''}</Typography>
						</Box>
					</Tooltip>
					{isCreateTask || (isActionable && !formDetails?.linkedKRDetails?.isLinkGreyedOut) ? (
						<OKRButton className='cancel-btn' icon={<CancelIcon />} handleClick={handleRemoveKR} />
					) : (
						<></>
					)}
				</Box>
			) : (
				<OKRButton
					className='blue-btn link-icon-btn'
					icon={<LinkKrIcon />}
					title={
						isCreateTask
							? t('linkKR')
							: !checkIsDisable()
							? formDetails?.isCompleted
								? t('disableQuickLinkTitleForCompleted')
								: t('disableQuickLinkTitle')
							: t('linkKR')
					}
					disabled={isCreateTask ? false : !checkIsDisable()}
					handleClick={(e) => {
						handleClick(e);
						handlePopperScroll(e, 'krLinkPopup');
					}}
					id={uniqueId ? `${uniqueId}_link_btn` : 'kr_link_btn'}
					buttonType={'disableButtonTooltip'}
					wrapClassName={'disable-button-wrap'}
				/>
			)}

			<Popper
				className={`task-kr-link-popup ${formDetails?.goalId ? 'task-kr-link-popup-type1' : ''}`}
				open={open}
				anchorEl={anchorEl}
				placement='bottom-start'
				modifiers={{
					flip: {
						enabled: false,
					},
				}}
				id={'krLinkPopup'}
			>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<Box className='task-kr-link-popup-inner'>
							<Box className='search-kr'>
								<TextField
									className='search-text-field'
									id='user-src-field'
									placeholder={t('srcKeyResult')}
									fullWidth
									value={searchText || ''}
									onChange={handleKrSearchChange}
									InputProps={{
										startAdornment: (
											<InputAdornment position='start'>
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position='end'>
												{searchText ? (
													<OKRButton
														className={'editIcon'}
														icon={<CancelIcon />}
														title={t('clear')}
														tooltipPlacement={'top'}
														handleClick={clearKrListSearch}
													/>
												) : (
													<></>
												)}
											</InputAdornment>
										),
									}}
								/>
							</Box>

							{allKRList && allKRList.length ? (
								<Box className='task-link-to-kr-list'>
									{searchText && searchText.trim() !== '' ? (
										<Box className='my-kr-title'>
											<Typography variant='h6'>{`Search Result (${allKRList?.length || 0})`}</Typography>
										</Box>
									) : (
										<></>
									)}
									<Box className='my-kr-list'>
										{allKRList && allKRList.length ? (
											allKRList.map((kr: any) => (
												<Box
													className={`my-kr-list-box ${formDetails?.goalId === kr?.goalKeyId ? 'active' : ''}`}
													key={'item.goalKeyId'}
													id={`my-kr-${kr?.goalKeyId}`}
													onClick={(e) => {
														handleClose();
														handleKRLink(e, kr);
													}}
												>
													<Typography>{kr?.keyDescription || ''}</Typography>
													<Box className='my-kr-info'>
														{kr?.teamName ? (
															<Box className='teams-chips'>
																<Tooltip title={kr?.teamName || ''} arrow>
																	<Chip
																		style={{
																			backgroundColor: getRandomBackgroundColor(kr?.backGroundColorCode),
																			color: getRandomColor(kr?.colorCode),
																		}}
																		label={getUserName({
																			firstName: '',
																			lastName: '',
																			fullName: kr.teamName || '',
																		})}
																	/>
																</Tooltip>
															</Box>
														) : (
															<></>
														)}
														<Box className='my-kr-date'>
															<Typography variant='h6'>{kr?.dueCycle || ''}</Typography>
														</Box>
													</Box>
												</Box>
											))
										) : (
											<></>
										)}
									</Box>
								</Box>
							) : (
								<Box className='search-no-record'>
									<Box className='no-record-message'>
										<Typography variant='h2'>{t('noRecordFound')}</Typography>
										<Typography variant='h6' className='no-sentence-case'>
											{t('noRecordFoundSecondary')}
										</Typography>
										<AnimatedIcon
											className=''
											width='250'
											type='image/svg+xml'
											alt={t('noRecordFoundSecondary')}
											imagePath={noRecordFound}
										/>
									</Box>
								</Box>
							)}
						</Box>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
};
