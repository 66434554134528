import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import NoDataImg from '../../../images/search-no-colleagues.svg';

export const NoRecord: React.FC<any> = (props) => {
	const { t } = useTranslation();
	const { noRecordMessage, subHead, noImg, noDataImgWidth } = props;

	return (
		<Box className='user-no-record'>
			{noRecordMessage ? (
				<Typography variant='h2'>{noRecordMessage}</Typography>
			) : (
				<Typography variant='h2'>{t('SearchText1')}</Typography>
			)}
			{subHead ? <Typography>{subHead}</Typography> : <Typography>{t('SearchText2')}</Typography>}
			<AnimatedIcon
				className=''
				width={noDataImgWidth ? noDataImgWidth : '260'}
				type='image/svg+xml'
				alt='No user'
				imagePath={noImg ? noImg : NoDataImg}
			/>
		</Box>
	);
};
