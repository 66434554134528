import React, { Fragment, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	FormLabel,
	Grid,
	Popper,
	Switch,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
	PointIcon,
	QuestionIcon,
	ReportsTooltipIcon,
	ScaleName,
	ScaleType,
} from '../../../../../config/svg/CommonSvgIcon';
import { ScaleTypeDropdown } from '../ScaleTypeDropdown';
import { OKRButton } from '../../../../Common/OKRButton';
import { AddIconSvg, DeleteIcon, DragIndicator } from '../../../../../config/svg/CommonSvg';
import { ScaleNameDropdown } from './ScaleNameDropdown';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { Enums } from '../../../../../config/enums';
import { getLabelDescNumber } from '../../../../../config/utils';
import { RatingScaleList } from '../ManageScale/RatingScaleList';

export const QuestionList: React.FC<any> = (props: any) => {
	const {
		section,
		question,
		sequence,
		questionIndex,
		handleFormSectionQuestionScaleTypeChange,
		handleFormSectionQuestionNameChange,
		questionTypeMasterData,
		callingFrom,
		customScaleType,
		sectionIndex,
		getScaleListByType,
		handleScaleNameChange,
		handleDeleteQuestion,
		getItemStyle,
		provided,
		snapshot,
		handleIsQuestionMandatory,
		handleIsCommentBoxAvailable,
		handleIsCommentBoxMandatory,
		handleManageScale,
	} = props;
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};

	const getSelectedScaleDetails = (performanceScaleId: any, type = 'name', questionsName: any, questionsType: any) => {
		const selectedItem = getScaleListByType(questionsName, questionsType)?.find(
			(item: any) => item?.performanceScaleId === performanceScaleId
		);
		if (type === 'name') {
			const selectedScaleName = selectedItem
				? `${selectedItem?.scaleName || ''} ${getLabelDescNumber(selectedItem)}`
				: '';
			return selectedScaleName || '';
		} else {
			const updatedDetails: any[] = [];
			if (selectedItem && selectedItem?.manageScaleDetailResponses && selectedItem?.manageScaleDetailResponses.length) {
				selectedItem?.manageScaleDetailResponses.forEach((item: any) => {
					const convertScaleDetails = {
						ratingScale: item?.name || '',
						point: item?.weightage || 0,
						scaleIndex: item?.sequence || 0,
						performanceScaleDetailId: item?.performanceScaleDetailId || 0,
						performanceScaleId: item?.performanceScaleId || 0,
						isDisable: false,
						isError: false,
						errorMessage: '',
						isPointError: false,
						pointErrorMessage: '',
					};
					updatedDetails.push(convertScaleDetails);
				});
			}
			return updatedDetails || [];
		}
	};

	return (
		<>
			<Box
				ref={provided.innerRef}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
				style={getItemStyle(snapshot.isDragging, provided.draggableProps?.style)}
				className='assessment-question-row'
				id={`form_section_item_index_${sectionIndex}_question_index_${questionIndex}`}
			>
				<Tooltip arrow title={t('dragDropLabel')}>
					<span className='drag-icon'>
						<DragIndicator />
					</span>
				</Tooltip>

				<Box className='question-info'>
					<Box className='col-1'>
						<TextField
							className='helperText-bottom'
							label={
								<>
									<QuestionIcon /> {`Question ${sequence}`}
								</>
							}
							placeholder={t('questionPlaceholder')}
							value={question?.questionsName || ''}
							name={t('questionPlaceholder')}
							error={question?.isSectionQuestionNameError || false}
							helperText={
								question?.isSectionQuestionNameError && question?.sectionQuestionNameErrorMessage ? (
									<>
										<ErrorIcon /> {question?.sectionQuestionNameErrorMessage || ''}
									</>
								) : null
							}
							onChange={(e: any) =>
								handleFormSectionQuestionNameChange(e, question, questionIndex, section, sectionIndex)
							}
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{ 'aria-label': t('questionPlaceholder'), disableUnderline: true }}
						/>
					</Box>
					<Box className='col-2'>
						<Box className={`helperText-bottom ${question.isSectionQuestionTypeError ? 'error-text-field' : ''}`}>
							<FormLabel component='legend'>
								<ScaleType />
								{t('questionType')}
							</FormLabel>
							<ScaleTypeDropdown
								customScaleDetails={{
									scaleType: question?.questionsTypeName || '',
									scaleTypeId: question?.questionsType || 0,
								}}
								handleScaleTypeChange={(id: any, name: any, scaleType: any) =>
									handleFormSectionQuestionScaleTypeChange(
										id,
										name,
										scaleType,
										question,
										questionIndex,
										section,
										sectionIndex
									)
								}
								questionTypeMasterData={questionTypeMasterData}
								callingFrom={callingFrom}
								customScaleType={customScaleType}
							/>
							{question.isSectionQuestionTypeError && question?.sectionQuestionTypeErrorMessage ? (
								<Typography className='error-field'>
									<ErrorIcon /> {question?.sectionQuestionTypeErrorMessage || ''}
								</Typography>
							) : (
								<></>
							)}
						</Box>
						{section?.ratingFormDetailRequest && section?.ratingFormDetailRequest?.length > 1 ? (
							<OKRButton
								icon={<DeleteIcon />}
								className='editIcon'
								title={t('delete')}
								handleClick={(e: any) => handleDeleteQuestion(e, question, questionIndex, section, sectionIndex)}
							/>
						) : (
							<></>
						)}
					</Box>
				</Box>
				{question?.questionsName && question?.questionsName?.trim() && question?.questionsType ? (
					<>
						{question?.questionsType === Enums.FOUR ? (
							<>
								<Box className='rating-type-listing short-answer-type'>
									<Box className='rating-type-content'>
										<Box className='rating-row'>
											<TextField
												className='helperText-bottom'
												placeholder={t('Assessee can enter the answer, upload images, add links etc')}
												name={t('Assessee can enter the answer, upload images, add links etc')}
												InputLabelProps={{
													shrink: true,
												}}
												InputProps={{
													'aria-label': t('Assessee can enter the answer, upload images, add links etc'),
													disableUnderline: true,
												}}
												disabled={true}
											/>
										</Box>
									</Box>
								</Box>
								<Box className='question-action'>
									<Box className='col'>
										<Grid component='label' container>
											<Grid item>
												<Box>
													<Switch
														// id='isQuestionMandatory'
														// name='isQuestionMandatory'
														checked={question?.isQuestionsMandatory}
														onChange={(e) =>
															handleIsQuestionMandatory(e, question, questionIndex, section, sectionIndex)
														}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
												</Box>
											</Grid>
											<Grid item>{t('isQuesMandatory')}</Grid>
										</Grid>
									</Box>
								</Box>
							</>
						) : (
							<>
								<Box className='scale-info'>
									<Box className='helperText-bottom'>
										<FormLabel component='legend'>
											<ScaleName />
											{t('scale')}
										</FormLabel>
										<ScaleNameDropdown
											performanceScaleId={question?.performanceScaleId || 0}
											getScaleListByType={() => getScaleListByType(question?.questionsName, question?.questionsType)}
											handleScaleNameChange={(e: any, item: any) =>
												handleScaleNameChange(e, item, questionIndex, sectionIndex, question, section)
											}
											getSelectedScaleDetails={(performanceId: any) =>
												getSelectedScaleDetails(performanceId, 'name', question?.questionsName, question?.questionsType)
											}
										/>
									</Box>
									<OKRButton
										icon={<AddIconSvg />}
										handleClick={(e: any) =>
											handleManageScale(e, 'AssessmentForm', question?.questionsTypeName, question?.questionsType)
										}
										className='btn-add-type1'
										text={t('addCustomScale')}
									/>
								</Box>
								{question?.performanceScaleId ? (
									<>
										<Box className='rating-type-listing'>
											<Box className='rating-type-head'>
												<Box className='rating-row'>
													<Box className='col-1'></Box>
													<Box className='col-2'>
														<Box className='weightage-head'>
															<Typography variant='h5'>
																<PointIcon />
																{t('points')}
															</Typography>
															<Box
																aria-owns={open ? 'mouse-over-popover' : undefined}
																aria-haspopup='true'
																onClick={handleOpen}
																//onMouseLeave={handleClose}
																component={'div'}
																className='filter-info-box'
															>
																<OKRButton id={'btnId'} icon={<ReportsTooltipIcon />} className={'info-icon'} />
																<Popper
																	className={'role-description-popup point-tooltip point-tooltip-page'}
																	open={open}
																	anchorEl={anchorEl}
																	transition
																	placement={'bottom-end'}
																>
																	<ClickAwayListener onClickAway={handleClose}>
																		<Box className='textarea-expand generate-okr-filter-selected'>
																			<Typography variant='body1'>{t('pointsTooltip')}</Typography>
																		</Box>
																	</ClickAwayListener>
																</Popper>
															</Box>
														</Box>
													</Box>
												</Box>
											</Box>
											{getSelectedScaleDetails(
												question?.performanceScaleId,
												'selectedItem',
												question?.questionsName,
												question?.questionsType
											) ? (
												<RatingScaleList
													customScaleDetails={{
														scaleTypeId: question?.questionsType || 0,
														scaleType: question?.questionsName || '',
														ratingScaleDetails: getSelectedScaleDetails(
															question?.performanceScaleId,
															'selectedItem',
															question?.questionsName,
															question?.questionsType
														),
													}}
													customScaleType={'View'}
													handleRatingScaleNameChange={() => {}}
													handleRatingScalePointChange={() => {}}
													checkRatingScaleLength={() => {}}
													handleDeleteRatingScaleItem={() => {}}
												/>
											) : (
												<></>
											)}
										</Box>
										<Box className='question-action'>
											<Box className='col'>
												<Grid component='label' container>
													<Grid item>
														<Box>
															<Switch
																// id='isQuestionMandatory'
																// name='isQuestionMandatory'
																checked={question?.isQuestionsMandatory}
																onChange={(e) =>
																	handleIsQuestionMandatory(e, question, questionIndex, section, sectionIndex)
																}
																inputProps={{ 'aria-label': 'controlled' }}
															/>
														</Box>
													</Grid>
													<Grid item>{t('isQuesMandatory')}</Grid>
												</Grid>
											</Box>
											<Box className={'col'}>
												{/* ${!question?.isQuestionsMandatory ? 'disable-radio' : ''} */}
												<Grid component='label' container>
													<Grid item>
														<Box>
															<Switch
																// id='addCommentBox'
																// name='addCommentBox'
																// disabled={!question?.isQuestionsMandatory}
																checked={question?.isCommentBoxAvailable}
																onChange={(e) =>
																	handleIsCommentBoxAvailable(e, question, questionIndex, section, sectionIndex)
																}
																inputProps={{ 'aria-label': 'controlled' }}
															/>
														</Box>
													</Grid>
													<Grid item>{t('addCommentBox')}</Grid>
												</Grid>
											</Box>
											<Box
												className={`col ${
													question?.isCommentBoxAvailable && question?.isQuestionsMandatory ? '' : 'disable-radio'
												}`}
											>
												<Grid component='label' container>
													<Grid item>
														<Box>
															<Switch
																// id='isCommentMandatory'
																// name='isCommentMandatory'
																disabled={
																	question?.isCommentBoxAvailable && question?.isQuestionsMandatory ? false : true
																}
																checked={question?.isCommentBoxMandatory}
																onChange={(e) =>
																	handleIsCommentBoxMandatory(e, question, questionIndex, section, sectionIndex)
																}
																inputProps={{ 'aria-label': 'controlled' }}
															/>
														</Box>
													</Grid>
													<Grid item>{t('isCommentMandatory')}</Grid>
												</Grid>
											</Box>
										</Box>
									</>
								) : (
									<></>
								)}
							</>
						)}
					</>
				) : (
					<></>
				)}
			</Box>
		</>
	);
};
