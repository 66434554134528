import { deleteTaskAPI, updateTaskAPI } from '../services/OkrService';
import {
	completedOneOneOneTaskAPI,
	createNotesAPI,
	createOneOneRequestAPI,
	createTaskAPI,
	deleteOneOneRequestAPI,
	getAllMomDetailsAPI,
	getNotesListAPI,
	getOneOnOneListAPI,
	getOneOnOneTeamDetailsAPI,
	getTasksListAPI,
	launchDateOneOnOneRequestAPI,
	launchOneOnOneDataAPI,
	launchOneOnOneRequestAPI,
	updateNotesAPI,
	updateOneOneRequestAPI,
	getDownloadMeetingNotesAPI,
} from '../services/oneOnOneRequestService';
import {
	GET_ONE_ON_ONE_REQUEST_LIST_PENDING,
	PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED,
	PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS,
} from './actionTypes';

const getOneOnOneListCompleted = (payload) => ({
	type: PERFORMANCE_REVIEW_CORE_VALUE_LIST_SUCCESS,
	payload,
});
const getOneOnOneListFailed = (error) => ({
	type: PERFORMANCE_REVIEW_CORE_VALUE_LIST_FAILED,
	payload: error,
});
const getOneOnOneListPending = (error) => ({
	type: GET_ONE_ON_ONE_REQUEST_LIST_PENDING,
	payload: true,
});

export const getOneOnOneList = (data) => {
	return (dispatch) => {
		dispatch(getOneOnOneListPending());
		return getOneOnOneListAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(getOneOnOneListCompleted(response?.data));
				} else {
					dispatch(getOneOnOneListFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(getOneOnOneListFailed(error));
			});
	};
};
/**
 *
 */
export const getAllMomDetails = (data) => {
	return () => {
		return getAllMomDetailsAPI(data);
	};
};

export const getOneOnOneListDirect = (data) => {
	return () => {
		return getOneOnOneListAPI(data);
	};
};

export const createOneOneRequest = (data) => {
	return () => {
		return createOneOneRequestAPI(data);
	};
};

export const updateOneOneRequest = (data) => {
	return () => {
		return updateOneOneRequestAPI(data);
	};
};

export const deleteOneOneRequest = (data) => {
	return () => {
		return deleteOneOneRequestAPI(data);
	};
};

export const getOneOnOneTeamDetails = (data) => {
	return () => {
		return getOneOnOneTeamDetailsAPI(data);
	};
};

export const launchOneOnOneRequest = (data) => {
	return () => {
		return launchOneOnOneRequestAPI(data);
	};
};

export const launchOneOnOneData = (data) => {
	return () => {
		return launchOneOnOneDataAPI(data);
	};
};

export const launchDateOneOnOneRequest = (data) => {
	return () => {
		return launchDateOneOnOneRequestAPI(data);
	};
};

export const createNotesForOneOnOne = (data) => {
	return () => {
		return createNotesAPI(data);
	};
};

export const updatedNotesForOneOnOne = (data) => {
	return () => {
		return updateNotesAPI(data);
	};
};

export const getNotesList = (data) => {
	return () => {
		return getNotesListAPI(data);
	};
};

export const getTasksList = (data) => {
	return () => {
		return getTasksListAPI(data);
	};
};

export const createTaskForOneOnOne = (data) => {
	return () => {
		return createTaskAPI(data);
	};
};

export const updatedTaskForOneOnOne = (data) => {
	return () => {
		return updateTaskAPI(data);
	};
};

export const deleteTaskForOneOnOne = (data) => {
	return () => {
		return deleteTaskAPI(data);
	};
};

export const completedOneOneOneTask = (data) => {
	return () => {
		return completedOneOneOneTaskAPI(data);
	};
};
export const getMeetingNotes = (data) => {
	return () => {
		return getDownloadMeetingNotesAPI(data);
	};
};
