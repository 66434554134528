import React, { useState } from 'react';
import { Box, ClickAwayListener, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import { DownArrowIcon, SortingUpArrow, SortingDownArrow, UpArrowIcon } from '../../../config/svg/ArrowSvg';
import { SortingButtonIcon } from '../../../config/svg/CommonSvgIcon';

export const TaskSorting: React.FC<any> = (props: any) => {
	const { filterDetails, handleSortingFilterClick } = props;
	const { t } = useTranslation();
	const [openSorting, setOpenSorting] = React.useState<Boolean>(false);

	const handleClick = () => {
		setOpenSorting((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpenSorting(false);
	};

	return (
		<Box className={`okr-team-dropdown sorting-dropdown ${openSorting ? 'active' : ''} `}>
			<OKRButton
				className={`task-sorting-btn ${openSorting ? 'active' : ''}`}
				icon={<SortingButtonIcon />}
				text={
					<span className='filter-txt'>
						{t('sortingLabel')}
						{openSorting ? <UpArrowIcon /> : <DownArrowIcon />}
					</span>
				}
				handleClick={handleClick}
				id='task-sorting-btn'
			/>
			{openSorting && (
				<ClickAwayListener onClickAway={handleClickAway}>
					<Box className='task-sorting-list'>
						<Box className='task-sorting-list-inner'>
							<List component='div' disablePadding>
								<ListItem
									component='div'
									className={`sorting-due-on ${
										filterDetails?.sortingText === '' || filterDetails?.sortingText === 'dueDate' ? 'active' : ''
									}`}
									onClick={(e) => {
										handleClickAway();
										handleSortingFilterClick(
											'dueDate',
											filterDetails?.order === 'asc' || filterDetails?.order === '' ? 'desc' : 'asc'
										);
									}}
								>
									<ListItemText primary={t('dueOn')} />
									<ListItemIcon>
										{filterDetails?.sortingText === '' || filterDetails?.sortingText === 'dueDate' ? (
											<>
												{filterDetails?.order === 'asc' || filterDetails?.order === '' ? (
													<SortingUpArrow />
												) : (
													<SortingDownArrow />
												)}
											</>
										) : (
											<SortingUpArrow />
										)}
									</ListItemIcon>
								</ListItem>
								<ListItem
									component='div'
									className={`sorting-created-on ${filterDetails?.sortingText === 'createdOn' ? 'active' : ''}`}
									onClick={(e) => {
										handleClickAway();
										handleSortingFilterClick(
											'createdOn',
											filterDetails?.order === 'asc' || filterDetails?.order === '' ? 'desc' : 'asc'
										);
									}}
								>
									<ListItemText primary={t('createOnLabel')} />
									<ListItemIcon>
										{filterDetails?.sortingText === 'createdOn' ? (
											<>
												{filterDetails?.order === 'asc' || filterDetails?.order === '' ? (
													<SortingUpArrow />
												) : (
													<SortingDownArrow />
												)}
											</>
										) : (
											<SortingUpArrow />
										)}
									</ListItemIcon>
								</ListItem>
							</List>
						</Box>
					</Box>
				</ClickAwayListener>
			)}
		</Box>
	);
};
