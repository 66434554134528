import React from 'react';
import { Avatar } from '@material-ui/core';
import { getUserName } from '../../../config/utils';

export const AvatarUser: React.FC<any> = (props) => {
	const { userDetail, className } = props;
	return Boolean(userDetail) && userDetail && (userDetail.imagePath || userDetail.image) ? (
		<Avatar
			className={`avatar-default ${className }`}
			key={`user-icon-${userDetail.employeeId}`}
			alt={`${userDetail.firstName} ${userDetail.lastName}`}
			src={userDetail.imagePath || userDetail.image}
		/>
	) : (
		<Avatar className='avatar-default'>
			{getUserName({
				firstName: userDetail.firstName || '',
				lastName: userDetail.lastName || '',
				fullName: userDetail.fullName || '',
			})}
		</Avatar>
	);
};
