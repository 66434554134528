import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
	getObjectiveList,
	resetOkr,
	addMyGoals,
	updateKrProgress,
	deleteObjective,
	getAlignObjective,
	editMyGoals,
	updateStatus,
	deleteContributor,
	unlockRequest,
	alignMyGoals,
	getOkrStatusList,
	getEmployeeViewGoals,
	getDashboardDetail,
	getDeltaScore,
	getDashboardUpdatedDetails,
	checkAlignStatus,
	downloadPdf,
	updateGoalDetails,
	getGoalsDetails,
	updateSequence,
	getOkrMasterData,
	addUpdateObjective,
	getKrContributors,
	updateGoalAndKrName,
	updateGoalAttributes,
	updateKrAttributes,
	acceptKr,
	updateKrProgressValue,
	becomeContributor,
	fetchObjectiveList,
	getKrDetail,
	getOkrDetail,
	getUserTeamsData,
	getOwnerTeamsData,
	getMyTeamDetails,
	updateNudgeTeams,
	getMyTeamOKRDetails,
	resetObjective,
	getLinkOwnerData,
	updateNudgePerson,
	getLinkObjectiveData,
	changeOwnerData,
	getRecentContributordDetail,
	getDashboardProgress,
	getTasks,
	resetTasks,
	postProgressConversation,
	getNoteById,
	getConversationById,
	isCheckinSubmitted,
	getCheckInWeeklyDates,
	getCheckInData,
	updateCheckInData,
	getUserDirectEmployees,
	updateConfidenceData,
	getWeeklyTrendDetails,
	getConfidenceAlertDetails,
	peopleViewFeedback,
	fetchFeedbackReport,
	getOkrImport,
	getOkrImportCategory,
	postOkrImport,
	postMyOkrImport,
	checkInImport,
	underAssign,
} from '../../action/myGoal';
import { fetchFeedback } from '../../action/feedback';
import { addAlignmentFilter, updateObjetciveDueDate } from '../../action/alignment';
import { showNotification, updateDashboardProgress, isAnyDrawerOpened } from '../../action/signalR';
import { Goals } from '../../components/Goals';

import { searchEmployee, globalSearchAPIWithTeam, searchTeamEmpAPI } from '../../action/search';
import {
	updatePage,
	getPreviousCycleDetails,
	updateRoute,
	updatePrevRoute,
	showLoader,
	updateGoalType,
	getUserById,
	getCycleDetails,
} from '../../action/common';
import {
	giveFeedback,
	askFeedback,
	resetFeedback,
	requestOneToOneFeedback,
	commentFeedback,
} from '../../action/feedback';
import {
	requestOneToOnePersonalFeedback,
	askDetailsPersonalFeedback,
	askPersonalFeedback,
	giveDetailsPersonalFeedback,
	giveUpdatePersonalFeedback,
	getCriteriaMaster,
	cancelRequestPersonalFeedback,
	requestAgainPersonalFeedback,
	viewPersonalFeedback,
} from '../../action/personalFeedback';
import {
	getOnboarding,
	updateOnboarding,
	updateOnboardingCount,
	getOnboardingCount,
	updateCalloutPanel,
} from '../../action/onboarding';

import { fetchDirectsReportsMap, resetAlignment } from '../../action/alignment';
import {
	getNotes,
	addNotes,
	deleteNotes,
	UpdateNotes,
	resetCount,
	getConversation,
	addConversation,
	deleteConversation,
	UpdateConversation,
	resetConversationCount,
	likeConversation,
	resetConversation,
	resetNotes,
} from '../../action/cfr';
import { getUserDetails } from '../../action/users';
import { getOrgList, fetchOrganizationsList, updatedOrganizationList } from '../../action/organization';

const mapStateToProps = (state) => {
	return {
		globalSearchPage: state.commonReducer.globalSearchPage,

		listOkrPending: state.okrReducer.listOkrPending,
		listOkrResult: state.okrReducer.listOkrResult,
		listOkrSuccess: state.okrReducer.listOkrSuccess,
		listOkrError: state.okrReducer.listOkrError,

		listAlignOkrPending: state.okrReducer.listAlignOkrPending,
		listAlignOkrResult: state.okrReducer.listAlignOkrResult,
		listAlignOkrSuccess: state.okrReducer.listAlignOkrSuccess,
		listAlignOkrError: state.okrReducer.listAlignOkrError,

		fetchFeedbackPending: state.feedbackReducer.fetchFeedbackPending,
		fetchFeedbackSuccess: state.feedbackReducer.fetchFeedbackSuccess,
		fetchFeedbackResult: state.feedbackReducer.fetchFeedbackResult,
		fetchFeedbackError: state.feedbackReducer.fetchFeedbackError,

		searchResult: state.searchReducer.searchResult,
		giveFeedbackStatus: state.feedbackReducer.giveFeedbackStatus,
		askFeedbackStatus: state.feedbackReducer.askFeedbackStatus,
		feedbackOneOnOneStatus: state.feedbackReducer.feedback1To1Status,

		commentFeedbackStatus: state.feedbackReducer.commentFeedbackStatus,
		addGoalsStatus: state.okrReducer.addGoalsStatus,
		editGoalsStatus: state.okrReducer.editGoalsStatus,
		updateKrProgressStatus: state.okrReducer.updateKrProgressStatus,
		alignGoalsStatus: state.okrReducer.alignGoalsStatus,

		listOkrStatusSuccess: state.okrReducer.listOkrStatusSuccess,
		listOkrStatusResult: state.okrReducer.listOkrStatusResult,
		currentRoute: state.commonReducer.currentRoute,
		// new reducer state for okr master data
		okrMasterData: state.okrReducer.okrMasterData,
		okrMasterDataStatus: state.okrReducer.okrMasterDataStatus,
		okrType: state.commonReducer.okrType,
		recentContributorData: state.okrReducer.recentContributorResult,
		recentContibutorSuccess: state.okrReducer.recentContibutorSuccess,
		listOrgResult: state.organizationReducer.listOrgResult,
		onboardingStatus: state.onboardingReducer.onboardingStatus,
		onboardingResult: state.onboardingReducer.onboardingResult,
		onboardingCount: state.onboardingReducer.onboardingCount,
		activeOnboardingPanel: state.onboardingReducer.activeOnboardingPanel,
		dashboardCurrentTab: state.commonReducer.dashboardCurrentTab,

		dashboardProgressStatus: state.okrReducer.dashboardProgressStatus,
		dashboardProgressData: state.okrReducer.dashboardProgressData,

		getPermissionStatus: state.rolesReducer.getPermissionStatus,
		getPermissionResult: state.rolesReducer.getPermissionResult,
		tasks: state.okrReducer.tasks,
		taskStatus: state.okrReducer.taskStatus,
		notesList: state.cfrReducer.notesList,
		getNotesStatus: state.cfrReducer.getNotesStatus,
		conversationList: state.cfrReducer.conversationList,
		getConversationStatus: state.cfrReducer.getConversationStatus,
		checkInResult: state.okrReducer.checkInData,
		// notificationHighLight: state.signalRReducer.showNotification,
		isProgressUpdated: state.signalRReducer.updateProgress,
		appDrawerInfo: state.signalRReducer.appDrawerInfo,
		showRefreshOptionSnackBar: state.signalRReducer.showRefreshOptionSnackBar,
		userSelectedCycle: state.commonReducer.userSelectedCycle,
		cycleIdDetails: state.commonReducer.cycleIdDetails,
		confidenceAlertData: state.okrReducer.confidenceAlertData,
		okrImportData: state.okrReducer.okrImportData,
		okrImportDataStatus: state.okrReducer.okrImportDataStatus,
		okrImportCategoryData: state.okrReducer.okrImportCategoryData,
		okrImportCategoryDataStatus: state.okrReducer.okrImportCategoryDataStatus,
		myOkrImportData: state.okrReducer.myOkrImportData,
		myOkrImportDataStatus: state.okrReducer.myOkrImportDataStatus,
		peopleProgressFilters: state.commonReducer.peopleProgressFilters,
	};
};

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			getUserById,
			getObjectiveList,
			getAlignObjective,
			fetchFeedback,
			searchEmployee,
			globalSearchAPIWithTeam,
			searchTeamEmpAPI,
			giveFeedback,
			askFeedback,
			resetFeedback,
			requestOneToOneFeedback,
			resetOkr,
			commentFeedback,
			updatePage,
			addMyGoals,
			deleteObjective,
			updateKrProgress,
			editMyGoals,
			updateStatus,
			deleteContributor,
			getPreviousCycleDetails,
			unlockRequest,
			alignMyGoals,
			getOkrStatusList,
			getEmployeeViewGoals,
			updateRoute,
			getDashboardDetail,
			getDeltaScore,
			getDashboardUpdatedDetails,
			updatePrevRoute,
			checkAlignStatus,
			downloadPdf,
			updateGoalDetails,
			getGoalsDetails,
			requestOneToOnePersonalFeedback,
			askDetailsPersonalFeedback,
			askPersonalFeedback,
			giveDetailsPersonalFeedback,
			giveUpdatePersonalFeedback,
			getCriteriaMaster,
			cancelRequestPersonalFeedback,
			requestAgainPersonalFeedback,
			viewPersonalFeedback,
			updateSequence,
			getOkrMasterData, //new v2 action for okr master data
			addUpdateObjective, // V2 API for add edit objective
			updateGoalAndKrName,
			getKrContributors,
			updateGoalAttributes,
			updateKrAttributes,
			acceptKr,
			updateKrProgressValue,
			becomeContributor,
			fetchObjectiveList,
			getKrDetail,
			getOkrDetail,
			getUserTeamsData,
			getOwnerTeamsData,
			getMyTeamDetails,
			updateNudgeTeams,
			getMyTeamOKRDetails,
			showLoader,
			updateGoalType,
			resetObjective,
			fetchDirectsReportsMap,
			getLinkOwnerData,
			updateNudgePerson,
			getLinkObjectiveData,
			resetAlignment,
			changeOwnerData,
			addAlignmentFilter,
			getRecentContributordDetail,
			getOnboarding,
			updateOnboarding,
			updateOnboardingCount,
			getOnboardingCount,
			updateCalloutPanel,
			updateObjetciveDueDate,
			getDashboardProgress,
			getTasks,
			resetTasks,
			getNotes,
			resetCount,
			addNotes,
			deleteNotes,
			UpdateNotes,
			postProgressConversation,
			getNoteById,
			getConversationById,
			getConversation,
			addConversation,
			deleteConversation,
			UpdateConversation,
			resetConversationCount,
			likeConversation,
			getUserDetails,
			resetConversation,
			resetNotes,
			getOrgList,
			fetchOrganizationsList,
			updatedOrganizationList,
			isCheckinSubmitted,
			getCheckInWeeklyDates,
			getCheckInData,
			updateCheckInData,
			getUserDirectEmployees,
			showNotification,
			updateDashboardProgress,
			isAnyDrawerOpened,
			getCycleDetails,
			updateConfidenceData,
			getWeeklyTrendDetails,
			getConfidenceAlertDetails,
			peopleViewFeedback,
			fetchFeedbackReport,
			getOkrImport,
			getOkrImportCategory,
			postOkrImport,
			postMyOkrImport,
			checkInImport,
			underAssign,
		},
		dispatch
	);
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Goals));
