import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Chip, Link, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { AttachmentIcon, CalendarIcon, LoadMoreIcon } from '../../../../config/svg/CommonSvgIcon';
import { UserAvatarGroup } from '../../../Common/UserAvatarGroup';
import { OKRButton } from '../../../Common/OKRButton';
import { AlertInfoIcon, CommentIcon } from '../../../../config/svg/CommonSvg';
import { getDateMonthTime, getMonthDateYear, getMonthDateYearTime, getUserName } from '../../../../config/utils';
import { PipSkeleton } from './PipSkeleton';
import { Enums } from '../../../../config/enums';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import animatedTag from '../../../../images/tag_animation.svg';

export const PipBlock = (props: any) => {
	const { performanceData, handlePIPItemClick, showLoadMore, loadMore } = props;
	const { t } = useTranslation();
	const getReviewers = (reviewersList: any[]) => {
		let list = reviewersList && reviewersList.length > 0 ? reviewersList.slice(1) : [];
		return list;
	};
	return (
		<Box className='pip-list-area'>
			<Box className='pip-card-panel-list'>
				{performanceData && performanceData?.length ? (
					<>
						{performanceData?.map((item: any) => (
							<>
								<Box className={`pip-card-panel ${item?.isHighlighted ? 'highlighted-pip-item' : ''}`}>
									<Box className='pip-card-header' onClick={(event: any) => handlePIPItemClick(event, item)}>
										<Box
											className={`user-info v-center ${!item.nomineeDetails.isActive ? 'terminated-user-info' : ''}`}
										>
											<Box className='user-img'>
												<Avatar
													className='avatar-default'
													key={`user-icon-${item.employeeId}`}
													src={item.nomineeDetails.imagePath || ''}
												>
													{getUserName({
														firstName: item.nomineeDetails.firstName || '',
														lastName: item.nomineeDetails.lastName || '',
														fullName: item.nomineeDetails.fullName || '',
													})}
												</Avatar>
											</Box>
											<Box className='user-info-details'>
												<Typography variant='h4' className='font-weight-normal'>
													{item.nomineeDetails.fullName}
												</Typography>
											</Box>
										</Box>
										<Box className='pip-status'>
											{/* Draft = 1, Submitted = 2, Active = 3, SuccessfullyCleared = 4, Terminate = 5 */}
											{item?.performanceImprovementPlanStatusId === Enums.ONE ? (
												<Chip className='draft-chip' label={item?.performanceImprovementPlanStatus} />
											) : item?.performanceImprovementPlanStatusId === Enums.TWO ? (
												<Chip className='lagging-chip' label={item?.performanceImprovementPlanStatus} />
											) : item?.performanceImprovementPlanStatusId === Enums.THREE ? (
												<Chip className='lagging-chip' label={item?.performanceImprovementPlanStatus} />
											) : item?.performanceImprovementPlanStatusId === Enums.FOUR ? (
												<Box className='timeframe-animation'>
													<AnimatedIcon
														className=''
														width='480'
														type='image/svg+xml'
														alt={t('globalSearchNoRecord')}
														imagePath={animatedTag}
													/>
													<Chip className='on-track-chip' label={item?.performanceImprovementPlanStatus} />
												</Box>
											) : item?.performanceImprovementPlanStatusId === Enums.FIVE ? (
												<Chip className='at-risk-chip' label={item?.performanceImprovementPlanStatus} />
											) : (
												<Chip className='grey-chip' label={item?.performanceImprovementPlanStatus} />
											)}
										</Box>
									</Box>
									<Box className='pip-card-content' onClick={(event: any) => handlePIPItemClick(event, item)}>
										<Box className='pip-feedback-list'>
											<List disablePadding>
												<ListItem>
													{item.title ? (
														<Tooltip
															title={item.title}
															arrow
															placement='bottom-start'
															classes={{ popper: 'tooltip-pipfeedbacktitle' }}
														>
															<Typography variant='h4'>{item.title}</Typography>
														</Tooltip>
													) : (
														<Typography className='no-title' variant='h4'>
															{t('pipNoTitleAdded')}
														</Typography>
													)}
													{item?.timeframeStartDate && item?.timeframeEndDate ? (
														<Box className='feedback-date-range'>
															{item?.timeframeStartDate && item?.timeframeEndDate && item.isTimeFrameAlert ? (
																<Typography variant='h6' className='alert-field'>
																	<Tooltip title={t('timeFramePassed')} arrow classes={{ popper: 'tooltip-alert' }}>
																		<span className='alert-icon'>
																			<AlertInfoIcon />
																		</span>
																	</Tooltip>
																	<CalendarIcon />
																	{getMonthDateYear(item?.timeframeStartDate)} -{' '}
																	{getMonthDateYear(item?.timeframeEndDate)}
																</Typography>
															) : item?.timeframeStartDate && item?.timeframeEndDate ? (
																<Tooltip title={t('timeFrameText')} arrow>
																	<Typography variant='h6'>
																		<CalendarIcon />
																		{getMonthDateYear(item?.timeframeStartDate)} -{' '}
																		{getMonthDateYear(item?.timeframeEndDate)}
																	</Typography>
																</Tooltip>
															) : (
																<></>
															)}

															{item.timeFrameChangedCount > 0 && (
																<Tooltip
																	classes={{ popper: 'timeframe-tooltip' }}
																	title={
																		<>
																			{t('timeFrameChangedOn')}
																			<List>
																				{item?.performanceImprovementPlanTimeframes.map((extendedDate: any) => (
																					<>
																						<ListItem>
																							<span>
																								{extendedDate?.createdOn
																									? getDateMonthTime(extendedDate?.createdOn)
																									: ''}
																							</span>
																							<span>
																								{extendedDate?.extendTimeframeStartDate &&
																								extendedDate?.extendTimeframeEndDate ? (
																									`${getMonthDateYearTime(
																										extendedDate?.extendTimeframeStartDate
																									)} - ${getMonthDateYearTime(extendedDate?.extendTimeframeEndDate)}`
																								) : (
																									<></>
																								)}
																							</span>
																							<span>{extendedDate?.performanceImprovementPlanReasonName}</span>
																						</ListItem>
																					</>
																				))}
																				{/* <ListItem>
																		<span>30 May</span>
																		<span>May 30, 2024 - May 30, 2024</span>
																		<span>Extended</span>
																	</ListItem> */}
																			</List>
																		</>
																	}
																	arrow
																>
																	<Chip
																		className='grey-chip'
																		label={
																			item?.performanceImprovementPlanTimeframes.length === 1
																				? t('timeframeChanged')
																				: item?.performanceImprovementPlanTimeframes.length > 1
																				? 'Changed: ' + getMonthDateYearTime(item.extendTimeframeEndDate)
																				: ''
																		}
																	/>
																</Tooltip>
															)}
														</Box>
													) : (
														<Box className='feedback-date-range'>
															<Typography variant='h6'>{t('pipNoTimeframeSet')}</Typography>
														</Box>
													)}
												</ListItem>
											</List>
										</Box>
									</Box>
									<Box className='pip-card-footer'>
										<Typography
											variant='body2'
											className='font-weight-normal'
											onClick={(event: any) => handlePIPItemClick(event, item)}
										>
											{t('reviewersLabel')}
										</Typography>
										<Box className='pip-card-footer-content'>
											<Box className='pip-card-footer-left'>
												<Box className='pip-reviewers-list'>
													<Box className='review-others'>
														<UserAvatarGroup
															{...props}
															t={t}
															contributorClassName={'contributor-details-popover'}
															isButtonVisible={false}
															handleAddUserClick={() => {}}
															contributorDetailsType={true}
															isOnHoverVisible={true}
															loginUserAvatar={
																item.reviewerDetails && item.reviewerDetails.length > 0 ? item.reviewerDetails[0] : {}
															}
															contributorDetails={getReviewers(item.reviewerDetails)}
															isUserPlaceholder={true}
															subTitle={' '}
															helpText={' '}
															max={5}
															//isUserAvatarClick={type !== 'Add' && editableDetails ? true : false}
															//handleUserAvatarClick={handleUserAvatarClick}
															isDisabled={true}
															viewType='pipReviewers'
														/>
													</Box>
												</Box>
											</Box>
											<Box className='pip-card-footer-right' onClick={(event: any) => handlePIPItemClick(event, item)}>
												{item.isCommentAlert ? (
													<Box className='comment-badge'>
														<OKRButton
															icon={<CommentIcon />}
															text={item.commentCount || '0'}
															title={t('totalComments')}
															buttonType={'hasBadge'}
														/>
													</Box>
												) : (
													<OKRButton
														icon={<CommentIcon />}
														text={item.commentCount || '0'}
														title={t('totalComments')}
													/>
												)}
												<OKRButton
													icon={<AttachmentIcon />}
													text={item.attachmentCount || '0'}
													title={t('attachedFiles')}
												/>
											</Box>
										</Box>
									</Box>
								</Box>
							</>
						))}
						{performanceData?.length < 3 && <PipSkeleton />}
						{performanceData?.length < 2 && <PipSkeleton />}
					</>
				) : (
					<></>
				)}
			</Box>
			{showLoadMore && (
				<Box className='load-more-box'>
					<OKRButton className='load-more-btn' icon={<LoadMoreIcon />} text={t('loadMore')} handleClick={loadMore} />
				</Box>
			)}
		</Box>
	);
};
