import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControlLabel, FormLabel, List, ListItem, Switch, Tab, Tabs, Typography } from '@material-ui/core';
import LinearLoader from '../../../Common/Loader';
import { AlertInfoIcon } from '../../../../config/svg/CommonSvg';
import { AddIconSvg, DeleteIcon, PermissionIcon } from '../../../../config/svg/CommonSvgIcon';
import { PermissionSearch } from './PermissionSearch';
import {
	getCategoryPermissionSearch,
	getSystemPermissionAdminData,
	getSystemPermissionSearch,
	saveFeedbackPermission,
} from '../../../../action/adminSettings';
import { useDispatch } from 'react-redux';
import { JobLevelIcon } from '../../../../config/svg/formElementIcons';
import { OKRButton } from '../../../Common/OKRButton';
import { HeaderTooltip } from '../../../Reports/HeaderTooltip';
import { Enums } from '../../../../config/enums';
import { useSnackbar } from 'notistack';
import AlertDialog from '../../../Common/Dialog';

export const PermissionBox: React.FC<any> = (props: any) => {
	const {
		formElement,
		formIndex,
		setIsFormEdited,
		isFormEdited,
		setFocussed,
		focussed,
		defaultData,
		saveForm,
		formData,
		setFormData,
		hideCategory = false,
		callingTab,
		getData,
		tabSelected,
		permissionType,
		hideAllowToggle,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [modalProps, setModalProps] = useState<any>({
		open: false,
		data: {},
	});

	const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(getSystemPermissionSearch(searchStr, page, pageSize, 1));
	};

	const searchCategoryPermission = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(getCategoryPermissionSearch(searchStr, page, pageSize, 1));
	};

	const handleFieldChange = (index: number, indexChild: number, key: any, value: any, validateChange = false) => {
		let formDataCp = [...formData];
		if (
			validateChange === true &&
			((key === 'isGrantPermissionToEveryOne' &&
				value === true &&
				formDataCp[index][indexChild]['additionalPermissionGrantToMapping'] &&
				formDataCp[index][indexChild]['additionalPermissionGrantToMapping'].length > 0) ||
				(key === 'isAccessOnEveryOne' &&
					value === true &&
					formDataCp[index][indexChild]['additionalPermissionAccessOnMapping'] &&
					formDataCp[index][indexChild]['additionalPermissionAccessOnMapping'].length > 0))
		) {
			setModalProps({
				open: true,
				type: 'resetData',
				data: {
					index,
					indexChild,
					key,
					value,
				},
			});
			return false;
		} else {
			formDataCp[index][indexChild][key] = value;
			if (key === 'isAccessOnEveryOne' && value === true) {
				formDataCp[index][indexChild]['additionalPermissionAccessOnMapping'] = [];
				formDataCp[index][indexChild]['additionalPermissionAccessOnMappingError'] = '';
			} else if (key === 'isGrantPermissionToEveryOne' && value === true) {
				formDataCp[index][indexChild]['additionalPermissionGrantToMapping'] = [];
				formDataCp[index][indexChild]['additionalPermissionGrantToMappingError'] = '';
			}
			setIsFormEdited(true);
			setFormData(formDataCp);
		}
	};

	const addRolePermission = (index: number) => {
		let formDataCp = [...formData];
		let cpyData = { ...defaultData };
		if (permissionType) {
			cpyData.permissionType = permissionType;
		}
		formDataCp[index].push({ ...cpyData });
		setIsFormEdited(true);
		setFormData(formDataCp);
	};

	const onSelectUser = (data: any, index: number, indexChild: number, key: any) => {
		let formDataCp = [...formData];
		formDataCp[index][indexChild][key] = data;
		formDataCp[index][indexChild][key + 'Error'] = '';
		setFormData(formDataCp);
		setIsFormEdited(true);
		setFocussed(true);
	};

	const handleDelete = (index: number, indexChild: number) => {
		setModalProps({
			open: true,
			type: 'delete',
			data: { index, indexChild },
		});
	};
	const handleDialogCancelClick = (e: any, type: any) => {
		if (type === 1) {
			if (modalProps.type === 'delete') {
				deleteRolePermission(modalProps.data.index, modalProps.data.indexChild);
			} else if (modalProps.type === 'cancel') {
				setIsFormEdited(false);
				getData(modalProps.data.tabSelected);
			} else if (modalProps.type === 'resetData') {
				handleFieldChange(
					modalProps.data.index,
					modalProps.data.indexChild,
					modalProps.data.key,
					modalProps.data.value
				);
			}
		}
		setModalProps({
			open: false,
			data: {},
		});
	};
	const deleteRolePermission = (index: number, indexChild: number) => {
		let formDataCp = [...formData];
		formDataCp[index].splice(indexChild, 1);
		setIsFormEdited(true);
		setFormData(formDataCp);
	};
	const cancelForm = () => {
		if (isFormEdited) {
			setModalProps({
				open: true,
				type: 'cancel',
				data: {
					tabSelected: tabSelected,
				},
			});
		} else {
			getData(tabSelected);
		}
	};
	return (
		<>
			<Box className='additional-permissions-box full-rectangular-card'>
				{formElement.map((element: any, index: number) => {
					return (
						<>
							<Box className={`${index + 1 !== 1 ? 'additional-permissions-box-divider' : ''}`}>
								<Box className='permission-section-title'>
									<Box className='permission-section-title-left'>
										<Typography variant='subtitle2' className='permisssions-count-txt'>
											Permission {index + 1} of {formElement.length}
										</Typography>
										<Typography variant='h4' className='font-weight-normal'>
											{formIndex === 0 ? t('readOnlyText') : t('fullAccessText')}
										</Typography>
										{formIndex === 0 ? (
											<Typography>
												{callingTab === 'goalSetting'
													? t('goalsPermissionSubText')
													: callingTab === 'feedback'
													? t('feedbackPermissionsSubText')
													: callingTab === 'pipPermission'
													? t('pipPermissionSubText')
													: callingTab === 'oneOnOnePermission'
													? t('oneOnOnePermissionSubText')
													: callingTab === 'goalReview'
													? t('goalReviewSubText')
													: ''}
											</Typography>
										) : (
											<Typography>
												{callingTab === 'feedback'
													? t('feedbackFullAccessPermissionSubText')
													: callingTab === 'pipPermission'
													? t('pipFullAccessPermissionSubText')
													: callingTab === 'oneOnOnePermission'
													? t('oneOnOneFullAccessPermissionSubText')
													: ''}
											</Typography>
										)}
									</Box>
									<Box className='permission-section-title-right'>
										{index !== 0 ? (
											<OKRButton
												className='delete-btn'
												icon={<DeleteIcon />}
												title={t('delete')}
												handleClick={() => {
													handleDelete(formIndex, index);
												}}
											/>
										) : (
											''
										)}
									</Box>
								</Box>
								<Box className='permissions-section-fields'>
									<Box className='col-full'>
										<Box className='permission-form-field col-half'>
											<Box className='permissions-form-field-top'>
												<Box className='permissions-form-field-title'>
													<FormLabel id='grant-permissions-to' className='form-label-required' component='legend'>
														<PermissionIcon />
														{t('grantPermissionsTo')}
														<i style={{ color: 'red' }}>*</i>
													</FormLabel>
													<HeaderTooltip
														infoTooltipClass='permission-info-tooltip'
														tooltipText={t('grantPermissionInfoText')}
													/>
												</Box>
												<Box className='everyone-switch'>
													<FormControlLabel
														control={
															<Switch
																name='isGrantPermissionToEveryOne'
																checked={element['isGrantPermissionToEveryOne']}
																onChange={(e) => {
																	const { checked } = e?.target || {};
																	handleFieldChange(
																		formIndex,
																		index,
																		'isGrantPermissionToEveryOne',
																		checked || false,
																		true
																	);
																}}
															/>
														}
														label={t('everyOneLabel')}
													/>
												</Box>
											</Box>
											<PermissionSearch
												{...props}
												searchCustomEmployee={searchCustomEmployee}
												selectedUser={element['additionalPermissionGrantToMapping']}
												setSelectedUser={(value: any) => {
													onSelectUser(value, formIndex, index, 'additionalPermissionGrantToMapping');
												}}
												elementKey={'additionalPermissionGrantToMappingError'}
												formErrors={element}
												onInputFocus={() => {}}
												fieldLabel={'fullName'}
												focussed={focussed}
												disabled={
													element['isGrantPermissionToEveryOne'] && element['isGrantPermissionToEveryOne'] === true
														? true
														: false
												}
											/>
										</Box>
										<Box className='permission-form-field col-half'>
											<Box className='permissions-form-field-top'>
												<Box className='permissions-form-field-title'>
													<FormLabel id='grant-permissions-to' className='form-label-required' component='legend'>
														<JobLevelIcon />
														{t('accessOn')}
														<i style={{ color: 'red' }}>*</i>
													</FormLabel>
													<HeaderTooltip
														infoTooltipClass='permission-info-tooltip'
														tooltipText={t('accessOnPermissionInfoText')}
													/>
												</Box>
												<Box className='everyone-switch'>
													<FormControlLabel
														control={
															<Switch
																name='isAccessOnEveryOne'
																checked={element['isAccessOnEveryOne']}
																onChange={(e) => {
																	const { checked } = e?.target || {};
																	handleFieldChange(formIndex, index, 'isAccessOnEveryOne', checked || false, true);
																}}
																//onChange={(e) => handleStatusChange(e, item, itemIndex, feedbackStatusDetails)}
															/>
														}
														label={t('everyOneLabel')}
													/>
												</Box>
											</Box>
											<PermissionSearch
												{...props}
												disabled={
													element['isAccessOnEveryOne'] && element['isAccessOnEveryOne'] === true ? true : false
												}
												searchCustomEmployee={searchCustomEmployee}
												selectedUser={element['additionalPermissionAccessOnMapping']}
												setSelectedUser={(value: any) => {
													onSelectUser(value, formIndex, index, 'additionalPermissionAccessOnMapping');
												}}
												fieldLabel={'fullName'}
												elementKey={'additionalPermissionAccessOnMappingError'}
												formErrors={element}
												onInputFocus={() => {}}
												focussed={focussed}
											/>
										</Box>
									</Box>
									<Box className='col-full next-row'>
										{!hideCategory ? (
											<>
												<Box className='permission-form-field col-half'>
													<Box className='permissions-form-field-top'>
														<Box className='permissions-form-field-title'>
															<FormLabel id='grant-permissions-to' className='form-label-required' component='legend'>
																<PermissionIcon />
																{t('categoriesLabel')}
																<i style={{ color: 'red' }}>*</i>
															</FormLabel>
														</Box>
													</Box>

													<PermissionSearch
														{...props}
														customSearch={true}
														searchCustomEmployee={searchCategoryPermission}
														selectedUser={element['additionalPermissionCategoriesMapping']}
														setSelectedUser={(value: any) => {
															onSelectUser(value, formIndex, index, 'additionalPermissionCategoriesMapping');
														}}
														onInputFocus={() => {
															searchCategoryPermission('finder=&pageIndex=1&pageSize=10', 1, 10);
														}}
														elementKey={'additionalPermissionCategoriesMappingError'}
														fieldLabel={'name'}
														fieldId={'feedbackCategoryId'}
														formErrors={element}
														focussed={focussed}
														performSearchOnFocus={true}
													/>
												</Box>
												<Box className='permission-form-field col-half switch-col'>
													<Box className='everyone-switch'>
														<FormControlLabel
															control={
																<Switch
																	name='everyonePermission'
																	checked={element['everyonePermission']}
																	onChange={(e) => {
																		const { checked } = e?.target || {};
																		handleFieldChange(formIndex, index, 'everyonePermission', checked || false);
																	}}
																	//onChange={(e) => handleStatusChange(e, item, itemIndex, feedbackStatusDetails)}
																/>
															}
															label={t('allowToReviewComments')}
														/>
													</Box>
												</Box>
											</>
										) : !hideAllowToggle ? (
											callingTab === 'oneOnOnePermission' ? (
												<Box className='permission-form-field col-half'>
													<Box className='everyone-switch'>
														<FormControlLabel
															control={
																<Switch
																	name='everyonePermission'
																	checked={element['everyonePermission']}
																	onChange={(e) => {
																		const { checked } = e?.target || {};
																		handleFieldChange(formIndex, index, 'everyonePermission', checked || false);
																	}}
																	//onChange={(e) => handleStatusChange(e, item, itemIndex, feedbackStatusDetails)}
																/>
															}
															label={t('allowToAddTask')}
														/>
													</Box>
												</Box>
											) : (
												<Box className='permission-form-field col-half'>
													<Box className='everyone-switch'>
														<FormControlLabel
															control={
																<Switch
																	name='isAllowToAddComment'
																	checked={element['isAllowToAddComment']}
																	onChange={(e) => {
																		const { checked } = e?.target || {};
																		handleFieldChange(formIndex, index, 'isAllowToAddComment', checked || false);
																	}}
																	//onChange={(e) => handleStatusChange(e, item, itemIndex, feedbackStatusDetails)}
																/>
															}
															label={t('allowToAddComments')}
														/>
													</Box>
												</Box>
											)
										) : (
											<></>
										)}
									</Box>
								</Box>
							</Box>
						</>
					);
				})}
				<Box className='permission-actions'>
					<Box className='permission-actions-left'>
						<OKRButton
							handleClick={() => {
								addRolePermission(formIndex);
							}}
							className='btn-add-key'
							icon={<AddIconSvg />}
							text={t('addPermissionLabel')}
							id={'add-goal-btn'}
						/>
					</Box>
					<Box className='permission-actions-right'>
						<OKRButton
							className='btn-link'
							text={t('cancelBtn')}
							handleClick={() => {
								cancelForm();
							}}
						/>
						<OKRButton
							className='btn-primary'
							id='save-btn'
							text={t('submit')}
							handleClick={() => {
								saveForm(formIndex);
							}}
						/>
					</Box>
				</Box>
			</Box>
			{modalProps.open && (
				<AlertDialog
					module='information'
					message={modalProps?.type === 'delete' ? t('deleteRatingScale') : t('unSavedItemAlertTwo')}
					handleCloseModal={handleDialogCancelClick}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
