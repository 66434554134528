import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	Checkbox,
	FormControlLabel,
	FormLabel,
	InputAdornment,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, NameRenameIcon } from '../../../../../config/svg/CommonSvgIcon';
import { MultiSelect } from '../../../CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../../CommonComponent/NoSearchRecord';
import {
	BorderCheckboxIcon,
	CheckedIcon,
	EmailTriggerIcon,
	ErrorIcon,
	TeamsIcon,
	TimerIcon,
} from '../../../../../config/svg/formElementIcons';
import { OKRButton } from '../../../../Common/OKRButton';
import { DatePickerField } from '../../../CommonComponent/DatePickerField';
import { Enums } from '../../../../../config/enums';
import { MeetingDays } from '../../../../../config/svg/CommonSvg';

export const Align: React.FC<any> = (props: any) => {
	const {
		getCyclesData,
		cycleMasterData,
		setShowLoader,
		setOrgOriginalData,
		emailPlanningPeriodError,
		emailExecutionPeriodError,
		zeroNotAllowed,
		parentOrganisation,
		handleAlignSettingsSave,
		handleAlignSettingReset,
		onCycleSelect,
		selectedCycle,
		setSelectedCycle,
		cycleList,
		setCycleList,
		selectedDate,
		setDate,
		handleOrganisationIndustryChange,
		handleOrganisationFieldsChange,
		handleOrganisationFieldsBlur,
		handleOrganisationToggleChange,
		defaultOkrTagNameError,
		setDefaultOkrTagNameError,
		handleDefaultOkrTagNameChange,
		organisationDetails,
		cycleCompletionPeriodError,
	} = props;
	const { t } = useTranslation();
	// const [organisationDetails, setOrganisationDetails] = useState<any>({});

	// useEffect(() => {
	// 	props.getParentOrganization();
	// }, []);

	// useEffect(() => {
	// 	if (!cycleMasterData.length) {
	// 		setShowLoader(false);
	// 		getCyclesData();
	// 	} else {
	// 		setShowLoader(false);
	// 	}
	// }, []);

	// useEffect(() => {
	// 	if (parentOrganisation && parentOrganisation.teamId) {
	// 		setOrganisationDetails(parentOrganisation);
	// 		setOrgOriginalData(parentOrganisation);
	// 		setDate(parentOrganisation.cycleStartDate);
	// 	}
	// }, [parentOrganisation]);

	// useEffect(() => {
	// 	if (cycleMasterData.length && parentOrganisation.teamId) {
	// 		setShowLoader(false);
	// 		let cycles = cycleMasterData.map((cycle: any) => {
	// 			return {
	// 				...cycle,
	// 				label: cycle.cycleName,
	// 				value: cycle.cycleId,
	// 			};
	// 		});
	// 		let currentCycle = cycles.filter((item: any) => item.cycleId === parentOrganisation.cycleId);
	// 		setCycleList(cycles);
	// 		setSelectedCycle(currentCycle[0]);
	// 	}
	// }, [cycleMasterData, parentOrganisation]);

	const getRemainingCharacter = (currentValue = '', maxLimit = 0) => {
		const remaining = Math.max(maxLimit - currentValue.length, 0);

		return remaining;
	};

	return (
		<>
			<Box className='align-setting-card full-rectangular-card'>
				<Box className='so-setting-head'>
					<Box>
						<Typography variant='h4' className='font-weight-normal'>
							{t('generalSettings')}
						</Typography>
					</Box>
				</Box>
				<Box className='so-setting-card-row'>
					<Box className='so-setting-card-col'>
						<Box className='settings-fields settings-fields-col3'>
							<Box className='settings-fields-col'>
								<FormLabel id='set-a-duration' component='legend' className='disable-label'>
									<CalendarIcon />
									{t('timeFrameLabel')}
								</FormLabel>
								<MultiSelect
									key={'alignCycleDropdown'}
									disabled={true}
									id='align-cycle-dropdown'
									selectedOptions={selectedCycle}
									optionsList={cycleList}
									onSelectOption={(value: any) => {
										onCycleSelect(value);
									}}
									customFilter={(option: any, searchText: any) => {
										if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
											return true;
										}
									}}
									placeHolder={t('timeFramePlaceholdar')}
									noOptionsMessage={<NoSearchRecord />}
									fetchAsyncData={false}
									isSingleSelection={false}
									performSearch={() => {}}
									closeMenuOnSelect={true}
									labelTemplate={'onlyLabel'}
									isMulti={false}
									selectClassName={`dropdown-default-wrapper`}
									selectClassNamePrefix={'dropdown-default'}
									disableSearch={true}
								/>
							</Box>
							<Box className='settings-fields-col'>
								{/* <FormLabel id='set-a-duration' component='legend'>
											<CalendarIcon />
											{t('okrStartFrom')}
										</FormLabel>
										<TextField /> */}
								<DatePickerField {...props} selectedDate={selectedDate} label={t('okrStartFrom')} disabled={true} />
							</Box>
							<Box className='settings-fields-col'>
								<TextField
									id='industryType'
									className=''
									fullWidth
									label={
										<Fragment>
											<TeamsIcon />
											{t('industryLabel')}
										</Fragment>
									}
									placeholder={t('industryLabelPlaceholdar')}
									value={organisationDetails.industryType}
									name='industryType'
									InputLabelProps={{
										shrink: true,
									}}
									type='string'
									onChange={(e) => {
										handleOrganisationIndustryChange(e);
									}}
								/>
							</Box>
						</Box>
						<Box className='settings-fields'>
							<Box className='settings-fields-col'>
								<TextField
									id='planningPeriod'
									className=''
									fullWidth
									label={
										<Fragment>
											<TimerIcon />
											{t('planningPeriodLockDate')}
										</Fragment>
									}
									placeholder={t('planningPeriodPlaceholdar')}
									value={organisationDetails.planningPeriod}
									name='planningPeriod'
									InputLabelProps={{
										shrink: true,
									}}
									type='number'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{
													<>
														{t('daysLabel')}
														{/* <span className='days-short-text'>{t('max90Days')}</span> */}
													</>
												}
											</InputAdornment>
										),
									}}
									onChange={(e) => {
										let value = parseInt(e.target.value) || 0;
										value >= 0 && value <= Enums.GRACE_PERIOD && handleOrganisationFieldsChange(e); //
									}}
									onBlur={(e) => {
										handleOrganisationFieldsBlur(e);
									}}
								/>
								<Typography variant='h6' className='grace-period-notes'>
									{t('planningPeriodNotes')}
								</Typography>
							</Box>
							<Box className='settings-fields-col'>
								<TextField
									id='gracePeriod'
									className=''
									fullWidth
									label={
										<Fragment>
											<TimerIcon />
											{t('gracePeriodLabel')}
										</Fragment>
									}
									placeholder={t('gracePeriodPlaceholdar')}
									value={organisationDetails.gracePeriod}
									name='gracePeriod'
									InputLabelProps={{
										shrink: true,
									}}
									type='number'
									InputProps={{
										endAdornment: (
											<InputAdornment position='end'>
												{
													<>
														{t('daysLabel')}
														{/* <span className='days-short-text'>{t('max90Days')}</span> */}
													</>
												}
											</InputAdornment>
										),
									}}
									onChange={(e) => {
										let value = parseInt(e.target.value) || 0;
										value >= 0 && value <= Enums.GRACE_PERIOD && handleOrganisationFieldsChange(e);
									}}
									onBlur={(e) => {
										handleOrganisationFieldsBlur(e);
									}}
								/>
								<Typography variant='h6' className='grace-period-notes'>
									{t('gracePeriodNotes')}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='align-setting-card email-and-other-setting-card'>
				<Box className='trigger-for-email-area full-rectangular-card'>
					<Box className='so-setting-head'>
						<Box>
							<Typography variant='h4' className='font-weight-normal'>
								{t('triggerForEmail')}
							</Typography>
						</Box>
					</Box>
					<Box className='trigger-for-email'>
						<Box className='setting-blue-box committed-okr-box'>
							<Box className='setting-blue-box-head'>
								<Box className='head-left'>
									{/* <EmailTriggerIcon /> */}
									{t('planningPeriod')}
								</Box>
								<FormControlLabel
									control={
										<Switch
											id='isEmailPlanningPeriod'
											name='isEmailPlanningPeriod'
											checked={Boolean(organisationDetails?.isEmailPlanningPeriod)}
											inputProps={{ 'aria-label': 'controlled' }}
											onChange={handleOrganisationToggleChange}
										/>
									}
									label={''}
									className='toggle-label toggle-no-label'
								/>
							</Box>

							<Box className='setting-user-action'>
								<Box className='setting-des perform-txt-field'>
									<TextField
										id='emailPlanningPeriod'
										fullWidth
										className='helperText-bottom'
										label={
											<>
												<MeetingDays /> {t('setDaysLabel')}
											</>
										}
										name='emailPlanningPeriod'
										placeholder={t('planningPeriodLabel')}
										value={organisationDetails?.emailPlanningPeriod || Enums.ZERO}
										InputLabelProps={{
											shrink: true,
										}}
										type='number'
										InputProps={{
											disabled: !organisationDetails?.isEmailPlanningPeriod,
											endAdornment: (
												<InputAdornment position='end'>
													<>
														<span className='days-short-text'>{t('max30Days')}</span>
													</>
												</InputAdornment>
											),
										}}
										onChange={(e) => {
											let value = parseInt(e.target.value) || Enums.ZERO;
											value >= Enums.ZERO &&
												value <= Enums.MAX_PERIOD_FOR_EMAIL_TRIGGER &&
												handleOrganisationFieldsChange(e);
										}}
										onBlur={(e) => {
											handleOrganisationFieldsBlur(e);
										}}
										error={
											(emailPlanningPeriodError?.type === 'emailPlanningPeriod' &&
												zeroNotAllowed === true &&
												emailPlanningPeriodError?.error) ||
											''
										}
										helperText={
											(emailPlanningPeriodError?.type === 'emailPlanningPeriod' &&
												zeroNotAllowed === true &&
												emailPlanningPeriodError?.helperText && (
													<>
														<ErrorIcon />
														{emailPlanningPeriodError?.helperText}
													</>
												)) ||
											''
										}
									/>
								</Box>
							</Box>
							<Box className='committed-okr-des-box'>
								<Typography variant='h6'>{t('planningPeriodEmailNotificationNote')}</Typography>
							</Box>
						</Box>
						<Box className='setting-blue-box committed-okr-box'>
							<Box className='setting-blue-box-head'>
								<Box className='head-left'>
									{/* <EmailTriggerIcon /> */}
									{t('executionPeriod')}
								</Box>
								<FormControlLabel
									control={
										<Switch
											id='isEmailExecutionPeriod'
											name='isEmailExecutionPeriod'
											checked={Boolean(organisationDetails?.isEmailExecutionPeriod)}
											onChange={handleOrganisationToggleChange}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									}
									label={''}
									className='toggle-label toggle-no-label'
								/>
							</Box>

							<Box className='setting-user-action'>
								<Box className='setting-des perform-txt-field'>
									<TextField
										id='emailExecutionPeriod'
										fullWidth
										className='helperText-bottom'
										label={
											<>
												<MeetingDays /> {t('setDaysLabel')}
											</>
										}
										placeholder={t('ExecutionPeriodLabel')}
										value={organisationDetails?.emailExecutionPeriod || Enums.ZERO}
										name='emailExecutionPeriod'
										InputLabelProps={{
											shrink: true,
										}}
										type='number'
										InputProps={{
											disabled: !organisationDetails?.isEmailExecutionPeriod,
											endAdornment: (
												<InputAdornment position='end'>
													<>
														<span className='days-short-text'>{t('max30Days')}</span>
													</>
												</InputAdornment>
											),
										}}
										onChange={(e) => {
											let value = parseInt(e.target.value) || Enums.ZERO;
											value >= Enums.ZERO &&
												value <= Enums.MAX_PERIOD_FOR_EMAIL_TRIGGER &&
												handleOrganisationFieldsChange(e);
										}}
										onBlur={(e) => {
											handleOrganisationFieldsBlur(e);
										}}
										error={
											(emailExecutionPeriodError?.type === 'emailExecutionPeriod' &&
												zeroNotAllowed === true &&
												emailExecutionPeriodError?.error) ||
											''
										}
										helperText={
											(emailExecutionPeriodError?.type === 'emailExecutionPeriod' &&
												zeroNotAllowed === true &&
												emailExecutionPeriodError?.helperText && (
													<>
														<ErrorIcon />
														{emailExecutionPeriodError?.helperText}
													</>
												)) ||
											''
										}
									/>
								</Box>
							</Box>
							<Box className='committed-okr-des-box'>
								<Typography variant='h6'>{t('executionPeriodEmailNotificationNote')}</Typography>
							</Box>
						</Box>
						<Box className='setting-blue-box committed-okr-box cycle-completion-box'>
							<Box className='setting-blue-box-head'>
								<Box className='head-left'>
									{/* <EmailTriggerIcon /> */}
									{t('cycleCompletion')}
								</Box>
								<FormControlLabel
									control={
										<Switch
											id='isCycleCompletion'
											name='isCycleCompletion'
											checked={Boolean(organisationDetails?.isCycleCompletion)}
											onChange={handleOrganisationToggleChange}
											inputProps={{ 'aria-label': 'controlled' }}
										/>
									}
									label={''}
									className='toggle-label toggle-no-label'
								/>
							</Box>

							<Box className='setting-user-action'>
								<Box className='setting-des perform-txt-field'>
									<TextField
										id='cycleCompletionPeriod'
										fullWidth
										className='helperText-bottom'
										label={
											<>
												<MeetingDays /> {t('Email triggers x days pre-cycle')}
											</>
										}
										placeholder={t('Email triggers x days pre-cycle')}
										value={organisationDetails?.cycleCompletionPeriod || ''}
										name='cycleCompletionPeriod'
										InputLabelProps={{
											shrink: true,
										}}
										InputProps={{
											disabled: !organisationDetails?.isCycleCompletion,
											// endAdornment: (
											// 	<InputAdornment position='end'>
											// 		<>
											// 			<span className='days-short-text'>{t('max30Days')}</span>
											// 		</>
											// 	</InputAdornment>
											// ),
										}}
										onChange={(e) => {
											// let value = parseInt(e.target.value) || Enums.ZERO;
											// value >= Enums.ZERO &&
											// 	value <= Enums.MAX_PERIOD_FOR_EMAIL_TRIGGER &&
											const value = e.target.value || '';
											const regex = /^[0-9,]+$/;
											if ((value && regex.test(value)) || !value) {
												handleOrganisationFieldsChange(e);
											}
										}}
										onBlur={(e) => {
											handleOrganisationFieldsBlur(e);
										}}
										error={
											(cycleCompletionPeriodError?.type === 'cycleCompletionPeriod' &&
												zeroNotAllowed === true &&
												cycleCompletionPeriodError?.error) ||
											''
										}
										helperText={
											(cycleCompletionPeriodError?.type === 'cycleCompletionPeriod' &&
												zeroNotAllowed === true &&
												cycleCompletionPeriodError?.helperText && (
													<>
														<ErrorIcon />
														{cycleCompletionPeriodError?.helperText}
													</>
												)) ||
											''
										}
									/>
								</Box>
							</Box>
							<Box className='committed-okr-des-box align-cycle-completion'>
								<FormControlLabel
									control={
										<Checkbox
											id='isAllConsecutiveDay'
											name='isAllConsecutiveDay'
											checked={Boolean(organisationDetails?.isAllConsecutiveDay)}
											onChange={handleOrganisationToggleChange}
											disabled={!organisationDetails?.isCycleCompletion}
											icon={<BorderCheckboxIcon />}
											checkedIcon={<CheckedIcon />}
										/>
									}
									label={t('Send daily emails before cycle ends')}
								/>
							</Box>
							<Box className='committed-okr-des-box'>
								<Typography variant='h6'>{t('cycleCompletionEmailNotificationNote')}</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
				{/*  */}
				<Box className='other-settings-area full-rectangular-card'>
					<Box className='so-setting-head'>
						<Box>
							<Typography variant='h4' className='font-weight-normal'>
								{'Other Setting'}
							</Typography>
						</Box>
					</Box>
					<Box className='trigger-for-email'>
						<Box className='setting-blue-box committed-okr-box'>
							<Box className='setting-blue-box-head'>
								<Box className='head-left'>
									{/* <NameRenameIcon /> */}
									{'OKRs by Admin'}
								</Box>
							</Box>

							<Box className='setting-user-action'>
								<Box className='setting-des perform-txt-field'>
									<TextField
										id='defaultOkrTagName'
										fullWidth
										className='helperText-bottom'
										label={
											<>
												<NameRenameIcon /> {'Name'}
											</>
										}
										name='defaultOkrTagName'
										placeholder={t('planningPeriodLabel')}
										value={organisationDetails?.defaultOkrTagName || ''}
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{ maxLength: 15 }}
										type='text'
										onChange={handleDefaultOkrTagNameChange}
										error={
											(defaultOkrTagNameError?.type === 'defaultOkrTagName' && defaultOkrTagNameError?.error) || false
										}
										helperText={
											(defaultOkrTagNameError?.type === 'defaultOkrTagName' &&
												defaultOkrTagNameError?.error &&
												defaultOkrTagNameError?.helperText && (
													<>
														<ErrorIcon />
														{defaultOkrTagNameError?.helperText}
													</>
												)) ||
											''
										}
									/>
								</Box>
							</Box>
							<Box className='committed-okr-des-box'>
								<Typography variant='h6'>{`Remaining character: ${getRemainingCharacter(
									organisationDetails?.defaultOkrTagName || '',
									15
								)}`}</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
			<Box className='so-setting-card-actions'>
				<Box className='so-setting-card-actions-left'></Box>
				<Box className='so-setting-card-actions-right'>
					<OKRButton className='btn-link' handleClick={handleAlignSettingReset} text={t('cancelBtn')} />
					<OKRButton className='btn-primary' handleClick={handleAlignSettingsSave} text={t('saveBtn')} />
				</Box>
			</Box>
		</>
	);
};
