import React from 'react';

export const HomeIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.0238 0.361115C14.5855 -0.120372 15.4145 -0.120372 15.9762 0.361115L29.127 11.6332C29.6811 12.1082 30 12.8016 30 13.5314V26.5714C30 28.465 28.465 30 26.5714 30H18.8571C18.0287 30 17.3571 29.3284 17.3571 28.5V22.7143C17.3571 21.4125 16.3018 20.3571 15 20.3571C13.6982 20.3571 12.6429 21.4125 12.6429 22.7143V28.5C12.6429 29.3284 11.9713 30 11.1429 30H3.42857C1.53502 30 0 28.465 0 26.5714V13.5314C0 12.8016 0.318904 12.1082 0.87302 11.6332L14.0238 0.361115ZM3 13.7613V26.5714C3 26.8081 3.19188 27 3.42857 27H9.64286V22.7143C9.64286 19.7556 12.0413 17.3571 15 17.3571C17.9587 17.3571 20.3571 19.7556 20.3571 22.7143V27H26.5714C26.8081 27 27 26.8081 27 26.5714V13.7613L15 3.47562L3 13.7613Z'
			fill='#292929'
		/>
	</svg>
);
export const MenuIcon = () => (
	<svg width='18' height='18' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.5 24C1.5 23.1716 2.17157 22.5 3 22.5H27C27.8284 22.5 28.5 23.1716 28.5 24C28.5 24.8284 27.8284 25.5 27 25.5H3C2.17157 25.5 1.5 24.8284 1.5 24Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.5 15C1.5 14.1716 2.17157 13.5 3 13.5H27C27.8284 13.5 28.5 14.1716 28.5 15C28.5 15.8284 27.8284 16.5 27 16.5H3C2.17157 16.5 1.5 15.8284 1.5 15Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.5 6C1.5 5.17157 2.17157 4.5 3 4.5H27C27.8284 4.5 28.5 5.17157 28.5 6C28.5 6.82843 27.8284 7.5 27 7.5H3C2.17157 7.5 1.5 6.82843 1.5 6Z'
			fill='#292929'
		/>
	</svg>
);

export const NotificationIcon = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M14.8712 3.375C9.74046 3.375 5.625 7.45736 5.625 12.4396V21.5042H24.1174V12.4396C24.1174 7.45736 20.0019 3.375 14.8712 3.375ZM25.4662 24.4967C25.5159 24.5017 25.5664 24.5042 25.6174 24.5042C26.4458 24.5042 27.1174 23.8326 27.1174 23.0042V12.4396C27.1174 5.75251 21.6104 0.375 14.8712 0.375C8.132 0.375 2.625 5.75251 2.625 12.4396V23.0042C2.625 23.8326 3.29657 24.5042 4.125 24.5042C4.17601 24.5042 4.22642 24.5017 4.27612 24.4967C4.32582 24.5017 4.37623 24.5042 4.42724 24.5042H9.10455C9.41137 27.401 11.897 29.625 14.8712 29.625C17.8454 29.625 20.331 27.401 20.6378 24.5042H25.3151C25.3661 24.5042 25.4165 24.5017 25.4662 24.4967ZM17.5999 24.5042H12.1424C12.4232 25.7044 13.521 26.625 14.8712 26.625C16.2213 26.625 17.3192 25.7044 17.5999 24.5042Z'
			fill='#292929'
		/>
	</svg>
);

export const HomeHoverIcon = () => (
	<svg width='37' height='30' viewBox='0 0 37 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M13 0C13.4238 0 13.8291 0.167438 14.122 0.463451L25.4622 11.9262C25.8074 12.2751 26 12.7392 26 13.2218V26.5714C26 28.1718 24.8476 30 22.8124 30H16.2708C15.4135 30 14.7185 29.3284 14.7185 28.5V22.7143C14.7185 21.1193 13.6744 20.3571 13 20.3571C12.3256 20.3571 11.2814 21.1193 11.2814 22.7143V28.5C11.2814 29.3284 10.5865 30 9.72921 30H3.18763C1.15242 30 0 28.1718 0 26.5714V13.2218C0 12.7392 0.192632 12.2751 0.537805 11.9262L11.878 0.463451C12.1709 0.167438 12.5762 0 13 0ZM3.10448 13.6731V26.5714C3.10448 26.778 3.17648 26.9191 3.23186 26.9844C3.23715 26.9906 3.24184 26.9958 3.24589 27H8.17697V22.7143C8.17697 20.0488 10.0616 17.3571 13 17.3571C15.9384 17.3571 17.823 20.0488 17.823 22.7143V27H22.7541C22.7582 26.9958 22.7628 26.9906 22.7681 26.9844C22.8235 26.9191 22.8955 26.778 22.8955 26.5714V13.6731L13 3.67066L3.10448 13.6731ZM22.7352 27.0168C22.7352 27.0167 22.736 27.0161 22.7376 27.0152C22.736 27.0164 22.7352 27.0168 22.7352 27.0168ZM3.26481 27.0168C3.26481 27.0168 3.26401 27.0164 3.26241 27.0152C3.26403 27.0161 3.26482 27.0167 3.26481 27.0168Z'
			fill='#292929'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.0557 1.49908C12.0557 0.67116 12.7268 0 13.5547 0H26.3027C26.891 0 27.4559 0.230562 27.8761 0.642231L35.6775 8.28369C36.1093 8.70665 36.3526 9.28563 36.3526 9.89008V27.7514C36.3526 28.9933 35.3459 30 34.104 30H20.4249C19.597 30 18.9258 29.3288 18.9258 28.5009C18.9258 27.673 19.597 27.0018 20.4249 27.0018H33.3545V10.2051L25.9967 2.99816H13.5547C12.7268 2.99816 12.0557 2.327 12.0557 1.49908Z'
			fill='#292929'
		/>
	</svg>
);
