import React, { useRef, useState } from 'react';
import { Box, Button, Link, List, ListItem, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UploadIcon from '../../../../images/upload-img-new.svg';
import { DeleteIcon, DownloadIcon } from '../../../../config/svg/CommonSvgIcon';
import '../../../../styles/pages/admin/bulkUserUpload.scss';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import exportFromJSON from 'export-from-json';
import { BulkEmailCsvTemplateUrl } from '../../../../config/url';

export const BulkEmailUpdateForm: React.FC<any> = (props) => {
	const {
		errorLogMessage,
		setErrorLogMessage,
		uploadFileData,
		setUploadFileData,
		formError,
		setValidationInProgress,
		setLoader,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const inputFileRef = useRef<HTMLInputElement>(null);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '' });

	const uploadFile = (e: any) => {
		if (inputFileRef) {
			inputFileRef?.current?.click();
		}
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const thisvalidateBulkUsers = async (file: any) => {
		if (file.fileName) {
			const formData = new FormData();
			formData.append('formFile', file.filePath);
			const response = await props.validateBulkEmailsUpload(formData);
			if (Boolean(response) && response.data && response.data && response.data.status === 200) {
				// setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
				setValidationInProgress(false);
				setLoader(false);
				setUploadFileData({ ...uploadFileData, filePath: file.filePath, fileName: file.fileName, fileUploaded: true });
				showApiMsgs(t('bulkEmailsSuccess'), 'success');
				return response.data;
			} else if (response.data.status === 400) {
				if (response.data.entity && response.data.entity.bulkErrors.length > 0) {
					const errorDetail = response.data.entity.bulkErrors;
					setErrorLogMessage(errorDetail);
				}
				const responseAPI = response.data.messageList;
				const keys = Object.keys(responseAPI);
				const messages = keys.map((item) => responseAPI[item]);
				showApiMsgs(`${messages} `, 'error');
			}
		}
	};

	const handleFileChange = async (e: any) => {
		const file = e.target.files[0];
		e.target.value = '';
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = [
			'application/vnd.ms-excel',
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			'text/csv',
		];
		if (size >= 1024) {
			enqueueSnackbar(t('fileSize1MB'), { variant: 'error', autoHideDuration: 5000 });
		} else if (!format.includes(fileType.toLowerCase())) {
			enqueueSnackbar(t('fileType4BulkUpload'), { variant: 'error', autoHideDuration: 5000 });
		} else {
			// setUploadFileData({ ...uploadFileData, filePath: file, fileName: file.name, fileUploaded: true });
			let fileObj = { filePath: file, fileName: file.name, fileUploaded: true };
			thisvalidateBulkUsers(fileObj);
		}
		setTimeout(() => {
			const selectedEl = document.getElementById('uploaded-file-details');
			if (selectedEl) {
				selectedEl.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
			}
		}, 15);

		setErrorLogMessage([]);
	};

	const removeFile = () => {
		setUploadFileData({ ...uploadFileData, filePath: '', fileName: '', fileUploaded: false });
	};

	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'deleteFile') {
				removeFile();
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '' });
	};

	const exportToExcel = () => {
		const updatedErrorLogMessage = errorLogMessage.map((item: any) => {
			return {
				'Record Row & Name': item.key, // Replace 'key' with 'Record Row & Name'
				'Error Description': item.value, // Replace 'value' with 'Error Description'
			};
		});
		exportFromJSON({ data: updatedErrorLogMessage, fileName: 'error_report', exportType: 'csv' });
	};
	const preventDefault = (event: any) => event.preventDefault();
	return (
		<>
			<input ref={inputFileRef} type='file' style={{ display: 'none' }} onChange={handleFileChange} />
			<Box className='bulk-user-content'>
				<Box className='upload-head'>
					<Typography variant='h5'>
						<Link id={'download-temp'} target='_blank' rel='noopener noreferrer' href={BulkEmailCsvTemplateUrl}>
							{t('downloadTemplate')}
						</Link>{' '}
						<span dangerouslySetInnerHTML={{ __html: t('bulkUpdateHeading') }}></span>
					</Typography>
				</Box>
				<Box
					className='upload-file'
					onClick={(event: any) => {
						uploadFile(event);
					}}
				>
					<Box className='upload-file-inner'>
						<img src={UploadIcon} alt='Upload File' className='upload-img' />
						<Typography variant='h4'>
							<Link id={'browse-btn'} href='#' onClick={preventDefault}>
								{t('UsersBulkUploadBrowseFile')}
							</Link>
						</Typography>
					</Box>
				</Box>
				{uploadFileData.fileName !== '' ? (
					<Box className='uploading-content'>
						<Box className='uploading-bar'>
							<Box className='bar'>
								<Box className='success'></Box>
								<Box className='inner-bar'>
									<Typography>
										{t('UsersBulkUploadFileName')} : {uploadFileData.fileName ? uploadFileData.fileName : ''}
									</Typography>
									<Button
										className='delete-icon'
										onClick={() =>
											setModalProps({
												open: true,
												module: 'delete',
												type: 'deleteFile',
												message: t('UsersBulkUploadDeleteFile'),
											})
										}
									>
										<DeleteIcon />
									</Button>
								</Box>
							</Box>
						</Box>
					</Box>
				) : (
					<></>
				)}
				{errorLogMessage && errorLogMessage.length > 0 && (
					<Box className='upload-result'>
						<Box className='no-error-mssg'>
							<ErrorIcon />
							<Typography variant='h5'>
								{formError && formError.bulkUserError ? formError.bulkUserError : t('noRecordsAdded')}
							</Typography>
						</Box>
						<Box className='download-error-link' onClick={exportToExcel}>
							<DownloadIcon />
							<Link>{t('downloadErrorReportsText')}</Link>
						</Box>
					</Box>
				)}
				<Box className='note-text' id='uploaded-file-details'>
					<Typography variant='body1' className='short-des-text'>
						<strong>{t('RoleEditNote')}:</strong> <br />
						{t('bulkUpdateEmailNotify')} 
						
						{/* <br />
						{t('bulkUpdateNotificationText')} */}
					</Typography>
				</Box>
			</Box>
			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
