import {
	Box,
	Avatar,
	FormControlLabel,
	Link,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Switch,
	Typography,
	Badge,
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { getUserDetails, getUserName } from '../../../config/utils';
import { saveRecognizePost, updateRecogPost } from '../../../action/recognize';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { enableTaggingWithImage } from '../../Common/CkEditor/CkEditorEnums';
import { globalSearchAPIWithTeam, recognitionSearch, searchEmployee } from '../../../action/search';
import { EmployeeSearch } from './EmployeeSearch';
import { RecognizePostBadges } from '../RecognizePostBadges/RecognizePostBadges';
import { useSnackbar } from 'notistack';
import { removeLocalStorageItem } from '../../../services/StorageService';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import AlertDialog from '../../Common/Dialog';

export const CreatePost = (props: any) => {
	const { recognizeData } = props;
	const [selectedUser, setSelectedUser] = React.useState<any>([]);
	const [postText, setPostText] = React.useState<any>('');
	const [activeBadgeData, setActiveBadgeData] = useState<any>({});
	const [formErrors, setFormErrors] = useState<any>({});
	const [focussed, setFocussed] = React.useState<boolean>(false);
	const [imageList, setImageList] = useState<any[]>([]); // test
	const loggedInUserDetail = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();
	const [saveClicked, setSaveClicked] = React.useState<boolean>(false);
	const dispatch = useDispatch();
	let userDetail = getUserDetails();
	const { t } = useTranslation();
	const [modalOpen, setModalOpen] = useState<any>({ open: false, message: '', type: '' });
	const [isFormEdited, setIsFormEdited] = React.useState<boolean>(false);

	useEffect(() => {
		if (recognizeData && recognizeData.recognitionId) {
			setFocussed(true);
			let editorPrefilledData: any = {};
			editorPrefilledData.message = recognizeData.message;
			editorPrefilledData.selectedUser = [];
			recognizeData.receiverDetails.forEach((value: any) => {
				editorPrefilledData.selectedUser.push({
					label: value.receiverName,
					value: value.receiverId,
					employeeId: value.receiverId,
					imagePath: value.receiverImagePath,
					teamId: value.searchType === 2 ? value.receiverId : 0,
				});
			});
			editorPrefilledData.activeBadgeData = {
				recognitionCategoryId: recognizeData.recognitionCategoryId,
				recognitionCategoryTypeId: recognizeData.recognitionCategoryTypeId,
				imageFilePath: recognizeData.attachmentImagePath,
				name: recognizeData.attachmentName,
			};
			resetForm(editorPrefilledData);
		}
	}, [recognizeData]);

	useEffect(() => {
		if (!(recognizeData && recognizeData.recognitionId)) {
			if (props.selectedUser && props.selectedUser.employeeId) {
				let editorPrefilledData: any = {};
				setFocussed(true);
				editorPrefilledData.selectedUser = [
					{
						...props.selectedUser,
						label: props.selectedUser.teamId
							? props.selectedUser.teamName
							: props.selectedUser.firstName + ' ' + props.selectedUser.lastName,
						value: props.selectedUser.employeeId,
						employeeId: props.selectedUser.employeeId,
						imagePath: props.selectedUser.imagePath,
						teamId: props.selectedUser.teamId,
					},
				];
				resetForm(editorPrefilledData);
			} else {
				setFocussed(false);
				resetForm({});
			}
		}
	}, [props.selectedUser]);

	const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(recognitionSearch(searchStr, page, pageSize, 1));
	};
	const validateForm = () => {
		let errors: any = {};
		if (selectedUser.length === 0) {
			errors.receiver = 'Please select receiver';
		}
		if (!postText) {
			errors.comment = 'Please enter Comment';
		}
		return Object.keys(errors).length === 0 && !saveClicked;
	};

	const isEditorVisible = () => {
		if (selectedUser.length > 0 || postText || activeBadgeData?.recognitionCategoryTypeId) {
			return true;
		}

		return false;
	};

	const resetForm = (data: any) => {
		setSelectedUser(data?.selectedUser ? data.selectedUser : []);
		setActiveBadgeData(data?.activeBadgeData ? data.activeBadgeData : {});
		setPostText(data?.message ? data.message : '');
	};

	const onSelectUser = (data: any) => {
		if (data && data.length === 0 && props.selectedUser && props.selectedUser.employeeId) {
		} else {
			setSelectedUser(data);
		}
		setIsFormEdited(true);
		setFocussed(true);
	};
	const savePost = async () => {
		setSaveClicked(true);
		if (validateForm()) {
			let data: any = {
				recognitionId: 0,
				message: postText,
				isAttachment: activeBadgeData?.recognitionCategoryTypeId ? true : false,
				recognitionCategoryTypeId: activeBadgeData?.recognitionCategoryTypeId
					? activeBadgeData?.recognitionCategoryTypeId
					: 0,
				recognitionCategoryId: activeBadgeData?.recognitionCategoryId ? activeBadgeData?.recognitionCategoryId : 0,
				recognitionImageRequests: [],
				recognitionEmployeeTags: [],
				receiverRequest: [],
			};
			if (recognizeData && recognizeData.recognitionId) {
				if (
					recognizeData.message !== data.message ||
					recognizeData.recognitionCategoryTypeId !== data.recognitionCategoryTypeId
				) {
					data.IsContentChange = true;
				}
			}
			const elem = document.createElement('span');
			elem.innerHTML = data.message;
			let tagged = elem.getElementsByClassName('tagged-name');
			for (let i = 0; i < tagged.length; i++) {
				let employeeId: any = tagged[i].getAttribute('rel');
				if (employeeId) {
					let searchType = 1;
					if (employeeId.includes('_teamId')) {
						searchType = 2;
						employeeId = employeeId.replace('_teamId', '');
					}
					data.recognitionEmployeeTags.push({
						id: parseInt(employeeId),
						searchType: searchType,
					});
				}
			}
			imageList.forEach((value: any) => {
				if (postText.includes(value.filePath)) {
					data.recognitionImageRequests.push({
						fileName: value.fileName,
						guidFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					});
				}
			});
			selectedUser.forEach((value: any) => {
				data.receiverRequest.push({
					id: value.teamId ? value.teamId : value.employeeId,
					searchType: value.teamId ? 2 : 1,
				});
			});
			let response: any;
			if (recognizeData && recognizeData.recognitionId) {
				data.recognitionId = recognizeData.recognitionId;
				response = await dispatch(updateRecogPost(data));
			} else {
				response = await dispatch(saveRecognizePost(data));
			}
			if (response.data.status === Enums.STATUS_SUCCESS) {
				setIsFormEdited(false);
				// enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
				// 	variant: 'success',
				// 	autoHideDuration: 5000,
				// });
				dispatch(showLoader(false));
				props.getAllOrgRecognize(1, 10, props.isMyPost);
				resetForm({});
				if (props.selectedUser && props.selectedUser.employeeId) {
					let editorPrefilledData: any = {};
					editorPrefilledData.selectedUser = [
						{
							...props.selectedUser,
							label: props.selectedUser.teamId
								? props.selectedUser.teamName
								: props.selectedUser.firstName + ' ' + props.selectedUser.lastName,
							value: props.selectedUser.employeeId,
							employeeId: props.selectedUser.employeeId,
							imagePath: props.selectedUser.imagePath,
							teamId: props.selectedUser.teamId,
						},
					];
					resetForm(editorPrefilledData);
				}
				if (props.selectedUser && props.selectedUser.employeeId) {
					setFocussed(true);
				} else {
					setFocussed(false);
				}
				setSaveClicked(false);
			} else {
				setSaveClicked(false);
				if (response?.data?.messageList.BlockedWords) {
					setModalOpen({ open: true, message: response?.data?.messageList.BlockedWords });
				} else {
					enqueueSnackbar(response?.data?.messageList.message, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		}
	};
	const onInputFocus = (focus = true) => {
		//setFocussed(focus);
		if (props.selectedUser && props.selectedUser.employeeId) {
			let editorPrefilledData: any = {};
			editorPrefilledData.selectedUser = [
				{
					label: props.selectedUser.firstName + ' ' + props.selectedUser.lastName,
					value: props.selectedUser.employeeId,
					employeeId: props.selectedUser.employeeId,
					imagePath: props.selectedUser.imagePath,
				},
			];
			resetForm(editorPrefilledData);
		}
	};
	const handleCancel = (focus = true) => {
		setFocussed(focus);
		if (props.selectedUser && props.selectedUser.employeeId) {
			let editorPrefilledData: any = {};
			editorPrefilledData.selectedUser = [
				{
					label: props.selectedUser.firstName + ' ' + props.selectedUser.lastName,
					value: props.selectedUser.employeeId,
					employeeId: props.selectedUser.employeeId,
					imagePath: props.selectedUser.imagePath,
				},
			];
			resetForm(editorPrefilledData);
		} else {
			resetForm({});
		}
		props.setEditRecognizationId && props.setEditRecognizationId(0);
	};
	const onCancel = (focus = true) => {
		if (isFormEdited) {
			setModalOpen({ open: true, message: t('unSavedItemAlert'), type: 'unsaved', focus: focus });
		} else {
			handleCancel(focus)
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (modalOpen.type === 'unsaved' && type === 1) {
			handleCancel(modalOpen.focus);
			setIsFormEdited(false);
		}
		setModalOpen({ open: false, message: '' });
	};
	return (
		<Box className='recog-create-post-wall'>
			<Box className='recog-create-post-search'>
				<Box className='recog-user-info'>
					<Box className='user-img'>
						{loggedInUserDetail && loggedInUserDetail.imagePath ? (
							<Avatar
								className='avatar-default'
								alt={`${loggedInUserDetail.firstName} ${loggedInUserDetail.lastName}`}
								src={loggedInUserDetail.imagePath}
							/>
						) : (
							<Avatar className='avatar-default'>
								{getUserName({
									firstName: loggedInUserDetail.firstName,
									lastName: loggedInUserDetail.lastName,
									fullName: loggedInUserDetail.fullname,
								})}
							</Avatar>
						)}
					</Box>
				</Box>
				<Box className='recog-user-search'>
					<EmployeeSearch
						{...props}
						searchCustomEmployee={searchCustomEmployee}
						selectedUser={selectedUser}
						setSelectedUser={onSelectUser}
						formErrors={formErrors}
						setFormErrors={setFormErrors}
						//onInputFocus={onInputFocus}
						onInputFocus={() => { }}
						focussed={focussed}
					//disabled={props.selectedUser && (props.selectedUser.employeeId || props.selectedUser.teamId) ? true : false}
					/>
				</Box>
			</Box>
			<Box className='recog-create-post-box'>
				{focussed && (
					<>
						{/* <Box className='recog-create-post'> */}
						<RecognizePostBadges
							activeBadgeData={activeBadgeData}
							setActiveBadgeData={(item: any) => {
								setActiveBadgeData(item);
								setIsFormEdited(true);
							}}
							formErrors={formErrors}
							setFormErrors={setFormErrors}
						/>
						<Box className='editior-pane'>
							<Box className='cfr-control-section' id='rteImage'>
								<CkEditor
									{...props}
									enableTagging={true}
									focusOnLoad={true}
									placeholder={''}
									handleEditorChange={(value: string) => {
										if (
											value &&
											(value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>')
										) {
											setIsFormEdited(true);
											//setIsTaskEdited(false);
										} else {
											//setIsTaskEdited(true);
											if (postText !== value) {
												setIsFormEdited(true);
											}
											setPostText(value);
											setFormErrors({
												...formErrors,
												comment: '',
											});
										}
									}}
									value={postText}
									setImageList={setImageList}
									imageList={imageList}
									uploadCase={'CONVERSATION'}
									uploadType={'2'}
									removedPlugins={enableTaggingWithImage}
									uploadImage={true}
									globalSearchAPIWithTeam={searchCustomEmployee}
									showEmoticons={true}
								/>
								<Box className='help-text'>
									{formErrors?.comment !== '' ? (
										<Typography className='error-text'>{formErrors.comment}</Typography>
									) : (
										<></>
									)}
								</Box>
							</Box>
						</Box>
						{/* </Box> */}
						<Box className='recog-add-post-action'>
							{props.selectedUser && props.selectedUser.employeeId ? (
								<></>
							) : (
								<OKRButton className='btn-link' text={'Cancel'} handleClick={() => onCancel(false)} />
							)}
							<OKRButton className='btn-primary' text={'Post'} handleClick={savePost} disabled={!validateForm()} />
						</Box>
					</>
				)}
			</Box>
			{modalOpen.open ? (
				<AlertDialog
					module={modalOpen?.type === 'unsaved' ? 'user' : ''}
					message={modalOpen?.message || ''}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen?.open}
					isCancel={modalOpen?.type !== 'unsaved'}
				/>
			) : (
				<></>
			)}
		</Box>
	);
};
