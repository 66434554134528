import * as React from 'react';
import { Box, Typography } from '@material-ui/core';

const OkrNature: React.FC<any> = (props: any) => {
	const { okrMasterData, okr } = props;
	return (
		<>
			{okrMasterData &&
				okrMasterData?.okrNature &&
				okrMasterData?.okrNature.length &&
				okrMasterData?.okrNature.map((item: any, index: number) => (
					<Box className='okr-nature-type-tag' key={index}>
						{okr?.goalNatureId === item.okrNatureId && (
							<Typography variant='subtitle2' className='operational'>
								{item.name}
							</Typography>
						)}
					</Box>
				))}
		</>
	);
};

export default OkrNature;
