import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	FormControlLabel,
	List,
	ListItem,
	ListItemText,
	Switch,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import { OKRButton } from '../../../../Common/OKRButton';
import { BottomArrowIcon } from '../../../../../config/svg/CommonSvgIcon';

export const VisibilitySettingDropdown: React.FC<any> = (props: any) => {
	const { itemData, name, handleDropdownChange, selectedValue, isDisabled=false } = props;
	const { t } = useTranslation();
	const [open, setOpen] = useState<Boolean>(false);
	const [selectedDropdownValue, setSelectedDropdownValue] = useState<any>({});

	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const handleChange = (item: any) => {
		handleDropdownChange(name, item);
		handleClickAway();
	};
	useEffect(() => {
		let selected = itemData?.filter((item: any) => item.id === selectedValue);
		if (selected && selected.length > 0) {
			setSelectedDropdownValue(selected[0]);
		}
	}, [selectedValue]);

	return (
		<>
			<Box className={`okr-team-dropdown scale-type-dropdown visibility-type-dropdown ${open ? 'active' : ''} `}>
				<OKRButton
					className={'dropdown-button'}
					icon={<BottomArrowIcon />}
					handleClick={handleClick}
					disabled={isDisabled}
					text={
						selectedDropdownValue && selectedDropdownValue.label ? (
							<span className='text-content' dangerouslySetInnerHTML={{ __html: selectedDropdownValue.label }}></span>
						) : (
							t('hide')
						)
					}
				/>
				{open && (
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box className='team-dropdown'>
							<Box className='team-dropdown-inner'>
								<Box className='dropdown-multi-list-sub-panel'>
									<List component='div' disablePadding>
										{itemData.map((item: any) => (
											<ListItem
												component='div'
												className={`${
													selectedDropdownValue && selectedDropdownValue.label === item.label ? 'active' : ''
												}`}
												onClick={(event) => {
													handleChange(item);
												}}
											>
												<ListItemText>
													<span dangerouslySetInnerHTML={{ __html: item.label }}></span>
												</ListItemText>
											</ListItem>
										))}
									</List>
								</Box>
							</Box>
						</Box>
					</ClickAwayListener>
				)}
			</Box>
		</>
	);
};
