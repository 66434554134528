import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import {
	Box,
	FormControlLabel,
	Typography,
	TextField,
	InputAdornment,
	Avatar,
	List,
	ListItem,
	ClickAwayListener,
	Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { OrgChart } from './TreeC10';
import { Checkbox } from '@material-ui/core';
import CustomNodeContent from './customNodeContent';
import { CheckedIcon, BorderCheckboxIcon } from '../../config/svg/formElementIcons';
import { AlignmentMapTabPanel, AlignmentMapTabs } from './AlignmentMapTabs';
import { useTranslation } from 'react-i18next';
import '../../styles/pages/alignmentmap-chart/alignmentmap-chart.scss';
import { SearchIcon } from '../../config/svg/CommonSvg';
import { Visibility } from '../../config/svg/ProfileSvg';
import { OKRButton } from '../Common/OKRButton';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { TeamIcon } from '../../config/svg/MyGoalSvg';
import { OKRDrawer } from '../Common/OKRDrawer';
import { DirectReportDrawer } from './DirectReportDrawer';
import noRecordFound from '../../images/search-placeholder.svg';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import AlignmentImage from '../../images/alignment-bg.svg';
import { getUserName, getRandomBackgroundColor, getRandomColor } from '../../config/utils';
import { globalSearchAPIWithTeam, searchEmployee } from '../../action/search';
import { getUserById, updatePage } from '../../action/common';
import AssignContributor from '../OkrAction/AssignContributor';
import { CancelIcon } from '../../config/svg/Action';

const styles = {
	orgChart: {
		height: 'calc(100vh - 150px)',
		//backgroundColor: '#eaeaea',
	},
};

const AlignmentMapChart = (props) => {
	const {
		completeData,
		organisations,
		loadTeamData,
		selectedTeamDetail,
		selectedUserDetails,
		recentSearchDetails,
		setRecentSearchData,
		directReportDetails,
		setDirectReportDetails,
		handleUserSelection,
		krData,
		searchSelectedUser,
		setSearchSelectedUser,
		setTabValue,
		tabValue,
	} = props;
	const { okrMasterData } = useSelector((state) => state?.okrReducer);
	const d3Container = useRef(null);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const maxLength = 50;
	const [cardShow, setCardShow] = useState(false);
	const [virtualLink, setVirtualLink] = useState(false);
	const defaultDataValue = {
		data: [],
		expand: false,
		nodeId: 0,
		activeNodeId: 0,
		screenLevel: 1,
		treeType: 'top',
		isVirtual: false,
		rootMargin: 10,
		rootOtherMargin: 10,
		activeKrId: [],
	};
	const [treeData, setTreeData] = useState({ ...defaultDataValue });
	const [openCFR, setOpenCFR] = useState({ open: false, details: null });
	const [open, setOpen] = useState(false);
	const handleRecentSearchItemClick = (e, record) => {
		handleClickAway();
		handleAlignmentDrDrawerClose(e);
		handleUserSelection(record);
	};
	const RenderUserAvatar = ({ rec, className }) => {
		return rec?.imagePath ? (
			<Avatar className={className || 'user-avatar'} src={rec?.imagePath} />
		) : (
			<Avatar className={className || 'user-avatar'}>
				{getUserName({
					fullName: '',
					firstName: rec?.firstName,
					lastName: rec?.lastName,
				})}
			</Avatar>
		);
	};
	const RenderUserName = ({ rec, textLimit }) => {
		const textLimitValue = textLimit || maxLength;

		return (rec?.firstName + rec?.lastName).length > textLimitValue ? (
			<Tooltip title={rec?.firstName + ' ' + rec?.lastName} arrow>
				<Typography variant='h4'>
					{(rec?.firstName + ' ' + rec?.lastName).substring(0, textLimitValue)}
					...
				</Typography>
			</Tooltip>
		) : (
			<Typography variant='h4'>{`${rec?.firstName} ${rec?.lastName}`}</Typography>
		);
	};
	const RenderEmailAddress = ({ rec, textLimit }) => {
		const textLimitValue = textLimit || maxLength;

		return rec?.emailId?.length > textLimitValue ? (
			<Tooltip title={rec?.emailId} arrow>
				<Typography variant='subtitle2'>
					{rec?.emailId?.substring(0, textLimitValue)}
					...
				</Typography>
			</Tooltip>
		) : (
			<Typography variant='subtitle2'>{`${rec?.emailId || ''}`}</Typography>
		);
	};

	let chart;

	useEffect(() => {
		renderTree();
	}, [treeData]);

	useEffect(() => {
		if (completeData.length > 0) {
			chart = new OrgChart();
			setTreeData({
				data: [...completeData],
				expand: false,
				nodeId: 0,
				activeNodeId: 0,
				screenLevel: 1,
				treeType: 'top',
				rootMargin: 10,
				rootOtherMargin: 10,
			});
		}
	}, [completeData]);

	const handleShow = () => setCardShow(true);
	const handleClose = () => setCardShow(false);

	useEffect(() => {
		if (props.tabMainValue === 5) {
			const myElement = document.getElementById('okr-app');
			myElement.classList.add('alignment-map-main');
			return () => {
				myElement.classList.remove('alignment-map-main');
			};
		}
	}, [props.tabMainValue]);

	const renderTree = () => {
		if (treeData.data && treeData.data.length > 0 && d3Container.current) {
			//if (!chart) {
			chart = null;
			chart = new OrgChart();
			//}
			treeData.data.forEach((item, index) => {
				item._expanded = true;
				item.isVirtual = treeData.isVirtual;
				if (treeData.nodeId === item.id) {
					item._centered = true;
				}
				//item.activeKrId = treeData.activeKrId;
				if (index === 0) {
					//item._centered = true
				}
			});

			chart
				.container(d3Container.current)
				.expandLevel(10)
				.setActiveNodeCentered(true)
				.layout(treeData.treeType || 'top')
				.showLinks(treeData.screenLevel !== 1)
				.activeNode(treeData.data[0].id)
				.data(treeData.data)
				.nodeWidth((d) => 340)
				.nodeHeight((d) => 150)
				.rootMargin(treeData.rootMargin)
				.rootOtherMargin(treeData.rootOtherMargin)
				.compactMarginBetween((d) => 50)
				.firstDraw(treeData.screenLevel !== 2)
				.nodeContent((d) => {
					return (
						<CustomNodeContent
							{...d}
							okrMasterData={okrMasterData}
							activeKrId={treeData.activeKrId}
							virtualLink={virtualLink}
							loadOkr={toggleDetailsCard}
							loadKRList={toggleKrDetailsCard}
							toggleVirtualLink={toggleVirtualLink}

						/>

					);
				})
				.render(treeData.expand, treeData.screenLevel === 1);
			treeData.expand && chart.expandAll();
		}
	};
	const toggleDetailsCard = (nodeId) => {

		if (treeData.screenLevel === 1) {
			const data = completeData && completeData.length ? completeData.filter((item) => item.id === nodeId) : [];
			const copy = data.map((item) => ({ ...item }));
			let treeData1 = [];
			if (data.length > 0) {
				treeData1 = Object.assign([], [...copy]);
				treeData1[0].parentId = '';
				treeData1[0].type = 2;
			}
			//chart.data(treeData).updateNodesState().setCentered(treeData[0].id);;
			setTreeData({
				data: treeData1,
				expand: false,
				activeNodeId: treeData1.length > 0 ? treeData1[0].id : 0,
				nodeId: 0,
				screenLevel: 2,
				treeType: 'top',
				rootMargin: 10,
				rootOtherMargin: 10,
			});

		}
	};

	const toggleVirtualLink = (nodeData) => {
		const dataKr = completeData && completeData.length ? completeData.filter((item) => item.id === nodeData.id) : [];
		const data1 = dataKr.map((item) => ({ ...item }));
		if (data1.length > 0 && data1[0].virtualOKRs.length > 0) {
			let newChartData = [];
			let treeD = { ...data1[0] };
			treeD.parentId = '';
			treeD.type = 2;
			newChartData.push({ ...treeD });
			let lastParentId = treeD.id;
			data1[0].virtualOKRs.forEach((item, index) => {
				item.type = 5;
				item.parentId = lastParentId;
				item.id = lastParentId + '_' + index;
				newChartData.push({ ...item });
			});

			setTreeData({
				data: newChartData,
				expand: false,
				nodeId: 0, // treeD.id,
				activeNodeId: treeD.id,
				screenLevel: 3,
				treeType: 'left',
				rootMargin: 400,
				rootOtherMargin: 100,
			});
		}
	};

	const toggleKrDetailsCard = (nodeId, kr, nodeData) => {
		setVirtualLink(false);
		const dataKr = completeData && completeData.length ? completeData.filter((item) => item.id === nodeId) : [];
		const data1 = dataKr.map((item) => ({ ...item }));
		if (data1.length > 0 && nodeData.type === 2) {
			let newChartData = [];
			let treeD = { ...data1[0] };
			const data = kr.parent;
			if (kr.parent.parentChildContributors && kr.parent.parentChildContributors.length > 0) {
				data.tileType = 3;
				data.okrViewKeyResults = kr.parent.parentChildContributors.map((item) => {
					return { ...item, id: 'parent_' + item.goalKeyId + '_cont', teamId: kr.teamId  };
				});
				if (kr.parent.parentChildContributors.length === 0) {
					data.isNoParent = true;
					data.isNoParentType = 'parent';
				}
				data.parentId = '';
				data.nameKey = 'ownerName';
				data.type = 3;
				data.id = 'parent_' + kr.goalKeyId;
				newChartData.push(data);
				treeD.type = 2;
				treeD.parentId = data.id;
				newChartData.push(treeD);
				if (kr.child.parentChildContributors && kr.child.parentChildContributors.length > 0) {
					kr.child.parentId = treeD.id;
					kr.child.okrViewKeyResults = kr.child.parentChildContributors.map((item) => {
						return { ...item, id: 'child_' + item.goalKeyId, teamId: kr.teamId };
					});
					kr.child.type = 4;
					kr.child.id = 'child_' + kr.goalKeyId;
					kr.child.keyDescription = kr.keyDescription;
					kr.child.teamId = kr.teamId;
					kr.child.nameKey = 'ownerName';
					if (kr.child.parentChildContributors.length === 0) {
						kr.child.isNoParent = true;
						kr.child.isNoParentType = 'child';
					}
					newChartData.push(kr.child);
				}
				setTreeData({
					data: newChartData,
					expand: false,
					nodeId: treeD.id,
					activeNodeId: treeD.id,
					activeKrId: [kr.goalKeyId],
					screenLevel: 3,
					treeType: 'left',
					rootMargin: 10,
					rootOtherMargin: 100,
				});
			} else {
				treeD.type = 2;
				treeD.parentId = '';
				newChartData.push(treeD);
				if (kr.child.parentChildContributors && kr.child.parentChildContributors.length > 0) {
					kr.child.parentId = treeD.id;
					kr.child.okrViewKeyResults = kr.child.parentChildContributors.map((item) => {
						return { ...item, id: 'child_' + item.goalKeyId, teamId: kr.teamId };
					});
					kr.child.keyDescription = kr.keyDescription;
					kr.child.type = 4;
					kr.child.nameKey = 'ownerName';
					kr.child.id = 'child_' + kr.goalKeyId;
					if (kr.child.parentChildContributors.length === 0) {
						kr.child.isNoParent = true;
						kr.child.isNoParentType = 'child';
					}
					newChartData.push(kr.child);
				}
				setTreeData({
					data: newChartData,
					expand: true,
					nodeId: treeD.id,
					activeNodeId: treeD.id,
					activeKrId: [kr.goalKeyId],
					screenLevel: 3,
					treeType: 'left',
					rootMargin: 400,
					rootOtherMargin: 100,
				});
			}
		} else if (nodeData.type === 4) {
			let childKrList = krData && krData.length ? krData.filter((item) => item.goalKeyId === kr.goalKeyId) : [];
			if (childKrList.length > 0) {
				let existingChartData = [...treeData.data];
				let activeIds = [...treeData.activeKrId];
				let newActiveIds = [];
				const currentNodeIndex = existingChartData.findIndex((item) => item.id === nodeData.id);
				existingChartData = existingChartData.filter((item, index) => {
					return index <= currentNodeIndex;
				});

				existingChartData.forEach((item) => {
					item.okrViewKeyResults.forEach((itemL1) => {
						let itemIndex = activeIds.indexOf(itemL1.id);
						if (item.id !== nodeData.id && itemIndex > -1) {
							newActiveIds.push(itemL1.id);
						}
					});
				});
				newActiveIds.push(kr.id);
				let dataT = childKrList.map((item) => ({ ...item }))[0];
				if (dataT.child?.parentChildContributors && dataT.child.parentChildContributors.length > 0) {
					dataT.parentId = existingChartData[existingChartData.length - 1].id;
					dataT.okrViewKeyResults =
						dataT.child?.parentChildContributors.map((item) => {
							return { ...item, id: 'child_' + item.goalKeyId, teamId: kr.teamId };
						}) || [];
					dataT.alignedCount = dataT.child?.alignedCount;
					dataT.assignedCount = dataT.child?.assignedCount;
					dataT.keyDescription = kr.keyDescription;
					dataT.nameKey = 'ownerName';
					dataT.type = 4;
					dataT.id = 'emp_' + dataT.employeeId + 'child_' + kr.goalKeyId;
					existingChartData.push(dataT);
					setTreeData({
						...treeData,
						data: existingChartData,
						nodeId: existingChartData[existingChartData.length - 2].id,
						activeKrId: newActiveIds, // check here
						rootMargin: 10,
						rootOtherMargin: 100,
					});
				}
			}
		} else if (nodeData.type === 3) {
			let childKrList = krData && krData.length ? krData.filter((item) => item.goalKeyId === kr.goalKeyId) : [];
			if (childKrList && childKrList.length > 0) {
				let existingChartData = [...treeData.data];
				let newChartData = [];
				let newActiveIds = [...treeData.activeKrId];
				newActiveIds.push(kr.id);
				let dataT = childKrList.map((item) => ({ ...item }))[0];
				if (dataT.parent?.parentChildContributors && dataT.parent.parentChildContributors.length > 0) {
					dataT.okrViewKeyResults =
						dataT.parent?.parentChildContributors.map((item) => {
							return { ...item, id: 'child_' + item.goalKeyId, teamId: kr.teamId };
						}) || [];
					dataT.alignedCount = dataT.parent?.alignedCount;
					dataT.assignedCount = dataT.parent?.assignedCount;
					dataT.keyDescription = kr.keyDescription;
					dataT.nameKey = 'ownerName';
					dataT.type = 3;
					dataT.parentId = '';
					dataT.id = 'emp_' + dataT.employeeId + 'parent_' + kr.goalKeyId;
					const currentNodeIndex = existingChartData.findIndex((item) => item.id === dataT.id);
					newChartData.push(dataT);
					existingChartData = existingChartData.map((item, index) => {
						if (index > currentNodeIndex) {
							if (newChartData.length === 1) {
								item.parentId = dataT.id;
							}
							newChartData.push(item);
						}
					});
					setTreeData({
						...treeData,
						data: newChartData,
						nodeId: newChartData[1].id,
						activeKrId: newActiveIds, // check here
						rootMargin: 10,
						rootOtherMargin: 100,
					});
				}
			}
		}
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
		if (newValue === 0) {
			loadTeamData({ teamId: 0 });
			setSearchSelectedUser({});
		}
	};
	const handleAlignmentDrOpen = (event) => {
		setOpenCFR({ open: true, details: null });
	};
	const handleAlignmentDrDrawerClose = (event) => {
		setOpenCFR({ open: false, details: null });
	};

	const handleClickAway = () => {
		setOpen(false);
	};

	const [localArrList, setLocalArrList] = useState({
		selectedUser: [],
		raisedForId: [],
	});
	const onSelectedUser = async (selected) => {
		const isExist =
			selected && selected.length && localArrList.selectedUser && localArrList.selectedUser.length
				? localArrList.selectedUser.find((item) => item.employeeId === selected[0].employeeId)
				: false;
		if (selected.length > 0 && !isExist) {
			const allUser = [...selected];
			const allUserIds = allUser.map((item) => item.employeeId);

			setLocalArrList({
				...localArrList,
				selectedUser: allUser,
				raisedForId: allUserIds,
			});
			handleRecentSearchItemClick({}, selected[0]);
		}
	};
	const searchCustomEmployee = (searchStr, page, pageSize) => {
		return dispatch(searchEmployee(searchStr, page, pageSize));
	};
	const updateCustomPage = (page) => {
		return dispatch(updatePage(page));
	};
	const globalSearchWithTeam = (searchStr, page, pageSize, type) => {
		return dispatch(globalSearchAPIWithTeam(searchStr, page, pageSize, type));
	};
	const getCustomUserById = (data) => {
		return dispatch(getUserById(data));
	};

	const getAvatar = () => {
		let selectedData =
			selectedTeamDetail && selectedTeamDetail.teamId
				? {
					...selectedTeamDetail,
					imagePath: selectedTeamDetail.teamLogo,
					fullName: selectedTeamDetail.teamName,
				}
				: searchSelectedUser && searchSelectedUser?.employeeId
					? {
						...searchSelectedUser,
						fullName: searchSelectedUser.firstName + ' ' + searchSelectedUser.lastName,
					}
					: {
						...selectedUserDetails,
						fullName: selectedUserDetails.firstName + ' ' + selectedUserDetails.lastName,
					};
		let avatarClass = selectedTeamDetail && selectedTeamDetail.teamId ? 'team-avatar-small' : '';
		return (
			<>
				<Box className='alignment-logged-in-profile '>
					{treeData.screenLevel !== 1 ? (
						<OKRButton
							className='view-all-okr'
							handleClick={(e) => {
								setTreeData({
									data: [...completeData],
									expand: false,
									nodeId: 0,
									screenLevel: 1,
									treeType: 'top',
									rootMargin: 10,
									rootOtherMargin: 10,
									activeNodeId: 0,
								});
							}}
							icon={<BackArrow />}
							text={'View all OKRs'}
						/>
					) : (
						''
					)}
					<Box className='selected-user-box'>
						{searchSelectedUser && searchSelectedUser?.employeeId ? (
							<Box className='back-alignment-map'>
								<OKRButton
									className='blue-filled-btn '
									handleClick={(e) => {
										loadTeamData({ teamId: 0 });
										setSearchSelectedUser({});
										setTabValue(0);
									}}
									icon={<CancelIcon />}
									title={t('backYourMap')}
								/>
							</Box>
						) : (
							''
						)}
						<Tooltip arrow title={selectedData.fullName}>
							{selectedData.imagePath ? (
								<Avatar src={selectedData.imagePath} className={`${avatarClass}`} />
							) : (
								<Avatar
									style={{
										backgroundColor: getRandomBackgroundColor(selectedData.backGroundColorCode),
										color: getRandomColor(selectedData.colorCode),
									}}
									className={`${avatarClass}`}
								>
									{getUserName({
										fullName: selectedData.fullName,
										firstName: '',
										lastName: '',
									})}
								</Avatar>
							)}
						</Tooltip>
					</Box>
				</Box>
			</>
		);
	};
	return (
		<>
			<Box className='alignment-chart-head'>
				<Box className='col-left'>
					{!searchSelectedUser?.employeeId && (
						<AlignmentMapTabs
							{...props}
							loadTeamData={loadTeamData}
							organisations={organisations}
							handleTabChange={handleTabChange}
							tabValue={tabValue}
						/>
					)}
				</Box>
				<Box className='col-right'>
					{treeData.screenLevel !== 1 ? (
						<Box className='visible-link-filter'>
							<Typography className='label-txt'>
								<Visibility /> {t('visibleLabel')}:
							</Typography>
							<FormControlLabel
								control={
									<Checkbox
										name='isVirtualLink'
										icon={<BorderCheckboxIcon />}
										checkedIcon={<CheckedIcon />}
										id={'is-virtual-link'}
										disabled={treeData.screenLevel === 1}
										checked={virtualLink}
										onChange={(e) => {
											let isChecked = e.target.checked ? true : false;
											setVirtualLink(isChecked);
											if (isChecked) {
												let tData = treeData.data.filter((item) => item.type === 2);
												if (tData.length > 0) {
													tData[0].parentId = '';
												}
												setTreeData({
													...treeData,
													data: [...tData],
													isVirtual: isChecked,
													activeKrId: [],
												});
											} else {
												let tData = [];
												if (treeData.data.length === 1) {
													tData = treeData.data.map((item) => ({ ...item }));
													setTreeData({
														...treeData,
														data: [...tData],
														isVirtual: isChecked,
														activeKrId: [],
													});
												} else {
													tData = treeData.data.filter((item) => item.type === 2);
													if (tData.length > 0) {
														tData[0].parentId = '';
													}
													setTreeData({
														...treeData,
														data: [...tData],
														isVirtual: isChecked,
														rootMargin: 400,
														rootOtherMargin: 100,
														activeKrId: [],
													});
												}
											}
										}}
									/>
								}
								label={t('virtualLinksLabel')}
							/>
							{/* <FormControlLabel
								control={
									<Checkbox
										name='isTeam'
										disabled={treeData.screenLevel === 1}
										icon={<BorderCheckboxIcon />}
										checkedIcon={<CheckedIcon />}
										id={'is-team'}
									/>
								}
								label={t('teamLabel')}
							/> */}
						</Box>
					) : (
						<></>
					)}
					<ClickAwayListener onClickAway={handleClickAway}>
						<Box className='user-search'>
							{/* <TextField
								className='search-text-field'
								id='user-src-field'
								placeholder={t('searchPeopleText')}
								fullWidth
								autoComplete='off'
								//value={searchValue}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start'>
											<SearchIcon />
										</InputAdornment>
									),
								}}
								inputProps={{
									onClick: () => {
										if (recentSearchDetails && recentSearchDetails.length) {
											setOpen(true);
										}
									},
								}}
							/> */}
							<AssignContributor
								{...props}
								goal={{ selectedUser: [], raisedForId: [] }}
								onSelectedUser={onSelectedUser}
								removeUser={() => { }}
								addKey={{ type: 'map' }}
								fieldLabel={''}
								fetchCycleUsers={true}
								customClass={'alignment-map-src'}
								customId={'give-pf-src'}
								multiple={false}
								defaultValue={null}
								defaultFocus={null}
								placeHolderText={t('searchPeopleText')}
								searchEmployee={searchCustomEmployee}
								updatePage={updateCustomPage}
								globalSearchAPIWithTeam={globalSearchWithTeam}
								getUserById={getCustomUserById}
								isChipsHide={true}
								handleGlobalSearchClick={(value) => {
									if (recentSearchDetails && recentSearchDetails.length) {
										setOpen(value || false);
									}
								}}
							/>
							{open && (
								<Box className='recent-src-dropdown rbt-menu dropdown-menu'>
									{recentSearchDetails && recentSearchDetails.length ? (
										<List>
											{[...recentSearchDetails]?.map((rec) => (
												<ListItem onClick={(event) => handleRecentSearchItemClick(event, rec)}>
													<Box className='user-info'>
														<Box className='user-img'>
															<RenderUserAvatar rec={rec} className='avatar-default' />
														</Box>
														<Box className='user-info-details'>
															<RenderUserName rec={rec} textLimit={maxLength} />
															<RenderEmailAddress rec={rec} textLimit={maxLength} />
														</Box>
													</Box>
												</ListItem>
											))}
										</List>
									) : recentSearchDetails && recentSearchDetails.length === 0 ? (
										<></>
									) : (
										<Box className='search-find'>
											<Typography>Searching....</Typography>
										</Box>
									)}

									{/* <Box className='search-no-record-global'>
										<Box className='no-record-message'>
											<Typography variant='h2' component='h2'>
												{t('CommonOOPS')}
											</Typography>
											<Typography variant='h6' className='no-sentence-case'>
												{t('globalSearchNoRecord')}
											</Typography>

											<AnimatedIcon
												className=''
												width='230'
												type='image/svg+xml'
												alt={t('globalSearchNoRecord')}
												imagePath={noRecordFound}
											/>
										</Box>
									</Box> */}
								</Box>
							)}
						</Box>
					</ClickAwayListener>
					<Box className='al-map-direct-rpt'>
						<OKRButton
							icon={<TeamIcon />}
							className={`blue-filled-btn`}
							handleClick={handleAlignmentDrOpen}
							title={t(`View other's Alignment Map`)}
							// tooltipPlacement={`${open ? 'top' : 'right'}`}
							tooltipClassName={'tooltip-layout10'}
						/>
					</Box>
				</Box>
			</Box>
			<Box className='alignment-chart-content'>
				<AlignmentMapTabPanel value={tabValue} index={0}></AlignmentMapTabPanel>

				{getAvatar()}
				{completeData.length > 0 ? (
					<div style={styles.orgChart} ref={d3Container}></div>
				) : (
					<>
						<Box className='welcome-content'>
							<Box className='align-center'>
								<AnimatedIcon
									className=''
									width='440'
									type='image/svg+xml'
									alt='WelcomeImage'
									imagePath={AlignmentImage}
								/>
								{props.prevCycleMsg ? (
									<>
										<Typography variant='h2'>{t('NoRecord')}</Typography>
										<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
									</>
								) : (
									<>
										<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
										<Typography>{t('myGoalNoDataMsg2')}</Typography>
									</>
								)}
							</Box>
						</Box>
					</>
				)}
				{/* panding status */}
				{/* <Box className='welcome-content'>
						<Box className='align-center'>
							<Typography>{t('loading')}</Typography>
						</Box>
					</Box> */}
				{/* //else pa */}
				{/* <Box className='welcome-content'>
						<Box className='align-center'>
							<AnimatedIcon
								className=''
								width='440'
								type='image/svg+xml'
								alt='WelcomeImage'
								imagePath={AlignmentImage}
							/>
							{prevCycleMsg ? (
								<>
									<Typography variant='h2'>{t('NoRecord')}</Typography>
									<Typography className='no-record-max'>{t('pastCycleNotFoundText')}</Typography>
								</>
							) : (
								<>
									<Typography variant='h2'>{t('myGoalNoDataMsg1')}</Typography>
									<Typography>{t('myGoalNoDataMsg2')}</Typography>
								</>
							)}
						</Box>
					</Box> */}
			</Box>
			<Box class='scroll-drag-info alignment-map-scroll-info'>
				<Typography variant='body2'>Click the key result to view</Typography>
				<Typography variant='body2'>parent/contributor</Typography>
			</Box>

			{openCFR && openCFR.open ? (
				<OKRDrawer
					open={openCFR.open || false}
					drawerClassName={'main-drawer-panel drawer-no-footer alignment-dr-drawer'}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t(`View other's Alignment Map`)}
					drawerHead={true}
					children={
						<DirectReportDrawer
							{...props}
							recentSearchUserList={recentSearchDetails}
							directReportList={directReportDetails}
							RenderUserAvatar={RenderUserAvatar}
							RenderUserName={RenderUserName}
							RenderEmailAddress={RenderEmailAddress}
							handleRecentSearchItemClick={handleRecentSearchItemClick}
						/>
					}
					moreButton={false}
					handleDrawerClose={(event) => handleAlignmentDrDrawerClose(event)}
					handleSaveClick={(event) => handleAlignmentDrDrawerClose(event)}
					handleCancelClick={(event) => handleAlignmentDrDrawerClose(event)}
					hideDefaultButton={true}
				/>
			) : (
				<></>
			)}
		</>
	);
};

export default AlignmentMapChart;
