import React from 'react';
import GlobalSearch from '../Common/GlobalSearch';

export default function PrimarySearch(props) {
	return (
		<div className='primary-search-field'>
			<GlobalSearch {...props} module={'global'} fetchCycleUsers={true} customId={'global-src'} />
		</div>
	);
}
