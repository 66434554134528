import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Box,
	Avatar,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Popper,
	Grow,
	IconButton,
	Paper,
	Typography,
	Tooltip,
	Link as MaterialLink,
} from '@material-ui/core';
import Profile from '../../Profile';
import { LogoutIcon } from '../../../config/svg/ProfileSvg';
import { clearLocalStorage, getLocalStorageItem } from '../../../services/StorageService';
import { getUserName } from '../../../config/utils';
import { ProfileIcon } from '../../../config/svg/CommonSvg';
import { AZURE_LOGOUTURI } from '../../../config/constant';
import { ProfileInfoDrawer } from '../../Common/ProfileInfoDrawer';

export default function MyAccount(props) {
	const userDetail = JSON.parse(getLocalStorageItem('userDetail'));
	const { profileOpen, setProfileOpen } = props;
	const anchorRef = useRef(null);
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);

	const handleToggle = () => {
		setOpen((prev) => !prev);
	};

	const handleClose = (event) => {
		if (event && anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
	};

	useEffect(() => {
		if (!props.isFederationUserSuccess) {
			props.isFederationUser(userDetail.emailId);
		}
	}, []);

	return (
		<Box className='user-tab'>
			<Tooltip
				title={userDetail?.isImpersonatedUser ? `${userDetail.firstName} ${userDetail.lastName}` : t('meLabel')}
				arrow
			>
				<IconButton
					className='profile-btn'
					type='button'
					ref={anchorRef}
					aria-controls={open ? 'menu-list-grow' : undefined}
					onClick={handleToggle}
					id='profile-btn'
				>
					{userDetail && userDetail.imagePath && userDetail.imagePath.trim() !== '' ? (
						<Avatar className='avatar-type3' src={userDetail.imagePath} />
					) : userDetail && userDetail.firstName ? (
						<Avatar className='avatar-type3'>{getUserName(userDetail)}</Avatar>
					) : (
						<Avatar className='avatar-type3'></Avatar>
					)}
				</IconButton>
			</Tooltip>

			<ProfileInfoDrawer
				{...props}
				open={open}
				handleClose={handleClose}
				profileOpen={profileOpen}
				setProfileOpen={setProfileOpen}
			/>

			{/* <Popper className='my-account' open={open} anchorEl={anchorRef.current} transition>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
					>
						<Paper className=''>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id='menu-list-grow'>
									<MenuItem className='profile-btn' id='profile-link' onClick={handleClose}>
										<MaterialLink underline='none' to={'#'} onClick={() => setProfileOpen(true)}>
											<ProfileIcon />
											<Typography>{t('profile')}</Typography>
										</MaterialLink>
									</MenuItem>

									<MenuItem className='logout-btn' id='logout-link' onClick={logoutUser}>
										<LogoutIcon />
										<Typography>{t('logout')}</Typography>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper> */}
			{profileOpen && <Profile {...props} setProfileOpen={setProfileOpen} open={profileOpen} />}
		</Box>
	);
}
