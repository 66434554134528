import React, { useEffect } from 'react';
import { Box, Checkbox, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../Common/OKRButton';
import { BorderCheckboxIcon, CheckedIcon } from '../../config/svg/formElementIcons';
import { AnimatedIcon } from '../Common/AnimatedIcon';
import noRecordImg from '../../images/no-found-notes.svg';
import { getMonthDateYear, getPriorityName } from '../../config/utils';
import { globalTaskDrawer, updateRoute } from '../../action/common';
import { useDispatch } from 'react-redux';
import {
	AddIconSvg,
	AlertInfoIcon,
	DueDateIcon,
	HighPriorityIcon,
	LowPriorityIcon,
	MediumPriorityIcon,
} from '../../config/svg/CommonSvg';
import { GLOBAL_Task } from '../../config/app-url';
import { Enums } from '../../config/enums';

export const TaskWidget: React.FC<any> = (props: any) => {
	const { globalTaskData, globalTaskLoading, toDoTaskCount, handleCompleteTask } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const handleClick = (event: any) => {
		dispatch(updateRoute('/task'));
		props.switchUser(event, 'user', GLOBAL_Task);
	};
	const handleClickCreate = (event: any) => {
		dispatch(updateRoute('/task'));
		props.switchUser(event, 'user', GLOBAL_Task);
		dispatch(globalTaskDrawer(true));
	};
	const getPriorityClass = (priority: number) => {
		switch (priority) {
			case Enums.TWO:
				return 'medium-priority';
			case Enums.THREE:
				return 'high-priority';
			default:
				return 'low-priority';
		}
	};
	const getPriorityIcon = (priority: number) => {
		switch (priority) {
			case Enums.TWO:
				return <MediumPriorityIcon />;
			case Enums.THREE:
				return <HighPriorityIcon />;
			default:
				return <LowPriorityIcon />;
		}
	};
	return (
		<Box className='dashboard-task-widget white-box'>
			<Box className='dashboard-widget-head'>
				{globalTaskData && globalTaskData.length > 0 ? (
					<>
						<Typography variant='h6'>{t('toDoText')}</Typography>
						<OKRButton
							className='btn-link-type1'
							text={`${t('allTasksLabel')} (${toDoTaskCount})`}
							handleClick={handleClick}
						/>
					</>
				) : (
					<>
						{globalTaskLoading ? (
							<></>
						) : (
							<OKRButton
								className='btn-link-type1'
								text={`${t('createTask')}`}
								handleClick={handleClickCreate}
								icon={<AddIconSvg />}
							/>
						)}
					</>
				)}
			</Box>

			<Box className={`task-widget-list ${globalTaskData && globalTaskData.length > 0 ? '' : 'no-task'}`}>
				{globalTaskData && globalTaskData.length > 0 ? (
					<List className='task-list'>
						{globalTaskData &&
							globalTaskData.length > 0 &&
							globalTaskData?.slice(0, 6)?.map((item: any, index: any) => (
								<ListItem key={`task-list-${index}`}>
									<Box className='task-box'>
										<Checkbox
											className='task-checkbox'
											icon={<BorderCheckboxIcon />}
											checkedIcon={<CheckedIcon />}
											aria-label='Task Checkbox'
											checked={item.isCompleted}
											onChange={(e) => handleCompleteTask(e, item)}
										/>

										<Typography>
											<span>{item.taskName}</span>
											{/* {item.taskName.length > 80 ? (
												<Tooltip
													arrow
													title={item.taskName}
													aria-label='Task Name'
													classes={{ popper: 'tooltip-layout5' }}
												>
													<span>{item.taskName.substring(0, 80)}...</span>
												</Tooltip>
											) : (
												<span>{item.taskName}</span>
											)} */}
										</Typography>
									</Box>
									<Box className='dashboard-task-action'>
										<Box className='quick-links-btn'>
											<Box className='links-col priority-links'>
												<OKRButton
													className={`blue-btn ${getPriorityClass(item?.priorityId)}`}
													icon={getPriorityIcon(item?.priorityId)}
													title={`${getPriorityName(t, item?.priorityId)} `}
													id={'priority_btn'}
												/>
											</Box>
											<Box className='links-col due-on-links'>
												<OKRButton
													className='btn-link-type2'
													text={
														<>
															{item?.dueDateText}
															{item?.isOverDue && (
																<Typography className={`alert-due-on`}>
																	<AlertInfoIcon />
																</Typography>
															)}
														</>
													}
													icon={<DueDateIcon />}
													title={
														item?.isOverDue ? (
															<>
																Overdue <br /> ({item?.overDueText})
															</>
														) : (
															t('dueOn')
														)
													}
													tooltipClassName={item?.isOverDue ? 'tooltip-alert' : ''}
													id={'due_on_btn'}
												/>
											</Box>
										</Box>
									</Box>
								</ListItem>
							))}
					</List>
				) : (
					<>
						{globalTaskLoading ? (
							<>
								<Box className='no-todo-task'>
									<Typography>{t('loading')}</Typography>
								</Box>
							</>
						) : (
							<Box className='no-todo-task'>
								<Typography variant='h4'>{t('noHomeTaskHead')}</Typography>
								<Typography>{t('noHomeTaskDes')}</Typography>
								<Box className='no-task-img'>
									<AnimatedIcon
										className='no-record-img'
										width={'260'}
										type='image/svg+xml'
										alt={'No Task'}
										imagePath={noRecordImg}
									/>
								</Box>
							</Box>
						)}
					</>
				)}
			</Box>
			{/* <Box className='task-widget-footer'>
				{globalTaskData && globalTaskData.length > 0 ? (
					<OKRButton
						className='btn-primary'
						text={`${t('viewAllTasks')} (${toDoTaskCount})`}
						handleClick={handleClick}
					/>
				) : (
					<>
						{globalTaskLoading ? (
							<></>
						) : (
							<OKRButton className='btn-primary' text={`${t('createTask')}`} handleClick={handleClickCreate} />
						)}
					</>
				)}
			</Box> */}
		</Box>
	);
};
