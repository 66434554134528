import React from 'react';
import { Avatar, Box, Link, Slider, Typography, Tooltip } from '@material-ui/core';
import '../../../styles/dashboard.scss';
import '../style.scss';
import {
	getUserName,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getCurrentUserDetails,
} from '../../../config/utils';
import { TeamsPopperMenu } from './TeamsPopperMenu';
import { ProfileIcon } from '../../../config/svg/CommonSvg';
import { setLocalStorageItem } from '../../../services/StorageService';
import { USER_VIEW, HOME } from '../../../config/app-url';
import { ProgressDownArrow, ProgressUpArrow } from '../../../config/svg/ArrowSvg';
import { KeyResultIcon, ObjectiveIcon } from '../../../config/svg/MyGoalSvg';

export const TeamsDashboard: React.FC<any> = (props) => {
	const { handleMenuAction, teamsOKRDetails, t, setAnchorEl, setOpen, teamsOKR, handleCardClick, cardDashboard } =
		props;

	const userDetail = getUserDetails();
	const currentUser = getCurrentUserDetails();

	const getCardColor = (progress: any) => {
		let colorClass = 'grey';
		if (progress) {
			if (progress?.toLowerCase() === 'ff9401') {
				colorClass = 'yellow';
			} else if (progress?.toLowerCase() === 'ff0000') {
				colorClass = 'red';
			} else if (progress?.toLowerCase() === '07a363') {
				colorClass = 'green';
			}
		}
		return colorClass;
	};

	const handleProgressOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};

	const ProgressCard = (cardDetails: any) => {
		const { cardName, cardData, cardClassName, cardType, cardId } = cardDetails;

		return (
			<Box
				className={cardDashboard[cardType] ? 'progress-card active' : 'progress-card'}
				onClick={(e) => handleCardClick(e, cardType)}
				id={cardId}
			>
				<Box className='progress-card-top'>
					{cardData && cardData[0] && cardData[0].score ? (
						<Box className='user-progress-text'>
							<Typography variant='h2' id={`score-${cardId}`}>
								{`${cardData[0].score}`}
								<sup>%</sup>
							</Typography>
							{/* {cardData[0]?.isDeltaVisible &&
								(cardData[0].lastSevenDaysProgress && cardData[0].lastSevenDaysProgress > 0 ? (
									<Typography variant='body2' className='progress-started' id={`delta-score-${cardId}`}>
										<ProgressUpArrow />
										{`${cardData[0].lastSevenDaysProgress}%`}
									</Typography>
								) : (
									<></>
								))} */}
						</Box>
					) : (
						<Typography variant='h2'>
							0<sup>%</sup>
						</Typography>
					)}
					<Box className={`progress-card-name ${cardClassName}`}>
						<span>{cardName}</span>
					</Box>
				</Box>
				<Box className='progress-card-bottom'>
					<Box className='progress-bar'>
						<Slider
							className={cardClassName || ''}
							id={`prgbar-${cardId}`}
							value={cardData && cardData[0] && cardData[0].score ? cardData[0].score : 0}
							aria-label=''
							valueLabelDisplay='off'
							marks={false}
							disabled
						/>
					</Box>
					<Box className='okrs-count'>
						<Typography variant='h6'>{t('okrsLabel')}</Typography>
						<Typography variant='h6' id={`okr-count-${cardId}`}>
							{cardData && cardData[0] && cardData[0].objectiveCount ? cardData[0].objectiveCount : 0}
						</Typography>
					</Box>
				</Box>
			</Box>
		);
	};
	const redirectToPeoplePage = (event: any, _team: any) => {
		event.preventDefault();
		event.stopPropagation();
		const { employeeId } = userDetail;
		_team.employeeId = _team.leaderEmployeeId;
		if (employeeId !== teamsOKR?.leaderDetails?.employeeId) {
			setLocalStorageItem('currentUser', JSON.stringify(_team || userDetail));
			// setting redirect url for dashboard teamsOkr tab
			setLocalStorageItem(
				'backUrl',
				JSON.stringify({
					url: HOME,
					tabToOpen: 'TEAMSOKR',
					teamDetails: true,
				})
			);
			props.resetOkr();
			props.history.push(USER_VIEW);
			props.updateRoute(USER_VIEW);
		}
	};
	return (
		<Box className='pad-container'>
			<Box className='progress-cards team-progress-cards' id='dashboard'>
				<Box className='okr-tabpanel-card' id='team-progress-card'>
					<Box className='okr-tabpanel-card-top'>
						<Box className='user-info user-team-info'>
							<Box className='user-img'>
								{teamsOKRDetails?.teamLogo ? (
									<Avatar
										alt={`${teamsOKRDetails?.firstName} ${teamsOKRDetails?.lastName}`}
										src={teamsOKRDetails?.teamLogo}
									/>
								) : (
									<Avatar
										className='avatar-default'
										style={{
											backgroundColor: getRandomBackgroundColor(teamsOKRDetails?.backGroundColorCode),
											color: getRandomColor(teamsOKRDetails?.colorCode),
										}}
									>
										{getUserName({ firstName: '', lastName: '', fullName: teamsOKRDetails?.teamName || '' })}
									</Avatar>
								)}
							</Box>
							<Box className='user-info-details'>
								<Box className='card-team-name'>
									<Typography variant='h6'>{teamsOKRDetails.teamName} </Typography>
									<Typography variant='h2' id='team-okr-progress'>
										{`${
											teamsOKRDetails?.keyCount && teamsOKRDetails?.keyCount > 0
												? teamsOKRDetails?.teamAvgScore || 0
												: 0
										}`}
										<sup>%</sup>
									</Typography>
								</Box>

								<Typography variant='subtitle2'>
									{`Leader - `}
									{teamsOKRDetails?.leaderEmployeeId === 0 ? (
										teamsOKRDetails?.leaderName
									) : (
										<Link
											href='#'
											onClick={(event: any) => {
												event.preventDefault();
												event.stopPropagation();
												redirectToPeoplePage(event, teamsOKRDetails);
											}}
										>
											{teamsOKRDetails?.leaderName}
										</Link>
									)}
								</Typography>
							</Box>
						</Box>
						<Box className='okr-tabpanel-card-actions'>
							{/* {teamsOKRDetails.isDeltaVisible && (
								<>
									{teamsOKRDetails?.deltaProgress > 0 ? (
										<Tooltip title={`${t('deltaProgressHover')}`} arrow classes={{ popper: 'tooltip-layout9' }}>
											<Typography
												variant='body2'
												id='team-delta-prg-started'
												className='progress-started'
												onClick={handleProgressOpen}
											>
												<ProgressUpArrow />
												{`${teamsOKRDetails?.deltaProgress}%`}
											</Typography>
										</Tooltip>
									) : (
										<Tooltip title={`${t('deltaProgressHover')}`} arrow classes={{ popper: 'tooltip-layout9' }}>
											<Typography
												id='team-delta-prg-not-started'
												variant='body2'
												className='progress-not-started progress-started'
											>
												{teamsOKRDetails?.deltaProgress < 0 && <ProgressDownArrow />}
												{teamsOKRDetails?.deltaProgress}%
											</Typography>
										</Tooltip>
									)}
								</>
							)} */}
							<TeamsPopperMenu
								team={teamsOKRDetails}
								t={t}
								currentUser={currentUser}
								{...props}
								isTeamsDashboard={true}
								handleMenuAction={handleMenuAction}
							/>
						</Box>
					</Box>
					<Box className='okr-tabpanel-card-bottom'>
						<Box className='user-progress-bar'>
							<Slider
								className={`linear-slider ${getCardColor(teamsOKRDetails?.progressCode)}`}
								value={
									teamsOKRDetails?.keyCount && teamsOKRDetails?.keyCount > 0 ? teamsOKRDetails?.teamAvgScore || 0 : 0
								}
								aria-label=''
								valueLabelDisplay='off'
								marks={false}
								disabled
								id='team-avg-progress-bar'
							/>
						</Box>
						<Box className='tabpanel-okr-counts'>
							<Box className='okr-counts-text'>
								<Typography variant='h6' id='okr-count'>
									<ObjectiveIcon />
									{`${teamsOKRDetails?.okrCount || 0} Objective(s)`}
								</Typography>
								<Typography variant='h6' id='kr-count'>
									<KeyResultIcon />
									{`${teamsOKRDetails?.keyCount || 0} Key Result(s)`}
								</Typography>
							</Box>
							<Box className='user-count-txt'>
								<Tooltip title={`${t('contributorsLabel')}`} arrow>
									<span>
										<ProfileIcon />
										<Typography variant='h6' id='team-emp-count'>
											{teamsOKRDetails?.teamEmployeeCount || 0}
										</Typography>
									</span>
								</Tooltip>
							</Box>
						</Box>
					</Box>
				</Box>
				<ProgressCard
					cardType={'onTrack'}
					cardName={'On Track'}
					cardData={teamsOKRDetails?.onTrack}
					cardClassName={'green'}
					cardId={'team-on-track-card'}
				/>
				<ProgressCard
					cardType={'lagging'}
					cardName={'Lagging'}
					cardData={teamsOKRDetails?.lagging}
					cardClassName={'yellow'}
					cardId={'team-lagging-card'}
				/>
				<ProgressCard
					cardType={'atRisk'}
					cardName={'At Risk'}
					cardData={teamsOKRDetails?.atRisk}
					cardClassName={'red'}
					cardId={'team-at-risk-card'}
				/>
				<ProgressCard
					cardType={'notStarted'}
					cardName={'Not Started'}
					cardData={teamsOKRDetails?.notStarted}
					cardClassName={'grey'}
					cardId={'team-not-started-card'}
				/>
			</Box>
		</Box>
	);
};
