import { Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { OKRButton } from '../../../Common/OKRButton';
import { DeleteIcon, KababIcon, TickIcon, UnLockIcon } from '../../../../config/svg/CommonSvgIcon';
import { ReportingToIcon, RoleIcon } from '../../../../config/svg/formElementIcons';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/admin/bulkUserEdit.scss';
import { USER_ROLE_ID } from '../../../../config/users';
import { Enums } from '../../../../config/enums';
import { useSnackbar } from 'notistack';
import { PopperMenu } from '../../CommonComponent/PopperMenu';
import { checkPermission } from '../../../../config/rolePermission';

import { deleteUsers, editUsers } from '../../AdminConstant';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import { ChangeBulkRole } from './ChangeBulkRole';
import { ChangeReportingTo } from './ChangeReportingTo';

export const BulkUserEdit: React.FC<any> = (props) => {
	const { checkedUser, rolesDataSuccess, rolesDataResult, getActiveRoles, getPermissionResult, resetListing } = props;
	const { t } = useTranslation();
	const [openChangeRole, setOpenChangeRole] = useState<boolean>(false);
	const [openChangeReportingTo, setOpenChangeReportingTo] = useState<boolean>(false);
	const [rolesOptions, setRolesOptions] = useState<any>([]);
	const [selectedRole, setSelectedRole] = useState([{ roleId: 0 }]);

	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const [selectedReportingTo, setSelectedReportingTo] = useState<any>([]);

	const [openDialog, setOpenDialog] = useState({ type: '', open: false, id: 0 });
	const { enqueueSnackbar } = useSnackbar();

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const handleAPImessages = (messageList: any, variant: any) => {
		const responseAPI = messageList;
		const keys = Object.keys(responseAPI);
		const messages = keys.map((item) => responseAPI[item]);
		showApiMsgs(`${messages} `, variant);
	};

	useEffect(() => {
		if (rolesDataResult.length === 0) {
			getActiveRoles();
		} else {
			let defaultRole: any = [];
			let rolesOptionsList = rolesDataResult.map((rolesData: any) => {
				if (rolesData.roleId === USER_ROLE_ID) {
					defaultRole.push({
						...rolesData,
						label: rolesData.roleName,
						value: rolesData.roleId,
					});
				}
				return {
					...rolesData,
					label: rolesData.roleName,
					value: rolesData.roleId,
				};
			});
			setRolesOptions(rolesOptionsList);
			setSelectedRole(defaultRole);
		}
	}, [rolesDataSuccess]);

	const handleUpdateRole = async (roleId: number) => {
		const employeeIds = checkedUser.map((item: any) => {
			return item.employeeId;
		});
		const data = { roleId: roleId, employeeIds: employeeIds };
		const response = await props.changeUsersRole(data);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			setOpenChangeRole(false);
			handleAPImessages(response.data.messageList, 'success');
			resetListing();
		} else if (response.data.status === Enums.STATUS_BAD_REQUEST) {
			handleAPImessages(response.data.messageList, 'error');
		}
	};

	const handleUpdateReportingTo = async (employeeId: number) => {
		const employeeIds = checkedUser.map((item: any) => {
			return item.employeeId;
		});
		const data = { newReportingToId: employeeId, employeeIds: employeeIds };
		const response = await props.changeUsersReportingTo(data);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			setOpenChangeReportingTo(false);
			// Handle Success
			handleAPImessages(response.data.messageList, 'success');
			resetListing();
		} else if (response.data.status === Enums.STATUS_BAD_REQUEST) {
			handleAPImessages(response.data.messageList, 'error');
		}
	};

	const handleUnlockUser = async () => {
		setOpenDialog({ type: '', open: false, id: 0 });
		const response = await props.unlockUsers(checkedUser);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			// Handle Success
			handleAPImessages(response.data.messageList, 'success');
			resetListing();
		} else if (response.data.status === Enums.STATUS_BAD_REQUEST) {
			handleAPImessages(response.data.messageList, 'error');
		}
	};

	const handleDeleteUser = async () => {
		setOpenDialog({ type: '', open: false, id: 0 });
		const employeeIds = checkedUser.map((item: any) => {
			return item.employeeId;
		});
		const response = await props.deleteUsers(employeeIds);
		if (response.data.status === Enums.STATUS_SUCCESS) {
			// Handle Success
			handleAPImessages(response.data.messageList, 'success');
			props.getAllOrganisations();
			props.getLicenseDetails();
			resetListing();
		} else if (response.data.status === Enums.STATUS_BAD_REQUEST) {
			handleAPImessages(response.data.messageList, 'error');
		}
	};

	const handleBulkConfirmClick = () => {
		if (openDialog.type === 'delete') {
			handleDeleteUser();
		} else if (openDialog.type === 'unlockUsers') {
			handleUnlockUser();
		}
	};

	const handleDialogCancelClick = async (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		if (type === 1 && openDialog.type === 'changeRole') {
			handleUpdateRole(selectedRole[0].roleId);
		} else if (type === 1 && openDialog.type === 'changeReportingTo') {
			if (selectedReportingTo.length > 0) {
				handleUpdateReportingTo(selectedReportingTo[0].employeeId);
			}
		}
		setOpenDialog({ type: '', open: false, id: 0 });
	};

	//returns Heading and sub Heading Text
	const getDialogHeading = (type: any, head: boolean = true) => {
		if (type === 'delete') {
			return head ? t('deletingUser') : t('userNotLogin');
		} else if (type === 'unlockUsers') {
			return head ? t('unlockUsersLabel') : t('unlockUsersFor48H');
		}
	};

	return (
		<Fragment>
			<Box className='bulk-user-edit-bar'>
				<Box className='bulk-user-selected'>
					<Typography variant='body1'>
						<TickIcon />
						{checkedUser && checkedUser.length} {t('selectedLabel')}
					</Typography>
				</Box>
				<Box className='bulk-user-action'>
					<List component='ul' disablePadding>
						{checkPermission(getPermissionResult?.employeePermissions, editUsers) ? (
							<>
								<ListItem className={openChangeRole ? 'active-whiteColor' : ''}>
									<OKRButton
										icon={<RoleIcon />}
										title={t('changeRoleLabel')}
										handleClick={() => setOpenChangeRole(true)}
										className='action-btn'
									></OKRButton>
									{openChangeRole ? (
										<ChangeBulkRole
											{...props}
											t={t}
											rolesOptions={rolesOptions}
											selectedRole={selectedRole}
											setSelectedRole={setSelectedRole}
											confirmUpdate={(value: any) => setOpenDialog({ type: 'changeRole', open: true, id: value })}
											setOpenChangeRole={setOpenChangeRole}
										/>
									) : (
										<></>
									)}
								</ListItem>
								<ListItem className={openChangeReportingTo ? 'active-whiteColor' : ''}>
									<OKRButton
										icon={<ReportingToIcon />}
										title={t('changeReportingLabel')}
										handleClick={() => setOpenChangeReportingTo(true)}
										className='action-btn'
									></OKRButton>
									{openChangeReportingTo ? (
										<ChangeReportingTo
											{...props}
											t={t}
											selectedReportingTo={selectedReportingTo}
											setSelectedReportingTo={setSelectedReportingTo}
											reportingToOptions={reportingToOptions}
											setReportingToOptions={setReportingToOptions}
											confirmUpdate={(value: any) =>
												setOpenDialog({ type: 'changeReportingTo', open: true, id: value })
											}
											setOpenChangeReportingTo={setOpenChangeReportingTo}
										/>
									) : (
										<></>
									)}
								</ListItem>
								{/* <ListItem>
									<OKRButton
										icon={<UnLockIcon />}
										title={t('unlockLabel')}
										className='action-btn'
										handleClick={() => setOpenDialog({ type: 'unlockUsers', open: true, id: 0 })}
									></OKRButton>
								</ListItem> */}
							</>
						) : (
							<></>
						)}

						{checkPermission(getPermissionResult?.employeePermissions, deleteUsers) ? (
							<ListItem>
								<OKRButton
									icon={<DeleteIcon />}
									title={t('delete')}
									className='action-btn'
									handleClick={() => setOpenDialog({ type: 'delete', open: true, id: 0 })}
								></OKRButton>
							</ListItem>
						) : (
							<></>
						)}
					</List>
				</Box>
			</Box>

			{/* Confirm delete dialog box */}
			{openDialog.open && (
				<DialogComponent
					dialogFor={openDialog.type}
					module={
						openDialog.type === 'changeRole' || openDialog.type === 'changeReportingTo' ? 'information' : 'confirmation'
					}
					message={
						openDialog.type === 'changeRole'
							? t('changeRoleAlert')
							: openDialog.type === 'changeReportingTo'
							? t('changeReportingToAlert')
							: ''
					}
					modalOpen={openDialog.open}
					handleCloseModal={handleDialogCancelClick}
					matchString={
						openDialog.type === 'delete'
							? Enums.DELETE_TEXT
							: openDialog.type === 'unlockUsers'
							? Enums.UNLOCK_TEXT
							: ''
					}
					handleConfirmClick={handleBulkConfirmClick}
					headingText={getDialogHeading(openDialog.type, true)}
					subHeading={getDialogHeading(openDialog.type, false)}
				/>
			)}
		</Fragment>
	);
};
