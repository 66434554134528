import React, { Fragment, useEffect, useState } from 'react';
import { Avatar, Box, List, ListItem, TablePagination, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NoRecord } from '../../CommonComponent/NoRecord';
import { getMonthDateYearTime, getUserName } from '../../../../config/utils';

export const MissionVisionHistoryList: React.FC<any> = (props: any) => {
	const { missionVisionHistory } = props;
	const { t } = useTranslation();

	return (
		<Box className='mv-his-listing-drawer-inner'>
			{missionVisionHistory && missionVisionHistory.length > 0 ? (
				missionVisionHistory.length > 0 &&
				missionVisionHistory
					.filter((record: any) => record.missionDescription || record.visionDescription)
					.map((item: any) => {
						return (
							<Box className='mv-his-tile'>
								<Box className='mv-his-tile-left'>
									{item && item.imagePath ? (
										<Avatar
											className='avatar-default'
											alt={`${item?.firstName} ${item?.lastName}`}
											src={item?.imagePath}
										/>
									) : (
										<Avatar className='avatar-default'>
											{getUserName({
												firstName: item?.firstName,
												lastName: item?.lastName,
												fullName: '',
											})}
										</Avatar>
									)}
								</Box>
								<Box className='mv-his-tile-right'>
									<Box className='mv-his-tile-right-top'>
										<Box className='user-info'>
											<Typography>
												{item?.firstName} {item?.lastName}
											</Typography>
											<Typography variant='subtitle2'>{item.emailId}</Typography>
										</Box>
										<Box className='date'>
											<Typography>{getMonthDateYearTime(item.createdOn)}</Typography>
										</Box>
									</Box>
									<Box className='mv-his-tile-right-btm'>
										{item.missionDescription ? (
											<Box className='mission-txt long-text'>
												<Typography
													variant='body1'
													dangerouslySetInnerHTML={{
														__html: `<strong>${t('missionLabel')}:</strong><span>${item.missionDescription}</span>`,
													}}
												></Typography>
											</Box>
										) : (
											<></>
										)}
										{item.visionDescription ? (
											<Box className='vision-txt long-text'>
												<Typography
													variant='body1'
													dangerouslySetInnerHTML={{
														__html: `<strong>${t('visionLabel')}:</strong><span>${item.visionDescription}</span>`,
													}}
												></Typography>
											</Box>
										) : (
											<></>
										)}
									</Box>
								</Box>
							</Box>
						);
					})
			) : (
				<></>
			)}
		</Box>
	);
};
