import React, { Fragment, useRef, useState, useEffect } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/admin/settings/recognizeSettings.scss';
import { MainDrawer } from '../../CommonComponent/MainDrawer';
import { RecognizeTemplateDrawer } from './RecognizeTemplateDrawer';
import AvatarEditor from 'react-avatar-editor';
import ImageEditor from './ImageEditor';
import { createUUID } from '../../../../config/utils';
import { useDispatch } from 'react-redux';
import { addManageBadges, manageBadgesStatus, updatedManageBadges } from '../../../../action/organization';
import { useSnackbar } from 'notistack';
import AlertDialog from '../../../Common/Dialog';
import { getRequestImage } from '../../../../config/auth';

export const CreateBadge: React.FC<any> = (props: any) => {
	const {
		setOpenRecognizeDrawer,
		openRecognizeDrawer,
		handleGetManagesList,
		editableDetails,
		setLoader,
		setEditableDetails,
	} = props;
	const { t } = useTranslation();
	//== ====Upload Logo Functions==== ==//
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const [isImageEditor, setIsImageEditor] = useState(false);
	const imagePath: any = '';
	const [uploadingPic, setUploadingPic] = useState(false);
	const avatarEditorRef = useRef<AvatarEditor>(null);
	const [imageName, setImageName] = useState('');
	const [image, setImage] = useState(imagePath);
	const [formError, setFormError] = useState({});
	const [teamData, setTeamData] = useState<any>({ formFile: '', imageName: '' });
	const [uploadedImage, setUploadedImage] = useState('');
	const [formEdited, setFormEdited] = useState<boolean>(false);

	const [badgeFormData, setBadgeFormData] = useState<any>({
		Name: '',
		IsOnlyManager: false,
		IsActive: true,
		CategoryTypeId: 1,
	});

	const [modalProps, setModalProps] = useState<any>({
		open: false,
		type: '',
		message: '',
		module: '',
		date: '',
		isCancel: false,
	});

	useEffect(() => {
		if (editableDetails) {
			setBadgeFormData({
				Name: editableDetails?.name || '',
				IsOnlyManager: editableDetails?.isOnlyManager,
				IsActive: editableDetails?.isActive,
				CategoryTypeId: editableDetails?.categoryTypeId || 1,
			});
			// setImage(editableDetails?.filePath || '');
			editableDetails?.filePath && getUpdatedImage(editableDetails);
		} else {
			resetCreateBadgeForm();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editableDetails]);

	const resetCreateBadgeForm = () => {
		setBadgeFormData({
			Name: '',
			IsOnlyManager: false,
			IsActive: true,
			CategoryTypeId: 1,
		});
		setImage('');
		setTeamData({ formFile: '', imageName: '' });

		setUploadedImage('');
		setFormError({});
	};
	const createFile = async (filePath: any) => {
		try {
			const response = await getRequestImage(filePath, undefined, { responseType: 'blob', isProfileUrl: true });
			return new File([response.data], 'file.jpg', { type: 'image/jpeg' });
		} catch (error) {
			return null;
		}
	};

	const getUpdatedImage = (imageDetails: any) => {
		const imgPath = imageDetails?.filePath;
		createFile(imgPath).then((image: any) => {
			if (image) {
				setImage(image);
				setImageName(imageDetails?.fileName || '');

				setUploadedImage(image || '');
				const formData = new FormData();
				const name = imageName && !imageName.includes('svg') ? imageName : createUUID() + '.png';
				formData.append('formFile', image, imageDetails?.fileName || name);
				setTeamData({
					formFile: imageDetails?.fileName || '',
					imageName: '',
					imagePath: formData || image || '',
				});
			}
		});
	};

	const showImageEditor = (open: any) => {
		setIsImageEditor(open);
	};
	const handleFileChange = async (e: any, files: any) => {
		setFormError({});
		const file = files ? files : e.target.files[0];
		const fSize = file.size;
		const size = Math.round(fSize / 1024);
		const fileType = file.type;
		const format = ['image/jpg', 'image/svg+xml', 'image/png', 'image/jpeg'];
		if (size >= 1024) {
			setFormError({ fileSize: true });
		} else if (!format.includes(fileType.toLowerCase())) {
			setFormError({ fileType: true });
		} else {
			let fileData = { ...teamData, imageName: file.name, imagePath: file };
			fileData = files ? fileData : { ...teamData, imagePath: file };
			setFormError({});
			setTeamData(fileData);
		}
	};
	const updateImageDataImagePath = (formData: any, imageBase64: any) => {
		if (formData && imageBase64) {
			formData && setTeamData({ ...teamData, imagePath: formData });
			imageBase64 && setUploadedImage(imageBase64);
			setImage(imageBase64);
		} else {
			setTeamData({ ...teamData, imagePath: '' });
			setUploadedImage('');
			setImage('');
		}
	};
	const updateImage = async (file: any, imageBase64: any) => {
		if (file && imageBase64) {
			const formData = new FormData();
			const name = imageName && !imageName.includes('svg') ? imageName : createUUID() + '.png';
			formData.append('formFile', file, name);
			updateImageDataImagePath(formData, imageBase64);
		} else {
			updateImageDataImagePath('', '');
		}
	};
	const DataURIToBlob = (dataURI: any) => {
		const splitDataURI = dataURI.split(',');
		const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

		return new Blob([ia], { type: mimeString });
	};
	const handleImageEditorClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		if (type === 'save') {
			setUploadingPic(true);
			try {
				if (image && avatarEditorRef && avatarEditorRef.current) {
					setFormEdited(true);
					const imageBase64 = avatarEditorRef.current?.getImageScaledToCanvas().toDataURL(image.type || 'image/png');
					const file = DataURIToBlob(imageBase64);
					updateImage(file, imageBase64);
				} else {
					updateImage('', '');
				}
				showImageEditor(false);
			} catch (err) {
				setUploadingPic(false);
			}
		} else {
			showImageEditor(false);
			setImage(uploadedImage);
		}
	};
	const validateForm = () => {
		let errorList: any = {};
		let isValid = true;
		if (badgeFormData.Name.trim() === '') {
			errorList.Name = 'Please add Badge Name';
			isValid = false;
		}
		if (image === '') {
			errorList.image = 'Please upload badge image';
			isValid = false;
		}
		setFormError(errorList);
		return isValid;
	};
	const handleDrawerClose = (event: any, type: string) => {
		if (type === 'save') {
			if (validateForm()) {
				setOpenRecognizeDrawer(false);
				saveForm();
			}
		} else if (type === 'cancel' || type === 'close') {
			setOpenRecognizeDrawer(false);
			resetCreateBadgeForm();
		}
	};

	const saveForm = async () => {
		try {
			setLoader(true);
			const formFile = new FormData();
			formFile.append('File', teamData.imagePath.get('formFile'));
			formFile.append('Name', badgeFormData.Name);
			formFile.append('IsActive', badgeFormData.IsActive);
			formFile.append('IsOnlyManager', badgeFormData.IsOnlyManager);
			formFile.append('CategoryTypeId', badgeFormData.CategoryTypeId);

			let response: any = null;
			if (editableDetails && editableDetails?.recognitionCategoryId) {
				if (editableDetails?.isDefault) {
					const requestPayload = {
						recognitionCategoryId: editableDetails?.recognitionCategoryId,
						isActive: badgeFormData.IsActive,
						IsOnlyManager: badgeFormData.IsOnlyManager,
					};
					response = await dispatch(manageBadgesStatus(requestPayload));
				} else {
					formFile.append('RecognitionCategoryId', editableDetails?.recognitionCategoryId);
					response = await dispatch(updatedManageBadges(formFile));
				}
			} else {
				response = await dispatch(addManageBadges(formFile));
			}
			if (Boolean(response) && response.data.status === 200) {
				setLoader(false);
				resetCreateBadgeForm();
				handleGetManagesList();
				setEditableDetails(null);
				setFormEdited(false);
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				let messages = keys.map((item) => messageList[item]);

				if (editableDetails && editableDetails?.isDefault) {
					messages = ['Badge updated successfully.'];
				}

				enqueueSnackbar(messages, {
					variant: 'success',
					autoHideDuration: 5000,
				});
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || t('someErrorOccurred'), {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setEditableDetails(null);
				setFormEdited(false);
				setLoader(false);
			}
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setEditableDetails(null);
			setFormEdited(false);
			console.error(error);
		}
	};

	const handleDeleteLogo = () => {
		updateImage('', '');
	};

	const isBadgeUpdated = (event: any) => {
		// if (badgeFormData.Name.trim() !== '' || teamData.imagePath) {
		if (formEdited) {
			setModalProps({
				open: true,
				type: 'KRAttr',
				message: t('unSavedItemAlert'),
				module: 'user',
				date: '',
			});
		} else {
			setEditableDetails(null);
			setFormEdited(false);
			handleDrawerClose(event, 'close');
		}
	};
	const handleCloseGoalAttributeModal = (_event: any, type: Number) => {
		if (type === 1) {
			setFormEdited(false);
			setEditableDetails(null);
			handleDrawerClose({}, 'close');
		}
		setModalProps({ open: false, type: '', message: '', module: '', date: '' });
	};

	return (
		<>
			{openRecognizeDrawer && (
				<MainDrawer
					open={openRecognizeDrawer}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t('recognition')}
					//loader={ratingFormLoader}
					children={
						<Box className='drawer-inner-content'>
							<RecognizeTemplateDrawer
								showImageEditor={showImageEditor}
								{...props}
								image={image}
								setBadgeFormData={setBadgeFormData}
								badgeFormData={badgeFormData}
								setFormError={setFormError}
								formError={formError}
								setFormEdited={setFormEdited}
								editableDetails={editableDetails}
							/>
						</Box>
					}
					saveButtonText={editableDetails && editableDetails?.recognitionCategoryId ? t('update') : t('addBtn')}
					rightCloseBtn={false}
					isSaveDraft={false}
					//isSaveButtonDisabled={loader}
					//isDraftButtonDisabled={loader}
					handleDrawerClose={(event: any) => isBadgeUpdated(event)}
					handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
					handleCancelClick={(event: any) => isBadgeUpdated(event)}
				/>
			)}
			{isImageEditor && (
				<MainDrawer
					open={isImageEditor}
					transitionDuration={{ enter: 500, exit: 1000 }}
					children={
						<Box className='drawer-inner-content'>
							<ImageEditor
								{...props}
								imagePath={imagePath}
								handleFileChange={handleFileChange}
								removeLogo={() => {}}
								setImage={setImage}
								image={image}
								avatarEditorRef={avatarEditorRef}
								handleDeleteLogo={handleDeleteLogo}
							/>
						</Box>
					}
					saveButtonText={t('saveBtn')}
					rightCloseBtn={true}
					drawerClassName={'main-drawer-panel main-drawer-subpanel'}
					handleDrawerClose={(event: any) => handleImageEditorClose(event, 'close')}
					handleSaveClick={(event: any) => handleImageEditorClose(event, 'save')}
					handleCancelClick={(event: any) => handleImageEditorClose(event, 'cancel')}
				/>
			)}
			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'goals'}
					message={modalProps.message}
					handleCloseModal={handleCloseGoalAttributeModal}
					modalOpen={modalProps.open}
					isCancel={modalProps?.isCancel || false}
				/>
			)}
		</>
	);
};
