import React from 'react';
import { Typography, Avatar, Tooltip, Box, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProgressBar } from '../Common/ProgressBar';
import {
	confidenceIcon,
	getCurrentUserDetails,
	getOkrProgressDetails,
	getPercentageUpperValue,
	getRandomBackgroundColor,
	getRandomColor,
	getUserName,
} from '../../config/utils';
import { KrConfidence } from '../Common/KrConfidence';
import { OKRButton } from '../Common/OKRButton';
import { AlignOKRIcon, parentTeamIcon } from '../../config/svg/CommonSvg';
import { UserAvatarGroup } from '../Common/UserAvatarGroup';

const nodeWidth = 340;
const nodeHeight = 150;
const CustomNodeContent = (props: any) => {
	const { virtualLink, toggleVirtualLink, okrMasterData } = props;
	const { t } = useTranslation();

	const getTitleName = () => {
		let title = props.data.type === 5 ? props.data.linkedObjectiveName : props.data?.objectiveName;
		return (
			<Box className='okr-title'>
				{props.data.type !== 3 && props.data.type !== 4 && (
					<Tooltip arrow title={title?.length > 80 ? title : ''}>
						<Typography variant='h5'>{title?.length > 80 ? `${title.substring(0, 80)}...` : `${title}`}</Typography>
					</Tooltip>
				)}
			</Box>
		);
	};
	const currentUser: any = getCurrentUserDetails();

	const getKrInfoHeading = () => {
		return props.data.type === 4 ? (
			<Box className='alignment-info'>
				<Box className='kr-align-status'>
					<Typography variant='subtitle2'>{<>{props.data.assignedCount} Assigned</>}</Typography>
					<Typography variant='subtitle2'>{<>{props.data.alignedCount} Aligned</>}</Typography>
				</Box>
				<Box className='kr-info'>
					<Tooltip arrow title={props.data.keyDescription}>
						<Typography variant='subtitle2'>{'Kr Info'}</Typography>
					</Tooltip>
				</Box>
			</Box>
		) : (
			<></>
		);
	};

	const getVirtualIcon = () => {
		return props.data.type === 2 && virtualLink && props.data.virtualOKRs.length > 0 ? (
			<Box
				className='virtual-icon'
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
					props.toggleVirtualLink(props.data);
				}}
			>
				<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M12.3755 4.09714C11.9823 4.48022 11.6412 5.20564 11.6412 6.68744C11.6412 8.1686 11.9821 8.91892 12.3865 9.32091C12.7927 9.72474 13.552 10.0648 15.0442 10.0648C16.5356 10.0648 17.3306 9.72493 17.7647 9.30573C18.1881 8.89673 18.5417 8.14636 18.5417 6.68744C18.5417 5.22821 18.1882 4.50248 17.7757 4.11215C17.3459 3.70542 16.5505 3.37494 15.0442 3.37494C13.5367 3.37494 12.7773 3.70582 12.3755 4.09714ZM10.2823 1.94809C11.4896 0.772214 13.1816 0.374939 15.0442 0.374939C16.908 0.374939 18.6113 0.772615 19.8377 1.93308C21.0814 3.10995 21.5417 4.79047 21.5417 6.68744C21.5417 8.58471 21.0815 10.273 19.8488 11.4636C18.6266 12.644 16.923 13.0648 15.0442 13.0648C13.1664 13.0648 11.4742 12.6442 10.2714 11.4484C9.06674 10.2508 8.6412 8.56247 8.6412 6.68744C8.6412 4.81304 9.06663 3.13221 10.2823 1.94809ZM19.1119 19.4976L10.9765 19.4976C8.42992 19.4976 6.375 21.5525 6.375 24.0769V26.6249L23.7134 26.6249V24.0769C23.7134 21.5525 21.6585 19.4976 19.1119 19.4976ZM10.9765 16.4976L19.1119 16.4976C23.3049 16.4976 26.7134 19.8852 26.7134 24.0769V28.1249C26.7134 28.9534 26.0419 29.6249 25.2134 29.6249L4.875 29.6249C4.47718 29.6249 4.09564 29.4669 3.81434 29.1856C3.53304 28.9043 3.375 28.5228 3.375 28.1249V24.0769C3.375 19.8852 6.78358 16.4976 10.9765 16.4976Z'
						fill='#292929'
					/>
				</svg>
			</Box>
		) : (
			<></>
		);
	};

	const getProgressColor = (krData: any) => {
		const { color } = getOkrProgressDetails({
			value: krData.realScore,
			dueDate: krData.dueDate,
		});
		return color;
	};

	const getKrOrcontributorList = () => {
		let nameKey = props.data.nameKey ? props.data.nameKey : 'keyDescription';
		return (props.data.type === 2 || props.data.type === 3 || props.data.type === 4) && props.data.okrViewKeyResults ? (
			props.data.okrViewKeyResults.map((krData: any) => {
				return (
					<Box
						className={`kr-list-item ${
							(props.data.type === 2 || props.data.type === 4 || props.data.type === 3) &&
							props.activeKrId &&
							props.activeKrId.includes(krData.id)
								? 'active'
								: ''
						} ${
							!krData.teamId &&
							(!krData.child.parentChildContributors || krData.child.parentChildContributors.length === 0)
								? 'no-team-con'
								: krData.teamId &&
								  (!krData.child.parentChildContributors || krData.child.parentChildContributors.length === 0)
								? 'only-team'
								: !krData.teamId &&
								  krData.child.parentChildContributors &&
								  krData.child.parentChildContributors.length > 0
								? 'only-contributor'
								: ''
						}`}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							props.loadKRList(props.data.id, krData, props.data);
						}}
					>
						<Box className='kr-col1'>
							<Tooltip arrow title={'Aligned'}>
								<Typography component={'span'} className={'align-icon'}>
									{krData.isAligned && <AlignOKRIcon />}
								</Typography>
							</Tooltip>
							{props.data.type === 2 ? (
								<Box className='align-kr-name'>
									<Tooltip arrow title={`${krData.keyDescription}`}>
										<Typography variant='body2'>{krData.keyDescription}</Typography>
									</Tooltip>
								</Box>
							) : (
								<Box className='user-info'>
									<Box className='user-img'>
										{krData.ownerImagePath ? (
											<Avatar src={krData.ownerImagePath} className='team-avatar-smaller' />
										) : (
											<Avatar
												style={{
													backgroundColor: getRandomBackgroundColor(null),
													color: getRandomColor(null),
												}}
												className='team-avatar-smaller'
											>
												{getUserName({
													fullName: krData.ownerName,
													firstName: '',
													lastName: '',
												})}
											</Avatar>
										)}
									</Box>
									<Box className='user-info-details'>
										<Tooltip arrow title={krData[nameKey]?.length > 20 ? krData[nameKey] : ''}>
											<Typography
												variant='body2'
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
													props.loadKRList(props.data.id, krData, props.data);
												}}
											>
												{krData[nameKey]?.length > 20 ? `${krData[nameKey].substring(0, 20)}...` : `${krData[nameKey]}`}
											</Typography>
										</Tooltip>
									</Box>
								</Box>
							)}
						</Box>
						<Box className='kr-col2'>
							<Box className='teams-avatars teams-chips'>
								{krData.teamName && krData.teamId ? (
									<>
										<Tooltip title={krData.teamName} arrow>
											<Chip
												avatar={
													<>
														{krData.teamLogoImagePath ? (
															<Avatar src={krData.teamLogoImagePath} className='team-avatar-smaller' />
														) : (
															<Avatar
																style={{
																	backgroundColor: getRandomBackgroundColor(krData.backGroundColorCode),
																	color: getRandomColor(krData.colorCode),
																}}
																className='team-avatar-smaller'
															>
																{getUserName({
																	fullName: krData.teamName,
																	firstName: '',
																	lastName: '',
																})}
															</Avatar>
														)}
													</>
												}
												style={{
													backgroundColor: getRandomBackgroundColor(krData.backGroundColorCode),
													color: getRandomColor(krData.colorCode),
												}}
											/>
										</Tooltip>
									</>
								) : (
									''
								)}
								{krData.parentTeamDetails && krData.parentTeamDetails.teamId ? (
									<Tooltip arrow title={krData.parentTeamDetails.teamName}>
										<Chip
											avatar={
												<>
													{parentTeamIcon(krData.parentTeamDetails.colorCode)}
													{krData.parentTeamDetails.teamLogoImagePath ? (
														<Avatar src={krData.parentTeamDetails.teamLogoImagePath} className='team-avatar-smaller' />
													) : (
														<Avatar
															style={{
																backgroundColor: getRandomBackgroundColor(krData.parentTeamDetails.backGroundColorCode),
																color: getRandomColor(krData.parentTeamDetails.colorCode),
															}}
															className='team-avatar-smaller parent-link'
														>
															{getUserName({
																fullName: krData.parentTeamDetails.teamName,
																firstName: '',
																lastName: '',
															})}
														</Avatar>
													)}
												</>
											}
											style={{
												backgroundColor: getRandomBackgroundColor(krData.parentTeamDetails.backGroundColorCode),
												color: getRandomColor(krData.parentTeamDetails.colorCode),
											}}
										/>
									</Tooltip>
								) : (
									''
								)}
							</Box>
							{props.data.type === 2 && krData && krData.child.parentChildContributors.length > 0 && (
								<UserAvatarGroup
									{...props}
									contributorDetails={krData.child.parentChildContributors}
									avatarView='badge'
									showContributorGroup={false}
									okr={krData}
									addButtonClassName='add-btn grey-out-section'
									avatarClassName=''
									isButtonVisible={false}
									max={3}
									loginUserAvatar={krData}
									currentUser={currentUser}
									contributorDetailsType={true}
									isOnHoverVisible={true}
									viewType='okrList'
									alignmentMapTab={true}
									contributorClassName={'contributor-details-popover alignment-contributors-popover'}
									//virtualAlignmentList={virtualAlignmentList}
									//virtualAlignmentListPopUp={virtualAlignmentListPopUp}
									//getVirtuals={getVirtuals}
									fullEmailId={true}
								/>
							)}
						</Box>
						<Box className='kr-col3'>
							<Box className='progress-value'>
								<Typography variant='body2' style={{ color: getProgressColor(krData) }}>
									{getPercentageUpperValue(krData.realScore || 0)}%
								</Typography>
							</Box>
						</Box>
					</Box>
				);
			})
		) : (
			<></>
		);
	};
	return (
		<foreignObject
			id={`org-foreignObject-card_${props.data.id}`}
			className={`alignment-card-wrap_${props.data.id}`}
			width={nodeWidth}
			height={nodeHeight}
		>
			<Box
				onClick={(event) => {
					props.loadOkr(props.data.id);
				}}
				className={'alignment-okr-card'}
			>
				{getVirtualIcon()}
				{getKrInfoHeading()}
				{((props.data.type === 2 && props.data.teams) || (props.data.type !== 3 && props.data.type !== 4)) && (
					<Box className='card-top'>
						{props.data.isDefaultGoalObjective && (
							<Chip className='default-gray-chip' label={okrMasterData?.defaultOkrTagName} />
						)}
						{getTitleName()}
						<Box className='card-middle'>
							{props.data.type !== 1 && props.data.teamName && props.data.teamId ? (
								<Box className='teams-avatars teams-chips'>
									<Tooltip arrow title={props.data.teamName}>
										<Chip
											avatar={
												<>
													{props.data.teamLogoImagePath ? (
														<Avatar src={props.data.teamLogoImagePath} className='team-avatar-smaller' />
													) : (
														<Avatar
															style={{
																backgroundColor: getRandomBackgroundColor(props.data.backGroundColorCode),
																color: getRandomColor(props.data.colorCode),
															}}
															className='team-avatar-smaller'
														>
															{getUserName({
																fullName: props.data.teamName,
																firstName: '',
																lastName: '',
															})}
														</Avatar>
													)}
												</>
											}
											style={{
												backgroundColor: getRandomBackgroundColor(props.data.backGroundColorCode),
												color: getRandomColor(props.data.colorCode),
											}}
										/>
									</Tooltip>
								</Box>
							) : (
								''
							)}
							{props.data.type !== 1 && props.data.parentTeamDetails && props.data.parentTeamDetails.teamId ? (
								<Box className='teams-avatars teams-chips'>
									<Tooltip arrow title={props.data.parentTeamDetails.teamName}>
										<Chip
											avatar={
												<>
													{parentTeamIcon(props.data.parentTeamDetails.colorCode)}
													{props.data.parentTeamDetails.teamLogoImagePath ? (
														<Avatar
															src={props.data.parentTeamDetails.teamLogoImagePath}
															className='team-avatar-smaller'
														/>
													) : (
														<Avatar
															style={{
																backgroundColor: getRandomBackgroundColor(
																	props.data.parentTeamDetails.backGroundColorCode
																),
																color: getRandomColor(props.data.parentTeamDetails.colorCode),
															}}
															className='team-avatar-smaller parent-link'
														>
															{getUserName({
																fullName: props.data.parentTeamDetails.teamName,
																firstName: '',
																lastName: '',
															})}
														</Avatar>
													)}
												</>
											}
											style={{
												backgroundColor: getRandomBackgroundColor(props.data.parentTeamDetails.backGroundColorCode),
												color: getRandomColor(props.data.parentTeamDetails.colorCode),
											}}
										/>
									</Tooltip>
								</Box>
							) : (
								''
							)}
							{props.data.type === 5 && props.data.virtualOwnerName && (
								<Box className='virtual-avatar'>
									<Box className='user-info'>
										<Box className='user-img'>
											{props.data.virtualOwnerImagePath ? (
												<Avatar src={props.data.virtualOwnerImagePath} className='team-avatar-smaller' />
											) : (
												<>
													<Avatar
														style={{
															backgroundColor: getRandomBackgroundColor(props.data.backGroundColorCode),
															color: getRandomColor(props.data.colorCode),
														}}
														className='team-avatar-smaller'
													>
														{getUserName({
															fullName: props.data.virtualOwnerName,
															firstName: '',
															lastName: '',
														})}
													</Avatar>
												</>
											)}
										</Box>
										<Box className='user-info-details'>
											<Tooltip
												arrow
												title={props.data.virtualOwnerName?.length > 20 ? props.data.virtualOwnerName : ''}
											>
												<Typography variant='body2'>
													{props.data.virtualOwnerName?.length > 20
														? `${props.data.virtualOwnerName.substring(0, 20)}...`
														: `${props.data.virtualOwnerName}`}
												</Typography>
											</Tooltip>
										</Box>
									</Box>
								</Box>
							)}
						</Box>
						{props.data.type !== 3 && props.data.type !== 4 && (
							<Box className='alignment-card-progress'>
								<ProgressBar score={props.data.score} dueDate={props.data.dueDate} t={t} />
							</Box>
						)}
					</Box>
				)}
				{(props.data.type === 2 || props.data.type === 3 || props.data.type === 4) && props.data.okrViewKeyResults ? (
					<Box className='card-bottom'>
						<Box className='kr-list'>{getKrOrcontributorList()}</Box>
					</Box>
				) : (
					<></>
				)}
			</Box>
		</foreignObject>
	);
};

export default CustomNodeContent;
