import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SOObjectiveList } from './SOObjectiveList';
import { NoRecord } from '../../Admin/CommonComponent/NoRecord';
import { getCycleDefaultSelection, getUserDetails } from '../../../config/utils';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';

export const LinkSO: React.FC<any> = (props: any) => {
	const {
		objectiveListPeopleTab,
		setObjectiveListPeopleTab,
		objectiveListMyTab,
		setObjectiveListMyTab,
		cycleId,
		cycleDetail,
		currentCycleYear,
		cycleDuration,
		getObjectiveListBasedOnCycleChange,
		okrMasterData,
		soPermissionDetails,
		soObjCreateTabSelected,
	} = props;
	const { t } = useTranslation();
	const userDetail: any = getUserDetails();

	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [searchText, setSearchText] = useState('');
	const [currentCycleSelection, setCurrentCycleSelection] = useState<any[]>([]);
	const [myGoalList, setMyGoalList] = useState<any>(null);
	const [myGoalKRList, setMyGoalKRList] = useState<any>(null);
	const [checkItemUnlinked, setCheckItemUnlinked] = useState(false);

	useEffect(() => {
		if (cycleDetail && cycleDetail.cycleDetails && cycleDetail.cycleDetails.length) {
			let currentQuarterDetails: any = getCycleDefaultSelection(cycleDetail.cycleDetails, cycleId);
			if (currentQuarterDetails) {
				setSelectedCycleDetails({ ...currentQuarterDetails });
				setIsCurrentCycle({ ...currentQuarterDetails });
				setCurrentSelectedCycleYear(currentQuarterDetails?.year || currentCycleYear);
				setCurrentCycleId(currentQuarterDetails?.organisationCycleId || currentCycleId);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab, 1);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveListMyTab, objectiveListPeopleTab]);

	useEffect(() => {
		if (soObjCreateTabSelected === 2) {
			getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab, 2);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [soObjCreateTabSelected]);

	const getLinkedObjectiveAndKeyResultList = (myOkrList: any, peopleOkrList: any, filterType: number) => {
		try {
			let completeList: any = [];
			if (myOkrList && myOkrList.length) {
				completeList = [...completeList, ...myOkrList];
			}
			if (peopleOkrList && peopleOkrList.length) {
				completeList = [...completeList, ...peopleOkrList];
			}
			const selectedIndividualKRList = getSelectedIndividualKRList(
				completeList && completeList.length ? [...completeList] : [],
				filterType
			);
			// const filterData = completeList && completeList.length ? [...completeList].filter((item: any) => item?.isSelected) : [];
			let filterData =
				completeList && completeList.length
					? [...completeList].filter((item: any) => item?.isLinkTab || item?.isSelected)
					: [];
			if (filterType === 2) {
				filterData =
					completeList && completeList.length ? [...completeList].filter((item: any) => item?.isSelected) : [];
			}
			setMyGoalList(filterData);
			setMyGoalKRList(selectedIndividualKRList);
		} catch (error) {
			console.error(error);
		}
	};
	const getSelectedIndividualKRList = (list: any, filterType: number) => {
		let selectedKRList: any = [];
		list.forEach((item: any) => {
			if (!item?.isSelected) {
				const krItems =
					item?.linkedKrResponses && item?.linkedKrResponses.length
						? item?.linkedKrResponses
								.filter((kr: any) => (filterType !== 2 && kr?.isLinkTab && !item?.isLinkTab) || kr?.isSelected)
								.map((record: any) => {
									return { ...record, okrDetails: item };
								})
						: [];
				selectedKRList = [...selectedKRList, ...krItems];
			}
		});

		return selectedKRList;
	};

	const handleCycleChange = (id: any, quarterData: any, year: any) => {
		updateCycleDetails(quarterData, year);
	};
	const updateCycleDetails = (quarterData: any, year: any) => {
		quarterData?.organisationCycleId && setCurrentCycleId(quarterData?.organisationCycleId);
		year && setCurrentSelectedCycleYear(year);
		setSelectedCycleDetails({ ...quarterData, year });
		const selectedCycleDetail = {
			...quarterData,
			year,
		};
		getObjectiveListBasedOnCycleChange(selectedCycleDetail, 'MyTab');
	};

	const getLinkedKeyResultCount = (list: any) => {
		return 0;
	};

	const handleObjectiveKRLinkingChange = (event: any, linkingType: string, okr: any, kr: any) => {
		try {
			const { checked } = event?.target || {};
			const item = linkingType === 'OKR' ? okr : kr;
			const empId = item?.customDetails?.employeeId || item?.employeeId || 0;
			const okrType = empId === userDetail?.employeeId ? 'my' : 'people';

			let updatedMainList = objectiveListPeopleTab && objectiveListPeopleTab.length ? [...objectiveListPeopleTab] : [];
			if (okrType === 'my') {
				updatedMainList = objectiveListMyTab && objectiveListMyTab.length ? [...objectiveListMyTab] : [];
			}
			// console.log('handleObjectiveKRLinkingChange', myGoalList, selectedCycleDetails, okr, kr);
			if (linkingType === 'OKR') {
				updatedMainList = [...updatedMainList].map((obj: any) => {
					const data = { ...obj };
					if (obj.goalObjectiveId === okr.goalObjectiveId) {
						data.linkedKrResponses =
							data.linkedKrResponses && data.linkedKrResponses.length
								? data.linkedKrResponses.map((krData: any) => {
										const krObj = { ...krData };
										krObj.isSelected = checked || false;
										krObj.isLinkTab = true;

										return krObj;
								  })
								: [];
						data.onlyKrLinked = false;
						data.isSelected = checked || false;
						data.isLinkTab = true;
					}

					return { ...data };
				});
			} else if (linkingType === 'KR') {
				updatedMainList = [...updatedMainList].map((obj: any) => {
					const data = { ...obj };
					if (obj.goalObjectiveId === okr.goalObjectiveId) {
						data.linkedKrResponses =
							data.linkedKrResponses && data.linkedKrResponses.length
								? data.linkedKrResponses.map((krData: any) => {
										const krObj = { ...krData };
										if (krObj?.goalkeyId === kr?.goalkeyId) {
											krObj.isSelected = checked || false;
											krObj.isLinkTab = true;
										}

										return krObj;
								  })
								: [];
						data.onlyKrLinked = true;
						data.isSelected = false; // checked ? validateAllKRCheck(data) : false;
					}

					return { ...data };
				});
			} else {
				updatedMainList =
					objectiveListPeopleTab && objectiveListPeopleTab.length
						? [...objectiveListPeopleTab]
						: objectiveListPeopleTab;
			}
			const isOkrUnlinked =
				updatedMainList && updatedMainList?.length
					? updatedMainList?.filter((item: any) => !item?.onlyKrLinked)?.some((item: any) => item.isSelected === false)
					: false;
			if (isOkrUnlinked) {
				setCheckItemUnlinked(isOkrUnlinked);
			} else {
				let isUnlinked = false;
				const onlyKrLinkedList =
					updatedMainList && updatedMainList?.length ? updatedMainList?.filter((item: any) => item?.onlyKrLinked) : [];

				onlyKrLinkedList.forEach((item: any) => {
					const isKrUnlinked =
						item?.linkedKrResponses && item?.linkedKrResponses.length
							? item?.linkedKrResponses.some((krItem: any) => krItem.isSelected === false)
							: false;
					if (isKrUnlinked) {
						isUnlinked = isKrUnlinked;
					}
				});
				setCheckItemUnlinked(isUnlinked);
			}

			if (okrType === 'my') {
				setObjectiveListMyTab(updatedMainList);
				// getLinkedObjectiveAndKeyResultList(updatedMainList, objectiveListPeopleTab);
			} else {
				setObjectiveListPeopleTab(updatedMainList);
				// getLinkedObjectiveAndKeyResultList(objectiveListMyTab, updatedMainList);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const validateAllKRCheck = (okr: any) => {
		const krList = okr.linkedKrResponses && okr.linkedKrResponses.length ? okr.linkedKrResponses : [];
		const selectedKR = krList.filter((item: any) => item?.isSelected);

		return krList.length === 0 ? false : selectedKR.length === krList.length ? true : false;
	};

	return (
		<Fragment>
			{checkItemUnlinked ? (
				<>
					<InformationMessage messageText={t('soUnlinkedMessage')} icon={true} />
				</>
			) : (
				<></>
			)}
			<SOObjectiveList
				cycleId={cycleId}
				cycleDetail={cycleDetail}
				currentCycleYear={currentCycleYear}
				cycleDuration={cycleDuration}
				getObjectiveListBasedOnCycleChange={getObjectiveListBasedOnCycleChange}
				okrMasterData={okrMasterData}
				selectedCycleDetails={selectedCycleDetails}
				currentCycleSelection={currentCycleSelection}
				currentCycleId={currentCycleId}
				currentSelectedCycleYear={currentSelectedCycleYear}
				handleCycleChange={handleCycleChange}
				isCurrentCycle={isCurrentCycle}
				searchText={searchText}
				setSearchText={setSearchText}
				myGoalList={myGoalList}
				setMyGoalList={setMyGoalList}
				handleObjectiveKRLinkingChange={handleObjectiveKRLinkingChange}
				getLinkedKeyResultCount={getLinkedKeyResultCount}
				isLinkingTab={true}
				myGoalKRList={myGoalKRList}
				setMyGoalKRList={setMyGoalKRList}
				soPermissionDetails={soPermissionDetails}
				isAnyItemUnlinked={checkItemUnlinked}
			/>
			{/* <NoRecord noRecordMessage={t('linkSoHead')} subHead={t('linkSoSubHead')} /> */}
		</Fragment>
	);
};
