import { Box } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { saveCommentsPost } from '../../../action/recognize';
import { showLoader } from '../../../action/common';
import { Enums } from '../../../config/enums';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { enableTaggingWithImage } from '../../Common/CkEditor/CkEditorEnums';
import { globalSearchAPIWithTeam, recognitionSearch } from '../../../action/search';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import { addConversation, UpdateConversation } from '../../../action/cfr';
import { getUserDetails } from '../../../config/utils';
import { saveCommentGoalData } from '../../../action/perform';

export const CreateReply = (props: any) => {
	const {
		replyUser,
		updateCount,
		replyPageIndex,
		setIsTaskEdited,
		getSourceId,
		setEditedConversation,
		setShowReplyBox,
		commentDetailsId,
		commentData,
	} = props;
	const { t } = useTranslation();
	const [postText, setPostText] = React.useState<any>('');
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const loggedInUserDetail = getUserDetails();
	const [imageList, setImageList] = useState<any[]>([]);
	const [saveClicked, setSaveClicked] = React.useState<boolean>(false);
	const [modalOpen, setModalOpen] = useState<any>({ open: false, message: '' });
	const { performanceGoalCycle } = useSelector((state: any) => state?.adminSettingReducer);
	useEffect(() => {
		if (props.replyData && props.replyData.commentDetailsId) {
			setPostText(props.replyData.comments);
		} else {
			if (replyUser !== null) {
				let userId = replyUser.firstName + '' + replyUser.lastName;
				setPostText(
					'<p><a class="mention tagged-name" data-mention="@' +
						userId +
						'" data-user-id="' +
						replyUser.createdBy +
						'" rel="' +
						replyUser.createdBy +
						'">@' +
						userId +
						'</a></p>'
				);
			} else {
				setPostText('');
			}
		}
	}, [props.replyData, replyUser]);
	const searchCustomEmployee = (searchStr: string, page: number, pageSize: number) => {
		return dispatch(recognitionSearch(searchStr, page, pageSize, 1));
	};
	const saveReply = async () => {
		if (postText) {
			setIsTaskEdited(false);
			setSaveClicked(true);
			let data: any = {
				commentDetailsId: 0,
				comments: postText,
				moduleDetailsId:commentDetailsId,// commentData.performanceGoalLinkedFormCategoryId,
				moduleId: 6,
				employeeId: props.drUserData.employeeId || loggedInUserDetail.employeeId,
				status: props.performanceGoalsList.status,
				performanceGoalCycleId:
					performanceGoalCycle && performanceGoalCycle.performanceGoalCycleId
						? performanceGoalCycle.performanceGoalCycleId
						: 0,
				documentRequest: [],
				type: 4,
			};
			if (data.commentDetailsId) {
				data.commentDetailsId = props.replyData.commentDetailsId;
				data.comments = postText;
			}
			const elem = document.createElement('span');
			elem.innerHTML = data.description;
			let tagged = elem.getElementsByClassName('tagged-name');
			for (let i = 0; i < tagged.length; i++) {
				let employeeId: any = tagged[i].getAttribute('rel');
				if (employeeId) {
					let searchType = 1;
					if (employeeId.includes('_teamId')) {
						searchType = 2;
						employeeId = employeeId.replace('_teamId', '');
					}
					data.employeeTags.push({
						employeeId: parseInt(employeeId),
						id: parseInt(employeeId),
						searchType: searchType,
					});
				}
			}
			imageList.forEach((value: any) => {
				if (postText.includes(value.filePath)) {
					data.assignedFiles.push({
						fileName: value.fileName,
						filePath: value.filePath,
						storageFileName: value.filePath.substring(value.filePath.lastIndexOf('/') + 1),
					});
				}
			});
			let response: any = await dispatch(saveCommentGoalData(data));
			if (response.data.status === Enums.STATUS_SUCCESS) {
				dispatch(showLoader(false));
				setEditedConversation({});
				setShowReplyBox(false);
				props.getReplies(1, 9999, props.replyData?.commentDetailsId ? false : true, props.replyData?.commentDetailsId ? false : true);
				setPostText('');
				/* enqueueSnackbar(response?.data?.messageList?.messageSuccess, {
					variant: 'success',
					autoHideDuration: 5000,
				}); */
				setSaveClicked(false);
			} else {
				setSaveClicked(false);
				if (response?.data?.messageList.BlockedWords) {
					setModalOpen({ open: true, message: response?.data?.messageList.BlockedWords });
				} else {
					enqueueSnackbar(response?.data?.messageList.message, {
						variant: 'error',
						autoHideDuration: 5000,
					});
				}
			}
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();

		setModalOpen({ open: false, message: '' });
	};
	return (
		<>
			<Box id='create-reply-box'>
				<Box className='cfr-reply-list-content'>
					{' '}
					<Box className='editor-pane'>
						<Box className='cfr-control-section' id='rteImage'>
							<CkEditor
								{...props}
								enableTagging={true}
								focusOnLoad={true}
								placeholder={''}
								handleEditorChange={(value: string) => {
									if (value && (value.trim() === '' || value.trim() === '<p></p>' || value.trim() === '<p><br></p>')) {
										//setPostText('');
										setIsTaskEdited(false);
									} else {
										//setIsTaskEdited(true);
										setPostText(value);
										if (!props.replyData?.commentDetailsId) {
											setIsTaskEdited(!value ? false : true);
										} else {
											setIsTaskEdited(true);
										}
									}
									//setEditorText(value);
								}}
								value={postText}
								setImageList={setImageList}
								imageList={imageList}
								uploadCase={'CONVERSATION'}
								uploadType={'4'}
								removedPlugins={enableTaggingWithImage}
								uploadImage={true}
								globalSearchAPIWithTeam={searchCustomEmployee}
								showEmoticons={true}
							/>
						</Box>
					</Box>
				</Box>
				<Box className='cfr-add-post-action'>
					<OKRButton
						className='btn-primary'
						text={t('reply')}
						handleClick={saveReply}
						disabled={!postText || saveClicked ? true : false}
					/>
				</Box>
			</Box>
			{modalOpen.open && (
				<DialogComponent
					module='information'
					message={modalOpen.message}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen.open}
					isCancel={true}
				/>
			)}
		</>
	);
};
