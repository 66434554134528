/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState, useEffect } from 'react';
import { Box, Typography, Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import RolesAndPermisson from './RolePermission';
import { RoleListing } from './RoleListing';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../CommonComponent/DialogComponent';
import CloneRoleComponent from '../../CommonComponent/CloneRoleDialog';
import { Enums } from '../../../../config/enums';
import { AddTeam } from '../../Organizations/AddTeam/AddTeam';
import { useSelector } from 'react-redux';

export const SystemRolesPermissions: React.FC<any> = (props) => {
	const { openRolePermissionDrawer, handleMenuClick, permissions, getPermissions, getRole, deleteRole, cloneRole } =
		props;
	const { t } = useTranslation();
	const [rolesList, setRolesList] = React.useState<any>({});
	const [allPermissions, setAllPermission] = useState<any>([]);
	const [manageUsers, setManageUsers] = useState<boolean>(false);
	const [activeRoleId, setActiveRoleId] = useState<number>(0);
	const [clonedRoleId, setClonedRoleId] = useState<number>(0);
	const [isEditRole, setIsEditRole] = useState<boolean>(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
	const [selectedRole, setSelectedRole] = useState<any>({});
	const { enqueueSnackbar } = useSnackbar();
	const pageSize = 10;
	const [openCloneDialog, setOpenCloneDialog] = useState<any>({
		open: false,
		roleDetails: {},
	});
	const { currentRolePermissionsTab } = useSelector((state: any) => state.commonReducer);

	useEffect(() => {
		if (currentRolePermissionsTab === 'systemRolesPermissions') {
			fetchRolePermission();
		}
	}, [currentRolePermissionsTab]);
	// useEffect(() => {
	// 	if (tabSelected === 1) {
	// 		fetchRolePermission();
	// 	}
	// }, []);

	useEffect(() => {
		permissions.length && setAllPermission(permissions);
	}, [permissions]);

	useEffect(() => {
		if (props.updateRoleNameSuccess === true) {
			let rolesListData = Object.assign([], rolesList);
			if (rolesListData && rolesListData.length > 0) {
				rolesListData.forEach((element: any) => {
					if (element.roleId === props.roleNameData.roleId) {
						element.roleName = props.roleNameData.roleName;
					}
				});
				setRolesList(rolesListData);
				enqueueSnackbar(t('roleNameUpdateSuccess'), {
					variant: 'success',
					autoHideDuration: 2000,
				});
				props.resetAllRoleStatus();
			}
		}
	}, [props.updateRoleNameSuccess]);

	useEffect(() => {
		if (props.updateRoleNameFailed === true && props?.updateRoleNameError?.roleName) {
			let rolesListData = Object.assign([], rolesList);
			setRolesList(rolesListData);
			enqueueSnackbar(props?.updateRoleNameError?.roleName, {
				variant: 'error',
				autoHideDuration: 2000,
			});
		}
	}, [props.updateRoleNameFailed]);

	/**
	 * Fetch all permissions
	 */
	useEffect(() => {
		if (currentRolePermissionsTab === 'systemRolesPermissions' && !permissions.length) {
			getPermissions();
		}
	}, []);

	const fetchRolePermission = async (roleId: number = 0) => {
		const apiRes = await props.getRoleListing();
		if (apiRes && apiRes.data.status === 200) {
			setRolesList(apiRes.data.entityList);
			setActiveRoleId(roleId);
			if (roleId !== 0) {
				scrollTo('role-card-' + roleId);
				setTimeout(function () {
					setActiveRoleId(0);
				}, 5000);
			}
		}
	};

	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			let element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 50 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 300);
	};
	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const updatePermission = (permissions: any, roleId: any, checked: any) => {
		const data = permissions.map((item: any) => {
			return {
				roleId: roleId,
				permissionId: item.permissionId,
				isActive: checked,
			};
		});

		const response = props.updatePermission({ assignRolePermissions: data });
		response.then((res: any) => {
			fetchRolePermission();
			showApiMsgs(t('roleUpdatedMsg'), 'success');
		});
	};

	const handleModulePermissionChange = (e: React.ChangeEvent<HTMLInputElement>, roleId: any, permission: any) => {
		e.preventDefault();
		e.stopPropagation();
		const { checked } = e.target;
		let permissionObj = permission.permissions
			.filter((item: any) => item.isEditable)
			.map((permission: any) => {
				return {
					roleId: roleId,
					isActive: checked,
					permissionId: permission.permissionId,
				};
			});
		const response = props.updatePermission({ assignRolePermissions: permissionObj });
		response.then((res: any) => {
			fetchRolePermission();
			showApiMsgs(t('roleUpdatedMsg'), 'success');
		});
	};

	const changePermission = (
		e: React.ChangeEvent<HTMLInputElement>,
		role: any,
		permission: any,
		roleIndex: number,
		checkStatus: boolean
	) => {
		const { name, checked } = e.target;
		let allRoles = [...rolesList];
		allRoles[roleIndex][name] = checked;
		setRolesList(allRoles);

		updatePermission([permission], role.roleId, checked);
	};

	const checkModuleSelection = (roles: any, moduleId: number, permission: any) => {
		let findModuleLength = roles.permissions.filter((role: any) => role.moduleId === moduleId).length;
		if (findModuleLength === 0) {
			return false;
		} else if (findModuleLength < permission.permissions.length) {
			return null;
			//'partial'
		} else if (permission.permissions.length === findModuleLength) {
			return true;
		}
	};

	const findPermission = (roles: any, permissionId: number) => {
		if (roles.permissions.length) {
			let isTrue = roles.permissions.find((role: any) => role.permissionId === permissionId);
			return isTrue !== undefined ? true : false;
		}
		return false;
	};

	/**
	 * Handle role edit click
	 * role Object
	 */
	const handleEditRole = (role: any) => {
		setManageUsers(false);
		handleMenuClick();
		let data = `?roleId=${role.roleId}&finder=${''}&pageIndex=${1}&pageSize=${pageSize}`;
		getRole(data);
		setIsEditRole(true);
		setcurrentRoleData(role);
	};

	/**
	 * Handle manage user redrection
	 * @param role Object
	 */
	const [currentRoleData, setcurrentRoleData] = useState<any>({});
	const handleManageUsersTab = (role: any) => {
		setManageUsers(true);
		setIsEditRole(true);
		handleMenuClick();
		let data = `?roleId=${role.roleId}&finder=${''}&pageIndex=${1}&pageSize=${10}`;
		props.getRole(data);
		setcurrentRoleData(role);
	};

	const handleDialogCancelClick = () => {
		setOpenDeleteDialog(false);
		setSelectedRole({});
	};

	const handleDeleteRole = (roleDetail?: any) => {
		setOpenDeleteDialog(true);
		setSelectedRole(roleDetail);
	};

	const handleConfirmClick = async () => {
		setOpenDeleteDialog(false);
		const response = await deleteRole(selectedRole.roleId);
		if (response.data.status === 200) {
			const rolesListData = rolesList.filter((item: any) => item.roleId !== selectedRole.roleId);
			setRolesList(rolesListData);
			showApiMsgs(t('roleDeletedMsg'), 'success');
			setSelectedRole({});
		} else if (response.data.status === 400) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages} `, 'error');
		}
	};

	const handleCloneRole = (role: any) => {
		setOpenCloneDialog({
			...openCloneDialog,
			open: !openCloneDialog.open,
			roleDetails: role,
		});
	};

	/**
	 * Handle cloning save
	 * @param e HTMLInputElement
	 * @param cloneObj Object
	 * @param role Object
	 */

	const handleCloneSubmit = async (e: React.ChangeEvent<HTMLInputElement>, cloneObj: any, role: any) => {
		const cloneRoleObj = { ...cloneObj, roleId: role.roleDetails.roleId };
		const response = await cloneRole(cloneRoleObj);
		if (Boolean(response) && response.data && response.data) {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			if (response.data.status === 200) {
				showApiMsgs(`${messages} `, 'success');
				setOpenCloneDialog({
					...openCloneDialog,
					open: false,
					roleDetails: {},
				});
				setActiveRoleId(response.data.entity);
				setClonedRoleId(response.data.entity);
				if (cloneRoleObj.roleId !== 0) {
					scrollTo('role-card-' + response.data.entity);
					setTimeout(function () {
						setActiveRoleId(0);
					}, 5000);
				}
				fetchRolePermission(response.data.entity);
			} else {
				showApiMsgs(`${messages} `, 'error');
			}
		}
	};

	return (
		<Fragment>
			<Box className='admin-tabpanel-inner role-inner'>
				<Box className='admin-tabpanel-head'>
					<Typography variant='h2'>{t('manageRolePermission')}</Typography>
				</Box>
				<RoleListing
					{...props}
					permissions={allPermissions}
					rolesList={rolesList}
					setRolesList={setRolesList}
					checkModuleSelection={checkModuleSelection}
					findPermission={findPermission}
					handleEditRole={handleEditRole}
					handleManageUsersTab={handleManageUsersTab}
					changePermission={changePermission}
					handleModulePermissionChange={handleModulePermissionChange}
					activeRoleId={activeRoleId}
					clonedRoleId={clonedRoleId}
					setClonedRoleId={setClonedRoleId}
					handleDeleteRole={handleDeleteRole}
					handleCloneRole={handleCloneRole}
					showApiMsgs={showApiMsgs}
					fetchRolePermission={fetchRolePermission}
				/>
			</Box>
			{openRolePermissionDrawer && (
				<RolesAndPermisson
					{...props}
					showApiMsgs={showApiMsgs}
					openRolePermissionDrawer={openRolePermissionDrawer}
					handleMenuClick={handleMenuClick}
					fetchRolePermission={fetchRolePermission}
					manageUsers={manageUsers}
					setManageUsers={setManageUsers}
					isEditRole={isEditRole}
					setIsEditRole={setIsEditRole}
					currentRoleData={currentRoleData}
				/>
			)}

			{/* Confirm delete dialog box */}
			<DialogComponent
				module='confirmation'
				modalOpen={openDeleteDialog}
				handleCloseModal={handleDialogCancelClick}
				matchString={Enums.DELETE_TEXT}
				handleConfirmClick={handleConfirmClick}
				headingText={t('deletingRole')}
				subHeading={t('userNotLogin')}
			/>

			{/* Clone Role Dialog */}

			<CloneRoleComponent
				modalOpen={openCloneDialog}
				handleCloseModal={handleCloneRole}
				handleCloneSubmit={handleCloneSubmit}
			/>
			{props.openAddTeamDrawer && (
				<AddTeam
					{...props}
					handleMenuClick={handleMenuClick}
					openAddTeamDrawer={props.openAddTeamDrawer}
					refreshList={false}
					fetchOrgList={() => {}}
				/>
			)}
		</Fragment>
	);
};
