import React, { Fragment, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SearchSoTabPanel, SearchSoTabs } from './SearchSOTabs';
import { SearchSOMyOkr } from './SearchSOMyOkr';
import { SearchSOPeopleOkr } from './SearchSOPeopleOkr';

export const SearchSO: React.FC<any> = (props: any) => {
	const { t } = useTranslation();
	const { searchSoTabSelected, setSearchSoTabSelected } = props;

	const handleTabChange = (event: any, newValue: any) => {
		setSearchSoTabSelected(newValue);
	};
	const getLinkedKeyResultCount = (list: any) => {
		try {
			let selectedKRCount = 0;
			let selectedOKRCount = 0;
			if (list && list.length) {
				list
					?.filter((itm: any) => !itm.isSelected)
					.forEach((item: any) => {
						if (item?.linkedKrResponses && item?.linkedKrResponses.length) {
							const count = item?.linkedKrResponses?.filter((rec: any) => rec.isSelected);
							selectedKRCount = selectedKRCount + count?.length;
						}
					});
				selectedOKRCount = list?.filter((itm: any) => itm.isSelected)?.length;
			}

			return selectedOKRCount && selectedKRCount
				? `Selected ${selectedOKRCount} Objective(s) and ${selectedKRCount} Key Result(s)`
				: selectedOKRCount && !selectedKRCount
				? `Selected ${selectedOKRCount} Objective(s)`
				: !selectedOKRCount && selectedKRCount
				? `Selected ${selectedKRCount} Key Result(s)`
				: '';
		} catch (error) {
			return 0;
		}
	};

	return (
		<Box className='search-so-tab'>
			<SearchSoTabs t={t} tabSelected={searchSoTabSelected} handleTabChange={handleTabChange} {...props} />
			<Box className='drawer-tabs-panel import-okr-tabpanel'>
				<SearchSoTabPanel value={searchSoTabSelected} index={0} className='my-okrs-tab'>
					<SearchSOMyOkr {...props} getLinkedKeyResultCount={getLinkedKeyResultCount} />
				</SearchSoTabPanel>
				<SearchSoTabPanel value={searchSoTabSelected} index={1} className='people-search-tab'>
					<SearchSOPeopleOkr {...props} getLinkedKeyResultCount={getLinkedKeyResultCount} />
				</SearchSoTabPanel>
			</Box>
		</Box>
	);
};
