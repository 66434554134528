import * as actionTypes from './actionTypes';
import {
	getOrgListApi,
	addOrgAPI,
	addParentAPI,
	getOrganizationAPI,
	deleteOrganizationAPI,
	updateOrganizationAPI,
	addChildAPI,
	uploadLogoAPI,
	getAllUsersAPI,
	updateChildAPI,
	getParentTeamsAPI,
	getCycleMasterDataAPI,
	getParentOrganizationAPI,
	updateParentOrganizationAPI,
	addTeamAPI,
	updateTeamAPI,
	deleteTeamAPI,
	assignedEmployeeTeamAPI,
	getOrgViewApi,
	getCheckinMasterDataAPI,
	getManageOrgListAPI,
	addBulkTeamUploadAPI,
	getManageBadgesAPI,
	addBadgesAPI,
	badgesStatusApi,
	deleteBadgesApi,
	updatedManageBadgesAPI,
	updateAlignSettingsAPI,
	updateEngageSettingsAPI
} from '../services/OrgService';
import { showLoader } from './common';

export const fetchOrgPending = () => ({
	type: actionTypes.FETCH_ORG_PENDING,
});
export const fetchOrgCompleted = (payload) => ({
	type: actionTypes.FETCH_ORG_SUCCESS,
	payload,
});
export const fetchOrgFailed = (error) => ({
	type: actionTypes.FETCH_ORG_FAILED,
	error,
});

export const getOrgList = (org) => {
	return (dispatch) => {
		return org ? getOrgViewApi() : getOrgListApi();
	};
};

export const fetchOrganizationsList = () => {
	return (dispatch) => {
		dispatch(fetchOrgPending());
		return getOrgListApi()
			.then((response) => {
				dispatch(fetchOrgCompleted(response.data));
			})
			.catch((error) => {
				dispatch(fetchOrgFailed(error));
			});
	};
};

const addOrgPending = () => ({
	type: actionTypes.ADD_ORG_PENDING,
});
export const addOrgCompleted = (payload) => ({
	type: actionTypes.ADD_ORG_SUCCESS,
	payload,
});
export const addOrgFailed = (error) => ({
	type: actionTypes.ADD_ORG_FAILED,
	error,
});

export const addOrg = (data) => {
	return (dispatch) => {
		return addOrgAPI(data);
	};
};

export const addParent = (data) => {
	return (dispatch) => {
		dispatch(updatedOrganizationList());
		dispatch(addOrgPending());
		return addParentAPI(data);
	};
};

export const addChild = (data, type) => {
	return (dispatch) => {
		dispatch(updatedOrganizationList());
		return type === 'editChild' ? updateChildAPI(data) : addChildAPI(data);
	};
};

export const getOrganization = (data) => {
	return () => {
		return getOrganizationAPI(data);
	};
};

export const addManageBadges = (data, type) => {
	return () => {
		return addBadgesAPI(data);
	};
};

export const updatedManageBadges = (data) => {
	return () => {
		return updatedManageBadgesAPI(data);
	};
};

export const getManageBadges = (data) => {
	return () => {
		return getManageBadgesAPI(data);
	};
};

export const deleteBadges = (data) => {
	return () => {
		return deleteBadgesApi(data);
	};
};

export const manageBadgesStatus = (data, type) => {
	return () => {
		return badgesStatusApi(data);
	};
};

export const updateOrganization = (data) => {
	return (dispatch) => {
		dispatch(updatedOrganizationList());
		return updateOrganizationAPI(data);
	};
};

export const deleteOrganization = (data) => {
	return (dispatch) => {
		dispatch(updatedOrganizationList());
		return deleteOrganizationAPI(data);
	};
};

export const uploadLogo = (data) => {
	return () => {
		return uploadLogoAPI(data);
	};
};

const fetchAllUsersPending = () => ({
	type: actionTypes.FETCH_ALL_USERS_PENDING,
});

const fetchAllUsersCompleted = (payload) => ({
	type: actionTypes.FETCH_ALL_USERS_SUCCESS,
	payload,
});

const fetchAllUsersFailed = (error) => ({
	type: actionTypes.FETCH_ALL_USERS_FAILED,
	error,
});

export const getAllUsers = (data) => {
	return (dispatch) => {
		dispatch(fetchAllUsersPending());
		return getAllUsersAPI(data)
			.then((response) => {
				dispatch(fetchAllUsersCompleted(response.data));
			})
			.catch((error) => {
				dispatch(fetchAllUsersFailed(error));
			});
	};
};

export const getParentTeam = (data) => {
	return () => {
		return getParentTeamsAPI(data);
	};
};

export const getAllUsersList = (data) => {
	return () => {
		return getAllUsersAPI(data);
	};
};

export const callAPI = (payload) => ({
	type: actionTypes.ADD_ORG_SUCCESS,
	payload,
});

/** Fetch All Organisations */

const fetchAllOrganisationsPending = () => ({
	type: actionTypes.FETCH_ALL_ORGANISATIONS_PENDING,
});

const fetchAllOrganisationsCompleted = (payload) => ({
	type: actionTypes.FETCH_ALL_ORGANISATIONS_SUCCESS,
	payload,
});

const fetchAllOrganisationsFailed = (error) => ({
	type: actionTypes.FETCH_ALL_ORGANISATIONS_FAILED,
	error,
});

export const getAllOrganisations = (data) => {
	return (dispatch) => {
		dispatch(fetchAllOrganisationsPending());
		return getOrgListApi(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchAllOrganisationsCompleted(response.data));
				} else {
					dispatch(fetchAllOrganisationsFailed('error'));
				}
			})
			.catch((error) => {
				dispatch(fetchAllOrganisationsFailed(error));
			});
	};
};

export const updatedOrganizationList = () => ({
	type: actionTypes.UPDATE_ORG_LIST,
});

/** Get cycle details */

const getCycleDetailsPending = () => ({
	type: actionTypes.FETCH_CYCLES_PENDING,
});

const getCycleDetailsCompleted = (payload) => ({
	type: actionTypes.FETCH_CYCLES_SUCCESS,
	payload,
});

const getCycleDetailsFailed = (error) => ({
	type: actionTypes.FETCH_CYCLES_FAILED,
	error,
});

export const getCyclesData = (data) => {
	return (dispatch) => {
		dispatch(getCycleDetailsPending());
		return getCycleMasterDataAPI(data)
			.then((response) => {
				dispatch(getCycleDetailsCompleted(response.data));
			})
			.catch((error) => {
				dispatch(getCycleDetailsFailed(error));
			});
	};
};

const getCheckinMasterDataPending = () => ({
	type: actionTypes.FECTH_CHECK_IN_MASTERDATA_PENDING,
});

const getCheckinMasterDataCompleted = (payload) => ({
	type: actionTypes.FECTH_CHECK_IN_MASTERDATA_SUCCESS,
	payload,
});

const getCheckinMasterDataFailed = (error) => ({
	type: actionTypes.FECTH_CHECK_IN_MASTERDATA_FAILED,
	error,
});

export const getCheckinMasterData = (data) => {
	return (dispatch) => {
		dispatch(getCheckinMasterDataPending());
		return getCheckinMasterDataAPI(data)
			.then((response) => {
				dispatch(getCheckinMasterDataCompleted(response.data));
			})
			.catch((error) => {
				dispatch(getCheckinMasterDataFailed(error));
			});
	};
};

/** Get parent organisation */

const getParentOrgPending = () => ({
	type: actionTypes.GET_PARENT_ORGANISATION_PENDING,
});

const getParentOrgCompleted = (payload) => ({
	type: actionTypes.GET_PARENT_ORGANISATION_SUCCESS,
	payload,
});

const getParentOrgFailed = (error) => ({
	type: actionTypes.GET_PARENT_ORGANISATION_FAILED,
	error,
});

const resetParentOrg = () => ({
	type: actionTypes.RESET_ORGANISATION,
});

export const getParentOrganization = (data) => {
	return (dispatch) => {
		dispatch(getParentOrgPending());
		return getParentOrganizationAPI(data)
			.then((response) => {
				dispatch(getParentOrgCompleted(response.data));
			})
			.catch((error) => {
				dispatch(getParentOrgFailed(error));
			});
	};
};

export const resetParentOrganisation = () => {
	return (dispatch) => {
		dispatch(resetParentOrg());
	};
};

/** Save Parent organisation */
export const updateParentOrganisation = (data) => {
	return () => {
		return updateParentOrganizationAPI(data);
	};
};

/** Save Align Settings */
export const updateAlignSettings = (data) => {
	return () => {
		return updateAlignSettingsAPI(data);
	};
};

/** Save Engage Settings */
export const updateEngageSettings = (data) => {
	return () => {
		return updateEngageSettingsAPI(data);
	};
};

export const addTeam = (data) => {
	return () => {
		return addTeamAPI(data);
	};
};

export const updateTeam = (data) => {
	return () => {
		return updateTeamAPI(data);
	};
};

/** Delete organisation */
export const deleteTeam = (data) => {
	return () => {
		return deleteTeamAPI(data);
	};
};

/**Get assigned users */

export const assignedEmployeeTeam = (data) => {
	return () => {
		return assignedEmployeeTeamAPI(data);
	};
};

/** Get manage organization  list  */

const fetchManageOrgPending = () => ({
	type: actionTypes.GET_MANAGE_ORGANISATIONS_PENDING,
});
const fetchManageOrgCompleted = (payload) => ({
	type: actionTypes.GET_MANAGE_ORGANISATIONS_SUCCESS,
	payload,
});
const fetchManageOrgFailed = (error) => ({
	type: actionTypes.GET_MANAGE_ORGANISATIONS_FAILED,
	error,
});

export const getManageOrgList = (
	data = {
		pageIndex: 1,
		pageSize: 10,
		sortingText: '',
		order: '',
		finder: [],
	}
) => {
	return (dispatch) => {
		dispatch(showLoader(true));
		dispatch(fetchManageOrgPending());
		return getManageOrgListAPI(data)
			.then((response) => {
				if (response.status === 200) {
					dispatch(fetchManageOrgCompleted(response.data));
				} else {
					dispatch(fetchManageOrgFailed('error'));
				}

				dispatch(showLoader(false));
			})
			.catch((error) => {
				dispatch(fetchManageOrgFailed(error));
				dispatch(showLoader(false));
			});
	};
};

/** Add Bulk Team Upload  */
export const addBulkTeamUpload = (data) => {
	return () => {
		return addBulkTeamUploadAPI(data);
	};
};
