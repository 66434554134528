import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Link, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { CreatePip } from './CreatePip';
import { ViewPip } from './ViewPip';
import { PipMyList } from './PipMyList';
import { OKRButton } from '../../Common/OKRButton';
import {
	checkUnSavePopUpVisible,
	formatFeedbackDate,
	formatOneOnOneRequestDate,
	getUserDetails,
	getUserName,
} from '../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Enums } from '../../../config/enums';
import AlertDialog from '../../Common/Dialog';
import {
	checkPipNomineeExist,
	createPIPPlan,
	deletePIPPlan,
	getNomineeFeedbackList,
	getOtherPipListingData,
	getPIPDetailsForView,
	getPipListingData,
	getPipListingFilter,
	getPlanReason,
	getResolveStatus,
	reloadPIPList,
	setPipImprovementData,
	updatePIPPlan,
	getFeedbackAlertData,
	getPipAlertData,
} from '../../../action/performanceImprovementPlan';
import { AddIconSvg } from '../../../config/svg/CommonSvgIcon';
import { getOkrMasterData } from '../../../action/myGoal';
import { CommonFilters } from '../Common/CommonFilters';
import { globalSearchAPIWithTeam } from '../../../action/search';
import { CreateOneOnOne } from '../../Engagement/Request1on1/CreateOneOnOne';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import WelcomeImage from '../../../images/my-goal-bg.svg';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import { fetchGlobalTaskPending, getAllGlobalTask } from '../../../action/globalTask';
import { setPipFiltersList } from '../../../action/performanceImprovementPlan';
import { pipFiltersLabels } from '../../../config/constant';
import { DeleteIcon } from '../../../config/svg/CommonSvg';
import { PopperMenu } from '../../Admin/CommonComponent/PopperMenu';

export const PerformanceImprovementPlan = (props: any) => {
	const {
		loader,
		setLoader,
		feedbackDetailsForPip,
		setFeedbackDetailsForPip,
		goBackToFeedback,
		setGoBackToFeedback,
		goBackToFeedbackDetails,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const userDetails = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { reloadPIP, pipImprovementData } = useSelector((state: any) => state?.performanceImprovementPlanReducer);
	let defaultFinalSettings = {
		...{
			resolvedStatus: [],
			status: [],
			start: [],
			filterData: [],
			timeframe: [],
		},
	};
	const [finalFilterCondition, setFinalFilterCondition] = useState<any>(defaultFinalSettings);
	const [noRecords, setNoRecords] = useState<boolean>(false);
	/**
	 * Create Pip related Details Start Here
	 */
	const ref = useRef<any>(null);
	// const ref1 = useRef<any>(null);
	// const ref2 = useRef<any>(null);
	const defaultPIPFormDetails = {
		nomineeId: Enums.ZERO,
		title: '',
		summary: '',
		timeframeStartDate: '',
		timeframeEndDate: '',
		areasOfConcern: '',
		roleExpectations: '',
		improvementGoals: '',
		performanceImprovementPlanStatusId: Enums.ONE, // Draft = 1, Submitted = 2, Active = 3, SuccessfullyCleared = 4, Terminate = 5
		feedbackIds: [], // [1, 2]
		performanceImprovementPlanReviewDates: [],
		documents: [],
		tasks: [],
	};

	const [expanded, setExpanded] = useState<any>([1]);
	const [openCreatePipForm, setOpenCreatePipForm] = useState<any>({
		open: false,
		pipFormEditDetails: null,
	});
	const [openViewPipForm, setOpenViewPipForm] = useState<any>({
		open: false,
		pipItem: null,
	});
	const [visiblePip, setVisiblePip] = useState<number>(0);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [twoFactorModalProps, setTwoFactorModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [existAlreadyShown, setExistAlreadyShown] = useState<boolean>(false);
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [pipFormDetails, setPIPFormDetails] = useState<any>(defaultPIPFormDetails);
	const [allTaskList, setAllTaskList] = useState<any>(null);
	const [performanceImprovementList, setPerformanceImprovementList] = useState<any>(null);
	const [performanceOthersImprovementList, setPerformanceOthersImprovementList] = useState<any>(null);
	const [performanceImprovementPlanPermission, setPerformanceImprovementPlanPermission] = useState<any>(null);
	const [requestOneOnOneDrawer, setRequestOneOnOneDrawer] = useState<any>({ open: false, type: '' });
	const [isExtendTimeFrameClicked, setIsExtendTimeFrameClicked] = useState<boolean>(false);
	const [isDraftDirectEdit, setIsDraftDirectEdit] = useState<boolean>(false);
	const [resolveStatusTypes, setResolveStatusTypes] = useState<any[]>([]);
	const [timeFrameChangeReasonTypes, settimeFrameChangeReasonTypes] = useState<any[]>([]);
	const [selectedNomineeFeedbackList, setSelectedNomineeFeedbackList] = useState<any[]>([]);
	const [newAddedPip, setNewAddedPip] = useState<any>(null);
	const [isFormCleared, setIsFormCleared] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [popperMenuClicked, setPopperMenuClicked] = useState<boolean>(false);

	const { statusFilterList, pipFiltersList } = useSelector((state: any) => state?.performanceImprovementPlanReducer);
	useEffect(() => {
		if (statusFilterList) {
			if (pipFiltersList && pipFiltersList.length > 0) {
				const selectedCycleIndex = pipFiltersList.findIndex(
					(cycleRec: any) => cycleRec.performanceImprovementPlanStatusId === 'resolvedStatus'
				);
				if (
					selectedCycleIndex &&
					pipFiltersList[selectedCycleIndex] &&
					pipFiltersList[selectedCycleIndex].values.length === 0
				) {
					let pipFiltersListCopy: any[] = Object.assign([], pipFiltersList);
					pipFiltersListCopy[selectedCycleIndex].values = statusFilterList;
					dispatch(setPipFiltersList(pipFiltersListCopy));
				}
			} else {
				let pipFiltersListCopy: any[] = Object.assign([], pipFiltersLabels);
				const selectedCycleIndex = pipFiltersListCopy.findIndex(
					(cycleRec: any) => cycleRec.performanceImprovementPlanStatusId === 'resolvedStatus'
				);
				if (
					selectedCycleIndex &&
					pipFiltersListCopy[selectedCycleIndex] &&
					pipFiltersListCopy[selectedCycleIndex].values.length === 0
				) {
					pipFiltersListCopy[selectedCycleIndex].values = statusFilterList;
					dispatch(setPipFiltersList(pipFiltersListCopy));
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statusFilterList]);
	useEffect(() => {
		getResolveStatusAPICall();
		getTimeFrameReasonAPICall();
		dispatch(reloadPIPList(true));
		dispatch(getOkrMasterData());
		dispatch(getPipListingFilter(''));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (feedbackDetailsForPip) {
			feedbackDetailsForCreatePip(feedbackDetailsForPip);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedbackDetailsForPip]);
	useEffect(() => {
		if (reloadPIP) {
			getAllPipData();
			dispatch(reloadPIPList(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadPIP]);
	useEffect(() => {
		if (pipImprovementData && pipImprovementData?.pipId) {
			setOpenViewPipForm({
				open: true,
				pipItem: { performanceImprovementPlanId: pipImprovementData?.pipId },
			});
			if (!pipImprovementData.reviewerId) {
				dispatch(setPipImprovementData(null));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pipImprovementData]);
	useEffect(() => {
		if (okrMasterData && okrMasterData?.performanceImprovementPlanPermission) {
			setPerformanceImprovementPlanPermission(okrMasterData?.performanceImprovementPlanPermission);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [okrMasterData]);
	// ======= HANDLING UN_SAVE CHANGES WITH BELOW CODE =========== //
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited, requestOneOnOneDrawer]);

	const handleClickOutside = (event: any) => {
		// ref1.current?.contains(event.target) ||
		// ref2.current?.contains(event.target) ||
		const priorityPopup = document.getElementById('priorityPopup');
		const krDelegatePopup = document.getElementById('krDelegatePopup');
		const taskCalenderPopup = document.getElementById('taskCalenderPopup');
		const krLinkPopup = document.getElementById('krLinkPopup');
		const taskLinkTextPopup = document.getElementById('taskLinkTextPopup');
		const calenderDiv = document.getElementsByClassName('calender-control-popover');
		const searchUserInner = document.getElementsByClassName('search-user-inner');
		const reviewersDetailsPopover = document.getElementsByClassName('reviewers-details-popover');
		const customSnackbarError = document.getElementsByClassName('custom-snackbar-error');
		const ckLinkForm = document.getElementsByClassName('ck-link-form');

		if (
			event?.target?.download ||
			(isDraftDirectEdit && event?.currentTarget?.activeElement?.id === 'more-btn-c') ||
			event?.target?.offsetParent?.id === 'delete_pip_details'
		) {
			//
		} else {
			if (
				ref.current &&
				!ref.current?.contains(event.target) &&
				checkUnSavePopUpVisible(event) &&
				isFormEdited &&
				!priorityPopup &&
				!krDelegatePopup &&
				!taskCalenderPopup &&
				!krLinkPopup &&
				!taskLinkTextPopup &&
				!calenderDiv.length &&
				!searchUserInner.length &&
				!reviewersDetailsPopover.length &&
				!customSnackbarError.length &&
				!ckLinkForm.length &&
				!requestOneOnOneDrawer?.open
			) {
				const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
				if (dialogDiv.length === 0) {
					event.preventDefault();
					event.stopPropagation();
					setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('unSavedItemAlert') });
				}
			} else {
				//
			}
		}
	};
	const showMsg = (variant: any, messages: any) => {
		enqueueSnackbar(messages || `Error occurred while fetching comments`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};
	const getResolveStatusAPICall = async () => {
		try {
			setLoader(true);
			const response: any = await dispatch(getResolveStatus());
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				let updatedStatusDetails: any[] = entityList && entityList?.length ? entityList : [];
				updatedStatusDetails = updatedStatusDetails
					.filter((item: any) => item?.isResolvedStatus && item?.isEnabled)
					.map((item: any, index: any) => {
						const data = { ...item };
						return data;
					});
				setResolveStatusTypes(updatedStatusDetails);
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Some error occurred`);
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const getTimeFrameReasonAPICall = async () => {
		try {
			setLoader(true);
			const response: any = await dispatch(getPlanReason());
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				settimeFrameChangeReasonTypes(entityList && entityList?.length ? entityList : []);
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Some error occurred`);
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const isUserHavePIPPermission = () => {
		return Boolean(performanceImprovementPlanPermission?.isAddingPipAllowed);
	};
	const isUserHavePIPEditPermission = () => {
		return Boolean(performanceImprovementPlanPermission?.allowReviewersToEditPIP);
	};
	// const isUserHavePIPPermission = () => {
	// 	return Boolean(performanceImprovementPlanPermission?.isPIPVisibleToRM);
	// };
	const handleChange = (event: any, newExpanded: any) => {
		let updatedExpanded = [...expanded];
		if (updatedExpanded.includes(newExpanded)) {
			updatedExpanded = updatedExpanded?.filter((rec: any) => rec !== newExpanded);
		} else {
			updatedExpanded = [...updatedExpanded, newExpanded];
		}
		setExpanded(updatedExpanded);
	};
	const updatePipFormDetails = (keyName: string, value: any, fromFeedback = false) => {
		try {
			if (keyName === 'nomineeId') {
				let nomineeDetails: any = {
					nomineeId: value ? value[0]?.employeeId : Enums.ZERO,
					nomineeInfo: value || null,
					nomineeError: '',
				};
				if (fromFeedback) {
					nomineeDetails = { ...nomineeDetails, feedbackIds: [] };
				}
				setIsFormEdited(true);
				setPIPFormDetails({ ...pipFormDetails, ...nomineeDetails });
			} else if (keyName === 'timeFrameDates') {
				setIsFormEdited(true);
				setPIPFormDetails({ ...pipFormDetails, ...value, timeFrameError: '' });
			} else if (keyName === 'feedbackIds') {
				setIsFormEdited(true);
				setPIPFormDetails({ ...pipFormDetails, [keyName]: value });
			} else {
				if (keyName !== 'performanceImprovementPlanReviewDates' && keyName !== 'roleExpectations') {
					setIsFormEdited(true);
				}
				// setIsFormEdited(true);
				const errorObj =
					keyName === 'title'
						? { titleError: '' }
						: keyName === 'summary'
						? { summaryError: '' }
						: keyName === 'areasOfConcern'
						? { areasOfConcernError: '' }
						: keyName === 'roleExpectations'
						? { roleExpectationsError: '' }
						: keyName === 'improvementGoals'
						? { improvementGoalsError: '' }
						: {};
				setPIPFormDetails({ ...pipFormDetails, ...errorObj, [keyName]: value });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 0 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
				window.scroll({ top: topPos, behavior: 'smooth' });
			}
		}, 100);
	};
	const checkPipNomineeExistApiCall = async (employeeId: any) => {
		try {
			setLoader(true);
			const empId = employeeId || Enums.ZERO;
			const response: any = await dispatch(checkPipNomineeExist(empId));
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				const msg = `<span>
				${messages.toString()}
				<br />
				Do you still want to add one more?
			</span>`;
				return { isExist: !Boolean(response.data?.entity), message: msg };
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				return { isExist: !Boolean(response.data?.entity), message: messages };
			}
		} catch (error) {
			console.error(error);
			const messages = 'Some Error occurred';
			return { isExist: false, message: messages };
		}
	};
	const handleSaveAsDraftPIPForm = async (e: any, formType: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			setLoader(true);
			if (checkPIPFormValidation(Enums.ONE)) {
				handleSavePIPFormAPICall(Enums.ONE, formType);
			} else {
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleSubmitPIPForm = async (e: any, formType: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (checkPIPFormValidation(Enums.TWO)) {
				// if (assessmentFormDetails && assessmentFormDetails?.length) {
				if (formType === 'Add' || pipFormDetails?.performanceImprovementPlanStatusId === Enums.ONE) {
					if (pipFormDetails?.nomineeId && !existAlreadyShown) {
						const { isExist, message } = await checkPipNomineeExistApiCall(pipFormDetails?.nomineeId);
						if (isExist) {
							setLoader(true);
							handleSavePIPFormAPICall(Enums.TWO, formType);
						} else {
							setLoader(false);
							setModalProps({
								open: true,
								type: 'NomineeSearch',
								message: message,
								details: formType,
							});
						}
					} else {
						setLoader(true);
						handleSavePIPFormAPICall(Enums.TWO, formType);
					}
				} else {
					setLoader(true);
					handleSavePIPFormAPICall(Enums.TWO, formType);
				}
				// } else {
				// 	setLoader(false);
				// }
			} else {
				// setLoader(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const checkPIPFormValidation = (status: any) => {
		let isFormValidated = true;
		let scrollToPosition = 0;
		let reviewDateIndexNum = 0;
		let updatedPipFormDetails = pipFormDetails ? { ...pipFormDetails } : {};
		if (status === Enums.ONE) {
			// Draft
			if (!updatedPipFormDetails?.nomineeId) {
				updatedPipFormDetails.nomineeError = 'Search and select nominee';
				if (!scrollToPosition) {
					scrollToPosition = 1;
				}

				isFormValidated = false;
			} else {
				updatedPipFormDetails.nomineeError = '';
			}

			if (scrollToPosition === 1) {
				scrollTo('pip_nominee_container');
			}

			setPIPFormDetails(updatedPipFormDetails);

			return isFormValidated;
		} else if (status === Enums.TWO) {
			// Submit
			if (updatedPipFormDetails && !updatedPipFormDetails?.nomineeId) {
				updatedPipFormDetails.nomineeError = 'Search and select nominee';
				if (!scrollToPosition) {
					scrollToPosition = 1;
				}
				let updatedExpanded = [...expanded];
				if (!updatedExpanded.includes(1)) {
					setExpanded([...updatedExpanded, 1]);
				}
				isFormValidated = false;
			} else if (updatedPipFormDetails?.nomineeId) {
				updatedPipFormDetails.nomineeError = '';
			}

			if (!updatedPipFormDetails?.title || updatedPipFormDetails?.title?.trim() === '') {
				updatedPipFormDetails.titleError = 'Title is required';
				if (!scrollToPosition) {
					scrollToPosition = 2;
				}
				let updatedExpanded = [...expanded];
				if (!updatedExpanded.includes(1)) {
					setExpanded([...updatedExpanded, 1]);
				}
				isFormValidated = false;
			} else if (updatedPipFormDetails?.title && updatedPipFormDetails?.title?.trim() !== '') {
				updatedPipFormDetails.titleError = '';
			}

			// if (!updatedPipFormDetails?.summary || updatedPipFormDetails?.summary?.trim() === '') {
			// 	updatedPipFormDetails.summaryError = 'Summary is required';
			// 	if (!scrollToPosition) {
			// 		scrollToPosition = 3;
			// 	}
			// 	let updatedExpanded = [...expanded];
			// 	if (!updatedExpanded.includes(1)) {
			// 		setExpanded([...updatedExpanded, 1]);
			// 	}
			// 	isFormValidated = false;
			// } else if (updatedPipFormDetails?.summary && updatedPipFormDetails?.summary?.trim() !== '') {
			// 	updatedPipFormDetails.summaryError = '';
			// }

			if (!updatedPipFormDetails?.timeframeStartDate || !updatedPipFormDetails?.timeframeEndDate) {
				updatedPipFormDetails.timeFrameError = 'Timeframe is required';
				if (!scrollToPosition) {
					scrollToPosition = 4;
				}
				let updatedExpanded = [...expanded];
				if (!updatedExpanded.includes(1)) {
					setExpanded([...updatedExpanded, 1]);
				}
				isFormValidated = false;
			} else if (updatedPipFormDetails?.timeframeStartDate && updatedPipFormDetails?.timeframeEndDate) {
				updatedPipFormDetails.timeFrameError = '';
			}

			// if (
			// 	updatedPipFormDetails?.performanceImprovementPlanReviewDates &&
			// 	!updatedPipFormDetails?.performanceImprovementPlanReviewDates?.length
			// ) {
			// 	updatedPipFormDetails.reviewDatesError = 'Please add review dates';
			// 	isFormValidated = false;
			// } else
			if (updatedPipFormDetails?.performanceImprovementPlanReviewDates?.length) {
				const isAnyErrorWithReviewDates = [...updatedPipFormDetails?.performanceImprovementPlanReviewDates]?.map(
					(item: any, indexNum: number) => {
						const rec = { ...item };
						if (!rec?.reviewDate) {
							isFormValidated = false;
							rec.reviewDateError = 'At least 1 review date is required';
							if (!reviewDateIndexNum) {
								reviewDateIndexNum = indexNum + 1;
							}
						}
						if (rec?.reviewerIds?.length === Enums.ZERO) {
							isFormValidated = false;
							rec.reviewDateError = 'Reviewer is required';
						}
						return rec;
					}
				);
				// const isErrorExist = isAnyErrorWithReviewDates?.find((rec: any) => rec.reviewDateError);
				updatedPipFormDetails.performanceImprovementPlanReviewDates = isAnyErrorWithReviewDates;
				// updatedPipFormDetails.reviewDatesError = isErrorExist ? 'Please add review dates' : '';
				if (!scrollToPosition) {
					scrollToPosition = 6;
				}
				let updatedExpanded = [...expanded];
				if (!updatedExpanded.includes(1)) {
					setExpanded([...updatedExpanded, 1]);
				}
			}

			if (!updatedPipFormDetails?.areasOfConcern || updatedPipFormDetails?.areasOfConcern?.trim() === '') {
				updatedPipFormDetails.areasOfConcernError = 'Areas of concern is required';
				if (!scrollToPosition) {
					scrollToPosition = 5;
				}
				let updatedExpanded = [...expanded];
				if (!updatedExpanded.includes(2)) {
					setExpanded([...updatedExpanded, 2]);
				}
				isFormValidated = false;
			} else if (updatedPipFormDetails?.areasOfConcern || updatedPipFormDetails?.areasOfConcern?.trim() !== '') {
				updatedPipFormDetails.areasOfConcernError = '';
			}
			if (!updatedPipFormDetails?.roleExpectations || updatedPipFormDetails?.roleExpectations?.trim() === '') {
				updatedPipFormDetails.roleExpectationsError = 'Role expectations is required';
				isFormValidated = false;
			} else if (updatedPipFormDetails?.roleExpectations || updatedPipFormDetails?.roleExpectations?.trim() !== '') {
				updatedPipFormDetails.roleExpectationsError = '';
			}
			if (!updatedPipFormDetails?.improvementGoals || updatedPipFormDetails?.improvementGoals?.trim() === '') {
				updatedPipFormDetails.improvementGoalsError = 'Improvement goals is required';
				isFormValidated = false;
			} else if (updatedPipFormDetails?.improvementGoals || updatedPipFormDetails?.improvementGoals?.trim() !== '') {
				updatedPipFormDetails.improvementGoalsError = '';
			}

			if (scrollToPosition === 1) {
				scrollTo('pip_nominee_container');
			} else if (scrollToPosition === 2) {
				scrollTo('pip_title_container');
			} else if (scrollToPosition === 3) {
				scrollTo('pip_summary_container');
			} else if (scrollToPosition === 4) {
				scrollTo('pip_time_frame_container');
			} else if (scrollToPosition === 5) {
				scrollTo('pip_area_of_improvements_container');
			} else if (scrollToPosition === 6) {
				scrollTo(`pip_review_date_${reviewDateIndexNum ? reviewDateIndexNum - 1 : reviewDateIndexNum}`);
			}

			setPIPFormDetails(updatedPipFormDetails);

			return isFormValidated;
		}
	};
	const handleSavePIPFormAPICall = async (status: any, formType: any) => {
		try {
			setLoader(true);
			setExistAlreadyShown(false);
			if (pipFormDetails) {
				let updatedPipFormDetails = pipFormDetails
					? {
							...pipFormDetails,
							timeframeStartDate: pipFormDetails?.timeframeStartDate || null,
							timeframeEndDate: pipFormDetails?.timeframeEndDate || null,
					  }
					: {};
				delete updatedPipFormDetails.nomineeError;
				delete updatedPipFormDetails.titleError;
				delete updatedPipFormDetails.summaryError;
				delete updatedPipFormDetails.timeFrameError;
				delete updatedPipFormDetails.areasOfConcernError;
				delete updatedPipFormDetails.roleExpectationsError;
				delete updatedPipFormDetails.improvementGoalsError;
				delete updatedPipFormDetails.nomineeInfo;
				delete updatedPipFormDetails.reviewDatesError;

				delete updatedPipFormDetails.historyTimeframeStartDate;
				delete updatedPipFormDetails.historyTimeframeEndDate;
				delete updatedPipFormDetails.historyExtendTimeframe;
				delete updatedPipFormDetails.isTimeFrameChanged;
				delete updatedPipFormDetails.createdBy;

				updatedPipFormDetails.performanceImprovementPlanReviewDates =
					updatedPipFormDetails?.performanceImprovementPlanReviewDates?.map((rec: any) => {
						const data = { ...rec };
						delete data.reviewDateRange;
						delete data.reviewerDetails;

						return data;
					});

				updatedPipFormDetails.tasks =
					allTaskList && allTaskList?.length
						? allTaskList?.map((item: any) => {
								const task = { ...item };
								let formTaskLink: any[] = [];
								task?.link?.forEach((linkArray: any) => {
									formTaskLink.push(linkArray.link);
								});
								const newTask = {
									taskId: task?.isTaskAddedNew ? Enums.ZERO : task?.taskId || Enums.ZERO,
									goalTypeId: task?.goalTypeId || Enums.ZERO,
									goalId: task?.goalId || Enums.ZERO,
									taskName: task?.taskName || '',
									isCompleted: task?.isCompleted || false,
									taskType: task?.taskTypeId || Enums.FIVE,
									assignedTo: task?.assignedTo?.employeeId || Enums.ZERO,
									dueDate: task?.dueDate || '',
									priority: task?.priorityId || Enums.ONE,
									sourceMappingId: Enums.ZERO, // We have to send PIP ID at time of edit
									isCheckedInLinked: task?.isCheckedInLinked || false,
									link: formTaskLink,
									// reviewerIds: [620], // ============
									// startDate: '', // ============
								};
								return newTask;
						  })
						: [];
				updatedPipFormDetails.performanceImprovementPlanStatusId = status || Enums.ONE;

				if (updatedPipFormDetails.reviewers && updatedPipFormDetails.reviewers.length > 0) {
					updatedPipFormDetails.performanceImprovementPlanReviewDates.forEach((revierwes: any) => {
						updatedPipFormDetails.reviewers.forEach((item: any) => {
							revierwes.reviewerIds.push(item.id);
						});
					});
				}
				const requestPayload = { ...updatedPipFormDetails };
				let response: any = null;
				if (formType === 'Add') {
					response = await dispatch(createPIPPlan(requestPayload));
				} else if (formType === 'Edit') {
					response = await dispatch(updatePIPPlan(requestPayload));
				}

				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					if (status === Enums.ONE && response?.data?.entity) {
						const pipItem: any = await getPIPViewDetails(response?.data?.entity?.performanceImprovementPlanId);
						handleEditPip({}, pipItem, formType, false, true);
						getTaskDetailsForDraftSave();
						// const { messageList } = response?.data || {};
						// const keys = messageList ? Object.keys(messageList) : [];
						// const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('success', 'Draft saved successfully');
						setIsFormEdited(false);
					} else {
						if (formType === 'Add') {
							setNewAddedPip(response?.data?.entity || null);
						} else if (formType === 'Edit' && pipFormDetails?.performanceImprovementPlanStatusId === Enums.ONE) {
							setNewAddedPip(response?.data?.entity || null);
						} else {
							setNewAddedPip(null);
						}

						const { messageList } = response?.data || {};
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						showMsg('success', messages ? messages : `Successfully saved.`);

						setPIPFormDetails(defaultPIPFormDetails);
						setLoader(false);
						handleBackToListClick(formType);
						window.scrollTo(0, 0);
						//getAllPipData();
					}
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg(
						'error',
						messages ||
							`Error occurred while ${formType === 'Add' ? 'creating' : 'updating'} personal development plan.`
					);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const getPIPViewDetails = async (performanceImprovementPlanId: any) => {
		try {
			if (performanceImprovementPlanId) {
				// setLoader(true);
				const requestParam = `${performanceImprovementPlanId || Enums.ZERO}`;
				const response: any = await dispatch(getPIPDetailsForView(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					setLoader(false);
					return response?.data?.entity;
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Error occurred while fetching personal development plan.`);
					setLoader(false);
					return null;
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
			return null;
		}
	};
	const handleClearPIPForm = (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (isFormEdited) {
				setModalProps({
					open: true,
					event: null,
					type: 'ClearForm',
					message: t('Clear Form? <br />This will reset form and cannot be undone.'),
				});
			} else {
				setIsFormCleared(true);
				setSelectedDateRange(null);
				setPIPFormDetails(defaultPIPFormDetails);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'ClearForm') {
				setSelectedDateRange(null);
				setPIPFormDetails(defaultPIPFormDetails);
				setIsFormEdited(false);
				setIsFormCleared(true);
			} else if (modalProps?.type === 'UnSaveChanges') {
				if (modalProps?.event) {
					setPIPFormDetails(defaultPIPFormDetails);
					setIsFormEdited(false);
					const { target } = modalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
			} else if (modalProps?.type === 'DeletePIP') {
				const { pipItem, pipFormType } = modalProps?.details || {};
				deletePIPApiCall(pipItem, pipFormType);
			} else if (modalProps?.type === 'NomineeSearch') {
				handleSavePIPFormAPICall(Enums.TWO, modalProps?.details);
				setModalProps({ open: false, event: null, message: '' });
			}
			setModalProps({ open: false, event: null, message: '' });
		} else {
			if (modalProps?.type === 'NomineeSearchDirect') {
				setModalProps({ open: false, type: '', message: '', details: null });
				updatePipFormDetails('nomineeId', null, true);
			} else if (modalProps?.type === 'UnSaveChanges') {
				setModalProps({ open: false, type: '', message: '', details: null });
			}
		}
	};
	const handleCreatePIPClick = (e: any) => {
		setAllTaskList([]);
		setOpenCreatePipForm({
			open: true,
			pipFormEditDetails: null,
		});
		setOpenViewPipForm({
			open: false,
			pipItem: null,
		});
	};
	const handleBackToListClick = (formType: string, clickType = '') => {
		if (goBackToFeedback && clickType === 'onBackClick') {
			goBackToFeedbackDetails(goBackToFeedback);
		}
		dispatch(reloadPIPList(true));
		setIsExtendTimeFrameClicked(false);
		setIsDraftDirectEdit(false);
		setFinalFilterCondition(defaultFinalSettings);
		setPIPFormDetails(defaultPIPFormDetails);
		setAllTaskList([]);
		setOpenCreatePipForm({
			open: false,
			pipFormEditDetails: null,
		});
		setOpenViewPipForm({
			open: false,
			pipItem: null,
		});
		setGoBackToFeedback(null);
	};
	const pageSize = 3;
	const [searchValue, setSearchValue] = useState('');
	const [myPagingInfo, setMyPagingInfo] = useState({
		pageIndex: 1,
		pageSize: 3,
		totalPages: 0,
		totalRecords: 0,
	});
	const [otherPagingInfo, setOtherPagingInfo] = useState({
		pageIndex: 1,
		pageSize: 3,
		totalPages: 0,
		totalRecords: 0,
	});
	/**
	 * Create Pip related Details End Here
	 */
	const getFilters = (type = '') => {
		let payload: any = {
			finder: '',
			pageIndex: 1,
			pageSize: pageSize,
		};
		if (searchValue !== '') {
			payload.finder = searchValue;
		}

		if (selectedFilterDateRange && selectedFilterDateRange?.start?._d && selectedFilterDateRange?.end?._d) {
			payload.isDateFilterAdded = true;
			payload.timeframeStartDate = selectedFilterDateRange?.start?._d
				? formatFeedbackDate(selectedFilterDateRange?.start?._d)
				: '';
			payload.timeframeEndDate = selectedFilterDateRange?.end?._d
				? formatFeedbackDate(selectedFilterDateRange?.end?._d)
				: '';
		}
		if (finalFilterCondition?.status && finalFilterCondition?.status.length > 0) {
			let statusIds: any[] = [];
			finalFilterCondition.status.forEach((item: any) => {
				statusIds.push(item.performanceImprovementPlanStatusId);
			});
			payload.status = statusIds;
		}
		if (finalFilterCondition?.resolvedStatus && finalFilterCondition?.resolvedStatus.length > 0) {
			let resolvedStatusIds: any[] = [];
			finalFilterCondition.resolvedStatus.forEach((item: any) => {
				resolvedStatusIds.push(item.performanceImprovementPlanStatusId);
			});
			payload.resolvedStatus = resolvedStatusIds;
		}
		if (finalFilterCondition?.timeframe && finalFilterCondition?.timeframe.length === 1) {
			payload.isTimeFrameChanged = finalFilterCondition?.timeframe[0].performanceImprovementPlanStatusId === 1 ? 1 : 2;
		}
		let visiblePipIndex = 0;
		if (finalFilterCondition?.start && finalFilterCondition?.start.length === 1) {
			if (finalFilterCondition?.start[0].performanceImprovementPlanStatusId === 1) {
				visiblePipIndex = 1;
			} else if (finalFilterCondition?.start[0].performanceImprovementPlanStatusId === 2) {
				visiblePipIndex = 2;
			}
		}
		payload.visiblePipIndex = visiblePipIndex;
		setVisiblePip(visiblePipIndex);
		return payload;
	};
	const loadMoreMyRecords = async (pageIndex = 1) => {
		try {
			let payload = getFilters();
			payload.pageIndex = pageIndex;
			const myResp: any = await dispatch(getPipListingData(payload));
			if (myResp && myResp?.status === 200) {
				let options = Object.assign([], performanceImprovementList);
				const { entityList, pageInfo } = myResp?.data || {};
				let finalOptions = options.concat(entityList);
				setPerformanceImprovementList(finalOptions);
				setMyPagingInfo(pageInfo);
			}
		} catch (error) {}
	};
	const loadMoreOtherRecords = async (pageIndex = 1, pageSize = 3, existingData = null) => {
		let payload = getFilters();
		payload.pageIndex = pageIndex;
		payload.pageSize = pageSize;
		const otherResp: any = await dispatch(getOtherPipListingData(payload));
		if (otherResp && otherResp?.status === 200) {
			let options: any = [];
			if (existingData !== null) {
				options = Object.assign([], existingData);
			} else {
				options = Object.assign([], performanceOthersImprovementList);
			}
			const { entityList, pageInfo } = otherResp?.data || {};
			let finalOptions = options.concat(entityList);
			setPerformanceOthersImprovementList(finalOptions);
			setOtherPagingInfo(pageInfo);
			setLoader(false);
		}
	};
	const getAllPipData = async () => {
		try {
			let payload = getFilters();
			setLoader(true);
			const [myResp, otherResp]: any = await Promise.allSettled([
				dispatch(getPipListingData(payload)),
				dispatch(getOtherPipListingData(payload)),
			]);
			let loaded = true;
			let hasData = false;
			let entityListCount = 0;
			if (myResp.value && myResp.value?.status === 200) {
				// call alert api
				dispatch(getFeedbackAlertData());
				dispatch(getPipAlertData());
				const { entityList, pageInfo } = myResp.value?.data || {};
				if (payload.visiblePipIndex !== 2) {
					setPerformanceImprovementList(entityList || []);
					entityListCount = entityList.length;
				} else {
					setPerformanceImprovementList([]);
				}
				setMyPagingInfo(pageInfo);
				hasData = hasData || entityList?.length > 0;
			} else {
				setPerformanceImprovementList([]);
				loaded = false;
			}
			if (otherResp.value && otherResp.value?.status === 200) {
				const { entityList, pageInfo } = otherResp.value?.data || {};
				if (payload.visiblePipIndex !== 1) {
					hasData = hasData || otherResp.value?.data?.entityList.length > 0;
					if (entityListCount === 0 && otherResp.value?.data?.entityList.length > 0) {
						loadMoreOtherRecords(2, 3, otherResp.value?.data?.entityList);
					} else {
						let finalList: any[] = entityList || [];
						if (newAddedPip) {
							finalList = finalList.filter(
								(item: any) => item.performanceImprovementPlanId !== newAddedPip.performanceImprovementPlanId
							);
							finalList = [{ ...newAddedPip, isHighlighted: true }, ...finalList];
						}
						setPerformanceOthersImprovementList(finalList);
						setOtherPagingInfo(pageInfo);
						setLoader(false);
						setTimeout(() => {
							setNewAddedPip(null);
						}, 5000);
					}
				} else {
					let finalList: any[] = [];
					if (newAddedPip) {
						finalList = [{ ...newAddedPip, isHighlighted: true }];
					}
					setPerformanceOthersImprovementList(finalList);
					setLoader(false);
					setTimeout(() => {
						setNewAddedPip(null);
					}, 5000);
				}
			} else {
				let finalList: any[] = [];
				if (newAddedPip) {
					finalList = [{ ...newAddedPip, isHighlighted: true }];
				}
				setPerformanceOthersImprovementList(finalList);
				loaded = false;
				setLoader(false);
				setTimeout(() => {
					setNewAddedPip(null);
				}, 5000);
			}
			setNoRecords(!hasData);
		} catch (error) {
			let finalList: any[] = [];
			if (newAddedPip) {
				finalList = [{ ...newAddedPip, isHighlighted: true }];
			}
			setPerformanceOthersImprovementList(finalList);
			setPerformanceImprovementList([]);
			setLoader(false);
			setTimeout(() => {
				setNewAddedPip(null);
			}, 5000);
		}
	};
	const handlePIPItemClick = async (event: any, pipItem: any) => {
		try {
			if (pipItem?.performanceImprovementPlanStatusId === Enums.ONE) {
				setLoader(true);
				const pipItemDetails: any = await getPIPViewDetails(pipItem?.performanceImprovementPlanId);
				handleEditPip({}, pipItemDetails, 'Edit', false, true);
			} else {
				setOpenViewPipForm({
					open: true,
					pipItem,
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleEditPip = async (
		event: any,
		pipItem: any,
		pipFormType: string,
		isTimeFrameExtendClick: boolean,
		isDraftDirectEditClick = false
	) => {
		try {
			setLoader(true);
			const defaultPipEditInfo = {
				performanceImprovementPlanId: pipItem?.performanceImprovementPlanId || Enums.ZERO,
				nomineeId: pipItem?.nomineeDetails?.employeeId || Enums.ZERO,
				nomineeInfo: pipItem?.nomineeDetails
					? [
							{
								...pipItem?.nomineeDetails,
								label: `${pipItem?.nomineeDetails?.firstName || ''} ${pipItem?.nomineeDetails?.lastName || ''}`,
								value: pipItem?.nomineeDetails?.employeeId || Enums.ZERO,
								secondaryText: pipItem?.nomineeDetails?.designation || '',
							},
					  ]
					: null, // for local use
				title: pipItem?.title || '',
				summary: pipItem?.summary || '',
				timeframeStartDate: pipItem?.timeframeStartDate || '',
				timeframeEndDate: pipItem?.timeframeEndDate || '',
				extendTimeframes: pipItem?.extendTimeframes,
				historyTimeframeStartDate: pipItem?.timeframeStartDate || '',
				historyTimeframeEndDate: pipItem?.timeframeEndDate || '',
				historyExtendTimeframe: pipItem?.extendTimeframes || '',
				areasOfConcern: pipItem?.areasOfConcern || '',
				roleExpectations: pipItem?.roleExpectations || '',
				improvementGoals: pipItem?.improvementGoals || '',
				performanceImprovementPlanStatusId: pipItem?.performanceImprovementPlanStatusId || Enums.ONE,
				feedbackIds: pipItem?.linkedFeedbacks?.length ? pipItem?.linkedFeedbacks.map((rec: any) => rec.feedbackId) : [],
				performanceImprovementPlanReviewDates:
					pipItem?.performanceImprovementPlanReviewDates?.map((item: any) => {
						const data = { ...item };
						let ids: any[] = [];
						data.performanceImprovementPlanReviewers = data?.performanceImprovementPlanReviewers.map((it: any) => {
							const rec = { ...it };
							ids.push(rec?.reviewerId || 0);
							rec.employeeId = rec.reviewerId;

							return rec;
						});
						data.reviewerIds = ids;
						return data;
					}) || [],
				documents: pipItem?.documents || [],
				tasks: [],
				createdBy: pipItem?.createdBy,
			};
			const response: any = await dispatch(getNomineeFeedbackList(pipItem?.nomineeDetails?.employeeId));
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				// defaultPipEditInfo.feedbackIds =
				// 	entityList && entityList?.length ? entityList?.map((item: any) => item.feedbackId) : [];
				setSelectedNomineeFeedbackList(entityList || []);

				setAllTaskList(null);
				setIsExtendTimeFrameClicked(isTimeFrameExtendClick);
				setIsDraftDirectEdit(isDraftDirectEditClick);
				setPIPFormDetails(defaultPipEditInfo);
				setOpenCreatePipForm({
					open: true,
					pipFormEditDetails: defaultPipEditInfo,
				});
				setOpenViewPipForm({
					open: false,
					pipItem: null,
				});
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Some error occurred`);
				setSelectedNomineeFeedbackList([]);

				setAllTaskList(null);
				setIsExtendTimeFrameClicked(isTimeFrameExtendClick);
				setIsDraftDirectEdit(isDraftDirectEditClick);
				setPIPFormDetails(defaultPipEditInfo);
				setOpenCreatePipForm({
					open: true,
					pipFormEditDetails: defaultPipEditInfo,
				});
				setOpenViewPipForm({
					open: false,
					pipItem: null,
				});
				setLoader(false);
			}
			// setSelectedNomineeFeedbackList(
			// 	pipItem?.linkedFeedbacks?.length
			// 		? pipItem?.linkedFeedbacks.map((rec: any) => {
			// 				const data = { ...rec };
			// 				return data;
			// 		  })
			// 		: []
			// );
			// setAllTaskList(null);
			// setIsExtendTimeFrameClicked(isTimeFrameExtendClick);
			// setPIPFormDetails(defaultPipEditInfo);
			// setOpenCreatePipForm({
			// 	open: true,
			// 	pipFormEditDetails: defaultPipEditInfo,
			// });
			// setOpenViewPipForm({
			// 	open: false,
			// 	pipItem: null,
			// });
			// setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleTwoFactorCloseModal = async (e: any, type: any) => {
		if (type === 1) {
			const { pipItem, pipFormType } = twoFactorModalProps?.details || {};
			setTwoFactorModalProps({ open: false, message: '', details: '' });
			deletePIPApiCall(pipItem, pipFormType);
		} else {
			setTwoFactorModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleDeletePip = (event: any, pipItem: any, pipFormType: string) => {
		// setModalProps({
		// 	open: true,
		// 	event: null,
		// 	details: { pipItem, pipFormType },
		// 	type: 'DeletePIP',
		// 	message: t('Are you sure you want to delete this PIP?'),
		// });
		setTwoFactorModalProps({
			open: true,
			message: {
				headingText: '', // t('Deleting the Performance Improvement Plan?'),
				subHeading: t(
					'Deleting the Performance Improvement Plan will delete all reviews and tasks details (if any) and data cannot be recovered.'
				),
			},
			details: { pipItem, pipFormType },
		});
	};
	const deletePIPApiCall = async (pipItem: any, pipFormType = 'View') => {
		try {
			if (pipItem?.performanceImprovementPlanId) {
				setLoader(true);
				const requestParam = `performanceImprovementPlanId=${pipItem?.performanceImprovementPlanId}`;
				const response: any = await dispatch(deletePIPPlan(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					handleBackToListClick(pipFormType);
					showMsg('success', `Performance Improvement Plan deleted successfully`);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Error occurred while deleting personal development plan.`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const handleOneOnOneClick = (e: any, clickType: string, empId: any) => {
		const { reportingTo } = userDetails || {};
		let employeeId = reportingTo || 0;
		if (clickType === 'ViewPip') {
			employeeId = empId || 0;
		}
		if (clickType && employeeId) {
			const redirectInfo = { employeeId: employeeId, employeeInfo: null, redirectFrom: 'PIP' };

			getRedirectedEmployeeData(redirectInfo);
		}
	};
	const getRedirectedEmployeeData = async (details: any) => {
		setLoader(true);
		const { employeeId } = details || {};
		const resp: any = await dispatch(globalSearchAPIWithTeam(employeeId, 1, 999999, 1));
		setLoader(false);
		if (resp && resp?.data && resp?.data?.status === 200) {
			let list = resp?.data?.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetails.employeeId);
			const employeeInfo = list && list.length ? list.find((rec: any) => rec?.employeeId === employeeId) : null;
			if (employeeInfo) {
				handleClickRequestOneOnOneCreate({});
				// handleRequestOneOnOneCall({}, employeeInfo);
			} else {
				handleClickRequestOneOnOneCreate({});
			}
		} else {
			handleClickRequestOneOnOneCreate({});
		}
	};
	const handleRequestOneOnOneCall = (event: any, empRecord: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Direct', details: '', empInfo: empRecord });
	};
	const handleClickRequestOneOnOneCreate = (event: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Add' });
	};

	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const handleSearchText = (searchText: any) => {
		//perform search
		dispatch(reloadPIPList(true));
	};
	const applyFilter = (filterData: any) => {
		setFinalFilterCondition(filterData);
		dispatch(reloadPIPList(true));
	};
	const applyDateFilter = (range: any) => {
		setSelectedFilterDateRange(range);
		dispatch(reloadPIPList(true));
	};
	const myLoadMore = () => {
		loadMoreMyRecords(myPagingInfo.pageIndex + 1);
	};
	const otherLoadMore = () => {
		loadMoreOtherRecords(otherPagingInfo.pageIndex + 1);
	};
	const showPermissionMessage = () => {
		if (okrMasterData && typeof okrMasterData === 'object') {
			return okrMasterData?.performanceImprovementPlanPermission?.isAddingPipAllowed !== false;
		}
		return true;
	};
	const getNomineeFeedbackListAPICall = async (nomineeId = 0) => {
		try {
			if (nomineeId) {
				setLoader(true);
				const response: any = await dispatch(getNomineeFeedbackList(nomineeId));
				if (response?.data?.status === Enums.STATUS_SUCCESS) {
					updatePipFormDetails('feedbackIds', []);
					setSelectedNomineeFeedbackList(response?.data?.entityList || []);
					setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					updatePipFormDetails('feedbackIds', []);
					setSelectedNomineeFeedbackList([]);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			updatePipFormDetails('feedbackIds', []);
			setSelectedNomineeFeedbackList([]);
			setLoader(false);
		}
	};
	// =============================================================
	const getTaskDetailsForDraftSave = () => {
		dispatch(fetchGlobalTaskPending());
		fetchGlobalTask(Enums.ZERO);
		// getRecentSearchesList();
	};
	const fetchGlobalTask = async (pageIndex: number) => {
		setLoader(true);
		let dateData = {};
		if (selectedFilterDateRange) {
			const startDate = selectedFilterDateRange?.start?._d || new Date();
			const endDate = selectedFilterDateRange?.end?._d || new Date();
			dateData = {
				startDate: formatOneOnOneRequestDate(startDate),
				endDate: formatOneOnOneRequestDate(endDate),
			};
		}
		// console.log('PIP-TASK-DETAILS === pipFormDetails', pipFormDetails);
		const defaultTaskListRequest = {
			isMyTask: true,
			isCompleted: false,
			pageSize: Enums.MAX_USER_SIZE,
			pageIndex: Enums.ONE,
			order: '',
			sortingText: '',
			finder: [],
			taskFilter: {
				dueDate: [],
				taskType: [],
				priority: [],
			},
			startDate: null,
			endDate: null,
			taskGroupById: Enums.ZERO,
			taskGroupByType: Enums.ZERO,
			moduleId: Enums.FIVE,
			employeeId: Enums.ZERO,
			moduleItemKeyId: Enums.ZERO,
		};
		const data = {
			...defaultTaskListRequest,
			moduleItemKeyId: pipFormDetails?.performanceImprovementPlanId || Enums.ZERO,
			employeeId: userDetails?.employeeId || Enums.ZERO,
			// employeeId: pipFormDetails?.nomineeDetails?.employeeId || pipFormDetails?.nomineeId || getEmployeeId(Enums.ONE),
			pageIndex: pageIndex + 1,
			isMyTask: true,
			...dateData,
		};
		// setGlobalTaskRequest(data);
		dispatch(getAllGlobalTask(data, false));
	};
	//===============================================================
	const feedbackDetailsForCreatePip = async (feedbackDetails: any) => {
		try {
			if (feedbackDetails?.nomineeDetails?.employeeId) {
				setLoader(true);
				setOpenCreatePipForm({
					open: true,
					pipFormEditDetails: null,
				});
				setOpenViewPipForm({
					open: false,
					pipItem: null,
				});
				const defaultPipEditInfo = {
					performanceImprovementPlanId: Enums.ZERO,
					nomineeId: feedbackDetails?.nomineeDetails?.employeeId || Enums.ZERO,
					nomineeInfo: feedbackDetails?.nomineeDetails
						? [
								{
									...feedbackDetails?.nomineeDetails,
									label: `${feedbackDetails?.nomineeDetails?.firstName || ''} ${
										feedbackDetails?.nomineeDetails?.lastName || ''
									}`,
									value: feedbackDetails?.nomineeDetails?.employeeId || Enums.ZERO,
									secondaryText: feedbackDetails?.nomineeDetails?.designation || '',
								},
						  ]
						: null, // for local use
					title: '',
					summary: '',
					timeframeStartDate: '',
					timeframeEndDate: '',
					extendTimeframes: [],
					areasOfConcern: '',
					roleExpectations: '',
					improvementGoals: '',
					performanceImprovementPlanStatusId: Enums.ONE,
					feedbackIds: [],
					performanceImprovementPlanReviewDates: [],
					documents: [],
					tasks: [],
				};
				//prefilled title
				const pipTitlePrefilledText =
					okrMasterData && okrMasterData?.performanceImprovementPlanPermission?.isPIPTitlePrefilled
						? okrMasterData?.performanceImprovementPlanPermission?.pipTitlePrefilledText
						: '';
				defaultPipEditInfo.title = pipFormDetails?.title || pipTitlePrefilledText;
				const response: any = await dispatch(getNomineeFeedbackList(feedbackDetails?.nomineeDetails?.employeeId));
				if (response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entityList } = response?.data || {};
					defaultPipEditInfo.feedbackIds =
						entityList && entityList?.length
							? entityList
									?.filter((item: any) => item.feedbackId === feedbackDetails?.feedbackId)
									.map((item: any) => item.feedbackId)
							: [];
					setSelectedNomineeFeedbackList(entityList || []);
					setAllTaskList(null);
					setPIPFormDetails(defaultPipEditInfo);
					setFeedbackDetailsForPip(null);
					setLoader(false);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Some error occurred`);
					setSelectedNomineeFeedbackList([]);
					setAllTaskList(null);
					setPIPFormDetails(defaultPipEditInfo);
					setFeedbackDetailsForPip(null);
					setLoader(false);
				}
				setTimeout(() => {
					checkEmployeePIPCreatedOrNot(feedbackDetails?.nomineeDetails?.employeeId || Enums.ZERO);
				}, 1000);
			} else {
				setFeedbackDetailsForPip(null);
				showMsg('error', 'Some error occurred');
			}
		} catch (error) {
			console.error(error);
			showMsg('error', 'Some error occurred');
			setFeedbackDetailsForPip(null);
			setLoader(false);
		}
	};
	const checkEmployeePIPCreatedOrNot = async (empId: any) => {
		try {
			const { isExist, message } = await checkPipNomineeExistApiCall(empId);
			setLoader(false);
			if (!isExist) {
				setTimeout(() => {
					setModalProps({
						open: true,
						type: 'NomineeSearchDirect',
						message: message,
						details: null,
					});
					setExistAlreadyShown(true);
				}, 1000);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			{' '}
			{!showPermissionMessage() &&
			performanceImprovementList &&
			performanceImprovementList.length === 0 &&
			performanceOthersImprovementList &&
			performanceOthersImprovementList.length === 0 ? (
				<Box className='welcome-content pip-welcome pip-no-permission' id='pip-welcome'>
					<Box textAlign='center' className='pad-container'>
						<Typography variant='h2'>{t('NoPipMsg1')}</Typography>
						<Typography>{t('NoPipMsg2')}</Typography>
						<AnimatedIcon className='' width='516' type='image/svg+xml' alt='WelcomeImage' imagePath={WelcomeImage} />
					</Box>
				</Box>
			) : (
				<>
					<Box className='pip-area'>
						{openCreatePipForm?.open && !openViewPipForm?.open ? (
							<Box className='pip-header-area'>
								<Box className='pip-header-left'>
									<Box className='back-to-main'>
										<Typography variant='body2'>
											<Link id='back-btn' onClick={() => handleBackToListClick('Add', 'onBackClick')}>
												<BackArrow /> {t('back')}
											</Link>
										</Typography>
									</Box>
									{openCreatePipForm?.pipFormEditDetails &&
									openCreatePipForm?.pipFormEditDetails?.nomineeInfo?.length &&
									pipFormDetails?.performanceImprovementPlanStatusId !== Enums.ONE ? (
										<Box className='user-info'>
											<Box className='user-img'>
												{openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0] ? (
													<>
														{openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0]?.imagePath ? (
															<Avatar
																className='avatar-default'
																src={openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0].imagePath}
															/>
														) : (
															<Avatar className={'avatar-default'}>
																{getUserName({
																	firstName: openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0]?.firstName || '',
																	lastName: openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0]?.lastName || '',
																	fullName: openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0]?.fullName || '',
																})}
															</Avatar>
														)}
													</>
												) : (
													<></>
												)}
											</Box>

											<Box className='user-info-details'>
												<Typography variant='h4'>{`${
													openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0]?.firstName || ''
												} ${openCreatePipForm?.pipFormEditDetails?.nomineeInfo[0]?.lastName || ''}`}</Typography>
											</Box>
										</Box>
									) : (
										<Typography variant='body2'>{t('createPipLabel')}</Typography>
									)}
								</Box>
								<Box className='pip-header-right'>
									{isDraftDirectEdit ? (
										<PopperMenu
											className={'user-menu-btn'}
											popperClassName={'alignment-popper-menu popper-menu-list'}
											anchorEl={anchorEl}
											handleClose={handleClose}
											includeKebabMenu={true}
											menuClicked={popperMenuClicked}
											setPopperMenuClicked={setPopperMenuClicked}
											btnId={`more-btn-c`}
											{...props}
											children={
												<>
													<ListItem
														id='delete_pip_details'
														onClick={(event: any) => {
															handleClose();
															handleDeletePip(event, openCreatePipForm?.pipFormEditDetails, 'Edit');
														}}
													>
														<ListItemAvatar>
															<DeleteIcon />
														</ListItemAvatar>
														<ListItemText primary={t('delete')} />
													</ListItem>
												</>
											}
										></PopperMenu>
									) : (
										<></>
									)}
								</Box>
							</Box>
						) : (
							<></>
						)}
						{openCreatePipForm?.open || openViewPipForm?.open ? (
							<></>
						) : (
							<Box className='pip-header-area'>
								<Box className='pip-header-left'>
									{!loader &&
										(visiblePip !== 2 && performanceImprovementList && performanceImprovementList.length > 0 ? (
											<Typography variant='h2'>My ({myPagingInfo.totalRecords})</Typography>
										) : visiblePip !== 1 &&
										  performanceOthersImprovementList &&
										  performanceOthersImprovementList.length > 0 ? (
											<Typography variant='h2'>
												{t('allNominations')} ({otherPagingInfo.totalRecords})
											</Typography>
										) : (
											<></>
										))}
								</Box>
								<Box className='pip-header-right'>
									{(performanceImprovementList && performanceImprovementList.length > 0) ||
									(performanceOthersImprovementList && performanceOthersImprovementList.length > 0) ||
									selectedFilterDateRange ||
									(finalFilterCondition.filterData && finalFilterCondition.filterData.length) ||
									searchValue ? (
										<CommonFilters
											{...props}
											searchValue={searchValue}
											setSearchValue={setSearchValue}
											defaultFinalSettings={defaultFinalSettings}
											finalFilterCondition={finalFilterCondition}
											setFinalFilterCondition={applyFilter}
											handleSearchText={handleSearchText}
											setSelectedFilterDateRange={applyDateFilter}
											selectedFilterDateRange={selectedFilterDateRange}
											minDateValue={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
											maxDateValue={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
											defaultLocalSettings={defaultFinalSettings}
											pipFiltersList={pipFiltersList}
										/>
									) : (
										<></>
									)}
									{isUserHavePIPPermission() ? (
										<OKRButton
											className='add-btn'
											icon={<AddIconSvg />}
											title={'Create PIP'}
											handleClick={handleCreatePIPClick}
										/>
									) : (
										<></>
									)}
								</Box>
							</Box>
						)}
						<Box className='pip-content-area'>
							{openCreatePipForm?.open && !openViewPipForm?.open ? (
								<div ref={ref}>
									<CreatePip
										pipFormType={openCreatePipForm?.pipFormEditDetails ? 'Edit' : 'Add'}
										loader={loader}
										setLoader={setLoader}
										expanded={expanded}
										handleChange={handleChange}
										selectedDateRange={selectedDateRange}
										setSelectedDateRange={setSelectedDateRange}
										pipFormDetails={pipFormDetails}
										setPIPFormDetails={setPIPFormDetails}
										updatePipFormDetails={updatePipFormDetails}
										isFormEdited={isFormEdited}
										handleClearPIPForm={handleClearPIPForm}
										handleSaveAsDraftPIPForm={handleSaveAsDraftPIPForm}
										handleSubmitPIPForm={handleSubmitPIPForm}
										allTaskList={allTaskList}
										setAllTaskList={setAllTaskList}
										isExtendTimeFrameClicked={isExtendTimeFrameClicked}
										scrollTo={scrollTo}
										timeFrameChangeReasonTypes={timeFrameChangeReasonTypes}
										checkPipNomineeExistApiCall={checkPipNomineeExistApiCall}
										showMsg={showMsg}
										getNomineeFeedbackList={getNomineeFeedbackListAPICall}
										selectedNomineeFeedbackList={selectedNomineeFeedbackList}
										setIsFormEdited={setIsFormEdited}
										isFormCleared={isFormCleared}
										setIsFormCleared={setIsFormCleared}
										checkEmployeePIPCreatedOrNot={checkEmployeePIPCreatedOrNot}
									/>
								</div>
							) : (
								<>
									{!openCreatePipForm?.open && openViewPipForm?.open ? (
										<ViewPip
											pipFormType={'View'}
											loader={loader}
											setLoader={setLoader}
											pipItem={openViewPipForm?.pipItem || null}
											handleBackToListClick={handleBackToListClick}
											isUserHavePIPPermission={isUserHavePIPPermission}
											isUserHavePIPEditPermission={isUserHavePIPEditPermission}
											handleDeletePip={handleDeletePip}
											handleEditPip={handleEditPip}
											handleOneOnOneClick={handleOneOnOneClick}
											userDetails={userDetails}
											allTaskList={allTaskList}
											setAllTaskList={setAllTaskList}
											resolveStatusTypes={resolveStatusTypes}
											timeFrameChangeReasonTypes={timeFrameChangeReasonTypes}
											showMsg={showMsg}
											requestOneOnOneDrawer={requestOneOnOneDrawer}
										/>
									) : loader ? (
										<Box className='loading-center-global'>
											<Typography>Loading...</Typography>
										</Box>
									) : noRecords ? (
										<Box className='welcome-content pip-welcome pip-no-permission' id='pip-welcome'>
											<Box textAlign='center' className='pad-container'>
												<Typography variant='h2'>
													{selectedFilterDateRange &&
													selectedFilterDateRange?.start?._d &&
													selectedFilterDateRange?.end?._d
														? t('noRecordPip')
														: finalFilterCondition.filterData && finalFilterCondition.filterData.length > 0
														? t('noRecordPip')
														: searchValue
														? t('noRecordPip')
														: t('NoPipMsg1')}
												</Typography>
												<Typography>
													{selectedFilterDateRange &&
													selectedFilterDateRange?.start?._d &&
													selectedFilterDateRange?.end?._d
														? t('noRecordPipText')
														: finalFilterCondition.filterData && finalFilterCondition.filterData.length > 0
														? t('noRecordPipText')
														: searchValue
														? t('noRecordPipText')
														: t('NoPipMsg2')}
												</Typography>
												<AnimatedIcon
													className=''
													width='516'
													type='image/svg+xml'
													alt='WelcomeImage'
													imagePath={WelcomeImage}
												/>
											</Box>
										</Box>
									) : (
										<>
											<>
												{visiblePip !== 2 && performanceImprovementList && performanceImprovementList.length > 0 && (
													<>
														<PipMyList
															performanceData={performanceImprovementList}
															setPerformanceData={setPerformanceImprovementList}
															handlePIPItemClick={handlePIPItemClick}
															headingLabel={'My'}
															showLoadMore={
																myPagingInfo.pageIndex !== myPagingInfo.totalPages &&
																myPagingInfo.pageIndex <= myPagingInfo.totalPages
															}
															loadMore={myLoadMore}
														/>
													</>
												)}
											</>
											<>
												{visiblePip !== 1 &&
													performanceOthersImprovementList &&
													performanceOthersImprovementList.length > 0 && (
														<>
															{performanceOthersImprovementList &&
															performanceImprovementList &&
															performanceImprovementList.length > 0 &&
															performanceOthersImprovementList.length ? (
																<Box className='pip-others-header'>
																	<Typography variant='h2'>
																		{t('allNominations')} ({otherPagingInfo.totalRecords})
																	</Typography>
																</Box>
															) : (
																<></>
															)}
															<PipMyList
																performanceData={performanceOthersImprovementList}
																setPerformanceData={setPerformanceOthersImprovementList}
																handlePIPItemClick={handlePIPItemClick}
																headingLabel={'Other'}
																showLoadMore={
																	otherPagingInfo.pageIndex !== otherPagingInfo.totalPages &&
																	otherPagingInfo.pageIndex <= otherPagingInfo.totalPages
																	//(otherPagingInfo.pageIndex === otherPagingInfo.totalPages)
																}
																loadMore={otherLoadMore}
															/>
														</>
													)}
											</>
										</>
									)}
								</>
							)}
						</Box>
					</Box>
					{/* Delete user dialog */}
					{twoFactorModalProps && twoFactorModalProps.open && (
						<DialogComponent
							module='confirmation'
							modalOpen={twoFactorModalProps.open}
							handleCloseModal={(e: any) => handleTwoFactorCloseModal(e, 2)}
							matchString={Enums.DELETE_TEXT}
							handleConfirmClick={(e: any) => handleTwoFactorCloseModal(e, 1)}
							headingText={twoFactorModalProps?.message?.headingText}
							subHeading={twoFactorModalProps?.message?.subHeading}
						/>
					)}
					{modalProps?.open && (
						<AlertDialog
							message={modalProps?.message || ''}
							handleCloseModal={handleCloseModal}
							modalOpen={modalProps.open}
						/>
					)}
					{requestOneOnOneDrawer && requestOneOnOneDrawer?.open ? (
						<CreateOneOnOne
							requestOneOnOneDrawer={requestOneOnOneDrawer}
							setRequestOneOnOneDrawer={setRequestOneOnOneDrawer}
							isDirect={1}
							callingType={'PIP'}
						/>
					) : (
						<></>
					)}
				</>
			)}
		</>
	);
};
