import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MainDrawer } from '../../../../../Admin/CommonComponent/MainDrawer';
import { CreateAdminFeedbackDrawer } from './CreateAdminFeedbackDrawer';
import { useDispatch } from 'react-redux';
import { Enums } from '../../../../../../config/enums';
import {
	insertFeedbackCategory,
	reloadFeebackCategoryList,
	updateFeedbackCategory,
} from '../../../../../../action/adminPerformanceDevelopment';
import { useSnackbar } from 'notistack';
import { DialogComponent } from '../../../../CommonComponent/DialogComponent';

export const CreateFeedbackcategory: React.FC<any> = (props: any) => {
	const { handleAdminFeedbackDrawerClose, addFeedbackCategoryDrawer, setLoader } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();

	const defaultPostData: any = {
		feedbackCategoryId: 0,
		feedbackTypeId: 0,
		name: '',
		description: '',
		timeframe: 3,
		timeframeType: 1,
		timeframeDuration: 0,
		reminderDuration: 0,
		editTimeFrameType: 0,
		editEndDateType: 0,
		noOfExtensionsAllows: 0,
		autoExtensionsDays: 0,
		isDefault: true,
		isEnabled: true,
	};
	const [createEditData, setCreateEditData] = useState<any>(defaultPostData);
	const [formEdited, setFormEdited] = useState<any>(false);
	const [errorObjectData, setErrorObjectData] = useState<any>({});
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if (addFeedbackCategoryDrawer.categoryData) {
			setCreateEditData({ ...defaultPostData, ...addFeedbackCategoryDrawer.categoryData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addFeedbackCategoryDrawer]);
	const updateFieldData = (name: string, value: any, extraData: any = {}) => {
		if (name === 'timeframe' && value === 3) {
			setCreateEditData({
				...createEditData,
				[name]: value,
				timeframeType: 2,
				timeframeDuration: 0,
				reminderDuration: 0,
				editTimeFrameType: 0,
				editEndDateType: 0,
				noOfExtensionsAllows: 0,
				autoExtensionsDays: 0,
			});
		} else {
			if (Object.keys(extraData).length > 0) {
				setCreateEditData({ ...createEditData, ...extraData, [name]: value });
			} else {
				setCreateEditData({ ...createEditData, [name]: value });
			}
		}
		setFormEdited(true);
	};
	const validateForm = () => {
		let isError = false;
		let errorObjectDataCopy: any = {};
		if (createEditData.name.trim() === '') {
			errorObjectDataCopy.name = true;
			isError = true;
		}

		if (
			createEditData?.timeframe === 1 &&
			createEditData?.timeframeType === 2 &&
			createEditData.timeframeDuration === 0
		) {
			errorObjectDataCopy.timeframeDuration = true;
			isError = true;
		}

		if (
			createEditData?.timeframe === 1 &&
			createEditData?.timeframeType === 2 &&
			createEditData?.reminderDuration === 0
		) {
			errorObjectDataCopy.reminderDuration = true;
			isError = true;
		}
		if (createEditData?.timeframe !== 3 && createEditData?.timeframeType === 1) {
			if (createEditData?.editTimeFrameType === 0) {
				errorObjectDataCopy.editTimeFrameType = true;
				isError = true;
			} else {
				if (createEditData?.editTimeFrameType === 3) {
					if (createEditData?.editEndDateType === 0) {
						errorObjectDataCopy.editEndDateType = true;
						isError = true;
					} else {
						if (createEditData?.editEndDateType === 3) {
							if (createEditData?.noOfExtensionsAllows === 0) {
								errorObjectDataCopy.noOfExtensionsAllows = true;
								isError = true;
							}
							if (createEditData?.autoExtensionsDays === 0) {
								errorObjectDataCopy.autoExtensionsDays = true;
								isError = true;
							}
						}
					}
				}
			}
		}
		setErrorObjectData(errorObjectDataCopy);
		return isError;
	};
	const handleFeedbackCategoryDrawerClose = async () => {
		let categoryCreateData = { ...createEditData };
		categoryCreateData.feedbackTypeId = addFeedbackCategoryDrawer.typeData.feedbackTypeId;
		if (validateForm()) {
			return false;
		}
		try {
			setLoader(true);
			let response: any = {};
			if (categoryCreateData.feedbackCategoryId) {
				response = await dispatch(updateFeedbackCategory(categoryCreateData));
			} else {
				response = await dispatch(insertFeedbackCategory(categoryCreateData));
			}
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				dispatch(reloadFeebackCategoryList(categoryCreateData.feedbackTypeId));
				handleAdminFeedbackDrawerClose({}, 'save', response?.data || {});
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				enqueueSnackbar(messages || `Error occurred while performing action`, {
					variant: 'success',
					autoHideDuration: 3000,
				});
				setLoader(false);
				//showEWrror
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleCloseAlertModal = (e: React.ChangeEvent<HTMLInputElement>, type: number) => {
		e.preventDefault();
		if (type === 1) {
			setFormEdited(false);
			handleAdminFeedbackDrawerClose({}, 'close');
		}
		setModalOpen(false);
	};
	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			handleFeedbackCategoryDrawerClose();
		} else {
			if (formEdited) {
				setModalOpen(true);
			} else {
				handleAdminFeedbackDrawerClose(event, 'cancel');
			}
		}
	};
	return (
		<>
			<MainDrawer
				open={true}
				transitionDuration={{ enter: 500, exit: 1000 }}
				headerTitle={<Typography variant='h2'>{t('addCategory')}</Typography>}
				rightCloseBtn={false}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
				//loader={loader}
				saveButtonText={t('submit')}
				children={
					<Box className='drawer-inner-content'>
						<CreateAdminFeedbackDrawer
							{...props}
							updateFieldData={updateFieldData}
							createEditData={createEditData}
							errorObjectData={errorObjectData}
							setErrorObjectData={setErrorObjectData}
						/>
					</Box>
				}
				handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
				handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
				handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
				//isSaveButtonDisabled={isSaveDisabled}
			/>

			{modalOpen && (
				<DialogComponent
					module='information'
					message={t('unSavedItemAlert')}
					handleCloseModal={handleCloseAlertModal}
					modalOpen={modalOpen}
				/>
			)}
		</>
	);
};
