import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { OKRButton } from '../../../../Common/OKRButton';
import { MultiSelect } from '../../../CommonComponent/MultiSelect';
import { EnumAllowUsersToCreateFeedback } from '../../../../../config/enums';
import { useDispatch, useSelector } from 'react-redux';
import { getEmailRecipient, updateEmailRecipient } from '../../../../../action/adminPerformanceDevelopment';
import { useSnackbar } from 'notistack';

export const FeedbackRightSettingDropdown: React.FC<any> = (props: any) => {
	const {
		t,
		loader,
		messageNote,
		setLoader,
		feedbackSettingDetails,
		setFeedbackSettingDetails,
		setIsFeedbackStatusUpdated,
	} = props;

	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const allowUsersToCreateFeedback = [
		{
			id: EnumAllowUsersToCreateFeedback.AllInlineReportingManagers,
			label: 'All Inline Reporting Managers',
			value: 'All Inline Reporting Managers',
		},
		{
			id: EnumAllowUsersToCreateFeedback.SkipManager,
			label: 'Skip Manager',
			value: 'Skip Manager',
		},
		{
			id: EnumAllowUsersToCreateFeedback.BUHead,
			label: 'BU Head',
			value: 'BU Head',
		},
		{
			id: EnumAllowUsersToCreateFeedback.HRBP,
			label: 'HR BP',
			value: 'HR BP',
		},
	];
	const [emailRecipientOptions, setEmailRecipientOptions] = useState<any>(allowUsersToCreateFeedback);
	const [emailRecipientDetails, setEmailRecipientDetails] = useState<any>({
		receipientId: [EnumAllowUsersToCreateFeedback.ReportingManager],
		recipients: [
			{
				label: 'Reporting Manager',
				id: EnumAllowUsersToCreateFeedback.ReportingManager,
				value: EnumAllowUsersToCreateFeedback.ReportingManager,
				isNotDeletable: true,
			},
		],
	});

	useEffect(() => {
		if (feedbackSettingDetails.permissionGroupId && feedbackSettingDetails.permissionGroupId.length !== 0) {
			let selectedValues: any[] = [
				{
					label: 'Reporting Manager',
					id: EnumAllowUsersToCreateFeedback.ReportingManager,
					value: EnumAllowUsersToCreateFeedback.ReportingManager,
					isNotDeletable: true,
				},
			];
			emailRecipientOptions.forEach((element: any) => {
				if (feedbackSettingDetails.permissionGroupId.includes(element.id)) {
					selectedValues.push(element);
				}
			});
			setEmailRecipientDetails({
				receipientId: [EnumAllowUsersToCreateFeedback.ReportingManager],
				recipients: selectedValues,
			});
		} else {
			setEmailRecipientDetails({
				receipientId: [EnumAllowUsersToCreateFeedback.ReportingManager],
				recipients: [
					{
						label: 'Reporting Manager',
						value: EnumAllowUsersToCreateFeedback.ReportingManager,
						id: EnumAllowUsersToCreateFeedback.ReportingManager,
						isNotDeletable: true,
					},
				],
			});
			setFeedbackSettingDetails({
				...feedbackSettingDetails,
				permissionGroupId: [EnumAllowUsersToCreateFeedback.ReportingManager],
			});
		}
	}, []);
	const handleEmailRecipientData = (field: string, value: any) => {
		setEmailRecipientDetails({
			receipientId: [EnumAllowUsersToCreateFeedback.ReportingManager],
			recipients: value,
		});
		setIsFeedbackStatusUpdated(true);
		setFeedbackSettingDetails({ ...feedbackSettingDetails, [field]: value.map((item: any) => item.id) });
	};
	const showMsg = (variant: any, messages: any) => {
		enqueueSnackbar(messages || `Error occurred while fetching comments`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};

	return (
		<>
			{emailRecipientDetails ? (
				<>
					<Box className='performance-development-card full-rectangular-card pip-send-update-settings'>
						<Box className='pd-card-header'>
							<Typography variant='h4' className='font-weight-normal'>
								{t('Allow these users to create feedback')}
							</Typography>
						</Box>
						<MultiSelect
							key={'receipientId'}
							id='receipient_Id_dropdown'
							selectedOptions={emailRecipientDetails?.recipients}
							optionsList={emailRecipientOptions}
							onSelectOption={(value: any) => {
								handleEmailRecipientData('permissionGroupId', value);
							}}
							customFilter={(option: any, searchText: any) => {
								return true;
							}}
							placeHolder={t('searchText')}
							isMulti={true}
							noOptionsMessage={''}
							showNoOptions={true}
							fetchAsyncData={false}
							isSingleSelection={false}
							performSearch={(searchText: string) => {}}
							blurCalled={() => {}}
							closeMenuOnSelect={true}
							labelTemplate={'teamAvatarLabel'}
							selectClassName={'select-search-dropdown select-search-team-dropdown'}
							selectClassNamePrefix={'react-select'}
						/>
					</Box>
				</>
			) : (
				<></>
			)}
		</>
	);
};
