import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	ClickAwayListener,
	FormControl,
	FormControlLabel,
	FormLabel,
	Link,
	Popper,
	Radio,
	RadioGroup,
	TextField,
	Tooltip,
	Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../../../config/svg/GlobalSvg';
import {
	CalendarIcon,
	DummyIcon,
	NameRenameIcon,
	ReportsTooltipIcon,
	TickIcon,
} from '../../../../../config/svg/CommonSvgIcon';
import { formatFeedbackDate, getRemainingCharacter } from '../../../../../config/utils';
import ReactDateRangePicker from '../../../../Common/ReactDateRangePicker';
import { Enums } from '../../../../../config/enums';
import { ReviewWorkflowGroup } from './ReviewWorkflowGroup';
import { NumberFormatComponent } from '../../../../Common/NumberFormat';
import { GoalCycleIcon, GoalWorkflowIcon } from '../../../../../config/svg/MyGoalSvg';
import { MultiSelect } from '../../../CommonComponent/MultiSelect';
import { OKRButton } from '../../../../Common/OKRButton';
import { CancelIcon } from '../../../../../config/svg/Action';
import { useDispatch, useSelector } from 'react-redux';
import {
	goalCycleList,
	deleteGoalCycle,
	setHighlightGoalCycleId,
	setGoalFormCount,
	setGoalCyclePaginationData,
	getPerformanceGoalTabCount,
	goalWorkflowListApi,
	savePerformanceGoalReviewCycle,
	setHighlightGoalReviewCycleId,
	refreshGoalReviewReviewList,
	updatePerformanceGoalReviewCycle,
} from '../../../../../action/adminSettings';
import { MultiSelectGoalReviewPopper } from './MultiSelectGoalReviewPopper';
import { FormSelectPopper } from '../../PerformanceReview/AssessmentFormsSetting/FormSelectPopper';
import { AssignReviewUsers } from '../AssignUsers';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { useSnackbar } from 'notistack';
import { checkUnSavePopUpVisible } from '../../../../../config/utils';
import AlertDialog from '../../../../Common/Dialog';
import moment from 'moment';
import { AlertInfoIcon } from '../../../../../config/svg/CommonSvg';
import { HeaderTooltip } from '../../../../Reports/HeaderTooltip';

export const CreateGoalReview: React.FC<any> = (props: any) => {
	const { goalReviewOpen, setGoalReviewOpen, setTabSelected } = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const [goalCycleListData, setGoalCycleListData] = useState<any[]>([]);
	const [formObjectData, setFormObjectData] = useState<any>({
		ratingFormId: 0,
		ratingFormName: '',
		ratingFormSectionResponse: [],
	});
	const staticErrorObj = {
		error: '',
		helperText: '',
		type: '',
	};
	const goalCycleObj = {
		performanceGoalCycleId: Enums.ZERO,
		name: '',
		startDate: null,
		endDate: null,
		isAssignToAllEmployee: true,
		status: 1,
		scoreCalculatedOn: 0,
		assignedEmployeeIds: [],
		assignedUsers: [],
	};
	const [anchorEl, setAnchorEl] = useState<any>(null);
	const [open, setOpen] = useState<boolean>(false);
	const minDateValue = new Date();
	minDateValue.setMonth(minDateValue.getMonth() - 18);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);
	const [goalCycleData, setGoalCycleData] = useState<any>(goalCycleObj);
	const [goalFormError, setGoalFormError] = useState<any>({
		name: '',
		startDate: '',
		scoreCalculatedUponRadio: 0,
	});
	const [isGoalCycleFormEdited, setIsGoalCycleFormEdited] = useState<boolean>(false);
	const [selectedUsersOptions, setSelectedUsersOptions] = useState<any>([]);
	const ref = useRef<any>(null);

	const [goalCycleAssignedUserError, setGoalCycleAssignedUserError] = useState<any>(staticErrorObj);
	const [reviewWorkflowGroupList, setReviewWorkflowGroupList] = useState<any>([]);
	const [statusValue, setStatusValue] = useState<any>([]);
	const [selectedDateRange, setSelectedDateRange] = useState<any>([]);
	const [selCycleValue, setSelCycleValue] = useState<any>([]);

	const [selectedDurationStartDateRange, setSelectedDurationStartDateRange] = useState<any>(null);
	const [selectedDurationEndDateRange, setSelectedDurationEndDateRange] = useState<any>(null);
	const [selectedDurationTimeframeStartDateRange, setSelectedDurationTimeframeStartDateRange] = useState<any>(null);
	const [selectedDurationTimeframeEndDateRange, setSelectedDurationTimeframeEndDateRange] = useState<any>(null);
	const [downloadErrorUsers, setDownloadErrorUsers] = useState([]);
	const [goalCycleSearchTextArray, setGoalCycleSearchTextArray] = useState<string>('');
	const [goalReviewModalProps, setGoalReviewModalProps] = useState<any>({
		open: false,
		event: null,
		message: '',
		details: '',
		type: '',
	});
	useEffect(() => {
		if (goalReviewOpen && goalReviewOpen.open && goalReviewOpen.data && goalReviewOpen.data.isEdit === true) {
			let goalEditFormData = {
				...goalReviewOpen.data.performanceGoalCycleDetail,
				name: goalReviewOpen.data.performanceGoalCycleDetail.name,
				id: goalReviewOpen.data.performanceGoalCycleDetail.performanceGoalCycleId,
			};
			setGoalCycleData({
				...goalReviewOpen.data,
				scoreCalculatedUponText: goalReviewOpen.data.scoreCalculatedOn,
				goalForm: [goalEditFormData],
				...{
					goalFormCycleId: goalEditFormData.id,
					allUsersChecked: true,
					noFormUpdate: true,
					performanceGoalCycleId: goalEditFormData.id,
				},
			});
			setSelectedDurationStartDateRange(
				goalReviewOpen.data.startDate ? moment(new Date(goalReviewOpen.data.startDate)) : null
			);
			setSelectedDurationEndDateRange(
				goalReviewOpen.data.endDate ? moment(new Date(goalReviewOpen.data.endDate)) : null
			);
			setSelectedDurationTimeframeStartDateRange(
				goalReviewOpen.data.timeframeStartDate ? moment(new Date(goalReviewOpen.data.timeframeStartDate)) : null
			);
			setSelectedDurationTimeframeEndDateRange(
				goalReviewOpen.data.timeframeEndDate ? moment(new Date(goalReviewOpen.data.timeframeEndDate)) : null
			);
			setStatusValue({ ...goalReviewOpen.data, name: goalReviewOpen.data.performanceGoalReviewWorkFlowName });
			setSelCycleValue([goalEditFormData]);
			updateFormObject({
				...goalReviewOpen.data.performanceGoalCycleDetail,
				name: goalReviewOpen.data.performanceGoalCycleDetail.name,
				id: goalReviewOpen.data.performanceGoalCycleDetail.performanceGoalCycleId,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalReviewOpen]);

	const updateFormObject = (value: any) => {
		let formObjectDataCopy: any = {
			performanceGoalCycleId: 0,
			ratingFormName: '',
			ratingFormSectionResponse: [],
			performanceCycleId: formObjectData.performanceCycleId,
		};
		let nonRaitingQuestion: any[] = [];
		if (value.ratingFormSectionResponse) {
			value.ratingFormSectionResponse.forEach((element: any) => {
				if (element['isRatingQuestion'] !== undefined && !element.isRatingQuestion) {
					nonRaitingQuestion.push(element);
				} else {
					formObjectDataCopy.ratingFormSectionResponse.push({
						...element,
						weightagePercentage: element.weightagePercentage || 0,
					});
				}
			});
		} else if (value.formQuestionListRequest) {
			value.formQuestionListRequest.forEach((element: any) => {
				if (element['isRatingQuestion'] !== undefined && !element.isRatingQuestion) {
					nonRaitingQuestion.push(element);
				} else {
					formObjectDataCopy.ratingFormSectionResponse.push({
						...element,
						weightagePercentage: element.weightagePercentage || 0,
					});
				}
			});
		}
		formObjectDataCopy.performanceGoalCycleId = value.performanceGoalCycleId;
		formObjectDataCopy.ratingFormName = value.name;
		formObjectDataCopy.totalSectionsCount = value.totalSectionsCount;
		formObjectDataCopy.totalQuestionsCount = value.totalQuestionsCount;
		formObjectDataCopy.assigedUsersCount = value.assigedUsersCount;
		setFormObjectData(formObjectDataCopy);
	};
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isGoalCycleFormEdited]);
	useEffect(() => {
		/*getGoalCycleList({
			finder: goalCycleSearchTextArray,
			pageIndex: 1,
			sortingText: 'createdOn',
			order: 'desc',
			pageSize: 100,
		});*/
		getGoalReviewWorkflowList({
			finder: '',
			pageIndex: 1,
			sortingText: 'createdOn',
			order: 'desc',
			pageSize: 100,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const [selectedUser, setSelectedUser] = useState([]);
	const handleBackButtonOnClick = (event: any) => {
		setGoalReviewOpen({ open: false, data: {} });
		setTabSelected(0);
	};
	const getGoalReviewWorkflowList = async (requestPayload: any) => {
		try {
			const response: any = await dispatch(goalWorkflowListApi(requestPayload));
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entity } = response.data;
					if (entity) {
						const { records } = entity || {};
						if (records && records.length) {
							setReviewWorkflowGroupList(records);
						} else {
							setReviewWorkflowGroupList([]);
						}
					} else {
						setReviewWorkflowGroupList([]);
					}
				} else {
					setReviewWorkflowGroupList([]);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
	const saveGoalCycleAsDraft = (e: any) => {
		let goalFormErrorCpy: any = { ...goalFormError };
		if (!goalCycleData.name) {
			goalFormErrorCpy.name = t('goalCycleNameErrorMsg');
			setGoalFormError(goalFormErrorCpy);
			return false;
		} else {
			saveGoalCycleAsSubmit(e, 1);
		}
		//submitGoalCycleCreateRequest(1);
		if (isGoalCycleFormEdited) {
		} else {
			setGoalReviewOpen({ open: false, data: {} });
		}
	};

	const scrollTo = (elementId: string) => {
		setTimeout(function () {
			const element = document.getElementById(elementId);
			let topPos = element?.offsetTop;
			topPos = topPos ? topPos - 0 : 0;
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);
	};
	const validateForm = () => {
		let goalFormErrorCpy: any = { ...goalFormError };
		let error: boolean = true;
		let scrollToId = '';
		if (!goalCycleData.name) {
			goalFormErrorCpy.name = t('goalCycleNameErrorMsg');
			error = false;
			scrollToId = 'name-field';
		}
		if (!goalCycleData.timeframeStartDate || !goalCycleData.timeframeEndDate) {
			goalFormErrorCpy.timeframeStartDate = t('timeframeError');
			error = false;
			if (!scrollToId) {
				scrollToId = 'timeframe-field';
			}
		}
		if (!goalCycleData.startDate || !goalCycleData.endDate) {
			goalFormErrorCpy.startDate = t('reviewDuartionErrorMssg');
			error = false;
			if (!scrollToId) {
				scrollToId = 'duration-field';
			}
		} else {
			let todaysDate = formatFeedbackDate(new Date());
			let startDate = formatFeedbackDate(selectedDurationStartDateRange?._d);
			let endDate = formatFeedbackDate(selectedDurationEndDateRange?._d);
			if (startDate > endDate) {
				goalFormErrorCpy.startDate = 'End date should be greater than start date';
				error = false;
				if (!scrollToId) {
					scrollToId = 'duration-field';
				}
				//setModalProps({ open: true, type: 2, event: null, message: t('pastFeedbackDate') });
				//return false;
			}
		}
		if (!goalCycleData.performanceGoalReviewWorkFlowId) {
			goalFormErrorCpy.performanceGoalReviewWorkFlowId = t('reviewWorkflowGroupErrorMssg');
			error = false;
			if (!scrollToId) {
				scrollToId = 'review-field';
			}
		}
		if (!goalCycleData.scoreCalculatedOn) {
			goalFormErrorCpy.scoreCalculatedOn = t('reviewScoreErrorMssg');
			error = false;
			if (!scrollToId) {
				scrollToId = 'score-field';
			}
		}
		if (!goalCycleData.performanceGoalCycleId) {
			goalFormErrorCpy.goalForm = t('goalCycleErrorMssg');
			error = false;
			if (!scrollToId) {
				scrollToId = 'cycle-field';
			}
		}
		if (!goalCycleData.assignedUsers || goalCycleData.assignedUsers.length === 0) {
			goalFormErrorCpy.assignedUsers = t('goalCycleAssignUserErrorMsg');
			setGoalCycleAssignedUserError({
				error: '',
				helperText: t('goalCycleAssignUserErrorMsg'),
				type: 'assignedUser',
			});
			error = false;
			if (!scrollToId) {
				scrollToId = 'user-field';
			}
		}
		if (scrollToId) {
			scrollTo(scrollToId);
		}
		setGoalFormError(goalFormErrorCpy);
		return error;
	};
	const saveGoalCycleAsSubmit = async (e: any, status: number) => {
		//submitGoalCycleCreateRequest(2);
		if (status === 1 || validateForm()) {
			const reviewStartDate = goalCycleData.startDate?._d || goalCycleData.startDate;
			const reviewEndDate = goalCycleData.endDate?._d || goalCycleData.endDate;
			const timeframeReviewStartDate = goalCycleData.timeframeStartDate?._d || goalCycleData.timeframeStartDate;
			const timeframeReviewEndDate = goalCycleData.timeframeEndDate?._d || goalCycleData.timeframeEndDate;
			let requestPayload: any = {
				name: goalCycleData.name,
				startDate: reviewStartDate ? formatFeedbackDate(reviewStartDate) : null,
				endDate: reviewEndDate ? formatFeedbackDate(reviewEndDate) : null,
				timeframeStartDate: timeframeReviewStartDate ? formatFeedbackDate(timeframeReviewStartDate) : null,
				timeframeEndDate: timeframeReviewEndDate ? formatFeedbackDate(timeframeReviewEndDate) : null,
				performanceGoalReviewWorkFlowId: goalCycleData.performanceGoalReviewWorkFlowId,
				assignedUsers: goalCycleData.assignedUsers,
				scoreCalculatedOn: goalCycleData.scoreCalculatedOn,
				performanceGoalCycleId: goalCycleData.performanceGoalCycleId,
				status: status,
			};
			let response: any = {};
			if (goalCycleData.performanceGoalReviewCycleId) {
				requestPayload.performanceGoalReviewCycleId = goalCycleData.performanceGoalReviewCycleId;
				response = await dispatch(updatePerformanceGoalReviewCycle(requestPayload));
			} else {
				response = await dispatch(savePerformanceGoalReviewCycle(requestPayload));
			}

			if (response && response.data && response.data.status === 200) {
				const { messageList, isSuccess, entity } = response.data;
				if (isSuccess) {
					dispatch(setHighlightGoalReviewCycleId(entity));
					dispatch(refreshGoalReviewReviewList(true));
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages && messages.length > 0 ? messages : `Goal review cycle created successfully`, {
						variant: 'success',
						autoHideDuration: 3000,
					});
					setGoalReviewOpen({ open: false, data: {} });
				} else {
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while adding performance review', {
						variant: 'error',
						autoHideDuration: 3000,
					});
					window.scrollTo(0, 0);
				}
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || 'Error occurred while adding performance review', {
					variant: 'error',
					autoHideDuration: 3000,
				});
				window.scrollTo(0, 0);
			}
		} else {
			console.log('invalid form');
		}
	};
	const handleCloseGoalCycleModal = async (e: any, type: any) => {
		e.preventDefault();

		if (type === 1) {
			await setIsGoalCycleFormEdited(false);
			const { target } = goalReviewModalProps?.event || {};
			if (target) {
				const clonedNativeEvent = document.createEvent('HTMLEvents');
				clonedNativeEvent.initEvent('click', true, false);
				target.dispatchEvent(clonedNativeEvent);
			}
		}

		setGoalReviewModalProps({ open: false, event: null, message: '', details: '', type: '' });
	};
	const getGoalCycleList = async (requestPayload: any) => {
		try {
			const response: any = await dispatch(goalCycleList(requestPayload));
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entity } = response.data;
					if (entity) {
						const { records } = entity || {};
						if (records && records.length) {
							setGoalCycleListData(records);
						} else {
							setGoalCycleListData([]);
						}
					} else {
						setGoalCycleListData([]);
					}
				} else {
					setGoalCycleListData([]);
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
	const handleOpen = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
		setAnchorEl(null);
	};
	const handleClickOutside = (event: any) => {
		const datePickerPopup = document.getElementsByClassName('DateRangePicker');

		const dialogDiv = document.getElementsByClassName('MuiDialog-paper');

		if (
			ref.current &&
			!ref.current?.contains(event.target) &&
			checkUnSavePopUpVisible(event) &&
			isGoalCycleFormEdited &&
			!datePickerPopup.length
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				event.preventDefault();
				event.stopPropagation();
				setGoalReviewModalProps({ open: true, event: event, message: t('unSavedItemAlert'), details: '', type: '' });
			}
		} else {
			//
		}
	};
	const handleGoalCycleValueChange = (name: any, value: any, extraData: any = {}) => {
		if (value) {
			let errorsList: any = {};
			if (name === 'scoreCalculatedUponText' || name === 'scoreCalculatedUponRadio') {
				errorsList.scoreCalculatedOn = '';
			}
			setGoalFormError({
				...goalFormError,
				...errorsList,
				[name]: '',
			});
			setIsGoalCycleFormEdited(true);
			setGoalCycleData({ ...goalCycleData, [name]: value, ...extraData });
		} else {
			setGoalCycleData({ ...goalCycleData, [name]: '', ...extraData });
		}
		setIsGoalCycleFormEdited(true);
	};

	return (
		<Box className='create-goal-review-area'>
			<Box className='admin-tabpanel-inner'>
				<Box className='admin-tabpanel-head'>
					<Box className='back-to-main' onClick={handleBackButtonOnClick}>
						<Typography variant='body2'>
							<Link id='back-btn'>
								<BackArrow /> {t('back')}
							</Link>
						</Typography>
					</Box>
					<Typography variant='h2'>{t('goalReviewCycleLevel')}</Typography>
				</Box>
			</Box>
			<div ref={ref}>
				{' '}
				<Box className='full-rectangular-card create-review-form-card'>
					<Box className='settings-card-row settings-card-row-3'>
						<Box className='settings-card-col'>
							<Box className='review-form-name-field' id='name-field'>
								<TextField
									id='name'
									fullWidth
									className='helperText-bottom'
									placeholder={t('goalCycleNamePlaceholder')}
									value={goalCycleData?.name || ''}
									label={
										<>
											<NameRenameIcon /> {t('cycleNameLevel')}
											<i style={{ color: 'red' }}>*</i>
										</>
									}
									name={t('cycleNameLevel')}
									onChange={(e: any) => handleGoalCycleValueChange('name', e.target.value)}
									inputProps={{ maxLength: 50 }}
									InputLabelProps={{
										shrink: true,
									}}
									InputProps={{
										'aria-label': t('cycleNameLevel'),
										disableUnderline: true,
										//disabled: goalCycleData?.status === EnumPerformanceGoalCycleStatus.Completed,
									}}
									error={goalFormError?.name && goalFormError?.name !== '' ? true : false}
									helperText={
										goalFormError?.name &&
										goalFormError?.name !== '' && (
											<>
												<ErrorIcon />
												{goalFormError?.name}
											</>
										)
									}
								/>
								<Typography variant='h6' className='input-label-pad'>
									{`50 ${t('charactersRemaining')}`}
								</Typography>
							</Box>
						</Box>
						<Box className='settings-card-col' id='timeframe-field'>
							<Box
								className={`setting-duration-field helperText-bottom ${
									(goalFormError?.timeframeStartDate && goalFormError?.timeframeStartDate !== '') ||
									(goalFormError?.timeframeEndDate && goalFormError?.timeframeEndDate !== '')
										? 'custom-error-field'
										: ''
								}`}
							>
								<FormLabel id='add-duration-start' component='legend' className='form-label-required'>
									<CalendarIcon />
									{t('timeframeLabel')} <i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<Box className='calendar-start-end-fields'>
									<ReactDateRangePicker
										type={'ContributorList'}
										name='startDate'
										selectedCycleDetails={{}}
										startDateValue={null}
										minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
										maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
										handleDateSelection={(range: any, start: any, end: any) => {
											if (range) {
												setIsGoalCycleFormEdited(true);
												setSelectedDurationTimeframeStartDateRange(range);
												handleGoalCycleValueChange('timeframeStartDate', range);
											}
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={selectedDurationTimeframeStartDateRange || null}
										setSelectedDateRange={(range: any) => {
											if (range) {
												setSelectedDurationTimeframeStartDateRange(range);
											}
										}}
										calendarTitle={t('singleDateStartDateTitle')}
										placeholderText={t('selectStartDateLabel')}
									/>
									{(goalFormError?.timeframeStartDate && goalFormError?.timeframeStartDate !== '') ||
									(goalFormError?.timeframeEndDate && goalFormError?.timeframeEndDate !== '') ? (
										<Typography className='error-field'>
											<ErrorIcon /> {goalFormError?.timeframeStartDate}
										</Typography>
									) : (
										<></>
									)}

									<ReactDateRangePicker
										type={'ContributorList'}
										name='endDate'
										selectedCycleDetails={{}}
										startDateValue={null}
										minimumDate={new Date()}
										maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
										handleDateSelection={(range: any, start: any, end: any) => {
											if (range) {
												setIsGoalCycleFormEdited(true);
												setSelectedDurationTimeframeEndDateRange(range);
												handleGoalCycleValueChange('timeframeEndDate', range);
											}
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={selectedDurationTimeframeEndDateRange || null}
										setSelectedDateRange={(range: any) => {
											if (range) {
												setSelectedDurationTimeframeEndDateRange(range);
											}
										}}
										calendarTitle={t('singleDateTitle')}
										placeholderText={t('selectEndDateLabel')}
										//isDatePickerDisable={false}
									/>
									{/* {goalFormError?.endDate && goalFormError?.endDate !== '' ? (
									<Typography className='error-field'>
										<ErrorIcon /> {goalFormError?.endDate}
									</Typography>
								) : (
									<></>
								)} */}
								</Box>
							</Box>
						</Box>
						<Box className='settings-card-col'></Box>
					</Box>
					<Box className='settings-card-row settings-card-row-3'>
						<Box className='settings-card-col' id='duration-field'>
							<Box
								className={`setting-duration-field helperText-bottom ${
									(goalFormError?.startDate && goalFormError?.startDate !== '') ||
									(goalFormError?.endDate && goalFormError?.endDate !== '')
										? 'custom-error-field'
										: ''
								}`}
							>
								<FormLabel id='add-duration-start' component='legend' className='form-label-required'>
									<CalendarIcon />
									{t('reviewDurationLevel')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<Box className='calendar-start-end-fields'>
									<ReactDateRangePicker
										type={'ContributorList'}
										name='startDate'
										selectedCycleDetails={{}}
										startDateValue={null}
										minimumDate={minDateValue ? new Date(minDateValue) : new Date()}
										maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
										handleDateSelection={(range: any, start: any, end: any) => {
											if (range) {
												setIsGoalCycleFormEdited(true);
												setSelectedDurationStartDateRange(range);
												handleGoalCycleValueChange('startDate', range);
											}
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={selectedDurationStartDateRange || null}
										setSelectedDateRange={(range: any) => {
											if (range) {
												setSelectedDurationStartDateRange(range);
											}
										}}
										calendarTitle={t('singleDateStartDateTitle')}
										placeholderText={t('selectStartDateLabel')}
										isDatePickerDisable={goalCycleData.status === 3 || goalCycleData.status === 4}
									/>
									{(goalFormError?.startDate && goalFormError?.startDate !== '') ||
									(goalFormError?.endDate && goalFormError?.endDate !== '') ? (
										<Typography className='error-field'>
											<ErrorIcon /> {goalFormError?.startDate}
										</Typography>
									) : (
										<></>
									)}

									<ReactDateRangePicker
										type={'ContributorList'}
										name='endDate'
										selectedCycleDetails={{}}
										startDateValue={null}
										minimumDate={minDateValue ? goalCycleData.startDate : new Date()}
										maximumDate={maxDateValue ? new Date(maxDateValue) : new Date()}
										handleDateSelection={(range: any, start: any, end: any) => {
											if (range) {
												setIsGoalCycleFormEdited(true);
												setSelectedDurationEndDateRange(range);
												handleGoalCycleValueChange('endDate', range);
											}
										}}
										isDateRangeTextFieldVisible={true}
										isOnlyDueDateVisible={false}
										allCycleDetails={[]}
										isCycleVisible={false}
										isEndOfMonthVisible={false}
										numberOfCalendars={Enums.ONE}
										selectionType={'single'}
										selectedDateRange={selectedDurationEndDateRange || null}
										setSelectedDateRange={(range: any) => {
											if (range) {
												selectedDurationEndDateRange(range);
											}
										}}
										calendarTitle={t('singleDateTitle')}
										placeholderText={t('selectEndDateLabel')}
										//isDatePickerDisable={false}
									/>
									{/* {goalFormError?.endDate && goalFormError?.endDate !== '' ? (
									<Typography className='error-field'>
										<ErrorIcon /> {goalFormError?.endDate}
									</Typography>
								) : (
									<></>
								)} */}
								</Box>
							</Box>
						</Box>
						<Box className='settings-card-col'>
							<Box
								className={`review-workflow-group-field helperText-bottom ${
									goalFormError?.performanceGoalReviewWorkFlowId &&
									goalFormError?.performanceGoalReviewWorkFlowId !== ''
										? 'custom-error-field'
										: ''
								}`}
								id='review-field'
							>
								<FormLabel component='legend'>
									<GoalWorkflowIcon />
									{t('reviewWorkflowGroupLevel')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<ReviewWorkflowGroup
									{...props}
									reviewWorkflowGroupList={reviewWorkflowGroupList}
									setReviewWorkflowGroupList={setReviewWorkflowGroupList}
									handleChange={(value: any) => {
										handleGoalCycleValueChange(
											'performanceGoalReviewWorkFlowId',
											value.performanceGoalReviewWorkFlowId
										);
									}}
									selValue={statusValue}
									isDisabled={goalCycleData.status === 3 || goalCycleData.status === 4}
								/>
								{goalFormError?.performanceGoalReviewWorkFlowId &&
								goalFormError?.performanceGoalReviewWorkFlowId !== '' ? (
									<Typography className='error-field'>
										<ErrorIcon /> {goalFormError?.performanceGoalReviewWorkFlowId}
									</Typography>
								) : (
									<></>
								)}
							</Box>
						</Box>
						<Box className='settings-card-col'>
							<Box
								className={`review-score-radio helperText-bottom ${
									goalFormError?.scoreCalculatedOn && goalFormError?.scoreCalculatedOn !== ''
										? 'custom-error-field'
										: ''
								}`}
								id='score-field'
							>
								<FormLabel id='review-score-radio-label'>
									<DummyIcon />
									{t('scoreToBeCalculatedOutOf')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<Box className='review-score-group-box'>
									<RadioGroup
										aria-labelledby='review-score-buttons-label'
										name='review-score-group'
										onChange={(e: any, value: any) => {
											handleGoalCycleValueChange('scoreCalculatedUponRadio', parseInt(value), {
												scoreCalculatedUponText: '',
												scoreCalculatedOn: parseInt(value),
											});
										}}
									>
										<FormControlLabel
											value={4}
											control={<Radio />}
											label='4'
											checked={goalCycleData.scoreCalculatedUponRadio === 4}
											disabled={goalCycleData.status === 3}
										/>
										<FormControlLabel
											value={5}
											control={<Radio />}
											label='5'
											checked={goalCycleData.scoreCalculatedUponRadio === 5}
											disabled={goalCycleData.status === 3}
										/>
										<FormControlLabel
											value={10}
											control={<Radio />}
											label='10'
											checked={goalCycleData.scoreCalculatedUponRadio === 10}
											disabled={goalCycleData.status === 3}
										/>
										<FormControlLabel
											value={100}
											control={<Radio />}
											label='100'
											checked={goalCycleData.scoreCalculatedUponRadio === 100}
											disabled={goalCycleData.status === 3}
										/>
									</RadioGroup>
									<Box className='other-score'>
										<TextField
											id='other'
											value={goalCycleData?.scoreCalculatedUponText}
											fullWidth
											placeholder={t('other')}
											name={'scoreToBeCalculatedOutOf'}
											onChange={(e: any) => {
												if (e.target.value !== goalCycleData?.scoreCalculatedUponText) {
													handleGoalCycleValueChange('scoreCalculatedUponText', e.target.value, {
														scoreCalculatedUponRadio: '',
														scoreCalculatedOn: parseInt(e.target.value),
													});
												}
											}}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												maxLength: Enums.FIVE,
											}}
											InputProps={{
												'aria-label': t('noOfExtensionsAllowed'),
												disableUnderline: true,
												inputComponent: NumberFormatComponent,
											}}
											disabled={goalCycleData.status === 3}
										/>
									</Box>
									<HeaderTooltip
										tooltipPlacement='bottom-end'
										infoTooltipClass='score-info-tooltip'
										tooltipText={t('reviewScoreInfoText')}
									/>
									{goalFormError?.scoreCalculatedOn && goalFormError?.scoreCalculatedOn !== '' ? (
										<Typography className='error-field'>
											<ErrorIcon /> {goalFormError?.scoreCalculatedOn}
										</Typography>
									) : (
										<></>
									)}
								</Box>
							</Box>
						</Box>
					</Box>
					<Box className='settings-card-row goal-cycle-select-field'>
						<Box
							className={`settings-card-col helperText-bottom ${
								goalFormError?.performanceGoalCycleId && goalFormError?.performanceGoalCycleId !== ''
									? 'custom-error-field'
									: ''
							}`}
							id='cycle-field'
						>
							<FormLabel id='goal-setting-cycle-level'>
								<GoalCycleIcon />
								{t('goalSettingCycleLevel')}
								<i style={{ color: 'red' }}>*</i>
							</FormLabel>
							<MultiSelectGoalReviewPopper
								{...props}
								formObjectData={formObjectData}
								setFormObjectData={(value: any) => {
									setFormObjectData(value);
									handleGoalCycleValueChange('goalForm', value, {
										goalFormCycleId: value.performanceGoalCycleId,
										allUsersChecked: true,
										performanceGoalCycleId: value.performanceGoalCycleId,
									});
								}}
								goalCycleListData={goalCycleListData}
								setToggleClick={() => {}}
								setIsFormEdited={() => {}}
								nonRatingQuestion={[]}
								setNonRatingQuestion={() => {}}
								selCycleValue={selCycleValue}
								isDisabled={goalCycleData.status === 3 || goalCycleData.status === 4}
							/>
							{goalFormError?.goalForm && goalFormError?.goalForm !== '' ? (
								<Typography className='error-field'>
									<ErrorIcon /> {goalFormError?.goalForm}
								</Typography>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</Box>
				<Box>
					<AssignReviewUsers
						{...props}
						setIsGoalCycleFormEdited={setIsGoalCycleFormEdited}
						selectedUsersOptions={selectedUsersOptions}
						setSelectedUsersOptions={(value: any[]) => {
							setSelectedUsersOptions(value);
							if (!goalCycleData.noFormUpdate) {
								setIsGoalCycleFormEdited(true);
							}
							setGoalCycleData({ ...goalCycleData, assignedUsers: value, noFormUpdate: false });
							setGoalCycleAssignedUserError({
								error: '',
								helperText: '',
								type: '',
							});
						}}
						//setLoader={setLoader}
						//loader={loader}
						goalCycleAssignedUserError={goalCycleAssignedUserError}
						setGoalCycleAssignedUserError={setGoalCycleAssignedUserError}
						goalCycleData={goalCycleData}
						allUsersChecked={goalCycleData.allUsersChecked}
						setGoalCycleData={setGoalCycleData}
						handleAllToggleCheck={(value: any) => {
							setGoalCycleData({ ...goalCycleData, allUsersChecked: value });
							setIsGoalCycleFormEdited(true);
							setGoalCycleAssignedUserError({
								error: '',
								helperText: '',
								type: '',
							});
							if (!value) {
								setSelectedUsersOptions([]);
								setGoalCycleAssignedUserError({
									error: '',
									helperText: '',
									type: '',
								});
							}
						}}
					/>
					<Box className='create-goal-cycle-actions'>
						{goalCycleData?.performanceGoalCycleId === 0 || goalCycleData?.status === Enums.ONE ? (
							<OKRButton
								className='btn-link'
								id='save-as-draft'
								handleClick={saveGoalCycleAsDraft}
								text={t('saveAsDraft')}
								//disabled={loader}
							/>
						) : (
							<></>
						)}
						<OKRButton
							className='btn-primary'
							id='save-btn'
							//	disabled={loader}
							handleClick={(e: any) => saveGoalCycleAsSubmit(e, 2)}
							text={t('saveBtn')}
						/>
					</Box>
				</Box>
			</div>
			{goalReviewModalProps && goalReviewModalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={goalReviewModalProps?.message || ''}
					handleCloseModal={handleCloseGoalCycleModal}
					modalOpen={goalReviewModalProps.open}
				/>
			)}
		</Box>
	);
};
