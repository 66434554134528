import React, { useEffect, useState } from 'react';
import { Box, FormLabel, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BackArrow } from '../../../../../../config/svg/GlobalSvg';
import '../../../../../../styles/pages/admin/settings/default-okr/createDefaultOkr.scss';
import { CalendarIcon } from '../../../../../../config/svg/CommonSvgIcon';
import { Enums } from '../../../../../../config/enums';
import ReactDateRangePicker from '../../../../../Common/ReactDateRangePicker';
import { AssignChip } from '../AssignChip';
// import { getLocalStorageItem } from '../../../../../../services/StorageService';
import { getCycleDefaultSelection } from '../../../../../../config/utils';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { MainDrawer } from '../../../../CommonComponent/MainDrawer';
import { AssignDefaultObjDrawer } from './AssignDefaultObjDrawer';
import { CreateDefaultOkrDetails } from './CreateDefaultOkrDetails';
import { DefaultKeyResultAttributes } from './DefaultKeyResultAttributes';
import AlertDialog from '../../../../../Common/Dialog';
import { DialogComponent } from '../../../../CommonComponent/DialogComponent';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { createDefaultOkrCall, defaultOkrDelete, editDefaultOkrCall } from '../../../../../../action/adminDefaultOkr';
import { getLocalStorageItem } from '../../../../../../services/StorageService';

export const CreateDefaultOkr: React.FC<any> = (props: any) => {
	const {
		defaultOkrDetailsObj,
		setDefaultOkrOpen,
		setTabSelected,
		allCycleDetails,
		cycleId,
		defaultGoalKeyObject,
		defaultOkrFormDetails,
		setDefaultOkrFormDetails,
		defaultOkrDesignationDetails,
		defaultOkPerformanceRoleDetails,
		selectedCycleDetailsInTab,
		mode = 'Add',
		// editDetails,
		showLoader,
		setShowLoader,
		setEditDetails,
		setSelectedCycle,
		setSelectedOkrListCycleDetails,
		showApiMsg,
		setIsDefaultOkrFormEdited,
		refObject,
		setHighlightDefaultOkr,
		openDefaultObjDrawer,
		setOpenDefaultObjDrawer,
		openDefaultKrDrawer,
		setOpenDefaultKrDrawer,
		weightSelectionOpen,
		setWeightSelectionOpen,
	} = props;
	const dispatch = useDispatch();
	const moment = extendMoment(Moment);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state.okrReducer);
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	// const allCycleDetails = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	// const cycleId = getLocalStorageItem('cycleId') ? parseInt(getLocalStorageItem('cycleId') || '0') : 0;
	// const currentCycleYear = getLocalStorageItem('year') || null;

	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	// const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	// const [selectedKrCycleDetails, setSelectedKrCycleDetails] = useState<any>(null);
	// const [selectedKrDateRange, setSelectedKrDateRange] = useState<any>(null);
	const [selectedDesignationOptions, setSelectedDesignationOptions] = useState<any>([]);
	const [selectedPerformanceRoleOptions, setSelectedPerformanceRoleOptions] = useState<any>([]);
	const [selectedUsersOptions, setSelectedUsersOptions] = useState<any>([]);
	const [defaultOkrFormEdited, setDefaultOkrFormEdited] = useState<any>(false);
	const [selectedRoleTab, setSelectedRoleTab] = useState<any>(0);
	// const [openDefaultObjDrawer, setOpenDefaultObjDrawer] = useState<boolean>(false);
	// const [openDefaultKrDrawer, setOpenDefaultKrDrawer] = useState<any>({ open: false, details: null });
	const [selectedAssignTab, setSelectedAssignTab] = useState<any>(0);
	const [selectedDefaultKrTab, setSelectedDefaultKrTab] = useState<any>(0);
	const [allEmployeeChecked, setAllEmployeeChecked] = useState<boolean>(false);
	const [modalPerformanceProps, setModalPerformanceProps] = useState<any>({
		open: false,
		message: '',
		details: '',
		type: '',
	});
	const [showErrorMessageModal, setShowErrorMessageModal] = useState<any>({ open: false, message: '', details: '' });
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [keyData, setKeyData] = useState<any>(null);
	const [krAttributeEdited, setKrAttributeEdited] = useState<boolean>(false);
	const [okrNameError, setOkrNameError] = useState<any>({ error: false, errorText: '' });
	const [okrRoleError, setOkrRoleError] = useState<any>({ error: false, errorText: '' });
	const [selectedOkrListCycleValue, setSelectedOkrListCycleValue] = useState<any>(null);

	useEffect(() => {
		if (allCycleDetails && allCycleDetails.cycleDetails && allCycleDetails.cycleDetails.length) {
			let currentQuarterDetails: any = getCycleDefaultSelection(allCycleDetails.cycleDetails, cycleId);
			if (currentQuarterDetails) {
				let defaultCycleDetails = selectedCycleDetailsInTab ? selectedCycleDetailsInTab : { ...currentQuarterDetails };
				if (mode !== 'Add') {
					const { startDate, endDate } = defaultOkrFormDetails || {};
					defaultCycleDetails.defaultStartDate = startDate;
					defaultCycleDetails.defaultEndDate = endDate;
				} else {
					defaultCycleDetails.defaultStartDate = null;
					defaultCycleDetails.defaultEndDate = null;
				}
				const updatedCycleDetails = {
					teamCycleDetailId: defaultCycleDetails?.organisationCycleId || Enums.ZERO,
					startDate: defaultCycleDetails.defaultStartDate || defaultCycleDetails?.startDate || null,
					endDate: defaultCycleDetails.defaultEndDate || defaultCycleDetails?.endDate || null,
				};
				const { defaultGoalKeys } = defaultOkrFormDetails;
				let updatedDefaultGoalKeys = updateKrDates(
					defaultGoalKeys,
					defaultCycleDetails.defaultStartDate || defaultCycleDetails?.startDate || null,
					defaultCycleDetails.defaultEndDate || defaultCycleDetails?.endDate || null,
					defaultCycleDetails.defaultStartDate && defaultCycleDetails.defaultEndDate ? true : false
				);
				setDefaultOkrFormDetails({
					...defaultOkrFormDetails,
					...updatedCycleDetails,
					defaultGoalKeys: updatedDefaultGoalKeys,
				});
				setSelectedCycleDetails(defaultCycleDetails);
				// setSelectedKrCycleDetails({ ...currentQuarterDetails });
				setIsCurrentCycle({ ...currentQuarterDetails });
				// setCurrentSelectedCycleYear(currentQuarterDetails?.year || currentCycleYear);
				// setCurrentCycleId(currentQuarterDetails?.organisationCycleId || currentCycleId);

				// const { startDate, endDate } = defaultCycleDetails;
				// setSelectedDateRange(moment().range(new Date(startDate), new Date(endDate)));
				if (mode === 'Add') {
					const { startDate, endDate } = defaultCycleDetails;
					setSelectedDateRange(moment().range(new Date(startDate), new Date(endDate)));
				} else {
					const { startDate, endDate } = defaultOkrFormDetails || {};
					if (startDate && endDate) {
						setSelectedDateRange(moment().range(new Date(startDate), new Date(endDate)));
					}
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (selectedCycleDetails && selectedCycleDetails?.organisationCycleId) {
			const updatedCycleDetails = {
				teamCycleDetailId: selectedCycleDetails?.organisationCycleId || Enums.ZERO,
				startDate: selectedCycleDetails?.defaultStartDate || selectedCycleDetails?.startDate || null,
				endDate: selectedCycleDetails?.defaultEndDate || selectedCycleDetails?.endDate || null,
			};
			const { defaultGoalKeys } = defaultOkrFormDetails;
			let updatedDefaultGoalKeys = updateKrDates(
				defaultGoalKeys,
				selectedCycleDetails?.defaultStartDate || selectedCycleDetails?.startDate || null,
				selectedCycleDetails?.defaultEndDate || selectedCycleDetails?.endDate || null,
				selectedCycleDetails.defaultStartDate && selectedCycleDetails?.defaultEndDate ? true : false
			);
			setDefaultOkrFormDetails({
				...defaultOkrFormDetails,
				...updatedCycleDetails,
				defaultGoalKeys: updatedDefaultGoalKeys,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCycleDetails]);

	const getCycleDetailsForCreateEditOkr = () => {
		const { status, teamCycleDetailId } = defaultOkrFormDetails || {};
		let updatedAllCycleDetails = { ...allCycleDetails } || {};
		// if (status === Enums.TWO) {
		let currentQuarterDetails: any = getCycleDefaultSelection(updatedAllCycleDetails.cycleDetails, teamCycleDetailId);
		if (currentQuarterDetails?.year) {
			const cycleDetails =
				updatedAllCycleDetails?.cycleDetails && updatedAllCycleDetails?.cycleDetails?.length
					? [...updatedAllCycleDetails?.cycleDetails]
					: [];
			const selectedCycleIndex = cycleDetails.findIndex(
				(cycleRec: any) => cycleRec.year === currentQuarterDetails?.year
			);
			let remainingCycles = cycleDetails.filter((rec: any, index: number) => index >= selectedCycleIndex);
			remainingCycles = remainingCycles?.map((data: any) => {
				const rec = { ...data };
				rec.quarterDetails = rec?.quarterDetails.filter((q: any) => q?.organisationCycleId >= teamCycleDetailId);
				return rec;
			});
			updatedAllCycleDetails.cycleDetails = [...remainingCycles];
		}
		// }

		return updatedAllCycleDetails;
	};
	const updateKrDates = (defaultGoalKeys: any, startDate: any, endDate: any, isDefaultDate: boolean) => {
		let updatedDefaultGoalKeys = defaultGoalKeys && defaultGoalKeys?.length ? [...defaultGoalKeys] : [];
		updatedDefaultGoalKeys = [...defaultGoalKeys]?.map((rec: any) => {
			return {
				...rec,
				startDate: isDefaultDate ? rec?.startDate || startDate || null : startDate || null,
				endDate: isDefaultDate ? rec?.endDate || endDate || null : endDate || null,
				isKrEdited: true,
			};
		});

		return updatedDefaultGoalKeys;
	};

	const handleBackButtonOnClick = () => {
		setDefaultOkrOpen(false);
		setTabSelected(1);
		setDefaultOkrFormDetails(defaultOkrDetailsObj);
		setEditDetails(null);
		setIsDefaultOkrFormEdited(false);
	};

	const handleDrawerOpen = () => {
		setIsDefaultOkrFormEdited(false);
		setOpenDefaultObjDrawer(true);
	};
	const handleKrDrawerOpen = (e: any, krItem: any, krIndex: number) => {
		setOpenDefaultKrDrawer({ open: true, details: { krItem, krIndex } });
		setIsDefaultOkrFormEdited(false);
	};
	const validateKrDrawerData = (krDetails: any) => {
		if (!krDetails?.name || krDetails?.name?.trim() === '') {
			const data = {
				isKrNameError: true,
				krNameErrorMsg: t('addKrTitle'),
			};
			setKeyData({ ...keyData, ...data });

			return false;
		} else if (!krDetails.metricType) {
			const data = {
				isMetricTypeError: true,
				krMetricTypeErrorMsg: t('metricTypeRequired'),
				isMetricCurrencyError: false,
				krMetricCurrencyErrorMsg: '',
				isKrNameError: false,
				krNameErrorMsg: '',
			};
			setKeyData({ ...keyData, ...data });

			return false;
		} else if (krDetails.metricType === Enums.TWO && !krDetails?.currencyId) {
			const data = {
				isMetricTypeError: false,
				krMetricTypeErrorMsg: '',
				isMetricCurrencyError: true,
				krMetricCurrencyErrorMsg: t('currencyRequired'),
				isKrNameError: false,
				krNameErrorMsg: '',
			};
			setKeyData({ ...keyData, ...data });

			return false;
		}
		return true;
	};
	const deleteExtraRecord = (keyDetails: any) => {
		delete keyDetails.isMetricTypeError;
		delete keyDetails.krMetricTypeErrorMsg;
		delete keyDetails.isMetricCurrencyError;
		delete keyDetails.krMetricCurrencyErrorMsg;
		delete keyDetails.isKrNameError;
		delete keyDetails.krNameErrorMsg;
	};
	const handleKrDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		try {
			if (type === 'save') {
				if (validateKrDrawerData(keyData)) {
					const { krIndex } = openDefaultKrDrawer?.details;
					const { defaultGoalKeys } = defaultOkrFormDetails || {};
					let updatedDefaultGoalKeys = defaultGoalKeys && defaultGoalKeys?.length ? [...defaultGoalKeys] : [];
					updatedDefaultGoalKeys = updatedDefaultGoalKeys.map((rec: any, indexVal: number) => {
						if (krIndex === indexVal) {
							if (keyData) {
								deleteExtraRecord(keyData);

								return {
									...keyData,
									isKrEdited: true,
								};
							} else {
								deleteExtraRecord(rec);

								return { ...rec };
							}
						} else {
							deleteExtraRecord(rec);

							return { ...rec };
						}
					});
					setDefaultOkrFormDetails({
						...defaultOkrFormDetails,
						defaultGoalKeys: [...updatedDefaultGoalKeys],
					});
					setKrAttributeEdited(false);
					setIsDefaultOkrFormEdited(true);
					setOpenDefaultKrDrawer({ open: false, details: null });
				}
			} else if (type === 'close' || type === 'cancel') {
				if (krAttributeEdited) {
					// Need to show unSave popup
					setModalPerformanceProps({ open: true, message: t('unSavedItemAlert'), details: '', type: 'KrAttribute' });
				} else {
					setIsDefaultOkrFormEdited(true);
					setOpenDefaultKrDrawer({ open: false, details: null });
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleDrawerClose = (event: React.ChangeEvent<HTMLInputElement>, type: string) => {
		try {
			if (type === 'save') {
				if (allEmployeeChecked) {
					handleUpdateAssignIds(
						[
							{
								defaultGoalObjectiveAssignMappingId: Enums.ZERO,
								assignId: Enums.ZERO,
								userCount: okrMasterData?.totalUserCount || Enums.ZERO,
								label: t('allEmpInOrgLevel'),
								name: t('allEmpInOrgLevel'),
								value: Enums.ZERO,
							},
						],
						4
					);
					setDefaultOkrFormEdited(false);
					setIsDefaultOkrFormEdited(true);
					setOpenDefaultObjDrawer(false);
				} else {
					const message = t('selectRoleMessage');
					if (selectedRoleTab === 0) {
						if (selectedDesignationOptions && selectedDesignationOptions?.length) {
							handleUpdateAssignIds(selectedDesignationOptions, 1);
							setDefaultOkrFormEdited(false);
							setIsDefaultOkrFormEdited(true);
							setOpenDefaultObjDrawer(false);
						} else {
							setShowErrorMessageModal({
								open: true,
								message,
								details: '',
							});
						}
					} else if (selectedRoleTab === 1) {
						if (selectedPerformanceRoleOptions && selectedPerformanceRoleOptions?.length) {
							handleUpdateAssignIds(selectedPerformanceRoleOptions, 2);
							setDefaultOkrFormEdited(false);
							setIsDefaultOkrFormEdited(true);
							setOpenDefaultObjDrawer(false);
						} else {
							setShowErrorMessageModal({
								open: true,
								message,
								details: '',
							});
						}
					} else if (selectedRoleTab === 2) {
						if (selectedUsersOptions && selectedUsersOptions?.length) {
							handleUpdateAssignIds(selectedUsersOptions, 3);
							setDefaultOkrFormEdited(false);
							setIsDefaultOkrFormEdited(true);
							setOpenDefaultObjDrawer(false);
						} else {
							setShowErrorMessageModal({
								open: true,
								message,
								details: '',
							});
						}
					}
				}
				setIsDefaultOkrFormEdited(true);
				setOkrRoleError({ error: false, errorText: '' });
			} else if (type === 'close' || type === 'cancel') {
				if (defaultOkrFormEdited) {
					// Need to show unSave popup
					setModalPerformanceProps({ open: true, message: t('unSavedItemAlert'), details: '', type: 'OKR' });
				} else {
					setOpenDefaultObjDrawer(false);
					setIsDefaultOkrFormEdited(true);
					setDefaultOkrFormEdited(false);
					setSelectedRoleTab(0);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalPerformanceProps?.type === 'KrAttribute') {
				setKeyData(null);
				setIsDefaultOkrFormEdited(true);
				setOpenDefaultKrDrawer({ open: false, message: '', details: null });
			} else if (modalPerformanceProps?.type === 'OKR') {
				setOpenDefaultObjDrawer(false);
				setIsDefaultOkrFormEdited(true);
				setSelectedDesignationOptions([]);
				setSelectedPerformanceRoleOptions([]);
				setSelectedUsersOptions([]);
				setAllEmployeeChecked(false);
				setDefaultOkrFormEdited(false);
				setSelectedRoleTab(0);
			} else if (modalPerformanceProps?.type === 'KrWeightToggleChange') {
				const { checked, krItem, krIndex } = modalPerformanceProps?.details || {};
				if (krItem) {
					updateIsWeightSameToggleDetails(checked, krItem, krIndex);
				}
			} else if (modalPerformanceProps?.type === 'KrTargetToggleChange') {
				const { checked, krItem, krIndex } = modalPerformanceProps?.details || {};
				if (krItem) {
					updateIsTargetSameToggleDetails(checked, krItem, krIndex);
				}
			} else if (modalPerformanceProps?.type === 'ROLE') {
				const { item, indexVal, deleteType, assignType } = modalPerformanceProps?.details || {};
				if (assignType === Enums.ONE) {
					let updatedList = [...selectedDesignationOptions];
					updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.value === rec.value));
					setSelectedDesignationOptions(updatedList);
				} else if (assignType === Enums.TWO) {
					let updatedList = [...selectedPerformanceRoleOptions];
					updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.value === rec.value));
					setSelectedPerformanceRoleOptions(updatedList);
				} else if (assignType === Enums.THREE) {
					let updatedList = [...selectedUsersOptions];
					updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.value === rec.value));
					setSelectedUsersOptions(updatedList);
				}
				setIsDefaultOkrFormEdited(true);
			} else if (modalPerformanceProps?.type === 'OKRDelete') {
				handleBackButtonOnClick();
			} else if (modalPerformanceProps?.type === 'KRDelete') {
				const { item, indexValue } = modalPerformanceProps?.details;

				if (mode === 'Add' || !item?.defaultGoalKeyId) {
					// remove locally
				} else {
					deleteDefaultOkr(item?.defaultGoalKeyId, 'KR');
				}
				const { defaultGoalKeys } = defaultOkrFormDetails;
				if (defaultGoalKeys && defaultGoalKeys?.length) {
					const updatedDefaultGoalKeys = defaultGoalKeys.filter(
						(rec: any, itemIndex: number) => itemIndex !== indexValue
					);
					setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: updatedDefaultGoalKeys });
					setIsDefaultOkrFormEdited(true);
				}
			} else if (modalPerformanceProps?.type === 'DefaultOKR') {
				deleteDefaultOkr(modalPerformanceProps?.details?.okrDetails?.defaultGoalObjectiveId, 'OKR');
			}

			setModalPerformanceProps({ open: false, message: '', details: '', type: '' });
		} else {
			setModalPerformanceProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: Number) => {
		event.preventDefault();
		// setSelectedAssignTab(newValue);
		setSelectedDefaultKrTab(newValue);
	};
	/**
	 *
	 */
	const handleOkrDeletionClick = (event: any, okrDetails: any) => {
		try {
			if (mode !== 'Add') {
				if (okrDetails?.status === Enums.ONE) {
					let msg = '';
					const goalKeys =
						okrDetails?.defaultGoalKeys && okrDetails?.defaultGoalKeys?.length
							? okrDetails?.defaultGoalKeys?.filter((item: any) => item.name)
							: [];
					if (goalKeys?.length === Enums.ZERO) {
						msg = t('deleteOkrAlertMessage');
					} else if (goalKeys?.length) {
						msg = t('deleteOkrAlertMessageNew');
					}
					setModalPerformanceProps({
						open: true,
						message: msg,
						type: 'DefaultOKR',
						details: { okrDetails: okrDetails, deleteType: 'DefaultOKR' },
						event: event,
					});
				} else {
					setModalProps({
						open: true,
						message: '',
						module: 'confirmation',
						details: {
							deleteType: 'OKR',
							headingText: t('deleteOkrAlertMessage'),
							okrDetails,
							//subHeading: t('deletePerformanceCycleSubHead'),
							//matchString: Enums.DELETE_TEXT,
						},
					});
				}
			} else {
				setModalPerformanceProps({
					open: true,
					message: t('deleteOkrAlertMessage'),
					details: '',
					type: 'OKRDelete',
				});
				// handleBackButtonOnClick();
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleKRDeletionClick = (event: any, krItem: any, krIndex: number) => {
		try {
			if (mode !== 'Add') {
				setModalProps({
					open: true,
					message: '',
					module: 'confirmation',
					details: {
						deleteType: 'KR',
						headingText: t('deleteKeyResultConfirmation'),
						item: krItem,
						indexValue: krIndex,
					},
				});
			} else {
				setModalPerformanceProps({
					open: true,
					message: t('deleteKeyResultConfirmation'),
					details: {
						item: krItem,
						indexValue: krIndex,
					},
					type: 'KRDelete',
				});
			}
		} catch (error) {
			console.error(error);
		}
	};
	/** */
	const updateIsWeightSameToggleDetails = (checked: boolean, krItem: any, krIndex: number) => {
		const { defaultGoalKeys } = defaultOkrFormDetails;
		let updatedDefaultGoalKeys = [...defaultGoalKeys];
		updatedDefaultGoalKeys[krIndex].isWeightSame = checked;
		updatedDefaultGoalKeys[krIndex].defaultGoalKeyAssignMappings =
			updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings &&
			updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.length
				? updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.map((role: any) => {
						role.weight = checked ? krItem.weight : Enums.ONE;

						return role;
				  })
				: [];
		setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedDefaultGoalKeys] });
		setIsDefaultOkrFormEdited(true);
	};
	const handleIsWeightSameToggleClick = (event: any, krItem: any, krIndex: number) => {
		try {
			const { checked } = event?.target;
			if (mode === 'Edit' && defaultOkrFormDetails?.status === Enums.TWO && krItem?.defaultGoalKeyId) {
				setModalPerformanceProps({
					open: true,
					message: t('changeWeightOrTargetToggle'),
					details: {
						checked,
						krItem,
						krIndex,
					},
					type: 'KrWeightToggleChange',
				});
			} else {
				if (krItem) {
					updateIsWeightSameToggleDetails(checked, krItem, krIndex);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const updateIsTargetSameToggleDetails = (checked: boolean, krItem: any, krIndex: number) => {
		const { defaultGoalKeys } = defaultOkrFormDetails;
		let updatedDefaultGoalKeys = [...defaultGoalKeys];
		updatedDefaultGoalKeys[krIndex].isTargetSame = checked;
		updatedDefaultGoalKeys[krIndex].isTargetValueError = false;
		updatedDefaultGoalKeys[krIndex].krTargetValueErrorMsg = false;
		updatedDefaultGoalKeys[krIndex].defaultGoalKeyAssignMappings =
			updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings &&
			updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.length
				? updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.map((role: any) => {
						role.startValue = checked ? krItem.startValue : Enums.ZERO;
						role.targetValue = checked ? krItem.targetValue : Enums.ZERO;
						role.isRoleTargetValueError = false;
						role.roleTargetValueErrorMsg = '';

						return role;
				  })
				: [];
		setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedDefaultGoalKeys] });
		setIsDefaultOkrFormEdited(true);
	};
	const handleIsTargetSameToggleClick = (event: any, krItem: any, krIndex: number) => {
		try {
			const { checked } = event?.target;
			if (mode === 'Edit' && defaultOkrFormDetails?.status === Enums.TWO && krItem?.defaultGoalKeyId) {
				setModalPerformanceProps({
					open: true,
					message: t('changeWeightOrTargetToggle'),
					details: {
						checked,
						krItem,
						krIndex,
					},
					type: 'KrTargetToggleChange',
				});
			} else {
				updateIsTargetSameToggleDetails(checked, krItem, krIndex);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleKrStartTargetValueChange = (event: any, krItem: any, krIndex: number, keyName: string) => {
		try {
			const { value } = event?.target;
			const regexp = /^[0-9\\.b]+$/;
			if (value === '' || regexp.test(value)) {
				let newValue = !Boolean(value) ? '0' : value;
				const { defaultGoalKeys } = defaultOkrFormDetails;
				let updatedDefaultGoalKeys = [...defaultGoalKeys];
				updatedDefaultGoalKeys[krIndex][keyName] = parseInt(newValue);
				updatedDefaultGoalKeys[krIndex].isTargetValueError = false;
				updatedDefaultGoalKeys[krIndex].krTargetValueErrorMsg = '';
				updatedDefaultGoalKeys[krIndex].defaultGoalKeyAssignMappings =
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings &&
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.length
						? updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.map((role: any) => {
								role[keyName] = krItem?.isTargetSame ? parseInt(newValue) : role[keyName];
								role.isRoleTargetValueError = false;
								role.roleTargetValueErrorMsg = '';

								return role;
						  })
						: [];
				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedDefaultGoalKeys] });
				setIsDefaultOkrFormEdited(true);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleKrRoleStartTargetValueChange = (
		event: any,
		krItem: any,
		krIndex: number,
		roleItem: any,
		roleIndex: number,
		keyName: string
	) => {
		try {
			const { value } = event?.target;
			const regexp = /^[0-9\\.b]+$/;
			if (value === '' || regexp.test(value)) {
				let newValue = !Boolean(value) ? '0' : value;
				// console.log('handleKrRoleStartTargetValueChange ===> ', newValue, krItem, krIndex);
				const { defaultGoalKeys } = defaultOkrFormDetails;
				let updatedDefaultGoalKeys = [...defaultGoalKeys];
				updatedDefaultGoalKeys[krIndex].defaultGoalKeyAssignMappings =
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings &&
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.length
						? updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.map((roleVal: any, indexVal: number) => {
								if (indexVal === roleIndex) {
									roleVal[keyName] = parseInt(newValue);
									roleVal.isRoleTargetValueError = false;
									roleVal.roleTargetValueErrorMsg = '';
								}

								return roleVal;
						  })
						: [];
				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedDefaultGoalKeys] });
				setIsDefaultOkrFormEdited(true);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleWeightUpdate = (selectedWeightDetails: any, selectedWeight: number) => {
		try {
			const { details, type } = selectedWeightDetails || {};
			const { defaultGoalKeys } = defaultOkrFormDetails;
			if (type === 'KrWeight') {
				const { krItem, krIndex } = details || {};
				let updatedDefaultGoalKeys = [...defaultGoalKeys];
				const checked = updatedDefaultGoalKeys[krIndex].isWeightSame;
				updatedDefaultGoalKeys[krIndex].weight = selectedWeight;
				updatedDefaultGoalKeys[krIndex].defaultGoalKeyAssignMappings =
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings &&
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.length
						? updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.map((role: any) => {
								role.weight = checked ? selectedWeight : Enums.ONE;

								return role;
						  })
						: [];
				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedDefaultGoalKeys] });
			} else if (type === 'KrRoleWeight') {
				const { krItem, krIndex, role, roleIndex } = details || {};
				let updatedDefaultGoalKeys = [...defaultGoalKeys];
				updatedDefaultGoalKeys[krIndex].defaultGoalKeyAssignMappings =
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings &&
					updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.length
						? updatedDefaultGoalKeys[krIndex]?.defaultGoalKeyAssignMappings?.map((roleVal: any, indexVal: number) => {
								if (indexVal === roleIndex) {
									roleVal.weight = selectedWeight || Enums.ONE;
								}

								return roleVal;
						  })
						: [];
				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedDefaultGoalKeys] });
			}
			setIsDefaultOkrFormEdited(true);
		} catch (error) {}
	};
	/** */
	const handleObjectiveNameChange = (event: any) => {
		try {
			const { name, value } = event?.target || {};
			if (name) {
				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, [name]: value });
				setOkrNameError({ error: false, errorText: '' });
				setIsDefaultOkrFormEdited(true);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleKrExpandCollapse = (krItem: any, krIndex: number) => {
		try {
			let krListDetails =
				defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys.length
					? defaultOkrFormDetails?.defaultGoalKeys
					: [];
			krListDetails = krListDetails.map((kr: any, index: number) => {
				if (index === krIndex) {
					kr.isExpand = !kr?.isExpand;
				}
				//  else {
				// 	kr.isExpand = false;
				// }
				return kr;
			});

			setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...krListDetails] });
		} catch (error) {
			console.error(error);
		}
	};
	const handleKrRoleSort = (column: string, updatedRoleList: any, krItem: any, krIndex: number) => {
		// sortColumn
		let krListDetails =
			defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys.length
				? defaultOkrFormDetails?.defaultGoalKeys
				: [];
		krListDetails[krIndex].defaultGoalKeyAssignMappings = updatedRoleList;
		krListDetails[krIndex].krSortColumn = column;
		setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...krListDetails] });
	};
	const handleKrRoleSearch = (value = '', searchType: string, krItem: any, krIndex: number) => {
		try {
			let krListDetails =
				defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys.length
					? defaultOkrFormDetails?.defaultGoalKeys
					: [];
			if (krListDetails && krListDetails.length) {
				if (searchType === 'insert') {
					krListDetails[krIndex].searchTextValueForKrRole = value;
				} else if (searchType === 'search') {
					krListDetails[krIndex].searchTextValueForKrRole = '';
					krListDetails[krIndex].searchTextForKrRole = value;
				} else if (searchType === 'clear') {
					krListDetails[krIndex].searchTextValueForKrRole = '';
					krListDetails[krIndex].searchTextForKrRole = '';
				}
				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...krListDetails] });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleKrNameChange = (event: any, krItem: any, krIndex: number) => {
		try {
			const { value } = event?.target || {};
			let krListDetails =
				defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys.length
					? defaultOkrFormDetails?.defaultGoalKeys
					: [];
			if (krListDetails && krListDetails.length) {
				krListDetails[krIndex].name = value;
				krListDetails[krIndex].isKrEdited = true;
				krListDetails[krIndex].isKrNameError = false;
				krListDetails[krIndex].krNameErrorMsg = '';
				if (value && value?.trim() !== '' && noBlankKrAvailable(krListDetails)) {
					insertNewKrRow(krListDetails);
				} else {
					setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...krListDetails] });
				}
			} else {
				insertNewKrRow([]);
			}
			setIsDefaultOkrFormEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const noBlankKrAvailable = (krListDetails: any) => {
		const krItem = krListDetails?.find((item: any) => item?.name === '' || (item.name && item.name.trim() === ''));

		return krItem ? false : true;
	};
	const insertNewKrRow = (krListDetails: any) => {
		const { assignIds, defaultGoalObjectiveId, assignType } = defaultOkrFormDetails || {};

		setDefaultOkrFormDetails({
			...defaultOkrFormDetails,
			defaultGoalKeys: [
				...krListDetails,
				{
					...defaultGoalKeyObject,
					startDate: selectedCycleDetails?.defaultStartDate || selectedCycleDetails?.startDate,
					endDate: selectedCycleDetails?.defaultEndDate || selectedCycleDetails?.endDate,
					defaultGoalKeyAssignMappings:
						assignType === Enums.FOUR
							? assignIds
							: assignIds && assignIds?.length
							? assignIds?.map((item: any) => {
									return {
										...item,
										defaultGoalKeyAssignMappingId: Enums.ZERO,
										defaultGoalKeyId: defaultGoalObjectiveId || Enums.ZERO,
										weight: Enums.ONE,
										startValue: Enums.ZERO,
										targetValue: Enums.ZERO,
									};
							  })
							: [],
				},
			],
		});
	};
	const handleUpdateAssignIds = (assignIdsDetails: any, assignType: number) => {
		try {
			const { defaultGoalKeys } = defaultOkrFormDetails || {};
			const updatedAssignIds =
				assignType === Enums.FOUR
					? assignIdsDetails
					: assignIdsDetails && assignIdsDetails?.length
					? assignIdsDetails.map((item: any) => {
							const rec = { ...item };
							let extraParamForUser = {};
							if (assignType === Enums.THREE) {
								extraParamForUser = {
									firstName: item?.firstName || '',
									lastName: item?.lastName || '',
									employeeId: item?.employeeId || '',
									imagePath: item?.imagePath || '',
									emailId: item?.emailId || '',
									isActive: item?.isActive,
								};
							}
							return {
								...extraParamForUser,
								defaultGoalObjectiveAssignMappingId: Enums.ZERO,
								assignId: rec?.value || Enums.ZERO,
								name: rec?.label || '',
								userCount: rec?.userCount || Enums.ZERO,
							};
					  })
					: [];
			let updatedDefaultGoalKeys = defaultGoalKeys && defaultGoalKeys?.length ? [...defaultGoalKeys] : [];
			updatedDefaultGoalKeys = [...defaultGoalKeys]?.map((rec: any) => {
				const updatedDefaultGoalKeyAssignMappings =
					updatedAssignIds && updatedAssignIds?.length
						? updatedAssignIds?.map((item: any) => {
								let findData = null;
								if (defaultOkrFormDetails?.assignType === assignType) {
									findData = rec?.defaultGoalKeyAssignMappings.find((re: any) => re?.assignId === item?.assignId);
								}

								return {
									...item,
									defaultGoalKeyAssignMappingId: Enums.ZERO,
									// assignId: item?.assignId || Enums.ZERO,
									defaultGoalKeyId: defaultOkrFormDetails?.defaultGoalObjectiveId || Enums.ZERO,
									// defaultGoalObjectiveAssignMappingId: item?.defaultGoalObjectiveAssignMappingId || Enums.ZERO,
									weight: rec?.isWeightSame ? rec?.weight : findData?.weight || Enums.ONE,
									startValue: rec?.isTargetSame ? rec?.startValue : findData?.startValue || Enums.ZERO,
									targetValue: rec?.isTargetSame ? rec?.targetValue : findData?.targetValue || Enums.ZERO,
								};
						  })
						: [];
				return {
					...rec,
					isKrEdited: true,
					defaultGoalKeyAssignMappings: [...updatedDefaultGoalKeyAssignMappings],
				};
			});

			setDefaultOkrFormDetails({
				...defaultOkrFormDetails,
				assignType,
				assignIds: updatedAssignIds,
				selectedListDetails: assignIdsDetails && assignIdsDetails?.length ? assignIdsDetails : [],
				defaultGoalKeys: [...updatedDefaultGoalKeys],
			});
			setSelectedDesignationOptions([]);
			setSelectedPerformanceRoleOptions([]);
			setSelectedUsersOptions([]);
			setAllEmployeeChecked(false);
		} catch (error) {
			console.error(error);
		}
	};
	const handleAllEmployeeSelection = (allEmpChecked: boolean) => {
		try {
			setAllEmployeeChecked(allEmpChecked || false);
			setSelectedDesignationOptions([]);
			setSelectedPerformanceRoleOptions([]);
			setSelectedUsersOptions([]);
			setDefaultOkrFormEdited(true);
		} catch (error) {}
	};
	const handleSelectedRoleDeletion = (
		event: any, // button event
		item: any, // array for single or bulk
		indexVal: number, // item index for single item
		deleteType: string, // single bulk
		assignType: number // 1 2 3
	) => {
		if (defaultOkrFormDetails?.status !== Enums.TWO) {
			setModalPerformanceProps({
				open: true,
				message: t('selectedRecordsDelete'),
				details: {
					headingText: t('assignRoleDelete'),
					subHeading: ' ',
					matchString: Enums.DELETE_TEXT,
					item,
					indexVal,
					deleteType: 'ROLE',
					assignType,
				},
				type: 'ROLE',
			});
		} else {
			setModalProps({
				open: true,
				message: '',
				module: 'confirmation',
				details: {
					headingText: t('assignRoleDelete'),
					subHeading: ' ',
					matchString: Enums.DELETE_TEXT,
					item,
					indexVal,
					deleteType: 'ROLE',
					assignType,
				},
			});
		}
	};
	const deleteDefaultOkr = async (deletionId: any, deleteType: string) => {
		try {
			if (deletionId) {
				setShowLoader(true);
				const isGoalObjective = deleteType === 'OKR' ? true : false;
				const requestParams = `id=${deletionId}&isGoalObjective=${isGoalObjective}`;
				const response: any = await dispatch(defaultOkrDelete(requestParams));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					// defaultOkrAPICall();
					if (deleteType === 'OKR') {
						handleBackButtonOnClick();
					} else {
						setShowLoader(false);
					}
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : null;
					const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
					showApiMsg('error', messages || t('FetchingDefaultOkrError'));
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleConfirmationCloseModal = async (e: any, type: any) => {
		try {
			// e.preventDefault();
			if (type === 1) {
				const { details } = modalProps || {};
				if (details && details?.deleteType === 'OKR') {
					if (mode === 'Add') {
						handleBackButtonOnClick();
					} else {
						deleteDefaultOkr(details?.okrDetails?.defaultGoalObjectiveId, details?.deleteType);
					}
				} else if (details && details?.deleteType === 'KR') {
					const { item, indexValue } = details;
					if (mode === 'Add' || !item?.defaultGoalKeyId) {
						// console.log(item, indexValue);
						// remove locally
					} else {
						deleteDefaultOkr(item?.defaultGoalKeyId, details?.deleteType);
					}
					const { defaultGoalKeys } = defaultOkrFormDetails;
					if (defaultGoalKeys && defaultGoalKeys?.length) {
						const updatedDefaultGoalKeys = defaultGoalKeys.filter(
							(rec: any, itemIndex: number) => itemIndex !== indexValue
						);
						setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: updatedDefaultGoalKeys });
						setIsDefaultOkrFormEdited(true);
					}
				} else if (details && details?.deleteType === 'OKRCycleChange') {
					const { range, start, end, selCycleData } = details;
					if (range) {
						setSelectedDateRange(range);
						// setSelectedKrDateRange(range);
						const updatedCycleDetails = {
							teamCycleDetailId: selectedCycleDetails?.organisationCycleId || Enums.ZERO,
							startDate: start || null,
							endDate: end || null,
						};
						const { defaultGoalKeys } = defaultOkrFormDetails;
						let updatedDefaultGoalKeys = updateKrDates(defaultGoalKeys, start, end, false);
						setDefaultOkrFormDetails({
							...defaultOkrFormDetails,
							...updatedCycleDetails,
							defaultGoalKeys: [...updatedDefaultGoalKeys],
						});

						setSelectedCycle({
							organisationCycleId: selCycleData?.organisationCycleId || 0,
							currentCycleYear: selCycleData?.year || '',
							cycleDuration: cycleDuration || '',
						});
						setSelectedOkrListCycleValue({
							...selCycleData,
						});
						setIsDefaultOkrFormEdited(true);
					}
				} else if (details && details?.deleteType === 'ROLE') {
					const { item, indexVal, deleteType, assignType } = details || {};
					if (assignType === Enums.ONE) {
						let updatedList = [...selectedDesignationOptions];
						updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.value === rec.value));
						setSelectedDesignationOptions(updatedList);
					} else if (assignType === Enums.TWO) {
						let updatedList = [...selectedPerformanceRoleOptions];
						updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.value === rec.value));
						setSelectedPerformanceRoleOptions(updatedList);
					} else if (assignType === Enums.THREE) {
						let updatedList = [...selectedUsersOptions];
						updatedList = updatedList.filter((rec: any) => !item?.find((role: any) => role?.value === rec.value));
						setSelectedUsersOptions(updatedList);
					}
					setIsDefaultOkrFormEdited(true);
				}
				setModalProps({ open: false, message: '', details: '' });
			} else {
				const { details } = modalProps || {};
				if (details && details?.deleteType === 'OKRCycleChange') {
					const { prevSelection } = details;
					if (prevSelection) {
						setSelectedCycleDetails(prevSelection);
					}
				}
				setModalProps({ open: false, message: '', details: '' });
			}
		} catch (error) {
			console.error(error);
		}
	};
	const validateDraftForm = (type = 1) => {
		if (type === Enums.ONE) {
			if (!defaultOkrFormDetails?.name || defaultOkrFormDetails?.name?.trim() === '') {
				setOkrNameError({ error: true, errorText: t('addObjTitle') });
				return false;
			} else {
				return true;
			}
		} else if (type === Enums.TWO || type === Enums.THREE) {
			if (!defaultOkrFormDetails?.assignIds || defaultOkrFormDetails?.assignIds?.length === Enums.ZERO) {
				setOkrRoleError({ error: true, errorText: t('roleRequiredAlertMssg') });
				return false;
			} else if (!defaultOkrFormDetails?.name || defaultOkrFormDetails?.name?.trim() === '') {
				setOkrNameError({ error: true, errorText: t('addObjTitle') });
				return false;
			} else if (defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys?.length > 1) {
				const { defaultGoalKeys } = defaultOkrFormDetails || {};
				let updatedErrorMessage = defaultGoalKeys && defaultGoalKeys?.length ? [...defaultGoalKeys] : [];
				const lastUpdatedErrorMessage = updatedErrorMessage.splice(-1); // to remove last blank kr
				const krDataWithNoNames = updatedErrorMessage?.length
					? [...updatedErrorMessage]?.findIndex(
							(rec: any) =>
								!rec?.name ||
								rec?.name?.trim() === '' ||
								!rec.metricType ||
								(rec?.isTargetSame && !rec.targetValue && !rec.startValue) ||
								(rec?.isTargetSame && rec.targetValue && rec.startValue && rec.targetValue === rec.startValue) ||
								(rec.metricType === Enums.TWO && !rec?.currencyId)
					  )
					: -1;
				if (krDataWithNoNames > -1) {
					// updatedErrorMessage = defaultGoalKeys && defaultGoalKeys?.length ? [...defaultGoalKeys] : [];
					updatedErrorMessage = updatedErrorMessage.map((it: any) => {
						let data = { ...it };
						if (!data?.name || data?.name?.trim() === '') {
							data = { ...data, isExpand: true, isKrNameError: true, krNameErrorMsg: t('addKrTitle') };
						} else {
							data = { ...data, isKrNameError: false, krNameErrorMsg: '' };
						}
						if (!data.metricType) {
							data = {
								...data,
								isExpand: true,
								isMetricTypeError: true,
								krMetricTypeErrorMsg: t('metricTypeRequired'),
								isMetricCurrencyError: false,
								krMetricCurrencyErrorMsg: '',
							};
						} else if (data.metricType === Enums.TWO && !data?.currencyId) {
							data = {
								...data,
								isExpand: true,
								isMetricTypeError: false,
								krMetricTypeErrorMsg: '',
								isMetricCurrencyError: true,
								krMetricCurrencyErrorMsg: t('currencyRequired'),
							};
						}
						if (data?.isTargetSame && !data.targetValue && !data.startValue) {
							data = {
								...data,
								isExpand: true,
								isTargetValueError: true,
								krTargetValueErrorMsg: t('targetValueRequired'),
							};
						} else if (data?.isTargetSame && data.targetValue === data.startValue) {
							data = {
								...data,
								isExpand: true,
								isTargetValueError: true,
								krTargetValueErrorMsg: t('startTargetNotSame'),
							};
						} else {
							data = { ...data, isTargetValueError: false, krTargetValueErrorMsg: '' };
						}

						return data;
					});

					setDefaultOkrFormDetails({
						...defaultOkrFormDetails,
						defaultGoalKeys: [...updatedErrorMessage, ...lastUpdatedErrorMessage],
					});

					return false;
				} else {
					const krDetailsCheckForIsTargetSame = updatedErrorMessage?.length
						? [...updatedErrorMessage]?.findIndex((rec: any) => !rec?.isTargetSame)
						: -1;
					if (krDetailsCheckForIsTargetSame > -1) {
						let returnVal = true;
						updatedErrorMessage = updatedErrorMessage.map((it: any) => {
							let data = { ...it };
							if (!data?.isTargetSame) {
								data.defaultGoalKeyAssignMappings =
									data?.defaultGoalKeyAssignMappings && data?.defaultGoalKeyAssignMappings?.length
										? data?.defaultGoalKeyAssignMappings?.map((role: any) => {
												let roleData = { ...role };
												if (!roleData.targetValue && !roleData.startValue) {
													roleData = {
														...roleData,
														isRoleTargetValueError: true,
														roleTargetValueErrorMsg: t('targetValueRequired'),
													};
													data.isExpand = true;
													returnVal = false;
												} else if (roleData.targetValue === roleData.startValue) {
													roleData = {
														...roleData,
														isRoleTargetValueError: true,
														roleTargetValueErrorMsg: t('startTargetNotSame'),
													};
													data.isExpand = true;
													returnVal = false;
												} else {
													roleData = { ...roleData, isRoleTargetValueError: false, roleTargetValueErrorMsg: '' };
												}

												return roleData;
										  })
										: [];
							}

							return data;
						});

						setDefaultOkrFormDetails({
							...defaultOkrFormDetails,
							defaultGoalKeys: [...updatedErrorMessage, ...lastUpdatedErrorMessage],
						});

						return returnVal;
					}
					return true;
				}
			} else if (defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys?.length === 1) {
				const { defaultGoalKeys } = defaultOkrFormDetails || {};
				let updatedErrorMessage = defaultGoalKeys && defaultGoalKeys?.length ? [...defaultGoalKeys] : [];
				updatedErrorMessage = updatedErrorMessage.map((it: any) => {
					let data = { ...it };
					if (!data?.name || data?.name?.trim() === '') {
						data = { ...data, isKrNameError: true, krNameErrorMsg: t('addKrTitle') };
					} else {
						data = { ...data, isKrNameError: false, krNameErrorMsg: '' };
					}
					if (!data.metricType) {
						data = {
							...data,
							isMetricTypeError: true,
							krMetricTypeErrorMsg: t('metricTypeRequired'),
							isMetricCurrencyError: false,
							krMetricCurrencyErrorMsg: 'false',
						};
					} else if (data.metricType === Enums.TWO && !data?.currencyId) {
						data = {
							...data,
							isMetricTypeError: false,
							krMetricTypeErrorMsg: '',
							isMetricCurrencyError: true,
							krMetricCurrencyErrorMsg: t('currencyRequired'),
						};
					}
					if (!data.targetValue) {
						data = { ...data, isTargetValueError: true, krTargetValueErrorMsg: t('targetValueRequired') };
					} else if (data.targetValue === data.startValue) {
						data = {
							...data,
							isTargetValueError: true,
							krTargetValueErrorMsg: t('startTargetNotSame'),
						};
					} else {
						data = { ...data, isTargetValueError: false, krTargetValueErrorMsg: '' };
					}

					return data;
				});

				setDefaultOkrFormDetails({ ...defaultOkrFormDetails, defaultGoalKeys: [...updatedErrorMessage] });

				return false;
			}
		} else {
			return true;
		}
	};
	const handleDraftDefaultOkrClick = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (validateDraftForm(Enums.ONE)) {
				setShowLoader(true);
				setOkrNameError({ error: false, errorText: '' });
				setOkrRoleError({ error: false, errorText: '' });
				let requestPayload = {
					...defaultOkrFormDetails,
					natureTypeId: Enums.TWO,
					status: Enums.ONE,
					isUpdatedNotPublished: false,
					defaultGoalKeys:
						defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys?.length
							? defaultOkrFormDetails?.defaultGoalKeys
									?.filter((rec: any) => rec?.name || rec?.name?.trim() !== '')
									.map((rec: any) => {
										return { ...rec, status: Enums.ONE, isUpdatedNotPublished: false };
									})
							: [],
				};
				// Need to check KR Status based on isKrEdited flag
				delete requestPayload.selectedListDetails;
				// console.log('handleDraftDefaultOkrClick ===>', requestPayload);
				let response: any = null;
				if (mode === 'Add') {
					response = await dispatch(createDefaultOkrCall(requestPayload));
				} else if (mode === 'Edit') {
					response = await dispatch(editDefaultOkrCall(requestPayload));
				}
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					// NEED TO UPDATE
					setHighlightDefaultOkr(response?.data?.entity?.defaultGoalObjectiveId);

					selectedOkrListCycleValue && setSelectedOkrListCycleDetails(selectedOkrListCycleValue);
					handleBackButtonOnClick();
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : null;
					const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
					enqueueSnackbar(messages || t('savingDraftError'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				setIsDefaultOkrFormEdited(false);
				setShowLoader(false);
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('savingDraftError'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setShowLoader(false);
		}
	};
	const handleSaveAndPublishLaterDefaultOkrClick = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (validateDraftForm(Enums.TWO)) {
				// console.log('handleSaveAndPublishLaterDefaultOkrClick ===>', defaultOkrFormDetails);
				setShowLoader(true);
				setOkrNameError({ error: false, errorText: '' });
				setOkrRoleError({ error: false, errorText: '' });
				const requestPayload = {
					...defaultOkrFormDetails,
					natureTypeId: Enums.TWO,
					status:
						defaultOkrFormDetails?.status === Enums.TWO || defaultOkrFormDetails?.status === Enums.FOUR
							? Enums.FOUR
							: Enums.THREE,
					isUpdatedNotPublished:
						defaultOkrFormDetails?.status === Enums.TWO || defaultOkrFormDetails?.status === Enums.FOUR ? true : false,
					defaultGoalKeys:
						defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys?.length
							? defaultOkrFormDetails?.defaultGoalKeys
									?.filter((rec: any) => rec?.name || rec?.name?.trim() !== '')
									.map((rec: any) => {
										return {
											...rec,
											status:
												defaultOkrFormDetails?.status === Enums.TWO || defaultOkrFormDetails?.status === Enums.FOUR
													? Enums.FOUR
													: Enums.THREE,
											isUpdatedNotPublished:
												defaultOkrFormDetails?.status === Enums.TWO || defaultOkrFormDetails?.status === Enums.FOUR
													? true
													: false,
										};
									})
							: [],
				};
				// Need to check KR Status based on isKrEdited flag
				delete requestPayload.selectedListDetails;
				// console.log('handleSaveAndPublishLaterDefaultOkrClick ===>', requestPayload);
				// /*
				let response: any = null;
				if (mode === 'Add') {
					response = await dispatch(createDefaultOkrCall(requestPayload));
				} else if (mode === 'Edit') {
					response = await dispatch(editDefaultOkrCall(requestPayload));
				}
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					// NEED TO UPDATE
					setHighlightDefaultOkr(response?.data?.entity?.defaultGoalObjectiveId);
					selectedOkrListCycleValue && setSelectedOkrListCycleDetails(selectedOkrListCycleValue);
					handleBackButtonOnClick();
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : null;
					const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
					enqueueSnackbar(messages || t('savingDraftError'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
				// */
				setIsDefaultOkrFormEdited(false);
				setShowLoader(false);
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('savingDraftError'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setShowLoader(false);
		}
	};
	const handlePublishDefaultOkrClick = async (e: any) => {
		try {
			e.preventDefault();
			e.stopPropagation();
			if (validateDraftForm(Enums.TWO)) {
				// console.log('handlePublishDefaultOkrClick ===>', defaultOkrFormDetails);
				setShowLoader(true);
				setOkrNameError({ error: false, errorText: '' });
				setOkrRoleError({ error: false, errorText: '' });
				const requestPayload = {
					...defaultOkrFormDetails,
					natureTypeId: Enums.TWO,
					status:
						defaultOkrFormDetails?.status === Enums.TWO
							? Enums.FOUR
							: defaultOkrFormDetails?.status === Enums.FOUR
							? Enums.FOUR
							: Enums.TWO,
					isUpdatedNotPublished: false,
					defaultGoalKeys:
						defaultOkrFormDetails?.defaultGoalKeys && defaultOkrFormDetails?.defaultGoalKeys?.length
							? defaultOkrFormDetails?.defaultGoalKeys
									?.filter((rec: any) => rec?.name || rec?.name?.trim() !== '')
									.map((rec: any) => {
										return {
											...rec,
											status:
												defaultOkrFormDetails?.status === Enums.TWO
													? Enums.FOUR
													: defaultOkrFormDetails?.status === Enums.FOUR
													? Enums.FOUR
													: Enums.TWO,
											isUpdatedNotPublished: false,
										};
									})
							: [],
				};
				// Need to check KR Status based on isKrEdited flag
				delete requestPayload.selectedListDetails;
				// console.log('handlePublishDefaultOkrClick ===>', requestPayload);
				let response: any = null;
				if (mode === 'Add') {
					response = await dispatch(createDefaultOkrCall(requestPayload));
				} else if (mode === 'Edit') {
					response = await dispatch(editDefaultOkrCall(requestPayload));
				}
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					setShowLoader(false);
					// NEED TO UPDATE
					setHighlightDefaultOkr(response?.data?.entity?.defaultGoalObjectiveId);
					selectedOkrListCycleValue && setSelectedOkrListCycleDetails(selectedOkrListCycleValue);
					handleBackButtonOnClick();
				} else {
					setShowLoader(false);
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : null;
					const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
					enqueueSnackbar(messages || t('savingDraftError'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}

				setIsDefaultOkrFormEdited(false);
				setShowLoader(false);
			}
		} catch (error) {
			console.error(error);
			enqueueSnackbar(t('savingDraftError'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setShowLoader(false);
		}
	};
	const checkDisableButton = () => {
		const isDisable = !keyData?.metricType
			? true
			: keyData?.metricType === Enums.TWO && !keyData?.currencyId
			? true
			: false;

		return isDisable;
	};

	// console.log('CreateDefaultOkr ===> ', mode, defaultOkrFormDetails);

	return (
		<>
			<Box className='create-default-okr'>
				<Box className='admin-tabpanel-inner'>
					<Box className='admin-tabpanel-head'>
						<Box className='back-to-main' onClick={handleBackButtonOnClick}>
							<Typography variant='body2'>
								<Link id='back-btn'>
									<BackArrow /> {t('back')}
								</Link>
							</Typography>
						</Box>
						<Typography variant='h2'>{okrMasterData?.defaultOkrTagName || t('Default OKR')}</Typography>
					</Box>
				</Box>
				<div ref={refObject}>
					<Box className='full-rectangular-card default-okr-card'>
						<Box className={`helperText-bottom drawer-date-field`}>
							<FormLabel id='set-a-duration' component='legend' className='custom-form-label'>
								<CalendarIcon />
								{t('setATimeframe')}
							</FormLabel>
							<ReactDateRangePicker
								type={'KRAttribute'}
								selectedCycleDetails={selectedCycleDetails}
								setSelectedCycleDetails={setSelectedCycleDetails}
								startDateValue={defaultOkrFormDetails?.startDate ? defaultOkrFormDetails?.startDate : new Date()}
								endDateValue={defaultOkrFormDetails?.endDate ? defaultOkrFormDetails?.endDate : new Date()}
								minimumDate={selectedCycleDetails?.startDate ? new Date(selectedCycleDetails?.startDate) : new Date()}
								maximumDate={selectedCycleDetails?.endDate ? new Date(selectedCycleDetails?.endDate) : new Date()}
								handleDateSelection={(
									range: any,
									start: any,
									end: any,
									selectionType: any,
									isCycleChanged: any,
									selCycleData: any,
									prevSelection: any
								) => {
									// range && handleDateOverlapCheck(range, start, end);
									// console.log(range, start, end, selectionType, isCycleChanged, selCycleData, prevSelection);
									if (mode === 'Edit' && isCycleChanged && defaultOkrFormDetails?.status === Enums.TWO) {
										setModalProps({
											open: true,
											message: '',
											details: {
												deleteType: 'OKRCycleChange',
												headingText: t('changingCycleAlert'),
												subHeading: t('deletePerformanceCycleSubHead'),
												confirmMsg: t('stillWantToProceed'),
												matchString: Enums.CHANGE_TEXT,
												module: 'defaultKR',
												range,
												start,
												end,
												selCycleData,
												prevSelection,
											},
										});
									} else {
										if (range) {
											setSelectedDateRange(range);
											// setSelectedKrDateRange(range);
											const updatedCycleDetails = {
												teamCycleDetailId: selectedCycleDetails?.organisationCycleId || Enums.ZERO,
												startDate: start || null,
												endDate: end || null,
											};
											const { defaultGoalKeys } = defaultOkrFormDetails;
											let updatedDefaultGoalKeys = updateKrDates(defaultGoalKeys, start, end, false);
											setDefaultOkrFormDetails({
												...defaultOkrFormDetails,
												...updatedCycleDetails,
												defaultGoalKeys: [...updatedDefaultGoalKeys],
											});

											setSelectedCycle({
												organisationCycleId: selCycleData?.organisationCycleId || 0,
												currentCycleYear: selCycleData?.year || '',
												cycleDuration: cycleDuration || '',
											});
											setSelectedOkrListCycleValue({
												...selCycleData,
											});
											setIsDefaultOkrFormEdited(true);
										}
									}
								}}
								isDateRangeTextFieldVisible={true}
								isOnlyDueDateVisible={false}
								allCycleDetails={getCycleDetailsForCreateEditOkr()}
								isCurrentCycle={isCurrentCycle}
								isCycleVisible={true}
								isEndOfMonthVisible={false}
								numberOfCalendars={Enums.TWO}
								selectionType={'range'}
								selectedDateRange={selectedDateRange}
								setSelectedDateRange={setSelectedDateRange}
								placeholderText={t('selectedDateRange')}
								editModee={false}
							/>
							{selectedCycleDetails ? (
								<Box className={`cycle-days`}>
									<Typography variant='h6'>{`Cycle: ${selectedCycleDetails?.symbol}, ${selectedCycleDetails?.year}`}</Typography>
								</Box>
							) : (
								<></>
							)}
						</Box>
						<AssignChip
							handleDrawerOpen={handleDrawerOpen}
							defaultOkrFormDetails={defaultOkrFormDetails}
							okrRoleError={okrRoleError}
						/>
					</Box>
					<CreateDefaultOkrDetails
						handleKrDrawerOpen={handleKrDrawerOpen}
						defaultOkrFormDetails={defaultOkrFormDetails}
						handleObjectiveNameChange={handleObjectiveNameChange}
						handleKrNameChange={handleKrNameChange}
						handleOkrDeletionClick={handleOkrDeletionClick}
						handleKRDeletionClick={handleKRDeletionClick}
						selectedCycleDetails={selectedCycleDetails}
						handleDraftDefaultOkrClick={handleDraftDefaultOkrClick}
						handleSaveAndPublishLaterDefaultOkrClick={handleSaveAndPublishLaterDefaultOkrClick}
						handlePublishDefaultOkrClick={handlePublishDefaultOkrClick}
						okrNameError={okrNameError}
						okrRoleError={okrRoleError}
						showLoader={showLoader}
						handleIsWeightSameToggleClick={handleIsWeightSameToggleClick}
						handleIsTargetSameToggleClick={handleIsTargetSameToggleClick}
						handleKrStartTargetValueChange={handleKrStartTargetValueChange}
						handleKrRoleStartTargetValueChange={handleKrRoleStartTargetValueChange}
						handleWeightUpdate={handleWeightUpdate}
						handleKrRoleSearch={handleKrRoleSearch}
						handleKrRoleSort={handleKrRoleSort}
						handleKrExpandCollapse={handleKrExpandCollapse}
						weightSelectionOpen={weightSelectionOpen}
						setWeightSelectionOpen={setWeightSelectionOpen}
					/>
					{openDefaultObjDrawer ? (
						<MainDrawer
							open={openDefaultObjDrawer}
							transitionDuration={{ enter: 500, exit: 1000 }}
							headerTitle={`${
								okrMasterData?.defaultOkrTagName ? 'Assign ' + okrMasterData?.defaultOkrTagName : t('defaultObjective')
							}`}
							loader={showLoader}
							children={
								<Box className='drawer-inner-content'>
									<AssignDefaultObjDrawer
										selectedAssignTab={selectedAssignTab}
										handleTabChange={handleTabChange}
										selectedRoleTab={selectedRoleTab}
										setSelectedRoleTab={setSelectedRoleTab}
										defaultOkrDesignationDetails={defaultOkrDesignationDetails}
										defaultOkPerformanceRoleDetails={defaultOkPerformanceRoleDetails}
										setDefaultOkrFormEdited={setDefaultOkrFormEdited}
										selectedDesignationOptions={selectedDesignationOptions}
										setSelectedDesignationOptions={setSelectedDesignationOptions}
										selectedPerformanceRoleOptions={selectedPerformanceRoleOptions}
										setSelectedPerformanceRoleOptions={setSelectedPerformanceRoleOptions}
										selectedUsersOptions={selectedUsersOptions}
										setSelectedUsersOptions={setSelectedUsersOptions}
										handleUpdateAssignIds={handleUpdateAssignIds}
										defaultOkrFormDetails={defaultOkrFormDetails}
										allEmployeeChecked={allEmployeeChecked}
										handleAllEmployeeSelection={handleAllEmployeeSelection}
										setAllEmployeeChecked={setAllEmployeeChecked}
										handleSelectedRoleDeletion={handleSelectedRoleDeletion}
									/>
								</Box>
							}
							saveButtonText={t('saveBtn')}
							handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
							handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
							handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
						/>
					) : (
						<></>
					)}
					{openDefaultKrDrawer && openDefaultKrDrawer?.open ? (
						<MainDrawer
							open={openDefaultKrDrawer.open}
							drawerClassName={'main-drawer-panel default-kr-attribute-panel'}
							transitionDuration={{ enter: 500, exit: 1000 }}
							headerTitle={t('keyResult')}
							loader={showLoader}
							children={
								<Box className='drawer-inner-content'>
									<DefaultKeyResultAttributes
										{...props}
										mode={mode}
										defaultOkrFormDetails={defaultOkrFormDetails}
										krDetails={openDefaultKrDrawer?.details ? openDefaultKrDrawer?.details : null}
										selectedDefaultKrTab={selectedDefaultKrTab}
										handleTabChange={handleTabChange}
										allCycleDetails={allCycleDetails}
										// selectedKrDateRange={selectedKrDateRange}
										// setSelectedKrDateRange={setSelectedKrDateRange}
										isCurrentCycle={isCurrentCycle}
										selectedKrCycleDetails={selectedCycleDetails}
										setSelectedKrCycleDetails={setSelectedCycleDetails}
										keyData={keyData}
										setKeyData={setKeyData}
										krAttributeEdited={krAttributeEdited}
										setKrAttributeEdited={setKrAttributeEdited}
									/>
								</Box>
							}
							isSaveButtonDisabled={checkDisableButton()}
							saveButtonText={t('saveBtn')}
							handleDrawerClose={(event: any) => handleKrDrawerClose(event, 'close')}
							handleSaveClick={(event: any) => handleKrDrawerClose(event, 'save')}
							handleCancelClick={(event: any) => handleKrDrawerClose(event, 'cancel')}
						/>
					) : (
						<></>
					)}
				</div>
			</Box>
			{modalPerformanceProps && modalPerformanceProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalPerformanceProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalPerformanceProps.open}
				/>
			)}
			{showErrorMessageModal && showErrorMessageModal.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={true}
					message={showErrorMessageModal?.message || ''}
					handleCloseModal={() => {
						setShowErrorMessageModal(false);
					}}
					modalOpen={showErrorMessageModal.open}
				/>
			)}
			{modalProps && modalProps.open && (
				<DialogComponent
					module={modalProps.module || 'defaultKR'}
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleConfirmationCloseModal(e, 2)}
					matchString={modalProps?.details?.matchString || Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleConfirmationCloseModal(e, 1)}
					confirmMsg={modalProps?.details?.confirmMsg || ''}
					headingText={modalProps?.details?.headingText || ''}
					subHeading={modalProps?.details?.subHeading || ''}
				/>
			)}
		</>
	);
};
