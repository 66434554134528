import { getRequest, postRequest, putRequest, deleteRequest } from '../config/auth';
import {
	GET_GLOBAL_TASK,
	GET_GLOBAL_TASK_SOURCE_LIST,
	TASK_COMPLETED,
	UPDATE_TASK,
	DELETE_TASK,
	GET_RECENT_ASSIGNEE,
} from '../config/api-url';

export const getAllGlobalTaskAPI = (data) => postRequest(`${GET_GLOBAL_TASK}`, data);
export const getGlobalTaskSourceAPI = (data) => getRequest(`${GET_GLOBAL_TASK_SOURCE_LIST}?${data}`, data);
export const completedGlobalTaskAPI = (data) => putRequest(`${TASK_COMPLETED}`, data);
export const createGlobalTaskAPI = (data) => postRequest(`${UPDATE_TASK}`, data);
export const updateGlobalTaskAPI = (data) => putRequest(`${UPDATE_TASK}`, data);
export const deleteGlobalTaskAPI = (data) => deleteRequest(`${DELETE_TASK}?${data}`, data);
/**
 * New Global Task Service Start Here
 */
export const getRecentAssigneeAPI = (data) => getRequest(`${GET_RECENT_ASSIGNEE}?${data}`, data);
