import React, { useEffect, Fragment, useState } from 'react';
import { Box, Typography, List, ListItem, TextField } from '@material-ui/core';
import { OKRButton } from './OKRButton';
import { removeLocalStorageItem } from '../../services/StorageService';
import { SettingsIcon } from '../../config/svg/MyGoalSvg';
import { DeleteIcon, GenerateOKRColorIcon } from '../../config/svg/CommonSvg';
import { UserAvatarGroup } from './UserAvatarGroup';
import SkeletonAvatar from './SkeletonAvatar';
import { getDueDaysText, getMonthDate, getMonthDateYear } from '../../config/utils';
import { OkrProgressBar } from '../OkrAction/OkrProgressBar';
import { Enums } from '../../config/enums';
import { KrConfidencePopper } from './KrConfidencePopper';
import { OKRListProgressBar } from './OKRListProgressBar';
import { CancelIcon } from '../../config/svg/Action';
import { CalendarIcon, TickIcon } from '../../config/svg/CommonSvgIcon';
import { useSelector } from 'react-redux';

const AddNewKr: React.FC<any> = (props: any) => {
	const {
		okrMasterData,
		okr,
		newKeyList,
		handleKRFocus,
		handleKRBlur,
		handleUserClickOnCard,
		handleNewKeyResultChange,
		checkMandatoryFieldforKR,
		showGoalAndKrAttribute,
		isAddClicked,
		finalSubmitted,
		deleteNewKeyResult,
		krFocus,
		loginUserAvatar,
		checkIfOkrList,
		userDetail,
		checkTeamIdAndCreatedBy,
		checkCanAddKr,
		updateProgress,
		t,
		showEditLoader,
		currentUser,
		showArchiveList,
		listOkrResult,
		enqueueSnackbar,
		setNewKeyList,
		selectedCycleDetails,
		handleOpenGenerateKrDrawer,
		isValidOkrKr,
	} = props;
	const [errorOpen, setErrorOpen] = useState<boolean>(false);

	const [updatedNewKRList, setUpdatedNewKRList] = useState<any>(newKeyList);

	useEffect(() => {
		// This will use to update Add new KR Start and End date if we updated OKR date

		const updatedList =
			newKeyList && newKeyList.length > 0
				? newKeyList.map((item: any) => {
						return {
							...item,
							dateRange: item?.dateRange || null,
						};
				  })
				: newKeyList;
		setUpdatedNewKRList(updatedList);
		setTimeout(() => {
			removeLocalStorageItem('updatedGoalObjId');
		}, 2000);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newKeyList]);
	const { progressPopupOpenKrId } = useSelector((state: any) => state.okrReducer);
	return (
		<>
			{newKeyList &&
				newKeyList.length > 0 &&
				newKeyList.map((item: any) => (
					<Box className=''>
						<Box
							className={`okr-listing-wrapper kr-listing-content new-kr ${
								!checkMandatoryFieldforKR(item) && finalSubmitted ? 'kr-level-error' : ''
							} ${progressPopupOpenKrId && progressPopupOpenKrId === item.id ? 'add-shadow' : ''}
							 ${item.keyDescription && item.keyDescription.trim() !== '' ? 'new-kr-title-creation' : ''}`}
							key={`newly_added_key_${item.id}`}
							onClick={(e) => handleUserClickOnCard(e)}
						>
							<OKRButton
								icon={<GenerateOKRColorIcon />}
								title={t('generateKeyResult')}
								className={'generate-okr-btn'}
								handleClick={(e) => handleOpenGenerateKrDrawer(e, okr)}
							/>
							<Box className='okr-listing-wrapper-inner'>
								<List component='ul'>
									<ListItem className='okr-col1'>
										<Box
											className='add-kr-textfield'
											key={`newly_added_key_${item.id}`}
											onClick={handleUserClickOnCard}
										>
											<Box className='kr-field'>
												<TextField
													value={item.keyDescription}
													className='add-kr'
													multiline
													maxRows={2}
													placeholder={t('addKeyRes')}
													InputProps={{ 'aria-label': 'Add Key Result', disableUnderline: true }}
													onChange={(e: any) => handleNewKeyResultChange(e.target.value, 'keyDescription', item)}
													error={isAddClicked && !Boolean(item.keyDescription)}
													autoFocus
													onFocus={handleKRFocus}
													onBlur={handleKRBlur}
												/>
											</Box>
											{item.keyDescription && item.keyDescription.trim() !== '' && (
												<Box className='listing-add-kr card-toggle'>
													<OKRButton
														title={t('weightHover')}
														className='btn-oval-shape'
														text={`${item.weightId}x`}
														buttonType={'disableButtonTooltip'}
														wrapClassName='btn-weight'
														handleClick={(e) => showGoalAndKrAttribute(e, okr, item, 2, 1)}
													/>
													<Box className='set-timeline-area'>
														<Box className='calender-date'>
															{checkIfOkrList() &&
															item.keyDescription &&
															item.keyDescription.trim() !== '' &&
															(item?.owner === userDetail?.employeeId ||
																checkTeamIdAndCreatedBy(okr) ||
																checkCanAddKr(okr, item)) ? (
																<Box className='timeline-field'>
																	<Box className='calendar-btn'>
																		<OKRButton
																			//tooltipClassName='tooltip-layout3'
																			text={`${getMonthDate(item.dueDate)} (${props.userSelectedCycle.label})`}
																			handleClick={(e: any) => {
																				props.updateCalloutPanel && props.updateCalloutPanel('');
																				setTimeout(() => {
																					props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
																				}, 500);

																				showGoalAndKrAttribute(e, okr, item, 2, 1);
																				removeLocalStorageItem('showCallouts');
																			}}
																			title={
																				<>
																					{t('blankSelection')}
																					{/* <Typography variant='h6'>
																						{`${getMonthDateYear(item.startDate)} - ${getMonthDateYear(item.dueDate)}`}
																					</Typography>
																					<Typography variant='h6'>
																						{getDueDaysText(item.startDate, item.dueDate, selectedCycleDetails)}
																					</Typography> */}
																				</>
																			}
																			icon={<CalendarIcon />}
																		/>
																	</Box>
																</Box>
															) : (
																<Box className='timeline-field'>
																	<Box className='calendar-btn'>
																		<OKRButton
																			//tooltipClassName='tooltip-layout3'
																			text={`${getMonthDate(item.dueDate)} (${props.userSelectedCycle.label})`}
																			title={
																				<>
																					{t('blankSelection')}
																					{/* <Typography variant='h6'>
																						{`${getMonthDateYear(item.startDate)} - ${getMonthDateYear(item.dueDate)}`}
																					</Typography>
																					<Typography variant='h6'>
																						{getDueDaysText(item.startDate, item.dueDate, selectedCycleDetails)}
																					</Typography> */}
																				</>
																			}
																			icon={<CalendarIcon />}
																		/>
																	</Box>
																</Box>
															)}
														</Box>
													</Box>
													<Box className='okr-field-actions'>
														<OKRButton
															title={t('okrHoverText')}
															className={
																!checkMandatoryFieldforKR(item) && finalSubmitted
																	? 'settings-btn settings-btn-error'
																	: 'settings-btn'
															}
															handleClick={(e: any) => {
																props.updateCalloutPanel && props.updateCalloutPanel('');
																setTimeout(() => {
																	props.updateCalloutPanel && props.updateCalloutPanel('krDrawer');
																}, 500);

																showGoalAndKrAttribute(e, okr, item, 2, 1);
																removeLocalStorageItem('showCallouts');
															}}
															icon={<SettingsIcon />}
															tooltipClassName={` ${
																!checkMandatoryFieldforKR(item) && finalSubmitted
																	? 'tooltip-layout3 tooltip-error-new'
																	: 'tooltip-layout3'
															}`}
															//openTooltip={isValidOkrKr(item, 'kr')}
															openTooltip={!checkMandatoryFieldforKR(item) && finalSubmitted ? true : false}
														/>

														<OKRButton
															title={t('delete')}
															handleClick={() => deleteNewKeyResult(okr, item)}
															className='delete-btn'
															icon={<DeleteIcon />}
														/>
													</Box>
												</Box>
											)}
										</Box>
									</ListItem>
									{(item.keyDescription && item.keyDescription.trim() !== '') || krFocus ? (
										<ListItem className='okr-col2 '>
											<OKRListProgressBar
												{...props}
												parentOkr={okr}
												kr={item}
												krObj={item}
												setOpenKrId={() => {}}
												newKr={true}
												loginUserAvatar={item.contributors && item.contributors.length ? item.contributors[0] : item}
												contributorDetails={
													item?.contributors && item?.contributors.length
														? item?.contributors
																.slice(1, item?.contributors.length)
																.filter((items: any) => !items.isSource)
														: []
												}
												isEditable={false}
												classNames={'create-progress-bar'}
												setErrorOpen={setErrorOpen}
											/>
										</ListItem>
									) : (
										''
									)}
								</List>
							</Box>
						</Box>
					</Box>
				))}
		</>
	);
};

export default AddNewKr;
