import React, { Fragment, useEffect, useState } from 'react';
import { Box, ClickAwayListener, List, ListItem, Typography } from '@material-ui/core';
import { OKRButton } from '../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { OkrStatus } from '../Common/OkrStatus';
import { ReportsGaugeChart } from '../Reports/HighchartReports/ReportsGaugeChart';
import { Enums } from '../../config/enums';
import { ReportsLineChart } from '../Reports/HighchartReports/ReportsLineChart';
import { CHART_COLOR } from '../../config/constant';
import { confidenceIcon, getCurrentQuarterAndYear, getUserDetails } from '../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOkrProgressDetails, getOkrProgressDetails } from '../../action/report';
import { getLocalStorageItem } from '../../services/StorageService';
import _ from 'lodash';

export const DashboardMyProgress: React.FC<any> = (props: any) => {
	const { handleCardClick } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	// let userDetail = getUserDetails();
	// const { userSelectedCycle, currentEngageTab, dashboardCurrentTab } = useSelector((state: any) => state.commonReducer);
	const cycleId = getLocalStorageItem('cycleId') || 0;
	const cycleEndDate = getLocalStorageItem('cycleEndDate') || '';
	const cycleStartDate = getLocalStorageItem('cycleStartDate') || '';
	const currentCycleDetails = getLocalStorageItem('currentCycle')
		? JSON.parse(getLocalStorageItem('currentCycle') || '')
		: null;
	//const year = getLocalStorageItem('year') || '';
	const loginUser = getUserDetails();
	const [overAllProgress, setOverAllProgress] = React.useState<any>({});
	const [okrData, setOkrData] = React.useState<any>({});
	const [myProgress, setMyProgress] = React.useState<any>({});
	const [fourWeeksDetails, setFourWeeksDetails] = React.useState<any>({});
	const [maxValueRecord, setMaxValueRecord] = React.useState<any>({});
	const { organisationCycleId, year, quarterSymbol } = getCurrentQuarterAndYear();

	useEffect(() => {
		if (cycleId && cycleEndDate && cycleStartDate) {
			getMyProgress();
		}
	}, [cycleId, cycleEndDate, cycleStartDate]);

	const getMyProgress = async () => {
		let data = {
			cycleId: Number(organisationCycleId) || 0,
			cycleStartDate:
				currentCycleDetails && currentCycleDetails?.cycleStartDate
					? currentCycleDetails?.cycleStartDate.split('T')[0]
					: cycleStartDate.split('T')[0],
			cycleEndDate:
				currentCycleDetails && currentCycleDetails?.cycleEndDate
					? currentCycleDetails?.cycleEndDate.split('T')[0]
					: cycleEndDate.split('T')[0],
			teamIds: [],
			okrNatureIds: [],
		};
		let okrData = `empId=${loginUser.employeeId}&cycle=${organisationCycleId}&year=${year}`;
		const okrDetails: any = await dispatch(getAllOkrProgressDetails(okrData));
		const apiRes: any = await dispatch(getOkrProgressDetails(data));
		if (okrDetails && okrDetails.data.status === 200 && apiRes && apiRes.data.status === 200) {
			setOverAllProgress(apiRes.data.entity.myProgressOverall);
			let allRecords = [
				...apiRes.data?.entity.myOrgMemberProgressDetails,
				...apiRes.data?.entity?.myProgressDetails,
				...apiRes.data?.entity?.myTeamMemberProgressDetails,
			];
			let maxVal = _.maxBy(allRecords, 'progress');
			setMaxValueRecord(maxVal || {});
			setMyProgress(apiRes.data?.entity?.myProgressOverall);
			setFourWeeksDetails(apiRes.data?.entity);
			setOkrData(okrDetails.data.entity);
		}
	};

	return (
		<>
			<Box className='my-progress-left' onClick={(e) => handleCardClick(e, 'myOkrs')}>
				<Box className='col'>
					<Typography variant='h6'>
						{t('myProgressLabel')} ({quarterSymbol}, {year})
					</Typography>
					<ReportsGaugeChart
						isOrgReport={true}
						progress={okrData?.avgScore}
						// memberCount={orgData?.entity?.memberCount}
						okrCount={okrData?.okrCount}
						krCount={okrData?.keyCount}
						opacity={0.5}
						radialCount={Enums.ONE}
						colors={[`#${okrData.progressCode}`]}
						labels={['My Progress']}
						chartHight={'145'}
						paneBackgroundColor={'rgba(255, 255, 255, 0.1)'}
					/>
				</Box>
				<Box className='col'>
					<OkrStatus {...props} data={okrData} countBg={true} />
					{/* <Box className='kr-confidence-list'>
						<Typography variant='h6'>{t('keyResultsConfidence')}</Typography>
						<List>
							<ListItem>
								<Typography variant='body2' className='confidence-label'>
									{confidenceIcon(3)} {t('highLabel')}
								</Typography>
								<Typography variant='body2' className='count'>
									{okrData.high}
								</Typography>
							</ListItem>
							<ListItem>
								<Typography variant='body2' className='confidence-label'>
									{confidenceIcon(2)} {t('mediumLabel')}
								</Typography>
								<Typography variant='body2' className='count'>
									{okrData.medium}
								</Typography>
							</ListItem>
							<ListItem>
								<Typography variant='body2' className='confidence-label'>
									{confidenceIcon(1)} {t('Low')}
								</Typography>
								<Typography variant='body2' className='count'>
									{okrData.low}
								</Typography>
							</ListItem>
							<ListItem>
								<Typography variant='body2' className='confidence-label'>
									{confidenceIcon(4)} {t('notSetLabel')}
								</Typography>
								<Typography variant='body2' className='count'>
									{okrData.notSet}
								</Typography>
							</ListItem>
						</List>
					</Box> */}
				</Box>
			</Box>
			<Box className='my-progress-right' onClick={(e) => handleCardClick(e, 'reports')}>
				<Typography variant='h6'>{t('weeksObjLabel')}</Typography>
				<ReportsLineChart
					t={t}
					dataLoaded={true}
					overAllProgress={{}}
					reportsData={fourWeeksDetails}
					showLegend={true}
					maxValueRecord={maxValueRecord}
					colors={CHART_COLOR}
					labels={[t('meLabel'), t('myTeamsMemberLabel'), t('orgMembersLabel')]}
					toolTipPostfix={'%'}
					//xAxisText={'Weeks'}
					//yAxisText={'Engagement'}
					chartHight={'210'}
					isEngagement={false}
					isDashboard={true}
				/>
			</Box>
		</>
	);
};
