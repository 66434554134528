import React, { useState, useEffect, useRef } from 'react';
import {
	Box,
	InputAdornment,
	List,
	ListItem,
	TextField,
	Typography,
	TablePagination,
	Tooltip,
	Chip,
	Avatar,
	Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { OKRButton } from '../../Common/OKRButton';
import { AddIconSvg, SearchIcon, DraftIcon, MissionVisionIcon, MOMIcon } from '../../../config/svg/CommonSvg';
import { RequestOneOnOneIcon2 } from '../../../config/svg/PersonalFeedbackSvg';
import '../../../styles/pages/engagement/one-on-one.scss';
import { FilterBox } from './FilterBox';
import { CrossIcon, SortingIcon } from '../../../config/svg/CommonSvgIcon';
import { MeetingList } from './MeetingList';
import LinearLoader from '../../Common/Loader';
import { MainDrawer } from '../../Admin/CommonComponent/MainDrawer';
import { RequestOneOnOneForm } from './RequestOneOnOneForm';
import { LaunchTemplate } from './LaunchTemplate';
import { useDispatch, useSelector } from 'react-redux';
import {
	completedOneOneOneTask,
	createNotesForOneOnOne,
	createOneOneRequest,
	createTaskForOneOnOne,
	deleteOneOneRequest,
	deleteTaskForOneOnOne,
	getNotesList,
	getOneOnOneList,
	getOneOnOneListDirect,
	getOneOnOneTeamDetails,
	getTasksList,
	launchDateOneOnOneRequest,
	launchOneOnOneData,
	updatedNotesForOneOnOne,
	updatedTaskForOneOnOne,
	updateOneOneRequest,
} from '../../../action/oneOnOneRequest';
import { Enums } from '../../../config/enums';
import { UserSearchListPopover } from '../../Common/UserSearchListPopover';
import { getLocalStorageItem, removeLocalStorageItem } from '../../../services/StorageService';
import {
	formatOneOnOneRequestDate,
	getUserDetails,
	getUserName,
	getFullName,
	checkUnSavePopUpVisible,
	createUUID,
	urlPatternValidation,
	combineDateTime,
	convertDateTimeToUTC,
	convertDateTimeToUserTimezone,
} from '../../../config/utils';
import AlertDialog from '../../Common/Dialog';
import { RequestColorIcon, ResendRequestColorIcon } from '../../../config/svg/AssessmentSvg';
import { BackArrow } from '../../../config/svg/GlobalSvg';
import { TeamListRightPanel } from './TeamListRightPanel';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import UserImage from '../../../images/my-user-bg.svg';
import NoTeamMemberImg from '../../../images/no-direct-reportees.svg';
import { globalSearchAPIWithTeam } from '../../../action/search';
import moment from 'moment';
import { isOneOnOneRefresh } from '../../../action/signalR';
import { MomDrawer } from './MomDrawer';
import { UpArrowIcon } from '../../../config/svg/ArrowSvg';
import { getMonthDateYear, generateDefaultTimeOptions } from '../../../config/utils';
import { getAllGlobalTask } from '../../../action/globalTask';
import MeetingNotesDownload from './MeetingNotesDownload';
import { getFeedbackOneToOneSetting } from '../../../action/adminPerformanceDevelopment';
import { addFeedbackPermission } from '../../../action/personalFeedback';

export const Request1on1 = (props: any) => {
	const { tabSelected } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { isOneOnOneRefreshValue, isDashboardRefreshValue, isRecognizeRefreshValue } = useSelector(
		(state: any) => state.signalRReducer
	);
	const { isCreateLinkPopupEnable, createLinkPopupDetail } = useSelector((state: any) => state.globalTaskReducer);

	const currentUser = getLocalStorageItem('currentUser') ? JSON.parse(getLocalStorageItem('currentUser') || '') : null;
	const userDetail = getUserDetails();
	const isDirectCall = getLocalStorageItem('requestOneOnOneRedirect')
		? JSON.parse(getLocalStorageItem('requestOneOnOneRedirect') || '')
		: null;
	const staticTitleText = '1-on-1 Meeting';

	const [loader, setLoader] = useState<boolean>(false);
	const [requestOneOnOneDrawer, setRequestOneOnOneDrawer] = useState<any>({ open: false, type: '' });
	const [modalProps, setModalProps] = useState<any>({ open: false, type: '', message: '', details: '' });

	const defaultTimeOptions = generateDefaultTimeOptions();
	const staticData = {
		requestId: 0,
		requestedTo: 0,
		onetoOneRemark: '',
		oneToOneTitle: staticTitleText,
		isQuickLaunch: false,
		selectedUser: [],
		meetingLink: '',
		meetingStartDate: new Date(),
		meetingEndDate: new Date(),
		meetingStartTime: defaultTimeOptions[0],
		meetingEndTime: defaultTimeOptions[1],
		natureOfMeeting: Enums.ONE,
	};
	const staticErrorObject = {
		error: '',
		helperText: '',
		type: '',
	};

	const [filterBoxData, setFilterBoxData] = useState<any>({ completed: 0, notCompleted: 0 });
	const [formData, setFormData] = useState<any>(staticData);
	const [formDataUpdated, setFormDataUpdated] = useState<any>(false);
	const [isFormEdited, setIsFormEdited] = useState<any>(false);
	const [oneToOneTitleError, setOneToOneTitleError] = useState<any>(staticErrorObject);
	const [oneToOneAgendaError, setOneToOneAgendaError] = useState<any>(staticErrorObject);
	const [oneToOneUserError, setOneToOneUserError] = useState<any>(staticErrorObject);
	const [oneToOneTitleOnLaunchError, setOneToOneTitleLaunchError] = useState<any>(staticErrorObject);
	const [oneToOneMeetingLinkError, setOneToOneMeetingLinkError] = useState<any>(staticErrorObject);
	const [oneToOneMeetingDateError, setOneToOneMeetingDateError] = useState<any>(staticErrorObject);

	const [oneOnOneList, setOneOnOneList] = useState<any>(null);
	const [totalUser, setTotalUser] = useState(0);
	const [pageIndex, setPageIndex] = useState<any>(0);
	const [pageSize, setPageSize] = useState<any>(Enums.TEN);
	const [sortOrder, setSortOrder] = useState<string>('');
	const [sortingColumn, setSortingColumn] = useState<string>('');
	const [searchTextArray, setSearchTextArray] = useState<any>([]);
	const [searchValue, setSearchValue] = useState('');
	const [openUserSearch, setOpenUserSearch] = useState<any>(false);
	const [anchorElUserSearch, setAnchorElUserSearch] = useState(null);

	const [isLaunchFormEdited, setIsLaunchFormEdited] = useState<any>(false);
	const [prevIsLaunchFormEdited, setPrevIsLaunchFormEdited] = useState<any>(false);
	const [openLaunchForm, setOpenLaunchForm] = useState<any>({ open: false, type: '', details: '' });
	const [oneOnOneTeamList, setOneOnOneTeamList] = useState<any>(null);
	const [boxSelected, setBoxSelected] = useState<boolean>(false);
	const [noShowAddIcon, setNoShowAddIcon] = useState<boolean>(false);
	const [activeTabBox, setActiveTabBox] = useState<string>('all');
	const [copiedTaskList, setCopiedTaskList] = useState<any>([]);
	const [momHeaderTitle, setMomHeaderTitle] = useState<string>('');
	const [isCreateLinkPopupOpen, setIsCreateLinkPopupOpen] = useState<boolean>(false);
	const [select1on1Tab, setSelect1on1Tab] = useState<any>(Enums.ONE);
	const [allTaskList, setAllTaskList] = useState<any>(null);
	const [OtherAllTaskList, setOtherAllTaskList] = useState<any>(null);
	const [addPermission, setAddPermission] = useState<boolean>(false);

	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	const ref2 = useRef<any>(null);
	const ref3 = useRef<any>(null);
	const ref4 = useRef<any>(null);

	const [momDrawer, setMomDrawer] = useState<boolean>(false);

	const { oneToOneSettings } = useSelector((state: any) => state?.adminPerformanceDevelopmentReducer);
	useEffect(() => {
		// if (checkSupportPermissionType(supportModulePerformAssessment)) {}
		if (!oneOnOneList) {
			getOneOnOneRefreshed();
		}
		if (!oneToOneSettings) {
			dispatch(getFeedbackOneToOneSetting());
		}
		getPermissionToAddFeedback();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isOneOnOneRefreshValue && tabSelected === Enums.THREE) {
			getOneOnOneRefreshed();
			dispatch(isOneOnOneRefresh(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOneOnOneRefreshValue]);

	useEffect(() => {
		setNoShowAddIcon(false);
	}, []);
	useEffect(() => {
		if (boxSelected || (searchTextArray && searchTextArray.length)) {
			setNoShowAddIcon(true);
		}
	}, [boxSelected, searchTextArray]);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLaunchFormEdited, isCreateLinkPopupOpen]);

	const getOneOnOneRefreshed = () => {
		setLoader(true);
		setPageIndex(1);
		setBoxSelected(false);
		setActiveTabBox('all');
		if (isDirectCall && isDirectCall?.redirectFrom) {
			if (isDirectCall?.redirectFrom === 'Dashboard') {
				removeLocalStorageItem('requestOneOnOneRedirect');
				setActiveTabBox('notCompleted');
				genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pageSize, 2, 0);
			} else if (isDirectCall?.redirectFrom === 'Assessment') {
				const directCallData = { ...isDirectCall };
				// employeeInfo, employeeId, redirectFrom === Assessment
				genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pageSize, 0, 0);
				getRedirectedEmployeeData(directCallData);
				removeLocalStorageItem('requestOneOnOneRedirect');
			}
		} else {
			genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pageSize, 0, 0);
		}
		getTeamDetails();
	};
	const getPermissionToAddFeedback = async () => {
		try {
			const response: any = await dispatch(addFeedbackPermission());
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entity } = response.data;
					setAddPermission(entity);
				} else {
				}
			}
		} catch (e) {
			console.error(e);
		}
	};
	const handleClickOutside = (event: any) => {
		if (ref3?.current?.contains(event?.target) || ref4?.current?.contains(event?.target)) {
			//
		} else {
			const id = createLinkPopupDetail ? document.getElementById(createLinkPopupDetail) : null;
			const taskLinkTextPopup = document.getElementById('taskLinkTextPopup');
			if (
				ref.current &&
				// (ref1.current?.contains(event.target) ||
				// !ref2.current?.contains(event.target) ||
				(!ref.current?.contains(event.target) || event.target.id === 'back-btn') &&
				checkUnSavePopUpVisible(event) &&
				isLaunchFormEdited &&
				!id?.contains(event.target) &&
				!taskLinkTextPopup?.contains(event.target)
			) {
				const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
				if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
					event.preventDefault();
					event.stopPropagation();
					setModalProps({
						open: true,
						type: 'unSaveChange',
						message: t('unSavedItemAlert'),
						details: null,
						event: event,
					});
				}
			} else {
			}
		}
	};

	const genericRequestOneOnOneGetCall = (
		pgIndex: number,
		sortColumnVal: string,
		sortOrderVal: string,
		searchTextArrayVal: any,
		pageSizeVal: number,
		statusVal: number,
		employeeId: number
	) => {
		getOneOnOneRequestListDetails({
			pageIndex: pgIndex || 1,
			sortingText: sortColumnVal || sortingColumn,
			order: sortOrderVal || sortOrder,
			finder: searchTextArrayVal || searchTextArray,
			pageSize: pageSizeVal || pageSize,
			employeeId: employeeId || 0, // userDetail?.employeeId
			statusId: statusVal || 0, // 0 for All, 1 for completed and 2 for not completed
		});
	};
	const getOneOnOneRequestListDetails = async (requestPayload: any) => {
		try {
			const response: any = await dispatch(getOneOnOneListDirect(requestPayload));
			if (response) {
				if (response && response.data && response.data.status === 200) {
					const { entity } = response.data;
					if (entity) {
						const { oneToOneDetails, completedCount, notCompletedCount, totalRecords } = entity || {};
						if (oneToOneDetails && oneToOneDetails.length) {
							oneToOneDetails.map((data: any) => {
								let localStartDateTime = convertDateTimeToUserTimezone(
									data.meetingStartDate || '',
									data.meetingStartTime || ''
								);
								let localEndDateTime = convertDateTimeToUserTimezone(
									data.meetingEndDate || '',
									data.meetingEndTime || ''
								);
								data.meetingStartDate = localStartDateTime[0] || staticData.meetingStartDate;
								data.meetingStartTime = localStartDateTime[1] || staticData.meetingStartTime;
								data.meetingEndDate = localEndDateTime[0] || staticData.meetingEndDate;
								data.meetingEndTime = localEndDateTime[1] || staticData.meetingEndTime;
							});
							setOneOnOneList(oneToOneDetails);
							// getTeamDetails();
						} else {
							setDefaultData(1);
						}
						setTotalUser(totalRecords || 0);
						setFilterBoxData({ completed: completedCount || 0, notCompleted: notCompletedCount || 0 });
					} else {
						setDefaultData(2);
					}
					setLoader(false);
				} else {
					setLoader(false);
					setDefaultData(2);
				}
			}
		} catch (e) {
			setLoader(false);
			setDefaultData(2);
			console.error(e);
		}
	};
	const setDefaultData = (type: number) => {
		setOneOnOneList([]);
		if (type === 2) {
			setTotalUser(0);
			setFilterBoxData({ completed: 0, notCompleted: 0 });
		}
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		setLoader(true);

		genericRequestOneOnOneGetCall(1, column, newSortOrder, searchTextArray, pageSize, 0, 0);
		setSortingColumn(column);
	};
	const handleChangePage = (event: any, newPage: number) => {
		newPage = newPage + 1;
		setPageIndex(newPage);
		setLoader(true);

		genericRequestOneOnOneGetCall(newPage, sortingColumn, sortOrder, searchTextArray, pageSize, 0, 0);
	};
	const handleChangeRowsPerPage = async (event: any) => {
		const pSize = event.target.value;
		setPageSize(pSize);
		setPageIndex(1);
		setLoader(true);
		genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pSize, 0, 0);
	};
	const handleUserOpen = (event: any) => {
		setAnchorElUserSearch(event.currentTarget);
		setOpenUserSearch(true);
	};
	const handleUserClose = () => {
		setOpenUserSearch(false);
		setAnchorElUserSearch(null);
	};
	const handleChange = (e: any) => {
		setSearchValue(e.target.value);
	};
	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleSearch(e.target.value, 0, 'Direct');
			}
			setSearchValue('');
		}
	};
	const handleSearch = (value: any, empId: number, type: string) => {
		const isExist = searchTextArray.find((item: any) => item === value);
		let updatedValue = isExist ? [...searchTextArray] : [...searchTextArray, value];
		if (type === 'TeamList') {
			updatedValue = [value];
		}
		setPageIndex(1);
		setSearchTextArray(updatedValue);
		setLoader(true);

		genericRequestOneOnOneGetCall(1, '', '', updatedValue, pageSize, 0, empId || 0);
	};
	const clearSearch = (searchText: any) => {
		if (searchText === 'all') {
			setPageIndex(1);
			setSearchTextArray([]);
			setLoader(true);

			genericRequestOneOnOneGetCall(1, '', '', [], pageSize, 0, 0);
		} else {
			const newArray = searchTextArray.filter((item: any) => item !== searchText);
			setPageIndex(1);
			setSearchTextArray(newArray);
			setLoader(true);
			genericRequestOneOnOneGetCall(1, '', '', newArray, pageSize, 0, 0);
		}
	};
	const handleClickRequestOneOnOneEdit = (event: any, record: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Edit', details: record });
	};
	const handleClickRequestOneOnOneCreate = (event: any) => {
		setFormData(staticData);
		setRequestOneOnOneDrawer({ open: true, type: 'Add' });
	};
	const handleRequestOneOnOneCall = (event: any, empRecord: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Direct', details: '', empInfo: empRecord });
	};
	const handleQuickLaunchCall = (event: any, empRecord: any) => {
		const requestPayload = {
			...formData,
			requestId: 0,
			requestedTo: empRecord?.employeeId || 0,
			isQuickLaunch: true,
			selectedUser: [empRecord],
		};

		handleSubmitOneOnOneRequest('launch', requestPayload, 'Direct');
	};
	const handleDrawerClose = (event: any, type: string) => {
		event.preventDefault();
		if (type === 'save') {
			const requestPayload = { ...formData, isQuickLaunch: false };
			setOneToOneTitleError(staticErrorObject);
			setOneToOneUserError(staticErrorObject);
			setOneToOneMeetingLinkError(staticErrorObject);
			setOneToOneMeetingDateError(staticErrorObject);
			handleSubmitOneOnOneRequest(type, requestPayload, requestOneOnOneDrawer?.type);
			setIsFormEdited(false);
		} else if (type === 'launch') {
			const requestPayload = { ...formData, isQuickLaunch: true };
			// setModalProps({
			// 	open: true,
			// 	type: 'drawerStartLaunch',
			// 	message: t('alertLaunchStart'),
			// 		details: { type: type, requestPayload: requestPayload, requestType: requestOneOnOneDrawer?.type },
			// });
			setModalProps({ open: false, type: '', message: '', details: '' });
			setOneToOneTitleError(staticErrorObject);
			setOneToOneMeetingLinkError(staticErrorObject);
			setOneToOneMeetingDateError(staticErrorObject);
			setOneToOneUserError(staticErrorObject);
			handleSubmitOneOnOneRequest(type, requestPayload, requestOneOnOneDrawer?.type);
			setIsFormEdited(false);
			// ============================
			// setOneToOneTitleError(staticErrorObject);
			// setOneToOneUserError(staticErrorObject);
			// handleSubmitOneOnOneRequest(type, requestPayload, requestOneOnOneDrawer?.type);
			// setIsFormEdited(false);
		} else {
			if (isFormEdited) {
				setModalProps({
					open: true,
					type: 'unSaveChangeOneOnOne',
					message: t('unSavedItemAlert'),
					details: null,
				});
			} else {
				setOneToOneUserError(staticErrorObject);
				setOneToOneTitleError(staticErrorObject);
				setOneToOneMeetingLinkError(staticErrorObject);
				setOneToOneMeetingDateError(staticErrorObject);
				setOneToOneTitleLaunchError(staticErrorObject);
				setRequestOneOnOneDrawer({ open: false, type: '' });
				setIsFormEdited(false);
			}
		}
	};
	const validateDetails = (requestPayload: any) => {
		if (requestOneOnOneDrawer?.type !== 'Edit') {
			const employeeId =
				requestPayload?.selectedUser.length > 0
					? requestPayload?.selectedUser.find((user: any) => user.employeeId)?.employeeId
					: null;

			requestPayload.requestedTo = requestPayload.requestedTo || employeeId;
			if (!requestPayload?.requestedTo) {
				setOneToOneUserError({
					error: true,
					helperText: 'Please select a user',
					type: 'requestedTo',
				});
				moveScrollToBox('box-oneToOneUser');
				return false;
			}
		}

		if (!requestPayload?.oneToOneTitle) {
			setOneToOneTitleError({
				error: true,
				helperText: 'Please enter title',
				type: 'oneToOneTitle',
			});
			moveScrollToBox('box-oneToOneTitle');
			return false;
		}
		if (!requestPayload?.meetingStartTime || !requestPayload?.meetingEndTime) {
			setOneToOneMeetingDateError({
				error: true,
				helperText: 'Please enter valid meeting date or time',
				type: 'meetingEndTime',
			});
			moveScrollToBox('box-oneToOneMeetingDate');
			return false;
		}
		const startDateTime = combineDateTime(requestPayload?.meetingStartDate, requestPayload?.meetingStartTime);
		const endDateTime = combineDateTime(requestPayload?.meetingEndDate, requestPayload?.meetingEndTime);
		if (endDateTime <= startDateTime) {
			setOneToOneMeetingDateError({
				error: true,
				helperText: 'Please enter valid meeting date or time',
				type: 'meetingEndTime',
			});
			moveScrollToBox('box-oneToOneMeetingDate');
			return false;
		}
		if (requestPayload?.meetingLink && !urlPatternValidation(requestPayload?.meetingLink)) {
			setOneToOneMeetingLinkError({
				error: true,
				helperText: 'Please enter valid meeting link',
				type: 'oneToOneMeetingLink',
			});
			moveScrollToBox('box-oneToOneMeetingLink');
			return false;
		}
		let remarkText = requestPayload.onetoOneRemark.trim();
		if (
			oneToOneSettings?.isOneToOneAgendaRequired &&
			(!remarkText ||
				remarkText.trim() === '' ||
				remarkText.trim() === '<p></p>' ||
				remarkText.trim() === '<p><br></p>')
		) {
			setOneToOneAgendaError({
				error: true,
				helperText: 'Please enter Agenda',
				type: 'oneToOneAgenda',
			});
			moveScrollToBox('box-requestOneOnOneAgenda');
			return false;
		} else {
			return true;
		}
	};
	const handleSubmitOneOnOneRequest = async (type: string, requestPayload: any, requestType: string) => {
		if (!requestPayload?.oneToOneTitle) {
			requestPayload.oneToOneTitle = staticTitleText;
		}
		if (validateDetails(requestPayload)) {
			setLoader(true);
			// const requestType = requestOneOnOneDrawer?.type;
			// const requestPayload = { ...formData };
			delete requestPayload?.selectedUser;
			delete requestPayload?.isError;
			requestPayload.meetingLink =
				requestPayload?.natureOfMeeting === Enums.ONE ? requestPayload?.meetingLink || '' : '';
			let response: any = null;

			let meetingStartDateTime = convertDateTimeToUTC(requestPayload.meetingStartDate, requestPayload.meetingStartTime);
			let meetingEndDateTime = convertDateTimeToUTC(requestPayload.meetingEndDate, requestPayload.meetingEndTime);
			requestPayload.meetingStartDate = meetingStartDateTime[0] || '';
			requestPayload.meetingStartTime = meetingStartDateTime[1] || '';
			requestPayload.meetingEndDate = meetingEndDateTime[0] || '';
			requestPayload.meetingEndTime = meetingEndDateTime[1] || '';
			if (requestType === 'Add' || requestType === 'Direct') {
				response = await dispatch(createOneOneRequest(requestPayload));
				handleAPIResponse(response, requestType, type);
			} else if (requestType === 'Edit') {
				response = await dispatch(updateOneOneRequest(requestPayload));
				handleAPIResponse(response, requestType, type);
			} else {
				setFormData(staticData);
				setLoader(false);
				console.error('No RequestType Found');
			}
		}
	};
	const getEmployeeId = (type: number) => {
		return type === Enums.ONE
			? userDetail?.employeeId || Enums.ZERO
			: type === Enums.TWO
			? openLaunchForm?.details?.requestedTo !== userDetail?.employeeId
				? openLaunchForm?.details?.requestedTo
				: openLaunchForm?.details?.requestedFrom || Enums.ZERO
			: Enums.ZERO;
	};
	const handleAPIResponse = async (response: any, requestType: string, type: string) => {
		if (response) {
			if (response?.data && response?.data?.status === 200) {
				const { entity } = response?.data || {};

				let localStartDateTime = convertDateTimeToUserTimezone(
					entity.meetingStartDate || '',
					entity.meetingStartTime || ''
				);
				let localEndDateTime = convertDateTimeToUserTimezone(entity.meetingEndDate || '', entity.meetingEndTime || '');
				entity.meetingStartDate = localStartDateTime[0] || staticData.meetingStartDate;
				entity.meetingStartTime = localStartDateTime[1] || staticData.meetingStartTime;
				entity.meetingEndDate = localEndDateTime[0] || staticData.meetingEndDate;
				entity.meetingEndTime = localEndDateTime[1] || staticData.meetingEndTime;

				// const { messageList } = response.data;
				// const keys = Object.keys(messageList);
				// const messages = keys.map((item) => messageList[item]);

				// Make Get List API call
				// genericRequestOneOnOneGetCall(1, '', '', [], pageSize, 0, 0);
				// setRequestOneOnOneDrawer({ open: false, type: '' });
				// setFormData(staticData);
				if (type === 'launch') {
					setOpenLaunchForm({ open: false, type: '', details: null });
					handleDirectLaunchClick(type, entity, requestType);
				} else {
					//=================================================================
					if (requestType === 'Add' || requestType === 'Direct') {
						genericRequestOneOnOneGetCall(1, '', '', [], pageSize, 0, 0);
						setRequestOneOnOneDrawer({ open: false, type: '' });
						setFormData(staticData);
						setOpenLaunchForm({ open: false, type: '', details: null });
					} else {
						const details = await getNotesAndTaskDetails(entity);
						setOpenLaunchForm({
							open: true,
							type: 'LaunchEdit',
							details: { ...details, isTaskAddUpdated: false },
						});

						const defaultTaskListRequest = {
							isMyTask: true,
							isCompleted: false,
							pageSize: Enums.MAX_USER_SIZE,
							pageIndex: Enums.ONE,
							order: '',
							sortingText: '',
							finder: [],
							taskFilter: {
								dueDate: [],
								taskType: [],
								priority: [],
							},
							startDate: null,
							endDate: null,
							taskGroupById: Enums.ZERO,
							taskGroupByType: Enums.ZERO,
							moduleId: Enums.THREE,
							employeeId: Enums.ZERO,
							moduleItemKeyId: Enums.ZERO,
						};
						const data = {
							...defaultTaskListRequest,
							moduleItemKeyId: details?.oneToOneDetailId || Enums.ZERO,
							employeeId: getEmployeeId(select1on1Tab),
							isMyTask: true,
						};
						dispatch(getAllGlobalTask(data, false));

						// const message =
						// 	requestType === 'Add' || requestType === 'Direct'
						// 		? '1-on-1 request has been sent successfully'
						// 		: 'Updated 1-on-1 request has been sent successfully';
						enqueueSnackbar(t('oneOnOneMeetingUpdate'), {
							variant: 'success',
							autoHideDuration: 3000,
						});
					}
					//=================================================================
					setLoader(false);
				}
				setCopiedTaskList([]);
			} else {
				setLoader(false);
				// const { messageList } = response.data;
				// const keys = Object.keys(messageList);
				// const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(
					`Error occurred while ${requestType === 'Add' || requestType === 'Direct' ? 'sending' : 'updating'} 1-on-1`,
					{
						variant: 'error',
						autoHideDuration: 3000,
					}
				);
			}
		} else {
			setLoader(false);
			setFormData(staticData);
			enqueueSnackbar(
				`Error occurred while ${requestType === 'Add' || requestType === 'Direct' ? 'sending' : 'updating'} 1-on-1`,
				{
					variant: 'error',
					autoHideDuration: 3000,
				}
			);
		}
	};
	const handleDirectLaunchClick = async (type: string, requestInfo: any, requestType: string) => {
		const requestPayload = { ...requestInfo };
		if (requestPayload?.oneToOneDetailId) {
			const reqParams = `requestId=${requestPayload?.oneToOneDetailId}`;
			const resp: any = await dispatch(launchOneOnOneData(reqParams));
			if (resp && resp?.data) {
				const { entity } = resp?.data || {};
				let localStartDateTime = convertDateTimeToUserTimezone(
					entity.meetingStartDate || '',
					entity.meetingStartTime || ''
				);
				let localEndDateTime = convertDateTimeToUserTimezone(entity.meetingEndDate || '', entity.meetingEndTime || '');
				entity.meetingStartDate = localStartDateTime[0] || staticData.meetingStartDate;
				entity.meetingStartTime = localStartDateTime[1] || staticData.meetingStartTime;
				entity.meetingEndDate = localEndDateTime[0] || staticData.meetingEndDate;
				entity.meetingEndTime = localEndDateTime[1] || staticData.meetingEndTime;

				setLoader(false);
				if (requestType === 'Direct' || type === 'launch') {
					entity && directLaunchRequestOneOnOne(entity);
				} else {
					entity && handleClickRequestOneOnOneLaunch({}, entity);
				}
			} else {
				setLoader(false);
			}
		} else {
			setRequestOneOnOneDrawer({ open: false, type: '' });
			setFormData(staticData);
			setLoader(false);
			console.error('No RequestType Found');
		}
	};

	const handleBoxClick = (type: string) => {
		// 0 for All, 1 for completed and 2 for not completed
		setSearchTextArray([]);
		if (activeTabBox === type) {
			setBoxSelected(false);
			setActiveTabBox('All');
			setLoader(true);
			genericRequestOneOnOneGetCall(1, '', '', [], pageSize, 0, 0);
		} else {
			if (type === 'completed') {
				setActiveTabBox(type);
				setLoader(true);
				genericRequestOneOnOneGetCall(1, '', '', [], pageSize, 1, 0);
			} else if (type === 'notCompleted') {
				setActiveTabBox(type);
				setLoader(true);
				genericRequestOneOnOneGetCall(1, '', '', [], pageSize, 2, 0);
			}
			setBoxSelected(true);
		}
	};
	const handleBackButtonOnClick = (event: any) => {
		if (isLaunchFormEdited) {
			setModalProps({
				open: true,
				type: 'unSaveChange',
				message: t('unSavedItemAlert'),
				details: null,
				event: event,
			});
		} else {
			getOneOnOneListOnBackClick();
			setCopiedTaskList([]);
			setOpenLaunchForm({ open: false, type: '', details: null });
			setIsLaunchFormEdited(false);
			setSelect1on1Tab(Enums.ONE);

			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	const getNotesAndTaskDetails = async (record: any) => {
		let details = { ...record, notesDetails: null, tasksDetails: null };
		const reqParams = `goalId=${record?.oneToOneDetailId}&goalTypeId=3&pageIndex=1&pageSize=999`;
		const tasksResp: any = null;
		const [notesResp]: any = await Promise.all([dispatch(getNotesList(reqParams))]);
		if (notesResp && notesResp?.data && notesResp?.data?.status === 200) {
			const notesEntityList = notesResp?.data?.entityList || [];
			const requestedFromNotes =
				notesEntityList && notesEntityList.length > 0
					? notesEntityList.filter((item: any) => item.createdBy === record?.requestedFrom)
					: [];
			const requestedToNotes =
				notesEntityList && notesEntityList.length > 0
					? notesEntityList.filter((item: any) => item.createdBy === record?.requestedTo)
					: [];
			setLoader(false);
			details = {
				...details,
				notesDetails: {
					myNotes: record?.isReciver ? requestedToNotes : requestedFromNotes,
					otherNotes: record?.isReciver ? requestedFromNotes : requestedToNotes,
				},
				tasksDetails: null,
			};
		}
		if (tasksResp && tasksResp?.data && tasksResp?.data?.status === 200) {
			const tasksEntityList = tasksResp?.data?.entityList || [];
			let requestedFromTasks =
				tasksEntityList && tasksEntityList.length > 0
					? tasksEntityList.filter((item: any) => item.createdBy === record?.requestedFrom)
					: [];
			requestedFromTasks = requestedFromTasks.map((rec: any) => {
				return {
					...rec,
					momentDueDate: moment(new Date(rec?.dueDate)),
					historyDate: rec?.dueDate || null,
					history: rec?.taskName || '',
					historyAssignedBy: rec?.assignedBy,
				};
			});
			let requestedToTasks =
				tasksEntityList && tasksEntityList.length > 0
					? tasksEntityList.filter((item: any) => item.createdBy === record?.requestedTo)
					: [];
			requestedToTasks = requestedToTasks.map((rec: any) => {
				return {
					...rec,
					momentDueDate: moment(new Date(rec?.dueDate)),
					historyDate: rec?.dueDate || null,
					history: rec?.taskName || '',
					historyAssignedBy: rec?.assignedBy,
				};
			});

			setLoader(false);
			details = {
				...details,
				tasksDetails: {
					myTasks: record?.isReciver ? requestedToTasks : requestedFromTasks,
					otherTasks: record?.isReciver ? requestedFromTasks : requestedToTasks,
				},
			};
		}

		return details;
	};
	const handleClickRequestOneOnOneLaunch = async (e: any, record: any) => {
		setModalProps({ open: false, type: '', message: '', details: '' });
		try {
			if (record && record?.oneToOneDetailId) {
				setLoader(true);
				const requestParams = `requestId=${record?.oneToOneDetailId}`;
				const response: any = await dispatch(launchDateOneOnOneRequest(requestParams));
				if (response && response?.data && response?.data?.status === 200) {
					directLaunchRequestOneOnOne(record);
				} else {
					setLoader(false);
					enqueueSnackbar('Error occurred while launching request 1-on-1', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			}
		} catch (error) {
			console.error(error);
			setOpenLaunchForm({
				open: true,
				type: 'Launch',
				details: { ...record, isLaunched: true, notesDetails: null, tasksDetails: null, isTaskAddUpdated: false },
			});
		}
	};
	const directLaunchRequestOneOnOne = async (record: any) => {
		try {
			setLoader(true);
			const details = await getNotesAndTaskDetails(record);
			setLoader(false);
			setOpenLaunchForm({
				open: true,
				type: 'Launch',
				details: { ...details, isTaskAddUpdated: false },
			});
			setRequestOneOnOneDrawer({ open: false, type: '' });
			setFormData(staticData);
		} catch (error) {
			console.error(error);
			setOpenLaunchForm({
				open: true,
				type: 'Launch',
				details: { ...record, notesDetails: null, tasksDetails: null, isTaskAddUpdated: false },
			});
		}
	};
	const handleClickStartLaunch = (e: any, record: any) => {
		// setModalProps({
		// 	open: true,
		// 	type: 'requestStartLaunch',
		// 	message: t('alertLaunchStart'),
		// 	details: record,
		// });
		record && handleClickRequestOneOnOneLaunch(e, record);
	};
	const handleClickEditLaunch = (e: any, record: any) => {
		// setModalProps({
		// 	open: true,
		// 	type: 'requestEditLaunch',
		// 	message: t('alertLaunchEdit'),
		// 	details: record,
		// });
		record && handleClickRequestOneOnOneEditLaunch(e, record);
	};
	const handleClickRequestOneOnOneEditLaunch = async (e: any, record: any) => {
		setModalProps({ open: false, type: '', message: '', details: '' });
		try {
			setLoader(true);
			const details = await getNotesAndTaskDetails(record);
			setLoader(false);
			setOpenLaunchForm({
				open: true,
				type: 'LaunchEdit',
				details: { ...details, isTaskAddUpdated: false },
			});
		} catch (error) {
			console.error(error);
			setOpenLaunchForm({
				open: true,
				type: 'LaunchEdit',
				details: { ...record, notesDetails: null, tasksDetails: null, isTaskAddUpdated: false },
			});
		}
	};
	const handleEmployeeClick = (e: any, record: any) => {
		const value = getFullName(record); // ?.firstName || '';
		const empId = 0; // record?.employeeId || 0;
		value && handleSearch(value, empId, 'TeamList');
	};
	const handleClickRequestOneOnOneDelete = (e: any, record: any) => {
		if (record && record?.oneToOneDetailId) {
			setModalProps({
				open: true,
				type: 'requestDelete',
				message: 'Are you sure you want to delete the scheduled 1-on-1?',
				details: record,
			});
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'requestDelete') {
				setLoader(true);
				callDeleteOneOnOneAPI();
			} else if (modalProps?.type === 'unSaveChange') {
				setOneToOneTitleLaunchError(staticErrorObject);

				getOneOnOneListOnBackClick();
				setOpenLaunchForm({ open: false, type: '', details: null });
				setCopiedTaskList([]);
				setSelect1on1Tab(Enums.ONE);
				// setIsLaunchFormEdited(false);
				await setIsLaunchFormEdited(false);
				const { target } = modalProps?.event || {};
				if (target) {
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}

				setModalProps({ open: false, type: '', message: '', details: '' });
			} else if (modalProps?.type === 'unSaveChangeOneOnOne') {
				setOneToOneUserError(staticErrorObject);
				setOneToOneTitleError(staticErrorObject);
				setOneToOneMeetingLinkError(staticErrorObject);
				setOneToOneMeetingDateError(staticErrorObject);
				setRequestOneOnOneDrawer({ open: false, type: '' });
				setIsFormEdited(false);

				setModalProps({ open: false, type: '', message: '', details: '' });
			} else if (modalProps?.type === 'requestEditLaunch') {
				handleClickRequestOneOnOneEditLaunch(e, modalProps?.details);
			} else if (modalProps?.type === 'requestStartLaunch') {
				handleClickRequestOneOnOneLaunch(e, modalProps?.details);
			} else if (modalProps?.type === 'drawerStartLaunch') {
				setModalProps({ open: false, type: '', message: '', details: '' });
				setOneToOneTitleError(staticErrorObject);
				setOneToOneMeetingLinkError(staticErrorObject);
				setOneToOneMeetingDateError(staticErrorObject);
				setOneToOneUserError(staticErrorObject);
				handleSubmitOneOnOneRequest(
					modalProps?.details.type,
					modalProps?.details.requestPayload,
					modalProps?.details.requestType
				);
				setIsFormEdited(false);
			}
		} else {
			setOneToOneUserError(staticErrorObject);
			setOneToOneTitleError(staticErrorObject);
			setOneToOneMeetingLinkError(staticErrorObject);
			setOneToOneMeetingDateError(staticErrorObject);
			setOneToOneTitleLaunchError(staticErrorObject);

			setModalProps({ open: false, type: '', message: '', details: '' });
		}
	};
	const callDeleteOneOnOneAPI = async () => {
		const { details } = modalProps;
		if (details && details?.oneToOneDetailId) {
			setModalProps({ open: false, type: '', message: '', details: '' });
			const requestParams = `requestId=${details?.oneToOneDetailId}`;
			const response: any = await dispatch(deleteOneOneRequest(requestParams));
			if (response && response?.data && response?.data?.status === 200) {
				setLoader(false);
				enqueueSnackbar('Request 1-on-1 deleted successfully', {
					variant: 'success',
					autoHideDuration: 3000,
				});

				genericRequestOneOnOneGetCall(pageIndex, sortingColumn, sortOrder, searchTextArray, pageSize, 0, 0);
			} else {
				setLoader(false);
				enqueueSnackbar('Error occurred while deleting request 1-on-1', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		}
	};
	const moveScrollToBox = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const updateTaskList = (updatedTaskListings: any[], tabType: number) => {
		if (tabType === Enums.ONE) {
			setAllTaskList([...updatedTaskListings]);
		}
		if (tabType === Enums.TWO) {
			setOtherAllTaskList([...updatedTaskListings]);
		}
	};
	const resetHighlightOneOnOneTask = () => {
		const tasksCopy = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		tasksCopy?.forEach((taskVal: any) => {
			taskVal.isRecentlyAdded = false;
		});

		setAllTaskList([...tasksCopy]);
	};
	const handleSaveLaunchEditRequestAPICall = async (e: any, requestPayload: any) => {
		e.preventDefault();
		setLoader(true);
		setIsLaunchFormEdited(false);
		const taskCreateRequests: any = [];
		const noteCreateRequests: any = [];
		let myTasks: any[] = [];
		let isTaskChanged = false;

		if (select1on1Tab === Enums.ONE) {
			myTasks = allTaskList && allTaskList?.length ? [...allTaskList] : [];
		} else if (select1on1Tab === Enums.TWO) {
			myTasks = OtherAllTaskList && OtherAllTaskList?.length ? [...OtherAllTaskList] : [];
		}
		if (requestPayload && myTasks && myTasks.length) {
			// Removed Due Date Validation for copy task
			/*const isDueDateRec = myTasks.find((rec: any) => !rec?.dueDate);
				const errorTaskIndex = myTasks.findIndex((rec: any) => !rec?.dueDate);
				if (isDueDateRec && !isDueDateRec?.dueDate) {
					const eleId = `due_date_Copy_Error_${isDueDateRec?.taskId}_${errorTaskIndex}`;
					const documentEle = document.getElementById(eleId);
					if (documentEle) {
						documentEle.style.display = '';
					}
					setLoader(false);
					moveScrollToBox('my_task_list');
					return;
				}*/

			myTasks?.forEach((task: any) => {
				if (task.isTaskChanged || task?.isTaskAddedNew) {
					isTaskChanged = true;
				}
				let formTaskLink: any[] = [];
				task?.link?.forEach((linkArray: any) => {
					formTaskLink.push(linkArray.link);
				});
				const newTask = {
					// taskId: task?.isTaskAddedNew ? Enums.ZERO : task?.taskId,
					taskId: task?.isTaskAddedNew ? Enums.ZERO : task?.taskId,
					taskName: task?.taskName ? task?.taskName.trim() : '',
					dueDate: task?.dueDate ? formatOneOnOneRequestDate(task?.dueDate) : null,
					goalTypeId: task?.goalTypeId || Enums.THREE,
					goalId: task?.goalId || Enums.ZERO,
					taskType: task?.taskTypeId || Enums.THREE,
					isCompleted: task?.isCompleted || false,
					// assignedTo: task?.assignedTo || Enums.ZERO,
					priority: task?.priorityId || Enums.ONE,
					assignedTo:
						task?.assignedBy?.employeeId === task?.assignedTo?.employeeId
							? Enums.ZERO
							: task?.assignedTo?.employeeId || Enums.ZERO,
					sourceMappingId: requestPayload?.oneToOneDetailId || task?.sourceMappingId,
					link: formTaskLink,
					isCheckedInLinked: task?.isCheckedInLinked || false,
				};
				taskCreateRequests.push(newTask);
			});
		}
		if (requestPayload && (requestPayload?.oneToOnePublicNotes || requestPayload?.publicNoteId)) {
			const publicNotesRequestPayload = {
				noteId: requestPayload?.publicNoteId || 0,
				description: requestPayload?.oneToOnePublicNotes || '',
				goalTypeId: 3,
				goalId: requestPayload?.oneToOneDetailId || 0,
				isPrivate: false,
			};
			noteCreateRequests.push(publicNotesRequestPayload);
		}
		if (requestPayload && (requestPayload?.oneToOnePrivateNotes || requestPayload?.privateNoteId)) {
			const privateNotesRequestPayload = {
				noteId: requestPayload?.privateNoteId || 0,
				description: requestPayload?.oneToOnePrivateNotes || '',
				goalTypeId: 3,
				goalId: requestPayload?.oneToOneDetailId || 0,
				isPrivate: true,
			};
			noteCreateRequests.push(privateNotesRequestPayload);
		}

		let isNoteAdded = false;
		const { myNotes } = openLaunchForm?.details?.notesDetails || {};
		const oneToOnePublicNotesData = myNotes && myNotes.length > 0 ? myNotes.find((item: any) => !item.isPrivate) : null;
		const oneToOnePrivateNotesData = myNotes && myNotes.length > 0 ? myNotes.find((item: any) => item.isPrivate) : null;
		if (!oneToOnePublicNotesData?.description && !requestPayload?.oneToOnePublicNotes) {
			isNoteAdded = false;
		} else if (!oneToOnePublicNotesData?.description && requestPayload?.oneToOnePublicNotes) {
			isNoteAdded = true;
		} else if (oneToOnePublicNotesData?.description !== requestPayload?.oneToOnePublicNotes) {
			isNoteAdded = true;
		}

		if (!isNoteAdded && !oneToOnePrivateNotesData?.description && !requestPayload?.oneToOnePrivateNotes) {
			isNoteAdded = false;
		} else if (!isNoteAdded && !oneToOnePrivateNotesData?.description && requestPayload?.oneToOnePrivateNotes) {
			isNoteAdded = true;
		} else if (!isNoteAdded && oneToOnePrivateNotesData?.description !== requestPayload?.oneToOnePrivateNotes) {
			isNoteAdded = true;
		}
		let meetingStartDateTime = convertDateTimeToUTC(requestPayload.meetingStartDate, requestPayload.meetingStartTime);
		let meetingEndDateTime = convertDateTimeToUTC(requestPayload.meetingEndDate, requestPayload.meetingEndTime);
		const requestOneOneUpdate = {
			requestId: requestPayload?.oneToOneDetailId || 0,
			onetoOneRemark: requestPayload?.oneToOneRemark || '',
			oneToOneTitle: requestPayload?.title || staticTitleText,
			isNoteAdded: isNoteAdded,
			isTaskChanged: isTaskChanged,
			meetingLink: requestPayload?.natureOfMeeting === Enums.ONE ? requestPayload?.meetingLink || '' : '',
			natureOfMeeting: requestPayload?.natureOfMeeting,
			taskCreateRequests,
			noteCreateRequests,
			meetingStartDate: meetingStartDateTime[0] || '',
			meetingStartTime: meetingStartDateTime[1] || '',
			meetingEndDate: meetingEndDateTime[0] || '',
			meetingEndTime: meetingEndDateTime[1] || '',
		};
		const response: any = await dispatch(updateOneOneRequest(requestOneOneUpdate));
		handleAPIResponse(response, 'Edit', 'save');
	};

	const getTeamDetails = async () => {
		const response: any = await dispatch(getOneOnOneTeamDetails());
		if (response && response?.data) {
			//  && response?.data?.status === 200
			const { entityList } = response?.data || {};
			if (entityList && entityList.length > 0) {
				setOneOnOneTeamList(entityList);
			} else {
				setOneOnOneTeamList([]);
			}
		}
	};
	const getRedirectedEmployeeData = async (details: any) => {
		setLoader(true);
		const { employeeId } = details || {};
		const resp: any = await dispatch(globalSearchAPIWithTeam(employeeId, 1, 999999, 1));
		setLoader(false);
		if (resp && resp?.data && resp?.data?.status === 200) {
			let list = resp?.data?.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			const employeeInfo = list && list.length ? list.find((rec: any) => rec?.employeeId === employeeId) : null;
			if (employeeInfo) {
				handleRequestOneOnOneCall({}, employeeInfo);
			} else {
				handleClickRequestOneOnOneCreate({});
			}
		} else {
			handleClickRequestOneOnOneCreate({});
		}
	};
	const getOneOnOneListOnBackClick = () => {
		if (activeTabBox === 'completed') {
			setLoader(true);
			genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pageSize, 1, 0);
		} else if (activeTabBox === 'notCompleted') {
			setLoader(true);
			genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pageSize, 2, 0);
		} else if (activeTabBox === 'all') {
			setLoader(true);
			genericRequestOneOnOneGetCall(1, sortingColumn, sortOrder, searchTextArray, pageSize, 0, 0);
		}
	};
	/** Task Related Methods Start */
	const addUpdateTask = async (requestPayload: any, isEdit: boolean, record: any) => {
		try {
			setLoader(true);
			let updatedRecord = { ...record };
			let updatedTasksDetails = null;
			let response: any = null;
			if (isEdit) {
				setOpenLaunchForm({
					...openLaunchForm,
					details: { ...openLaunchForm?.details, tasksDetails: null },
				});
			}
			if (isEdit) {
				const { tasksDetails } = record || {};
				if (tasksDetails) {
					const { myTasks, otherTasks } = tasksDetails || {};
					const updatedTasks =
						myTasks && myTasks.length
							? myTasks.map((item: any) => {
									let data = { ...item };
									if (data?.taskId === requestPayload?.taskId) {
										data = {
											...data,
											...requestPayload,
											dueDate: requestPayload?.dueDate ? new Date(requestPayload?.dueDate) : null,
											momentDueDate: requestPayload?.dueDate ? moment(new Date(requestPayload?.dueDate)) : null,
											isEdited: true,
										};
									}
									return { ...data, isEditMode: false, disabled: false };
							  })
							: myTasks;

					updatedTasksDetails = { myTasks: [...updatedTasks], otherTasks };
				}
				// 	response = await dispatch(updatedTaskForOneOnOne(requestPayload));
			} else {
				const extraParams = {
					taskId: createUUID(),
					isTaskAddedNew: true,
					isEdited: false,
					disabled: false,
					dueDate: requestPayload?.dueDate ? new Date(requestPayload?.dueDate) : null,
					momentDueDate: requestPayload?.dueDate ? moment(new Date(requestPayload?.dueDate)) : null,
				};
				const { tasksDetails } = record || {};
				if (tasksDetails) {
					const { myTasks, otherTasks } = tasksDetails || {};
					updatedTasksDetails = { myTasks: [...myTasks, { ...requestPayload, ...extraParams }], otherTasks };
				} else {
					updatedTasksDetails = { myTasks: [{ ...requestPayload, ...extraParams }], otherTasks: [] };
				}
				// 	response = await dispatch(createTaskForOneOnOne(requestPayload));
			}
			setLoader(false);
			updatedRecord = { ...updatedRecord, tasksDetails: updatedTasksDetails };
			setOpenLaunchForm({
				...openLaunchForm,
				details: { ...updatedRecord, isTaskAddUpdated: true },
			});
			if (Boolean(response) && response.data.status === 200) {
				const details: any = await getNotesAndTaskDetails(record);
				setOpenLaunchForm({
					...openLaunchForm,
					details: { ...openLaunchForm?.details, tasksDetails: null },
				});
				setOpenLaunchForm({
					...openLaunchForm,
					details: { ...details, isTaskAddUpdated: true },
				});
				// taskDeleted
				// enqueueSnackbar(isEdit ? t('taskUpdate') : t('taskAdded'), {
				// 	variant: 'success',
				// 	autoHideDuration: 3000,
				// });
				return true;
			} else if (Boolean(response)) {
				const responseAPI = response?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				return false;
			}
		} catch (error: any) {
			console.error(error);
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			return false;
		}
	};
	const handleCompleteTask = async (e: any, task: any, allTasks: any, record: any) => {
		try {
			setLoader(true);
			let updatedRecord = { ...record };
			let updatedTasksDetails = null;
			// const currentTasks = [...allTasks];
			const { checked } = e.target;

			// const updateTask = currentTasks.map((item: any) => {
			// 	if (item.taskId === task.taskId) {
			// 		return {
			// 			...item,
			// 			isCompleted: checked,
			// 			isUpdatingMode: true,
			// 		};
			// 	} else {
			// 		return {
			// 			...item,
			// 		};
			// 	}
			// });
			const { tasksDetails } = record || {};
			if (tasksDetails) {
				const { myTasks, otherTasks } = tasksDetails || {};
				const updatedTasks =
					myTasks && myTasks.length
						? myTasks.map((item: any) => {
								let data = { ...item };
								if (data?.taskId === task.taskId) {
									data = {
										...data,
										isCompleted: checked,
										dueDate: task?.dueDate ? new Date(task?.dueDate) : null,
										momentDueDate: task?.dueDate ? moment(new Date(task?.dueDate)) : null,
									};
								}
								return { ...data, isEditMode: false, disabled: false };
						  })
						: myTasks;

				updatedTasksDetails = { myTasks: [...updatedTasks], otherTasks };
			}
			updatedRecord = { ...updatedRecord, tasksDetails: updatedTasksDetails };
			setOpenLaunchForm({
				...openLaunchForm,
				details: { ...updatedRecord, isTaskAddUpdated: true },
			});

			// const postData = { taskId: task.taskId, isCompleted: checked };
			const taskCompleteResponse: any = null; // await dispatch(completedOneOneOneTask(postData));
			if (Boolean(taskCompleteResponse) && taskCompleteResponse?.data?.status === 200) {
				const details: any = await getNotesAndTaskDetails(record);
				setOpenLaunchForm({
					...openLaunchForm,
					details: { ...details, isTaskAddUpdated: true },
				});
				enqueueSnackbar(t('taskUpdate'), {
					variant: 'success',
					autoHideDuration: 3000,
				});
				setLoader(false);
			} else if (Boolean(taskCompleteResponse)) {
				const responseAPI = taskCompleteResponse?.data?.messageList || {};
				const keys = Object.keys(responseAPI);
				const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
				enqueueSnackbar(messages, {
					variant: 'error',
					autoHideDuration: 3000,
				});
				setLoader(false);
			}
			setLoader(false);
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
	const handleConfirmDeleteTaskClick = async (e: any, taskDetails: any, record: any, directRemove: boolean) => {
		try {
			if (directRemove) {
				deleteTaskFromLocalList(taskDetails, record);

				return true;
			} else {
				setLoader(true);
				const requestParams = `taskId=${taskDetails?.taskId}`;
				const deleteTaskResponse: any = await dispatch(deleteTaskForOneOnOne(requestParams));
				if (Boolean(deleteTaskResponse) && deleteTaskResponse?.data?.status === 200) {
					// const details: any = await getNotesAndTaskDetails(record);
					// enqueueSnackbar(t('taskDeleted'), {
					// 	variant: 'success',
					// 	autoHideDuration: 3000,
					// });
					// setOpenLaunchForm({
					// 	...openLaunchForm,
					// 	details: { ...details, isTaskAddUpdated: true },
					// });
					deleteTaskFromLocalList(taskDetails, record);
					setLoader(false);
				} else {
					const responseAPI = deleteTaskResponse?.data?.messageList || {};
					const keys = Object.keys(responseAPI);
					const messages = keys && keys.length ? keys.map((item) => responseAPI[item]) : t('someErrorOccurred');
					enqueueSnackbar(messages, {
						variant: 'error',
						autoHideDuration: 3000,
					});
					setLoader(false);
				}
				return true;
			}
		} catch (error) {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 3000,
			});
			setLoader(false);
			return true;
		}
	};
	const deleteTaskFromLocalList = (taskDetails: any, record: any) => {
		let updatedRecord = { ...record };
		let updatedTasksDetails = null;
		const { tasksDetails } = record || {};
		if (tasksDetails) {
			const { myTasks, otherTasks } = tasksDetails || {};
			const updatedTasks =
				myTasks && myTasks.length ? myTasks.filter((item: any) => item?.taskId !== taskDetails?.taskId) : myTasks;

			updatedTasksDetails = { myTasks: [...updatedTasks], otherTasks };
		}
		updatedRecord = { ...updatedRecord, tasksDetails: updatedTasksDetails };
		setOpenLaunchForm({
			...openLaunchForm,
			details: { ...updatedRecord, isTaskAddUpdated: true },
		});
		let updatedCopiedTaskList = copiedTaskList && copiedTaskList?.length ? [...copiedTaskList] : [];
		updatedCopiedTaskList = updatedCopiedTaskList?.filter((rec: any) => rec.taskId !== taskDetails?.copiedTaskId);
		setCopiedTaskList([...updatedCopiedTaskList]);
	};
	/** Task Related Methods End */
	/**mom drawer  */
	const handleClickMom = (event: any) => {
		setPrevIsLaunchFormEdited(isLaunchFormEdited);
		setIsLaunchFormEdited(false);
		setMomDrawer(true);
	};
	const handleMomDrawerClose = (event: any, type: string) => {
		event.preventDefault();
		setMomDrawer(false);
		let updatedCopiedTaskList = copiedTaskList && copiedTaskList?.length ? [...copiedTaskList] : [];
		updatedCopiedTaskList = updatedCopiedTaskList.map((rec: any) => {
			const data = { ...rec };
			data.isNewItem = true;
			delete data.recentAdded;
			delete data.recentTaskId;

			return data;
		});
		if (updatedCopiedTaskList && updatedCopiedTaskList.length) {
			setIsLaunchFormEdited(true);
		} else {
			setIsLaunchFormEdited(prevIsLaunchFormEdited);
		}
		setPrevIsLaunchFormEdited(false);
		setCopiedTaskList([...updatedCopiedTaskList]);
	};
	const setHeaderDetails = (selectedDateValue: any) => {
		const startDate = selectedDateValue?.start?._d || new Date();
		const endDate = selectedDateValue?.end?._d || new Date();
		if (startDate && endDate) {
			const headerTitle = `(${getMonthDateYear(startDate)} - ${getMonthDateYear(endDate)})`;

			setMomHeaderTitle(headerTitle);
		}
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box className='eng-1on1-container'>
				<Box className='request-sub-head'>
					{/* meeting template head */}
					{openLaunchForm && openLaunchForm?.open ? (
						<Box className='meeting-sub-head'>
							<Box className='back-to-main'>
								<Typography variant='body2' onClick={handleBackButtonOnClick}>
									<Link id='back-btn'>
										<BackArrow /> {t('back')}
									</Link>
								</Typography>
								{/* <OKRButton
										id='back-btn'
										handleClick={handleBackButtonOnClick}
										icon={<BackArrow />}
										text={t('back')}
									/> */}
							</Box>
							<div className='user-info v-center' ref={ref2}>
								<Box className='user-img'>
									{Boolean(openLaunchForm?.details?.imagePath) ? (
										<Avatar
											alt={`${openLaunchForm?.details?.firstName} ${openLaunchForm?.details?.lastName}`}
											src={openLaunchForm?.details.imagePath}
										/>
									) : (
										<Avatar>{getUserName(openLaunchForm?.details)}</Avatar>
									)}
									{/* <Tooltip title={t('requested1on1withYou')} arrow placement='bottom-start'>
										<span className='request-arrow'>
											<BackArrow />
										</span>
									</Tooltip> */}
									{openLaunchForm?.details?.isReciver ? (
										<Tooltip title={t('requested1on1withYou')} arrow placement='bottom-start'>
											<span className='request-arrow arrow-left'>
												<BackArrow />
											</span>
										</Tooltip>
									) : (
										<Tooltip title={t('youRequested1on1')} arrow placement='bottom-start'>
											<span className='request-arrow'>
												<BackArrow />
											</span>
										</Tooltip>
									)}
								</Box>
								<Box className='user-info-details'>
									<Typography variant='h4'>
										{openLaunchForm?.details?.firstName || ''} {openLaunchForm?.details?.lastName || ''}
									</Typography>
								</Box>
							</div>
						</Box>
					) : (
						<>
							{(oneOnOneList && oneOnOneList.length > 0) ||
							noShowAddIcon ||
							filterBoxData?.completed ||
							filterBoxData?.notCompleted ? (
								<Box className={'request-filter-box'}>
									<FilterBox
										{...props}
										classNameValue={activeTabBox === 'notCompleted' ? 'active' : ''}
										icon={<ResendRequestColorIcon />}
										mainHead={t('pendingMeeting')}
										//mainHead={'Not Completed'}
										description={t('pendingMeetingDes')}
										bgColor={'white-color'}
										tileCount={filterBoxData?.notCompleted}
										activeTabBox={activeTabBox}
										handleBoxClick={() => handleBoxClick('notCompleted')}
									/>
									<FilterBox
										{...props}
										classNameValue={activeTabBox === 'completed' ? 'active' : ''}
										icon={<RequestColorIcon />}
										mainHead={t('launchedMeeting')}
										//mainHead={'Completed'}
										description={t('launchedMeetingDes')}
										bgColor={'white-color'}
										tileCount={filterBoxData?.completed}
										activeTabBox={activeTabBox}
										handleBoxClick={() => handleBoxClick('completed')}
									/>
								</Box>
							) : (
								<></>
							)}
						</>
					)}
					<Box className='request-sub-head-right'>
						<div ref={ref3}>
							{openLaunchForm && openLaunchForm?.open ? (
								<>
									<Tooltip arrow title={t('momBtnTooltip')} classes={{ popper: 'tooltip-layout4' }}>
										<Typography className={'btn-circle-white mom-btn'} onClick={handleClickMom} component={'span'}>
											<MOMIcon />
										</Typography>
									</Tooltip>
								</>
							) : (
								<></>
							)}
						</div>
						<div ref={ref4}>
							{openLaunchForm && openLaunchForm?.open ? (
								<>
									<MeetingNotesDownload setLoader={setLoader} itemDetail={openLaunchForm?.details} {...props} />
								</>
							) : (
								<></>
							)}
						</div>
						<div ref={ref1}>
							{(addPermission && oneOnOneList && oneOnOneList.length > 0) || noShowAddIcon ? (
								<OKRButton
									handleClick={handleClickRequestOneOnOneCreate}
									icon={<AddIconSvg />}
									className={'add-btn'}
									title={t('requestOnetoOneText')}
								/>
							) : (
								<></>
							)}
						</div>
					</Box>
				</Box>
				{openLaunchForm && openLaunchForm?.open ? (
					<div ref={ref}>
						<LaunchTemplate
							{...props}
							openLaunchForm={openLaunchForm}
							setOpenLaunchForm={setOpenLaunchForm}
							oneToOneTitleOnLaunchError={oneToOneTitleOnLaunchError}
							setOneToOneTitleLaunchError={setOneToOneTitleLaunchError}
							userDetail={userDetail}
							currentUser={currentUser}
							handleBackButtonOnClick={handleBackButtonOnClick}
							handleSaveLaunchEditRequestAPICall={handleSaveLaunchEditRequestAPICall}
							isLaunchFormEdited={isLaunchFormEdited}
							setIsLaunchFormEdited={setIsLaunchFormEdited}
							addUpdateTask={addUpdateTask}
							handleCompleteTask={handleCompleteTask}
							handleConfirmDeleteTaskClick={handleConfirmDeleteTaskClick}
							loader={loader}
							setLoader={setLoader}
							copiedTaskList={copiedTaskList}
							setCopiedTaskList={setCopiedTaskList}
							setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
							select1on1Tab={select1on1Tab}
							setSelect1on1Tab={setSelect1on1Tab}
							allTaskList={allTaskList}
							setAllTaskList={setAllTaskList}
							OtherAllTaskList={OtherAllTaskList}
							setOtherAllTaskList={setOtherAllTaskList}
							updateTaskList={updateTaskList}
							resetHighlightOneOnOneTask={resetHighlightOneOnOneTask}
							oneToOneSettings={oneToOneSettings}
							setOneToOneAgendaError={setOneToOneAgendaError}
							oneToOneAgendaError={oneToOneAgendaError}
						/>
					</div>
				) : (
					<>
						{(oneOnOneList && oneOnOneList.length > 0) ||
						noShowAddIcon ||
						filterBoxData?.completed ||
						filterBoxData?.notCompleted ? (
							<Box className='request-meeting'>
								<Box className='col-left'>
									<Box className='meeting-head'>
										<Typography variant='h2'>{t('meetings')}</Typography>
										<Box className='meeting-head-right'>
											<Box>
												{totalUser ? (
													<TablePagination
														component='div'
														className='user-list-pagination'
														count={totalUser}
														page={pageIndex > 1 ? pageIndex - 1 : 0}
														onPageChange={handleChangePage}
														rowsPerPage={pageSize}
														onRowsPerPageChange={handleChangeRowsPerPage}
														SelectProps={{
															MenuProps: {
																PopoverClasses: { paper: 'okr-default-dropdown user-number-dropdown' },
															},
														}}
													/>
												) : (
													<></>
												)}
											</Box>
											<Box className='user-search'>
												<TextField
													className='search-text-field'
													placeholder={t('searchText')}
													fullWidth
													value={searchValue}
													onChange={(e) => handleChange(e)}
													onKeyDown={(e) => searchClicked(e)}
													inputProps={{
														'aria-label': 'search',
													}}
													InputProps={{
														startAdornment: (
															<InputAdornment position='start'>
																<SearchIcon />
															</InputAdornment>
														),
													}}
												/>
												{searchTextArray && searchTextArray.length > 0 && (
													<Box className='user-search-chip'>
														<List>
															{searchTextArray.map((user: any, idx: any) => {
																if (idx < 3)
																	return (
																		<ListItem key={`user${user}${idx}`} id={`user-chip-${user}${idx}`}>
																			{user.length > 20 ? (
																				<Tooltip title={user} arrow>
																					<Chip
																						label={user.substring(0, 20) + '...'}
																						onDelete={(e) => clearSearch(user)}
																						deleteIcon={
																							<span>
																								<CrossIcon />
																							</span>
																						}
																					/>
																				</Tooltip>
																			) : (
																				<Chip
																					label={user}
																					onDelete={(e) => clearSearch(user)}
																					deleteIcon={
																						<span>
																							<CrossIcon />
																						</span>
																					}
																				/>
																			)}
																		</ListItem>
																	);
															})}
															{searchTextArray && searchTextArray.length > 3 && (
																<>
																	<ListItem
																		className='search-more'
																		id='src-more'
																		aria-owns={openUserSearch ? 'mouse-over-popover' : undefined}
																		aria-haspopup='true'
																		onMouseOver={handleUserOpen}
																		onMouseLeave={handleUserClose}
																	>
																		<Typography>{searchTextArray.length - 3}+</Typography>
																		<UserSearchListPopover
																			{...props}
																			open={openUserSearch}
																			setOpen={setOpenUserSearch}
																			anchorEl={anchorElUserSearch}
																			setAnchorEl={setAnchorElUserSearch}
																			searchTextArray={searchTextArray}
																			clearSearch={clearSearch}
																		/>
																	</ListItem>
																	<ListItem className='clear-all'>
																		<OKRButton
																			className='btn-link'
																			id='clear-all'
																			handleClick={() => clearSearch('all')}
																			text={'Clear All'}
																		></OKRButton>
																	</ListItem>
																</>
															)}
														</List>
													</Box>
												)}
											</Box>
										</Box>
									</Box>
									{oneOnOneList && oneOnOneList.length > 0 ? (
										<MeetingList
											{...props}
											oneOnOneList={oneOnOneList}
											sortUserList={sortUserList}
											sortingColumn={sortingColumn}
											sortOrder={sortOrder}
											setLoader={setLoader}
											handleClickRequestOneOnOneEdit={handleClickRequestOneOnOneEdit}
											handleClickRequestOneOnOneDelete={handleClickRequestOneOnOneDelete}
											handleClickRequestOneOnOneLaunch={handleClickRequestOneOnOneLaunch}
											handleClickRequestOneOnOneEditLaunch={handleClickRequestOneOnOneEditLaunch}
											handleClickEditLaunch={handleClickEditLaunch}
											handleClickStartLaunch={handleClickStartLaunch}
										/>
									) : (
										<Box className='user-no-record'>
											{activeTabBox === 'completed' ? (
												<Typography variant='h2'>{t('noCompletedMeeting')}</Typography>
											) : activeTabBox === 'notCompleted' ? (
												<Typography variant='h2'>{t('noPendingMeeting')}</Typography>
											) : activeTabBox === 'all' ? (
												<Typography variant='h2'>{t('noMeeting')}</Typography>
											) : (
												<Typography variant='h2'>{t('noMeeting')}</Typography>
											)}
											<AnimatedIcon
												className=''
												width='260'
												type='image/svg+xml'
												alt='UserImage'
												imagePath={UserImage}
											/>
										</Box>
									)}
								</Box>
								{oneOnOneTeamList && oneOnOneTeamList.length ? (
									<Box className='col-right'>
										<Box className='white-box team-member-box'>
											<TeamListRightPanel
												oneOnOneTeamList={oneOnOneTeamList}
												handleRequestOneOnOneCall={handleRequestOneOnOneCall}
												handleQuickLaunchCall={handleQuickLaunchCall}
												handleEmployeeClick={handleEmployeeClick}
												handleClickStartLaunch={handleClickStartLaunch}
											/>
										</Box>
									</Box>
								) : (
									<Box className='col-right'>
										<Box className='white-box team-member-box'>
											<Box className='no-record'>
												<Typography variant='h2'>{t('noTeamFound')}</Typography>

												<AnimatedIcon
													className='no-record-img'
													width={'300'}
													type='image/svg+xml'
													alt={'No Conversation Available'}
													imagePath={NoTeamMemberImg}
												/>
											</Box>
										</Box>
									</Box>
								)}
							</Box>
						) : (
							<></>
						)}
						{oneOnOneList &&
						oneOnOneList.length === 0 &&
						!noShowAddIcon &&
						!filterBoxData?.completed &&
						!filterBoxData?.notCompleted ? (
							<Box className={`no-records-area request-no-record`}>
								<Box className='no-record-message'>
									<Box className='create-align-link-area'>
										{(!addPermission && oneOnOneList && oneOnOneList.length == 0) || noShowAddIcon ? (
											<Box className='no-record-head'>
												<Typography variant='h2'>{t('noPermissionOneonOne')}</Typography>
												<Typography>{t('noPermissionOneonOne2')}</Typography>
											</Box>
										) : (
											<>
												<Typography variant='h2'>{t('no1on1Yet')}</Typography>
												<Typography>{t('oneOnOneDes')}</Typography>
											</>
										)}
										{(addPermission && oneOnOneList && oneOnOneList.length === 0) || noShowAddIcon ? (
											<Box className='create-align-card create-new-obj-card' onClick={handleClickRequestOneOnOneCreate}>
												<Box className='card-left'>
													<RequestColorIcon />
													<Box>
														<Typography variant='h2'>{t('requestOnetoOneText')}</Typography>
														<Typography>{t('oneOnOneNoRecord')}</Typography>
													</Box>
												</Box>
												<Box className='card-right'>
													<OKRButton
														//handleClick={handleClickRequestOneOnOneCreate}
														className='add-btn-white'
														id='one-on-one'
														icon={<AddIconSvg />}
													/>
												</Box>
											</Box>
										) : (
											<></>
										)}
									</Box>
								</Box>
							</Box>
						) : (
							<></>
						)}
					</>
				)}
			</Box>

			{requestOneOnOneDrawer && requestOneOnOneDrawer?.open && (
				<MainDrawer
					open={requestOneOnOneDrawer?.open}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t('requestOnetoOne')}
					loader={loader}
					children={
						<Box className='drawer-inner-content'>
							<RequestOneOnOneForm
								{...props}
								formData={formData}
								setFormData={setFormData}
								formDataUpdated={formDataUpdated}
								setFormDataUpdated={setFormDataUpdated}
								setIsFormEdited={setIsFormEdited}
								oneToOneTitleError={oneToOneTitleError}
								oneToOneUserError={oneToOneUserError}
								setOneToOneUserError={setOneToOneUserError}
								setOneToOneTitleError={setOneToOneTitleError}
								setOneToOneAgendaError={setOneToOneAgendaError}
								oneToOneAgendaError={oneToOneAgendaError}
								isEdit={requestOneOnOneDrawer?.type === 'Edit' ? true : false}
								editableDetails={requestOneOnOneDrawer?.details || null}
								isDirect={requestOneOnOneDrawer?.type === 'Direct' ? true : false}
								directEmpDetails={requestOneOnOneDrawer?.empInfo || null}
								staticTitleText={staticTitleText}
								userDetail={userDetail}
								oneToOneMeetingLinkError={oneToOneMeetingLinkError}
								setOneToOneMeetingLinkError={setOneToOneMeetingLinkError}
								oneToOneMeetingDateError={oneToOneMeetingDateError}
								setOneToOneMeetingDateError={setOneToOneMeetingDateError}
								oneToOneSettings={oneToOneSettings}
							/>
						</Box>
					}
					saveButtonText={requestOneOnOneDrawer?.type === 'Edit' ? t('update') : t('saveBtn')}
					draftButtonText={t('launch')}
					draftButtonIcon={<MissionVisionIcon />}
					rightCloseBtn={false}
					isSaveDraft={
						requestOneOnOneDrawer?.type === 'Edit' &&
						requestOneOnOneDrawer?.details &&
						requestOneOnOneDrawer?.details.isLaunched
							? false
							: true
					}
					isSaveButtonVisible={true}
					isSaveButtonDisabled={loader}
					isDraftButtonDisabled={formData?.requestedTo === 0 ? true : loader}
					//drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
					handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
					handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
					handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
					handleDraftButtonClick={(event: any) => handleDrawerClose(event, 'launch')}
				/>
			)}

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
			{momDrawer && (
				<MainDrawer
					open={momDrawer}
					drawerClassName={`main-drawer-panel main-drawer-no-footer`}
					transitionDuration={{ enter: 500, exit: 1000 }}
					headerTitle={t('minutesOfMeeting')}
					loader={loader}
					children={
						<Box className='drawer-inner-content'>
							<MomDrawer
								{...props}
								loader={loader}
								setLoader={setLoader}
								currentMeetingDetails={openLaunchForm && openLaunchForm?.open ? openLaunchForm?.details || null : null}
								copiedTaskList={copiedTaskList}
								setCopiedTaskList={setCopiedTaskList}
								setHeaderDetails={setHeaderDetails}
							/>
						</Box>
					}
					isFooterVisible={false}
					rightCloseBtn={true}
					isSaveButtonVisible={true}
					isSaveButtonDisabled={loader}
					handleDrawerClose={(event: any) => handleMomDrawerClose(event, 'close')}
					handleSaveClick={(event: any) => handleMomDrawerClose(event, 'save')}
					handleCancelClick={(event: any) => handleMomDrawerClose(event, 'cancel')}
					handleDraftButtonClick={(event: any) => handleMomDrawerClose(event, 'launch')}
				/>
			)}
		</>
	);
};
