import * as actionTypes from './actionTypes';
import {
	userUnreadNotificationAPI,
	fetchNotificationForTabsAPI,
	deleteMultiNotificationAPI,
	readNotificationAPI,
} from '../services/NotificationService';
import { personalFeedbackAcceptRejectAPI } from '../services/FeedbackService';

const userUnreadNotificationPending = () => ({
	type: actionTypes.USER_UNREAD_NOTIFICATION_PENDING,
});
const userUnreadNotificationCompleted = (payload) => ({
	type: actionTypes.USER_UNREAD_NOTIFICATION_SUCCESS,
	payload,
});
const userUnreadNotificationFailed = (error) => ({
	type: actionTypes.USER_UNREAD_NOTIFICATION_FAILED,
	error,
});

export const userUnreadNotification = (data) => {
	return (dispatch) => {
		dispatch(userUnreadNotificationPending());
		return userUnreadNotificationAPI(data)
			.then((response) => {
				dispatch(userUnreadNotificationCompleted(response.data));
			})
			.catch((error) => {
				dispatch(userUnreadNotificationFailed(error));
			});
	};
};

export const readSingleNotification = (data, requestBody) => {
	return () => {
		return readNotificationAPI(data, requestBody);
	};
};

const deleteNotificationPending = () => ({
	type: actionTypes.DELETE_NOTIFICATION_PENDING,
});
const deleteNotificationCompleted = (payload) => ({
	type: actionTypes.DELETE_NOTIFICATION_SUCCESS,
	payload,
});
const deleteNotificationFailed = (error) => ({
	type: actionTypes.DELETE_NOTIFICATION_FAILED,
	error,
});
export const deleteNotification = (data, requestBody) => {
	return (dispatch) => {
		dispatch(deleteNotificationPending());
		return deleteMultiNotificationAPI(data, requestBody)
			.then((response) => {
				if (response.status === 200) {
					dispatch(deleteNotificationCompleted(response.data));
				} else {
					dispatch(deleteNotificationFailed(''));
				}
			})
			.catch((error) => {
				dispatch(deleteNotificationFailed(error));
			});
	};
};

export const resetNotification = () => ({
	type: actionTypes.RESET_NOTIFICATION,
});

export const personalFeedbackAcceptReject = (data) => {
	return () => {
		return personalFeedbackAcceptRejectAPI(data);
	};
};

const updateNotificationTabData = (payload) => ({
	type: actionTypes.UPDATE_NOTIFICATION_TAB_DATA,
	payload,
});

export const fetchNotificationForTabs = (data) => {
	return async (dispatch) => {
		const response = await fetchNotificationForTabsAPI(data);
		if (response && response.data && response.data.status === 200) {
			dispatch(updateNotificationTabData(response));
		}
	};
};

export const updateFeedbackRedirectionInfo = (payload) => ({
	type: actionTypes.FEEDBACK_REDIRECTION_DETAILS,
	payload,
});

export const resetFeedbackRedirectionInfo = (payload) => ({
	type: actionTypes.RESET_FEEDBACK_REDIRECTION_DETAILS,
	payload,
});
