import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import { GoalsCycleColoredIcon } from '../../../config/svg/MyGoalSvg';
import { GoalResponseLog } from './GoalResponseLog';
import { GoalAttachments } from './GoalAttachements';
import { useDispatch, useSelector } from 'react-redux';
import { getPerformanceGoalDocument, setPerfomanceGoalAttachmentCount } from '../../../action/adminSettings';
import { Enums } from '../../../config/enums';

export const GoalSettingsTabPanel: React.FC<any> = (props: any) => {
	const { performanceGoalsList, children, value, index, ...other } = props;

	return (
		<Box
			role='tabpanel'
			hidden={value !== index}
			id={`category-settings-tab-${index}`}
			aria-labelledby={`category-settings-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={0}>{children}</Box>}
		</Box>
	);
};

GoalSettingsTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
	return {
		id: `goal-settings-tabpanel-${index}`,
		'aria-controls': `goal-settings-tabpanel-${index}`,
	};
}

export const GoalSettingsDrawer: React.FC<any> = (props) => {
	const { t, handleTabChange = {}, tabSelected = 0, setLoader, loader } = props;
	const [goalDocumentCount, setGoalDocumentCount] = useState<number>(0);
	const { goalAttachmentCont } = useSelector((state: any) => state?.adminSettingReducer);
	return (
		<>
			<Box className='added-category-name'>
				<Typography variant='h4'>
					<GoalsCycleColoredIcon />
					{props.kr.goalList.name}
				</Typography>
			</Box>
			<Tabs className='okr-tabs drawer-tabs' value={tabSelected} onChange={handleTabChange} aria-label='Admin Tabs'>
				<Tab
					className='arrow-tab'
					label={`${t('attachmentsLabel')} ${goalAttachmentCont ? '(' + goalAttachmentCont + ')' : ''}`}
					value={0}
					{...a11yProps(0)}
				/>
				<Tab className='arrow-tab' label={t('responseLog')} value={1} {...a11yProps(1)} />
			</Tabs>
			<Box className={`drawer-tabs-panel`}>
				<GoalSettingsTabPanel value={tabSelected} index={0}>
					<GoalAttachments
						{...props}
						goalDocumentCount={goalDocumentCount}
						setGoalDocumentCount={setGoalDocumentCount}
						setLoader={setLoader}
						loader={loader}
					/>
				</GoalSettingsTabPanel>
				<GoalSettingsTabPanel value={tabSelected} index={1}>
					<GoalResponseLog {...props} tabSelected={tabSelected} />
				</GoalSettingsTabPanel>
			</Box>
		</>
	);
};
