import React, { Fragment } from 'react';
import { Box, Checkbox, FormLabel, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CalendarIcon } from '../../../config/svg/CommonSvgIcon';
// import { ErrorIcon } from '../../../config/svg/formElementIcons';
import { PerspectiveSOIcon, StrategicObjIcon } from '../../../config/svg/CommonSvg';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { Enums } from '../../../config/enums';
import { TimeFrameDropdown } from '../TimeFrameDropdown';

export const Attributes: React.FC<any> = (props: any) => {
	const {
		soDetailsDrawer,
		startDateValue,
		maxDateValue,
		selectedDateRange,
		setSelectedDateRange,
		soAttributeDetails,
		handleTimeFrameSelection,
		handleSONameChange,
		handlePerspectiveOfSOChange,
		perspectiveList,
		soOkrTimeframeList,
		setSelectedTimeFrame,
		handleTimeFrameChangeFromDrawer,
		handleTimeFrameEditClick,
		soPermissionDetails,
	} = props;
	const { t } = useTranslation();

	// console.log('soAttributeDetails ===> ', soAttributeDetails, perspectiveList);

	return (
		<Box className='so-attributes'>
			<Box className='drawer-input-field helperText-bottom drawer-date-field'>
				<FormLabel id='set-a-duration' component='legend'>
					<CalendarIcon />
					{t('timeFrameText')} <span className='asterisk'>*</span>
				</FormLabel>
				{soDetailsDrawer && soOkrTimeframeList && soOkrTimeframeList.length ? (
					<TimeFrameDropdown
						{...props}
						isCalenderVisible={true}
						soOkrTimeframeList={soOkrTimeframeList}
						setSelectedTimeFrame={setSelectedTimeFrame}
						handleTimeFrameChange={handleTimeFrameChangeFromDrawer}
						selectedDateRange={selectedDateRange}
						setSelectedDateRange={setSelectedDateRange}
						soAttributeDetails={soAttributeDetails}
						startDateValue={startDateValue}
						maxDateValue={maxDateValue}
						handleTimeFrameSelection={handleTimeFrameSelection}
						handleTimeFrameEditClick={handleTimeFrameEditClick}
						isTimeFrameEdit={false}
						isTimeFrameDelete={false}
					/>
				) : (
					<ReactDateRangePicker
						type={'ContributorList'}
						selectedCycleDetails={{}}
						startDateValue={soAttributeDetails?.soTimeframeId ? null : soAttributeDetails?.timeFrameStartDate || null}
						endDateValue={soAttributeDetails?.soTimeframeId ? null : soAttributeDetails?.timeFrameEndDate || null}
						minimumDate={startDateValue || new Date()}
						maximumDate={maxDateValue || new Date()}
						isDateRangeTextFieldVisible={true}
						isOnlyDueDateVisible={false}
						allCycleDetails={[]}
						isCycleVisible={false}
						isEndOfMonthVisible={false}
						numberOfCalendars={Enums.ONE}
						selectionType={'range'}
						handleDateSelection={(range: any, start: any, end: any, selectionType: any, isCycleChanged: any) => {
							handleTimeFrameSelection(range, start, end);
						}}
						selectedDateRange={selectedDateRange}
						setSelectedDateRange={setSelectedDateRange}
						placeholderText={t('timeFramePlaceholdar')}
						isDatePickerDisable={!soPermissionDetails?.isCreatorRights}
					/>
				)}
			</Box>
			<Box className='drawer-blue-bg-full'>
				<Box className='drawer-form-fields-group'>
					<Box className='drawer-input-field '>
						<TextField
							fullWidth
							className='helperText-bottom so-name-obj'
							label={
								<Fragment>
									<StrategicObjIcon />
									{t('strategicObjectiveLabel')} <span className='asterisk'>*</span>
								</Fragment>
							}
							placeholder={t('strategicObjPlaceholder')}
							name='strategicObjectiveName'
							value={soAttributeDetails?.strategicObjectiveName || ''}
							onChange={handleSONameChange}
							InputLabelProps={{
								shrink: true,
							}}
							disabled={!soPermissionDetails?.isCreatorRights}
							InputProps={{ 'aria-label': t('strategicObjPlaceholder'), disableUnderline: true }}
						/>
					</Box>
					<Box className='drawer-input-field '>
						<FormLabel id='perspective-so' component='legend'>
							<PerspectiveSOIcon />
							{t('perspectiveSO')} <span className='asterisk'>*</span>
						</FormLabel>
						<FormGroup aria-label='position' row className='checkbox-btn-style'>
							{perspectiveList && perspectiveList.length ? (
								perspectiveList
									.filter((ite: any) => ite?.isActive)
									.map((rec: any) => (
										<>
											<FormControlLabel
												disabled={!soPermissionDetails?.isCreatorRights}
												value={rec?.perspectiveNameValue || false}
												control={
													<Checkbox
														checked={rec?.perspectiveNameValue || false}
														onChange={(event) => handlePerspectiveOfSOChange(event, rec)}
														name={rec?.perspectiveTagId}
													/>
												}
												label={rec?.perspectiveNameTag || ''}
											/>
										</>
									))
							) : (
								<></>
							)}
						</FormGroup>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
