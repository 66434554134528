import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { AddIconBlue, AddUserIcon } from '../../../config/svg/CommonSvgIcon';
import AddUserImg from '../../../images/add-user-img.svg';
import { checkPermission } from '../../../config/rolePermission';
import { addNewUsers } from '../AdminConstant';

export const NoAdminRecords: React.FC<any> = (props) => {
	const { t } = useTranslation();
	return (
		<Box className='no-records-area'>
			<Box className='no-record-message'>
				<Typography variant='h2' component='h2'>
					{t('noUsersLabel')}
				</Typography>
				<Typography variant='h4'>{t('noUserMessage')}</Typography>
				<Box className='get-started-card'>
					<AnimatedIcon
						className='add-user-img'
						width='157'
						type='image/svg+xml'
						alt='No user'
						imagePath={AddUserImg}
					/>
					{checkPermission(props.getPermissionResult?.employeePermissions, addNewUsers) ? (
						<Box>
							<Typography variant='h4'>{t('addUserMessage')}</Typography>
							<Link onClick={(e: any) => props.addUserClick(e, 'addNewUsers')}>
								<AddIconBlue />
								{t('getStartedLabel')}
							</Link>
						</Box>
					) : (
						<></>
					)}
				</Box>
				{/* <Box className='or-separator'>
					<Typography variant='subtitle2'>{t('orLabel')}</Typography>
				</Box> */}
			</Box>
		</Box>
	);
};
