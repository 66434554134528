import * as actionTypes from '../action/actionTypes';

const INITIAL_STATE = {
	globalTaskListResponse: {},
	globalTaskListStatus: '',
	isDataLoading: false,
	globalDashboardTaskListResponse: {},
	globalTaskSourceListResponse: {},
	globalTaskSourceListStatus: '',
	/**
	 * New Global Task States Start Here
	 */
	isRedirectedFromNotification: { isRedirected: false, taskName: '', taskId: 0, tabId: 0, isCompleted: false },
	isCreateLinkPopupEnable: false,
	createLinkPopupDetail: null,
};

export default function globalTaskReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case actionTypes.GLOBAL_TASK_PENDING:
			return {
				...state,
				globalTaskListResponse: {},
				globalTaskListStatus: false,
			};
		case actionTypes.GLOBAL_TASK_SUCCESS:
			return {
				...state,
				globalTaskListResponse: action.payload,
				globalTaskListStatus: true,
			};
		case actionTypes.GLOBAL_DASHBOARD_TASK_SUCCESS:
			return {
				...state,
				globalDashboardTaskListResponse: action.payload,
				globalTaskListStatus: true,
			};
		case actionTypes.GLOBAL_TASK_FAILED:
			return {
				...state,
				globalTaskListResponse: {},
				globalTaskListStatus: true,
			};
		case actionTypes.IS_DATA_LOADING:
			return {
				...state,
				isDataLoading: action.data,
			};
		case actionTypes.GLOBAL_TASK_SOURCE_PENDING:
			return {
				...state,
				globalTaskSourceListStatus: false,
			};
		case actionTypes.GLOBAL_TASK_SOURCE_SUCCESS:
			return {
				...state,
				globalTaskSourceListResponse: action.payload,
				globalTaskSourceListStatus: true,
			};
		case actionTypes.GLOBAL_TASK_SOURCE_FAILED:
			return {
				...state,
				globalTaskSourceListResponse: [],
				globalTaskSourceListStatus: false,
			};
		/**
		 * New Global Task States Start Here
		 */
		case actionTypes.GLOBAL_TASK_NOTIFICATION_REDIRECTION:
			return {
				...state,
				isRedirectedFromNotification: action?.payload || {
					isRedirected: false,
					taskName: '',
					taskId: 0,
					tabId: 0,
					isCompleted: false,
				},
			};
		case actionTypes.GLOBAL_TASK_CREATE_LINK_UN_SAVE_CHECK:
			return {
				...state,
				isCreateLinkPopupEnable: action?.payload || false,
			};

		case actionTypes.GLOBAL_TASK_CREATE_POP_UP_DETAILS:
			return {
				...state,
				createLinkPopupDetail: action?.payload || null,
			};

		default:
			return state;
	}
}
