import React, { Fragment, useEffect, useState } from 'react';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Link,
	Radio,
	RadioGroup,
	Switch,
	Typography,
	TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useSnackbar } from 'notistack';
import { BackArrow } from '../../../../../config/svg/GlobalSvg';
import '../../../../../styles/pages/admin/settings/addPerformReview.scss';
import { GeneralSettings } from './GeneralSettings';
import { PerformanceInputStream } from './PerformanceInputsStream';
import { OKRButton } from '../../../../Common/OKRButton';
import { TemplateIcon } from '../../../../../config/svg/CommonSvgIcon';
import { CompetencyRatingSettings } from './CompetencyRatingSettings';
import { DeleteIcon, DraftIcon } from '../../../../../config/svg/CommonSvg';
import { InformationMessage } from '../../../CommonComponent/InformationMessage';
import { checkSupportPermissionType, formatDate, formatFeedbackDate } from '../../../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	cycleDateOverlap,
	performanceCycleExcludeEmployeeList,
	performanceRoleAssigned,
	performanceRoleAssignedForCodeValue,
} from '../../../../../action/adminSettings';
import { getLocalStorageItem } from '../../../../../services/StorageService';
import { supportModuleAlignOkr } from '../../../AdminConstant';
import { Enums } from '../../../../../config/enums';
import AlertDialog from '../../../../Common/Dialog';
import { CancelIcon } from '../../../../../config/svg/Action';
import { AssessmentFormRoleSettings } from './AssessmentFormRoleSettings';
import { AssessmentSettingVisibility } from './AssessmentSettingVisibility';
import { ErrorIcon } from '../../../../../config/svg/formElementIcons';
import { NumberFormatComponent } from '../../../../Common/NumberFormat';
import { AssessmentFormVisibility } from './AssessmentFormVisibility';

export const AddPerformanceReview: React.FC<any> = (props: any) => {
	const {
		type,
		editableDetails,
		handleManageReviewForm,
		handlePerformanceReviewForm,
		handleBackButtonClick,
		handleDeletePerformanceReviewForm,
		setLoader,
		loader,
		selectedTemplateDetails,
		selectedCoreValueTemplateDetails,
		performanceReviewList,
		setIsPerformanceFormEdited,
		isPerformanceFormEdited,
		refObject,
		openAssessmentSettingForm,
		setOpenAssessmentSettingForm,
		assessmentFormSetting,
		setAssessmentFormSetting,
	} = props;
	const { competencyRatingSettingTabValue } = useSelector((state: any) => state?.adminSettingReducer);
	const moment = extendMoment(Moment);
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useDispatch();
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 12);

	const {
		performanceReviewRoleList,
		performanceReviewRoleError,
		performanceReviewCoreValueList,
		performanceReviewCoreValueError,
	} = useSelector((state: any) => state?.adminSettingReducer);

	const [performanceReviewRoleListData, setPerformanceReviewRoleListData] = useState<any>(null);
	const [isGracePeriodEdited, setIsGracePeriodEdited] = useState<any>(false);
	const [modalPropsGracePeriodDate, setModalPropsGracePeriodDate] = useState<any>({ open: false, data: {} });
	const staticErrorObj = {
		error: '',
		helperText: '',
		type: '',
	};
	const staticPerformDataObj = {
		performanceReviewName: '',
		excludedUsersList: [],
		excludedEmployeeId: [],
		isOneToOneReview: true,
		isOkrProgressPercentage: true,
		okrProgressPercentage: 25,
		isSelfRatingPercentage: true,
		selfRatingPercentage: 25,
		selfRatingStartDate: null,
		selfRatingEndDate: null,
		isManagerRatingPercentage: true,
		managerRatingPercentage: 25,
		managerRatingStartDate: null,
		managerRatingEndDate: null,
		isThreeSixtyEnabled: true,
		threeSixtyPercentage: 25,
		threeSixtyStartDate: null,
		threeSixtyEndDate: null,
		finalRatingPercentage: 100,
		finalRatingStartDate: null,
		finalRatingEndDate: null,
		isVisibleIfEachCompleted: true,
		isVisibleIfFinalCompleted: false,
		isVisibleOnlyFinalIfFinalCompleted: false,
		isVisibleThreeSixtyCompleted: true,
		scoreCalculatedUponRadio: 5,
		scoreCalculatedUponText: '',
		scoreCalculatedUpon: 5,
		isFinalCommentMandatory: false,
		isShowFinalRatingField: false,
	};
	const staticData = {
		selectedUser: [],
		raisedForId: [],
	};

	const currentCycleDetails = getLocalStorageItem('currentCycle');
	const curCycleData = currentCycleDetails ? JSON.parse(currentCycleDetails) : {};
	const [isDraftCreated, setIsDraftCreated] = useState<boolean>(false);
	const [isFormFilled, setIsFormFilled] = useState<boolean>(false);
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [selectedPerformacePeriodDateRange, setSelectedPerformacePeriodDateRange] = useState<any>(null);
	const [completeByDate, setCompleteBy] = useState<any>(null);
	const [performanceFormData, setPerformanceFormData] = useState<any>(null);
	const [performanceReviewError, setPerformanceReviewError] = useState<any>(staticErrorObj);
	const [cycleDurationError, setCycleDurationError] = useState<any>(staticErrorObj);
	const [completeByError, setCompleteByError] = useState<any>(staticErrorObj);
	const [performancePeriodByError, setPerformancePeriodByError] = useState<any>(staticErrorObj);
	const [totalValueCount, setTotalValueCount] = useState<number>(0);
	const [minDateValue, setMinDateValue] = useState<any>(null);
	const [completeByMinDateValue, setCompleteByMinDateValue] = useState<any>(new Date());
	const [competencyRatingFormError, setCompetencyRatingFormError] = useState<any>(staticErrorObj);
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '', type: '' });
	const [excludedUseList, setExcludedUseList] = useState<any>(staticData);
	const [isAssessmentVisibilitySettingSelected, setIsAssessmentVisibilitySettingSelected] = useState<boolean>(false);
	const [minDateValueForAssessmentInputs, setMinDateValueForAssessmentInputs] = useState<any>(new Date());
	const [selfRatingDuration, setSelfRatingDuration] = useState<any>(null);
	const [scoreVisibilityDuration, setScoreVisibilityDuration] = useState<any>(null);
	const [managerRatingDuration, setManagerRatingDuration] = useState<any>(null);
	const [threeSixtyRatingDuration, setThreeSixtyRatingDuration] = useState<any>(null);
	const [finalRatingDuration, setFinalRatingDuration] = useState<any>(null);
	const [selfRatingDurationError, setSelfRatingDurationError] = useState<any>(staticErrorObj);
	const [managerRatingDurationError, setManagerRatingDurationError] = useState<any>(staticErrorObj);
	const [threeSixtyRatingDurationError, setThreeSixtyRatingDurationError] = useState<any>(staticErrorObj);
	const [finalRatingDurationError, setFinalRatingDurationError] = useState<any>(staticErrorObj);
	const [finalRatingTotalError, setFinalRatingTotalError] = useState<any>(staticErrorObj);
	const [calculatedError, setCalculatedError] = useState<boolean>(false);
	const visibilitySettingObject = [
		{
			assessmentType: 1,
			selfScoreVisibilty: 2,
			managerScoreVisibilty: 6,
			threeSixtyScoreVisibilty: 6,
			finalScoreVisibilty: 0,
			finalScoreVisibleFrom: null,
		},
		{
			assessmentType: 2,
			selfScoreVisibilty: 2,
			managerScoreVisibilty: 3,
			threeSixtyScoreVisibilty: 4,
			finalScoreVisibilty: 5,
		},
		{
			assessmentType: 3,
			selfScoreVisibilty: 1,
			managerScoreVisibilty: 1,
			threeSixtyScoreVisibilty: 4,
			finalScoreVisibilty: 1,
		},
	];
	const visibilityFormObject = [
		{
			assessmentType: 1,
			selfFormVisibilty: 2,
			managerFormVisibilty: 2,
			threeSixtyFormVisibilty: 2,
		},
		{
			assessmentType: 2,
			selfFormVisibilty: 1,
			managerFormVisibilty: 2,
			threeSixtyFormVisibilty: 2,
		},
		{
			assessmentType: 3,
			selfFormVisibilty: 1,
			managerFormVisibilty: 1,
			threeSixtyFormVisibilty: 2,
		},
	];
	const visibilityFormObjectEditable: any = [
		{
			assessmentType: 1,
			selfFormVisibilty: false,
			managerFormVisibilty: true,
			threeSixtyFormVisibilty: true,
		},
		{
			assessmentType: 2,
			selfFormVisibilty: true,
			managerFormVisibilty: false,
			threeSixtyFormVisibilty: true,
		},
		{
			assessmentType: 3,
			selfFormVisibilty: false,
			managerFormVisibilty: false,
			threeSixtyFormVisibilty: false,
		},
	];
	const [performanceVisibility, setPerformanceVisibility] = useState<any>(visibilitySettingObject);
	const [performanceFormVisibility, setPerformanceFormVisibility] = useState<any>(visibilityFormObject);
	const [toggleCoreClick, setToggleCoreClick] = useState<boolean>(false);
	const [assessmentError, setAssessmentError] = useState<any>({ ...staticErrorObj, errorOn: [] });
	const [visibilityError, setVisibilityError] = useState<any>(staticErrorObj);

	useEffect(() => {
		dispatch(performanceRoleAssigned(`ratingFormType=1`));
		dispatch(performanceRoleAssignedForCodeValue(`ratingFormType=2`));
		getPerformanceReviewCycle();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (editableDetails?.performanceVisibiltySettingRequest) {
			let performanceVisibilityCopy = [...performanceVisibility];
			editableDetails?.performanceVisibiltySettingRequest.forEach((element: any) => {
				const indexVal = performanceVisibility.findIndex((rec: any) => rec?.assessmentType === element.assessmentType);
				if (indexVal !== -1) {
					performanceVisibilityCopy[indexVal] = Object.assign({}, element);
				}
				if (element.assessmentType === 1) {
					setScoreVisibilityDuration(
						element.finalScoreVisibleFrom ? moment().range(new Date(element?.finalScoreVisibleFrom), new Date()) : null
					);
				}
			});
			setPerformanceVisibility(performanceVisibilityCopy);
		}
	}, [editableDetails?.performanceVisibiltySettingRequest]);

	useEffect(() => {
		if (editableDetails?.performanceCycleFormVisibiltySettingRequest) {
			let performanceVisibilityCopy = [...performanceFormVisibility];
			editableDetails?.performanceCycleFormVisibiltySettingRequest.forEach((element: any) => {
				const indexVal = performanceFormVisibility.findIndex(
					(rec: any) => rec?.assessmentType === element.assessmentType
				);
				if (indexVal !== -1) {
					let formVisibilityElement: any = performanceVisibilityCopy[indexVal];
					Object.keys(element).forEach((key: any) => {
						if (key !== 'assessmentType' && visibilityFormObjectEditable[indexVal][key]) {
							formVisibilityElement[key] = element[key];
						}
					});
					performanceVisibilityCopy[indexVal] = Object.assign({}, formVisibilityElement);
				}
			});
			setPerformanceFormVisibility(performanceVisibilityCopy);
		}
	}, [editableDetails?.performanceCycleFormVisibiltySettingRequest]);
	useEffect(() => {
		if (editableDetails?.ratingFormType) {
			setToggleCoreClick(editableDetails.ratingFormType === 2 ? true : false);
		}
	}, [editableDetails?.ratingFormType]);
	useEffect(() => {
		let formsData: any = {};
		editableDetails?.performanceRoleLinkedFormList.forEach((element: any) => {
			//if (element.assessmentType) {
			if (!formsData[element.performanceRoleId]) {
				formsData[element.performanceRoleId] = {};
			}
			formsData[element.performanceRoleId][element.assessmentType] = element;
			//}
		});
		setAssessmentFormSetting(formsData);
	}, [editableDetails?.performanceRoleLinkedFormList]);

	useEffect(() => {
		let errorData: any = Object.assign({}, assessmentError.errorOn);
		let keysLength = Object.keys(errorData);
		if (keysLength.length > 0) {
			Object.keys(assessmentFormSetting).forEach((performanceId: any) => {
				if (errorData[performanceId]) {
					let errorToRemove: any[] = [];
					if (assessmentFormSetting[performanceId][1]) {
						errorToRemove.push(1);
					}
					if (assessmentFormSetting[performanceId][2]) {
						errorToRemove.push(2);
					}
					if (assessmentFormSetting[performanceId][3]) {
						errorToRemove.push(3);
					}
					if (errorToRemove.length === 3) {
						delete errorData[performanceId];
					} else {
						errorData[performanceId] = errorData[performanceId].filter((x: any) => !errorToRemove.includes(x));
					}
				}
			});
			setAssessmentError({ ...assessmentError, errorOn: errorData });
		}
	}, [assessmentFormSetting]);

	useEffect(() => {
		if (type === 'Add') {
			setSelectedDateRange(null);
			setCompleteBy(null);
			setSelfRatingDuration(null);
			setManagerRatingDuration(null);
			setThreeSixtyRatingDuration(null);
			setFinalRatingDuration(null);
			performanceCycleExcludeEmployeeListDetailsForAdd(type);
		} else if (type === 'Clone') {
			performanceCycleExcludeEmployeeListDetails(editableDetails);
			setIsDraftCreated(false);
			// setIsFormFilled(true);
		} else if (type === 'Edit') {
			performanceCycleExcludeEmployeeListDetails(editableDetails);
			// setIsFormFilled(true);
			setIsDraftCreated(true);
		} else if (type === 'View') {
			performanceCycleExcludeEmployeeListDetails(editableDetails);
			setIsDraftCreated(true);
		}
		setSelectedPerformacePeriodDateRange(null);
		// console.log('useEffect ==> setIsPerformanceFormEdited ===> ');
		setIsPerformanceFormEdited(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	useEffect(() => {
		let totalValue = 0;
		let isVisibleErrorInfo = false;
		if (checkSupportPermissionType(supportModuleAlignOkr)) {
			if (performanceFormData?.isOkrProgressPercentage) {
				totalValue = totalValue + parseFloat(performanceFormData?.okrProgressPercentage);
			}
		}
		if (performanceFormData?.isSelfRatingPercentage) {
			totalValue = totalValue + parseFloat(performanceFormData?.selfRatingPercentage);
		}
		if (performanceFormData?.isManagerRatingPercentage) {
			totalValue = totalValue + parseFloat(performanceFormData?.managerRatingPercentage);
		}
		if (performanceFormData?.isThreeSixtyEnabled) {
			totalValue = totalValue + parseFloat(performanceFormData?.threeSixtyPercentage);
		}
		if (
			!Boolean(performanceFormData?.isVisibleIfEachCompleted) &&
			!Boolean(performanceFormData?.isVisibleIfFinalCompleted) &&
			!Boolean(performanceFormData?.isVisibleOnlyFinalIfFinalCompleted)
		) {
			// isVisibleErrorInfo = true;
		}
		totalValue = Math.round(totalValue);
		setTotalValueCount(totalValue);

		setIsAssessmentVisibilitySettingSelected(isVisibleErrorInfo);
	}, [performanceFormData]);

	useEffect(() => {
		const isPublish = true; //checkIsPublishEnableOrNot();
		const onGoingRec =
			performanceReviewList && performanceReviewList.length > 0
				? performanceReviewList.find((item: any) => item.status === Enums.TWO)
				: null;
		const reviewDate = onGoingRec?.reviewDate || new Date(); // '2022-12-26T00:00:00';
		const diff = onGoingRec?.reviewDate
			? moment(new Date(formatDate(reviewDate))).diff(new Date(formatDate(new Date()))) >= 0
			: false;
		if (type === 'Edit' && editableDetails?.status === Enums.TWO) {
			setIsFormFilled(true);
		} else {
			setIsFormFilled(isPublish && diff === false ? true : false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		performanceFormData,
		selectedDateRange,
		completeByDate,
		selectedTemplateDetails,
		selectedCoreValueTemplateDetails,
		competencyRatingSettingTabValue,
		performanceReviewRoleList,
		performanceReviewCoreValueList,
		selfRatingDuration,
		managerRatingDuration,
		threeSixtyRatingDuration,
		finalRatingDuration,
		totalValueCount,
	]);

	const performanceCycleExcludeEmployeeListDetailsForAdd = async (type: string) => {
		try {
			setLoader(true);
			// const onGoingRec =
			// 	performanceReviewList && performanceReviewList.length > 0
			// 		? performanceReviewList.find((item: any) => item.status === Enums.TWO)
			// 		: null;
			const requestPayload = {
				performanceCycleId: 0,
				isExcludedFromCycle: true,
				pageIndex: 1,
				pageSize: 9999999,
				sortingText: '',
				order: 'asc',
				finder: '',
			};
			const response: any = await dispatch(performanceCycleExcludeEmployeeList(requestPayload));
			if (response) {
				if (response?.data && response?.data?.status === 200) {
					const { records } = response?.data?.entity || {};

					setLoader(false);
					setPerformanceFormData({
						...staticPerformDataObj,
						scoreCalculatedUponRadio: 5,
						scoreCalculatedUpon: 5,
						scoreCalculatedUponText: 5,
						excludedUsersList: records || [],
						excludedEmployeeId: records && records.length > 0 ? records.map((item: any) => item.employeeId) : [],
					});
					setExcludedUseList({
						selectedUser: records || [],
						raisedForId: records && records.length > 0 ? records.map((item: any) => item.employeeId) : [],
					});
					setIsDraftCreated(false);
				} else {
					setLoader(false);
					setPerformanceFormData({
						...staticPerformDataObj,
						scoreCalculatedUponRadio: 5,
						scoreCalculatedUpon: 5,
						scoreCalculatedUponText: 5,
						excludedUsersList: [],
						excludedEmployeeId: [],
					});
					setExcludedUseList(staticData);
					setIsDraftCreated(false);
				}
			} else {
				setLoader(false);
				setPerformanceFormData({
					...staticPerformDataObj,
					scoreCalculatedUponRadio: 5,
					scoreCalculatedUpon: 5,
					scoreCalculatedUponText: 5,
					excludedUsersList: [],
					excludedEmployeeId: [],
				});
				setExcludedUseList(staticData);
				setIsDraftCreated(false);
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};

	const handleRatingInputStreamsCheckClick = (e: any, name: any, checked: any) => {
		e.preventDefault();
		if (
			type === 'Add' ||
			type === 'Clone' ||
			(type === 'Edit' && (editableDetails?.status === Enums.ONE || editableDetails?.status === Enums.FOUR))
		) {
			const totalValue = 100;
			let count = 0;
			let updatedData = { ...performanceFormData, [name]: checked };
			if (checkSupportPermissionType(supportModuleAlignOkr)) {
				if (updatedData?.isOkrProgressPercentage) {
					count = count + 1;
				}
			}
			if (updatedData?.isSelfRatingPercentage) {
				count = count + 1;
			}
			if (updatedData?.isManagerRatingPercentage) {
				count = count + 1;
			}
			if (updatedData?.isThreeSixtyEnabled) {
				count = count + 1;
				updatedData.isVisibleThreeSixtyCompleted = true;
			} else {
				updatedData.isVisibleThreeSixtyCompleted = false;
			}

			if (count !== 0) {
				if (count === 4) {
					updatedData.okrProgressPercentage = 25;
					updatedData.selfRatingPercentage = 25;
					updatedData.managerRatingPercentage = 25;
					updatedData.threeSixtyPercentage = 25;
				} else {
					const values = totalValue / count;
					if (updatedData?.isOkrProgressPercentage) {
						updatedData.okrProgressPercentage = values;
					} else {
						updatedData.okrProgressPercentage = 0;
					}
					if (updatedData?.isSelfRatingPercentage) {
						updatedData.selfRatingPercentage = values;
					} else {
						updatedData.selfRatingPercentage = 0;
					}
					if (updatedData?.isThreeSixtyEnabled) {
						updatedData.threeSixtyPercentage = values;
					} else {
						updatedData.threeSixtyPercentage = 0;
					}

					updatedData.managerRatingPercentage = values;
				}
			}

			setPerformanceFormData({ ...updatedData });
		}
	};

	const checkIsPublishEnableOrNot = () => {
		let isPublish = false;
		if (performanceFormData?.performanceReviewName && performanceFormData?.managerRatingPercentage) {
			if (performanceFormData?.isOkrProgressPercentage) {
				if (performanceFormData?.okrProgressPercentage) {
					isPublish = anotherLevelCheckForPublish(isPublish);
				} else {
					isPublish = false;
				}
			} else {
				isPublish = anotherLevelCheckForPublish(isPublish);
			}

			if (performanceFormData?.isSelfRatingPercentage) {
				if (performanceFormData?.selfRatingPercentage && selfRatingDuration) {
					isPublish = anotherLevelCheckForPublish(isPublish);
				} else {
					isPublish = false;
				}
			} else {
				isPublish = anotherLevelCheckForPublish(isPublish);
			}

			if (performanceFormData?.isThreeSixtyEnabled) {
				if (performanceFormData?.threeSixtyPercentage && threeSixtyRatingDuration) {
					isPublish = anotherLevelCheckForPublish(isPublish);
				} else {
					isPublish = false;
				}
			} else {
				isPublish = anotherLevelCheckForPublish(isPublish);
			}
		} else {
			isPublish = false;
		}

		return isPublish;
	};
	const anotherLevelCheckForPublish = (isPublishValue: boolean) => {
		let isPublish = isPublishValue;
		if (
			selectedDateRange &&
			completeByDate &&
			managerRatingDuration &&
			finalRatingDuration &&
			totalValueCount === 100
		) {
			// isPublish = true;
			if (
				competencyRatingSettingTabValue === 0 &&
				selectedTemplateDetails?.map((item: any) => item?.ratingFormDetails).filter((item: any) => item).length ===
					performanceReviewRoleList?.entityList?.length
			) {
				isPublish = true;
			} else if (
				competencyRatingSettingTabValue === 1 &&
				selectedCoreValueTemplateDetails?.map((item: any) => item?.ratingFormDetails).filter((item: any) => item)
					.length === performanceReviewCoreValueList?.entityList?.length
			) {
				isPublish = true;
			} else {
				isPublish = false;
			}
		} else {
			isPublish = false;
		}

		return isPublish;
	};

	const getPerformanceReviewCycle = async () => {
		const minDefaultDateValue = new Date();
		minDefaultDateValue.setMonth(minDefaultDateValue.getMonth() - 18);
		setMinDateValue(minDefaultDateValue);
		/*
		try {
			const response: any = await dispatch(performanceCycleCycle());
			if (response && response?.data && response?.data?.status === 200) {
				const { entityList } = response?.data;
				if (entityList && entityList.length) {
					const sortedList = sortArrayOnDate(entityList, 'startDate');
					if (sortedList && sortedList.length > 0) {
						const nextDay = sortedList[0].startDate ? new Date(sortedList[0].startDate) : new Date(minDefaultDateValue);
						nextDay.setDate(nextDay.getDate() + 1);
						setMinDateValue(nextDay);
					} else {
						setMinDateValue(minDefaultDateValue);
					}
				} else {
					setMinDateValue(minDefaultDateValue);
				}
			} else {
				setMinDateValue(minDefaultDateValue);
			}
		} catch (error) {
			setMinDateValue(minDefaultDateValue);
		}
		*/
	};

	const performanceCycleExcludeEmployeeListDetails = async (cycleDetails: any) => {
		setPerformanceFormData({
			performanceReviewName: cycleDetails?.name || '',
			excludedUsersList: cycleDetails?.excludedUsersList || [],
			excludedEmployeeId: cycleDetails?.excludedEmployeeId || [],
			isOneToOneReview: cycleDetails?.isOneToOneReview || false,
			isOkrProgressPercentage: checkSupportPermissionType(supportModuleAlignOkr)
				? cycleDetails?.isOkrProgressPercentage || false
				: false,
			okrProgressPercentage: checkSupportPermissionType(supportModuleAlignOkr)
				? cycleDetails?.okrProgressPercentage || 0
				: 0,
			isSelfRatingPercentage: cycleDetails?.isSelfRatingPercentage || false,
			selfRatingPercentage: cycleDetails?.selfRatingPercentage || 0,
			isManagerRatingPercentage: true,
			managerRatingPercentage: cycleDetails?.managerRatingPercentage || 100,
			isThreeSixtyEnabled: cycleDetails?.isThreeSixtyEnabled || false,
			threeSixtyPercentage: cycleDetails?.threeSixtyPercentage || 0,
			finalRatingPercentage: 100,
			isVisibleIfEachCompleted: cycleDetails?.isVisibleIfEachCompleted || false,
			isVisibleIfFinalCompleted: cycleDetails?.isVisibleIfFinalCompleted || false,
			isVisibleOnlyFinalIfFinalCompleted: cycleDetails?.isVisibleOnlyFinalIfFinalCompleted || false,
			isVisibleThreeSixtyCompleted: cycleDetails?.isVisibleThreeSixtyCompleted || false,
			scoreCalculatedUponText: cycleDetails?.scoreCalculatedUpon,
			scoreCalculatedUpon: cycleDetails?.scoreCalculatedUpon,
			isFinalCommentMandatory: cycleDetails?.isFinalCommentMandatory,
			isShowFinalRatingField: cycleDetails?.isShowFinalRatingField,
		});
		setExcludedUseList({
			selectedUser: cycleDetails?.excludedUsersList || [],
			raisedForId: cycleDetails?.excludedEmployeeId || [],
		});
		setSelectedDateRange(
			cycleDetails?.startDate && cycleDetails?.endDate
				? moment().range(new Date(cycleDetails?.startDate), new Date(cycleDetails?.endDate))
				: null
		);
		setCompleteBy(cycleDetails?.reviewDate ? moment(new Date(cycleDetails?.reviewDate)) : null);

		setSelfRatingDuration(
			cycleDetails?.selfRatingStartDate && cycleDetails?.selfRatingEndDate
				? moment().range(new Date(cycleDetails?.selfRatingStartDate), new Date(cycleDetails?.selfRatingEndDate))
				: null
		);
		setManagerRatingDuration(
			cycleDetails?.managerRatingStartDate && cycleDetails?.managerRatingEndDate
				? moment().range(new Date(cycleDetails?.managerRatingStartDate), new Date(cycleDetails?.managerRatingEndDate))
				: null
		);
		setThreeSixtyRatingDuration(
			cycleDetails?.finalRatingStartDate && cycleDetails?.threeSixtyEndDate
				? moment().range(new Date(cycleDetails?.finalRatingStartDate), new Date(cycleDetails?.threeSixtyEndDate))
				: null
		);
		setFinalRatingDuration(
			cycleDetails?.selfRatingStartDate && cycleDetails?.finalRatingEndDate
				? moment().range(new Date(cycleDetails?.selfRatingStartDate), new Date(cycleDetails?.finalRatingEndDate))
				: null
		);

		const diff = moment(new Date(formatDate(cycleDetails?.endDate))).diff(new Date(formatDate(new Date()))) >= 0;
		if (diff) {
			setCompleteByMinDateValue(new Date(cycleDetails?.endDate));
			setMinDateValueForAssessmentInputs(new Date(cycleDetails?.startDate));
		} else {
			setCompleteByMinDateValue(new Date());
			setMinDateValueForAssessmentInputs(new Date());
		}
	};
	const handleDateOverlapCheck = async (range: any, start: any, end: any) => {
		if (range && start && end) {
			setLoader(true);
			setCycleDurationError(staticErrorObj);
			const diff = moment(new Date(formatDate(end))).diff(new Date(formatDate(new Date()))) >= 0;

			const queryParams = `startDate=${formatDate(start)}&endDate=${formatDate(end)}&performanceCycleId=${
				editableDetails?.performanceCycleId || 0
			}`;
			const response: any = await dispatch(cycleDateOverlap(queryParams));
			if (response) {
				if (response?.data && response?.data?.status === 200) {
					const { entity, isSuccess } = response?.data || {};
					if (entity && isSuccess) {
						range && setSelectedDateRange(range);
						setCompleteBy(null);
						setSelfRatingDuration(null);
						setManagerRatingDuration(null);
						setThreeSixtyRatingDuration(null);
						setFinalRatingDuration(null);
						if (diff) {
							setCompleteByMinDateValue(new Date(end));
							setMinDateValueForAssessmentInputs(new Date(start));
						} else {
							setCompleteByMinDateValue(new Date());
							setMinDateValueForAssessmentInputs(new Date());
						}
						setIsPerformanceFormEdited(true);
					} else {
						const { messageList } = response.data;
						const keys = Object.keys(messageList);
						const messages = keys.map((item) => messageList[item]);
						enqueueSnackbar(messages || t('commonErrorMsg'), {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || t('commonErrorMsg'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
					// setCycleDurationError({
					// 	error: 'Please enter Cycle Duration Date',
					// 	helperText: 'Please enter Cycle Duration Date',
					// 	type: 'startDate',
					// });
				}
			} else {
				enqueueSnackbar(t('commonErrorMsg'), {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		}
	};
	const handlePerformancePeriodDateOverlapCheck = async (range: any, start: any, end: any) => {
		if (range && start && end) {
			setLoader(true);
			setCycleDurationError(staticErrorObj);
			const diff = moment(new Date(formatDate(end))).diff(new Date(formatDate(new Date()))) >= 0;

			const queryParams = `startDate=${formatDate(start)}&endDate=${formatDate(end)}&performanceCycleId=${
				editableDetails?.performanceCycleId || 0
			}&isOkrPeriod=true`;
			const response: any = await dispatch(cycleDateOverlap(queryParams));
			if (response) {
				if (response?.data && response?.data?.status === 200) {
					const { entity, isSuccess } = response?.data || {};
					if (entity && isSuccess) {
						setPerformancePeriodByError({
							error: '',
							helperText: '',
							type: '',
						});
						setSelectedPerformacePeriodDateRange(range);
						setIsPerformanceFormEdited(true);
					} else {
						setModalProps({
							open: true,
							message: t('performancePeriodCheck'),
							details: '',
							type: 'performancePeriod',
							data: {
								range: range,
							},
						});
					}
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || t('commonErrorMsg'), {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				enqueueSnackbar(t('commonErrorMsg'), {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		}
	};
	const moveScrollToBox = (elementId: string) => {
		const element = document.getElementById(elementId);
		if (element) {
			element?.scrollIntoView({ behavior: 'smooth' });
		}
	};
	const validateRoleForm = () => {
		let valid = true;
		let errorOn: any = {};
		if (toggleCoreClick) {
			let invalidIds: any[] = [];
			if (!assessmentFormSetting[0]) {
				valid = false;
				invalidIds = [1, 2, 3];
			} else {
				if (!assessmentFormSetting[0][2]) {
					valid = false;
					invalidIds.push(2);
				}
				if (performanceFormData.isSelfRatingPercentage && !assessmentFormSetting[0][1]) {
					valid = false;
					invalidIds.push(1);
				}
				if (performanceFormData.isThreeSixtyEnabled && !assessmentFormSetting[0][3]) {
					valid = false;
					invalidIds.push(3);
				}
			}
			if (valid === false) {
				errorOn[0] = invalidIds;
			}
		} else {
			performanceReviewRoleList?.entityList.forEach((element: any) => {
				let inValid = true;
				let invalidIds: any[] = [];
				if (!assessmentFormSetting[element.performanceRoleId]) {
					valid = false;
					inValid = false;
					invalidIds = [1, 2, 3];
				} else {
					if (!assessmentFormSetting[element.performanceRoleId][2]) {
						valid = false;
						inValid = false;
						invalidIds.push(2);
					}
					if (performanceFormData.isSelfRatingPercentage && !assessmentFormSetting[element.performanceRoleId][1]) {
						valid = false;
						inValid = false;
						invalidIds.push(1);
					}
					if (performanceFormData.isThreeSixtyEnabled && !assessmentFormSetting[element.performanceRoleId][3]) {
						valid = false;
						inValid = false;
						invalidIds.push(3);
					}
				}
				if (inValid === false) {
					errorOn[element.performanceRoleId] = invalidIds;
				}
			});
		}
		return { valid, errorOn };
	};
	const validateVisibility = () => {
		let valid = true;
		if (performanceFormData.isSelfRatingPercentage) {
			let selfData = performanceVisibility?.filter((item: any) => item.assessmentType === 1);
			if (selfData.length > 0) {
				if (!selfData[0].finalScoreVisibleFrom) {
					valid = false;
				}
			}
		}
		return valid;
	};
	const validateForm = (statusType: number) => {
		let returnFlag: boolean = true;
		if (!performanceFormData?.performanceReviewName) {
			setPerformanceReviewError({
				error: t('performanceReviewErrorMsg'),
				helperText: t('performanceReviewErrorMsg'),
				type: 'performanceReviewName',
			});
			moveScrollToBox('general_setting_scroll');

			returnFlag = false;
		}
		if (!selectedDateRange) {
			setCycleDurationError({
				error: t('cycleDurationErrorMsg'),
				helperText: t('cycleDurationErrorMsg'),
				type: 'startDate',
			});
			moveScrollToBox('general_setting_scroll');

			returnFlag = false;
		}
		if (!completeByDate) {
			setCompleteByError({
				error: t('completeByErrorMsg'),
				helperText: t('completeByErrorMsg'),
				type: 'reviewDate',
			});
			moveScrollToBox('general_setting_scroll');

			returnFlag = false;
		}
		if (!selectedPerformacePeriodDateRange) {
			setPerformancePeriodByError({
				error: t('completeByErrorMsg'),
				helperText: t('performancePeriodErrorMsg'),
				type: 'performancePeriod',
			});
			moveScrollToBox('general_setting_scroll');

			returnFlag = false;
		}
		if (
			(!performanceFormData.scoreCalculatedUpon ||
				performanceFormData.scoreCalculatedUpon > 100 ||
				performanceFormData.scoreCalculatedUpon < 5) &&
			statusType !== 1
		) {
			setCalculatedError(true);
			if (returnFlag) {
				moveScrollToBox('performance_input_stream_scroll');
			}
			returnFlag = false;
		}
		let validData: any = validateRoleForm();
		if (statusType === 2 && !validData.valid) {
			setAssessmentError({
				error: t('cycleDurationErrorMsg'),
				helperText: t('cycleDurationErrorMsg'),
				type: 'assessmentFormSetting',
				errorOn: validData.errorOn,
			});
			if (returnFlag) {
				moveScrollToBox('assessment-form-role-settings-sec');
			}
			returnFlag = false;
		}
		let validVisibilityData: any = validateVisibility();
		if (!validVisibilityData && statusType !== 1) {
			setVisibilityError({
				error: t('cycleDurationErrorMsg'),
				helperText: t('cycleDurationErrorMsg'),
				type: 'assessmentVisibility',
			});
			if (returnFlag) {
				moveScrollToBox('assessment-form-role-settings-sec');
			}
			returnFlag = false;
		}
		if (statusType === 2 && isGeneralSettingError()) {
			setFinalRatingTotalError({
				helperText: t('finalRatingDurationErrorMsg'),
				type: 'finalRatingTotal',
			});
			if (returnFlag) {
				moveScrollToBox('performance_input_stream_scroll');
			}
			returnFlag = false;
		}
		let visibilityReturnFlag = validateAssessmentDuration(statusType);
		return returnFlag && visibilityReturnFlag;
		// if (statusType !== 1 && totalValueCount !== 100) {
		// 	return false;
		// }
	};
	const validateAssessmentDuration = (statusType: number) => {
		let returnFlag: boolean = true;
		if (statusType !== 1 && !selfRatingDuration && performanceFormData?.isSelfRatingPercentage) {
			setSelfRatingDurationError({
				helperText: t('selfRatingDurationErrorMsg'),
				type: 'selfAssessmentDate',
			});
			moveScrollToBox('performance_input_stream_scroll');

			returnFlag = false;
		}
		if (statusType !== 1 && !managerRatingDuration) {
			setManagerRatingDurationError({
				helperText: t('managerRatingDurationErrorMsg'),
				type: 'managerAssessmentDate',
			});
			returnFlag = false;
		}
		if (statusType !== 1 && !threeSixtyRatingDuration && performanceFormData?.isThreeSixtyEnabled) {
			setThreeSixtyRatingDurationError({
				helperText: t('threeSixtyRatingDurationErrorMsg'),
				type: 'threeSixtyAssessmentDate',
			});
			moveScrollToBox('performance_input_stream_scroll');

			returnFlag = false;
		}
		if (statusType !== 1 && !finalRatingDuration) {
			setFinalRatingDurationError({
				helperText: t('finalRatingDurationErrorMsg'),
				type: 'finalRatingDate',
			});
			moveScrollToBox('performance_input_stream_scroll');

			returnFlag = false;
		}
		// else {
		// 	returnFlag = true;
		// }
		return returnFlag;
	};

	const validateCompetencyRatingForm = (statusType: number) => {
		if (
			statusType !== 1 &&
			competencyRatingSettingTabValue === 0 &&
			selectedTemplateDetails.length !== performanceReviewRoleList?.entityList?.length
		) {
			setCompetencyRatingFormError({
				error: t('competencyRatingFormErrorMsg'),
				helperText: t('competencyRatingFormErrorMsg'),
				type: 'ratingForm',
			});

			return false;
		}

		if (
			statusType !== 1 &&
			competencyRatingSettingTabValue === 1 &&
			selectedCoreValueTemplateDetails.length !== performanceReviewCoreValueList?.entityList?.length
		) {
			setCompetencyRatingFormError({
				error: t('competencyRatingFormErrorMsg'),
				helperText: t('competencyRatingFormErrorMsg'),
				type: 'ratingForm',
			});

			return false;
		} else {
			return true;
		}
	};
	const submitPerformanceReviewCreateRequest = async (statusType: number) => {
		if (validateForm(statusType) && totalValueCount === 100 && !isAssessmentVisibilitySettingSelected) {
			setLoader(true);
			setCycleDurationError(staticErrorObj);
			setCompleteByError(staticErrorObj);
			setPerformancePeriodByError(staticErrorObj);
			setCompetencyRatingFormError(staticErrorObj);
			setSelfRatingDurationError(staticErrorObj);
			setManagerRatingDurationError(staticErrorObj);
			setThreeSixtyRatingDurationError(staticErrorObj);
			setFinalRatingDurationError(staticErrorObj);

			const startDate = selectedDateRange?.start?._d || '';
			const endDate = selectedDateRange?.end?._d || '';
			const reviewDate = completeByDate?._d || '';

			const selfStartDate = selfRatingDuration?.start?._d || '';
			const selfEndDate = selfRatingDuration?.end?._d || '';
			const managerStartDate = managerRatingDuration?.start?._d || '';
			const managerEndDate = managerRatingDuration?.end?._d || '';
			const threeSixtyStartDate = threeSixtyRatingDuration?.start?._d || '';
			const threeSixtyEndDate = threeSixtyRatingDuration?.end?._d || '';
			const finalStartDate = finalRatingDuration?.start?._d || '';
			const finalEndDate = finalRatingDuration?.end?._d || '';
			const performancePeriodStartDate = selectedPerformacePeriodDateRange?.start?._d || '';
			const performancePeriodEndDate = selectedPerformacePeriodDateRange?.end?._d || '';

			const requestPayload: any = {
				name: performanceFormData?.performanceReviewName ? performanceFormData.performanceReviewName.trim() : '',
				startDate: startDate ? formatFeedbackDate(startDate) : '',
				endDate: endDate ? formatFeedbackDate(endDate) : '',
				performancePeriodStartDate: performancePeriodStartDate ? formatFeedbackDate(performancePeriodStartDate) : null,
				performancePeriodEndDate: performancePeriodEndDate ? formatFeedbackDate(performancePeriodEndDate) : null,
				reviewDate: reviewDate ? formatFeedbackDate(reviewDate) : '',
				isOneToOneReview: performanceFormData?.isOneToOneReview || false,
				scoreCalculatedUpon: performanceFormData.scoreCalculatedUpon || editableDetails.scoreCalculatedUpon,
				isOkrProgressPercentage: performanceFormData?.isOkrProgressPercentage || false,
				okrProgressPercentage: parseFloat(performanceFormData?.okrProgressPercentage) || 0,
				isSelfRatingPercentage: performanceFormData?.isSelfRatingPercentage || false,
				selfRatingPercentage: parseFloat(performanceFormData?.selfRatingPercentage) || 0,
				managerRatingPercentage: parseFloat(performanceFormData?.managerRatingPercentage) || 0,
				ratingFormType: toggleCoreClick ? 2 : 1,
				performanceRoleLinkedFormRequest: [],
				performanceVisibiltySettingRequest: performanceVisibility, //filter
				performanceCycleFormVisibiltySettingRequest: performanceFormVisibility,
				excludedEmployeeId: performanceFormData?.excludedEmployeeId,
				status: statusType == 2 ? 4 : statusType,
				isThreeSixtyEnabled: performanceFormData?.isThreeSixtyEnabled || false,
				threeSixtyPercentage: parseFloat(performanceFormData?.threeSixtyPercentage) || 0,
				threeSixtyStartDate: threeSixtyStartDate ? formatFeedbackDate(threeSixtyStartDate) : null,
				threeSixtyEndDate: threeSixtyEndDate ? formatFeedbackDate(threeSixtyEndDate) : null,
				selfRatingStartDate: selfStartDate ? formatFeedbackDate(selfStartDate) : null,
				selfRatingEndDate: selfEndDate ? formatFeedbackDate(selfEndDate) : null,
				managerRatingStartDate: managerStartDate ? formatFeedbackDate(managerStartDate) : null,
				managerRatingEndDate: managerEndDate ? formatFeedbackDate(managerEndDate) : null,
				finalRatingStartDate: finalStartDate ? formatFeedbackDate(finalStartDate) : null,
				finalRatingEndDate: finalEndDate ? formatFeedbackDate(finalEndDate) : null,
				isVisibleIfEachCompleted: performanceFormData?.isVisibleIfEachCompleted || false,
				isVisibleIfFinalCompleted: performanceFormData?.isVisibleIfFinalCompleted || false,
				isVisibleOnlyFinalIfFinalCompleted: performanceFormData?.isVisibleOnlyFinalIfFinalCompleted || false,
				isVisibleThreeSixtyCompleted: performanceFormData?.isVisibleThreeSixtyCompleted || false,
				isFinalCommentMandatory: performanceFormData.isFinalCommentMandatory || false,
				isShowFinalRatingField: performanceFormData.isShowFinalRatingField || false,
			};

			let performanceVisibilityCopy = [...performanceVisibility];
			let performanceVisibilityFinalData: any[] = [];
			performanceVisibilityCopy.forEach((item: any) => {
				let element = Object.assign({}, item);

				if (!performanceFormData.isSelfRatingPercentage && element.assessmentType === 1) {
					/*element = {
						...{
							assessmentType: 1,
							selfScoreVisibilty: 2,
							managerScoreVisibilty: 6,
							threeSixtyScoreVisibilty: 6,
							finalScoreVisibilty: 0,
							finalScoreVisibleFrom: null,
						},
						finalScoreVisibleFrom: item.finalScoreVisibleFrom,
					};*/
					performanceVisibilityFinalData.push(element);
				} else if (!performanceFormData.isThreeSixtyEnabled && element.assessmentType === 3) {
					element = {
						...{
							assessmentType: 3,
							selfScoreVisibilty: 1,
							managerScoreVisibilty: 1,
							threeSixtyScoreVisibilty: 4,
							finalScoreVisibilty: 1,
						},
					};
					performanceVisibilityFinalData.push(element);
				} else {
					if (!performanceFormData.isSelfRatingPercentage) {
						delete element['selfScoreVisibilty'];
					}
					if (!performanceFormData.isThreeSixtyEnabled) {
						delete element['threeSixtyScoreVisibilty'];
					}
					performanceVisibilityFinalData.push(element);
				}
			});
			const keys = Object.keys(assessmentFormSetting);
			let performanceAssessmentFinalData: any[] = [];
			if (keys.length > 0) {
				keys.forEach((performanceKey: any) => {
					const roleKeys = Object.keys(assessmentFormSetting[performanceKey]);
					roleKeys.forEach((roleKey: any) => {
						let roleKeyVal = parseInt(roleKey);
						let element = Object.assign({}, assessmentFormSetting[performanceKey][roleKey]);
						if (
							(!performanceFormData.isSelfRatingPercentage && roleKeyVal === 1) ||
							(!performanceFormData.isThreeSixtyEnabled && roleKeyVal === 3)
						) {
						} else {
							element.performanceRoleLinkedFormSectionRequest = element.ratingFormSectionResponse;
							performanceAssessmentFinalData.push(element);
						}
					});
				});
			}
			requestPayload.performanceVisibiltySettingRequest = performanceVisibilityFinalData;
			requestPayload.performanceRoleLinkedFormRequest = performanceAssessmentFinalData;

			if (type === 'Edit' && editableDetails) {
				requestPayload.performanceCycleId = editableDetails?.performanceCycleId || 0;
			}
			//return false;
			handlePerformanceReviewForm(requestPayload, type);
		}
	};
	const handlePerformanceReviewValueChange = (event: any) => {
		const { value, name, checked } = event?.target;
		const regexp = /^[0-9\\.b]+$/;

		if (name === 'performanceReviewName' && value) {
			setPerformanceReviewError({
				error: '',
				helperText: '',
				type: '',
			});
		}

		if (name === 'isOneToOneReview') {
			setIsPerformanceFormEdited(true);
			setPerformanceFormData({ ...performanceFormData, [name]: checked });
		} else if (name === 'isOkrProgressPercentage') {
			// setPerformanceFormData({
			// 	...performanceFormData,
			// 	[name]: checked,
			// 	// okrProgressPercentage: checked ? performanceFormData?.okrProgressPercentage : 0,
			// });
			setIsPerformanceFormEdited(true);
			handleRatingInputStreamsCheckClick(event, name, checked);
		} else if (name === 'isSelfRatingPercentage') {
			// setPerformanceFormData({
			// 	...performanceFormData,
			// 	[name]: checked,
			// 	// selfRatingPercentage: checked ? performanceFormData?.selfRatingPercentage : 0,
			// });
			setIsPerformanceFormEdited(true);
			handleRatingInputStreamsCheckClick(event, name, checked);
		} else if (name === 'isThreeSixtyEnabled') {
			// setPerformanceFormData({
			// 	...performanceFormData,
			// 	[name]: checked,
			// 	// selfRatingPercentage: checked ? performanceFormData?.selfRatingPercentage : 0,
			// });
			setIsPerformanceFormEdited(true);
			handleRatingInputStreamsCheckClick(event, name, checked);
		} else if (
			name === 'okrProgressPercentage' ||
			name === 'selfRatingPercentage' ||
			name === 'managerRatingPercentage' ||
			name === 'threeSixtyPercentage'
		) {
			if (value === '' || regexp.test(value)) {
				setIsPerformanceFormEdited(true);
				setPerformanceFormData({ ...performanceFormData, [name]: value });
				setFinalRatingTotalError(staticErrorObj);
			}
		} else if (name === 'scoreCalculatedUponText') {
			const regexpNumber = /^[0-9\b]+$/;
			if (value === '' || (regexpNumber.test(value) && value <= 100)) {
				setIsPerformanceFormEdited(true);
				setPerformanceFormData({
					...performanceFormData,
					[name]: value,
					scoreCalculatedUpon: value,
					scoreCalculatedUponRadio: '',
				});
			} else {
				setPerformanceFormData({
					...performanceFormData,
					[name]: performanceFormData[name],
					scoreCalculatedUponRadio: '',
					scoreCalculatedUpon: performanceFormData[name],
				});
			}
			setCalculatedError(false);
		} else if (
			name === 'isVisibleIfEachCompleted' ||
			name === 'isVisibleIfFinalCompleted' ||
			name === 'isVisibleOnlyFinalIfFinalCompleted' ||
			name === 'isVisibleThreeSixtyCompleted'
		) {
			setIsPerformanceFormEdited(true);
			if (name === 'isVisibleIfEachCompleted') {
				setPerformanceFormData({
					...performanceFormData,
					[name]: checked,
					isVisibleIfFinalCompleted: false,
					isVisibleOnlyFinalIfFinalCompleted: false,
				});
			} else if (name === 'isVisibleIfFinalCompleted' && checked) {
				setPerformanceFormData({ ...performanceFormData, [name]: checked, isVisibleOnlyFinalIfFinalCompleted: false });
			} else if (name === 'isVisibleOnlyFinalIfFinalCompleted' && checked) {
				setPerformanceFormData({ ...performanceFormData, [name]: checked, isVisibleIfFinalCompleted: false });
			} else {
				setPerformanceFormData({ ...performanceFormData, [name]: checked });
			}
		}
		// else if (name === 'isManagerRatingPercentage') {
		// 	setPerformanceFormData({ ...performanceFormData, [name]: checked });
		// }
		else {
			setIsPerformanceFormEdited(true);
			setPerformanceFormData({ ...performanceFormData, [name]: value });
		}
	};
	const savePerformanceAsDraft = (e: any) => {
		!loader && submitPerformanceReviewCreateRequest(1);
	};
	const savePerformanceAsPublish = (e: any) => {
		!loader && submitPerformanceReviewCreateRequest(2);
	};
	const deletePerformance = (e: any) => {
		!loader && handleDeletePerformanceReviewForm(performanceFormData, editableDetails, type);
	};
	const handleBackButtonOnClick = () => {
		// console.log('handleBackButtonOnClick ===>', isPerformanceFormEdited);
		if (isPerformanceFormEdited) {
			setModalProps({ open: true, message: t('unSavedItemAlert'), details: '', type: '' });
		} else {
			setCycleDurationError(staticErrorObj);
			setCompleteByError(staticErrorObj);
			setPerformancePeriodByError(staticErrorObj);
			setCompetencyRatingFormError(staticErrorObj);
			setSelfRatingDurationError(staticErrorObj);
			setManagerRatingDurationError(staticErrorObj);
			setThreeSixtyRatingDurationError(staticErrorObj);
			setFinalRatingDurationError(staticErrorObj);
			setIsPerformanceFormEdited(false);
			handleBackButtonClick();
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'performancePeriod') {
				setSelectedPerformacePeriodDateRange(modalProps.data.range);
				setIsPerformanceFormEdited(true);
				setPerformancePeriodByError({
					error: '',
					helperText: '',
					type: '',
				});
			} else {
				setCycleDurationError(staticErrorObj);
				setCompleteByError(staticErrorObj);
				setPerformancePeriodByError(staticErrorObj);
				setCompetencyRatingFormError(staticErrorObj);
				setSelfRatingDurationError(staticErrorObj);
				setManagerRatingDurationError(staticErrorObj);
				setThreeSixtyRatingDurationError(staticErrorObj);
				setFinalRatingDurationError(staticErrorObj);
				setIsPerformanceFormEdited(false);
				handleBackButtonClick();
			}
			setModalProps({ open: false, message: '', details: '', type: '' });
		} else {
			if (modalProps.type === 'performancePeriod') {
				setPerformancePeriodByError({
					error: t('completeByErrorMsg'),
					helperText: t('performancePeriodErrorMsg'),
					type: 'performancePeriod',
				});
			}
			setModalProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const handleCompleteByDateChange = (range: any, start: any) => {
		setCompleteBy(range);
		setIsPerformanceFormEdited(true);
		const finalRatingDiff =
			moment(new Date(formatDate(finalRatingDuration?.end))).diff(new Date(formatDate(new Date(start)))) > 0;
		if (finalRatingDiff) {
			setFinalRatingDuration(moment().range(new Date(finalRatingDuration?.start), new Date(start)));
		}
		const threeSixtyRatingDiff =
			moment(new Date(formatDate(threeSixtyRatingDuration?.end))).diff(new Date(formatDate(new Date(start)))) > 0;
		if (threeSixtyRatingDiff) {
			setThreeSixtyRatingDuration(moment().range(new Date(threeSixtyRatingDuration?.start), new Date(start)));
		}
		const managerRatingDiff =
			moment(new Date(formatDate(managerRatingDuration?.end))).diff(new Date(formatDate(new Date(start)))) > 0;
		if (managerRatingDiff) {
			setManagerRatingDuration(moment().range(new Date(managerRatingDuration?.start), new Date(start)));
		}
		const selfRatingDiff =
			moment(new Date(formatDate(selfRatingDuration?.end))).diff(new Date(formatDate(new Date(start)))) > 0;
		if (selfRatingDiff) {
			setSelfRatingDuration(moment().range(new Date(selfRatingDuration?.start), new Date(start)));
		}
	};

	const checkIfDraftGracePeriodEdited = (range: any, start: any) => {
		if (type === 'Edit' && !isGracePeriodEdited) {
			setIsGracePeriodEdited(true);
			setModalPropsGracePeriodDate({ open: true, data: { range, start } });
		} else {
			handleCompleteByDateChange(range, start);
		}
	};
	const handleCloseGraceModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			handleCompleteByDateChange(modalPropsGracePeriodDate.data.range, modalPropsGracePeriodDate.data.start);
		}
		setModalPropsGracePeriodDate({ open: false, data: {} });
	};
	const isGeneralSettingError = () => {
		if (performanceReviewError?.type === 'performanceReviewName' && performanceReviewError?.helperText) {
			return true;
		}
		if (cycleDurationError?.type === 'startDate' && cycleDurationError?.helperText) {
			return true;
		}
		if (completeByError?.type === 'reviewDate' && completeByError?.helperText) {
			return true;
		}
		if (performancePeriodByError?.type === 'reviewDate' && performancePeriodByError?.helperText) {
			return true;
		}

		return false;
	};
	const isPerformanceInputStreamError = () => {
		if (selfRatingDurationError?.type === 'selfAssessmentDate' && selfRatingDurationError?.helperText) {
			return true;
		}
		if (managerRatingDurationError?.type === 'managerAssessmentDate' && managerRatingDurationError?.helperText) {
			return true;
		}
		if (
			threeSixtyRatingDurationError?.type === 'threeSixtyAssessmentDate' &&
			threeSixtyRatingDurationError?.helperText
		) {
			return true;
		}
		if (finalRatingDurationError?.type === 'finalRatingDate' && finalRatingDurationError?.helperText) {
			return true;
		}

		return false;
	};
	const isCompetencyRatingFormError = () => {
		if (
			competencyRatingFormError &&
			competencyRatingFormError?.type === 'ratingForm' &&
			competencyRatingFormError?.helperText
		) {
			return true;
		}
		return false;
	};

	const handleScoreChange = (e: any, value: any) => {
		setIsPerformanceFormEdited(true);
		setCalculatedError(false);
		setPerformanceFormData({
			...performanceFormData,
			scoreCalculatedUpon: parseInt(value),
			scoreCalculatedUponRadio: parseInt(value),
			scoreCalculatedUponText: '',
		});
	};
	const handleFinalPublishDateTo = (value: any) => {
		setScoreVisibilityDuration(value);
		setIsPerformanceFormEdited(true);
		let currentIndex = -1;
		let performanceVisibilityCopy = performanceVisibility.map((item: any, index: number) => {
			if (item.assessmentType === 1) {
				currentIndex = index;
			}
			return item;
		});
		if (currentIndex === -1) {
		} else {
			performanceVisibilityCopy[currentIndex]['finalScoreVisibleFrom'] = null;
		}
		setPerformanceVisibility(performanceVisibilityCopy);
	};
	return (
		<>
			<div ref={refObject}>
				{performanceFormData ? (
					<Box className='add-perform-review'>
						<Box className='admin-tabpanel-inner'>
							<Box className='admin-tabpanel-head'>
								<Box className='back-to-main'>
									<Typography variant='body2'>
										<Link id='back-btn' onClick={handleBackButtonOnClick}>
											<BackArrow /> {t('back')}
										</Link>
									</Typography>
								</Box>
								<Typography variant='h2'>
									{type === 'Add'
										? t('addPerformReview')
										: type === 'Edit'
										? t('editPerformReview')
										: type === 'Clone'
										? t('clonePerformReview')
										: t('viewPerformReview')}
								</Typography>
							</Box>
						</Box>
						<Box className='perform-card general-settings' id='general_setting_scroll'>
							<Box className='perform-card-head'>
								<Typography>{t('generalSettings')}</Typography>
							</Box>
							<GeneralSettings
								{...props}
								performanceFormData={performanceFormData}
								setPerformanceFormData={setPerformanceFormData}
								performanceReviewError={performanceReviewError}
								cycleDurationError={cycleDurationError}
								completeByError={completeByError}
								setCompleteByError={setCompleteByError}
								setPerformancePeriodByError={setPerformancePeriodByError}
								performancePeriodByError={performancePeriodByError}
								handlePerformanceReviewValueChange={handlePerformanceReviewValueChange}
								selectedDateRange={selectedDateRange}
								setSelectedDateRange={setSelectedDateRange}
								handleDateOverlapCheck={handleDateOverlapCheck}
								handlePerformancePeriodDateOverlapCheck={handlePerformancePeriodDateOverlapCheck}
								completeByDate={completeByDate}
								setCompleteBy={setCompleteBy}
								currentCycleDetails={currentCycleDetails}
								curCycleData={curCycleData}
								minDateValue={minDateValue}
								maxDateValue={maxDateValue}
								completeByMinDateValue={completeByMinDateValue}
								setIsPerformanceFormEdited={setIsPerformanceFormEdited}
								excludedUseList={excludedUseList}
								setExcludedUseList={setExcludedUseList}
								handleCompleteByDateChange={checkIfDraftGracePeriodEdited}
								selectedPerformacePeriodDateRange={selectedPerformacePeriodDateRange}
								setSelectedPerformacePeriodDateRange={setSelectedPerformacePeriodDateRange}
							/>
						</Box>
						<Box
							id='performance_input_stream_scroll'
							className={`perform-card perform-input-stream ${totalValueCount === 100 ? '' : 'iserror'}`}
						>
							<Box className='perform-card-head'>
								<Typography>{t('performanceInputsStream')}</Typography>
							</Box>
							{totalValueCount === 100 ? <></> : <InformationMessage messageText={t('totalPerformance')} icon={true} />}
							{/* <Box className='perform-score-switch'>
								<FormControlLabel
									control={
										<Switch
											name='isVisibleIfEachCompleted'
											checked={true}
											//onChange={handlePerformanceReviewValueChange}
										/>
									}
									label={t('generatePerformScores')}
								/>
							</Box> */}
							<Box className='perform-score-radio'>
								<FormControl>
									<FormLabel id='perform-score-radio-label'>{t('performScoreRadioLabel')}</FormLabel>
									<RadioGroup
										aria-labelledby='perform-score-buttons-label'
										name='perform-score-group'
										onChange={handleScoreChange}
									>
										<FormControlLabel
											value={5}
											disabled={
												editableDetails &&
												editableDetails?.status !== Enums.ONE &&
												editableDetails?.status !== Enums.FOUR
											}
											control={<Radio />}
											label='5'
											checked={performanceFormData.scoreCalculatedUponRadio === 5}
										/>
										<FormControlLabel
											value={10}
											disabled={
												editableDetails &&
												editableDetails?.status !== Enums.ONE &&
												editableDetails?.status !== Enums.FOUR
											}
											control={<Radio />}
											label='10'
											checked={performanceFormData.scoreCalculatedUponRadio === 10}
										/>
										<FormControlLabel
											value={15}
											disabled={
												editableDetails &&
												editableDetails?.status !== Enums.ONE &&
												editableDetails?.status !== Enums.FOUR
											}
											control={<Radio />}
											label='15'
											checked={performanceFormData.scoreCalculatedUponRadio === 15}
										/>
										<FormControlLabel
											value={20}
											disabled={
												editableDetails &&
												editableDetails?.status !== Enums.ONE &&
												editableDetails?.status !== Enums.FOUR
											}
											control={<Radio />}
											label='20'
											checked={performanceFormData.scoreCalculatedUponRadio === 20}
										/>
									</RadioGroup>
									<Box className='other-score'>
										<TextField
											id='other'
											value={performanceFormData?.scoreCalculatedUponText}
											fullWidth
											disabled={
												editableDetails &&
												editableDetails?.status !== Enums.ONE &&
												editableDetails?.status !== Enums.FOUR
											}
											placeholder={t('other')}
											//error={''}
											helperText={
												calculatedError && (
													<>
														<ErrorIcon />
														{t('otherValidation')}
													</>
												)
											}
											name={'scoreCalculatedUponText'}
											onChange={handlePerformanceReviewValueChange}
											InputLabelProps={{
												shrink: true,
											}}
											inputProps={{
												maxLength: Enums.FIVE,
												inputType: 'numberFieldProgress',
												inputComponent: NumberFormatComponent,
											}}
										/>
									</Box>
								</FormControl>
							</Box>
							<PerformanceInputStream
								{...props}
								performanceFormData={performanceFormData}
								setPerformanceFormData={setPerformanceFormData}
								handlePerformanceReviewValueChange={handlePerformanceReviewValueChange}
								minDateValueForAssessmentInputs={minDateValueForAssessmentInputs}
								setIsPerformanceFormEdited={setIsPerformanceFormEdited}
								maxDateValue={maxDateValue}
								completeByDate={completeByDate ? moment(completeByDate) : completeByDate}
								selectedDateRange={selectedDateRange}
								selfRatingDuration={selfRatingDuration}
								managerRatingDuration={managerRatingDuration}
								threeSixtyRatingDuration={threeSixtyRatingDuration}
								finalRatingDuration={finalRatingDuration}
								setSelfRatingDuration={setSelfRatingDuration}
								setManagerRatingDuration={setManagerRatingDuration}
								setThreeSixtyRatingDuration={setThreeSixtyRatingDuration}
								setFinalRatingDuration={setFinalRatingDuration}
								selfRatingDurationError={selfRatingDurationError}
								managerRatingDurationError={managerRatingDurationError}
								threeSixtyRatingDurationError={threeSixtyRatingDurationError}
								finalRatingDurationError={finalRatingDurationError}
								setSelfRatingDurationError={setSelfRatingDurationError}
								setManagerRatingDurationError={setManagerRatingDurationError}
								setThreeSixtyRatingDurationError={setThreeSixtyRatingDurationError}
								setFinalRatingDurationError={setFinalRatingDurationError}
								setFinalRatingTotalError={setFinalRatingTotalError}
								finalRatingTotalError={finalRatingTotalError}
								handleFinalPublishDateTo={handleFinalPublishDateTo}
								scoreVisibilityDuration={scoreVisibilityDuration}
								setPerformanceVisibility={setPerformanceVisibility}
								performanceVisibility={performanceVisibility}
							/>
							<Box className='setting-box-error'>
								{isPerformanceInputStreamError() ? (
									<Typography variant='body2'>
										<>
											<span>*</span>
											{t('finalRatingDurationErrorMsg')}
										</>
									</Typography>
								) : (
									<></>
								)}
							</Box>
						</Box>
						{/* <Box className={`perform-card perform-input-stream perform-visibility`}>
							<Box className='perform-card-head'>
								<Box>
									<Typography>{t('performVisibilitySettings')}</Typography>
									<Typography variant='subtitle2'>{t('performVisibilitySettingsSubHead')}</Typography>
								</Box>
							</Box>
							{isAssessmentVisibilitySettingSelected ? (
								<InformationMessage messageText={t('visibilitySettingError')} icon={true} />
							) : (
								<></>
							)}
							<InformationMessage messageText={<>t('infoVisibilitySettings')</>} icon={true} html={true} />
							<Box className='perform-visibility-settings'>
								<Box className='visibility-settings-blue-box'>
									<Box className='setting-row'>
										<FormControlLabel
											control={
												<Switch
													name='isVisibleIfEachCompleted'
													// disabled={
													// 	type === 'View' || (type === 'Edit' && editableDetails.status === Enums.TWO) ? true : false
													// }
													checked={Boolean(performanceFormData?.isVisibleIfEachCompleted)}
													onChange={handlePerformanceReviewValueChange}
												/>
											}
											label={t('isVisibleIfEachCompleted')}
											className='toggle-label'
										/>
									</Box>
									<Box className='setting-row'>
										<FormControlLabel
											control={
												<Switch
													name='isVisibleIfFinalCompleted'
													disabled={Boolean(performanceFormData?.isVisibleIfEachCompleted)}
													checked={Boolean(performanceFormData?.isVisibleIfFinalCompleted)}
													onChange={handlePerformanceReviewValueChange}
												/>
											}
											label={t('isVisibleIfFinalCompleted')}
											className='toggle-label'
										/>
									</Box>
									<Box className='setting-row'>
										<FormControlLabel
											control={
												<Switch
													name='isVisibleOnlyFinalIfFinalCompleted'
													disabled={Boolean(performanceFormData?.isVisibleIfEachCompleted)}
													checked={Boolean(performanceFormData?.isVisibleOnlyFinalIfFinalCompleted)}
													onChange={handlePerformanceReviewValueChange}
												/>
											}
											label={t('isVisibleOnlyFinalIfFinalCompleted')}
											className='toggle-label'
										/>
									</Box>
								</Box>
								<Box className='is-visible-three-sixty'>
									<Box className='setting-row'>
										<FormControlLabel
											control={
												<Switch
													name='isVisibleThreeSixtyCompleted'
													disabled={!performanceFormData?.isThreeSixtyEnabled}
													checked={Boolean(performanceFormData?.isVisibleThreeSixtyCompleted)}
													onChange={handlePerformanceReviewValueChange}
												/>
											}
											label={t('isVisibleThreeSixtyCompleted')}
											className='toggle-label'
										/>
									</Box>
								</Box>
							</Box>
						</Box> */}
						<Box className={`perform-card perform-input-stream assessment-visibility`}>
							<Box className='perform-card-head'>
								<Box>
									<Typography>{t('assessmentVisibility')}</Typography>
								</Box>
							</Box>
							<AssessmentSettingVisibility
								{...props}
								finalRatingDuration={finalRatingDuration}
								setScoreVisibilityDuration={setScoreVisibilityDuration}
								scoreVisibilityDuration={scoreVisibilityDuration}
								setIsPerformanceFormEdited={setIsPerformanceFormEdited}
								performanceVisibility={performanceVisibility}
								setPerformanceVisibility={setPerformanceVisibility}
								performanceFormData={performanceFormData}
								setVisibilityError={setVisibilityError}
								visibilityError={visibilityError}
								staticErrorObj={staticErrorObj}
								editableDetails={editableDetails}
								setPerformanceFormData={setPerformanceFormData}
							/>
						</Box>
						<Box className={`perform-card perform-input-stream assessment-visibility`}>
							<Box className='perform-card-head'>
								<Box>
									<Typography>{t('newFormVisibilitySettings')}</Typography>
								</Box>
							</Box>
							<AssessmentFormVisibility
								{...props}
								finalRatingDuration={finalRatingDuration}
								setScoreVisibilityDuration={setScoreVisibilityDuration}
								scoreVisibilityDuration={scoreVisibilityDuration}
								setIsPerformanceFormEdited={setIsPerformanceFormEdited}
								performanceFormVisibility={performanceFormVisibility}
								setPerformanceFormVisibility={setPerformanceFormVisibility}
								performanceFormData={performanceFormData}
								setVisibilityError={setVisibilityError}
								visibilityError={visibilityError}
								staticErrorObj={staticErrorObj}
								editableDetails={editableDetails}
								setPerformanceFormData={setPerformanceFormData}
							/>
						</Box>
						<Box className='perform-card perform-input-stream'>
							<Box className='perform-card-head'>
								<Box>
									<Typography className='form-label-required'>{t('assessmentForms')}</Typography>
								</Box>
							</Box>
							{/* <CompetencyRatingSettings
								{...props}
								performanceFormData={performanceFormData}
								setPerformanceFormData={setPerformanceFormData}
								handlePerformanceReviewValueChange={handlePerformanceReviewValueChange}
								performanceReviewRoleList={performanceReviewRoleList}
								performanceReviewRoleError={performanceReviewRoleError}
								performanceReviewCoreValueList={performanceReviewCoreValueList}
								performanceReviewCoreValueError={performanceReviewCoreValueError}
								competencyRatingFormError={competencyRatingFormError}
							/> */}
							<AssessmentFormRoleSettings
								{...props}
								performanceReviewRoleList={performanceReviewRoleList}
								setOpenAssessmentSettingForm={setOpenAssessmentSettingForm}
								assessmentFormSetting={assessmentFormSetting}
								setAssessmentFormSetting={setAssessmentFormSetting}
								performanceFormData={performanceFormData}
								toggleCoreClick={toggleCoreClick}
								setToggleCoreClick={setToggleCoreClick}
								setAssessmentError={setAssessmentError}
								assessmentError={assessmentError}
								setIsPerformanceFormEdited={setIsPerformanceFormEdited}
								editableDetails={editableDetails}
							/>
						</Box>
						<Box className='prs-notes-text'>
							<Typography variant='h6' className='font-weight-normal'>
								{t('prsSettingNote')}
							</Typography>
						</Box>
						<Box className='add-perform-action'>
							{type !== 'Edit' ? (
								<Box className='btn-section'>
									<OKRButton
										disabled={loader}
										//icon={<CancelIcon />}
										handleClick={handleBackButtonOnClick}
										className={'btn-link'}
										id={'btn-delete'}
										text={t('cancelBtn')}
									/>
								</Box>
							) : (
								<Box className='btn-section'>
									<OKRButton
										disabled={!isDraftCreated || loader}
										//icon={<DeleteIcon />}
										handleClick={deletePerformance}
										className={'btn-link'}
										id={'btn-delete'}
										text={t('delete')}
									/>
								</Box>
							)}
							{type !== 'View' ? (
								type === 'Edit' && editableDetails?.status === Enums.TWO ? (
									<>
										{isFormFilled ? (
											<OKRButton
												disabled={!isFormFilled || loader ? true : false}
												handleClick={savePerformanceAsPublish}
												className={'btn-primary'}
												id={'btn-publish'}
												text={t('saveBtn')}
											/>
										) : (
											<></>
										)}
									</>
								) : (
									<Box>
										<OKRButton
											// icon={<DraftIcon />}
											disabled={loader ? true : false}
											handleClick={savePerformanceAsDraft}
											className={'btn-link'}
											id={'btn-save-draft'}
											text={t('saveAsDraft')}
										/>

										{1 || isFormFilled ? (
											<OKRButton
												//disabled={!isFormFilled || loader ? true : false}
												handleClick={savePerformanceAsPublish}
												className={'btn-primary'}
												id={'btn-publish'}
												text={t('submit')}
											/>
										) : (
											<></>
										)}
									</Box>
								)
							) : (
								<></>
							)}
						</Box>
					</Box>
				) : (
					<></>
				)}
			</div>

			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}

			{modalPropsGracePeriodDate && modalPropsGracePeriodDate.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={
						'If you wish to extend the grace period for a particular assessment, please make the necessary changes in the designated input box for that specific stream.'
					}
					handleCloseModal={handleCloseGraceModal}
					modalOpen={modalPropsGracePeriodDate.open}
				/>
			)}
		</>
	);
};
