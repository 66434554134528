import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, Typography } from '@material-ui/core';

import { DownloadIcon } from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { AnimatedIcon } from '../../../Common/AnimatedIcon';
import { DialogComponent } from '../../../Admin/CommonComponent/DialogComponent';
import NofoundImg from '../../../../images/no-attachments.svg';

export const FeedbackAttachmentList = (props: any) => {
	const { pipDetails, showMsg } = props;
	const { t } = useTranslation();

	const [uploadFileData, setUploadFileData] = useState<any>(null);
	const [modalProps, setModalProps] = useState({ open: false, type: '', message: '', module: '', details: null });

	useEffect(() => {
		if (pipDetails?.documents) {
			setUploadFileData(pipDetails?.documents?.length ? pipDetails?.documents : []);
		}
	}, [pipDetails]);

	const handleUploadedFileDownload = async (e: any, file: any) => {
		try {
			const s3Url = file?.filePath || '';
			if (s3Url) {
				const response = await fetch(s3Url);
				const blob = await response.blob();

				// Create a link element and simulate a click to trigger the download
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = file?.uploadFileName || 'performance-improvement-development'; // Set the desired file name
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			} else {
				showMsg('error', 'An error occurred');
			}
		} catch (error) {
			showMsg('error', 'An error occurred');
			// Handle error accordingly
		}
	};
	// NOT IN USE Start
	const handleUploadedFileDeletion = (e: any, file: any) => {
		setModalProps({
			open: true,
			module: 'delete',
			type: 'deleteFile',
			details: { ...file },
			message: t('UsersBulkUploadDeleteFile'),
		});
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'deleteFile') {
				const { details } = modalProps;
				if (details) {
					removeFile(details);
				}
			}
		}
		setModalProps({ open: false, type: '', message: '', module: '', details: null });
	};
	const removeFile = (selectedFile: any) => {
		let updatedUploadFileData = uploadFileData && uploadFileData?.length ? [...uploadFileData] : [];
		updatedUploadFileData = updatedUploadFileData?.filter(
			(rec: any, index: number) => index !== selectedFile?.fileIndex
		);
		setUploadFileData(updatedUploadFileData);
	};
	// NOT IN USE End

	return (
		<>
			<Box className='pip-attachments-box full-rectangular-card'>
				<>
					{uploadFileData?.length ? (
						<Typography variant='h6'>{`Attachments (${uploadFileData?.length})`}</Typography>
					) : (
						<></>
					)}
					<Box className='upload-attachments-list'>
						<List>
							{uploadFileData && uploadFileData?.length ? (
								uploadFileData?.map((file: any, index: number) => {
									return (
										<ListItem>
											<span className='count'>{index + 1}</span>
											<Box className='file-name'>
												<Typography>{file?.uploadFileName}</Typography>
												<Box className='attachment-action'>
													<OKRButton
														icon={<DownloadIcon />}
														title={t('Download')}
														handleClick={(e: any) => handleUploadedFileDownload(e, { ...file, fileIndex: index })}
														className='btn'
													/>
													{/* <OKRButton
														icon={<DeleteIcon />}
														title={t('Delete')}
														handleClick={(e: any) => handleUploadedFileDeletion(e, { ...file, fileIndex: index })}
														className='btn'
													/> */}
												</Box>
											</Box>
										</ListItem>
									);
								})
							) : (
								<></>
							)}
						</List>
					</Box>
				</>
				{/* No Record content */}
				{uploadFileData && uploadFileData?.length === 0 ? (
					<Box className='user-no-record'>
						<Typography variant='h2'>{t('noAttachmentsUploaded')}</Typography>
						<Typography className='font-weight-normal'>{t('editPlanToUploadAttachments')}</Typography>
						<AnimatedIcon
							className=''
							width='188'
							type='image/svg+xml'
							alt={t('globalSearchNoRecord')}
							imagePath={NofoundImg}
						/>
					</Box>
				) : (
					<></>
				)}
			</Box>
			{modalProps.open && (
				<DialogComponent
					module='information'
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
